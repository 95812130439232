import React, { useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import { RoleRequired } from '../../app';
import {
  CommonGridFilter,
  CommonGridFilterHandle,
  FlexBox,
  InvertedRoundButton,
  IsolatedGrid,
  LightTooltip,
} from '../../shared/components';
import {
  AccountStatus,
  ConfirmDialogType,
  Dialogs,
  GridColumns,
  InformativeDialog,
  UserRole,
} from '../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import {
  getDateString,
  getGenericHeight,
  getGridRequest,
  isNullOrWhitespace,
  openConfirmDialog,
  openInformativeDialog,
} from '../../shared/utils';
import {
  clearSuppliersState,
  deleteInviteSupplierThunk,
  getSuppliersThunk,
  patchSupplierThunk,
  postResendInviteSupplierThunk,
  setSupplierActionPatch,
  setSupplierGridModel,
  setSupplierStatus,
} from './suppliersSlice';
import { setDialog } from '../main-screen';
import { setViewSupplierSupplierId } from './view-supplier';
import { MESSAGES_SUPPLIER_BY_STATUS } from '../../shared/constants';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

export const Suppliers = () => {
  const state = useAppSelector((x) => x.supplier);
  const appState = useAppSelector((x) => x.app);
  const stateConfirmDialog = useAppSelector((x) => x.confirmDialog);
  const { account: accountState } = { ...appState };
  const successInviteSupplier = useAppSelector((x) => x.inviteSupplier.success);
  const dispatch = useAppDispatch();
  const {
    grid,
    status: supplierStatus,
    successResendInvite,
    successDeleteInvite,
    actionPatch,
    successPatch,
  } = { ...state };
  const { confirmed, type, value: valueConfirmedDialog } = stateConfirmDialog;

  const { filter, sort, page, pageSize } = { ...grid };
  const filterRef = useRef<CommonGridFilterHandle>(null);

  const getParams = () => ({
    ...getGridRequest(grid),
    status: supplierStatus,
    accountId: accountState?.id ?? '-1',
  });

  useEffect(() => {
    if (!successResendInvite) return;
    openInformativeDialog({
      title: `Resent Invite`,
      contentTitle: 'Invite Sent',
      type: InformativeDialog.ResendInviteSupplier,
      text: 'View their status in the dashboard.',
      confirmText: 'Close',
      hideCheckBox: true,
    });
  }, [successResendInvite]);

  useEffect(() => {
    if (!successDeleteInvite) return;
    openInformativeDialog({
      title: `Cancel Invite`,
      contentTitle: 'Invitation Cancelled ',
      type: InformativeDialog.DeleteInviteSupplier,
      text: 'Your invitation has been cancelled. The supplier will no longer be able to complete the registration process.',
      confirmText: 'Close',
      hideCheckBox: true,
    });
    dispatch(getSuppliersThunk(getParams()));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successDeleteInvite, dispatch]);

  useEffect(() => {
    if (!successPatch) return;
    dispatch(getSuppliersThunk(getParams()));
    const configMessage =
      MESSAGES_SUPPLIER_BY_STATUS[actionPatch as keyof typeof MESSAGES_SUPPLIER_BY_STATUS];
    if (actionPatch === AccountStatus.Active || actionPatch === AccountStatus.Inactive) {
      const successMessage = configMessage?.successMessage ?? `Supplier ${actionPatch}`;
      toast.success(successMessage);
      return;
    }
    const contentTitle = `Supplier ${actionPatch}`;
    const text = configMessage?.successMessage ?? `Supplier ${actionPatch}`;
    const title = `${configMessage?.titleDialog ?? actionPatch} Supplier`;
    openInformativeDialog({
      contentTitle,
      title,
      type: InformativeDialog.PatchSupplier,
      text,
      confirmText: 'Close',
      hideCheckBox: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successPatch, dispatch]);

  useEffect(() => {
    dispatch(getSuppliersThunk(getParams()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, supplierStatus]);

  useEffect(() => {
    if (!successInviteSupplier) return;
    dispatch(getSuppliersThunk(getParams()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successInviteSupplier]);

  useEffect(() => {
    if (
      !confirmed ||
      type !== ConfirmDialogType.ResendInviteSupplier ||
      isNullOrWhitespace(valueConfirmedDialog)
    )
      return;
    const findUser = grid.data.results.find((item) => item.account.id === valueConfirmedDialog);
    const payload = {
      id: valueConfirmedDialog!,
      accountId: accountState?.id ?? '-1',
      username: findUser?.user.username ?? '-1',
    };
    dispatch(postResendInviteSupplierThunk(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed, type, dispatch]);

  useEffect(() => {
    if (
      !confirmed ||
      type !== ConfirmDialogType.PatchInviteSupplier ||
      isNullOrWhitespace(valueConfirmedDialog) ||
      isNullOrWhitespace(actionPatch)
    )
      return;
    dispatch(
      patchSupplierThunk({
        id: valueConfirmedDialog ?? '-1',
        payload: { status: actionPatch!, accountId: accountState?.id ?? '-1' },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed, type, dispatch]);

  useEffect(() => {
    if (
      !confirmed ||
      type !== ConfirmDialogType.CancelInviteSupplier ||
      isNullOrWhitespace(valueConfirmedDialog)
    )
      return;
    dispatch(
      deleteInviteSupplierThunk({
        id: valueConfirmedDialog!,
        accountId: accountState?.id ?? '-1',
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed, type, dispatch]);

  useEffect(
    () => () => {
      dispatch(clearSuppliersState());
    },
    [dispatch],
  );

  const viewDetailsSupplier = (id: string) => {
    dispatch(setViewSupplierSupplierId(id));
    dispatch(setDialog({ open: true, type: Dialogs.DetailsSupplier, maxWidth: 'md' }));
  };

  const resendInvite = (id: string) => {
    openConfirmDialog({
      title: 'Resend Invite',
      text: `The supplier you invited has not completed the registration process.\n Would you like to resend their invitation?`,
      confirmText: 'Resend',
      type: ConfirmDialogType.ResendInviteSupplier,
      value: id,
    });
  };

  const cancelInvite = (id: string) => {
    openConfirmDialog({
      title: 'Cancel Invite',
      text: `The supplier you invited has not completed the registration process.\n Are you sure you want to cancel their invitation?`,
      confirmText: 'Cancel Invite',
      type: ConfirmDialogType.CancelInviteSupplier,
      value: id,
    });
  };

  const patchMethod = (id: string, status: AccountStatus) => {
    const configMessage =
      MESSAGES_SUPPLIER_BY_STATUS[status as keyof typeof MESSAGES_SUPPLIER_BY_STATUS];
    const text = configMessage?.confirmMessage ?? '';
    const title = `${configMessage?.titleDialog} Supplier`;
    const confirmText = configMessage?.confirmText ?? status;

    openConfirmDialog({
      title,
      text,
      confirmText,
      type: ConfirmDialogType.PatchInviteSupplier,
      value: id,
    });
    dispatch(setSupplierActionPatch(status));
  };

  const showColumnInvitedOrIndustry = () => {
    if (supplierStatus !== AccountStatus.Pending) {
      return {
        field: 'industry',
        headerName: 'Industry',
        valueGetter: (params: any) =>
          params.row.account.industry.industry
            ? params.row.account.industry.industry
            : params.row.account.industry.displayLabel,
        flex: 1,
        filterable: true,
      };
    }
    return {
      field: 'createdAt',
      headerName: 'Invited',
      type: GridColumns.Date,
      flex: 1,
      filterable: true,
      renderCell: ({ row: { user } }: GridRenderCellParams<any, any, any>) =>
        !user?.createdAt ? (
          ''
        ) : (
          <LightTooltip title={getDateString(user.createdAt) ?? ''}>
            <Box>{getDateString(user.createdAt, true)}</Box>
          </LightTooltip>
        ),
    };
  };

  const columns: Array<GridColDef> = [
    {
      field: 'company',
      headerName: 'Account',
      valueGetter: (params) => params.row.account.company.name,
      flex: 1,
      filterable: true,
    },
    {
      field: 'username',
      headerName: 'Initial Contact',
      flex: 1,
      filterable: false,
      renderCell: ({ row: { user } }) => user?.username ?? '',
    },
    showColumnInvitedOrIndustry(),
    {
      field: 'action',
      disableReorder: true,
      resizable: false,
      headerName: 'Actions',
      type: GridColumns.Actions,
      flex: 1,
      minWidth: 300,
      renderCell: ({
        row: {
          account: { id },
        },
      }) => (
        <FlexBox sx={{ gap: 0.5 }}>
          <Button
            variant="outlined"
            color="info"
            data-testid="viewButton"
            sx={{ backgroundColor: '#fff' }}
            onClick={() => viewDetailsSupplier(id)}
          >
            View
          </Button>
          {(supplierStatus === AccountStatus.Active ||
            supplierStatus === AccountStatus.Inactive) && (
            <RoleRequired roles={[UserRole.OrgAdmin, UserRole.SuperAdmin]}>
              <Button
                variant="outlined"
                data-testid="lockButton"
                color="warning"
                sx={{ backgroundColor: '#fff' }}
                onClick={() => patchMethod(id, AccountStatus.Locked)}
              >
                Lock
              </Button>
            </RoleRequired>
          )}
          {(supplierStatus === AccountStatus.Inactive ||
            supplierStatus === AccountStatus.Locked) && (
            <RoleRequired roles={[UserRole.OrgAdmin, UserRole.SuperAdmin]}>
              <Button
                variant="outlined"
                data-testid="cancelButton"
                color="success"
                sx={{ backgroundColor: '#fff' }}
                onClick={() => patchMethod(id, AccountStatus.Active)}
              >
                Activate
              </Button>
            </RoleRequired>
          )}
          {(supplierStatus === AccountStatus.Active || supplierStatus === AccountStatus.Locked) && (
            <RoleRequired roles={[UserRole.OrgAdmin, UserRole.SuperAdmin]}>
              <Button
                variant="outlined"
                data-testid="deactivateButton"
                color="error"
                sx={{ backgroundColor: '#fff' }}
                onClick={() => patchMethod(id, AccountStatus.Inactive)}
              >
                Deactivate
              </Button>
            </RoleRequired>
          )}
          {supplierStatus === AccountStatus.Pending && (
            <RoleRequired roles={[UserRole.OrgAdmin, UserRole.SuperAdmin]}>
              <FlexBox sx={{ gap: 1 }}>
                <Button
                  variant="outlined"
                  color="warning"
                  data-testid="resendButton"
                  sx={{ backgroundColor: '#fff' }}
                  onClick={() => resendInvite(id)}
                >
                  Resend
                </Button>
                <Button
                  variant="outlined"
                  data-testid="cancelButton"
                  color="error"
                  onClick={() => cancelInvite(id)}
                  sx={{ backgroundColor: '#fff' }}
                >
                  Cancel
                </Button>
              </FlexBox>
            </RoleRequired>
          )}
        </FlexBox>
      ),
      sortable: false,
      filterable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  const addSupplier = () => {
    dispatch(setDialog({ open: true, type: Dialogs.InviteSupplier, maxWidth: 'md' }));
  };

  const onHandleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(setSupplierStatus(newValue));
    filterRef.current?.clearFilterSelections();
  };

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: colors.primary,
            height: '4px',
          },
        },
      },
    },
  });

  return (
    <Box className="dashboard" sx={{ px: '35px', py: '8px' }}>
      <FlexBox>
        <Typography
          sx={{ flex: 1, color: colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 700 }}
        >
          Suppliers
        </Typography>
        <RoleRequired roles={[UserRole.OrgAdmin, UserRole.SuperAdmin]}>
          <InvertedRoundButton
            sx={{
              backgroundColor: colors.primary,
              color: colors.secondary,
              fontWeight: fonts.fontWeight700,
            }}
            onClick={addSupplier}
          >
            Invite Supplier
          </InvertedRoundButton>
        </RoleRequired>
      </FlexBox>

      <Grid container alignItems="end">
        <Grid item md={6} sm={12}>
          <Box my={1}>
            <ThemeProvider theme={theme}>
              <Tabs value={supplierStatus} onChange={onHandleChangeTab}>
                <Tab
                  value={AccountStatus.Active}
                  label="Active"
                  sx={{
                    textTransform: 'capitalize',
                    color: supplierStatus === AccountStatus.Active ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: supplierStatus === AccountStatus.Active ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
                <Tab
                  value={AccountStatus.Inactive}
                  label="Inactive"
                  sx={{
                    textTransform: 'capitalize',
                    color: supplierStatus === AccountStatus.Inactive ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: supplierStatus === AccountStatus.Inactive ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
                <Tab
                  value={AccountStatus.Locked}
                  label="Locked"
                  sx={{
                    textTransform: 'capitalize',
                    color: supplierStatus === AccountStatus.Locked ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: supplierStatus === AccountStatus.Locked ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
                <Tab
                  value={AccountStatus.Pending}
                  label="Pending"
                  sx={{
                    textTransform: 'capitalize',
                    color: supplierStatus === AccountStatus.Pending ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: supplierStatus === AccountStatus.Pending ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
              </Tabs>
            </ThemeProvider>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box my={1} display="flex" justifyContent="flex-end">
            <CommonGridFilter
              columns={columns}
              currentFilter={filter}
              onChange={(x) => {
                dispatch(setSupplierGridModel({ filter: x }));
              }}
              ref={filterRef}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '400px', height: getGenericHeight(300), marginTop: 1 }}>
        <IsolatedGrid
          status={supplierStatus}
          columns={columns}
          model={grid}
          onChange={(x) => dispatch(setSupplierGridModel(x))}
        />
      </Box>
    </Box>
  );
};
