import React, { ChangeEvent } from 'react';
import { Typography, Grid } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import TextFieldInput from './TextFieldInput';
import InfoIcon from '../../../assets/svg/info-icon.svg';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface Props {
  typographyText?: string;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  value?: any;
  colWidth?: any;
  rows?: number;
  multiline?: boolean;
  variantType?: any;
  adornmentText?: any;
  helperText?: string;
  infoIcon?: boolean;
  tooltipText?: string;
  height?: string;
  required?: boolean;
  error?: boolean;
  defaultValue?: any;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const GridInput: React.FC<Props> = ({
  helperText,
  typographyText,
  disabled,
  type,
  placeholder,
  value,
  colWidth,
  rows,
  multiline,
  variantType,
  adornmentText,
  infoIcon,
  tooltipText = '',
  height,
  onChange,
  required,
  error,
  defaultValue,
}) => (
  <Grid item xs={12} sm={colWidth}>
    <Typography
      variant={variantType}
      sx={{
        display: 'flex',
        width: '100%',
        fontSize: `${fonts.fontSize16} !important`, 
        fontWeight: `${fonts.fontWeight500} !important`, 
        color: `${colors.textPrimary} !important`,
      }}
    >
      <span>{typographyText}</span>
      {infoIcon && (
        <Tooltip title={tooltipText} placement="top" arrow TransitionComponent={Zoom}>
          <img src={InfoIcon} style={{ marginLeft: '10px', marginTop: '5px' }} alt="info Icon" />
        </Tooltip>
      )}
    </Typography>
    <TextFieldInput
      helperText={helperText}
      disabled={disabled}
      type={type}
      placeholder={placeholder}
      value={value}
      rows={rows}
      multiline={multiline}
      adornmentText={adornmentText}
      height={height}
      onChange={onChange}
      required={false || required}
      error={error}
      defaultValue={defaultValue}
    />
  </Grid>
);
export default GridInput;
