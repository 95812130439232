import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Button } from '@mui/material';
import { useAppDispatch } from '../../shared/hooks';
import { fetchFundingbyUser } from './fundingSlice';
import '../sio/formFilling.scss';
import { Footer } from '../main-screen';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

const FundingFormLandingPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [fundingAmount, setFundingAmount] = useState(0);
  const [currency, setCurrency] = useState('');
  const [programName, setProgramName] = useState('');

  useEffect(() => {
    if (id) {
      dispatch(fetchFundingbyUser(id)).then((fundingData: any) => {
        setFundingAmount(fundingData.payload.fundingAmount);
        setCurrency(fundingData.payload.fundingCurrency);
        setProgramName(fundingData.payload.general.programName.platformProgramName);
      });
    }
  }, [id]);

  return (
    <section className="bgLight">
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardContent
              sx={{
                padding: '80px',
                flex: 1,
              }}
            >
              <Typography
                sx={{
                  mb: 4,
                  fontSize: fonts.fontSize24,
                  fontWeight: fonts.fontWeight700,
                  color: colors.textPrimary,
                }}
                gutterBottom
              >
                Congratulations! Your Social Impact Organization program, {`"${programName}"`} has
                been selected to receive {`${currency} ${fundingAmount}`} ScaleWith Funding.
              </Typography>
              <Typography
                sx={{
                  my: 4,
                  fontSize: fonts.fontSize16,
                  color: colors.textPrimary,
                }}
              >
                Please note that this form has been auto-populated with information your
                organization previously submitted via the ScaleWith and some information may need to
                be updated. Please fill out this Program Funding Form in order to receive the funds.
              </Typography>

              <Typography sx={{ my: 4, fontSize: fonts.fontSize16, color: colors.textPrimary }}>
                Please make any appropriate changes to the Program Funding Form, if and where
                necessary, to accurately reflect how the funds will be used before submitting for
                review.
              </Typography>

              <Typography sx={{ my: 4, fontSize: fonts.fontSize16, color: colors.textPrimary }}>
                Should you have any questions, please reach out to your Partnership Manager or email{' '}
                <span style={{ fontSize: fonts.fontSize16, color: colors.primary }}>
                  help@scalewith.com
                </span>
              </Typography>

              <Grid item xs={12} textAlign="left" sx={{ p: 0 }}>
                <Button
                  onClick={() => navigate(`/app/sio/funding/${id}/edit`)}
                  sx={{
                    my: 4,
                    backgroundColor: colors.buttonDefault,
                    ':hover': { backgroundColor: colors.buttonDefault },
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight500,
                    color: colors.secondary,
                  }}
                  variant="contained"
                  size="large"
                >
                  Get Started
                </Button>
              </Grid>
            </CardContent>
          </Card>
          <Footer />
        </Grid>
      </Grid>
    </section>
  );
};

export default FundingFormLandingPage;
