import { Theme, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { MenuButton } from './LeftNavBar';
import { setDialog } from './mainScreenSlice';
import { useAppDispatch } from '../../shared/hooks';
import { Dialogs } from '../../shared/enums';
import colors from '../../assets/scss/color.module.scss';

export const buttonStyle = (theme: Theme, isChild: boolean) => ({
  color: colors.textPrimary,
  backgroundColor: isChild ? colors.secondary : colors.secondary,
  marginX: '8px',
  // backgroundColor: isChild ? theme.palette.secondary.dark : theme.palette.secondary.dark,
  '& .MuiListItemIcon-root': { color: colors.textPrimary, minWidth: 34 },
  '&.active': {
    backgroundColor: `${colors.secondary}`,
    // color: '#034575',
    color: colors.primary,
    borderRadius: '10px',
    padding: '7px 12px',
    borderColor: colors.strokeClick,
    border: `1px solid ${colors.strokeClick}`,
    '& .MuiListItemIcon-root': { color: colors.primary },
  },
});

interface Props {
  button: MenuButton;
  paddingLeft?: number;
  handleNavigation?: (value: any) => void;
}

export const NavBarButton: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { button, paddingLeft, handleNavigation } = props;
  const { text, to, subMenu, id, icon, dialog } = button;

  const [open, setOpen] = React.useState(false);
  const linkProps = { component: NavLink, to, end: true };
  const hasSubMenu = subMenu?.length;

  const handleClick = () => {
    handleNavigation?.(window?.location?.pathname);
    if (dialog) dispatch(setDialog({ open: true, type: Dialogs.AccountSettings, maxWidth: 'md' }));
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        id={id}
        {...(to ? linkProps : {})}
        onClick={handleClick}
        sx={(theme) => ({ ...buttonStyle(theme, (paddingLeft ?? 0) > 0), paddingLeft })}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            sx: {
              fontSize: '18px',
              fontWeight: 600,
            },
          }}
        />
        {hasSubMenu && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {hasSubMenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subMenu!.map((x) => (
              <NavBarButton key={x.id} button={x} paddingLeft={(paddingLeft ?? 0) + 4} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};
