import React, { useEffect, ChangeEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Button,
  Tooltip,
  TextField,
  Divider,
  Card,
  CardContent,
  Typography,
  CardHeader,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import '../sio/formFilling.scss';
import { fetchProgrambyUser, updateProgrambyUserThunk } from './ProgramSlice';
import { uploadFile } from '../../api/portal/programRequest';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#68839A',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#FFF',
  },
}));

const ProgramContentQuestions = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const programState = useAppSelector((state) => state.programs);
  const { program } = programState;
  const initialFormValue = {
    filmLocation: program?.content?.filmLocation,
    assets: program?.content?.assets,
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [files, setFiles] = useState<File>();
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (id && program) {
      dispatch(fetchProgrambyUser(id)).then((programData: any) => {
        setFormValues({
          filmLocation: programData.payload?.content?.filmLocation,
          assets: programData.payload?.content?.assets,
        });
      });
    }
  }, [id]);

  const updateProgramDetails = () => {
    const payload = {
      content: {
        filmLocation: formValues.filmLocation,
        assets: formValues.assets,
      },
    };
    const programPayload = payload;
    if (id) {
      return dispatch(updateProgrambyUserThunk({ program: programPayload, id }));
    }
    return true;
  };

  const uploadFileData = async () => {
    const formData = new FormData();
    if (!files) return;
    formData.append('file', files);
    formData.append('path', 'content.assets');
    if (id) {
      const response = await uploadFile(id, formData);
      if (response.data) {
        setFormValues({
          ...formValues,
          assets: response.data.content.assets.file.name,
        });
        toast.success('Upload Successfull');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const handlePreviousbutton = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextButton = async () => {
    const response = await updateProgramDetails();
    if (response) {
      uploadFileData();
      if (pageNumber < 2) {
        setPageNumber(pageNumber + 1);
      } else {
        navigate(`/app/sio/programs/${id}`);
      }
    } else {
      toast.error('An error occurred while submitting in. Please try again.');
    }
    return null;
  };

  const handleSaveExit = async () => {
    const response = await updateProgramDetails();
    if (response) {
      navigate(`/app/sio/programs/${id}`);
    } else {
      toast.error('An error occurred while logging in. Please try again.');
    }
    return null;
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setFiles(fileList[0]);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  // *** Start Questions ***
  const question1 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {/* <Typography sx={{ mb: 5 }} variant='h3' >Let&apos;s get started!</Typography> */}
        <Typography sx={commonTextStyle.questionStyle}>Question 1 of 2</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Please describe where and when we could potentially film this program in action.
        </Typography>
        <Typography sx={{ mb: 2 }} variant="body1">
          ScaleWith aims to create custom content for each program and nonprofit on our platform.
          Please explain generally where and when we could potentially film this program in action
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Description{' '}
          <Tooltip title="Enter Description">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="filmLocation"
          placeholder="Enter Description"
          fullWidth
          value={formValues.filmLocation}
          required
          onChange={handleChange}
          variant="outlined"
          // margin="none"
          multiline
          rows={4}
          sx={commonTextStyle.textAreaStyle}
        />
      </Grid>
    </Grid>
  );

  const question2 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 2 of 2</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Please provide a zip file with all applicable photos and videos.
        </Typography>
        <Typography sx={{ mb: 2 }} variant="body1">
          ScaleWith recognizes that many of our social impact organizations have beautiful content.
          We want to leverage these assets to help you expand your opportunities with our clients.
          We will review any of your existing assets to determine if we are able to use them or not.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="assets"
          placeholder="Select File"
          fullWidth
          type="file"
          // value={formValues.assets}
          onChange={handleUpload}
          margin="normal"
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
        <br />
        {program?.content?.assets?.name && (
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ ...commonTextStyle.heading, mb: 2 }}>
              Uploaded Files:
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <b>File Name: </b>
              {program?.content?.assets?.name}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
  // *** end questions ***

  return (
    <section className="bgLight">
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardHeader
              sx={{
                padding: '20px 80px',
                background: colors.backgroundColorGradient,
                borderRadius: '20px 20px 0px 0px',
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize18,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Section 6
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize24,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Content
                    </Typography>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <Typography
                        sx={{
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fontWeight600,
                          color: colors.secondary,
                        }}
                      >
                        {pageNumber === 1 ? 0 : (pageNumber - 1) * 45}% complete
                      </Typography>
                    </Grid>
                    <BorderLinearProgress
                      variant="determinate"
                      value={pageNumber === 1 ? 1 : (pageNumber - 1) * 45}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent sx={{ minHeight: '70vh', padding: '40px 80px' }}>
              {pageNumber === 1 && question1}
              {pageNumber === 2 && question2}
              <Grid item xs={12} textAlign="right">
                <Divider sx={{ my: 3 }} orientation="horizontal" flexItem />
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  onClick={handleSaveExit}
                  variant="outlined"
                  size="large"
                >
                  Save and Exit
                </Button>
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  disabled={pageNumber === 1}
                  onClick={handlePreviousbutton}
                  variant="outlined"
                  size="large"
                >
                  Previous
                </Button>
                <Button
                  sx={{
                    ...commonTextStyle.buttonStyle,
                    mx: 2,
                    backgroundColor: colors.primary,
                    color: colors.secondary,
                  }}
                  onClick={handleNextButton}
                  variant="contained"
                  size="large"
                >
                  Next
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default ProgramContentQuestions;
