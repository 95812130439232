import { DeleteOutlineOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { FlexBox, FormDialog } from '../../../shared/components';
import { Dialogs } from '../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { emailValid } from '../../../shared/utils';
import {
  clearResendNotificationState,
  putResendNotificationThunk,
  removeResendNotificationEmailAt,
  addResendNotificationEmail,
  setResendNotificationEmail,
} from './resendNotificationSlice';

export function ResendNotification() {
  const state = useAppSelector((x) => x.resendNotification);
  const dispatch = useAppDispatch();
  const { loading, success, emails, email } = state;

  useEffect(
    () => () => {
      dispatch(clearResendNotificationState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (!success) return;

    toast.success('Notification resent');
  }, [success]);

  return (
    <FormDialog
      title="Resend Notification"
      type={Dialogs.ResendNotification}
      loading={loading}
      closeFlag={success}
      Buttons={
        <Button
          variant="contained"
          size="large"
          onClick={() => dispatch(putResendNotificationThunk())}
          disabled={emails.length === 0}
        >
          Resend Notification
        </Button>
      }
    >
      <FlexBox sx={{ flexDirection: 'column', gap: 2 }}>
        <Typography variant="body1" mt={1}>
          Who would you like to notify of the completed Social Impact Contribution via email?
        </Typography>
        <FlexBox sx={{ gap: 2, alignItems: 'baseline' }}>
          <TextField
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => {
              dispatch(setResendNotificationEmail(e.target.value));
            }}
            required
            error={!emailValid(email) || emails.findIndex((x) => x === email) !== -1}
            helperText={emails.findIndex((x) => x === email) !== -1 ? 'Email already on list' : ''}
          />
          <Box>
            <Button
              variant="contained"
              size="large"
              onClick={() => dispatch(addResendNotificationEmail(email))}
              disabled={!emailValid(email)}
            >
              Add
            </Button>
          </Box>
        </FlexBox>
        <List dense sx={{ height: 360, overflowY: 'auto' }}>
          {emails.map((x, index) => (
            <ListItem
              // eslint-disable-next-line react/no-array-index-key
              key={`resend-notification-email-${x}-${index}`}
              secondaryAction={
                <IconButton
                  onClick={() => {
                    dispatch(removeResendNotificationEmailAt(index));
                  }}
                >
                  <DeleteOutlineOutlined />
                </IconButton>
              }
            >
              <ListItemText primary={x} />
            </ListItem>
          ))}
        </List>
      </FlexBox>
    </FormDialog>
  );
}
