import { Button, TextField, Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { searchUser } from '../../../../../api';
import {
  CommonRolesForm,
  FlexBox,
  FormDialog,
  Loading as Loader,
} from '../../../../../shared/components';
import { Dialogs } from '../../../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { isNullOrWhitespace, emailValid } from '../../../../../shared/utils';
import { ROLE_TO_STRING } from '../../../../../shared/constants';
import {
  clearInviteUserState,
  postInviteUserThunk,
  setInviteUserEmail,
  setInviteUserFirstName,
  setInviteUserLastName,
  setInviteUserRoles,
  setInviteUserType,
} from './inviteUserSlice';
import fonts from '../../../../../assets/scss/font.module.scss';
import colors from '../../../../../assets/scss/color.module.scss';

export function InviteUser() {
  const state = useAppSelector((x) => x.inviteUser);
  const dispatch = useAppDispatch();
  const { success, loading } = state;
  const { firstName, lastName, username, roles } = state.invitedUser;
  const [showAlert, setShowAlert] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const isInviteValid = () =>
    !isNullOrWhitespace(firstName) ||
    (!isNullOrWhitespace(userData?.firstName) && !isNullOrWhitespace(lastName)) ||
    (!isNullOrWhitespace(userData?.lastName) &&
      emailValid(username) &&
      !isNullOrWhitespace(username));

  useEffect(
    () => () => {
      dispatch(clearInviteUserState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (success) toast.success('User invitation sent, thank you');
  }, [success]);

  const inviteUser = () => {
    dispatch(postInviteUserThunk());
  };

  const commonTextFieldStyle = {
    '& label': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
    '& input': {
      fontSize: fonts.fontSize18,
    },
    '&::placeholder': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
  };

  const handleSearchClick = (reqEmail: string) => {
    setIsLoading(true);
    const request = {
      username: reqEmail,
      offset: 0,
      count: 10,
      orderBy: 'asc',
    };

    searchUser(request)
      .then((res) => {
        if (res.status === 200 && res.data?.results?.length > 0) {
          setIsLoading(false);
          const modifiedUser = { ...res.data.results[0], roles: [] };
          dispatch(setInviteUserType('current-user'));
          setUserData(modifiedUser);
          setShowAlert(false);
        } else {
          setInviteUserType('new-user');
          setUserData(null);
        }
      })
      .catch((error) => {
        console.error('Error while searching user:', error);
        setUserData(null); // Reset user data in case of an error
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (userData === null) {
      setInviteUserFirstName('');
      setInviteUserLastName('');
    }
  }, [userData]);

  const handleLocalRoleChange = (role: string, isSelected: boolean) => {
    dispatch(setInviteUserRoles({ role, isSelected }));
  };

  return (
    <FormDialog
      title="Invite User"
      type={Dialogs.InviteUser}
      loading={loading}
      closeFlag={success}
      Buttons={
        <Button
          sx={{
            backgroundColor: !isInviteValid() ? colors.strokeDefault : colors.primary,
            color: colors.secondary,
          }}
          onClick={inviteUser}
          disabled={!isInviteValid()}
        >
          invite
        </Button>
      }
    >
      <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
        <FlexBox sx={{ gap: 4 }}>
          <form
            style={{ width: '100%' }}
            onSubmit={(e) => {
              e.preventDefault();
              setShowDetails(true);
              // setInviteUserType(
              //   userData && userData?.firstName !== null ? 'current-user' : 'new-user',
              // );
              handleSearchClick(username);
            }}
          >
            <TextField
              label="Email Address"
              fullWidth
              value={username}
              onChange={(e) => {
                dispatch(setInviteUserEmail(e.target.value));
              }}
              onBlur={() =>
                setInviteUserType(
                  userData && userData?.firstName !== null ? 'current-user' : 'new-user',
                )
              }
              error={!emailValid(username) && username.length > 0}
              required
              sx={commonTextFieldStyle}
              InputProps={{
                endAdornment: (
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </form>
        </FlexBox>
        {isLoading ? (
          <Loader loading />
        ) : (
          showDetails && (
            <>
              <FlexBox sx={{ gap: 4 }}>
                <TextField
                  label={userData === null && 'First Name'}
                  fullWidth
                  value={userData !== null ? userData?.firstName : firstName}
                  onChange={(e) => {
                    dispatch(setInviteUserFirstName(e.target.value));
                  }}
                  disabled={userData && userData?.firstName !== ''}
                  required={userData === null}
                  sx={commonTextFieldStyle}
                />
                <TextField
                  label={userData === null && 'Last Name'}
                  fullWidth
                  value={userData !== null ? userData?.lastName : lastName}
                  onChange={(e) => {
                    dispatch(setInviteUserLastName(e.target.value));
                  }}
                  disabled={userData && userData?.lastName !== ''}
                  required={userData === null}
                  sx={commonTextFieldStyle}
                />
              </FlexBox>
              <CommonRolesForm
                roles={userData === null ? roles : userData?.roles}
                onRoleChange={handleLocalRoleChange}
              />
              {showAlert && (
                <Alert severity="warning">
                  The {ROLE_TO_STRING.org_admin} role by default is view only.
                </Alert>
              )}
            </>
          )
        )}
      </FlexBox>
    </FormDialog>
  );
}
