import React, { useEffect, useState } from 'react';
import { Button, Slider, Typography, InputAdornment} from '@mui/material';
import { FlexBox, StyledInput } from '../../../shared/components';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface Props {
  onChange: (value: number) => void;
  quoteAmount?: number;
  currency: string;
  onClose: () => void;
  isDealAmount: boolean;
  minFundingAmount?: number;
  incentiveAmount?: number;
  defaultContributionPercentage?: number;
  onChangeAccordianEffect ?:(value:any)=>void;
}

const PercentDropdown: React.FC<Props> = ({
  onChange,
  quoteAmount,
  currency,
  onClose,
  isDealAmount,
  minFundingAmount,
  incentiveAmount,
  defaultContributionPercentage,
  onChangeAccordianEffect
}) => {
  const [selectedValue, setSelectedValue] = useState<number>(
    Math.ceil(((defaultContributionPercentage || 0.01) * (quoteAmount || 1)) / 100),
  );
  const [percentValue, setPercentValue] = useState<number>(defaultContributionPercentage || 0.01);

  const handlePercentChange = (e: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      const newIncentiveAmount = (newValue / 100) * (quoteAmount || 1);
      setPercentValue(newValue as number);
      setSelectedValue(Math.ceil(newIncentiveAmount));
    }
  };
  const handleInputChange = (newValue: number) => {
    const val = newValue || 0;
    const percent = (val / (quoteAmount || 1)) * 100;
    setPercentValue(percent);
    setSelectedValue(Math.ceil(val));
  };

  const handleApplyClick = () => {
    onChange(selectedValue);
    onClose();
    if(onChangeAccordianEffect){
      onChangeAccordianEffect(false)
    }
  
  };
  const marks = Array.from({ length: 9 }, (value, i) => ({
    value: i * 2,
    label: `${i * 2}%`,
  }));
  const formatNumberWithCommas = (inputNumber: string) => {
    const formattedNumber = inputNumber.replace(/,/g, '');
    const decimalIndex = formattedNumber.indexOf('.');
    if (decimalIndex !== -1) {
      const integerPart = formattedNumber
        .slice(0, decimalIndex)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const decimalPart = formattedNumber.slice(decimalIndex + 1, decimalIndex + 3);
      return `${integerPart}.${decimalPart}`;
    }
    return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  useEffect(() => {
    const amount = incentiveAmount;
    if (amount) {
      setSelectedValue(Math.ceil(amount));
      handleInputChange(amount);
    }
  }, [incentiveAmount]);

  const formatedValue = () => {
    const isZeros = selectedValue.toString().split('.')[1]?.slice(0, 2);
    if (isZeros === '00') {
      return Math.floor(selectedValue).toString();
    }
    return selectedValue.toString();
  };

  const errorMessage = () => {
    if (
      (minFundingAmount && selectedValue < minFundingAmount) ||
      (quoteAmount && selectedValue > quoteAmount)
    ) {
      return (
        <Typography variant="h6" sx={(theme) => ({ color: theme.palette.error.main })}>
          Contribution Amount should be minimum of ${minFundingAmount} and maximum of <br /> total
          deal value ${quoteAmount}
        </Typography>
      );
    }
    return null;
  };

  const isApplyDisable = () => {
    if (!isDealAmount) {
      return true;
    }
    if (minFundingAmount) {
      if (selectedValue < minFundingAmount) {
        return true;
      }
      if (quoteAmount && selectedValue > quoteAmount) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="PopperContainer percentageDropDown">
      {/* <Button onClick={onClose} className="close__button">
        <Close />
      </Button> */}
      <Typography variant="h5">Calculate Contribution amount</Typography>
      <FlexBox sx={{ width: '100%', gap: '5px' }}>
        <Slider
          defaultValue={2}
          value={Number(percentValue.toFixed(1))}
          onChange={handlePercentChange}
          min={0}
          step={2}
          getAriaValueText={(value: number) => `${value}%`}
          max={17}
          marks={marks}
          valueLabelDisplay="auto"
          style={{ color: colors.primary}}
        />
        {/* <Box sx={{ marginLeft: '10px !important'}}> */}
          {/* <TextField */}
          <Typography sx={{ width: '65px', border: `1px solid ${colors.primary}`, height: '35px', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14px !important', fontWeight: '500 !important', lineHeight: '16px !important', color: '#249ACB !important', fontStyle: 'normal !important'}}>{`${Number(percentValue.toFixed(1))}%`}</Typography>
          
          {/* size="small"
          value=
          aria-readonly */}
        {/* /> */}
        {/* </Box> */}
      </FlexBox>
      <Typography style={{ fontStyle: 'normal !important' }}>
        Slide the percentage to find out the total value of entered contribution amount .
      </Typography>
      <Typography sx={{fontWeight: '500 !important', fontSize: '12px !important', lineHeight: '14px !important', color: `${colors.textPrimary} !important`, marginBottom: '-15px !important'}}>
        Value
      </Typography>
      <FlexBox sx={{ gap: '2rem', alignItems: 'center', width: '100%' }}>
        <StyledInput
          sx={{ padding: '0', width: '100%', color: colors.textPrimary, fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight600 }}
          className="main-outline"
          value={formatNumberWithCommas(formatedValue())}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(parseInt(e.target.value.replace(/,/g, ''), 10))
          }
          startAdornment={
            <InputAdornment
              sx={{ height: '5rem', maxHeight: '100%', color: colors.textColor, fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight600 }}
              className="inputAdornment"
              position="start"
            >
              {currency}
            </InputAdornment>
          }
        />
        <Button
          variant="outlined"
          size="large"
          className="main-outline"
          onClick={handleApplyClick}
          disabled={isApplyDisable()}
          sx={{ height: '48px', width: '98px', fontsize: fonts.fontSize14, fontWeight: fonts.fontWeight700, borderColor: colors.primary, color: colors.primary}}
        >
          Apply
        </Button>
      </FlexBox>
      {errorMessage()}
    </div>
  );
};

export default PercentDropdown;
