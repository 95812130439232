// @ts-nocheck
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Grid, Typography, TextField, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import './programFundingForm.scss';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Box } from '@mui/system';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { fetchFundingbyUser } from './fundingSlice';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

type ChildComponentProps = {
  onDataSubmit: (data: any) => void;
  onQuantityChange: (data: any) => void;
};

interface FormValues {
  funding: {
    programDate: {
      start: string;
      end: string;
    };
    location: {
      value: string;
    };
    itemization: {
      value: {
        quantity: string;
        description: string;
      }[];
    };
  };
}

const commonTextStyle = {
  textFieldStyle: {
    marginTop: '5px',
    borderRadius: '5px',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fntWeight400,
    },
  },
};

const ProgramFundingInformation: React.FC<ChildComponentProps> = ({
  onDataSubmit,
  onQuantityChange,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const fundingState = useAppSelector((state) => state.funding);
  const { funding } = fundingState;
  const [fundingAmount, setFundingAmount] = useState(0);
  const [sumQuantity, setSumQuantity] = useState(0);

  const initialFormValue = {
    funding: {
      programDate: {
        start: funding.funding?.programDate?.start || '',
        end: funding.funding?.programDate?.start || '',
      },
      location: {
        value: funding.funding?.location?.value || '',
      },
      itemization: {
        value:
          funding.funding?.itemization?.value.map((item: any) => ({
            quantity: item.quantity || '',
            description: item.description || '',
          })) || [],
      },
    },
  };

  const [formValues, setFormValues] = useState(initialFormValue);

  useEffect(() => {
    if (id && funding) {
      dispatch(fetchFundingbyUser(id)).then((fundingData: any) => {
        setFundingAmount(fundingData.payload?.fundingAmount);
        const quantity = fundingData.payload?.funding.itemization.value.reduce(
          (sum: any, item: any) => sum + parseFloat(item.quantity || '0'),
          0,
        );
        setSumQuantity(quantity);
        setFormValues({
          funding: {
            programDate: {
              start: fundingData.payload?.funding.programDate.start || '',
              end: fundingData.payload?.funding.programDate.end || '',
            },
            location: {
              value: fundingData.payload?.funding.location.value || '',
            },
            itemization: {
              value:
                fundingData.payload?.funding.itemization.value.map((item: any) => ({
                  quantity: item.quantity || '',
                  description: item.description || '',
                })) || [],
            },
          },
        });
      });
    }
  }, [id]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'description' || name === 'quantity') {
      const newValue = {
        description: formValues.funding.itemization.value[0].description,
        quantity: formValues.funding.itemization.value[0].quantity,
      };
      newValue[name] = value;

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        funding: {
          ...prevFormValues.funding,
          itemization: {
            ...prevFormValues.funding.itemization,
            value: [newValue],
          },
        },
      }));
    } else {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        funding: {
          ...prevFormValues.funding,
          location: {
            value,
          },
        },
      }));
    }
  };

  const handleQuantityChange = (index: number, value: string) => {
    setFormValues((prevFormValues) => {
      const updatedItemization = [...prevFormValues.funding.itemization.value];
      updatedItemization[index] = {
        ...updatedItemization[index],
        quantity: value,
      };
      const quantity = updatedItemization.reduce(
        (sum: any, item: any) => sum + parseFloat(item.quantity || '0'),
        0,
      );
      setSumQuantity(quantity);
      return {
        ...prevFormValues,
        funding: {
          ...prevFormValues.funding,
          itemization: {
            value: updatedItemization,
          },
        },
      };
    });
  };

  const handleDescriptionChange = (index: number, value: string) => {
    setFormValues((prevFormValues) => {
      const updatedItemization = [...prevFormValues.funding.itemization.value];
      updatedItemization[index] = {
        ...updatedItemization[index],
        description: value,
      };
      return {
        ...prevFormValues,
        funding: {
          ...prevFormValues.funding,
          itemization: {
            value: updatedItemization,
          },
        },
      };
    });
  };

  const addItem = () => {
    const updatedFormValues: FormValues = { ...formValues };
    updatedFormValues.funding.itemization.value.push({
      quantity: '',
      description: '',
    });
    setFormValues(updatedFormValues);
  };

  function generateUniqueKey() {
    let counter = 0;
    counter += 1;
    return `item-${counter}`;
  }

  useEffect(() => {
    onDataSubmit(formValues);
  }, [formValues]);

  useEffect(() => {
    if (sumQuantity > fundingAmount) {
      onQuantityChange(true);
    } else {
      onQuantityChange(false);
    }
  }, [sumQuantity, fundingAmount]);

  return (
    <div>
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{ display: 'flex', gap: '2rem', marginTop: '2rem' }}
        className="formContainer"
      >
        <Grid container>
          <Grid
            item
            xs={14}
            sx={{
              background: colors.backgroundPrimary,
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              marginTop: '-30px',
              marginLeft: '-10px',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontSize: fonts.fontSize24,
                fontWeight: fonts.fontWeight700,
                color: colors.textPrimary,
              }}
            >
              Program Funding Information
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >{`${funding?.progress?.funding?.complete} of ${funding?.progress?.funding?.total} Completed`}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 1 of 3<span style={{ color: 'red' }}>*</span>
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              What are the start and end dates of this program?
            </Typography>
          </Grid>
          <Grid container direction="row" spacing={2} sx={{ marginTop: '2px', marginLeft: '-3px' }}>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  // mb: 1,
                  fontSize: fonts.fontSize16,
                  fontWeight: fonts.fontWeight500,
                  color: colors.textSecondary,
                }}
              >
                Start Date{' '}
                <Tooltip title="Select start date">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        variant="outlined"
                        defaultValue={formValues.funding.programDate.start}
                        fullWidth
                        margin="normal"
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '40px',
                          },
                          marginTop: '5px',
                          borderRadius: '5px',
                          '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                            fontSize: fonts.fontSize14,
                            fontWeight: fonts.fntWeight400,
                          },
                        }}
                      />
                    )}
                    value={formValues.funding.programDate.start}
                    onChange={(date) =>
                      setFormValues({
                        ...formValues,
                        funding: {
                          ...formValues.funding,
                          programDate: {
                            ...formValues.funding.programDate,
                            start: date !== null ? date : '',
                          },
                        },
                      })
                    }
                    toolbarPlaceholder="Select start date"
                    InputProps={{
                      sx: {
                        // border: `1px solid ${colors.strokeDefault}`,
                        // outline: `1px solid ${colors.strokeDefault}`,
                        '& .MuiOutlinedInput-root': {
                          height: '40px',
                          fontWeight: 'lighter',
                          fontSize: '1rem',
                          padding: '10px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '4px 12px 5px',
                        },
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: '1rem',
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  // mb: 1,
                  fontSize: fonts.fontSize16,
                  fontWeight: fonts.fontWeight500,
                  color: colors.textSecondary,
                }}
              >
                End Date{' '}
                <Tooltip title="Select end date">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <div>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        variant="outlined"
                        defaultValue={formValues.funding.programDate.end}
                        fullWidth
                        margin="normal"
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '40px',
                          },
                          marginTop: '5px',
                          borderRadius: '5px',
                          '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                            fontSize: fonts.fontSize14,
                            fontWeight: fonts.fntWeight400,
                          },
                        }}
                      />
                    )}
                    value={formValues.funding.programDate.end}
                    onChange={(date) =>
                      setFormValues({
                        ...formValues,
                        funding: {
                          ...formValues.funding,
                          programDate: {
                            ...formValues.funding.programDate,
                            end: date !== null ? date : '',
                          },
                        },
                      })
                    }
                    toolbarPlaceholder="Select start date"
                    InputProps={{
                      sx: {
                        border: '1px solid lightgrey',
                        '& .MuiOutlinedInput-root': {
                          height: '40px',
                          fontWeight: 'lighter',
                          fontSize: '1rem',
                          padding: '10px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '4px 12px 5px',
                        },
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: '1rem',
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{ display: 'flex', gap: '2rem', marginTop: '2rem' }}
        className="formContainer"
      >
        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 2 of 3<span style={{ color: 'red' }}>*</span>
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Please specify the location where this funding will be applied.
            </Typography>
          </Grid>
          <Grid container direction="row" spacing={2} sx={{ marginTop: '4px', marginLeft: '-3px' }}>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  mb: 1,
                  fontSize: fonts.fontSize16,
                  fontWeight: fonts.fontWeight500,
                  color: colors.textSecondary,
                }}
              >
                Location Description{' '}
                <Tooltip title="Enter location description">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                name="location"
                value={formValues.funding.location.value}
                onChange={handleChange}
                fullWidth
                placeholder="Enter location description here"
                multiline
                rows={2}
                maxRows={4}
                margin="normal"
                variant="outlined"
                sx={commonTextStyle.textFieldStyle}
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '1rem',
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{ display: 'flex', gap: '2rem', marginTop: '2rem' }}
        className="formContainer"
      >
        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 3 of 3<span style={{ color: 'red' }}>*</span>
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              How will this funding be used?
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Please ensure itemization adds up to the total funding amount.
            </Typography>
          </Grid>

          <Grid container direction="row" spacing={2} sx={{ marginTop: '4px', marginLeft: '-3px' }}>
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  border: `1px solid ${colors.strokeDefault}`,
                  backgroundColor: colors.backgroundPrimary,
                  borderRadius: '5px',
                  height: '40px',
                  marginTop: '-3px',
                }}
              >
                <AttachMoneyIcon
                  sx={{
                    color: 'action.active',
                    background: colors.backgroundSecondary,
                    padding: '10px',
                    margin: '0',
                    width: '45px',
                    borderRadius: '5px',
                    height: '20px',
                  }}
                />
                <Typography
                  gutterBottom
                  sx={{ color: colors.textPrimary, marginTop: '5px', mx: '5px' }}
                >
                  {sumQuantity}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Typography
            sx={{
              fontSize: fonts.fontSize14,
              fontWeight: fonts.fontWeight400,
              color: colors.textSecondary,
              // marginLeft: '3px'
              margin: '5px',
              marginX: '13px',
            }}
          >
            Of ${fundingAmount} itemized
          </Typography>

          <Grid container direction="row" spacing={2} sx={{ marginTop: '4px', marginLeft: '-3px' }}>
            {formValues.funding.itemization.value.map((item, index) => (
              <React.Fragment key={`item-${generateUniqueKey}`}>
                {/* Item Quantity */}
                <Grid item xs={12} sm={4}>
                  <Typography
                    sx={{
                      mb: 1,
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight500,
                      color: colors.textSecondary,
                    }}
                  >
                    {`Item ${index + 1} Quantity`}{' '}
                    <Tooltip title={`Enter item ${index + 1} quantity`}>
                      <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                    </Tooltip>
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: `1px solid ${colors.strokeDefault}`,
                      backgroundColor: colors.backgroundPrimary,
                      borderRadius: '5px',
                      height: '40px',
                      marginTop: '-3px',
                    }}
                  >
                    <AttachMoneyIcon
                      sx={{
                        color: 'action.active',
                        background: colors.backgroundSecondary,
                        padding: '10px',
                        margin: '0',
                        width: '45px',
                        borderRadius: '5px',
                        height: '20px',
                      }}
                    />
                    <TextField
                      type="number"
                      id={`quantity-${index}`}
                      name={`quantity-${index}`}
                      margin="normal"
                      placeholder="Enter item quantity"
                      value={item.quantity}
                      onChange={(e) => handleQuantityChange(index, e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                          '& .MuiInputBase-input': {
                            padding: '0',
                          },
                          '& input[type=number]': {
                            '-moz-appearance': 'textfield',
                          },
                          '& input[type=number]::-webkit-outer-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                          },
                          '& input[type=number]::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                          },
                        },
                      }}
                      sx={{
                        // marginBottom: '10px',
                        marginTop: '6px',
                        '& .MuiInputBase-root': {
                          border: 'none',
                          outline: 'none',
                          height: '40px',
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fntWeight500,
                          color: colors.textPrimary,
                        },
                        '& .MuiInput-root:before': {
                          borderBottom: 'none',
                        },
                        '& .MuiInput-root:after': {
                          borderBottom: 'none',
                        },
                        '& .MuiInput-root:hover': {
                          borderBottom: 'none',
                        },
                        borderRadius: '5px',
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                          fontSize: fonts.fontSize14,
                          fontWeight: fonts.fntWeight400,
                        },
                        ':hover': { borderBottom: 'none' },
                        width: '100%',
                        border: 'none',
                        outline: 'none',
                      }}
                    />
                  </Box>
                </Grid>

                {/* Item Description */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      mb: 1,
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight500,
                      color: colors.textSecondary,
                    }}
                  >
                    {`Item ${index + 1} Description`}{' '}
                    <Tooltip title={`Enter item ${index + 1} description`}>
                      <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                    </Tooltip>
                  </Typography>
                  <TextField
                    name={`description-${index}`}
                    placeholder="Enter item description"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    value={item.description}
                    onChange={(e) => handleDescriptionChange(index, e.target.value)}
                    InputProps={{
                      sx: {
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: '1rem',
                        },
                      },
                    }}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '40px',
                        color: colors.textPrimary
                      },
                      marginTop: '-3px',
                      borderRadius: '5px',
                      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                        fontSize: fonts.fontSize14,
                        fontWeight: fonts.fntWeight400,
                      },
                    }}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>

          <Button
            variant="outlined"
            onClick={addItem}
            disabled={sumQuantity > fundingAmount}
            sx={{
              border: `1px solid ${colors.strokeClick}`,
              borderRadius: '8px',
              color: colors.primary,
              ':hover': { borderColor: colors.primary },
              marginLeft: '10px',
            }}
          >
            Add Item
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProgramFundingInformation;
