import React from 'react';
import NumberFormat from 'react-number-format';
import { useAppSelector } from '../hooks';

interface Props {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  allowNegative?: boolean;
}

export const MoneyInputWithLocaleSymbol = React.forwardRef<any, Props>((props, ref) => {
  const appState = useAppSelector((x) => x.app);
  const { loadingUserData, userData } = { ...appState };
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandsGroupStyle="thousand"
      disabled={loadingUserData}
      prefix={userData?.locale?.settings.symbol ?? ' '}
      thousandSeparator
      decimalScale={2}
    />
  );
});
