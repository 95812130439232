/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button } from '@mui/material';
import { toast } from 'react-toastify';
import DeleteIcon from '../../../assets/svg/Delete.svg';
import FileIcon from '../../../assets/svg/file.svg';
import LinearWithValueLabel from './linearProgress';
import { uploadCompletionFile } from '../../../api/portal/programRequest';

interface props {
  imageFile: (data: Object) => void;
  researchFileData: any;
  formId: string;
}

const UploadFileComponent: React.FC<props> = ({ imageFile, researchFileData, formId }) => {
  const [uploaded, setUploaded] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [researchFile, setResearchFile] = useState({
    name: '',
    url: '',
  });
  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };

  const handleChange = (event: any) => {
    if (event.target.files.length > 0) {
      setFile(event.target.files[0]);
      if (event.target.files[0].size < 910180) {
        setUploaded(true);
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('path', 'researchAndEvaluation.researchFile');
        uploadCompletionFile(formId, formData).then((res) => {
          if (res.status === 200) {
            setResearchFile({
              name: res?.data?.researchAndEvaluation?.researchFile?.name,
              url: res?.data?.researchAndEvaluation?.researchFile?.url,
            });
          }
        });
      } else {
        toast.error('File size should not be more than 900 Kb');
      }
    } else {
      setUploaded(false);
    }
  };
  const deleteFile = () => {
    if (file) {
      setFile(null);
      setUploaded(false);
    }
  };

  useEffect(() => {
    if (researchFile?.name !== '') {
      imageFile(researchFile);
    }
  }, [researchFile]);

  useEffect(() => {
    setFile(researchFileData);
    setUploaded(true);
  }, [researchFileData]);

  return (
    <Grid
      container
      sx={{
        border: '1px dashed #005FEA',
        backgroundColor: '#E7F2FF',
        padding: '32px',
        borderRadius: '10px',
      }}
    >
      {!uploaded ? (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'white',
            borderRadius: '10px',
          }}
        >
          <Button variant="contained" color="primary" sx={{ m: 2 }} component="label">
            Upload Your File
            <input type="file" id="upload" onChange={handleChange} hidden />
          </Button>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            backgroundColor: 'white',
            borderRadius: '10px',
          }}
        >
          <Grid item xs={3} sx={{ marginRight: '-80px' }}>
            <img
              src={FileIcon}
              style={{ marginLeft: '10px', marginTop: '5px', height: '40px', width: '40px' }}
              alt="info Icon"
            />
          </Grid>
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              marginTop: '5px',
              marginLeft: '10px',
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h6">{file?.name}</Typography>
            </Grid>
            {file?.size && (
              <Grid item xs={12}>
                <Typography variant="h6">{file && formatBytes(file?.size)}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={8}>
              {file?.size && <LinearWithValueLabel />}
            </Grid>
            <Grid item xs={3} onClick={() => deleteFile()}>
              <img
                src={DeleteIcon}
                style={{ marginLeft: '40px', cursor: 'pointer' }}
                alt="info Icon"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default UploadFileComponent;
