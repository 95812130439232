import {
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  Box,
  Link,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Link as RouterLink } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { setViewErrorLogId } from '..';
import { RoleRequired } from '../../app';
import { setDialog } from '../main-screen/mainScreenSlice';
import {
  CommonGridFilter,
  CommonGridFilterHandle,
  FlexBox,
  IsolatedGrid,
  LightTooltip,
  RoundButton,
} from '../../shared/components';
import {
  BETWEEN_FILTER_OPERATOR,
  CONTAINS_FILTER_OPERATOR,
  EQUALS_FILTER_OPERATOR,
  FILTER_BY_TRANSACTION_ID,
  GIVEWITH_ROLES,
} from '../../shared/constants';
import { booleanToTextValues, ConfirmDialogType, Dialogs, GridColumns } from '../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import {
  booleanToText,
  getDateString,
  getGenericHeight,
  getGridRequest,
  isNullOrWhitespace,
  openConfirmDialog,
} from '../../shared/utils';
import {
  clearErrorLogsState,
  clearErrorLogsSuccessMessage,
  getErrorLogsThunk,
  patchErrorLogThunk,
  patchMultipleErrorLogThunk,
  setErrorLogsGridModel,
  setErrorLogsUnresolved,
} from './errorLogsSlice';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

const getConfirmDialogValues = (
  resolve: boolean,
  id?: string,
): {
  title: string;
  text?: string;
  type: ConfirmDialogType;
  confirmText?: string;
  value?: string;
} => {
  const multiple = isNullOrWhitespace(id);
  return resolve
    ? {
        title: `Resolve Error${multiple ? 's' : ''}?`,
        text: `Would you like to proceed with resolving ${
          multiple ? 'these errors' : 'this error'
        }?`,
        type: multiple
          ? ConfirmDialogType.ResolveMultipleErrorLogs
          : ConfirmDialogType.ResolveErrorLog,
        confirmText: 'Resolve',
        value: id,
      }
    : {
        title: `Unresolve Error${multiple ? 's' : ''}?`,
        text: `Would you like to proceed with unresolving ${
          multiple ? 'these errors' : 'this error'
        }?`,
        type: multiple
          ? ConfirmDialogType.UnresolveMultipleErrorLogs
          : ConfirmDialogType.UnresolveErrorLog,
        confirmText: 'Unresolve',
        value: id,
      };
};

export const ErrorLogs = () => {
  const state = useAppSelector((x) => x.errorsLog);
  const stateConfirmDialog = useAppSelector((x) => x.confirmDialog);
  const dispatch = useAppDispatch();

  const filterRef = useRef<CommonGridFilterHandle>(null);
  const { value, confirmed, type } = stateConfirmDialog;
  const { grid, successMessage, unresolved } = state;
  const { filter, sort, page, pageSize, selectionModel } = grid;

  const getErrorLogsGridRequest = () => ({ ...getGridRequest(grid), isResolved: !unresolved });

  useEffect(() => {
    dispatch(getErrorLogsThunk(getErrorLogsGridRequest()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, unresolved]);

  useEffect(() => {
    if (!successMessage) return;
    dispatch(getErrorLogsThunk(getErrorLogsGridRequest()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMessage]);

  useEffect(() => {
    if (isNullOrWhitespace(successMessage)) return;
    toast.success(successMessage);
    dispatch(clearErrorLogsSuccessMessage());
  }, [dispatch, successMessage]);

  useEffect(
    () => () => {
      dispatch(clearErrorLogsState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      !confirmed ||
      ![ConfirmDialogType.ResolveErrorLog, ConfirmDialogType.UnresolveErrorLog].find(
        (x) => x === type,
      ) ||
      isNullOrWhitespace(value)
    )
      return;
    dispatch(
      patchErrorLogThunk({
        id: value!,
        value: { isResolved: ConfirmDialogType.ResolveErrorLog === type },
      }),
    );
  }, [value, confirmed, type, dispatch]);

  useEffect(() => {
    if (
      !confirmed ||
      !selectionModel ||
      ![
        ConfirmDialogType.ResolveMultipleErrorLogs,
        ConfirmDialogType.UnresolveMultipleErrorLogs,
      ].find((x) => x === type)
    )
      return;

    selectionModel.forEach((x) =>
      dispatch(
        patchMultipleErrorLogThunk({
          id: x.toString(),
          value: { isResolved: unresolved },
        }),
      ),
    );
  }, [value, confirmed, type, dispatch, selectionModel, unresolved]);

  const viewError = (id: string) => {
    dispatch(setViewErrorLogId(id));
    dispatch(setDialog({ open: true, type: Dialogs.ViewErrorLog, maxWidth: 'md' }));
  };

  const resolveErrorLogs = (id?: string) => {
    openConfirmDialog(getConfirmDialogValues(true, id), 'sm');
  };

  const unresolveErrorLogs = (id?: string) => {
    openConfirmDialog(getConfirmDialogValues(false, id), 'sm');
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(setErrorLogsUnresolved(newValue === 'true'));
    filterRef.current?.clearFilterSelections();
  };

  const columns: Array<GridColDef> = [
    {
      field: 'createdAt',
      type: GridColumns.DateTime,
      headerName: 'Date',
      flex: 1,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      filterable: true,
      renderCell: (params) => (
        <LightTooltip title={getDateString(params?.value) ?? ''}>
          <Box>{getDateString(params?.value, true)}</Box>
        </LightTooltip>
      ),
    },
    {
      field: 'description',
      headerName: 'Error',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'source',
      headerName: 'Source',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'accountId',
      headerName: 'Account ID',
      flex: 1,
      filterable: true,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      valueGetter: (params) => params.row.account?.id,
    },
    {
      field: 'accountName',
      headerName: 'Account',
      flex: 1,
      filterable: false,
      renderCell: (params) =>
        params.row?.account?.id ? (
          <Link component={RouterLink} to={`/app/accounts/${params.row.account.id}`}>
            {params.row.account?.name}
          </Link>
        ) : (
          params.row.account?.name
        ),
    },
    {
      field: 'incentiveId',
      headerName: 'Order Unique ID',
      flex: 1,
      filterOperators: EQUALS_FILTER_OPERATOR,
      filterable: true,
      valueGetter: (params) => params.row.incentive?.id,
    },
    {
      field: 'orderName',
      headerName: 'ID',
      flex: 1,
      filterable: false,
      renderCell: (params) =>
        params.row?.order?.id ? (
          <Link component={RouterLink} to={`/app/impact/${params.row.incentive.id}/details`}>
            {params.row.incentive?.name}
          </Link>
        ) : (
          params.row.incentive?.name
        ),
    },
    {
      field: 'transactionId',
      headerName: 'Transaction ID',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
      valueGetter: (params) => params.row.transaction?.id,
    },
    {
      field: 'transactionName',
      headerName: 'Transaction',
      flex: 1,
      filterable: false,
      renderCell: (params) =>
        params.row?.transaction?.id ? (
          <Link
            component={RouterLink}
            to={`/app/accounts/transactions?${FILTER_BY_TRANSACTION_ID}=${params.row.transaction.id}`}
          >
            {params.row.transaction?.name}
          </Link>
        ) : (
          params.row.transaction?.name
        ),
    },
    {
      field: 'action',
      disableReorder: true,
      resizable: false,
      headerName: 'Actions',
      type: GridColumns.Actions,
      flex: 1,
      renderCell: ({ row: { isResolved, id } }) => (
        <RoleRequired roles={GIVEWITH_ROLES}>
          <FlexBox sx={{ gap: 1 }}>
            <Button
              variant="outlined"
              color="info"
              sx={{ backgroundColor: '#fff' }}
              onClick={() => {
                viewError(id);
              }}
            >
              View
            </Button>

            <Button
              variant="outlined"
              color="error"
              sx={{ backgroundColor: '#fff' }}
              onClick={() => (isResolved ? unresolveErrorLogs(id) : resolveErrorLogs(id))}
            >
              {booleanToText(!isResolved, booleanToTextValues.ResolveUnresolve)}
            </Button>
          </FlexBox>
        </RoleRequired>
      ),
      sortable: false,
      filterable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: colors.primary,
            height: '4px',
          },
        },
      },
    },
  });

  return (
    <Box className="dashboard" sx={{ px: '35px', pt: '12px', pb: '5px' }}>
      <FlexBox>
        <Typography sx={{ flex: 1, color: colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 700 }}>
          Error Log
        </Typography>
        <RoleRequired roles={GIVEWITH_ROLES}>
          {unresolved ? (
            <RoundButton onClick={() => resolveErrorLogs()} disabled={selectionModel?.length === 0}>
              Resolve Errors
            </RoundButton>
          ) : (
            <RoundButton
              onClick={() => unresolveErrorLogs()}
              disabled={selectionModel?.length === 0}
            >
              Unresolve Errors
            </RoundButton>
          )}
        </RoleRequired>
      </FlexBox>

      <Grid container alignItems="end">
        <Grid item md={3} sm={12}>
          <Box my={1}>
            <ThemeProvider theme={theme}>
              <Tabs value={`${unresolved}`} onChange={handleTabChange}>
                <Tab
                  value="true"
                  label="Unresolved"
                  sx={{
                    textTransform: 'capitalize',
                    color: unresolved ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: unresolved ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
                <Tab
                  value="false"
                  label="Resolved"
                  sx={{
                    textTransform: 'capitalize',
                    color: !unresolved ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: !unresolved ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
              </Tabs>
            </ThemeProvider>
          </Box>
        </Grid>
        <Grid item md={9} sm={12}>
          <Box my={1} display="flex" justifyContent="flex-end">
            <CommonGridFilter
              columns={columns}
              currentFilter={filter}
              onChange={(x) => {
                dispatch(setErrorLogsGridModel({ filter: x }));
              }}
              ref={filterRef}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '400px', height: getGenericHeight(300), marginTop: 1 }}>
        <IsolatedGrid
          status={unresolved}
          model={grid}
          columns={columns}
          onChange={(x) => dispatch(setErrorLogsGridModel(x))}
        />
      </Box>
    </Box>
  );
};
