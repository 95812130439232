/* eslint-disable react/no-array-index-key */
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { getAccountInstanceLocaleThunk, RoleRequired } from '../../app';
import {
  CommonGridFilter,
  CommonGridFilterHandle,
  FlexBox,
  InvertedRoundButton,
  IsolatedGrid,
} from '../../shared/components';
import {
  BETWEEN_FILTER_OPERATOR,
  CONTAINS_FILTER_OPERATOR,
  MAX_NUMBER_RECORDS,
} from '../../shared/constants';
import {
  booleanToTextValues,
  ConfirmDialogType,
  Dialogs,
  GridColumns,
  InstanceSettingsType,
  InstanceStatus,
  UserRole,
} from '../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import {
  getGenericHeight,
  isNullOrWhitespace,
  openConfirmDialog,
  booleanToText,
  formatter,
} from '../../shared/utils';
import { getGridRequest } from '../../shared/utils/grid';
import { setDialog } from '../main-screen';
import { setInstanceSettingsInstanceId, setInstanceSettingsIsNew } from './instance-settings';
import {
  clearInstancesState,
  deleteInstanceThunk,
  getInstanceLocalesThunk,
  getInstancesThunk,
  patchInstanceThunk,
  setInstancesActive,
  setInstancesGridModel,
} from './instancesSlice';
import { setCallInstanceThunk } from '../../app/appSlice';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';
import borders from '../../assets/scss/strokes.module.scss';

export const Instances = () => {
  const state = useAppSelector((x) => x.instances);
  const stateConfirmDialog = useAppSelector((x) => x.confirmDialog);
  const instanceSettingsSuccess = useAppSelector((x) => x.instanceSettings.success);

  const { value, confirmed, type } = stateConfirmDialog;
  const dispatch = useAppDispatch();

  const filterRef = useRef<CommonGridFilterHandle>(null);
  const { active, grid, success, toastMessage, localesLoading, locales } = state;
  const { filter, sort, page, pageSize } = grid;

  useEffect(() => {
    const status = active ? InstanceStatus.Active : InstanceStatus.Inactive;
    dispatch(getInstancesThunk({ status, ...getGridRequest(grid) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, active]);

  useEffect(() => {
    if (!instanceSettingsSuccess) return;
    const status = active ? InstanceStatus.Active : InstanceStatus.Inactive;
    dispatch(getInstancesThunk({ status, ...getGridRequest(grid) }));
    dispatch(getAccountInstanceLocaleThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceSettingsSuccess]);

  useEffect(() => {
    if (!success) return;
    const status = active ? InstanceStatus.Active : InstanceStatus.Inactive;
    dispatch(getInstancesThunk({ status, ...getGridRequest(grid) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(
    () => () => {
      dispatch(clearInstancesState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (!success || isNullOrWhitespace(toastMessage)) return;
    toast.success(toastMessage);
  }, [success, toastMessage]);

  useEffect(() => {
    if (!confirmed || type !== ConfirmDialogType.ActivateInstance || isNullOrWhitespace(value))
      return;
    dispatch(patchInstanceThunk(value!)).then((data: any) => {
      if (data?.meta?.requestStatus === 'fulfilled') {
        dispatch(setCallInstanceThunk(true));
      }
    });
  }, [value, confirmed, type, dispatch]);

  useEffect(() => {
    if (!confirmed || type !== ConfirmDialogType.DeactivateInstance || isNullOrWhitespace(value))
      return;
    dispatch(deleteInstanceThunk(value!)).then((data: any) => {
      if (data?.meta?.requestStatus === 'fulfilled') {
        dispatch(setCallInstanceThunk(true));
      }
    });
  }, [value, confirmed, type, dispatch]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(setInstancesActive(newValue === 'true'));
    filterRef.current?.clearFilterSelections();
  };

  const activateInstance = (id: string) => {
    openConfirmDialog(
      {
        title: 'Activate Instance ',
        text: 'Are you sure you want to activate this instance?',
        type: ConfirmDialogType.ActivateInstance,
        confirmText: 'Activate',
        value: id,
      },
      'sm',
    );
  };

  const deactivateInstance = (id: string) => {
    openConfirmDialog(
      {
        title: 'Deactivate Instance ',
        text: 'Are you sure you want to deactivate this instance? Deactivated instances can be reactivated from the inactive tab on the instances dashboard.',
        type: ConfirmDialogType.DeactivateInstance,
        confirmText: 'Deactivate',
        value: id,
      },
      'sm',
    );
  };

  const addInstance = () => {
    dispatch(setInstanceSettingsIsNew(true));
    dispatch(setDialog({ open: true, type: Dialogs.InstanceSettings, maxWidth: 'md' }));
  };

  const editInstance = (id: string) => {
    dispatch(setInstanceSettingsInstanceId(id));
    dispatch(setDialog({ open: true, type: Dialogs.InstanceSettings, maxWidth: 'md' }));
  };

  useEffect(() => {
    if (locales?.length !== 0) return;
    dispatch(
      getInstanceLocalesThunk({
        count: MAX_NUMBER_RECORDS,
        offset: 0,
        orderBy: 'name',
        descending: false,
      }),
    );
  }, [dispatch, locales]);

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'locale',
      valueGetter: (params) => params.row.settings?.locale.name,
      headerName: 'Locale',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'serviceFee',
      valueGetter: (params) => params.row.settings?.serviceFee,
      headerName: 'Service Fee',
      flex: 1,
      filterable: true,
      renderCell: ({
        row: {
          settings: { serviceFee },
        },
      }) => `${(serviceFee ?? 0) * 100}%`,
    },
    {
      field: 'defaultSKUs',
      headerName: 'Default SKUs',
      flex: 1,
      renderCell: ({
        row: {
          skus,
          settings: {
            locale: { id },
          },
        },
      }) => {
        const currentLocale = locales?.find((item) => item.id === id);
        return skus
          .map((item: number) => `${currentLocale?.settings.symbol ?? ''}${formatter.format(item)}`)
          .join(', \xa0');
      },
    },
    {
      field: 'type',
      headerName: 'Instance Type',
      flex: 1,
      type: GridColumns.SingleSelect,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      valueOptions: Object.values(InstanceSettingsType),
      filterable: true,
    },
    {
      field: 'causeAreaCount',
      type: GridColumns.Number,
      headerName: 'Cause Areas',
      flex: 1,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      filterable: true,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'loginUrl',
      valueGetter: (params) => params.row.settings?.loginUrl,
      headerName: 'Login URL',
      flex: 1,
      filterable: true,
    },
    {
      field: 'allowRegistration',
      valueGetter: (params) => params.row.settings?.allowRegistration,
      headerName: 'Registration Allowed',
      flex: 1,
      filterable: true,
      renderCell: ({
        row: {
          settings: { allowRegistration },
        },
      }) => booleanToText(allowRegistration),
    },
    {
      field: 'action',
      resizable: false,
      disableReorder: true,
      headerName: 'Actions',
      type: GridColumns.Actions,
      flex: 1,
      minWidth: 200,
      renderCell: ({ row: { id } }) => (
        <FlexBox sx={{ gap: 1 }}>
          <Button
            variant="outlined"
            color="info"
            // sx={{ backgroundColor: '#fff' }}
            onClick={() => editInstance(id)}
            sx={{
              backgroundColor: colors.secondary,
              fontSize: fonts.fontSize14,
              fontWeight: fonts.fontWeight700,
              color: colors.primary,
              borderRadius: borders.borderRadius8,
              borderColor: colors.primary,
              '&:focus': colors.primary,
              '&:hover': colors.primary,
            }}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            color={active ? 'error' : 'success'}
            // sx={{ backgroundColor: '#fff' }}
            onClick={() => (active ? deactivateInstance(id) : activateInstance(id))}
            sx={{
              backgroundColor: colors.secondary,
              fontSize: fonts.fontSize14,
              fontWeight: fonts.fontWeight700,
              color: active ? colors.statusAlert : colors.statusSuccess,
              borderRadius: borders.borderRadius8,
              borderColor: active ? colors.statusAlert : colors.statusSuccess,
              '&:focus': active ? colors.statusAlert : colors.statusSuccess,
              '&:hover': active ? colors.statusAlert : colors.statusSuccess,
            }}
          >
            {booleanToText(!active, booleanToTextValues.DeactivateActive)}
          </Button>
        </FlexBox>
      ),
      sortable: false,
      filterable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: colors.primary,
            height: '4px',
          },
        },
      },
    },
  });

  return (
    <Box className="dashboard" sx={{ pb: '30px' }}>
      <FlexBox sx={{ gap: 1 }}>
        <Typography
          sx={{ flex: 1, color: colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 700 }}
        >
          Instances
        </Typography>
        <RoleRequired roles={[UserRole.SuperAdmin]}>
          <InvertedRoundButton sx={{
              height: '48px',
              marginLeft: '30px',
              paddingX: '16px',
              fontWeight: fonts.fontWeight700,
              fontSize: fonts.fontSize14,
              backgroundColor: `${colors.primary} !important`,
              color: `${colors.secondary} !important`,
              borderRadius: '8px !important',
              '&.Mui-disabled': {
                backgroundColor: `${colors.strokeDefault} !important`,
              },
            }} onClick={addInstance}>
            New Instance
          </InvertedRoundButton>
        </RoleRequired>
      </FlexBox>

      <Grid container alignItems="end">
        <Grid my={1} item md={3} sm={12}>
          <Box>
            <ThemeProvider theme={theme}>
              <Tabs value={`${active}`} onChange={handleTabChange}>
                <Tab
                  value="true"
                  label="Active"
                  sx={{
                    textTransform: 'capitalize',
                    color: active ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: active ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
                <Tab
                  value="false"
                  label="Inactive"
                  sx={{
                    textTransform: 'capitalize',
                    color: !active ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: !active ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
              </Tabs>
            </ThemeProvider>
          </Box>
        </Grid>
        <Grid item md={9} sm={12} my={1}>
          <Box display="flex" justifyContent="flex-end">
            <CommonGridFilter
              columns={columns}
              currentFilter={filter}
              onChange={(x) => {
                dispatch(setInstancesGridModel({ filter: x }));
              }}
              ref={filterRef}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '400px', height: getGenericHeight(300), marginTop: 1 }}>
        <IsolatedGrid
          status={active}
          model={grid}
          columns={columns}
          onChange={(x) => dispatch(setInstancesGridModel(x))}
          extraLoading={localesLoading}
          alternativeName="instance"
        />
      </Box>
    </Box>
  );
};
