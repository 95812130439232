import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Dialogs } from '../../enums';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { FormDialog } from '../FormDialog';
import { BoldTypography, FlexBox } from '../StyledComponents';
import {
  clearInformativeDialog,
  setInformativeDialogClose,
  setInformativeDialogConfirmed,
} from './informativeDialogSlice';

export const InformativeDialog = () => {
  const state = useAppSelector((x) => x.informativeDialog);
  const dispatch = useAppDispatch();

  const { text, title, confirmed, confirmText, close, hideCheckBox, contentTitle } = state;

  useEffect(
    () => () => {
      dispatch(clearInformativeDialog());
    },
    [dispatch],
  );

  return (
    <FormDialog title={title} type={Dialogs.InformativeDialog} hideCancel closeFlag={close}>
      <FlexBox sx={{ flexDirection: 'column', gap: 3, alignItems: 'center' }}>
        <BoldTypography variant="h6">{contentTitle}</BoldTypography>
        <Typography>{text}</Typography>
        <Button
          variant="contained"
          sx={{ width: 100 }}
          onClick={() => dispatch(setInformativeDialogClose())}
        >
          {confirmText ?? 'Got It'}
        </Button>
        {!hideCheckBox && (
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmed}
                onChange={(e) => dispatch(setInformativeDialogConfirmed(e.target.checked))}
              />
            }
            label="Do not Show this message again"
          />
        )}
      </FlexBox>
    </FormDialog>
  );
};
