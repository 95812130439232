export enum CauseAreaMetricsPills {
  ProposedValue = 'Proposed Value',
  ProposedCount = 'Proposed Count',
  ApprovedValue = 'Approved Value',
  ApprovedCount = 'Approved Count',
  CompletedValue = 'Completed Value',
  CompletedCount = 'Completed Count',
  AvgGive = 'Avg. Impact',
  WinRate = 'Win Rate',
  Impact = 'Impact',
  AvgDeal = 'Avg. % of Deal',
}
