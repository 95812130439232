import { Typography, IconButton, Tooltip, Button } from '@mui/material';
import React, { useEffect } from 'react';
import _ from 'lodash';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';
import { Box } from '@mui/system';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import TextAreaInput from '../common/textAreaInput';
import { FormDialog } from '../FormDialog';
import { ORDERS_NEGATIVE_ACTIONS } from '../../constants';
import { Dialogs, ContributionStatus } from '../../enums';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { clearDialog } from '../../../features/main-screen/mainScreenSlice';
import { clearUpdateContributionStatusState } from './updateIncentiveStatusSlice';
import { getContributionActionMessage } from '../../utils';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';
import borders from '../../../assets/scss/strokes.module.scss';

const getTitle = (status?: ContributionStatus) =>
  `Your Contribution has been ${getContributionActionMessage(status)}`;

export function SuccessIncentiveDialog() {
  const dispatch = useAppDispatch();
  const state = useAppSelector((x) => x.updateContributionStatus);
  const appState = useAppSelector((x: any) => x.app);
  const {
    account: { settings },
  } = appState;
  const { successIncentive } = state;

  useEffect(
    () => () => {
      dispatch(clearUpdateContributionStatusState({ clearSuccessIncentive: true }));
    },
    [dispatch],
  );

  if (!successIncentive) return <p>order not found</p>;
  const { status, incentiveId, causeArea, id } = successIncentive;
  return (
    <FormDialog
      subtitle={ORDERS_NEGATIVE_ACTIONS.includes(status!) ? undefined : 'Thank you!'}
      title={getTitle(status)}
      type={Dialogs.SuccessContributionDialog}
      hideCancel
      Buttons={
        <>
          {status !== ContributionStatus.Deleted && (
            <Button
              variant="outlined"
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.primary,
                borderRadius: borders.borderRadius8,
                borderColor: colors.primary,
                '&:focus': colors.primary,
                '&:hover': colors.primary,
              }}
              // size="large"
              // sx={{ padding: 1, minWidth: 180 }}
              href={`/app/contribution/${id}/${
                status !== ContributionStatus.Draft ? 'details' : 'draft'
              }`}
              onClick={() => dispatch(clearDialog(Dialogs.SuccessContributionDialog))}
            >
              View Details
            </Button>
          )}
          <Button
            variant="contained"
            size="large"
            sx={{ padding: 1, minWidth: 180 }}
            href="/app/dashboard"
            onClick={() => dispatch(clearDialog(Dialogs.SuccessContributionDialog))}
          >
            Go to Dashboard
          </Button>
        </>
      }
    >
      <Typography variant="body1">
        Contribution ID: {incentiveId || successIncentive.giveId}
      </Typography>
      <Typography variant="body1">{causeArea?.name ?? ''}</Typography>
      {status !== ContributionStatus.Draft &&
        settings?.showProposalText &&
        !_.isEmpty(successIncentive?.proposalText) && (
          <Box gap={0} sx={{ marginTop: '20px' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', width: '100%' }}
            >
              <Typography variant="h5">Proposal Text</Typography>
              {successIncentive?.proposalText && (
                <CopyToClipboard
                  text={successIncentive?.proposalText}
                  onCopy={() => toast.success('Text Copied Successfully')}
                >
                  <Tooltip title="Copy Text to clipboard" arrow>
                    <IconButton size="medium">
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              )}
            </Box>
            <Box sx={{ width: '100%' }}>
              <TextAreaInput
                tooltipText="Proposal Text"
                isTextField
                value={successIncentive?.proposalText}
              />
            </Box>
          </Box>
        )}
    </FormDialog>
  );
}
