import { AddCircleOutlined, RemoveCircleOutlined } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FlexBox } from '../../../shared/components';
import { CauseAreaSku, CauseAreaSelectedSku } from '../../../shared/interfaces';

interface Props {
  sku: CauseAreaSku;
  amount: number;
  skuChanged?: (value: CauseAreaSelectedSku) => void;
  disabled?: boolean;
}

export const Sku: FC<Props> = (props) => {
  const { sku, amount, skuChanged, disabled } = props;
  return (
    <FlexBox
      sx={(theme) => ({
        backgroundColor: theme.palette.common.grey.main,
        padding: 1,
        borderRadius: 2,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      })}
    >
      <Typography variant="h2">{sku.description}</Typography>
      <Typography variant="h6">{sku.currency ?? ''}</Typography>
      <FlexBox sx={{ alignItems: 'center', gap: 1 }}>
        <IconButton
          size="small"
          onClick={() => {
            if (amount === 0) return;
            if (skuChanged) skuChanged({ sku, value: amount - 1 });
          }}
          disabled={disabled}
        >
          <RemoveCircleOutlined />
        </IconButton>
        <Typography variant="body1">{amount}</Typography>
        <IconButton
          size="small"
          color="primary"
          onClick={() => {
            if (skuChanged) skuChanged({ sku, value: amount + 1 });
          }}
          disabled={disabled}
        >
          <AddCircleOutlined />
        </IconButton>
      </FlexBox>
    </FlexBox>
  );
};
