import React from 'react';
import {
  alpha,
  Box,
  Button,
  LinearProgress,
  linearProgressClasses,
  Menu,
  MenuProps,
  OutlinedInput,
  Paper,
  PaperProps,
  Stepper,
  styled,
  Switch,
  Typography,
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import fonts from '../../assets/scss/font.module.scss';

export const InputBox = styled(Box)(() => ({
  minHeight: '40px',
  margin: '15px 0 0',
}));

export const RoundedBox = styled(Box)(() => ({
  borderRadius: '24px',
  backgroundColor: 'rgb(0 0 0/4%)',
}));

export const CircleContainer = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  width: 34,
  height: 34,
  padding: 10,
  background: '#fff',
  border: `3px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  textAlign: 'center',
  fontSize: '1.75rem',
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
}));
export const FlexBoxColumn = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const SwitchLabelTypography = styled(Typography)(() => ({
  color: '#424242',
}));

export const PaddingRoundedBox = styled(Box)(() => ({
  borderRadius: 8,
  paddingTop: 24,
  paddingBottom: 24,
  paddingLeft: 32,
  paddingRight: 32,
  boxSizing: 'border-box',
}));

export const WhiteRoundedBox = styled(Box)(() => ({
  borderRadius: 8,
  backgroundColor: '#fff',
  padding: 16,
}));

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: '.9rem',
    padding: '6px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: `1px solid rgba(0, 0, 0, 0.4)`,
    },
    fontSize: '1.8rem',
    color: theme.palette.common.white,
  },
}));

export const RoundButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  fontSize: '1.2rem',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'white',
  borderRadius: 50,
  color: theme.palette.secondary.dark,
  ':hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.common.grey.contrast,
  },
}));

export const InvertedRoundButton = styled(RoundButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  borderColor: theme.palette.primary.main,
  ':hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 14,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.common.grey.main,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'light' ? '#0052b7' : '#308fe8',
  },
}));

export const CommonPaper = styled(({ ...props }: PaperProps) => (
  <Paper variant="outlined" {...props}>
    {props.children}
  </Paper>
))(({ theme }) => ({
  padding: 16,
  backgroundColor: theme.palette.common.grey.main,
}));

export const ThankYouTypography = styled(Typography)(() => ({
  fontSize: '1.25rem',
  marginBottom: 0,
}));

export const BoldTypography = styled(Typography)(() => ({
  fontWeight: 700,
}));

export const OverViewLabelTypography = styled(Typography)(() => ({
  flex: 0.7,
  fontSize: '0.875rem',
}));

export const PopularTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  width: 'max-content',
  padding: '0 5px',
  borderRadius: 2,
}));

export const GreenTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  width: 'max-content',
  padding: '0 5px',
  borderRadius: 2,
}));

export const MainTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.primary.contrastText
            : theme.palette.primary.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export const InvertedStepper = styled(Stepper)(({ theme }) => ({
  color: theme.palette.common.white,
  '& .MuiStepIcon-root': {
    color: theme.palette.primary.dark,
    width: '1.5rem',
    height: '1.5rem',
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: theme.palette.common.white,
  },
  '& .MuiSvgIcon-root.Mui-active .MuiStepIcon-text': {
    fill: theme.palette.common.black,
  },
  '& .MuiStepIcon-text': {
    fontSize: '14px',
    fill: theme.palette.common.white,
  },
  '& .MuiStepLabel-label': {
    color: theme.palette.common.white,
    fontSize: '1.5rem',
  },
  '& .MuiStepLabel-label.Mui-active': {
    color: theme.palette.common.white,
    fontWeight: 700,
  },
  '& .MuiStepLabel-label.Mui-completed': {
    color: theme.palette.common.grey.light,
  },
  '& .MuiStepIcon-root.Mui-completed': {
    color: theme.palette.common.white,
  },
  '& .MuiStepContent-root, .MuiStepConnector-root': {
    marginLeft: '20px',
  },
  '& .MuiStepLabel-iconContainer': {
    width: '40px',
    height: '34px',
  },
  '& .MuiSvgIcon-root': {
    width: '40px',
    height: '34px',
  },
}));

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxHeight: 550,
    minWidth: 400,
    maxWidth: 400,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  'label + &': {
    position: 'relative',
    marginTop: theme.spacing(2.8),
  },
  '& .MuiFormControl-root': {
    minWidth: '100%',
    height: '40px',
  },
  '& .MuiInputBase-root': {
    borderRadius: 4,
    position: 'relative',
    fontSize: fonts.fontSIze16, // Set the base font size to 16px
    lineHeight: '150%',
    minWidth: '100%',
    display: 'flex',
    height: '40px',
    paddingLeft: '0',
    '& input': {
      fontSize: fonts.fontSIze16, // Set the font size for the entered text to 16px
    },
    '&::placeholder': {
      fontSize: fonts.fontSIze16, // Set the font size for the placeholder to 16px
    },
    '&:focus': {
      '& input': {
        fontSize: fonts.fontSIze16, // Set the font size for the focused text to 16px
      },
    },
  },
  height: '40px',
  paddingLeft: '0',
}));
