import { CANCELED_CONTRIBUTIONS_SEARCH_PARAM } from '../constants';
import { ContributionActions, ContributionStatus, ContributionStatusLabel } from '../enums';

export function getContributionStatusLabel(
  status: ContributionActions | ContributionStatus,
): ContributionStatusLabel {
  switch (status) {
    case ContributionStatus.Fulfilled:
      return ContributionStatusLabel.Fulfill;
    case ContributionActions.shared:
      return ContributionStatusLabel.share;
    case ContributionStatus.Completed:
    default:
      return ContributionStatusLabel.Complete;
  }
}

export const getContributionActionMessage = (status?: ContributionStatus) => {
  switch (status) {
    case ContributionStatus.PendingApproval:
      return 'submitted';
    case ContributionStatus.Draft:
      return 'saved as draft';
    case ContributionStatus.Deleted:
      return 'deleted';
    case ContributionStatus.Abandoned:
      return 'abandoned';
    case ContributionStatus.Rejected:
      return 'rejected';
    case ContributionStatus.Fulfilled:
      return 'fulfilled';
    case ContributionStatus.Denied:
      return 'denied';
    case ContributionStatus.Approved:
      return 'approved';
    case ContributionStatus.Completed:
      return 'completed';
    case ContributionStatus.FinancialHold:
      return 'financially withheld';
    case ContributionStatus.PendingPayment:
        return 'completed';
    default:
      return '';
  }
};

export const getCanceledContributionsUrl = (href: string, contributionId: Array<string>) => {
  const url = new URL(href);
  contributionId.forEach((x) => url.searchParams.append(CANCELED_CONTRIBUTIONS_SEARCH_PARAM, x));
  return url;
};
