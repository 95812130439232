import { createTheme, responsiveFontSizes } from '@mui/material';
import type {} from '@mui/lab/themeAugmentation';
import { Link, LinkProps } from 'react-router-dom';
import React from 'react';
import main from './scss/_main.module.scss';
import { platform } from '../shared/utils';

const LinkBehavior = React.forwardRef<any, Omit<LinkProps, 'to'> & { href: LinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props;
    return <Link ref={ref} to={href} {...other} />;
  },
);
const platformName = platform();
const getPrimaryColors = (platName: string) => {
  switch (platName) {
    case 'buy':
      return {
        main: main.BuywithDark,
        light: main.BuywithLight,
        dark: main.BuywithDark,
      };
    case 'sell':
      return {
        main: main.SellwithDark,
        light: main.SellwithLight,
        dark: main.SellwithDark,
      };
    case 'give':
      return {
        main: main.GivewithDark,
        light: main.GivewithLight,
        dark: main.GivewithDark,
      };
    default:
      return {
        main: main.ScalewithDark,
        light: main.ScalewithLight,
        dark: main.ScalewithDark,
      };
  }
};
declare module '@mui/material/styles/createPalette' {
  export interface CommonColors {
    grey: {
      light: string;
      main: string;
      contrast: string;
      text: string;
    };
    masala: string;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: main.primaryFontFamily,
    h2: {
      fontSize: '3rem',
      fontFamily: main.headerFontFamily,
    },
    h3: {
      fontSize: '2.2rem',
      fontFamily: main.primaryFontFamily,
    },
    h1: {
      fontFamily: main.headerFontFamily,
    },
  },
  palette: {
    primary: getPrimaryColors(platformName),
    secondary: {
      main: main.secondaryMain,
      light: main.secondaryLight,
      dark: main.secondaryDark,
    },
    success: {
      main: main.successMain,
      light: main.successLight,
      dark: main.successDark,
    },
    warning: {
      main: main.warningMain,
      light: main.warningLight,
      dark: main.warningDark,
    },
    error: {
      main: main.errorMain,
      light: main.errorLight,
      dark: main.errorDark,
    },
    common: {
      grey: {
        main: main.commonGrey,
        light: main.commonLightGrey,
        contrast: main.commonContrastGrey,
        text: main.commonGreyText,
      },
      masala: main.masala,
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          textTransform: 'capitalize',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: main.secondaryDark,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          padding: '8px 7px 5px',
        },
        containedPrimary: {
          color: main.white,
        },
        outlinedPrimary: {
          color: main.black,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled:before': {
            borderBottomStyle: 'solid !important',
          },
        },
        formControl: {
          fontFamily: main.primaryFontFamily,
          fontSize: '1.4rem',
          lineHeight: '1.8rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          fontFamily: main.primaryFontFamily,
          fontSize: '1.4rem',
          lineHeight: '1.8rem',
          color: main.greyTitle,
        },
        shrink: {
          textTransform: 'uppercase',
          fontFamily: main.primaryFontFamily,
          fontSize: '1.2rem',
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
});

export const responsiveTheme = responsiveFontSizes(theme);
