import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  // Checkbox,
  CircularProgress,
  Container,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
// import DownloadIcon from '@mui/icons-material/Download';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { fetchSioUser } from './sioSlice';
import { fetchProgramListThunk } from '../program/ProgramSlice';
import { getGridRequest } from '../../shared/utils';
import { fetchCompletionListThunk } from '../completion/CompletionSlice';
import { fetchFundingListThunk } from '../funding/fundingSlice';
import { StatusPill } from './sio-home/StatusPill';
import FilterDialog from './sio-home/FilterDialog';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

// type CheckedItems = {
//   [id: string]: boolean;
// };

const SioHome = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const id = useAppSelector((state) => state.app.account?.sioPartnerId);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  // const [checkedItems, setCheckedItems] = useState<CheckedItems>({});
  // const [isAllChecked, setIsAllChecked] = useState(false);

  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [dateOrder, setDateOrder] = useState<'asc' | 'desc'>('desc');
  const [statusFilter, setStatusFilter] = useState<string | null>(null);

  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const programState = useAppSelector((state) => state.programs);
  const programs = useAppSelector((state) => state.programs.grid.data.results);
  const { grid: programGrid, status: programStatus } = programState;

  const fundingState = useAppSelector((state) => state.fundings);
  const funding = useAppSelector((state) => state.fundings.grid.data.results);
  const { grid: fundingGrid, status: sioStatus } = fundingState;

  const completionstate = useAppSelector((state) => state.completion);
  const { grid: completionGrid, status: completionStatus } = completionstate;
  const completions = useAppSelector((state) => state.completion.grid.data.results);

  const [isIframeExpanded, setIsIframeExpanded] = useState(true);

  const toggleIframeSize = () => {
    setIsIframeExpanded(!isIframeExpanded);
  };

  const allItems = useMemo(
    () => [...programs, ...completions, ...funding],
    [programs, completions, funding],
  );

  const filteredSortedItems = useMemo(() => {
    const filtered =
      statusFilter === null ? allItems : allItems.filter((item) => item.status === statusFilter);

    const sorted = [...filtered].sort((a, b) => {
      const dateA = new Date(a.lastUpdated);
      const dateB = new Date(b.lastUpdated);
      return dateOrder === 'asc'
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime();
    });

    return sorted;
  }, [allItems, dateOrder, statusFilter]);

  const currentItems = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredSortedItems.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, filteredSortedItems, itemsPerPage]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (id) {
        setLoadingUser(true);
        try {
          await dispatch(fetchSioUser({ id })).unwrap();
        } catch (error) {
          console.error('Failed to fetch user data:', error);
        } finally {
          setLoadingUser(false);
        }
      }
    };

    fetchUserData();
  }, [id, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (!id || loadingUser) return;

      setLoadingData(true);
      try {
        await Promise.all([
          dispatch(
            fetchProgramListThunk({
              status: programStatus,
              ...getGridRequest(programGrid),
            }),
          ).unwrap(),
          dispatch(
            fetchCompletionListThunk({
              status: completionStatus,
              ...getGridRequest(completionGrid),
            }),
          ).unwrap(),
          dispatch(
            fetchFundingListThunk({ status: sioStatus, ...getGridRequest(fundingGrid) }),
          ).unwrap(),
        ]);
      } catch (error) {
        console.error('Failed to fetch related data:', error);
      } finally {
        setLoadingData(false);
      }
    };

    fetchData();
  }, [id, loadingUser, dispatch, programStatus, sioStatus]);

  // useEffect(() => {
  //   const areAllChecked =
  //     currentItems.length > 0 && currentItems.every((item) => checkedItems[item.id]);
  //   setIsAllChecked(areAllChecked);
  // }, [currentItems, checkedItems]);

  const formatDate = (item: any) => {
    const date = new Date(item);

    const friendlyDate = date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    const friendlyTime = date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit',
    });

    return `${friendlyDate} at ${friendlyTime}`;
  };

  const getDisplayName = (item: any): string => {
    if ('completionFormName' in item) {
      return item.completionFormName;
    }
    if ('fundingFormName' in item) {
      return item.fundingFormName;
    }
    if ('programName' in item && item.programName) {
      return item.programName;
    }
    return 'Click here to edit program';
  };

  const navigateToForm = (item: any) => {
    if ('completionFormName' in item) {
      return navigate(`/app/sio/completions/${item.id}`);
    }
    if ('fundingFormName' in item) {
      return navigate(`/app/sio/funding/${item.id}`);
    }
    return navigate(`/app/sio/programs/${item.id}`);
  };

  // const handleCheckChange = (boxId: string, isChecked: boolean) => {
  //   const newCheckedItems = { ...checkedItems, [boxId]: isChecked };
  //   setCheckedItems(newCheckedItems);

  //   setIsAllChecked(currentItems.every((item) => newCheckedItems[item.id]));
  // };

  // const handleCheckAllChange = (isChecked: boolean) => {
  //   setIsAllChecked(isChecked);

  //   const newCheckedItems: CheckedItems = {};
  //   currentItems.forEach((item) => {
  //     newCheckedItems[item.id] = isChecked;
  //   });
  //   setCheckedItems(newCheckedItems);
  // };

  const handleFilterApply = (newDateOrder: 'asc' | 'desc', newStatusFilter: string | null) => {
    setDateOrder(newDateOrder);
    setStatusFilter(newStatusFilter);
    setCurrentPage(1);
    // setCheckedItems({});
  };

  const resetFilters = () => {
    setDateOrder('asc');
    setStatusFilter(null);
    setCurrentPage(1);
  };

  if (loadingUser || loadingData) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container sx={{ backgroundColor: 'white', paddingBottom: '20px' }}>
      &nbsp;
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px 0px',
        }}
      >
        <Typography
          flex={1}
          variant="h5"
          sx={{
            flex: 1,
            color: colors.textPrimary,
            fontSize: fonts.fontSize24,
            fontWeight: 700,
          }}
        >
          Social Impact Organization Overview
        </Typography>
        <Typography
          flex={1}
          variant="body1"
          sx={{
            flex: 1,
            color: '#BCBEBF',
            fontSize: '12pt',
            fontWeight: 700,
          }}
        >
          Application form status
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: '#F5F8FA',
          borderRadius: '20px',
          alignItems: 'baseline',
        }}
      >
        <Box pt={2} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {/* <Checkbox
            sx={{ paddingLeft: '40px' }}
            checked={isAllChecked}
            onChange={(e) => handleCheckAllChange(e.target.checked)}
          />
          <Typography>Select All</Typography> */}
        </Box>
        <Box
          pt={2}
          sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, paddingRight: '10px' }}
        >
          <Button
            startIcon={<FilterListIcon />}
            variant="outlined"
            sx={{ mr: 1, borderColor: '#BCBEBF' }}
            onClick={() => setIsFilterDialogOpen(true)}
          >
            Filter
          </Button>
          {/* <Button
            startIcon={<DownloadIcon />}
            variant="outlined"
            sx={{ mr: 1, borderColor: '#BCBEBF' }}
          >
            Export
          </Button> */}
        </Box>
        <FilterDialog
          isOpen={isFilterDialogOpen}
          onClose={() => setIsFilterDialogOpen(false)}
          onApply={handleFilterApply}
          onReset={resetFilters}
          dateOrder={dateOrder}
          setDateOrder={setDateOrder}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
        />
      </Box>
      &nbsp;
      <Table>
        <TableBody>
          {currentItems.map((item, index) => (
            <React.Fragment key={item.id || index}>
              <Box
                component="tr"
                sx={{
                  '&:not(:last-child)': { mb: '10px' },
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  border: '1px solid #BCBEBF',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 16px',
                  }}
                >
                  <TableCell
                    component="td"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 16px',
                      border: 'none',
                    }}
                  >
                    {/* <Checkbox
                      checked={!!checkedItems[item.id]}
                      onChange={(e) => handleCheckChange(item.id, e.target.checked)}
                    /> */}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      color: '#249ACB',
                      textDecoration: 'underline',
                      fontSize: '12pt',
                      cursor: 'pointer',
                      border: '1px black',
                    }}
                    onClick={() => navigateToForm(item)}
                  >
                    {getDisplayName(item)}
                  </TableCell>
                </Box>
                <TableCell
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    borderRadius: '8px',
                    border: 'none',
                  }}
                >
                  <StatusPill
                    text={item.status as 'approved' | 'in_progress' | 'submitted' | 'denied'}
                  />
                  <Typography sx={{ paddingLeft: '20px', whiteSpace: 'nowrap' }}>
                    {formatDate(item.lastUpdated)}
                  </Typography>
                </TableCell>
              </Box>

              <TableRow sx={{ height: '16px' }} />
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(filteredSortedItems.length / itemsPerPage)}
        page={currentPage}
        onChange={(event, page) => setCurrentPage(page)}
        sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
      />
      <Box
        sx={{
          position: 'fixed',
          right: 16,
          bottom: 16,
          zIndex: 1000,
          width: isIframeExpanded ? '450px' : '0px',
          height: isIframeExpanded ? '500px' : '40px',
          transition: 'all 0.3s ease-in-out',
        }}
      >
        <iframe
          title="Embedded Writesonic Widget"
          style={{
            height: '100%',
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: '8px',
          }}
          src="https://widget.writesonic.com/CDN/index.html?service-base-url=https://api-azure.botsonic.ai&token=1632afad-a303-4b66-a0c9-cbdcb0e198de&base-origin=https://bot.writesonic.com&instance-name=Botsonic&standalone=true&page-url=https://bot.writesonic.com/67d2aad1-b2f9-4dc2-b821-e048795667b6?t=connect&workspace_id=1acc67f7-462b-42bf-bf88-633c257175a3"
        />
        <Button
          onClick={toggleIframeSize}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            minWidth: '40px',
            height: '20px',
            backgroundColor: 'rgba(255,255,255,0.9)',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,1)',
            },
          }}
        >
          {isIframeExpanded ? '-' : '+'}
        </Button>
      </Box>
    </Container>
  );
};

export default SioHome;
