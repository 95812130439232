import { Button, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { clearDialog } from '../../../features';
import { Dialogs } from '../../enums';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { emailValid, isNullOrWhitespace } from '../../utils';
import { FormDialog } from '../FormDialog';
import { FlexBox } from '../StyledComponents';
import {
  clearContactUsState,
  setContactUsName,
  setContactUsEmail,
  setContactUsMessage,
  postContactUsThunk,
  setContactUsUser,
} from './contactUsSlice';

export function ContactUs() {
  const state = useAppSelector((x) => x.contactUs);
  const userClaims = useAppSelector((x) => x.app.userClaims);
  const dispatch = useAppDispatch();

  const { email, name, message, loading, success } = state;

  const accountId = useAppSelector((x) => x.app.account?.id);

  useEffect(() => {
    if (!userClaims) return;

    dispatch(setContactUsUser(userClaims));
  }, [userClaims, dispatch]);

  useEffect(
    () => () => {
      dispatch(clearContactUsState());
    },
    [dispatch],
  );

  const isValid = () =>
    !isNullOrWhitespace(name) && emailValid(email) && !isNullOrWhitespace(message);

  return (
    <FormDialog
      title={!success ? 'Contact Us' : 'Thank you for contacting us we’ve received your details'}
      type={Dialogs.ContactUs}
      subtitle={
        !success && !accountId
          ? "Send us a message and we'll get back to you as soon as possible."
          : undefined
      }
      subtitleBelow
      hideCancel={success}
      Buttons={
        !success ? (
          <Button
            variant="contained"
            size="large"
            onClick={() => dispatch(postContactUsThunk())}
            disabled={!isValid()}
          >
            Send
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            onClick={() => dispatch(clearDialog(Dialogs.ContactUs))}
          >
            Ok, thanks
          </Button>
        )
      }
      loading={loading}
    >
      {!success ? (
        <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
          <TextField
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => {
              dispatch(setContactUsName(e.target.value));
            }}
            required
          />
          <TextField
            label="Email"
            fullWidth
            value={email}
            onChange={(e) => {
              dispatch(setContactUsEmail(e.target.value));
            }}
            required
          />
          <TextField
            label="Message"
            fullWidth
            value={message}
            multiline
            rows={4}
            onChange={(e) => {
              dispatch(setContactUsMessage(e.target.value));
            }}
            required
          />
          <Typography variant="h6" color="#878787" mt={1}>
            ScaleWith Support
          </Typography>
        </FlexBox>
      ) : (
        <Typography variant="h6" mt={1}>
          We&apos;ll get back to you shortly
        </Typography>
      )}
    </FormDialog>
  );
}
