export enum ContributionStatus {
  Draft = 'draft',
  DraftLabel = 'Saved as Draft',
  PendingApproval = 'pending approval',
  Approved = 'approved',
  Denied = 'denied',
  FinancialHold = 'financial hold',
  PendingPayment = 'pending payment',
  Completed = 'completed',
  Abandoned = 'abandoned',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
  Deleted = 'deleted',
  MarkAsPaid="mark as paid",
  ProcessingPayment="processing payment"
}

export enum ContributionStatusLabel {
  Approve = 'Approve',
  Deny = 'Deny',
  Complete = 'Complete',
  Abandon = 'Abandon',
  Fulfill = 'Fulfill',
  Reject = 'Reject',
  Delete = 'Delete',
  share = 'Share',
  MarkAsPaid="Mark As Paid",
  ProcessingPayment="Processing Payment"
}

export enum ContributionActions {
  Draft = 'draft',
  PendingApproval = 'pending approval',
  Approved = 'approved',
  Denied = 'denied',
  FinancialHold = 'financial hold',
  PendingPayment = 'pending payment',
  Completed = 'completed',
  Abandoned = 'abandoned',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
  Deleted = 'deleted',
  shared = 'Shared',
  MarkAsPaid="mark as paid"
}
