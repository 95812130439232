import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCancelTokenEvent, getProgram, getPrograms, patchProgramStatus } from '../../api';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';
import { Program, GridResponse, GridModel, GridRequest } from '../../shared/interfaces';

interface programsGridRequest extends GridRequest {
  status: string | null;
}

export interface programsState {
  grid: GridModel<Program>;
  program: Program | null;
  updateStatusSuccess: boolean;
  status: string | null;
  loading: boolean;
}

const initialState: programsState = {
  grid: {
    name: 'programs',
    data: { totalCount: 0, results: [] },
    page: 0,
    defaultSort: { field: 'name', direction: 'asc' },
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
  },
  program: null,
  updateStatusSuccess: false,
  status: null,
  loading: false,
};

export const getprogramsThunk = createAsyncThunk(
  'programs/getprogramsThunk',
  async (request: programsGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getPrograms(request);
    return data;
  },
);
export const getProgramThunk = createAsyncThunk(
  'programs/getProgramsThunk',
  async (params: { id: string; fundingAmount: number }, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getProgram(params.id, params.fundingAmount);
    return data;
  },
);
export const patchprogramAdminThunk = createAsyncThunk(
  'programs/patchprogramAdminThunk',
  async (request: { programId: string; status: string }, { signal }) => {
    addCancelTokenEvent(signal);
    const { programId, status } = request;
    const { data } = await patchProgramStatus(programId, status);
    return data;
  },
);

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    setprogramsGridModel: (state, action: PayloadAction<Partial<GridModel<Program>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearprogramsState: (state) => {
      state.grid = initialState.grid;
      state.updateStatusSuccess = false;
    },
    clearprogramsUpdateSuccess: (state) => {
      state.updateStatusSuccess = false;
    },
    setprogramsStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getprogramsThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<Program>>) => {
          state.grid.loadingGrid = false;
          state.grid.data = action.payload;
        },
      )
      .addCase(getprogramsThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getprogramsThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(patchprogramAdminThunk.fulfilled, (state, action: PayloadAction<Program>) => {
        state.updateStatusSuccess = true;
        state.loading = false;
        const index = state.grid.data.results.findIndex((a) => a.id === action.payload.id);
        if (index === -1) return;
        state.grid.data.results[index] = action.payload;
      })
      .addCase(patchprogramAdminThunk.pending, (state) => {
        state.updateStatusSuccess = false;
        if (!state.loading) state.loading = true;
      })
      .addCase(getProgramThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(getProgramThunk.fulfilled, (state, action: PayloadAction<Program>) => {
        state.loading = false;
        state.program = action.payload;
      })
      .addCase(getProgramThunk.rejected, (state) => {
        state.loading = false;
        state.program = null;
      });
  },
});

export const {
  setprogramsGridModel,
  clearprogramsState,
  setprogramsStatus,
  clearprogramsUpdateSuccess,
} = programsSlice.actions;

export const programsReducer = programsSlice.reducer;
