import { Box } from '@mui/material';
import React, { FC } from 'react';
import Logo from '../../shared/components/Logo';
import { RegisterFooter } from './Footer';
import { Hero } from './Hero';

interface Props {
  hideLogo?: boolean;
  coloredLogo?: boolean;
  LeftComponent?: JSX.Element | null;
}

export const CommonBody: FC<Props> = (props) => {
  const { children, hideLogo, coloredLogo, LeftComponent } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
      }}
    >
      <Hero hideLogo={hideLogo}>{LeftComponent}</Hero>
      <Box
        sx={(theme) => ({
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          padding: '0 30px 0',
          boxSizing: 'border-box',
          color: theme.palette.grey[500],
          [theme.breakpoints.up('xl')]: { padding: '0 150px 0', width: '66.67%' },
          [theme.breakpoints.up('lg')]: { padding: '0 70px 0', width: '66.67%' },
          [theme.breakpoints.up('md')]: { padding: '0 50px 0', width: '66.67%' },
        })}
      >
        <Box sx={{ display: 'center', marginTop: '3rem', height: '11%' }}>
          <Logo coloredLogo={coloredLogo} />
        </Box>
        {children}
        <RegisterFooter />
      </Box>
    </Box>
  );
};
