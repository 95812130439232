export enum GridColumns {
  String = 'string',
  Number = 'number',
  Date = 'date',
  DateTime = 'dateTime',
  Boolean = 'boolean',
  Actions = 'actions',
  SingleSelect = 'singleSelect',
  Money = 'money',
  MoneyWithLocaleSymbol = 'moneyWithLocaleSymbol',
}
