import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Button,
  TextField,
  Autocomplete,
  IconButton,
  createTheme,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/AccessTime';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassBottom from '@mui/icons-material/HourglassBottom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Close } from '@mui/icons-material';
import { ButtonBack, ButtonNext, CarouselProvider, Slider } from 'pure-react-carousel';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { ReactComponent as UnionIcon } from '../../assets/svg/UnionIcon.svg';
import filterListIcon from '../../assets/images/filter_list_icon.svg';
import { GridColumns, SioStatus } from '../../shared/enums';
import { getGenericHeight, getGridRequest } from '../../shared/utils';
import { CustomGridColDef } from '../../shared/interfaces';
import { SioStatusCell } from '../../shared/components/SiosStatusCell';
import { CommonGridFilterHandle, FlexBox, IsolatedGrid } from '../../shared/components';
import {
  fetchSios,
  clearSiosState,
  clearSiosUpdateSuccess,
  setSiosGridModel,
  setSiosStatus,
  fetchSioStatus,
} from './sioSlice';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { STARTS_WITH_OPERATOR } from '../../shared/constants';
// import './Sio.css';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';
import borders from '../../assets/scss/strokes.module.scss';

interface TabInterface {
  label: string;
  value: SioStatus | null;
  count: number;
}


const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.primary,
          height: '4px',
        },
      },
    },
  },
});



const SIOListPage = () => {
  const sioState = useAppSelector((state) => state.sios);
  const dispatch = useAppDispatch();
  const { grid, updateStatusSuccess, status: sioStatus } = sioState;
  const { sort, filter, page, pageSize } = grid;
  const { totalCount } = grid.data;
  const [searchQuery, setSearchQuery] = useState<any>({
    publicOrganizationName: '',
    partnership: '',
    applicationFormName: '',
  });
  const [filteredItems, setFilteredItems] = useState(grid.data.results);
  const filterRef = useRef<CommonGridFilterHandle>(null);
  const navigate = useNavigate();
  const [selectedColumn, setSelectedColumn] = useState<CustomGridColDef | null | undefined>(null);
  const filteredColumns: Array<CustomGridColDef> = [
    {
      field: 'publicOrganizationName',
      headerName: 'Public Organization Name',
      description: 'Public Organization Name',
      type: 'text',
      flex: 0.5,
      filterable: true,
      filterOperators: STARTS_WITH_OPERATOR,
      hideable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'applicationFormName',
      headerName: 'Application Name',
      description: 'Application Name',
      type: 'text',
      flex: 0.5,
      filterable: true,
      filterOperators: STARTS_WITH_OPERATOR,
      hideable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'partnership',
      headerName: 'Partnership Manager',
      description: 'Partnership Manager',
      type: 'text',
      flex: 0.5,
      filterable: true,
      filterOperators: STARTS_WITH_OPERATOR,
      hideable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  const [tabs, setTabs] = useState<TabInterface[]>([
    { label: 'All', value: null, count: 0 },
    { label: 'In Progress', value: SioStatus.InProgress, count: 0 },
    { label: 'Pending Approval', value: SioStatus.PendingApproval, count: 0 },
    { label: 'Approved', value: SioStatus.Approved, count: 0 },
    { label: 'Archived', value: SioStatus.Archived, count: 0 },
  ]);

  const isMediumDevice = useMediaQuery(`(min-width:768px) and (max-width:1023px`);
  const isLg = useMediaQuery(`(min-width: 1024px) and (max-width:1279px`);
  const isXL = useMediaQuery(`(min-width: 1280px) and (max-width:1535px`);
  const isXXL = useMediaQuery(`(min-width: 1536px)`);

  let slides: any = 1;
  if (isMediumDevice) {
    slides = 3;
  } else if (isLg) {
    slides = 2;
  } else if (isXL || isXXL) {
    slides = 3;
  }

  useEffect(() => {
    dispatch(fetchSioStatus());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchSioStatus()).then((siodata: any) => {
      const totalStatusCount = siodata.payload.status_list.reduce(
        (total: number, status: any) => total + status.count,
        0,
      );
      const updatedTabs = tabs.map((tab) => {
        if (tab.label === 'All') {
          return {
            ...tab,
            count: totalStatusCount,
          };
        }
        /* eslint-disable no-underscore-dangle */
        const status = siodata?.payload?.status_list?.find((s: any) => s._id === tab.value);
        return {
          ...tab,
          count: status ? status.count : 0,
        };
      });

      setTabs(updatedTabs);
    });
  }, []);

  useEffect(() => {
    dispatch(setSiosGridModel({ data: { results: filteredItems, totalCount } }));
  }, [dispatch]);

  useEffect(() => {
    setFilteredItems(grid.data.results);
  }, [grid]);

  useEffect(() => {
    if (selectedColumn?.field) {
      const field: any = selectedColumn?.field;
      const fieldValue: any = searchQuery[field];
      dispatch(fetchSios({ status: sioStatus, ...getGridRequest(grid), [field]: fieldValue }));
    } else {
      dispatch(fetchSios({ status: sioStatus, ...getGridRequest(grid) }));
    }
  }, [sort, filter, page, sioStatus, pageSize, searchQuery]);

  useEffect(
    () => () => {
      dispatch(clearSiosState());
    },
    [dispatch],
  );
  useEffect(() => {
    dispatch(clearSiosUpdateSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatusSuccess]);

  const clearInputControllers = () => {
    if (!_.isNil(searchQuery))
      setSearchQuery({ publicOrganizationName: '', partnership: '', applicationFormName: '' });
  };

  const clearFilterController = () => {
    setSelectedColumn(null);
    clearInputControllers();
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearchQuery({ publicOrganizationName: '', partnership: '', applicationFormName: '' });
    dispatch(setSiosStatus(newValue));
    filterRef.current?.clearFilterSelections();
    clearFilterController();
  };
  const handleSearchChange = (event: any, key: any) => {
    const query = event.target.value;
    setSearchQuery((prevState: any) => ({
      ...prevState,
      [key]: query,
    }));
  };
  const getStatusDisplayValue = (status: string): string => {
    switch (status) {
      case 'approved':
        return 'Approved';
      case 'submitted':
        return 'Submitted';
      case 'in_progress':
        return 'In Progress';
      default:
        return status;
    }
  };

  const columns: Array<CustomGridColDef> = [
    {
      field: 'srno',
      headerName: 'Sr No',
      type: 'number',
      flex: 0.5,
      align: 'left',
      renderCell: (params) => {
        const { api } = params;
        const rowIndex = api.getRowIndex(params.id);
        return rowIndex + 1;
      },
      headerAlign: 'left',
    },
    {
      field: 'applicationFormName',
      headerName: 'Application Name',

      flex: 1,
    },
    {
      field: 'publicOrganizationName',
      headerName: 'Public Organization Name',
      renderCell: (params) => <span>{params.row.publicOrganizationName}</span>,
      flex: 1,
    },
    {
      field: 'siAdminName',
      headerName: 'Partnership Manager',
      renderCell: (params) => (
        <span>
          {params.row.siAdmin_firstname} {params.row.siAdmin_lastname}
        </span>
      ),
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated On',
      flex: 1,
      type: GridColumns.Date,
      renderCell: (params) => (
        <Box>
          {new Date(params.row.lastUpdated).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      type: GridColumns.SingleSelect,
      flex: 1,
      minWidth: 250,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div
          style={{
            ...(params.row.status === SioStatus.Approved && {
              backgroundColor: colors.statusBackgroundSuccess,
              color: colors.statusSuccess,
              border : `1px solid ${colors.statusSuccess}`,
            }),
            ...(params.row.status === SioStatus.Submitted && {
              backgroundColor : colors.statusBackgroundWarning,
              border : `1px solid ${colors.statusWarning}`,
              color : colors.statusWarning,
            }),
            ...(params.row.status === SioStatus.InProgress && {
              backgroundColor : colors.statusBackgroundInfo,
              border : `1px solid ${colors.statusInfo}`,
              fontWeight : fonts.fontWeight400,
              color : colors.statusInfo,
            }),
            ...(params.row.status === SioStatus.Archived && {
              backgroundColor: '#DFE2E6',
              color: '#000000',
              border : `1px solid #333333`,
              fontWeight : fonts.fontWeight400,
            }),
            borderRadius: '25px',
            display: 'flex',
            alignItems: 'center',
            padding: '2px 0px 2px 15px',
            justifyContent: 'left',
            textTransform: 'capitalize',
            width: 200,
            whiteSpace: 'nowrap',
          }}
        >
          {params.row.status === SioStatus.InProgress && <HourglassBottom  />}
          {params.row.status === SioStatus.Approved && <CheckCircleIcon />}
          {params.row.status === SioStatus.Submitted && <WatchLaterIcon />}
          {params.row.status === SioStatus.Archived && <UnionIcon style={{ marginLeft: '7px' }} />}
          <SioStatusCell>
            {params.row.status === SioStatus.InProgress
              ? `${getStatusDisplayValue(params.row.status)} ${params.row.percentComplete}%`
              : getStatusDisplayValue(params.row.status)}
          </SioStatusCell>
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 180,
      renderCell: ({ row: { id } }) => (
        <Button
          variant="outlined"
          // color="info"
          // sx={{ backgroundColor: '#fff' }}
          sx={{
            backgroundColor: colors.secondary,
            fontSize: fonts.fontSize14,
            fontWeight: fonts.fontWeight700,
            color: colors.primary,
            borderRadius: borders.borderRadius8,
            borderColor: colors.primary,
            '&:focus': colors.primary,
            '&:hover': colors.primary,
          }}
          onClick={() => navigate(`/app/sio/${id}`)}
          endIcon={<ArrowForwardIcon />}
        >
          <span style={{ marginLeft: '0.5rem' }}>View Details</span>
          {/* <ArrowForwardIcon /> */}
        </Button>
      ),
    },
  ];

  const getColumnValue = (field: any) => searchQuery[field];

  return (
    <Box className="dashboard" sx={{ pt: '12px', pb: '0px' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: '2rem',
        }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <Box sx={{ marginBottom: '1.5rem' }}>
            <FlexBox sx={{ gap: 3 }}>
              <Typography
                sx={{ flex: 1, color:colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 700 }}
              >
                Social Impact Organization Application
              </Typography>
              <Button
                variant="outlined"
                onClick={() => navigate('create-sio')}
                sx={{
                  height: '48px',
                  marginLeft: '30px',
                  paddingX: '16px',
                  fontWeight: fonts.fontWeight700,
                  fontSize: fonts.fontSize14,
                  backgroundColor: `${colors.primary} !important`,
                  color: `${colors.secondary} !important`,
                  borderRadius: '8px !important',
                  '&.Mui-disabled': {
                    backgroundColor: `${colors.strokeDefault} !important`,
                  },
                }}
              >
                Add New SIO
              </Button>
            </FlexBox>
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              gap: '1rem',
              marginLeft: '13px',
              borderBottom: '1px solid #e9ecef',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <CarouselProvider
                  naturalSlideHeight={25}
                  naturalSlideWidth={100}
                  totalSlides={tabs?.length}
                  visibleSlides={slides}
                  className="tabs-carousel"
                  step={slides - 1}
                >
                  <Slider>
              <Box>
                <Box my={1}>
                <ThemeProvider theme={theme}>
                    <Tabs
                      value={sioStatus}
                      onChange={handleTabChange}
                      sx={{ display: 'flex', gap: '6rem' }}
                    >
                      {tabs.map((tab) => (
                        <Tab
                          key={`common-order-tab-${tab.value}`}
                          value={tab.value}
                          label={`${tab.label}(${tab.count})`}
                          sx={{
                            textTransform: 'capitalize',
                            fontSize: fonts.fontSize18,
                            fontWeight: `${
                              tab.value === sioStatus
                                ? fonts.fontWeight700
                                : fonts.fontWeight600
                            }`,
                            color: colors.textPrimary,
                          }}
                        />
                      ))}
                    </Tabs>
                  </ThemeProvider>
                </Box>
              </Box>
              </Slider>
                  <ButtonBack className="tab-carousel-btn-back">
                    <KeyboardArrowLeftOutlinedIcon />
                  </ButtonBack>
                  <ButtonNext className="tab-carousel-btn-next">
                    <KeyboardArrowRightOutlinedIcon />
                  </ButtonNext>
                </CarouselProvider>
              <FlexBox
                sx={{
                  gap: 0.5,
                  alignItems: 'end',
                  zIndex: 9,
                }}
              >
                <img
                  src={filterListIcon}
                  alt="filterListIcon"
                  style={{width:"24px",height:"24px", color:"#4F4F4F" ,marginBottom:"10px",marginRight:"5px"}}
                />

                <Autocomplete
                  disablePortal
                  id="combo-box-columns"
                  sx={{ width: '250px' }}
                  getOptionLabel={(option) => option.headerName ?? ''}
                  options={filteredColumns.filter((x) => x.filterable)}
                  value={selectedColumn}
                  onChange={(event: any, newValue: CustomGridColDef | null) => {
                    clearInputControllers();
                    setSelectedColumn(newValue);
                  }}
                  isOptionEqualToValue={(option, value) => option.field === value.field}
                  renderInput={(params) => <TextField {...params} label="Filter Column" 
                  sx={{
                    "& .MuiFormLabel-root": {
               color:colors.textPrimary,
               fontSize: fonts.fontSize20,
               fontWeight: 600
             }          }}/>}
                />
                {selectedColumn && (
                  <TextField
                    label="Starts With"
                    disabled={!selectedColumn}
                    value={getColumnValue(selectedColumn.field)}
                    onChange={(event) => handleSearchChange(event, selectedColumn.field)}
                  />
                )}
                {selectedColumn && searchQuery && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      clearFilterController();
                    }}
                  >
                    <Close />
                  </IconButton>
                )}
              </FlexBox>
            </Box>
          </Box>
          <Box sx={{ minHeight: '400px', height: getGenericHeight(300), border: 'none' }}>
            <IsolatedGrid
              status={sioStatus}
              model={grid}
              columns={columns}
              onChange={(x) => dispatch(setSiosGridModel(x))}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SIOListPage;
