import React, { FC } from 'react';
import { Box } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';


interface Props {
  categories: Array<string>;
  series: Array<number>;
  formatter: (val: number | string) => string;
}

export const ProcurementMetricsSupplierChart: FC<Props> = (props) => {
  const { categories, series, formatter } = props;

  const seriesChart = [
    {
      data: [...series],
    },
  ];

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        distributed: true,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
      labels: {
        formatter,
        rotate: 0,
        hideOverlappingLabels: false,
        trim: true,
      },
      tickPlacement: 'between',
      tickAmount: 1
    },
    yaxis: {
      labels: {
        maxWidth: 100,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        title: {
          formatter: () => '',
        },
        formatter,
      },
    },
    colors: ['#80D7F7'],
  };

  return (
    <Box sx={{ width: '90%' }}>
      <Chart options={options} series={seriesChart} type="bar" />
    </Box>
  );
};
