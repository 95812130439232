import React, { ChangeEvent, useEffect, useState } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Checkbox,
  Button,
  Box,
  TextField,
  Divider,
  Card,
  CardContent,
  Typography,
  CardHeader,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import '../sio/formFilling.scss';
import { fetchProgrambyUser, updateProgrambyUserThunk } from './ProgramSlice';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { getVocab, uploadFile } from '../../api/portal/programRequest';
import { Vocabulary } from '../../shared/interfaces/program/program';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#68839A',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#FFF',
  },
}));

const lifetimeOutputForm = [{ quantity: 0, description: '' }];

// const outputForm = [{ quantity: 0, description: '' }, { quantity: 0, description: '' }, { quantity: 0, description: '' }]

const ProgramResearchQuestions = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const programState = useAppSelector((state) => state.programs);
  const { program } = programState;
  const initialFormValue = {
    researchFile: program?.researchAndEvaluation?.researchFile,
    evidenceDescription: program?.researchAndEvaluation?.evidenceDescription?.selected ?? [],
    evidenceOptional: program?.researchAndEvaluation?.evidenceDescription?.optional ?? [],
    studyDescription: program?.researchAndEvaluation?.studyDescription?.selected ?? [],
    studyOptional: program?.researchAndEvaluation?.studyDescription?.optional ?? [],
    dataDescription: program?.researchAndEvaluation?.dataDescription?.selected ?? [],
    dataOptional: program?.researchAndEvaluation?.dataDescription?.optional ?? [],
    outcomeDescription: program?.researchAndEvaluation?.outcomeDescription ?? [],
    strength: program?.researchAndEvaluation?.strength,
    environmentalOutputValues: lifetimeOutputForm,
    environmentalOutputs: program?.researchAndEvaluation?.environmentalOutputs?.selected ?? [],
    researchApproach: program?.researchAndEvaluation?.researchApproach ?? [],
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [pageNumber, setPageNumber] = useState(1);
  const [files, setFiles] = useState<File>();
  const [vocab, setVocab] = useState<Vocabulary>();
  const [evidenceOptionalCheck, setEvidenceOptionalCheck] = useState(false);
  const [studyOptionalCheck, setStudyOptionalCheck] = useState(false);
  const [dataOptionalCheck, setDataOptionalCheck] = useState(false);

  useEffect(() => {
    getVocab().then((res) => {
      if (res.status === 200) {
        setVocab(res.data);
      }
    });
    if (id && program) {
      dispatch(fetchProgrambyUser(id)).then((programData: any) => {
        if (programData.payload?.researchAndEvaluation?.evidenceDescription?.optional) {
          setEvidenceOptionalCheck(!evidenceOptionalCheck);
        }
        setFormValues({
          researchFile: programData.payload?.researchAndEvaluation?.researchFile,
          evidenceDescription:
            programData.payload?.researchAndEvaluation?.evidenceDescription?.selected ?? [],
          evidenceOptional:
            programData.payload?.researchAndEvaluation?.evidenceDescription?.optional ?? '',
          studyDescription:
            programData.payload?.researchAndEvaluation?.studyDescription?.selected ?? [],
          studyOptional:
            programData.payload?.researchAndEvaluation?.studyDescription?.optional ?? '',
          dataDescription:
            programData.payload?.researchAndEvaluation?.dataDescription?.selected ?? [],
          dataOptional: programData.payload?.researchAndEvaluation?.dataDescription?.optional ?? '',
          outcomeDescription: programData.payload?.researchAndEvaluation?.outcomeDescription ?? [],
          strength: programData.payload?.researchAndEvaluation?.strength,
          environmentalOutputValues:
            programData.payload?.researchAndEvaluation?.environmentalOutputValues ??
            lifetimeOutputForm,
          researchApproach: programData.payload?.researchAndEvaluation?.researchApproach ?? [],
          environmentalOutputs:
            programData.payload?.researchAndEvaluation?.environmentalOutputs?.selected ?? [],
        });
        // savePageNumber()
      });
    }
  }, [id]);

  const updateProgramDetails = (fields: any) => {
    const { field, value } = fields;
    const payload = {
      researchAndEvaluation: {
        [field]: value,
      },
    };
    const programPayload = payload;
    if (id) {
      return dispatch(updateProgrambyUserThunk({ program: programPayload, id }));
    }
    return true;
  };

  const getfield = () => {
    let field = '';
    let value;
    const newResearch = formValues.researchApproach?.map((item) => {
      if (typeof item === 'string') {
        return { _id: item };
      }
      return item;
    });
    switch (pageNumber) {
      case 1:
        field = 'researchFile';
        value = files;
        break;
      case 2:
        field = 'evidenceDescription';
        value = { selected: formValues.evidenceDescription, optional: formValues.evidenceOptional };
        break;
      case 3:
        field = 'studyDescription';
        value = { selected: formValues.studyDescription, optional: formValues.studyOptional };
        break;
      case 4:
        field = 'dataDescription';
        value = { selected: formValues.dataDescription, optional: formValues.dataOptional };
        break;
      case 5:
        field = 'outcomeDescription';
        value =
          typeof formValues.outcomeDescription === 'string'
            ? [formValues.outcomeDescription]
            : formValues.outcomeDescription;
        break;
      case 9:
        field = 'strength';
        value = formValues.strength;
        break;
      case 7:
        field = 'environmentalOutputs';
        value = { selected: formValues.environmentalOutputs };
        break;
      case 6:
        field = 'researchApproach';
        value = newResearch;
        break;
      case 8:
        field = 'environmentalOutputValues';
        value = formValues.environmentalOutputValues;
        break;
      default:
        break;
    }
    return { field, value };
  };

  const handlePreviousbutton = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const uploadFileData = async () => {
    const formData = new FormData();
    if (!files) return;
    formData.append('file', files);
    formData.append('path', 'researchAndEvaluation.researchFile');
    if (id) {
      const response = await uploadFile(id, formData);
      if (response.data) {
        setFormValues({
          ...formValues,
          researchFile: response.data.researchAndEvaluation.researchFile.file.name,
        });
        toast.success('Upload Successfull');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const handleLifetimeOutputChange = (
    field: string,
    index: number,
    value: any,
    lifetimeOutput: any,
  ) => {
    const newLifetimeOutputs = [...formValues.environmentalOutputValues!];
    const label: any = vocab?.sccConversion?.filter((item: any) => item?._id === lifetimeOutput);
    newLifetimeOutputs[index] = {
      ...newLifetimeOutputs[index],
      [field]: value,
      description: label && label[0]?.label,
    };
    if (Number(value) >= 0) {
      setFormValues((prevValues) => ({
        ...prevValues,
        environmentalOutputValues: newLifetimeOutputs,
      }));
    }
  };

  const handleNextButton = async () => {
    const response = await updateProgramDetails(getfield());
    if (response) {
      uploadFileData();
      // toast.success('Successfully Submitted Form to ScaleWith');
      if (pageNumber < 9) {
        setPageNumber(pageNumber + 1);
      } else {
        navigate(`/app/sio/programs/${id}`);
      }
    } else {
      toast.error('An error occurred while submitting in. Please try again.');
    }
    return null;
  };

  const isChecked = (value: string) => {
    let returnValue = false;
    formValues.researchApproach.map((item: any) => {
      if (item._id === value) returnValue = true;
      return true;
    });
    return returnValue;
  };

  const handleSaveExit = async () => {
    const response = await updateProgramDetails(getfield());
    if (response) {
      uploadFileData();
      navigate(`/app/sio/programs/${id}`);
    } else {
      toast.error('An error occurred while logging in. Please try again.');
    }
    return null;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'evidenceOptionalValue') {
      setFormValues({
        ...formValues,
        evidenceOptional: value,
      });
    }
    if (name === 'studyOptionalValue') {
      setFormValues({
        ...formValues,
        studyOptional: value,
      });
    }
    if (name === 'dataOptionalValue') {
      setFormValues({
        ...formValues,
        dataOptional: value,
      });
    }
    if (name === 'evidenceDescription') {
      setEvidenceOptionalCheck(false);
      let newvalue = formValues.evidenceDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        evidenceDescription: newvalue,
        evidenceOptional: '',
      });
    }
    if (name === 'studyDescription') {
      setStudyOptionalCheck(false);
      let newvalue = formValues.studyDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        studyDescription: newvalue,
        studyOptional: '',
      });
    }
    if (name === 'dataDescription') {
      setDataOptionalCheck(false);
      let newvalue = formValues.dataDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        dataDescription: newvalue,
        dataOptional: '',
      });
    }
    if (name === 'outcomeDescription') {
      let newvalue = formValues.outcomeDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        outcomeDescription: newvalue,
      });
    }
    if (name === 'researchApproach') {
      let newvalue = formValues.researchApproach;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else if (formValues.researchApproach.length < 3) {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        researchApproach: newvalue,
      });
    }
    if (name === 'environmentalOutputs') {
      let newvalue = formValues.environmentalOutputs;
      let newOutputValues = [...formValues.environmentalOutputValues];
      const label: any = vocab?.sccConversion?.filter((voc: any) => voc?._id === value);
      const index = formValues?.environmentalOutputs?.indexOf(value);
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
        newOutputValues?.splice(index, 1);
      } else {
        newvalue = [...newvalue, value];
        newOutputValues = [...newOutputValues, { quantity: 0, description: label[0]?.label }];
      }
      setFormValues({
        ...formValues,
        environmentalOutputs: newvalue,
        environmentalOutputValues: newOutputValues,
      });
    }
  };

  useEffect(() => {
    if (formValues && formValues?.environmentalOutputs?.length === 0) {
      setFormValues({
        ...formValues,
        environmentalOutputValues: [],
      });
    }
  }, [formValues?.environmentalOutputs]);
  useEffect(() => {
    if (formValues?.evidenceOptional?.length > 0) {
      setEvidenceOptionalCheck(true);
    } else {
      setEvidenceOptionalCheck(false);
    }
  }, [formValues?.evidenceOptional]);
  useEffect(() => {
    if (formValues?.studyOptional?.length > 0) {
      setStudyOptionalCheck(true);
    } else {
      setStudyOptionalCheck(false);
    }
  }, [formValues?.studyOptional]);
  useEffect(() => {
    if (formValues?.dataOptional?.length > 0) {
      setDataOptionalCheck(true);
    } else {
      setDataOptionalCheck(false);
    }
  }, [formValues?.dataOptional]);
  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setFiles(fileList[0]);
  };

  const getFieldValue = (indexNumber: number, field: string) => {
    let value;
    formValues?.environmentalOutputValues?.map((item, index) => {
      if (index === indexNumber) {
        if (field === 'quantity') {
          value = item.quantity;
        }
        if (field === 'description') {
          value = item.description;
        }
      }
      return 1;
    });
    return value;
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  // *** Start Questions ***

  const question1 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 5 }} variant="h3">
          Let&apos;s get started!
        </Typography>
        <Typography sx={commonTextStyle.questionStyle}>Question 1 of 9</Typography>
        <Typography sx={commonTextStyle.heading}>
          Please upload the research or evaluation that informs your program design.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="budgetFile"
          placeholder="Select File"
          fullWidth
          type="file"
          // value={formValues.researchFile.name}
          onChange={handleUpload}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
        {formValues.researchFile && (
          <Grid item xs={12}>
            <Typography sx={commonTextStyle.heading}>
              File Name: {formValues.researchFile.name}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
  const question2 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 2 of 9</Typography>
        <Typography sx={commonTextStyle.heading}>
          How would you describe the evidence or data?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>
          Please note, this information helps us derive additional value your organization is
          creating through the use of methods that have been researched and evaluated. This should
          be program-specific, not at an organizational level.
        </Typography>
      </Grid>

      {
        /* eslint no-underscore-dangle: 0 */
        vocab?.studyEvidenceType.map((item: any) => (
          <Grid key={item._id} item>
            <Box
              className={formValues.evidenceDescription.includes(item._id) ? 'active' : ''}
              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
            >
              <Typography variant="body1" display="inline">
                {item.label}
              </Typography>
              <Checkbox
                name="evidenceDescription"
                checked={formValues.evidenceDescription.includes(item._id)}
                value={item._id}
                onChange={handleChangeSelect}
                style={{
                  color: `${
                    formValues.evidenceDescription.includes(item._id) ? colors.primary : ''
                  }`,
                }}
              />
            </Box>
          </Grid>
        ))
      }
      <Grid style={{ paddingTop: '2em', paddingLeft: '2em' }}>
        <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
          <Typography variant="body1" display="inline">
            Others
          </Typography>
          <Checkbox
            name="evidenceOthers"
            checked={evidenceOptionalCheck}
            onChange={() => {
              setEvidenceOptionalCheck(!evidenceOptionalCheck);
              setFormValues({
                ...formValues,
                evidenceDescription: [],
              });
            }}
            style={{ color: `${evidenceOptionalCheck ? colors.primary : ''}` }}
          />
        </Box>
      </Grid>
      {evidenceOptionalCheck && (
        <Grid style={{ paddingTop: '2em', paddingLeft: '2em' }}>
          <Typography variant="body1" display="inline">
            Please describe others
          </Typography>
          <TextField
            name="evidenceOptionalValue"
            type="text"
            variant="outlined"
            className="inputTextFiled"
            value={formValues.evidenceOptional}
            onChange={handleChangeSelect}
            fullWidth
            sx={commonTextStyle.textFieldStyle}
          />
        </Grid>
      )}
    </Grid>
  );

  const question3 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 3 of 9 </Typography>
        <Typography sx={commonTextStyle.heading}>
          How would you describe the study design?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>Please select all that apply.</Typography>
      </Grid>
      {
        /* eslint no-underscore-dangle: 0 */
        vocab?.studyDesignType.map((item: any) => (
          <Grid key={item._id} item>
            <Box
              className={formValues.studyDescription.includes(item._id) ? 'active' : ''}
              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
            >
              <Typography variant="body1" display="inline">
                {item.label}
              </Typography>
              <Checkbox
                name="studyDescription"
                checked={formValues.studyDescription.includes(item._id)}
                value={item._id}
                onChange={handleChangeSelect}
                style={{
                  color: `${formValues.studyDescription.includes(item._id) ? colors.primary : ''}`,
                }}
              />
            </Box>
          </Grid>
        ))
      }
      <Grid style={{ paddingTop: '2em', paddingLeft: '2em' }}>
        <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
          <Typography variant="body1" display="inline">
            Others
          </Typography>
          <Checkbox
            name="evidenceOthers"
            checked={studyOptionalCheck}
            onChange={() => {
              setStudyOptionalCheck(!studyOptionalCheck);
              setFormValues({
                ...formValues,
                studyDescription: [],
              });
            }}
            style={{ color: `${studyOptionalCheck ? colors.primary : ''}` }}
          />
        </Box>
      </Grid>
      {studyOptionalCheck && (
        <Grid style={{ paddingTop: '2em', paddingLeft: '2em' }}>
          <Typography variant="body1" display="inline">
            Please describe others
          </Typography>
          <TextField
            name="studyOptionalValue"
            type="text"
            variant="outlined"
            className="inputTextFiled"
            value={formValues.studyOptional}
            onChange={handleChangeSelect}
            fullWidth
            sx={commonTextStyle.textFieldStyle}
          />
        </Grid>
      )}
    </Grid>
  );

  const question4 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 4 of 9 </Typography>
        <Typography sx={commonTextStyle.heading}>
          How would you describe the data in the study?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>Please select all that apply.</Typography>
      </Grid>
      {
        /* eslint no-underscore-dangle: 0 */
        vocab?.researchDataType.map((item: any) => (
          <Grid key={item._id} item>
            <Box
              className={formValues.dataDescription.includes(item._id) ? 'active' : ''}
              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
            >
              <Typography variant="body1" display="inline">
                {item.label}
              </Typography>
              <Checkbox
                name="dataDescription"
                checked={formValues.dataDescription.includes(item._id)}
                value={item._id}
                onChange={handleChangeSelect}
                style={{
                  color: `${formValues.dataDescription.includes(item._id) ? colors.primary : ''}`,
                }}
              />
            </Box>
          </Grid>
        ))
      }
      <Grid style={{ paddingTop: '2em', paddingLeft: '2em' }}>
        <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
          <Typography variant="body1" display="inline">
            Others
          </Typography>
          <Checkbox
            name="evidenceOthers"
            checked={dataOptionalCheck}
            onChange={() => {
              setDataOptionalCheck(!dataOptionalCheck);
              setFormValues({
                ...formValues,
                dataDescription: [],
              });
            }}
            style={{ color: `${dataOptionalCheck ? colors.primary : ''}` }}
          />
        </Box>
      </Grid>
      {dataOptionalCheck && (
        <Grid style={{ paddingTop: '2em', paddingLeft: '2em' }}>
          <Typography variant="body1" display="inline">
            Please describe others
          </Typography>
          <TextField
            name="dataOptionalValue"
            type="text"
            variant="outlined"
            className="inputTextFiled"
            value={formValues.dataOptional}
            onChange={handleChangeSelect}
            fullWidth
            sx={commonTextStyle.textFieldStyle}
          />
        </Grid>
      )}
    </Grid>
  );

  const question5 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 5 of 9 </Typography>
        <Typography sx={commonTextStyle.heading}>
          How would you describe the correlation of your program to your outcomes based on the
          study?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            sx={{ display: 'inline-block' }}
            name="outcomeDescription"
            value={formValues.outcomeDescription}
            onChange={handleChange}
          >
            {vocab?.effect.map((item: any) => (
              <FormControlLabel
                sx={{ p: 2, mx: 2, border: '1px solid #E0E0E0', borderRadius: '20px' }}
                labelPlacement="start"
                key={item._id}
                value={item._id}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );

  const question6 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 6 of 9</Typography>
        <Typography sx={commonTextStyle.heading}>
          Which strategies and approaches align with the research or evaluation this program design
          is based on?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>
          Please select at least one, and up to three.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {vocab?.causes.map((item: any) => (
          <Grid key={item._id} sx={{ my: 2 }} item xs={12}>
            <Box
              className={formValues.evidenceDescription.includes(item._id) ? 'active' : ''}
              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
            >
              <Typography sx={{ ...commonTextStyle.heading }}>{item.label}</Typography>
              <br />
              {vocab?.approach.map((subItem: any) => {
                if (subItem.causeId === item._id) {
                  return (
                    <Box
                      key={subItem._id}
                      className={
                        isChecked(subItem._id) || formValues.researchApproach.includes(subItem._id)
                          ? 'active'
                          : ''
                      }
                      sx={{
                        border: '1px solid #E0E0E0',
                        display: 'inline-block',
                        width: 'fit-content',
                        m: 2,
                        borderRadius: '20px',
                        p: 2,
                      }}
                    >
                      <Typography variant="body1" display="inline">
                        {subItem.label}
                      </Typography>
                      <Checkbox
                        name="researchApproach"
                        checked={
                          isChecked(subItem._id) ||
                          formValues.researchApproach.includes(subItem._id)
                        }
                        value={subItem._id}
                        onChange={handleChangeSelect}
                      />
                    </Box>
                  );
                }
                return true;
              })}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const question7 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 7 of 9 </Typography>
        <Typography sx={commonTextStyle.heading}>
          What environmental outputs does this program create?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>
          The question was added because you selected a environmental strategies and approaches.
          Please select all that apply.
        </Typography>
      </Grid>
      {
        /* eslint no-underscore-dangle: 0 */
        vocab?.sccConversion.map((item: any) => (
          <Grid key={item._id} item>
            <Box
              className={formValues.environmentalOutputs.includes(item._id) ? 'active' : ''}
              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
            >
              <Typography variant="body1" display="inline">
                {item.label}
              </Typography>
              <Checkbox
                name="environmentalOutputs"
                checked={formValues.environmentalOutputs.includes(item._id)}
                value={item._id}
                onChange={handleChangeSelect}
                style={{
                  color: `${
                    formValues.environmentalOutputs.includes(item._id) ? colors.primary : ''
                  }`,
                }}
              />
            </Box>
          </Grid>
        ))
      }
    </Grid>
  );

  const question8 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 8 of 9</Typography>
        <Typography sx={commonTextStyle.heading}>
          Please add numeric values for the environmental outputs this program creates.
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>
          Outputs are purely numeric values that are proportionate to your budget. If your program
          was completely funded, these would be the metrics you report.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {formValues?.environmentalOutputs?.map((lifetimeOutput: any, index) => {
          const label: any = vocab?.sccConversion?.filter(
            (item: any) => item?._id === lifetimeOutput,
          );
          return (
            <Grid container spacing={4} key={lifetimeOutput.key}>
              <Grid item xs={12} sm={3} mb={2}>
                <Typography sx={commonTextStyle.labelStyle}>Quantity {index + 1}</Typography>
                <TextField
                  name="quantity"
                  type="number"
                  value={getFieldValue(index, 'quantity')}
                  onChange={(event) => {
                    handleLifetimeOutputChange(
                      'quantity',
                      index,
                      event.target.value,
                      lifetimeOutput,
                    );
                  }}
                  variant="outlined"
                  fullWidth
                  sx={commonTextStyle.textFieldStyle}
                />
              </Grid>
              <Grid item xs={12} sm={6} mb={2}>
                <Typography sx={commonTextStyle.labelStyle}>
                  Output {index + 1} Description
                </Typography>
                <TextField
                  name="description"
                  type="text"
                  value={getFieldValue(index, 'description')}
                  onChange={() => {
                    handleLifetimeOutputChange(
                      'description',
                      index,
                      label && label[0]?.label!,
                      lifetimeOutput,
                    );
                  }}
                  sx={commonTextStyle.textFieldStyle}
                  variant="outlined"
                  className="inputTextFiled"
                  fullWidth
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );

  const question9 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 9 of 9</Typography>
        <Typography sx={commonTextStyle.heading}>
          How strong is the relation of the outcomes from the study to the strategies and approaches
          you identified?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            sx={{ display: 'inline-block' }}
            name="strength"
            value={formValues.strength}
            onChange={handleChange}
          >
            {vocab?.strength.map((item: any) => (
              <FormControlLabel
                sx={{ p: 2, mx: 2, border: '1px solid #E0E0E0', borderRadius: '20px' }}
                labelPlacement="start"
                key={item._id}
                value={item._id}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
  // *** end questions ***

  return (
    <section className="bgLight">
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardHeader
              sx={{
                padding: '20px 80px',
                background: colors.backgroundColorGradient,
                borderRadius: '20px 20px 0px 0px',
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize18,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Section 4
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize24,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Research and Evaluation
                    </Typography>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <Typography
                        sx={{
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fontWeight600,
                          color: colors.secondary,
                        }}
                      >
                        {pageNumber === 1 ? 0 : (pageNumber - 1) * 11}% complete
                      </Typography>
                    </Grid>
                    <BorderLinearProgress
                      variant="determinate"
                      value={pageNumber === 1 ? 1 : (pageNumber - 1) * 11}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent sx={{ minHeight: '70vh', padding: '40px 80px' }}>
              {pageNumber === 1 && question1}
              {pageNumber === 2 && question2}
              {pageNumber === 3 && question3}
              {pageNumber === 4 && question4}
              {pageNumber === 5 && question5}
              {pageNumber === 6 && question6}
              {pageNumber === 7 && question7}
              {pageNumber === 8 && question8}
              {pageNumber === 9 && question9}
              <Grid item xs={12} textAlign="right">
                <Divider sx={{ my: 3 }} orientation="horizontal" flexItem />
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  onClick={handleSaveExit}
                  variant="outlined"
                  size="large"
                >
                  Save and Exit
                </Button>
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  disabled={pageNumber === 1}
                  onClick={handlePreviousbutton}
                  variant="outlined"
                  size="large"
                >
                  Previous
                </Button>
                <Button
                  sx={{
                    ...commonTextStyle.buttonStyle,
                    mx: 2,
                    backgroundColor: colors.primary,
                    color: colors.secondary,
                  }}
                  onClick={handleNextButton}
                  variant="contained"
                  size="large"
                >
                  Next
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default ProgramResearchQuestions;
