// @ts-nocheck
import * as React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { CompanyEntity } from '../interfaces';
// import { LightTooltip } from './StyledComponents';
import { getCompanyEntities } from '../../api/portal/companyEntityRequests';
import fonts from '../../assets/scss/font.module.scss';

// const filter = createFilterOptions<CompanyEntity>();
interface Props {
  disabled?: boolean;
  onChange: (event: any, newValue: { name: string; id: string }) => void;
  customerName?: string;
  validateSpecialChar?: boolean;
}
export const CompanySearch: React.FC<Props> = ({
  onChange,
  customerName,
  validateSpecialChar = true,
}) => {
  const [value, setValue] = React.useState<CompanyEntity | null>(null);
  const [inputValue, setInputValue] = React.useState(customerName);
  const [toastError, setToastError] = React.useState<boolean>(false);
  const [companyNames, setCompanyNames] = React.useState<any>([]);

  const inputChange = async (inputChangeValue: any) => {
    if (validateSpecialChar && inputChangeValue?.match(/[^\w\s.,]/gi)) {
      setToastError(true);
      if (!toastError) {
        toast.error('Special characters are not allowed');
      }
    } else {
      const response = await getCompanyEntities({
        name: inputChangeValue,
        count: 10,
        offset: 0,
        orderBy: 'name',
        descending: false,
      });
      if (response) {
        setCompanyNames(response?.data?.results);
        setToastError(false);
      }
    }
  };

  React.useEffect(() => {
    if (customerName !== '' && customerName !== undefined) {
      inputChange(customerName);
    }
  }, [customerName]);
  // if (customerName) {
  //   return <StyledInput value={customerName} readOnly />;
  // }

  return (
    <Autocomplete
      fullWidth
      // disabled={disabled}
      value={value}
      sx={{
        fontSize: fonts.fontSize16,
        '& .MuiFormControl-root, & .MuiOutlinedInput-root': {
          minWidth: '100%',
          fontSize: fonts.fontSize16,
          padding: '0px',
          marginTop: '2px',
          height: 43,
        },
        lineHeight: '150%',
      }}
      className='select-enter-buyer-name-autocomplete'
      onInputChange={(event: any, newInputvalue) => {
        setInputValue(newInputvalue);
        inputChange(newInputvalue);
      }}
      onChange={(event, newValue) => {
        if (newValue === null) {
          setValue(newValue);
        } else if (typeof newValue !== 'string' && newValue?.id && newValue?.name) {
          setValue(newValue);
          setInputValue(newValue?.name);
          setToastError(true);
          inputChange(newValue?.name);
          if (inputValue !== '') {
            onChange(event, { name: newValue?.name, id: newValue.id });
          }
        }
      }}
      onKeyUp={() => {
        if (inputValue?.length === 1 && !inputValue?.match(/^[a-z0-9]+$/i) && inputValue === ' ') {
          setToastError(true);
          toast.error('Buyer should not start with a Space!!!');
        }
      }}
      // filterOptions={(options, params) => {
      //   const filtered = filter(options, params);

      //   const { inputValue } = params;
      //   // Suggest the creation of a new value
      //   const isExisting = options.some((option) => inputValue === option.name);
      //   if (inputValue !== '' && !isExisting) {
      //     filtered.push({
      //       inputValue,
      //       name: `Add "${inputValue}"`,
      //     });
      //   }

      //   return filtered;
      // }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="autocomplete-with-add-new"
      options={inputValue?.match(/[a-zA-Z0-9]/) ? companyNames : []}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.name) {
          return option.name;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      freeSolo
      renderInput={(params) => (
        <TextField placeholder='Select or enter buyer name' {...params} variant="outlined" />
      )}
    />
  );
};
