import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  CommonGridFilter,
  CommonGridFilterHandle,
  FlexBox,
  IsolatedGrid,
  LightTooltip,
  MoneyBalanceWithLocaleSymbol,
  ContributionStatusCell,
} from '../../shared/components';
import {
  BETWEEN_FILTER_OPERATOR,
  CONTAINS_FILTER_OPERATOR,
  EQUALS_FILTER_OPERATOR,
  STARTS_WITH_OPERATOR,
} from '../../shared/constants';
import { GridColumns, ContributionStatus } from '../../shared/enums';
import { useAppDispatch, useAppSelector, useInstance } from '../../shared/hooks';
import { CustomGridColDef } from '../../shared/interfaces';
import {
  getDateString,
  getGenericHeight,
  getGridRequest,
  getUsernameFromEmail,
} from '../../shared/utils';
import {
  clearProcurement,
  getProcurementContributionsThunk,
  setProcurementGridModel,
  setProcurementTab,
} from './procurementSlice';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';
import borders from '../../assets/scss/strokes.module.scss';

export const Procurement = () => {
  const procurementState = useAppSelector((x) => x.procurement);
  const accountState = useAppSelector((x) => x.app.account);
  const dispatch = useAppDispatch();
  const { loadingUserData } = useInstance();
  const filterRef = useRef<CommonGridFilterHandle>(null);
  const navigate = useNavigate();
  const { selectedTab, grid } = { ...procurementState };
  const { sort, page, pageSize, filter } = grid;

  useEffect(() => {
    const payload =
      selectedTab === null
        ? { sharedWithId: accountState?.id ?? '-1' }
        : { sharedWithId: accountState?.id ?? '-1', status: selectedTab };
    dispatch(getProcurementContributionsThunk({ ...getGridRequest(grid), ...payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, selectedTab]);

  useEffect(
    () => () => {
      dispatch(clearProcurement());
    },
    [dispatch],
  );

  const tabs = [
    { label: 'All', value: null },
    { label: 'Completed', value: ContributionStatus.Completed },
    { label: 'Pending', value: ContributionStatus.Approved },
  ];

  const columns: Array<CustomGridColDef> = [
    {
      field: 'giveId',
      description: 'ID',
      headerName: 'Contribution ID',
      type: 'number',
      flex: 0.5,
      filterable: false,
      filterOperators: STARTS_WITH_OPERATOR,
      hideable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'accountName',
      headerName: 'Supplier',
      description: 'Supplier Name',
      valueGetter: ({ row: { account } }) => account?.name,
      flex: 1,
      filterable: true,
      filterOperators: CONTAINS_FILTER_OPERATOR,
    },
    // {
    //   field: 'customerId',
    //   headerName: 'Supplier ID',
    //   description: 'Supplier ID',
    //   flex: 1,
    //   filterable: false,
    //   filterOperators: STARTS_WITH_OPERATOR,
    // },
    {
      field: 'programName',
      headerName: 'Programs',
      description: 'Programs',
      valueGetter: ({ row }) => (row.causeArea ? row.causeArea.name : row.programName),
      flex: 1,
      filterable: false,
      filterOperators: CONTAINS_FILTER_OPERATOR,
    },
    // hide Quote for now
    // {
    //   field: 'quoteNumber',
    //   headerName: 'Quote #',
    //   description: 'Quote Number',
    //   flex: 0.5,
    //   filterable: true,
    //   filterOperators: STARTS_WITH_OPERATOR,
    // },
    {
      field: 'description',
      headerName: 'Opportunity Name',
      flex: 1,
      filterable: true,
      filterOperators: CONTAINS_FILTER_OPERATOR,
    },
    {
      field: 'grandTotal',
      headerName: 'Contribution Amount',
      description: 'Contribution Amount',
      flex: 1,
      filterable: true,
      type: GridColumns.String,
      customColumnType: GridColumns.MoneyWithLocaleSymbol,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      renderCell: (params) => (
        <MoneyBalanceWithLocaleSymbol variant="body2">{params.value}</MoneyBalanceWithLocaleSymbol>
      ),
    },
    {
      field: 'quoteAmount',
      headerName: 'TDV',
      description: 'Total Deal Value',
      flex: 1,
      filterable: true,
      type: GridColumns.String,
      customColumnType: GridColumns.MoneyWithLocaleSymbol,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      renderCell: (params) => (
        <MoneyBalanceWithLocaleSymbol variant="body2">{params.value}</MoneyBalanceWithLocaleSymbol>
      ),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
      filterable: true,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      renderCell: (params) => (
        <LightTooltip title={params?.value}>
          <Box>{getUsernameFromEmail(params?.value)}</Box>
        </LightTooltip>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      filterable: true,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      type: GridColumns.Date,
      renderCell: (params) => (
        <LightTooltip title={getDateString(params?.value) ?? ''}>
          <Box>{getDateString(params?.value, true)}</Box>
        </LightTooltip>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      description: 'Status',
      type: GridColumns.SingleSelect,
      flex: 1,
      filterOperators: EQUALS_FILTER_OPERATOR,
      filterable: false,
      renderCell: (params) => (
        <ContributionStatusCell>
          {params.value === ContributionStatus.Approved ? 'Pending' : params.value}
        </ContributionStatusCell>
      ),
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Last Updated By',
      flex: 1,
      filterable: true,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      renderCell: (params) => (
        <LightTooltip title={params?.value}>
          <Box>{getUsernameFromEmail(params?.value)}</Box>
        </LightTooltip>
      ),
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      flex: 1,
      filterable: true,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      type: GridColumns.Date,
      renderCell: (params) => (
        <LightTooltip title={getDateString(params?.value) ?? ''}>
          <Box>{getDateString(params?.value, true)}</Box>
        </LightTooltip>
      ),
    },
    {
      field: 'action',
      headerName: 'Actions',
      disableReorder: true,
      resizable: false,
      type: GridColumns.Actions,
      minWidth: 150,
      renderCell: ({ row: { id } }) => (
        <FlexBox sx={{ gap: 1 }}>
          <Button
            variant="outlined"
            endIcon={<ArrowForwardIcon />}
            // color="success"
            // sx={{ backgroundColor: '#fff', fontSize:"14px",fontWeight:400 }}
            sx={{
              backgroundColor: colors.secondary,
              fontSize: fonts.fontSize14,
              fontWeight: fonts.fontWeight700,
              color: colors.primary,
              borderRadius: borders.borderRadius8,
              borderColor: colors.primary,
              '&:focus': colors.primary,
              '&:hover': colors.primary,
            }}
            onClick={() => navigate(`/app/contribution/${id}/details`)}
          >
            View Details
          </Button>
        </FlexBox>
      ),
      sortable: false,
      filterable: false,
      hideable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  const handleTabChange = (event: React.SyntheticEvent, newValue: ContributionStatus | null) => {
    dispatch(setProcurementTab(newValue));
    filterRef.current?.clearFilterSelections();
  };

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: colors.primary,
            height: '4px',
          },
        },
      },
    },
  });

  return (
    <Box className="dashboard" sx={{ px: '35px', pt: '12px', pb: '12px' }}>
      <FlexBox>
        <Typography
          sx={{ flex: 1, color: colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 700 }}
        >
          Dashboard
        </Typography>
      </FlexBox>
      <Grid container alignItems="end">
        <Grid item md={6} sm={12}>
          <Box my={1}>
            <ThemeProvider theme={theme}>
              <Tabs value={selectedTab} onChange={handleTabChange}>
                {tabs.map(({ value, label }) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Tab
                    value={value}
                    label={label}
                    key={`tab-Procurement-${label}`}
                    sx={{
                      textTransform: 'capitalize',
                      color: selectedTab === value ? colors.primary : colors.textPrimary,
                      fontSize: fonts.fontSize18,
                      fontWeight: selectedTab === value ? fonts.fontWeight700 : fonts.fontWeight600,
                    }}
                  />
                ))}
              </Tabs>
            </ThemeProvider>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box my={1} display="flex" justifyContent="flex-end">
            <CommonGridFilter
              columns={columns}
              currentFilter={filter}
              onChange={(x) => {
                dispatch(setProcurementGridModel({ filter: x }));
              }}
              ref={filterRef}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '400px', height: getGenericHeight(300), marginTop: 1 }}>
        <IsolatedGrid
          status={selectedTab}
          model={grid}
          columns={columns}
          onChange={(x) => dispatch(setProcurementGridModel(x))}
          extraLoading={loadingUserData}
          alternativeName="procurement"
        />
      </Box>
    </Box>
  );
};
