import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { Typography, Box } from '@mui/material';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress color="primary" variant="determinate" {...props} className="progress_bar" />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          // eslint-disable-next-line react/destructuring-assignment
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const LinearWithValueLabel = () => {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%', marginLeft: '30px', marginRight: '30px' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
};
export default LinearWithValueLabel;
