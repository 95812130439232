import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle, OpenInNew } from '@mui/icons-material';
import { BoldTypography, FlexBox, Loading, ThankYouTypography } from '../../shared/components';
import { useAppDispatch, useAppSelector, useInstance } from '../../shared/hooks';
import {
  postRegisterInviteFinanceUserThunk,
  putRegisterUserSubscriptionThunk,
  setRegisterDetailsInviteEmail,
  setRegisterDetailsInviteFirstName,
  setRegisterDetailsInviteLastName,
  setRegisterStepperStep,
} from './registerDetailsSlice';
import { emailValid, formatter, isNullOrWhitespace, platform, redirect } from '../../shared/utils';
import { AccountSubscription, FinancialChoice, RegisterStep } from '../../shared/enums';
import { refreshAppAccountThunk } from '../../app';
import { Subscription } from '../../shared/interfaces';

export const RegisterFinalize = () => {
  const state = useAppSelector((x) => x.registerDetails);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { locale, instance: currentInstanceData, loadingUserData } = useInstance();

  const { financialChoice, subscriptionChoice, inviteForm, success, redirectUrl, loading } = state;
  const { email, firstName, lastName } = inviteForm;
  const { settings: settingsInstanceData } = { ...currentInstanceData };
  const { stripe: stripeInstanceData } = { ...settingsInstanceData };
  const { trialPeriodDays: trialPeriodDaysInstanceData, prices: pricesInstanceData } = {
    ...stripeInstanceData,
  };
  const { annual: annualInstanceData, monthly: monthlyInstanceData } = { ...pricesInstanceData };
  const { amount: amountAnnualInstanceData, footer: footerAnnualInstanceData, enabled: annualEnabled } = {
    ...annualInstanceData,
  };
  const { amount: amountMonthlyInstanceData, footer: footerMonthlyInstanceData, enabled: monthlyEnabled } = {
    ...monthlyInstanceData,
  };

  useEffect(() => {
    dispatch(setRegisterStepperStep(RegisterStep.Finalize));
  }, [dispatch]);

  useEffect(() => {
    if (!success) return;
    dispatch(refreshAppAccountThunk()).then(() => navigate('/register-pending-finance'));
  }, [dispatch, navigate, success]);

  useEffect(() => {
    if (isNullOrWhitespace(redirectUrl)) return;

    redirect(redirectUrl!);
  }, [redirectUrl]);

  const finalize = () => {
    if (financialChoice === FinancialChoice.InviteFinancialUser) {
      dispatch(putRegisterUserSubscriptionThunk()).then(() =>
        dispatch(postRegisterInviteFinanceUserThunk()),
      );
    } else {
      dispatch(putRegisterUserSubscriptionThunk());
    }
  };

  const ACCOUNT_SUBSCRIPTION_CONFIG: Record<string, Subscription> = {
    [AccountSubscription.Annual]: {
      id: AccountSubscription.Annual,
      title: 'Annual',
      text: `One annual payment to maintain access to your ${
        platform()[0].toUpperCase() + platform().slice(1)
      }with account.`,
      price: amountAnnualInstanceData || 4999,
      period: 'year',
      bullets: ['Cancellation anytime'],
      savings: 1000,
      savingFooter: footerAnnualInstanceData!,
      trialPeriodDays: trialPeriodDaysInstanceData!,
      currencySymbol: locale?.settings.symbol,
      enabled: annualEnabled,
    },
    [AccountSubscription.Monthly]: {
      id: AccountSubscription.Monthly,
      title: 'Monthly',
      text: `Regular monthly payments to maintain access to your ${
        platform()[0].toUpperCase() + platform().slice(1)
      }with account.`,
      price: amountMonthlyInstanceData || 499,
      period: 'month',
      bullets: ['Cancellation anytime'],
      savingFooter: footerMonthlyInstanceData!,
      trialPeriodDays: trialPeriodDaysInstanceData!,
      currencySymbol: locale?.settings.symbol,
      enabled: monthlyEnabled,
    },
  };

  const isInvite = financialChoice === FinancialChoice.InviteFinancialUser;

  const actionDisabled = () =>
    financialChoice === FinancialChoice.IamReady
      ? false
      : !emailValid(email) || isNullOrWhitespace(firstName) || isNullOrWhitespace(lastName);

  const handleBack = () => {
    if (isInvite) {
      return navigate('/register-details/financial-choice');
    }
    return navigate('/register-details/subscription');
  };

  const FinalizeCheck = <CheckCircle sx={(theme) => ({ color: theme.palette.primary.main })} />;

  const Summary = subscriptionChoice && (
    <Box>
      <BoldTypography variant="h5" mb={2}>
        Subscription Plan
      </BoldTypography>
      <FlexBox sx={{ gap: 2, flexDirection: 'column' }}>
        <FlexBox gap={2}>
          {FinalizeCheck}
          <Typography>{ACCOUNT_SUBSCRIPTION_CONFIG[subscriptionChoice].title}</Typography>
        </FlexBox>
        <FlexBox gap={2}>
          {FinalizeCheck}
          <Typography>
            {`${locale?.settings.symbol ?? ''}${formatter.format(
              ACCOUNT_SUBSCRIPTION_CONFIG[subscriptionChoice].price,
            )}`}
            &nbsp;/&nbsp;
            {ACCOUNT_SUBSCRIPTION_CONFIG[subscriptionChoice]?.period}
          </Typography>
        </FlexBox>
        {ACCOUNT_SUBSCRIPTION_CONFIG[subscriptionChoice].trialPeriodDays !== 0 && (
          <FlexBox gap={2}>
            {FinalizeCheck}
              <Typography>
                  {`First ${ACCOUNT_SUBSCRIPTION_CONFIG[subscriptionChoice].trialPeriodDays} days FREE`}
              </Typography>
          </FlexBox>
         )}
      </FlexBox>
    </Box>
  );

  const InviteForm = (
    <Box>
      <BoldTypography variant="h5" mb={2}>
        Invite
      </BoldTypography>
      <ThankYouTypography>
        Please invite your company&apos;s finance contact to make the appropriate funding and
        subscription selections along with payment information.
      </ThankYouTypography>
      <FlexBox sx={{ flexDirection: 'column', gap: 4, flex: 1 }}>
        <TextField
          label="First Name"
          fullWidth
          value={firstName}
          onChange={(e) => {
            dispatch(setRegisterDetailsInviteFirstName(e.target.value));
          }}
          required
        />
        <TextField
          label="Last Name"
          fullWidth
          value={lastName}
          onChange={(e) => {
            dispatch(setRegisterDetailsInviteLastName(e.target.value));
          }}
          required
        />
        <TextField
          label="Email"
          fullWidth
          value={email}
          onChange={(e) => {
            dispatch(setRegisterDetailsInviteEmail(e.target.value));
          }}
          required
        />
      </FlexBox>
    </Box>
  );

  if (loading || loadingUserData) return <Loading loading={loading || loadingUserData} />;

  return (
    <>
      <FlexBox
        sx={{
          marginTop: 4,
          flexDirection: 'column',
          color: 'black',
          gap: 6,
        }}
      >
        {!isInvite && Summary}
        {isInvite && InviteForm}
      </FlexBox>
      <FlexBox
        sx={{
          flexDirection: 'row-reverse',
          marginTop: 4,
          gap: 2,
          flex: 1,
          alignItems: 'start',
        }}
      >
        <Button
          variant="contained"
          size="large"
          disabled={actionDisabled()}
          sx={{ padding: 2, width: 200, height: 'fit-content' }}
          onClick={() => finalize()}
          endIcon={isInvite ? null : <OpenInNew />}
        >
          {isInvite ? 'Invite' : 'Stripe Checkout'}
        </Button>
        <Button
          variant="outlined"
          size="large"
          sx={{ padding: 2, width: 200, height: 'fit-content' }}
          onClick={() => handleBack()}
        >
          Back
        </Button>
      </FlexBox>
    </>
  );
};
