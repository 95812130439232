import React, { useState, ChangeEvent, useEffect } from 'react';
import _ from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Button,
  Radio,
  FormControl,
  Switch,
  Select,
  Checkbox,
  Modal,
  Divider,
  InputAdornment,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  styled,
  tableCellClasses,
  TableCell,
  Paper,
  Autocomplete,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RemoveIcon from '@mui/icons-material/Remove';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import {
  fetchProgram,
  patchProgrambyAdminThunk,
  removeProgram,
  updateProgrambyAdminThunk,
  downloadFileThunk,
} from './ProgramSlice';
import { getUsersThunk, clearUsersState } from '../users/usersSlice';
import { getGenericHeight, getGridRequest } from '../../shared/utils';
import { fetchSios } from '../sio/sioSlice';
import { Locations, Vocabulary } from '../../shared/interfaces/program/program';
import '../sio/Sio.scss';
import {
  getAllCompanies,
  getAllIndustries,
  getLocations,
  getVocab,
  programAction,
  sendEmail,
  uploadFile,
} from '../../api/portal/programRequest';
import { CellPhoneInput } from '../../shared/components';
import EyeIcon from '../../assets/svg/eyeIcon.svg';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface LocationState {
  isCreate: string;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#FFFFFF',
  border: '1px solid #E6E6E6',
  boxShadow: 24,
  borderRadius: 5,
};

const successStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FFFFFF',
  width: '40vw',
  border: '1px solid #E6E6E6',
  boxShadow: 24,
  borderRadius: 5,
  padding: '30px 50px',
};

interface ProgramData {
  status: string;
  preferredByCompanies?: any;
}

const lifetimeOutputForm = [{ quantity: 0, description: '' }];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F6F6F6',
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ProgramDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const isCreate = location.state as LocationState;
  const sioState = useAppSelector((state) => state.sios);
  const accountId = useAppSelector((state) => state.app.account?.id!);
  const programState = useAppSelector((state) => state.programs);
  const instance = useAppSelector((state) => state.app.associatedInstance);
  const [openModal, setOpenModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const { program } = programState;
  const success = useAppSelector((state) => state.editUser.success);
  const [openApprovedModal, setOpenApprovedModal] = React.useState('');
  const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const { grid, active } = usersState;
  const { page, pageSize, sort, filter } = grid;
  const navigate = useNavigate();
  const [applicationFormName, setApplicationFormName] = useState('');
  const [sio, setSio] = useState('');
  const [siAdmin, setSiAdmin] = useState('');
  const [programStrengthRating, setProgramStrengthRating] = useState(0);
  const [edit, setEdit] = useState(program.active);
  const [activeEdit, setActiveEdit] = useState(program.editing ?? true);
  const [visibilityEdit, setVisibilityEdit] = useState(false);
  const [threshHoldEdit, setThreshHoldEdit] = useState(false);
  const [minimumFundRequired, setMinimumFundRequired] = useState(0);
  const [internalNotes, setInternalNotes] = useState('');
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [locations, setLocations] = useState<Locations>();
  const [vocab, setVocab] = useState<Vocabulary>();
  const [eligible, setEligible] = useState(false);
  const [companyData, setCompanyData] = useState<any>([]);
  const [sioNames, setSioNames] = useState(sioState.grid.data.results);
  const [sioValue, setSioValue] = useState<any>(null);
  const [currencyType, setCurrencyType] = useState('USD');

  const currency = ['USD', 'EUR', 'GBP', 'Other'];
  const [locExpDetails, setLocaExpDetails] = useState({
    regions: false,
    countries: false,
    states: false,
    cities: false,
  });

  useEffect(() => {
    if (sioState.grid.data.results) {
      const list = sioState.grid.data.results;
      setSioNames(list);
      if (sio) {
        setSioValue(list.find((item) => item.id === sio));
      } else {
        setSioValue(null);
      }
    }
  }, [sioState.grid.data.results.length, sio]);

  const updatedGrid = {
    ...grid,
    pageSize: 1000,
    defaultSort: { field: 'roles', direction: 'asc' as const },
    filter: { field: 'roles', simpleValue: 'sw_si_admin' },
  };
  const getUsersGridRequest = () => ({
    ...getGridRequest(updatedGrid),
    accountId,
  });

  const initialFormValue = {
    fistLastName: program?.general?.contact?.name,
    phone: program?.general?.contact?.phone,
    email: program?.general?.contact?.email,
    internalName: program?.general?.programName?.internalProgramName,
    platformName: program?.general?.programName?.platformProgramName,
    description: program?.general?.programDescription?.description,
    causes: program?.impactAndScope?.causes ?? [],
    primaryImpact: program?.impactAndScope?.primaryImpact ?? [],
    secondaryImpact: program?.impactAndScope?.secondaryImpacts ?? [],
    targetGroup: program?.impactAndScope?.targetGroup ?? [],
    audienceAge: program?.impactAndScope?.audienceAge ?? [],
    regions: program?.impactAndScope?.locationDetails?.regions ?? [],
    countries: program?.impactAndScope?.locationDetails?.countries ?? [],
    states: program?.impactAndScope?.locationDetails?.states ?? [],
    cities: program?.impactAndScope?.locationDetails?.cities ?? [],
    additionalLocationDetails: program?.impactAndScope?.locationDetails?.additionalLocationDetails,
    strategies: program?.strategiesAndApproaches?.strategies ?? [],
    activities: program?.strategiesAndApproaches?.activities?.value,
    approachDuration: program?.strategiesAndApproaches?.approachDuration,
    activitiesFrequency: program?.strategiesAndApproaches?.activitiesFrequency,
    outputs: lifetimeOutputForm,
    outcomes: lifetimeOutputForm,
    dataMeasurementType: program?.strategiesAndApproaches?.dataMeasurementType,
    nonprofitPartners: program?.strategiesAndApproaches?.nonprofitPartners ?? false,
    nonprofitPartnersDescription:
      program?.strategiesAndApproaches?.nonprofitPartnersDescription ?? '',
    researchFile: program?.researchAndEvaluation?.researchFile,
    evidenceDescription: program?.researchAndEvaluation?.evidenceDescription?.selected ?? [],
    evidenceOptional: program?.researchAndEvaluation?.evidenceDescription?.optional ?? '',
    studyDescription: program?.researchAndEvaluation?.studyDescription?.selected ?? [],
    studyOptional: program?.researchAndEvaluation?.studyDescription?.optional ?? '',
    dataDescription: program?.researchAndEvaluation?.dataDescription?.selected ?? [],
    dataOptional: program?.researchAndEvaluation?.dataDescription?.optional ?? '',
    outcomeDescription: program?.researchAndEvaluation?.outcomeDescription ?? [],
    strength: program?.researchAndEvaluation?.strength,
    environmentalOutputValues: lifetimeOutputForm,
    environmentalOutputs: program?.researchAndEvaluation?.environmentalOutputs?.selected ?? [],
    researchApproach: program?.researchAndEvaluation?.researchApproach ?? [],
    currency: program?.finance?.currency,
    budget: program?.finance?.budget,
    inKindContributions: program?.finance?.inKindContributions,
    cashContributions: program?.finance?.cashContributions,
    budgetFile: program?.finance?.budgetFile,
    filmLocation: program?.content?.filmLocation,
    assets: program?.content?.assets,
    locationExplanation: {
      regions: program?.impactAndScope?.locationDetails?.locationExplanation?.regions ?? '',
      countries: program?.impactAndScope?.locationDetails?.locationExplanation?.countries ?? '',
      states: program?.impactAndScope?.locationDetails?.locationExplanation?.states ?? '',
      cities: program?.impactAndScope?.locationDetails?.locationExplanation?.cities ?? '',
    },
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [industries, setIndustries] = useState<any>([]);
  const [industryValue, setIndustryValue] = useState<any>({});
  const [files, setFiles] = useState<File>();
  const [formPreviewFiles, setformPreviewFiles] = useState<any>({});
  const [company, setCompany] = useState<any>({});
  const [selectedCompany, setSelectedCompany] = useState<any>([]);
  const [rowsPrefrered, setRowsPrefrered] = useState<any>([]);
  const [requestParams, setRequestParams] = useState({
    count: 10,
    offset: 0,
    orderBy: 'name',
    name: '',
    industry: '',
  });

  const Roles = grid.data.results;
  const SiAdminRoles = Roles.filter((arr) => (arr.roles ? arr.roles.includes('sw_si_admin') : ''));
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [evidenceOptionalCheck, setEvidenceOptionalCheck] = useState(false);
  const [studyOptionalCheck, setStudyOptionalCheck] = useState(false);
  const [dataOptionalCheck, setDataOptionalCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  const downloadFile = (file: any) => {
    if (_.has(file, 'url')) {
      dispatch(downloadFileThunk({ id, url: file.url })).then(() => {
        toast.success('File downloaded successfully');
      });
    } else {
      toast.error('Unable to fetch file url');
    }
  };

  const downloadTextButton = (file: any) => (
    <Box
      sx={(theme) => ({
        color: theme.palette.success.dark,
        textDecoration: 'underline',
        lineHeight: '23.8px',
        fontWeight: 400,
        fontSize: '14px',
        cursor: 'pointer',
        marginLeft: '8px',
        display: 'flex',
        alignItems: 'center',
      })}
      onClick={() => downloadFile(file)}
    >
      Download file
    </Box>
  );

  const SioNameOptions = sioState.grid.data.results;
  const selectOptions = SiAdminRoles.map((option) => (
    <MenuItem key={option.id} value={option.id}>
      {`${option.firstName} ${option.lastName}`}
    </MenuItem>
  ));
  const selectIndustryOptions = industries?.map((option: any) => (
    <MenuItem key={option._id} value={option}>
      {`${option.label}`}
    </MenuItem>
  ));

  const createPreferedData = (_id: string, name: string, type: string, preferredOn: string) => ({
    _id,
    name,
    type,
    preferredOn,
  });

  useEffect(() => {
    getVocab().then((res) => {
      if (res.status === 200) {
        setVocab(res.data);
      }
    });
    getLocations().then((res) => {
      if (res.status === 200) {
        setLocations(res.data);
      }
    });
    getAllIndustries().then((res) => {
      if (res.status === 200) {
        setIndustries(res.data);
      }
    });
    if (id) {
      dispatch(fetchProgram(id)).then((programData: any) => {
        const prefrered = programData?.payload?.preferredByCompanies?.companies.map((item: any) =>
          createPreferedData(item._id, item.name, item.type, item.preferredOn),
        );
        setApplicationFormName(programData.payload.applicationFormName);
        setSio(programData.payload.sio);
        setSiAdmin(programData.payload.siAdmin);
        setEdit(programData.payload.active ?? false);
        setProgramStrengthRating(programData.payload.programStrengthRating);
        setMinimumFundRequired(programData.payload.minimumFundRequired);
        setInternalNotes(programData.payload.internalNotes);
        setSelectedCompany(programData.payload.preferredByCompanies?.companies ?? []);
        setRowsPrefrered(prefrered);
        setEligible(programData.payload.isStandard ?? false);
        setFormValues({
          fistLastName: programData.payload?.general?.contact?.name,
          phone: programData.payload?.general?.contact?.phone,
          email: programData.payload?.general?.contact?.email,
          internalName: programData.payload?.general?.programName?.internalProgramName,
          platformName: programData.payload?.general?.programName?.platformProgramName,
          description: programData.payload?.general?.programDescription?.description,
          causes: programData.payload?.impactAndScope?.causes ?? [],
          primaryImpact: programData.payload?.impactAndScope?.primaryImpact ?? [],
          secondaryImpact: programData.payload?.impactAndScope?.secondaryImpacts ?? [],
          targetGroup: programData.payload?.impactAndScope?.targetGroup ?? [],
          audienceAge: programData.payload?.impactAndScope?.audienceAge ?? [],
          regions: programData.payload?.impactAndScope?.locationDetails?.regions ?? [],
          countries: programData.payload?.impactAndScope?.locationDetails?.countries ?? [],
          states: programData.payload?.impactAndScope?.locationDetails?.states ?? [],
          cities: programData.payload?.impactAndScope?.locationDetails?.cities ?? [],
          additionalLocationDetails:
            programData.payload?.impactAndScope?.locationDetails?.additionalLocationDetails,
          strategies: programData.payload?.strategiesAndApproaches?.strategies ?? [],
          activities: programData.payload?.strategiesAndApproaches?.activities?.value,
          approachDuration: programData.payload?.strategiesAndApproaches?.approachDuration,
          activitiesFrequency: programData.payload?.strategiesAndApproaches?.activitiesFrequency,
          outputs: programData.payload?.strategiesAndApproaches?.outputs ?? lifetimeOutputForm,
          outcomes: programData.payload?.strategiesAndApproaches?.outcomes ?? lifetimeOutputForm,
          dataMeasurementType: programData.payload?.strategiesAndApproaches?.dataMeasurementType,
          nonprofitPartners:
            programData.payload?.strategiesAndApproaches?.nonprofitPartners ?? false,
          nonprofitPartnersDescription:
            programData.payload?.strategiesAndApproaches.nonprofitPartnersDescription ?? '',
          researchFile: programData.payload?.researchAndEvaluation?.researchFile,
          evidenceDescription:
            programData.payload?.researchAndEvaluation?.evidenceDescription?.selected ?? [],
          evidenceOptional:
            programData.payload?.researchAndEvaluation?.evidenceDescription?.optional ?? '',
          studyDescription:
            programData.payload?.researchAndEvaluation?.studyDescription?.selected ?? [],
          studyOptional:
            programData.payload?.researchAndEvaluation?.studyDescription?.optional ?? '',
          dataDescription:
            programData.payload?.researchAndEvaluation?.dataDescription?.selected ?? [],
          dataOptional: programData.payload?.researchAndEvaluation?.dataDescription?.optional ?? '',
          outcomeDescription: programData.payload?.researchAndEvaluation?.outcomeDescription ?? [],
          strength: programData.payload?.researchAndEvaluation?.strength,
          environmentalOutputValues:
            programData.payload?.researchAndEvaluation?.environmentalOutputValues ??
            lifetimeOutputForm,
          researchApproach: programData.payload?.researchAndEvaluation?.researchApproach ?? [],
          environmentalOutputs:
            programData.payload?.researchAndEvaluation?.environmentalOutputs?.selected ?? [],
          currency: programData.payload?.finance?.currency,
          budget: programData.payload?.finance?.budget,
          inKindContributions: programData.payload?.finance?.inKindContributions,
          cashContributions: programData.payload?.finance?.cashContributions,
          budgetFile: programData.payload?.finance?.budgetFile,
          filmLocation: programData.payload?.content?.filmLocation,
          assets: programData.payload?.content?.assets,
          locationExplanation: {
            regions:
              programData.payload?.impactAndScope?.locationDetails?.locationExplanation?.regions ??
              '',
            countries:
              programData.payload?.impactAndScope?.locationDetails?.locationExplanation
                ?.countries ?? '',
            states:
              programData.payload?.impactAndScope?.locationDetails?.locationExplanation?.states ??
              '',
            cities:
              programData.payload?.impactAndScope?.locationDetails?.locationExplanation?.cities ??
              '',
          },
        });
        const c1 = programData.payload?.finance?.currency;
        const otherType = currency.includes(c1) ? c1 : 'Other';
        const c2 = _.isEmpty(c1) ? 'USD' : otherType;
        setCurrencyType(c2);
        setIsOtherSelected(c2 === 'Other');
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, active]);

  useEffect(() => {
    if (success) dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    dispatch(
      fetchSios({ status: 'approved', ...getGridRequest({ ...sioState.grid, pageSize: 500 }) }),
    );
  }, [
    sioState.grid.sort,
    sioState.grid.filter,
    sioState.grid.page,
    sioState.status,
    sioState.grid.pageSize,
  ]);

  useEffect(
    () => () => {
      dispatch(clearUsersState());
    },
    [dispatch],
  );

  const commonTextStyle = {
    mainHeading: {
      fontSize: fonts.fontSize24,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    accordionStyle: {
      fontSize: fonts.fontSize24,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    accordionCount: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textSecondary,
    },
    accordionButton: {
      border: `1px solid ${colors.strokeClick}`,
      width: '44px',
      height: '44px',
      display: 'flex',
      borderRadius: '5px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormValues({ ...formValues, [name]: value });
    setApplicationFormName(value);
  };

  const handleOutputChange =
    (field: string, index: number, name: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (name === 'outputs') {
        const newLifetimeOutputs = [...formValues.outputs];
        newLifetimeOutputs[index] = { ...newLifetimeOutputs[index], [field]: value };
        setFormValues((prevValues) => ({
          ...prevValues,
          outputs: newLifetimeOutputs,
        }));
      } else {
        const newLifetimeOutputs = [...formValues.outcomes];
        newLifetimeOutputs[index] = { ...newLifetimeOutputs[index], [field]: value };
        setFormValues((prevValues) => ({
          ...prevValues,
          outcomes: newLifetimeOutputs,
        }));
      }
    };

  const filterWithString = (newInputvalue: string) => {
    const options = SioNameOptions.filter(
      (item) => item.publicOrganizationName.toLowerCase().indexOf(newInputvalue.toLowerCase()) >= 0,
    );
    setSioNames(options);
  };

  const locationExpCheck = (key: string, isCheck: boolean) => {
    setLocaExpDetails((prevState) => ({ ...prevState, [key]: isCheck }));
  };

  const handleSioInput = (e: any, newValue: any, type: string) => {
    const value = newValue?.id || '';
    setSioValue(newValue);
    setSio(value);
    setFormValues({ ...formValues, [type]: value });
  };

  const handleCheck = (event: any, key: keyof typeof formValues) => {
    let newvalue = formValues[key];
    if (Array.isArray(newvalue)) {
      if (event.target.checked) {
        setFormValues({
          ...formValues,
          [key]: [event.target.value],
        });
      } else {
        newvalue = newvalue.filter((item: any) => item !== event.target.value);
        setFormValues({
          ...formValues,
          [key]: newvalue,
        });
      }
    }
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>, isNa?: boolean) => {
    const { name, value } = event.target;
    if (name === 'evidenceOptionalValue') {
      setFormValues({
        ...formValues,
        evidenceOptional: value,
      });
    }
    if (name === 'studyOptionalValue') {
      setFormValues({
        ...formValues,
        studyOptional: value,
      });
    }
    if (name === 'dataOptionalValue') {
      setFormValues({
        ...formValues,
        dataOptional: value,
      });
    }
    if (name === 'causes') {
      let newvalue = formValues.causes;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else if (formValues.causes.length <= 1) {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        causes: newvalue,
      });
    }
    if (name === 'secondaryImpact') {
      let newvalue = formValues.secondaryImpact;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else if (formValues.secondaryImpact.length < 3) {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        secondaryImpact: newvalue,
      });
    }
    if (name === 'targetGroup') {
      if (isNa) {
        setFormValues({
          ...formValues,
          targetGroup: [],
        });
        handleCheck(event, 'targetGroup');
      } else {
        let newvalue = formValues.targetGroup;
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          targetGroup: newvalue,
        });
      }
    }
    if (name === 'audienceAge') {
      if (isNa) {
        setFormValues({
          ...formValues,
          audienceAge: [],
        });
        handleCheck(event, 'audienceAge');
      } else {
        let newvalue = formValues.audienceAge;
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          audienceAge: newvalue,
        });
      }
    }
    if (name === 'regions') {
      if (value === 'N/A') {
        if (event.target.checked) {
          locationExpCheck('regions', true);
          setFormValues({ ...formValues, regions: [], countries: [], states: [], cities: [] });
        } else {
          locationExpCheck('regions', false);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            locationExplanation: {
              regions: '',
            },
          }));
        }
      } else {
        let newvalue = formValues.regions;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          regions: newvalue,
        });
      }
    }
    if (name === 'countries') {
      if (value === 'N/A') {
        if (event.target.checked) {
          locationExpCheck('countries', true);
          setFormValues({ ...formValues, countries: [], states: [], cities: [] });
        } else {
          locationExpCheck('countries', false);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            locationExplanation: {
              countries: '',
            },
          }));
        }
      } else {
        let newvalue = formValues.countries;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          countries: newvalue,
        });
      }
    }
    if (name === 'states') {
      if (value === 'N/A') {
        if (event.target.checked) {
          locationExpCheck('states', true);
          setFormValues({ ...formValues, states: [] });
        } else {
          locationExpCheck('states', false);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            locationExplanation: {
              states: '',
            },
          }));
        }
      } else {
        let newvalue = formValues.states;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          states: newvalue,
        });
      }
    }
    if (name === 'cities') {
      if (value === 'N/A') {
        if (event.target.checked) {
          locationExpCheck('cities', true);
          setFormValues({ ...formValues, cities: [] });
        } else {
          locationExpCheck('cities', false);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            locationExplanation: {
              cities: '',
            },
          }));
        }
      } else {
        let newvalue = formValues.cities;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          cities: newvalue,
        });
      }
    }
    if (name === 'strategies') {
      let newvalue = formValues.strategies;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        strategies: newvalue,
      });
    }
    if (name === 'evidenceDescription') {
      setEvidenceOptionalCheck(false);
      let newvalue = formValues.evidenceDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        evidenceDescription: newvalue,
        evidenceOptional: '',
      });
    }
    if (name === 'studyDescription') {
      setStudyOptionalCheck(false);
      let newvalue = formValues.studyDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        studyDescription: newvalue,
        studyOptional: '',
      });
    }
    if (name === 'dataDescription') {
      setDataOptionalCheck(false);
      let newvalue = formValues.dataDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        dataDescription: newvalue,
        dataOptional: '',
      });
    }
    if (name === 'outcomeDescription') {
      let newvalue = formValues.outcomeDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        outcomeDescription: newvalue,
      });
    }
    if (name === 'researchApproach') {
      let newvalue = formValues.researchApproach;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else if (formValues.researchApproach.length < 3) {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        researchApproach: newvalue,
      });
    }
    if (name === 'environmentalOutputs') {
      let newvalue = formValues.environmentalOutputs;
      let newOutputValues = [...formValues.environmentalOutputValues];
      const label: any = vocab?.sccConversion?.filter((voc: any) => voc?._id === value);

      const index = formValues?.environmentalOutputs?.indexOf(value);
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
        newOutputValues?.splice(index, 1);
      } else {
        newvalue = [...newvalue, value];
        newOutputValues = [...newOutputValues, { quantity: 0, description: label[0]?.label }];
      }
      setFormValues({
        ...formValues,
        environmentalOutputs: newvalue,
        environmentalOutputValues: newOutputValues,
      });
    }
  };
  useEffect(() => {
    if (formValues && formValues?.environmentalOutputs?.length === 0) {
      setFormValues({
        ...formValues,
        environmentalOutputValues: [],
      });
    }
  }, [formValues?.environmentalOutputs]);

  useEffect(() => {
    if (formValues?.evidenceOptional?.length > 0) {
      setEvidenceOptionalCheck(true);
    } else {
      setEvidenceOptionalCheck(false);
    }
  }, [formValues?.evidenceOptional]);
  useEffect(() => {
    if (formValues?.studyOptional?.length > 0) {
      setStudyOptionalCheck(true);
    } else {
      setStudyOptionalCheck(false);
    }
  }, [formValues?.studyOptional]);
  useEffect(() => {
    if (formValues?.dataOptional?.length > 0) {
      setDataOptionalCheck(true);
    } else {
      setDataOptionalCheck(false);
    }
  }, [formValues?.dataOptional]);

  const saveEligible = async () => {
    if (id) {
      const data = {
        program: {
          applicationFormName,
          siAdmin,
          sio,
          active: edit,
          isStandard: !eligible,
          editing: program.editing,
          status: program.status,
          content: program.content,
          finance: program.finance,
          general: program.general,
          impactAndScope: program.impactAndScope,
          researchAndEvaluation: program.researchAndEvaluation,
          review: program.review,
          strategiesAndApproaches: program.strategiesAndApproaches,
          minimumFundRequired,
          internalNotes,
        },
        id,
      };
      const newProgram: ProgramData = (await dispatch(updateProgrambyAdminThunk(data)))
        .payload as ProgramData;
      if (newProgram?.status) {
        setEligible(!eligible);
        toast.success('Program updated Successfully');
      }
    }
  };

  const getLocationData = (field: string, data: any) => {
    const newFormat: string[] = [];
    data.map((items: any) => {
      if (field === 'country') {
        const n = items.lastIndexOf(';');
        const result = items.substring(n + 1);
        const countries = Object.entries(locations!.countries);
        countries.map((item) => {
          const allCountries = item[1].map((country: any) => country.name);
          if (allCountries.includes(result)) {
            newFormat.push(`${items}`);
          }
          return true;
        });
      }
      if (field === 'state') {
        const n = items.lastIndexOf(';');
        const result = items.substring(n + 1);
        const states = Object.entries(locations!.states);
        states.map((item) => {
          const allCountries = item[1].map((country: any) => country.name);
          if (allCountries.includes(result)) {
            newFormat.push(`${items}`);
          }
          return true;
        });
      }
      if (field === 'city') {
        const n = items.lastIndexOf(';');
        const result = items.substring(n + 1);
        const cities = Object.entries(locations!.cities);
        cities.map((item) => {
          const allCountries = item[1].map((country: any) => country.name);
          if (allCountries.includes(result)) {
            newFormat.push(`${items}`);
          }
          return true;
        });
      }
      return false;
    });
    return newFormat;
  };

  const handleLocationExpChange = (event: any, key: string) => {
    if (event.target.value.length !== 0) {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        locationExplanation: {
          [key]: event.target.value,
        },
      }));
    } else {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        locationExplanation: {
          [key]: '',
        },
      }));
      setLocaExpDetails((prevValues: any) => ({
        ...prevValues,
        [key]: false,
      }));
    }
  };

  useEffect(() => {
    if (program?.impactAndScope?.locationDetails?.locationExplanation?.regions) {
      locationExpCheck('regions', true);
    } else {
      locationExpCheck('regions', false);
    }
    if (program?.impactAndScope?.locationDetails?.locationExplanation?.countries) {
      locationExpCheck('countries', true);
    } else {
      locationExpCheck('countries', false);
    }
    if (program?.impactAndScope?.locationDetails?.locationExplanation?.states) {
      locationExpCheck('states', true);
    } else {
      locationExpCheck('states', false);
    }
    if (program?.impactAndScope?.locationDetails?.locationExplanation?.cities) {
      locationExpCheck('cities', true);
    } else {
      locationExpCheck('cities', false);
    }
  }, [program?.impactAndScope?.locationDetails?.locationExplanation]);

  const finalDataSubmit = async (researchFile: any, budgetFile: any, assets: any) => {
    if (id) {
      setLoading(true);
      const newResearch = formValues.researchApproach?.map((item: any) => {
        if (typeof item === 'string') {
          return { _id: item };
        }
        return item;
      });
      const data = {
        program: {
          applicationFormName,
          siAdmin,
          sio,
          active: edit,
          isStandard: eligible,
          editing: program.editing,
          status: program.status,
          content: {
            filmLocation: formValues.filmLocation,
            assets,
          },
          finance: {
            currency: formValues.currency,
            budget: formValues.budget,
            inKindContributions: formValues.inKindContributions,
            cashContributions: formValues.cashContributions,
            budgetFile,
          },
          general: {
            contact: {
              email: formValues.email,
              name: formValues.fistLastName,
              phone: formValues.phone,
            },
            programDescription: {
              description: formValues.description,
            },
            programName: {
              internalProgramName: formValues.internalName,
              platformProgramName: formValues.platformName,
            },
          },
          impactAndScope: {
            causes: formValues.causes,
            primaryImpact:
              typeof formValues.primaryImpact === 'string'
                ? [formValues.primaryImpact]
                : formValues.primaryImpact,
            secondaryImpacts: formValues.secondaryImpact,
            targetGroup: formValues.targetGroup,
            audienceAge: formValues.audienceAge,
            locationDetails: {
              countries: getLocationData('country', formValues.countries),
              regions: formValues.regions,
              states: getLocationData('state', formValues.states),
              cities: getLocationData('city', formValues.cities),
              additionalLocationDetails: formValues.additionalLocationDetails,
            },
          },
          researchAndEvaluation: {
            researchFile,
            evidenceDescription: {
              selected: formValues.evidenceDescription,
              optional: formValues?.evidenceOptional,
            },
            studyDescription: {
              selected: formValues.studyDescription,
              optional: formValues?.studyOptional,
            },
            dataDescription: {
              selected: formValues.dataDescription,
              optional: formValues?.dataOptional,
            },
            outcomeDescription:
              typeof formValues.outcomeDescription === 'string'
                ? [formValues.outcomeDescription]
                : formValues.outcomeDescription,
            strength: formValues.strength,
            environmentalOutputs: { selected: formValues.environmentalOutputs },
            researchApproach: newResearch,
            environmentalOutputValues: formValues.environmentalOutputValues,
          },
          review: program.review,
          strategiesAndApproaches: {
            strategies: formValues.strategies,
            activities: { value: formValues.activities },
            activitiesFrequency: formValues.activitiesFrequency,
            approachDuration: formValues.approachDuration,
            outputs: formValues.outputs,
            outcomes: formValues.outcomes,
            dataMeasurementType: formValues.dataMeasurementType,
            nonprofitPartners: formValues.nonprofitPartners,
            nonprofitPartnersDescription: formValues.nonprofitPartnersDescription,
          },
          minimumFundRequired,
          internalNotes,
        },
        id,
      };
      const newProgram: ProgramData = (await dispatch(updateProgrambyAdminThunk(data)))
        .payload as ProgramData;
      if (newProgram?.status) {
        toast.success('Program updated Successfully');
      }
      setLoading(false);
    }
  };

  const updateProgramDetails = async (response: any) => {
    if (response.data) {
      await setFormValues({
        ...formValues,
        researchFile: response.data.researchAndEvaluation.researchFile,
        budgetFile: response.data.finance.budgetFile,
        assets: response.data.content.assets,
      });
      await finalDataSubmit(
        response.data.researchAndEvaluation.researchFile,
        response.data.finance.budgetFile,
        response.data.content.assets,
      );
    }
  };

  const handleUploadFile = async (fileValue: any, filePath: string) => {
    setLoading(true);
    let response: any;
    const formData = new FormData();
    formData.append('file', fileValue);
    formData.append('path', filePath);
    if (id) {
      response = await uploadFile(id, formData);
      setLoading(false);
    }
    return response;
  };

  const handleFinalSubmit = async () => {
    if (formPreviewFiles.researchFile || formPreviewFiles.budgetFile || formPreviewFiles.assets) {
      const { researchFile, budgetFile, assets } = formPreviewFiles;
      let response: any;
      if (researchFile && budgetFile && assets) {
        response = await handleUploadFile(researchFile, 'researchAndEvaluation.researchFile');
        response = await handleUploadFile(budgetFile, 'finance.budgetFile');
        response = await handleUploadFile(assets, 'content.assets');
        updateProgramDetails(response);
      } else if (researchFile && budgetFile) {
        response = await handleUploadFile(researchFile, 'researchAndEvaluation.researchFile');
        response = await handleUploadFile(budgetFile, 'finance.budgetFile');
        updateProgramDetails(response);
      } else if (researchFile && assets) {
        response = await handleUploadFile(researchFile, 'researchAndEvaluation.researchFile');
        response = await handleUploadFile(assets, 'content.assets');
        updateProgramDetails(response);
      } else if (budgetFile && assets) {
        response = await handleUploadFile(budgetFile, 'finance.budgetFile');
        response = await handleUploadFile(assets, 'content.assets');
        updateProgramDetails(response);
      } else if (researchFile && !budgetFile && !assets) {
        response = await handleUploadFile(researchFile, 'researchAndEvaluation.researchFile');
        updateProgramDetails(response);
      } else if (budgetFile && !assets && !researchFile) {
        response = await handleUploadFile(budgetFile, 'finance.budgetFile');
        updateProgramDetails(response);
      } else if (assets && !researchFile && !budgetFile) {
        response = await handleUploadFile(assets, 'content.assets');
        updateProgramDetails(response);
      }
    } else {
      const { researchFile, budgetFile, assets } = formValues;
      finalDataSubmit(researchFile, budgetFile, assets);
    }
  };

  const handleLifetimeOutputChange = (
    field: string,
    index: number,
    value: any,
    lifetimeOutput: any,
  ) => {
    const newLifetimeOutputs = [...formValues.environmentalOutputValues!];
    const label: any = vocab?.sccConversion?.filter((item: any) => item?._id === lifetimeOutput);
    newLifetimeOutputs[index] = {
      ...newLifetimeOutputs[index],
      [field]: value,
      description: label && label[0]?.label,
    };
    if (Number(value) >= 0) {
      setFormValues((prevValues) => ({
        ...prevValues,
        environmentalOutputValues: newLifetimeOutputs,
      }));
    }
  };

  const isChecked1 = (value: string) => {
    let returnValue = false;
    formValues.researchApproach.map((item: any) => {
      if (item._id === value) returnValue = true;
      return true;
    });
    return returnValue;
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormValues({ ...formValues, [name]: value });
    if (name === 'sio') {
      setSio(value);
    }
    if (name === 'siAdmin') {
      setSiAdmin(value);
    }
    if (name === 'eligibility') {
      saveEligible();
    }
    if (name === 'industry') {
      setIndustryValue(value);
      const data: any = value;
      const reqParams = { ...requestParams, industry: data?.label };
      setRequestParams(reqParams);
      getAllCompanies(reqParams).then((res) => {
        const extractedCompanyData = res.data.results.map((item: any) => ({
          _id: item.id,
          name: item.name,
        }));
        setCompanyData(extractedCompanyData);
      });
    }
  };

  const handleBudgetSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target as HTMLInputElement;
    setCurrencyType(value);
    if (value !== 'Other') {
      setIsOtherSelected(false);
      setFormValues({ ...formValues, [name]: value });
    } else {
      setFormValues({ ...formValues, [name]: '' });
      setIsOtherSelected(true);
    }
  };

  const handleOtherCurrency = (event: any) => {
    setFormValues({ ...formValues, currency: _.toUpper(event.target.value) });
  };

  const createSelectedData = (
    proposalName: string,
    customer: string,
    client: string,
    fundingAmount: number,
  ) => ({ proposalName, customer, client, fundingAmount });

  const rows = program?.selectedByCompanies?.map((item) =>
    createSelectedData(item.proposalName, item.buyer, item.seller, item.fundingAmount),
  );

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (id) {
      if (program.status === 'approved') {
        const data = {
          program: {
            applicationFormName,
            siAdmin,
            sio,
            active: edit,
            isStandard: eligible,
            // editing: program.editing,
            status: program.status,
            content: program.content,
            finance: program.finance,
            general: program.general,
            impactAndScope: program.impactAndScope,
            researchAndEvaluation: program.researchAndEvaluation,
            review: program.review,
            strategiesAndApproaches: program.strategiesAndApproaches,
            minimumFundRequired,
            internalNotes,
          },
          id,
        };
        const newProgram: ProgramData = (await dispatch(updateProgrambyAdminThunk(data)))
          .payload as ProgramData;
        if (newProgram?.status) {
          toast.success('Program updated Successfully');
        }
      } else {
        const data = {
          applicationFormName,
          siAdmin,
          sio,
          editing: activeEdit,
          status: program.status,
        };
        const newProgram: ProgramData = (
          await dispatch(patchProgrambyAdminThunk({ program: data, id }))
        ).payload as ProgramData;
        if (newProgram?.status) {
          toast.success('Program updated Successfully');
        }
      }
    }
  };

  const submitCompanies = async (companies: any) => {
    const data: any = {
      program: {
        applicationFormName,
        siAdmin,
        sio,
        active: edit,
        isStandard: eligible,
        // editing: program.editing,
        status: program.status,
        content: program.content,
        finance: program.finance,
        general: program.general,
        impactAndScope: program.impactAndScope,
        researchAndEvaluation: program.researchAndEvaluation,
        review: program.review,
        strategiesAndApproaches: program.strategiesAndApproaches,
        minimumFundRequired,
        internalNotes,
        preferredByCompanies: {
          industries: [industryValue],
          companies,
        },
      },
      id,
    };
    const newProgram: ProgramData = (await dispatch(updateProgrambyAdminThunk(data)))
      .payload as ProgramData;
    if (newProgram?.status) {
      const prefered = newProgram?.preferredByCompanies.companies.map((item: any) =>
        createPreferedData(item._id, item.name, item.type, item.preferredOn),
      );
      await setIndustryValue({});
      await setCompany({});
      await setSelectedCompany(companies);
      await setRowsPrefrered(prefered);
      toast.success('Program updated Successfully');
    }
  };

  const removeCompany = async (_id: string) => {
    const companies = selectedCompany.filter((item: any) => item._id !== _id);
    await submitCompanies(companies);
  };

  const isChecked = (data: any, value: string) => {
    let returnValue = false;
    data.map((item: any) => {
      const n = item.lastIndexOf(';');
      const result = item.substring(n + 1);
      if (result === value) {
        returnValue = true;
      }
      return null;
    });
    return returnValue;
  };

  const handleOpenModal = (type: string) => {
    if (type === 'delete') {
      setOpenDeleteModal(true);
    } else {
      setOpenModal(true);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // const NUMERIC_DASH_REGEX = /^[0-9-]+$/;
    // if (name === 'phone') {
    //   if (NUMERIC_DASH_REGEX.test(value)) {
    //     setFormValues({ ...formValues, [name]: value });
    //   }
    // } else
    if (name === 'nonprofitPartners') {
      if (value === 'yes') {
        setFormValues({ ...formValues, [name]: true });
      } else {
        setFormValues({ ...formValues, [name]: false, nonprofitPartnersDescription: '' });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const renderCountriesOptions = (country: any, region: string) =>
    country[0][1]?.map((items: any) => (
      <Box
        key={items.name}
        className={isChecked(formValues.countries, items.name) ? 'active' : ''}
        sx={{
          border: '1px solid #E0E0E0',
          display: 'inline-block',
          width: 'fit-content',
          m: 1,
          borderRadius: '20px',
          p: 2,
        }}
      >
        <Typography variant="body1" display="inline">
          {items.name}
        </Typography>
        <Checkbox
          disabled={program.status !== 'approved' || locExpDetails.countries}
          name="countries"
          checked={isChecked(formValues.countries, items.name)}
          value={`${region};${items.name}`}
          onChange={handleChangeSelect}
        />
      </Box>
    ));

  const renderCitiesOptions = (country: any, regionCountry: any) =>
    country.length > 0 &&
    country[0][1]?.map((items: any) => (
      <Box
        key={items.name}
        className={isChecked(formValues.cities, items.name) ? 'active' : ''}
        sx={{
          border: '1px solid #E0E0E0',
          display: 'inline-block',
          width: 'fit-content',
          m: 1,
          borderRadius: '20px',
          p: 2,
        }}
      >
        <Typography variant="body1" display="inline">
          {items.name}
        </Typography>
        <Checkbox
          name="cities"
          checked={isChecked(formValues.cities, items.name)}
          value={`${regionCountry};${items.name}`}
          onChange={handleChangeSelect}
          disabled={locExpDetails.cities}
        />
      </Box>
    ));

  const renderstatesOptions = (state: any, regionCountry: any) =>
    state.length > 0 &&
    state[0][1]?.map((items: any) => (
      <Box
        key={items.name}
        className={isChecked(formValues.states, items.name) ? 'active' : ''}
        sx={{
          border: '1px solid #E0E0E0',
          display: 'inline-block',
          width: 'fit-content',
          m: 1,
          borderRadius: '20px',
          p: 2,
        }}
      >
        <Typography variant="body1" display="inline">
          {items.name}
        </Typography>
        <Checkbox
          name="states"
          checked={isChecked(formValues?.states, items.name)}
          value={`${regionCountry};${items.name}`}
          onChange={handleChangeSelect}
          disabled={locExpDetails.states}
        />
      </Box>
    ));

  const renderCountries = (region: string) => {
    const regions = Object.entries(locations!.countries);
    let countries: any = [];
    if (region !== 'N/A') {
      countries = regions.filter((item) => item[0] === region ?? item);
      return renderCountriesOptions(countries, region);
    }
    return true;
  };

  const renderStates = (country: string, regionCountry: any) => {
    const regions = Object.entries(locations!.states);
    let states: any = [];
    if (country === 'United States' || country === 'Canada' || country !== 'N/A') {
      states = regions.filter((item) => item[0] === country ?? item);
      return renderstatesOptions(states, regionCountry);
    }
    return true;
  };

  const renderCities = (country: string, regionCountry: any) => {
    const regions = Object.entries(locations!.cities);
    let cities: any = [];
    if (country !== 'United States' && country !== 'Canada' && country !== 'N/A') {
      cities = regions.filter((item) => item[0] === country ?? item);
      return renderCitiesOptions(cities, regionCountry);
    }
    return true;
  };

  const StatusBadge = (status: String) => {
    let data;
    switch (status) {
      case 'in_progress':
        data = (
          <>
            <HourglassEmptyIcon fontSize="small" sx={{ marginRight: '10px' }} />
            <Typography
              sx={{ display: 'inline-block', position: 'relative', textTransform: 'capitalize' }}
              variant="button"
            >
              In Progress {program.percentComplete}%
            </Typography>
          </>
        );

        break;
      case 'approved':
        data = (
          <>
            <CheckCircleOutlineIcon sx={{ marginRight: '10px' }} />
            <Typography
              sx={{ display: 'inline-block', position: 'relative', textTransform: 'capitalize' }}
              variant="button"
            >
              Approved
            </Typography>
          </>
        );

        break;
      case 'submitted':
        data = (
          <>
            <AccessTimeIcon sx={{ marginRight: '10px' }} />
            <Typography
              sx={{ display: 'inline-block', position: 'relative', textTransform: 'capitalize' }}
              variant="button"
            >
              Submitted
            </Typography>
          </>
        );
        break;
      default:
    }
    return data;
  };

  const handleAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSendForm = async () => {
    const data = {
      programFormLink: `${instance?.settings?.portalUrl}/program-form/${id}`,
    };
    if (id) sendEmail(id, data).then(() => setOpenSuccessModal(true));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenDeleteModal(false);
    setOpenApprovedModal('');
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    navigate('/app/programs');
  };

  const handleDeleteProgram = () => {
    if (id) dispatch(removeProgram(id));
    toast.success('Program deleted Successfully');
    navigate('/app/programs');
  };

  const handleApprovedAction = async (action: string) => {
    if (id) {
      const response = await programAction(id, action.toLocaleLowerCase());
      if (response) {
        if (action === 'Rejected') {
          navigate('/app/programs');
          toast.success('Program Rejected Successfully');
        } else if (action === 'Archived') {
          navigate('/app/programs');
          toast.success('Program Archived Successfully');
        } else if (action === 'Approved') {
          handleCloseModal();
          dispatch(fetchProgram(id));
          toast.success(`Program form ${action.toLocaleLowerCase()} successfully`);
        } else {
          dispatch(fetchProgram(id));
          handleCloseModal();
          navigate('/app/programs');
          toast.success(`Program ${action.toLocaleLowerCase()} successfully`);
        }
      }
    }
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    const { name } = e.target;
    if (name === 'assets' || name === 'budgetFile' || name === 'researchFile') {
      setformPreviewFiles({ ...files, [name]: fileList[0] });
    } else {
      setFiles(fileList[0]);
    }
  };

  const getFieldValue = (indexNumber: number, field: string) => {
    let value;
    formValues?.environmentalOutputValues?.map((item, index) => {
      if (index === indexNumber) {
        if (field === 'quantity') {
          value = item.quantity;
        }
        if (field === 'description') {
          value = item.description;
        }
      }
      return 1;
    });
    return value;
  };

  const uploadFileData = async (name: any) => {
    const formData = new FormData();
    if (!files) return;
    formData.append('file', files);
    if (name === 'imageLandscape' || name === 'imagePortrait' || name === 'previewImage') {
      formData.append('path', name);
    } else {
      formData.append('path', `deliverables.${name}`);
    }
    formData.append('is_public', 'true');
    if (id) {
      const response = await uploadFile(id, formData);
      if (response.data) {
        dispatch(fetchProgram(id));
        toast.success('Upload Successfull');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const renderButtons = () => {
    let buttons;
    if (program.status === 'submitted') {
      buttons = (
        <>
          <Button
            onClick={() => setOpenApprovedModal('Archived')}
            sx={{ ml: 3 }}
            variant="outlined"
            size="large"
            color="primary"
          >
            Archive
          </Button>
          <Button
            onClick={() => setOpenApprovedModal('Rejected')}
            sx={{ ml: 3 }}
            variant="outlined"
            size="large"
            color="error"
          >
            Reject
          </Button>
          <Button
            onClick={() => setOpenApprovedModal('Approved')}
            sx={{ mx: 3 }}
            variant="contained"
            size="large"
            color="primary"
          >
            Approve
          </Button>
        </>
      );
    } else if (isCreate) {
      buttons = (
        <>
          <Button
            onClick={() => handleOpenModal('cancel')}
            sx={{ ml: 3 }}
            variant="outlined"
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSendForm}
            sx={{ mx: 3 }}
            variant="contained"
            size="large"
            color="primary"
          >
            Send Form
          </Button>
        </>
      );
    } else {
      buttons = (
        <>
          <Button
            onClick={() => handleOpenModal('delete')}
            sx={{ float: 'right', mx: 3 }}
            variant="outlined"
            size="large"
            color="error"
          >
            Delete Program Form
          </Button>
          {program.status === 'approved' && (
            <LoadingButton
              loading={loading}
              onClick={handleFinalSubmit}
              sx={{ ...commonTextStyle.buttonStyle, mx: 3 }}
              variant="outlined"
              size="large"
              color="primary"
              disabled={!sio || !applicationFormName}
            >
              Submit Updated Details
            </LoadingButton>
          )}
        </>
      );
    }

    return buttons;
  };

  return (
    <>
      <Modal
        open={openModal || openDeleteModal}
        onClose={handleCloseModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                bgcolor: `${openDeleteModal ? '#FDE3E3' : 'rgba(0,95,234,0.2)'}`,
                mb: 2,
                p: 2,
                borderRadius: '20px 20px 0 0',
              }}
            >
              <Typography
                sx={commonTextStyle.heading}
                color={openDeleteModal ? 'error' : 'primary'}
              >
                {openDeleteModal ? 'Delete Confirmation' : 'Cancel Confirmation'}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ p: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Are you sure you want to {openDeleteModal ? 'delete this' : 'cancel'}?
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {openDeleteModal
                  ? 'Deleting this record cannot be undone and all associated data will be permanently removed. Please confirm that you wish to proceed with the deletion.'
                  : 'Cancelling this process will redirect you to SIO Organizations Dashboard Page. Any unsaved data record will be lost permanently.'}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ p: 2, borderTop: '1px solid #E6E6E6' }}>
              {openDeleteModal ? (
                <>
                  <Button
                    onClick={handleDeleteProgram}
                    sx={{ float: 'right', ml: 3 }}
                    variant="contained"
                    size="large"
                    color="error"
                  >
                    Delete Program Form
                  </Button>
                  <Button
                    onClick={handleCloseModal}
                    sx={{ float: 'right', ml: 3 }}
                    variant={openDeleteModal ? 'outlined' : 'contained'}
                    size="large"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={handleCloseModal}
                    sx={{ float: 'right', ml: 3 }}
                    variant="outlined"
                    size="large"
                    color="primary"
                  >
                    Stay
                  </Button>
                  <Button
                    // onClick={handleCancelSio}
                    sx={{ float: 'right', ml: 3 }}
                    variant={openDeleteModal ? 'outlined' : 'contained'}
                    size="large"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openSuccessModal}
        onClose={handleCloseSuccessModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...successStyle }}>
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <CloseIcon
                onClick={handleCloseSuccessModal}
                fontSize="medium"
                sx={{ position: 'absolute', right: '20px', top: '20px' }}
              />
              <CheckCircleOutlineIcon fontSize="large" color="success" />
              <Typography sx={{ ...commonTextStyle.heading, mb: 3 }}>
                Submission Form Shared Successfully!
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                An email with below form link has been sent to your onboarded SIO Users
                successfully!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ border: '1px solid #c4bebe', borderRadius: '16px', padding: '20px' }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={{ ...commonTextStyle.labelStyle, mb: 1 }}>
                      Form Link
                      <Tooltip title="Click to copy URL">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      disabled
                      placeholder="No data yet"
                      fullWidth
                      value={`${instance?.settings?.portalUrl}/program-form/${id}`}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
                    <CopyToClipboard
                      text={`${instance?.settings?.portalUrl}/program-form/${id}`}
                      onCopy={() => toast.success('URL Copied Successfully')}
                    >
                      <Button variant="text" color="success">
                        <FileCopyIcon fontSize="small" color="success" /> copy to clipboard
                      </Button>
                    </CopyToClipboard>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* approve, archive and reject modal */}
      <Modal
        open={openApprovedModal !== ''}
        onClose={handleCloseModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                bgcolor: `${openApprovedModal === 'Rejected' ? '#FDE3E3' : 'rgba(0,95,234,0.2)'}`,
                mb: 2,
                p: 2,
                borderRadius: '20px 20px 0 0',
              }}
            >
              <Typography
                sx={commonTextStyle.heading}
                color={openApprovedModal === 'Rejected' ? 'error' : 'primary'}
              >
                {`${openApprovedModal} Confirmation`}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ p: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Are you sure you want to {openApprovedModal === 'Approved' && 'approve'}
                {openApprovedModal === 'Archived' && 'archive'}
                {openApprovedModal === 'Rejected' && 'reject'} &quot;
                {program.applicationFormName}&quot; Application?
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="right" sx={{ p: 2, borderTop: '1px solid #E6E6E6' }}>
              <Button
                onClick={handleCloseModal}
                sx={{ ml: 3 }}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleApprovedAction(openApprovedModal)}
                sx={{ ml: 3 }}
                variant="contained"
                size="large"
                color={openApprovedModal === 'Rejected' ? 'error' : 'primary'}
              >
                {openApprovedModal}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Box sx={{ maxWidth: '100%', mx: 'auto', mb: 2, p: 2, borderRadius: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <span
              style={{
                fontSize: fonts.fontSize24,
                fontWeight: fonts.fontWeight700,
                color: colors.textPrimary,
              }}
            >
              {applicationFormName}
            </span>
            <div
              style={{
                ...(program.status === 'approved' && {
                  backgroundColor: '#c3fae8',
                  color: '#099268',
                  border: '1px solid #099268',
                }),
                ...(program.status === 'submitted' && {
                  backgroundColor: '#ffec99',
                  color: '#f08c00',
                  border: '1px solid #f08c00',
                }),
                ...(program.status === 'in_progress' && {
                  backgroundColor: '#d0ebff',
                  color: '#1864ab',
                  border: '1px solid #313B72',
                }),
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                height: '27px',
                width: '197px',
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight400,
                padding: '6px 15px 6px 15px',
                marginLeft: '20px',
                justifyContent: 'center',
              }}
            >
              {StatusBadge(program.status)}
            </div>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* {(program.status === 'approved' || program.status === 'submitted') && ( */}
            <Link
              to={`/program-preview/${id}?sio_funding_amt=${
                minimumFundRequired === undefined || minimumFundRequired === 0
                  ? 50000
                  : minimumFundRequired
              }`}
              target="_blank"
              style={{
                display: 'flex',
                alignItems: 'baseline',
                marginRight: '30px',
                textDecoration: 'none',
              }}
            >
              <img width="20px" height="14px" src={EyeIcon} alt="eye icon" />
              <Typography
                sx={{ color: '#005FEA', fontSize: '18px', fontWeight: 400, paddingLeft: '5px' }}
              >
                Program Preview
              </Typography>
            </Link>
            {/* )} */}
            <Button
              variant="contained"
              onClick={() => navigate('/app/programs')}
              sx={{
                ...commonTextStyle.buttonStyle,
                backgroundColor: colors.primary,
                color: colors.secondary,
                marginLeft: 'auto',
              }}
            >
              Back
            </Button>
          </Box>
        </Box>
        <Box boxShadow={3} sx={{ borderRadius: '20px', bgcolor: 'white' }}>
          <Grid
            container
            spacing={2}
            sx={{
              width: '100%',
              margin: 0,
              border: '1px solid #f1f3f5',
              borderRadius: '20px',
              p: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: fonts.fontSize24,
                  fntWeight: fonts.fontWeight600,
                  color: colors.textPrimary,
                }}
              >
                Overview
              </Typography>
            </Grid>
            <Grid
              container
              spacing={3}
              sx={{
                width: '100%',
                margin: 0,
                alignItems: 'flex-end',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {program?.status !== 'approved' ? (
                <>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      Submission Form Name{' '}
                      <Tooltip title="Enter Submission Form Name">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="applicationFormName"
                      placeholder="Enter The Submission Form Name"
                      fullWidth
                      value={applicationFormName}
                      onChange={handleInputChange}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      SIO Name{' '}
                      <Tooltip title="Select SIO Name">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <FormControl fullWidth>
                      <Autocomplete
                        clearOnBlur
                        value={sioValue}
                        options={sioNames}
                        fullWidth
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            height: '41px',
                            padding: 0,
                          },
                          '& input::placeholder': {
                            fontSize: fonts.fontsWeight14,
                            fontWeight: fonts.fontWeight400,
                          },
                        }}
                        onChange={(e, newValue) => {
                          handleSioInput(e, newValue, 'sio');
                        }}
                        onInputChange={(event: any, newInputvalue) => {
                          filterWithString(newInputvalue);
                        }}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          // Regular option
                          return option.publicOrganizationName;
                        }}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.publicOrganizationName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Search SIO Name"
                            variant="outlined"
                            {...params}
                            sx={commonTextStyle.textFieldStyle}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      ScaleWith Partnership Manager{' '}
                      <Tooltip title="ScaleWith Partnership Manager">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        name="siAdmin"
                        labelId="manager-label"
                        placeholder="ScaleWith Partnership Manager"
                        value={siAdmin}
                        onChange={handleSelectChange}
                        variant="outlined"
                        sx={{
                          ...commonTextStyle.textFieldStyle,
                          '& .MuiSelect-select': {
                            fontSize: fonts.fontSize14,
                            paddingTop: '27px',
                            height: '100%',
                          },
                        }}
                      >
                        <MenuItem selected value="" disabled>
                          <em>ScaleWith Partnership Manager</em>
                        </MenuItem>
                        {selectOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      Submission Form URL{' '}
                      <Tooltip title="Submission Form URL">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="applicationFormName"
                      placeholder="Enter The Submission Form Name"
                      fullWidth
                      disabled
                      // onClick={() => {
                      //   setCopyText(`${instance?.settings?.portalUrl}/program-form/${id}`);
                      //   navigator.clipboard.writeText(copyText);
                      // }}
                      value={`${instance?.settings?.portalUrl}/program-form/${id}`}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CopyToClipboard
                              text={`${instance?.settings?.portalUrl}/program-form/${id}`}
                              onCopy={() => toast.success('URL Copied Successfully')}
                            >
                              <FileCopyIcon />
                            </CopyToClipboard>
                          </InputAdornment>
                        ),
                      }}
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      Allow Editing{' '}
                      <Tooltip title="Enable editing">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <Switch
                      sx={{ mb: 2 }}
                      checked={activeEdit}
                      onClick={() => setActiveEdit(!activeEdit)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '2rem' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={!sio || !applicationFormName}
                      sx={{
                        ...commonTextStyle.buttonStyle,
                        backgroundColor: colors.primary,
                        color: colors.secondary,
                        ':hover': { backgroundColor: colors.primary, color: colors.secondary },
                      }}
                    >
                      Update
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      Submission Form Name{' '}
                      <Tooltip title="Enter Submission Form Name">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="applicationFormName"
                      placeholder="Enter The Submission Form Name"
                      fullWidth
                      value={applicationFormName}
                      onChange={handleInputChange}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      SIO Name{' '}
                      <Tooltip title="Select SIO Name">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <FormControl fullWidth margin="normal">
                      <Autocomplete
                        clearOnBlur
                        value={sioValue}
                        options={sioNames}
                        fullWidth
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            height: '50px',
                            padding: 0,
                          },
                          '& input::placeholder': {
                            fontSize: '1rem',
                          },
                        }}
                        onChange={(e, newValue) => {
                          handleSioInput(e, newValue, 'sio');
                        }}
                        onInputChange={(event: any, newInputvalue) => {
                          filterWithString(newInputvalue);
                        }}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          // Regular option
                          return option.publicOrganizationName;
                        }}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.publicOrganizationName}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Search SIO Name"
                            variant="outlined"
                            {...params}
                            sx={commonTextStyle.textFieldStyle}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      ScaleWith Partnership Manager{' '}
                      <Tooltip title="ScaleWith Partnership Manager">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <FormControl fullWidth margin="normal">
                      <Select
                        name="siAdmin"
                        labelId="manager-label"
                        placeholder="ScaleWith Partnership Manager"
                        value={siAdmin}
                        onChange={handleSelectChange}
                        variant="outlined"
                        sx={{
                          ...commonTextStyle.textFieldStyle,
                          '& .MuiSelect-select': {
                            fontSize: fonts.fontSize14,
                            paddingTop: '24px',
                            height: '100%',
                          },
                        }}
                      >
                        <MenuItem selected value="" disabled>
                          <em>ScaleWith Partnership Manager</em>
                        </MenuItem>
                        {selectOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      Program Strength{' '}
                      <Tooltip title="Program Strength">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="programStrengthRating"
                      placeholder="Enter Program strength"
                      fullWidth
                      disabled
                      value={programStrengthRating}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} textAlign="left" sx={{ marginBottom: '1rem' }}>
                    <Typography sx={{ ...commonTextStyle.labelStyle }}>
                      Active{' '}
                      <Tooltip title="Enable editing">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <Switch sx={{ mb: 2 }} checked={edit} onClick={() => setEdit(!edit)} />
                  </Grid>
                  <Grid item xs={12} sm={4} textAlign="left" sx={{ marginBottom: '1rem' }}>
                    <Typography sx={{ ...commonTextStyle.labelStyle }}>
                      Publish if it’s below the threshold{' '}
                      <Tooltip title="Enable editing">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <Switch
                      disabled
                      sx={{ mb: 2 }}
                      checked={threshHoldEdit}
                      onClick={() => setThreshHoldEdit(!threshHoldEdit)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      Impact Multiple{' '}
                      <Tooltip title="Enter Impact Multiple">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="impactMultiple"
                      placeholder="Enter The Impact Multiple"
                      fullWidth
                      disabled
                      // value={applicationFormName}
                      // onChange={handleInputChange}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      Impact Multiple Score{' '}
                      <Tooltip title="Enter Impact Multiple Score">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="impactMultiple"
                      placeholder="Enter The Impact Multiple Score"
                      fullWidth
                      disabled
                      // value={applicationFormName}
                      // onChange={handleInputChange}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} textAlign="left" sx={{ marginBottom: '1rem' }}>
                    <Typography sx={{ ...commonTextStyle.labelStyle }}>
                      Impact Multiple Visibility{' '}
                      <Tooltip title="Enable editing">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <Switch
                      disabled
                      sx={{ mb: 2 }}
                      checked={visibilityEdit}
                      onClick={() => setVisibilityEdit(!visibilityEdit)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      Submission Form URL{' '}
                      <Tooltip title="Submission Form URL">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="applicationFormName"
                      placeholder="Enter The Submission Form Name"
                      fullWidth
                      disabled
                      margin="normal"
                      value={`${instance?.settings?.portalUrl}/program-form/${id}`}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CopyToClipboard
                              text={`${instance?.settings?.portalUrl}/program-form/${id}`}
                              onCopy={() => toast.success('URL Copied Successfully')}
                            >
                              <FileCopyIcon />
                            </CopyToClipboard>
                          </InputAdornment>
                        ),
                      }}
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      Minimum fund required{' '}
                      <Tooltip title="Enter Minimum fund required">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="minimumFundRequired"
                      placeholder="Enter The Minimum fund required"
                      fullWidth
                      type="number"
                      value={minimumFundRequired}
                      onChange={(e) => setMinimumFundRequired(Number(e.target.value))}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      Internal Notes{' '}
                      <Tooltip title="Enter Internal Notes">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="internalNotes"
                      placeholder="Enter The Internal Notes"
                      fullWidth
                      value={internalNotes}
                      onChange={(e) => setInternalNotes(e.target.value)}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>

                  <Grid item xs={12} textAlign="right" sx={{ marginBottom: '2rem' }}>
                    <Button
                      variant="contained"
                      sx={{
                        ...commonTextStyle.buttonStyle,
                        backgroundColor: colors.primary,
                        color: colors.secondary,
                        ':hover': { backgroundColor: colors.primary, color: colors.secondary },
                      }}
                      onClick={handleSubmit}
                      disabled={!sio || !applicationFormName}
                    >
                      Update
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Box>

        {/* Eligibility Box */}
        {program.status === 'approved' && (
          <Box boxShadow={3} sx={{ bgcolor: 'white', borderRadius: '20px' }}>
            <Grid
              container
              spacing={2}
              sx={{
                width: '100%',
                margin: 0,
                borderRadius: '20px',
                p: 3,
                marginTop: '2rem',
              }}
            >
              <Grid item xs={12}>
                <Typography sx={commonTextStyle.mainHeading}>
                  Select Program Eligibility{' '}
                  <Typography
                    sx={{
                      display: 'inline-block',
                      width: '45vw',
                      borderRadius: '20px',
                      py: 1,
                      px: 2,
                      bgcolor: '#E9F5FF',
                    }}
                    variant="body1"
                  >
                    By enabling this checkbox, customers will have the option to choose this program
                    as one of the four recommended standard programs.
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={commonTextStyle.subHeading}>
                  To declare this program as a standard program, please mark the checkbox.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox name="eligibility" onChange={handleSelectChange} checked={eligible} />
                  }
                  label="Standard Program"
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {program.status === 'approved' && (
          <Accordion
            expanded={expanded === 'calculator'}
            onChange={handleAccordion('calculator')}
            sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={
                expanded === 'calculator' ? (
                  <RemoveIcon color="success" />
                ) : (
                  <AddIcon color="success" />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={commonTextStyle.mainHeading} gutterBottom>
                Impact Multiple Calculator
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ ...commonTextStyle.labelStyle, mt: 0 }}>
                    Impact Multiple
                  </Typography>
                  <TextField
                    // name="imagePortrait"
                    // type='file'
                    disabled
                    fullWidth
                    placeholder="no data found"
                    onChange={handleUpload}
                    variant="outlined"
                    sx={commonTextStyle.textFieldStyle}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Button
                    sx={{ ...commonTextStyle.buttonStyle, mt: '28px' }}
                    size="large"
                    variant="outlined"
                  >
                    <Typography>Recalculate</Typography>
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                </Grid>
                <Box
                  sx={{
                    width: '100%',
                    m: 2,
                    borderRadius: '20px',
                    border: '1px solid #E0E0E0',
                    p: 2,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.labelStyle}>Strategy and approach</Typography>
                      <TextField
                        // name="imagePortrait"
                        // type='file'
                        disabled
                        fullWidth
                        placeholder="no data found"
                        onChange={handleUpload}
                        variant="outlined"
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography sx={commonTextStyle.labelStyle}>Beneficiaries Served</Typography>
                      <TextField
                        // name="imagePortrait"
                        // type='file'
                        disabled
                        fullWidth
                        placeholder="Enter Beneficiaries Served"
                        onChange={handleUpload}
                        variant="outlined"
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography sx={commonTextStyle.labelStyle}>Evidence Score</Typography>
                      <TextField
                        // name="imagePortrait"
                        // type='file'
                        disabled
                        fullWidth
                        placeholder="Select Evidence Score"
                        onChange={handleUpload}
                        variant="outlined"
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {program.status === 'approved' && (
          <Accordion
            expanded={expanded === 'prefferedBy'}
            onChange={handleAccordion('prefferedBy')}
            sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={
                expanded === 'prefferedBy' ? (
                  <RemoveIcon color="success" />
                ) : (
                  <AddIcon color="success" />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={commonTextStyle.mainHeading} gutterBottom>
                Selected By
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ minHeight: '400px', height: getGenericHeight(300), border: 'none' }}>
                {/* <IsolatedGrid
                model={grid}
                columns={columnSelected}
                onChange={(x) => dispatch(setProgramsGridModel(x))}
              /> */}
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Proposal Name</StyledTableCell>
                        <StyledTableCell align="right">Customer</StyledTableCell>
                        <StyledTableCell align="right">Client</StyledTableCell>
                        <StyledTableCell align="right">Funding Amount</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows?.map((row) => (
                        <StyledTableRow key={row.proposalName}>
                          <StyledTableCell component="th" scope="row">
                            {row.proposalName}
                          </StyledTableCell>
                          <StyledTableCell align="right">{row.customer}</StyledTableCell>
                          <StyledTableCell align="right">{row.client}</StyledTableCell>
                          <StyledTableCell align="right">{row.fundingAmount}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        {program.status === 'approved' && (
          <Accordion
            expanded={expanded === 'selectedBy'}
            onChange={handleAccordion('selectedBy')}
            sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={
                expanded === 'selectedBy' ? (
                  <RemoveIcon color="success" />
                ) : (
                  <AddIcon color="success" />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={commonTextStyle.mainHeading} gutterBottom>
                Preferred By
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <Typography sx={commonTextStyle.labelStyle}>Industry</Typography>
                  <FormControl sx={{ marginTop: '0px' }} fullWidth margin="normal">
                    <Select
                      value={industryValue}
                      name="industry"
                      placeholder="Select industry"
                      onChange={handleSelectChange}
                      variant="outlined"
                      sx={{
                        height: '50px',
                        '& .MuiSelect-select': {
                          fontSize: fonts.fontSize14,
                          paddingTop: '35px',
                          height: '100%',
                        },
                      }}
                    >
                      <MenuItem selected value="" disabled>
                        <em>Select Industry</em>
                      </MenuItem>
                      {selectIndustryOptions}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={5}>
                  <Typography sx={commonTextStyle.labelStyle}>Company</Typography>
                  <Autocomplete
                    fullWidth
                    onChange={(__, newValue: any) => {
                      setSelectedCompany([...selectedCompany, newValue]);
                      setCompany(newValue);
                    }}
                    value={company}
                    options={companyData}
                    getOptionLabel={(option) => option.name || ''}
                    sx={{ width: 300, padding: `1px 5px !important` }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        sx={commonTextStyle.textFieldStyle}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={4}>
                  <Button
                    sx={{ mt: 2 }}
                    disabled={
                      program.status !== 'approved' ||
                      _.isEmpty(company) ||
                      _.isEmpty(industryValue)
                    }
                    onClick={() => submitCompanies(selectedCompany)}
                    variant="outlined"
                    size="large"
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              <Box sx={{ minHeight: '400px', height: getGenericHeight(300), border: 'none' }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell align="right">Type</StyledTableCell>
                        <StyledTableCell align="right">Preferred On</StyledTableCell>
                        <StyledTableCell align="right">Actions</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsPrefrered?.map((row: any) => (
                        <StyledTableRow key={row?.name}>
                          <StyledTableCell component="th" scope="row">
                            {row?.name}
                          </StyledTableCell>
                          <StyledTableCell align="right">{row?.type}</StyledTableCell>
                          <StyledTableCell align="right">{row?.preferredOn}</StyledTableCell>
                          <StyledTableCell align="right">
                            <Button
                              variant="outlined"
                              size="large"
                              onClick={() => removeCompany(row?._id)}
                            >
                              Remove
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        {program.status === 'approved' && (
          <Accordion
            expanded={expanded === 'analysis'}
            onChange={handleAccordion('analysis')}
            sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
          >
            <AccordionSummary
              expandIcon={
                expanded === 'analysis' ? (
                  <RemoveIcon color="success" />
                ) : (
                  <AddIcon color="success" />
                )
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={commonTextStyle.mainHeading} gutterBottom>
                Analysis
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {' '}
                  Program Detail Page assets
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>
                  {' '}
                  Matchmaking Images
                </Typography>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                  {' '}
                  Potrait Image
                </Typography>
                <Grid container spacing={2}>
                  {program?.imagePortrait && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program.imagePortrait}
                        alt="imagePortrait"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="imagePortrait"
                      type="file"
                      placeholder="Select portrait image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={commonTextStyle.buttonStyle}
                      onClick={() => uploadFileData('imagePortrait')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>Upload Portrait Image</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                  {' '}
                  Landscape Image
                </Typography>
                <Grid container spacing={2}>
                  {program?.imageLandscape && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program.imageLandscape}
                        alt="potraitImage"
                        style={{
                          width: '100%',
                          height: '100px',
                          border: '1px solid #E0E0E0',
                          marginRight: '20px',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item sm={3}>
                    <TextField
                      name="imageLandscape"
                      type="file"
                      placeholder="Select landscape image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <Button
                      sx={commonTextStyle.buttonStyle}
                      onClick={() => uploadFileData('imageLandscape')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>Upload Landscape Image</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                  {' '}
                  Preview Image
                </Typography>
                <Grid container spacing={2}>
                  {program?.previewImage && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program.previewImage}
                        alt="potraitImage"
                        style={{
                          width: '100%',
                          height: '100px',
                          border: '1px solid #E0E0E0',
                          marginRight: '20px',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item sm={3}>
                    <TextField
                      name="previewImage"
                      type="file"
                      placeholder="Select preview image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <Button
                      sx={commonTextStyle.buttonStyle}
                      onClick={() => uploadFileData('previewImage')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>Upload Preview Image</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Grid container spacing={2}>
                  <Grid item sm={5}>
                    <Typography sx={commonTextStyle.labelStyle}>Preview Vimeo Video</Typography>
                    <TextField
                      name="vimeoVideo"
                      disabled
                      fullWidth
                      placeholder="Enter Vimeo Video"
                      margin="none"
                      value={program?.previewVimeoId}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item sm={5}>
                    <Typography sx={commonTextStyle.labelStyle}>Deliverables</Typography>
                    <TextField
                      name="deliverables"
                      placeholder="Enter Vimeo Video"
                      margin="none"
                      fullWidth
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <Button
                      sx={{ ...commonTextStyle.buttonStyle, mt: 3 }}
                      variant="outlined"
                      size="large"
                      color="primary"
                    >
                      <Typography> Generate</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {' '}
                  Deliverables
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>
                  {' '}
                  Attachments
                </Typography>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}> Long Video</Typography>
                <Grid container spacing={2}>
                  {program?.deliverables?.longVideo && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program?.deliverables?.longVideo}
                        alt="longVideo"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="longVideo"
                      type="file"
                      placeholder="Select portrait image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={{ ...commonTextStyle.buttonStyle, margin: '0px 2px' }}
                      onClick={() => uploadFileData('longVideo')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>Upload Long Video</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}> Short Video</Typography>
                <Grid container spacing={2}>
                  {program?.deliverables?.shortVideo && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program?.deliverables?.shortVideo}
                        alt="shortVideo"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="shortVideo"
                      type="file"
                      placeholder="Select portrait image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={{ ...commonTextStyle.buttonStyle, margin: '0px 2px' }}
                      onClick={() => uploadFileData('shortVideo')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>Upload Short video</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                  {' '}
                  Photo Gallery
                </Typography>
                <Grid container spacing={2}>
                  {program?.deliverables?.photoGallery && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program?.deliverables?.photoGallery}
                        alt="photoGallery"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="photoGallery"
                      type="file"
                      placeholder="Select portrait image"
                      margin="normal"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={{ ...commonTextStyle.buttonStyle, margin: '0px 2px' }}
                      onClick={() => uploadFileData('photoGallery')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>Upload Photo gallery</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                  {' '}
                  Invester Communications
                </Typography>
                <Grid container spacing={2}>
                  {program?.deliverables?.investorCommunications && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program?.deliverables?.investorCommunications}
                        alt="investorCommunications"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="investorCommunications"
                      type="file"
                      placeholder="Select portrait image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={commonTextStyle.buttonStyle}
                      onClick={() => uploadFileData('investorCommunications')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>
                        Upload Invester Communications
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                  {' '}
                  Press Release
                </Typography>
                <Grid container spacing={2}>
                  {program?.deliverables?.pressRelease && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program?.deliverables?.pressRelease}
                        alt="pressRelease"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="pressRelease"
                      type="file"
                      placeholder="Select portrait image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={commonTextStyle.buttonStyle}
                      onClick={() => uploadFileData('pressRelease')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>Upload Press Release</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                  {' '}
                  Sustainability Reporting
                </Typography>
                <Grid container spacing={2}>
                  {program?.deliverables?.sustainabilityReporting && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program?.deliverables?.sustainabilityReporting}
                        alt="sustainabilityReporting"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="sustainabilityReporting"
                      type="file"
                      placeholder="Select portrait image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={commonTextStyle.buttonStyle}
                      onClick={() => uploadFileData('sustainabilityReporting')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>
                        Upload Sustainability Reporting
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                  {' '}
                  Employee Communications
                </Typography>
                <Grid container spacing={2}>
                  {program?.deliverables?.employeeCommunications && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program?.deliverables?.employeeCommunications}
                        alt="employeeCommunications"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="employeeCommunications"
                      type="file"
                      placeholder="Select portrait image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={commonTextStyle.buttonStyle}
                      onClick={() => uploadFileData('employeeCommunications')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>
                        Upload Employee Communications
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                  {' '}
                  Program Fact Sheet
                </Typography>
                <Grid container spacing={2}>
                  {program?.deliverables?.programFactSheet && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program?.deliverables?.programFactSheet}
                        alt="programFactSheet"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="programFactSheet"
                      type="file"
                      placeholder="Select portrait image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={commonTextStyle.buttonStyle}
                      onClick={() => uploadFileData('programFactSheet')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>
                        Upload Program Fact Sheet
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                  {' '}
                  Social Media Posts
                </Typography>
                <Grid container spacing={2}>
                  {program?.deliverables?.socialMediaPosts && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program?.deliverables?.socialMediaPosts}
                        alt="socialMediaPosts"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="socialMediaPosts"
                      type="file"
                      placeholder="Select portrait image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={commonTextStyle.buttonStyle}
                      onClick={() => uploadFileData('socialMediaPosts')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>
                        Upload Social Media Posts
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                  {' '}
                  Csr Report Highlight
                </Typography>
                <Grid container spacing={2}>
                  {program?.deliverables?.csrReportHighlight && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program?.deliverables?.csrReportHighlight}
                        alt="csrReportHighlight"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="csrReportHighlight"
                      type="file"
                      placeholder="Select portrait image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={commonTextStyle.buttonStyle}
                      onClick={() => uploadFileData('csrReportHighlight')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>
                        Upload Csr Report Highlight
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}> Infographic</Typography>
                <Grid container spacing={2}>
                  {program?.deliverables?.infographic && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program?.deliverables?.infographic}
                        alt="infographic"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="infographic"
                      type="file"
                      placeholder="Select portrait image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={commonTextStyle.buttonStyle}
                      onClick={() => uploadFileData('infographic')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>Upload Infographic</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}> Additional</Typography>
                <Grid container spacing={2}>
                  {program?.deliverables?.additional && (
                    <Grid item xs={12} sm={2}>
                      <img
                        src={program?.deliverables?.additional}
                        alt="additional"
                        style={{
                          width: '100%',
                          height: '100px',
                          marginRight: '20px',
                          border: '1px solid #E0E0E0',
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="additional"
                      type="file"
                      placeholder="Select portrait image"
                      onChange={handleUpload}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      sx={commonTextStyle.buttonStyle}
                      onClick={() => uploadFileData('additional')}
                      variant="outlined"
                      size="large"
                    >
                      <Typography sx={{ color: colors.primary }}>Upload Additional</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        <Box boxShadow={3} sx={{ bgcolor: 'white', borderRadius: '20px' }}>
          <Grid
            container
            spacing={2}
            sx={{
              width: '100%',
              margin: 0,
              borderRadius: '20px',
              p: 3,
              marginTop: '2rem',
            }}
          >
            <Grid item xs={12} sx={{ marginBottom: '2rem' }}>
              <Typography
                sx={{
                  fontWeight: fonts.fontWeight600,
                  color: colors.textColor,
                  fontSize: fonts.fontSize24,
                }}
              >
                Form Preview
              </Typography>
            </Grid>
            {program && (
              <Grid item xs={12}>
                {/* General information Accordion */}
                <Accordion
                  expanded={expanded === 'general'}
                  onChange={handleAccordion('general')}
                  sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === 'general' ? (
                        <Box sx={commonTextStyle.accordionButton}>
                          <RemoveIcon sx={{ color: colors.primary }} />
                        </Box>
                      ) : (
                        <Box sx={commonTextStyle.accordionButton}>
                          <AddIcon sx={{ color: colors.primary }} />
                        </Box>
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={9}>
                      <Typography sx={commonTextStyle.accordionStyle}>
                        General Information
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={commonTextStyle.accordionCount}>
                        {program?.progress?.general?.complete
                          ? program?.progress?.general?.complete
                          : 0}{' '}
                        of{' '}
                        {program?.progress?.general?.total ? program?.progress?.general?.total : 6}{' '}
                        Completed
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Who will be the main contact person for this program?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ ...commonTextStyle.labelStyle }}>
                          First and Last Name
                        </Typography>
                        <TextField
                          disabled={program.status !== 'approved'}
                          name="fistLastName"
                          placeholder="Enter your first name and last name"
                          fullWidth
                          value={formValues.fistLastName}
                          required
                          onChange={handleChange}
                          variant="outlined"
                          sx={commonTextStyle.textFieldStyle}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ ...commonTextStyle.labelStyle }}>
                          Email Address
                        </Typography>
                        <TextField
                          name="email"
                          placeholder="Enter your email address"
                          fullWidth
                          required
                          disabled={program.status !== 'approved'}
                          value={formValues.email}
                          onChange={handleChange}
                          variant="outlined"
                          type="email"
                          sx={commonTextStyle.textFieldStyle}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={{ ...commonTextStyle.labelStyle }}>Phone Number</Typography>
                        <TextField
                          name="phone"
                          placeholder="Enter phone number"
                          fullWidth
                          required
                          disabled={program.status !== 'approved'}
                          value={formValues.phone}
                          onChange={handleChange}
                          variant="outlined"
                          type="text"
                          error={
                            formValues.phone !== undefined &&
                            formValues.phone!.length > 0 &&
                            (formValues.phone![0] === '0' ||
                              formValues.phone![0] === '1' ||
                              (formValues.phone!.length < 10 && formValues.phone !== ''))
                          }
                          InputProps={{
                            inputComponent: CellPhoneInput as any,
                          }}
                          sx={commonTextStyle.textFieldStyle}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Please provide a name for this program.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ ...commonTextStyle.labelStyle }}>
                          Internal Program Name
                        </Typography>
                        <TextField
                          name="internalName"
                          placeholder="Enter internal program name"
                          fullWidth
                          value={formValues.internalName}
                          disabled={program.status !== 'approved'}
                          onChange={handleChange}
                          variant="outlined"
                          sx={commonTextStyle.textFieldStyle}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ ...commonTextStyle.labelStyle }}>
                          Platform Program name
                        </Typography>
                        <TextField
                          name="platformName"
                          placeholder="Enter your platform program name"
                          value={formValues.platformName}
                          fullWidth
                          onChange={handleChange}
                          disabled={program.status !== 'approved'}
                          variant="outlined"
                          sx={commonTextStyle.textFieldStyle}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Please provide a description of this program
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{ ...commonTextStyle.labelStyle }}>Description</Typography>
                        <TextField
                          name="description"
                          placeholder="Enter your program description"
                          fullWidth
                          onChange={handleChange}
                          margin="none"
                          disabled={program.status !== 'approved'}
                          rows={4}
                          multiline
                          value={formValues.description}
                          variant="outlined"
                          sx={commonTextStyle.textAreaStyle}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {/* Impact and scope Accordion */}
                <Accordion
                  expanded={expanded === 'impact'}
                  onChange={handleAccordion('impact')}
                  sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === 'impact' ? (
                        <Box sx={commonTextStyle.accordionButton}>
                          <RemoveIcon sx={{ color: colors.primary }} />
                        </Box>
                      ) : (
                        <Box sx={commonTextStyle.accordionButton}>
                          <AddIcon sx={{ color: colors.primary }} />
                        </Box>
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={9}>
                      <Typography sx={commonTextStyle.accordionStyle}>Impact and Scope</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={commonTextStyle.accordionCount}>
                        {program?.progress?.impactAndScope?.complete
                          ? program?.progress?.impactAndScope?.complete
                          : 0}{' '}
                        of{' '}
                        {program?.progress?.impactAndScope?.total
                          ? program?.progress?.impactAndScope?.total
                          : 5}{' '}
                        Completed
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Which cause areas best match this program&apos;s work?
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.causes.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={formValues.causes.includes(item._id) ? 'active' : ''}
                              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                            >
                              <Typography variant="body1" display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                disabled={program.status !== 'approved'}
                                onChange={handleChangeSelect}
                                name="causes"
                                checked={formValues.causes.includes(item._id)}
                                value={item._id}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          What is this program&apos;s primary targeted impact?
                        </Typography>
                      </Grid>
                      {vocab?.causes.map((item: any) => (
                        <Grid key={item._id} sx={{ my: 1 }} item xs={12}>
                          <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                            <Typography sx={commonTextStyle.heading}>{item.label}</Typography>
                            <br />
                            <Grid item xs={12}>
                              <FormControl>
                                <RadioGroup
                                  sx={{ display: 'inline-block' }}
                                  name="primaryImpact"
                                  value={formValues.primaryImpact}
                                  onChange={handleChange}
                                >
                                  {vocab?.impact.map((subitem: any) => {
                                    if (subitem.causeId === item._id) {
                                      return (
                                        <FormControlLabel
                                          sx={{
                                            p: 2,
                                            m: 1,
                                            border: '1px solid #E0E0E0',
                                            borderRadius: '20px',
                                          }}
                                          labelPlacement="start"
                                          key={subitem._id}
                                          value={subitem._id}
                                          control={
                                            <Radio disabled={program.status !== 'approved'} />
                                          }
                                          label={subitem.label}
                                        />
                                      );
                                    }
                                    return true;
                                  })}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Box>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          What are the secondary impacts of this program?
                        </Typography>
                      </Grid>
                      {vocab?.impact.map((subItem: any) => {
                        if (subItem?.label === 'N/A') {
                          return (
                            <Grid item>
                              <Box
                                className={
                                  formValues.secondaryImpact.includes(subItem?._id) ? 'active' : ''
                                }
                                sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                              >
                                <Typography variant="body1" display="inline">
                                  N/A
                                </Typography>
                                <Checkbox
                                  name="secondaryImpact"
                                  checked={formValues.secondaryImpact.includes(subItem?._id)}
                                  value={subItem?._id}
                                  onChange={(event) => handleCheck(event, 'secondaryImpact')}
                                />
                              </Box>
                            </Grid>
                          );
                        }
                        return true;
                      })}
                      {vocab?.causes.map((item: any) => (
                        <Grid key={item._id} sx={{ my: 1 }} item xs={12}>
                          <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                            <Typography sx={commonTextStyle.heading}>{item.label}</Typography>
                            <br />
                            {vocab?.impact.map((subItem: any) => {
                              const findNaObject: any = vocab?.impact?.find(
                                (obj: any) => obj.label === 'N/A',
                              );
                              if (subItem.causeId === item._id) {
                                return (
                                  <Box
                                    key={subItem._id}
                                    className={
                                      formValues.secondaryImpact.includes(subItem._id)
                                        ? 'active'
                                        : ''
                                    }
                                    sx={{
                                      border: '1px solid #E0E0E0',
                                      display: 'inline-block',
                                      width: 'fit-content',
                                      m: 1,
                                      borderRadius: '20px',
                                      p: 2,
                                    }}
                                  >
                                    <Typography variant="body1" display="inline">
                                      {subItem.label}
                                    </Typography>
                                    <Checkbox
                                      onChange={handleChangeSelect}
                                      name="secondaryImpact"
                                      checked={formValues.secondaryImpact.includes(subItem._id)}
                                      value={subItem._id}
                                      disabled={
                                        program.status !== 'approved' ||
                                        formValues?.secondaryImpact?.includes(findNaObject?._id)
                                      }
                                    />
                                  </Box>
                                );
                              }
                              return true;
                            })}
                          </Box>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Does this program specifically target any of the following groups of
                          people?
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.audience.attribute.map((item: any) => {
                          const findNaObject: any = vocab?.audience?.attribute?.find(
                            (obj: any) => obj.label === 'N/A',
                          );
                          return (
                            <Grid key={item._id} item>
                              <Box
                                className={
                                  formValues.targetGroup.includes(item._id) ? 'active' : ''
                                }
                                sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                              >
                                <Typography variant="body1" display="inline">
                                  {item.label}
                                </Typography>
                                <Checkbox
                                  disabled={
                                    program.status !== 'approved' ||
                                    (formValues?.targetGroup?.includes(findNaObject?._id) &&
                                      item.label !== 'N/A')
                                  }
                                  name="targetGroup"
                                  checked={formValues.targetGroup.includes(item._id)}
                                  value={item._id}
                                  onChange={(event) =>
                                    handleChangeSelect(event, item.label === 'N/A' && true)
                                  }
                                />
                              </Box>
                            </Grid>
                          );
                        })
                      }
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          What age groups does this program serve?
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.audience.age.map((item: any) => {
                          const findNaObject: any = vocab?.audience.age?.find(
                            (obj: any) => obj.label === 'N/A',
                          );
                          return (
                            <Grid key={item._id} item>
                              <Box
                                className={
                                  formValues.audienceAge.includes(item._id) ? 'active' : ''
                                }
                                sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                              >
                                <Typography variant="body1" display="inline">
                                  {item.label}
                                </Typography>
                                <Checkbox
                                  name="audienceAge"
                                  checked={formValues.audienceAge.includes(item._id)}
                                  value={item._id}
                                  disabled={
                                    program.status !== 'approved' ||
                                    (formValues?.audienceAge?.includes(findNaObject?._id) &&
                                      item.label !== 'N/A')
                                  }
                                  onChange={(event) =>
                                    handleChangeSelect(event, item.label === 'N/A' && true)
                                  }
                                />
                              </Box>
                            </Grid>
                          );
                        })
                      }
                      {/* {
                      vocab && <Grid item><Box className={formValues.audienceAge.includes('N/A') ? "active" : ""} sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                        <Typography variant='body1' display='inline'>N/A</Typography>
                        <Checkbox disabled={program.status !== 'approved'} name="audienceAge" checked={formValues.audienceAge.includes("N/A")} value="N/A" onChange={handleChangeSelect} />
                      </Box></Grid>
                    } */}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          In which specific regions does this program operate?
                        </Typography>
                      </Grid>
                      <Grid sx={{ my: 2 }} item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} textAlign="center" sm={6}>
                            <Box
                              className={locExpDetails?.regions ? 'active' : ''}
                              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                            >
                              <Typography variant="body1" display="inline">
                                Able to operate in anywhere (please explain)
                              </Typography>
                              <Checkbox
                                name="regions"
                                checked={locExpDetails.regions}
                                disabled={program.status !== 'approved'}
                                value="N/A"
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                              display: !locExpDetails?.regions ? 'none' : 'initial',
                            }}
                          >
                            <Typography sx={commonTextStyle.labelStyle}>Description</Typography>
                            <TextField
                              name="locationExplanation"
                              placeholder="Enter description here"
                              fullWidth
                              value={formValues?.locationExplanation?.regions}
                              disabled={!locExpDetails?.regions || program.status !== 'approved'}
                              onChange={(event) => handleLocationExpChange(event, 'regions')}
                              variant="outlined"
                              multiline
                              rows={2}
                              sx={commonTextStyle.textAreaStyle}
                            />
                          </Grid>
                        </Grid>
                        <Box
                          sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, my: 2 }}
                        >
                          <Typography sx={{ ...commonTextStyle.heading, my: 2 }} display="inline">
                            Regions
                          </Typography>
                          <br />
                          {locations?.continents.map((subItem: any) => (
                            <Box
                              key={subItem.name}
                              className={formValues.regions.includes(subItem.name) ? 'active' : ''}
                              sx={{
                                border: '1px solid #E0E0E0',
                                display: 'inline-block',
                                width: 'fit-content',
                                m: 2,
                                borderRadius: '20px',
                                p: 2,
                              }}
                            >
                              <Typography variant="body1" display="inline">
                                {subItem.name}
                              </Typography>
                              <Checkbox
                                disabled={locExpDetails?.regions || program.status !== 'approved'}
                                name="regions"
                                checked={formValues.regions.includes(subItem.name)}
                                value={subItem.name}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          In which specific countries does this program operate?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} textAlign="center" sm={6}>
                            <Box
                              className={locExpDetails?.countries ? 'active' : ''}
                              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                            >
                              <Typography variant="body1" display="inline">
                                Not able to specify further (please explain)
                              </Typography>
                              <Checkbox
                                name="countries"
                                disabled={program.status !== 'approved'}
                                checked={locExpDetails.countries}
                                value="N/A"
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                              display: !locExpDetails?.countries ? 'none' : 'initial',
                            }}
                          >
                            <Typography sx={commonTextStyle.labelStyle}>Description</Typography>
                            <TextField
                              name="locationExplanation"
                              placeholder="Enter description here"
                              fullWidth
                              value={formValues?.locationExplanation?.countries}
                              disabled={!locExpDetails?.countries || program.status !== 'approved'}
                              onChange={(event) => handleLocationExpChange(event, 'countries')}
                              variant="outlined"
                              multiline
                              rows={2}
                              sx={commonTextStyle.textAreaStyle}
                            />
                          </Grid>
                        </Grid>
                        {formValues.regions.map((item: string) => (
                          <Grid key={item} sx={{ my: 1 }} item xs={12}>
                            <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                              <Typography
                                sx={{ ...commonTextStyle.heading, my: 2 }}
                                display="inline"
                              >
                                {item}
                              </Typography>
                              <br />
                              {locations && renderCountries(item)}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          In which specific states does this program operate?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} textAlign="center" sm={6}>
                            <Box
                              className={locExpDetails?.states ? 'active' : ''}
                              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                            >
                              <Typography variant="body1" display="inline">
                                Able to operate in anywhere (please explain)
                              </Typography>
                              <Checkbox
                                name="states"
                                disabled={program.status !== 'approved'}
                                checked={locExpDetails.states}
                                value="N/A"
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                              display: !locExpDetails?.states ? 'none' : 'initial',
                            }}
                          >
                            <Typography sx={commonTextStyle.labelStyle}>Description</Typography>
                            <TextField
                              name="locationExplanation"
                              placeholder="Enter description here"
                              fullWidth
                              value={formValues?.locationExplanation?.states}
                              disabled={!locExpDetails?.states || program.status !== 'approved'}
                              onChange={(event) => handleLocationExpChange(event, 'states')}
                              variant="outlined"
                              multiline
                              rows={2}
                              sx={commonTextStyle.textAreaStyle}
                            />
                          </Grid>
                        </Grid>
                        {formValues.countries.map((item: string) => {
                          const n = item.lastIndexOf(';');
                          const result = item.substring(n + 1);
                          if (result === 'United States' || result === 'Canada') {
                            return (
                              <Grid key={result} sx={{ my: 1 }} item xs={12}>
                                <Box
                                  sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                                >
                                  <Typography
                                    sx={{ ...commonTextStyle.heading, my: 2 }}
                                    display="inline"
                                  >
                                    {result}
                                  </Typography>
                                  <br />
                                  {locations && renderStates(result, item)}
                                </Box>
                              </Grid>
                            );
                          }
                          return true;
                        })}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          In which specific cities does this program operate?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} textAlign="center" sm={6}>
                            <Box
                              className={locExpDetails?.cities ? 'active' : ''}
                              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                            >
                              <Typography variant="body1" display="inline">
                                Able to operate in anywhere (please explain)
                              </Typography>
                              <Checkbox
                                name="cities"
                                disabled={program.status !== 'approved'}
                                checked={locExpDetails.cities}
                                value="N/A"
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                              display: !locExpDetails?.cities ? 'none' : 'initial',
                            }}
                          >
                            <Typography sx={commonTextStyle.labelStyle}>Description</Typography>
                            <TextField
                              name="locationExplanation"
                              placeholder="Enter description here"
                              fullWidth
                              value={formValues?.locationExplanation?.cities}
                              disabled={!locExpDetails?.cities || program.status !== 'approved'}
                              onChange={(event) => handleLocationExpChange(event, 'cities')}
                              variant="outlined"
                              multiline
                              rows={2}
                              sx={commonTextStyle.textAreaStyle}
                            />
                          </Grid>
                        </Grid>
                        {formValues.countries.map((item: string) => {
                          const n = item.lastIndexOf(';');
                          const result = item.substring(n + 1);
                          if (result !== 'United States' && result !== 'Canada') {
                            return (
                              <Grid key={result} sx={{ my: 1 }} item xs={12}>
                                <Box
                                  sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                                >
                                  <Typography
                                    sx={{ ...commonTextStyle.heading, my: 2 }}
                                    display="inline"
                                  >
                                    {result}
                                  </Typography>
                                  <br />
                                  {locations && renderCities(result, item)}
                                </Box>
                              </Grid>
                            );
                          }
                          return true;
                        })}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Is there anything you&apos;d like to add about where your program
                          operates?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ ...commonTextStyle.labelStyle }}>Description</Typography>
                        <TextField
                          name="additionalLocationDetails"
                          placeholder="Enter details"
                          fullWidth
                          value={formValues.additionalLocationDetails}
                          onChange={handleChange}
                          variant="outlined"
                          multiline
                          rows={4}
                          sx={commonTextStyle.textAreaStyle}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {/* Strategies and Approaches */}
                <Accordion
                  expanded={expanded === 'strategies'}
                  onChange={handleAccordion('strategies')}
                  sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === 'strategies' ? (
                        <Box sx={commonTextStyle.accordionButton}>
                          <RemoveIcon sx={{ color: colors.primary }} />
                        </Box>
                      ) : (
                        <Box sx={commonTextStyle.accordionButton}>
                          <AddIcon sx={{ color: colors.primary }} />
                        </Box>
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={9}>
                      <Typography sx={commonTextStyle.accordionStyle}>
                        Strategies and Approaches
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={commonTextStyle.accordionCount}>
                        {program?.progress?.strategiesAndApproaches?.complete
                          ? program?.progress?.strategiesAndApproaches?.complete
                          : 0}{' '}
                        of{' '}
                        {program?.progress?.strategiesAndApproaches?.total
                          ? program?.progress?.strategiesAndApproaches?.total
                          : 7}{' '}
                        Completed
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          How would you classify the strategies this program employs?
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.programApproach.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={formValues.strategies.includes(item._id) ? 'active' : ''}
                              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                            >
                              <Typography variant="body1" display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                disabled={program.status !== 'approved'}
                                name="strategies"
                                checked={formValues.strategies.includes(item._id)}
                                value={item._id}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Describe the specific activities you will undertake to implement your
                          program.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="activities"
                          placeholder="Enter activities in your program"
                          fullWidth
                          disabled={program.status !== 'approved'}
                          value={formValues.activities}
                          onChange={handleChange}
                          variant="outlined"
                          multiline
                          rows={4}
                          sx={commonTextStyle.textAreaStyle}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Is the program intervention one-time or ongoing?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            sx={{ display: 'inline-block' }}
                            name="approachDuration"
                            value={formValues.approachDuration}
                            onChange={handleChange}
                          >
                            {vocab?.approachDuration.map((item: any) => (
                              <FormControlLabel
                                sx={{
                                  p: 2,
                                  mx: 2,
                                  border: '1px solid #E0E0E0',
                                  borderRadius: '20px',
                                }}
                                labelPlacement="start"
                                key={item._id}
                                value={item._id}
                                control={<Radio disabled={program.status !== 'approved'} />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Describe the frequency and length of the program activities.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="activitiesFrequency"
                          placeholder="Enter length of program"
                          fullWidth
                          disabled={program.status !== 'approved'}
                          value={formValues.activitiesFrequency}
                          onChange={handleChange}
                          variant="outlined"
                          multiline
                          rows={4}
                          sx={commonTextStyle.textAreaStyle}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          What outputs does this program create?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {formValues?.outputs?.map((lifetimeOutput: any, index) => (
                          <Grid container spacing={4} key={lifetimeOutput.key}>
                            <Grid item xs={12} sm={2} mb={2}>
                              <Typography sx={commonTextStyle.labelStyle}>
                                Output {index + 1}{' '}
                              </Typography>
                              <TextField
                                disabled={program.status !== 'approved'}
                                name="quantity"
                                type="number"
                                value={lifetimeOutput.quantity}
                                onChange={handleOutputChange('quantity', index, 'outputs')}
                                variant="outlined"
                                className="inputTextFiled"
                                fullWidth
                                sx={commonTextStyle.textFieldStyle}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} mb={2}>
                              <Typography sx={commonTextStyle.labelStyle}>
                                Output {index + 1} Description{' '}
                                <Tooltip title="Enter program outputs">
                                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                                </Tooltip>
                              </Typography>
                              <TextField
                                disabled={program.status !== 'approved'}
                                name="description"
                                type="text"
                                value={lifetimeOutput.description}
                                onChange={handleOutputChange('description', index, 'outputs')}
                                sx={commonTextStyle.textFieldStyle}
                                variant="outlined"
                                className="inputTextFiled"
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          What outcomes does this program create?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {formValues?.outcomes?.map((lifetimeOutput: any, index) => (
                          <Grid container spacing={4} key={lifetimeOutput.key}>
                            <Grid item xs={12} sm={2} mb={2}>
                              <Typography sx={commonTextStyle.labelStyle}>
                                Outcome {index + 1}{' '}
                              </Typography>
                              <TextField
                                disabled={program.status !== 'approved'}
                                name="quantity"
                                type="number"
                                value={lifetimeOutput.quantity}
                                onChange={handleOutputChange('quantity', index, 'outcomes')}
                                variant="outlined"
                                className="inputTextFiled"
                                fullWidth
                                sx={commonTextStyle.textFieldStyle}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} mb={2}>
                              <Typography sx={commonTextStyle.labelStyle}>
                                Outcome {index + 1} Description{' '}
                                <Tooltip title="Enter program outputs">
                                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                                </Tooltip>
                              </Typography>
                              <TextField
                                disabled={program.status !== 'approved'}
                                name="description"
                                type="text"
                                value={lifetimeOutput.description}
                                onChange={handleOutputChange('description', index, 'outcomes')}
                                sx={commonTextStyle.textFieldStyle}
                                variant="outlined"
                                className="inputTextFiled"
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Are your results measured qualitatively or quantitatively?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl>
                          <RadioGroup
                            sx={{ display: 'inline-block' }}
                            name="dataMeasurementType"
                            value={formValues.dataMeasurementType}
                            onChange={handleChange}
                          >
                            {vocab?.dataMeasurement.map((item: any) => (
                              <FormControlLabel
                                sx={{
                                  p: 2,
                                  mx: 2,
                                  border: '1px solid #E0E0E0',
                                  borderRadius: '20px',
                                }}
                                labelPlacement="start"
                                key={item._id}
                                value={item._id}
                                control={<Radio disabled={program.status !== 'approved'} />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Are there any SIO partners supporting this program?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl>
                          <RadioGroup
                            sx={{ display: 'inline-block' }}
                            name="nonprofitPartners"
                            value={formValues.nonprofitPartners ? 'yes' : 'no'}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              sx={{
                                p: 2,
                                mx: 2,
                                border: '1px solid #E0E0E0',
                                borderRadius: '20px',
                              }}
                              value="yes"
                              control={<Radio disabled={program.status !== 'approved'} />}
                              label="Yes"
                            />
                            <FormControlLabel
                              sx={{
                                p: 2,
                                mx: 2,
                                border: '1px solid #E0E0E0',
                                borderRadius: '20px',
                              }}
                              value="no"
                              control={<Radio disabled={program.status !== 'approved'} />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {formValues.nonprofitPartners && (
                        <Grid item xs={12} sm={6}>
                          <Typography sx={commonTextStyle.labelStyle}>
                            SIO partners{' '}
                            <Tooltip title="Please mention all SIO partners supporting this program">
                              <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                            </Tooltip>
                          </Typography>
                          <TextField
                            name="nonprofitPartnersDescription"
                            placeholder="Please mention all SIO partners supporting this program."
                            fullWidth
                            disabled={program.status !== 'approved'}
                            value={formValues?.nonprofitPartnersDescription?.trimLeft()}
                            onChange={handleChange}
                            variant="outlined"
                            multiline
                            rows={2}
                            sx={commonTextStyle.textAreaStyle}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {/* Research and Evaluation */}
                <Accordion
                  expanded={expanded === 'research'}
                  onChange={handleAccordion('research')}
                  sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === 'research' ? (
                        <Box sx={commonTextStyle.accordionButton}>
                          <RemoveIcon sx={{ color: colors.primary }} />
                        </Box>
                      ) : (
                        <Box sx={commonTextStyle.accordionButton}>
                          <AddIcon sx={{ color: colors.primary }} />
                        </Box>
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={9}>
                      <Typography sx={commonTextStyle.accordionStyle}>
                        Research and Evaluation
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={commonTextStyle.accordionCount}>
                        {program?.progress?.researchAndEvaluation?.complete
                          ? program?.progress?.researchAndEvaluation?.complete
                          : 0}{' '}
                        of{' '}
                        {program?.progress?.researchAndEvaluation?.total
                          ? program?.progress?.researchAndEvaluation?.total
                          : 2}{' '}
                        Completed
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Please upload the research or evaluation that informs your program design.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="researchFile"
                          placeholder="Select File"
                          fullWidth
                          type="file"
                          disabled={program.status !== 'approved'}
                          // value={formValues.researchFile.name}
                          onChange={handleUpload}
                          variant="outlined"
                          sx={commonTextStyle.textFieldStyle}
                        />
                        {formValues.researchFile && (
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              sx={{ lineHeight: '19.36px', display: 'flex', alignItems: 'center' }}
                            >
                              <b style={{ marginRight: '4px' }}>File Name: </b>{' '}
                              {formValues.researchFile.name}
                              {formValues.researchFile.name &&
                                downloadTextButton(formValues.researchFile)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          How would you describe the evidence or data?
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.studyEvidenceType.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={
                                formValues.evidenceDescription.includes(item._id) ? 'active' : ''
                              }
                              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                            >
                              <Typography variant="body1" display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                disabled={program.status !== 'approved'}
                                name="evidenceDescription"
                                checked={formValues.evidenceDescription.includes(item._id)}
                                value={item._id}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid style={{ padding: '1em' }}>
                        <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                          <Typography variant="body1" display="inline">
                            Others
                          </Typography>
                          <Checkbox
                            name="evidenceOthers"
                            checked={evidenceOptionalCheck}
                            onChange={() => {
                              setEvidenceOptionalCheck(!evidenceOptionalCheck);
                              setFormValues({
                                ...formValues,
                                evidenceDescription: [],
                              });
                            }}
                          />
                        </Box>
                      </Grid>
                      {evidenceOptionalCheck && (
                        <Grid style={{ paddingLeft: '1em' }}>
                          <Typography variant="body1" display="inline">
                            Please describe others
                          </Typography>
                          <TextField
                            name="evidenceOptionalValue"
                            type="text"
                            variant="outlined"
                            className="inputTextFiled"
                            value={formValues.evidenceOptional}
                            onChange={handleChangeSelect}
                            fullWidth
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          How would you describe the study design?
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.studyDesignType.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={
                                formValues.studyDescription.includes(item._id) ? 'active' : ''
                              }
                              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                            >
                              <Typography variant="body1" display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                disabled={program.status !== 'approved'}
                                name="studyDescription"
                                checked={formValues.studyDescription.includes(item._id)}
                                value={item._id}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid style={{ padding: '1em' }}>
                        <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                          <Typography variant="body1" display="inline">
                            Others
                          </Typography>
                          <Checkbox
                            name="studyOthers"
                            checked={studyOptionalCheck}
                            onChange={() => {
                              setStudyOptionalCheck(!studyOptionalCheck);
                              setFormValues({
                                ...formValues,
                                studyDescription: [],
                              });
                            }}
                          />
                        </Box>
                      </Grid>
                      {studyOptionalCheck && (
                        <Grid style={{ padding: '1em 0 0 1em' }}>
                          <Typography variant="body1" display="inline">
                            Please describe others
                          </Typography>
                          <TextField
                            name="studyOptionalValue"
                            type="text"
                            variant="outlined"
                            className="inputTextFiled"
                            value={formValues.studyOptional}
                            onChange={handleChangeSelect}
                            fullWidth
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          How would you describe the data in the study?
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.researchDataType.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={
                                formValues.dataDescription.includes(item._id) ? 'active' : ''
                              }
                              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                            >
                              <Typography variant="body1" display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                disabled={program.status !== 'approved'}
                                name="dataDescription"
                                checked={formValues.dataDescription.includes(item._id)}
                                value={item._id}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid style={{ padding: '1em' }}>
                        <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                          <Typography variant="body1" display="inline">
                            Others
                          </Typography>
                          <Checkbox
                            name="dataOthers"
                            checked={dataOptionalCheck}
                            onChange={() => {
                              setDataOptionalCheck(!dataOptionalCheck);
                              setFormValues({
                                ...formValues,
                                dataDescription: [],
                              });
                            }}
                          />
                        </Box>
                      </Grid>
                      {dataOptionalCheck && (
                        <Grid style={{ padding: '1em 0 0 1em' }}>
                          <Typography variant="body1" display="inline">
                            Please describe others
                          </Typography>
                          <TextField
                            name="dataOptionalValue"
                            type="text"
                            variant="outlined"
                            className="inputTextFiled"
                            value={formValues.dataOptional}
                            onChange={handleChangeSelect}
                            fullWidth
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          How would you describe the correlation of your program to your outcomes
                          based on the study?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            sx={{ display: 'inline-block' }}
                            name="outcomeDescription"
                            value={formValues.outcomeDescription}
                            onChange={handleChange}
                          >
                            {vocab?.effect.map((item: any) => (
                              <FormControlLabel
                                sx={{
                                  p: 2,
                                  mx: 2,
                                  border: '1px solid #E0E0E0',
                                  borderRadius: '20px',
                                }}
                                labelPlacement="start"
                                key={item._id}
                                value={item._id}
                                control={<Radio disabled={program.status !== 'approved'} />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Which strategies and approaches align with the research or evaluation this
                          program design is based on?
                        </Typography>
                      </Grid>
                      {vocab?.causes.map((item: any) => (
                        <Grid key={item._id} sx={{ my: 2 }} item xs={12}>
                          <Box
                            className={
                              formValues.evidenceDescription.includes(item._id) ? 'active' : ''
                            }
                            sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                          >
                            <Typography sx={{ ...commonTextStyle.heading }}>
                              {item.label}
                            </Typography>
                            <br />
                            {vocab?.approach.map((subItem: any) => {
                              if (subItem.causeId === item._id) {
                                return (
                                  <Box
                                    key={subItem._id}
                                    className={
                                      isChecked1(subItem._id) ||
                                      formValues.researchApproach.includes(subItem._id)
                                        ? 'active'
                                        : ''
                                    }
                                    sx={{
                                      border: '1px solid #E0E0E0',
                                      display: 'inline-block',
                                      width: 'fit-content',
                                      m: 2,
                                      borderRadius: '20px',
                                      p: 2,
                                    }}
                                  >
                                    <Typography variant="body1" display="inline">
                                      {subItem.label}
                                    </Typography>
                                    <Checkbox
                                      disabled={program.status !== 'approved'}
                                      name="researchApproach"
                                      checked={
                                        isChecked1(subItem._id) ||
                                        formValues.researchApproach.includes(subItem._id)
                                      }
                                      value={subItem._id}
                                      onChange={handleChangeSelect}
                                    />
                                  </Box>
                                );
                              }
                              return true;
                            })}
                          </Box>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          What environmental outputs does this program create?
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.sccConversion.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={
                                formValues.environmentalOutputs.includes(item._id) ? 'active' : ''
                              }
                              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                            >
                              <Typography variant="body1" display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                disabled={program.status !== 'approved'}
                                name="environmentalOutputs"
                                checked={formValues.environmentalOutputs.includes(item._id)}
                                value={item._id}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Please add numeric values for the environmental outputs this program
                          creates.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {formValues?.environmentalOutputs?.map((lifetimeOutput: any, index) => {
                          const label: any = vocab?.sccConversion?.filter(
                            (item: any) => item?._id === lifetimeOutput,
                          );
                          return (
                            <Grid container spacing={4} key={lifetimeOutput}>
                              <Grid item xs={12} sm={2} mb={2}>
                                <Typography sx={commonTextStyle.labelStyle}>
                                  Quantity {index + 1}
                                </Typography>
                                <TextField
                                  value={getFieldValue(index, 'quantity')}
                                  disabled={program.status !== 'approved'}
                                  name="quantity"
                                  type="number"
                                  onChange={(event) => {
                                    handleLifetimeOutputChange(
                                      'quantity',
                                      index,
                                      event.target.value,
                                      lifetimeOutput,
                                    );
                                  }}
                                  variant="outlined"
                                  className="inputTextFiled"
                                  fullWidth
                                  sx={commonTextStyle.textFieldStyle}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} mb={2}>
                                <Typography sx={commonTextStyle.labelStyle}>
                                  Output {index + 1} Description
                                </Typography>
                                <TextField
                                  disabled={program.status !== 'approved'}
                                  name="description"
                                  type="text"
                                  value={getFieldValue(index, 'description')}
                                  onChange={() => {
                                    handleLifetimeOutputChange(
                                      'description',
                                      index,
                                      label && label[0]?.label!,
                                      lifetimeOutput,
                                    );
                                  }}
                                  sx={commonTextStyle.textFieldStyle}
                                  variant="outlined"
                                  className="inputTextFiled"
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          How strong is the relation of the outcomes from the study to the
                          strategies and approaches you identified?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            sx={{ display: 'inline-block' }}
                            name="strength"
                            value={formValues.strength}
                            onChange={handleChange}
                          >
                            {vocab?.strength.map((item: any) => (
                              <FormControlLabel
                                sx={{
                                  p: 2,
                                  mx: 2,
                                  border: '1px solid #E0E0E0',
                                  borderRadius: '20px',
                                }}
                                labelPlacement="start"
                                key={item._id}
                                value={item._id}
                                control={<Radio disabled={program.status !== 'approved'} />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {/* Budget Accordion */}
                <Accordion
                  expanded={expanded === 'budget'}
                  onChange={handleAccordion('budget')}
                  sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === 'budget' ? (
                        <Box sx={commonTextStyle.accordionButton}>
                          <RemoveIcon sx={{ color: colors.primary }} />
                        </Box>
                      ) : (
                        <Box sx={commonTextStyle.accordionButton}>
                          <AddIcon sx={{ color: colors.primary }} />
                        </Box>
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={9}>
                      <Typography sx={commonTextStyle.accordionStyle}>Budget</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={commonTextStyle.accordionCount}>
                        {program?.progress?.finance?.complete
                          ? program?.progress?.finance?.complete
                          : 0}{' '}
                        of{' '}
                        {program?.progress?.finance?.total ? program?.progress?.finance?.total : 4}{' '}
                        Completed
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          What is your total program budget?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={commonTextStyle.labelStyle}>
                          Currency{' '}
                          <Tooltip title="Enter Budget">
                            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                          </Tooltip>
                        </Typography>
                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                          <Select
                            disabled={program.status !== 'approved'}
                            name="currency"
                            value={currencyType}
                            onChange={handleBudgetSelectChange}
                            sx={{
                              ...commonTextStyle.textFieldStyle,
                              '& .MuiSelect-select': {
                                fontSize: fonts.fontSize14,
                                paddingTop: '27px',
                                height: '100%',
                              },
                            }}
                          >
                            {currency.map((currencyItem: any) => (
                              <MenuItem value={currencyItem} key={currencyItem}>
                                {currencyItem}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {isOtherSelected && (
                        <Grid item xs={12} sm={4}>
                          <Typography sx={commonTextStyle.labelStyle}>
                            Mention Other{' '}
                            <Tooltip title="Enter Other Currency">
                              <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                            </Tooltip>
                          </Typography>
                          <TextField
                            disabled={program.status !== 'approved'}
                            name="otherCurrency"
                            placeholder="Enter Other Currency"
                            type="text"
                            fullWidth
                            onChange={handleOtherCurrency}
                            value={_.toUpper(formValues.currency)}
                            margin="none"
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={4}>
                        <Typography sx={commonTextStyle.labelStyle}>
                          Budget{' '}
                          <Tooltip title="Enter Budget">
                            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                          </Tooltip>
                        </Typography>
                        <TextField
                          name="budget"
                          placeholder="Enter your budget here"
                          value={formValues.budget}
                          required
                          disabled={program.status !== 'approved'}
                          onChange={handleChange}
                          variant="outlined"
                          margin="none"
                          sx={commonTextStyle.textFieldStyle}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Please upload your budget.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="budgetFile"
                          placeholder="Select File"
                          fullWidth
                          type="file"
                          disabled={program.status !== 'approved'}
                          // value={formValues.budgetFile}
                          onChange={handleUpload}
                          margin="normal"
                          variant="outlined"
                          sx={commonTextStyle.textFieldStyle}
                        />
                        {program?.finance?.budgetFile?.name && (
                          <Grid item xs={12}>
                            <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
                              Uploaded Files:
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                mb: 2,
                                lineHeight: '19.36px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <b style={{ marginRight: '4px' }}>File Name: </b>{' '}
                              {program?.finance?.budgetFile?.name}
                              {downloadTextButton(program?.finance?.budgetFile)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          What is the total value of cash contributions to the program?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={commonTextStyle.labelStyle}>
                          Cash Contributions{' '}
                          <Tooltip title="Enter Cash Contributions">
                            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                          </Tooltip>
                        </Typography>
                        <FormControl variant="outlined">
                          <Select
                            name="currency"
                            disabled
                            value={formValues.currency}
                            onChange={handleSelectChange}
                            sx={{
                              ...commonTextStyle.textFieldStyle,
                              '& .MuiSelect-select': {
                                fontSize: fonts.fontSize14,
                                paddingTop: '27px',
                                marginRight: '1em',
                                height: '100%',
                              },
                            }}
                          >
                            <MenuItem value={formValues?.currency}>{formValues?.currency}</MenuItem>
                            {/* <MenuItem value="">Select Currency</MenuItem>
                          <MenuItem value="USD">USD</MenuItem>
                          <MenuItem value="EUR">EUR</MenuItem> */}
                          </Select>
                        </FormControl>
                        <TextField
                          name="cashContributions"
                          placeholder="Enter Cash Contribution here"
                          value={formValues.cashContributions}
                          required
                          disabled={program.status !== 'approved'}
                          onChange={handleChange}
                          variant="outlined"
                          margin="none"
                          sx={{ ...commonTextStyle.textFieldStyle, marginLeft: '1em' }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          What is the total value of in-kind contributions to the program?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={commonTextStyle.labelStyle}>
                          In-Kind Contributions{' '}
                          <Tooltip title="Enter In-Kind Contributions">
                            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                          </Tooltip>
                        </Typography>
                        <FormControl variant="outlined">
                          <Select
                            name="currency"
                            value={formValues.currency}
                            disabled
                            onChange={handleSelectChange}
                            sx={{
                              ...commonTextStyle.textFieldStyle,
                              '& .MuiSelect-select': {
                                fontSize: fonts.fontSize14,
                                paddingTop: '27px',
                                height: '100%',
                              },
                            }}
                          >
                            <MenuItem value={formValues?.currency}>{formValues?.currency}</MenuItem>
                            {/* <MenuItem value="">Select Currency</MenuItem>
                          <MenuItem value="USD">USD</MenuItem>
                          <MenuItem value="EUR">EUR</MenuItem> */}
                          </Select>
                        </FormControl>
                        <TextField
                          name="inKindContributions"
                          placeholder="Enter In-Kind Contribution here"
                          value={formValues.inKindContributions}
                          required
                          disabled={program.status !== 'approved'}
                          onChange={handleChange}
                          variant="outlined"
                          margin="none"
                          sx={{ ...commonTextStyle.textFieldStyle, marginLeft: '1em' }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {/* Content */}
                <Accordion
                  expanded={expanded === 'content'}
                  onChange={handleAccordion('content')}
                  sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === 'content' ? (
                        <Box sx={commonTextStyle.accordionButton}>
                          <RemoveIcon sx={{ color: colors.primary }} />
                        </Box>
                      ) : (
                        <Box sx={commonTextStyle.accordionButton}>
                          <AddIcon sx={{ color: colors.primary }} />
                        </Box>
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={9}>
                      <Typography sx={commonTextStyle.accordionStyle}>Content</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={commonTextStyle.accordionCount}>
                        {program?.progress?.content?.complete
                          ? program?.progress?.content?.complete
                          : 0}{' '}
                        of{' '}
                        {program?.progress?.content?.total ? program?.progress?.content?.total : 1}{' '}
                        Completed
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Please describe where and when we could potentially film this program in
                          action.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="filmLocation"
                          placeholder="Enter Description"
                          fullWidth
                          disabled={program.status !== 'approved'}
                          value={formValues.filmLocation}
                          required
                          onChange={handleChange}
                          variant="outlined"
                          margin="none"
                          multiline
                          rows={4}
                          sx={commonTextStyle.textAreaStyle}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.heading}>
                          Please upload a zip file with all applicable photos and videos.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="assets"
                          placeholder="Select File"
                          fullWidth
                          type="file"
                          disabled={program.status !== 'approved'}
                          // value={formValues.assets}
                          onChange={handleUpload}
                          margin="normal"
                          variant="outlined"
                          sx={commonTextStyle.textFieldStyle}
                        />
                        <br />
                        {program?.content?.assets?.name && (
                          <Grid item xs={12}>
                            <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
                              Uploaded Files:
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                mb: 2,
                                lineHeight: '19.36px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <b style={{ marginRight: '4px' }}>File Name: </b>{' '}
                              {program?.content?.assets?.name}
                              {downloadTextButton(program?.content?.assets)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
          </Grid>

          <Box sx={{ bgcolor: 'white', height: '100px', borderRadius: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} textAlign="right">
                {renderButtons()}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProgramDetails;
