import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { FlexBox, FlexBoxColumn } from '../../shared/components';
import { Program } from '../../shared/interfaces';

interface Props {
  program: Program;
}

const fallbackImageStyles = {
  maxWidth: '100%',
  maxHeight: '100%',
  width: '700px',
  height: 'auto',
  display: 'block',
  margin: '0 auto',
  objectFit: 'contain' as const,
};

const SioInformationProgramPage: FC<Props> = ({ program }) => {
  const { sio, vimeoID } = program;
  const programVimeoID = vimeoID;
  const { asset, name, description } = sio;
  const sioAssetVimeoID = asset?.vimeoId;

  let videoContent;

  if (programVimeoID || sioAssetVimeoID) {
    videoContent = (
      <iframe className="ProgramVideo pdf-image" title="Nonprofit vimeo" src={`https://player.vimeo.com/video/${programVimeoID || sioAssetVimeoID}?autoplay=0`}>
        <track default kind="captions" src="path/to/no-captions.vtt" />
      </iframe>
    );
  } else if (asset?.videoFallback?.url) {
    videoContent = <img src={asset.videoFallback.url} alt="social impact organization" style={fallbackImageStyles} />;
  } else {
    videoContent = null; // or any default content you want to render when there's no video or fallback image
  }
  
  return (
    <FlexBox className="program__page-social-impact-organization__container">
      {videoContent}
      <FlexBoxColumn className="social-impact-organization__details">
        <FlexBoxColumn className="programHeader__details">
          <Typography variant="h5">Social Impact Organization</Typography>
          <Typography variant="h3">{name}</Typography>
        </FlexBoxColumn>
       
        <Typography className="programDescription">{description}</Typography>
      </FlexBoxColumn>
    </FlexBox>
  );
};

export default SioInformationProgramPage;
