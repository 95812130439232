import { Box, Button, Grid, Tab, Tabs } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import {
  CommonGridFilter,
  CommonGridFilterHandle,
  FlexBox,
  IsolatedGrid,
  LightTooltip,
} from '../../shared/components';
import { BETWEEN_FILTER_OPERATOR, CONTAINS_FILTER_OPERATOR } from '../../shared/constants';
import {
  booleanToTextValues,
  CauseAreasTabs,
  ConfirmDialogType,
  Dialogs,
  GridColumns,
} from '../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import {
  booleanToText,
  getDateString,
  getGenericHeight,
  getGridRequest,
  isNullOrWhitespace,
  openConfirmDialog,
} from '../../shared/utils';
import { setDialog } from '../main-screen';
import { setCauseAreaSettingsCauseAreaId } from './cause-area-settings';

import {
  setCauseAreasTab,
  setCauseAreasGridModel,
  getCauseAreasInformationThunk,
  patchCauseAreaThunk,
  deleteCauseAreaThunk,
  clearCauseAreasDashboardState,
} from './causeAreasSlice';


export const CauseAreaDashBoard = ()=> {
  const state = useAppSelector((x) => x.causeAreas);
  const causeAreaSuccess = useAppSelector((x) => x.causeAreaSettings.success);
  const stateConfirmDialog = useAppSelector((x) => x.confirmDialog);
  const dispatch = useAppDispatch();

  const filterRef = useRef<CommonGridFilterHandle>(null);
  const { grid, selectedTab, success, toastMessage } = state;
  const { filter, sort, page, pageSize } = grid;
  const { value, confirmed, type } = stateConfirmDialog;

  useEffect(
    () => () => {
      dispatch(clearCauseAreasDashboardState());
    },
    [dispatch],
  );

  useEffect(() => {
    const active = selectedTab === CauseAreasTabs.Active;
    dispatch(getCauseAreasInformationThunk({ active, ...getGridRequest(grid) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, selectedTab]);

  useEffect(() => {
    if (!causeAreaSuccess) return;
    const active = selectedTab === CauseAreasTabs.Active;
    dispatch(getCauseAreasInformationThunk({ active, ...getGridRequest(grid) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [causeAreaSuccess]);

  useEffect(() => {
    if (!success) return;
    const active = selectedTab === CauseAreasTabs.Active;
    dispatch(getCauseAreasInformationThunk({ active, ...getGridRequest(grid) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (!success || isNullOrWhitespace(toastMessage)) return;
    toast.success(toastMessage);
  }, [success, toastMessage]);

  useEffect(() => {
    if (!confirmed || type !== ConfirmDialogType.DeactivateCauseArea || isNullOrWhitespace(value))
      return;
    dispatch(deleteCauseAreaThunk(value!));
  }, [value, confirmed, type, dispatch]);

  useEffect(() => {
    if (!confirmed || type !== ConfirmDialogType.ActivateCauseArea || isNullOrWhitespace(value))
      return;
    dispatch(patchCauseAreaThunk(value!));
  }, [value, confirmed, type, dispatch]);

  const editCauseArea = (id: string) => {
    dispatch(setDialog({ open: true, type: Dialogs.CauseAreaSettings, maxWidth: 'md' }));
    dispatch(setCauseAreaSettingsCauseAreaId(id));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setCauseAreasTab(newValue));
  };

  const deactivateCauseArea = (id: string) => {
    openConfirmDialog(
      {
        title: 'Deactivate Cause Area',
        text: 'Are you sure you want to deactivate this Cause Area? Deactivated Cause Areas can be reactivated from the inactive tab on the Cause Areas dashboard.',
        type: ConfirmDialogType.DeactivateCauseArea,
        confirmText: 'Deactivate',
        value: id,
      },
      'sm',
    );
  };

  const activateCauseArea = (id: string) => {
    openConfirmDialog(
      {
        title: 'Activate Cause Area',
        text: 'Are you sure you want to activate this Cause Area?',
        type: ConfirmDialogType.ActivateCauseArea,
        confirmText: 'Activate',
        value: id,
      },
      'sm',
    );
  };

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'partnerName',
      headerName: 'Partner',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'instanceCount',
      headerName: 'Instances',
      flex: 1,
      filterable: true,
      renderCell: ({ row: { instanceCount } }) => instanceCount ?? 0,
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      flex: 1,
      filterable: true,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      type: GridColumns.Date,
      renderCell: (params) => (
        <LightTooltip title={getDateString(params?.value) ?? ''}>
          <Box>{getDateString(params?.value, true)}</Box>
        </LightTooltip>
      ),
    },
    {
      field: 'action',
      disableReorder: true,
      resizable: false,
      headerName: 'Actions',
      type: GridColumns.Actions,
      flex: 1,
      renderCell: ({ row: { id, instanceCount, active } }) => (
        <FlexBox sx={{ gap: 1 }}>
          <Button
            variant="outlined"
            color="info"
            sx={{ backgroundColor: '#fff' }}
            onClick={() => {
              editCauseArea(id);
            }}
          >
            Edit
          </Button>
          {!instanceCount && (
            <Button
              variant="outlined"
              color={active ? 'error' : 'success'}
              sx={{ backgroundColor: '#fff' }}
              onClick={() => (active ? deactivateCauseArea(id) : activateCauseArea(id))}
            >
              {booleanToText(!active, booleanToTextValues.DeactivateActive)}
            </Button>
          )}
        </FlexBox>
      ),
      sortable: false,
      filterable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  return (
    <Box>
      <Grid container alignItems="end">
        <Grid item md={6} sm={12}>
          <Box my={1}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab value={CauseAreasTabs.Active} label="Active" />
              <Tab value={CauseAreasTabs.Inactive} label="Inactive" />
              <Tab value={CauseAreasTabs.Metrics} label="Metrics" />
            </Tabs>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box my={1} display="flex" justifyContent="flex-end">
            <CommonGridFilter
              columns={columns}
              currentFilter={filter}
              onChange={(x) => {
                dispatch(setCauseAreasGridModel({ filter: x }));
              }}
              ref={filterRef}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '400px', height: getGenericHeight(300), marginTop: 1 }}>
        <IsolatedGrid
          status={selectedTab}
          model={grid}
          columns={
            selectedTab === CauseAreasTabs.Active
              ? columns
              : columns.filter((x) => x.field !== 'instanceCount')
          }
          onChange={(x) => {
            dispatch(setCauseAreasGridModel(x));
          }}
        />
      </Box>
    </Box>
  );
};
