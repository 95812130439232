import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Dialogs } from '../../shared/enums';

interface MainDialogData {
  open: boolean;
  type: Dialogs;
  maxWidth?: false | 'sm' | 'md' | 'xs' | 'lg' | 'xl' | undefined;
  hideCloseIcon?: boolean;
}

interface MainScreenState {
  dialogs: Array<MainDialogData>;
}

const initialState: MainScreenState = {
  dialogs: [],
};

const mainScreenSlice = createSlice({
  name: 'mainScreen',
  initialState,
  reducers: {
    setDialog: (state, action: PayloadAction<MainDialogData>) => {
      const index = state.dialogs.findIndex((x) => x.type === action.payload.type);
      if (index > -1) state.dialogs[index] = action.payload;
      else state.dialogs.push(action.payload);
    },
    hideDialogCloseIcon: (state, action: PayloadAction<Dialogs>) => {
      const dialog = _.find(state.dialogs, (x) => x.type === action.payload);
      if (dialog) dialog.hideCloseIcon = true;
    },
    clearDialog: (state, action: PayloadAction<Dialogs>) => {
      _.remove(state.dialogs, (dialog) => dialog.type === action.payload);
    },
  },
});

export const { setDialog, clearDialog, hideDialogCloseIcon } = mainScreenSlice.actions;

export const mainScreenReducer = mainScreenSlice.reducer;
