import { Box } from '@mui/material';
import React, { FC } from 'react';
import Icon1 from '../../assets/svg/sdg-icons/SDG1.png';
import Icon2 from '../../assets/svg/sdg-icons/SDG2.png';
import Icon3 from '../../assets/svg/sdg-icons/SDG3.png';
import Icon4 from '../../assets/svg/sdg-icons/SDG4.png';
import Icon5 from '../../assets/svg/sdg-icons/SDG5.png';
import Icon6 from '../../assets/svg/sdg-icons/SDG6.png';
import Icon7 from '../../assets/svg/sdg-icons/SDG7.png';
import Icon8 from '../../assets/svg/sdg-icons/SDG8.png';
import Icon9 from '../../assets/svg/sdg-icons/SDG9.png';
import Icon10 from '../../assets/svg/sdg-icons/SDG10.png';
import Icon11 from '../../assets/svg/sdg-icons/SDG11.png';
import Icon12 from '../../assets/svg/sdg-icons/SDG12.png';
import Icon13 from '../../assets/svg/sdg-icons/SDG13.png';
import Icon14 from '../../assets/svg/sdg-icons/SDG14.png';
import Icon15 from '../../assets/svg/sdg-icons/SDG15.png';
import Icon16 from '../../assets/svg/sdg-icons/SDG16.png';
import Icon17 from '../../assets/svg/sdg-icons/SDG17.png';

const sdgStyle = { width: '107.6px', height: '107.6px' }

interface Props {
  code: string;
}
export const SDGIcon: FC<Props> = ({ code }) => {
  const getLogo = (num: string) => {
    switch (num) {
      case 'SDG 1':
        return <img src={Icon1} alt={num} style={sdgStyle} />;
      case 'SDG 2':
        return <img src={Icon2} alt={num} style={sdgStyle} />;
      case 'SDG 3':
        return <img src={Icon3} alt={num} style={sdgStyle} />;
      case 'SDG 4':
        return <img src={Icon4} alt={num} style={sdgStyle} />;
      case 'SDG 5':
        return <img src={Icon5} alt={num} style={sdgStyle} />;
      case 'SDG 6':
        return <img src={Icon6} alt={num} style={sdgStyle} />;
      case 'SDG 7':
        return <img src={Icon7} alt={num} style={sdgStyle} />;
      case 'SDG 8':
        return <img src={Icon8} alt={num} style={sdgStyle} />;
      case 'SDG 9':
        return <img src={Icon9} alt={num} style={sdgStyle} />;
      case 'SDG 10':
        return <img src={Icon10} alt={num} style={sdgStyle} />;
      case 'SDG 11':
        return <img src={Icon11} alt={num} style={sdgStyle} />;
      case 'SDG 12':
        return <img src={Icon12} alt={num} style={sdgStyle} />;
      case 'SDG 13':
        return <img src={Icon13} alt={num} style={sdgStyle} />;
      case 'SDG 14':
        return <img src={Icon14} alt={num} style={sdgStyle} />;
      case 'SDG 15':
        return <img src={Icon15} alt={num} style={sdgStyle} />;
      case 'SDG 16':
        return <img src={Icon16} alt={num} style={sdgStyle} />;
      case 'SDG 17':
        return <img src={Icon17} alt={num} style={sdgStyle} />;
      default:
        return <img src={Icon1} alt={num} style={sdgStyle} />;
    }
  };
  return (
    <Box className="sdgiconContainer" sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box sx={{ width: '107.6px', height: '107.6px', minWidth: '100px' }}>{getLogo(code)}</Box>
    </Box>
  );
};
