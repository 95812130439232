import React, { FC, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Typography } from '@mui/material';
import {
  DataGridProProps,
  GridColDef,
  GridColumnVisibilityModel,
  GridSortModel,
  GridColumnOrderChangeParams,
  GridCallbackDetails,
  MuiEvent,
  GridRowParams,
  GridSlotsComponentsProps,
} from '@mui/x-data-grid-pro';
import Pagination from '@mui/material/Pagination';
import { GridModel } from '../interfaces';
import { ROWS_PER_PAGE_OPTIONS } from '../constants';
import { CommonGrid } from './CommonGrid';
import { CommonGridColumnMenu } from './CommonGridColumnMenu';
import {
  getColumnOrderFromStorage,
  getColumnVisibilityFromStorage,
  resetColumnsOnStorage,
  setColumnReorderOnStorage,
  setColumnVisibilityOnStorage,
  getDefaultVisibility,
} from '../utils';

import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface Props {
  model: GridModel<any>;
  columns: Array<GridColDef>;
  onChange: (value: Partial<GridModel<any>>) => void;
  isCheckBoxSelectable?: (params: GridRowParams<any>) => boolean;
  gridProps?: Partial<DataGridProProps>;
  alternativeName?: string;
  componentProps?: GridSlotsComponentsProps;
  extraLoading?: boolean;
  hidePagination?: boolean;
  status?: any;
  showIcon?: boolean;
  isGiveWithUser?: boolean;
}
interface MyPaginationProps {
  pageSize: number;
  model: {
    data: {
      totalCount: number;
    };
  };
  onChange: (data: { page: number }) => void;
  page: number;
  status?: any;
}

const MyPagination = ({ pageSize, model, onChange, page, status }: MyPaginationProps) => {
  const [pageSizeCount, setPageSizeCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const pageCount = Math.ceil(model.data.totalCount / pageSizeCount);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onChange({ page: value - 1 });
    setPageNo(value);
  };
  useEffect(() => {
    setPageNo(1);
    onChange({ page: 0 });
  }, [status]);

  useEffect(() => {
    if(pageSize > 0) {
      setPageSizeCount(pageSize);
    }
  }, [pageSize, page])

  const currentPage = page;
  const startRange = currentPage * pageSizeCount;
  const endRange =
    startRange + pageSizeCount < model.data.totalCount ? startRange + pageSizeCount : model.data.totalCount;
  const text =
    model.data.totalCount > 10
      ? `Showing ${(startRange + 1).toString().padStart(2, '0')}-${endRange
          .toString()
          .padStart(2, '0')}`
      : `Showing ${model.data.totalCount}`;
  return (
    <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      gap: '2rem',
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight400,
    }}
    >
      <Typography
        sx={{
          fontSize: fonts.fontSize16,
          fontWeight: fonts.fontWeight400,
          color: colors.textPrimary,
        }}
      >
        {` ${text} of ${' '}
        ${model.data.totalCount}`}
      </Typography>
      <Pagination
        variant="outlined"
        shape="rounded"
        count={pageCount}
        page={pageNo}
        onChange={handleChange}
        sx={() => ({
          '& .MuiPaginationItem-root': {
            fontSize: fonts.fontSize18 ,
            fontWeight: fonts.fontWeight400,
            // fontFamily: 'inter , sans-serif !important',
            color: colors.textPrimary,
          },
          '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: '#249ACB',
            color: '#FFFFFF',
            '&:hover': {
            backgroundColor: '#249ACB',
            color: '#FFFFFF',
          },
        },
          '& .MuiPaginationItem-previousNext': {
            color: colors.primary,
            border: 'none',
            fontSize: fonts.fontSize16,
            fontWeight: fonts.fontWeight700,
          },
          '& .Mui-disabled': {
            color: colors.textSecondary,
            opacity: `1 !important`,
          },

          '& .MuiPagination-ul li:first-child button::after': {
            content: "'Previous'",
            marginLeft: '8px',
          },

          '& .MuiPagination-ul li:last-child button::before': {
            content: "'Next'",
            marginRight: '8px',
          },
        })}
      />
    </div>
  );
};

const IsolatedGridComponent: FC<Props> = (props) => {
  const {
    model,
    columns,
    onChange,
    gridProps,
    alternativeName,
    extraLoading,
    isCheckBoxSelectable,
    componentProps,
    hidePagination,
    status,
    showIcon,
    isGiveWithUser,
  } = props;
  const { name, data, loadingGrid, page, pageSize, selectionModel } = model;

  const getName = useCallback(() => alternativeName ?? name, [alternativeName, name]);
  const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>(
    getDefaultVisibility(getName()) ?? {},
  );
  const [columnDetails, setColumnDetails] = useState(_.cloneDeep(columns));

  useEffect(() => {
    const newVisibilityModel = getColumnVisibilityFromStorage(
      getName(),
      getDefaultVisibility(getName()),
    );
    setVisibilityModel(newVisibilityModel);
  }, [getName]);

  useEffect(() => {
    setColumnDetails(getColumnOrderFromStorage(getName(), columns));
  }, [columns, getName]);

  const resetDashboard = () => {
    resetColumnsOnStorage(getName());
    setVisibilityModel(getDefaultVisibility(getName()) ?? {});
    setColumnDetails(_.cloneDeep(columns));
  };

  return (
    <CommonGrid
      style={{
        border: 'none',
        overflow: 'hidden',
        overflowX: 'hidden',
        overflowY: 'hidden',
        padding: '1rem 0',
      }}
      key={getName()}
      showIcon={showIcon}
      isGiveWithUser={isGiveWithUser}
      columns={columnDetails}
      rows={data?.results}
      rowCount={data.totalCount}
      loading={loadingGrid || extraLoading}
      page={page}
      pageSize={pageSize}
      onColumnOrderChange={(
        params: GridColumnOrderChangeParams,
        event: MuiEvent<{}>,
        details: GridCallbackDetails,
      ) => {
        setColumnReorderOnStorage(getName(), params, details);
        setColumnDetails(getColumnOrderFromStorage(getName(), columns));
      }}
      rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
      paginationMode="server"
      pagination={!hidePagination}
      onPageSizeChange={(x) => {
        onChange({ pageSize: x });
      }}
      onPageChange={(x) => {
        onChange({ page: x });
      }}
      onSortModelChange={(x: GridSortModel) => {
        if (!x || x?.length < 1) {
          onChange({ sort: undefined });
          return;
        }
        onChange({ sort: { field: x[0].field, direction: x[0].sort } });
      }}
      components={{
        ColumnMenu: CommonGridColumnMenu,
        Pagination: MyPagination,
      }}
      componentsProps={{
        ...componentProps,
        columnMenu: { resetDashboard },
        pagination: { onChange, pageSize, model, page, status },
      }}
      columnVisibilityModel={visibilityModel}
      onColumnVisibilityModelChange={(x) => {
        setColumnVisibilityOnStorage(getName(), x);
        setVisibilityModel(x);
      }}
      selectionModel={selectionModel}
      onSelectionModelChange={selectionModel ? (x) => onChange({ selectionModel: x }) : undefined}
      checkboxSelection={!!selectionModel}
      disableSelectionOnClick={!!selectionModel}
      isRowSelectable={selectionModel ? isCheckBoxSelectable : undefined}
      // initialState={{ pinnedColumns: { right: ['action'] } }}
      {...gridProps}
    />
  );
};

export const IsolatedGrid = React.memo(
  IsolatedGridComponent,
  (previous, next) =>
    _.isEqual(previous.model, next.model) &&
    _.isEqual(previous.gridProps, next.gridProps) &&
    _.isEqual(previous.columns, next.columns),
);
