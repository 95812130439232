import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addCancelTokenEvent, putResendNotification } from '../../../api';
import { RootState } from '../../../app';

interface ResendNotificationState {
  email: string;
  emails: Array<string>;
  success: boolean;
  loading: boolean;
}

const initialState: ResendNotificationState = {
  email: '',
  emails: [],
  success: false,
  loading: false,
};

export const putResendNotificationThunk = createAsyncThunk<number, void, { state: RootState }>(
  'resendNotification/putResendNotificationThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const { resendNotification, incentive } = getState();
    const { emails } = resendNotification;

    const { status } = await putResendNotification(incentive.incentive?.id ?? '', emails);
    return status;
  },
);

const resendNotificationSlice = createSlice({
  name: 'resendNotification',
  initialState,
  reducers: {
    addResendNotificationEmail: (state, action: PayloadAction<string>) => {
      state.email = '';
      state.emails.unshift(action.payload);
    },
    removeResendNotificationEmailAt: (state, action: PayloadAction<number>) => {
      _.pullAt(state.emails, action.payload);
    },
    clearResendNotificationState: (state) => {
      _.assign(state, initialState);
    },
    setResendNotificationEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(putResendNotificationThunk.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(putResendNotificationThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(putResendNotificationThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      });
  },
});

export const {
  addResendNotificationEmail,
  removeResendNotificationEmailAt,
  clearResendNotificationState,
  setResendNotificationEmail,
} = resendNotificationSlice.actions;

export const resendNotificationReducer = resendNotificationSlice.reducer;
