import React, { useEffect } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FlexBox, Loading } from '../../shared/components';
import { CauseArea, Instance, InstanceCauseAreaImpact } from '../../shared/interfaces';
import { ActionButton, AmountBar, Cause, Sku } from '../incentive';
import { AmountForm } from '../incentive/select-amount/AmountForm';
import { useAppDispatch, useInstance } from '../../shared/hooks';
import { setAppShowOptionsLeftNavBar } from '../../app';
import { formatter } from '../../shared/utils';

interface localStorageData {
  causeAreaData?: CauseArea;
  associatedInstance?: {
    totalCount: number;
    results: Array<Instance>;
  };
}

export const ViewCauseArea = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    loadingUserData,
    instance: currentInstance,
    locale: currentLocale,
    account,
  } = useInstance();
  const state = localStorage.getItem('gw-view-cause-area');
  const values: localStorageData = state ? JSON.parse(state) : null;

  useEffect(() => {
    if (!values) navigate('/app');
    dispatch(setAppShowOptionsLeftNavBar(false));
    return () => {
      dispatch(setAppShowOptionsLeftNavBar(true));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getSkUs = () => {
    const currentInstanceIsOnAssociate = values.associatedInstance?.results.find(
      (instance) => instance.id === currentInstance?.id,
    );
    if (currentInstanceIsOnAssociate) {
      const causeAreaOnInstance = currentInstanceIsOnAssociate.causeAreas?.find(
        (causeArea) => causeArea.causeArea?.id === values.causeAreaData?.id,
      );
      if (causeAreaOnInstance?.skus !== undefined && causeAreaOnInstance?.skus?.length > 0) {
        const SKUsOnInstance = causeAreaOnInstance.skus?.map((item) => ({
          currency: currentLocale?.settings.currency ?? '',
          description: `${currentLocale?.settings.symbol ?? ''}${item}`,
          id: `cause-area-sku-${item}`,
          unitCost: item,
          name: 'cause area',
        }));
        return SKUsOnInstance;
      }
    }
    const defaultSKUs = currentInstance?.skus?.map((item) => ({
      currency: currentLocale?.settings.currency ?? '',
      description: `${currentLocale?.settings.symbol ?? ''}${formatter.format(item)}`,
      id: `cause-area-sku-${item}`,
      unitCost: item,
      name: 'cause area',
    }));
    return defaultSKUs;
  };

  const getCauseArea = () => {
    const currentInstanceIsOnAssociate = values.associatedInstance?.results.find(
      (instance) => instance.id === currentInstance?.id,
    );
    if (currentInstanceIsOnAssociate) {
      const causeAreaOnInstance = currentInstanceIsOnAssociate.causeAreas?.find(
        (causeArea) => causeArea.causeArea?.id === values.causeAreaData?.id,
      );
      if (causeAreaOnInstance?.impact)
        return {
          serviceFee: currentInstance?.settings?.serviceFee,
          ...causeAreaOnInstance?.impact,
        };
    }
    return {
      unitPrice: 1,
      unitValue: 1,
      impactMeasurement: 'items',
      ContributionwithFee: 0.18,
    } as InstanceCauseAreaImpact;
  };
  if (loadingUserData) return <Loading loading={loadingUserData} />;

  const SKUs = getSkUs() || [];

  return (
    <Box sx={(theme) => ({ [theme.breakpoints.up('lg')]: { height: '100%' } })}>
      <FlexBox sx={{ flexDirection: 'column', height: '100%', gap: 1 }}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ sm: 1, md: 2 }}
          sx={{ flex: 1, overflowY: 'auto', height: '100%' }}
        >
          <Grid item xs={12} lg={6} height="100%">
            <FlexBox sx={{ height: '100%', flexDirection: 'column', gap: 2 }}>
              <Typography variant="h4">
                2. Select amount of Social Impact sales contribution
              </Typography>
              <FlexBox
                sx={(theme) => ({
                  justifyContent: 'space-evenly',
                  flexWrap: 'wrap',
                  gap: 1,
                  [theme.breakpoints.up('xl')]: { gap: 6 },
                  [theme.breakpoints.up('md')]: { gap: 3 },
                })}
              >
                {SKUs.map((sku) => (
                  <Sku key={sku.id} sku={sku} disabled amount={100} />
                ))}
              </FlexBox>

              <Box>
                <AmountBar
                  calculation={getCauseArea()}
                  skus={SKUs}
                  grandTotal={100}
                  locale={currentLocale}
                />
              </Box>
              {values.causeAreaData && (
                <Box sx={{ flex: 1, overflow: 'auto', minHeight: 350 }}>
                  <Cause cause={values.causeAreaData} disableHover isDetail viewMode />
                </Box>
              )}
            </FlexBox>
          </Grid>
          <Grid item xs={12} lg={6} height="100%">
            <FlexBox
              sx={{
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Typography variant="h4">3. Tell us about your deal</Typography>
              <Box flex={3}>
                <FlexBox
                  sx={(theme) => ({
                    backgroundColor: theme.palette.common.grey.main,
                    flexDirection: 'column',
                    gap: 2,
                    borderRadius: '8px',
                    paddingY: 3,
                    paddingX: 4,
                    boxSizing: 'border-box',
                  })}
                >
                  <Box sx={{ overflowY: 'auto' }}>
                    <Paper elevation={3} sx={{ paddingX: 4, paddingY: 2, overflowY: 'auto' }}>
                      <AmountForm disabled customFields={account?.incentiveFields} />
                    </Paper>
                  </Box>
                </FlexBox>
              </Box>
              <FlexBox
                sx={{
                  flex: 1,
                  justifyContent: 'end',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="h4" mb={2.75}>
                  4. Submit your Social Impact Contribution for approval
                </Typography>
                <FlexBox
                  sx={{
                    flexDirection: 'row-reverse',
                    gap: 2,
                    alignSelf: 'end',
                    width: '100%',
                    flexWrap: 'nowrap',
                  }}
                >
                  <ActionButton size="large" variant="contained" disabled>
                    Submit
                  </ActionButton>
                  <ActionButton size="large" variant="outlined" disabled>
                    Save as Draft
                  </ActionButton>
                  <ActionButton size="large" variant="outlined" disabled>
                    Back
                  </ActionButton>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </Grid>
        </Grid>
      </FlexBox>
    </Box>
  );
};
