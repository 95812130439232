import { Typography, TypographyProps } from '@mui/material';
import React, { FC, useRef, useEffect, useState } from 'react';
import { LightTooltip } from './StyledComponents';

interface Props {
  label: string;
  value?: string;
  valueProps?: TypographyProps;
}

export const ReadOnlyDataField: FC<Props> = (props) => {
  const { label, value, valueProps } = props;
  const textElementRef = useRef<HTMLSpanElement>(null);
  const [tooltip, setTooltip] = useState(false);

  const compareSize = () => {
    const compare =
      (textElementRef?.current?.scrollWidth ?? 0) > (textElementRef?.current?.clientWidth ?? 0);
    setTooltip(compare);
  };

  useEffect(() => {
    compareSize();
  }, []);

  const typographyElement = (
    <Typography
      ref={textElementRef}
      sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      {...valueProps}
    >
      {value}
    </Typography>
  );

  return (
    <>
      <Typography variant="body2" fontWeight={800} color="#616161" textTransform="uppercase">
        {label}
      </Typography>
      {tooltip && <LightTooltip title={value ?? ''}>{typographyElement}</LightTooltip>}
      {!tooltip && typographyElement}
    </>
  );
};
