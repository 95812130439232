import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addCancelTokenEvent,
  getCauseArea,
  getCauseAreaAssociatedFile,
  getInstances,
  postCauseArea,
  putCauseArea,
} from '../../../api';
import { RootState } from '../../../app';
import { DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { CauseAreaSettingsTabs } from '../../../shared/enums';
import {
  CauseArea,
  GridModel,
  GridRequest,
  GridResponse,
  Instance,
} from '../../../shared/interfaces';

interface CauseAreaAssociatedInstanceSettings extends GridRequest {
  causeAreaId: string;
}

interface CauseAreaSettingsSlice {
  instanceAssociatedGrid: GridModel<Instance>;
  values: CauseArea;
  selectedTab: CauseAreaSettingsTabs;
  loadingCauseArea: boolean;
  editCauseAreaId: string;
  isNew: boolean;
  success: boolean;
  loadingDownload: boolean;
}

const initialState: CauseAreaSettingsSlice = {
  instanceAssociatedGrid: {
    name: 'instance Associated',
    data: {
      totalCount: 0,
      results: [],
    },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'name', direction: 'asc' },
  },
  values: {
    id: '',
    name: '',
    partnerName: '',
    description: '',
    listImageName: '',
    detailImageName: '',
    detailLinkUrl: '',
    detailLinkText: '',
    createdBy: '',
    lastUpdatedBy: '',
    skuPrefix: '',
    sageVendorId: '',
    active: true,
  },
  selectedTab: CauseAreaSettingsTabs.General,
  loadingCauseArea: false,
  editCauseAreaId: '',
  isNew: false,
  success: false,
  loadingDownload: false,
};

export const getCauseAreaInstanceAssociated = createAsyncThunk<
  GridResponse<Instance>,
  CauseAreaAssociatedInstanceSettings
>('causeAreaSettings/getCauseAreaInstanceAssociated', async (id, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getInstances(id);
  return data;
});

export const getCauseAreaInformationThunk = createAsyncThunk<CauseArea, string>(
  'causeAreaSettings/getCauseAreaThunk',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getCauseArea(id, false);
    return data;
  },
);

export const getCauseAreaInstanceAssociatedFileThunk = createAsyncThunk<void, string>(
  'causeAreaSettings/getCauseAreaInstanceAssociatedFileThunk',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getCauseAreaAssociatedFile(id);
    return data;
  },
);

export const postCauseAreaThunk = createAsyncThunk<CauseArea, void, { state: RootState }>(
  'causeAreaSettings/postCauseAreaThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const {
      causeAreaSettings: {
        values: {
          description,
          detailImageName,
          listImageName,
          name,
          partnerName,
          sageVendorId,
          skuPrefix,
          detailLinkText,
          detailLinkUrl,
          tooltip,
          active,
        },
      },
    } = state;
    const { data } = await postCauseArea({
      description,
      detailImageName,
      listImageName,
      name,
      partnerName,
      sageVendorId,
      skuPrefix,
      detailLinkText,
      detailLinkUrl,
      tooltip,
      active,
    });
    return data;
  },
);

export const putCauseAreaThunk = createAsyncThunk<CauseArea, void, { state: RootState }>(
  'causeAreaSettings/putCauseAreaThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const {
      causeAreaSettings: {
        values: {
          id,
          description,
          detailImageName,
          listImageName,
          name,
          partnerName,
          sageVendorId,
          skuPrefix,
          detailLinkText,
          detailLinkUrl,
          tooltip,
          active,
        },
      },
    } = state;
    const { data } = await putCauseArea({
      id,
      description,
      name,
      partnerName,
      sageVendorId,
      skuPrefix,
      detailLinkText,
      detailLinkUrl,
      tooltip,
      detailImageName,
      listImageName,
      active,
    });
    return data;
  },
);

const causeAreaSettingsSlice = createSlice({
  name: 'causeAreaSettings',
  initialState,
  reducers: {
    setCauseAreaSettingName: (state, action: PayloadAction<string>) => {
      state.values.name = action.payload;
    },
    setCauseAreaSettingPartnerName: (state, action: PayloadAction<string>) => {
      state.values.partnerName = action.payload;
    },
    setCauseAreaSettingDetailLinkUrl: (state, action: PayloadAction<string>) => {
      state.values.detailLinkUrl = action.payload;
    },
    setCauseAreaSettingDetailLinkText: (state, action: PayloadAction<string>) => {
      state.values.detailLinkText = action.payload;
    },
    setCauseAreaSettingDescription: (state, action: PayloadAction<string>) => {
      state.values.description = action.payload;
    },
    setCauseAreaSettingListImageUrl: (state, action: PayloadAction<string>) => {
      state.values.listImageName = action.payload;
    },
    setCauseAreaSettingDetailImageName: (state, action: PayloadAction<string>) => {
      state.values.detailImageName = action.payload;
    },
    setCauseAreaSettingTooltip: (state, action: PayloadAction<string>) => {
      state.values.tooltip = action.payload;
    },
    setCauseAreaSettingSKUPrefix: (state, action: PayloadAction<string>) => {
      state.values.skuPrefix = action.payload;
    },
    setCauseAreaSettingSageVendorId: (state, action: PayloadAction<string>) => {
      state.values.sageVendorId = action.payload;
    },
    clearCauseAreaSettingsState: (state) => {
      _.assign(state, initialState);
    },
    setCauseAreaSettingsIsNew: (state, action: PayloadAction<boolean>) => {
      state.isNew = action.payload;
    },
    setCauseAreaSettingsCauseAreaId: (state, action: PayloadAction<string>) => {
      state.editCauseAreaId = action.payload;
    },
    setCauseAreaSettingsTab: (state, action: PayloadAction<number>) => {
      state.selectedTab = action.payload;
    },
    setCauseAreaInstancesGridModel: (
      state,
      action: PayloadAction<Partial<GridModel<Instance>>>,
    ) => {
      _.assign(state.instanceAssociatedGrid, action.payload);
    },
    setCauseAreaSettingsStatus: (state, action: PayloadAction<boolean>) => {
      state.values.active = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCauseAreaInformationThunk.fulfilled,
        (state, action: PayloadAction<CauseArea>) => {
          state.loadingCauseArea = false;
          state.values = action.payload;
        },
      )
      .addCase(getCauseAreaInformationThunk.rejected, (state) => {
        state.loadingCauseArea = false;
      })
      .addCase(getCauseAreaInformationThunk.pending, (state) => {
        if (!state.loadingCauseArea) state.loadingCauseArea = true;
      })
      .addCase(postCauseAreaThunk.fulfilled, (state) => {
        state.loadingCauseArea = false;
        state.success = true;
      })
      .addCase(postCauseAreaThunk.rejected, (state) => {
        state.loadingCauseArea = false;
      })
      .addCase(postCauseAreaThunk.pending, (state) => {
        if (!state.loadingCauseArea) state.loadingCauseArea = true;
      })
      .addCase(putCauseAreaThunk.fulfilled, (state) => {
        state.loadingCauseArea = false;
        state.success = true;
      })
      .addCase(putCauseAreaThunk.rejected, (state) => {
        state.loadingCauseArea = false;
      })
      .addCase(putCauseAreaThunk.pending, (state) => {
        if (!state.loadingCauseArea) state.loadingCauseArea = true;
      })
      .addCase(getCauseAreaInstanceAssociatedFileThunk.fulfilled, (state) => {
        state.loadingDownload = false;
      })
      .addCase(getCauseAreaInstanceAssociatedFileThunk.rejected, (state) => {
        state.loadingDownload = false;
      })
      .addCase(getCauseAreaInstanceAssociatedFileThunk.pending, (state) => {
        if (!state.loadingDownload) state.loadingDownload = true;
      })
      .addCase(
        getCauseAreaInstanceAssociated.fulfilled,
        (state, action: PayloadAction<GridResponse<Instance>>) => {
          state.instanceAssociatedGrid.loadingGrid = false;
          state.instanceAssociatedGrid.data = action.payload;
        },
      )
      .addCase(getCauseAreaInstanceAssociated.rejected, (state) => {
        state.instanceAssociatedGrid.loadingGrid = false;
      })
      .addCase(getCauseAreaInstanceAssociated.pending, (state) => {
        if (!state.instanceAssociatedGrid.loadingGrid)
          state.instanceAssociatedGrid.loadingGrid = true;
      });
  },
});

export const {
  clearCauseAreaSettingsState,
  setCauseAreaSettingsIsNew,
  setCauseAreaSettingName,
  setCauseAreaSettingDetailLinkUrl,
  setCauseAreaSettingDetailLinkText,
  setCauseAreaSettingPartnerName,
  setCauseAreaSettingDescription,
  setCauseAreaSettingListImageUrl,
  setCauseAreaSettingTooltip,
  setCauseAreaSettingSKUPrefix,
  setCauseAreaSettingSageVendorId,
  setCauseAreaSettingsTab,
  setCauseAreaSettingsCauseAreaId,
  setCauseAreaInstancesGridModel,
  setCauseAreaSettingsStatus,
  setCauseAreaSettingDetailImageName,
} = causeAreaSettingsSlice.actions;

export const causeAreaSettingsReducer = causeAreaSettingsSlice.reducer;
