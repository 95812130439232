import React, { useState, ChangeEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Tab,
  Tabs,
  TextField,
  Checkbox,
  Tooltip,
  Button,
  FormControlLabel,
  Card,
  Divider,
  CardContent,
  CardHeader,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import { fetchSioUser, postSioUserThunk } from './sioSlice';
import './Sio.scss';
import GeneralInfoDetails from './GeneralInfoDetails';
import OverViewAndMissionReview from './OverViewAndMissionReview';
import OperationalInformationReview from './OperationalInformationReview';
import { emailValid } from '../../shared/utils';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

const SioReviewAndSubmit = () => {
  const dispatch = useAppDispatch();
  const sioState = useAppSelector((state) => state.sios);
  const { sio } = sioState;
  const { id, password } = useParams();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const initialFormValue = {
    legalName: sio.general?.name?.legalOrganizationName,
    publicName: sio.general?.name?.publicOrganizationName,
    websiteUrl: sio.general?.social?.websiteURL,
    facebook: sio.general?.social?.facebook,
    linkedIn: sio.general?.social?.linkedIn,
    instagram: sio.general?.social?.instagram,
    twitter: sio.general?.social?.twitter,
    phoneNumber: sio.general?.contact?.phone,
    countryCode: sio.general?.contact?.countryCode,
    firstLastName: sio.general?.contact?.name,
    professionalTitle: sio.general?.contact?.professionalTitle,
    email: sio.general?.contact?.email,
    professionalPhoneNo: sio.general?.contact?.phone,
    registeredLocation: sio.general?.location?.specificLocation,
    committed: sio.general?.missionAgreement,
    missionHistory: sio.overviewAndMission?.historyDescription,
    problem: sio.overviewAndMission?.problemDescription,
    causeAreas: { selected: sio.overviewAndMission?.causeAreas?.selected },
    programInitiativesPriorites: sio.overviewAndMission?.initiativesDescription,
    programOperate: sio.overviewAndMission?.programLocations,
    researchEvaluation: sio.overviewAndMission?.researchAndEvaluation,
    fileUpload: sio.overviewAndMission?.researchAndEvaluationFile?.name,
    lifetimeOutputs: sio.overviewAndMission?.lifetimeOutputs ?? [],
    fullTimeStaff: sio.operationalInformation?.staff.fullTime,
    partTimeStaff: sio.operationalInformation?.staff.partTime,
    volunteers: sio.operationalInformation?.staff.volunteers,
    formalInformalRelationship: sio.operationalInformation?.partnershipsDescription,
    yearlyBudgetCurrency: sio.operationalInformation?.yearlyBudgetCurrency,
    budget: sio.operationalInformation?.yearlyBudget,
    financialStatement: sio.operationalInformation?.financialStatement?.file?.name,
    supportersPartners: sio.operationalInformation?.supportersAndPartners,
    reviewName: '',
    reviewEmail: '',
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [isMandatoryFilled, setIsMandatoryFilled] = useState(false);

  const payload = {
    status: 'submitted',
    general: {
      name: {
        legalOrganizationName: formValues.legalName,
        publicOrganizationName: formValues.publicName,
      },
      social: {
        facebook: formValues.facebook,
        instagram: formValues.instagram,
        linkedIn: formValues.linkedIn,
        twitter: formValues.twitter,
        websiteURL: formValues.websiteUrl,
      },
      contact: {
        professionalTitle: formValues.professionalTitle,
        name: formValues.firstLastName,
        phone: formValues.professionalPhoneNo,
        email: formValues.email,
        countryCode: formValues.countryCode,
      },
      location: {
        generalLocation: formValues.registeredLocation,
        specificLocation: formValues.registeredLocation,
      },
      missionAgreement: formValues.committed,
    },
    overviewAndMission: {
      historyDescription: formValues.missionHistory,
      problemDescription: formValues.problem,
      causeAreas: {
        selected: formValues.causeAreas.selected,
      },
      lifetimeOutputs: formValues.lifetimeOutputs,
      researchAndEvaluationFile: {
        name: formValues.fileUpload,
      },
      initiativesDescription: formValues.programInitiativesPriorites,
      programLocations: formValues.programOperate,
      researchAndEvaluation: formValues.researchEvaluation,
    },
    operationalInformation: {
      staff: {
        fullTime: formValues.fullTimeStaff,
        partTime: formValues.partTimeStaff,
        volunteers: formValues.volunteers,
      },
      financialStatement: {
        file: {
          name: formValues.financialStatement,
        },
      },
      partnershipsDescription: formValues.formalInformalRelationship,
      yearlyBudget: formValues.budget,
      yearlyBudgetCurrency: formValues.yearlyBudgetCurrency,
      supportersAndPartners: formValues.supportersPartners,
    },
    review: {
      name: formValues.reviewName,
      email: formValues.reviewEmail,
    },
  };
  useEffect(() => {
    if (id && password && sio) {
      dispatch(fetchSioUser({ id })).then((sioData: any) => {
        setFormValues({
          legalName: sioData.payload.general?.name?.legalOrganizationName ?? '',
          publicName: sioData.payload.general?.name?.publicOrganizationName ?? '',
          websiteUrl: sioData.payload.general?.social?.websiteURL ?? '',
          facebook: sioData.payload.general?.social?.facebook ?? '',
          linkedIn: sioData.payload.general?.social?.linkedIn ?? '',
          instagram: sioData.payload.general?.social?.instagram ?? '',
          twitter: sioData.payload.general?.social?.twitter ?? '',
          phoneNumber: sioData.payload.general?.contact?.phone ?? '',
          countryCode: sioData.payload.general?.contact?.countryCode ?? '',
          firstLastName: sioData.payload.general?.contact?.name ?? '',
          professionalTitle: sioData.payload.general?.contact?.professionalTitle ?? '',
          email: sioData.payload.general?.contact?.email ?? '',
          professionalPhoneNo: sioData.payload.general?.contact?.phone ?? '',
          registeredLocation: sioData.payload.general?.location?.specificLocation ?? '',
          committed: sioData.payload.general?.missionAgreement,
          missionHistory: sioData.payload.overviewAndMission?.historyDescription,
          problem: sioData.payload.overviewAndMission?.problemDescription,
          causeAreas: { selected: sioData.payload.overviewAndMission?.causeAreas?.selected },
          programInitiativesPriorites: sioData.payload.overviewAndMission?.initiativesDescription,
          programOperate: sioData.payload.overviewAndMission?.programLocations,
          researchEvaluation: sioData.payload.overviewAndMission?.researchAndEvaluation,
          fileUpload: sioData.payload.overviewAndMission?.researchAndEvaluationFile?.name,
          lifetimeOutputs: sioData.payload.overviewAndMission?.lifetimeOutputs ?? [],
          fullTimeStaff: sioData.payload.operationalInformation?.staff.fullTime,
          partTimeStaff: sioData.payload.operationalInformation?.staff.partTime,
          volunteers: sioData.payload.operationalInformation?.staff.volunteers,
          formalInformalRelationship:
            sioData.payload.operationalInformation?.partnershipsDescription,
          budget: sioData.payload.operationalInformation?.yearlyBudget,
          financialStatement: sioData.payload.operationalInformation?.financialStatement?.file.name,
          supportersPartners: sioData.payload.operationalInformation?.supportersAndPartners,
          yearlyBudgetCurrency: sioData.payload.operationalInformation?.yearlyBudgetCurrency ?? '',
          reviewName: '',
          reviewEmail: '',
        });
      });
    }
  }, [id, password]);

  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };
  const handleSioFormSubmit = async () => {
    const sioPayload = payload;
    if (id && password) {
      const response = await dispatch(postSioUserThunk({ sio: sioPayload, id }));
      if (response && response.payload) {
        toast.success('Successfully Submitted Form to ScaleWith');
        setFormValues(initialFormValue);
        setTermsAccepted(false);
      } else {
        toast.error('An error occurred while logging in. Please try again.');
      }
    }
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleTermsAcceptedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    setTermsAccepted(inputElement.checked);
  };
  const generalCompletion = Math.round(
    ((sio?.progress?.general?.complete ?? 0) / (sio?.progress?.general?.total ?? 1)) * 100,
  );
  const overviewCompletion = Math.round(
    ((sio?.progress?.overviewAndMission?.complete ?? 0) /
      (sio?.progress?.overviewAndMission?.total ?? 1)) *
      100,
  );
  const operationalCompletion = Math.round(
    ((sio?.progress?.operationalInformation?.complete ?? 0) /
      (sio?.progress?.operationalInformation?.total ?? 1)) *
      100,
  );

  useEffect(() => {
    if (generalCompletion === 100 && overviewCompletion === 100 && operationalCompletion === 100) {
      setIsMandatoryFilled(true);
    } else {
      setIsMandatoryFilled(false);
    }
  }, [generalCompletion, overviewCompletion, operationalCompletion]);

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: '0px',
          },
        },
      },
    },
  });

  const commonTabStyle = {
    tabsStyle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    tabStyle: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
      color: `${colors.textPrimary} !important`,
      textTransform: 'capitalize',
      marginRight: '20px',
      '&.Mui-selected': {
        border: `1px solid ${colors.strokeClick}`,
        backgroundColor: colors.backgroundPrimary,
      },
    },
    textStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: `${colors.textPrimary} !important`,
      '&.Mui-selected': {
        color: `${colors.primary} !important`,
      },
    },
  };

  const commonTextStyle = {
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  return (
    <section className="bgLight">
      {/* <TopBar /> */}
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardHeader
              sx={{
                padding: '20px 80px',
                background: colors.backgroundColorGradient,
                borderRadius: '20px 20px 0px 0px',
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        mb: 2,
                        fontSize: fonts.fontSize24,
                        fontWeight: fonts.fontWeight700,
                        color: colors.secondary,
                      }}
                    >
                      Review Your Application
                    </Typography>
                    <Typography
                      sx={{
                        color: colors.secondary,
                        fontSize: fonts.fontSize18,
                        fontWeight: fonts.fontWeight400,
                      }}
                    >
                      {' '}
                      You&apos;ve reached the end of ScaleWith&apos;s Social Impact Network
                      Application Form. By clicking &quot;Submit&quot; you represent and warrant
                      that all information and documentation you&apos;ve provided is truthful and
                      accurate. We will review your application and look forward to being in touch
                      soon. In the meantime, please contact a member of our team with any questions.
                      Thank you!{' '}
                    </Typography>
                  </Grid>
                </Grid>
              }
            />
            <CardContent sx={{ minHeight: '70vh', padding: '40px 80px' }}>
              <Grid container>
                <Grid item xs={12} className="secondContainer">
                  <ThemeProvider theme={theme}>
                    <Tabs
                      value={selectedTab}
                      onChange={handleTabClick}
                      style={commonTabStyle.tabsStyle}
                    >
                      <Tab
                        value={0}
                        sx={commonTabStyle.tabStyle}
                        icon={
                          <>
                            <span>General Information</span>
                            <span style={commonTabStyle.textStyle}>
                              {generalCompletion}% Completed
                            </span>
                          </>
                        }
                        className="tab tab1"
                      />
                      <Tab
                        value={1}
                        sx={commonTabStyle.tabStyle}
                        icon={
                          <>
                            <span>OverView and Mission</span>
                            <span style={commonTabStyle.textStyle}>
                              {overviewCompletion}% Completed
                            </span>
                          </>
                        }
                        className="tab tab2"
                      />
                      <Tab
                        value={2}
                        sx={commonTabStyle.tabStyle}
                        icon={
                          <>
                            <span>Operational Information</span>
                            <span style={commonTabStyle.textStyle}>
                              {operationalCompletion}% Completed
                            </span>
                          </>
                        }
                        className="tab tab3"
                      />
                    </Tabs>
                  </ThemeProvider>
                </Grid>
                <Grid item xs={12}>
                  {selectedTab === 0 && formValues.legalName && (
                    <GeneralInfoDetails SioData={formValues} />
                  )}
                  {selectedTab === 1 && <OverViewAndMissionReview SioData={formValues} />}
                  {selectedTab === 2 && <OperationalInformationReview SioData={formValues} />}
                  <Grid item xs={12}>
                    <Divider sx={{ my: 5 }} orientation="horizontal" flexItem />
                  </Grid>
                  {sioState?.sio?.status !== 'approved' ? (
                    <Grid container className="SubmitFormContainer" sx={{ border: 'none' }}>
                      <Typography
                        sx={{
                          fontSize: fonts.fontSize24,
                          fontWeight: fonts.fontWeight700,
                          color: colors.textPrimary,
                          my: 4,
                        }}
                      >
                        Please enter your name and email to submit the application to ScaleWith.
                      </Typography>
                      <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
                        <Grid item className="gridItem">
                          <Typography sx={commonTextStyle.labelStyle}>
                            Name <sup style={commonTextStyle.sup}>*</sup>
                            <Tooltip title="Enter The  Name">
                              <InfoIcon className="tooltipIcon" />
                            </Tooltip>
                          </Typography>
                          <TextField
                            name="reviewName"
                            value={formValues.reviewName}
                            onChange={handleInputChange}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                            fullWidth
                          />
                        </Grid>
                        <Grid item className="gridItem">
                          <Typography sx={commonTextStyle.labelStyle}>
                            Email Address <sup style={commonTextStyle.sup}>*</sup>
                            <Tooltip title="Enter The Email">
                              <InfoIcon className="tooltipIcon" />
                            </Tooltip>
                          </Typography>
                          <TextField
                            name="reviewEmail"
                            value={formValues.reviewEmail}
                            onChange={handleInputChange}
                            variant="outlined"
                            error={
                              !emailValid(formValues.reviewEmail) &&
                              formValues?.reviewEmail?.length > 0
                            }
                            sx={commonTextStyle.textFieldStyle}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{ my: 4 }}
                        direction="row"
                        className="agreeTermsSubmitForm"
                      >
                        <Grid xs={12} item className="gridItemCheckbox">
                          <FormControlLabel
                            sx={{ display: 'flex', gap: '1rem' }}
                            control={
                              <Checkbox
                                checked={termsAccepted}
                                onChange={handleTermsAcceptedChange}
                                sx={{ fontSize: '2rem' }}
                              />
                            }
                            label={
                              <span
                                style={{
                                  fontSize: fonts.fontSize18,
                                  fontWeight: fonts.fontWeight500,
                                }}
                              >
                                I agree to the{' '}
                                <a
                                  href="https://www.scalewith.com/terms-and-conditions/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: 'none',
                                    fontWeight: fonts.fontWeight600,
                                    color: colors.strokeClick,
                                  }}
                                >
                                  Terms And Conditions
                                </a>{' '}
                                &{' '}
                                <a
                                  href="https://www.scalewith.com/privacy-policy/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: 'none',
                                    fontWeight: fonts.fontWeight600,
                                    color: colors.strokeClick,
                                  }}
                                >
                                  Privacy Policy
                                </a>{' '}
                                hereby represents and warrants that all information contained herein
                                will be truthful and accurate as of the date of said submission.
                              </span>
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography variant="h4">Note: Form is already submitted.</Typography>
                  )}
                </Grid>
                <Divider sx={{ mt: 4, mb: 2 }} orientation="horizontal" flexItem />
                {sioState?.sio?.status !== 'approved' && (
                  <Grid item xs={12} textAlign="right">
                    <Button
                      variant="outlined"
                      disabled={
                        !formValues.reviewEmail ||
                        !formValues.reviewName ||
                        !termsAccepted ||
                        !isMandatoryFilled ||
                        !emailValid(formValues.reviewEmail)
                      }
                      sx={{
                        backgroundColor: `${
                          formValues.reviewEmail &&
                          formValues.reviewName &&
                          termsAccepted &&
                          colors.primary
                        }`,
                        color: `${
                          formValues.reviewEmail &&
                          formValues.reviewName &&
                          termsAccepted &&
                          colors.secondary
                        }`,
                        '&:hover': {
                          backgroundColor: colors.primary,
                          color: colors.secondary,
                        },
                      }}
                      onClick={handleSioFormSubmit}
                    >
                      Submit to ScaleWith
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
    // <Grid container sx={{ padding: '2rem' }}>
    //   <Grid
    //     container
    //     spacing={0}
    //     justifyContent="center"
    //     className="sioFormFilling-container"
    //     sx={{ borderRadius: '30px', padding: '20px' }}
    //   >

    //     <Grid item xs={12} className="secondContainer" sx={{ paddingBottom: '4rem' }}>

    //     </Grid>
    //   </Grid>
    // </Grid>
  );
};

export default SioReviewAndSubmit;
