import React, { FC } from 'react';
import { TextField, Typography, Box } from '@mui/material';
import { FlexBox } from '../../../shared/components';
import { InstanceFormConfig } from '../../../shared/interfaces';
import { useAppDispatch } from '../../../shared/hooks';
import { setInstanceSettingsData } from './instanceSettingsSlice';

interface Props {
  value: InstanceFormConfig;
}

export const InstanceSettingsSage: FC<Props> = (props) => {
  const { value } = props;
  const {
    sage: {
      annualSubscription,
      exchangeRate,
      locationId,
      monthlySubscription,
      sales,
      socialImpact,
      socialImpactPayable,
      socialImpactEnablement,
      subscriptionRevenue,
      transaction,
      transactionRevenue,
      billTaxDetailId,
      invoiceTaxDetailId,
      taxSolutionId,
    },
  } = value;
  const dispatch = useAppDispatch();

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 3, maxHeight: 70 }}>
      <FlexBox sx={{ gap: 3 }}>
        <TextField
          sx={{ flex: 1 }}
          label={locationId.label}
          value={locationId.value}
          required={locationId.required}
          onChange={(event) => {
            dispatch(
              setInstanceSettingsData({ settings: { sage: { locationId: event.target.value } } }),
            );
          }}
        />
        <TextField
          sx={{ flex: 1 }}
          label={exchangeRate.label}
          value={exchangeRate.value}
          required={exchangeRate.required}
          onChange={(event) => {
            dispatch(
              setInstanceSettingsData({ settings: { sage: { exchangeRate: event.target.value } } }),
            );
          }}
        />
      </FlexBox>
      <TextField
        sx={{ flex: 1 }}
        label={taxSolutionId.label}
        value={taxSolutionId.value}
        required={taxSolutionId.required}
        onChange={(event) => {
          dispatch(
            setInstanceSettingsData({ settings: { sage: { taxSolutionId: event.target.value } } }),
          );
        }}
      />
      <FlexBox sx={{ gap: 3 }}>
        <TextField
          sx={{ flex: 1 }}
          label={invoiceTaxDetailId.label}
          value={invoiceTaxDetailId.value}
          required={invoiceTaxDetailId.required}
          onChange={(event) => {
            dispatch(
              setInstanceSettingsData({
                settings: { sage: { invoiceTaxDetailId: event.target.value } },
              }),
            );
          }}
        />
        <TextField
          sx={{ flex: 1 }}
          label={billTaxDetailId.label}
          value={billTaxDetailId.value}
          required={billTaxDetailId.required}
          onChange={(event) => {
            dispatch(
              setInstanceSettingsData({
                settings: { sage: { billTaxDetailId: event.target.value } },
              }),
            );
          }}
        />
      </FlexBox>
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h5">Accounts</Typography>
        <FlexBox sx={{ gap: 3, marginTop: 1, flexDirection: 'column' }}>
          <FlexBox sx={{ gap: 3 }}>
            <TextField
              sx={{ flex: 1 }}
              label={socialImpactPayable.label}
              value={socialImpactPayable.value}
              required={socialImpactPayable.required}
              onChange={(event) => {
                dispatch(
                  setInstanceSettingsData({
                    settings: { sage: { accounts: { socialImpactPayable: event.target.value } } },
                  }),
                );
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              label={socialImpactEnablement.label}
              value={socialImpactEnablement.value}
              required={socialImpactEnablement.required}
              onChange={(event) => {
                dispatch(
                  setInstanceSettingsData({
                    settings: {
                      sage: { accounts: { socialImpactEnablement: event.target.value } },
                    },
                  }),
                );
              }}
            />
          </FlexBox>
          <FlexBox sx={{ gap: 3 }}>
            <TextField
              sx={{ flex: 1 }}
              label={subscriptionRevenue.label}
              value={subscriptionRevenue.value}
              required={subscriptionRevenue.required}
              onChange={(event) => {
                dispatch(
                  setInstanceSettingsData({
                    settings: { sage: { accounts: { subscriptionRevenue: event.target.value } } },
                  }),
                );
              }}
            />
            <TextField
              sx={{ flex: 1 }}
              label={transactionRevenue.label}
              value={transactionRevenue.value}
              required={transactionRevenue.required}
              onChange={(event) => {
                dispatch(
                  setInstanceSettingsData({
                    settings: { sage: { accounts: { transactionRevenue: event.target.value } } },
                  }),
                );
              }}
            />
          </FlexBox>
        </FlexBox>
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h5">Departments</Typography>

        <FlexBox sx={{ gap: 3, marginTop: 1 }}>
          <TextField
            sx={{ flex: 1 }}
            label={sales.label}
            value={sales.value}
            required={sales.required}
            onChange={(event) => {
              dispatch(
                setInstanceSettingsData({
                  settings: { sage: { departments: { sales: event.target.value } } },
                }),
              );
            }}
          />
          <TextField
            sx={{ flex: 1 }}
            label={socialImpact.label}
            value={socialImpact.value}
            required={socialImpact.required}
            onChange={(event) => {
              dispatch(
                setInstanceSettingsData({
                  settings: { sage: { departments: { socialImpact: event.target.value } } },
                }),
              );
            }}
          />
        </FlexBox>
      </Box>
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="h5">Products</Typography>
        <FlexBox sx={{ flexDirection: 'column', gap: 3, marginTop: 1 }}>
          <TextField
            label={annualSubscription.label}
            value={annualSubscription.value}
            required={annualSubscription.required}
            onChange={(event) => {
              dispatch(
                setInstanceSettingsData({
                  settings: { sage: { products: { annualSubscription: event.target.value } } },
                }),
              );
            }}
          />
          <TextField
            label={monthlySubscription.label}
            value={monthlySubscription.value}
            required={monthlySubscription.required}
            onChange={(event) => {
              dispatch(
                setInstanceSettingsData({
                  settings: { sage: { products: { monthlySubscription: event.target.value } } },
                }),
              );
            }}
          />
          <TextField
            label={transaction.label}
            value={transaction.value}
            required={transaction.required}
            onChange={(event) => {
              dispatch(
                setInstanceSettingsData({
                  settings: { sage: { products: { transaction: event.target.value } } },
                }),
              );
            }}
          />
        </FlexBox>
      </Box>
    </FlexBox>
  );
};
