import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Button,
  useMediaQuery,
  Grid,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassBottom from '@mui/icons-material/HourglassBottom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ButtonBack, ButtonNext, CarouselProvider, Slider } from 'pure-react-carousel';
import { ReactComponent as UnionIcon } from '../../assets/svg/UnionIcon.svg';
import { GridColumns, SioStatus } from '../../shared/enums';
import { getGenericHeight, getGridRequest } from '../../shared/utils';
import { CustomGridColDef } from '../../shared/interfaces';
import { SioStatusCell } from '../../shared/components/SiosStatusCell';
import { CommonGridFilterHandle, FlexBox, IsolatedGrid } from '../../shared/components';
import {
  fetchProgramListThunk,
  clearProgramsState,
  clearProgramsUpdateSuccess,
  setProgramsGridModel,
  setProgramsStatus,
  fetchProgramStatus,
} from './ProgramSlice';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';
import borders from '../../assets/scss/strokes.module.scss';
import { getTenantIdCookie } from '../../api';
import ProgramFilterSearch from './components/ProgramFilterSearch';

interface TabInterface {
  label: string;
  value: SioStatus | null;
  count: number;
}

interface TenantAccessGrant {
  id: string;
  createdBy: string;
  createdAt: {
    $date: string;
  };
  lastUpdatedBy: string;
  lastUpdated: {
    $date: string;
  };
  default: boolean;
  friendlyName: string;
  roles: string[];
  instanceId: {
    $oid: string;
  };
  app: string;
  type: string;
}

const ProgramList = () => {
  const programState = useAppSelector((state) => state.programs);
  // const programs = useAppSelector((state) => state.programs.grid.data.results);
  const user = useAppSelector((x) => x.myProfile.user);
  const tenant = getTenantIdCookie();
  const dispatch = useAppDispatch();
  const { grid, updateStatusSuccess, status: programStatus } = programState;
  const { sort, filter, page, pageSize } = grid;
  const { totalCount } = grid.data;
  const [searchQuery, setSearchQuery] = useState<any>({
    applicationFormName: '',
    programName: '',
    publicOrganizationName: '',
  });
  const [hasAdminRole, setHasAdminRole] = useState(false);
  const [filteredItems, setFilteredItems] = useState(grid.data.results);
  const filterRef = useRef<CommonGridFilterHandle>(null);
  const navigate = useNavigate();
  const [key, setKey] = useState<any>();
  const [value, setValue] = useState<any>();
  const [activeTab, setActiveTab] = useState<any>(null);
  const [tabs, setTabs] = useState<TabInterface[]>([
    { label: 'All', value: null, count: 0 },
    { label: 'In Progress', value: SioStatus.InProgress, count: 0 },
    { label: 'Pending Approval', value: SioStatus.PendingApproval, count: 0 },
    { label: 'Approved', value: SioStatus.Approved, count: 0 },
    { label: 'Archived', value: SioStatus.Archived, count: 0 },
  ]);
  const isMediumDevice = useMediaQuery(`(min-width:768px) and (max-width:1023px`);
  const isLg = useMediaQuery(`(min-width: 1024px) and (max-width:1279px`);
  const isXL = useMediaQuery(`(min-width: 1280px) and (max-width:1535px`);
  const isXXL = useMediaQuery(`(min-width: 1536px)`);

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: colors.primary,
            height: '4px',
          },
        },
      },
    },
  });

  let slides: any = 1;
  if (isMediumDevice) {
    slides = 3;
  } else if (isLg) {
    slides = 2;
  } else if (isXL || isXXL) {
    slides = 3;
  }

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      setActiveTab(null);
      dispatch(setProgramsStatus(''));
    };
  }, []);

  useEffect(() => {
    if (user) {
      const correctTenant = (user.tenantAccessGrants as TenantAccessGrant[] | undefined)?.find(
        (grant) => grant.id === tenant,
      );

      if (correctTenant) {
        const givewithRoleExists = correctTenant.roles.includes('givewith');
        setHasAdminRole(givewithRoleExists);
      } else {
        setHasAdminRole(false);
      }
    }
  }, [user, tenant]);

  useEffect(() => {
    dispatch(fetchProgramStatus());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProgramStatus()).then((programdata: any) => {
      const totalStatusCount = programdata.payload?.status_list.reduce(
        (total: number, status: any) => total + status.count,
        0,
      );
      const updatedTabs = tabs.map((tab) => {
        if (tab.label === 'All') {
          return {
            ...tab,
            count: totalStatusCount,
          };
        }
        /* eslint-disable no-underscore-dangle */
        const status = programdata?.payload?.status_list?.find((s: any) => s._id === tab.value);
        return {
          ...tab,
          count: status ? status.count : 0,
        };
      });

      setTabs(updatedTabs);
    });
  }, []);

  useEffect(() => {
    dispatch(setProgramsGridModel({ data: { results: filteredItems, totalCount } }));
  }, [dispatch]);

  useEffect(() => {
    setFilteredItems(grid.data.results);
  }, [grid]);

  useEffect(() => {
    if (key && value) {
      const fieldValue: any = value?.id ? value?.id : value;
      dispatch(
        fetchProgramListThunk({
          status: programStatus,
          ...getGridRequest(grid),
          [key.value]: fieldValue,
        }),
      );
    } else {
      dispatch(fetchProgramListThunk({ status: programStatus, ...getGridRequest(grid) }));
    }
  }, [sort, filter, page, programStatus, pageSize, searchQuery, value]);

  useEffect(() => {
    dispatch(fetchProgramListThunk({ status: activeTab, ...getGridRequest(grid) }));
  }, []);

  useEffect(
    () => () => {
      dispatch(clearProgramsState());
    },
    [dispatch],
  );
  useEffect(() => {
    dispatch(clearProgramsUpdateSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatusSuccess]);

  const clearInputControllers = () => {
    if (!_.isNil(searchQuery))
      setSearchQuery({ applicationFormName: '', programName: '', publicOrganizationName: '' });
  };

  const clearFilterController = () => {
    clearInputControllers();
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearchQuery({ applicationFormName: '', programName: '', publicOrganizationName: '' });
    setActiveTab(newValue);
    dispatch(setProgramsStatus(newValue));
    filterRef.current?.clearFilterSelections();
    clearFilterController();
  };

  const getStatusDisplayValue = (status: string): string => {
    switch (status) {
      case 'approved':
        return 'Approved';
      case 'submitted':
        return 'Submitted';
      case 'in_progress':
        return 'In Progress';
      default:
        return status;
    }
  };

  const columns: Array<CustomGridColDef> = [
    {
      field: 'srno',
      headerName: 'Sr No',
      type: 'number',
      flex: 0.5,
      align: 'left',
      renderCell: (params) => {
        const { api } = params;
        const rowIndex = api.getRowIndex(params.id);
        return rowIndex + 1;
      },
      headerAlign: 'left',
    },
    {
      field: 'applicationFormName',
      headerName: 'Submission Forms',
      renderCell: (params) => <span>{params.row.applicationFormName}</span>,
      flex: 1,
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      renderCell: (params) => <span>{params.row.programName}</span>,
    },
    {
      field: 'siAdminName',
      headerName: 'Scalewith Partnership Manager',
      renderCell: (params) => (
        <span>
          {params.row.siAdmin_firstname} {params.row.siAdmin_lastname}
        </span>
      ),
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated On',
      flex: 1,
      type: GridColumns.Date,
      renderCell: (params) => (
        <Box>
          {new Date(params.row.lastUpdated).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      type: GridColumns.SingleSelect,
      flex: 1,
      minWidth: 250,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div
          style={{
            ...(params.row.status === SioStatus.Approved && {
              backgroundColor: colors.statusBackgroundSuccess,
              color: colors.statusSuccess,
              border: `1px solid ${colors.statusSuccess}`,
            }),
            ...(params.row.status === SioStatus.Submitted && {
              backgroundColor: colors.statusBackgroundWarning,
              border: `1px solid ${colors.statusWarning}`,
              color: colors.statusWarning,
            }),
            ...(params.row.status === SioStatus.InProgress && {
              backgroundColor: colors.statusBackgroundInfo,
              border: `1px solid ${colors.statusInfo}`,
              fontWeight: fonts.fontWeight400,
              color: colors.statusInfo,
            }),
            ...(params.row.status === SioStatus.Archived && {
              backgroundColor: '#DFE2E6',
              color: '#000000',
              border: `1px solid #333333`,
              fontWeight: fonts.fontWeight400,
            }),
            borderRadius: '25px',
            display: 'flex',
            alignItems: 'center',
            padding: '2px 0px 2px 15px',
            justifyContent: 'left',
            textTransform: 'capitalize',
            width: 200,
            whiteSpace: 'nowrap',
          }}
        >
          {params.row.status === SioStatus.InProgress && <HourglassBottom />}
          {params.row.status === SioStatus.Approved && <CheckCircleIcon />}
          {params.row.status === SioStatus.Submitted && <WatchLaterIcon />}
          {params.row.status === SioStatus.Archived && <UnionIcon />}
          <SioStatusCell>
            {params.row.status === SioStatus.InProgress
              ? `${getStatusDisplayValue(params.row.status)} ${params.row.percentComplete}%`
              : getStatusDisplayValue(params.row.status)}
          </SioStatusCell>
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 180,
      renderCell: ({ row: { id } }) => (
        <Button
          variant="outlined"
          // color="info"
          // sx={{ backgroundColor: '#fff' }}
          sx={{
            backgroundColor: colors.secondary,
            fontSize: fonts.fontSize14,
            fontWeight: fonts.fontWeight700,
            color: colors.primary,
            borderRadius: borders.borderRadius8,
            borderColor: colors.primary,
            '&:focus': colors.primary,
            '&:hover': colors.primary,
          }}
          endIcon={<ArrowForwardIcon />}
          onClick={() =>
            hasAdminRole
              ? navigate(`/app/sio/admin/programs/${id}`)
              : navigate(`/app/sio/programs/${id}`)
          }
        >
          <span style={{ marginLeft: '0.5rem' }}>View Details</span>
        </Button>
      ),
    },
  ];

  if (hasAdminRole) {
    columns.splice(2, 0, {
      field: 'publicOrganizationName',
      headerName: 'SIO Name',
      renderCell: (params) => <span>{params.row.publicOrganizationName}</span>,
      flex: 1,
    });
  }

  return (
    <Box className="dashboard" sx={{ pt: '12px', pb: '1px' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: '2rem',
        }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <Box sx={{ marginBottom: '1.5rem' }}>
            <FlexBox sx={{ gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '10px 0px',
                }}
              >
                <Typography
                  flex={1}
                  sx={{
                    flex: 1,
                    color: colors.textPrimary,
                    fontSize: fonts.fontSize24,
                    fontWeight: 700,
                  }}
                >
                  Programs
                </Typography>
                <Typography
                  flex={1}
                  variant="body1"
                  sx={{
                    flex: 1,
                    color: '#BCBEBF',
                    fontSize: '12pt',
                    fontWeight: 700,
                  }}
                >
                  View, Edit, Update and Create Social Impact Programs
                </Typography>
              </Box>
            </FlexBox>
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              gap: '1rem',
              marginLeft: '13px',
              borderBottom: '1px solid #e9ecef',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Grid item md={6} sm={12} sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <CarouselProvider
                  naturalSlideHeight={25}
                  naturalSlideWidth={100}
                  totalSlides={tabs?.length}
                  visibleSlides={slides}
                  className="tabs-carousel"
                  step={slides - 1}
                >
                  <Slider>
                    <Box my={1}>
                      <ThemeProvider theme={theme}>
                        <Tabs
                          value={activeTab}
                          onChange={handleTabChange}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '2rem',
                            flex: '1 0 auto',
                          }}
                        >
                          {tabs.map((tab) => (
                            <Tab
                              key={`common-order-tab-${tab.value}`}
                              value={tab.value}
                              label={`${tab.label} (${tab.count})`}
                              sx={{
                                textTransform: 'capitalize',
                                fontSize: fonts.fontSize18,
                                fontWeight: `${
                                  tab.value === activeTab
                                    ? fonts.fontWeight700
                                    : fonts.fontWeight600
                                }`,
                                color: colors.textPrimary,
                              }}
                            />
                          ))}
                        </Tabs>
                      </ThemeProvider>
                    </Box>
                  </Slider>
                  {hasAdminRole && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <ButtonBack className="tab-carousel-btn-back">
                        <KeyboardArrowLeftOutlinedIcon />
                      </ButtonBack>
                      <ButtonNext className="tab-carousel-btn-next">
                        <KeyboardArrowRightOutlinedIcon />
                      </ButtonNext>
                    </Box>
                  )}
                </CarouselProvider>
              </Grid>{' '}
              {hasAdminRole && (
                <FlexBox
                  sx={{
                    gap: 0.5,
                    alignItems: 'center',
                    padding: '0px 10px',
                    marginBottom: '15px',
                  }}
                >
                  <ProgramFilterSearch
                    onChangeField={(data: any) => {
                      setKey(data);
                    }}
                    onChangeValue={(data: string) => setValue(data)}
                  />
                </FlexBox>
              )}
              <Button
                variant="outlined"
                onClick={() => navigate('create-program')}
                sx={{
                  height: '48px',
                  marginLeft: 'auto',
                  paddingX: '16px',
                  fontWeight: fonts.fontWeight700,
                  fontSize: fonts.fontSize14,
                  backgroundColor: `${colors.primary} !important`,
                  color: `${colors.secondary} !important`,
                  borderRadius: '8px !important',
                  '&.Mui-disabled': {
                    backgroundColor: `${colors.strokeDefault} !important`,
                  },
                }}
              >
                Add New Program
              </Button>
            </Box>
          </Box>

          <Box sx={{ minHeight: '400px', height: getGenericHeight(300), border: 'none' }}>
            <IsolatedGrid
              status={activeTab}
              model={grid}
              columns={columns}
              onChange={(x) => dispatch(setProgramsGridModel(x))}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgramList;
