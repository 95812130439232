import React, { FC } from 'react';
import { Button, ButtonProps, SxProps, Theme } from '@mui/material';
import { Dialogs, ContributionActions, ContributionStatusLabel } from '../../enums';
import { useAppDispatch } from '../../hooks';
import { setDialog } from '../../../features/main-screen/mainScreenSlice';
import { setUpdateContributionStatusRequest } from './updateIncentiveStatusSlice';
import { ORDERS_POSITIVE_ACTIONS } from '../../constants';

interface Props {
  id: string;
  proposedStatus: ContributionActions;
  action: ContributionStatusLabel;
  buttonProps?: ButtonProps;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  showSuccessModal?: boolean;
  isPositiveStatus?: boolean;
}

export const UpdateContributionStatusButton: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const {
    id,
    proposedStatus,
    action,
    sx,
    buttonProps,
    showSuccessModal,
    disabled,
    isPositiveStatus,
  } = props;

  const isPositiveStatusLocal =
    ORDERS_POSITIVE_ACTIONS.includes(proposedStatus) || isPositiveStatus;

  return (
    <Button
      variant="outlined"
      color={isPositiveStatusLocal ? 'success' : 'error'}
      sx={{
        backgroundColor: '#fff',
        ...sx,
      }}
      disabled={disabled}
      onClick={() => {
        dispatch(
          setUpdateContributionStatusRequest({
            id,
            proposedStatus,
            action,
            showSuccessModal,
          }),
        );
        dispatch(setDialog({ open: true, type: Dialogs.UpdateContributionStatus, maxWidth: 'md' }));
      }}
      {...buttonProps}
    >
      {action}
    </Button>
  );
};
