import React, { ChangeEvent, useEffect, useState } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Checkbox,
  Button,
  Box,
  Tooltip,
  TextField,
  Divider,
  Card,
  CardContent,
  Typography,
  CardHeader,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import '../sio/formFilling.scss';
import { fetchProgrambyUser, updateProgrambyUserThunk } from './ProgramSlice';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { getVocab } from '../../api/portal/programRequest';
import { Vocabulary } from '../../shared/interfaces/program/program';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#68839A',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#FFF',
  },
}));

interface Output {
  id: string;
  quantity: number;
  description: string;
}

const outputForm = [{ id: '', quantity: 0, description: '' }];
const outcomesForm = [{ id: '', quantity: 0, description: '' }];

const ProgramStrategiesQuestions = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const programState = useAppSelector((state) => state.programs);
  const { program } = programState;
  const initialFormValue = {
    strategies: program?.strategiesAndApproaches?.strategies ?? [],
    activities: program?.strategiesAndApproaches?.activities?.value,
    approachDuration: program?.strategiesAndApproaches?.approachDuration,
    activitiesFrequency: program?.strategiesAndApproaches?.activitiesFrequency,
    outputs: outputForm,
    outcomes: outcomesForm,
    dataMeasurementType: program?.strategiesAndApproaches?.dataMeasurementType,
    nonprofitPartners: program?.strategiesAndApproaches?.nonprofitPartners ?? false,
    nonprofitPartnersDescription:
      program?.strategiesAndApproaches?.nonprofitPartnersDescription ?? '',
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [pageNumber, setPageNumber] = useState(1);
  const [isNonProfitDesc, setIsNonprofirDesc] = useState(
    program?.strategiesAndApproaches?.nonprofitPartners,
  );
  const [vocab, setVocab] = useState<Vocabulary>();

  useEffect(() => {
    getVocab().then((res) => {
      if (res.status === 200) {
        setVocab(res.data);
      }
    });
    if (id && program) {
      dispatch(fetchProgrambyUser(id)).then((programData: any) => {
        setFormValues({
          strategies: programData.payload?.strategiesAndApproaches?.strategies ?? [],
          activities: programData.payload?.strategiesAndApproaches?.activities?.value,
          approachDuration: programData.payload?.strategiesAndApproaches?.approachDuration,
          activitiesFrequency: programData.payload?.strategiesAndApproaches?.activitiesFrequency,
          outputs: programData.payload?.strategiesAndApproaches?.outputs ?? outputForm,
          outcomes: programData.payload?.strategiesAndApproaches?.outcomes ?? outputForm,
          dataMeasurementType: programData.payload?.strategiesAndApproaches?.dataMeasurementType,
          nonprofitPartners:
            programData.payload?.strategiesAndApproaches?.nonprofitPartners ?? false,
          nonprofitPartnersDescription:
            programData?.payload?.strategiesAndApproaches?.nonprofitPartnersDescription ?? '',
        });
        // savePageNumber()
      });
    }
  }, [id]);

  const updateProgramDetails = () => {
    const payload = {
      strategiesAndApproaches: {
        strategies: formValues.strategies,
        activities: { value: formValues.activities },
        activitiesFrequency: formValues.activitiesFrequency,
        approachDuration: formValues.approachDuration,
        outputs: formValues.outputs,
        outcomes: formValues.outcomes,
        dataMeasurementType: formValues.dataMeasurementType,
        nonprofitPartners: formValues.nonprofitPartners,
        nonprofitPartnersDescription: formValues.nonprofitPartnersDescription,
      },
    };
    const programPayload = payload;
    if (id) {
      return dispatch(updateProgrambyUserThunk({ program: programPayload, id }));
    }
    return true;
  };

  const handlePreviousbutton = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextButton = async () => {
    const response = await updateProgramDetails();
    if (response) {
      // toast.success('Successfully Submitted Form to ScaleWith');
      if (pageNumber < 8) {
        setPageNumber(pageNumber + 1);
      } else {
        navigate(`/app/sio/programs/${id}`);
      }
    } else {
      toast.error('An error occurred while submitting in. Please try again.');
    }
    return null;
  };

  const handleSaveExit = async () => {
    const response = await updateProgramDetails();
    if (response) {
      // toast.success('Successfully Submitted Form to ScaleWith');
      navigate(`/app/sio/programs/${id}`);
    } else {
      toast.error('An error occurred while logging in. Please try again.');
    }
    return null;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'nonprofitPartners') {
      if (value === 'yes') {
        setFormValues({ ...formValues, [name]: true });
        setIsNonprofirDesc(true);
      } else {
        setFormValues({ ...formValues, [name]: false, nonprofitPartnersDescription: '' });
        setIsNonprofirDesc(false);
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let newvalue = formValues.strategies;
    if (newvalue.includes(value)) {
      newvalue = newvalue.filter((item) => value !== item);
    } else {
      newvalue = [...newvalue, value];
    }
    setFormValues({
      ...formValues,
      strategies: newvalue,
    });
  };

  const handleOutputChange =
    (field: keyof Output, index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      let numericValue: number | null = null;

      if (field === 'quantity') {
        const filteredValue = value.replace(/[^0-9]/g, '');
        numericValue = parseInt(filteredValue, 10);

        if (Number.isNaN(numericValue) || numericValue < 0) {
          numericValue = 0; // Reset to 0 if the input is not a valid number
        } else if (pageNumber === 6 && numericValue > 100) {
          // Condition for the percentage fields, adjust as necessary
          toast.warning('Percentage should not exceed 100%');
          return;
        }
      }

      setFormValues((prevValues) => {
        const newValues = { ...prevValues };
        if (pageNumber === 5) {
          // Assume pageNumber 5 corresponds to question 5 dealing with outputs
          const updatedOutputs = newValues.outputs.map((output, i) =>
            i === index
              ? { ...output, [field]: field === 'quantity' ? numericValue : value }
              : output,
          );
          newValues.outputs = updatedOutputs;
        } else if (pageNumber === 6) {
          // Assume pageNumber 6 corresponds to question 6 dealing with outcomes
          const updatedOutcomes = newValues.outcomes.map((outcome, i) =>
            i === index
              ? { ...outcome, [field]: field === 'quantity' ? numericValue : value }
              : outcome,
          );
          newValues.outcomes = updatedOutcomes;
        }
        return newValues;
      });
    };

  let outputIdCounter = 0;
  let outconmeIdCounter = 0;

  function generateOutputId() {
    const oid = `output-${outputIdCounter}`;
    outputIdCounter += 1;
    return oid;
  }

  function generateOutcomeId() {
    const oid = `output-${outconmeIdCounter}`;
    outconmeIdCounter += 1;
    return oid;
  }

  const addOutput = () => {
    setFormValues((prevValues) => {
      if (prevValues.outputs.length >= 3) {
        toast.warning('You can only add up to 3 outputs.');
        return prevValues;
      }

      const newOutput = { id: generateOutputId(), quantity: 0, description: '' };

      return {
        ...prevValues,
        outputs: [...prevValues.outputs, newOutput],
      };
    });
  };

  const addOutcome = () => {
    setFormValues((prevValues) => {
      if (prevValues.outcomes.length >= 3) {
        toast.warning('You can only add up to 3 outputs.');
        return prevValues;
      }

      const newOutcome = { id: generateOutcomeId(), quantity: 0, description: '' };

      return {
        ...prevValues,
        outcomes: [...prevValues.outcomes, newOutcome],
      };
    });
  };

  const removeOutput = (index: number) => {
    setFormValues((prevValues) => {
      if (prevValues.outputs.length <= 1) {
        toast.warning('You must have at least one output.');
        return prevValues;
      }

      const newOutputs = prevValues.outputs.filter((_, i) => i !== index);

      return { ...prevValues, outputs: newOutputs };
    });
  };

  const removeOutcome = (index: number) => {
    setFormValues((prevValues) => {
      if (prevValues.outcomes.length <= 1) {
        toast.warning('You must have at least one outcome.');
        return prevValues;
      }

      const newOutcomes = prevValues.outcomes.filter((_, i) => i !== index);
      return { ...prevValues, outcomes: newOutcomes };
    });
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  // *** Start Questions ***
  const question1 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 5 }} variant="h3">
          Let&apos;s get started!
        </Typography>
        <Typography sx={commonTextStyle.questionStyle}>Question 1 of 8</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          How would you classify the strategies this program employees?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>Please select all that apply.</Typography>
      </Grid>

      {
        /* eslint no-underscore-dangle: 0 */
        vocab?.programApproach.map((item: any) => (
          <Grid key={item._id} item>
            <Box
              className={formValues.strategies.includes(item._id) ? 'active' : ''}
              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
            >
              <Typography variant="body1" display="inline">
                {item.label}
              </Typography>
              <Checkbox
                checked={formValues.strategies.includes(item._id)}
                value={item._id}
                onChange={handleChangeSelect}
                style={{
                  color: `${formValues.strategies.includes(item._id) ? colors.primary : ''}`,
                }}
              />
            </Box>
          </Grid>
        ))
      }
    </Grid>
  );

  const question2 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 2 of 8</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Describe the specific activities you will undertake to implement your program.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Description
          <Tooltip title="Enter activities in your program">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="activities"
          placeholder="Enter activities in your program"
          fullWidth
          value={formValues.activities}
          onChange={handleChange}
          variant="outlined"
          multiline
          rows={4}
          sx={commonTextStyle.textAreaStyle}
        />
      </Grid>
    </Grid>
  );

  const question3 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 3 of 8 </Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Is the program intervention one-time or ongoing?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            sx={{ display: 'inline-block' }}
            name="approachDuration"
            value={formValues.approachDuration}
            onChange={handleChange}
          >
            {vocab?.approachDuration.map((item: any) => (
              <FormControlLabel
                sx={{ p: 2, mx: 2, border: '1px solid #E0E0E0', borderRadius: '20px' }}
                labelPlacement="start"
                key={item._id}
                value={item._id}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );

  const question4 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 4 of 8</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Describe the frequency and length of the program activities.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Description
          <Tooltip title="Enter length of program">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="activitiesFrequency"
          placeholder="Enter length of program"
          fullWidth
          value={formValues.activitiesFrequency}
          onChange={handleChange}
          variant="outlined"
          multiline
          rows={4}
          sx={commonTextStyle.textAreaStyle}
        />
      </Grid>
    </Grid>
  );

  const question5 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 5 of 8</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          What outputs does this funding create?
        </Typography>
        <Typography sx={{ mb: 2 }} variant="body1">
          These outputs should be based on the entire funding amount provided by this opportunity
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8}>
        {formValues?.outputs?.map((output, index) => (
          <Grid container spacing={4} key={output.id}>
            {' '}
            {/* Use index for key for simplicity */}
            <Grid item xs={12} sm={2} mb={2}>
              <Typography sx={commonTextStyle.labelStyle}>
                Output Unit {index + 1}
                <Tooltip title="Enter the quantity of each output created by this funding">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                name="quantity"
                value={output.quantity || ''} // Handle potential null/undefined values
                onChange={handleOutputChange('quantity', index)}
                variant="outlined"
                fullWidth
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <Typography sx={commonTextStyle.labelStyle}>
                Output Description {index + 1}
                <Tooltip title="Describe the output created by this funding">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                name="description"
                type="text"
                value={output.description}
                onChange={handleOutputChange('description', index)}
                sx={commonTextStyle.textFieldStyle}
                variant="outlined"
                fullWidth
              />
            </Grid>
            {formValues.outputs.length > 1 && (
              <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  onClick={() => removeOutput(index)}
                  sx={{ color: '#249ACB', marginTop: '8px' }}
                >
                  Remove
                </Button>
              </Grid>
            )}
          </Grid>
        ))}
        {formValues.outputs.length < 3 && (
          <Button
            onClick={addOutput}
            variant="contained"
            sx={{
              backgroundColor: '#249ACB',
              ':hover': { color: '#249ACB', backgroundColor: 'white' },
              marginTop: '8px',
            }}
          >
            Add Another Output
          </Button>
        )}
      </Grid>

      <Grid
        item
        xs={4}
        sm={4}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Grid item>
          <Box
            sx={{
              mb: 3,
              padding: '15px 20px',
              width: '300px',
              background: '#E7F2FF',
              borderRadius: '20px',
            }}
          >
            <Typography variant="h5" sx={{ mb: 1 }}>
              <InfoIcon fontSize="medium" /> Outputs (example)
            </Typography>
            <Typography variant="body1">Output: 1500</Typography>
            <Typography variant="body1">Output Description: Clothes Donated</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  const question6 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 6 of 8</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          What outcomes does this funding create?
        </Typography>
        <Typography sx={{ mb: 2 }} variant="body1">
          These outcomes should be based on the entire funding amount provided by this opportunity
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8}>
        {formValues.outcomes.map((outcome, index) => (
          <Grid container spacing={4} key={outcome.id}>
            {' '}
            {/* Use unique ID for key */}
            <Grid item xs={12} sm={2} mb={2}>
              <Typography sx={commonTextStyle.labelStyle}>Outcome {index + 1} %</Typography>
              <TextField
                name="quantity"
                value={outcome.quantity || ''}
                onChange={handleOutputChange('quantity', index)}
                variant="outlined"
                fullWidth
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <Typography sx={commonTextStyle.labelStyle}>
                Outcome Description {index + 1}
                <Tooltip title="Enter program outcomes">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                name="description"
                type="text"
                value={outcome.description}
                onChange={handleOutputChange('description', index)}
                sx={commonTextStyle.textFieldStyle}
                variant="outlined"
                fullWidth
              />
            </Grid>
            {formValues.outcomes.length > 1 && (
              <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  onClick={() => removeOutcome(index)}
                  sx={{ color: '#249ACB', marginTop: '8px' }}
                >
                  Remove
                </Button>
              </Grid>
            )}
          </Grid>
        ))}
        {formValues.outcomes.length < 3 && (
          <Button
            onClick={addOutcome}
            variant="contained"
            sx={{
              backgroundColor: '#249ACB',
              ':hover': { color: '#249ACB', backgroundColor: 'white' },
              marginTop: '8px',
            }}
          >
            Add Another Outcome
          </Button>
        )}
      </Grid>

      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid item xs={12}>
          <Box
            sx={{
              mb: 3,
              padding: '15px 20px',
              width: '300px',
              background: '#E7F2FF',
              borderRadius: '20px',
            }}
          >
            <Typography variant="h5" sx={{ mb: 1 }}>
              <InfoIcon fontSize="medium" /> Outcomes (example)
            </Typography>
            <Typography variant="body1">Outcome: 45%</Typography>
            <Typography variant="body1">
              Outcome Description: Increase in children receiving accessing nutritious meals
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  const question7 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 7 of 8</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Are your results measured qualitatively or quantitatively?
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl>
          <RadioGroup
            sx={{ display: 'inline-block' }}
            name="dataMeasurementType"
            value={formValues.dataMeasurementType}
            onChange={handleChange}
          >
            {vocab?.dataMeasurement.map((item: any) => (
              <FormControlLabel
                sx={{ p: 2, mx: 2, border: '1px solid #E0E0E0', borderRadius: '20px' }}
                labelPlacement="start"
                key={item._id}
                value={item._id}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );

  const question8 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 8 of 8</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Are there any SIO partners supporting this program?
        </Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl>
          <RadioGroup
            sx={{ display: 'inline-block' }}
            name="nonprofitPartners"
            value={formValues.nonprofitPartners ? 'yes' : 'no'}
            onChange={handleChange}
          >
            <FormControlLabel
              sx={{ p: 2, mx: 2, border: '1px solid #E0E0E0', borderRadius: '20px' }}
              value="yes"
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              sx={{ p: 2, mx: 2, border: '1px solid #E0E0E0', borderRadius: '20px' }}
              value="no"
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {isNonProfitDesc && (
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">
            SIO partners{' '}
            <Tooltip title="Please mention all SIO partners supporting this program">
              <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
            </Tooltip>
          </Typography>
          <TextField
            name="nonprofitPartnersDescription"
            placeholder="Please mention all SIO partners supporting this program."
            fullWidth
            value={formValues?.nonprofitPartnersDescription?.trimLeft()}
            onChange={handleChange}
            error={
              formValues?.nonprofitPartnersDescription?.trimLeft() === '' &&
              formValues?.nonprofitPartners
            }
            variant="outlined"
            multiline
            rows={2}
            sx={commonTextStyle.textAreaStyle}
          />
        </Grid>
      )}
    </Grid>
  );
  // *** end questions ***

  const disable = () => {
    let value;
    if (pageNumber === 8) {
      if (formValues.nonprofitPartners && formValues?.nonprofitPartnersDescription?.trim() === '') {
        value = true;
      }
    } else value = false;
    return value;
  };

  return (
    <section className="bgLight">
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardHeader
              sx={{
                padding: '20px 80px',
                background: colors.backgroundColorGradient,
                borderRadius: '20px 20px 0px 0px',
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize18,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Section 3
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize24,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Strategies and Approaches
                    </Typography>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <Typography
                        sx={{
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fontWeight600,
                          color: colors.secondary,
                        }}
                      >
                        {pageNumber === 1 ? 0 : (pageNumber - 1) * 12}% complete
                      </Typography>
                    </Grid>
                    <BorderLinearProgress
                      variant="determinate"
                      value={pageNumber === 1 ? 1 : (pageNumber - 1) * 12}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent sx={{ minHeight: '70vh', padding: '40px 80px' }}>
              {pageNumber === 1 && question1}
              {pageNumber === 2 && question2}
              {pageNumber === 3 && question3}
              {pageNumber === 4 && question4}
              {pageNumber === 5 && question5}
              {pageNumber === 6 && question6}
              {pageNumber === 7 && question7}
              {pageNumber === 8 && question8}
              <Grid item xs={12} textAlign="right">
                <Divider sx={{ my: 3 }} orientation="horizontal" flexItem />
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  onClick={handleSaveExit}
                  variant="outlined"
                  size="large"
                >
                  Save and Exit
                </Button>
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  disabled={pageNumber === 1}
                  onClick={handlePreviousbutton}
                  variant="outlined"
                  size="large"
                >
                  Previous
                </Button>
                <Button
                  sx={{
                    ...commonTextStyle.buttonStyle,
                    mx: 2,
                    backgroundColor: colors.primary,
                    color: colors.secondary,
                  }}
                  onClick={handleNextButton}
                  variant="contained"
                  size="large"
                  disabled={disable()}
                >
                  Next
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default ProgramStrategiesQuestions;
