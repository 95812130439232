import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FlexBox, FlexBoxColumn } from '../../shared/components';
import { useAppSelector } from '../../shared/hooks';
import { Program } from '../../shared/interfaces';
import ImpactComponent from './ImpactComponent';

interface Props {
  program?: Program;
}
const MeasurableSocialImpact: FC<Props> = ({ program: dealProgram }) => {
  const { program } = useAppSelector((state) => state.program);

  const impactprogram = program || dealProgram;

  return (
    <FlexBoxColumn className="measurable-social-impact__container">
      <FlexBoxColumn className="measurable-social-impact__headers">
        <Typography variant="h3" className="PoweredByScaleWith">
          Powered By ScaleWith
        </Typography>
        <Typography variant="h4">Measurable Social Impact</Typography>
      </FlexBoxColumn>
      <div className="measurable-social-impact__border"/>
      <FlexBox className="measurable-social-impact__content">
        <Box className="measurable-social-impact__innertext">
          This social impact contribution was developed in partnership with ScaleWith, a SaaS
          technology company that embeds social impact funding into B2B and B2G transactions.
          ScaleWith makes social impact scalable, sustainable, and simple. With a network of 140+
          Social Impact Partners delivering 500+ programs worldwide, ScaleWith enabled us to support
          this impactful program, and will closely track and quantify social impact funding results
          through reports tied to KPIs that will be shared with stakeholders.
        </Box>
        <FlexBox className="measurable-social-impact__impact_container">
          {impactprogram?.outputs &&
            impactprogram.outputs.map((output) => (
              <ImpactComponent
                key={output.description}
                value={output?.value?.toLocaleString()}
                description={output.description}
              />
            ))}
        </FlexBox>
      </FlexBox>
    </FlexBoxColumn>
  );
};
export default MeasurableSocialImpact;
