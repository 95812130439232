import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cancelToken, httpSecuredCommon, addCancelTokenEvent, putUser } from '../../api';
import { RootState } from '../../app';
import { User } from '../../shared/interfaces';

interface SelectedRoleModel {
  role: string;
  isSelected: boolean;
}

export interface EditUserState {
  editedUser: User;
  editedUserId: string;
  loading: boolean;
  success: boolean;
}

const initialState: EditUserState = {
  editedUser: {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    company: '',
    title: '',
    departmentName: '',
    departmentType: '',
    phoneNumber: '',
    active: false,
    roles: [],
  },
  editedUserId: '',
  loading: false,
  success: false,
};

export const getEditUserThunk = createAsyncThunk(
  'users/getEditUserThunk',
  async (id: string, { signal }) => {
    addCancelTokenEvent(signal);
    const response = await httpSecuredCommon.get<User>(`users/${id}`, {
      cancelToken: cancelToken.token,
    });
    return response.data;
  },
);

export const putUserAdminThunk = createAsyncThunk<number, void, { state: RootState }>(
  'users/putUserAdminThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const { firstName, lastName, title, departmentName, phoneNumber, active, roles } =
      state.editUser.editedUser;
    const { status } = await putUser(state.editUser.editedUserId, {
      firstName,
      lastName,
      title,
      departmentName,
      phoneNumber,
      active,
      roles,
    });
    return status;
  },
);

const editUserSlice = createSlice({
  name: 'editUser',
  initialState,
  reducers: {
    setEditedUserId: (state, action: PayloadAction<string>) => {
      state.editedUserId = action.payload;
    },
    setEditUserFirstName: (state, action: PayloadAction<string>) => {
      state.editedUser.firstName = action.payload;
    },
    setEditUserLastName: (state, action: PayloadAction<string>) => {
      state.editedUser.lastName = action.payload;
    },
    setEditUserTitle: (state, action: PayloadAction<string>) => {
      state.editedUser.title = action.payload;
    },
    setEditUserDepartmentName: (state, action: PayloadAction<string>) => {
      state.editedUser.departmentName = action.payload;
    },
    setEditUserPhoneNumber: (state, action: PayloadAction<string>) => {
      state.editedUser.phoneNumber = action.payload;
    },
    setEditUserActive: (state, action: PayloadAction<boolean>) => {
      state.editedUser.active = action.payload;
    },
    setSelectedRoles: (state, action: PayloadAction<SelectedRoleModel>) => {
      const { role, isSelected } = action.payload;
      state.editedUser.roles ??= [];
      const index = state.editedUser.roles.indexOf(role);
      if (isSelected && index === -1) {
        state.editedUser.roles.push(role);
      } else if (!isSelected && index !== -1) {
        state.editedUser.roles.splice(index, 1);
      }
    },
    clearEditUserState: (state) => {
      state.editedUser = initialState.editedUser;
      state.editedUserId = initialState.editedUserId;
      state.loading = initialState.loading;
      state.success = initialState.success;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditUserThunk.fulfilled, (state, action: PayloadAction<User>) => {
        state.editedUser = action.payload;
        state.editedUser.roles ??= [];
        state.loading = false;
      })
      .addCase(getEditUserThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getEditUserThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(putUserAdminThunk.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(putUserAdminThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(putUserAdminThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      });
  },
});

export const {
  setEditedUserId,
  setEditUserFirstName,
  setEditUserLastName,
  setEditUserTitle,
  setEditUserDepartmentName,
  setEditUserPhoneNumber,
  setEditUserActive,
  setSelectedRoles,
  clearEditUserState,
} = editUserSlice.actions;

export const editUserReducer = editUserSlice.reducer;
