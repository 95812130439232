import _ from 'lodash';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addCancelTokenEvent,
  deleteContributionUnshare,
  getApprovalContributions,
  getContributions,
} from '../../../api';
import { GridModel, GridRequest, GridResponse, Contribution } from '../../interfaces';
import { ContributionStatus } from '../../enums';

interface CommonContributionsGridRequest extends GridRequest {
  status?: string | null;
  accountId: string;
  isApprovalsPage?: boolean;
}

interface CommonContributionsState {
  grid: GridModel<Contribution>;
  status?: string | null;
  successUnshare: boolean;
}

interface CommonContributionInitialState {
  initialTabValue: string | null;
  selectionModel?: GridSelectionModel;
}

const initialState: CommonContributionsState = {
  grid: {
    name: 'commonContributions',
    data: { totalCount: 0, results: [] },
    page: 0,
    pageSize: 10,
    loadingGrid: false,
    defaultSort: { field: 'lastUpdated', direction: 'desc' },
  },
  successUnshare: false,
};

export const getCommonContributionsThunk = createAsyncThunk(
  'commonContributions/getCommonContributionsThunk',
  async (request: CommonContributionsGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const filterApprovals =
      request.isApprovalsPage && request.status === ContributionStatus.PendingApproval;
    const params = _.omit(request, 'isApprovalsPage');
    const { data } = filterApprovals
      ? await getApprovalContributions(params)
      : await getContributions(params);
    return data;
  },
);

export const deleteContributionUnshareThunk = createAsyncThunk(
  'commonContributions/deleteContributionUnshareThunk',
  async (id: string, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await deleteContributionUnshare(id);
    return data;
  },
);

const commonContributionsSlice = createSlice({
  name: 'commonContributions',
  initialState,
  reducers: {
    setCommonContributionsGridModel: (state, action: PayloadAction<Partial<GridModel<Contribution>>>) => {
      _.assign(state.grid, action.payload);
    },
    setCommonContributionsStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    clearCommonContributionsState: (state) => {
      state.grid = initialState.grid;
      state.status = initialState.status;
    },
    setCommonContributionsInitialState: (
      state,
      action: PayloadAction<CommonContributionInitialState>,
    ) => {
      state.status = action.payload.initialTabValue;
      state.grid.selectionModel = action.payload.selectionModel;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCommonContributionsThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<Contribution>>) => {
          state.grid.loadingGrid = false;
          state.grid.data = action.payload;
        },
      )
      .addCase(getCommonContributionsThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getCommonContributionsThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(deleteContributionUnshareThunk.fulfilled, (state) => {
        state.successUnshare = true;
      })
      .addCase(deleteContributionUnshareThunk.rejected, (state) => {
        state.successUnshare = false;
      })
      .addCase(deleteContributionUnshareThunk.pending, (state) => {
        if (state.successUnshare) state.successUnshare = false;
      });
  },
});

export const {
  setCommonContributionsGridModel,
  clearCommonContributionsState,
  setCommonContributionsStatus,
  setCommonContributionsInitialState,
} = commonContributionsSlice.actions;

export const commonContributionsReducer = commonContributionsSlice.reducer;
