import { GridRequest, GridResponse, Instance } from '../../shared/interfaces';
import { httpSecuredCommon, cancelToken } from '../httpCommon';

const base = 'instance-settings';

export const INSTANCES_API_ROUTES = {
  getInstances: base,
  postInstanceSettings: base,
  getInstance: (id: string) => `${base}/sioprograms/${id}`,
  putInstance: (id: string) => `${base}/sioprograms/${id}`,
  deleteInstance: (id: string) => `${base}/${id}`,
  patchInstance: (id: string) => `${base}/${id}`,
};

export const getInstances = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<Instance>>(
    INSTANCES_API_ROUTES.getInstances,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const postInstanceSettings = async (
  value: Omit<Instance, 'id' | 'createdBy' | 'createdAt' | 'lastUpdatedBy' | 'lastUpdated'>,
) => {
  const response = await httpSecuredCommon.post<Instance>(
    INSTANCES_API_ROUTES.postInstanceSettings,
    value,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getInstance = async (id: string) => {
  const response = await httpSecuredCommon.get<Instance>(INSTANCES_API_ROUTES.getInstance(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const deleteInstance = async (id: string) => {
  const response = httpSecuredCommon.delete<Instance>(INSTANCES_API_ROUTES.deleteInstance(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const putInstance = async (
  value: Omit<Instance, 'createdBy' | 'createdAt' | 'lastUpdatedBy' | 'lastUpdated'>,
) => {
  const { id, ...values } = value;
  const response = await httpSecuredCommon.put<Instance>(
    INSTANCES_API_ROUTES.putInstance(id!),
    values,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};
export const patchInstance = async (id: string, values: Partial<Instance>) => {
  const response = httpSecuredCommon.patch<Instance>(
    INSTANCES_API_ROUTES.patchInstance(id),
    { ...values },
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};
