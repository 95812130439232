import React from 'react';
import { Close } from '@mui/icons-material';
import { Dialog, IconButton } from '@mui/material';
import { Dialogs } from '../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { EditUser, ResetUserPassword } from '../users';
import { AccountSettings, NewTransaction, BrandLockWarning } from '../accounts';
import { GiveHistory, ResendNotification } from '../incentive';
import { clearDialog } from './mainScreenSlice';
import { SwitchAccount, InviteUser, RevertAccount, MyProfile } from './header';
import { LocaleSettings } from '../locales';
import { InstanceSettings } from '../instances';
import {
  ConfirmDialog,
  InformativeDialog,
  ContactUs,
  UpdateIncentiveStatus,
  SuccessIncentiveDialog,
  FinancialHoldDialog,
} from '../../shared/components';
import { ViewErrorLog } from '../error-logs';
import { CauseAreaMetricsDialog, CauseAreaSettings } from '../cause-areas';
import { InviteSupplier } from '../suppliers/invite-supplier';
import { SupplierDetails } from '../suppliers';
import {
  ProcurementMetricsDetails,
  ProcurementMetricsProgramDetails,
} from '../procurement-metrics';
import { ShareProposalDialog } from '../ProposalPage/ShareProposalDialog';
import { ShowProposalText } from '../ProposalPage/ShowProposalText';
import { InviteApp } from './header/menu/invite-app/InviteApp';

const renderModal = (type: Dialogs | undefined) => {
  switch (type) {
    case Dialogs.MyProfile:
      return <MyProfile />;
    case Dialogs.InviteUser:
      return <InviteUser />;
    case Dialogs.InviteApp:
      return <InviteApp />;
    case Dialogs.EditUser:
      return <EditUser />;
    case Dialogs.SuccessContributionDialog:
      return <SuccessIncentiveDialog />;
    case Dialogs.AccountSettings:
      return <AccountSettings />;
    case Dialogs.SwitchAccount:
      return <SwitchAccount />;
    case Dialogs.RevertAccount:
      return <RevertAccount />;
    case Dialogs.AddTransaction:
      return <NewTransaction />;
    case Dialogs.UpdateContributionStatus:
      return <UpdateIncentiveStatus />;
    case Dialogs.ViewContributionHistory:
      return <GiveHistory />;
    case Dialogs.ContactUs:
      return <ContactUs />;
    case Dialogs.ResetUserPassword:
      return <ResetUserPassword />;
    case Dialogs.ResendNotification:
      return <ResendNotification />;
    case Dialogs.ConfirmDialog:
      return <ConfirmDialog />;
    case Dialogs.FinancialHold:
      return <FinancialHoldDialog />;
    case Dialogs.LocaleSettings:
      return <LocaleSettings />;
    case Dialogs.GivewithBrandLockWarning:
      return <BrandLockWarning />;
    case Dialogs.InstanceSettings:
      return <InstanceSettings />;
    case Dialogs.CauseAreaSettings:
      return <CauseAreaSettings />;
    case Dialogs.ViewErrorLog:
      return <ViewErrorLog />;
    case Dialogs.CauseAreaMetrics:
      return <CauseAreaMetricsDialog />;
    case Dialogs.InformativeDialog:
      return <InformativeDialog />;
    case Dialogs.InviteSupplier:
      return <InviteSupplier />;
    case Dialogs.DetailsSupplier:
      return <SupplierDetails />;
    case Dialogs.ProcurementMetricsDetails:
      return <ProcurementMetricsDetails />;
    case Dialogs.ShareProposal:
      return <ShareProposalDialog />;
    case Dialogs.ProcurementMetricsProgramDetails:
      return <ProcurementMetricsProgramDetails />;
    case Dialogs.ShowProposalText:
      return <ShowProposalText />;
    default:
      return null;
  }
};

export function MainDialog() {
  const modals = useAppSelector((state) => state.main.dialogs);
  const dispatch = useAppDispatch();

  return (
    <>
      {modals.map((modal) => {
        const { open, type, maxWidth, hideCloseIcon } = modal;
        return (
          <Dialog
            sx={{ borderRadius: '15px !important' }}
            key={`app-dialog-${type}`}
            open={open}
            fullWidth
            maxWidth={maxWidth}
            onClose={() => {
              dispatch(clearDialog(type));
            }}
            disableEscapeKeyDown={hideCloseIcon}
            aria-labelledby="modal-main"
            aria-describedby="modal-main"
          >
            {!hideCloseIcon && (
              <IconButton
                sx={{ position: 'absolute', top: 16, right: 16 }}
                onClick={() => {
                  dispatch(clearDialog(type));
                }}
              >
                <Close />
              </IconButton>
            )}
            {renderModal(type)}
          </Dialog>
        );
      })}
    </>
  );
}
