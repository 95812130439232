import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addCancelTokenEvent, getLocale, postLocale, putLocale } from '../../api';
import { RootState } from '../../app';
import { Locale } from '../../shared/interfaces';

interface LocaleSettingsSlice {
  values: Locale;
  loadingLocale: boolean;
  editLocaleId: string;
  temporalRegionLabel: string;
  isNew: boolean;
  success: boolean;
}

const initialState: LocaleSettingsSlice = {
  values: {
    id: '',
    name: '',
    active: true,
    description: '',
    settings: {
      currency: '',
      symbol: '',
      regionLabel: '',
      regions: [],
    },
    createdBy: '',
    lastUpdatedBy: '',
    instanceCount: 0,
  },
  loadingLocale: false,
  editLocaleId: '',
  temporalRegionLabel: '',
  isNew: false,
  success: false,
};

export const getLocaleThunk = createAsyncThunk<Locale, string>(
  'localeSettings/getLocaleThunk',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getLocale(id);
    return data;
  },
);

export const postLocaleThunk = createAsyncThunk<Locale, void, { state: RootState }>(
  'localeSettings/postLocaleThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const {
      localeSettings: {
        values: {
          active,
          description,
          name,
          settings: { currency, regionLabel, regions, symbol },
        },
      },
    } = state;
    const { data } = await postLocale({
      active,
      settings: { currency, regionLabel, regions, symbol },
      description,
      name,
    });
    return data;
  },
);

export const putLocaleThunk = createAsyncThunk<Locale, void, { state: RootState }>(
  'localeSettings/putLocaleThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const {
      localeSettings: {
        values: {
          id,
          active,
          description,
          name,
          settings: { currency, regionLabel, regions, symbol },
        },
      },
    } = state;
    const { data } = await putLocale({
      id,
      active,
      settings: { currency, regionLabel, regions, symbol },
      description,
      name,
    });
    return data;
  },
);

const localeSettingsSlice = createSlice({
  name: 'localeSettings',
  initialState,
  reducers: {
    setLocaleSettingsRegions: (state, action: PayloadAction<Array<string>>) => {
      state.values.settings.regions = action.payload;
    },
    setLocaleSettingLocalName: (state, action: PayloadAction<string>) => {
      state.values.name = action.payload;
    },
    setLocaleSettingCurrency: (state, action: PayloadAction<string>) => {
      state.values.settings.currency = action.payload;
    },
    setLocaleSettingCurrencySymbol: (state, action: PayloadAction<string>) => {
      state.values.settings.symbol = action.payload;
    },
    setLocaleSettingDescription: (state, action: PayloadAction<string>) => {
      state.values.description = action.payload;
    },
    setLocaleSettingRegionLabel: (state, action: PayloadAction<string>) => {
      state.values.settings.regionLabel = action.payload;
    },
    setLocaleSettingIsActive: (state, action: PayloadAction<boolean>) => {
      state.values.active = action.payload;
    },
    clearLocaleSettingsState: (state) => {
      _.assign(state, initialState);
    },
    setLocaleSettingsIsNew: (state, action: PayloadAction<boolean>) => {
      state.isNew = action.payload;
    },
    setLocaleSettingsLocaleId: (state, action: PayloadAction<string>) => {
      state.editLocaleId = action.payload;
    },
    setLocaleSettingsTemporalRegionLabel: (state, action: PayloadAction<string>) => {
      state.temporalRegionLabel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocaleThunk.fulfilled, (state, action: PayloadAction<Locale>) => {
        state.loadingLocale = false;
        state.values = action.payload;
        state.temporalRegionLabel = action.payload.settings.regionLabel;
      })
      .addCase(getLocaleThunk.rejected, (state) => {
        state.loadingLocale = false;
      })
      .addCase(getLocaleThunk.pending, (state) => {
        if (!state.loadingLocale) state.loadingLocale = true;
      })
      .addCase(postLocaleThunk.fulfilled, (state) => {
        state.loadingLocale = false;
        state.success = true;
      })
      .addCase(postLocaleThunk.rejected, (state) => {
        state.loadingLocale = false;
      })
      .addCase(postLocaleThunk.pending, (state) => {
        if (!state.loadingLocale) state.loadingLocale = true;
      })
      .addCase(putLocaleThunk.fulfilled, (state) => {
        state.loadingLocale = false;
        state.success = true;
      })
      .addCase(putLocaleThunk.rejected, (state) => {
        state.loadingLocale = false;
      })
      .addCase(putLocaleThunk.pending, (state) => {
        if (!state.loadingLocale) state.loadingLocale = true;
      });
  },
});

export const {
  setLocaleSettingsRegions,
  clearLocaleSettingsState,
  setLocaleSettingsIsNew,
  setLocaleSettingLocalName,
  setLocaleSettingCurrency,
  setLocaleSettingCurrencySymbol,
  setLocaleSettingDescription,
  setLocaleSettingIsActive,
  setLocaleSettingRegionLabel,
  setLocaleSettingsLocaleId,
  setLocaleSettingsTemporalRegionLabel,
} = localeSettingsSlice.actions;

export const localeSettingsReducer = localeSettingsSlice.reducer;
