import {
    GridRequest,
    GridResponse,
  } from '../../shared/interfaces';
import { Completion, CompletionList, PostCompletionRequest, UpdateCompletionRequest } from '../../shared/interfaces/completion/completion';
import { httpSecuredCommon, cancelToken } from '../httpCommon';

const base = '/completion-forms'

export const COMPLETION_API_ROUTES = {
    getCompletion: base,
    postCompletion: base,
    getCompletionStatus:  `${base}/status`,
    getCompletionDetail:(id:string) =>  `${base}/${id}`,
    updateCompletion: (id:string) =>  `${base}/${id}`,
    sendEmail: (id: string) => `${base}/sendform/${id}`,
    completionAction: (id: string, action: string) => `${base}/${id}/${action}`,
    downloadFile: (id: string) => `${base}/file/${id}`,
  }

  export const downloadFile = async (request: any) => {
    const { id, url, name } = request;
    const response = await httpSecuredCommon.get(COMPLETION_API_ROUTES.downloadFile(id), {
      cancelToken: cancelToken.token,
      params: {
        url,
      },
      responseType: 'blob',
    });
  
    const responseURL = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = responseURL;
    a.download = name;
    window.URL.revokeObjectURL(responseURL);
    return response;
  };

export const getCompletionList = async (request: GridRequest) => {
    const response = await httpSecuredCommon.get<GridResponse<CompletionList>>(
      COMPLETION_API_ROUTES.getCompletion,
      {
        cancelToken: cancelToken.token,
        params: request,
      },
    );
    return response;
  };

  export const getCompletion = async (id: string) => {
    const response = await httpSecuredCommon.get(COMPLETION_API_ROUTES.getCompletionDetail(id), {
      cancelToken: cancelToken.token,
    });
    return response;
  };

  export const getCompletionStatus = async () => {
    const response = await httpSecuredCommon.get(COMPLETION_API_ROUTES.getCompletionStatus, {
      cancelToken: cancelToken.token,
    });
    return response;
  };

  export const postCompletion = async (completion: PostCompletionRequest) => {
    const response = await httpSecuredCommon.post<Completion>(COMPLETION_API_ROUTES.postCompletion, completion, {
      cancelToken: cancelToken.token,
    });
    return response;
  };

  export const sendEmail = async (id: string, data: object) => {
    const response = await httpSecuredCommon.post(COMPLETION_API_ROUTES.sendEmail(id), data);
    return response;
  }

  export const completionAction = async (id: string, action: string) => {
    const response = await httpSecuredCommon.post(COMPLETION_API_ROUTES.completionAction(id, action));
    return response;
  }

  export const patchCompletion = async (id: string, completion: UpdateCompletionRequest) => {
    const response = await httpSecuredCommon.patch<Completion>(COMPLETION_API_ROUTES.updateCompletion(id), completion, {
      cancelToken: cancelToken.token,
    });
    return response;
  };

  export const deleteCompletion = async (id: string) => {
    const response = await httpSecuredCommon.delete(COMPLETION_API_ROUTES.updateCompletion(id));
    return response;
  };

