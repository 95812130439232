import React, { useEffect, useState } from 'react';
import { Button, TextField, Alert, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { FlexBox, FormDialog } from '../../../../../shared/components';
import { Dialogs, UserRole } from '../../../../../shared/enums';
import { isNullOrWhitespace } from '../../../../../shared/utils';
import fonts from '../../../../../assets/scss/font.module.scss';
import colors from '../../../../../assets/scss/color.module.scss';
import { inviteApp as inviteAppApi } from '../../../../../api';

interface InvitedApp {
  orgID: string;
}

export function InviteApp() {
  const [invitedApp, setInvitedApp] = useState<InvitedApp>({
    orgID: '',
  });
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const clearInviteAppState = () => {
    setInvitedApp({
      orgID: '',
    });
    setLoading(false);
    setSuccess(false);
    setShowAlert(false);
  };

  useEffect(() => {
    clearInviteAppState();

    return () => {
      clearInviteAppState();
    };
  }, []);

  useEffect(() => {
    if (success) toast.success('App invitation sent, thank you');
  }, [success]);

  const isInviteValid = () => !isNullOrWhitespace(invitedApp.orgID);

  const inviteApp = async () => {
    setLoading(true);
    try {
      const response = await inviteAppApi({
        forceOrgId: invitedApp.orgID,
        type: 'sellwith-for-salesforce-app',
        roles: [UserRole.Approver, UserRole.Sales, UserRole.Transactor, UserRole.OrgAdmin],
      });
      if (response && response.status === 200) {
        setApiKey(response.data.apiKey);
        setSuccess(true);
      } else {
        // Handle failure case
      }
    } catch (error) {
      // Handle error
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const commonTextFieldStyle = {
    '& label': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
    '& input': {
      fontSize: fonts.fontSize18,
    },
    '&::placeholder': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
  };

  return (
    <FormDialog
      title="Invite App"
      type={Dialogs.InviteApp}
      loading={loading}
      Buttons={
        !success ? (
          <Button
            sx={{
              backgroundColor: !isInviteValid() ? colors.strokeDefault : colors.primary,
              color: colors.secondary,
            }}
            onClick={inviteApp}
            disabled={!isInviteValid()}
          >
            Generate App Key
          </Button>
        ) : (
          <div />
        )
      }
    >
      <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
        <FlexBox sx={{ gap: 4 }}>
          <TextField
            label="Organization ID"
            fullWidth
            value={invitedApp.orgID}
            onChange={(e) => setInvitedApp({ orgID: e.target.value })}
            required
            sx={commonTextFieldStyle}
          />
        </FlexBox>
        {success && apiKey && (
          <FlexBox sx={{ gap: 2 }}>
            <TextField
              label="App Key"
              fullWidth
              variant="outlined"
              value={apiKey}
              InputProps={{
                readOnly: true,
              }}
              sx={{ commonTextFieldStyle }}
            />
            <Button onClick={() => navigator.clipboard.writeText(apiKey)}>Copy to Clipboard</Button>
          </FlexBox>
        )}
        {success && apiKey && (
          <Typography variant="body2" color="textSecondary">
            Please copy the app key to the app key input box on the SellWith for Salesforce setup
            app and click the apply button. If you need to reconfigure your app, you can generate a
            new key.
          </Typography>
        )}
        {showAlert && (
          <Alert severity="warning">
            Not able to invite app at this time. Please try again later
          </Alert>
        )}
      </FlexBox>
    </FormDialog>
  );
}
