import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addCancelTokenEvent,
  deleteInstance,
  getInstances,
  getLocales,
  patchInstance,
} from '../../api';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';
import { GridModel, GridRequest, GridResponse, Instance, Locale } from '../../shared/interfaces';

interface InstancesGridRequest extends GridRequest {
  status: string;
}

interface InstanceSettingsLocaleRequest extends GridRequest {
  active?: boolean;
}

interface InstancesState {
  grid: GridModel<Instance>;
  active: boolean;
  success: boolean;
  toastMessage: string;
  locales?: Array<Locale>;
  localesLoading: boolean;
}

const initialState: InstancesState = {
  grid: {
    name: 'instances',
    data: {
      totalCount: 0,
      results: [],
    },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'name', direction: 'asc' },
  },
  locales: [],
  localesLoading: false,
  active: true,
  success: false,
  toastMessage: '',
};

export const getInstancesThunk = createAsyncThunk<GridResponse<Instance>, InstancesGridRequest>(
  'instances/getInstancesThunk',
  async (request: InstancesGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getInstances(request);
    return data;
  },
);

export const deleteInstanceThunk = createAsyncThunk<Instance, string>(
  'instance/deleteInstanceThunk',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await deleteInstance(id);
    return data;
  },
);

export const patchInstanceThunk = createAsyncThunk<Instance, string>(
  'instance/patchInstanceThunk',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const values = { status: 'active' };
    const { data } = await patchInstance(id, values);
    return data;
  },
);

export const getInstanceLocalesThunk = createAsyncThunk<
  GridResponse<Locale>,
  InstanceSettingsLocaleRequest
>('instanceSettings/getInstanceLocalesThunk', async (request: GridRequest, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getLocales(request);
  return data;
});

const instancesSlice = createSlice({
  name: 'instances',
  initialState,
  reducers: {
    setInstancesGridModel: (state, action: PayloadAction<Partial<GridModel<Instance>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearInstancesState: (state) => {
      _.assign(state, initialState);
    },
    setInstancesActive: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getInstancesThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<Instance>>) => {
          state.grid.loadingGrid = false;
          state.grid.data = action.payload;
        },
      )
      .addCase(getInstancesThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getInstancesThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(deleteInstanceThunk.fulfilled, (state) => {
        state.success = true;
        state.grid.loadingGrid = false;
        state.toastMessage = 'Instance deactivated';
      })
      .addCase(deleteInstanceThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(deleteInstanceThunk.pending, (state) => {
        state.success = false;
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
        state.toastMessage = '';
      })
      .addCase(patchInstanceThunk.fulfilled, (state) => {
        state.success = true;
        state.grid.loadingGrid = false;
        state.toastMessage = 'Instance activated';
      })
      .addCase(patchInstanceThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(patchInstanceThunk.pending, (state) => {
        state.success = false;
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
        state.toastMessage = '';
      })
      .addCase(
        getInstanceLocalesThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<Locale>>) => {
          state.localesLoading = false;
          state.locales = action.payload.results;
        },
      )
      .addCase(getInstanceLocalesThunk.rejected, (state) => {
        state.localesLoading = false;
      })
      .addCase(getInstanceLocalesThunk.pending, (state) => {
        if (!state.localesLoading) state.localesLoading = true;
      });
  },
});

export const { setInstancesGridModel, clearInstancesState, setInstancesActive } =
  instancesSlice.actions;

export const instancesReducer = instancesSlice.reducer;
