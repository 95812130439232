import { CheckCircle } from '@mui/icons-material';
import { Paper, SxProps, Theme, useTheme } from '@mui/material';
import React, { FC } from 'react';
import colors from '../../assets/scss/color.module.scss';

const minHeight = 270;

interface PaperSelectionProps {
  isSelected: boolean;
  withoutMinHeight?: boolean;
  sx?: SxProps<Theme>;
}

export const PaperSelection: FC<PaperSelectionProps> = (props) => {
  const { children, isSelected, sx, withoutMinHeight } = props;
  const theme = useTheme();
  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: 1,
        padding: 2,
        border: `2px solid ${isSelected ? colors.primary : theme.palette.common.white}`,
        position: 'relative',
        minHeight: withoutMinHeight ? 'initial' : minHeight,
        ...sx,
      }}
    >
      {isSelected && (
        <CheckCircle
          sx={{
            position: 'absolute',
            top: -12,
            right: -12,
            color: colors.secondary,
            backgroundColor: colors.primary,
            borderRadius: '50%'
          }}
        />
      )}
      {children}
    </Paper>
  );
};
