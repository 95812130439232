import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCancelTokenEvent, getUsers } from '../../api';
import { GridModel, GridRequest, GridResponse, UserGrid } from '../../shared/interfaces';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';

interface UsersGridRequest extends GridRequest {
  active: boolean | null;
  accountId: string;
}

export interface UsersState {
  grid: GridModel<UserGrid>;
  active: boolean | null;
}

const initialState: UsersState = {
  grid: {
    name: 'users',
    data: { totalCount: 0, results: [] },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'username', direction: 'asc' },
  },
  active: null,
};

export const getUsersThunk = createAsyncThunk(
  'users/getUsersThunk',
  async (request: UsersGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getUsers(request);
    return data;
  },
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsersGridModel: (state, action: PayloadAction<Partial<GridModel<UserGrid>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearUsersState: (state) => {
      state.grid = initialState.grid;
    },
    setUsersActive: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersThunk.fulfilled, (state, action: PayloadAction<GridResponse<UserGrid>>) => {
        state.grid.loadingGrid = false;
        state.grid.data = action.payload;
      })
      .addCase(getUsersThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getUsersThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      });
  },
});

export const { setUsersGridModel, clearUsersState, setUsersActive } = usersSlice.actions;

export const usersReducer = usersSlice.reducer;
