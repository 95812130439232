import { Locale, GridRequest, GridResponse } from '../../shared/interfaces';
import { httpSecuredCommon, cancelToken } from '../httpCommon';

const base = '/locales';

export const LOCALES_API_ROUTES = {
  getLocales: base,
  getLocale: (id: string) => `${base}/${id}`,
  postLocale: base,
  putLocale: (id: string) => `${base}/${id}`,
  deleteLocale: (id: string) => `${base}/${id}`,
  patchLocale: (id: string) => `${base}/${id}`,
};

export const getLocales = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<Locale>>(
    LOCALES_API_ROUTES.getLocales,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const getLocale = async (id: string) => {
  const response = await httpSecuredCommon.get<Locale>(LOCALES_API_ROUTES.getLocale(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postLocale = async (
  value: Pick<Locale, 'name' | 'active' | 'description' | 'settings'>,
) => {
  const response = await httpSecuredCommon.post<Locale>(LOCALES_API_ROUTES.postLocale, value, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const putLocale = async (
  value: Pick<Locale, 'name' | 'active' | 'description' | 'settings' | 'id'>,
) => {
  const { id, ...values } = value;
  const response = await httpSecuredCommon.put<Locale>(LOCALES_API_ROUTES.putLocale(id), values, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const deleteLocale = async (id: string) => {
  const response = httpSecuredCommon.delete<Locale>(LOCALES_API_ROUTES.deleteLocale(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const patchLocale = async (id: string) => {
  const response = httpSecuredCommon.patch<Locale>(
    LOCALES_API_ROUTES.patchLocale(id),
    { active: true },
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};
