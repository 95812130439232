import React, { FC } from 'react';
import { Button, Typography, useMediaQuery } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CardHeaderBlue, FlexBox } from '../../../shared/components';
import { useAppDispatch, useInstance } from '../../../shared/hooks';
import { formatter } from '../../../shared/utils';
import { Dialogs } from '../../../shared/enums';
import { setDialog } from '../../main-screen';
import { ProcurementMetricsResponseObject } from '../../../shared/interfaces';
import { setProgramNameforMetrics } from '../procurementMetricsSlice';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';
import borders from '../../../assets/scss/strokes.module.scss';

interface Props {
  values?: ProcurementMetricsResponseObject;
}

export const ProcurementMetricsCauseAreaCard: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { currencySymbol } = useInstance();
  const { values } = props;
  const matches = useMediaQuery('(max-width: 1900px)');
  // const is1860 = useMediaQuery('(max-width: 1860px)');
  // const navigate = useNavigate();
  const {
    key,
    avgGiveAmount,
    programPreviewUrl,
    impact,
    completedGiveCount,
    totalGiveAmount,
    percentAllCompletedGive,
    impactMeasurement,
  } = { ...values };
  const previewUrl = `${programPreviewUrl}?sio_funding_amt=${totalGiveAmount}`;
  const openDetails = (programName: any) => {
    dispatch(setProgramNameforMetrics(programName));
    dispatch(
      setDialog({ open: true, type: Dialogs.ProcurementMetricsProgramDetails, maxWidth: 'md' }),
    );
  };

  const styles = ({
      "@media (min-width: 1440px) and (max-width: 1590px)": {
        minWidth: '530px',
      },
      "@media (main-width: 1600px) and (max-width: 1629px)": {
        minWidth: '600px',
      },
      "@media (min-width: 1660px) and (max-width: 1900px)": {
        minWidth: '630px',
      },
      "@media (min-width: 1920px)": {
        minWidth: '497px',
      }
  });

  return (
    <CardHeaderBlue
      title={key?.program ?? ' '}
      styleContainer={{ border: '1px solid #BCBEBF', marginBottom: '24px', ...styles }}
      styleTitle={{ color: colors.textPrimary, fontSize: `${fonts.fontSize18} !important`, fontWeight: 700, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `${matches ? '250px' : '450px'}` }}
      // styleTitle={{ textAlign: 'center' }}
      styleHeader={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0, backgroundColor: colors.backgroundPrimary }}
      toolTipOnTitle
      maxTitleLength={36}
    >
      <FlexBox
        sx={{
          width: '100%',
          height: '100px',
          justifyContent: 'space-between',
          border: '1px solid #BCBEBF',
          borderRadius: '15px',
        }}
      >
        <FlexBox
          sx={{
            width: '50%',
            paddingX: '10px',
            paddingY: '15px',
            alignItems: 'center',
            flexDirection: 'column',
            borderRight: '1px solid #C4C4C4',
          }}
        >
          <Typography sx={{color: colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 700 }}>{`${currencySymbol}${formatter.format(
            totalGiveAmount ?? 0,
          )}`}</Typography>
          <Typography sx={{color: colors.textPrimary, fontSize: fonts.fontSize14, fontWeight: 600 }}>Completed Value </Typography>
        </FlexBox>
        <FlexBox
          sx={{ width: '50%', paddingY: '15px', alignItems: 'center', flexDirection: 'column', paddingLeft: '20px' }}
        >
          <Typography sx={{color: colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 600, alignSelf: 'start' }}>{`${percentAllCompletedGive ?? 0}%`}</Typography>
          <Typography sx={{color: colors.textPrimary, fontSize: fonts.fontSize14, fontWeight: 600, alignSelf: 'start' }}>% Of All Completed Incentives</Typography>
        </FlexBox>
      </FlexBox>
      <FlexBox sx={{ gap: 1, paddingTop: '35px', paddingBottom: '25px', width: '100%', flexDirection: 'column' }}>
        <FlexBox sx={{ width: '100%', justifyContent: 'space-between' }}>
          <Typography sx={{ width: '100%', justifyContent: 'space-between', color: colors.textPrimary, fontSize: fonts.fontSize16, fontWeight: 400 }}>Completed Incentive Count</Typography>
          <Typography sx={{ textAlign: 'end', color: colors.textPrimary, fontSize: fonts.fontSize16, fontWeight: 700 }}>
            {completedGiveCount ?? 0}
          </Typography>
        </FlexBox>
        <FlexBox sx={{ width: '100%', justifyContent: 'space-between' }}>
          <Typography  sx={{ width: '100%', justifyContent: 'space-between', color: colors.textPrimary, fontSize: fonts.fontSize16, fontWeight: 400 }}>Average Impact Value</Typography>
          <Typography
            sx={{ textAlign: 'end', fontWeight: 'bold' }}
          >{`${currencySymbol}${formatter.format(avgGiveAmount ?? 0)}`}</Typography>
        </FlexBox>
        <FlexBox sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ flex: 0.7,  width: '100%', justifyContent: 'space-between', color: colors.textSecondary, fontSize: fonts.fontSize16, fontWeight: 400  }}>Estimate Impact Count</Typography>
          <FlexBox
            sx={{ flexDirection: 'column', flex: 1 }}
            onClick={() => openDetails(key?.program)}
          >
            <Typography
              sx={{ textAlign: 'end', color: colors.primary, cursor: 'pointer', fontWeight: 'bold' }}
            >
              {formatter.format(impact ?? 0)}
            </Typography>
            <Typography sx={{ textAlign: 'end' }}>{impactMeasurement ?? ''}</Typography>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <FlexBox>
        <Button
          variant="outlined"
          // color="info"
          // sx={{ backgroundColor: '#fff' }}
          sx={{
            backgroundColor: colors.secondary,
            fontSize: fonts.fontSize14,
            fontWeight: fonts.fontWeight700,
            color: colors.primary,
            borderRadius: borders.borderRadius8,
            borderColor: colors.primary,
            '&:focus': colors.primary,
            '&:hover': colors.primary,
          }}
          onClick={() => window.open(`${previewUrl}`)?.focus()}
          endIcon={<ArrowForwardIcon />}
        >
          <span>View Details</span>
        </Button>
      </FlexBox>
      {/* <Link
          href={programPreviewUrl}
          target="blank"
          rel="noopener noreferrer"
          title="Learn more About Program"
        >
          More Details
        </Link> */}
    </CardHeaderBlue>
  );
};
