import React from 'react';
import { Grid, Card, CardContent, Typography, Button, Link } from '@mui/material';

import '../sio/formFilling.scss';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

const FundingSuccess = () => (
  <section className="bgLight">
    <Grid container>
      <Grid item xs={12}>
        <Card className="mainCard">
          <CardContent sx={{ padding: '80px' }}>
            <Typography
              sx={{
                mb: 4,
                fontSize: fonts.fontSize24,
                fontWeight: fonts.fontWeight700,
                color: colors.textPrimary,
              }}
            >
              Congratulations! Your Social Impact Organization Funding Form has been Submitted
              Successfully.
            </Typography>
            <Typography sx={{ mb: 3, fontSize: fonts.fontSize16, color: colors.textPrimary }}>
              Thank you for submitting your Program Funding form. We will be in touch regarding your
              submission. If you have any questions, please reach out to your designated
              Partnerships Manager, or email
              <Link
                className="mailtoLink"
                href="mailto:help@scalewith.com"
                sx={{
                  display: 'inline',
                  ml: 1,
                  color: colors.primary,
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fontWeight600,
                }}
              >
                <span>help@scalewith.com</span>
              </Link>
            </Typography>
            <Grid item xs={12} textAlign="left" sx={{ p: 0 }}>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                sx={{
                  color: colors.primary,
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fontWeight700,
                  ':hover': { borderColor: colors.primary },
                  borderRadius: '8px',
                  backgroundColor: colors.secondary,
                }}
              >
                Download Form
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </section>
);

export default FundingSuccess;
