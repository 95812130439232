import { Button, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { FlexBox, FormDialog, MoneyInput } from '../../../shared/components';
import { Dialogs } from '../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { isNullOrWhitespace } from '../../../shared/utils';
import {
  clearAccountTransactionsFormState,
  postAccountTransactionsThunk,
  setAccountTransactionsFormAmount,
  setAccountTransactionsFormMemo,
  setAccountTransactionsFormSageId,
} from './accountTransactionsSlice';

export function NewTransaction() {
  const state = useAppSelector((x) => x.accountTransactions.addTransactionState);
  const account = useAppSelector((x) => x.app.account);
  const success = useAppSelector((x) => x.accountTransactions.success);
  const dispatch = useAppDispatch();
  const { sageInvoiceId, memo, amount, loading } = state;

  const isTransactionValid = () =>
    !isNullOrWhitespace(sageInvoiceId) && !isNullOrWhitespace(memo) && amount;

  useEffect(
    () => () => {
      dispatch(clearAccountTransactionsFormState());
    },
    [dispatch],
  );

  const submitTransaction = () => {
    dispatch(
      postAccountTransactionsThunk({
        accountId: account?.id!,
        accountName: account?.company?.name ?? '',
        amount: amount!,
        memo,
        sageInvoiceId,
      }),
    );
  };

  if (!account || !account.id) return null;

  return (
    <FormDialog
      title="New Transaction"
      type={Dialogs.AddTransaction}
      closeFlag={success}
      loading={loading}
      Buttons={
        <Button
          variant="contained"
          size="large"
          onClick={submitTransaction}
          disabled={!isTransactionValid()}
        >
          Submit Transaction
        </Button>
      }
    >
      <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
        <TextField
          label="Date"
          fullWidth
          value={new Date().toLocaleDateString()}
          disabled
          sx={{ width: 150 }}
        />
        <FlexBox sx={{ gap: 2 }}>
          <TextField
            label="Sage Invoice #"
            fullWidth
            value={sageInvoiceId}
            onChange={(e) => {
              dispatch(setAccountTransactionsFormSageId(e.target.value));
            }}
            required
          />
          <TextField
            label="Amount"
            fullWidth
            value={amount}
            onChange={(e) => {
              const { value } = e.target;
              dispatch(
                setAccountTransactionsFormAmount(
                  isNullOrWhitespace(value) ? undefined : parseFloat(value),
                ),
              );
            }}
            required
            InputProps={{ inputComponent: MoneyInput as any }}
          />
        </FlexBox>
        <FlexBox>
          <TextField
            label="Memo"
            fullWidth
            value={memo}
            onChange={(e) => {
              dispatch(setAccountTransactionsFormMemo(e.target.value));
            }}
            required
          />
        </FlexBox>
      </FlexBox>
    </FormDialog>
  );
}
