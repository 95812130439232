import { Button } from '@mui/material';
import { Dangerous } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { FormDialog } from '../../../shared/components';
import { UserRole, Dialogs } from '../../../shared/enums';
import { useAppDispatch, useAppSelector, useAuth } from '../../../shared/hooks';
import { patchAccountAdminThunk } from './accountsSlice';
import { getAccountSettingsAccountThunk, clearAccountSettingsState } from '../account-settings';

export function BrandLockWarning() {
  const state = useAppSelector((x) => x.accountSettings);
  const success = useAppSelector((x) => x.accounts.updateStatusSuccess);
  const patchLoading = useAppSelector((x) => x.accounts.loading);
  const dispatch = useAppDispatch();
  const [isUserInRole] = useAuth();

  const { general } = state;
  const { account, loading: accountLoading } = general;
  const { id } = { ...account };
  const formDisabled = !isUserInRole([UserRole.SuperAdmin]);

  useEffect(() => {
    dispatch(getAccountSettingsAccountThunk());
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(clearAccountSettingsState());
    },
    [dispatch],
  );

  const saveAccount = () => {
    if (id) {
      dispatch(patchAccountAdminThunk({ accountId: id, status: 'locked' }));
    }
  };

  return (
    <FormDialog
      title={
        <>
          ScaleWith Brand Lock Warning &nbsp;
          <Dangerous
            fontSize="large"
            sx={(theme) => ({
              color: theme.palette.error.dark,
            })}
          />
        </>
      }
      type={Dialogs.GivewithBrandLockWarning}
      closeFlag={success}
      loading={patchLoading || accountLoading}
      Buttons={
        <Button
          variant="contained"
          size="large"
          disabled={formDisabled}
          onClick={saveAccount}
          sx={(theme) => ({
            backgroundColor: theme.palette.error.dark,
          })}
        >
          Yes, I’m sure
        </Button>
      }
    >
      <strong> WARNING: </strong> <br />
      Locking the ScaleWith Brand will remove access for all GW Employees.
      <br /> <strong> Are you sure you would like to move forward?</strong>
    </FormDialog>
  );
}
