import React from 'react';
import { Grid, Typography, Link, Button } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface props {
  submitResponse: () => void;
  saveProgress: () => void;
  progressData: any;
}

const CompletionFooter: React.FC<props> = ({ submitResponse, saveProgress, progressData }) => {
  let completedSum = 0;
  let totalSum = 0;
  if(progressData){
  Object.keys(progressData).forEach((val) => {
    completedSum += progressData[val].complete;
    totalSum += progressData[val].total;
  });}

  return (
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', padding: '1em' }}>
      <Grid item xs={4}>
        <Typography sx={{ display: 'flex', color: colors.textPrimary, fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight400 }}>
          Need Help?
        </Typography>
        <Typography variant="body2" sx={{ display: 'flex' }}>
          <Link className="mailtoLink" href="mailto:impact@scalewith.com">
            <EmailOutlinedIcon className="emailIcon" sx={{ color: colors.strokeClick, fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight400, marginTop: '0.3em' }}/>&nbsp;
            <Typography component="span" className="emailText" sx={{ color: colors.strokeClick, fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight400 }}>
              impact@scalewith.com
            </Typography>
          </Link>
        </Typography>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Button
          variant="outlined"
          sx={{ mr: 2, height: '50px', padding: '20px', border: `1px solid ${colors.strokeClick}`, fontSize: fonts.fontSize14, fontWeight: fonts.fontWeight700, color: colors.primary }}
          onClick={saveProgress}
        >
          Save Progress
        </Button>
        <Button
          variant="outlined"
          sx={{ mr: 2, height: '50px', padding: '20px', backgroundColor: `${completedSum !== totalSum ? colors.strokeDefault : colors.primary}`, color: `${colors.secondary} !important`, fontSize: fonts.fontSize14, fontWeight: fonts.fontWeight700, '&:hover': {
            backgroundColor: colors.primary,
          },}}
          onClick={submitResponse}
          disabled={completedSum !== totalSum}
        >
          Submit Response
        </Button>
      </Grid>
    </Grid>
  );
};

export default CompletionFooter;
