import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addCancelTokenEvent,
  getSupplierMetricsCauseArea,
  getSupplierMetricsDetail,
  getMetricSuppliers,
  postSupplierMetricsCSV,
} from '../../api';
import { RootState } from '../../app';
import { DEFAULT_PAGE_SIZE, DEFAULT_VALUES_REQUEST } from '../../shared/constants';
import {
  GridModel,
  GridResponse,
  Industry,
  ProcurementMetricsRequest,
  ProcurementMetricsResponse,
  Supplier,
} from '../../shared/interfaces';
import { getDateFromJanuaryToNow } from '../../shared/utils';

interface allRequestOption {
  gridAccount: GridResponse<Supplier>;
}

interface responseProcurementMetricsDetailCauseArea {
  causeArea?: ProcurementMetricsResponse;
  details?: ProcurementMetricsResponse;
}

interface ProcurementMetricsState {
  industries: Array<Industry>;
  gridAccount: GridModel<Supplier>;
  requestValues: ProcurementMetricsRequest;
  responseValues: responseProcurementMetricsDetailCauseArea;
  programNameforMetrics?: String
  loadingOptions: boolean;
  orderBy: 'desc' | 'asc';
  isLoadingRequest: boolean;
  isLoadingCSV: boolean;
}

const initialState: ProcurementMetricsState = {
  gridAccount: {
    name: 'Account',
    data: { totalCount: 0, results: [] },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'name', direction: 'asc' },
  },
  industries: [],
  requestValues: {
    accountId: '',
    lastUpdatedFrom: getDateFromJanuaryToNow().from,
    lastUpdatedTo: getDateFromJanuaryToNow().to,
    active: [true],
  },
  responseValues: {},
  loadingOptions: false,
  orderBy: 'desc',
  isLoadingRequest: false,
  isLoadingCSV: false,
};

export const getProcurementMetricsAllOptionsThunk = createAsyncThunk<allRequestOption, { id: string; accountId: string }>(
  'procurementMetrics/getProcurementMetricsAllOption',
  async (params, { signal }) => {
    addCancelTokenEvent(signal);
    const { data: dataAccount } = await getMetricSuppliers(DEFAULT_VALUES_REQUEST,params.id, params.accountId);
    const allOptions: allRequestOption = {
      gridAccount: dataAccount,
    };
    return allOptions;
  },
);

export const getDataMetricsThunk = createAsyncThunk<
  responseProcurementMetricsDetailCauseArea,
  ProcurementMetricsRequest,
  { state: RootState }
>('procurement/getDataMetricsThunk', async (request, { getState,signal }) => {
  addCancelTokenEvent(signal);
  const state = getState();
  const { app } = state;
  const { account } = { ...app };
  const requestData =  {
    accountId: account?.id,
    lastUpdatedFrom: request.lastUpdatedFrom,
    lastUpdatedTo: request.lastUpdatedTo,
    active: request.active,
    accounts: request.accounts,
    industries: request.industries
  }
  const { data: dataDetail } = await getSupplierMetricsDetail(requestData);
  const { data: dataCauseArea } = await getSupplierMetricsCauseArea(requestData);
  const response = {
    details: dataDetail,
    causeArea: dataCauseArea,
    
  };

  return response;
});

export const getProcurementCSVThunk = createAsyncThunk<void, ProcurementMetricsRequest,{ state: RootState }>(
  'causeAreasMetricsTable/getProcurementCSVThunk',
  async (request, { getState,signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const { app } = state;
    const { account } = { ...app };
    const requestData =  {
      accountId: account?.id,
      lastUpdatedFrom: request.lastUpdatedFrom,
      lastUpdatedTo: request.lastUpdatedTo,
      active: request.active,
      accounts: request.accounts,
      industries: request.industries
    }
    const { data } = await postSupplierMetricsCSV(requestData);
    return data;
  },
);

const procurementMetricsSlice = createSlice({
  name: 'procurementMetrics',
  initialState,
  reducers: {
    clearProcurementMetrics: (state) => {
      _.assign(state, initialState);
    },
    setProcurementMetricsData: (state, action: PayloadAction<Partial<ProcurementMetricsState>>) => {
      _.merge(state, action.payload);
    },
    setProgramNameforMetrics: (state, action: PayloadAction<string>) => {
      state.programNameforMetrics = action.payload;
    },
    setProcurementMetricRequestValues: (
      state,
      action: PayloadAction<ProcurementMetricsRequest>,
    ) => {
      state.requestValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getProcurementMetricsAllOptionsThunk.fulfilled,
        (state, action: PayloadAction<allRequestOption>) => {
          state.gridAccount.data = action.payload.gridAccount;
          state.loadingOptions = false;
        },
      )
      .addCase(getProcurementMetricsAllOptionsThunk.rejected, (state) => {
        state.loadingOptions = false;
      })
      .addCase(getProcurementMetricsAllOptionsThunk.pending, (state) => {
        if (!state.loadingOptions) state.loadingOptions = true;
      })
      .addCase(
        getDataMetricsThunk.fulfilled,
        (state, action: PayloadAction<responseProcurementMetricsDetailCauseArea>) => {
          state.responseValues = action.payload;
          state.isLoadingRequest = false;
        },
      )
      .addCase(getDataMetricsThunk.rejected, (state) => {
        state.isLoadingRequest = false;
      })
      .addCase(getDataMetricsThunk.pending, (state) => {
        if (!state.isLoadingRequest) state.isLoadingRequest = true;
      })
      .addCase(getProcurementCSVThunk.fulfilled, (state) => {
        state.isLoadingCSV = false;
      })
      .addCase(getProcurementCSVThunk.rejected, (state) => {
        state.isLoadingCSV = false;
      })
      .addCase(getProcurementCSVThunk.pending, (state) => {
        if (!state.isLoadingCSV) state.isLoadingCSV = true;
      });
  },
});

export const {
  clearProcurementMetrics,
  setProcurementMetricsData,
  setProgramNameforMetrics,
  setProcurementMetricRequestValues,
} = procurementMetricsSlice.actions;

export const procurementMetricsReducer = procurementMetricsSlice.reducer;
