import React, { useState } from 'react';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';
import { Box } from '@mui/system';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Typography, TextField, IconButton, InputAdornment, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useAppSelector } from '../../shared/hooks';

export const ShareProposalDialog = () => {
  const { incentive } = useAppSelector((state) => state.incentive);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const url = `${window.location.origin}/shared/proposal/${incentive?.id}`;
  return (
    <Box className="Share__component__container">
      <Typography>
        Share this link and password with your client and any other key stakeholders so they can see
        an overview of this deal and more information about the program outcomes.
      </Typography>
      <TextField
        label='URL'
        value={url}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CopyToClipboard text={url} onCopy={() => toast.success('URL Copied Successfully')}>
              <Tooltip title="Copy URL to clipboard" arrow>
                <IconButton  size="medium">
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
              </CopyToClipboard>
            </InputAdornment>
          ),
        }}
      />
      {incentive?.password && (
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={incentive?.password}
          InputProps={{
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPasswordToggle} className="eyeIcon">
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
                <InputAdornment position="end">
                  <CopyToClipboard text={incentive?.password || ''}  onCopy={() => toast.success('Password Copied Successfully')}>
                  <Tooltip title="Copy password to clipboard" arrow>
                    <IconButton
                      
                      size="medium"
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                  </CopyToClipboard>
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
    </Box>
  );
};
