import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { Download, Cached } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useAppSelector } from '../../../../shared/hooks';
import { FlexBox, IsolatedGrid } from '../../../../shared/components';
import { getGenericHeight } from '../../../../shared/utils';
import {
  getCauseAreaMetricsDetailsDownloadThunk,
  getCauseAreaMetricsViewAsTableThunk,
} from './causeAreasMetricsDialogSlice';

export const CauseAreaMetricsDialogTable = () => {
  const causeAreaMetricsState = useAppSelector((x) => x.causeAreasMetrics);
  const causeAreaMetricsDialogState = useAppSelector((x) => x.causeAreaMetricsDialog);
  const { requestValues } = causeAreaMetricsState;
  const { causeAreaMetricsDialogData, isLoadingDownload } = causeAreaMetricsDialogState;
  const dispatch = useDispatch();

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Cause Area',
      flex: 1,
      filterable: false,
      renderCell: ({
        row: {
          key: { causeArea },
        },
      }) => causeArea,
    },
    {
      field: 'proposed',
      headerName: 'Proposed',
      flex: 1,
      renderCell: ({
        row: {
          key: { currencySymbol },
          proposed,
        },
      }) => `${currencySymbol ?? ''}${proposed}`,
    },
    {
      field: 'completed',
      headerName: 'Completed',
      flex: 1,
      renderCell: ({
        row: {
          key: { currencySymbol },
          completed,
        },
      }) => `${currencySymbol ?? ''}${completed}`,
    },
    {
      field: 'avgGiveAmount',
      headerName: 'Avg Social Impact Contribution',
      flex: 1,
      renderCell: ({
        row: {
          key: { currencySymbol },
          avgGiveAmount,
        },
      }) => `${currencySymbol ?? ''}${avgGiveAmount}`,
    },
    {
      field: 'winRate',
      headerName: 'Win Rate',
      flex: 1,
      renderCell: ({ row: { winRate } }) => `${winRate}%`,
    },
    {
      field: 'impact',
      headerName: 'Impact',
      flex: 1,
      filterable: false,
    },
    {
      field: 'avgGivePercent',
      headerName: 'Avg % of deal',
      flex: 1,
      filterable: false,
      renderCell: ({ row: { avgGivePercent } }) => `${avgGivePercent}%`,
    },
    {
      field: 'key',
      headerName: 'Currency',
      flex: 1,
      filterable: false,
      renderCell: ({
        row: {
          key: { currency },
        },
      }) => currency,
    },
  ];

  useEffect(() => {
    dispatch(getCauseAreaMetricsViewAsTableThunk(requestValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const refreshTable = () => dispatch(getCauseAreaMetricsViewAsTableThunk(requestValues));

  const onHandleDownload = () => {
    if (isLoadingDownload) return;
    dispatch(getCauseAreaMetricsDetailsDownloadThunk(requestValues));
  };

  return (
    <FlexBox sx={{ gap: 1, flexDirection: 'column', maxHeight: 85 }}>
      <FlexBox sx={{ justifyContent: 'end', gap: 1 }}>
        <Cached sx={{ cursor: 'pointer' }} onClick={refreshTable} />
        <Download sx={{ cursor: 'pointer' }} onClick={onHandleDownload} />
      </FlexBox>
      <Box sx={{ minHeight: '500px', height: getGenericHeight(600), marginTop: 1 }}>
        <IsolatedGrid
          model={{
            ...causeAreaMetricsDialogData,
            data: {
              totalCount: causeAreaMetricsDialogData.data.results.length,
              results: causeAreaMetricsDialogData.data.results,
            },
          }}
          columns={columns}
          onChange={() => {}}
        />
      </Box>
    </FlexBox>
  );
};
