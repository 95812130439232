/* eslint-disable react/no-array-index-key */
import {
  Box,
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { DisabledTextField, FlexBox, FormDialog, RecordDetails } from '../../shared/components';
import { FILTER_BY_TRANSACTION_ID } from '../../shared/constants';
import { booleanToTextValues, Dialogs } from '../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { booleanToText, isNullOrWhitespace } from '../../shared/utils';
import { clearDialog } from '../main-screen';
import {
  clearViewErrorLogState,
  getErrorLogThunk,
  patchViewErrorLogThunk,
} from './viewErrorLogSlice';

export const ViewErrorLog = () => {
  const state = useAppSelector((x) => x.viewErrorLog);
  const dispatch = useAppDispatch();

  const { id, loading, success, value } = state;

  const clearViewErrorDialog = () => dispatch(clearDialog(Dialogs.ViewErrorLog));

  useEffect(() => {
    if (isNullOrWhitespace(id)) return;

    dispatch(getErrorLogThunk(id));
  }, [id, dispatch]);

  useEffect(
    () => () => {
      dispatch(clearViewErrorLogState());
    },
    [dispatch],
  );

  return (
    <FormDialog
      type={Dialogs.ViewErrorLog}
      title="Error Details"
      closeFlag={success}
      loading={loading}
      cancelButtonText="Close"
      Buttons={
        <Button
          variant="contained"
          color={value?.isResolved ? 'error' : 'primary'}
          onClick={() =>
            dispatch(patchViewErrorLogThunk({ id, value: { isResolved: !value?.isResolved } }))
          }
        >
          {booleanToText(!value?.isResolved, booleanToTextValues.ResolveUnresolve)}
        </Button>
      }
    >
      {value && (
        <FlexBox sx={{ gap: 4 }}>
          <FlexBox sx={{ flexDirection: 'column', flex: 0.7, gap: 2 }}>
            <Typography>{value.description}</Typography>
            <Box sx={{ maxHeight: 600, overflowY: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Error Code</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Error Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {value?.messages &&
                    value.messages?.map((x, i) => (
                      <TableRow key={`view-error-${value.id}-message-${i}`}>
                        <TableCell>{x?.code}</TableCell>
                        <TableCell>{x?.message}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </FlexBox>
          <FlexBox sx={{ flexDirection: 'column', flex: 0.3 }}>
            <RecordDetails
              value={value}
              additionalValues={
                <>
                  <DisabledTextField
                    label="Account"
                    valueComponent={
                      <Link
                        component={RouterLink}
                        to={`/app/accounts/${value.account?.id}`}
                        onClick={clearViewErrorDialog}
                        fontSize={22}
                      >
                        {value.account?.name}
                      </Link>
                    }
                  />
                  <DisabledTextField
                    label="ID"
                    valueComponent={
                      <Link
                        component={RouterLink}
                        to={`/app/impact/${value.order?.id}/details`}
                        onClick={clearViewErrorDialog}
                        fontSize={22}
                      >
                        {value.order?.name}
                      </Link>
                    }
                  />
                  <DisabledTextField
                    label="Transaction"
                    valueComponent={
                      <Link
                        component={RouterLink}
                        to={`/app/accounts/transactions?${FILTER_BY_TRANSACTION_ID}=${value.transaction?.id}`}
                        onClick={clearViewErrorDialog}
                        fontSize={22}
                      >
                        {value.transaction?.name}
                      </Link>
                    }
                  />
                </>
              }
            />
          </FlexBox>
        </FlexBox>
      )}
    </FormDialog>
  );
};
