import { CompanyEntity, CompanySioandSdg, GridResponse } from '../../shared/interfaces';
import { httpSecuredCommon, httpSharedApiProxy, cancelToken } from '../httpCommon';

const base = '/company';

export const CORPORATE_ENTITY_API_ROUTES = {
  getCompanyEntity: (id: string) => `${base}/${id}`,
  getCompanySioandSdg: (companyName: string) => `${'/aif/sio-and-sdg'}/${companyName}`,
  putCompanyEntity: (id: string) => `${base}/${id}`,
  patchCompanyEntity: (id: string) => `${base}/${id}`,
  postCompanyEntity: base,
  getCompanyEntitys: base,
  getCompanyRecommendations: () => `${base}/recommend`,
  getCompanyThemes: (id: string) => `${base}/themes/${id}`,
  getCompanySDGs: (id: string) => `${base}/sdgs/${id}`,
  getMutualCompanyRecommendations: () => `${base}/recommendation`,
};

export const getCompanyEntity = async (id: string) => {
  const response = await httpSecuredCommon.get<CompanyEntity>(
    CORPORATE_ENTITY_API_ROUTES.getCompanyEntity(id),
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getCompanySioandSdg = async (companyName: string) => {
  const response = await httpSharedApiProxy.post<Array<CompanySioandSdg>>(
    CORPORATE_ENTITY_API_ROUTES.getCompanySioandSdg(companyName),
  );
  return response;
};

export const postCompanyEntity = async (corporateEntity: CompanyEntity) => {
  const response = await httpSecuredCommon.post<CompanyEntity>(
    CORPORATE_ENTITY_API_ROUTES.postCompanyEntity,
    corporateEntity,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const putCompanyEntity = async (id: string, corporateEntity: CompanyEntity) => {
  const response = await httpSecuredCommon.put<CompanyEntity>(
    CORPORATE_ENTITY_API_ROUTES.putCompanyEntity(id),
    corporateEntity,
    {
      cancelToken: cancelToken.token,
    },
  );

  return response;
};

export const patchCompanyEntity = async (id: string, status: string) => {
  const response = await httpSecuredCommon.patch(
    CORPORATE_ENTITY_API_ROUTES.patchCompanyEntity(id),
    { status },
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getCompanyEntities = async (request: any) => {
  const response = await httpSecuredCommon.get<GridResponse<CompanyEntity>>(
    CORPORATE_ENTITY_API_ROUTES.getCompanyEntitys,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const getCompanyRecommendations = async (request: {
  entityName: string;
  filterByThemes?: string[];
  filterBySios?: string[];
  filterBySDGS?: string[];
  fundingAmount: number;
}) => {
  const { entityName, filterBySios, filterByThemes, fundingAmount, filterBySDGS } = request;
  const response = await httpSecuredCommon.post(
    CORPORATE_ENTITY_API_ROUTES.getCompanyRecommendations(),
    {
      entityName,
      filterBySios,
      filterByThemes,
      filterBySdgs: filterBySDGS,
      sio_funding_amount: fundingAmount,
    },
  );
  return response;
};

export const getMutualCompanyRecommendations = async (request: {
  buyerEntityId: string;
  sellerEntityId: string;
  filterByThemes?: string[];
  filterBySios?: string[];
  filterBySDGS?: string[];
  fundingAmount: number;
}) => {
  const { buyerEntityId, sellerEntityId, filterBySios, filterByThemes, fundingAmount, filterBySDGS } = request;
  const response = await httpSecuredCommon.post(
    CORPORATE_ENTITY_API_ROUTES.getMutualCompanyRecommendations(),
    {
      buyerEntityId,
      sellerEntityId,
      filters: {
        sios: filterBySios?.length! > 0 ? filterBySios : [],
        themes: filterByThemes?.length! > 0 ? filterByThemes : [],
        sdgs: filterBySDGS?.length! > 0 ? filterBySDGS : [],
        locations: [],
        topics: [],
      },
      // filterBySios,
      // filterByThemes,
      // filterBySdgs: filterBySDGS,
      sioFundingAmount: fundingAmount,
    },
  );
  return response;
};
export const getCompanyThemes = async (request: {
  id: string;
  filter_by_sdgs?: string[];
  filter_by_sios?: string[];
}) => {
  const response = await httpSecuredCommon.post(
    CORPORATE_ENTITY_API_ROUTES.getCompanyThemes(request.id),
    { filter_by_sdgs: request.filter_by_sdgs || [], filter_by_sios: request.filter_by_sios || [] },
  );
  return response;
};

export const getCompanySDGs = async (request: {
  id: string;
  filter_by_themes?: string[];
  filter_by_sios?: string[];
}) => {
  const response = await httpSecuredCommon.post(
    CORPORATE_ENTITY_API_ROUTES.getCompanySDGs(request.id),
    {
      filter_by_themes: request.filter_by_themes || [],
      filter_by_sios: request.filter_by_sios || [],
    },
  );
  return response;
};
