import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCancelTokenEvent, getSios, patchSioStatus } from '../../api';

import { SIO, GridResponse, GridModel, GridRequest } from '../../shared/interfaces';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';

interface SiosGridRequest extends GridRequest {
  status: string | null;
  applicationFormName?: string;
}

export interface SiosState {
  grid: GridModel<SIO>;
  updateStatusSuccess: boolean;
  status: string | null;
  loading: boolean;
}

const initialState: SiosState = {
  grid: {
    name: 'sios',
    data: { totalCount: 0, results: [] },
    page: 0,
    defaultSort: { field: 'name', direction: 'asc' },
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
  },
  updateStatusSuccess: false,
  status: null,
  loading: false,
};

export const getSiosThunk = createAsyncThunk(
  'sios/getSiosThunk',
  async (request: SiosGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getSios(request);
    return data;
  },
);

export const patchSioAdminThunk = createAsyncThunk(
  'sios/patchSioAdminThunk',
  async (request: { sioId: string; status: string }, { signal }) => {
    addCancelTokenEvent(signal);
    const { sioId, status } = request;
    const { data } = await patchSioStatus(sioId, status);
    return data;
  },
);

const siosSlice = createSlice({
  name: 'sios',
  initialState,
  reducers: {
    setSiosGridModel: (state, action: PayloadAction<Partial<GridModel<SIO>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearSiosState: (state) => {
      state.grid = initialState.grid;
      state.updateStatusSuccess = false;
    },
    clearSiosUpdateSuccess: (state) => {
      state.updateStatusSuccess = false;
    },
    setSiosStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSiosThunk.fulfilled, (state, action: PayloadAction<GridResponse<SIO>>) => {
        state.grid.loadingGrid = false;
        state.grid.data = action.payload;
      })
      .addCase(getSiosThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getSiosThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(patchSioAdminThunk.fulfilled, (state, action: PayloadAction<SIO>) => {
        state.updateStatusSuccess = true;
        state.loading = false;
        const index = state.grid.data.results.findIndex((a) => a.id === action.payload.id);
        if (index === -1) return;
        state.grid.data.results[index] = action.payload;
      })
      .addCase(patchSioAdminThunk.pending, (state) => {
        state.updateStatusSuccess = false;
        if (!state.loading) state.loading = true;
      });
  },
});

export const { setSiosGridModel, clearSiosState, setSiosStatus, clearSiosUpdateSuccess } =
  siosSlice.actions;

export const siosReducer = siosSlice.reducer;
