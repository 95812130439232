import React from 'react';
import {
  GridColumnMenuProps,
  GridColumnMenuContainer,
  HideGridColMenuItem,
  GridColumnsMenuItem,
} from '@mui/x-data-grid-pro';
import { MenuItem } from '@mui/material';

interface CustomMenuProps extends GridColumnMenuProps {
  resetDashboard: () => void;
}
export const CommonGridColumnMenu = React.forwardRef<HTMLUListElement, CustomMenuProps>(
  ({ resetDashboard, ...props }, ref) => {
    const { hideMenu, currentColumn } = props;
    return (
      <GridColumnMenuContainer ref={ref} {...props}>
        <HideGridColMenuItem onClick={hideMenu} column={currentColumn!} />
        <GridColumnsMenuItem onClick={hideMenu} column={currentColumn!} />
        <MenuItem onClick={resetDashboard}>Reset Dashboard</MenuItem>
      </GridColumnMenuContainer>
    );
  },
);
