import React, { FC, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CK_AUTH_TOKEN, CK_USER_CLAIMS } from '../shared/constants';
import { AccountStatus, AccountType, UserRole } from '../shared/enums';
import { useAccount, useAppDispatch, useAppSelector, useAuth } from '../shared/hooks';
import {
  GWCookies,
  includesWithoutCaseSensi,
  isNullOrWhitespace,
  isUserAuthorized,
  UserClaims,
} from '../shared/utils';
import { Loading } from '../shared/components/Loading';
import {
  decodeUserClaims,
  setUserClaims,
  getAppAccountThunk,
  renewAccessTokenThunk,
  getInstanceAssociatedUserThunk,
} from './appSlice';
import { getUserProfileThunk } from '../features/main-screen/header/menu/my-profile/myProfileSlice';
import { findActiveTenantId } from '../shared/utils/findActiveTenantId';

const REDIRECT_TO_DASHBOARD: Array<string> = ['/', '/app', '/app/'];

export function AuthRequired() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isUserInRole] = useAuth();
  const accountError = useAppSelector((state) => state.app.accountError);
  const account = useAppSelector((state) => state.app.account);
  const profileState = useAppSelector((state) => state.myProfile);
  const userClaims = useAppSelector((state) => state.app.userClaims);
  const loading = useAppSelector((state) => state.app.loading);
  const instance = useAppSelector((state) => state.app.associatedInstance);
  const loadingAssociatedInstance = useAppSelector((state) => state.app.loadingAssociatedInstance);
  const isLoggedIn = isUserAuthorized(true);
  const isGivewithUser = isUserInRole([UserRole.SuperAdmin]);
  useEffect(() => {
    if (!isLoggedIn) {
      // navigate('/register');
      window.location.href = `${window.AUTH0_LOGIN_DOMAIN}`;
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (!isLoggedIn) return;
    const token = GWCookies.get(CK_AUTH_TOKEN);
    if (token && token.toString().length) {
      let decodedClaims: UserClaims = decodeUserClaims(token.toString());
      if (GWCookies.get(CK_USER_CLAIMS)) {
        const userClaimsDetails: any = GWCookies.get(CK_USER_CLAIMS);
        const { exp } = decodedClaims;
        const prasedCookies = JSON.parse(userClaimsDetails);
        let tenantKey = '';
        if (window.location.origin.includes('dev')) {
          tenantKey = 'dev_tenantId';
        } else if (window.location.origin.includes('qa')) {
          tenantKey = 'qa_tenantId';
        } else {
          tenantKey = 'tenantId';
        }
        const tenantId =
          GWCookies.get(tenantKey) !== undefined
            ? GWCookies.get(tenantKey)
            : prasedCookies?.accountId;

        const activeTenantId: string | null = findActiveTenantId(
          tenantId,
          prasedCookies?.tenantAccessGrants,
        );

        const userId = activeTenantId ?? prasedCookies?.accountId;

        const accountId: string | undefined = activeTenantId ?? undefined;

        const currentSelection: any = prasedCookies?.tenantAccessGrants.filter(
          (tenant: any) => String(tenant.id) === String(activeTenantId),
        );

        const tenantRoles: any = currentSelection[0]?.roles;
        const tenantDomain: any = currentSelection[0]?.domain;
        decodedClaims = {
          user_id: userId,
          departmentType: prasedCookies?.departmentType,
          subdomain: tenantDomain,
          scope: prasedCookies?.scope,
          version: '1.0',
          industry: '',
          first_name: prasedCookies?.firstName,
          last_name: prasedCookies?.lastName,
          email: prasedCookies?.username,
          orgName: '',
          accountId,
          exp,
          roles: tenantRoles,
          tenantAccessGrants: prasedCookies?.tenantAccessGrants,
        };
      }
      dispatch(setUserClaims(decodedClaims));
      return;
    }
    dispatch(renewAccessTokenThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userClaims) return;
    dispatch(getAppAccountThunk(userClaims?.accountId ?? '-1'));
    dispatch(getUserProfileThunk());
  }, [userClaims, dispatch]);

  useEffect(() => {
    if (!instance?.id || !instance?.settings?.portalUrl) return;
    if (includesWithoutCaseSensi(window.location.href, instance?.settings?.portalUrl)) return;
    let currentPath = location.pathname;
    if (instance?.settings?.portalUrl.endsWith('/') && currentPath.startsWith('/'))
      currentPath = currentPath.substring(1);
    let finalURL = `${instance.settings.portalUrl}${currentPath}`;
    if (!isNullOrWhitespace(location.search)) finalURL = `${finalURL}${location.search}`;
    if (!isNullOrWhitespace(location.hash)) finalURL = `${finalURL}${location.hash}`;
    window.location.href = finalURL;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance?.settings, instance]);

  useEffect(() => {
    if (!account?.instance?.id || instance?.settings?.portalUrl) return;
    dispatch(getInstanceAssociatedUserThunk(account.instance?.id));    
    const {
      id: accountId,
      company,
      industry,
      subscription,
      subscriptionFrequency,
      status,
      type,
      instance: instanceType,
    } = account;
    const { firstName: firstname, lastName: lastname, id: userId, username } = profileState.user;
    if (userId && username) {      
      window.pendo.initialize({
        visitor: {
            id: username,
            email: username,
            full_name: `${firstname} ${lastname}`,
            visitorId: userId,
            firstname,
            lastname,
        },
        account: {
            id: company?.name,
            accountId,
            companyname: company?.name,
            industry: industry?.subIndustry,
            instance: instanceType?.name,
            subscription,
            subscriptionfrequency: subscriptionFrequency,
            status,
            type,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, account, profileState]);

  useEffect(() => {
    if ((!account && !accountError) || !isLoggedIn || isGivewithUser) return;
    const isFinanceOrgAdmin = isUserInRole([UserRole.Finance, UserRole.OrgAdmin]);
    switch (account?.status) {
      case AccountStatus.Active:
        break;
      case AccountStatus.New:
        if (GWCookies.get(CK_USER_CLAIMS)) return;
        navigate('/register-details-form');
        break;
      case AccountStatus.Pending:
        if (location.pathname.match(/\/register-details\//i)) return;
        if (isFinanceOrgAdmin) navigate('/register-details/financial-choice');
        else navigate('/unauthorized');
        break;
      case AccountStatus.PendingStripe:
        if (location.pathname.match(/\/register-details\//i)) return;
        if (isFinanceOrgAdmin) navigate('/register-details/finalize');
        else navigate('/unauthorized');
        break;
      case AccountStatus.PendingFinance:
        if (location.pathname.match(/\/register-details\//i)) return;
        if (isFinanceOrgAdmin) navigate('/register-details/subscription');
        else navigate('/register-pending-finance');
        break;
      default:
        navigate('/account-invalid-status');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account,
    account?.status,
    isLoggedIn,
    isGivewithUser,
    navigate,
    accountError,
    location.pathname,
  ]);

  if (loading || loadingAssociatedInstance || instance === undefined) {
    return <Loading loading={loading || loadingAssociatedInstance || !instance} fullscreen />;
  }

  if (
    isLoggedIn &&
    REDIRECT_TO_DASHBOARD.includes(location.pathname) &&
    isUserInRole([UserRole.SIAdmin])
  )
    return <Navigate to="/app/dashboard" />;

  if (isLoggedIn && REDIRECT_TO_DASHBOARD.includes(location.pathname))
    return <Navigate to="/app/dashboard" />;

  return isLoggedIn ? <Outlet /> : <Navigate to="/register" />;
}

export function RoleRequired({
  roles,
  children,
  page = false,
}: {
  roles: Array<UserRole>;
  children: JSX.Element;
  page?: boolean;
}) {
  const [isUserInRole] = useAuth();
  const unauthorizedContent = page ? <Navigate to="/unauthorized" replace /> : null;
  return isUserInRole(roles) ? children : unauthorizedContent;
}

export function AccountStatusRequired({
  statuses,
  children,
  page = false,
}: {
  statuses: Array<AccountStatus>;
  children: JSX.Element;
  page?: boolean;
}) {
  const { isAccountInStatus } = useAccount();
  const unauthorizedContent = page ? <Navigate to="/unauthorized" replace /> : null;
  return isAccountInStatus(statuses) ? children : unauthorizedContent;
}

export function RoleRequiredOrAccountType({
  types,
  children,
  roles,
  page = false,
}: {
  types?: Array<AccountType>;
  roles?: Array<UserRole>;
  children: JSX.Element;
  page?: boolean;
}) {
  const { isAccountInType } = useAccount();
  const [isUserInRole] = useAuth();
  const listTypes = types ?? [];
  const listRoles = roles ?? [];
  const unauthorizedContent = page ? <Navigate to="/unauthorized" replace /> : null;
  const isValid = isAccountInType(listTypes) || isUserInRole(listRoles);
  return isValid ? children : unauthorizedContent;
}

export function AccountTypeRequired({
  types,
  children,
  page = false,
}: {
  types: Array<AccountType>;
  children: JSX.Element;
  page?: boolean;
}) {
  const { isAccountInType } = useAccount();
  const unauthorizedContent = page ? <Navigate to="/unauthorized" replace /> : null;
  return isAccountInType(types) ? children : unauthorizedContent;
}

interface LoggedRequiredProps {
  children: React.ReactNode;
}

export const LoggedRequired: FC<LoggedRequiredProps> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.app.loading);
  const userClaims = useAppSelector((state) => state.app.userClaims);
  const isLoggedIn = isUserAuthorized(true);
  const { children } = props;

  useEffect(() => {
    if (!isLoggedIn) navigate('/register');
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (!isLoggedIn) return;
    const token = GWCookies.get(CK_AUTH_TOKEN);
    if (token && token.toString().length) {
      const decodedClaims = decodeUserClaims(token.toString());
      dispatch(setUserClaims(decodedClaims));
      return;
    }
    dispatch(renewAccessTokenThunk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading && !userClaims) {    
    return <Loading loading={loading && !userClaims} fullscreen />;
  }

  return isLoggedIn ? <div>{children}</div> : <Navigate to="/register" />;
};
