import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addCancelTokenEvent,
  getAccountAppSettings,
  postRegisterUserSubscription,
  postUser,
  putRegisterUser,
  putRegisterUserSubscription,
} from '../../api';
import { getAppAccountThunk, RootState } from '../../app';
import { ACCOUNT_SUBSCRIPTION } from '../../shared/constants';
import {
  AccountStatus,
  AccountSubscription,
  FinancialChoice,
  RegisterStep,
  UserRole,
} from '../../shared/enums';
import { Account, Procurement, StripeCheckoutResponse } from '../../shared/interfaces';

interface responseGetTermsSuppliers {
  id: string;
  procurement: Procurement;
}

interface RegisterDetailsState {
  accountData: Account;
  supplierTerms?: responseGetTermsSuppliers;
  loadingSuppliersTerms: boolean;
  agreedTermsAndConditions: boolean;
  agreedSupplierTermsConditions: boolean;
  loading: boolean;
  success: boolean;
  financialChoice: number;
  subscriptionChoice?: AccountSubscription;
  inviteForm: {
    firstName: string;
    lastName: string;
    email: string;
  };
  currentStep: RegisterStep;
  redirectUrl?: string;
  finalizeSubmitted: boolean;
  accountValidationError: boolean;
}

const initialState: RegisterDetailsState = {
  accountData: {
    company: {
      address: {
        address1: '',
        address2: '',
        city: '',
        stateProvince: null,
        postalCode: '',
        country: '',
      },
      countryCode: '',
      phoneNumber: '',
      website: '',
    },
  },
  agreedTermsAndConditions: false,
  agreedSupplierTermsConditions: false,
  loading: false,
  success: false,
  financialChoice: -1,
  inviteForm: {
    firstName: '',
    lastName: '',
    email: '',
  },
  currentStep: RegisterStep.AccountSelection,
  subscriptionChoice: undefined,
  accountValidationError: false,
  finalizeSubmitted: false,
  loadingSuppliersTerms: false,
};

export const putRegisterDetailsUserThunk = createAsyncThunk<number, string, { state: RootState }>(
  'registerDetails/putRegisterDetailsUserThunk',
  async (companyName, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const formState = getState();
    const { address, phoneNumber, website, countryCode } = {
      ...formState.registerDetails.accountData.company,
    };
    const { address1, address2, city, stateProvince, postalCode, country } = { ...address };
    const { status } = await putRegisterUser({
      account: {
        company: {
          name: companyName,
          address: {
            address1,
            address2,
            city,
            stateProvince,
            postalCode,
            country,
          },
          countryCode,
          phoneNumber,
          website,
        },
        termsAccepted: true,
      },
    });
    return status;
  },
);

export const getSupplierTermsThunk = createAsyncThunk<responseGetTermsSuppliers, string>(
  'registerDetails/getSupplierTermsThunk',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getAccountAppSettings(id);
    return data;
  },
);

export const putRegisterUserSubscriptionThunk = createAsyncThunk<
  StripeCheckoutResponse,
  void,
  { state: RootState }
>('registerDetails/putRegisterUserSubscription', async (_void, { getState, signal }) => {
  addCancelTokenEvent(signal);
  const formState = getState();
  const { financialChoice, subscriptionChoice } = formState.registerDetails;

  const isInvite = financialChoice === FinancialChoice.InviteFinancialUser;

  const { data } = await putRegisterUserSubscription({
    createSetupSubscriptionCheckoutSession: !isInvite,
    subscriptionOptions: isInvite
      ? undefined
      : {
          subscriptionFrequency: ACCOUNT_SUBSCRIPTION[subscriptionChoice!].id,
        },
  });

  return data;
});

export const postRegisterInviteFinanceUserThunk = createAsyncThunk<
  number,
  void,
  { state: RootState }
>('inviteUser/postRegisterInviteFinanceUserThunk', async (_void, { getState, signal }) => {
  addCancelTokenEvent(signal);
  const { registerDetails, app } = getState();
  const accountId = app.account?.id ?? app.userClaims?.accountId ?? '-1';
  const { firstName, lastName, email } = registerDetails.inviteForm;
  const { status } = await postUser({
    accountId,
    firstName,
    lastName,
    username: email,
    roles: [UserRole.Finance],
  });
  return status;
});

export const postRegisterUserSubscriptionThunk = createAsyncThunk<
  StripeCheckoutResponse,
  { checkoutSessionId: string }
>('registerDetails/postRegisterUserSubscriptionThunk', async (request, { signal }) => {
  addCancelTokenEvent(signal);
  const { checkoutSessionId } = request;

  const { data } = await postRegisterUserSubscription({
    checkoutSessionId,
  });

  return data;
});

const registerDetailsSlice = createSlice({
  name: 'registerDetails',
  initialState,
  reducers: {
    setRegisterDetailsAccountData: (state, action: PayloadAction<Account>) => {
      _.merge(state.accountData, action.payload);
    },
    setAgreedTermsAndConditions: (state, action: PayloadAction<boolean>) => {
      state.agreedTermsAndConditions = action.payload;
    },
    setAgreedSupplierTerms: (state, action: PayloadAction<boolean>) => {
      state.agreedSupplierTermsConditions = action.payload;
    },
    setRegisterDetailsFinancialChoice: (state, action: PayloadAction<number>) => {
      if (state.financialChoice !== action.payload) _.assign(state, initialState);
      state.financialChoice = action.payload;
    },
    setRegisterDetailsSubscriptionChoice: (state, action: PayloadAction<AccountSubscription>) => {
      state.subscriptionChoice = action.payload;
    },
    setRegisterDetailsInviteFirstName: (state, action: PayloadAction<string>) => {
      state.inviteForm.firstName = action.payload;
    },
    setRegisterDetailsInviteLastName: (state, action: PayloadAction<string>) => {
      state.inviteForm.lastName = action.payload;
    },
    setRegisterDetailsInviteEmail: (state, action: PayloadAction<string>) => {
      state.inviteForm.email = action.payload;
    },
    clearRegisterDetailsState: (state) => {
      _.assign(state, initialState);
    },
    setRegisterStepperStep: (state, action: PayloadAction<RegisterStep>) => {
      state.currentStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(putRegisterDetailsUserThunk.fulfilled, (state) => {
        state.success = true;
      })
      .addCase(putRegisterDetailsUserThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(putRegisterDetailsUserThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(
        putRegisterUserSubscriptionThunk.fulfilled,
        (state, action: PayloadAction<StripeCheckoutResponse>) => {
          state.redirectUrl = action.payload.url;
        },
      )
      .addCase(putRegisterUserSubscriptionThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(putRegisterUserSubscriptionThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(postRegisterInviteFinanceUserThunk.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(postRegisterInviteFinanceUserThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postRegisterInviteFinanceUserThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(postRegisterUserSubscriptionThunk.fulfilled, (state) => {
        state.success = true;
      })
      .addCase(postRegisterUserSubscriptionThunk.rejected, (state) => {
        state.accountValidationError = true;
      })
      .addCase(getAppAccountThunk.fulfilled, (state, action: PayloadAction<Account>) => {
        const account = action.payload;
        if (
          ![AccountStatus.PendingFinance, AccountStatus.PendingStripe].includes(
            (account.status ?? AccountStatus.Deleted) as AccountStatus,
          )
        )
          return;
        state.financialChoice = FinancialChoice.IamReady;
        state.subscriptionChoice = account.subscriptionFrequency;
        state.currentStep =
          account.status === AccountStatus.PendingFinance
            ? RegisterStep.Finalize - 1
            : RegisterStep.SubscriptionSelection - 1;
      })
      .addCase(
        getSupplierTermsThunk.fulfilled,
        (state, action: PayloadAction<responseGetTermsSuppliers>) => {
          state.supplierTerms = action.payload;
          state.loadingSuppliersTerms = false;
        },
      )
      .addCase(getSupplierTermsThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSupplierTermsThunk.pending, (state) => {
        if (!state.loadingSuppliersTerms) state.loadingSuppliersTerms = true;
      });
  },
});

export const {
  setRegisterDetailsAccountData,
  setAgreedTermsAndConditions,
  setRegisterDetailsFinancialChoice,
  setRegisterDetailsSubscriptionChoice,
  setRegisterDetailsInviteFirstName,
  setRegisterDetailsInviteLastName,
  setRegisterDetailsInviteEmail,
  clearRegisterDetailsState,
  setRegisterStepperStep,
  setAgreedSupplierTerms,
} = registerDetailsSlice.actions;
export const registerDetailsReducer = registerDetailsSlice.reducer;
