export enum SubscriptionType {
  UNKNOWN = '',
  ADMIN = 'scalewith_admin',
  SELLWITH_PRO = 'sellwith_pro',
  SELLWITH_PRO_UK = 'sellwith_pro_uk',
  BUYWITH_PRO = 'buywith_pro',
  SELLWITH_SELECT = 'sellwith_select',
  BUYWITH_SELECT = 'buywith_select',
  ENTERPRISE = 'sellwith_enterprise',
  BUYWITH_ENTERPRISE = 'buywith_enterprise',
}
