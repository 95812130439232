export const ROLE_TO_STRING = {
  givewith: 'Super Admin',
  org_admin: 'Org Admin',
  user_admin: 'User Admin',
  finance: 'Finance',
  sales: 'Sales',
  approver: 'Approver',
  transactor: 'Transactor',
  executive: 'Executive',
  gw_operations: 'Scalewith Operations',
  gw_csm: 'Scalewith CSM',
  gw_finance: 'Scalewith Finance',
  sw_si_admin: 'Scalewith SI Admin'
};
