/* eslint-disable arrow-body-style */
import React, { ChangeEvent } from 'react';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

interface Props {
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  value?: any;
  multiline?: boolean;
  rows?: number;
  helperText?: string;
  adornmentText?: any;
  height?: string;
  required?: boolean;
  error?: boolean;
  defaultValue?: any;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TextFieldInput: React.FC<Props> = ({
  disabled,
  type,
  placeholder,
  value,
  multiline,
  rows,
  adornmentText,
  helperText,
  height = '40',
  onChange,
  required,
  error,
  defaultValue,
}) => {
  return (
    <TextField
      disabled={false || disabled}
      type={type}
      placeholder={placeholder}
      multiline={false || multiline}
      rows={rows}
      fullWidth
      defaultValue={defaultValue}
      value={value}
      helperText={helperText}
      margin="normal"
      variant="outlined"
      onChange={onChange}
      required={false || required}
      error={error}
      InputProps={{
        readOnly: false,
        startAdornment: <InputAdornment position="start">{adornmentText}</InputAdornment>,
        sx: {
          '& .MuiInputBase-input::placeholder': {
            fontSize: '16px',
          },
          '& .MuiInputBase-input': {
            fontSize: '16px',
          },
          backgroundColor: `${disabled && '#f2f2f3'}`,
        },
      }}
      sx={{
        '& .MuiInputBase-root': {
          height: `${height}px`,
        },
      }}
    />
  );
};

export default TextFieldInput;
