import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  Divider,
  ListItemText,
  Checkbox,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { addSio } from './sioSlice';
import { getUsersThunk, clearUsersState } from '../users/usersSlice';
import { getGridRequest } from '../../shared/utils';
import { CreateSIOAPIResponse, PostSIORequest } from '../../shared/interfaces/sio/sio';
import './Sio.scss';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';
import { SioUserRole } from '../../shared/enums';

interface Contact {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  roles: string[];
}

interface SioFormValues {
  data: {
    organizationName: string;
    website: string;
    users: Contact[];
  };
  relationships: {
    partnerManager: {
      data: {
        id: string;
        type: 'user';
      };
    };
  };
  type: 'sio-invitation';
}

const CreateSIO = () => {
  const accountId = useAppSelector((state) => state.app.account?.id!);
  const sioState = useAppSelector((state) => state.sios);
  const { updateStatusSuccess } = sioState;
  const success = useAppSelector((state) => state.editUser.success);
  const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const { grid, active } = usersState;
  const { page, pageSize, sort, filter } = grid;
  const navigate = useNavigate();
  const [sioFormValues, setSioFormValues] = useState<SioFormValues>({
    data: {
      organizationName: '',
      website: '',
      users: [
        {
          id: `contact-${Math.random().toString(36).substr(2, 9)}`,
          username: '',
          firstName: '',
          lastName: '',
          roles: [],
        },
      ],
    },
    relationships: {
      partnerManager: {
        data: {
          id: '',
          type: 'user',
        },
      },
    },
    type: 'sio-invitation',
  });

  const updatedGrid = {
    ...grid,
    pageSize: 1000,
    defaultSort: { field: 'roles', direction: 'asc' as const },
    filter: { field: 'roles', simpleValue: 'sw_si_admin' },
  };
  const getUsersGridRequest = () => ({
    ...getGridRequest(updatedGrid),
    accountId,
  });
  const [formValues, setFormValues] = useState<PostSIORequest>({
    applicationFormName: '',
    siAdmin: '',
    sioUserEmailList: [],
  });

  const Roles = grid.data.results;
  const SiAdminRoles = Roles.filter((arr) => (arr.roles ? arr.roles.includes('sw_si_admin') : ''));
  const roles = [
    { value: SioUserRole.OrgAdmin, label: 'Org Admin' },
    { value: SioUserRole.UserAdmin, label: 'User Admin' },
  ];

  const selectOptions = SiAdminRoles.map((option) => (
    <MenuItem key={option.id} value={option.id}>
      {`${option.firstName} ${option.lastName}`}
    </MenuItem>
  ));

  useEffect(() => {
    dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, active]);

  useEffect(() => {
    if (success) dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(
    () => () => {
      dispatch(clearUsersState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (updateStatusSuccess) toast.success('New SIO created Successfully');
  }, [updateStatusSuccess]);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent,
  ) => {
    const { name, value } = event.target;

    setSioFormValues((prevState) => {
      const updatedState = { ...prevState };

      if (name === 'organizationName') {
        updatedState.data.organizationName = value;
      } else if (name === 'website') {
        updatedState.data.website = value;
      } else if (name === 'partnerManager') {
        updatedState.relationships.partnerManager.data.id = value;
      }

      return updatedState;
    });
  };

  const handleContactInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSioFormValues((prevState) => {
      const updatedUsers = prevState.data.users.map((user, idx) => {
        if (idx === index) {
          return { ...user, [event.target.name]: event.target.value };
        }
        return user;
      });

      return {
        ...prevState,
        data: {
          ...prevState.data,
          users: updatedUsers,
        },
      };
    });
  };

  const handleRolesChange = (index: number, event: SelectChangeEvent<string[]>) => {
    setSioFormValues((prevState) => {
      const newUsers = prevState.data.users.map((user, idx) => {
        if (idx === index) {
          return { ...user, roles: event.target.value as string[] };
        }
        return user;
      });

      return {
        ...prevState,
        data: {
          ...prevState.data,
          users: newUsers,
        },
      };
    });
  };

  const createNewContact = () => ({
    id: `contact-${Math.random().toString(36).substr(2, 9)}`,
    username: '',
    firstName: '',
    lastName: '',
    roles: [],
  });

  const handleAddContact = () => {
    const newContact = createNewContact();
    setSioFormValues((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        users: [...prevState.data.users, newContact],
      },
    }));
  };

  const handleRemoveContact = (index: number) => {
    setSioFormValues((prevState) => {
      const updatedUsers = prevState.data.users.filter((_, idx) => idx !== index);
      return {
        ...prevState,
        data: {
          ...prevState.data,
          users: updatedUsers,
        },
      };
    });
  };

  // const validateEmail = (email: string) => {
  //   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return regex.test(email);
  // };

  // const runIt = false;

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const submissionData = {
      ...sioFormValues,
      data: {
        ...sioFormValues.data,
        users: sioFormValues.data.users.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ id, ...rest }) => rest,
        ),
      },
    };

    setFormValues(formValues);
    const newSio: CreateSIOAPIResponse = (await dispatch(addSio(submissionData))).payload as CreateSIOAPIResponse;
    if (newSio) {
      navigate(`/app/sio`, {
        state: {
          isCreate: true,
        },
      });
    }
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize24,
      fontWeight: fonts.fontWeight600,
      // marginLeft: '0.8em',
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      // marginLeft: '1em',
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    accordianLabel: {
      fontSize: fonts.fontSize20,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fontWeight400,
      },
    },
  };

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto', p: 2, borderRadius: '20px' }}>
      <Typography sx={commonTextStyle.heading} gutterBottom>
        Add New SIO
      </Typography>
      <Box boxShadow={3} sx={{ borderRadius: '20px', bgcolor: 'white', overflow: 'hidden' }}>
        <Grid container spacing={2} sx={{ width: '100%', margin: 0, p: 3 }}>
          {/* Overview Section */}
          <Grid item xs={12}>
            <Typography sx={commonTextStyle.heading} gutterBottom>
              Overview
            </Typography>
          </Grid>

          <Grid container spacing={2} alignItems="flex-end" sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6}>
              <Typography sx={commonTextStyle.labelStyle}>
                Social Impact Organization Name{' '}
                <Tooltip title="Enter The Application Name">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                name="organizationName"
                placeholder="Enter The Application Name"
                fullWidth
                value={sioFormValues.data.organizationName}
                onChange={handleInputChange}
                variant="outlined"
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={commonTextStyle.labelStyle}>
                ScaleWith Partnership Manager{' '}
                <Tooltip title="Select Partnership Manager">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <FormControl fullWidth>
                <Select
                  name="partnerManager"
                  labelId="manager-label"
                  value={sioFormValues.relationships.partnerManager.data.id}
                  onChange={handleInputChange}
                  variant="outlined"
                  sx={{
                    ...commonTextStyle.textFieldStyle,
                    '& .MuiSelect-select': {
                      fontSize: fonts.fontSize14,
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    <em>ScaleWith Partnership Manager</em>
                  </MenuItem>
                  {selectOptions}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography sx={commonTextStyle.labelStyle}>
                Website{' '}
                <Tooltip title="Enter the SIO website">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                name="website"
                placeholder="Enter the SIO website"
                fullWidth
                value={sioFormValues.data.website}
                onChange={handleInputChange}
                variant="outlined"
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
          </Grid>

          {/* Contacts Section */}
          {sioFormValues.data.users.map((contact, index) => (
            <React.Fragment key={contact.id}>
              <Grid container spacing={2} alignItems="flex-end" sx={{ mt: 2 }}>
                {/* Email */}
                <Grid item xs={12} sm={2}>
                  <Typography sx={commonTextStyle.labelStyle}>Email</Typography>
                  <TextField
                    name="username"
                    placeholder="Enter Email"
                    fullWidth
                    variant="outlined"
                    value={contact.username}
                    onChange={(event) => handleContactInputChange(index, event)}
                    sx={commonTextStyle.textFieldStyle}
                  />
                </Grid>

                {/* First Name */}
                <Grid item xs={12} sm={2}>
                  <Typography sx={commonTextStyle.labelStyle}>First Name</Typography>
                  <TextField
                    name="firstName"
                    placeholder="Enter First Name"
                    fullWidth
                    variant="outlined"
                    value={contact.firstName}
                    onChange={(event) => handleContactInputChange(index, event)}
                    sx={commonTextStyle.textFieldStyle}
                  />
                </Grid>

                {/* Last Name */}
                <Grid item xs={12} sm={2}>
                  <Typography sx={commonTextStyle.labelStyle}>Last Name</Typography>
                  <TextField
                    name="lastName"
                    placeholder="Enter Last Name"
                    fullWidth
                    variant="outlined"
                    value={contact.lastName}
                    onChange={(event) => handleContactInputChange(index, event)}
                    sx={commonTextStyle.textFieldStyle}
                  />
                </Grid>

                {/* Roles */}
                <Grid item xs={12} sm={4}>
                  <Typography sx={commonTextStyle.labelStyle}>Roles</Typography>
                  <FormControl fullWidth>
                    <Select
                      multiple
                      name={`roles-${index}`}
                      value={contact.roles}
                      onChange={(event) => handleRolesChange(index, event)}
                      renderValue={(selected) =>
                        selected
                          .map((value) => roles.find((role) => role.value === value)?.label)
                          .join(', ')
                      }
                      variant="outlined"
                      sx={{
                        ...commonTextStyle.textFieldStyle,
                        '& .MuiSelect-select': {
                          fontSize: fonts.fontSize14,
                        },
                      }}
                    >
                      {roles.map((role) => (
                        <MenuItem key={role.value} value={role.value}>
                          <Checkbox checked={contact.roles.indexOf(role.value) > -1} />
                          <ListItemText primary={role.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Remove Button */}
                <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {sioFormValues.data.users.length > 1 && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleRemoveContact(index)}
                    >
                      Remove
                    </Button>
                  )}
                </Grid>
              </Grid>
              {index < sioFormValues.data.users.length - 1 && <Divider sx={{ my: 2 }} />}
            </React.Fragment>
          ))}

          {/* Add Contact Button */}
          <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            {sioFormValues.data.users.length < 10 && (
              <Button
                variant="contained"
                onClick={handleAddContact}
                sx={{
                  ...commonTextStyle.buttonStyle,
                  bgcolor: colors.primary,
                  color: colors.secondary,
                  '&:hover': { bgcolor: 'primary.dark' },
                  '&:focus': { boxShadow: '0 0 0 2px rgba(0, 123, 255, .5)' },
                }}
              >
                Add Contact
              </Button>
            )}
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              sx={{
                ...commonTextStyle.buttonStyle,
                bgcolor: colors.primary,
                color: colors.secondary,
                '&:hover': { bgcolor: 'primary.dark' },
              }}
              variant="contained"
              disabled={
                !sioFormValues.data.organizationName ||
                !sioFormValues.data.users.length ||
                !sioFormValues.relationships.partnerManager.data.id
              }
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Form Preview Section */}
      <Box boxShadow={3} sx={{ borderRadius: '20px', height: '500px', bgcolor: 'white', mt: 4 }}>
        <Grid container spacing={2} sx={{ width: '100%', margin: 0, p: 3 }}>
          <Grid item xs={12}>
            <Typography sx={commonTextStyle.heading}>Form Preview</Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
            <NoteAltIcon sx={{ fontSize: '100px' }} color="primary" />
            <Typography sx={{ ...commonTextStyle.questionStyle, my: 3 }}>
              Please enter above details to get a preview of Organizations form.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateSIO;
