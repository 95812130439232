import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { RoleRequired } from '../../app';
import { FlexBox, InvertedRoundButton } from '../../shared/components';
import { CauseAreasTabs, Dialogs, UserRole } from '../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { setDialog } from '../main-screen';
import { setCauseAreaSettingsIsNew } from './cause-area-settings';
import { CauseAreaDashBoard } from './CauseAreaDashBoard';
import { CauseAreaMetrics } from './CauseAreasMetrics';
import { clearCauseAreasState } from './causeAreasSlice';

export const CauseAreas = () => {
  const state = useAppSelector((x) => x.causeAreas);
  const dispatch = useAppDispatch();
  const { selectedTab } = state;

  useEffect(
    () => () => {
      dispatch(clearCauseAreasState());
    },
    [dispatch],
  );

  const addCauseArea = () => {
    dispatch(setCauseAreaSettingsIsNew(true));
    dispatch(setDialog({ open: true, type: Dialogs.CauseAreaSettings, maxWidth: 'md' }));
  };

  const getTabComponent = (tab: CauseAreasTabs) => {
    switch (tab) {
      case CauseAreasTabs.Metrics:
        return <CauseAreaMetrics />;
      case CauseAreasTabs.Active:
      case CauseAreasTabs.Inactive:
      default:
        return <CauseAreaDashBoard />;
    }
  };

  return (
    <Box>
      <FlexBox>
        <Typography variant="h3" flex={1}>
          Cause Areas
        </Typography>
        <RoleRequired roles={[UserRole.SuperAdmin]}>
          <InvertedRoundButton onClick={addCauseArea} sx={{ color: '#000' }}>
            New Cause Area
          </InvertedRoundButton>
        </RoleRequired>
      </FlexBox>

      {getTabComponent(selectedTab)}
    </Box>
  );
};
