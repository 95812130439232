import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';

interface Props {
  programPage?: boolean;
}
const BusinessValue: FC<Props> = ({ programPage }) => (
  <Box sx={{ backgroundColor: 'white' }}>
    <Box
      className={
        !programPage ? 'businessImpact__container' : 'businessImpact__container program_page'
      }
    >
      {programPage && <Box className="background_business_value" />}
      <img
        alt="business value"
        crossOrigin=""
        src="https://scalewith-assets.s3.amazonaws.com/iStock-483232627.jpg"
        className="pdf-image businessImpactPhoto__container"
      />
      <Box className="businessImpact__text-container">
        <Typography className="impactPartnershipHeader">
          {programPage
            ? 'Generate Business Value'
            : 'How Impact Partnerships Generate Business Value'}
        </Typography>
        <Typography>
          {programPage
            ? 'By supporting a ScaleWith program, companies can simultaneously address a societal challenge and core business interests.'
            : 'By supporting a social impact program, companies can simultaneously address a societal challenge and core business interests.'}
        </Typography>
        <Typography>
          {programPage
            ? 'Below you will find information that will enable your company to report on several globally recognized reporting metrics.'
            : 'Below you will find how this program can help your company report— for the first time or additively—on several globally recognized reporting metrics.'}
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default BusinessValue;
