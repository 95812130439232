import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addCancelTokenEvent, postContactUs } from '../../../api';
import { RootState } from '../../../app';
// import { Account } from '../../interfaces';
import { formatString, UserClaims } from '../../utils';

interface ContactUsState {
  name: string;
  email: string;
  message: string;
  success: boolean;
  loading: boolean;
}

const initialState: ContactUsState = {
  name: '',
  email: '',
  message: '',
  success: false,
  loading: false,
};

export const postContactUsThunk = createAsyncThunk<number, void, { state: RootState }>(
  'contactUs/postContactUsThunk',
  async (_void, { getState, signal }) => {
    // const getMessage = (message: string, account?: Account) => {
    //   if (!account) return message;
    //   const { sageCustomerId, company } = account;

    //   return (
    //     formatString(sageCustomerId, '<b>Customer #: </b>', '<br/>') +
    //     formatString(company?.name, '<b>Customer Name: </b>', '<br/><br/>') +
    //     message
    //   );
    // };

    addCancelTokenEvent(signal);
    const { contactUs } = getState();
    const { name, email, message } = contactUs;
    

    const { status } = await postContactUs({
      name,
      email,
      // message: getMessage(message, app.account),
      message,
      app: 'GWS',
    });
    return status;
  },
);

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    setContactUsName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setContactUsEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setContactUsMessage: (state, action: PayloadAction<string>) => {
      console.log(state.message)
      state.message = action.payload;
    },
    clearContactUsState: (state) => {
      _.assign(state, initialState);
    },
    setContactUsUser: (state, action: PayloadAction<UserClaims>) => {
      const { first_name: firstName, last_name: lastName, email } = action.payload;
      state.name = formatString(firstName, '') + formatString(lastName);
      state.email = email ?? '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postContactUsThunk.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(postContactUsThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postContactUsThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      });
  },
});

export const {
  setContactUsName,
  setContactUsEmail,
  setContactUsMessage,
  clearContactUsState,
  setContactUsUser,
} = contactUsSlice.actions;

export const contactUsReducer = contactUsSlice.reducer;
