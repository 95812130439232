import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Chip, Card, CardContent, Divider, Typography, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import { fetchSioUser } from './sioSlice';
import ScaleWithLogo from '../../assets/svg/Scalewith-logo-new.svg';
import './formFilling.scss';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface ProgressTypes {
  general: { complete: number; total: number };
  operationalInformation: { complete: number; total: number };
  overviewAndMission: { complete: number; total: number };
}

export const TopBar = () => (
  <header>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img style={{ height: '40px' }} src={ScaleWithLogo} alt="scaleWith logo" />
      </Grid>
    </Grid>
  </header>
);

const SioFormFillingDashboard = () => {
  const { id, password } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sioState = useAppSelector((state) => state.sios);
  const { sio } = sioState;
  const progressReport: ProgressTypes = sio.progress;

  useEffect(() => {
    if (id && password) {
      dispatch(fetchSioUser({ id }));
    }
  }, [id]);

  const progressData = (type: string) => {
    let percentage = 0;
    let final;
    if (Object.keys(progressReport).length === 0) {
      final = 0;
    } else {
      switch (type) {
        case 'general':
          percentage = (progressReport.general.complete / progressReport.general.total) * 100;
          break;
        case 'operational':
          percentage =
            (progressReport.operationalInformation.complete /
              progressReport.operationalInformation.total) *
            100;
          break;
        case 'overview':
          percentage =
            (progressReport.overviewAndMission.complete / progressReport.overviewAndMission.total) *
            100;
          break;
        default:
      }
    }
    final = Math.ceil(percentage / 10) * 10;
    let chip;
    if (final < 100 || Number.isNaN(final)) {
      chip = (
        <Chip
          sx={{ bgcolor: '#E9F5FF' }}
          icon={<HourglassEmptyIcon color="primary" />}
          color="primary"
          label={final === 0 || Number.isNaN(final) ? 'Not Started' : `In Progress ${final}%`}
          variant="outlined"
        />
      );
    } else {
      chip = (
        <Chip
          color="success"
          sx={{ bgcolor: '#DBF6DB' }}
          icon={<CheckCircleOutlineIcon color="success" />}
          label="Completed"
          variant="outlined"
        />
      );
    }
    return chip;
  };

  const changeButton = (type: string) => {
    let percentage = 0;
    let final;
    if (Object.keys(progressReport).length === 0) {
      final = 0;
    } else {
      switch (type) {
        case 'general':
          percentage = (progressReport.general.complete / progressReport.general.total) * 100;
          break;
        case 'operational':
          percentage =
            (progressReport.operationalInformation.complete /
              progressReport.operationalInformation.total) *
            100;
          break;
        case 'overview':
          percentage =
            (progressReport.overviewAndMission.complete / progressReport.overviewAndMission.total) *
            100;
          break;
        default:
      }
    }
    final = Math.ceil(percentage / 10) * 10;
    let button;
    if (final < 100 || Number.isNaN(final)) {
      button = 'Start';
    } else {
      button = 'Review';
    }
    return button;
  };

  return (
    <section className="bgLight">
      <TopBar />
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardContent sx={{ padding: '80px' }}>
              <Typography
                sx={{
                  mb: 4,
                  fontSize: fonts.fontSize24,
                  fontWeight: fonts.fontWeight700,
                  color: colors.textPrimary,
                }}
                gutterBottom
              >
                ScaleWith Social Impact Network Application
              </Typography>
              <Typography
                sx={{
                  mb: 10,
                  fontSize: fonts.fontSize16,
                  fontWeight: fonts.fontWeight400,
                  color: colors.textPrimary,
                }}
              >
                Thank you for your interest in joining our network. ScaleWith harnesses the power of
                business transactions to support important social and environmental impacts while
                generating business value for companies around the world.
              </Typography>
              <Typography
                sx={{
                  my: 4,
                  fontSize: fonts.fontSize16,
                  fontWeight: fonts.fontWeight400,
                  color: colors.textPrimary,
                }}
              >
                Please complete and submit the following application.
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Card className="smallCards">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ mb: 2, fontSize: fonts.fontSize24, color: colors.textPrimary }}
                          >
                            General Information
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {sio.progress && progressData('general')}
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                          <Button
                            onClick={() =>
                              navigate(`/sio-form/${id}/${password}/general-information`)
                            }
                            variant="outlined"
                            size="large"
                            sx={{ color: colors.primary }}
                          >
                            {sio.progress && changeButton('general')}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="smallCards">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ mb: 2, fontSize: fonts.fontSize24, color: colors.textPrimary }}
                          >
                            Overview and Mission
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {sio.progress && progressData('overview')}
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                          <Button
                            onClick={() =>
                              navigate(`/sio-form/${id}/${password}/overview-information`)
                            }
                            variant="outlined"
                            size="large"
                            sx={{ color: colors.primary }}
                          >
                            {sio.progress && changeButton('overview')}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ mb: 3 }}>
                  <Card className="smallCards">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ mb: 2, fontSize: fonts.fontSize24, color: colors.textPrimary }}
                          >
                            Operational Information
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {sio.progress && progressData('operational')}
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                          <Button
                            onClick={() =>
                              navigate(`/sio-form/${id}/${password}/operational-information`)
                            }
                            variant="outlined"
                            size="large"
                            sx={{ color: colors.primary }}
                          >
                            {sio.progress && changeButton('operational')}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                </Grid>
                <Grid item xs={12} textAlign="right" sx={{ p: 2 }}>
                  <Button
                    disabled={sio.percentComplete !== 100}
                    onClick={() => navigate(`app/sio/organization-information/review`)}
                    sx={{
                      mb: 1,
                      backgroundColor: colors.primary,
                      color: colors.secondary,
                      '&:hover': { color: colors.secondary, background: colors.primary },
                    }}
                    variant="contained"
                    size="large"
                  >
                    Review and Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default SioFormFillingDashboard;
