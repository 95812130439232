import React, { FC, useMemo, useState } from 'react';
import { TextField, Typography, CircularProgress, Autocomplete } from '@mui/material';

import { FlexBox, PaddingRoundedBox } from '../../../shared/components';

interface Props {
  //   formConfig: AccountGiveSettingsConfig;
  //   setAccountFormData: (value: Account) => void;
  //   formDisabled?: boolean;
}

export const SelectSocialContributionForm: FC<Props> = () => {
  //   const { formConfig: formValue, setAccountFormData, formDisabled } = props;
  //   const {} = formValue;
  const [open, setOpen] = useState(false);
  const [options, setOptions] = React.useState(['HELLO', 'TEST ONE']);
  const loading = open && options.length === 0;
  // const dispatch = useAppDispatch();
  // const {companies} = useAppDispatch(state => state.company)

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const corporateEntityPartner = useMemo(
    () => (
      <Autocomplete
        id="asynchronous-demo"
        sx={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Asynchronous"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    ),
    [],
  );
  return (
    <PaddingRoundedBox
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        backgroundColor: theme.palette.common.grey.main,
      })}
    >
      <FlexBox>
        <Typography variant="h5" flex={1}>
          Create a Social Impact Contribution
        </Typography>
        {corporateEntityPartner}
      </FlexBox>
    </PaddingRoundedBox>
  );
};
