import { Box } from '@mui/material';
import React, { FC } from 'react';
import HeroImage from '../../assets/images/hero.jpg';

const imageWidth = '33.33%';

interface Props {
  hideLogo?: boolean;
}

export const Hero: FC<Props> = (props) => {
  const { hideLogo, children } = props;
  return (
    <Box
      sx={(theme) => ({
        display: 'none',
        width: imageWidth,
        [theme.breakpoints.up('md')]: { display: 'block' },
      })}
    >
      {hideLogo ? (
        <Box
          sx={(theme) => ({
            width: imageWidth,
            height: '100%',
            backgroundColor: theme.palette.secondary.dark,
            left: 0,
            top: 0,
            position: 'fixed',
          })}
        >
          {children}
        </Box>
      ) : (
        <Box
          sx={{
            width: imageWidth,
            backgroundImage: `url(${HeroImage})`,
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '100%',
            left: 0,
            top: 0,
            position: 'fixed',
          }}
        >
          <Box
            sx={{
              backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0.15) 30%)',
              height: '100%',
              position: 'absolute',
              width: '100%',
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </Box>
  );
};
