import { Typography, Box, Theme, Link } from '@mui/material';
import React, { FC, useState } from 'react';
import { FlexBox, LightTooltip } from '../../../shared/components';
import { CauseArea } from '../../../shared/interfaces';
import { isNullOrWhitespace } from '../../../shared/utils';

interface Props {
  cause: CauseArea;
  onClick?: (cause: CauseArea) => void;
  selected?: boolean;
  isDetail?: boolean;
  disableHover?: boolean;
  viewMode?: boolean;
}

const CauseComponent: FC<Props> = (props) => {
  const [hoveringLink, setHoveringLink] = useState(false);

  const { cause, onClick, selected, isDetail, disableHover, viewMode } = props;
  const {
    name,
    listImageName,
    detailImageName,
    tooltip,
    description,
    detailLinkUrl,
    detailLinkText,
  } = cause;

  const activeStyle = (theme: Theme) => ({
    background: 'rgba(232, 228, 225, 0.5)',
    border: `1px solid ${theme.palette.primary.main}`,
  });

  const buttonStyle = (theme: Theme) => ({
    color: 'white',
    padding: `8px 15px 5px;`,
    borderRadius: '50px',
    textDecoration: 'none',
    backgroundColor: theme.palette.primary.main,
    '& .MuiListItemIcon-root': { color: theme.palette.common.white, minWidth: 34 },
    '&.active': {
      backgroundColor: 'white',
      color: 'black',
      '& .MuiListItemIcon-root': { color: theme.palette.common.black },
    },
  });

  const surroundTooltip = (element: JSX.Element, text?: string, flag: boolean = true) =>
    text && flag ? (
      <LightTooltip title={text} placement="top" arrow>
        {element}
      </LightTooltip>
    ) : (
      element
    );

  const getImageURL = () => {
    if (viewMode) return detailImageName;
    return isDetail ? detailImageName : listImageName;
  };

  const gridItem = (
    <FlexBox
      sx={(theme) => ({
        flexDirection: 'column',
        padding: 1,
        borderRadius: '8px',
        cursor: disableHover ? 'inherit' : 'pointer',
        gap: 1,
        border: `1px solid ${theme.palette.common.white}`,
        [`${disableHover ? '' : ':hover,'}&.active-cause`]: activeStyle(theme),
      })}
      className={selected ? 'active-cause' : ''}
      onClick={() => {
        if (onClick && !hoveringLink) onClick(cause);
      }}
    >
      <Box height="300px">
        <Box
          sx={{
            height: '100%',
            backgroundImage: `url(${getImageURL()})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'grey',
            backgroundSize: 'cover',
          }}
        />
      </Box>
      <FlexBox sx={{ justifyContent: 'space-between', alignItems: 'baseline' }}>
        <Typography variant={isDetail ? 'h3' : 'h5'}>{name}</Typography>
        {!isDetail && (
          <Link
            onMouseLeave={() => setHoveringLink(false)}
            onMouseEnter={() => setHoveringLink(true)}
            href={detailLinkUrl}
            target="blank"
            rel="noopener noreferrer"
            title={detailLinkText}
            aria-label={detailLinkText}
          >
            {detailLinkText}
          </Link>
        )}
      </FlexBox>

      {isDetail && !isNullOrWhitespace(tooltip) && <Typography variant="h5">{tooltip}</Typography>}
      {isDetail && (
        <Typography variant="body1" textAlign="justify">
          {description}
        </Typography>
      )}
      {isDetail && (
        <Link
          sx={(theme) => ({ ...buttonStyle(theme) })}
          onMouseLeave={() => setHoveringLink(false)}
          onMouseEnter={() => setHoveringLink(true)}
          href={detailLinkUrl}
          target="blank"
          rel="noopener noreferrer"
          title={detailLinkText}
          aria-label={detailLinkText}
        >
          {detailLinkText}
        </Link>
      )}
    </FlexBox>
  );

  return <Box>{surroundTooltip(gridItem, tooltip, !disableHover)}</Box>;
};

export const Cause = React.memo(CauseComponent);
