import {
  Box,
  Button,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FlexBox } from '../../../../shared/components';
import { MAX_NUMBER_RECORDS } from '../../../../shared/constants';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import {
  setInstanceSettingsCauseArea,
  setProgramInstanceSettingsCauseArea,
} from '../instanceSettingsSlice';
import {
  clearInstanceSettingsCauseAreaFormValues,
  getCauseAreasInstanceSettingsThunk,
  setInstanceSettingsCauseAreaEntryMode,
} from './instanceSettingsCauseAreaSlice';
import { getAllProgramList } from '../../../../api/portal/programRequest';
import { CommonPaper } from '../../../../shared/components/StyledComponents';
import fonts from '../../../../assets/scss/font.module.scss';

interface Props {
  hideSave: () => void;
}

export const CauseAreaEntry: React.FC<Props> = ({ hideSave }) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((x) => x.instanceSettingsCauseArea);
  const stateCauseArea = useAppSelector((x) => x.instanceSettings);
  const [open, setOpen] = useState(false);

  const {
    values: { causeAreas },
  } = stateCauseArea;

  const {
    values: { causeArea, displayOrder },
    causeAreaTemplates,
    isNew,
  } = state;

  const commonTextFieldStyle = {
    '& label': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
    '& input': {
      fontSize: fonts.fontSize18,
    },
    '&::placeholder': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
  };

  useEffect(() => {
    if (causeAreaTemplates?.length !== 0) return;
    dispatch(
      getCauseAreasInstanceSettingsThunk({
        count: MAX_NUMBER_RECORDS,
        offset: 0,
        orderBy: 'name',
        descending: false,
        active: true,
      }),
    );
  }, [dispatch, causeAreaTemplates]);

  const [value, setValue] = React.useState<ProgramOptionType | null>(null);
  const [sioProgramsList, setSIOProgramsList] = React.useState([]);
  const [selectedProgram, setSelectedProgram] = React.useState<any>('');
  const [impacts, setImpacts] = React.useState<any[]>([]);

  const onSave = () => {
    let causeAreaPrograms: any = [];
    if (causeAreas === undefined) {
      causeAreaPrograms = [];
    } else {
      causeAreaPrograms = causeAreas;
    }
    const date: Date = new Date();
    const utcDate = date.toISOString();
    let clearValues: boolean = true;
    if (isNew) {
      const existedCauseArea = causeAreaPrograms.find(
        (item: any) => item?.causeArea?.id === selectedProgram,
      );
      const filtered = causeAreaPrograms.filter(
        (obj: any) => obj?.causeArea?.id !== selectedProgram,
      );
      if (existedCauseArea?.causeArea?.id) {
        if (existedCauseArea?.active) {
          clearValues = false;
          toast.error(`${existedCauseArea?.causeArea?.name} already exists in standard programs.`);
        } else {
          hideSave();
          dispatch(
            setProgramInstanceSettingsCauseArea([
              ...filtered,
              {
                ...existedCauseArea,
                active: true,
                displayOrder: causeAreaPrograms.filter((item: any) => item.active).length + 1,
                assignedDate: utcDate,
              },
            ]),
          );
        }
      } else {
        hideSave();
        dispatch(
          setProgramInstanceSettingsCauseArea([
            ...causeAreaPrograms,
            {
              active: true,
              causeArea: { id: selectedProgram, name: value?.platformProgramName, type: '' },
              skus: [],
              displayOrder:
                displayOrder === null
                  ? causeAreaPrograms.filter((item: any) => item.active).length + 1
                  : displayOrder,
              impact: impacts,
              assignedDate: utcDate,
            },
          ]),
        );
      }
    } else {
      hideSave();
      const replaceCauseArea = causeAreaPrograms.map((item: any) => {
        if (item.causeArea?.id === causeArea?.id) return { ...state.values };
        return item;
      });
      dispatch(setInstanceSettingsCauseArea([...replaceCauseArea]));
    }
    if (clearValues) {
      dispatch(setInstanceSettingsCauseAreaEntryMode(false));
      dispatch(clearInstanceSettingsCauseAreaFormValues());
    }
  };

  const handleBack = () => {
    hideSave();
    dispatch(setInstanceSettingsCauseAreaEntryMode(false));
    dispatch(clearInstanceSettingsCauseAreaFormValues());
  };

  interface ProgramOptionType {
    _id: any;
    platformProgramName: string;
    strategiesAndApproaches: any;
  }

  const defaultProps = {
    options: sioProgramsList,
    getOptionLabel: (option: ProgramOptionType) => option.platformProgramName,
  };

  const inputChange = async (inputChangeValue: any, isStandard: boolean) => {
    if (inputChangeValue?.length > 0) {
      const response = await getAllProgramList(inputChangeValue, isStandard);
      setSIOProgramsList(response.data.results);
      if (inputChangeValue === undefined) {
        setImpacts([]);
      }
    } else {
      setSIOProgramsList([]);
    }
  };
  const saveImpact = (data: any) => {
    data?.forEach((approach: any) => {
      setImpacts((existImpacts) => [
        ...existImpacts,
        {
          description: approach?.description,
          quantity: approach?.quantity,
          scaleType: approach?.scaleType?.$oid,
        },
      ]);
    });
  };
  useEffect(() => {
    setOpen(false);
  }, [selectedProgram]);
  return (
    <FlexBox
      sx={{
        flexDirection: 'column',
        bgcolor: 'background.paper',
        borderRadius: 5,
        borderColor: '#ccc',
        borderStyle: 'solid',
        borderWidth: '1px',
        p: 2,
        minWidth: 300,
      }}
    >
      <FlexBox sx={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 1 }}>
        <Typography sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }}>New Standard Program</Typography>
        <Box sx={{ width: 'auto' }}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ backgroundColor: '#fff' }}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={value === null}
            style={{ marginLeft: '0.5em' }}
          >
            Add
          </Button>
        </Box>
      </FlexBox>
      <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
        <FormControl>
          <Autocomplete
            {...defaultProps}
            forcePopupIcon={false}
            id="Add New Program"
            open={open}
            value={value}
            isOptionEqualToValue={(option, optionValue) =>
              option.platformProgramName === optionValue.platformProgramName
            }
            onInputChange={(event: any, newInputvalue) => {
              if (newInputvalue.length === 0) {
                setOpen(false);
                setImpacts([]);
              } else {
                setOpen(true);
                inputChange(newInputvalue, true);
              }
            }}
            onChange={(event: any, newValue: ProgramOptionType | null) => {
              setValue(newValue);
              /* eslint-disable no-underscore-dangle */
              setSelectedProgram(newValue?._id?.$oid);
              inputChange(newValue?.platformProgramName, true);
              saveImpact(newValue?.strategiesAndApproaches);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type Program Name"
                sx={commonTextFieldStyle}
              />
            )}
          />
        </FormControl>
        <FlexBox sx={{ flexDirection: 'column' }}>
          {impacts && impacts?.length > 0 && <Typography variant="h5" fontWeight={700} mb={2}>
            Measurable social impact
          </Typography>}
          {impacts && impacts?.length > 0 ? (
            <CommonPaper>
              <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Impact Name</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }} align="right">
                        Impact Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {impacts &&
                      impacts.map(
                        (impactData: any) =>
                          impactData?.quantity > 0 && (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row" sx={{ fontSize: '1rem' }}>
                                {impactData?.description}
                              </TableCell>
                              <TableCell align="right" sx={{ fontSize: '1rem' }}>
                                {impactData?.quantity}
                              </TableCell>
                            </TableRow>
                          ),
                      )}
                  </TableBody>
                </Table>
              </div>
            </CommonPaper>
          ) : (
            <div>
              <Typography sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700, }}>No program selected</Typography>
            </div>
          )}
        </FlexBox>
      </FlexBox>
      {/* <FlexBox sx={{ flexDirection: 'row', justifyContent: 'end', marginTop: 3 }}>
        <Box sx={{ width: 175, paddingTop: 2 }}>
          <Button variant="contained" color="primary" onClick={onSave} disabled={value === null}>
            Save Standard Program
          </Button>
        </Box>
      </FlexBox> */}
    </FlexBox>
  );
};
