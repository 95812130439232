import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCancelTokenEvent, invitePostUser } from '../../../../../api';
import { RootState } from '../../../../../app';
import { UserRole } from '../../../../../shared/enums';

interface InvitedUser {
  firstName?: string;
  lastName?: string;
  username: string;
  roles?: Array<string>;
  type?: string;
}

interface InviteUserState {
  invitedUser: InvitedUser;
  success: boolean;
  loading: boolean;
}

const initialState: InviteUserState = {
  invitedUser: { firstName: '', lastName: '', username: '', roles: [], type: 'new-user' },
  success: false,
  loading: false,
};

interface SelectedRoleModel {
  role: string;
  isSelected: boolean;
}

export const postInviteUserThunk = createAsyncThunk<number, void, { state: RootState }>(
  'inviteUser/postInviteUserThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const { inviteUser } = getState(); // To get accountId add app
    // const accountId = app.account?.id ?? app.userClaims?.accountId ?? '-1';
    const { firstName, lastName, username, roles, type } = inviteUser.invitedUser;
    const finalRoles = roles?.length ? roles : [UserRole.Sales];

    const requestObject: InvitedUser =
      type === 'current-user'
        ? { username, roles: finalRoles, type }
        : { firstName, lastName, username, roles: finalRoles, type };

    const { status } = await invitePostUser(requestObject);
    return status;
  },
);

const inviteUserSlice = createSlice({
  name: 'inviteUser',
  initialState,
  reducers: {
    setInviteUserFirstName: (state, action: PayloadAction<string>) => {
      state.invitedUser.firstName = action.payload;
    },
    setInviteUserLastName: (state, action: PayloadAction<string>) => {
      state.invitedUser.lastName = action.payload;
    },
    setInviteUserEmail: (state, action: PayloadAction<string>) => {
      state.invitedUser.username = action.payload;
    },
    setInviteUserType: (state, action: PayloadAction<string>) => {
      state.invitedUser.type = action.payload;
    },
    setInviteUserRoles: (state, action: PayloadAction<SelectedRoleModel>) => {
      const { role, isSelected } = action.payload;
      state.invitedUser.roles ??= [];
      const index = state.invitedUser.roles.indexOf(role);
      if (isSelected && index === -1) {
        state.invitedUser.roles.push(role);
      } else if (!isSelected && index !== -1) {
        state.invitedUser.roles.splice(index, 1);
      }
    },
    clearInviteUserState: (state) => {
      state.invitedUser = initialState.invitedUser;
      state.loading = initialState.loading;
      state.success = initialState.success;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postInviteUserThunk.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(postInviteUserThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postInviteUserThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      });
  },
});

export const {
  setInviteUserFirstName,
  setInviteUserLastName,
  setInviteUserEmail,
  setInviteUserRoles,
  clearInviteUserState,
  setInviteUserType,
} = inviteUserSlice.actions;

export const inviteUserReducer = inviteUserSlice.reducer;
