/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { COOKIES_POLICY_URL, TERMS_OF_USE_URL, PRIVACY_POLICY_URL } from '../../shared/constants';
import { Dialogs } from '../../shared/enums';
import { useAppDispatch, useInstance } from '../../shared/hooks';
import { getAllRightsReservedText, isUserAuthorized } from '../../shared/utils';
import { setDialog } from '../main-screen';

export function RegisterFooter() {
  const dispatch = useAppDispatch();
  const { instance: currentInstance } = useInstance();

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '10px',
        width: '100%',
        overflowX: 'auto',
        alignItems: 'center',
        columnGap: 1.5,
        padding: '40px 0',
        borderTop: '0.1rem solid rgba(0,0,0,0.05)',
      }}
    >
      <Typography sx={{ marginBottom: 0, flex: 1, minWidth: 325 }} paragraph>
        {getAllRightsReservedText()}
      </Typography>
      <Link
        href={currentInstance?.settings?.termsOfUseUrl ?? TERMS_OF_USE_URL}
        target="blank"
        rel="noopener noreferrer"
        title="Terms & Conditions"
        aria-label="Terms & Conditions"
        minWidth={105}
      >
        Terms & Conditions
      </Link>
      <Link
        href={currentInstance?.settings?.privacyPolicyUrl ?? PRIVACY_POLICY_URL}
        target="blank"
        rel="noopener noreferrer"
        title="privacy policy"
        aria-label="privacy policy"
        minWidth={110}
      >
        Privacy Policy
      </Link>
      <Link
        href={currentInstance?.settings?.cookiePolicyUrl ?? COOKIES_POLICY_URL}
        target="blank"
        rel="noopener noreferrer"
        title="cookie policy"
        aria-label="cookie policy"
        minWidth={115}
      >
        Cookies Policy
      </Link>
      <Link
        sx={{ cursor: 'pointer', minWidth: 90 }}
        onClick={() => dispatch(setDialog({ open: true, type: Dialogs.ContactUs, maxWidth: 'md' }))}
      >
        Contact Us
      </Link>
      {isUserAuthorized(true) && (
        <Link component={RouterLink} to="/logout" minWidth={60}>
          Logout
        </Link>
      )}
    </Box>
  );
}
