import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAppDispatch } from '../../shared/hooks';
import { NewContribution } from './new-incentive';
import { clearContributionState } from './incentiveSlice';
import { Details } from './details';
import { RoleRequired } from '../../app/Security';
import { NEW_CONTRIBUTION_PERMISSIONS } from '../../shared/constants';

const routes = (
  <Routes>
    <Route
      path="new/*"
      element={
        <RoleRequired page roles={NEW_CONTRIBUTION_PERMISSIONS}>
          <NewContribution />
        </RoleRequired>
      }
    />
    <Route
      path=":incentiveId/draft"
      element={
        <RoleRequired page roles={NEW_CONTRIBUTION_PERMISSIONS}>
          <Details draft />
        </RoleRequired>
      }
    />
    <Route path=":incentiveId/details" element={<Details />} />
  </Routes>
);

export function Contribution() {
  const dispatch = useAppDispatch();
  useEffect(() => () => {
    dispatch(clearContributionState());
  });

  return routes;
}
