import { Box, Button, Grid, Modal, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import defaultImage from '../../../assets/svg/Upload.svg';
import Close from '../../../assets/svg/closeIcon.svg';
import eyeIcon from '../../../assets/svg/eye_Icon.svg';
import {ReactComponent as CheckIcon} from '../../../assets/svg/Subtract.svg';
import {
  uploadAccountSettingsFile,
  deleteAccountSettingsFile,
} from '../../../api/portal/accountRequests';
import { useMediaQuery } from '../../../shared/components/common/useMediaQuery';
import colors from '../../../assets/scss/color.module.scss';

interface props {
  formId?: string;
  companyLogo?: string;
  onChangeBrand: (value: any) => void;
  onDeleteBrand:()=>void
}

const BrandLogo: React.FC<props> = ({ formId, companyLogo,onChangeBrand,onDeleteBrand}) => {
  const [file, setFile] = useState({
    name: companyLogo ? companyLogo?.split('/').pop() : '',
    url: companyLogo || '',
  });
  const [isHovering, setIsHovering] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const isMediumDevice = useMediaQuery(`(min-width:768px) and (max-width:900px`);
  const [errorMessage, setErrorMessage] = useState('');

  const text = [
    { description: 'The maximum allowed file size for logo uploads is 2MB.'},
    { description: 'To ensure the best visibility, we suggest uploading a logo with a height of 40 pixels.'},
    { description: 'It is recommended to upload a logo with a transparent background in one of the following formats: JPEG, PNG, SVG.'},
  ]

  // eslint-disable-next-line react/no-unstable-nested-components, @typescript-eslint/no-unused-vars
  const ToBeStyledTooltip = ({ className, color, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ tooltip: className }} />
  );

  const StyledTooltip = styled(ToBeStyledTooltip)(({ theme, color }) => ({
    [`& .${tooltipClasses.tooltipArrow}`]: {
      backgroundColor: color,
      boxShadow: '1px 2px 20px 0px #0000001A',
    },
    [`& .${tooltipClasses.arrow}`]: {
      "&:before": {
        border: `1px solid ${color}`
      },
      color
    },
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Inter !important',
    fontWeight: 400,
    minWidth: '420px',
    fontSize: theme.typography.pxToRem(14),
    borderRadius: '5px',
    boxShadow: '1px 2px 20px 0px #0000001A',
    arrow: {
      fontSize: 20,
      color: "#4A4A4A",
      "&::before": {
        backgroundColor: "blue",
        border: "2px solid red"
      }
    }
  }));

  const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background:'linear-gradient(180deg, #031A65 0%, rgba(47, 60, 111, 0.98) 50%, rgba(47, 60, 111, 0.9) 100%)',
    boxShadow: 24,
    borderRadius: '20px',
    height: '50vh',
    width: '50%',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#FFFFFF',
    border: '1px solid #E6E6E6',
    boxShadow: 24,
    borderRadius: 5,
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleChange = (event: any) => {
    if (event.target.files.length > 0) {
if (event.target.files[0].size < 250000) {
      const url = URL.createObjectURL(event.target.files[0]);
      const fileType= event.target.files[0].type;
      if (
        fileType === 'image/jpeg' ||
        fileType === 'image/png' ||
        fileType === 'image/svg+xml'
      ) {
        setFile({
          name: event.target.files[0]?.name,
          url,
        });
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        uploadAccountSettingsFile(formId!, formData).then((res) => {   
          if (res.status === 200) {
            onChangeBrand(res?.data?.company?.companyLogo)
            toast.success('Brand logo uploaded successfully');
          } else {
            setErrorMessage('Failed to upload Brand logo image');
          }
        });
      } else {
        setErrorMessage('Supported formats: JPEG, PNG, SVG');
      }
} else {
  setErrorMessage('The file size of your logo exceeds the maximum allowed limit of 2MB.');
}
    } else {
      setFile({ name: '', url: '' });
    }
  };

  return (
    <>
      {/* Brand logo preview modal */}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle}>
          <Box>
            <Box onClick={() => setOpen(false)}>
              <img
                width="100%"
                src={file?.url}
                alt="companyLogo"
                style={{ objectFit: 'contain' }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* delete and cancel modal */}
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                bgcolor: '#FDE3E3',
                mb: 2,
                p: 2,
                borderRadius: '20px 20px 0 0',
              }}
            >
              <Typography variant="h5" color="error">
                Delete Confirmation
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ p: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Are you sure you want to delete the Brand Logo?
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Deleting this record cannot be undone and all associated data will be permanently
                removed. Please confirm that you wish to proceed with the deletion.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ p: 2, borderTop: '1px solid #E6E6E6' }}>
              <>
                <Button
                  onClick={() => {
                    deleteAccountSettingsFile(formId!).then((res) => {
                      if (res.status === 200) {
                        onDeleteBrand();
                        toast.success('Brand logo deleted successfully');
                        setOpenDeleteModal(false);
                        setFile({ name: '', url: '' });
                      } else {
                        toast.warning('Failed to delete Brand logo');
                        setOpenDeleteModal(false);
                      }
                    });
                  }}
                  sx={{ float: 'right', ml: 3 }}
                  variant="contained"
                  size="large"
                  color="error"
                >
                  Delete Brand Logo
                </Button>
                <Button
                  onClick={() => setOpenDeleteModal(false)}
                  sx={{ float: 'right', ml: 3 }}
                  variant="outlined"
                  size="large"
                  color="primary"
                >
                  Cancel
                </Button>
              </>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontFamily: 'Inter,sans-serif', fontSize: '16px', fontWeight: '500' }}>
          Brand Logo
        </Typography>
        <Grid sx={{ display: 'flex', marginTop: '10px' }}>
          {isHovering && file?.name !== '' ? (
            <Grid
              style={{
                width: '76px',
                height: '77px',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#031A65',
                backgroundImage: `url(${file?.url})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
              onClick={() => setOpen(true)}
            >
              <div
                onMouseLeave={handleMouseOut}
                style={{
                  borderRadius: '5px',
                  width: '76px',
                  height: '77px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#000000',
                  opacity: '60%',
                }}
              >
                <img
                  src={eyeIcon}
                  style={{ cursor: 'pointer', width: '18px', height: '12px', color: '#ffffff' }}
                  alt="eye Icon"
                />
                <Typography
                  sx={{
                    fontFamily: 'Inter,sans-serif',
                    fontSize: '14px',
                    fontWeight: '500',
                    color: '#ffffff',
                    marginTop: '4px',
                  }}
                >
                  Preview
                </Typography>
              </div>
            </Grid>
          ) : (
            <img
              src={file?.name === '' ? defaultImage : file?.url}
              style={{
                objectFit: 'contain',
                cursor: 'pointer',
                height: '76px',
                width: '76px',
                borderRadius: '5px',
                background: `${
                  file?.name !== ''
                    ? 'linear-gradient(180deg, #031A65 0%, rgba(47, 60, 111, 0.98) 50%, rgba(47, 60, 111, 0.9) 100%)'
                    : ''
                }`,
              }}
              onMouseMove={handleMouseOver}
              alt="info Icon"
            />
          )}
          <Grid sx={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
            <Grid
              style={{
                width: `${isMediumDevice ? '375px' : '545px'}`,
                height: 'Fixed (46px)',
                gap: '10px',
              }}
              sx={{
                border: `${errorMessage ? '1px solid #F64747' : '1px solid #C4C4C4'}`,
                background: '#ffffff',
                padding: '11px 10px 11px 10px',
                borderRadius: '5px',

                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography
                  sx={{
                    fontFamily: 'Inter,sans-serif',
                    fontSize: '14px',
                    fontWeight: `${file?.name === '' ? '400' : '500'}`,
                    fontStyle: `${file?.name === '' ? 'italic' : ''}`,
                    color: `${file?.name === '' ? '#959595' : '#12130F'}`,
                  }}
                >
                  {file?.name === '' ? 'No file selected yet' : file?.name}
                </Typography>
                {file?.name !== '' && (
                  <Button
                    type="button"
                    style={{ background: 'none', border: 'none' }}
                    onClick={() => setOpenDeleteModal(true)}
                  >
                    <img
                      src={Close}
                      alt="delete"
                      style={{
                        cursor: 'pointer',
                        color: '#000000',
                        width: '10.5px',
                        height: '10.5px',
                        marginLeft: '10px',
                      }}
                    />
                  </Button>
                )}
              </Grid>
              <Button
                sx={{
                  border: `1px solid ${colors.primary}`,
                  padding: '8px 20px 8px 20px',
                  borderRadius: '5px',
                  backgroundColor: '#fff',
                  color: colors.primary,
                }}
              >
                <div>
                  <input
                    type="file"
                    name="uploadfile"
                    id="img"
                    hidden
                    onChange={handleChange}
                    accept=".jpg, .jpeg, .png, .svg"
                  />
                  <label
                    htmlFor="img"
                    style={{
                      fontFamily: 'Roboto,sans-serif',
                      fontSize: '12px',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    {file?.name === '' ? 'Upload File' : 'Reupload'}
                  </label>
                </div>
              </Button>
            </Grid>
            {errorMessage !== '' && (
              <Typography
                sx={{ color: '#F64747', fontSize: '12px', lineHeight: '16px', paddingTop: '5px' }}
              >
                {errorMessage}
              </Typography>
            )}
            <Typography
              sx={{
                fontFamily: 'Inter,sans-serif',
                fontSize: '12px !important',
                fontWeight: '400',
                marginTop: '5px',
              }}
            >
              Criteria for logo upload?{' '}
              <StyledTooltip
                arrow
                color="#FFFFFF"
                placement="bottom-start"
                title={text.map((index) =>(
                  <div style={{ padding: '10px', gap: '10px' }}>
                    <div style={{ display: 'flex' }}>
                    <CheckIcon style={{ width: '15px', height: '15px' }} />
                    <span
                      style={{
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: '400 !important',
                        fontSize: '14px',
                        lineHeight: '20px',
                        width: '400px',
                        color: '#000000',
                        marginLeft: '5px',
                      }}
                    >
                      {index.description}
                    </span>
                    </div>
                    </div>
                    ))
                }
              >
                <InfoIcon sx={{ fontSize: '15px', color: '#868e96', marginBottom: '-3px' }} />
              </StyledTooltip>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default BrandLogo;
