import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { InformativeDialog } from '../../enums';

interface InformativeDialogState {
  title: string;
  contentTitle: string;
  text?: string;
  type?: InformativeDialog;
  confirmed: boolean;
  confirmText?: string;
  close: boolean;
  value?: string;
  hideCheckBox?: boolean;
}

const initialState: InformativeDialogState = {
  title: '',
  text: '',
  contentTitle: '',
  close: false,
  type: undefined,
  confirmed: false,
  confirmText: undefined,
  value: undefined,
  hideCheckBox: false,
};

const informativeDialogSlice = createSlice({
  name: 'mainScreen informative Dialog',
  initialState,
  reducers: {
    setInformativeDialog: (state, action: PayloadAction<Partial<InformativeDialogState>>) => {
      _.assign(state, action.payload);
    },
    clearInformativeDialog: (state) => {
      _.assign(state, initialState);
    },
    setInformativeDialogConfirmed: (state, action: PayloadAction<boolean>) => {
      state.confirmed = action.payload;
    },
    setInformativeDialogClose: (state) => {
      state.close = true;
    },
  },
});

export const {
  setInformativeDialog,
  clearInformativeDialog,
  setInformativeDialogConfirmed,
  setInformativeDialogClose,
} = informativeDialogSlice.actions;

export const informativeDialogReducer = informativeDialogSlice.reducer;
