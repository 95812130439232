import { Autocomplete, Box, Button, Link, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputBox, Loading } from '../../shared/components';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { isNullOrWhitespace, emailValid, platformProductType } from '../../shared/utils';
import { CommonBody } from './CommonBody';
import {
  getRegisterIndustriesThunk,
  postRegisterUserThunk,
  setRegisterCompany,
  setRegisterEmail,
  setRegisterFirstName,
  setRegisterIndustry,
  setRegisterLastName,
  setProductType,
  setLocale
} from './registerSlice';
import { IndustryGrouped } from '../../shared/interfaces';

export function Register() {
  const registerState = useAppSelector((state) => state.register);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { firstName, lastName, company, emailAddress, industry, productType, loading, industries, success, locale } =
    registerState;


  const industriesGrouped: Array<IndustryGrouped> = industries.flatMap((x) =>
    x.items.map((i) => ({ group: x.name, label: i.subIndustry, value: i.subIndustry })),
  );

  const isRegisterValid = () =>
    !isNullOrWhitespace(firstName) &&
    !isNullOrWhitespace(lastName) &&
    !isNullOrWhitespace(company) &&
    !isNullOrWhitespace(emailAddress) &&
    emailValid(emailAddress) &&
    !isNullOrWhitespace(industry?.value) &&
    !isNullOrWhitespace(productType) && 
    !isNullOrWhitespace(locale);


  useEffect(() => {
    dispatch(setProductType(platformProductType()));

  }, [dispatch]);

  useEffect(() => {
    dispatch(getRegisterIndustriesThunk());

  }, [dispatch]);

  useEffect(() => {
    if (success) navigate('/register-finished');
  }, [navigate, success]);

  const registerUser = () => {
    dispatch(postRegisterUserThunk());
  };

  if (loading) return <Loading loading={loading} />;



  const handleproductType = (
    event: React.MouseEvent<HTMLElement>,
    newProductType: string,
  ) => {
    dispatch(setProductType(newProductType))
  };

  const handleLocale = (
    event: React.MouseEvent<HTMLElement>,
    newLocale: string,
  ) => {
    dispatch(setLocale(newLocale))
  };

  return (
    <CommonBody hideLogo coloredLogo>

      <Box sx={{ marginTop: '20px' }}>

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '40px', marginBottom: '10px' }}>
          <ToggleButtonGroup
            value={productType}
            exclusive
            onChange={handleproductType}
            aria-label="productType"
          >
            <ToggleButton value="buyWith" aria-label="buywith">
              <Typography sx={{ fontWeight: 'bold' }}>BuyWith</Typography>
            </ToggleButton>
            <ToggleButton value="sellWith" aria-label="sellwith">
              <Typography sx={{ fontWeight: 'bold' }}>SellWith</Typography>
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            value={locale}
            exclusive
            onChange={handleLocale}
            aria-label="locale"
          >
            <ToggleButton value="US" aria-label="us">
              <Typography sx={{ fontWeight: 'bold' }}>US</Typography>
            </ToggleButton>
            <ToggleButton value="UK" aria-label="uk">
              <Typography sx={{ fontWeight: 'bold' }}>UK</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <TextField
          label="First Name"
          fullWidth
          value={firstName}
          onChange={(e) => {
            dispatch(setRegisterFirstName(e.target.value));
          }}
          required
        />
        <InputBox>
          <TextField
            label="Last Name"
            fullWidth
            value={lastName}
            onChange={(e) => {
              dispatch(setRegisterLastName(e.target.value));
            }}
            required
          />
        </InputBox>
        <InputBox>
          <TextField
            label="Email Address"
            fullWidth
            value={emailAddress}
            onChange={(e) => {
              dispatch(setRegisterEmail(e.target.value));
            }}
            error={!emailValid(emailAddress) && emailAddress.length > 0}
            required
          />
        </InputBox>
        <InputBox>
          <TextField
            label="Company"
            fullWidth
            value={company}
            onChange={(e) => {
              dispatch(setRegisterCompany(e.target.value));
            }}
            required
          />
        </InputBox>
        <InputBox sx={{ marginTop: 2 }}>
          <Autocomplete
            disablePortal
            fullWidth
            id="combo-box-industry"
            options={industriesGrouped}
            value={industry!}
            groupBy={(option) => option.group}
            onChange={(event: any, newValue: IndustryGrouped | null) => {
              dispatch(setRegisterIndustry(newValue));
            }}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField {...params} required label="Select an industry" />}
            disableClearable
          />
        </InputBox>
        <Button
          sx={{ marginTop: '80px' }}
          variant="contained"
          fullWidth
          size="large"
          disabled={!isRegisterValid()}
          onClick={registerUser}
        >
          Create Account
        </Button>
        <Typography
          sx={{ marginTop: 2, fontWeight: '700', color: 'black', textAlign: 'center' }}
          paragraph
        >
          {'Do you have an account? '}
          <Link href={window.LOGIN_UI_URL} title="log in" aria-label="log in">
            Log in
          </Link>
        </Typography>
      </Box>
    </CommonBody>
  );
}
