import { Paper } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import Logo from '../../shared/components/Logo';
import { Hero } from '../register';
import { ShareLoginForm } from './ShareLoginForm';

interface LoginFormProps {
  onLogin?: (password: string) => void;
  errorMessage?: string;
  loading?: boolean;
}
export const ShareLoginPage: FC<LoginFormProps> = ({ onLogin, errorMessage, loading }) => (
  <Paper>
    <Hero />
    <Box>
      <Logo />
      <ShareLoginForm onLogin={onLogin} errorMessage={errorMessage} loading={loading} />
    </Box>
  </Paper>
);
