import { Typography, LinearProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { clearDialog } from '../../../features';
import { Dialogs, ContributionStatus } from '../../enums';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getContributionActionMessage, getContributionStatusLabel } from '../../utils';
import { FlexBox } from '../StyledComponents';
import { patchUpdateContributionStatusThunk } from './updateIncentiveStatusSlice';

export const UpdateContributionsProgressBar = () => {
  const state = useAppSelector((x) => x.updateContributionStatus);
  const dispatch = useAppDispatch();

  const { updatedIncentives, failedIncentives, incentives, proposedStatus } = state;

  useEffect(() => {
    incentives?.forEach((x) => dispatch(patchUpdateContributionStatusThunk(x.id ?? '-1')));
  }, [dispatch, incentives]);

  useEffect(() => {
    if (incentives?.length !== updatedIncentives + failedIncentives) return;
    toast.success(
      `${updatedIncentives} of ${incentives.length} Impacts ${getContributionActionMessage(
        proposedStatus! as ContributionStatus,
      )}`,
    );
    dispatch(clearDialog(Dialogs.UpdateContributionStatus));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedIncentives, failedIncentives, dispatch, incentives]);

  if (!incentives || incentives.length === 0) return null;

  return (
    proposedStatus && (
      <FlexBox sx={{ flexDirection: 'column', gap: 3 }} paddingX={6} paddingY={4}>
        <Typography variant="h3">{getContributionStatusLabel(proposedStatus)} Contributions</Typography>
        <Typography variant="h6">Do not close or refresh this page</Typography>
        <FlexBox sx={{ gap: 2, flexDirection: 'column' }}>
          <LinearProgress />
          <Typography>{`${updatedIncentives} of ${
            incentives.length
          } Impacts ${getContributionStatusLabel(proposedStatus)}`}</Typography>
        </FlexBox>
      </FlexBox>
    )
  );
};
