import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import {
  renewAccessToken,
  addCancelTokenEvent,
  getAccount,
  getIndustries,
  patchContribution,
  getInstances,
  getInstance,
  getLocale,
} from '../api';
import { RootState } from './store';
import { redirectToLogin, UserClaims, GWCookies } from '../shared/utils';
import { CK_AUTH_TOKEN, CK_REFRESH_TOKEN } from '../shared/constants';
import {
  Account,
  AccountInstanceLocale,
  GridRequest,
  GridResponse,
  IndustryGrouped,
  Instance,
  RenewAccessTokenResponse,
} from '../shared/interfaces';
import { saveAccountSettingsThunk } from '../features/accounts/account-settings/accountSettingsSlice';
import { ContributionStatus } from '../shared/enums';

export const decodeUserClaims = (token: string) => jwtDecode<UserClaims>(token);

interface InstancesGridRequest extends GridRequest {
  status: string;
}
export interface AppState {
  userClaims?: UserClaims;
  account?: Account;
  loading: boolean;
  industriesGrouped: Array<IndustryGrouped>;
  loadingIndustries: boolean;
  loadingInstances: boolean;
  instances: Array<Instance>;
  accountError?: boolean;
  userData?: AccountInstanceLocale;
  loadingUserData: boolean;
  showOptionsLeftNavBar: boolean;
  associatedInstance?: Instance | null;
  loadingAssociatedInstance: boolean;
  callAppInstancesThunk?: boolean;
}

const initialState: AppState = {
  userClaims: undefined,
  loading: true,
  industriesGrouped: [],
  loadingIndustries: true,
  accountError: false,
  loadingInstances: false,
  instances: [],
  userData: {
    account: {},
    instance: {
      logo:'sellwith'
    },
  },
  loadingUserData: false,
  loadingAssociatedInstance: false,
  showOptionsLeftNavBar: true,
  callAppInstancesThunk: true,
};

export const renewAccessTokenThunk = createAsyncThunk<
  RenewAccessTokenResponse,
  void,
  { state: RootState }
>('app/renewAccessTokenThunk', async (_void, { getState, signal }) => {
  addCancelTokenEvent(signal);
  const {
    app: { associatedInstance },
  } = getState();
  const refreshToken = GWCookies.get(CK_REFRESH_TOKEN);
  if (!refreshToken?.toString())
    redirectToLogin({ authError: false }, true, associatedInstance?.settings?.loginUrl);
  const { data } = await renewAccessToken(refreshToken!.toString());

  const claims = decodeUserClaims(data?.access_token ?? '');
  const tokenExp = new Date(claims.exp * 1000);
  if (new Date() > tokenExp) redirectToLogin({ authError: false });

  GWCookies.set(CK_AUTH_TOKEN, data?.access_token, tokenExp);
  return data;
});

export const getAccountInstanceLocaleThunk = createAsyncThunk<
  AccountInstanceLocale,
  void,
  { state: RootState }
>('app/accountInstanceLocale', async (_void, { getState, signal }) => {
  addCancelTokenEvent(signal);
  const { app } = getState();
  if (!app?.account?.id) return {};
  const id = app.account?.id;
  const { data: dataAccount } = await getAccount(id);
  if (!dataAccount.instance?.id) return { account: dataAccount };
  const { data: dataInstance } = await getInstance(dataAccount.instance?.id);
  if (!dataInstance.settings?.locale?.id) return { account: dataAccount, instance: dataInstance };
  const { data: dataLocale } = await getLocale(dataInstance.settings?.locale?.id);
  const AccountInformation = {
    account: dataAccount,
    instance: dataInstance,
    locale: dataLocale,
  };
  return AccountInformation;
});

export const getInstanceAssociatedUserThunk = createAsyncThunk<Instance, string>(
  'app/getInstanceAssociatedUserThunk',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getInstance(id);
    return data;
  },
);

export const getAppAccountThunk = createAsyncThunk(
  'app/getAppAccountThunk',
  async (id: string, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getAccount(id);
    return data;
  },
);

export const refreshAppAccountThunk = createAsyncThunk<Account, void, { state: RootState }>(
  'app/refreshAppAccountThunk',
  async (_void, { getState, signal }) => {
    const { app } = getState();
    addCancelTokenEvent(signal);
    const id = app.account?.id ?? app.userClaims?.accountId ?? '-1';
    const { data } = await getAccount(id);
    return data;
  },
);

export const getIndustriesGroupedThunk = createAsyncThunk(
  'account/getIndustriesThunk',
  async (_void, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getIndustries();
    const groupedIndustries = data.flatMap((x) =>
      x.items.map((i) => ({ group: x.name, label: i.subIndustry, value: i.subIndustry })),
    );
    return groupedIndustries;
  },
);

export const patchContributionStatusAppThunk = createAsyncThunk(
  'app/patchContributionStatusAppThunk',
  async (value: { id: string; status: ContributionStatus }, { signal }) => {
    addCancelTokenEvent(signal);
    const { id, status } = value;
    const { data } = await patchContribution(id, status, [
      { comment: 'Payment processing canceled by user' },
    ]);
    return data;
  },
);

export const getInstancesAppThunk = createAsyncThunk<GridResponse<Instance>, InstancesGridRequest>(
  'account/getInstancesAccountThunk',
  async (request: InstancesGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getInstances(request);
    return data;
  },
);

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserClaims: (state, action: PayloadAction<UserClaims>) => {
      state.userClaims = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAppAccountData: (state, action: PayloadAction<Account>) => {
      _.merge(state.account, action.payload);
    },
    setAppShowOptionsLeftNavBar: (state, action: PayloadAction<boolean>) => {
      state.showOptionsLeftNavBar = action.payload;
    },
    setCallInstanceThunk: (state, action: PayloadAction<boolean>) => {
      state.callAppInstancesThunk = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppAccountThunk.fulfilled, (state, action: PayloadAction<Account>) => {
        state.account = action.payload;
        state.accountError = false;
        state.loading = false;
      })
      .addCase(getAppAccountThunk.rejected, (state) => {
        state.account = undefined;
        state.accountError = true;
        state.loading = false;
        state.associatedInstance = null;
      })
      .addCase(getAppAccountThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(refreshAppAccountThunk.fulfilled, (state, action: PayloadAction<Account>) => {
        state.account = action.payload;
      })
      .addCase(refreshAppAccountThunk.rejected, (state) => {
        state.account = undefined;
      })
      .addCase(patchContributionStatusAppThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(patchContributionStatusAppThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(patchContributionStatusAppThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        renewAccessTokenThunk.fulfilled,
        (state, action: PayloadAction<RenewAccessTokenResponse>) => {
          const { access_token: newToken } = action.payload;
          state.userClaims = decodeUserClaims(newToken);
          state.loading = false;
        },
      )
      .addCase(renewAccessTokenThunk.rejected, () => redirectToLogin({ authError: true }))
      .addCase(renewAccessTokenThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(
        getIndustriesGroupedThunk.fulfilled,
        (state, action: PayloadAction<Array<IndustryGrouped>>) => {
          state.loadingIndustries = false;
          state.industriesGrouped = action.payload;
        },
      )
      .addCase(getIndustriesGroupedThunk.rejected, (state) => {
        state.loadingIndustries = false;
        state.industriesGrouped = [];
      })
      .addCase(getIndustriesGroupedThunk.pending, (state) => {
        if (!state.loadingIndustries) state.loadingIndustries = true;
      })
      .addCase(
        getInstancesAppThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<Instance>>) => {
          state.loadingInstances = false;
          state.instances = action.payload.results;
        },
      )
      .addCase(getInstancesAppThunk.rejected, (state) => {
        state.loadingInstances = false;
        state.associatedInstance = null;
      })
      .addCase(getInstancesAppThunk.pending, (state) => {
        if (!state.loadingInstances) state.loadingInstances = true;
      })
      .addCase(
        saveAccountSettingsThunk.fulfilled,
        (state, action: PayloadAction<{ account: Account; closeAfter: boolean }>) => {
          if (state.account?.id === action.payload.account.id)
            state.account = action.payload.account;
        },
      )
      .addCase(
        getAccountInstanceLocaleThunk.fulfilled,
        (state, action: PayloadAction<AccountInstanceLocale>) => {
          state.loadingUserData = false;
          state.userData = action.payload;
        },
      )
      .addCase(getAccountInstanceLocaleThunk.rejected, (state) => {
        state.loadingUserData = false;
      })
      .addCase(getAccountInstanceLocaleThunk.pending, (state) => {
        if (!state.loadingUserData) state.loadingUserData = true;
      })
      .addCase(
        getInstanceAssociatedUserThunk.fulfilled,
        (state, action: PayloadAction<Instance>) => {
          state.loadingAssociatedInstance = false;
          state.associatedInstance = action.payload;
        },
      )
      .addCase(getInstanceAssociatedUserThunk.rejected, (state) => {
        state.loadingAssociatedInstance = false;
      })
      .addCase(getInstanceAssociatedUserThunk.pending, (state) => {
        if (!state.loadingAssociatedInstance) state.loadingAssociatedInstance = true;
      });
  },
});

export const { setUserClaims, setLoading, setAppAccountData, setAppShowOptionsLeftNavBar, setCallInstanceThunk } =
  appSlice.actions;

export const appReducer = appSlice.reducer;
