import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { AccountStatus, AccountType, Dialogs, GridColumns, UserRole } from '../../../shared/enums';
import {
  CommonGridFilter,
  CommonGridFilterHandle,
  FlexBox,
  InvertedRoundButton,
  IsolatedGrid,
} from '../../../shared/components';
import { useAppDispatch, useAppSelector, useAuth } from '../../../shared/hooks';
import { getGenericHeight, getGridRequest } from '../../../shared/utils';
import { setDialog } from '../../main-screen/mainScreenSlice';
import {
  getAccountsThunk,
  patchAccountAdminThunk,
  clearAccountsState,
  setAccountsGridModel,
  setAccountsStatus,
  clearAccountsUpdateSuccess,
  postReceivedFundsAdminThunk,
} from './accountsSlice';
import {
  CONTAINS_FILTER_OPERATOR,
  ENABLE_LOCK,
  EQUALS_FILTER_OPERATOR,
} from '../../../shared/constants';
import { setAccountSettingsMode } from '../account-settings';
import { getInstancesThunk } from '../../instances';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';
import borders from '../../../assets/scss/strokes.module.scss';

export const ACCOUNTS_COLUMNS = (
  instances: Array<{ value: string; label: string }>,
): Array<GridColDef> => [
  {
    field: 'sageCustomerId',
    headerName: 'Scalewith Customer #',
    filterOperators: CONTAINS_FILTER_OPERATOR,
    filterable: true,
  },
  {
    field: 'company',
    headerName: 'Name',
    flex: 1,
    filterOperators: CONTAINS_FILTER_OPERATOR,
    filterable: true,
    valueGetter: ({
      row: {
        company: { name },
      },
    }) => name,
  },
  {
    field: 'instance',
    headerName: 'Instance',
    description: 'Instance',
    flex: 1,
    filterable: true,
    filterOperators: EQUALS_FILTER_OPERATOR,
    type: GridColumns.SingleSelect,
    valueOptions: instances,
    valueGetter: ({ row: { instance } }) => instance?.name,
  },
  {
    field: 'industry',
    headerName: 'Industry',
    flex: 1,
    filterOperators: CONTAINS_FILTER_OPERATOR,
    filterable: true,
    valueGetter: ({
      row: {
        industry: { displayLabel },
      },
    }) => displayLabel,
  },
  {
    field: 'type',
    headerName: 'Account Type',
    type: GridColumns.SingleSelect,
    filterOperators: CONTAINS_FILTER_OPERATOR,
    valueOptions: Object.values(AccountType),
    filterable: true,
  },
  {
    field: 'invitedBy',
    headerName: 'Invited By',
    filterable: true,
    valueGetter: ({ row: { invitedBy } }) => invitedBy?.name ?? '',
  },
  {
    field: 'city',
    headerName: 'City',
    filterOperators: CONTAINS_FILTER_OPERATOR,
    filterable: true,
    valueGetter: ({ row: { company } }) => company?.address?.city,
  },
  {
    field: 'state',
    headerName: 'State',
    filterOperators: CONTAINS_FILTER_OPERATOR,
    filterable: true,
    valueGetter: ({ row: { company } }) => company?.address?.stateProvince,
  },
];

function AccountsComponent() {
  const success = useAppSelector((state) => state.accountSettings.success);
  const accountsState = useAppSelector((state) => state.accounts);
  const instanceState = useAppSelector((state) => state.instances);
  const dispatch = useAppDispatch();
  const filterRef = useRef<CommonGridFilterHandle>(null);
  const params = useParams();
  const { accountId } = params;
  const { grid, updateStatusSuccess, status: accountsStatus } = accountsState;
  const { sort, filter, page, pageSize } = grid;

  useEffect(() => {
    dispatch(getAccountsThunk({ status: accountsStatus, ...getGridRequest(grid) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, accountsStatus]);

  useEffect(() => {
    if (success) dispatch(getAccountsThunk({ status: accountsStatus, ...getGridRequest(grid) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    dispatch(getInstancesThunk({ status: 'active', ...getGridRequest(grid) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      dispatch(clearAccountsState());
    },
    [dispatch],
  );

  const openBrandLockWarning = (id: string) => {
    dispatch(setAccountSettingsMode({ editAccountId: id }));
    dispatch(setDialog({ open: true, type: Dialogs.GivewithBrandLockWarning, maxWidth: 'md' }));
  };

  useEffect(() => {
    if (updateStatusSuccess) toast.success('Status updated');
    dispatch(clearAccountsUpdateSuccess());
    dispatch(getAccountsThunk({ status: accountsStatus, ...getGridRequest(grid) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatusSuccess]);

  const addAccount = () => {
    dispatch(setAccountSettingsMode({ isAccountNew: true }));
    dispatch(setDialog({ open: true, type: Dialogs.AccountSettings, maxWidth: 'md' }));
  };

  const editAccount = (id: string) => {
    dispatch(setAccountSettingsMode({ editAccountId: id }));
    dispatch(setDialog({ open: true, type: Dialogs.AccountSettings, maxWidth: 'md' }));
  };

  useEffect(() => {
    if (accountId) editAccount(accountId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, dispatch]);
  const updateStatus = (id: string, status: string) => {
    if (status !== 'mark as paid') {
      dispatch(patchAccountAdminThunk({ accountId: id, status }));
    } else {
      dispatch(postReceivedFundsAdminThunk({ accountId: id }));
    }
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(setAccountsStatus(newValue));
    filterRef.current?.clearFilterSelections();
  };
  const [isUserInRole] = useAuth();
  const getActionsCell = (
    id: string,
    status: string,
    name: string,
    paymentMethod?: string,
    paymentStatus?: string,
  ) => {
    switch (status) {
      case 'pending' || 'inactive':
        return (
          <Button
            variant="outlined"
            color="success"
            // sx={{ backgroundColor: '#fff' }}
            sx={{
              backgroundColor: colors.secondary,
              fontSize: fonts.fontSize14,
              fontWeight: fonts.fontWeight700,
              color: colors.statusSuccess,
              borderRadius: borders.borderRadius8,
              borderColor: colors.statusSuccess,
              '&:focus': colors.statusSuccess,
              '&:hover': colors.statusSuccess,
            }}
            onClick={() => {
              updateStatus(id, 'active');
            }}
          >
            Activate
          </Button>
        );
      case 'active':
        return (
          <>
            <Button
              variant="outlined"
              color="error"
              // sx={{ backgroundColor: '#fff' }}
              onClick={() => {
                if (name !== 'Scalewith') {
                  updateStatus(id, 'locked');
                } else {
                  openBrandLockWarning(id);
                }
              }}
              disabled={!isUserInRole(ENABLE_LOCK)}
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.statusAlert,
                borderRadius: borders.borderRadius8,
                borderColor: colors.statusAlert,
                '&:focus': colors.statusAlert,
                '&:hover': colors.statusAlert,
              }}
            >
              Lock
            </Button>
            {paymentMethod === 'invoice' && paymentStatus === 'pending payment' && (
              <Button
                variant="outlined"
                color="error"
                // sx={{ backgroundColor: '#fff' }}
                onClick={() => {
                  if (name !== 'Scalewith') {
                    updateStatus(id, 'mark as paid');
                  } else {
                    openBrandLockWarning(id);
                  }
                }}
                sx={{
                  backgroundColor: colors.secondary,
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fontWeight700,
                  color: colors.statusAlert,
                  borderRadius: borders.borderRadius8,
                  borderColor: colors.statusAlert,
                  '&:focus': colors.statusAlert,
                  '&:hover': colors.statusAlert,
                }}
              >
                Mark as Paid
              </Button>
            )}
          </>
        );
      case 'locked':
        return (
          <Button
            variant="outlined"
            color="warning"
            // sx={{ backgroundColor: '#fff' }}
            onClick={() => {
              updateStatus(id, 'active');
            }}
            disabled={!isUserInRole(ENABLE_LOCK)}
            sx={{
              backgroundColor: colors.secondary,
              fontSize: fonts.fontSize14,
              fontWeight: fonts.fontWeight700,
              color: colors.statusWarning,
              borderRadius: borders.borderRadius8,
              borderColor: colors.statusWarning,
              '&:focus': colors.statusWarning,
              '&:hover': colors.statusWarning,
            }}
          >
            Unlock
          </Button>
        );
      default:
        return null;
    }
  };

  const columns: Array<GridColDef> = [
    ...ACCOUNTS_COLUMNS(
      instanceState.grid.data.results.map((instance) => ({
        value: instance.id || '',
        label: instance.name || '',
      })),
    ),
    {
      field: 'status',
      headerName: 'Status',
      type: GridColumns.SingleSelect,
      valueOptions: Object.values(AccountStatus),
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: accountsStatus === null,
      valueGetter: ({ row: { status } }) => status,
    },

    {
      field: 'action',
      resizable: false,
      disableReorder: true,
      headerName: 'Actions',
      type: GridColumns.Actions,
      flex: 1,
      minWidth: 200,
      renderCell: ({
        row: {
          id,
          status,
          company: { name },
          subscriptionStatus,
        },
      }) => {
        const paymentMethod = subscriptionStatus?.paymentMethod!;
        const paymentStatus = subscriptionStatus?.status!;
        return (
          <FlexBox sx={{ gap: 1 }}>
            <Button
              variant="outlined"
              color="info"
              // sx={{ backgroundColor: '#fff' }}
              onClick={() => {
                editAccount(id.toString());
              }}
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.primary,
                borderRadius: borders.borderRadius8,
                borderColor: colors.primary,
                '&:focus': colors.primary,
                '&:hover': colors.primary,
              }}
            >
              Edit
            </Button>
            {getActionsCell(
              id.toString(),
              status.toString(),
              name.toString(),
              paymentMethod !== undefined ? paymentMethod.toString() : '',
              paymentStatus !== undefined ? paymentStatus.toString() : '',
            )}
          </FlexBox>
        );
      },
      sortable: false,
      filterable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: colors.primary,
            height: '4px',
          },
        },
      },
    },
  });

  return (
    <Box className="dashboard" sx={{ pb: '30px' }}>
      <FlexBox sx={{ gap: 1 }}>
        <Typography
          sx={{ flex: 1, color: colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 700 }}
        >
          Accounts
        </Typography>
        <InvertedRoundButton
          sx={{
            height: '48px',
            marginLeft: '30px',
            paddingX: '16px',
            fontWeight: fonts.fontWeight700,
            fontSize: fonts.fontSize14,
            backgroundColor: `${colors.primary} !important`,
            color: `${colors.secondary} !important`,
            borderRadius: '8px !important',
            '&.Mui-disabled': {
              backgroundColor: `${colors.strokeDefault} !important`,
            },
          }}
          onClick={() => addAccount()}
          disabled={!isUserInRole([UserRole.SuperAdmin, UserRole.GivewithOperations])}
        >
          Add Account
        </InvertedRoundButton>
      </FlexBox>
      <Grid container alignItems="end">
        <Grid item md={6} sm={12}>
          <Box my={1}>
            <ThemeProvider theme={theme}>
              <Tabs value={accountsStatus} onChange={handleTabChange}>
                <Tab
                  value={null}
                  label="All"
                  sx={{
                    textTransform: 'capitalize',
                    color:
                      accountsStatus === null ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: accountsStatus === null ? fonts.fontWeight700: fonts.fontWeight600,
                  }}
                />
                <Tab
                  value={AccountStatus.New}
                  label="New"
                  sx={{
                    textTransform: 'capitalize',
                    color:
                      accountsStatus === AccountStatus.New
                        ? `${colors.primary} !important`
                        : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: accountsStatus === AccountStatus.New ? fonts.fontWeight700: fonts.fontWeight600,
                  }}
                />
                <Tab
                  value={AccountStatus.Pending}
                  label="Pending"
                  sx={{
                    textTransform: 'capitalize',
                    color:
                      accountsStatus === AccountStatus.Pending
                        ? `${colors.primary} !important`
                        : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: accountsStatus === AccountStatus.Pending ? fonts.fontWeight700: fonts.fontWeight600,
                  }}
                />
                <Tab
                  value={AccountStatus.Active}
                  label="Active"
                  sx={{
                    textTransform: 'capitalize',
                    color:
                      accountsStatus === AccountStatus.Active
                        ? `${colors.primary} !important`
                        : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: accountsStatus === AccountStatus.Active ? fonts.fontWeight700: fonts.fontWeight600,
                  }}
                />
                <Tab
                  value={AccountStatus.Locked}
                  label="Locked"
                  sx={{
                    textTransform: 'capitalize',
                    color:
                      accountsStatus === AccountStatus.Locked
                        ? `${colors.primary} !important`
                        : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: accountsStatus === AccountStatus.Locked ? fonts.fontWeight700: fonts.fontWeight600,
                  }}
                />
              </Tabs>
            </ThemeProvider>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box my={1} display="flex" justifyContent="flex-end">
            <CommonGridFilter
              columns={columns}
              currentFilter={filter}
              onChange={(x) => {
                dispatch(setAccountsGridModel({ filter: x }));
              }}
              ref={filterRef}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '400px', height: getGenericHeight(300), marginTop: 1 }}>
        <IsolatedGrid
          status={accountsStatus}
          alternativeName="account"
          model={grid}
          columns={columns}
          onChange={(x) => {
            dispatch(setAccountsGridModel(x));
          }}
        />
      </Box>
    </Box>
  );
}

export const Accounts = React.memo(AccountsComponent);
