import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { FormDialog } from '../../shared/components';
import { Dialogs } from '../../shared/enums';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import { clearResetUserPasswordState, postResetUserPassword } from './resetUserPasswordSlice';

export const ResetUserPassword = () => {
  const { loading, success } = useAppSelector((x) => x.resetUserPassword);
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(clearResetUserPasswordState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (success) toast.success('Password Reset Email Sent');
  }, [success]);

  return (
    <FormDialog
      title="Are you sure you want to send this user a forgot password email?"
      type={Dialogs.ResetUserPassword}
      loading={loading}
      closeFlag={success}
      Buttons={
        <Button variant="contained" size="large" onClick={() => dispatch(postResetUserPassword())}>
          Submit
        </Button>
      }
    />
  );
};
