/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { clearDialog } from '../../features/main-screen/mainScreenSlice';
import { AccountSettingsTabs, Dialogs, UserRole } from '../enums';
import { useAppDispatch, useAuth } from '../hooks';
import { openAccountSettingsOnTab } from '../utils';
import { FormDialog } from './FormDialog';

export function FinancialHoldDialog() {
  const dispatch = useAppDispatch();
  const [isUserInRole] = useAuth();

  const financeHoldMessage = isUserInRole([UserRole.Finance]) ? (
    <>
      <Typography mt={2}>
        Inventive could not be completed because autopay is not enabled.
      </Typography>
      <Box mt={3}>
        <Link
          onClick={() => {
            dispatch(clearDialog(Dialogs.SuccessContributionDialog));
            openAccountSettingsOnTab(AccountSettingsTabs.Payment);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Enable autopay here
        </Link>
      </Box>
    </>
  ) : (
    <>
      <Typography mt={2}>
        A finance member on your team will be notified to complete the funding for this transaction.
      </Typography>
      <Box mt={3}>
        <Link
          onClick={() => {
            dispatch(clearDialog(Dialogs.SuccessContributionDialog));
            openAccountSettingsOnTab(AccountSettingsTabs.Payment);
          }}
          sx={{ cursor: 'pointer' }}
        >
          Lean about autopay
        </Link>
      </Box>
    </>
  );

  return (
    <FormDialog title="Thank you!" type={Dialogs.FinancialHold} cancelButtonText="Close">
      {financeHoldMessage}
    </FormDialog>
  );
}
