import React, { ChangeEvent, useEffect, useState } from 'react';
// import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Checkbox,
  Box,
  Tooltip,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  useMediaQuery,
} from '@mui/material';
import '../sio/formFilling.scss';
import './programFundingForm.scss';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { fetchFundingbyUser } from './fundingSlice';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { getVocab } from '../../api/portal/programRequest';
import { Vocabulary } from './fundingInterface';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

const outputForm = [
  { quantity: 0, description: '' },
  { quantity: 0, description: '' },
  { quantity: 0, description: '' },
];

type ChildComponentProps = {
  onDataSubmit: (data: any) => void;
};

const theme = createTheme({
  palette: {
    primary: {
      light: colors.primary,
      main: colors.primary,
    },
  },
});

const StrategiesAndApproaches: React.FC<ChildComponentProps> = ({ onDataSubmit }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const fundingState = useAppSelector((state) => state.funding);
  const { funding } = fundingState;
  const initialFormValue = {
    strategies: funding?.strategiesAndApproaches?.strategies ?? [],
    activities: {
      value: funding?.strategiesAndApproaches?.activities?.value,
    },
    approachDuration: funding?.strategiesAndApproaches?.approachDuration,
    activitiesFrequency: funding?.strategiesAndApproaches?.activitiesFrequency,
    outputs: outputForm,
    outcomes: outputForm,
    dataMeasurementType: funding?.strategiesAndApproaches?.dataMeasurementType,
    nonprofitPartners: funding?.strategiesAndApproaches?.nonprofitPartners,
    nonprofitPartnersDescription: funding?.strategiesAndApproaches?.nonprofitPartnersDescription,
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [vocab, setVocab] = useState<Vocabulary>();
  const is1024 = useMediaQuery('(max-width:1024px)');

  useEffect(() => {
    getVocab().then((res) => {
      if (res.status === 200) {
        setVocab(res.data);
        console.log(res.data);
      }
    });
    if (id && funding) {
      dispatch(fetchFundingbyUser(id)).then((fundingData: any) => {
        setFormValues({
          strategies: fundingData.payload?.strategiesAndApproaches?.strategies ?? [],
          activities: {
            value: fundingData.payload?.strategiesAndApproaches?.activities.value,
          },
          approachDuration: fundingData.payload?.strategiesAndApproaches?.approachDuration,
          activitiesFrequency: fundingData.payload?.strategiesAndApproaches?.activitiesFrequency,
          outputs: fundingData.payload?.strategiesAndApproaches?.outputs ?? outputForm,
          outcomes: fundingData.payload?.strategiesAndApproaches?.outcomes ?? outputForm,
          dataMeasurementType: fundingData.payload?.strategiesAndApproaches?.dataMeasurementType,
          nonprofitPartners: fundingData.payload?.strategiesAndApproaches?.nonprofitPartners,
          nonprofitPartnersDescription:
            fundingData?.payload?.strategiesAndApproaches?.nonprofitPartnersDescription,
        });
        // savePageNumber()
      });
    }
  }, [id]);

  const [isNonProfitDesc, setIsNonprofirDesc] = useState<boolean>();

  useEffect(() => {
    setIsNonprofirDesc(funding?.strategiesAndApproaches?.nonprofitPartners);
  }, [funding?.strategiesAndApproaches?.nonprofitPartners]);

  // const updateProgramDetails = () => {
  //     const payload = {
  //         strategiesAndApproaches: {
  // strategies: formValues.strategies,
  // activities: formValues.activities,
  // activitiesFrequency: formValues.activitiesFrequency,
  // approachDuration: formValues.approachDuration,
  // outputs: formValues.outputs,
  // outcomes: formValues.outcomes,
  // dataMeasurementType: formValues.dataMeasurementType,
  // nonprofitPartners: formValues.nonprofitPartners,
  //         },
  //     }
  //     const fundingPayload = payload
  //     if (id) {
  //         return dispatch(updateFundingbyUserThunk({ funding: fundingPayload, id }));
  //     }
  //     return true
  // }

  // const handleSaveExit = async () => {
  //     const response = await updateProgramDetails()
  //     if (response) {
  //         // toast.success('Successfully Submitted Form to ScaleWith');
  //         navigate(`/program-form/${id}`)
  //     } else {
  //         toast.error('An error occurred while logging in. Please try again.');
  //     }
  //     return null
  // }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'nonprofitPartners') {
      if (value === 'yes') {
        setFormValues({ ...formValues, [name]: true });
        setIsNonprofirDesc(true);
      } else {
        setFormValues({ ...formValues, [name]: false, nonprofitPartnersDescription: '' });
        setIsNonprofirDesc(false);
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let newvalue = formValues.strategies;
    if (newvalue.includes(value)) {
      newvalue = newvalue.filter((item) => value !== item);
    } else {
      newvalue = [...newvalue, value];
    }
    setFormValues({
      ...formValues,
      strategies: newvalue,
    });
  };

  const handleActivityChange = (event: any) => {
    setFormValues({
      ...formValues,
      activities: {
        value: event.target.value,
      },
    });
  };
  const handleOutputChange =
    (field: string, index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const newLifetimeOutputs = [...formValues.outputs];
      newLifetimeOutputs[index] = { ...newLifetimeOutputs[index], [field]: value };
      setFormValues((prevValues) => ({
        ...prevValues,
        outputs: newLifetimeOutputs,
      }));
    };

  const handleOutcomeChange =
    (field: string, index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const newLifetimeOutputs = [...formValues.outcomes];
      newLifetimeOutputs[index] = { ...newLifetimeOutputs[index], [field]: value };
      if ((field === 'quantity' && Number(value) <= 100) || field === 'description') {
        setFormValues((prevValues) => ({
          ...prevValues,
          outcomes: newLifetimeOutputs,
        }));
      } else {
        toast.warning('Outcomes should not exceed 100%');
      }
    };

  useEffect(() => {
    onDataSubmit(formValues);
  }, [formValues]);

  return (
    <div>
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{ display: 'flex', gap: '2rem', marginTop: '2rem' }}
        className="formContainer"
      >
        <Grid container>
          <Grid
            item
            xs={14}
            sx={{
              background: colors.backgroundPrimary,
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
              marginLeft: '-10px',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontSize: fonts.fontSize24,
                fontWeight: fonts.fontWeight700,
                color: colors.textPrimary,
              }}
            >
              Strategies And Approaches
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >{`${funding?.progress?.strategiesAndApproaches?.complete} of ${funding?.progress?.strategiesAndApproaches?.total} Completed`}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 1 of 8
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              How would you classify the strategies this program employs?
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Please select all that apply.
            </Typography>
          </Grid>

          {
            /* eslint no-underscore-dangle: 0 */
            vocab?.programApproach.map((item: any) => (
              <Grid key={item._id} item>
                <Box
                  className={formValues.strategies.includes(item._id) ? 'active' : ''}
                  sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                >
                  <Typography
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight400,
                      color: colors.textSecondary,
                    }}
                    display="inline"
                  >
                    {item.label}
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Checkbox
                      checked={formValues.strategies.includes(item._id)}
                      value={item._id}
                      onChange={handleChangeSelect}
                    />
                  </ThemeProvider>
                </Box>
              </Grid>
            ))
          }
        </Grid>
        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 2 of 8
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Describe the specific activities you will undertake to implement your program.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                mb: 1,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Description
              <Tooltip title="Enter activities in your program">
                <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
              </Tooltip>
            </Typography>
            <TextField
              name="activities"
              placeholder="Enter activities in your program"
              fullWidth
              value={formValues?.activities?.value}
              onChange={(event) => handleActivityChange(event)}
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
              sx={{
                marginTop: '5px',
                borderRadius: '5px',
                '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fntWeight400,
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 3 of 8{' '}
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Is the program intervention one-time or ongoing?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <ThemeProvider theme={theme}>
                <RadioGroup
                  sx={{
                    display: 'inline-block',
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight400,
                    color: colors.textSecondary,
                  }}
                  name="approachDuration"
                  value={formValues.approachDuration}
                  onChange={handleChange}
                >
                  {vocab?.approachDuration.map((item: any) => (
                    <FormControlLabel
                      sx={{ p: 2, mx: 2, border: '1px solid #E0E0E0', borderRadius: '20px' }}
                      labelPlacement="start"
                      key={item._id}
                      value={item._id}
                      control={<Radio />}
                      label={item.label}
                    />
                  ))}
                </RadioGroup>
              </ThemeProvider>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 4 of 8
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Describe the frequency and length of the program activities.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                mb: 1,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Description
              <Tooltip title="Enter length of program">
                <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
              </Tooltip>
            </Typography>
            <TextField
              name="activitiesFrequency"
              placeholder="Enter length of program"
              fullWidth
              value={formValues.activitiesFrequency}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
              sx={{
                marginTop: '5px',
                borderRadius: '5px',
                '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fntWeight400,
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 5 of 8
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              What outputs does this funding create?
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              These outputs should be based on the entire funding amount provided by this
              opportunity
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginLeft: '10px',
            }}
          >
            <Grid item xs={12} sm={8}>
              {formValues?.outputs?.map((lifetimeOutput: any, index) => (
                <Grid container spacing={4} key={lifetimeOutput.key}>
                  <Grid item xs={12} sm={3} mb={3} sx={{ marginRight: '40px' }}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize16,
                        fontWeight: fonts.fontWeight500,
                        color: colors.textSecondary,
                      }}
                    >
                      Output Unit {index + 1}
                    </Typography>
                    <TextField
                      name="quantity"
                      type="number"
                      value={lifetimeOutput.quantity}
                      onChange={handleOutputChange('quantity', index)}
                      variant="outlined"
                      className="inputTextFiled"
                      fullWidth
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '0.8rem',
                          },
                        },
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          width: '90px',
                          marginRight: '90px',
                          height: '40px',
                        },
                        borderRadius: '5px',
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                          fontSize: fonts.fontSize14,
                          fontWeight: fonts.fntWeight400,
                        },
                        marginTop: '5px',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} mb={3}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize16,
                        fontWeight: fonts.fontWeight500,
                        color: colors.textSecondary,
                      }}
                    >
                      Output Description {index + 1}{' '}
                      <Tooltip title="Enter program outputs">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="description"
                      type="text"
                      value={lifetimeOutput.description}
                      onChange={handleOutputChange('description', index)}
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={{
                        marginTop: '5px',
                        '& .MuiInputBase-root': {
                          height: '40px',
                        },
                        borderRadius: '5px',
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                          fontSize: fonts.fontSize14,
                          fontWeight: fonts.fntWeight400,
                        },
                      }}
                      variant="outlined"
                      className="inputTextFiled"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              xs={12}
              sm={4}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    mb: 3,
                    padding: '15px 20px',
                    width: `${is1024 ? '165px' : '300px'}`,
                    background: '#E7F2FF',
                    borderRadius: '20px',
                  }}
                >
                  <Typography
                    sx={{
                      mb: 1,
                      fontSize: fonts.fontSize18,
                      fontWeight: fonts.fontWeight600,
                      color: colors.textPrimary,
                    }}
                  >
                    <InfoIcon sx={{ color: colors.primary, fontSize: '16px' }} /> Outputs (example)
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight400,
                      color: colors.textSecondary,
                    }}
                  >
                    Output: 1500
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight400,
                      color: colors.textSecondary,
                    }}
                  >
                    Output Description: Clothes Donated
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 6 of 8
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              What outcomes does this funding create?
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              These outcomes should be based on the entire funding amount provided by this
              opportunity
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginLeft: '10px',
            }}
          >
            <Grid item xs={12} sm={8}>
              {formValues?.outcomes?.map((lifetimeOutput: any, index) => (
                <Grid container spacing={4} key={lifetimeOutput.key}>
                  <Grid item xs={12} sm={3} mb={3} sx={{ marginRight: '50px' }}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize16,
                        fontWeight: fonts.fontWeight500,
                        color: colors.textSecondary,
                      }}
                    >
                      Outcome {index + 1} %
                    </Typography>
                    <TextField
                      name="quantity"
                      type="number"
                      value={lifetimeOutput.quantity}
                      onChange={handleOutcomeChange('quantity', index)}
                      variant="outlined"
                      className="inputTextFiled"
                      fullWidth
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '40px',
                          width: '90px',
                          marginRight: '60px',
                        },
                        marginTop: '5px',
                        borderRadius: '5px',
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                          fontSize: fonts.fontSize14,
                          fontWeight: fonts.fntWeight400,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} mb={3}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize16,
                        fontWeight: fonts.fontWeight500,
                        color: colors.textSecondary,
                      }}
                    >
                      Outcome Description {index + 1}{' '}
                      <Tooltip title="Enter program outputs">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="description"
                      type="text"
                      value={lifetimeOutput.description}
                      onChange={handleOutcomeChange('description', index)}
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '40px',
                        },
                        borderRadius: '5px',
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                          fontSize: fonts.fontSize14,
                          fontWeight: fonts.fntWeight400,
                        },
                        marginTop: '5px',
                      }}
                      variant="outlined"
                      className="inputTextFiled"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid
              xs={12}
              sm={4}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Grid item xs={12}>
                <Box
                  sx={{
                    mb: 3,
                    padding: '15px 20px',
                    width: `${is1024 ? '165px' : '300px'}`,
                    background: '#E7F2FF',
                    borderRadius: '20px',
                  }}
                >
                  <Typography
                    sx={{
                      mb: 1,
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight500,
                      color: colors.textSecondary,
                    }}
                  >
                    <InfoIcon sx={{ color: colors.primary, fontSize: '16px' }} /> Outcomes (example)
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight400,
                      color: colors.textSecondary,
                    }}
                  >
                    Outcome: 45%
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight400,
                      color: colors.textSecondary,
                    }}
                  >
                    Outcome Description: Increase in children receiving accessing nutritious meals
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 7 of 8
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Are your results measured qualitatively or quantitatively?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl>
              <ThemeProvider theme={theme}>
                <RadioGroup
                  sx={{ display: 'inline-block' }}
                  name="dataMeasurementType"
                  value={formValues.dataMeasurementType}
                  onChange={handleChange}
                >
                  {vocab?.dataMeasurement.map((item: any) => (
                    <FormControlLabel
                      sx={{ p: 2, mx: 2, border: '1px solid #E0E0E0', borderRadius: '20px' }}
                      labelPlacement="start"
                      key={item._id}
                      value={item._id}
                      control={<Radio />}
                      label={item.label}
                    />
                  ))}
                </RadioGroup>
              </ThemeProvider>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1} className="NonProfitDetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 8 of 8
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Are there any SIO Partners supporting this Program?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl>
              <ThemeProvider theme={theme}>
                <RadioGroup
                  sx={{
                    display: 'inline-block',
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight400,
                    color: colors.textSecondary,
                  }}
                  name="nonprofitPartners"
                  value={formValues.nonprofitPartners ? 'yes' : 'no'}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    sx={{ p: 2, mx: 2, border: '1px solid #E0E0E0', borderRadius: '20px' }}
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    sx={{ p: 2, mx: 2, border: '1px solid #E0E0E0', borderRadius: '20px' }}
                    value="no"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </ThemeProvider>
            </FormControl>
          </Grid>

          {isNonProfitDesc && (
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontSize: fonts.fontSize16,
                  fontWeight: fonts.fontWeight600,
                  color: colors.textPrimary,
                  mb: 0.5 
                }}
              >
                SIO partners{' '}
                <Tooltip title="Please mention all SIO partners supporting this program">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                name="nonprofitPartnersDescription"
                placeholder="Please mention all SIO partners supporting this program."
                fullWidth
                value={formValues?.nonprofitPartnersDescription?.trim()}
                onChange={handleChange}
                error={
                  formValues?.nonprofitPartnersDescription?.trim() === '' &&
                  formValues?.nonprofitPartners
                }
                margin="normal"
                variant="outlined"
                multiline
                sx={{
                  marginTop: '0px',
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight400,
                    borderRadius: '5px',
                  },
                  '& .MuiSelect-select': {
                    height: '80%',
                  },
                }}
                rows={2}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default StrategiesAndApproaches;
