import { GridRequest, GridResponse } from '../../shared/interfaces';
import { postProgramSchema } from '../../shared/interfaces/completion-form/completionProgram';
import {
  ProgramList,
  PostProgramRequest,
  Program,
  UpdateProgramUserRequest,
} from '../../shared/interfaces/program/program';
import { httpSecuredCommon, cancelToken, httpCommon } from '../httpCommon';

const base = '/programs';

export const PROGRAM_API_ROUTES = {
  getPrograms: base,
  postProgram: base,
  getProgramStatus: `${base}/status-count`,
  getProgramDetails: (id: string) => `${base}/details/${id}`,
  getProgrambyUser: (id: string) => `${base}/user/${id}`,
  patchProgram: (id: string) => `${base}/${id}`,
  uploadFile: (id: string) => `${base}/file/${id}`,
  uploadCompletionFile: (id: string) => `/completion-forms/file/${id}`,
  getProgramFormDetail: (id: string) => `/programs/user/${id}`,
  updateProgramFormDetail: (id: string) => `/programs/user/${id}`,
  sendEmail: (id: string) => `${base}/sendform/${id}`,
  getVocabulary: `${base}/vocabulary`,
  getCompletionFormDetail: (id: string) => `/completion-forms/${id}`,
  postCompletionForm: (id: string) => `/completion-forms/users/${id}`,
  getLocations: `${base}/locations`,
  getAllSIOPrograms: (searchText: string, isStandard?: boolean) =>
    `/programs/get_all_sio_programs/?searchText=${searchText}&&isStandard=${isStandard}`,
  programAction: (id: string, action: string) => `${base}/${id}/${action}`,
  getIndustries: '/cause-areas/industries',
  getFilterListURL:`/scalewith_constants/sio.program.filters`,
  getFilter:`${base}/get_filter_options`,
  getCompanies: (count: number, offset:number, orderBy:string, name:string, industry:string) => `/company?count=${count}&offset=${offset}&orderBy=${orderBy}&descending=false&industry=${encodeURIComponent(industry)}`,
  downloadFile: (id: string) => `${base}/file/${id}`,
};

export const downloadFile = async (request: any) => {
  const { id, url, name } = request;
  const response = await httpSecuredCommon.get(PROGRAM_API_ROUTES.downloadFile(id), {
    cancelToken: cancelToken.token,
    params: {
      url,
    },
    responseType: 'blob',
  });

  const responseURL = window.URL.createObjectURL(new Blob([response.data]));
  const a = document.createElement('a');
  a.href = responseURL;
  a.download = name;
  window.URL.revokeObjectURL(responseURL);
  return response;
};

export const getProgramList = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<ProgramList>>(
    PROGRAM_API_ROUTES.getPrograms,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const getProgramStatus = async () => {
  const response = await httpSecuredCommon.get(PROGRAM_API_ROUTES.getProgramStatus, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getFilterList = async () => {
  const response = await httpSecuredCommon.get(PROGRAM_API_ROUTES.getFilterListURL);
  return response;
};

export const getFilteredCauseAreas = async(request: GridRequest ) => {
  const response = await httpSecuredCommon.get<GridResponse<ProgramList>>(
    
    PROGRAM_API_ROUTES.getFilter,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
}

export const getProgrambyUser = async (id: string) => {
  const response = await httpSecuredCommon.get(PROGRAM_API_ROUTES.getProgrambyUser(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const deleteProgram = async (id: string) => {
  const response = await httpSecuredCommon.delete(PROGRAM_API_ROUTES.patchProgram(id));
  return response;
};

export const getAllIndustries = async() => {
  const response = await httpCommon.get(PROGRAM_API_ROUTES.getIndustries)
  return response;
}

export const getAllCompanies = async({count, offset, orderBy, name, industry} : any ) => {
  const response = await httpSecuredCommon.get(PROGRAM_API_ROUTES.getCompanies(count,offset,orderBy,name,industry))
  return response;
}

export const updateProgrambyAdmin = async (program: any, id: string) => {
  const response = await httpSecuredCommon.post(PROGRAM_API_ROUTES.patchProgram(id), program, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const patchProgrambyAdmin = async (program: any, id: string) => {
  const response = await httpSecuredCommon.patch(PROGRAM_API_ROUTES.patchProgram(id), program, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const uploadFile = async (id: string, data: any) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  const response = await httpSecuredCommon.post(PROGRAM_API_ROUTES.uploadFile(id), data, config);
  return response;
};

export const uploadCompletionFile = async (id: string, data: any) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  const response = await httpSecuredCommon.post(
    PROGRAM_API_ROUTES.uploadCompletionFile(id),
    data,
    config,
  );
  return response;
};

export const updateProgrambyUser = async (program: any, id: string) => {
  const response = await httpSecuredCommon.patch(PROGRAM_API_ROUTES.getProgrambyUser(id), program, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postProgrambyUser = async (program: any, id: string) => {
  const response = await httpSecuredCommon.post(PROGRAM_API_ROUTES.getProgrambyUser(id), program, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getProgram = async (id: string) => {
  const response = await httpSecuredCommon.get(PROGRAM_API_ROUTES.getProgramDetails(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postProgram = async (program: PostProgramRequest) => {
  const response = await httpSecuredCommon.post<Program>(PROGRAM_API_ROUTES.postProgram, program, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const sendEmail = async (id: string, data: object) => {
  const response = await httpSecuredCommon.post(PROGRAM_API_ROUTES.sendEmail(id), data);
  return response;
};

export const getVocab = async () => {
  const response = await httpSecuredCommon.get(PROGRAM_API_ROUTES.getVocabulary);
  return response;
};

export const getCompletionFormData = async (id: string) => {
  const response = await httpSecuredCommon.get(PROGRAM_API_ROUTES.getCompletionFormDetail(id));
  return response;
};

export const programAction = async (id: string, action: string) => {
  const response = await httpSecuredCommon.post(PROGRAM_API_ROUTES.programAction(id, action));
  return response;
};

export const getLocations = async () => {
  const response = await httpSecuredCommon.get(PROGRAM_API_ROUTES.getLocations);
  return response;
};

export const getProgramUserDetails = async (id: string) => {
  const response = await httpSecuredCommon.get<Program>(
    PROGRAM_API_ROUTES.getProgramFormDetail(id),
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const updateProgramUserDetails = async (sio: UpdateProgramUserRequest, id: string) => {
  const response = await httpSecuredCommon.patch<Program>(
    PROGRAM_API_ROUTES.updateProgramFormDetail(id),
    sio,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getAllProgramList = async (searchText: string, isStandard?: boolean) => {
  const response = await httpSecuredCommon.get(
    PROGRAM_API_ROUTES.getAllSIOPrograms(searchText, isStandard),
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const postCompletionForm = async (program: object, id: string) => {
  const response = await httpSecuredCommon.patch<postProgramSchema>(
    PROGRAM_API_ROUTES.postCompletionForm(id),
    program,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const submitCompletion = async (program: object, id: string) => {
  const response = await httpSecuredCommon.post<postProgramSchema>(
    PROGRAM_API_ROUTES.postCompletionForm(id),
    program,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};
