import React, { useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  Checkbox,
  CircularProgress,
} from '@mui/material';

interface LoginFormProps {
  onLogin?: (password: string) => void;
  errorMessage?: string;
  loading?: boolean;
}
export const ShareLoginForm = ({ onLogin, errorMessage, loading }: LoginFormProps) => {
  const [password, setPassword] = useState('');
  const [termsAgreed, setTermsAgreed] = useState(false);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleToggle = () => {
    setTermsAgreed(!termsAgreed);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (termsAgreed && onLogin) {
      onLogin(password);
    }
  };

  const termContainerClassName = `LoginForm-terms ${termsAgreed ? 'LoginForm-terms-agreed' : ''}`;

  return (
    <Container maxWidth="xs">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              Log In
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoFocus
              onChange={handlePasswordChange}
              value={password}
            />
          </Grid>
          <Grid item xs={12}>
            {errorMessage ? <Typography color="error">{errorMessage}</Typography> : null}
            {loading && <CircularProgress />}
          </Grid>

          {!termsAgreed && (
            <Grid item xs={12} className={termContainerClassName}>
              <Button onClick={handleToggle}>
                <Checkbox checked={termsAgreed} />I agree to the{' '}
                <Link href="https://www.scalewith.com/terms-and-conditions/" target="_blank">
                  Terms & Conditions
                </Link>
                {'  '}
                &amp;
                {'  '}
                <Link href="https://scalewith.com/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!password || !termsAgreed}
            >
              Log In
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
