import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import '../assets/scss/global.scss';
import {
  MainScreen,
  Register,
  ThankYou,
  Logout,
  MainDialog,
  RegisterDetails,
  RegisterDetailsForm,
  Unauthorized,
} from '../features';
import { GWCookies } from '../shared/utils';
import { ProgramPage } from '../features/programs/ProgramPreviewPage';
import { PasswordProtectedPage } from '../features/SharePages/PasswordProtect';
import { AccountStatus, ThankYouMessage } from '../shared/enums';
import { useAppSelector } from '../shared/hooks';
import { AppListener } from './AppListener';
import { NoMatch } from './NoMatch';
import { AccountStatusRequired, AuthRequired, LoggedRequired } from './Security';
import SioLogin from '../features/sio/SioLogin';
import SioReviewAndSubmit from '../features/sio/SioReviewAndSubmit';
import SioFormFillingDashboard from '../features/sio/SioFormFillingDashboard';
import GeneralQuestions from '../features/sio/GeneralQuestions';
import OperationalQuestions from '../features/sio/OperationalQuestions';
import OverviewQuestions from '../features/sio/OverviewQuestions';
import MpaDashBoard from '../features/sio/mpa/MpaDashBoard';
import MpaQuestions from '../features/sio/mpa/MpaQuestions';
import MpaReviewSubmit from '../features/sio/mpa/MpaReviewSubmit';
import FundingFormLandingPage from '../features/program-fundig-form/FundingFormLandingPage';
import FundingFormFillingPage from '../features/program-fundig-form/FundingFormFillingPage';
import FundingSuccess from '../features/program-fundig-form/FundingSuccess';
import ProgramDashboard from '../features/program/ProgramDashboard';
import ProgramGeneralQuestions from '../features/program/ProgramGeneralQuestions';
import ProgramStrategiesQuestions from '../features/program/ProgramStrategiesQuestions';
import ExecutedProgramDashboard from '../features/program/ExecutedProgram';
import ProgramContentQuestions from '../features/program/ProgramContentQuestions';
import ProgramFinanceQuestions from '../features/program/ProgramFinanceQuestion';
import ProgramResearchQuestions from '../features/program/ProgramResearchQuestions';
import ProgramImpactQuestions from '../features/program/ProgramImpactQuestions';
import ProgramFormSuccess from '../features/program/ProgramFormSuccess';
import GreetingPage from '../features/suppliers/GreetingPage';

export function App() {
  const appState = useAppSelector((x) => x.app);
  const { userClaims } = appState;

  const setCookie = (name: string, value: string) => {
    const cookieDomain = window.LOCALHOST ? `${window.ENV_TYPE}.localhost` : `.${window.DOMAIN}`;
    const secureFlag = 'Secure';
    const cookieString = `${name}=${value}; domain=${cookieDomain}; path=/; SameSite=None; ${secureFlag}`;
    document.cookie = cookieString;
  };

  useEffect(() => {
    if (userClaims) {
      if (window.location.origin.includes('dev') && GWCookies.get('dev_tenantId') === undefined) {
        setCookie('dev_tenantId', userClaims?.accountId!);
      } else if (
        window.location.origin.includes('qa') &&
        GWCookies.get('qa_tenantId') === undefined
      ) {
        setCookie('qa_tenantId', userClaims?.accountId!);
      } else if (GWCookies.get('tenantId') === undefined) {
        setCookie('tenantId', userClaims?.accountId!);
      }
    }
  }, [userClaims]);

  return (
    <div className="App">
      <Routes>
        <Route path="/buyer/:id/greeting" element={<GreetingPage />} />
        <Route path="/program-form/:id/" element={<ProgramDashboard />} />
        <Route path="/program-form/:id/general-information" element={<ProgramGeneralQuestions />} />
        <Route path="/program-form/:id/content-information" element={<ProgramContentQuestions />} />
        <Route path="/program-form/:id/success" element={<ProgramFormSuccess />} />
        <Route path="/program-form/:id/budget-information" element={<ProgramFinanceQuestions />} />
        <Route
          path="/program-form/:id/research-evaluation-information"
          element={<ProgramResearchQuestions />}
        />
        <Route
          path="/program-form/:id/impact-scope-information"
          element={<ProgramImpactQuestions />}
        />
        <Route
          path="/program-form/:id/strategies-approaches-information"
          element={<ProgramStrategiesQuestions />}
        />
        <Route path="/mpa-form/:id/" element={<MpaDashBoard />} />
        <Route path="/mpa-form/:id/mpa-information" element={<MpaQuestions />} />
        <Route path="/mpa-form/:id/review-submit" element={<MpaReviewSubmit />} />
        <Route path="/sio-form/:id" element={<SioLogin />} />
        <Route path="/sio-form/:id/:password" element={<SioFormFillingDashboard />} />
        <Route path="/sio-form/:id/:password/general-information" element={<GeneralQuestions />} />
        <Route
          path="/sio-form/:id/:password/operational-information"
          element={<OperationalQuestions />}
        />
        <Route
          path="/sio-form/:id/:password/overview-information"
          element={<OverviewQuestions />}
        />
        <Route path="/sio-form/:id/:password/reviewSubmit" element={<SioReviewAndSubmit />} />
        <Route path="/completion-form/:id/" element={<ExecutedProgramDashboard />} />
        <Route path="/funding-form/:id/" element={<FundingFormLandingPage />} />
        <Route path="/funding-form/:id/edit" element={<FundingFormFillingPage />} />
        <Route path="funding-forms/:id/success" element={<FundingSuccess />} />
        <Route path="/" element={<AuthRequired />}>
          {/* <Route path="/program-form/:id/reviewSubmit" element={<ProgramReviewandSubmit />} /> */}
          <Route path="app/*" element={<MainScreen />} />
          <Route
            path="register-details-form"
            element={
              <AccountStatusRequired statuses={[AccountStatus.New]} page>
                <RegisterDetailsForm />
              </AccountStatusRequired>
            }
          />
          <Route path="register-details/*" element={<RegisterDetails />} />
        </Route>
        <Route path="/register" element={<Register />} />
        <Route
          path="/register-finished"
          element={<ThankYou message={ThankYouMessage.Register} />}
        />
        <Route
          path="/register-pending-finance"
          element={
            <LoggedRequired>
              <AccountStatusRequired statuses={[AccountStatus.PendingFinance]} page>
                <ThankYou message={ThankYouMessage.RegisterPendingFinance} />
              </AccountStatusRequired>
            </LoggedRequired>
          }
        />
        <Route
          path="/account-invalid-status"
          element={
            <LoggedRequired>
              <AccountStatusRequired
                statuses={[AccountStatus.Deleted, AccountStatus.Inactive, AccountStatus.Locked]}
                page
              >
                <ThankYou message={ThankYouMessage.AccountError} />
              </AccountStatusRequired>
            </LoggedRequired>
          }
        />
        <Route path="/program-preview/:programId" element={<ProgramPage />} />
        <Route path="/shared/*" element={<PasswordProtectedPage />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>

      <MainDialog />
      <AppListener />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
