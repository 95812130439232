import React, { FC } from 'react';
import { TextField } from '@mui/material';
import { FlexBox } from '../../../shared/components';
import { AccountResourceConfig } from '../../../shared/interfaces';
import { useAppDispatch } from '../../../shared/hooks';
import { setAccountSettingsAccountData } from './accountSettingsSlice';
import fonts from '../../../assets/scss/font.module.scss';

interface Props {
  AccountSettingsResourceConfig: AccountResourceConfig | undefined;
  formDisabled: boolean;
}

export const AccountSettingsResource: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { AccountSettingsResourceConfig, formDisabled } = props;
  const { supplierTermsOfUseText, supplierTermsOfUseURL } = { ...AccountSettingsResourceConfig };

  const commonTextFieldStyle = {
    '& label': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
    '& input': {
      fontSize: fonts.fontSize18,
    },
    '&::placeholder': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
  };

  if (AccountSettingsResourceConfig === undefined) return null;
  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 3, minHeight: '450px' }}>
      <TextField
        label={supplierTermsOfUseText?.label}
        value={supplierTermsOfUseText?.value}
        disabled={formDisabled}
        onChange={(e) => {
          dispatch(
            setAccountSettingsAccountData({
              procurement: { supplierTermsOfUseText: e.target.value },
            }),
          );
        }}
        sx={commonTextFieldStyle}
        fullWidth
      />

      <TextField
        disabled={formDisabled}
        label={supplierTermsOfUseURL?.label}
        value={supplierTermsOfUseURL?.value}
        onChange={(e) => {
          dispatch(
            setAccountSettingsAccountData({
              procurement: { supplierTermsOfUseUrl: e.target.value },
            }),
          );
        }}
        sx={commonTextFieldStyle}
        fullWidth
      />
    </FlexBox>
  );
};
