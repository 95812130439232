import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getFunding, getFundingList, getFundingStatus, postFunding, patchFunding, deleteFunding, downloadFile} from '../../api/portal/fundingRequests';
import { addCancelTokenEvent } from '../../api';
import { GridResponse, GridModel, GridRequest } from '../../shared/interfaces';
import { FundingList, StatusCount, PostFundingRequest, Funding, UpdateFundingRequest } from '../../shared/interfaces/funding/funding';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';


interface FundingGridRequest extends GridRequest {
  status: string | null | undefined;
  fundingFormName?: string;
  orderOppurtuinityName?: string;
  programName?: string;
}
interface FundingState {
  grid: GridModel<FundingList>;
  funding: Funding,
  FundingId: string;
  updateStatusSuccess: boolean;
  status: string | null | undefined;
  loading: boolean;
  statusCount: [StatusCount]
}

const initialState: FundingState = {
  grid: {
    name: 'fundings',
    data: { totalCount: 0, results: [] },
    page:0,
    defaultSort: { field: 'lastUpdated', direction: 'desc' },
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
  },
  funding:{
    id: '',
    createdBy: '',
    createdAt: '',
    lastUpdatedBy:'' ,
    lastUpdated:'' ,
    fundingFormName: '',
    fundingOpportunityName: '',
    effectiveDate: '',
    orderId: '',
    additionalTerms: '',
    notes: '',
    fundingStatus: '',
    editing: false,
    status:'' ,
    percentComplete: 0,
    progress:{
      funding:{
        total:0,
        complete:0
      },
      general:{
        total:0,
        complete:0
      },
      impactAndScope:{
        total:0,
        complete:0
      },
      strategiesAndApproaches:{
        total:0,
        complete:0
      },
      researchAndEvaluation:{
        total:0,
        complete:0
      }
    },
    siAdmin:'',
    scalewithCustomer:'',
    client:'',
    outputs:[{
      description:'',
      quantity:0,
      scaletype:'',
      value:0
    }],
    funding:{
      programDate:{
        start:'',
        end:'',
      },
      location:{
        value:''
    },
    itemization:{
      value:[]
  }
    },
    general:{
      contact:{
        name:'',
        email:'',
        phone:''
      }, 
      programName:{
        internalProgramName:'',
        platformProgramName:''
      },
      programDescription:{
        description:'',
        optional:''
      }
    },
    impactAndScope:{
      causes:[],
      primaryImpact:[],
      secondaryImpacts:[],
      protectAndEnhanceForest:'',
      targetGroup:[],
      audienceAge:[],
      locationDetails:{
        regions:[],
        countries:[],
        states:[],
        cities:[],
        additionalLocationDetails:'',
        locationExplanation: {
          regions: '',
          countries: '',
          states: '',
          cities: '',
        }
      },
    },
    strategiesAndApproaches:{
      strategies:[],
      activities: {
        value: ''
      },
      approachDuration:'',
      activitiesFrequency:'',
      dataMeasurementType:'',
      outputs:[{
        description:'',
        quantity:0,
        scaletype:'',
        value:0
      }],
      outcomes:[{
        description:'',
        quantity:0,
        scaletype:'',
        value:0
      }],
      resultsMeasurement:'',
      nonprofitPartners:false,
      nonprofitPartnersDescription:'',
    },
    researchAndEvaluation:{
      researchFile:{
        name: ''
      },
      evidenceDescription:{
        optional: '',
        selected:[]
      },
      studyDescription:{
        optional: '',
        selected:[]
      },
      dataDescription:{
        optional: '',
        selected:[]
      },
      environmentalOutputValues:{
        quantity: 0,
      description:''
      },
      environmentalOutputs: {
        selected: [],
      },
      outcomeDescription:[],
      researchApproach:[],
      strength:''
        },
    // signature:{
    //   name:'',
    //   email:''
    // }
    },
    FundingId:'',
    updateStatusSuccess: false,
  status: null,
  statusCount: [{
    status_list:{ _id:'',
     count: 0}
   }],
   loading: false
    }

    export const downloadFileThunk = createAsyncThunk(
      'fundings/downloadFile',
      async (request: any, { signal }) => {
        addCancelTokenEvent(signal);
        const { data } = await downloadFile(request);
        return data;
      },
    );

export const fetchFundingListThunk = createAsyncThunk('fundings/fetchFundingList', async (request: FundingGridRequest, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getFundingList(request);
  return data;
});

export const fetchFundingStatus = createAsyncThunk(
  'fundings/getfundingStatusThunk',
  async (unused, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getFundingStatus();
    return data;
  },
);

// function getFundingFormDetail(id: string): { data: any; } | PromiseLike<{ data: any; }> {
//   throw new Error('Function not implemented.');
// }

export const fetchFunding = createAsyncThunk(
  'fundings/getFundingThunk',
  async (id: string, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getFunding(id);
    return data;
  },
);

export const addFunding = createAsyncThunk(
  'fundings/addFunding',
  async (funding: PostFundingRequest) => {
    const response = await postFunding(funding);
    return response.data;
  },
);


export const updateFunding = createAsyncThunk(
  'fundings/updateFunding',
  async ({ id, funding }: { id: string, funding: UpdateFundingRequest }) => {
    const response = await patchFunding(id, funding);
    return response.data;
  },
);


export const removeFunding = createAsyncThunk(
  'fundings/removeFunding',
  async (id: string) => {
    const { data } = await deleteFunding(id);
    return data;
  },
);


export const fundingSlice = createSlice({
  name: 'fundings',
  initialState,
  reducers: {
    setFundingsGridModel: (state, action: PayloadAction<Partial<GridModel<FundingList>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearFundingsState: (state) => {
      state.grid = initialState.grid;
      state.updateStatusSuccess = false;
    },
    clearFundingsUpdateSuccess: (state) => {
      state.updateStatusSuccess = false;
    },
    setFundingsStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setViewFundingId: (state, action: PayloadAction<string>) => {
      state.FundingId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchFundingListThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<FundingList>>) => {
          // state.allData = action.payload.results
          state.grid.loadingGrid = false;
          state.grid.data = action.payload;
        },
      )
      .addCase(fetchFundingListThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchFundingListThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(
        fetchFundingStatus.fulfilled,
        (state, action) => {
          state.statusCount = action.payload;
        },
      )
      .addCase(fetchFundingStatus.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchFundingStatus.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(addFunding.fulfilled, (state, action) => {
        state.funding = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(addFunding.rejected, (state, action) => {
        state.grid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(addFunding.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(
        fetchFunding.fulfilled,
        (state, action: PayloadAction<Funding>) => {
          state.grid.loadingGrid = false;
          state.funding=action.payload;
        },
      )
  },
});

export const {
  setFundingsGridModel,
  clearFundingsState,
  setFundingsStatus,
  clearFundingsUpdateSuccess,
  setViewFundingId
} = fundingSlice.actions

export const fundingReducer = fundingSlice.reducer;