import React from 'react';
import NumberFormat from 'react-number-format';

interface Props {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const PercentageInput = React.forwardRef<any, Props>((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={2}
      suffix="%"
    />
  );
});
