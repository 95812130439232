import React, { useEffect, useState } from 'react';
import {
  Approval,
  Dashboard,
  FolderSharedOutlined,
  People,
  CheckCircle,
  Public,
  InsertDriveFile,
  // VolunteerActivism,
  WarningAmber,
  Handshake,
  Assessment,
  AdminPanelSettings,
  RocketLaunch,
} from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid, List, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import Logo from '../../shared/components/Logo';
// import AddCircleIcon from '../../assets/svg/add_circle.svg';
// import Transaction from '../../assets/svg/Transaction.svg';
// import TransactionActive from '../../assets/svg/TransactionActive.svg';
import {
  GIVEWITH_ROLES,
  NEW_CONTRIBUTION_PERMISSIONS,
  SUBSCRIPTION_PRICING,
} from '../../shared/constants';
import { FlexBox } from '../../shared/components';
import { AccountTypeRequired, RoleRequired, RoleRequiredOrAccountType } from '../../app/Security';
import { AccountType, Dialogs, UserRole } from '../../shared/enums';
import { NavBarButton } from './NavBarButton';
import { useAppSelector, useAccount } from '../../shared/hooks';
import { SubscriptionType } from '../../shared/enums/subscriptionType';
import { SelectAccountRequired } from '../../app/TierTypeWrapper';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

export interface MenuButton {
  id: string;
  text: string;
  to?: string;
  subMenu?: Array<MenuButton>;
  icon?: JSX.Element;
  dialog?: Dialogs;
  rolesRequired?: Array<UserRole>;
  accountType?: Array<AccountType>;
  onlyAccountType?: Array<AccountType>;
  onlyAccountTier?: Array<SubscriptionType>;
}

export function LeftNavBar() {
  const showMenu = useAppSelector((x) => x.app.showOptionsLeftNavBar);
  const { isAccountInType } = useAccount();
  const isProcUser = isAccountInType([AccountType.procurement]);
  const isSupplier = isAccountInType([AccountType.supplier]);
  const isSio = isAccountInType([AccountType.sio]);
  const navHeight = window.innerHeight - 250;
  const [path, setPath] = useState(window.location.pathname);
  const location = useLocation();
  const user = useAppSelector((x) => x.myProfile.user);
  const [buywithSupplier, setBuywithSupplier] = useState(false);

  useEffect(() => {
    if (user && user.tenantAccessGrants) {
      const hasBuyWithAccess = user.tenantAccessGrants.some(
        (grant: any) => grant.app === 'BuyWith',
      );
      setBuywithSupplier(hasBuyWithAccess);
    }
  }, [user]);

  const handleUpgradeClick = () => {
    window.open(SUBSCRIPTION_PRICING, '_blank');
  };

  const MENU: Array<MenuButton> = [
    !isSio
      ? {
          id: 'dashboard',
          text: 'Dashboard',
          to: 'dashboard',
          icon: <Dashboard />,
          rolesRequired: [
            ...GIVEWITH_ROLES,
            UserRole.OrgAdmin,
            UserRole.Approver,
            UserRole.Transactor,
            UserRole.Finance,
            UserRole.Executive,
          ],
        }
      : {
          id: 'menu',
          text: 'Overview',
          to: 'dashboard',
          icon: <Dashboard />,
          rolesRequired: [
            ...GIVEWITH_ROLES,
            UserRole.OrgAdmin,
            UserRole.Approver,
            UserRole.Transactor,
            UserRole.Finance,
            UserRole.Executive,
          ],
        },
    {
      id: 'approvals',
      text: 'Approvals',
      to: 'approvals',
      icon: <Approval />,
      rolesRequired: [...GIVEWITH_ROLES, UserRole.OrgAdmin, UserRole.Approver],
      onlyAccountType: [AccountType.default, AccountType.supplier],
    },
    {
      id: 'fulfillment',
      text: 'Fulfillment',
      to: 'fulfillment',
      icon: <CheckCircle />,
      rolesRequired: [...GIVEWITH_ROLES, UserRole.OrgAdmin, UserRole.Transactor, UserRole.Finance],
      onlyAccountType: [AccountType.default, AccountType.supplier],
    },
    {
      id: 'accounts-transactions',
      text: 'Transactions',
      to: 'accounts/transactions',
      icon: <SwapVerticalCircleIcon sx={{ transform: 'rotate(90deg)' }} />,
      rolesRequired: [...GIVEWITH_ROLES, UserRole.OrgAdmin, UserRole.Finance, UserRole.Executive],
      onlyAccountType: [AccountType.default, AccountType.supplier],
    },
    {
      id: 'Suppliers',
      text: 'Suppliers',
      to: 'suppliers',
      icon: <Handshake />,
      accountType: [AccountType.procurement],
    },
    {
      id: 'socialImpact',
      text: 'Social Impact',
      subMenu: [
        {
          id: 'sio',
          text: '- Organizations',
          to: 'sio',
          rolesRequired: [UserRole.SuperAdmin, UserRole.SIAdmin],
        },
        {
          id: 'program',
          text: '- Programs',
          to: 'programs',
          rolesRequired: [UserRole.SuperAdmin, UserRole.SIAdmin],
        },
      ],
      icon: <InsertDriveFile />,
      rolesRequired: [UserRole.SuperAdmin, UserRole.SIAdmin],
    },
    {
      id: 'sio',
      text: 'Organization',
      to: 'sio/organization-information',
      accountType: [AccountType.sio],
      icon: <InsertDriveFile />,
      // rolesRequired: [UserRole.SuperAdmin, UserRole.SIAdmin],
    },
    {
      id: 'program',
      text: 'Programs',
      to: 'sio/programs',
      icon: <Public />,
      // rolesRequired: [UserRole.SuperAdmin, UserRole.SIAdmin],
      accountType: [AccountType.sio],
    },

    {
      id: 'funding',
      text: 'Funding',
      to: 'sio/funding',
      icon: <SwapVerticalCircleIcon sx={{ transform: 'rotate(90deg)' }} />,
      accountType: [AccountType.sio],
    },
    {
      id: 'completion',
      text: 'Completion ',
      to: 'sio/completions',
      icon: <CheckCircle />,
      accountType: [AccountType.sio],
    },

    {
      id: 'fundingAdmin',
      text: 'Funding Admin',
      subMenu: [
        {
          id: 'funding',
          text: '- Funding',
          to: 'funding',
          rolesRequired: [UserRole.SuperAdmin, UserRole.SIAdmin],
        },
        {
          id: 'completion',
          text: '- Completion ',
          to: 'completions',
          rolesRequired: [UserRole.SuperAdmin, UserRole.SIAdmin],
        },
      ],
      icon: <InsertDriveFile />,
      rolesRequired: [UserRole.SuperAdmin, UserRole.SIAdmin],
    },
    {
      id: 'Procurement-Metrics',
      text: 'Metrics',
      to: 'metrics',
      icon: <Assessment />,
      onlyAccountType: [AccountType.procurement],
      rolesRequired: [UserRole.SuperAdmin, UserRole.Finance, UserRole.OrgAdmin],
    },
    {
      id: 'users',
      text: 'Users',
      to: 'users',
      icon: <People />,
      rolesRequired: [...GIVEWITH_ROLES, UserRole.OrgAdmin, UserRole.UserAdmin],
    },
    {
      id: 'admin-screens',
      text: 'Admin',
      to: 'admin',
      icon: <AdminPanelSettings />,
      rolesRequired: GIVEWITH_ROLES,
    },
    {
      id: 'accounts-settings',
      text: 'Accounts',
      to: 'accounts',
      icon: <FolderSharedOutlined />,
      rolesRequired: GIVEWITH_ROLES,
    },
    {
      id: 'locales',
      text: 'Locales',
      to: 'locales',
      icon: <Public />,
      rolesRequired: [UserRole.SuperAdmin, UserRole.GivewithOperations],
    },
    {
      id: 'instances',
      text: 'Instances',
      to: 'instances',
      icon: <InsertDriveFile />,
      rolesRequired: [UserRole.SuperAdmin, UserRole.GivewithOperations],
    },
    {
      id: 'errorLog',
      text: 'Error Log',
      to: 'error-log',
      icon: <WarningAmber />,
      rolesRequired: [UserRole.SuperAdmin, UserRole.GivewithOperations],
    },
  ];

  console.log(path);

  const contributionTextFontStyles = {
    fontSize: fonts.fontSize14,
    marginLeft: '10px',
    fontWeight: fonts.fontWeight700,
  };

  return (
    <Box
      sx={{
        width: '14%',
        minWidth: '250px',
        height: '100vh',
        position: 'relative',
        // backgroundColor: theme.palette.secondary.dark,
        backgroundColor: colors.secondary,
      }}
      id="leftnav"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '30px',
          width: '90%',
          paddingLeft: 1,
          paddingRight: '18px',
          borderBottom: '0.5px solid #F6F6F6',
          height: '71px',
        }}
        id="navLogo"
      >
        {isSio ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Logo />
            <Typography
              sx={{
                display: 'flex',
                fontSize: '8pt',
                fontWeight: 'bold',
                padding: '3px 5px',
                color: '#57585A',
                justifyContent: 'center',
              }}
            >
              Social Impact Network
            </Typography>
          </Box>
        ) : (
          <Logo />
        )}
      </Box>
      <Box
        sx={{
          width: '14%',
          minWidth: '230px',
          height: '71%',
          // padding: '20px',
          margin: '20px',
        }}
      >
        {showMenu && !isProcUser && (
          <Box
            sx={{ flex: 1, marginLeft: '7px', marginRight: '10px' }}
            textAlign="center"
            id="navButton"
          >
            <RoleRequired roles={NEW_CONTRIBUTION_PERMISSIONS}>
              <AccountTypeRequired types={[AccountType.default, AccountType.supplier]}>
                {location.pathname === '/app/contribution/new' ? (
                  <Button
                    href="contribution/new"
                    disabled
                    variant="contained"
                    disableElevation
                    fullWidth
                    className="new-contribution-button"
                    size="large"
                    sx={{
                      backgroundColor: `${colors.strokeDefault} !important`,
                      height: '56px',
                      borderRadius: '8px',
                      color: `${colors.secondary} !important`,
                    }}
                    startIcon={<AddCircleIcon />}
                  >
                    <FlexBox
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                      }}
                    >
                      <Typography sx={contributionTextFontStyles}>New Contribution</Typography>
                    </FlexBox>
                  </Button>
                ) : (
                  <Button
                    href="contribution/new"
                    variant="contained"
                    disableElevation
                    fullWidth
                    size="large"
                    className="new-contribution-button"
                    sx={{
                      backgroundColor: `${colors.buttonDefault} !important`,
                      height: '56px',
                      borderRadius: '8px',
                    }}
                    startIcon={<AddCircleIcon />}
                  >
                    <FlexBox
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignContent: 'center',
                      }}
                    >
                      <Typography sx={contributionTextFontStyles}>New Contribution</Typography>
                    </FlexBox>
                  </Button>
                )}
              </AccountTypeRequired>
            </RoleRequired>
          </Box>
        )}
        <Box mt={1}>
          <List
            component="nav"
            sx={{
              overflowY: 'auto',
              height: `${navHeight}px`,
              marginRight: '5px',
              paddingTop: '0px !important',
              paddingBottom: '0px !important',
              '&::-webkit-scrollbar': {
                width: '0.3em',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                border: 'solid 3px transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                // backgroundColor: 'white',
                outline: '1px solid slategrey',
                borderRadius: '10px',
              },
            }}
          >
            {!isSio && (
              <Typography
                sx={{
                  fontSize: '12px',
                  marginLeft: '10px',
                  fontWeight: 600,
                  color: colors.textPrimary,
                  textTransform: 'uppercase',
                  marginY: '10px',
                }}
              >
                General
              </Typography>
            )}
            {showMenu &&
              MENU.map((x) => {
                if (x.onlyAccountTier)
                  return (
                    <Box sx={{ borderRadius: '10px', marginRight: '15px' }}>
                      <SelectAccountRequired>
                        <NavBarButton
                          button={x}
                          handleNavigation={(pathname: string) => setPath(pathname)}
                        />
                      </SelectAccountRequired>
                    </Box>
                  );
                if (x.onlyAccountType)
                  return (
                    <AccountTypeRequired types={x.onlyAccountType} key={x.id}>
                      <RoleRequiredOrAccountType key={x.id} roles={x.rolesRequired}>
                        <NavBarButton
                          button={x}
                          handleNavigation={(pathname: string) => setPath(pathname)}
                        />
                      </RoleRequiredOrAccountType>
                    </AccountTypeRequired>
                  );
                if (x.accountType || x.rolesRequired)
                  return (
                    <RoleRequiredOrAccountType
                      key={x.id}
                      roles={x.rolesRequired}
                      types={x.accountType}
                    >
                      <NavBarButton
                        button={x}
                        handleNavigation={(pathname: string) => setPath(pathname)}
                      />
                    </RoleRequiredOrAccountType>
                  );
                return (
                  <NavBarButton
                    key={x.id}
                    button={x}
                    handleNavigation={(pathname: string) => setPath(pathname)}
                  />
                );
              })}
          </List>
          {isSupplier && buywithSupplier && (
            <Card
              elevation={0}
              style={{
                borderRadius: '20px',
                width: '210px',
                height: '130px',
                position: 'fixed',
                top: '75%',
                bottom: '50%',
                backgroundColor: '#F2FAFD',
              }}
            >
              <CardContent>
                <Typography
                  align="center"
                  sx={{ fontSize: '9pt', fontWeight: 500, color: '#666666' }}
                >
                  Current Plan:
                </Typography>
                <Typography
                  align="center"
                  color="textSecondary"
                  sx={{ fontSize: '12pt', fontWeight: 700, color: '#57585A' }}
                >
                  Starter
                </Typography>
                <Grid
                  container
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    bottom: 20,
                    left: 0,
                    right: 0,
                  }}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: 'white',
                        color: '#249ACB',
                        border: '1px solid #249ACB',
                        width: '180px',
                        borderRadius: '10px',
                        fontSize: '8pt',
                        fontWeight: 700,
                        height: '40px',
                      }}
                      onClick={handleUpgradeClick}
                    >
                      <RocketLaunch
                        sx={{
                          padding: '0px 5px',
                          height: '19.3px',
                          width: '19.3px',
                        }}
                      />
                      Upgrade Plan
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </Box>
      </Box>
    </Box>
  );
}
