import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addCancelTokenEvent,
  getCauseAreaSkus,
  getSupplierCustomFields,
  postContribution,
  putContribution,
} from '../../../api';
import { refreshAppAccountThunk, RootState } from '../../../app';
import { deleteContributionThunk, patchUpdateContributionStatusThunk } from '../../../shared/components';
import {
  AccountContributionFields,
  CauseAreaSku,
  Contribution,
  SelectAmountFormData,
} from '../../../shared/interfaces';
import { getContributionThunk } from '../incentiveSlice';

interface requestValuesCustomFields {
  id: string;
  accountId: string;
}

interface responseCustomFields {
  id: string;
  giveFields: AccountContributionFields;
}

export interface SelectAmountState {
  formData: SelectAmountFormData;
  loading: boolean;
  success: boolean;
  supplierCustomFields?: AccountContributionFields;
  loadingCustomFields: boolean;
}

const initialState: SelectAmountState = {
  formData: {
    selectedProgram: '',
    grandTotal: 0,
    customerName: '',
    customerId: '',
    quoteNumber: '',
    quoteAmount: undefined,
    customForm1: '',
    customForm2: '',
    customForm3: '',
    customForm4: '',
    description: '',
    emails: [],
    maxApprovalAmount: '',
  },
  supplierCustomFields: undefined,
  loading: false,
  success: false,
  loadingCustomFields: false,
};

export const postContributionThunk = createAsyncThunk(
  'selectAmount/postContributionThunk',
  async (incentive: Contribution, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await postContribution(incentive);
    return data;
  },
);

export const putContributionThunk = createAsyncThunk(
  'selectAmount/putContributionThunk',
  async (incentive: Contribution, { signal }) => {
    const updatedIncentive = { ...incentive };
    delete updatedIncentive.program;
    addCancelTokenEvent(signal);
    const { data } = await putContribution(updatedIncentive);
    return data;
  },
);

export const getSupplierCustomFieldsThunk = createAsyncThunk<
  responseCustomFields,
  requestValuesCustomFields
>(
  'selectAmount/getSupplierCustomFieldsThunk',
  async (values: requestValuesCustomFields, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getSupplierCustomFields(values);
    return data;
  },
);

export const getSkusThunk = createAsyncThunk<Array<CauseAreaSku>, string, { state: RootState }>(
  'selectAmount/getSkusThunk',
  async (id: string, { getState, signal }) => {
    const state = getState();
    const {
      app: { account },
    } = state;
    addCancelTokenEvent(signal);
    const { data } = await getCauseAreaSkus(id, account?.id ?? '-1');
    return data;
  },
);

const selectAmountSlice = createSlice({
  name: 'selectAmount',
  initialState,
  reducers: {
    setSelectAmountFormData: (state, action: PayloadAction<SelectAmountFormData>) => {
      state.formData = _.assign(state.formData, action.payload);
    },
    clearSelectAmountState: (state) => {
      _.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshAppAccountThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(refreshAppAccountThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(refreshAppAccountThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(postContributionThunk.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(postContributionThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postContributionThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(putContributionThunk.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(putContributionThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(putContributionThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(patchUpdateContributionStatusThunk.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(patchUpdateContributionStatusThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(patchUpdateContributionStatusThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(deleteContributionThunk.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(deleteContributionThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteContributionThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(getContributionThunk.fulfilled, (state, action: PayloadAction<Contribution>) => {
        const {
          grandTotal,
          selectedProgram,
          customerName,
          customerId,
          quoteNumber,
          quoteAmount,
          customForm1,
          customForm2,
          customForm3,
          customForm4,
          description,
          emails,
          maxApprovalAmount,
        } = action.payload;

        state.formData = {
          customerName,
          customerId,
          description,
          quoteNumber,
          grandTotal,
          selectedProgram,
          quoteAmount,
          customForm1,
          customForm2,
          customForm3,
          customForm4,
          emails,
          maxApprovalAmount,
        };
      })
      .addCase(getContributionThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(getContributionThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(
        getSupplierCustomFieldsThunk.fulfilled,
        (state, action: PayloadAction<responseCustomFields>) => {
          state.loadingCustomFields = false;
          state.supplierCustomFields = action.payload.giveFields;
        },
      )
      .addCase(getSupplierCustomFieldsThunk.rejected, (state) => {
        state.loadingCustomFields = false;
      })
      .addCase(getSupplierCustomFieldsThunk.pending, (state) => {
        if (!state.loadingCustomFields) state.loadingCustomFields = true;
      });
  },
});

export const { setSelectAmountFormData, clearSelectAmountState } = selectAmountSlice.actions;

export const selectAmountReducer = selectAmountSlice.reducer;
