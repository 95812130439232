export enum OrderStatus {
  Draft = 'draft',
  PendingApproval = 'pending approval',
  Approved = 'approved',
  Denied = 'denied',
  FinancialHold = 'financial hold',
  PendingPayment = 'pending payment',
  Completed = 'completed',
  Abandoned = 'abandoned',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
  Deleted = 'deleted',
}

export enum SioStatus {
  Approved = 'approved',
  Submitted = 'submitted',
  InProgress = 'in_progress',
  PendingApproval = 'submitted',
  Archived = 'archived',
}

export enum FundingStatus {
  Approved = 'approved',
  Submitted = 'submitted',
  InProgress = 'in_progress',
  PendingApproval = 'submitted',
  Archived = 'archived',
}


export enum OrderStatusLabel {
  Approve = 'Approve',
  Deny = 'Deny',
  Complete = 'Complete',
  Abandon = 'Abandon',
  Fulfill = 'Fulfill',
  Reject = 'Reject',
  Delete = 'Delete',
  share = 'Share',
}

export enum OrderActions {
  Draft = 'draft',
  PendingApproval = 'pending approval',
  Approved = 'approved',
  Denied = 'denied',
  FinancialHold = 'financial hold',
  PendingPayment = 'pending payment',
  Completed = 'completed',
  Abandoned = 'abandoned',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
  Deleted = 'deleted',
  shared = 'Shared',
}
