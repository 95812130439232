import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  InputAdornment,
  Box,
} from '@mui/material';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import {
  FlexBox,
  LabelAndSwitch,
  PercentageInput,
  RecordDetails,
  MoneyInput,
} from '../../../shared/components';
import { InstanceSettingsType, InstanceStatus } from '../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { InstanceFormConfig } from '../../../shared/interfaces';
import { isNullOrWhitespace } from '../../../shared/utils';
import {
  clearInstanceSettingsLocale,
  setInstanceSettingsCurrentLocale,
  setInstanceSettingsData,
} from './instanceSettingsSlice';
import fonts from '../../../assets/scss/font.module.scss';

interface Props {
  value: InstanceFormConfig;
}

export const InstanceSettingsGeneral: FC<Props> = (props) => {
  const {
    value: { general },
  } = props;
  const {
    status,
    allowRegistration,
    description,
    giveServiceFee,
    givewithEmail,
    instanceName,
    locale,
    loginUrl,
    portalUrl,
    type,
    minimumFundingAmount,
    defaultIncentivePercentage,
  } = general;

  const dispatch = useAppDispatch();
  const state = useAppSelector((x) => x.instanceSettings);
  const { locales, isNew, values, selectedLocale } = state;
  const optionLocales =
    status.value === InstanceStatus.Active
      ? locales?.filter((item) => item.active === true)
      : locales;

  const blockInvalidChar = (e: any) => ['.', '-', '+', 'e'].includes(e.key) && e.preventDefault();

  const commonTextFieldStyle = {
    '& label': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
    '& input': {
      fontSize: fonts.fontSize18,
    },
    '&::placeholder': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
  };

  return (
    <FlexBox sx={{ gap: 6, maxHeight: 75 }}>
      <FlexBox
        sx={{
          flexDirection: 'column',
          gap: 3,
          flex: 0.7,
        }}
      >
        <TextField
          label={instanceName?.label}
          value={instanceName?.value}
          required={instanceName?.required}
          fullWidth
          onChange={(event) => {
            dispatch(setInstanceSettingsData({ name: event.target.value }));
          }}
          sx={commonTextFieldStyle}
        />
        <TextField
          label={description?.label}
          value={description?.value}
          required={description?.required}
          onChange={(event) => {
            dispatch(setInstanceSettingsData({ description: event.target.value }));
          }}
          sx={commonTextFieldStyle}
        />
        <FlexBox sx={{ gap: 1, alignItems: 'end', flex: 1 }}>
          <FormControl sx={{ flex: 1 }}>
            <InputLabel sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }}>{locale.label}</InputLabel>
            <Select
              labelId="filter-select-label"
              id="filter-select"
              variant="standard"
              value={locale?.value}
              required={locale?.required}
              onChange={(event: SelectChangeEvent) => {
                const filterLocale = locales?.find((item) => item.id === event.target.value);
                if (filterLocale === undefined) return;
                dispatch(
                  setInstanceSettingsData({
                    settings: {
                      locale: { id: filterLocale?.id, name: filterLocale?.name, type: 'locale' },
                    },
                  }),
                );
                dispatch(setInstanceSettingsCurrentLocale(filterLocale));
              }}
              sx={{ fontSize: fonts.fontSize18 }}
            >
              {optionLocales?.map((item) => (
                <MenuItem key={`instance-settings-general-${item.id}`} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ flex: 1 }}>
            <InputLabel sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }}>{type.label}</InputLabel>
            <Select
              labelId="filter-select-label"
              id="filter-select"
              variant="standard"
              value={type?.value}
              required={type?.required}
              onChange={(event: SelectChangeEvent) => {
                dispatch(setInstanceSettingsData({ type: event.target.value }));
              }}
              sx={{ fontSize: fonts.fontSize18 }}
            >
              {Object.values(InstanceSettingsType).map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={`instance-setting-general-${item}-${index}`} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FlexBox>
        <TextField
          label={loginUrl?.label}
          value={loginUrl?.value}
          required={loginUrl?.required}
          onChange={(event) => {
            dispatch(setInstanceSettingsData({ settings: { loginUrl: event.target.value } }));
          }}
          sx={commonTextFieldStyle}
        />
        <TextField
          label={portalUrl?.label}
          value={portalUrl?.value}
          required={portalUrl?.required}
          onChange={(event) => {
            dispatch(setInstanceSettingsData({ settings: { portalUrl: event.target.value } }));
          }}
          sx={commonTextFieldStyle}
        />
        <TextField
          label={givewithEmail?.label}
          value={givewithEmail?.value}
          required={givewithEmail?.required}
          onChange={(event) => {
            dispatch(setInstanceSettingsData({ settings: { givewithEmail: event.target.value } }));
          }}
          sx={commonTextFieldStyle}
        />
        <FlexBox sx={{ gap: 4, flex: 1 }}>
          <TextField
            sx={{ ...commonTextFieldStyle, flex: 1 }}
            label={giveServiceFee?.label}
            value={giveServiceFee.value ? giveServiceFee.value * 100 : null}
            required={giveServiceFee?.required}
            InputProps={{ inputComponent: PercentageInput as any }}
            onChange={(event) => {
              dispatch(
                setInstanceSettingsData({
                  settings: {
                    serviceFee: isNullOrWhitespace(event.target.value)
                      ? null
                      : parseFloat(event.target.value) / 100,
                  },
                }),
              );
            }}
          />
          <FlexBox sx={{ gap: 1, alignItems: 'end', flex: 1 }}>
            <Chip
              sx={{ borderRadius: 1, backgroundColor: '#81c784' }}
              label={`${giveServiceFee.value ? 100 - (giveServiceFee.value * 100 || 0) : '100'} %`}
            />
            <Typography variant="body1">To non-profit partner.</Typography>
          </FlexBox>
        </FlexBox>
        <FlexBox sx={{ flexDirection: 'row', gap: 4, flex: 1 }}>
          <LabelAndSwitch
            value={allowRegistration.value}
            label={allowRegistration.label}
            messageYes="Yes"
            messageNo="No"
            onChange={(event) => {
              dispatch(
                setInstanceSettingsData({ settings: { allowRegistration: event.target.checked } }),
              );
            }}
          />
          <FormControl sx={{ flex: 1 }}>
            <InputLabel sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }}>{status.label}</InputLabel>
            <Select
              required={status?.required}
              labelId="filter-select-label"
              id="filter-select"
              variant="standard"
              value={status?.value}
              onChange={(event: SelectChangeEvent) => {
                const findLocale = locales?.find((item) => item.id === locale.value);
                if (
                  status.value === InstanceStatus.Inactive &&
                  event.target.value === InstanceStatus.Active &&
                  findLocale?.active === false
                )
                  dispatch(clearInstanceSettingsLocale());
                dispatch(setInstanceSettingsData({ status: event.target.value }));
              }}
              sx={{ fontSize: fonts.fontSize18 }}
            >
              {Object.values(InstanceStatus).map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={`instance-setting-general-${item}-${index}`} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FlexBox>
        <FlexBox sx={{ flex: 1 }} flexDirection="column">
            <FlexBox>
              <TextField
                sx={{ ...commonTextFieldStyle, flex: 1 }}
                label={minimumFundingAmount?.label}
                value={minimumFundingAmount.value}
                required={minimumFundingAmount?.required}
                onKeyDown={blockInvalidChar}
                onChange={(event) => {
                  dispatch(
                    setInstanceSettingsData({
                      settings: {
                        minimumFundingAmount: isNullOrWhitespace(event.target.value)
                          ? null
                          : Number(event.target.value),
                      },
                    }),
                  );
                }}
                InputProps={{
                  inputMode: 'numeric',
                  inputComponent: MoneyInput as any,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box>{selectedLocale?.settings.symbol}</Box>
                    </InputAdornment>
                  ),
                }}
              />
            </FlexBox>
            <FlexBox sx={{ paddingTop: '4px' }}>
              <Box sx={(theme) => ({ color: theme.palette.success.main })}>Hint: </Box>
              <Box sx={(theme) => ({ color: theme.palette.common.grey.text, paddingLeft: '4px' })}>
                Minimum Funding Amount should be minimum of {selectedLocale?.settings.symbol}1 
              </Box>
            </FlexBox>
          </FlexBox>
        <FlexBox sx={{ flex: 1 }} flexDirection="column">
            <FlexBox>
              <TextField
                sx={{ ...commonTextFieldStyle, flex: 1 }}
                label={defaultIncentivePercentage?.label}
                value={defaultIncentivePercentage.value}
                required={defaultIncentivePercentage?.required}
                InputProps={{ inputComponent: PercentageInput as any }}
                onChange={(event) => {
                  if(Number(event.target.value) > 100){
                    toast.error('Default Contribution Percentage should be less than 100%');
                  }
                    dispatch(
                      setInstanceSettingsData({
                        settings: {
                          defaultIncentivePercentage: isNullOrWhitespace(event.target.value) || Number(event.target.value) > 100
                            ? null
                            : Number(event.target.value),
                        },
                      }),
                    );
                }}
              />
            </FlexBox>
            <FlexBox sx={{ paddingTop: '4px' }}>
              <Box sx={(theme) => ({ color: theme.palette.success.main })}>Hint: </Box>
              <Box sx={(theme) => ({ color: theme.palette.common.grey.text, paddingLeft: '4px' })}>
                Default Contribution Percentage should be greater than 0%
              </Box>
            </FlexBox>
          </FlexBox>
      </FlexBox>
      <FlexBox sx={{ flexDirection: 'column', flex: 0.3 }}>
        {!isNew && <RecordDetails value={values} />}
      </FlexBox>
    </FlexBox>
  );
};
