import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  Autocomplete,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
// import { addSio } from '../sio/sioSlice';
import { clearUsersState } from '../users/usersSlice';
import { getGridRequest } from '../../shared/utils';
import { PostFundingRequest } from '../../shared/interfaces/funding/funding';
import '../sio/Sio.scss';
import { getProposalListThunk } from '../procurement';
import { addFunding } from './fundingSlice';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';
import { inputFieldStyles } from './FundingStyles';

interface FundingData {
  id: string;
}
const CreateFunding = () => {
  // const accountId = useAppSelector((state) => state.app.account?.id!);
  const procurementState = useAppSelector((x) => x.procurement);
  // const accountState = useAppSelector((x) => x.app.account);
  const fundingState = useAppSelector((state) => state.fundings);
  // const incentiveState= useAppSelector((state) => state.incentive);
  const { updateStatusSuccess } = fundingState;
  // const success = useAppSelector((state) => state.editUser.success);
  // const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const { grid } = { ...procurementState };
  // const { sort, page, pageSize, filter } = grid;
  const navigate = useNavigate();

  // useEffect(() => {
  //   const payload =
  //     selectedTab === null
  //       ? { sharedWithId: accountState?.id ?? '-1' }
  //       : { sharedWithId: accountState?.id ?? '-1', status: selectedTab };
  //   dispatch(getProcurementContributionsThunk({ ...getGridRequest(grid), ...payload }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sort, filter, page, pageSize, selectedTab]);

  // const updatedGrid = {
  //   ...grid,
  //   pageSize: 1000,
  //   defaultSort: { field: 'name', direction: 'asc' as const },
  //   // filter: { field: 'name', simpleValue: 'sw_si_admin' },
  // };

  // const getOrdersGridRequest = () => ({
  //   ...getGridRequest(updatedGrid),
  //   accountId,
  // });

  // useEffect(() => {
  //   const payload =
  //     selectedTab === null
  //       ? { accountId: accountState?.id ?? '-1' }
  //       : { accountId: accountState?.id ?? '-1', status: selectedTab };
  //   dispatch(getProposalListThunk({ ...getOrdersGridRequest(), ...payload }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sort, filter, page, pageSize, selectedTab]);

  useEffect(() => {
    dispatch(
      getProposalListThunk({
        ...getGridRequest(procurementState.grid),
        status: undefined,
      }),
    );
  }, [
    procurementState.grid.sort,
    procurementState.grid.filter,
    procurementState.grid.page,
    procurementState.grid.pageSize,
  ]);

  //   useEffect(() => {

  //   dispatch(fetchFundingListThunk({ status: 'executed', ...getGridRequest(fundingState.grid) }));

  // }, [fundingState.grid.sort, fundingState.grid.filter, fundingState.grid.page, fundingState.status, fundingState.grid.pageSize]);

  const [formValues, setFormValues] = useState<PostFundingRequest>({
    fundingFormName: '',
    fundingOpportunityName: '',
    effectiveDate: '',
    orderId: '',
    fundingStatus: '',
    notes: '',
    additionalTerms: '',
  });
  const [proposalNames, setProposalNames] = useState(grid.proposal);
  const [proposalValue, setProposalValue] = useState(null);

  const ProposalNameOptions = grid.proposal;

  // const SiAdminRoles = Roles.filter((sarr) => (arr.roles ? arr.roles.includes('sw_si_admin') : ''));

  useEffect(() => {
    if (grid.proposal) {
      setProposalNames(grid.proposal);
    }
  }, [grid.proposal]);

  // useEffect(() => {
  //   dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sort, filter, page, pageSize, active]);

  // useEffect(() => {
  //   if (success) dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [success]);

  useEffect(
    () => () => {
      dispatch(clearUsersState());
    },
    [dispatch],
  );
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormValues({ ...formValues, [name]: value });
  };
  // const validateEmail = (email: string) => {
  //   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return regex.test(email);
  // };
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target as HTMLInputElement;
    if (name === 'fundingOpportunityName') {
      const values = value.split(',');
      setFormValues({ ...formValues, fundingOpportunityName: values[1], orderId: values[0] });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  useEffect(() => {
    if (updateStatusSuccess) toast.success('New Funding Form created Successfully');
  }, [updateStatusSuccess]);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // const isValidEmail = formValues.sioUserEmailList.every(validateEmail);
    const newFunding: FundingData = (await dispatch(addFunding(formValues))).payload as FundingData;
    if (newFunding?.id) {
      navigate(`/app/fundings/${newFunding.id}`, {
        state: {
          isCreate: true,
        },
      });
    }
  };

  const filterWithString = (newInputvalue: string) => {
    const options = ProposalNameOptions.filter(
      (proposal: any) => proposal.name.toLowerCase().indexOf(newInputvalue.toLowerCase()) >= 0,
    );
    setProposalNames(options);
  };

  const handleProposalInput = (newValue: any) => {
    setProposalValue(newValue);
    const id = newValue?.id || '';
    const name = newValue?.name || '';
    setFormValues({ ...formValues, fundingOpportunityName: name, orderId: id });
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize24,
      fontWeight: fonts.fontWeight600,
      // marginLeft: '0.8em',
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      // marginLeft: '1em',
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    accordianLabel: {
      fontSize: fonts.fontSize20,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fontWeight400,
      },
    },
  };

  // function setDueDate(newValue: any) {
  //   throw new Error('Function not implemented.');
  // }

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto', p: 2, borderRadius: '20px' }}>
      <Typography sx={commonTextStyle.heading} gutterBottom>
        Add New Funding
      </Typography>
      <Box boxShadow={3} sx={{ borderRadius: '20px', bgcolor: 'white' }}>
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            margin: 0,
            border: '1px solid #f1f3f5',
            borderRadius: '20px',
            p: 3,
          }}
        >
          <Grid item xs={12}>
            <Typography sx={commonTextStyle.heading} gutterBottom>
              Overview
            </Typography>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{
              width: '100%',
              margin: 0,
              alignItems: 'flex-end',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
              <Typography sx={commonTextStyle.labelStyle}>
                Funding Form Name
                <Tooltip title="Enter Funding Form Name">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                name="fundingFormName"
                placeholder="Enter The Funding Form Name"
                fullWidth
                value={formValues.fundingFormName}
                onChange={handleInputChange}
                variant="outlined"
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: fonts.fontSize16,
                      fontWeight: 600,
                    },
                  },
                }}
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
              <Typography sx={commonTextStyle.labelStyle}>
                Select Proposal Name{' '}
                <Tooltip title="Select Proposal Name">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <FormControl
                fullWidth
                sx={{
                  ...commonTextStyle.textFieldStyle,
                  '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
                    padding: '0px',
                  },
                }}
              >
                <Autocomplete
                  clearOnBlur
                  value={proposalValue}
                  options={proposalNames}
                  fullWidth
                  sx={{
                    ...commonTextStyle.textFieldStyle,
                    '& .MuiSelect-select': {
                      fontSize: fonts.fontSize14,
                      paddingTop: '18px !important',
                      height: '40px !important',
                    },
                  }}
                  onChange={(e, newValue) => {
                    handleProposalInput(newValue);
                  }}
                  onInputChange={(event: any, newInputvalue) => {
                    filterWithString(newInputvalue);
                  }}
                  getOptionLabel={(option: any) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Regular option
                    return option?.name;
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option?.id}>
                      {option?.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Search Proposal Name"
                      variant="outlined"
                      {...params}
                      sx={{
                        ...commonTextStyle.textFieldStyle,
                        '& .MuiSelect-select': {
                          fontSize: fonts.fontSize14,
                          paddingTop: '18px !important',
                          height: '40px !important',
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
              <Typography sx={commonTextStyle.labelStyle}>
                {' '}
                Select Funding Status{' '}
                <Tooltip title="Select Funding Status">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <FormControl fullWidth>
                <Select
                  name="fundingStatus"
                  placeholder="Select Funding Status"
                  value={formValues.fundingStatus ?? 'Invoiced'}
                  onChange={handleSelectChange}
                  variant="outlined"
                  sx={{
                    ...commonTextStyle.textFieldStyle,
                    '& .MuiSelect-select': {
                      fontSize: fonts.fontSize14,
                    },
                  }}
                >
                  <MenuItem selected value="Invoiced">
                    Invoiced
                  </MenuItem>
                  <MenuItem selected value="Sent">
                    Sent
                  </MenuItem>
                  <MenuItem selected value="Received">
                    Received
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
              <Typography sx={commonTextStyle.labelStyle}>
                Creation Date
                <Tooltip title="Enter Creation Date">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <FormControl fullWidth>
                <DatePicker
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      variant="outlined"
                      fullWidth
                      sx={{ ...inputFieldStyles, borderColor: 'green' }}
                      error={false}
                    />
                  )}
                  value={formValues.effectiveDate}
                  onChange={(date) =>
                    setFormValues({ ...formValues, effectiveDate: date !== null ? date : '' })
                  }
                  toolbarPlaceholder="Select start date"
                  InputProps={{
                    sx: {
                      '& .MuiOutlinedInput-root': {
                        height: '40px',
                        fontWeight: fonts.fontWeight400,
                        fontSize: fonts.fontWeight14,
                        padding: '10px',
                      },
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '14px',
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
              <Typography sx={commonTextStyle.labelStyle}>
                Additional Terms
                <Tooltip title="Additional Terms">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                name="additionalTerms"
                placeholder="Enter additional terms"
                fullWidth
                value={formValues.additionalTerms}
                onChange={handleInputChange}
                variant="outlined"
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: fonts.fontSize16,
                      fontWeight: 600,
                    },
                  },
                }}
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>

            <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
              <Typography sx={commonTextStyle.labelStyle}>
                Notes{' '}
                <Tooltip title="Notes">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                name="notes"
                placeholder="Enter your additional notes here"
                fullWidth
                value={formValues.notes}
                onChange={handleInputChange}
                variant="outlined"
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: fonts.fontSize16,
                      fontWeight: 600,
                    },
                  },
                }}
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={1} sx={{ marginBottom: '2rem' }}>
              <Button
                sx={{
                  ...commonTextStyle.buttonStyle,
                  backgroundColor: colors.primary,
                  color: colors.secondary,
                  marginLeft: 'auto',
                }}
                variant="contained"
                color="primary"
                disabled={
                  !formValues.fundingFormName ||
                  !formValues.fundingOpportunityName ||
                  !formValues.effectiveDate ||
                  !formValues.fundingStatus
                }
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box boxShadow={3} sx={{ borderRadius: '20px', height: '500px', bgcolor: 'white' }}>
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            margin: 0,
            borderRadius: '20px',
            p: 3,
            marginTop: '2rem',
            maxHeight: '900px',
          }}
        >
          <Grid item xs={12} sx={{ marginBottom: '2rem' }}>
            <Typography sx={commonTextStyle.heading}>Form Preview</Typography>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center', mt: 3 }}>
            <NoteAltIcon sx={{ fontSize: '100px' }} color="primary" />
            <Typography sx={{ ...commonTextStyle.questionStyle, my: 3 }}>
              Please enter above details to get a preview of Funding form
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateFunding;
