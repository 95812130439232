import {
  // Autocomplete,
  // AutocompleteChangeReason,
  Box,
  // Button,
  CircularProgress,
  // FormLabel,
  Grid,
  MenuItem,
  // Popper,
  Select,
  // SelectChangeEvent,
  // Tab,
  // Tabs,
  // TextField,
  Typography,
  // ClickAwayListener,
  Button,
  // ClickAwayListener,
  useMediaQuery,
} from '@mui/material';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import React, { FC, useEffect, useState } from 'react';
// import { Close, Search } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ABOVE_PRO } from '../../../shared/constants/tierGroups';
import ClipboardImage from '../../../assets/svg/clipBoard.svg';
import { SubscriptionType, RecommedationTabs } from '../../../shared/enums';

import { useAppDispatch, useAppSelector, useSubcriptionType } from '../../../shared/hooks';

import {
  getMutualCompanyRecommendationThunk,
  // getCompanyRecommendationThunk,
  getFilteredRecommendationThunk,
  getCompanyThemesThunk,
  switchTab,
  getCompanySDGsThunk,
} from '../../company-intelligence/companiesSlice';

import { RecommendationPage } from './RecommendationPage';
import {  SelectAmountFormData } from '../../../shared/interfaces';
import { FlexBox } from '../../../shared/components';
// import SearchIcon from '../../../assets/svg/SearchIcon.svg'
// import IntelligenceModal from './IntelligenceModal';
import ProgramPicker from './ProgramPicker';
import IntelligenceModal from './IntelligenceModal';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss'; 

// const a11yProps = (name: RecommedationTabs) => ({
//   id: `simple-tab-${name}`,
//   'aria-controls': `simple-tabpanel-${name}`,
// });
interface HeaderProps {
  // incentive?: Contribution;
  // formInvalid: boolean;
  // draftInvalid: boolean;
  // submitIncentive: (status: ContributionStatus) => void;
  // formData: SelectAmountFormData;
  // canApproveDenyIncentive: boolean;
  newValue?: number;
  showIntelligenceLink?: boolean;
  // selectedProgram?: string;
  formData: SelectAmountFormData;
}
const SelectProgramHeader: FC<HeaderProps> = ({
  showIntelligenceLink,
  // selectedProgram,
  newValue,
  formData,
}) => (
  // const [isHovering, setIsHovering] = useState(false);
  // const [value, setValue] = useState('');
  // const handleChange=()=> {
  //   <Route path="/app/contribution/intelligence" element={ <IntellgienceModal formData={formData} />}/>
  // }
 
  // function updatedValue(e:any) {
  //   const val = e.target.value;
  //   setValue(val)
  // }

  // const handleMouseOver = () => {
  //   setIsHovering(true);
  // };
  

  // const handleClickAway = () => {
  //   if (value === ''){
  //     setIsHovering(false);
  //   } else {
  //     setIsHovering(true)
  //   }
  // };
// const onClickOpen1=()=>{
//   if(setIsOpen){
//     setIsOpen(true)
// }
// }
  // return (
    <FlexBox
      sx={{
        boxSizing: 'border-box',
        padding: '1rem',
        alignContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <Typography sx={{ color: colors.textPrimary, fontSize: fonts.fontSize20, fontWeight: fonts.fontWeight700 }}>
      <span style={{ backgroundColor: `${formData?.grandTotal !== 0 ? colors.primary : colors.strokeDefault}`, fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight700, color: `${formData?.grandTotal !== 0 ? colors.secondary : colors.textPrimary}`, marginRight: '10px', width: '24px', height: '24px', padding: '0px 6px', borderRadius: '30px' }}>2</span>Social Impact Program Selection
      </Typography>
      {showIntelligenceLink && (
        <FlexBox sx={{ alignItems: 'center' }}>
          {newValue !== 0 && (
            <>
                 {showIntelligenceLink ? <IntelligenceModal formData={formData} /> : null}
                 {/* <ClickAwayListener onClickAway={handleClickAway}> */}
              {/* <FlexBox sx={{ borderBottom:`${isHovering ? '1px solid blue': 'none'}`, marginLeft: '34px', marginRight: '20px' }}><img
                onMouseOver={handleMouseOver}
                onFocus={handleMouseOver}
                style={{ width: '24px', height: '24px' }}
                src={SearchIcon}
                alt="search"
              />
              {isHovering && (
                <>
                <input style={{ border: 'none', marginLeft: '10px', marginBottom: '10px', outline:'none' }} type="text" placeholder="Search Programs here" value={value} onChange={(e)=>updatedValue(e)} /><Box sx={{ marginBottom: '5px', borderBottom: '1px solid blue' }} />
                </>
              )}
              </FlexBox> */}
              {/* </ClickAwayListener> */}
            </>
          )}
        </FlexBox>
      )}
      {/* {selectedProgram && showIntelligenceLink && (
        <Button
          variant="outlined"
          size="large"
          color="success"
          sx={{ textDecoration: 'underline', height: '40px' }}
        >
          Proceed With Selection (1)
        </Button>
      )} */}
    </FlexBox>
  );
interface Props {
  entityName?: string;
  entityId?: string;
  onSelect: (id: string) => void;
  fundingAmount?: number;
  // incentive?: Contribution;
  // formInvalid: boolean;
  // draftInvalid: boolean;
  // submitIncentive: (status: ContributionStatus) => void;
  formData: SelectAmountFormData;
  // canApproveDenyIncentive: boolean;
}

const options = [
  // Enable once this option in scope
  { name: 'Recommendations', value: 'Recommendations', key: 1 },
  { name: 'Standard', value: 'Standard', key: 0 },
  { name: 'Browse All', value: 'Browse All', key: 2 },
  { name: 'Mutual Recommendations', value: 'Mutual Recommendations', key: 3 }
];

export const SelectProgramComponent: FC<Props> = ({
  entityName,
  entityId,
  onSelect,
  fundingAmount,
  // incentive,
  // formInvalid,
  // draftInvalid,
  // submitIncentive,
  // canApproveDenyIncentive,
  formData,
}) => {
  const { isAccountInTier } = useSubcriptionType();
  const {
    tab,
    currentPage,
    themes,
    enterpriseRecommendations,
    proRecommendations,
    selectRecommendations,
    mutualRecommendations,
    sdgs,
  } = useAppSelector((state) => state.company);
  const { account } = useAppSelector((state) => state.app);
  const isMedium = useMediaQuery('(min-width:1900px)');

  const [loading, setLoading] = useState(false);
  const [selectedThemes, setSelectedThemes] = useState<string[]>([]);
  const [selectedSios, setSelectedSios] = useState<string[]>([]);
  const [selectedSDGS, setSelectedSDGS] = useState<string[]>([]);
  const [SDGIds, setSDGIds] = useState<string[]>([]);
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sioOptions, setSioOptions] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [selectedOptions, setSelectedOptions] = useState(options);

  const dispatch = useAppDispatch();

  // const nFormatter = (number: number) => {
  //   if (number === 0) {
  //     return '0';
  //   }
  //   if (Math.floor(number / 100) === 0) {
  //     return number;
  //   }
  //   const numberTemp = String(Math.floor(number / 100) * 100);
  //   return `${numberTemp}+`;
  // }
  
  useEffect(() => {
    const arr: any = [];
    selectedOptions.map((option) => {
      const obj = option;
      if (obj.key === 0) {
        const programCount = proRecommendations?.filter((program) => program?.scores).filter((program: any) => program?.productTier === "pro")[0]?.totalCount;
        // obj.value = `${obj.name} for ${account?.company?.name} (${proRecommendations?.filter((program) => !program.scores)?.length! > 0 ? proRecommendations?.filter((program) => !program.scores)?.length : 0})`;
        obj.value = `${obj.name} for ${account?.company?.name} (${proRecommendations?.filter((program) => !program.scores)?.length! > 0 ? programCount : 0})`;
        arr.push(obj);
      }
      if (obj.key === 1) {
        if (isAccountInTier(ABOVE_PRO)) {
          const programCount = selectRecommendations?.filter((program) => program?.scores).filter((program: any) => program?.productTier === "select")[0]?.totalCount;
          // obj.value = `${obj.name} for ${formData.customerName} (${selectRecommendations?.filter((program) => !program.scores)?.length! > 0 ? selectRecommendations?.filter((program) => !program.scores)?.length : 0})`;
          // obj.value = `${obj.name} for ${formData.customerName} (${selectRecommendations?.filter((program) => !program.scores)?.length! > 0 ? 12 : 0})`;
          obj.value = `${obj.name} for ${formData.customerName} (${selectRecommendations?.filter((program) => !program.scores)?.length! > 0 ? programCount : 0})`;
          arr.push(obj);
        } 
         return null;
      }
      if (obj.key === 2) {
        if (isAccountInTier([
          SubscriptionType.ENTERPRISE,
          SubscriptionType.ADMIN,
          SubscriptionType.BUYWITH_ENTERPRISE,
        ])) {
          const programCount = enterpriseRecommendations?.filter((program) => program?.scores).filter((program: any) => program?.productTier === 'enterprise' && program?.recommendationType === "buyer")[0]?.totalCount;
          // obj.value = `${obj.name} (${enterpriseRecommendations?.filter((program) => !program.scores)?.length! > 0 ? enterpriseRecommendations?.filter((program) => !program.scores)?.length : 0})`;
          obj.value = `${obj.name} (${enterpriseRecommendations?.filter((program) => !program.scores)?.length! > 0 ? programCount : 0})`;
          arr.push(obj);
        } 
          return null;
      }
      if (obj.key === 3) {
        if (isAccountInTier(ABOVE_PRO)) {
          const programCount = mutualRecommendations?.filter((program) => program?.scores).filter((program: any) => program?.productTier === 'enterprise' && program?.recommendationType === "buyer_seller")[0]?.totalCount
          // obj.value = `${obj.name} for ${formData.customerName} and ${account?.company?.name} (${mutualRecommendations?.filter((program) => !program.scores)?.length! > 0 ? mutualRecommendations?.filter((program) => !program.scores)?.length : 0})`;
          obj.value = `${obj.name} for ${formData.customerName} and ${account?.company?.name} (${mutualRecommendations?.filter((program) => !program.scores)?.length! > 0 ? programCount : 0})`;
          arr.push(obj);
        } 
          return null;
      }
      return null;
    });
    setSelectedOptions(arr);
    // setSelectedOption(arr[0]);
  }, [proRecommendations, enterpriseRecommendations, selectRecommendations, mutualRecommendations,]);

  useEffect(() => {
    console.log(entityId);
    if (entityId) {
      dispatch(
        getCompanyThemesThunk({
          id: entityId,
          filter_by_sdgs: SDGIds,
          filter_by_sios: selectedSios,
        }),
      );
      dispatch(
        getCompanySDGsThunk({
          id: entityId,
          filter_by_sios: selectedSios,
          filter_by_themes: selectedThemes,
        }),
      );
    }
  }, [entityId]);

  useEffect(() => {
    const filteredWithoutScores:any = enterpriseRecommendations.filter((program) => !program.scores);
    const enterpriseScores:any = enterpriseRecommendations?.filter((program) => program?.scores).filter((program: any) => program?.productTier === 'enterprise' && program?.recommendationType === "buyer")[0]?.scores;
    const filteredArray: any = filteredWithoutScores.filter((item1:any) =>
      enterpriseScores.some((item2:any) => item1.programId === item2.programId)
    );
    // const lastIndex = enterpriseRecommendations.length! - 1;
    // const programsWithScores: any = enterpriseRecommendations[lastIndex]?.scores;
    // const programsWithoutScores = enterpriseRecommendations.filter((program) => !program.scores);
    // const filteredObjects = programsWithoutScores?.filter((obj1: any) =>
    // programsWithScores?.some((obj2: any) => obj1.programId === obj2.programId)
    // );
    if(sioOptions.length === 0){
      const sioOptionsNew: any = Array.from(
        new Set(
          (filteredArray || []).map((obj: any) => obj.sioName)
            .filter((sioName: string) => sioName !== "")
        )
      );
      setSioOptions(sioOptionsNew);
    }
  }, [enterpriseRecommendations]);

  // const getCompanyRecommendation = async (defaultPage: number, name: any) => {
  const getCompanyRecommendation = async (defaultPage: number, buyerEntityId: string, sellerEntityId: string) => {
    try {
      setLoading(true);
      await dispatch(
        getMutualCompanyRecommendationThunk({
          buyerEntityId: buyerEntityId || '',
          sellerEntityId: sellerEntityId || '',
          fundingAmount: fundingAmount || 0,
          filterByThemes: selectedThemes || [],
          filterBySios: selectedSios || [],
          filterBySDGS: SDGIds || [],
          defaultPage,
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // const getMutualCompanyRecommendation = async (defaultPage: number, buyerEntityId: string, sellerEntityId: string) => {
  //   try {
  //     setLoading(true);
  //     await dispatch(
  //       getMutualCompanyRecommendationThunk({
  //         buyerEntityId: buyerEntityId || '',
  //         sellerEntityId: sellerEntityId || '',
  //         fundingAmount: fundingAmount || 0,
  //         defaultPage,
  //       }),
  //     );
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  const getFilteredRecommendation = async (buyerEntityId: string, sellerEntityId: string, amount: number) => {
    try {
      setLoading(true);
      await dispatch(
        getFilteredRecommendationThunk({
          buyerEntityId: buyerEntityId || '',
          sellerEntityId: sellerEntityId || '',
          fundingAmount: amount,
          filterBySios: selectedSios,
          filterByThemes: selectedThemes,
          filterBySDGS: SDGIds,
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if(entityId){
  //     const buyerEntityId: string = '5c1415685b03bb0008c21b1c';
  //     const sellerEntityId: string = '5d5adec0ce900f392534913e';
  //     const defaultPage = isAccountInTier(ABOVE_PRO)
  //       ? RecommedationTabs.TopTwelve
  //       : RecommedationTabs.Standard;
  //     getMutualCompanyRecommendation(defaultPage, buyerEntityId, sellerEntityId);
  //   }
  // }, [fundingAmount]);

  useEffect(() => {
    const buyerEntityId: string = entityId!;
    const sellerEntityId: string = account?.company?.id!;
    if (entityName && fundingAmount) {
      // const name: any = entityName;
      const defaultPage = isAccountInTier(ABOVE_PRO)
        ? RecommedationTabs.TopTwelve
        : RecommedationTabs.Standard;
      // getCompanyRecommendation(defaultPage, name);
      getCompanyRecommendation(defaultPage, buyerEntityId, sellerEntityId);
    }
  }, [entityName, fundingAmount]);
  useEffect(() => {
    if (entityName && fundingAmount) {
      const buyerEntityId: string = entityId!;
      const sellerEntityId: string = account?.company?.id!;
      // const name: any = entityName;
      const amount: number = fundingAmount;
      getFilteredRecommendation(buyerEntityId, sellerEntityId, amount);
    }
  }, [selectedSios, selectedThemes, selectedSDGS]);

  useEffect(() => {
    if (isAccountInTier(ABOVE_PRO)) {
      dispatch(switchTab(RecommedationTabs.TopTwelve));
    } else {
      dispatch(switchTab(RecommedationTabs.Standard));
    }
    if(account?.subscription === SubscriptionType.SELLWITH_PRO || account?.subscription === SubscriptionType.BUYWITH_PRO){
      setSelectedOption(options[1]);
    } else {
      setSelectedOption(options[0]);
    }
  }, []);
  const { selectedProgram } = useAppSelector((state) => state.incentive);
  
  const handleTabChange = (e: React.SyntheticEvent, newValue: RecommedationTabs) => {
    dispatch(switchTab(newValue));
  };

  const setSelectedSDGIds = (SDGdata: any) => {
    const updatedSelectedSDGS = SDGdata?.map((selectedSdgName: any) => {
      const sdg = sdgs?.find((sdgItem) => sdgItem.sdgName === selectedSdgName);
      return sdg ? sdg.sdgCode : null;
    }).filter(Boolean);

    setSDGIds(updatedSelectedSDGS);
  }

  // const handleSelectedThemeChange = (e: SelectChangeEvent<string[]>) => {
  //   if (typeof e.target.value === 'string') {
  //     setSelectedThemes([e.target.value, ...selectedThemes]);
  //   } else {
  //     setSelectedThemes(e.target.value);
  //   }
  // };
  // const handleSelectedSiosChange = (
  //   event: React.ChangeEvent<{}>,
  //   value: string[],
  //   reason: AutocompleteChangeReason,
  // ) => {
  //   if (reason === 'selectOption' || reason === 'removeOption') {
  //     setSelectedSios(value);
  //   }
  // };

  // const handleSelectedSDGChange = (e: SelectChangeEvent<string[]>) => {
  //   if (typeof e.target.value === 'string') {
  //     setSelectedSDGS([e.target.value, ...selectedSDGS]);
  //   } else {
  //     setSelectedSDGS(e.target.value);
  //   }
  // };

  const getTabContent = (tabValue: RecommedationTabs) => {
    switch (tabValue) {
      case RecommedationTabs.Standard:
        return (
          <RecommendationPage
            selectedProgram={selectedProgram}
            onSelect={onSelect}
            fundingAmount={formData?.grandTotal}
            loading={loading}
          />
        );
      case RecommedationTabs.Enterprise:
        return (
          <RecommendationPage
            selectedProgram={selectedProgram}
            onSelect={onSelect}
            pagination
            fundingAmount={formData?.grandTotal}
            loading={loading}
          />
        );
      case RecommedationTabs.TopTwelve:
        return (
          <RecommendationPage
            selectedProgram={selectedProgram}
            onSelect={onSelect}
            fundingAmount={formData?.grandTotal}
            loading={loading}
          />
        );
        case RecommedationTabs.Mutual:
          return (
            <RecommendationPage
              selectedProgram={selectedProgram}
              onSelect={onSelect}
              fundingAmount={formData?.grandTotal}
              loading={loading}
            />
          );
      default:
        return null;
    }
  };

  // const handleClickAway = (e: any) => {
  //   const eventTarget = e.target as Node | null;
  //   if (eventTarget?.nodeName === 'BODY') {
  //     return true;
  //   }
  //   setAnchorEl(null);
  //   return false;
  // };

  const SelectBox = styled(Select)(() => ({
    width: '100%',
    boxShadow: 'none',
    height: '44px',
    border: `1px solid ${colors.primary}`,
    background: '#FFFFFF !important',
    color: colors.primary,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected': {
      backgroundColor: '#FFFFFF !important',
    },
    ':hover': {
        // backgroundColor: '#EDFFFA !important',
        color: `${colors.priamry} !important`,
        border: `1px solid ${colors.primary} !important`,
        boxShadow: 'none',
    },
}));

  if (!entityName || !currentPage || !fundingAmount || formData?.description === "") {
    return (
      <Box className="ProgramChoice__container" sx={{ minHeight: `${isMedium ? '77vh' : '700px'}` }}>
        <SelectProgramHeader
          // incentive={incentive}
          // formData={formData}
          // formInvalid={formInvalid}
          // draftInvalid={draftInvalid}
          // submitIncentive={submitIncentive}
          // canApproveDenyIncentive={canApproveDenyIncentive}
          showIntelligenceLink={false}
          // selectedProgram={selectedProgram}
          newValue={selectedOption?.key} 
          formData={formData}    
          />
        {/* <Box/> */}
          <img src={ClipboardImage} alt="clipboard" />
        <Typography sx={{ fontFamily: 'Inter, sans-serif', fontWeight: 400, fontSize: '18px', lineHeight: '27px', color: '#4F4F4F', textAlign: 'center'}}>Please enter above seller and buyer details to get <br /> program options.</Typography>
      </Box>
    );
  }
  return (
        <Box className="ProgramChoice__container" sx={{ minHeight: `${isMedium ? '77vh' : '700px'}` }}>
          <SelectProgramHeader
            // incentive={incentive}
            // formData={formData}
            // formInvalid={formInvalid}
            // draftInvalid={draftInvalid}
            // submitIncentive={submitIncentive}
            // canApproveDenyIncentive={canApproveDenyIncentive}
            showIntelligenceLink={Boolean(entityId && isAccountInTier(ABOVE_PRO))}
            // selectedProgram={selectedProgram}
            newValue={selectedOption?.key}
            formData={formData}
          />

          {/* new filters */}
          <Grid container alignItems="end" sx={{ padding: '0 30px 20px' }}>
            <FlexBox alignItems="center" flexWrap="wrap">
              <Box sx={{ mx: 0.5 }}>Filter By:</Box>
              <FlexBox alignItems="center" flexWrap="wrap">
                <Box sx={{ m: 0.5, width: '370px' }}>
                  <SelectBox
                    value={selectedOption?.value}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      color: colors.primary,
                      borderColor: colors.primary,
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight700,
                      backgroundColor: colors.secondary,
                      '&:before': {
                        borderColor: colors.primary,
                      },
                      '&:after': {
                        borderColor: colors.primary,
                        backgroundColor: colors.secondary,
                      },
                      '&:hover': {
                        color: colors.primary,
                        borderColor: colors.primary,
                        backgroundColor: colors.secondary,
                      },
                    }}
                  >
                    {selectedOptions?.map((option: any) => (
                      <MenuItem
                        value={option.value}
                        sx={{
                          borderBottom: '1px solid #eaecef',
                          color: option === selectedOption ? colors.primary : '',
                          boxShadow: 'none',
                          '& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected': {
                            backgroundColor: '#FFFFFF !important',
                          },
                        }}
                        onClick={(e: any) => {
                          setSelectedOption(option);
                          handleTabChange(e, option.key);
                        }}
                      >
                        {option.value}
                      </MenuItem>
                    ))}
                  </SelectBox>
                </Box>
                {selectedOption.key === 2 && (
                  <FlexBox alignItems="center" flexWrap="wrap" justifyContent="space-between">
                    <FlexBox alignItems="center" flexWrap="wrap">
                      <Box sx={{ m: 0.5 }}>
                        <ProgramPicker label="Cause Area" list={_.map(themes, 'theme')} selectedFilterValues={selectedThemes} setFilters={(causesFilters: string[]) => setSelectedThemes(causesFilters)} />
                      </Box>
                      <Box sx={{ m: 0.5 }}>
                        <ProgramPicker label="UN SDG" list={_.map(sdgs, 'sdgName')} selectedFilterValues={selectedSDGS} setFilters={(sdgsFilters: string[]) => {
                          setSelectedSDGIds(sdgsFilters);
                          setSelectedSDGS(sdgsFilters)
                        }
                          } />
                      </Box>
                      <Box sx={{ m: 0.5 }}>
                        <ProgramPicker label="SIO Name" list={sioOptions} selectedFilterValues={selectedSios} setFilters={(sioFilters: string[]) => setSelectedSios(sioFilters)} />
                      </Box>
                      </FlexBox>
                      <Button
                        sx={{
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '16px',
                          letterSpacing: '0px',
                          color: 'black',
                        }}
                        onClick={() => {
                          setSelectedThemes([]);
                          setSelectedSDGS([]);
                          setSelectedSios([]);
                        }}
                      >
                      Reset Filter
                    </Button>
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
          </Grid>

          {/* <Grid container alignItems="end" sx={{ padding: '0 30px 20px' }}>
            <Grid item lg={24} md={12} sm={12}>
              <FlexBox my={1}>
                <Tabs value={tab} onChange={handleTabChange}>
                  {isAccountInTier(ABOVE_PRO) && (
                    <Tab
                      value={RecommedationTabs.TopTwelve}
                      label={`Recommended ${selectRecommendations?.length}`}
                      {...a11yProps(RecommedationTabs.TopTwelve)}
                    />
                  )}
                  <Tab
                    value={RecommedationTabs.Standard}
                    label={`Standard ${proRecommendations?.length}`}
                    {...a11yProps(RecommedationTabs.Standard)}
                  />
                  {isAccountInTier([
                    SubscriptionType.ENTERPRISE,
                    SubscriptionType.ADMIN,
                    SubscriptionType.BUYWITH_ENTERPRISE,
                  ]) && (
                    <Tab
                      value={RecommedationTabs.Enterprise}
                      label={`Browse All ${nFormatter(enterpriseRecommendations?.length)} `}
                      {...a11yProps(RecommedationTabs.Enterprise)}
                    />
                  )}
                </Tabs>
                {tab === RecommedationTabs.Enterprise ? (
                  <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
                    <Search />
                  </Button>
                ) : null}
                <Popper
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  id={anchorEl ? 'simple-popper' : undefined}
                  placement="bottom-start"
                >
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box className="PopperContainer filters">
                      {' '}
                      <Button onClick={() => setAnchorEl(null)} className="close__button">
                        <Close />
                      </Button>
                      <Typography>Filters</Typography>
                      <FormLabel>Filter by Theme</FormLabel>
                      <Select
                        multiple
                        onChange={handleSelectedThemeChange}
                        fullWidth
                        value={selectedThemes}
                      >
                        {themes?.map((ele) => (
                          <MenuItem key={ele.id} value={ele.theme}>
                            {ele.theme}
                          </MenuItem>
                        ))}
                      </Select>{' '}
                      <FormLabel>Filter by SDG</FormLabel>
                      <Select
                        multiple
                        onChange={handleSelectedSDGChange}
                        fullWidth
                        value={selectedSDGS}
                      >
                        {sdgs?.map((ele) => (
                          <MenuItem key={ele.id} value={ele.sdgCode}>
                            {ele.sdgName}
                          </MenuItem>
                        ))}
                      </Select>{' '}
                      <FormLabel>Filter by Social Impact Organization</FormLabel>
                      <Autocomplete
                        options={sioOptions}
                        multiple
                        fullWidth
                        onChange={handleSelectedSiosChange}
                        value={selectedSios}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Search for a Social Impact Organization"
                            variant="outlined"
                            {...params}
                          />
                        )}
                      />
                    </Box>
                  </ClickAwayListener>
                </Popper>
              </FlexBox>
            </Grid>
          </Grid> */}
          {loading && <CircularProgress color="primary" size={50} />}
          {getTabContent(tab)}
        </Box>
  );
};

export const SelectProgram = React.memo(SelectProgramComponent);
