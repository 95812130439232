import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

export const mainHeadingStyles = {
  fontSize: fonts.fontSize24,
  fontWeight: fonts.fontWeight700,
  color: colors.textPrimary,
  textTransform: 'capitalize',
};

export const headingStyles = {
  fontSize: fonts.fontSize24,
  fontWeight: fonts.fontWeight600,
  color: colors.textPrimary,
};

export const subHeadingStyles = {
  fontSize: fonts.fontSize16,
  fontWeight: fonts.fontWeight600,
  color: colors.textPrimary,
};

export const hintstyles = {
  fontSize: fonts.fontSize14,
  fontWeight: fonts.fontWeight600,
  color: colors.textPrimary,
};

export const statusStyles = {
  fontSize: fonts.fontSize18,
  fontWeight: fonts.fontWeight600,
  color: colors.textPrimary,
};

export const buttonPrimary = {
  color: colors.secondary,
  fontSize: fonts.fontSize14,
  fontWeight: fonts.fontWeight700,
  ':hover': { borderColor: colors.primary },
  borderRadius: '8px',
  backgroundColor: colors.primary,
};

export const accordianHeadingsStyles = {
  fontSize: fonts.fontSize20,
  fontWeight: fonts.fontWeight600,
  color: colors.textPrimary,
};

export const accordianHeaderButtonStyles = {
  border: `1px solid ${colors.strokeClick}`,
  color: colors.primary,
  borderRadius: '5px',
};

export const alertButtonStyles = {
  color: colors.statusAlert,
  fontSize: fonts.fontSize14,
  fontWeight: fonts.fontWeight700,
  ':hover': { borderColor: colors.statusAlert },
  borderRadius: '8px',
};

export const inputFieldStyles = {
  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
    fontSize: fonts.fontSize14,
    fontWeight: fonts.fontWeight400,
    borderRadius: '5px',
  },
  '& .MuiInputBase-root': {
    height: '40px',
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: fonts.fontSize14,
  },
};

export const selectInputFieldStyles = {
  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
    fontSize: fonts.fontSize14,
    fontWeight: fonts.fontWeight400,
    borderRadius: '5px',
  },
  '& .MuiInputBase-root': {
    height: '40px',
  },
  height: '40px',
  '& .MuiSelect-select': {
    height: '80%',
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: fonts.fontSize14,
  },
};

export const inputFieldDisabledStyles = {
  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
    fontSize: fonts.fontSize14,
    fontWeight: fonts.fontWeight400,
    borderRadius: '5px',
  },
  '& .MuiInputBase-root': {
    height: '40px',
    backgroundColor: colors.backgroundPrimary,
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: fonts.fontSize14,
  },
};

export const inputFieldParaDisabledStyles = {
  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
    fontSize: fonts.fontSize14,
    fontWeight: fonts.fontWeight400,
    borderRadius: '5px',
  },
  '& .MuiInputBase-root': {
    backgroundColor: colors.backgroundPrimary,
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: fonts.fontSize14,
  },
};

export const inputFieldParaStyles = {
  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
    fontSize: fonts.fontSize14,
    fontWeight: fonts.fontWeight400,
    borderRadius: '5px',
  },
  '& .MuiInputBase-root': {
    backgroundColor: colors.secondary,
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: fonts.fontSize14,
  },
};

export const inputLink = {
  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
    fontSize: fonts.fontSize14,
    fontWeight: fonts.fntWeight400,
    borderRadius: '5px',
  },
  '& .MuiInputBase-root': {
    height: '40px',
    backgroundColor: colors.backgroundSecondary,
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: fonts.fontSize14,
  },
};

export const checkAndRadioButtonDisabledStyles = {
  border: `1px solid ${colors.strokeDefault}`,
  backgroundColor: colors.backgroundPrimary,
  color: colors.strokeDefault,
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px',
  margin: '10px',
};

export const checkAndRadioButtonMultiDisabledStyles = {
  border: `1px solid ${colors.strokeDefault}`,
  backgroundColor: colors.backgroundPrimary,
  color: colors.strokeDefault,
  borderRadius: '20px',
  display: 'inline-block',
  padding: '10px',
  margin: '10px',
  width: 'fit-centent',
  alignItems: 'center',
};

export const questionNoStyles = {
  fontSize: fonts.fontSize14,
  fontWeight: fonts.fontWeight400,
  lineHeight: '19px',
};

export const questionStyles = {
  fontSize: fonts.fontSize18,
  fontWeight: fonts.fontWeight600,
  lineHeight: '24px',
};

export const questionSubStyles = {
  fontSize: fonts.fontSize16,
  fontWeight: fonts.fontWeight500,
  lineHeight: '22px',
};

export const optionSubStyles = {
  fontSize: fonts.fontSize16,
  fontWeight: fonts.fontWeight600,
  lineHeight: '22px',
};
