import React, { useEffect } from 'react';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography, Checkbox } from '@mui/material';
import { BoldTypography, FlexBox, PaddingRoundedBox } from '../../../../shared/components';
import { SupplierInviteDataCard } from './SupplierInviteDataCard';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import {
  clearSupplierCSVState,
  clearSupplierInviteCSVDataByKey,
  setSupplierInviteCSVData,
  setSupplierInviteCSVSelect,
  toggleCheckBox
} from './supplierInviteCSVSlice';
import { isNullOrWhitespace } from '../../../../shared/utils';

export const SupplierInviteCSV = () => {
  const stateInviteSupplierCSV = useAppSelector((x) => x.inviteSupplierCSV);
  const state = useAppSelector((x) => x.inviteSupplier);
  const csvData = useAppSelector((x) => x.inviteSupplier.dataFromCSV);
  const {isChecked} = { ...stateInviteSupplierCSV.initialState2 }
  const { headers } = {
    ...state,
  };
  const csvHeaderOptions = csvData.map(header => header[0])
  const dispatch = useAppDispatch();
  const { selected } = { ...stateInviteSupplierCSV.initialState1 };

  useEffect(
    () => () => {
      dispatch(clearSupplierCSVState());
    },
    [dispatch],
  );

  const csvHeaderCheck = () => csvHeaderOptions.toLocaleString().toLowerCase().split(',').includes(headers[0].toLowerCase())

  useEffect(() => {
    if(csvHeaderCheck()){
      dispatch(setSupplierInviteCSVSelect(headers));
      headers.map((header,index)=>(
        dispatch(setSupplierInviteCSVData({ [header]: csvData[index] }))
      ))
    }
  },[])
  const onChange = (value: string, index: number) => {
    const copySelected = [...selected];
    const isAlreadySelected = copySelected.findIndex((item) => item === value);
    if (!isNullOrWhitespace(copySelected[index])) {
      dispatch(
        clearSupplierInviteCSVDataByKey(copySelected[index] as keyof typeof stateInviteSupplierCSV.initialState1),
      );
    }
    if (isAlreadySelected !== -1) {
      copySelected[isAlreadySelected] = '';
    }
    copySelected[index] = value;
    dispatch(setSupplierInviteCSVSelect([...copySelected]));
    dispatch(setSupplierInviteCSVData({ [value]: csvData[index] }));
  };

  return (
    <Box sx={{ height: 565 }}>
      <Typography sx={{ marginBottom: 1 }}>
        Select the imported data to match to the appropriate columns.
      </Typography>
      <Checkbox
      checked={isChecked}
      onChange={()=> dispatch(toggleCheckBox())}
      inputProps={{ 'aria-label': 'controlled' }}
    />Use First Row as headers
      <PaddingRoundedBox
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          backgroundColor: theme.palette.common.grey.main,
        })}
      >
        <FlexBox sx={{ alignItems: 'start', gap: 2 }}>
          <BoldTypography sx={{ flex: 1, textAlign: 'center' }}> Imported Data</BoldTypography>
          <BoldTypography sx={{ flex: 1, textAlign: 'center' }}> Mapped</BoldTypography>
        </FlexBox>
        {csvData.map((item, index) => (
          <FlexBox
            sx={{ alignItems: 'start', gap: 2 }}
            // eslint-disable-next-line react/no-array-index-key
            key={`mapped-Date-${item[0] ?? 'mappedData'}-${index}`}
          >
            <Box sx={{ flex: 1 }}>
              <SupplierInviteDataCard data={item} />
            </Box>
            <FlexBox sx={{ flex: 1, alignItems: 'center', gap: 3 }}>
              <Typography>Mapped to: </Typography>
              <FormControl sx={{ flex: 1 }}>
                <Select
                  labelId="filter-select-label"
                  id="filter-select"
                  variant="standard"
                  value={!csvHeaderCheck() ?  selected[index] : headers[index]}
                  onChange={(e: SelectChangeEvent) => onChange(e.target.value, index)}
                >
                    <MenuItem value="firstName">First Name</MenuItem>
                    <MenuItem value="lastName">Last Name</MenuItem>
                    <MenuItem value="company">Company</MenuItem>
                    <MenuItem value="industry">Industry</MenuItem>
                    <MenuItem value="username">Email</MenuItem>
                </Select>
              </FormControl>
            </FlexBox>
          </FlexBox>
        ))}
      </PaddingRoundedBox>
    </Box>
  );
};
