import React, { useState, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  SelectChangeEvent,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../shared/hooks';
import { FormValues } from '../../shared/interfaces/sio/sio';
import { updateSioUserThunk } from './sioSlice';
import './Sio.scss';
import { uploadFile } from '../../api/portal/sioRequest';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface EditingState {
  question1: boolean;
  question2: boolean;
  question3: boolean;
  question4: boolean;
  question5: boolean;
  question6: false;
  question7: false;
  question8: false;
}
const OverViewAndMissionReview = ({ SioData }: { SioData: FormValues }) => {
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState<EditingState>({
    question1: false,
    question2: false,
    question3: false,
    question4: false,
    question5: false,
    question6: false,
    question7: false,
    question8: false,
  });
  const menuOptions = [
    {
      label: 'Arts and Culture',
      value: 'Arts and Culture',
    },
    {
      label: 'Disaster Response, Relief and Recovery',
      value: 'Disaster Response, Relief and Recovery',
    },
    {
      label: 'Economic Empowerment and Workforce Development',
      value: 'Economic Empowerment and Workforce Development',
    },
    {
      label: 'Education',
      value: 'Education',
    },
    {
      label: 'Environment',
      value: 'Environment',
    },
    {
      label: 'Equity, Human Rights, and Civic Engagement',
      value: 'Equity, Human Rights, and Civic Engagement',
    },
    {
      label: 'Food and Hunger',
      value: 'Food and Hunger',
    },
    {
      label: 'Health and Wellness',
      value: 'Health and Wellness',
    },
    {
      label: 'Housing and Homelessness',
      value: 'Housing and Homelessness',
    },
    {
      label: 'Restorative Justice or Criminal Justice',
      value: 'Restorative Justice or Criminal Justice',
    },
  ];
  const { id, password } = useParams();
  const [files, setFiles] = useState<File>();
  const [formValues, setFormValues] = useState(SioData);
  const handleEditClick = (questionNumber: keyof EditingState) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [questionNumber]: !prevState[questionNumber],
    }));
  };
  const selectOptions = menuOptions.map((option) => (
    <MenuItem value={option.value}>{option.label}</MenuItem>
  ));
  const uploadFileData = async () => {
    const formData = new FormData();
    if (!files) return;
    formData.append('file', files);
    formData.append('path', 'overviewAndMission.researchAndEvaluationFile');
    if (id && password) {
      const response = await uploadFile(id, formData);
      if (response.data) {
        setFormValues({
          ...formValues,
          fileUpload: response.data.overviewAndMission.researchAndEvaluationFile.name,
        });
        toast.success('Upload Successfull');
      } else {
        toast.error('Something went wrong');
      }
    }
  };
  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setFiles(fileList[0]);
  };

  const handleSaveClick = async (questionNumber: keyof EditingState) => {
    const payload = {
      general: {
        name: {
          legalOrganizationName: formValues.legalName,
          publicOrganizationName: formValues.publicName,
        },
        social: {
          facebook: formValues.facebook,
          instagram: formValues.instagram,
          linkedIn: formValues.linkedIn,
          twitter: formValues.twitter,
          websiteURL: formValues.websiteUrl,
        },
        contact: {
          professionalTitle: formValues.professionalTitle,
          name: formValues.firstLastName,
          phone: formValues.professionalPhoneNo,
          email: formValues.email,
        },
        location: {
          generalLocation: formValues.registeredLocation,
          specificLocation: formValues.registeredLocation,
        },
        missionAgreement: formValues.committed,
      },
      overviewAndMission: {
        historyDescription: formValues.missionHistory,
        problemDescription: formValues.problem,
        causeAreas: {
          selected: formValues.causeAreas.selected,
        },
        lifetimeOutputs: formValues.lifetimeOutputs,
        researchAndEvaluationFile: {
          name: formValues.fileUpload,
        },
        initiativesDescription: formValues.programInitiativesPriorites,
        programLocations: formValues.programOperate,
        researchAndEvaluation: formValues.researchEvaluation,
      },
      operationalInformation: {
        staff: {
          fullTime: formValues.fullTimeStaff,
          partTime: formValues.partTimeStaff,
          volunteers: formValues.volunteers,
        },
        financialStatement: {
          file: {
            name: formValues.financialStatement,
          },
        },
        partnershipsDescription: formValues.formalInformalRelationship,
        yearlyBudget: formValues.budget,
        supportersAndPartners: formValues.supportersPartners,
      },
    };
    if (id && password) {
      const response = await dispatch(updateSioUserThunk({ sio: payload, id }));
      if (response && response.payload) {
        toast.success('Updated Successfully');
      } else {
        toast.error('Failed to Updated, Please try again');
      }
    }
    setIsEditing((prevState) => ({
      ...prevState,
      [questionNumber]: false,
    }));
    setFormValues(formValues);
  };

  const handleLifetimeOutputChange =
    (field: string, index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (!isEditing.question8) return;

      const newLifetimeOutputs = [...formValues.lifetimeOutputs];
      newLifetimeOutputs[index] = { ...newLifetimeOutputs[index], [field]: value };

      setFormValues((prevValues) => ({
        ...prevValues,
        lifetimeOutputs: newLifetimeOutputs,
      }));
    };

  const handleCancelClick = (questionNumber: keyof EditingState) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [questionNumber]: false,
    }));
    setFormValues(SioData);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleChange = (event: SelectChangeEvent<typeof formValues.causeAreas.selected>) => {
    const { value } = event.target;
    setFormValues({
      ...formValues,
      causeAreas: {
        selected: typeof value === 'string' ? value.split(',') : value,
      },
    });
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      marginLeft: '0.8em',
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      marginLeft: '1em',
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
      marginLeft: '1em',
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      sx={{ display: 'flex', gap: '2.5rem', marginTop: '2rem', padding: '1rem' }}
      className="formContainer"
    >
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 1 of 8</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Briefly describe your organization’s history and mission.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question1')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Description of Mission and History <sup style={commonTextStyle.sup}>*</sup>{' '}
              <Tooltip title="Enter The Description of Mission and History">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              name="missionHistory"
              value={formValues.missionHistory}
              onChange={handleInputChange}
              disabled={!isEditing.question1}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          {isEditing.question1 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question1')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question1')}
                disabled={formValues.missionHistory === ''}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 2 of 8</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Briefly describe the need or problem your organization works to address.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question2')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Description of Need or Problem and audience <sup style={commonTextStyle.sup}>*</sup>{' '}
              <Tooltip title="Enter The Description of Need or Problem and audience">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              name="problem"
              value={formValues.problem}
              onChange={handleInputChange}
              disabled={!isEditing.question2}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          {isEditing.question2 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question2')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question2')}
                disabled={formValues.problem === ''}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 3 of 8</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Which cause area best matches your organization’s mission?
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question3')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Please select at least one, and up to two from the list.{' '}
              <sup style={commonTextStyle.sup}>*</sup>{' '}
              <Tooltip title="Enter The cause area best matches your organization’s mission">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <FormControl fullWidth margin="normal">
              <Select
                labelId="causeArea"
                placeholder="Select causeArea"
                variant="outlined"
                name="selected"
                value={
                  formValues.causeAreas.selected ? formValues.causeAreas.selected.slice(0, 2) : []
                }
                onChange={handleChange}
                disabled={!isEditing.question3}
                sx={{
                  ...commonTextStyle.textFieldStyle,
                  '& .MuiSelect-select': {
                    fontSize: fonts.fontSize14,
                    paddingTop: '18px !important',
                    height: '100%',
                  },
                }}
                multiple
              >
                <MenuItem selected value="" disabled>
                  <em>ScaleWith Cause Area</em>
                </MenuItem>
                {selectOptions}
              </Select>
            </FormControl>
          </Grid>
          {isEditing.question3 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question3')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question3')}
                disabled={formValues.causeAreas.selected.length === 0}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 4 of 8</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Briefly describe your organization’s programs, initiatives, and current priorities.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question4')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Description of Program, Initiative and Priorities{' '}
              <sup style={commonTextStyle.sup}>*</sup>{' '}
              <Tooltip title="Enter The Description of Program, Initiative and Priorities">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              name="programInitiativesPriorites"
              value={formValues.programInitiativesPriorites}
              onChange={handleInputChange}
              disabled={!isEditing.question4}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          {isEditing.question4 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question4')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question4')}
                disabled={formValues.programInitiativesPriorites === ''}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 5 of 8</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>Where do your programs operate?</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question5')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Description of where Program Operate <sup style={commonTextStyle.sup}>*</sup>{' '}
              <Tooltip title="Enter The Description of where Program Operate">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              name="programOperate"
              value={formValues.programOperate}
              onChange={handleInputChange}
              disabled={!isEditing.question5}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          {isEditing.question5 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question5')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question5')}
                disabled={formValues.programOperate === ''}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 6 of 8</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Briefly describe the research and evaluation used to support your program design.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question6')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Describe Research and Evaluation <sup style={commonTextStyle.sup}>*</sup>{' '}
              <Tooltip title="Enter The Research and Evaluation">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              name="researchEvaluation"
              value={formValues.researchEvaluation}
              onChange={handleInputChange}
              disabled={!isEditing.question6}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          {isEditing.question6 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question6')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question6')}
                disabled={formValues.researchEvaluation === ''}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 7 of 8</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Please upload the research or evaluation that informs your program design.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question7')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Upload the file{' '}
              <Tooltip title="Upload the research or evaluation file">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              type="file"
              name="fileUpload"
              onChange={handleUpload}
              disabled={!isEditing.question7}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={uploadFileData}
                    className="uploadButton"
                    disabled={!isEditing.question7}
                    variant="outlined"
                    color="primary"
                  >
                    Reupload
                  </Button>
                ),
              }}
              fullWidth
            />
            <Typography sx={{ my: 2 }} variant="body1">
              File Name: {formValues.fileUpload}
            </Typography>
          </Grid>
          {isEditing.question7 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question7')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question7')}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 8 of 8</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              What are some of your organization’s lifetime outputs?
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question8')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          {formValues.lifetimeOutputs.map((lifetimeOutput, index) => (
            <React.Fragment key={lifetimeOutput.key}>
              <Grid item className="gridItem">
                <Typography sx={commonTextStyle.labelStyle}>
                  Quantity {index}
                  <Tooltip title="Enter The organization’s lifetime outputs">
                    <InfoIcon className="tooltipIcon" />
                  </Tooltip>
                </Typography>
                <TextField
                  name="quantity"
                  type="number"
                  value={lifetimeOutput.quantity}
                  onChange={handleLifetimeOutputChange('quantity', index)}
                  disabled={!isEditing.question8}
                  variant="outlined"
                  sx={commonTextStyle.textFieldStyle}
                  fullWidth
                />
              </Grid>
              <Grid item className="gridItem">
                <Typography sx={commonTextStyle.labelStyle}>
                  Output {index} description
                  <Tooltip title="Enter The organization’s lifetime outputs">
                    <InfoIcon className="tooltipIcon" />
                  </Tooltip>
                </Typography>
                <TextField
                  name="output"
                  type="text"
                  value={lifetimeOutput.output}
                  onChange={handleLifetimeOutputChange('output', index)}
                  disabled={!isEditing.question8}
                  variant="outlined"
                  sx={commonTextStyle.textFieldStyle}
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          ))}
          {isEditing.question8 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question8')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question8')}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default OverViewAndMissionReview;
