import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addCancelTokenEvent, deleteLocale, getLocales, patchLocale } from '../../api';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';
import { GridModel, GridRequest, GridResponse, Locale } from '../../shared/interfaces';

interface LocalesGridRequest extends GridRequest {
  active: boolean | null;
}

interface LocalesState {
  grid: GridModel<Locale>;
  active: boolean;
  success: boolean;
  toastMessage: string;
}

const initialState: LocalesState = {
  grid: {
    name: 'locales',
    data: { totalCount: 0, results: [] },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'id', direction: 'asc' },
  },
  active: true,
  success: false,
  toastMessage: '',
};

export const getLocalesThunk = createAsyncThunk<GridResponse<Locale>, LocalesGridRequest>(
  'locales/getLocalesThunk',
  async (request: LocalesGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getLocales(request);
    return data;
  },
);

export const deleteLocaleThunk = createAsyncThunk<Locale, string>(
  'locales/deleteLocaleThunk',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await deleteLocale(id);
    return data;
  },
);

export const patchLocaleThunk = createAsyncThunk<Locale, string>(
  'locales/patchLocaleThunk',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await patchLocale(id);
    return data;
  },
);

const localesSlice = createSlice({
  name: 'locales',
  initialState,
  reducers: {
    setLocalesGridModel: (state, action: PayloadAction<Partial<GridModel<Locale>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearLocalesState: (state) => {
      _.assign(state, initialState);
    },
    setLocalesActive: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocalesThunk.fulfilled, (state, action: PayloadAction<GridResponse<Locale>>) => {
        state.grid.loadingGrid = false;
        state.grid.data = action.payload;
      })
      .addCase(getLocalesThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getLocalesThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(deleteLocaleThunk.fulfilled, (state) => {
        state.success = true;
        state.grid.loadingGrid = false;
        state.toastMessage = 'Locale deactivated';
      })
      .addCase(deleteLocaleThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(deleteLocaleThunk.pending, (state) => {
        state.success = false;
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
        state.toastMessage = '';
      })
      .addCase(patchLocaleThunk.fulfilled, (state) => {
        state.success = true;
        state.grid.loadingGrid = false;
        state.toastMessage = 'Locale activated';
      })
      .addCase(patchLocaleThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(patchLocaleThunk.pending, (state) => {
        state.success = false;
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
        state.toastMessage = '';
      });
  },
});

export const { setLocalesGridModel, clearLocalesState, setLocalesActive } = localesSlice.actions;

export const localesReducer = localesSlice.reducer;
