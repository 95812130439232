import React, { FC, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  TextField,
  Checkbox,
  Button,
  InputAdornment,
  IconButton,
  Link,
  CircularProgress,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '../../shared/constants';
import backgroundImage from '../../assets/svg/bg.svg';
import { Footer } from '../main-screen/Footer';
import '../sio/Sio.scss';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

interface LoginFormProps {
    onLogin?: (password: string) => void;
    loading?: boolean;
}

const ShareLogin: FC<LoginFormProps> = ({ onLogin, loading }) => {
  const [password, setPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    setPassword(inputElement.value);
  };
  const handleTermsAcceptedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    setTermsAccepted(inputElement.checked);
  };

  const handleLogin = async () => {
    if (onLogin) onLogin(password);
  };

  return (
    <Grid container columns={{ md: 12 }}>
        <Grid
          xs={4}
          sx={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            alignContent: 'center',
            color: '#FFFFFF',
          }}
        >
          <Grid
      sx={{
        display: 'grid',
        gap: '40px',
      }}
    >
      <Typography
        sx={{
          font: 'inter',
          fontWeight: '700',
          fontSize: '50px',
          color: '#FFFFFF',
          lineHeight: '72.61px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        Welcome to <br /> ScaleWith
      </Typography>
    </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={8}
          sx={{
            position: 'relative',
            backgroundColor: '#fff',
          }}
        >
          <Box mb={4} className="LoginLogo">
            <img src="https://static.scalewith.com/assets/v1/img/png/logo-scalewith-lct-120.8b82fd9357.png" alt="scaleWith logo" className="Logo" style={{ paddingTop: '0px', paddingBottom: '0px'}} />
          </Box>
          <Box my={4} className="loginHeader">
            <Typography sx={{ fontSize: `${fonts.fontSize18} !important`, fontWeight: `${fonts.fontWeight700} !important`, display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
              Please enter the password provided in the email to log in.
            </Typography>
            <Box mt={2}>
              <Typography variant="body1" component="label" htmlFor="password">
                Password
              </Typography>
              <TextField
                id="password"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                placeholder="Enter the Password"
                value={password}
                onChange={handlePasswordChange}
                className='password-input'
                inputProps={{
                  className: 'proposal-password-input',
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon className="lockIcon" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPasswordToggle} className="eyeIcon">
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>      
            <Grid item xs={12}>
                {loading && <CircularProgress />}
            </Grid>
            <Box
              mt={5}
              sx={{ marginRight: '14rem', display: 'flex', gap: '1rem', marginTop: '4rem', alignItems: 'center' }}
              className="terms-checkbox"
            >
              <Checkbox
                checked={termsAccepted}
                onChange={handleTermsAcceptedChange}
                className='checkbox'
                inputProps={{
                  className: "proposal-checkbox",
                }}
              />
              <Typography
                sx={{ fontWeight: '700', color: 'black', textAlign: 'center', marginBottom: 0 }}
                paragraph
              >
                {'I agree with the '}
                <Link
                  href={TERMS_OF_USE_URL}
                  rel="noopener noreferrer"
                  title="Terms & Conditions"
                  target="_blank"
                  aria-label="Terms & Conditions"
                >
                  Terms & Conditions
                </Link>
                {' and '}
                <Link
                  href={PRIVACY_POLICY_URL}
                  rel="noopener noreferrer"
                  title="privacy policy"
                  target="_blank"
                  aria-label="privacy policy"
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
            <Box mt={4} className="button">
              <Button
                sx={{
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fontWeight900,
                  backgroundColor: colors.primary,
                }}
                variant="contained"
                size="large"
                color="success"
                fullWidth
                disabled={!termsAccepted}
                onClick={handleLogin}
                className="loginButton"
              >
                Login
              </Button>
            </Box>
            <Box mt={4} sx={{ marginRight: '90px'}} className="email">
              <Link className="mailtoLink" href="mailto:assist@scaleWith.com">
                <EmailOutlinedIcon className="emailIcon" />
                <Typography variant="body2" component="span" className="emailText">
                  assist@scalewith.com
                </Typography>
              </Link>
            </Box>
            <Box sx={{ position: 'absolute', bottom: 0, right: 0, width: '100%', maxWidth: 'inherit',border: '1px solid #D8D8D8', flexWrap: 'wrap' }}>
          <Footer />
          </Box>
          </Box>
          </Grid>
    </Grid>
  );
};

export default ShareLogin;
