import { combineReducers } from '@reduxjs/toolkit';
import {
  registerReducer,
  registerDetailsReducer,
  myProfileReducer,
  mainScreenReducer,
  inviteUserReducer,
  usersReducer,
  localesReducer,
  localeSettingsReducer,
  causeAreasReducer,
  causeAreasMetricsReducer,
  causeAreaMetricsDialogReducer,
  instancesReducer,
  instanceSettingsReducer,
  instanceSettingsCauseAreaReducer,
  editUserReducer,
  selectCauseReducer,
  contributionReducer,
  accountsReducer,
  selectAmountReducer,
  accountSettingsReducer,
  switchAccountReducer,
  accountTransactionsReducer,
  resetUserPasswordReducer,
  resendNotificationReducer,
  errorLogsReducer,
  viewErrorLogReducer,
  causeAreaSettingsReducer,
  suppliersReducer,
  inviteSuppliersReducer,
  viewSupplierReducer,
  procurementReducer,
  supplierInviteCSVReducer,
  procurementMetricsReducer,
} from '../features';
import { appReducer } from './appSlice';
import {
  commonContributionsReducer,
  updateContributionStatusReducer,
  contactUsReducer,
  confirmDialogReducer,
  informativeDialogReducer,
} from '../shared/components';
import { sioReducer } from '../features/sio/sioSlice';
import { programReducer } from '../features/program/ProgramSlice';
import { completionReducer } from '../features/completion/CompletionSlice';
import { companyEntitiesReducer } from '../features/company-intelligence/companiesSlice';
import { siosReducer } from '../features/social-impact-organization/sioSlice';
import { programsReducer } from '../features/programs/programSlice';
import { fundingFormReducer } from '../features/program-fundig-form/fundingSlice';
import { fundingReducer } from '../features/funding/fundingSlice';

export const rootReducer = combineReducers({
  app: appReducer,
  register: registerReducer,
  registerDetails: registerDetailsReducer,
  main: mainScreenReducer,
  myProfile: myProfileReducer,
  inviteUser: inviteUserReducer,
  users: usersReducer,
  editUser: editUserReducer,
  selectCause: selectCauseReducer,
  selectAmount: selectAmountReducer,
  incentive: contributionReducer,
  accounts: accountsReducer,
  accountSettings: accountSettingsReducer,
  accountTransactions: accountTransactionsReducer,
  commonContributions: commonContributionsReducer,
  switchAccount: switchAccountReducer,
  updateContributionStatus: updateContributionStatusReducer,
  contactUs: contactUsReducer,
  resetUserPassword: resetUserPasswordReducer,
  resendNotification: resendNotificationReducer,
  confirmDialog: confirmDialogReducer,
  informativeDialog: informativeDialogReducer,
  locales: localesReducer,
  localeSettings: localeSettingsReducer,
  instances: instancesReducer,
  instanceSettings: instanceSettingsReducer,
  causeAreas: causeAreasReducer,
  causeAreaSettings: causeAreaSettingsReducer,
  errorsLog: errorLogsReducer,
  viewErrorLog: viewErrorLogReducer,
  instanceSettingsCauseArea: instanceSettingsCauseAreaReducer,
  causeAreasMetrics: causeAreasMetricsReducer,
  causeAreaMetricsDialog: causeAreaMetricsDialogReducer,
  supplier: suppliersReducer,
  inviteSupplier: inviteSuppliersReducer,
  inviteSupplierCSV: supplierInviteCSVReducer,
  viewSupplier: viewSupplierReducer,
  procurement: procurementReducer,
  procurementMetrics: procurementMetricsReducer,
  sios: sioReducer,
  programs: programReducer,
  company: companyEntitiesReducer,
  sio: siosReducer,
  program: programsReducer,
  funding: fundingFormReducer,
  completion: completionReducer,
  fundings: fundingReducer,
});
