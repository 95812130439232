interface TenantAccessGrant {
  id: string;
  createdBy: string;
  createdAt: {
    $date: string;
  };
  lastUpdatedBy: string;
  lastUpdated: {
    $date: string;
  };
  default: boolean;
  friendlyName: string;
  roles: string[];
  instanceId: {
    $oid: string;
  };
  app: string;
  type: string;
}

export const findActiveTenantId = (
  tenantId: string | null | undefined,
  tenantAccessGrants: TenantAccessGrant[] | null | undefined,
): string | null => {
  let activeTenantId: string | null = null;

  if (tenantId && tenantAccessGrants) {
    const matchingTenant = tenantAccessGrants.find(
      (tenantAccessGrant) => String(tenantAccessGrant.id) === String(tenantId),
    );

    if (matchingTenant) {
      activeTenantId = matchingTenant.id;
    } else {
      const defaultTenant = tenantAccessGrants.find(
        (tenantAccessGrant) => tenantAccessGrant.default === true,
      );

      if (defaultTenant) {
        activeTenantId = defaultTenant.id;
      }
    }
  }

  return activeTenantId;
};

export const setCookie = (name: string, value: string | null) => {
  if (window.LOCALHOST) {
    console.log('window.ENV_TYPE', window.ENV_TYPE);
  } else {
    console.log('window.DOMAIN', window.DOMAIN);
  }
  const cookieDomain = window.LOCALHOST ? `${window.ENV_TYPE}.localhost` : `.${window.DOMAIN}`;
  const secureFlag = window.location.protocol === 'https:' ? 'Secure' : '';
  const cookieString = `${name}=${value}; domain=${cookieDomain}; path=/; SameSite=None; ${secureFlag}`;
  document.cookie = cookieString;
};
