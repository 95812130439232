import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import {} from '@mui/icons-material';
import { FlexBox, FormDialog } from '../../../shared/components';
import { Dialogs, SupplierInviteScreenStatus } from '../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  clearInviteSuppliersState,
  getSupplierInviteIndustriesGroupedThunk,
  postSupplierInviteThunk,
  setSupplierInviteList,
  setSupplierInviteScreenStatus,
} from './supplierInviteEntryDialog';
import { SupplierEntryForm } from './supplierInviteEntryDialog/SupplierEntryForm';
import { SupplierInviteCSV } from './SupplierInviteCSV/SupplierInviteCSV';
import { SupplierLoading } from './SupplierInviteCSV';
import { emailValid, isNullOrWhitespace } from '../../../shared/utils';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

export const InviteSupplier = () => {
  const state = useAppSelector((x) => x.inviteSupplier);
  const stateCSV = useAppSelector((x) => x.inviteSupplierCSV);
  const { listSuppliers, loading, success, screenStatus, industriesGrouped } = { ...state };
  const { company, firstName, industry, lastName, username, selected } = { ...stateCSV.initialState1 };
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(clearInviteSuppliersState());
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getSupplierInviteIndustriesGroupedThunk());
  }, [dispatch]);

  const addSupplierToList = () => {
    const itemSelected = selected.filter((item) => item !== undefined);
    const keyToMap = itemSelected[0];
    if (!itemSelected || !keyToMap) return;
    const getValidArray = stateCSV.initialState1[keyToMap as keyof typeof stateCSV.initialState1];
    const suppliersToAdd = getValidArray.map((value: string | undefined, index) => {
      let errorMessagesClient: Array<string> = [];
      if (isNullOrWhitespace(username[index]))
        errorMessagesClient = [...errorMessagesClient, 'Email is required'];
      if (!emailValid(username[index] ?? ''))
        errorMessagesClient = [...errorMessagesClient, 'Email is not valid'];
      if (isNullOrWhitespace(firstName[index]))
        errorMessagesClient = [...errorMessagesClient, 'First Name is required'];
      if (isNullOrWhitespace(lastName[index]))
        errorMessagesClient = [...errorMessagesClient, 'Last Name is required'];
      if (isNullOrWhitespace(company[index]))
        errorMessagesClient = [...errorMessagesClient, 'Company is required'];
      if (
        isNullOrWhitespace(industry[index]) ||
        !industriesGrouped.find(
          (item) => item.label.toLowerCase() === industry[index]?.toLowerCase(),
        )
      )
        errorMessagesClient = [...errorMessagesClient, 'Industry not found'];
      const getIndustry = industriesGrouped.find(
        (item) => item.label?.toLowerCase() === industry[index]?.toLowerCase(),
      );
      return {
        value: {
          account: {
            industry: {
              industry: getIndustry?.group ?? '',
              subIndustry: getIndustry?.value ?? '',
            },
            company: {
              name: company[index] ?? '',
            },
          },
          user: {
            username: username[index] ?? '',
            firstName: firstName[index] ?? '',
            lastName: lastName[index] ?? '',
          },
        },
        errorMessagesClient,
      };
    });

    const payload = [...listSuppliers, ...suppliersToAdd];

    dispatch(setSupplierInviteList(payload));
    dispatch(setSupplierInviteScreenStatus(SupplierInviteScreenStatus.Form));
  };

  const isValidDataToForm = () =>
    (company.length > 0 && selected.find((item) => item === 'company')) ||
    (firstName.length > 0 && selected.find((item) => item === 'firstName')) ||
    (industry.length > 0 && selected.find((item) => item === 'industry')) ||
    (lastName.length > 0 && selected.find((item) => item === 'lastName')) ||
    (username.length > 0 && selected.find((item) => item === 'username'));

  const isInviteInvalid = () => listSuppliers.length <= 0;
  const isInviteInvalidByCSV = () =>
    listSuppliers.some(
      (item) => item.errorMessagesClient !== undefined && item.errorMessagesClient.length > 0,
    );

  const onHandleInvite = () => {
    dispatch(postSupplierInviteThunk());
  };

  const COMPONENTS = {
    Form: <SupplierEntryForm />,
    Loading: <SupplierLoading />,
    UploadedCSV: <SupplierInviteCSV />,
  };

  const BUTTONS = {
    Form: (
      <Button
        variant="contained"
        disabled={isInviteInvalid() || isInviteInvalidByCSV()}
        onClick={onHandleInvite}
        size="large"
        sx={{ backgroundColor: colors.primary, color: colors.secondary, fontWeight: fonts.fontWeight700 }}
      >
        Invite
      </Button>
    ),
    Loading: undefined,
    UploadedCSV: (
      <FlexBox sx={{ gap: 1 }}>
        <Button
          color="info"
          size="large"
          onClick={() => dispatch(setSupplierInviteScreenStatus(SupplierInviteScreenStatus.Form))}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!isValidDataToForm()}
          onClick={addSupplierToList}
          size="large"
        >
          Continue
        </Button>
      </FlexBox>
    ),
  };

  return (
    <FormDialog
      title="Invite Supplier"
      type={Dialogs.InviteSupplier}
      hideCancel={screenStatus === SupplierInviteScreenStatus.UploadedCSV}
      loading={loading}
      closeFlag={success}
      Buttons={BUTTONS[screenStatus]}
    >
      {COMPONENTS[screenStatus]}
    </FormDialog>
  );
};
