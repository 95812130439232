import _ from 'lodash';
import { ContributionActions, ContributionStatus } from '../../shared/enums';
import {
  Contribution,
  GridRequest,
  GridResponse,
  AccountContributionTotals,
  StripeCheckoutResponse,
} from '../../shared/interfaces';
import {
  httpSecuredCommon,
  cancelToken,
  httpCommon,
  httpIncetiveProposalVerify,
} from '../httpCommon';

const base = '/impact-orders';

export const CONTRIBUTION_API_ROUTES = {
  postContribution: base,
  getContributions: base,
  getApprovalContributions: `${base}/approvals`,
  putContribution: (id: string) => `${base}/${id}`,
  deleteContribution: (id: string) => `${base}/${id}`,
  deleteContributionUnshare: (id: string) => `${base}/${id}/share`,
  patchContribution: (id: string) => `${base}/${id}`,
  postContributionShare: (id: string) => `${base}/${id}/share`,
  getContribution: (id: string) => `${base}/${id}`,
  getContributionProposal: (id: string) => `${base}/${id}/proposal`,
  postContributionProposal: (id: string, password: string) =>
    `${base}/${id}/proposal?password=${password}`,
  getContributionTotals: `${base}/totals`,
  getMultipleContributions: `${base}/multi_select`,
  putResendNotification: (id: string) => `${base}/${id}/resend-notification`,
  contributionProposalVerify: (id: string) => `${base}/${id}/verify_proposal`,
  getProposals: `${base}/proposal/list`,
  updatePaymentMethod: (orderId: string) => `${base}/${orderId}/payment`,
  postContributionStatus: (id: string) => `${base}/${id}/received-funds`
};

export const postContribution = async (contribution: Contribution) => {
  const response = await httpSecuredCommon.post<Contribution>(
    CONTRIBUTION_API_ROUTES.postContribution,
    contribution,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getContributions = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<Contribution>>(
    CONTRIBUTION_API_ROUTES.getContributions,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const getProposals = async (request: any) => {
  const response = await httpSecuredCommon.get(CONTRIBUTION_API_ROUTES.getProposals, {
    cancelToken: cancelToken.token,
    params: request,
  });
  return response;
};

export const getApprovalContributions = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<Contribution>>(
    CONTRIBUTION_API_ROUTES.getApprovalContributions,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const putContribution = async (contribution: Contribution) => {
  const payload = _.omit(contribution, 'id');
  const response = await httpSecuredCommon.put(
    CONTRIBUTION_API_ROUTES.putContribution(contribution.id!),
    payload,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const deleteContribution = async (id: string) => {
  const response = await httpSecuredCommon.delete(CONTRIBUTION_API_ROUTES.deleteContribution(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const patchContribution = async (
  id: string,
  status: ContributionActions | ContributionStatus,
  comments: Array<{ comment?: string }>,
) => {
  const response = await httpSecuredCommon.patch<Contribution>(
    CONTRIBUTION_API_ROUTES.patchContribution(id),
    { status, comments },
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const postContributionStatus = async (
  id: string,
  status: ContributionActions | ContributionStatus,
  comments: Array<{ comment?: string }>,
) => {
  const response = await httpSecuredCommon.post<Contribution>(
    CONTRIBUTION_API_ROUTES.postContributionStatus(id),
    {status, comments},
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const postContributionShare = async (id: string) => {
  const response = await httpSecuredCommon.post<Contribution>(
    CONTRIBUTION_API_ROUTES.postContributionShare(id),
    {},
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getContribution = async (request: { contributionId: string; accountId?: string }) => {
  const { contributionId, accountId } = request;
  if (!accountId) {
    const response = await httpSecuredCommon.get<Contribution>(
      CONTRIBUTION_API_ROUTES.getContribution(contributionId),
      {
        cancelToken: cancelToken.token,
      },
    );
    return response;
  }
  const response = await httpSecuredCommon.get<Contribution>(
    CONTRIBUTION_API_ROUTES.getContribution(contributionId),
    {
      cancelToken: cancelToken.token,
      params: {
        accountId,
      },
    },
  );
  return response;
};

export const getMultipleContributions = async (request: { ids: Array<string> }) => {
  const { ids } = request;
  const response = await httpSecuredCommon.post(
    CONTRIBUTION_API_ROUTES.getMultipleContributions,
    { ids },
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getContributionTotals = async (accountId: string) => {
  const response = await httpSecuredCommon.get<AccountContributionTotals>(
    CONTRIBUTION_API_ROUTES.getContributionTotals,
    {
      cancelToken: cancelToken.token,
      params: { accountId },
    },
  );
  return response;
};

export const putResendNotification = async (
  contributionId: string,
  emailAddresses: Array<string>,
) => {
  const response = await httpSecuredCommon.put(
    CONTRIBUTION_API_ROUTES.putResendNotification(contributionId),
    {
      emailAddresses,
    },
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const deleteContributionUnshare = async (id: string) => {
  const response = await httpSecuredCommon.delete(
    CONTRIBUTION_API_ROUTES.deleteContributionUnshare(id),
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getContributionProposal = async (id: string) => {
  const response = await httpCommon.get(CONTRIBUTION_API_ROUTES.getContributionProposal(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postContributionProposal = async (id: string, password: string) => {
  const response = await httpCommon.post<Contribution>(
    CONTRIBUTION_API_ROUTES.postContributionProposal(id, password),
    {},
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const postPaymentMethod = async (
  id: string,
  status: ContributionActions | ContributionStatus,
  comments: Array<{ comment?: string }>,
) => {
  const response = await httpSecuredCommon.post<Contribution>(
    CONTRIBUTION_API_ROUTES.updatePaymentMethod(id),
    { status, comments, paymentMethod: 'invoice' },
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const postContributionCheckoutSession = async (orderIds: any, request: any) => {
  const response = await httpSecuredCommon.post<StripeCheckoutResponse>(
    CONTRIBUTION_API_ROUTES.updatePaymentMethod(orderIds[0]),
    request,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const contributionProposalVerify = async (id: string, password: string) => {
  const response = await httpIncetiveProposalVerify(password).get<Contribution>(
    CONTRIBUTION_API_ROUTES.contributionProposalVerify(id),
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};
