import React from 'react';
import {
  Slide,
  Typography,
  Paper,
  Button,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface DetailsViewProps {
  isOpen: boolean;
  onClose: () => void;
  rowData: any;
}

const DetailsView: React.FC<DetailsViewProps> = ({ isOpen, onClose, rowData }) => {
  const details = [
    {
      heading: 'User Name',
      key: 'username',
    },
    {
      heading: 'Ledger Type',
      key: 'ledger_detail_type',
    },
    {
      heading: 'Ledger Source',
      key: 'ledger_source',
    },
  ];
  const stripeDetails = [
    {
      heading: 'Invoice Id',
      key: 'invoiceId',
    },
    {
      heading: 'Payment Intent Id',
      key: 'paymentIntentId',
    },
    {
      heading: 'Subscription Id',
      key: 'subscriptionId',
    },
  ];
  const sageDetails = [
    {
      heading: 'Invoice Id',
      key: 'invoiceId',
    },
    {
      heading: 'Invoice Record No.',
      key: 'invoiceRecordNo',
    },
  ];

  return (
    <Slide direction="left" in={isOpen} mountOnEnter unmountOnExit>
      <Paper
        style={{
          position: 'fixed',
          top: '34%',
          right: '2%',
          transform: 'translateY(-50%)',
          width: 'auto',
          padding: '16px',
          boxShadow:
            // '0 35px 68px 0 rgba(255,255,255,0.12), inset 0 -8px 16px 0 #f8f7f4',
            '-2px 1px 5px 5px rgba(0,0,0,0.12), 0px -1px 1px 1px rgba(0,0,0,0.12), 0px 1px 3px 0px rgba(0,0,0,0.12)',
          borderRadius: '10px',
        }}
      >
        <Typography sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }}>Details:</Typography>
        <TableContainer>
          <Table sx={{ minWidth: 400 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {details.map((header: any) => (
                  <TableCell sx={{ fontWeight: 600 }}>{header?.heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {details.map((detail: any) => (
                  <TableCell>{rowData?.[detail?.key]}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {rowData && rowData?.stripe && Object.keys(rowData?.stripe).length > 0 && (
          <>
            <Typography sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }}>Stripe:</Typography>
            <TableContainer>
              <Table sx={{ minWidth: 400 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {stripeDetails.map((header: any) => (
                      <TableCell sx={{ fontWeight: 600 }}>{header?.heading}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {stripeDetails.map((detail: any) => (
                      <TableCell>
                        {rowData?.stripe?.[detail?.key] ? rowData?.stripe?.[detail?.key] : 'NA'}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {rowData && rowData?.sage && Object.keys(rowData?.sage).length > 0 && (
          <>
            <Typography sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }}>Sage:</Typography>
            <TableContainer>
              <Table sx={{ minWidth: 400 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {sageDetails.map((header: any) => (
                      <TableCell>{header?.heading}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {sageDetails.map((detail: any) => (
                      <TableCell>
                        {rowData?.sage?.[detail?.key] ? rowData?.sage?.[detail?.key] : 'NA'}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            sx={{ marginTop: '5px', backgroundColor: colors.primary, color: colors.secondary }}
            variant="contained"
            size="large"
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </Paper>
    </Slide>
  );
};

export default DetailsView;
