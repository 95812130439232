import { Typography } from '@mui/material';
import React, { FC } from 'react';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface Props {
  causeAreaThemes?: string;
  index?: number;
  isAccordion?: boolean;
}

export const CauseAreaChip: FC<Props> = ({ causeAreaThemes, index, isAccordion }) => (
  <>
    <Typography
      sx={{
        fontWeight: fonts.fontWeight700,
        fontSize: fonts.fontSize14,
        color: colors.textPrimary,
        marginTop: '8px',
      }}
    >
      {index === 0 && !isAccordion && 'Cause Areas:'}
    </Typography>
    <Typography>
      <span
        style={{
          color: colors.textPrimary,
          marginLeft: `${isAccordion ? '3px' : '0px'}`,
          fontWeight: fonts.fontWeight400,
          fontSize: fonts.fontSize14,
          borderRight: `2px solid ${colors.strokeDefault}`,
          padding: '0px 14px',
          display: 'flex',
          minWidth: 'auto',
          maxWidth: '250px',
          marginBottom: '10px',
          height: '30px',
          alignItems: 'center',
        }}
      >
        {causeAreaThemes}
      </span>
    </Typography>
  </>
);
