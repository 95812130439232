import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';
import {
  CommonContributionsGrid,
  deleteContributionUnshareThunk,
  FlexBox,
  RoundButton,
  UpdateContributionStatusButton,
} from '../../shared/components';
import { RoleRequired } from '../../app/Security';

import {
  ContributionStatus,
  GridColumns,
  ContributionStatusLabel,
  UserRole,
  AccountType,
  ContributionActions,
  ConfirmDialogType,
} from '../../shared/enums';
import { useAccount, useAppDispatch, useAppSelector, useAuth } from '../../shared/hooks';
import { APPROVE_DENY_PERMISSIONS, ENABLE_APPROVE_DENY } from '../../shared/constants';
import {
  isAvailableShareByStatus,
  isNullOrWhitespace,
  openConfirmDialog,
  updateMultipleGives,
} from '../../shared/utils';
import borders from '../../assets/scss/strokes.module.scss';

export const Approvals = () => {
  const selectedStatus = useAppSelector((x) => x.commonContributions.status);
  const gridState = useAppSelector((x) => x.commonContributions.grid);
  const success = useAppSelector((state) => state.updateContributionStatus.success);
  const { isAccountInType } = useAccount();
  const [isUserInRole] = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const stateConfirmDialog = useAppSelector((x) => x.confirmDialog);
  const dispatch = useAppDispatch();
  const { value, confirmed, type } = stateConfirmDialog;

  useEffect(() => {
    if (!confirmed || type !== ConfirmDialogType.UnshareContribution || isNullOrWhitespace(value))
      return;
    dispatch(deleteContributionUnshareThunk(value!));
  }, [value, confirmed, type, dispatch]);

  const isAvailableShareByRole =
    isAccountInType([AccountType.supplier]) &&
    isUserInRole([UserRole.Finance, UserRole.SuperAdmin]);

  const isSelectedTabShare = selectedStatus === ContributionActions.shared;

  const isAvailableShare =
    (isAvailableShareByStatus(selectedStatus as ContributionStatus) || isSelectedTabShare) &&
    isAvailableShareByRole;

  const minWidth =
    (selectedStatus === ContributionStatus.PendingApproval &&
      isUserInRole(APPROVE_DENY_PERMISSIONS)) ||
    isAvailableShare
      ? 310
      : 220;

  const unshareContribution = (id: string) => {
    openConfirmDialog(
      {
        title: 'Unshare Social Impact Contribution',
        text: 'Are you sure you would like to unshare this Social Impact Contribution?',
        type: ConfirmDialogType.UnshareContribution,
        confirmText: 'Unshare',
        value: id,
      },
      'sm',
    );
  };

  const tabs = [
    { label: 'Pending Approval', value: ContributionStatus.PendingApproval },
    { label: 'Approved', value: ContributionStatus.Approved  },
    { label: 'Denied', value: ContributionStatus.Denied},
    ...(isAvailableShareByRole ? [{ label: 'Shared', value: ContributionActions.shared }] : []),
  ];


  const actionsColumn: GridColDef = {
    field: 'action',
    headerName: 'Actions',
    disableReorder: true,
    resizable: false,
    type: GridColumns.Actions,
    minWidth,
    renderCell: ({ row: { id, status, sharedWith } }) => (
      <FlexBox sx={{ gap: 1 }}>
        <Button
          endIcon={<ArrowForwardIcon />}
          variant="outlined"
          sx={{
            backgroundColor: colors.secondary,
            fontSize: fonts.fontSize14,
            fontWeight: fonts.fontWeight700,
            color: colors.primary,
            borderRadius: borders.borderRadius8,
            borderColor: colors.primary,
            '&:focus': colors.primary,
            '&:hover': colors.primary,
          }}
          onClick={() => {
            navigate(
              `/app/contribution/${id}/${
                status !== ContributionStatus.Draft ? 'details' : 'draft'
              }`,
              {
                state: {
                  selectedStatusTab: selectedStatus,
                  previousPage: pathname,
                },
              },
            );
          }}
        >
          View Details
        </Button>
        {status === ContributionStatus.PendingApproval && !isSelectedTabShare && (
          <RoleRequired roles={APPROVE_DENY_PERMISSIONS}>
            <UpdateContributionStatusButton
              proposedStatus={ContributionActions.Approved}
              action={ContributionStatusLabel.Approve}
              id={id}
              disabled={!isUserInRole(ENABLE_APPROVE_DENY)}
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.statusSuccess,
                borderRadius: borders.borderRadius8,
                borderColor: colors.statusSuccess,
                '&:focus': colors.statusSuccess,
                '&:hover': colors.statusSuccess,
              }}
            />
          </RoleRequired>
        )}
        {isAvailableShareByStatus(status as ContributionStatus) &&
          isAvailableShareByRole &&
          (!sharedWith?.id ? (
            <UpdateContributionStatusButton
              proposedStatus={ContributionActions.shared}
              action={ContributionStatusLabel.share}
              id={id}
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.statusWarning,
                borderRadius: borders.borderRadius8,
                borderColor: colors.statusWarning,
                '&:focus': colors.statusWarning,
                '&:hover': colors.statusWarning,
              }}
            />
          ) : (
            <Button
              variant="outlined"
              // color="error"
              // sx={{ backgroundColor: '#fff' }}
              onClick={() => unshareContribution(id)}
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.statusAlert,
                borderRadius: borders.borderRadius8,
                borderColor: colors.statusAlert,
                '&:focus': colors.statusAlert,
                '&:hover': colors.statusAlert,
              }}
            >
              Unshare
            </Button>
          ))}

        {status === ContributionStatus.PendingApproval && !isSelectedTabShare && (
          <RoleRequired roles={APPROVE_DENY_PERMISSIONS}>
            <UpdateContributionStatusButton
              proposedStatus={ContributionActions.Denied}
              action={ContributionStatusLabel.Deny}
              id={id}
              disabled={!isUserInRole(ENABLE_APPROVE_DENY)}
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.statusAlert,
                borderRadius: borders.borderRadius8,
                borderColor: colors.statusAlert,
                '&:focus': colors.statusAlert,
                '&:hover': colors.statusAlert,
              }}
            />
          </RoleRequired>
        )}
      </FlexBox>
    ),
    sortable: false,
    filterable: false,
    hideable: false,
    align: 'left',
    headerAlign: 'left',
  };

  return (
    <Box className="dashboard" sx={{ pb: '30px' }}>
    <FlexBox sx={{ gap: 1 }}>
        <Typography sx={{ flex: 1, color:colors.textPrimary,fontSize: fonts.fontSize24, fontWeight: 700 }}>
          Approvals
        </Typography>
        {isAvailableShare && !isSelectedTabShare && (
          <RoundButton
            disabled={gridState?.selectionModel?.length === 0}
            onClick={() => updateMultipleGives(gridState, ContributionActions.shared)}
          >
            Share Social Impact Contribution
          </RoundButton>
        )}
      </FlexBox>

      <CommonContributionsGrid
        tabs={tabs}
        defaultTab={ContributionStatus.PendingApproval}
        alternativeName="approvals"
        actionsColumn={actionsColumn}
        success={success}
        isApprovalsPage
      />
    </Box>
  );
};
