export enum UserRole {
  SuperAdmin = 'givewith',
  OrgAdmin = 'org_admin',
  UserAdmin = 'user_admin',
  Finance = 'finance',
  Sales = 'sales',
  Approver = 'approver',
  Transactor = 'transactor',
  Executive = 'executive',
  GivewithOperations = 'gw_operations',
  GivewithCSM = 'gw_csm',
  GivewithFinance = 'gw_finance',
  SIAdmin = 'sw_si_admin',
}

export enum SioUserRole {
  OrgAdmin = 'org_admin',
  UserAdmin = 'user_admin',
}
