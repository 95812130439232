import React, { useEffect, useState, ChangeEvent } from 'react'; // useState
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'; // Typography, Button
import {
  Grid,
  Button,
  TextField,
  FormControl,
  Select,
  Divider,
  MenuItem,
  Tooltip,
  Card,
  CardContent,
  Typography,
  CardHeader,
  SelectChangeEvent,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import { fetchSioUser, updateSioUserThunk } from './sioSlice';
import './formFilling.scss';
import { TopBar } from './SioFormFillingDashboard';
import { uploadFile } from '../../api/portal/sioRequest';
// import { uploadFile } from '../../api/portal/sioRequest';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#68839A',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#FFF',
  },
}));

const OperationalQuestions = () => {
  const sioState = useAppSelector((state) => state.sios);
  const { sio } = sioState;
  const { id, password } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const initialFormValue = {
    legalName: sio.general?.name?.legalOrganizationName,
    publicName: sio.general?.name?.publicOrganizationName,
    websiteURL: sio.general?.social?.websiteURL,
    facebook: sio.general?.social?.facebook,
    linkedIn: sio.general?.social?.linkedIn,
    instagram: sio.general?.social?.instagram,
    twitter: sio.general?.social?.twitter,
    phoneNumber: sio.general?.contact?.phone,
    firstLastName: sio.general?.contact?.name,
    professionalTitle: sio.general?.contact?.professionalTitle,
    email: sio.general?.contact?.email,
    professionalPhoneNo: sio.general?.contact?.phone,
    registeredLocation: sio.general?.location?.specificLocation,
    committed: sio.general?.missionAgreement,
    missionHistory: sio.overviewAndMission?.historyDescription,
    problem: sio.overviewAndMission?.problemDescription,
    causeAreas: { selected: sio.overviewAndMission?.causeAreas?.selected },
    programInitiativesPriorites: sio.overviewAndMission?.initiativesDescription,
    programOperate: sio.overviewAndMission?.programLocations,
    researchEvaluation: sio.overviewAndMission?.researchAndEvaluation,
    fileUpload: sio.overviewAndMission?.researchAndEvaluationFile?.name,
    lifetimeOutputs: sio.overviewAndMission?.lifetimeOutputs ?? [],
    fullTimeStaff: sio.operationalInformation?.staff.fullTime,
    partTimeStaff: sio.operationalInformation?.staff.partTime,
    volunteers: sio.operationalInformation?.staff.volunteers,
    formalInformalRelationship: sio.operationalInformation?.partnershipsDescription,
    budget: sio.operationalInformation?.yearlyBudget,
    yearlyBudgetCurrency: sio.operationalInformation?.yearlyBudgetCurrency,
    financialStatement: sio.operationalInformation?.financialStatement?.file?.name,
    supportersPartners: sio.operationalInformation?.supportersAndPartners,
    reviewName: '',
    reviewEmail: '',
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [files, setFiles] = useState<File>();
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  // const savePageNumber = () => {
  //     if([0,1].includes(sio.progress.operationalInformation.complete)){
  //         setPageNumber(1)
  //     }else{
  //         setPageNumber(sio.progress.operationalInformation.complete)
  //     }
  // }

  useEffect(() => {
    if (id && password && sio) {
      dispatch(fetchSioUser({ id })).then((sioData: any) => {
        setFormValues({
          legalName: sioData.payload.general?.name?.legalOrganizationName ?? '',
          publicName: sioData.payload.general?.name?.publicOrganizationName ?? '',
          websiteURL: sioData.payload.general?.social?.websiteURL ?? '',
          facebook: sioData.payload.general?.social?.facebook ?? '',
          linkedIn: sioData.payload.general?.social?.linkedIn ?? '',
          instagram: sioData.payload.general?.social?.instagram ?? '',
          twitter: sioData.payload.general?.social?.twitter ?? '',
          phoneNumber: sioData.payload.general?.contact?.phone ?? '',
          firstLastName: sioData.payload.general?.contact?.name ?? '',
          professionalTitle: sioData.payload.general?.contact?.professionalTitle ?? '',
          email: sioData.payload.general?.contact?.email ?? '',
          professionalPhoneNo: sioData.payload.general?.contact?.phone ?? '',
          registeredLocation: sioData.payload.general?.location?.specificLocation ?? '',
          committed: sioData.payload.general?.missionAgreement,
          missionHistory: sioData.payload.overviewAndMission?.historyDescription,
          problem: sioData.payload.overviewAndMission?.problemDescription,
          causeAreas: { selected: sioData.payload.overviewAndMission?.causeAreas?.selected },
          programInitiativesPriorites: sioData.payload.overviewAndMission?.initiativesDescription,
          programOperate: sioData.payload.overviewAndMission?.programLocations,
          researchEvaluation: sioData.payload.overviewAndMission?.researchAndEvaluation,
          fileUpload: sioData.payload.overviewAndMission?.researchAndEvaluationFile?.name,
          lifetimeOutputs: sioData.payload.overviewAndMission?.lifetimeOutputs ?? [],
          fullTimeStaff: sioData.payload.operationalInformation?.staff.fullTime,
          partTimeStaff: sioData.payload.operationalInformation?.staff.partTime,
          volunteers: sioData.payload.operationalInformation?.staff.volunteers,
          formalInformalRelationship:
            sioData.payload.operationalInformation?.partnershipsDescription,
          budget: sioData.payload.operationalInformation?.yearlyBudget,
          yearlyBudgetCurrency: sioData.payload.operationalInformation?.yearlyBudgetCurrency ?? '',
          financialStatement: sioData.payload.operationalInformation?.financialStatement?.file.name,
          supportersPartners: sioData.payload.operationalInformation?.supportersAndPartners,
          reviewName: '',
          reviewEmail: '',
        });
        // savePageNumber()
      });
    }
  }, [id, password]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'fullTimeStaff' || name === 'partTimeStaff' || name === 'volunteers') {
      if (Number(value) >= 0) {
        setFormValues({ ...formValues, [name]: value });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const updateSioDetails = async () => {
    const payload = {
      operationalInformation: {
        staff: {
          fullTime: formValues.fullTimeStaff,
          partTime: formValues.partTimeStaff,
          volunteers: formValues.volunteers,
        },
        financialStatement: {
          file: {
            name: formValues.financialStatement,
          },
        },
        partnershipsDescription: formValues.formalInformalRelationship,
        yearlyBudget: formValues.budget,
        yearlyBudgetCurrency: formValues.yearlyBudgetCurrency,
        supportersAndPartners: formValues.supportersPartners,
      },
    };
    if (!id || !password) {
      return false;
    }

    if (id && password) {
      try {
        const result = await dispatch(updateSioUserThunk({ sio: payload, id }));
        return result.meta.requestStatus;
      } catch (error) {
        console.error('Update failed with exception:', error);
        return false;
      }
    }
    return false;
  };

  const uploadFileData = async () => {
    const formData = new FormData();
    if (!files) return;
    formData.append('file', files);
    formData.append('path', 'operationalInformation.financialStatement.file');
    if (id && password) {
      const response = await uploadFile(id, formData);
      if (response.data) {
        setFormValues({
          ...formValues,
          financialStatement: response.data.operationalInformation.financialStatement.file.name,
        });
        toast.success('Upload Successfull');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const handleNextbutton = async () => {
    try {
      await uploadFileData();
      const updateResult = await updateSioDetails();

      switch (updateResult) {
        case 'fulfilled':
          toast.success('Successfully Submitted Form to ScaleWith');
          if (pageNumber < 5) {
            setPageNumber(pageNumber + 1);
          } else {
            navigate(`/sio-form/${id}/${password}`);
          }
          break;
        case 'rejected':
          toast.error('Failed to update values');
          break;
        default:
          toast.error(updateResult || 'An unexpected error occurred');
      }
    } catch (error) {
      console.error('Error handling next button:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };
  const handlePreviousbutton = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleSaveExit = async () => {
    try {
      const response = await updateSioDetails();
      switch (response) {
        case 'fulfilled':
          toast.success('Successfully Submitted Form to ScaleWith');
          navigate(`/sio-form/${id}/${password}`);
          break;
        case 'rejected':
          toast.error('Failed to update values');
          break;
        default:
          toast.error(response || 'An unexpected error occurred');
      }
    } catch (error) {
      console.error('Error in handleSaveExit:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };

  const disabledButtons = () => {
    let disable;
    if (pageNumber === 1) {
      if (!formValues.fullTimeStaff || !formValues.partTimeStaff || !formValues.volunteers)
        disable = true;
    }
    return disable;
  };
  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setFiles(fileList[0]);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target as HTMLInputElement;
    if (value !== 'Other') {
      setIsOtherSelected(false);
      setFormValues({ ...formValues, [name]: value });
    } else {
      setFormValues({ ...formValues, [name]: '' });
      setIsOtherSelected(true);
    }
  };

  const handleOtherCurrency = (event: any) => {
    setFormValues({ ...formValues, yearlyBudgetCurrency: event.target.value });
  };

  const currency = ['default', 'USD', 'EUR', 'GBP', 'Other'];

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  // *** Start Questions ***
  const question1 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 5 }} variant="h3">
          Let&apos;s get started!
        </Typography>
        <Typography sx={commonTextStyle.questionStyle}>Question 1 of 5</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Please provide the number of paid and volunteer staff at your organization
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ ...commonTextStyle.labelStyle }}>
          Full-time
          <Tooltip title="Enter number of full time employees">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="fullTimeStaff"
          placeholder="Enter number of full time employees"
          fullWidth
          type="number"
          margin="normal"
          value={formValues.fullTimeStaff}
          onChange={handleChange}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ ...commonTextStyle.labelStyle }}>
          Part-Time
          <Tooltip title="Enter number of part time employees">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="partTimeStaff"
          placeholder="Enter number of part time employees"
          fullWidth
          type="number"
          value={formValues.partTimeStaff}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ ...commonTextStyle.labelStyle }}>
          Volunteer
          <Tooltip title="Enter number of volunteer employees">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="volunteers"
          placeholder="Enter number of volunteer employees"
          fullWidth
          value={formValues.volunteers}
          type="number"
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
    </Grid>
  );

  const question2 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 2 of 5</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Briefly describe any formal and informal partnerships with other nonprofits or government
          entities
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ ...commonTextStyle.labelStyle }}>
          Formal and Informal Relationship{' '}
          <Tooltip title="Enter partnerships">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="formalInformalRelationship"
          placeholder="Enter formal and informal relationships"
          fullWidth
          value={formValues.formalInformalRelationship}
          onChange={handleChange}
          multiline
          rows={4}
          margin="none"
          variant="outlined"
          sx={commonTextStyle.textAreaStyle}
        />
      </Grid>
    </Grid>
  );

  const question3 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 3 of 5</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          What is your organization&apos;s total budget for the current year?
        </Typography>
      </Grid>
      <Grid item xs={12} sm={isOtherSelected ? 4 : 6}>
        <Typography sx={{ ...commonTextStyle.labelStyle }}>
          Currency{' '}
          <Tooltip title="Enter currency">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <FormControl fullWidth variant="outlined">
          <Select
            name="yearlyBudgetCurrency"
            onChange={handleSelectChange}
            value={
              isOtherSelected ||
              (formValues?.yearlyBudgetCurrency !== '' &&
                !currency?.includes(formValues?.yearlyBudgetCurrency))
                ? 'Other'
                : formValues.yearlyBudgetCurrency ?? 'default'
            }
            sx={{
              ...commonTextStyle.textFieldStyle,
              '& .MuiSelect-select': {
                fontSize: fonts.fontSize14,
                paddingTop: '28px',
                height: '100%',
              },
            }}
          >
            {currency.map((currencyItem: any) => (
              <MenuItem value={currencyItem === 'default' ? '' : currencyItem} key={currencyItem}>
                {currencyItem === 'default' ? 'Select Currency' : currencyItem}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {(isOtherSelected ||
        (formValues?.yearlyBudgetCurrency !== '' &&
          !currency?.includes(formValues?.yearlyBudgetCurrency))) && (
        <Grid item xs={12} sm={4}>
          <Typography sx={{ ...commonTextStyle.labelStyle }}>
            Mention Other{' '}
            <Tooltip title="Enter Other Currency">
              <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
            </Tooltip>
          </Typography>
          <TextField
            name="otherCurrency"
            placeholder="Enter Other Currency"
            type="text"
            fullWidth
            onChange={handleOtherCurrency}
            value={formValues.yearlyBudgetCurrency}
            variant="outlined"
            sx={commonTextStyle.textAreaStyle}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={isOtherSelected ? 4 : 6}>
        <Typography sx={{ ...commonTextStyle.labelStyle }}>
          Budget{' '}
          <Tooltip title="Enter Budget">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="budget"
          placeholder="Enter Budget"
          type="number"
          fullWidth
          onChange={handleChange}
          value={formValues.budget}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
    </Grid>
  );

  const question4 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 4 of 5</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Please provide your most recent financial statement
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ ...commonTextStyle.labelStyle }}>
          Financial Statement File
          <Tooltip title="Enter partnerships">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="financialStatement"
          placeholder="Upload Financial Statements"
          fullWidth
          // value={formValues.financialStatement}
          onChange={handleUpload}
          type="file"
          margin="none"
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      {formValues.financialStatement && (
        <Grid item xs={12}>
          <Typography sx={commonTextStyle.heading}>
            File Name: {formValues.financialStatement}
          </Typography>
        </Grid>
      )}
    </Grid>
  );

  const question5 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 5 of 5</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 5 }}>
          Please list your foundation and corporate supporters and partners from this or the most
          recent fiscal year
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ ...commonTextStyle.labelStyle }}>
          Supporters and Partners
          <Tooltip title="Enter supporters and partners">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="supportersPartners"
          placeholder="Enter supporters and partners"
          fullWidth
          value={formValues.supportersPartners}
          onChange={handleChange}
          multiline
          rows={4}
          margin="none"
          variant="outlined"
          sx={commonTextStyle.textAreaStyle}
        />
      </Grid>
    </Grid>
  );
  // *** End Questions ***

  return (
    <section className="bgLight">
      <TopBar />
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardHeader
              sx={{
                padding: '20px 80px',
                background: colors.backgroundColorGradient,
                borderRadius: '20px 20px 0px 0px',
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize18,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Section 3
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize24,
                        fontWeight: fonts.fontWeight700,
                        color: colors.secondary,
                      }}
                    >
                      Operational Information
                    </Typography>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <Typography
                        sx={{
                          color: colors.secondary,
                          fontSize: fonts.fontSize18,
                          fontWeight: fonts.fontWeight600,
                        }}
                      >
                        {pageNumber === 1 ? 0 : (pageNumber - 1) * 20}% complete
                      </Typography>
                    </Grid>
                    <BorderLinearProgress
                      variant="determinate"
                      value={pageNumber === 1 ? 1 : (pageNumber - 1) * 20}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent sx={{ minHeight: '70vh', padding: '40px 80px' }}>
              {pageNumber === 1 && question1}
              {pageNumber === 2 && question2}
              {pageNumber === 3 && question3}
              {pageNumber === 4 && question4}
              {pageNumber === 5 && question5}
              <Grid item xs={12} textAlign="right">
                {' '}
                {/* className={pageNumber===1 ? "":'bottomFix'} */}
                <Divider sx={{ my: 3 }} orientation="horizontal" flexItem />
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  disabled={disabledButtons()}
                  onClick={handleSaveExit}
                  variant="outlined"
                  size="large"
                >
                  Save and Exit
                </Button>
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  disabled={pageNumber === 1}
                  onClick={handlePreviousbutton}
                  variant="outlined"
                  size="large"
                >
                  Previous
                </Button>
                <Button
                  sx={{
                    ...commonTextStyle.buttonStyle,
                    backgroundColor: colors.primary,
                    color: colors.secondary,
                    mx: 2,
                  }}
                  disabled={disabledButtons()}
                  onClick={handleNextbutton}
                  variant="contained"
                  size="large"
                >
                  Next
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default OperationalQuestions;
