import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deleteProgram,
  getProgram,
  getProgrambyUser,
  getProgramList,
  getProgramStatus,
  patchProgrambyAdmin,
  postProgram,
  postProgrambyUser,
  updateProgrambyAdmin,
  updateProgrambyUser,
  downloadFile,
} from '../../api/portal/programRequest';
import { addCancelTokenEvent } from '../../api';
import { GridResponse, GridModel, GridRequest } from '../../shared/interfaces';
import {
  ProgramList,
  StatusCount,
  PostProgramRequest,
  Program,
} from '../../shared/interfaces/program/program';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';

interface ProgramGridRequest extends GridRequest {
  status: string | null | undefined;
  applicationFormName?: string;
  programName?: string;
  publicOrganizationName?: string;
}
interface ProgramState {
  grid: GridModel<ProgramList>;
  program: Program;
  programId: string;
  updateStatusSuccess: boolean;
  status: string | null | undefined;
  loading: boolean;
  statusCount: [StatusCount];
}

const initialState: ProgramState = {
  grid: {
    name: 'programs',
    data: { totalCount: 0, results: [] },
    page: 0,
    defaultSort: { field: 'lastUpdated', direction: 'desc' },
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
  },
  program: {
    id: '',
    applicationFormName: '',
    active: false,
    createdAt: '',
    imageLandscape: '',
    imagePortrait: '',
    previewImage: '',
    isStandard: false,
    selectedByCompanies: [],
    preferredByCompanies: {
      companies: [],
      industries: [],
    },
    previewVimeoId: '',
    deliverables: {
      longVideo: '',
      shortVideo: '',
      photoGallery: '',
      investorCommunications: '',
      pressRelease: '',
      sustainabilityReporting: '',
      employeeCommunications: '',
      programFactSheet: '',
      socialMediaPosts: '',
      csrReportHighlight: '',
      infographic: '',
      additional: '',
    },
    editing: false,
    lastUpdated: '',
    password: '',
    percentComplete: 0,
    publicOrganizationName: '',
    siAdmin: '',
    sio: '',
    status: '',
    general: {
      contact: {
        name: '',
        email: '',
        phone: '',
        countryCode: '',
      },
      programName: {
        internalProgramName: '',
        platformProgramName: '',
      },
      programDescription: {
        description: '',
        additionalDetails: '',
      },
    },
    impactAndScope: {
      causes: [],
      primaryImpact: [],
      secondaryImpacts: [],
      targetGroup: [],
      audienceAge: [],
      locationDetails: {
        regions: [],
        countries: [],
        cities: [],
        states: [],
        additionalLocationDetails: '',
        locationExplanation: {
          regions: '',
          countries: '',
          states: '',
          cities: '',
        },
      },
    },
    strategiesAndApproaches: {
      strategies: [],
      activities: {
        value: '',
      },
      activitiesFrequency: '',
      approachDuration: '',
      outputs: [],
      dataMeasurementType: '',
      nonprofitPartners: false,
      nonprofitPartnersDescription: '',
    },
    researchAndEvaluation: {
      evidenceDescription: {
        optional: '',
        selected: [],
      },
      studyDescription: {
        optional: '',
        selected: [],
      },
      dataDescription: {
        optional: '',
        selected: [],
      },
      environmentalOutputs: {
        selected: [],
      },
      researchApproach: [],
      researchFile: {
        name: '',
      },
      outcomeDescription: [],
      strength: '',
      environmentalOutputValues: {
        quantity: 0,
        description: '',
      },
    },
    finance: {
      budget: 0,
      budgetFile: {
        name: '',
        url: '',
      },
      cashContributions: 0,
      inKindContributions: 0,
      currency: '',
    },
    review: {},
    content: {
      filmLocation: '',
      assets: {
        name: '',
      },
    },
    progress: {
      general: {
        total: 0,
        complete: 0,
      },
      impactAndScope: {
        total: 0,
        complete: 0,
      },
      strategiesAndApproaches: {
        total: 0,
        complete: 0,
      },
      researchAndEvaluation: {
        total: 0,
        complete: 0,
      },
      finance: {
        total: 0,
        complete: 0,
      },
      content: {
        total: 0,
        complete: 0,
      },
    },
  },
  programId: '',
  updateStatusSuccess: false,
  status: null,
  statusCount: [
    {
      status_list: {
        _id: '',
        count: 0,
      },
    },
  ],
  loading: false,
};

export const fetchProgramListThunk = createAsyncThunk(
  'programs/fetchProgramList',
  async (request: ProgramGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getProgramList(request);
    return data;
  },
);

export const downloadFileThunk = createAsyncThunk(
  'programs/downloadFile',
  async (request: any, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await downloadFile(request);
    return data;
  },
);

export const fetchProgram = createAsyncThunk(
  'programs/getProgramThunk',
  async (id: string, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getProgram(id);
    return data;
  },
);

export const removeProgram = createAsyncThunk('programs/removeProgram', async (id: string) => {
  const { data } = await deleteProgram(id);
  return data;
});

export const fetchProgrambyUser = createAsyncThunk(
  'programs/getProgrambyUserThunk',
  async (id: string, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getProgrambyUser(id);
    return data;
  },
);

export const updateProgrambyUserThunk = createAsyncThunk(
  'program/updateProgrambyUser',
  async ({ program, id }: { program: any; id: string }) => {
    const response = await updateProgrambyUser(program, id);
    return response.data;
  },
);

export const updateProgrambyAdminThunk = createAsyncThunk(
  'program/updateProgrambyAdmin',
  async ({ program, id }: { program: any; id: string }) => {
    const response = await updateProgrambyAdmin(program, id);
    return response.data;
  },
);

export const patchProgrambyAdminThunk = createAsyncThunk(
  'program/patchProgrambyAdmin',
  async ({ program, id }: { program: any; id: string }) => {
    const response = await patchProgrambyAdmin(program, id);
    return response.data;
  },
);

export const postProgrambyUserThunk = createAsyncThunk(
  'program/postProgrambyUser',
  async ({ program, id }: { program: any; id: string }) => {
    const response = await postProgrambyUser(program, id);
    return response.data;
  },
);

export const fetchProgramStatus = createAsyncThunk(
  'programs/getprogramStatusThunk',
  async (unused, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getProgramStatus();
    return data;
  },
);

export const addProgram = createAsyncThunk(
  'programs/addProgram',
  async (program: PostProgramRequest) => {
    const response = await postProgram(program);
    return response.data;
  },
);

export const programSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    setProgramsGridModel: (state, action: PayloadAction<Partial<GridModel<ProgramList>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearProgramsState: (state) => {
      state.grid = initialState.grid;
      state.updateStatusSuccess = false;
    },
    clearProgramsUpdateSuccess: (state) => {
      state.updateStatusSuccess = false;
    },
    setProgramsStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setViewProgramId: (state, action: PayloadAction<string>) => {
      state.programId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchProgramListThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<ProgramList>>) => {
          // state.allData = action.payload.results
          state.grid.loadingGrid = false;
          state.grid.data = action.payload;
        },
      )
      .addCase(fetchProgramListThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchProgramListThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(fetchProgramStatus.fulfilled, (state, action) => {
        state.statusCount = action.payload;
      })
      .addCase(fetchProgramStatus.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchProgramStatus.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(addProgram.fulfilled, (state, action) => {
        state.program = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(addProgram.rejected, (state, action) => {
        state.grid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(addProgram.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(fetchProgram.fulfilled, (state, action) => {
        state.program = action.payload;
      })
      .addCase(fetchProgram.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchProgram.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(fetchProgrambyUser.fulfilled, (state, action) => {
        state.program = action.payload;
      })
      .addCase(fetchProgrambyUser.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchProgrambyUser.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(updateProgrambyUserThunk.fulfilled, (state, action) => {
        state.program = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(updateProgrambyUserThunk.rejected, (state, action) => {
        state.grid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(updateProgrambyUserThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(postProgrambyUserThunk.fulfilled, (state, action) => {
        state.program = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(postProgrambyUserThunk.rejected, (state, action) => {
        state.grid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(postProgrambyUserThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(updateProgrambyAdminThunk.fulfilled, (state, action) => {
        state.program = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(updateProgrambyAdminThunk.rejected, (state, action) => {
        state.grid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(updateProgrambyAdminThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(patchProgrambyAdminThunk.fulfilled, (state, action) => {
        state.program = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(patchProgrambyAdminThunk.rejected, (state, action) => {
        state.grid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(patchProgrambyAdminThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      });
  },
});

export const {
  setProgramsGridModel,
  clearProgramsState,
  setProgramsStatus,
  clearProgramsUpdateSuccess,
  setViewProgramId,
} = programSlice.actions;

export const programReducer = programSlice.reducer;
