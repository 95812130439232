import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { APPROVE_DENY_PERMISSIONS } from '../../../shared/constants';
import { ContributionStatus } from '../../../shared/enums';
import { useAppDispatch, useAppSelector, useAuth } from '../../../shared/hooks';
import { getContributionThunk, getContributionApprovalThunk } from '../incentiveSlice';
import { SelectAmount } from '../select-amount';

export function Details({ draft }: { draft?: boolean }) {
  const contribution = useAppSelector((x) => x.incentive.incentive);
  const dispatch = useAppDispatch();
  const { incentiveId } = useParams();
  const [isUserInRole] = useAuth();
  const canApproveDeny = isUserInRole(APPROVE_DENY_PERMISSIONS);

  useEffect(() => {
    if (!incentiveId) return;
    dispatch(getContributionThunk(incentiveId));
  }, [incentiveId, dispatch]);
  useEffect(() => {
    if (!contribution) return;

    if (contribution.status === ContributionStatus.PendingApproval && canApproveDeny) {
      dispatch(getContributionApprovalThunk(contribution));
    }
  }, [contribution, dispatch, canApproveDeny]);

  if (draft) {
    return <SelectAmount mode="draft" />;
  }
  return <SelectAmount mode="details" />;
}
