import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import TextAreaInput from '../../../shared/components/common/textAreaInput';
import BoxSection from '../../../shared/components/common/boxSection';
import Progressed from '../../../assets/svg/progressed.svg';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface props {
  setCompletionProgramData: any;
  completion: any;
  submitted?: boolean;
  validatedQuestions: (count: number) => void;
  isSaveProgress: boolean;
  setProcess: () => void;
  submitResponse: boolean;
}

const CompletionInformation: React.FC<props> = ({
  setCompletionProgramData,
  completion,
  submitted,
  validatedQuestions,
  isSaveProgress,
  setProcess,
  submitResponse,
}) => {
  // const [answeredQuestions, setAnsweredQuestions] = useState({
  //   adjustments: '',
  //   stories: '',
  // });

  const [adjustment, setAdjustment] = useState('');
  const [storie, setStorie] = useState('');

  const [validatedAnswers, setvalidateAnswers] = useState({
    adjustments: 0,
    stories: 0,
  });

  const validateFields = (value: string) => (value === '' || value === undefined ? 0 : 1);

  // const saveAnswers = (value: string, key: string, isRequired: boolean) => {
  //   setAnsweredQuestions((prevValues: any) => ({
  //     ...prevValues,
  //     [key]: value,
  //   }));
  //   if (isRequired) {
  //     setvalidateAnswers((prevValues: any) => ({
  //       ...prevValues,
  //       [key]: validateFields(value),
  //     }));
  //   }
  // };

  const validateAnswers = (value: string, key: string, isRequired: boolean) => {
    if (isRequired) {
      setvalidateAnswers((prevValues: any) => ({
        ...prevValues,
        [key]: validateFields(value),
      }));
    }
  };

  useEffect(() => {
    validatedQuestions(Object.values(validatedAnswers)?.filter((value) => value === 1)?.length);
  }, [validatedAnswers]);

  useEffect(() => {
    if (isSaveProgress || submitResponse) {
      setCompletionProgramData(
        {
          adjustments: adjustment,
          stories: storie,
        },
        'completion',
      );
      setProcess();
    }
  }, [isSaveProgress, submitResponse]);

  useEffect(() => {
    setAdjustment(completion?.adjustments);
    setStorie(completion?.stories);
    setvalidateAnswers({
      adjustments: completion?.adjustments ? 1 : 0,
      stories: completion?.stories ? 1 : 0,
    });
  }, [completion]);

  return (
    <>
      <BoxSection>
        <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 1 of 2
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <Grid item xs={7}>
          <TextAreaInput
            title="Were there any adjustments to the program design or implementation?"
            description="Please provide a brief explanation of any changes made to the auto-populated information in this report. If no changes were made, please type N/A."
            secondaryTitle="Description"
            isTextField
            infoIcon
            tooltipText="Description"
            placeholder="Adjustments explanation..."
            onChange={(event) => {
              setAdjustment(event.target.value);
              if (event.target.value.length === 1 || event.target.value.length === 0) {
                validateAnswers(event.target.value, 'adjustments', true);
              }
            }}
            value={adjustment}
            required
            disabled={submitted}
          />
        </Grid>
      </BoxSection>
      <BoxSection>
        <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 2 of 2
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <Grid item xs={7}>
          <TextAreaInput
            title="What stories would you like to share from this program as a result of this funding?"
            description="If there are no stories you’d like to share, please type N/A."
            secondaryTitle="Description"
            isTextField
            infoIcon
            tooltipText="Description"
            placeholder="Funding stories..."
            onChange={(event) => {
              setStorie(event.target.value);
              if (event.target.value.length === 1 || event.target.value.length === 0) {
                validateAnswers(event.target.value, 'stories', true);
              }
            }}
            // onChange={(event) => {
            //   saveAnswers(event.target.value, 'stories', true);
            // }}
            value={storie}
            required
            disabled={submitted}
          />
        </Grid>
      </BoxSection>
    </>
  );
};

export default CompletionInformation;
