import Cookies from 'js-cookie';

type SameSiteKind = 'strict' | 'Strict' | 'lax' | 'Lax' | 'none' | 'None';

export const GWCookies = {
  get: (key: string) => Cookies.get(key),

  set: (key: string, value: string, expires: Date) => {
    const domain = window.DOMAIN;
    const sameSite: SameSiteKind = 'lax';

    const options = {
      path: '/',
      domain: '',
      sameSite,
      expires,
    };

    if (domain && domain !== 'localhost') options.domain = `.${domain}`;

    Cookies.set(key, value, options);
  },

  remove: (key: string) => {
    const domain = window.DOMAIN;
    const options = {
      path: '/',
      domain: '',
    };

    if (domain && domain !== 'localhost') options.domain = `.${domain}`;

    Cookies.remove(key, options);
  },
};
