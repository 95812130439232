import React, { FC } from 'react';
import { FormControl, InputLabel, Input } from '@mui/material';
import { FlexBox, LightTooltip } from './StyledComponents';
import fonts from '../../assets/scss/font.module.scss';

interface Props {
  label: string;
  value?: string;
  valueComponent?: JSX.Element;
  tooltipTitle?: string;
}

export const DisabledTextField: FC<Props> = (props) => {
  const { label, value, tooltipTitle, valueComponent } = props;

  if (valueComponent)
    return (
      <FlexBox sx={{ flexDirection: 'column', gap: 1 }}>
        <InputLabel disabled sx={{ textTransform: 'capitalize' }}>{label}</InputLabel>
        {valueComponent}
      </FlexBox>
    );

  return (
    <FormControl>
      <InputLabel disabled sx={{ textTransform: 'capitalize' }}>{label}</InputLabel>
      <LightTooltip title={tooltipTitle ?? ''}>
        <Input sx={{ fontSize: fonts.fontSize16}} value={value} disabled disableUnderline />
      </LightTooltip>
    </FormControl>
  );
};
