import { SIO, GridRequest, GridResponse } from '../../shared/interfaces';
import { httpSecuredCommon, cancelToken } from '../httpCommon';

const base = '/sios';

export const SIO_API_ROUTES = {
  getSio: (id: string) => `${base}/${id}`,
  putSio: (id: string) => `${base}/${id}`,
  patchSioStatus: (id: string) => `${base}/${id}`,
  postSio: base,
  getSios: base,

  getSioApprovals: (id: string) => `${base}/${id}/approvals`,
  putSioApprovals: (id: string) => `${base}/${id}/approvals`,
  getSioAppSettings: (id: string) => `${base}/${id}/app-settings`,
};

export const getSio = async (id: string) => {
  const response = await httpSecuredCommon.get<SIO>(SIO_API_ROUTES.getSio(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postSio = async (sio: SIO) => {
  const response = await httpSecuredCommon.post<SIO>(SIO_API_ROUTES.postSio, sio, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const putSio = async (id: string, sio: SIO) => {
  const response = await httpSecuredCommon.put<SIO>(SIO_API_ROUTES.putSio(id), sio, {
    cancelToken: cancelToken.token,
  });

  return response;
};

export const patchSioStatus = async (id: string, status: string) => {
  const response = await httpSecuredCommon.patch(
    SIO_API_ROUTES.patchSioStatus(id),
    { status },
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getSios = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<SIO>>(SIO_API_ROUTES.getSios, {
    cancelToken: cancelToken.token,
    params: request,
  });
  return response;
};

export const getSioAppSettings = async (id: string) => {
  const response = await httpSecuredCommon.get(SIO_API_ROUTES.getSioAppSettings(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};
