import { Box, Button, Checkbox, FormControlLabel, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  InputBox,
  Loading,
  AccountSharedForm,
  isAccountFormInvalid,
} from '../../shared/components';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '../../shared/constants';
import { useAppDispatch, useAppSelector, useInstance } from '../../shared/hooks';
import { CommonBody } from '../register/CommonBody';
import {
  putRegisterDetailsUserThunk,
  setRegisterDetailsAccountData,
  setAgreedTermsAndConditions,
  setAgreedSupplierTerms,
  getSupplierTermsThunk,
} from './registerDetailsSlice';
import { AccountFormConfig } from '../../shared/interfaces';
import { refreshAppAccountThunk } from '../../app';
import {
  isNullOrWhitespace,
  isSubscriptionRequired,
  openInformativeDialog,
  verifyTokenExpiration,
} from '../../shared/utils';
import { InformativeDialog, AccountType } from '../../shared/enums';

export function RegisterDetailsForm() {
  const registerDetailsState = useAppSelector((state) => state.registerDetails);
  const appState = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loadingUserData, locale, instance, account: accountFromDB } = useInstance();
  const {
    accountData,
    agreedTermsAndConditions,
    loading,
    success,
    agreedSupplierTermsConditions,
    supplierTerms,
    loadingSuppliersTerms,
  } = registerDetailsState;
  const { address, phoneNumber, website, countryCode } = { ...accountData.company };
  const { address1, address2, city, stateProvince, postalCode, country } = { ...address };
  const { account: userAccount } = appState;
  const { procurement } = { ...supplierTerms };
  const { supplierTermsOfUseText, supplierTermsOfUseUrl } = { ...procurement };
  const { settings: localeSettings } = { ...locale };

  const addressForm: AccountFormConfig = {
    companyControl: { value: userAccount?.company?.name ?? '', disabled: true },
    addressLine1Control: { value: address1!, required: true },
    addressLine2Control: { value: address2! },
    cityControl: { value: city!, required: true },
    stateControl: { value: stateProvince!, required: true, label: localeSettings?.regionLabel },
    zipCodeControl: { value: postalCode! },
    phoneNumberControl: { value: phoneNumber!, required: true },
    companyWebsiteControl: { value: website! },
    countryCodeControl: { value: countryCode!, required: true },
    countryControl: { value: country!, required: true },
  };

  const isRequiredSupplierTerms = () => {
    if (accountFromDB?.type !== AccountType.supplier) return true;
    if (isNullOrWhitespace(supplierTermsOfUseUrl) || isNullOrWhitespace(supplierTermsOfUseText))
      return true;
    return agreedSupplierTermsConditions;
  };

  const isSubmitValid = () =>
    !isAccountFormInvalid(addressForm) && agreedTermsAndConditions && isRequiredSupplierTerms();
    const text=appState.account?.subscription?.includes("buywith")?"BuyWith":"SellWith"

  useEffect(() => {
    if (!success) return;
    dispatch(refreshAppAccountThunk()).then(() => {
      if (isSubscriptionRequired(userAccount, instance)) {
        navigate('/register-details/financial-choice');
        return;
      }
      verifyTokenExpiration(true);
      navigate('/app');
      openInformativeDialog({
        title: `You're All Set!`,
        type: InformativeDialog.WelcomeMessage,
        text: `Thank you for completing your registration with ${text}. We are so excited you are here!`,
        confirmText: 'Close',
        hideCheckBox: true,
      });
    });
  }, [dispatch, instance, navigate, success, userAccount]);

  useEffect(() => {
    if (
      accountFromDB?.type !== AccountType.supplier ||
      isNullOrWhitespace(accountFromDB.invitedBy?.id) ||
      loadingSuppliersTerms ||
      !isNullOrWhitespace(supplierTermsOfUseText)
    )
      return;
    dispatch(getSupplierTermsThunk(accountFromDB?.invitedBy?.id!));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accountFromDB]);

  const updateUser = () => {
    dispatch(putRegisterDetailsUserThunk(userAccount?.company?.name ?? ''));
  };

  if (loading || loadingUserData || loadingSuppliersTerms)
    return <Loading loading={loading || loadingUserData || loadingSuppliersTerms} />;

  return (
    <CommonBody hideLogo coloredLogo>
      <Box>
        <AccountSharedForm
          formConfig={addressForm}
          setAccountFormData={(value) => dispatch(setRegisterDetailsAccountData(value))}
          statesValues={localeSettings?.regions}
        />
        <InputBox>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreedTermsAndConditions}
                onChange={(e) => {
                  dispatch(setAgreedTermsAndConditions(e.target.checked));
                }}
              />
            }
            label={
              <Typography
                sx={{ fontWeight: '700', color: 'black', textAlign: 'center', marginBottom: 0 }}
                paragraph
              >
                {'I agree with the '}
                <Link
                  href={TERMS_OF_USE_URL}
                  rel="noopener noreferrer"
                  title="Terms & Conditions"
                  target="_blank"
                  aria-label="Terms & Conditions"
                >
                  Terms & Conditions
                </Link>
                {' and '}
                <Link
                  href={PRIVACY_POLICY_URL}
                  rel="noopener noreferrer"
                  title="privacy policy"
                  target="_blank"
                  aria-label="privacy policy"
                >
                  Privacy Policy
                </Link>
              </Typography>
            }
          />
        </InputBox>
        {accountFromDB?.type === AccountType.supplier &&
          !isNullOrWhitespace(supplierTermsOfUseUrl) &&
          !isNullOrWhitespace(supplierTermsOfUseText) && (
            <InputBox sx={{ margin: 0 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreedSupplierTermsConditions}
                    onChange={(e) => {
                      dispatch(setAgreedSupplierTerms(e.target.checked));
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{ fontWeight: '700', color: 'black', textAlign: 'center', marginBottom: 0 }}
                    paragraph
                  >
                    {'I agree with the '}
                    <Link
                      href={supplierTermsOfUseUrl!}
                      rel="noopener noreferrer"
                      title={supplierTermsOfUseText}
                      target="_blank"
                      aria-label={supplierTermsOfUseText}
                    >
                      {supplierTermsOfUseText}
                    </Link>
                  </Typography>
                }
              />
            </InputBox>
          )}
        <Button
          sx={{ marginTop: '20px' }}
          variant="contained"
          fullWidth
          size="large"
          disabled={!isSubmitValid()}
          onClick={updateUser}
        >
          Submit
        </Button>
      </Box>
    </CommonBody>
  );
}
