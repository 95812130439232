import React, { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FlexBox } from '../../../shared/components';
import { ProcurementMetricsFilterPopper } from './ProcurementMetricsFilterPopper';
import {
  ProcurementMetricsFilterConf,
  ProcurementMetricsRequest,
} from '../../../shared/interfaces';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  setProcurementMetricRequestValues,
  setProcurementMetricsData,
} from '../procurementMetricsSlice';
import fonts from '../../../assets/scss/font.module.scss';
import colors from '../../../assets/scss/color.module.scss';

interface Props {
  filterValues: ProcurementMetricsFilterConf;
}


export const ProcurementMetricsFilters: FC<Props> = (props) => {
  const { filterValues } = props;
  const order = useAppSelector((x) => x.procurementMetrics.orderBy);
  const dispatch = useAppDispatch();

  const applyFilter = (request: ProcurementMetricsRequest) => {
    dispatch(setProcurementMetricRequestValues(request));
  };

  return (
    <FlexBox sx={{ alignItems: 'baseline', justifyContent: 'end', gap: 2 }}>
      <FormControl>
        <InputLabel sx={{ color:colors.textPrimary,fontSize: fonts.fontSize20, fontWeight: 600, textTransform: 'none' }}>Sort By</InputLabel>
        <Select
          labelId="filter-select-label"
          id="filter-select"
          variant="standard"
          value={order}
          onChange={(e) => {
            if (e.target.value !== 'desc' && e.target.value !== 'asc') return;
            dispatch(setProcurementMetricsData({ orderBy: e.target.value }));
          }}
        >
          <MenuItem value="desc" sx={{ color:colors.textPrimary,fontSize: fonts.fontSize18, fontWeight: 600, textTransform: 'none' }}>Highest To Lowest</MenuItem>
          <MenuItem value="asc" sx={{ color:colors.textPrimary,fontSize: fonts.fontSize18, fontWeight: 600, textTransform: 'none' }}>Lowest To Highest</MenuItem>
        </Select>
      </FormControl>
      <ProcurementMetricsFilterPopper values={filterValues} applyFilter={applyFilter} />
    </FlexBox>
  );
};
