export enum PaymentMethods {
  Stripe = 'stripe',
  Invoice = 'invoice',
}

export enum PaymentMethodLabels {
  Stripe = 'Credit Card/Bank Debit',
  Invoice = 'Bank Transfer',
}

export enum PaymentMethodLabelInfo {
  Stripe = 'Pay now by Credit Card or Bank Debit',
  Invoice = 'Send an invoice to pay by bank transfer',
}