import { OpenInNew } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FlexBox, PaddingRoundedBox, WhiteRoundedBox } from '../../../shared/components';
import { ConfirmDialogType, StripeActionType } from '../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  getDateString,
  getGenericHeight,
  isNullOrWhitespace,
  openConfirmDialog,
} from '../../../shared/utils';
import {
  deleteAccountSettingsAutopayThunk,
  postCustomerPortalSessionAccountSettingsThunk,
  postSetupCheckoutSessionAccountSettingsThunk,
  saveAccountSettingsThunk,
  setAccountSettingsDisableAutopay,
} from './accountSettingsSlice';
import { AUTOPAY_ENABLED } from '../../../shared/constants';

interface Props {
  canEdit: boolean;
  isSaveValid: (stripe?: boolean) => boolean;
  accountSettingsTabsModified: () => boolean;
  isSubscriptionRequired: boolean;
  isProcurementUser: boolean;
}

export const AccountSettingsPayment: FC<Props> = (props) => {
  const state = useAppSelector((x) => x.accountSettings.payment);
  const stripe = useAppSelector((x) => x.accountSettings.general.account?.stripe);
  const confirmDialog = useAppSelector((x) => x.confirmDialog);
  const openStripePortalLoading = useAppSelector((x) => x.accountSettings.openStripePortalLoading);
  const dispatch = useAppDispatch();
  const {
    canEdit,
    isSaveValid,
    accountSettingsTabsModified,
    isSubscriptionRequired,
    isProcurementUser,
  } = props;
  const { autopay, customerId: stripeCustomerId } = { ...stripe };
  const {
    enabledDate: autopayEnabledDate,
    paymentMethodId,
    pendingMicroDepositVerification,
  } = { ...autopay };
  const { disable, disabled } = state;
  const { confirmed, negated, type: confirmDialogType } = confirmDialog;

  const autopayEnabled = !isNullOrWhitespace(paymentMethodId);

  useEffect(() => {
    if (!disabled) return;

    toast.success('Autopay disabled successfully');
  });

  useEffect(() => {
    if (!negated || confirmDialogType !== ConfirmDialogType.UpdateAutopayMethod) return;

    dispatch(
      postSetupCheckoutSessionAccountSettingsThunk(StripeActionType.UpdateAutomaticPaymentMethod),
    );
  }, [confirmDialogType, negated, dispatch]);

  useEffect(() => {
    if (!confirmed || confirmDialogType !== ConfirmDialogType.UpdateAutopayMethod) return;

    if (!isSaveValid(true)) return;

    dispatch(saveAccountSettingsThunk(false)).then(() =>
      dispatch(
        postSetupCheckoutSessionAccountSettingsThunk(StripeActionType.UpdateAutomaticPaymentMethod),
      ),
    );
  }, [confirmDialogType, confirmed, dispatch, isSaveValid]);

  useEffect(() => {
    if (!negated || confirmDialogType !== ConfirmDialogType.DisableAutopay) return;

    dispatch(deleteAccountSettingsAutopayThunk());
  }, [confirmDialogType, negated, dispatch]);

  useEffect(() => {
    if (!confirmed || confirmDialogType !== ConfirmDialogType.DisableAutopay) return;

    if (!isSaveValid(true)) return;

    dispatch(saveAccountSettingsThunk(false)).then(() =>
      dispatch(deleteAccountSettingsAutopayThunk()),
    );
  }, [confirmDialogType, confirmed, dispatch, isSaveValid]);

  useEffect(() => {
    if (!negated || confirmDialogType !== ConfirmDialogType.UpdatePaymentMethod) return;

    dispatch(
      postSetupCheckoutSessionAccountSettingsThunk(
        StripeActionType.UpdateSubscriptionPaymentMethod,
      ),
    );
  }, [confirmDialogType, negated, dispatch]);

  useEffect(() => {
    if (!confirmed || confirmDialogType !== ConfirmDialogType.UpdatePaymentMethod) return;

    if (!isSaveValid(true)) return;

    dispatch(saveAccountSettingsThunk(false)).then(() =>
      dispatch(
        postSetupCheckoutSessionAccountSettingsThunk(
          StripeActionType.UpdateSubscriptionPaymentMethod,
        ),
      ),
    );
  }, [confirmDialogType, confirmed, dispatch, isSaveValid]);

  const updateAutopayPayment = (enableAutopay?: boolean) => {
    if (accountSettingsTabsModified()) {
      openConfirmDialog({
        title: 'Pending Account Changes',
        text: `Do you want to save your changes before ${
          enableAutopay ? 'enable autopay?' : 'updating autopay payment method?'
        }`,
        type: ConfirmDialogType.UpdateAutopayMethod,
      });
    } else {
      dispatch(
        postSetupCheckoutSessionAccountSettingsThunk(StripeActionType.UpdateAutomaticPaymentMethod),
      );
    }
  };

  const disableAutopayPayment = () => {
    if (accountSettingsTabsModified()) {
      openConfirmDialog({
        title: 'Pending Account Changes',
        text: 'Do you want to save your changes before disabling autopay payment method?',
        type: ConfirmDialogType.DisableAutopay,
      });
    } else {
      dispatch(deleteAccountSettingsAutopayThunk());
    }
  };

  const updateSubscriptionPayment = () => {
    if (accountSettingsTabsModified() && isSubscriptionRequired) {
      openConfirmDialog({
        title: 'Pending Account Changes',
        text: 'Do you want to save your changes before updating subscription payment method?',
        type: ConfirmDialogType.UpdatePaymentMethod,
      });
    } else {
      dispatch(
        postSetupCheckoutSessionAccountSettingsThunk(
          StripeActionType.UpdateSubscriptionPaymentMethod,
        ),
      );
    }
  };

  const autopayItem = (
    title: string,
    text: string,
    buttons?: JSX.Element,
    extraBottomElement?: JSX.Element,
  ) => (
    <WhiteRoundedBox sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      <Typography variant="h6" fontWeight={700}>
        {title}
      </Typography>
      <Typography>{text}</Typography>
      <FlexBox sx={{ gap: 2 }}>{buttons}</FlexBox>
      {extraBottomElement}
    </WhiteRoundedBox>
  );

  const givesItem = autopayItem(
    'Social Impact Contribution',
    'Autopay allows you to put your preferred payment method on file and allow authorized users to approve Social Impact Contributions with just one click.',
    !autopayEnabled ? (
      <Button
        variant="contained"
        disabled={!canEdit || pendingMicroDepositVerification}
        endIcon={<OpenInNew />}
        onClick={() => updateAutopayPayment(true)}
      >
        {pendingMicroDepositVerification ? 'Pending Verification' : 'Enable Autopay'}
      </Button>
    ) : (
      <>
        <Button
          variant="outlined"
          disabled={!canEdit}
          onClick={() => dispatch(setAccountSettingsDisableAutopay(true))}
        >
          Disable Autopay
        </Button>
        <Button
          variant="contained"
          disabled={!canEdit || isNullOrWhitespace(stripeCustomerId)}
          endIcon={<OpenInNew />}
          onClick={() => updateAutopayPayment()}
        >
          Update Autopay Payment Method
        </Button>
      </>
    ),
    autopayEnabledDate && (
      <Typography variant="body2">
        Autopay enrolled on {getDateString(autopayEnabledDate, true)}
      </Typography>
    ),
  );

  const subscriptionItem = autopayItem(
    'Subscription',
    'Change or update your subscription payment method in Stripe.',
    <Button
      variant="contained"
      disabled={!canEdit || isNullOrWhitespace(stripeCustomerId)}
      endIcon={<OpenInNew />}
      sx={{ height: 'fit-content' }}
      onClick={() => updateSubscriptionPayment()}
    >
      Update Subscription Payment Method
    </Button>,
  );

  const activityItem = autopayItem(
    'Activity',
    'View recent transactions and payment activity in the Stripe portal.',
    <LoadingButton
      variant="contained"
      size="large"
      endIcon={<OpenInNew />}
      disabled={!canEdit || isNullOrWhitespace(stripeCustomerId)}
      onClick={() => dispatch(postCustomerPortalSessionAccountSettingsThunk())}
      loading={openStripePortalLoading}
    >
      Stripe Portal
    </LoadingButton>,
  );

  const disableAutopay = autopayItem(
    'Disable Autopay?',
    'Are you sure you want to disable your autopay?.',
    <>
      <Button
        variant="outlined"
        sx={{ width: 100 }}
        onClick={() => dispatch(setAccountSettingsDisableAutopay(false))}
      >
        No
      </Button>
      <Button
        variant="contained"
        sx={{ width: 100 }}
        disabled={!canEdit}
        onClick={() => disableAutopayPayment()}
      >
        Yes
      </Button>
    </>,
  );

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 2 }}>
      <Typography variant="h4">Payment</Typography>
      <Typography mb={1}>
        ScaleWith has partnered with Stripe, one of the world&apos;s leading online payment
        processors, to offer you simple and secure payments.
      </Typography>
      <PaddingRoundedBox
        sx={(theme) => ({
          backgroundColor: theme.palette.common.grey.main,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          height: getGenericHeight(500),
          overflowY: 'auto',
        })}
      >
        {AUTOPAY_ENABLED && (!disable && !isProcurementUser ? givesItem : !isProcurementUser && disableAutopay)}
        {!disable && isSubscriptionRequired && subscriptionItem}
        {!disable && isSubscriptionRequired && activityItem}
      </PaddingRoundedBox>
    </FlexBox>
  );
};
