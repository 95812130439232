import { Box, InputAdornment, TextField } from '@mui/material';
import React, { FC } from 'react';
import {
  BoldTypography,
  FlexBox,
  LabelAndSwitch,
  NumberInput,
} from '../../../../shared/components';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { InstanceFormConfig } from '../../../../shared/interfaces';
import { capitalizeFirstLetter } from '../../../../shared/utils';
import { setInstanceSettingsData } from '../instanceSettingsSlice';

interface Props {
  value: InstanceFormConfig;
}

export const InstanceSettingsStripeAccountForm: FC<Props> = (props) => {
  const currentLocale = useAppSelector((x) => x.instanceSettings.selectedLocale);
  const instanceType = useAppSelector((x) => x.instanceSettings.values.type);
  const { value } = props;
  const {
    stripe: {
      annualPriceId,
      monthlyPriceId,
      annualAmount,
      annualFooter,
      monthlyAmount,
      monthlyFooter,
      accountRequired,
      accountAnnualEnable,
      accountMonthlyEnable,
    },
  } = value;
  const dispatch = useAppDispatch();

  return (
    <FlexBox sx={{ marginTop: 3, gap: 1, flexDirection: 'column' }}>
      <BoldTypography variant="h5">
        {capitalizeFirstLetter(instanceType ?? '')} Products
      </BoldTypography>
      <LabelAndSwitch
        value={accountRequired.value}
        style={{ gap: 2, flexDirection: 'row' }}
        label={accountRequired.label}
        onChange={(e) => {
          dispatch(
            setInstanceSettingsData({
              settings: { stripe: { prices: { required: e.target.checked } } },
            }),
          );
        }}
      />
      {accountRequired.value && (
        <Box>
          <Box>
            <FlexBox sx={{ marginTop: 3 }}>
              <BoldTypography variant="h6" sx={{ flex: 1 }}>
                Annual Subscription
              </BoldTypography>
              <LabelAndSwitch
                value={accountAnnualEnable.value}
                style={{
                  gap: 2,
                  flexDirection: 'row',
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'end',
                }}
                onChange={(e) => {
                  dispatch(
                    setInstanceSettingsData({
                      settings: {
                        stripe: { prices: { annual: { enabled: e.target.checked } } },
                      },
                    }),
                  );
                }}
                messageNo="DISABLED"
                messageYes="ENABLED"
              />
            </FlexBox>
            <FlexBox sx={{ gap: 3, marginTop: 1, flexDirection: 'column' }}>
              <FlexBox sx={{ gap: 3 }}>
                <TextField
                  label={annualPriceId.label}
                  value={annualPriceId.value}
                  required={annualPriceId.required}
                  disabled={!accountAnnualEnable.value}
                  sx={{ flex: 1 }}
                  onChange={(event) => {
                    dispatch(
                      setInstanceSettingsData({
                        settings: { stripe: { prices: { annual: { id: event.target.value } } } },
                      }),
                    );
                  }}
                />
                <TextField
                  label={annualAmount.label}
                  value={annualAmount.value}
                  required={annualAmount.required}
                  disabled={!accountAnnualEnable.value}
                  sx={{ flex: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currentLocale?.settings.currency &&
                          `(${currentLocale?.settings?.currency})`}
                      </InputAdornment>
                    ),
                    inputComponent: NumberInput as any,
                  }}
                  onChange={(event) => {
                    dispatch(
                      setInstanceSettingsData({
                        settings: {
                          stripe: { prices: { annual: { amount: Number(event.target.value) } } },
                        },
                      }),
                    );
                  }}
                />
              </FlexBox>
              <TextField
                label={annualFooter.label}
                value={annualFooter.value}
                required={annualFooter.required}
                disabled={!accountAnnualEnable.value}
                sx={{ flex: 1 }}
                onChange={(event) => {
                  dispatch(
                    setInstanceSettingsData({
                      settings: {
                        stripe: { prices: { annual: { footer: event.target.value } } },
                      },
                    }),
                  );
                }}
              />
            </FlexBox>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <FlexBox>
              <BoldTypography variant="h6" sx={{ flex: 1 }}>
                Monthly Subscription
              </BoldTypography>
              <LabelAndSwitch
                value={accountMonthlyEnable.value}
                style={{
                  gap: 2,
                  flexDirection: 'row',
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'end',
                }}
                onChange={(e) => {
                  dispatch(
                    setInstanceSettingsData({
                      settings: {
                        stripe: { prices: { monthly: { enabled: e.target.checked } } },
                      },
                    }),
                  );
                }}
                messageNo="DISABLED"
                messageYes="ENABLED"
              />
            </FlexBox>
            <FlexBox sx={{ gap: 3, marginTop: 1, flexDirection: 'column' }}>
              <FlexBox sx={{ gap: 3 }}>
                <TextField
                  label={monthlyPriceId.label}
                  value={monthlyPriceId.value}
                  required={monthlyPriceId.required}
                  disabled={!accountMonthlyEnable.value}
                  sx={{ flex: 1 }}
                  onChange={(event) => {
                    dispatch(
                      setInstanceSettingsData({
                        settings: { stripe: { prices: { monthly: { id: event.target.value } } } },
                      }),
                    );
                  }}
                />
                <TextField
                  label={monthlyAmount.label}
                  value={monthlyAmount.value}
                  required={monthlyAmount.required}
                  disabled={!accountMonthlyEnable.value}
                  sx={{ flex: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currentLocale?.settings.currency &&
                          `(${currentLocale?.settings?.currency})`}
                      </InputAdornment>
                    ),
                    inputComponent: NumberInput as any,
                  }}
                  onChange={(event) => {
                    dispatch(
                      setInstanceSettingsData({
                        settings: {
                          stripe: { prices: { monthly: { amount: Number(event.target.value) } } },
                        },
                      }),
                    );
                  }}
                />
              </FlexBox>
              <TextField
                label={monthlyFooter.label}
                value={monthlyFooter.value}
                required={monthlyFooter.required}
                disabled={!accountMonthlyEnable.value}
                sx={{ flex: 1 }}
                onChange={(event) => {
                  dispatch(
                    setInstanceSettingsData({
                      settings: {
                        stripe: { prices: { monthly: { footer: event.target.value } } },
                      },
                    }),
                  );
                }}
              />
            </FlexBox>
          </Box>
        </Box>
      )}
    </FlexBox>
  );
};
