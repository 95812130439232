import React, { useEffect, useState, useRef } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { BoldTypography, FlexBox } from '../../../../shared/components';
import { InformativeDialog } from '../../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { openInformativeDialog, Visible } from '../../../../shared/utils';
import {
  clearInviteSuppliersForm,
  setSupplierInviteData,
  setSupplierInviteError,
  setSupplierInviteList,
} from './supplierInviteSlice';
import { SupplierInviteError } from './SupplierInviteError';
import { InviteSupplierForm } from './SupplierInviteForm';

export const SupplierEntryForm = () => {
  const state = useAppSelector((x) => x.inviteSupplier);
  const stateInviteSupplierCSV = useAppSelector((x) => x.inviteSupplierCSV);
  const { isChecked } = { ...stateInviteSupplierCSV.initialState2 };
  const { listSuppliers, success, serverError, inviteErrorMessage } = { ...state };
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const mainContainer = useRef<null | HTMLDivElement>(null);
  const clientError = listSuppliers.some(
    (item) => item.errorMessagesClient !== undefined && item.errorMessagesClient.length > 0,
  );

  useEffect(() => {
    if (!success) return;
    openInformativeDialog({
      title: 'Invite Supplier',
      type: InformativeDialog.InviteSuppliers,
      confirmText: 'close',
      text: 'Your Invites have been sent.',
      hideCheckBox: true,
    });
  }, [dispatch, success]);
  const cleanEditMode = () => {
    setIsEditMode(false);
    setEditIndex(null);
    dispatch(clearInviteSuppliersForm());
  };

  const onDeleteSupplier = (index: number) => {
    if (isEditMode && editIndex !== null) cleanEditMode();
    const copyList = [...listSuppliers];
    const newValue = copyList.slice(0, index).concat(copyList.slice(index + 1));
    dispatch(setSupplierInviteList(newValue));
  };

  useEffect(() => {
    if (!mainContainer.current) return;
    mainContainer.current.scrollIntoView();
    if (isChecked) {
      onDeleteSupplier(0);
    }
  }, []);

  const onHandleEdit = (index: number) => {
    const itemToEdit = listSuppliers[index];
    setIsEditMode(true);
    setEditIndex(index);
    const valuesToEdit = {
      username: itemToEdit.value.user.username,
      lastName: itemToEdit.value.user.lastName,
      company: itemToEdit.value.account.company?.name,
      firstName: itemToEdit.value.user.firstName,
      industry: {
        label: itemToEdit.value.account.industry?.subIndustry ?? '',
        value: itemToEdit.value.account.industry?.subIndustry ?? '',
        group: itemToEdit.value.account.industry?.industry ?? '',
      },
    };
    if (serverError) dispatch(setSupplierInviteError(false));
    dispatch(setSupplierInviteData({ ...valuesToEdit }));
  };

  return (
    <Box sx={{ height: 565 }} ref={mainContainer}>
      <FlexBox sx={{ flexDirection: 'column', gap: 2 }}>
        {listSuppliers.length > 0 && isEditMode === false && (serverError || clientError) ? (
          <SupplierInviteError />
        ) : (
          <InviteSupplierForm
            cleanEditMode={cleanEditMode}
            editIndex={editIndex}
            isEditMode={isEditMode}
          />
        )}

        <BoldTypography variant="h5">{`Invitees (${listSuppliers.length})`}</BoldTypography>
        <FlexBox sx={{ flexDirection: 'column', gap: 2 }}>
          {listSuppliers.map((user, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={`invite-supplier-${user.value.user.username}-${index}`}>
              <FlexBox sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <FlexBox sx={{ flexDirection: 'column' }}>
                  <BoldTypography>{`${user.value.user.firstName} ${user.value.user.lastName}`}</BoldTypography>
                  <Typography>{user.value.user.username}</Typography>
                </FlexBox>
                <FlexBox sx={{ alignItems: 'center', gap: 1 }}>
                  <Edit sx={{ cursor: 'pointer' }} onClick={() => onHandleEdit(index)} />
                  <Delete onClick={() => onDeleteSupplier(index)} sx={{ cursor: 'pointer' }} />
                </FlexBox>
              </FlexBox>
              <Visible
                condition={
                  !!user.errorMessagesServer ||
                  (user.errorMessagesClient !== undefined && user.errorMessagesClient.length > 0)
                }
              >
                <Box>
                  {user?.errorMessagesServer?.map((message, indexElement) => (
                    <Typography
                      // eslint-disable-next-line react/no-array-index-key
                      key={`supplier-invite-error-message=${indexElement}`}
                      sx={{ color: '#EA190D' }}
                    >
                      {message}
                    </Typography>
                  ))}
                  {user?.errorMessagesClient?.map((message, indexElement) => (
                    <Typography
                      // eslint-disable-next-line react/no-array-index-key
                      key={`supplier-invite-error-message=${indexElement}`}
                      sx={{ color: '#EA190D' }}
                    >
                      {message}
                    </Typography>
                  ))}
                </Box>
              </Visible>
              {inviteErrorMessage !== '' && (
                <Typography sx={{ color: '#EA190D' }}>{inviteErrorMessage}</Typography>
              )}

              <Divider />
            </Box>
          ))}
        </FlexBox>
      </FlexBox>
    </Box>
  );
};
