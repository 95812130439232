import { Box, Theme } from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassBottom from '@mui/icons-material/HourglassBottom';
import React, { FC } from 'react';
import { SioStatus } from '../enums';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

const getStatusStyle = (theme: Theme, status?: string) => {
  let backgroundColor = '';
  let fontWeight = '';
  let color = '';
  let border = '';

  switch (status) {
    case SioStatus.Approved: {
      backgroundColor = colors.statusBackgroundSuccess;
      border = `1px solid ${colors.statusSuccess}`;
      fontWeight = fonts.fontWeight400;
      color = colors.statusSuccess;
      break;
    }
    case SioStatus.Submitted: {
      backgroundColor = '#ffec99';
      color = '#f08c00';
      break;
    }
    case SioStatus.InProgress: {
      backgroundColor = colors.statusBackgroundInfo;
      border = `1px solid ${colors.statusInfo}`;
      fontWeight = fonts.fontWeight400;
      color = colors.statusInfo;
      break;
    }
    case SioStatus.Archived: {
      backgroundColor = '#DFE2E6';
      // border = `1px solid #333333`;
      fontWeight = fonts.fontWeight400;
      color = '#000000';
      break;
    }
    case SioStatus.PendingApproval: {
      backgroundColor = colors.statusBackgroundWarning;
      border = `1px solid ${colors.statusWarning}`;
      fontWeight = fonts.fontWeight400;
      color = colors.statusWarning;
      break;
    }
    default: {
      backgroundColor = '';
      color = '';
      break;
    }
  }

  return {
    fontSize: fonts.fontsize16,
    borderRadius: 20,
    padding: '6px 0px 6px 15px',
    width: 200,
    textAlign: 'left',
    backgroundColor,
    border,
    fontWeight,
    color,
    textTransform: 'capitalize',
    display: 'flex',
    // justifyContent: 'flex-start',
    alignItems: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };
};

interface Props {}

export const SioStatusCell: FC<Props> = (props) => {
  const { children } = props;
  const status = children?.toString();

  return (
    <Box sx={(theme) => getStatusStyle(theme, status)}>
      {' '}
      {status === SioStatus.InProgress && <HourglassBottom sx={{ marginRight: '0.5rem' }} />}
      {status === SioStatus.Approved && <CheckCircleIcon sx={{ marginRight: '5px' }} />}
      {status === SioStatus.Submitted && <WatchLaterIcon sx={{ marginRight: '0.5rem' }} />}
      {status}
    </Box>
  );
};
