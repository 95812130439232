import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCancelTokenEvent, requestForgotPassword } from '../../api';
import { RootState } from '../../app';

export interface ResetUserPasswordState {
  email: string;
  loading: boolean;
  success: boolean;
}

const initialState: ResetUserPasswordState = {
  email: '',
  loading: false,
  success: false,
};

export const postResetUserPassword = createAsyncThunk<number, void, { state: RootState }>(
  'resetUserPassword/postResetUserPassword',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const { email } = state.resetUserPassword;
    const { status } = await requestForgotPassword(email);
    return status;
  },
);

const resetUserPasswordSlice = createSlice({
  name: 'resetUserPassword',
  initialState,
  reducers: {
    setResetUserPasswordEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    clearResetUserPasswordState: (state) => {
      state.email = initialState.email;
      state.loading = initialState.loading;
      state.success = initialState.success;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postResetUserPassword.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(postResetUserPassword.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postResetUserPassword.pending, (state) => {
        if (!state.loading) state.loading = true;
      });
  },
});

export const { setResetUserPasswordEmail, clearResetUserPasswordState } =
  resetUserPasswordSlice.actions;

export const resetUserPasswordReducer = resetUserPasswordSlice.reducer;
