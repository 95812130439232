import React, {  useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import BoxSection from '../../../shared/components/common/boxSection';
import TextAreaInput from '../../../shared/components/common/textAreaInput';
import CheckBoxInput from '../../../shared/components/common/checkBox/checkBoxInput';
import UploadFileComponent from '../../../shared/components/common/uploadFile';
import GridInput from '../../../shared/components/common/gridTextField';
import ScrollBox from '../../../shared/components/common/scrollBox';
import Progressed from '../../../assets/svg/progressed.svg';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface vocabulary {
  causes: [
    {
      _id: string;
      label: string;
      slug: string;
    },
  ];
  impact: [];
  audience: [];
  programApproach: [];
  approachDuration: [];
  dataMeasurement: [];
  studyDesignType: [];
  studyEvidenceType: [];
  researchDataType: [];
  effect: [];
  sccConversion: [];
  strength: [];
}

interface props {
  vocabData: any;
  researchEvaluation: any;
  submitted?: boolean;
  setCompletionProgramData: any;
  validatedQuestions: (count: number) => void;
  formId: string;
  isSaveProgress: boolean;
  setProcess: () => void;
  submitResponse: boolean;
}

interface researchEvaluationProps {
  studyDescription: {
    selected: [] ;
    optional: string;
  };
  researchFile: {};
  evidenceDescription: {
    selected: [];
    optional: string;
  };
  environmentalOutputs: {
    selected: string[];
    optional: string;
  };
  environmentalOutputValues: [
    {
     
      quantity: number;
      description: 'string';
    },
  ];
  outcomeDescription: [];
  researchApproach: [
    {
      _id: string;
      evidenceScore: 0;
    },
  ];
  strength: string;
  dataDescription: {
    selected: [];
    optional: string;
  };
}

const lifetimeOutputForm = [{ quantity: 0, description: '' }]

const ResearchEvaluation: React.FC<props> = ({
  vocabData,
  researchEvaluation,
  submitted,
  setCompletionProgramData,
  validatedQuestions,
  formId,
  isSaveProgress,
  setProcess,
  submitResponse,
}) => {
  const [vocabularyData, setvocabulary] = useState<vocabulary>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isEvidence, setIsEVidence] = useState({
    na: false,
    others: false,
  });
  const [researchAndEvaluationData, setResearchAndEvaluationData] =
    useState<researchEvaluationProps>();
  const [validatedAnswers, setvalidateAnswers] = useState({
    evidenceDescription: 0,
    researchApproach: 0,
  });
  const [strengthKey, setStrengthKey] = useState('');
  const [outcomeDescriptionKey, setOutcomeDescriptionKey] = useState('');
  const [evidenceDesc, setEvidenceDesc] = useState('');
  const [studyDesc, setStudyDesc] = useState('');
  const [dataDesc, setDataDesc] = useState('');
  const [environmentOutputDesc, setEnvironmentalOutputDesc] = useState('');
  const [environmentalOutputValues,setEnvironmentalOutputValues]=useState<any>([])
 
  const validateFields = (value: string) => (value === '' || value === undefined ? 0 : 1);
  useEffect(() => {
    setvocabulary(vocabData);
    setIsLoaded(true);
    setResearchAndEvaluationData(researchEvaluation);
    setStrengthKey(researchEvaluation?.strength);
    if(researchEvaluation?.outcomeDescription){
    setOutcomeDescriptionKey(researchEvaluation?.outcomeDescription[0]);
    }
    setEvidenceDesc(researchEvaluation?.evidenceDescription?.optional);
    setStudyDesc(researchEvaluation?.studyDescription?.optional);
    setDataDesc(researchEvaluation?.dataDescription?.optional);
    setEnvironmentalOutputDesc(researchEvaluation?.environmentalOutputs?.optional);
    setEnvironmentalOutputValues(researchAndEvaluationData?.environmentalOutputValues ??lifetimeOutputForm)
  
    if (researchEvaluation?.evidenceDescription?.optional !== '') {
      setIsEVidence((prevValues: any) => ({
        ...prevValues,
        others: true,
      }));
    } else {
      setIsEVidence((prevValues: any) => ({
        ...prevValues,
        others: false,
      }));
    }
  }, [!isLoaded]);

  useEffect(() => {
    setvalidateAnswers({
      evidenceDescription:
        researchAndEvaluationData?.evidenceDescription?.selected?.length! > 0 ? 1 : 0,
      researchApproach: researchAndEvaluationData?.researchApproach?.length! > 0 ? 1 : 0,
    });
  }, [researchAndEvaluationData]);


  useEffect(() => {
    if (isSaveProgress || submitResponse) {
      setCompletionProgramData(
        {
          studyDescription: {
            selected: researchAndEvaluationData?.studyDescription?.selected,
            optional: studyDesc,
          },
          researchFile: researchAndEvaluationData?.researchFile,
          evidenceDescription: {
            selected: researchAndEvaluationData?.evidenceDescription?.selected,
            optional: evidenceDesc,
          },
          environmentalOutputs: {
            selected: researchAndEvaluationData?.environmentalOutputs?.selected,
            optional: environmentOutputDesc,
          },
          environmentalOutputValues,
          outcomeDescription: researchAndEvaluationData?.outcomeDescription,
          researchApproach: researchAndEvaluationData?.researchApproach,
          strength: researchAndEvaluationData?.strength,
          dataDescription: {
            selected: researchAndEvaluationData?.dataDescription?.selected,
            optional: dataDesc,
          },
        },
        'researchAndEvaluation',
      );
      setProcess();
    }
  }, [isSaveProgress, submitResponse]);


  const handleDataChange = (
    event: any,
    key: string,
    parentKey: string,
    type: string,
    isRequired?: boolean,
  ) => {

    // eslint-disable-next-line no-unsafe-optional-chaining
    let newOutputValues=[...researchAndEvaluationData?.environmentalOutputValues!]
 
    // eslint-disable-next-line no-underscore-dangle
    const label:any=vocabularyData?.sccConversion?.filter((voc:any)=>voc?._id===event.target.value)
    const index:any= researchAndEvaluationData?.environmentalOutputs && researchAndEvaluationData?.environmentalOutputs?.selected?.indexOf(event?.target?.value!);
    if (parentKey !== '') {
      if (event.target.checked) {
        if (type !== 'typeCheck') {
          setResearchAndEvaluationData((prevState: any) => ({
            ...prevState,
            [parentKey]: {
              ...prevState[parentKey],
              [key]: event.target.value,
            },
          }));
        } else {
         
          newOutputValues=[...newOutputValues,{quantity:0,description:label[0]?.label}]
          setResearchAndEvaluationData((prevState: any | undefined) => ({
            ...prevState,
            [parentKey]: {
              [key]: [...(prevState?.[parentKey]?.[key] || []), event.target.value],
            },
            environmentalOutputValues:newOutputValues
            
          }));
          setEnvironmentalOutputValues(newOutputValues)
        }
      }
      if (!event.target.checked) {
        if (parentKey !== '') {
          if (type !== 'typeCheck') {
            setResearchAndEvaluationData((prevState: any) => ({
              ...prevState,
              [parentKey]: {
                ...prevState[parentKey],
                [key]: event.target.value,
              },
            }));
          } else {
            newOutputValues?.splice(index!,1)
            setResearchAndEvaluationData((prevState: any | undefined) => ({
              ...prevState,
              [parentKey]: {
                ...prevState[parentKey],
                [key]: prevState[parentKey]?.[key].filter(
                  (item: any) => item !== event.target.value,
                ),
              },
              environmentalOutputValues:newOutputValues
            }));
            setEnvironmentalOutputValues(newOutputValues)
          }
        } else {
          setResearchAndEvaluationData((prevState: any | undefined) => ({
            ...prevState,
            [key]: prevState[key].filter((item: any) => item !== event.target.value),
          }));
        }
      }
    }
    if (parentKey === '') {
      if (type !== 'typeCheck') {
        setResearchAndEvaluationData((prevState: any) => ({
          ...prevState,
          [key]: event.target.value,
        }));
      }
      if (event.target.checked) {
        if (key === 'researchApproach') {
          setResearchAndEvaluationData((prevState: any | undefined) => ({
            ...prevState,
            [key]: [...(prevState?.[key] || []), { _id: event.target.value }],
          }));
        } else if (key === 'outcomeDescription') {
          setOutcomeDescriptionKey(event.target.value);
          setResearchAndEvaluationData((prevState: any | undefined) => ({
            ...prevState,
            outcomeDescription: [
              event.target.value,
              ...(prevState?.outcomeDescription.slice(1) || []),
            ],
          }));
        } else {
          setResearchAndEvaluationData((prevState: any | undefined) => ({
            ...prevState,
            [key]: [...(prevState?.[key] || []), event.target.value],
          }));
        }
      } else if (!event.target.checked) {
        if (key === 'researchApproach') {
          /* eslint-disable no-underscore-dangle */
          setResearchAndEvaluationData((prevState: any | undefined) => ({
            ...prevState,
            researchApproach: prevState?.researchApproach?.filter(
              (item: any) => item._id !== event.target.value,
            ),
          }));
        } else {
          setResearchAndEvaluationData((prevState: any | undefined) => ({
            ...prevState,
            [key]: prevState[key].filter((item: any) => item !== event.target.value),
          }));
        }
      }
    }
    if (isRequired) {
      if (parentKey !== '') {
        setvalidateAnswers((prevState: any) => ({
          ...prevState,
          [parentKey]: {
            ...prevState[parentKey],
            [key]: validateFields(event.target.value),
          },
        }));
      } else {
        setvalidateAnswers((prevValues: any) => ({
          ...prevValues,
          [key]: validateFields(event.target.value),
        }));
      }
    }
  };

  useEffect(() => {
    const count = Object.values(validatedAnswers)?.filter((value) => value === 1)?.length;
    validatedQuestions(count);
  }, [validatedAnswers]);

  const handleFile = (data: any) => {
    setResearchAndEvaluationData((prevState: any) => ({
      ...prevState,
      researchFile: data,
    }));
  };

  useEffect(() => {
    setResearchAndEvaluationData((prevState: any) => ({
      ...prevState,
      strength: strengthKey,
    }));
  }, [strengthKey]);

  const getFieldValue = (indexNumber: number, field: string) => {
    let value;
    researchAndEvaluationData?.environmentalOutputValues?.map((item, index) => {
        if(index===indexNumber){
            if(field==='quantity'){
                value = item.quantity
            }
            if(field==='description'){
                value = item.description
            }
        }
        return 1;
    })
    return value;
}
useEffect(()=>{

  if(researchAndEvaluationData && researchAndEvaluationData?.environmentalOutputs?.selected?.length===0){
   setEnvironmentalOutputValues([])
  }
    },[researchAndEvaluationData?.environmentalOutputs?.selected])
const handleLifetimeOutputChange = (field: string, index: number, value:any,lifetimeOutput:any)  => {
  
  // eslint-disable-next-line no-unsafe-optional-chaining
  const newLifetimeOutputs = [...researchAndEvaluationData?.environmentalOutputValues!];

  const label:any=vocabularyData?.sccConversion?.filter((item:any)=>item?._id===lifetimeOutput)
  newLifetimeOutputs[index] = { ...newLifetimeOutputs[index], [field]: value,description:label&& label[0]?.label };

  if (Number(value) >= 0) {
    setResearchAndEvaluationData((prevValues:any)=>({...prevValues,environmentalOutputValues:newLifetimeOutputs}))
    setEnvironmentalOutputValues(newLifetimeOutputs)
  }
};

const commonTextStyle = {
  heading: {
    fontSize: fonts.fontSize18,
    fontWeight: fonts.fontWeight600,
    color: colors.textPrimary,
  },
  labelStyle: {
    fontSize: fonts.fontSize16,
    fontWeight: fonts.fontWeight600,
    marinBottom: '3px',
    color: colors.textPrimary,
  },
  textFieldStyle: {
    height: '41px !important',
    borderRadius: '5px',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      padding: '11px 10px',
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fntWeight400,
    }
  }
}


  return (
    <>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 1 of 9
          {submitted && (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="Please upload the research or evaluation that informs your program design."
          disabled={submitted}
        />
        <UploadFileComponent
          imageFile={(researchFileDetails) => handleFile(researchFileDetails)}
          researchFileData={researchAndEvaluationData && researchAndEvaluationData?.researchFile}
          formId={formId}
        />
      </BoxSection>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 2 of 9
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <Grid item xs={7}>
          <TextAreaInput
            title="How would you describe the evidence or data?"
            disabled={submitted}
            description="Please note, this information helps us drive additional value your organization is creating through the use of methods that have been researched and evaluated. This should be program-specific, not at an organizational level"
          />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CheckBoxInput
              label="N/A - no research or evaluation informs program design"
              type="checkbox"
              value={isEvidence.na}
              disabled={
                submitted ||
                (researchAndEvaluationData &&
                  researchAndEvaluationData?.evidenceDescription?.selected?.length > 0)
              }
              onChange={(event) =>
                setIsEVidence((prevState: any) => ({
                  ...prevState,
                  na: event.target.checked,
                }))
              }
            />
          </Grid>
          {vocabularyData &&
            vocabularyData?.studyEvidenceType?.map((evidence: any) => {
              /* eslint-disable no-underscore-dangle */
              const selectedValues =
                researchAndEvaluationData?.evidenceDescription &&
                researchAndEvaluationData?.evidenceDescription?.selected?.filter(
                  (value) => value === evidence._id,
                );
              const checkedValue = selectedValues && selectedValues[0];
              return (
                <Grid item xs={4} key={evidence.label}>
                  <CheckBoxInput
                    label={evidence.label}
                    type="checkbox"
                    disabled={submitted}
                    value={evidence._id}
                    onChange={(event) =>
                      handleDataChange(event, 'selected', 'evidenceDescription', 'typeCheck', true)
                    }
                    checked={evidence._id === checkedValue}
                  />
                </Grid>
              );
            })}
          <Grid item xs={4}>
            <CheckBoxInput
              label="Other"
              type="checkbox"
              disabled={
                submitted ||
                (researchAndEvaluationData &&
                  researchAndEvaluationData?.evidenceDescription?.optional !== '')
              }
              value={isEvidence.others}
              onChange={(event) =>
                setIsEVidence((prevState: any) => ({
                  ...prevState,
                  others: event.target.checked,
                }))
              }
              checked={evidenceDesc !== ''}
            />
          </Grid>
          <Grid item xs={12} sx={{ pt: 4 }}>
            <Typography>Please Describe Other</Typography>
            <GridInput
              height="500px"
              disabled={submitted}
              value={evidenceDesc}
              onChange={(event) => {
                setEvidenceDesc(event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </BoxSection>
      {!isEvidence.na && (
        <BoxSection>
          <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
            Question 3 of 9
            {submitted && (
              <img
                src={Progressed}
                style={{ height: '14px', marginLeft: '5px' }}
                alt="Progressed"
              />
            )}
          </Typography>
          <TextAreaInput
            title="How would you describe the study design?"
            description="Please select all that apply."
            disabled={submitted}
          />
          <Grid container spacing={2}>
            {vocabularyData &&
              vocabularyData?.studyDesignType?.map((design: any) => {
                const selectedValues =
                  researchAndEvaluationData?.studyDescription &&
                  researchAndEvaluationData?.studyDescription?.selected?.filter(
                    (value) => value === design._id,
                  );
                const checkedValue = selectedValues && selectedValues[0];
                return (
                  <Grid item xs={4} key={design.label}>
                    <CheckBoxInput
                      label={design.label}
                      type="checkbox"
                      disabled={submitted}
                      value={design._id}
                      onChange={(event) =>
                        handleDataChange(event, 'selected', 'studyDescription', 'typeCheck')
                      }
                      checked={design._id === checkedValue}
                    />
                  </Grid>
                );
              })}
          </Grid>
          <Grid container sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={4}>
              <CheckBoxInput
                label="Economic enpowerment and workforce development"
                type="checkbox"
                disabled={submitted}
                checked={researchAndEvaluationData?.studyDescription?.optional !== ''}
              />
            </Grid>
            <Grid item xs={8} sx={{ pl: 4 }}>
              <Typography>Please Describe Other</Typography>
              <GridInput
                height="500px"
                disabled={submitted}
                value={studyDesc}
                onChange={(event) => setStudyDesc(event.target.value)}
              />
            </Grid>
          </Grid>
        </BoxSection>
      )}
      {!isEvidence.na && (
        <BoxSection>
          <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
            Question 4 of 9
            {submitted && (
              <img
                src={Progressed}
                style={{ height: '14px', marginLeft: '5px' }}
                alt="Progressed"
              />
            )}
          </Typography>
          <TextAreaInput
            title="How would you describe the data in the study?"
            description="Please select all that apply."
            disabled={submitted}
          />
          <Grid container spacing={2}>
            {vocabularyData &&
              vocabularyData?.researchDataType?.map((research: any) => {
                const selectedValues =
                  researchAndEvaluationData?.dataDescription &&
                  researchAndEvaluationData?.dataDescription?.selected?.filter(
                    (value) => value === research._id,
                  );
                const checkedValue = selectedValues && selectedValues[0];
                return (
                  <Grid item xs={4} key={research.label}>
                    <CheckBoxInput
                      label={research.label}
                      type="checkbox"
                      disabled={submitted}
                      value={research._id}
                      onChange={(event) =>
                        handleDataChange(event, 'selected', 'dataDescription', 'typeCheck')
                      }
                      checked={research._id === checkedValue}
                    />
                  </Grid>
                );
              })}
            <Grid item xs={4}>
              <CheckBoxInput
                label="Other"
                type="checkbox"
                disabled={submitted}
                checked={dataDesc !== ''}
              />
            </Grid>
            <Grid item xs={12} sx={{ pt: 4 }}>
              <Typography>Please Describe Other</Typography>
              <GridInput
                height="500px"
                disabled={submitted}
                value={dataDesc}
                onChange={(event) => setDataDesc(event.target.value)}
              />
            </Grid>
          </Grid>
        </BoxSection>
      )}
      {!isEvidence.na && (
        <BoxSection>
          <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
            Question 5 of 9
            {submitted && (
              <img
                src={Progressed}
                style={{ height: '14px', marginLeft: '5px' }}
                alt="Progressed"
              />
            )}
          </Typography>
          <TextAreaInput
            title="How would you describe the corelation of your program to your outcomes based on the study?"
            disabled={submitted}
          />
          <Grid container spacing={2}>
            {vocabularyData &&
              vocabularyData?.effect?.map((effect: any) => (
                <Grid item xs={3} key={effect.label}>
                  <CheckBoxInput
                    label={effect.label}
                    type="radio"
                    disabled={submitted}
                    value={effect._id}
                    onChange={(event) =>
                      handleDataChange(event, 'outcomeDescription', '', 'typeCheck')
                    }
                    checked={effect._id === outcomeDescriptionKey}
                  />
                </Grid>
              ))}
          </Grid>
        </BoxSection>
      )}
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 6 of 9
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="Which strategies and approaches align with the research or evaluation this program design is based on?"
          description="Please select atleast one and up to three."
          disabled={submitted}
        />
        {vocabularyData && (
          <ScrollBox submitted={submitted}>
            {vocabularyData?.causes?.map((cause: any) => {
              /* eslint-disable no-underscore-dangle */
              const filteredImpacts = vocabData.impact.filter(
                (item: any) => item.causeId === cause._id,
              );
              return (
                <BoxSection key={cause.label}>
                  <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
                    {cause.label}
                  </Typography>
                  <Grid container spacing={2}>
                    {filteredImpacts?.map((impact: any) => {
                      const filteredArray = researchAndEvaluationData?.researchApproach?.filter(
                        (obj) => obj._id === impact._id,
                      );
                      const checkedValue = filteredArray && filteredArray[0]?._id;
                      return (
                        <Grid item xs={4} key={impact.label}>
                          <CheckBoxInput
                            label={impact.label}
                            type="checkbox"
                            disabled={submitted}
                            value={impact._id}
                            onChange={(event) =>
                              handleDataChange(event, 'researchApproach', '', 'typeCheck', true)
                            }
                            checked={impact._id === checkedValue}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </BoxSection>
              );
            })}
          </ScrollBox>
        )}
      </BoxSection>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 7 of 9
          {submitted && (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <Grid item xs={7}>
          <TextAreaInput
            title="What environmental outputs does this program create?"
            disabled={submitted}
            description="This question was added because you selected a environmental strategies and approaches. Please select all that apply"
          />
        </Grid>
        <ScrollBox submitted={submitted}>
          <Grid container spacing={2}>
            {vocabularyData &&
              vocabularyData?.sccConversion?.map((ssc: any) => {
                const selectedValues =
                  researchAndEvaluationData?.environmentalOutputs &&
                  researchAndEvaluationData?.environmentalOutputs?.selected?.filter(
                    (value) => value === ssc._id,
                  );
                const checkedValue = selectedValues && selectedValues[0];
                return (
                  <Grid item xs={4} key={ssc.label}>
                    <CheckBoxInput
                      label={ssc.label}
                      type="checkbox"
                      disabled={submitted}
                      value={ssc._id}
                      // onChange={handleChangeSelect}
                      onChange={(event) =>
                        handleDataChange(event, 'selected', 'environmentalOutputs', 'typeCheck')
                      }
                      checked={ssc._id === checkedValue}
                    />
                  </Grid>
                );
              })}
          </Grid>
          <Grid item xs={12} sx={{ pt: 4 }}>
            <Typography>Please Describe Other</Typography>
            <GridInput
              height="500px"
              disabled={submitted}
              value={environmentOutputDesc}
              onChange={(event) => setEnvironmentalOutputDesc(event.target.value)}
            />
          </Grid>
        </ScrollBox>
      </BoxSection>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 8 of 9
          {submitted && (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography sx={commonTextStyle.heading}>Environmental Output Values</Typography>
        </Grid>
        {researchAndEvaluationData?.environmentalOutputs?.selected?.map((lifetimeOutput: any, index:any) => {
              const label:any=vocabularyData?.sccConversion?.filter((item:any)=>item?._id===lifetimeOutput)
              return(
              <Grid container spacing={4} key={lifetimeOutput.key}>
                <Grid item xs={12} sm={3} mb={2}>
                  <Typography sx={commonTextStyle.labelStyle}>Quantity </Typography>
                  <TextField
                    name="quantity"
                    type="number"
                    value={getFieldValue(index, 'quantity')}
                    onChange={(event)=>{handleLifetimeOutputChange('quantity', index,event.target.value,lifetimeOutput)}}
                    variant="outlined"
                    className="inputTextFiled"
                    fullWidth
                    sx={commonTextStyle.textFieldStyle}
                  />
                </Grid>
                <Grid item xs={12} sm={6} mb={2}>
                  <Typography sx={commonTextStyle.labelStyle}>Output </Typography>
                  <TextField
                    name="description"
                    value={getFieldValue(index, 'description')}
                    type="text"
                    onChange={()=>{handleLifetimeOutputChange('description', index, label && label[0]?.label!,lifetimeOutput)}}
                    // onChange={(event) => {
                    //   handleIndexChange(event.target.value, 'quantity', index);
                    // }}
                    sx={commonTextStyle.textFieldStyle}
                    variant="outlined"
                    className="inputTextFiled"
                    fullWidth
                  />
                </Grid>
              </Grid>
            )})}
      </BoxSection>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 9 of 9
          {submitted && (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <Grid item xs={7}>
          <TextAreaInput
            title="Please add numeric values for the environmental outputs this program creates?"
            disabled={submitted}
            description="Outputs are purely numeric values that are proportionate to your budget. If your program was completely funded, these would be the metrics you report"
          />
          {/* <TextAreaInput
            secondaryTitle="Description"
            infoIcon
            tooltipText="Description"
            isTextField
            required
            disabled={submitted}
            // value={researchAndEvaluationData && researchAndEvaluationData?.strength}
            // onChange={(event) => handleDataChange(event, 'strength', '', '')}
          /> */}
        </Grid>
        <Grid container spacing={2}>
          {vocabularyData &&
            vocabularyData?.strength?.map((strengthDet: any) => (
              <Grid item xs={4} key={strengthDet?._id}>
                <CheckBoxInput
                  label={strengthDet?.label}
                  type="radio"
                  disabled={submitted}
                  value={strengthDet?._id}
                  onChange={(event) => setStrengthKey(event.target.value)}
                  checked={strengthDet?._id === strengthKey}
                />
              </Grid>
            ))}
        </Grid>
      </BoxSection>
    </>
  );
};

export default ResearchEvaluation;
