import { Box, TextField, Typography } from '@mui/material';
import { Edit, Save } from '@mui/icons-material';
import React, { FC, useState } from 'react';
import { FlexBox, FlexBoxColumn } from '../../shared/components';
import CurrencySymbol from './CurrencySymbol';
import CheckIcon from '../../assets/svg/check-icon.png';
import { DEFAULT_FUNDING_VALUE_PROGRAM_PREVIEW } from '../../shared/constants';

const checkIconStyle = { width: '25px', height: '25px' };

interface Props {
  strengths?: Array<string>;
  contributionAmount?: number;
  currency?: string;
  setFunding?: (funding: number) => void;
}
interface fundingProps {
  contributionAmount?: number;
  currency?: string;
  setFunding?: (funding: number) => void;
}
const GetCopy: FC<fundingProps> = ({ contributionAmount, currency, setFunding }) => {
  const [editing, setEditing] = useState(false);
  // eslint-disable-next-line no-restricted-globals
  const [value, setValue] = useState(
    Number.isNaN(contributionAmount as number)
      ? DEFAULT_FUNDING_VALUE_PROGRAM_PREVIEW
      : contributionAmount,
  );
  const handleSave = () => {
    if (editing) {
      if (setFunding && value) {
        setEditing(false);
        setFunding(value);
      }
    } else {
      setEditing(true);
    }
  };
  if (setFunding) {
    return (
      <>
        <Typography>
          This content has been provided by the social impact organization and is subject to change.
          Projected outcomes are based on estimated funding of
        </Typography>
        <Box className="sio_funding_amount__container">
          <CurrencySymbol currency={currency} />
          {editing ? (
            <>
              <TextField
                type="number"
                value={value}
                onChange={(e) => setValue(parseInt(e.target.value, 10))}
              />
              <Save onClick={handleSave} />
            </>
          ) : (
            <Typography className="sio_funding_amount">
              {value} <Edit onClick={handleSave} />
            </Typography>
          )}
        </Box>
      </>
    );
  }
  return (
    <Typography>
      This content has been provided by the social impact organization and is subject to change.
      Projected outcomes are based on estimated funding.
    </Typography>
  );
};
const ProgramStrength: FC<Props> = ({ currency, strengths, contributionAmount, setFunding }) => (
  <FlexBox className="programStrength">
    <FlexBoxColumn className="programStrength__sub">
      <Typography>PROGRAM STRENGTH</Typography>
      {strengths ? (
        <FlexBox>
          {strengths.map((strength) => (
            <Box sx={{ display: 'flex' }} key={strength}>
              <img src={CheckIcon} alt="" style={checkIconStyle} />
              <div className="programStrength__descriptions">{strength}</div>
            </Box>
          ))}
        </FlexBox>
      ) : null}
    </FlexBoxColumn>

    <Box className="contributionAmountntntDisclaimer__container">
      <Box className="FundingAmountDisclaimer__container">
        <GetCopy
          setFunding={setFunding}
          currency={currency}
          contributionAmount={contributionAmount}
        />
      </Box>
    </Box>
  </FlexBox>
);

export default ProgramStrength;
