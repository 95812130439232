import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
} from '@mui/material';

interface FilterDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onApply: (dateOrder: 'asc' | 'desc', statusFilter: string | null) => void;
  onReset: () => void;
  dateOrder: 'asc' | 'desc';
  setDateOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  statusFilter: string | null;
  setStatusFilter: React.Dispatch<React.SetStateAction<string | null>>;
}

const FilterDialog: React.FC<FilterDialogProps> = ({
  isOpen,
  onClose,
  onApply,
  onReset,
  dateOrder,
  setDateOrder,
  statusFilter,
  setStatusFilter,
}) => {
  const theme = useTheme();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Filter Options</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
          <InputLabel>Date Order</InputLabel>
          <Select
            value={dateOrder}
            onChange={(e) => setDateOrder(e.target.value as 'asc' | 'desc')}
            autoWidth
            sx={{
              background: theme.palette.background.paper,
              borderRadius: theme.shape.borderRadius,
            }}
          >
            <MenuItem value="asc">Ascending</MenuItem>
            <MenuItem value="desc">Descending</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="dense">
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter || ''}
            onChange={(e) => setStatusFilter(e.target.value || null)}
            displayEmpty
            autoWidth
            sx={{
              background: theme.palette.background.paper,
              borderRadius: theme.shape.borderRadius,
            }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="in_progress">In Progress</MenuItem>
            <MenuItem value="submitted">Submitted</MenuItem>
            <MenuItem value="denied">Denied</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', padding: theme.spacing(2) }}>
        <Button onClick={onReset} color="warning">
          Reset
        </Button>
        <div>
          <Button onClick={onClose} sx={{ marginRight: theme.spacing(1) }}>
            Close
          </Button>
          <Button
            onClick={() => {
              onApply(dateOrder, statusFilter);
              onClose();
            }}
            variant="contained"
            color="primary"
          >
            Apply
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
