import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { contributionProposalVerifyThunk } from '../incentive';
import { ProposalPage } from '../ProposalPage/ProposalPage';
import { ShareLoginPage } from './ShareLoginPage';
import LoginPage from './LoginPage';

export const PasswordProtectedPage = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { error, loading, incentive } = useAppSelector((x) => x.incentive);
  const dispatch = useAppDispatch();

  const testing = useParams();
  const orderId = testing['*']?.split('/')[1];

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (pathname.includes('proposal') && incentive) {
      sessionStorage.setItem('isProposalAuthorised', 'true');
      sessionStorage.setItem('loggedId', orderId!);
    }
  }, [incentive, orderId, pathname]);


  useEffect(() => {
    if (sessionStorage.getItem('isProposalAuthorised') === 'true' && sessionStorage.getItem('loggedId') === orderId) {
      setIsLoggedIn(true);
    }
  });

  const proposalLoginFunction = async (password: string) => {
    
    if (orderId) {
      dispatch(contributionProposalVerifyThunk({ id: orderId, password }));
    }
  };
  const loginFunction = (password: string) => {
    if (pathname.includes('proposal')) {
      proposalLoginFunction(password);
    }
  };
  
  if (isLoggedIn) {
    return (
      <Routes>
        <Route element={<ProposalPage />} path="proposal/:incentiveId" />
        {/* I am just adding this as an example so that in the future we can add more pages that are sharable and password protected */}
        {/* <Route element={<div>Other Page</div>} path="otherpage" /> */}
      </Routes>
    );
  }
  if (!isLoggedIn && pathname.includes('proposal')) {
    return <LoginPage onLogin={loginFunction} loading={loading} />;
  }
  return <ShareLoginPage onLogin={loginFunction} errorMessage={error} loading={loading} />;
};
