import React, { FC } from 'react';

interface Props {
  image: string;
}

const ProgramImage: FC<Props> = ({ image }) => {
  const imageUrl = image?.includes('placeholder')
    ? 'https://scalewith-assets.s3.amazonaws.com/Social+Impact+Placeholder_partial%402x.png'
    : image;
  return <img alt="program" className="programImage__containerImage pdf-image" src={imageUrl} />;
};
export default ProgramImage;
