import React, { FC } from 'react';
import _ from 'lodash';
import { DeleteOutlineOutlined, ModeEditOutlineOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import {
  FlexBox,
  MoneyBalanceWithLocaleSymbol,
  PaddingRoundedBox,
} from '../../../shared/components';
import { useAppDispatch, useAppSelector, useAuth } from '../../../shared/hooks';
import { ApprovalLevelCard } from '../../../shared/interfaces';
import {
  setApprovalLevelsMode,
  removeApprovalLevel,
  setApprovalLevelsForm,
} from './accountSettingsSlice';
import { ENABLE_DELETE } from '../../../shared/constants';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface Props {
  formDisabled?: boolean;
}

export const ApprovalLevelsList: FC<Props> = (props) => {
  const state = useAppSelector((x) => x.accountSettings.approvalLevels);
  const dispatch = useAppDispatch();
  const { data } = state;
  const { formDisabled } = props;
  const [isUserInRole] = useAuth();

  const approvalLevelListItem = (value: ApprovalLevelCard) => (
    <FlexBox
      sx={{
        borderRadius: 2,
        backgroundColor: '#fff',
        gap: 2,
        alignItems: 'center',
        padding: 2,
      }}
      key={`account-approval-level-${value.approver.id}`}
    >
      <FlexBox sx={{ flexDirection: 'column', marginRight: 2, flex: 1 }}>
        <Typography sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }}>
          Level {value.level}
        </Typography>
      </FlexBox>
      <FlexBox sx={{ flexDirection: 'column', alignItems: 'end', marginRight: 8, flex: 1 }}>
        <Typography variant="body2" fontWeight="bold">
          UP TO AMOUNT
        </Typography>
        <MoneyBalanceWithLocaleSymbol variant="h6">{value?.amount}</MoneyBalanceWithLocaleSymbol>
      </FlexBox>
      <FlexBox sx={{ flexDirection: 'column', alignItems: 'center', marginRight: 2, flex: 1 }}>
        <Typography variant="body2" fontWeight="bold">
          APPROVER
        </Typography>
        <Typography variant="h6">{value.approver.name}</Typography>
      </FlexBox>
      <IconButton
        disabled={formDisabled}
        onClick={() => {
          dispatch(setApprovalLevelsForm(value));
          dispatch(setApprovalLevelsMode('edit'));
        }}
      >
        <ModeEditOutlineOutlined />
      </IconButton>
      <IconButton
        disabled={formDisabled && !isUserInRole(ENABLE_DELETE)}
        onClick={() =>
          dispatch(removeApprovalLevel({ id: value.approver.id, amount: value.amount }))
        }
      >
        <DeleteOutlineOutlined />
      </IconButton>
    </FlexBox>
  );

  const orderedApprovalLevels = _.orderBy(data!.levels, (x) => x.amount, 'desc');
  const orderedLevelAmounts = _(orderedApprovalLevels)
    .map((x) => x.amount)
    .uniq()
    .value();

  return (
    <PaddingRoundedBox
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        backgroundColor: theme.palette.common.grey.main,
      })}
    >
      <FlexBox>
        <Typography sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }} flex={1}>
          Approval Levels
        </Typography>
        <Box sx={{ backgroundColor: '#fff' }}>
          <Button
            disabled={formDisabled}
            sx={{ fontSize: fonts.fontSize14, fontWeight: fonts.fontWeight900 , backgroundColor: colors.primary }}
          variant="contained"
          size="large"
          color="success"
            onClick={() => dispatch(setApprovalLevelsMode('new'))}
          >
            New Level
          </Button>
        </Box>
      </FlexBox>
      {orderedApprovalLevels.map((x) =>
        approvalLevelListItem({
          ...x,
          level: orderedLevelAmounts.length - orderedLevelAmounts.indexOf(x.amount),
        }),
      )}
    </PaddingRoundedBox>
  );
};
