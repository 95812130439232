import { Box, Typography, Paper, Grid, useMediaQuery, Button } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Loading } from '../../../shared/components';
import {
  useAppSelector,
  useAppDispatch,
  useAuth,
  useInstance,
  useAccount,
} from '../../../shared/hooks';
import { isNullOrWhitespace, getGridRequest } from '../../../shared/utils';

import {
  clearSelectAmountState,
  setSelectAmountFormData,
  putContributionThunk,
  postContributionThunk,
  getSupplierCustomFieldsThunk,
} from './selectAmountSlice';
import { getCompanyEntitesThunk } from '../../company-intelligence/companiesSlice';
import { Contribution } from '../../../shared/interfaces';

import { AccountType, ContributionStatus } from '../../../shared/enums';
import { refreshAppAccountThunk } from '../../../app';
import { clearContributionState, setSelectedProgram } from '../incentiveSlice';
import {
  APPROVE_DENY_PERMISSIONS,
  COMPLETE_ABANDON_PERMISSIONS,
  FULFILL_REJECT_PERMISSIONS,
} from '../../../shared/constants';
import { ContributionDetails } from '../details/ContributionDetails';
import { ProposalPage } from '../../ProposalPage/ProposalPage';
import { SelectProgram } from '../select-program/SelectProgram';
import { IncentiveActionButtons } from './IncentiveActionButtons';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

export type GiveMode = 'new' | 'details' | 'draft';

interface Props {
  mode?: GiveMode;
}

export const SelectAmount: FC<Props> = (props) => {
  const { loadingUserData, locale, instance } = useInstance();
  const appState = useAppSelector((x) => x.app);
  const { grid } = useAppSelector((state) => state.company);
  const { data } = grid;
  const { results: buyerResult } = data;
  const { account: accountState } = { ...appState };
  const selectState = useAppSelector((x) => x.selectAmount);
  const incentiveState = useAppSelector((x) => x.incentive);
  const updateIncentiveSuccess = useAppSelector((x) => x.updateContributionStatus.success);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isUserInRole] = useAuth();
  const { isAccountInType } = useAccount();
  const { mode } = props;
  const {
    selectedProgram,
    loading: incentiveLoading,
    incentive: existingIncentive,
    canApproveDenyIncentive,
  } = incentiveState;
  const { account: createdBy } = { ...existingIncentive };
  const { incentiveFields } = { ...accountState };
  const {
    customField1,
    customField2,
    customField3,
    customField4,
    customerId: customerIdCustomField,
    customerName: customerNameCustomField,
    quoteAmount: quoteAmountCustomField,
    quoteNumber: quoteNumberCustomField,
    description: descriptionCustomField,
  } = { ...incentiveFields };
  const { formData, success, loadingCustomFields } = selectState;
  const {
    customerName,
    grandTotal,
    customerId,
    quoteAmount,
    quoteNumber,
    customForm1,
    customForm2,
    customForm3,
    customForm4,
    description,
    emails,
  } = formData;
  const isReadonly = mode === 'details' && existingIncentive?.status !== ContributionStatus.Draft;
  const isProcurementUser = isAccountInType([AccountType.procurement]);
  const [fundingAmount, setFundingAmount] = useState(0);
  // const matches = useMediaQuery('(max-width:1024px)');
  const is1440 = useMediaQuery('(max-width:2559px)');
  const [salesOpportunityExpanded, setSalesOpportunityExpanded] = useState(true);

  useEffect(() => {
    if (!success && !updateIncentiveSuccess) return;
    navigate('/app');
  }, [dispatch, navigate, success, updateIncentiveSuccess]);

  const getCompany = () => {
    dispatch(
      getCompanyEntitesThunk({
        name: accountState?.invitedBy?.name || null,
        ...getGridRequest(grid),
      }),
    );
  };

  useEffect(() => {
    dispatch(refreshAppAccountThunk());
    if (accountState?.type === 'supplier') {
      getCompany();
    }
  }, [dispatch]);

  useEffect(() => {
    if (accountState?.type === 'supplier') {
      dispatch(
        setSelectAmountFormData({
          customerId: buyerResult[0]?.id || '',
          customerName: accountState?.invitedBy?.name || '',
        }),
      );
    }
  }, [buyerResult.length]);

  useEffect(() => {
    if (!isReadonly || !createdBy || !existingIncentive || !isProcurementUser || !accountState?.id)
      return;

    dispatch(getSupplierCustomFieldsThunk({ accountId: accountState.id, id: createdBy.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdBy, existingIncentive]);

  useEffect(() => {
    if (mode === 'details') return;
    let defaultValues = {};
    if (!isNullOrWhitespace(customerIdCustomField?.defaultValue))
      defaultValues = { ...defaultValues, customerId: customerIdCustomField?.defaultValue! };
    if (!isNullOrWhitespace(customerNameCustomField?.defaultValue))
      defaultValues = { ...defaultValues, customerName: customerNameCustomField?.defaultValue! };
    if (quoteAmountCustomField?.defaultValue)
      defaultValues = {
        ...defaultValues,
        quoteAmount: Number(quoteAmountCustomField?.defaultValue!),
      };
    if (!isNullOrWhitespace(quoteNumberCustomField?.defaultValue))
      defaultValues = { ...defaultValues, quoteNumber: quoteNumberCustomField?.defaultValue! };
    if (!isNullOrWhitespace(customField1?.defaultValue))
      defaultValues = { ...defaultValues, customForm1: customField1?.defaultValue! };
    if (!isNullOrWhitespace(customField2?.defaultValue))
      defaultValues = { ...defaultValues, customForm2: customField2?.defaultValue! };
    if (!isNullOrWhitespace(customField3?.defaultValue))
      defaultValues = { ...defaultValues, customForm3: customField3?.defaultValue! };
    if (!isNullOrWhitespace(customField4?.defaultValue))
      defaultValues = { ...defaultValues, customForm4: customField4?.defaultValue! };
    if (!isNullOrWhitespace(descriptionCustomField?.defaultValue))
      defaultValues = { ...defaultValues, description: descriptionCustomField?.defaultValue! };
    dispatch(setSelectAmountFormData({ ...defaultValues }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(clearContributionState());
      dispatch(clearSelectAmountState());
    },
    [dispatch],
  );
  useEffect(() => {
    if (formData && formData.grandTotal && formData.grandTotal > 0) {
      const serviceFee = instance?.settings?.serviceFee || 0.2;
      setFundingAmount(formData.grandTotal - formData.grandTotal * serviceFee);
    }
  }, [formData.grandTotal]);

  const isApproval =
    formData?.maxApprovalAmount !== '' && formData?.maxApprovalAmount !== undefined
      ? Number(formData?.maxApprovalAmount) > formData?.grandTotal!
      : true;

  const getActionTitleByStatus = (status?: string) => {
    switch (status) {
      case ContributionStatus.PendingApproval: {
        return isUserInRole(APPROVE_DENY_PERMISSIONS) && isApproval
          ? 'Approve this Contribution'
          : null;
      }
      case ContributionStatus.Draft: {
        return 'Complete this contribution';
      }
      case ContributionStatus.Approved: {
        return isUserInRole(COMPLETE_ABANDON_PERMISSIONS) ? 'Complete this Contribution' : null;
      }
      case ContributionStatus.Completed: {
        return isUserInRole(FULFILL_REJECT_PERMISSIONS) ? 'Fulfill this Contribution' : null;
      }
      case ContributionStatus.Fulfilled: {
        return 'This contribution has been fulfilled';
      }
      case ContributionStatus.Denied:
      case ContributionStatus.Abandoned:
      case ContributionStatus.Rejected:
        return null;
      default:
        return 'Create New Social Impact Contribution';
    }
  };

  console.log(salesOpportunityExpanded);

  function getIncentiveData(status: ContributionStatus, incentive?: Contribution): Contribution {
    const program = incentive?.program;
    return {
      id: incentive?.id,
      account: {
        ...incentive?.account,
        id: accountState?.id ?? '-1',
        name: accountState?.company?.name,
      },

      currency: locale?.settings.currency ?? '',
      grandTotal: grandTotal ?? 0,
      status,
      customerName,
      customerId,
      quoteAmount,
      quoteNumber,
      description,
      ...(!isNullOrWhitespace(customForm1) && { customField1: customForm1 }),
      ...(!isNullOrWhitespace(customForm2) && { customField2: customForm2 }),
      ...(!isNullOrWhitespace(customForm3) && { customField3: customForm3 }),
      ...(!isNullOrWhitespace(customForm4) && { customField4: customForm4 }),
      comments: incentive?.comments ?? [],
      program,
      selectedProgram,
      emails,
    };
  }

  const submitIncentive = (status: ContributionStatus) => {
    dispatch(
      !isNullOrWhitespace(existingIncentive?.id)
        ? putContributionThunk(getIncentiveData(status, existingIncentive))
        : postContributionThunk(getIncentiveData(status, existingIncentive)),
    );
  };
  const handleSelectProgram = (value: string) => {
    const val = !isNullOrWhitespace(value) ? value : undefined;
    dispatch(setSelectedProgram(val));
    dispatch(setSelectAmountFormData({ selectedProgram: val }));
  };

  const isFormInvalid = () => {
    const isCustomerNameInValid =
      (customerNameCustomField?.required && isNullOrWhitespace(formData.customerName)) ?? false;
    const isCustomerIdInValid =
      (customerIdCustomField?.required && isNullOrWhitespace(formData.customerId)) ?? false;
    const isQuoteNumberInValid =
      (quoteNumberCustomField?.required && isNullOrWhitespace(formData.quoteNumber)) ?? false;
    // const isQuoteAmountInValid =
    //   (quoteAmountCustomField?.required && formData.quoteAmount === undefined) ?? false;
    const isQuoteAmountInValid = !(formData?.quoteAmount && formData.quoteAmount > 0);
    const isGrandTotalInValid = !(formData?.grandTotal && formData.grandTotal > 0);
    const isDescriptionInValid = isNullOrWhitespace(formData.description) ?? false;
    const isCustomField1InValid =
      (customField1?.required && isNullOrWhitespace(formData.customForm1)) ?? false;
    const isCustomField2InValid =
      (customField2?.required && isNullOrWhitespace(formData.customForm2)) ?? false;
    const isCustomField3InValid =
      (customField3?.required && isNullOrWhitespace(formData.customForm3)) ?? false;
    const isCustomField4InValid =
      (customField4?.required && isNullOrWhitespace(formData.customForm3)) ?? false;
    const selectedProgramInValid = isNullOrWhitespace(formData.selectedProgram) ?? false;

    return (
      !isReadonly &&
      (isCustomField1InValid ||
        isGrandTotalInValid ||
        isCustomerNameInValid ||
        isCustomerIdInValid ||
        selectedProgramInValid ||
        isQuoteNumberInValid ||
        isQuoteAmountInValid ||
        isDescriptionInValid ||
        isCustomField2InValid ||
        isCustomField3InValid ||
        isCustomField4InValid)
    );
  };
  if (mode === 'details' && formData)
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#efefef',
            marginTop: '-0.5rem',
            marginLeft: '-2px',
            // width: `${matches ? '80%' : '86.2%'}`,
            paddingTop: '10px',
          }}
        >
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: fonts.fontSize24,
              color: colors.textPrimary,
              fontWeight: fonts.fontWeight700,
            }}
          >
            <ArrowBackIcon onClick={() => navigate(-1)} sx={{ marginRight: '10px' }} />
            {getActionTitleByStatus(existingIncentive?.status)}
          </Typography>
          <Box
            sx={{
              margin: '6.5px 0px',
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                gap: '0.5rem',
                flexWrap: 'wrap',
                alignItems: 'end',
                justifyContent: 'flex-end',
                marginRight: `${is1440 && '45px'}`,
                flex: 1,
                width: '100%',
              }}
            >
              <IncentiveActionButtons
                proposalText
                id={existingIncentive?.id || ''}
                incentiveStatus={existingIncentive?.status}
                submitIncentive={(status) => submitIncentive(status)}
                draftInvalid={
                  !isReadonly &&
                  isNullOrWhitespace(formData.customerName) &&
                  formData.grandTotal !== undefined &&
                  formData.grandTotal > 0
                }
                formInvalid={isFormInvalid()}
                canApproveDenyIncentive={canApproveDenyIncentive}
                proposal
                isApproval={isApproval}
              />
            </Grid>
          </Box>
        </div>

        <Paper
          sx={(theme) => ({
            borderRadius: theme.shape.borderRadius,
            position: 'relative',
          })}
        >
          <ProposalPage details />
        </Paper>
      </>
    );
  if (incentiveLoading || loadingUserData || loadingCustomFields)
    return <Loading loading={incentiveLoading || loadingUserData || loadingCustomFields} />;
  return (
    <Box sx={(theme) => ({ [theme.breakpoints.up('lg')]: { height: '100%' } })}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <Typography
          sx={{
            fontSize: fonts.fontSize24,
            color: colors.textPrimary,
            fontWeight: fonts.fontWeight700,
          }}
        >
          {getActionTitleByStatus(existingIncentive?.status)}
        </Typography>
        {/* {getActionTitleByStatus(existingIncentive?.status) === 'Create New Social Impact Contribution' && */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {selectedProgram && (
            <Typography
              onClick={() => submitIncentive(ContributionStatus.Draft)}
              sx={{
                textDecoration: 'underline',
                color: colors.primary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
              }}
            >
              Save as Draft
            </Typography>
          )}

          <Button
            color="success"
            size="small"
            variant="contained"
            disabled={!selectedProgram}
            onClick={() => submitIncentive(ContributionStatus.PendingApproval)}
            sx={{
              height: '48px',
              marginLeft: '30px',
              paddingX: '16px',
              fontWeight: fonts.fontWeight700,
              fontSize: fonts.fontSize14,
              backgroundColor: `${colors.primary} !important`,
              color: `${colors.secondary} !important`,
              borderRadius: '8px !important',
              '&.Mui-disabled': {
                backgroundColor: `${colors.strokeDefault} !important`,
              },
            }}
          >
            Send for Approval
            <ArrowForwardIcon style={{ marginLeft: '10px' }} />
          </Button>
        </Box>
        {/* } */}
      </Box>
      <ContributionDetails
        onFormChange={(value: any) => {
          dispatch(setSelectAmountFormData(value));
          if (
            Object.keys(value)?.[0] === 'quoteAmount' ||
            Object.keys(value)?.[0] === 'grandTotal'
          ) {
            handleSelectProgram('');
          }
        }}
        formData={formData}
        isSalesOpportunityExpanded = {(value: boolean) => setSalesOpportunityExpanded(value)}
      />

      <Box
        sx={{
          border: `1px solid ${
            formData?.customerName !== undefined &&
            formData?.customerName !== '' &&
            formData?.grandTotal !== 0 && !salesOpportunityExpanded ?
            colors.primary : colors.secondary
          }`,
          borderRadius: '15px 15px 0px 0px',
        }}
      >
        <SelectProgram
          fundingAmount={fundingAmount}
          // incentive={existingIncentive}
          // submitIncentive={(status) => submitIncentive(status)}
          // draftInvalid={
          //   !isReadonly &&
          //   isNullOrWhitespace(formData.customerName) &&
          //   formData.grandTotal !== undefined &&
          //   formData.grandTotal > 0
          // }
          // formInvalid={isFormInvalid()}
          // canApproveDenyIncentive={canApproveDenyIncentive}
          formData={formData}
          entityName={customerName}
          entityId={customerId}
          onSelect={handleSelectProgram}
        />
      </Box>
    </Box>
  );
};
