import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addCancelTokenEvent, getUserProfile, putUserProfile } from '../../../../../api';
import { RootState } from '../../../../../app';
import { User } from '../../../../../shared/interfaces';

interface MyProfileState {
  user: User;
  loading: boolean;
  success: boolean;
}

const initialState: MyProfileState = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    company: '',
    title: '',
    departmentName: '',
    departmentType: '',
    username: '',
    phoneNumber: '',
    roles: [],
  },
  loading: false,
  success: false,
};

export const getUserProfileThunk = createAsyncThunk(
  'myProfile/getUserProfileThunk',
  async (_void, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getUserProfile();
    return data;
  },
);

export const putUserProfileThunk = createAsyncThunk<number, void, { state: RootState }>(
  'myProfile/putUserProfileThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const { firstName, lastName, title, departmentName, phoneNumber } = state.myProfile.user;
    const { status } = await putUserProfile({
      firstName,
      lastName,
      title,
      departmentName,
      phoneNumber,
    });
    return status;
  },
);

const myProfileSlice = createSlice({
  name: 'myProfile',
  initialState,
  reducers: {
    setMyProfileFirstName: (state, action: PayloadAction<string>) => {
      state.user.firstName = action.payload;
    },
    setMyProfileLastName: (state, action: PayloadAction<string>) => {
      state.user.lastName = action.payload;
    },
    setMyProfileTitle: (state, action: PayloadAction<string>) => {
      state.user.title = action.payload;
    },
    setMyProfileDepartmentName: (state, action: PayloadAction<string>) => {
      state.user.departmentName = action.payload;
    },
    setMyProfilePhoneNumber: (state, action: PayloadAction<string>) => {
      state.user.phoneNumber = action.payload;
    },
    clearMyProfileState: (state) => {
      state.user = initialState.user;
      state.loading = initialState.loading;
      state.success = initialState.success;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfileThunk.fulfilled, (state, action: PayloadAction<User>) => {
        state.loading = false;
        state.user = _.merge(state.user, action.payload);
      })
      .addCase(getUserProfileThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUserProfileThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(putUserProfileThunk.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(putUserProfileThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(putUserProfileThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      });
  },
});

export const {
  setMyProfileFirstName,
  setMyProfileLastName,
  setMyProfileTitle,
  setMyProfileDepartmentName,
  setMyProfilePhoneNumber,
  clearMyProfileState,
} = myProfileSlice.actions;

export const myProfileReducer = myProfileSlice.reducer;
