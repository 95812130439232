import { Box, Button, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { clearDialog } from '../../features/main-screen/mainScreenSlice';
import { Dialogs } from '../enums';
import { useAppDispatch } from '../hooks';
import { getGenericHeight, isNullOrWhitespace } from '../utils';
import { Loading } from './Loading';
import { FlexBox } from './StyledComponents';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface Props {
  type: Dialogs;
  title: string | JSX.Element;
  subtitle?: string;
  subtitleBelow?: boolean;
  closeFlag?: boolean;
  ExtraHeaderComponent?: JSX.Element;
  Buttons?: JSX.Element;
  ExtraBellowButtonsComponent?: JSX.Element;
  hideCancel?: boolean;
  loading?: boolean;
  cancelButtonText?: string;
  childrenStyle?: any;
}

export const FormDialog: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const {
    childrenStyle,
    title,
    type,
    subtitle,
    subtitleBelow,
    loading,
    closeFlag,
    Buttons,
    hideCancel,
    children,
    ExtraHeaderComponent,
    cancelButtonText,
    ExtraBellowButtonsComponent,
  } = props;

  useEffect(() => {
    if (closeFlag) {
      dispatch(clearDialog(type));
    }
  }, [closeFlag, dispatch, type]);

  // const handleMFAClick = () => {
  //   dispatch(clearDialog(type));
  //   redirectToLogin({ authError: false }, false, mfa);
  // };

  if (loading) return <Loading loading={loading} />;

  return (
    <Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.common.grey.light,
        })}
      >
        <Box paddingX={6} paddingY={4}>
          {!isNullOrWhitespace(subtitle) && !subtitleBelow && (
            <Typography variant="h5" mb={1}>
              {subtitle}
            </Typography>
          )}

          {title && title !== '' && (
            <FlexBox sx={{ flex: 1, gap: '10px', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: fonts.fontSize24, fontWeight: fonts.fontWeight700, color: colors.textColor }}>{title}</Typography>
              {/* <Button sx={{marginRight: '10px'}}
                variant="contained" 
                size="large"
                onClick={() => handleMFAClick()}>
                  Manage MFA
              </Button> */}
            </FlexBox>
          )}

          {!isNullOrWhitespace(subtitle) && subtitleBelow && (
            <Typography variant="h5" mt={1}>
              {subtitle}
            </Typography>
          )}
        </Box>
        {ExtraHeaderComponent}
      </Box>

      <Box sx={{ overflowY: 'auto', maxHeight: getGenericHeight(330) }}>
        <Box paddingX={6} sx={{ paddingTop: childrenStyle?.paddingTop || 2 }}>
          {children}
        </Box>
      </Box>
      <Box paddingX={6} paddingY={4}>
        <FlexBox sx={{ flex: 1, gap: '10px', justifyContent: 'end' }}>
          {!hideCancel && (
            <Button
              color="info"
              size="large"
              sx={{ fontSize: fonts.fontSize14, fontWeight: fonts.fontWeight900 }}
              onClick={() => {
                dispatch(clearDialog(type));
              }}
            >
              {cancelButtonText ?? 'Cancel'}
            </Button>
          )}
          {Buttons}
        </FlexBox>
      </Box>
      {ExtraBellowButtonsComponent && (
        <Box paddingX={6} paddingBottom={4}>
          {ExtraBellowButtonsComponent}
        </Box>
      )}
    </Box>
  );
};
