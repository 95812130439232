import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { ProcurementMetricsFilters } from './procurement-metrics-filters';
import { FlexBox, Loading } from '../../shared/components';
import {
  getDateFromJanuaryToNow,
  getDateLastCalendarYear,
  subtractDateDays,
  subtractDateMonths,
  Visible,
} from '../../shared/utils';
import {
  FilterPopperConf,
  FilterPopperItems,
  ProcurementMetricsFilterConf,
} from '../../shared/interfaces';
import { useAppDispatch, useAppSelector, useInstance } from '../../shared/hooks';
import {
  clearProcurementMetrics,
  getDataMetricsThunk,
  getProcurementMetricsAllOptionsThunk,
} from './procurementMetricsSlice';
import { ProcurementMetricsSupplierCard } from './procurement-metrics-suppliers';
import { ProcurementMetricsCauseAreaCard } from './procurement-metrics-cause-areas';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';
import { Footer } from '../main-screen';

export const ProcurementMetrics = () => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((x) => x.app);
  const state = useAppSelector((x) => x.viewSupplier);
  const { account: currentAccount } = { ...appState };
  const { loadingUserData } = useInstance();
  const metricsState = useAppSelector((x) => x.procurementMetrics);
  const { loadingOptions, gridAccount, orderBy, isLoadingRequest, responseValues, requestValues } =
    {
      ...metricsState,
    };
  const { causeArea, details } = { ...responseValues };
  const { supplierId } = { ...state };
  const { summary } = { ...details };

  useEffect(() => {
    dispatch(
      getProcurementMetricsAllOptionsThunk({ id: supplierId, accountId: currentAccount?.id! }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (loadingOptions || isLoadingRequest) return;
    dispatch(getDataMetricsThunk(requestValues));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestValues]);

  useEffect(
    () => () => {
      dispatch(clearProcurementMetrics());
    },
    [dispatch],
  );

  const getAccountOptions = () => {
    const data = gridAccount.data.results.map((item) => ({
      label: item.account.company?.name,
      change: item.account.id,
      checked: false,
      id: item.account.id,
    }));
    return { data, isMultiSelect: true } as FilterPopperConf;
  };

  const getIndustriesOPtions = () => {
    const data = gridAccount.data.results.map((result) => {
      const subCategories = {
        label: result.account.industry?.subIndustry,
        type: 'subCategory',
      } as FilterPopperItems;
      const industriesFormat = {
        label: `${result.account.industry?.subIndustry}`,
        change: result.account.industry?.subIndustry,
        checked: false,
        id: result.account.industry?.subIndustry,
      } as FilterPopperItems;
      return [subCategories, industriesFormat];
    });
    const formatValues: Array<FilterPopperItems> = data.reduce(
      (first: Array<FilterPopperItems>, second) => [...first, ...second],
      [],
    );
    return { data: _.uniqBy(formatValues, 'label'), isMultiSelect: true } as FilterPopperConf;
  };
  const valuesToFilter: ProcurementMetricsFilterConf = {
    date: {
      isMultiSelect: false,
      data: [
        { label: 'Last 30 Days', change: subtractDateDays(30), checked: false, id: '30Days' },
        { label: 'Last 90 Days', change: subtractDateDays(90), checked: false, id: '90Days' },
        {
          label: 'Last 12 Months',
          change: subtractDateMonths(12),
          checked: false,
          id: '12Months',
        },
        {
          label: 'Last Calendar Year',
          change: getDateLastCalendarYear(),
          checked: false,
          id: 'LastYear',
        },
        {
          label: 'This Year (JAN-TODAY)',
          change: getDateFromJanuaryToNow(),
          checked: true,
          id: 'ThisYear',
        },
        { label: 'Custom', change: 'customDate', checked: false, id: 'customDate' },
      ],
    },
    accounts: getAccountOptions(),
    industries: getIndustriesOPtions(),
    active: {
      isMultiSelect: true,
      data: [
        { label: 'Active', change: true, checked: true, id: 'Active' },
        { label: 'Inactive', change: false, checked: false, id: 'Inactive' },
      ],
    },
  };
  const getSortData = (key: 'avgGivePercent' | 'participation' | 'totalGiveAmount') => {
    let sortInfo = _.orderBy(details?.results, [key], [orderBy]);
    if (key === 'totalGiveAmount') {
      sortInfo = _.orderBy(details?.results, [(result) => result.totalGiveAmount], [orderBy]);
    }
    const reduceData = sortInfo.slice(0, 5);
    return reduceData;
  };

  const getGiveValue = () => {
    let names: Array<string> = [];
    let values: Array<number> = [];
    if (!details?.results) return { names, values };
    const data = getSortData('totalGiveAmount');
    data.map((item) => {
      names = [...names, item?.key?.account ?? ''];
      values = [...values, item?.totalGiveAmount ?? 0];
      return item;
    });
    const response = {
      names,
      values,
    };
    return response;
  };

  const getPercentageOfDeal = () => {
    let names: Array<string> = [];
    let values: Array<number> = [];
    if (!details?.results) return { names, values };
    const data = getSortData('avgGivePercent');
    data.map((item) => {
      names = [...names, item?.key?.account ?? ''];
      values = [...values, item?.avgGivePercent ?? 0];
      return item;
    });

    const response = {
      names,
      values,
    };
    return response;
  };

  const getParticipation = () => {
    let names: Array<string> = [];
    let values: Array<number> = [];
    if (!details?.results) return { names, values };
    const data = getSortData('participation');
    data.map((item) => {
      names = [...names, item?.key?.account ?? ''];
      values = [...values, item.participation ?? 0];
      return item;
    });
    const response = {
      names,
      values,
    };
    return response;
  };

  if (loadingUserData || loadingOptions) return <Loading loading />;

  return (
    <FlexBox
      sx={{
        flexDirection: 'column',
      }}
    >
      <Box
        className="metrics_board"
        sx={{
          flexDirection: 'column',
          gap: 2,
          px: '20px',
          pt: '12px',
          backgroundColor: colors.backgroundPrimary,
          height: '95vh',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{ flex: 1, color: colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 700 }}
          >
            Metrics
          </Typography>
          <ProcurementMetricsFilters filterValues={valuesToFilter} />
        </Box>
        {isLoadingRequest && <Loading loading />}
        <Visible condition={!isLoadingRequest}>
          <Box>
            <Box
              sx={{
                backgroundColor: colors.secondary,
                marginBottom: '20px',
                padding: '20px',
                borderRadius: '15px',
                border: `1px solid ${colors.strokeDefault}`,
              }}
            >
              <Typography
                sx={{
                  flex: 1,
                  color: colors.textPrimary,
                  fontSize: fonts.fontSize20,
                  fontWeight: 700,
                  marginBottom: '20px',
                }}
              >
                Suppliers
              </Typography>
              <FlexBox sx={{ flexDirection: 'column', gap: 3, color: colors.textPrimary }}>
                <FlexBox sx={{ alignContent: 'space-around', gap: 3, color: colors.textPrimary }}>
                  <ProcurementMetricsSupplierCard
                    title="Total Contribution Value"
                    total={summary?.totalGiveAmount ?? 0}
                    data={getGiveValue()}
                    typeValue="money"
                    valuesLength={responseValues.details?.results.length ?? 0}
                  />
                  <ProcurementMetricsSupplierCard
                    title="Avg. Percentage of Deal"
                    total={summary?.avgGivePercent ?? 0}
                    data={getPercentageOfDeal()}
                    typeValue="percentage"
                    valuesLength={responseValues.details?.results.length ?? 0}
                  />
                  <ProcurementMetricsSupplierCard
                    title="Overall Participation"
                    total={summary?.participation ?? 0}
                    data={getParticipation()}
                    typeValue="percentage"
                    valuesLength={responseValues.details?.results.length ?? 0}
                  />
                </FlexBox>
              </FlexBox>
            </Box>
            <Box
              sx={{
                backgroundColor: colors.secondary,
                padding: '20px',
                borderRadius: '15px',
                border: `1px solid ${colors.strokeDefault}`,
                marginBottom: '20px',
              }}
            >
              <Typography
                sx={{
                  flex: 1,
                  color: colors.textPrimary,
                  fontSize: fonts.fontSize20,
                  fontWeight: 700,
                  marginBottom: '20px',
                }}
              >
                Programs Funded
              </Typography>
              <FlexBox
                sx={{
                  alignContent: 'space-around',
                  color: colors.textPrimary,
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                {causeArea?.results.map((item) => (
                  <ProcurementMetricsCauseAreaCard key={item.impactMeasurement} values={item} />
                ))}
              </FlexBox>
            </Box>
          </Box>
        </Visible>
        <div style={{ margin: '-20px', position: 'relative', bottom: '10px', marginTop: '20vh' }}>
          <Footer />
        </div>
      </Box>
    </FlexBox>
  );
};
