import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCancelTokenEvent, getAccounts, patchAccountStatus, getBuyerAccount, getScaleWithConstants, postReceivedFunds } from '../../../api';
import { DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { Account, GridResponse, GridModel, GridRequest } from '../../../shared/interfaces';

interface AccountsGridRequest extends GridRequest {
  status: string | null;
}

export interface AccountsState {
  grid: GridModel<Account>;
  updateStatusSuccess: boolean;
  status: string | null;
  loading: boolean;
  buyerAccount: Account;
  error?: string;
  scaleWithConstants: string | null;
}

const initialState: AccountsState = {
  grid: {
    name: 'accounts',
    data: { totalCount: 0, results: [] },
    page: 0,
    defaultSort: { field: 'name', direction: 'asc' },
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
  },
  updateStatusSuccess: false,
  status: null,
  loading: false,
  buyerAccount: {
    id: '',
    company: {
      name: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        stateProvince: '',
        postalCode: '',
        country: '',
      },
      countryCode: '',
      phoneNumber: '',
      website: '',
    }
  },
  scaleWithConstants: null,
};

export const getAccountsThunk = createAsyncThunk(
  'accounts/getAccountsThunk',
  async (request: AccountsGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getAccounts(request);
    return data;
  },
);

export const getBuyerAccountThunk = createAsyncThunk(
  'accounts/getBuyerAccountThunk',
  async (request: { id: string }, { signal }) => {
    addCancelTokenEvent(signal);
    const { id } = request;
    const { data } = await getBuyerAccount(id);
    return data;
  },
);

export const getScaleWithConstantsThunk = createAsyncThunk(
  'accounts/getScaleWithConstantsThunk',
  async (request: { key: string }, { signal }) => {
    addCancelTokenEvent(signal);
    const { key } = request;
    const { data } = await getScaleWithConstants(key);
    return data;
  },
);

export const patchAccountAdminThunk = createAsyncThunk(
  'accounts/patchAccountAdminThunk',
  async (request: { accountId: string; status: string }, { signal }) => {
    addCancelTokenEvent(signal);
    const { accountId, status } = request;
    const { data } = await patchAccountStatus(accountId, status);
    return data;
  },
);

export const postReceivedFundsAdminThunk = createAsyncThunk(
  'accounts/postReceivedFundsAdminThunk',
  async (request: { accountId: string }, { signal }) => {
    addCancelTokenEvent(signal);
    const { accountId } = request;
    const { data } = await postReceivedFunds(accountId);
    return data;
  },
);



const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccountsGridModel: (state, action: PayloadAction<Partial<GridModel<Account>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearAccountsState: (state) => {
      state.grid = initialState.grid;
      state.updateStatusSuccess = false;
    },
    clearAccountsUpdateSuccess: (state) => {
      state.updateStatusSuccess = false;
    },
    setAccountsStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAccountsThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<Account>>) => {
          state.grid.loadingGrid = false;
          state.grid.data = action.payload;
        },
      )
      .addCase(getAccountsThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getAccountsThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(
        getBuyerAccountThunk.fulfilled,
        (state, action: PayloadAction<Account>) => {
          state.loading = false;
          state.buyerAccount = action.payload;
          state.error = "";
        },
      )
      .addCase(getBuyerAccountThunk.rejected, (state) => {
        state.loading = false;
        state.error = 'Buyer not found.'
      })
      .addCase(getBuyerAccountThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(
        getScaleWithConstantsThunk.fulfilled,
        (state, action) => {
          const { result } = action.payload;
          state.scaleWithConstants = result;
        },
      )
      .addCase(patchAccountAdminThunk.fulfilled, (state, action: PayloadAction<Account>) => {
        state.updateStatusSuccess = true;
        state.loading = false;
        const index = state.grid.data.results.findIndex((a) => a.id === action.payload.id);
        if (index === -1) return;
        state.grid.data.results[index] = action.payload;
      })
      .addCase(patchAccountAdminThunk.pending, (state) => {
        state.updateStatusSuccess = false;
        if (!state.loading) state.loading = true;
      })
      .addCase(postReceivedFundsAdminThunk.fulfilled, (state, action: PayloadAction<Account>) => {
        state.updateStatusSuccess = true;
        state.loading = false;
        const index = state.grid.data.results.findIndex((a) => a.id === action.payload.id);
        if (index === -1) return;
        state.grid.data.results[index] = action.payload;
      })
      .addCase(postReceivedFundsAdminThunk.pending, (state) => {
        state.updateStatusSuccess = false;
        if (!state.loading) state.loading = true;
      });
  },
});

export const {
  setAccountsGridModel,
  clearAccountsState,
  setAccountsStatus,
  clearAccountsUpdateSuccess,
} = accountsSlice.actions;

export const accountsReducer = accountsSlice.reducer;
