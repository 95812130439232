import React, { useEffect } from 'react';
import _ from 'lodash';
import { Grid, Tab, Tabs, Box, Typography } from '@mui/material';
import { Visibility, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  AccountType,
  CauseAreaMetricsShowBy,
  CauseAreasTabs,
  Dialogs,
  InformativeDialog,
  InstanceSettingsType,
} from '../../shared/enums';
import { useAppDispatch, useAppSelector, useInstance } from '../../shared/hooks';
import { setCauseAreasTab } from './causeAreasSlice';
import { CardHeaderBlue, FlexBox, Loading, PaddingRoundedBox } from '../../shared/components';
import { CauseAreaMetricsFilterPopper } from '../../shared/interfaces/cause-area/causeAreaFilterPopper';
import {
  getDateFromJanuaryToNow,
  getDateLastCalendarYear,
  openInformativeDialog,
  subtractDateDays,
  subtractDateMonths,
} from '../../shared/utils';
import {
  FilterPopperConf,
  FilterPopperItems,
} from '../../shared/interfaces/common/filterPopperItems';
import {
  clearCauseAreasMetrics,
  getCauseAreaMetricsAllOptionsThunk,
  getCauseAreaMetricsThunk,
  setCauseAreaMetricsChipsSelected,
  setCauseAreaMetricsData,
} from './CauseAreaMetricsSlice';
import {
  CauseAreaCardMetrics,
  CauseAreaMetricsChart,
  CauseAreaMetricsChip,
  MetricsFilter,
} from './cause-area-metrics';
import { setDialog } from '../main-screen';
import { CauseAreaMetricsOverView } from './cause-area-metrics/CauseAreaMetricsOverView';
import { CauseAreaMetricsCurrencies } from '../../shared/interfaces';

export const CauseAreaMetrics = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((x) => x.causeAreas);
  const { loadingUserData: loadingInformationAccount, locale: currentLocale } = useInstance();
  const informativeDialogState = useAppSelector((x) => x.informativeDialog);
  const CauseAreasMetricsState = useAppSelector((x) => x.causeAreasMetrics);
  const {
    gridAccount,
    gridCauseArea,
    gridInstance,
    gridLocale,
    industries,
    loadingOptions,
    requestValues,
    responseValues,
    chartShowBy,
    currency: currencySelected,
    isAsc,
    sortBy: sortByState,
    isLoadingRequest,
  } = CauseAreasMetricsState;
  const { selectedTab } = state;
  const { details } = { ...responseValues };
  const { currencies: currenciesFromResponse } = { ...details };
  const { confirmed: confirmedInfo, type: typeInfo, close: closeInfo } = informativeDialogState;
  const localeStoragesShow = localStorage.getItem('gw-view-show-dialog-multi-currency');

  useEffect(() => {
    dispatch(getCauseAreaMetricsAllOptionsThunk());
  }, [dispatch]);

  useEffect(() => {
    const userConfigSelected = localStorage.getItem('gw-cause-area-metrics-pills-selected');
    if (!userConfigSelected) return;
    const parseConfig = JSON.parse(userConfigSelected);
    dispatch(setCauseAreaMetricsChipsSelected([...parseConfig]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(clearCauseAreasMetrics());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const openDialogCurrency = () => {
    openInformativeDialog(
      {
        title: 'Currency Selection',
        text: 'Your filter criteria results in data across multiple currencies. To accurately display you data only one currency can be shown at a time. Switch between available currencies and the corresponding data by clicking the dropdown next to the filter.',
        type: InformativeDialog.CurrencySelection,
        confirmText: 'Got it!',
      },
      'md',
    );
  };

  useEffect(() => {
    if (loadingOptions) return;
    dispatch(getCauseAreaMetricsThunk({ ...requestValues }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestValues, dispatch]);

  useEffect(() => {
    if (currenciesFromResponse.length <= 1) return;
    if (!localeStoragesShow) openDialogCurrency();
    let currencyOption: CauseAreaMetricsCurrencies = currenciesFromResponse[0];
    const isCurrentLocalePresent = currenciesFromResponse.find(
      (item) =>
        item.currency === currentLocale?.settings.currency &&
        item.symbol === currentLocale?.settings.symbol,
    );
    const isUSDPresent = currenciesFromResponse.find(
      (item) => item.currency?.toUpperCase() === 'USD' && item.symbol === '$',
    );
    if (isUSDPresent !== undefined) currencyOption = isUSDPresent;
    if (isCurrentLocalePresent !== undefined) currencyOption = isCurrentLocalePresent;
    dispatch(setCauseAreaMetricsData({ currency: currencyOption }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currenciesFromResponse, currentLocale]);

  useEffect(() => {
    if (!confirmedInfo || typeInfo !== InformativeDialog.CurrencySelection || !closeInfo) return;
    localStorage.setItem('gw-view-show-dialog-multi-currency', JSON.stringify(true));
  }, [confirmedInfo, typeInfo, closeInfo]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setCauseAreasTab(newValue));
  };

  const getIndustriesOPtions = () => {
    const data = industries.map((item) => {
      const subCategories = { label: item.name, type: 'subCategory' } as FilterPopperItems;
      const industriesFormat = item.items.map(
        (industry) =>
          ({
            label: `${industry.subIndustry} (${industry.count ?? 0})`,
            change: industry.subIndustry,
            checked: false,
            id: industry.subIndustry,
          } as FilterPopperItems),
      );
      return [subCategories, ...industriesFormat];
    });
    const formatValues: Array<FilterPopperItems> = data.reduce(
      (first: Array<FilterPopperItems>, second) => [...first, ...second],
      [],
    );
    return { data: formatValues, isMultiSelect: true } as FilterPopperConf;
  };

  const getAccountOption = () => {
    const data = gridAccount.data.results.map((item) => ({
      label: item.company?.name,
      change: item.id,
      checked: false,
      id: item.id,
    }));
    return { data, isMultiSelect: true } as FilterPopperConf;
  };

  const getCauseAreasOption = () => {
    const data = gridCauseArea.data.results.map((item) => ({
      label: item.name,
      change: item.id,
      checked: false,
      id: item.id,
    }));
    return { data, isMultiSelect: true } as FilterPopperConf;
  };

  const getInstanceOption = () => {
    const data = gridInstance.data.results.map((item) => ({
      label: item.name,
      change: item.id,
      checked: false,
      id: item.id,
    }));
    return { data, isMultiSelect: true } as FilterPopperConf;
  };

  const getLocaleOption = () => {
    const data = gridLocale.data.results.map((item) => ({
      label: item.name,
      change: item.id,
      checked: false,
      id: item.id,
    }));
    return { data, isMultiSelect: true } as FilterPopperConf;
  };

  const getOPtionFilter = () => {
    const filterPopperItems: CauseAreaMetricsFilterPopper = {
      date: {
        isMultiSelect: false,
        data: [
          { label: 'Last 30 Days', change: subtractDateDays(30), checked: false, id: '30Days' },
          { label: 'Last 90 Days', change: subtractDateDays(90), checked: false, id: '90Days' },
          {
            label: 'Last 12 Months',
            change: subtractDateMonths(12),
            checked: false,
            id: '12Months',
          },
          {
            label: 'Last Calendar Year',
            change: getDateLastCalendarYear(),
            checked: false,
            id: 'LastYear',
          },
          {
            label: 'This Year (JAN-TODAY)',
            change: getDateFromJanuaryToNow(),
            checked: true,
            id: 'ThisYear',
          },
          { label: 'Custom', change: 'customDate', checked: false, id: 'customDate' },
        ],
      },
      accounts: getAccountOption(),
      locales: getLocaleOption(),
      instances: getInstanceOption(),
      causeArea: getCauseAreasOption(),
      industries: getIndustriesOPtions(),
      active: {
        isMultiSelect: true,
        data: [
          { label: 'Active', change: true, checked: true, id: 'Active' },
          { label: 'Inactive', change: false, checked: false, id: 'Inactive' },
        ],
      },
      accountType: {
        isMultiSelect: false,
        data: [
          {
            label: 'Default',
            change: AccountType.default,
            checked: false,
            id: 'AccountTypeDefault',
          },
          {
            label: 'Procurement',
            change: AccountType.procurement,
            checked: false,
            id: 'AccountTypeProcurement',
          },
          {
            label: 'Supplier',
            change: AccountType.supplier,
            checked: false,
            id: 'AccountTypeSupplier',
          },
        ],
      },
      instanceType: {
        isMultiSelect: false,
        data: [
          {
            label: 'Default',
            change: InstanceSettingsType.default,
            checked: false,
            id: 'InstanceTypeDefault',
          },
          {
            label: 'Procurement',
            change: InstanceSettingsType.procurement,
            checked: false,
            id: 'InstanceTypeProcurement',
          },
          {
            label: 'Sales',
            change: InstanceSettingsType.sales,
            checked: false,
            id: 'InstanceTypeSales',
          },
        ],
      },
    };
    return filterPopperItems;
  };

  const openCauseAreaMetricsDialog = () => {
    dispatch(setDialog({ open: true, type: Dialogs.CauseAreaMetrics, maxWidth: 'lg' }));
  };

  const changeShowByChart = (showValue: number) => {
    let validateNumber = showValue;
    if (validateNumber > 2) validateNumber = 0;
    if (validateNumber < 0) validateNumber = 2;
    dispatch(setCauseAreaMetricsData({ chartShowBy: validateNumber }));
  };

  const getLabelShowBy = () => {
    if (chartShowBy === CauseAreaMetricsShowBy.values) return 'Total Contribution Amount';
    if (chartShowBy === CauseAreaMetricsShowBy.count) return 'Number Of Social Impact Contributions';
    if (chartShowBy === CauseAreaMetricsShowBy.impact) return 'Total Impact';
    return 'Values';
  };

  const getDataBySort = () => {
    if (responseValues.details.results.length === 0) return responseValues.details.results;
    const order = isAsc ? 'asc' : 'desc';
    const sortData = _.orderBy(responseValues.details.results, [sortByState], [order]);
    return sortData;
  };

  const getData = () => {
    if (responseValues.details.results.length === 0) return [];
    const sortData = getDataBySort();
    if (currenciesFromResponse.length <= 1) return sortData;
    const filterByCurrency = sortData.filter(
      (item) =>
        item.key?.currency.toUpperCase() === currencySelected?.currency?.toUpperCase() &&
        item.key?.currencySymbol === currencySelected.symbol,
    );
    return filterByCurrency;
  };

  if (loadingOptions || loadingInformationAccount)
    return <Loading loading={loadingOptions || loadingInformationAccount} />;

  return (
    <Box>
      <Grid container alignItems="end">
        <Grid item md={3} sm={12}>
          <Box my={1}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab value={CauseAreasTabs.Active} label="Active" />
              <Tab value={CauseAreasTabs.Inactive} label="Inactive" />
              <Tab value={CauseAreasTabs.Metrics} label="Metrics" />
            </Tabs>
          </Box>
        </Grid>
        <Grid item md={9} sm={12}>
          <MetricsFilter optionFilter={getOPtionFilter()} causeAreas={gridCauseArea} />
        </Grid>
      </Grid>

      {isLoadingRequest ? (
        <Loading loading={isLoadingRequest} />
      ) : (
        <PaddingRoundedBox
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            backgroundColor: theme.palette.common.grey.main,
          })}
        >
          <FlexBox sx={{ gap: 2 }}>
            <CardHeaderBlue title="Social Impact Contribution Stages" styleContainer={{ flex: 2 }}>
              <FlexBox sx={{ alignItems: 'center' }}>
                <FlexBox
                  sx={{
                    flexDirection: 'column',
                    gap: 3,
                    alignItems: 'center',
                    flex: 0.1,
                  }}
                >
                  <KeyboardArrowUp
                    onClick={() => changeShowByChart(chartShowBy + 1)}
                    sx={{ cursor: 'pointer', flex: 2 }}
                  />
                  <Typography sx={{ writingMode: 'vertical-rl', transform: 'rotate(-180deg)' }}>
                    {getLabelShowBy()}
                  </Typography>
                  <KeyboardArrowDown
                    onClick={() => changeShowByChart(chartShowBy - 1)}
                    sx={{ cursor: 'pointer', flex: 1 }}
                  />
                </FlexBox>
                <Box sx={{ flex: 2.2 }}>
                  <CauseAreaMetricsChart data={getData()} />
                </Box>
              </FlexBox>
            </CardHeaderBlue>
            <CauseAreaMetricsOverView />
          </FlexBox>
          <FlexBox sx={{ alignItems: 'center', gap: 2 }}>
            <Typography variant="h4">Cause Areas</Typography>
            <FlexBox
              sx={(theme) => ({
                gap: 1,
                cursor: 'pointer',
                color: theme.palette.primary.main,
                alignItems: 'center',
              })}
              onClick={openCauseAreaMetricsDialog}
            >
              <Visibility />
              <Typography>View as Table</Typography>
            </FlexBox>
          </FlexBox>
          <CauseAreaMetricsChip />
          <FlexBox sx={{ gap: 5, marginTop: 3, flexWrap: 'wrap' }}>
            {getData().map((card) => (
              <CauseAreaCardMetrics cardValue={card} key={card.id} />
            ))}
          </FlexBox>
        </PaddingRoundedBox>
      )}
    </Box>
  );
};
