import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addCancelTokenEvent,
  getCauseAreaMetricsDetails,
  getCauseAreaMetricsOverview,
  getAccounts,
  getCauseAreas,
  getIndustriesCounts,
  getInstances,
  getLocales,
} from '../../api';
import { DEFAULT_PAGE_SIZE, DEFAULT_VALUES_REQUEST } from '../../shared/constants';
import {
  CauseAreaMetricsPills,
  CauseAreaMetricsShowBy,
  GiveStages,
  OrderStatus,
} from '../../shared/enums';
import {
  Account,
  CauseArea,
  CauseAreaMetricsCurrencies,
  CauseAreaMetricsRequest,
  CauseAreaMetricsResponse,
  GridModel,
  GridResponse,
  Industry,
  Instance,
  Locale,
} from '../../shared/interfaces';
import { getDateFromJanuaryToNow } from '../../shared/utils';

interface allOption {
  gridCauseArea: GridResponse<CauseArea>;
  gridInstance: GridResponse<Instance>;
  industries: Array<Industry>;
  gridLocale: GridResponse<Locale>;
  gridAccount: GridResponse<Account>;
}

interface responseCauseAreaMetricsDetailOverview {
  overView: CauseAreaMetricsResponse;
  details: CauseAreaMetricsResponse;
}

interface CauseAreasMetricsState {
  gridCauseArea: GridModel<CauseArea>;
  gridInstance: GridModel<Instance>;
  industries: Array<Industry>;
  gridLocale: GridModel<Locale>;
  gridAccount: GridModel<Account>;
  requestValues: CauseAreaMetricsRequest;
  responseValues: responseCauseAreaMetricsDetailOverview;
  giveStagesSelected: Array<GiveStages>;
  chartShowBy: number;
  loadingOptions: boolean;
  isAsc: boolean;
  sortBy: string;
  currency: CauseAreaMetricsCurrencies;
  chipsSelected: Array<CauseAreaMetricsPills>;
  isLoadingRequest: boolean;
}

const initialState: CauseAreasMetricsState = {
  gridCauseArea: {
    name: 'cause areas',
    data: { totalCount: 0, results: [] },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'name', direction: 'asc' },
  },
  gridAccount: {
    name: 'Account',
    data: { totalCount: 0, results: [] },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'name', direction: 'asc' },
  },
  industries: [],

  gridInstance: {
    name: 'instance',
    data: { totalCount: 0, results: [] },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'name', direction: 'asc' },
  },
  gridLocale: {
    name: 'locale',
    data: { totalCount: 0, results: [] },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'name', direction: 'asc' },
  },
  requestValues: {
    lastUpdatedFrom: getDateFromJanuaryToNow().from,
    lastUpdatedTo: getDateFromJanuaryToNow().to,
    active: [true],
  },
  responseValues: {
    details: { results: [], currencies: [] },
    overView: { results: [], currencies: [] },
  },
  currency: {},
  chartShowBy: CauseAreaMetricsShowBy.values,
  sortBy: 'proposed',
  loadingOptions: false,
  giveStagesSelected: [
    GiveStages.Proposed,
    GiveStages.Approved,
    GiveStages.FinancialHold,
    GiveStages.Completed,
  ],
  chipsSelected: [
    CauseAreaMetricsPills.ApprovedValue,
    CauseAreaMetricsPills.ApprovedCount,
    CauseAreaMetricsPills.CompletedValue,
    CauseAreaMetricsPills.CompletedCount,
    CauseAreaMetricsPills.AvgGive,
    CauseAreaMetricsPills.WinRate,
    CauseAreaMetricsPills.Impact,
    CauseAreaMetricsPills.AvgDeal,
  ],
  isAsc: false,
  isLoadingRequest: false,
};

export const getCauseAreaMetricsAllOptionsThunk = createAsyncThunk<allOption, void>(
  'causeAreasMetrics/getCauseAreaMetricsAllOption',
  async (_void, { signal }) => {
    addCancelTokenEvent(signal);
    const { data: dataInstance } = await getInstances(DEFAULT_VALUES_REQUEST);
    const { data: dataAccount } = await getAccounts(DEFAULT_VALUES_REQUEST);
    const { data: dataLocales } = await getLocales(DEFAULT_VALUES_REQUEST);
    const { data: dataCauseArea } = await getCauseAreas(DEFAULT_VALUES_REQUEST);
    const { data: dataIndustries } = await getIndustriesCounts();
    const allOptions: allOption = {
      gridAccount: dataAccount,
      gridCauseArea: dataCauseArea,
      gridInstance: dataInstance,
      gridLocale: dataLocales,
      industries: dataIndustries,
    };
    return allOptions;
  },
);

export const getCauseAreaMetricsThunk = createAsyncThunk<
  responseCauseAreaMetricsDetailOverview,
  CauseAreaMetricsRequest
>('causeAreasMetrics/getCauseAreaMetricsOverview', async (value, { signal }) => {
  addCancelTokenEvent(signal);
  const { data: OverviewData } = await getCauseAreaMetricsOverview(value);
  const { data: dataDetails } = await getCauseAreaMetricsDetails(value);
  return {
    details: dataDetails,
    overView: OverviewData,
  };
});

const causeAreasMetricsSlice = createSlice({
  name: 'causeAreasMetrics',
  initialState,
  reducers: {
    clearCauseAreasMetrics: (state) => {
      _.assign(state, initialState);
    },
    setCauseAreaMetricsIsAsc: (state, action: PayloadAction<boolean>) => {
      state.isAsc = action.payload;
    },
    setCauseAreaMetricsData: (state, action: PayloadAction<Partial<CauseAreasMetricsState>>) => {
      _.merge(state, action.payload);
    },
    setCauseAreaMetricRequestValues: (state, action: PayloadAction<CauseAreaMetricsRequest>) => {
      state.requestValues = action.payload;
    },
    setCauseAreaMetricsChipsSelected: (
      state,
      action: PayloadAction<Array<CauseAreaMetricsPills>>,
    ) => {
      state.chipsSelected = action.payload;
    },
    setCauseAreaMetricsGiveStage: (state, action: PayloadAction<Array<GiveStages>>) => {
      state.giveStagesSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCauseAreaMetricsAllOptionsThunk.fulfilled,
        (state, action: PayloadAction<allOption>) => {
          state.gridAccount.data = action.payload.gridAccount;
          state.gridCauseArea.data = action.payload.gridCauseArea;
          state.gridInstance.data = action.payload.gridInstance;
          state.gridLocale.data = action.payload.gridLocale;
          state.industries = action.payload.industries;
          state.loadingOptions = false;
        },
      )
      .addCase(getCauseAreaMetricsAllOptionsThunk.rejected, (state) => {
        state.loadingOptions = false;
      })
      .addCase(getCauseAreaMetricsAllOptionsThunk.pending, (state) => {
        if (!state.loadingOptions) state.loadingOptions = true;
      })
      .addCase(
        getCauseAreaMetricsThunk.fulfilled,
        (state, action: PayloadAction<responseCauseAreaMetricsDetailOverview>) => {
          const allInformation = action.payload.details.results.map((principalItem, index) => {
            const proposed = principalItem.details?.find(
              (item) => item.status === OrderStatus.PendingApproval,
            );
            const completed = principalItem.details?.find(
              (item) => item.status === OrderStatus.Completed,
            );
            const approved = principalItem.details?.find(
              (item) => item.status === OrderStatus.Approved,
            );
            const financialHold = principalItem.details?.find(
              (item) => item.status === OrderStatus.FinancialHold,
            );
            return {
              ...principalItem,
              id: `cause-area-metrics-${principalItem.key?.causeArea}${index}`,
              proposed: proposed?.totalGiveAmount ?? 0,
              completed: completed?.totalGiveAmount ?? 0,
              approved: approved?.totalGiveAmount ?? 0,
              financialHold: financialHold?.totalGiveAmount ?? 0,
            };
          });
          const newFormatData = {
            details: { ...action.payload.details, results: allInformation },
            overView: action.payload.overView,
          };
          state.responseValues = newFormatData;
          state.isLoadingRequest = false;
        },
      )
      .addCase(getCauseAreaMetricsThunk.rejected, (state) => {
        state.isLoadingRequest = false;
      })
      .addCase(getCauseAreaMetricsThunk.pending, (state) => {
        if (!state.isLoadingRequest) state.isLoadingRequest = true;
      });
  },
});

export const {
  clearCauseAreasMetrics,
  setCauseAreaMetricsIsAsc,
  setCauseAreaMetricsData,
  setCauseAreaMetricRequestValues,
  setCauseAreaMetricsChipsSelected,
  setCauseAreaMetricsGiveStage,
} = causeAreasMetricsSlice.actions;

export const causeAreasMetricsReducer = causeAreasMetricsSlice.reducer;
