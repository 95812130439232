import React from 'react';
import {
  BoldTypography,
  CardHeaderBlue,
  FlexBox,
  OverViewLabelTypography,
} from '../../../shared/components';
import { useAppSelector } from '../../../shared/hooks';
import { OrderStatus } from '../../../shared/enums';
import { formatter, getUsernameFromEmail } from '../../../shared/utils';

export const CauseAreaMetricsOverView = () => {
  const overViewState = useAppSelector((x) => x.causeAreasMetrics.responseValues.overView);
  const currentCurrency = useAppSelector((x) => x.causeAreasMetrics.currency);

  const getCurrentOverview = () => {
    if (overViewState.results.length === 1) return { ...overViewState.results[0] };
    const currentOverview = overViewState.results.find(
      (item) =>
        item.key?.currency === currentCurrency.currency &&
        item.key?.currencySymbol === currentCurrency.symbol,
    );
    if (!currentOverview) return {};
    return currentOverview;
  };

  const overviewData = getCurrentOverview();

  const getValues = (value: OrderStatus) => {
    if (overViewState.results.length === 0) return 0;
    const findStatus = overviewData.details?.find((item) => item.status === value);
    return `${overviewData.key?.currencySymbol ?? ''}${formatter.format(
      findStatus?.totalGiveAmount ?? 0,
    )}`;
  };

  const getNameOfMost = (value: OrderStatus) => {
    if (overViewState.results.length === 0) return '';
    const findStatus = overviewData.details?.find((item) => item.status === value);
    return findStatus?.mostGives ?? '';
  };

  return (
    <CardHeaderBlue title="Overview" styleContainer={{ flex: 0.5 }}>
      <FlexBox sx={{ flexDirection: 'column', gap: 2 }}>
        <FlexBox sx={{ alignItems: 'center', gap: 2 }}>
          <OverViewLabelTypography>PROPOSED:</OverViewLabelTypography>
          <BoldTypography variant="h6" sx={{ flex: 0.5 }}>
            {getValues(OrderStatus.PendingApproval)}
          </BoldTypography>
        </FlexBox>
        <FlexBox sx={{ alignItems: 'center', gap: 2 }}>
          <OverViewLabelTypography>COMPLETED:</OverViewLabelTypography>
          <BoldTypography variant="h6" sx={{ flex: 0.5 }}>
            {getValues(OrderStatus.Completed)}
          </BoldTypography>
        </FlexBox>
        <FlexBox sx={{ alignItems: 'center', gap: 2 }}>
          <OverViewLabelTypography>APPROVED:</OverViewLabelTypography>
          <BoldTypography variant="h6" sx={{ flex: 0.5 }}>
            {getValues(OrderStatus.Approved)}
          </BoldTypography>
        </FlexBox>
        <FlexBox sx={{ alignItems: 'center', gap: 2 }}>
          <OverViewLabelTypography>FINANCIAL HOLD:</OverViewLabelTypography>
          <BoldTypography variant="h6" sx={{ flex: 0.5 }}>
            {getValues(OrderStatus.FinancialHold)}
          </BoldTypography>
        </FlexBox>
        <FlexBox sx={{ alignItems: 'center', gap: 2 }}>
          <OverViewLabelTypography>AVG GIVE:</OverViewLabelTypography>
          <BoldTypography variant="h6" sx={{ flex: 0.5 }}>
            {`${overviewData?.key?.currencySymbol ?? ''}${formatter.format(
              overviewData?.avgGiveAmount ?? 0,
            )}`}
          </BoldTypography>
        </FlexBox>
        <FlexBox sx={{ alignItems: 'center', gap: 2 }}>
          <OverViewLabelTypography>WIN RATE:</OverViewLabelTypography>
          <BoldTypography variant="h6" sx={{ flex: 0.5 }}>
            {`${overviewData?.winRate ?? 0}%`}
          </BoldTypography>
        </FlexBox>
        <FlexBox sx={{ alignItems: 'center', gap: 2 }}>
          <OverViewLabelTypography>AVG PERCENT OF DEAL:</OverViewLabelTypography>
          <BoldTypography variant="h6" sx={{ flex: 0.5 }}>
            {`${overviewData?.avgGivePercent ?? 0}%`}
          </BoldTypography>
        </FlexBox>
        <FlexBox sx={{ alignItems: 'center', gap: 2 }}>
          <OverViewLabelTypography>MOST PROPOSED:</OverViewLabelTypography>
          <BoldTypography variant="h6" sx={{ wordBreak: 'break-all', flex: 0.5 }}>
            {getUsernameFromEmail(getNameOfMost(OrderStatus.PendingApproval))}
          </BoldTypography>
        </FlexBox>
        <FlexBox sx={{ alignItems: 'center', gap: 2 }}>
          <OverViewLabelTypography>MOST COMPLETED:</OverViewLabelTypography>
          <BoldTypography variant="h6" sx={{ wordBreak: 'break-all', flex: 0.5 }}>
            {getUsernameFromEmail(getNameOfMost(OrderStatus.Completed))}
          </BoldTypography>
        </FlexBox>
      </FlexBox>
    </CardHeaderBlue>
  );
};
