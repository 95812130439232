import React from 'react';
import { Grid, CardContent, Typography, Button, Link } from '@mui/material';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface props {
  onClick: () => void;
}

const ProgramInitiative: React.FC<props> = ({ onClick }) => (
  <CardContent sx={{ padding: '120px 150px' }}>
    <Typography sx={{ mb: 4, fontSize: fonts.fontSize24, fontWeight: fonts.fontWeight700, color: colors.textPrimary }} gutterBottom>
      Congratulations! Your Social Impact Organization Program Funding initiative has reached its
      conclusion as part of a ScaleWith transaction.
    </Typography>
    <Typography sx={{ mb: 4, lineHeight: '32px', fontSize: fonts.fontSize16, color: colors.textPrimary }} gutterBottom>
      Congratulations! Your Social Impact Organization Program Funding initiative has reached its
      conclusion as part of a ScaleWith transaction. Please fill out this Program Completion Form in
      order to gather information on the executed program. Please note that this form has been
      auto-populated with information your organization previously submitted via the ScaleWith and
      some information may need to be updated.
    </Typography>
    <Typography sx={{ mb: 4, lineHeight: '32px', fontSize: fonts.fontSize16, color: colors.textPrimary }} gutterBottom>
      Please make any appropriate changes to the Program Completion Form, if and where necessary, to
      accurately reflect how the funds will be used before submitting for review.
    </Typography>
    <Typography sx={{ mb: 12, lineHeight: '32px', fontSize: fonts.fontSize16, color: colors.textPrimary }} gutterBottom>
      Should you have any questions, please reach out to your Partnerships Manager or email&nbsp;
      <Link style={{ color: colors.strokeClick }} href="mailto:impact@scalewith.com">
        impact@scalewith.com.
      </Link>
    </Typography>
    <Grid item xs={12} sm={6} sx={{ textAlign: 'left' }}>
      <Button
        variant="outlined"
        size="large"
        sx={{
          backgroundColor: colors.primary,
          color: colors.secondary,
          '&:hover': {
            backgroundColor: colors.primary,
            color: colors.secondary,
          },
        }}
        onClick={() => onClick()}
      >
        Get Started
      </Button>
    </Grid>
  </CardContent>
);

export default ProgramInitiative;
