import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { FlexBox, MoneyInput, TextFieldAndChips } from '../../../shared/components';
import { formatter } from '../../../shared/utils';
import { InstanceFormConfig } from '../../../shared/interfaces';
import { setInstanceSettingsSkus } from './instanceSettingsSlice';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import fonts from '../../../assets/scss/font.module.scss';

interface Props {
  value: InstanceFormConfig;
}

export const InstanceSettingsSKUs: FC<Props> = (props) => {
  const { value } = props;
  const {
    SKUs: { SKUsValues },
  } = value;
  const dispatch = useAppDispatch();
  const currentLocale = useAppSelector((x) => x.instanceSettings.selectedLocale);

  const addChip = (chipValue: string) => {
    if (!SKUsValues.value.find((item) => item === Number(chipValue))) {
      dispatch(setInstanceSettingsSkus([...SKUsValues.value, Number(chipValue)]));
    } else {
      toast.warning(`'${chipValue}' is a duplicate`);
    }
  };

  const onDeleteChip = (chipValue: string) => {
    dispatch(
      setInstanceSettingsSkus(SKUsValues.value.filter((item) => item !== Number(chipValue))),
    );
  };

  const formatChip = (chipValue: string) =>
    `${currentLocale?.settings.symbol ?? ''}${formatter.format(Number(chipValue))}`;

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
      <Typography sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }}>Default SKUs</Typography>
      <TextFieldAndChips
        addChip={addChip}
        label={SKUsValues.label}
        data={SKUsValues.value.map((item) => item.toString())}
        onDelete={onDeleteChip}
        formatValue={formatChip}
        required={SKUsValues.required}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]',
          inputComponent: MoneyInput as any,
          inputProps: { symbol: currentLocale?.settings.symbol },
        }}
      />
    </FlexBox>
  );
};
