import {
  CauseArea,
  CauseAreaMetricsResponse,
  CauseAreaMetricsRequest,
  CauseAreaSku,
  GridRequest,
  GridResponse,
} from '../../shared/interfaces';
import { httpSecuredCommon, cancelToken } from '../httpCommon';

const base = '/cause-areas';

export const CAUSE_AREA_API_ROUTES = {
  getCauseArea: (id: string, queryString: string) => `${base}/${queryString}`,
  getCauseAreas: base,
  getCauseAreaSearch: `${base}/search`,
  getCauseAreaInstanceAssociatedFile: (id: string) => `${base}/${id}/instances`,
  getCauseAreaSkus: (id: string, accountId: string) => `${base}/${id}/skus/${accountId}`,
  postCauseArea: base,
  putCauseArea: (id: string) => `${base}/${id}`,
  deleteCauseArea: (id: string) => `${base}/${id}`,
  patchCauseArea: (id: string) => `${base}/${id}`,
  getCauseAreaMetricsOverview: `${base}/metrics/overview`,
  getCauseAreaMetricsDetails: `${base}/metrics/detail`,
  getCauseAreaMetricsDetailsDownload: `${base}/metrics/download`,
};

export const getCauseArea = async (id: string, isRequiredParams?: boolean) => {
  const queryString = isRequiredParams ? `${id}?count=${0}&&offset=${0}&&orderBy=lastUpdated` : id;
  const response = await httpSecuredCommon.get<CauseArea>(CAUSE_AREA_API_ROUTES.getCauseArea(id, queryString), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getCauseAreas = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<CauseArea>>(
    CAUSE_AREA_API_ROUTES.getCauseAreas,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const getCauseAreaSearch = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<CauseArea>>(
    CAUSE_AREA_API_ROUTES.getCauseAreaSearch,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const getCauseAreaSkus = async (id: string, accountId: string) => {
  const response = await httpSecuredCommon.get<Array<CauseAreaSku>>(
    CAUSE_AREA_API_ROUTES.getCauseAreaSkus(id, accountId),
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const postCauseArea = async (
  value: Omit<CauseArea, 'id' | 'createdBy' | 'createdAt' | 'lastUpdatedBy' | 'calculation'>,
) => {
  const response = await httpSecuredCommon.post<CauseArea>(
    CAUSE_AREA_API_ROUTES.postCauseArea,
    value,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const putCauseArea = async (
  value: Omit<CauseArea, 'createdBy' | 'createdAt' | 'lastUpdatedBy' | 'calculation'>,
) => {
  const { id, ...values } = value;
  const response = await httpSecuredCommon.put<CauseArea>(
    CAUSE_AREA_API_ROUTES.putCauseArea(id),
    values,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const deleteCauseArea = async (id: string) => {
  const response = httpSecuredCommon.delete<CauseArea>(CAUSE_AREA_API_ROUTES.deleteCauseArea(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const patchCauseArea = async (id: string) => {
  const response = httpSecuredCommon.patch<CauseArea>(
    CAUSE_AREA_API_ROUTES.patchCauseArea(id),
    { active: true },
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getCauseAreaAssociatedFile = async (id: string) => {
  const response = await httpSecuredCommon.get(
    CAUSE_AREA_API_ROUTES.getCauseAreaInstanceAssociatedFile(id),
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getCauseAreaMetricsOverview = async (value: CauseAreaMetricsRequest) => {
  const response = await httpSecuredCommon.post<CauseAreaMetricsResponse>(
    CAUSE_AREA_API_ROUTES.getCauseAreaMetricsOverview,
    value,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getCauseAreaMetricsDetails = async (value: CauseAreaMetricsRequest) => {
  const response = await httpSecuredCommon.post<CauseAreaMetricsResponse>(
    CAUSE_AREA_API_ROUTES.getCauseAreaMetricsDetails,
    value,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getCauseAreaMetricsDetailsDownload = async (value: CauseAreaMetricsRequest) => {
  const response = await httpSecuredCommon.post(
    CAUSE_AREA_API_ROUTES.getCauseAreaMetricsDetailsDownload,
    value,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};
