import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setDialog } from '../features/main-screen/mainScreenSlice';
import { CANCELED_CONTRIBUTIONS_SEARCH_PARAM } from '../shared/constants';
import { Dialogs, ContributionStatus } from '../shared/enums';
import { useAppDispatch, useAppSelector } from '../shared/hooks';
import { patchContributionStatusAppThunk } from './appSlice';

export function AppListener() {
  const updateContributionStatus = useAppSelector((x) => x.updateContributionStatus);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  // Fulfillment
  const canceledOrders = searchParams.getAll(CANCELED_CONTRIBUTIONS_SEARCH_PARAM);

  useEffect(() => {
    if (!canceledOrders || canceledOrders.length === 0) return;

    canceledOrders.forEach((x) =>
      dispatch(patchContributionStatusAppThunk({ id: x, status: ContributionStatus.FinancialHold })),
    );

    searchParams.delete(CANCELED_CONTRIBUTIONS_SEARCH_PARAM);
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canceledOrders]);

  // Update contribution status
  const {
    showSuccessModal,
    success: updateOrderSuccess,
    proposedStatus,
    successIncentive,
    transactionCompleted,
    updatingIncentives,
  } = updateContributionStatus;

  useEffect(() => {
    if (showSuccessModal && successIncentive) {
      dispatch(setDialog({ open: true, type: Dialogs.SuccessContributionDialog, maxWidth: 'sm' }));
    }
  }, [successIncentive, showSuccessModal, dispatch]);

  useEffect(() => {
    if (transactionCompleted && successIncentive?.status === ContributionStatus.FinancialHold) {
      dispatch(setDialog({ open: true, type: Dialogs.FinancialHold, maxWidth: 'sm' }));
    }
  }, [transactionCompleted, successIncentive?.status, dispatch]);

  useEffect(() => {
    if (
      updateOrderSuccess &&
      proposedStatus &&
      proposedStatus === successIncentive?.status &&
      !updatingIncentives
    ) {
      toast.success(`Social Impact Contribution was successfully ${proposedStatus}`);
    }
  }, [updateOrderSuccess, proposedStatus, successIncentive?.status, dispatch, updatingIncentives]);

  return <div />;
}
