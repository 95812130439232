import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import BoxSection from '../../../shared/components/common/boxSection';
import TextAreaInput from '../../../shared/components/common/textAreaInput';
import { getLocations } from '../../../api/portal/programRequest';
import CheckBoxInput from '../../../shared/components/common/checkBox/checkBoxInput';
import GridInput from '../../../shared/components/common/gridTextField';
import SearchInput from '../../../shared/components/common/searchInput/searchInput';
import ScrollBox from '../../../shared/components/common/scrollBox';
import Progressed from '../../../assets/svg/progressed.svg';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface Country {
  name: string;
}
interface locations {
  continents: [
    {
      name: string;
    },
  ];
  countries: Country[];
  states: [];
  cities: [];
}

interface vocabulary {
  causes: [
    {
      _id: string;
      label: string;
      slug: string;
    },
  ];
  impact: [];
  audience: {
    age: [];
    attribute: [];
  };
  programApproach: [];
  approachDuration: [];
  dataMeasurement: [];
  studyDesignType: [];
  studyEvidenceType: [];
  researchDataType: [];
  effect: [];
  animalHabitats: [];
}

interface props {
  vocabData: any;
  impactScope: any;
  submitted?: boolean;
  setCompletionProgramData: any;
  validatedQuestions: (count: number) => void;
  isSaveProgress: boolean;
  setProcess: () => void;
  submitResponse: boolean;
}

interface impactAndScope {
  locationDetails: {
    additionalLocationDetails: string;
    regions: [];
    countries: [];
    states: [];
    cities: [];
    locationExplanation: {
      states: string;
      regions: string;
      countries: string;
      cities: string;
    };
  };
  primaryImpact: [];
  secondaryImpacts: string[];
  causes: [];
  protectAndEnhanceForest: true;
  targetGroup: string[];
  audienceAge: string[];
  animalHabitat: string;
}

const ImpactScope: React.FC<props> = ({
  vocabData,
  impactScope,
  submitted,
  setCompletionProgramData,
  validatedQuestions,
  isSaveProgress,
  setProcess,
  submitResponse,
}) => {
  const [locationsData, setLocationsData] = useState<locations>();
  const [vocabularyData, setvocabulary] = useState<vocabulary>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [filteredCountries, setFilterCountries] = useState({});
  const [filteredStates, setFilterStates] = useState<string[]>([]);
  const [filteredCities, setFilterCities] = useState<string[]>([]);
  const [operateAnyWhere, setOperateAnyWhere] = useState(false);
  const [operateCountryAnyWhere, setOperateCountryAnyWhere] = useState(false);
  const [operateStateAnyWhere, setOperateStateAnyWhere] = useState(false);
  const [operateCityAnyWhere, setOperateCityAnyWhere] = useState(false);
  const [isImpactNotApplicable, setIsImpactNotApplicable] = useState(false);
  const [isTarget, setIsTarget] = useState(false);
  const [impactAndScopeData, setImpactAndScopeData] = useState<impactAndScope>();
  const [selectAll, setSelectAll] = useState(false);
  const [protectForest, setProtectForest] = useState(false);
  const [locationOptional, setLocationOptional] = useState('');
  const [regionOptional, setRegionOptional] = useState('');
  const [countryOptional, setCountryOptional] = useState('');
  const [stateOptional, setStateOptional] = useState('');
  const [cityOptional, setCityOptional] = useState('');
  const [validatedAnswers, setvalidateAnswers] = useState({
    // locationDetails: {
    //   regions: 0,
    // },
    primaryImpact: 0,
    secondaryImpacts: 0,
    causes: 0,
    targetGroup: 0,
    audienceAge: 0,
    // animalHabitat: 0,
  });

  const validateFields = (value: string) => (value === '' || value === undefined ? 0 : 1);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredContinents, setFilteredContinents] = useState([
    {
      name: '',
    },
  ]);

  useEffect(() => {
    const filteredData = locationsData?.continents?.filter((continent) =>
      continent.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    setFilteredContinents(filteredData || []);
  }, [searchQuery]);

  const selectAllRegions = (value: boolean) => {
    setSelectAll(value);
    if (value) {
      locationsData?.continents.forEach((continent) => {
        setImpactAndScopeData((prevValues: any) => ({
          ...prevValues,
          locationDetails: {
            ...prevValues?.locationDetails,
            regions: [...(prevValues?.locationDetails?.regions || []), continent?.name],
          },
        }));
      });
    } else {
      setImpactAndScopeData((prevValues: any) => ({
        ...prevValues,
        locationDetails: {
          ...prevValues?.locationDetails,
          regions: [],
        },
      }));
    }
  };

  useEffect(() => {
    getLocations().then((res) => {
      if (res.status === 200) {
        setIsLoaded(true);
        setLocationsData(res.data);
      }
    });
    setvocabulary(vocabData);
    setImpactAndScopeData(impactScope);
    setProtectForest(impactScope?.protectAndEnhanceForest);
    setLocationOptional(impactScope?.locationDetails?.additionalLocationDetails);
    setRegionOptional(impactScope?.locationDetails?.locationExplanation?.regions);
    setCountryOptional(impactScope?.locationDetails?.locationExplanation?.countries);
    setStateOptional(impactScope?.locationDetails?.locationExplanation?.states);
    setCityOptional(impactScope?.locationDetails?.locationExplanation?.cities);
    setvalidateAnswers({
      // locationDetails: {
      //   regions: impactAndScopeData?.locationDetails?.regions?.length! > 0 ? 1 : 0,
      // },
      primaryImpact: impactAndScopeData?.primaryImpact?.length! > 0 ? 1 : 0,
      secondaryImpacts: impactAndScopeData?.secondaryImpacts?.length! > 0 ? 1 : 0,
      causes: impactAndScopeData?.causes?.length! > 0 ? 1 : 0,
      targetGroup: impactAndScopeData?.targetGroup?.length! > 0 ? 1 : 0,
      audienceAge: impactAndScopeData?.audienceAge?.length! > 0 ? 1 : 0,
      // animalHabitat: impactAndScopeData?.animalHabitat ? 1 : 0,
    });
    if (impactScope?.locationDetails?.locationExplanation?.regions !== '') {
      setOperateAnyWhere(true);
    }
    if (impactScope?.locationDetails?.locationExplanation?.countries !== '') {
      setOperateCountryAnyWhere(true);
    }
    if (impactScope?.locationDetails?.locationExplanation?.states !== '') {
      setOperateStateAnyWhere(true);
    }
    if (impactScope?.locationDetails?.locationExplanation?.cities !== '') {
      setOperateCityAnyWhere(true);
    }
  }, [!isLoaded]);

  useEffect(() => {
    setvalidateAnswers({
      // locationDetails: {
      //   regions: impactAndScopeData?.locationDetails?.regions?.length! > 0 ? 1 : 0,
      // },
      primaryImpact: impactAndScopeData?.primaryImpact?.length! > 0 ? 1 : 0,
      secondaryImpacts: impactAndScopeData?.secondaryImpacts?.length! > 0 ? 1 : 0,
      causes: impactAndScopeData?.causes?.length! > 0 ? 1 : 0,
      targetGroup: impactAndScopeData?.targetGroup?.length! > 0 ? 1 : 0,
      audienceAge: impactAndScopeData?.audienceAge?.length! > 0 ? 1 : 0,
      // animalHabitat: impactAndScopeData?.animalHabitat ? 1 : 0,
    });
  }, [impactAndScopeData]);

  useEffect(() => {
    if (isSaveProgress || submitResponse) {
      setCompletionProgramData(
        {
          locationDetails: {
            additionalLocationDetails: locationOptional,
            regions: impactAndScopeData?.locationDetails?.regions,
            countries: impactAndScopeData?.locationDetails?.countries,
            states: impactAndScopeData?.locationDetails?.states,
            cities: impactAndScopeData?.locationDetails?.cities,
            locationExplanation: {
              regions: regionOptional !== undefined && regionOptional !== '' ? regionOptional : '',
              countries:
                countryOptional !== undefined && countryOptional !== '' ? countryOptional : '',
              states: stateOptional !== undefined && stateOptional !== '' ? stateOptional : '',
              cities: cityOptional !== undefined && cityOptional !== '' ? cityOptional : '',
            },
          },
          primaryImpact: impactAndScopeData?.primaryImpact,
          secondaryImpacts: impactAndScopeData?.secondaryImpacts,
          causes: impactAndScopeData?.causes,
          protectAndEnhanceForest: protectForest,
          targetGroup: impactAndScopeData?.targetGroup,
          audienceAge: impactAndScopeData?.audienceAge,
          animalHabitat: impactAndScopeData?.animalHabitat,
        },
        'impactAndScope',
      );
      setProcess();
    }
  }, [isSaveProgress, submitResponse]);

  useEffect(() => {
    const filteredData =
      locationsData &&
      Object.keys(locationsData?.countries)
        .filter((key) => {
          const regions: string[] = impactAndScopeData?.locationDetails?.regions || [];
          return regions.includes(key);
        })
        .reduce((obj: any, key: any) => {
          obj[key] = locationsData?.countries[key];
          return obj;
        }, {});
    setFilterCountries(filteredData);
  }, [locationsData, impactAndScopeData?.locationDetails?.regions]);

  useEffect(() => {
    if (locationsData && impactAndScopeData?.locationDetails?.countries) {
      const filteredCitiesData: string[] = [];
      const allCities = Object.entries(locationsData!.cities);
      impactAndScopeData?.locationDetails?.countries?.map((items: any) => {
        allCities.map((item: any) => {
          const n = items.lastIndexOf(';');
          const result = items.substring(n + 1);
          if (item[0].includes(result)) {
            filteredCitiesData.push(item);
          }
          return true;
        });
        return true;
      });
      setFilterCities(filteredCitiesData);

      const filteredStateData: string[] = [];
      const allStates = Object.entries(locationsData!.states);
      impactAndScopeData?.locationDetails?.countries?.map((items: any) => {
        allStates.map((item: any) => {
          const n = items.lastIndexOf(';');
          const result = items.substring(n + 1);
          if (item[0].includes(result)) {
            filteredStateData.push(item);
          }
          return true;
        });
        return true;
      });
      setFilterStates(filteredStateData);
    }
  }, [
    locationsData,
    impactAndScopeData?.locationDetails?.countries,
    impactAndScopeData?.locationDetails?.regions,
  ]);

  useEffect(() => {
    // const regions = Object.values(validatedAnswers?.locationDetails)?.filter(
    //   (value) => value === 1,
    // )?.length;
    const balKeyValues = Object.values(validatedAnswers)?.filter((value) => value === 1)?.length;
    validatedQuestions(balKeyValues);
  }, [validatedAnswers]);

  const handleCheck = (event: any, key: string) => {
    if (event.target.checked) {
      setImpactAndScopeData((prevValues: any) => ({
        ...prevValues,
        [key]: [event.target.value],
      }));
    } else {
      setImpactAndScopeData((prevValues: any) => ({
        ...prevValues,
        [key]: [],
      }));
    }
  };

  const handleDataChange = (
    event: any,
    key: string,
    parentKey: string,
    type: string,
    isRequired?: boolean,
  ) => {
    if (parentKey !== '') {
      if (type === 'typeCheck') {
        if (event.target.checked) {
          if (key !== 'states') {
            setImpactAndScopeData((prevState: any | undefined) => ({
              ...prevState,
              [parentKey]: {
                ...prevState[parentKey],
                [key]: [...(prevState?.[parentKey]?.[key] || []), event.target.value],
              },
            }));
          } else {
            setImpactAndScopeData((prevState: any | undefined) => ({
              ...prevState,
              [parentKey]: {
                ...prevState[parentKey],
                states: [...(prevState?.[parentKey]?.states || []), event.target.value],
              },
            }));
          }
        } else if (!event.target.checked) {
          setImpactAndScopeData((prevState: any | undefined) => ({
            ...prevState,
            [parentKey]: {
              ...prevState[parentKey],
              [key]: prevState[parentKey]?.[key].filter((item: any) => item !== event.target.value),
            },
          }));
          const uncheckedKeys = Object.keys(locationsData!.countries)?.filter(
            (keyValue: string) => keyValue === event.target.value,
          );
          const keyData = Object(
            locationsData?.countries![uncheckedKeys[0] as keyof typeof locationsData.countries],
          ).map((value: any) => value.name);
          setImpactAndScopeData((prevState: any) => ({
            ...prevState,
            locationDetails: {
              ...prevState.locationDetails,
              countries: impactAndScopeData?.locationDetails?.countries.filter(
                (value) => !keyData.includes(value),
              ),
            },
          }));
        }
      } else {
        setImpactAndScopeData((prevState: any) => ({
          ...prevState,
          [parentKey]: {
            ...prevState[parentKey],
            [key]: event.target.value,
          },
        }));
      }
    }
    if (parentKey === '') {
      if (event.target.checked) {
        if (type !== 'typeCheck') {
          setImpactAndScopeData((prevState: any) => ({
            ...prevState,
            [key]: event.target.value,
          }));
        } else if (
          (key === 'causes' && impactAndScopeData?.causes.length! < 2) ||
          (key === 'secondaryImpacts' && impactAndScopeData?.secondaryImpacts.length! < 3) ||
          (key !== 'causes' && key !== 'secondaryImpacts')
        ) {
          setImpactAndScopeData((prevState: any | undefined) => {
            const filteredValue = prevState[key]?.filter((item: any) => item !== 'value');
            return {
              ...prevState,
              [key]: [...(filteredValue || []), event.target.value],
            };
          });
        } else {
          toast.warning('Maximum limit reached');
        }
      }
      if (!event.target.checked) {
        if (type !== 'typeCheck') {
          setImpactAndScopeData((prevState: any) => ({
            ...prevState,
            [key]: event.target.value,
          }));
        } else {
          setImpactAndScopeData((prevState: any | undefined) => ({
            ...prevState,
            [key]: prevState[key].filter((item: any) => item !== event.target.value),
          }));
        }
      }
    }
    if (isRequired) {
      if (parentKey !== '') {
        setvalidateAnswers((prevState: any) => ({
          ...prevState,
          [parentKey]: {
            ...prevState[parentKey],
            [key]: validateFields(event.target.value),
          },
        }));
      } else {
        setvalidateAnswers((prevValues: any) => ({
          ...prevValues,
          [key]: validateFields(event.target.value),
        }));
      }
    }
  };

  useEffect(() => {
    if (impactAndScopeData?.locationDetails?.regions?.length === 0) {
      setImpactAndScopeData((prevState: any | undefined) => ({
        ...prevState,
        locationDetails: {
          ...prevState.locationDetails,
          countries: [],
          states: [],
          cities: [],
        },
      }));
    }
  }, [impactAndScopeData?.locationDetails?.regions]);

  // useEffect(()=>{
  // if(impactAndScopeData?.causes.length!>2){
  //   toast.warning("You can select upto 2 only")
  // }
  // },[impactAndScopeData?.causes])

  useEffect(() => {
    if (operateAnyWhere) {
      setImpactAndScopeData((prevState: any | undefined) => ({
        ...prevState,
        locationDetails: {
          ...prevState.locationDetails,
          regions: [],
          countries: [],
          states: [],
          cities: [],
        },
      }));
    }
  }, [operateAnyWhere]);

  /* Function to replace the value if any selection in the same cause */
  // const checkValue = (event: any, causeId: string) => {
  //   const impactDataCheck = vocabData?.impact?.filter((item: any) => item.causeId === causeId);
  //   /* eslint-disable no-underscore-dangle */
  //   const selectedObjects = impactDataCheck?.filter((obj: { _id: never }) =>
  //     impactAndScopeData?.primaryImpact?.includes(obj._id),
  //   );
  //   const updateValues = impactAndScopeData?.primaryImpact?.filter(
  //     (value) => value !== selectedObjects[0]?._id,
  //   );
  //   setImpactAndScopeData((prevState: any) => ({
  //     ...prevState,
  //     primaryImpact: updateValues,
  //   }));
  //   handleDataChange(event, 'primaryImpact', '', 'typeCheck', false);
  // };

  const checkValue = (event: any) => {
    setImpactAndScopeData((prevState: any) => ({
      ...prevState,
      primaryImpact: [],
    }));
    handleDataChange(event, 'primaryImpact', '', 'typeCheck', false);
  };
  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
  }

  return (
    <>
      <BoxSection>
        <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 1 of 12
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="Which cause areas best match this program's work?"
          description="Please select up to two from the list below."
          disabled={submitted}
        />
        <ScrollBox submitted={submitted}>
          <Grid container spacing={2}>
            {vocabularyData &&
              vocabularyData.causes &&
              vocabularyData.causes.map((cause: any) => {
                /* eslint-disable no-underscore-dangle */
                const selectedValues =
                  impactAndScopeData &&
                  impactAndScopeData.causes &&
                  impactAndScopeData?.causes?.filter((value) => value === cause._id);
                const checkedValue = selectedValues && selectedValues[0];
                return (
                  <Grid item xs={4} key={cause.label}>
                    <CheckBoxInput
                      label={cause.label}
                      type="checkbox"
                      value={cause._id}
                      disabled={submitted}
                      checked={cause._id === checkedValue}
                      onChange={(event) => handleDataChange(event, 'causes', '', 'typeCheck', true)}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </ScrollBox>
      </BoxSection>
      <BoxSection>
        <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 2 of 12
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="What is this program's primary targeted impact?"
          description="Impact are long-term effects of your program. Please select one option from the list below."
          disabled={submitted}
        />
        {vocabularyData && (
          <ScrollBox submitted={submitted}>
            {vocabularyData.causes &&
              vocabularyData.causes.map((cause: any) => {
                /* eslint-disable no-underscore-dangle */
                const filteredImpacts =
                  vocabData.impact &&
                  vocabData.impact.filter((item: any) => item.causeId === cause._id);
                return (
                  <BoxSection key={cause.label}>
                    <Typography sx={{ ...commonTextStyle.labelStyle, mb: 2}}>
                      {cause.label}
                    </Typography>
                    <Grid container spacing={2}>
                      {filteredImpacts &&
                        filteredImpacts.map((impact: any) => {
                          const selectedValues =
                            impactAndScopeData?.primaryImpact &&
                            impactAndScopeData.primaryImpact.filter(
                              (value) => value === impact._id,
                            );
                          const checkedValue = selectedValues && selectedValues[0];

                          return (
                            <Grid item xs={4} key={impact.label}>
                              <CheckBoxInput
                                label={impact.label}
                                type="radio"
                                disabled={submitted}
                                value={impact._id}
                                checked={impact._id === checkedValue}
                                // onChange={(event) => checkValue(event, cause._id)}
                                onChange={(event) => checkValue(event)}
                              />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </BoxSection>
                );
              })}
          </ScrollBox>
        )}
      </BoxSection>
      <BoxSection>
        <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 3 of 12
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          disabled={submitted}
          title="What are the secondary impacts of this program?"
          description="Impacts are long-term effects of your program. Please select up to three the list below, or N/A if not applicable"
        />
        <ScrollBox submitted={submitted}>
          {vocabularyData?.impact?.map((subItem: any) => {
            if (subItem?.label === 'N/A') {
              return (
                <Grid item xs={4} sx={{ mb: 2 }}>
                  <CheckBoxInput
                    label={subItem?.label}
                    type="checkbox"
                    value={subItem?._id}
                    onChange={(event) => {
                      setIsImpactNotApplicable(!isImpactNotApplicable);
                      handleCheck(event, 'secondaryImpacts');
                    }}
                    disabled={submitted}
                    checked={impactAndScopeData?.secondaryImpacts?.includes(subItem._id) ?? false}
                  />
                </Grid>
              );
            }
            return null;
          })}
          {vocabularyData &&
            vocabularyData.causes &&
            vocabularyData.causes.map((cause: any) => {
              /* eslint-disable no-underscore-dangle */
              const filteredImpacts =
                vocabData.impact &&
                vocabData.impact.filter((item: any) => item.causeId === cause._id);
              return (
                <BoxSection key={cause.label}>
                  <Typography sx={{ ...commonTextStyle.labelStyle, mb: 2}}>
                    {cause.label}
                  </Typography>
                  <Grid container spacing={2}>
                    {filteredImpacts &&
                      filteredImpacts?.map((impact: any) => {
                        const selectedValues =
                          impactAndScopeData &&
                          impactAndScopeData?.secondaryImpacts?.filter(
                            (value) => value === impact._id,
                          );
                        const checkedValue = selectedValues && selectedValues[0];
                        return (
                          <Grid item xs={4} key={impact.label}>
                            <CheckBoxInput
                              label={impact.label}
                              type="checkbox"
                              value={impact._id}
                              disabled={isImpactNotApplicable || submitted}
                              onChange={(event) =>
                                handleDataChange(event, 'secondaryImpacts', '', 'typeCheck', true)
                              }
                              checked={impact._id === checkedValue}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </BoxSection>
              );
            })}
        </ScrollBox>
      </BoxSection>
      {impactAndScopeData &&
        impactAndScopeData?.secondaryImpacts &&
        impactAndScopeData?.secondaryImpacts?.filter(
          (value) => value === '5d56e39082098a55e6ad6307',
        ).length > 0 && (
          <BoxSection>
            <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
              Question 4 of 12
              {submitted && (
                <img
                  src={Progressed}
                  style={{ height: '14px', marginLeft: '5px' }}
                  alt="Progressed"
                />
              )}
            </Typography>
            <TextAreaInput
              title="Does this program specifically protect or enhance forests?"
              disabled={submitted}
            />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <CheckBoxInput
                  label="Yes"
                  type="radio"
                  value="Yes"
                  checked={protectForest}
                  onChange={() => {
                    setProtectForest(true);
                  }}
                  disabled={submitted}
                />
              </Grid>
              <Grid item xs={4}>
                <CheckBoxInput
                  label="No"
                  type="radio"
                  value="No"
                  checked={!protectForest}
                  onChange={() => {
                    setProtectForest(false);
                  }}
                  disabled={submitted}
                />
              </Grid>
            </Grid>
          </BoxSection>
        )}
      {impactAndScopeData &&
        impactAndScopeData?.secondaryImpacts?.filter((value) =>
          ['5aec61bff581061865110cb6', '5d56e39082098a55e6ad6309'].includes(value),
        ).length > 0 && (
          <BoxSection>
            <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
              Question 5 of 12
              {submitted && (
                <img
                  src={Progressed}
                  style={{ height: '14px', marginLeft: '5px' }}
                  alt="Progressed"
                />
              )}
            </Typography>
            <TextAreaInput
              title="Please select the habitat of the animals that you protect."
              disabled={submitted}
            />
            <Grid container spacing={2}>
              {vocabularyData &&
                vocabularyData?.animalHabitats &&
                vocabularyData?.animalHabitats?.map((habitat: any) => {
                  const checkedValue = impactAndScopeData && impactAndScopeData?.animalHabitat;
                  return (
                    <Grid item xs={4} key={habitat.label}>
                      <CheckBoxInput
                        label={habitat.label}
                        type="radio"
                        disabled={isTarget || submitted}
                        value={habitat._id}
                        onChange={(event) => handleDataChange(event, 'animalHabitat', '', '', true)}
                        checked={habitat._id === checkedValue}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </BoxSection>
        )}
      <BoxSection>
        <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 6 of 12
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="Does this program specifically target any of the following groups of people?"
          description="Please select all that apply."
          disabled={submitted}
        />
        <ScrollBox submitted={submitted}>
          <Grid container spacing={2}>
            {vocabularyData &&
              vocabularyData?.audience?.attribute &&
              vocabularyData?.audience?.attribute.map((attribute: any) => {
                const selectedValues =
                  impactAndScopeData &&
                  impactAndScopeData?.targetGroup &&
                  impactAndScopeData?.targetGroup?.filter((value) => value === attribute._id);
                const checkedValue = selectedValues && selectedValues[0];
                const findNaObject: any = vocabularyData?.audience?.attribute?.find(
                  (obj: any) => obj.label === 'N/A',
                );
                return (
                  <Grid item xs={4} key={attribute.label}>
                    <CheckBoxInput
                      label={attribute.label}
                      type="checkbox"
                      disabled={
                        (impactAndScopeData?.targetGroup?.includes(findNaObject._id) &&
                          attribute.label !== 'N/A') ||
                        submitted
                      }
                      value={attribute._id}
                      onChange={(event) => {
                        if (attribute.label === 'N/A') {
                          setIsTarget(!isTarget);
                          handleCheck(event, 'targetGroup');
                        } else {
                          handleDataChange(event, 'targetGroup', '', 'typeCheck', true);
                        }
                      }}
                      checked={attribute._id === checkedValue}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </ScrollBox>
      </BoxSection>
      <BoxSection>
        <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 7 of 12
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="What age groups does this program serve?"
          description="Please select all that apply."
          disabled={submitted}
        />
        <Grid container spacing={2}>
          {vocabularyData &&
            vocabularyData?.audience?.age &&
            vocabularyData?.audience?.age?.map((attribute: any) => {
              const selectedValues =
                impactAndScopeData &&
                impactAndScopeData?.audienceAge &&
                impactAndScopeData?.audienceAge?.filter((value) => value === attribute._id);
              const checkedValue = selectedValues && selectedValues[0];
              const findNaObject: any = vocabularyData?.audience?.age?.find(
                (obj: any) => obj.label === 'N/A',
              );
              return (
                <Grid item xs={3} key={attribute.label}>
                  <CheckBoxInput
                    label={attribute.label}
                    type="checkbox"
                    disabled={
                      (impactAndScopeData?.audienceAge?.includes(findNaObject._id) &&
                        attribute.label !== 'N/A') ||
                      submitted
                    }
                    value={attribute._id}
                    onChange={(event) => {
                      if (attribute.label === 'N/A') {
                        handleCheck(event, 'audienceAge');
                      } else {
                        handleDataChange(event, 'audienceAge', '', 'typeCheck', true);
                      }
                    }}
                    checked={attribute._id === checkedValue}
                  />
                </Grid>
              );
            })}
        </Grid>
      </BoxSection>
      <BoxSection>
        <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 8 of 12
          {submitted && (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="In which specific regions does this program operate?"
          description="Please select all that apply."
          disabled={submitted}
        />
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={4}>
            <CheckBoxInput
              label="Able to operate in anywhere (please explain)"
              type="checkbox"
              value="anywhere"
              onChange={(event) => {
                setOperateAnyWhere(!operateAnyWhere);
                if (!event.target.checked) {
                  setRegionOptional('');
                }
              }}
              disabled={submitted}
              checked={operateAnyWhere}
            />
          </Grid>
          <Grid item xs={8} sx={{ pl: 4 }}>
            <Typography>Description</Typography>
            <GridInput
              value={regionOptional}
              onChange={(event) => setRegionOptional(event.target.value)}
              disabled={submitted}
            />
          </Grid>
        </Grid>
        <ScrollBox submitted={submitted}>
          <BoxSection>
            <Grid
              container
              sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
            >
              <Grid item>
                <SearchInput onChange={(e) => setSearchQuery(e.target.value)} />
              </Grid>
              <Grid item>
                <Typography
                  sx={{ color: '#005fea', mx: 2, fontSize: '16px', cursor: 'pointer' }}
                  onClick={() => selectAllRegions(!selectAll)}
                >
                  Select All
                </Typography>
              </Grid>
            </Grid>
            <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
              Regions
            </Typography>
            <Grid container spacing={2}>
              {(filteredContinents?.length > 0
                ? filteredContinents
                : locationsData?.continents
              )?.map((continent) => {
                const selectedValues =
                  impactAndScopeData?.locationDetails &&
                  impactAndScopeData?.locationDetails?.regions?.filter(
                    (value) => value === continent.name,
                  );
                const checkedValue = selectedValues && selectedValues[0];
                return (
                  <Grid item xs={4} key={continent.name}>
                    <CheckBoxInput
                      label={continent?.name}
                      type="checkbox"
                      value={continent.name}
                      onChange={(event) =>
                        handleDataChange(event, 'regions', 'locationDetails', 'typeCheck', true)
                      }
                      disabled={operateAnyWhere || submitted}
                      checked={!selectAll ? continent?.name === checkedValue : true}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </BoxSection>
        </ScrollBox>
      </BoxSection>
      {!operateAnyWhere &&
        impactAndScopeData?.locationDetails?.regions &&
        impactAndScopeData?.locationDetails?.regions?.length > 0 && (
          <BoxSection>
            <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
              Question 9 of 12
              {submitted && (
                <img
                  src={Progressed}
                  style={{ height: '14px', marginLeft: '5px' }}
                  alt="Progressed"
                />
              )}
            </Typography>
            <TextAreaInput
              title="In which specific countries does this program operate?"
              description="Please select all that apply."
              disabled={submitted}
            />
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <CheckBoxInput
                  label="Not able to specify further (please explain)"
                  type="checkbox"
                  value="anywhere"
                  onChange={(event) => {
                    setOperateCountryAnyWhere(!operateCountryAnyWhere);
                    if (event.target.checked) {
                      setImpactAndScopeData((prevValues: any) => ({
                        ...prevValues,
                        locationDetails: {
                          ...prevValues.locationDetails,
                          countries: [],
                          states: [],
                          cities: [],
                        },
                      }));
                    } else {
                      setCountryOptional('');
                    }
                  }}
                  checked={operateCountryAnyWhere}
                  disabled={submitted}
                />
              </Grid>
              <Grid item xs={8} sx={{ pl: 4 }}>
                <Typography>Description</Typography>
                <GridInput
                  value={countryOptional}
                  onChange={(event) => setCountryOptional(event.target.value)}
                  disabled={submitted}
                />
              </Grid>
            </Grid>
            {filteredCountries && (
              <ScrollBox submitted={submitted}>
                {Object.entries(filteredCountries).map(([key, value]: any) => (
                  <BoxSection key={key}>
                    {/* <Grid
                      container
                      sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                    >
                      <Grid item>
                        <SearchInput />
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{ color: '#005fea', mx: 2, fontSize: '16px', cursor: 'pointer' }}
                        >
                          Select All
                        </Typography>
                      </Grid>
                    </Grid> */}
                    <Grid container spacing={2}>
                      <Grid item xs={12} sx={commonTextStyle.heading}>
                        {key}
                      </Grid>
                      {value.map((rec: any) => {
                        const selectedValues =
                          impactAndScopeData?.locationDetails &&
                          impactAndScopeData?.locationDetails?.countries?.filter(
                            (locValue) => locValue === `${key};${rec.name}`,
                          );
                        const checkedValue = selectedValues && selectedValues[0];
                        return (
                          <Grid item xs={4} key={rec.name}>
                            <CheckBoxInput
                              label={rec.name}
                              type="checkbox"
                              value={`${key};${rec.name}`}
                              onChange={(event) =>
                                handleDataChange(
                                  event,
                                  'countries',
                                  'locationDetails',
                                  'typeCheck',
                                  false,
                                )
                              }
                              disabled={operateCountryAnyWhere || submitted}
                              checked={`${key};${rec.name}` === checkedValue}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </BoxSection>
                ))}
              </ScrollBox>
            )}
          </BoxSection>
        )}
      {impactAndScopeData &&
        impactAndScopeData?.locationDetails?.countries &&
        impactAndScopeData?.locationDetails?.countries.filter((value: any) => {
          const n = value.lastIndexOf(';');
          const result = value.substring(n + 1);
          return ['United States', 'Canada'].includes(result);
        }).length > 0 &&
        impactAndScopeData?.locationDetails?.regions?.length > 0 && (
          <BoxSection>
            <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
              Question 10 of 12{' '}
              {submitted && (
                <img
                  src={Progressed}
                  style={{ height: '14px', marginLeft: '5px' }}
                  alt="Progressed"
                />
              )}
            </Typography>
            <TextAreaInput
              title="In which specific States does this program operate?"
              description="Please select all that apply."
              disabled={submitted}
            />
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <CheckBoxInput
                  label="Able to operate in anywhere (please explain)"
                  type="checkbox"
                  value="anywhere"
                  onChange={(event) => {
                    setOperateStateAnyWhere(!operateStateAnyWhere);
                    if (event.target.checked) {
                      setImpactAndScopeData((prevValues: any) => ({
                        ...prevValues,
                        locationDetails: {
                          ...prevValues.locationDetails,
                          states: [],
                        },
                      }));
                    } else {
                      setStateOptional('');
                    }
                  }}
                  checked={operateStateAnyWhere}
                  disabled={submitted}
                />
              </Grid>
              <Grid item xs={8} sx={{ pl: 4 }}>
                <Typography>Description</Typography>
                <GridInput
                  value={stateOptional}
                  onChange={(event) => setStateOptional(event.target.value)}
                  disabled={submitted}
                />
              </Grid>
            </Grid>
            <ScrollBox submitted={submitted}>
              {impactAndScopeData?.locationDetails?.countries.map((item: string) => {
                const n = item.lastIndexOf(';');
                const result = item.substring(n + 1);
                if (['United States', 'Canada'].includes(result)) {
                  return filteredStates.map(([key, value]: any) => {
                    if (key === result) {
                      return (
                        <BoxSection key={key}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sx={commonTextStyle.heading}>
                              {key}
                            </Grid>
                            {value.map((rec: any) => {
                              const selectedValues =
                                impactAndScopeData?.locationDetails &&
                                impactAndScopeData?.locationDetails?.states?.filter(
                                  (locValue: any) => locValue === `${item};${rec.name}`,
                                );
                              const checkedValue = selectedValues && selectedValues[0];
                              return (
                                <Grid item xs={4} key={rec.name}>
                                  <CheckBoxInput
                                    label={rec.name}
                                    type="checkbox"
                                    value={`${item};${rec.name}`}
                                    onChange={(event) =>
                                      handleDataChange(
                                        event,
                                        'states',
                                        'locationDetails',
                                        'typeCheck',
                                        false,
                                      )
                                    }
                                    disabled={submitted || operateStateAnyWhere}
                                    checked={`${item};${rec.name}` === checkedValue}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </BoxSection>
                      );
                    }
                    return null;
                  });
                }
                return null;
              })}
            </ScrollBox>
          </BoxSection>
        )}
      {!operateAnyWhere &&
        impactAndScopeData?.locationDetails?.countries &&
        impactAndScopeData?.locationDetails?.countries?.length > 0 &&
        impactAndScopeData?.locationDetails?.regions?.length > 0 && (
          <BoxSection>
            <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
              Question 11 of 12
              {submitted && (
                <img
                  src={Progressed}
                  style={{ height: '14px', marginLeft: '5px' }}
                  alt="Progressed"
                />
              )}
            </Typography>
            <TextAreaInput
              title="In which specific cities does this program operate?"
              description="Please select all that apply."
              disabled={submitted}
            />
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <CheckBoxInput
                  label="Able to operate in anywhere (please explain)"
                  type="checkbox"
                  value="anywhere"
                  onChange={(event) => {
                    setOperateCityAnyWhere(!operateCityAnyWhere);
                    if (event.target.checked) {
                      setImpactAndScopeData((prevValues: any) => ({
                        ...prevValues,
                        locationDetails: {
                          ...prevValues.locationDetails,
                          cities: [],
                        },
                      }));
                    } else {
                      setCityOptional('');
                    }
                  }}
                  checked={operateCityAnyWhere}
                  disabled={submitted}
                />
              </Grid>
              <Grid item xs={8} sx={{ pl: 4 }}>
                <Typography>Description</Typography>
                <GridInput
                  value={cityOptional}
                  onChange={(event) => setCityOptional(event.target.value)}
                  disabled={submitted}
                />
              </Grid>
            </Grid>
            {filteredCities && (
              <ScrollBox submitted={submitted}>
                {impactAndScopeData?.locationDetails?.countries.map((item: string) => {
                  const n = item.lastIndexOf(';');
                  const result = item.substring(n + 1);
                  if (!['United States', 'Canada'].includes(result)) {
                    return filteredCities.map(([key, value]: any) => {
                      if (key === result) {
                        return (
                          <BoxSection key={key}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sx={commonTextStyle.heading}>
                                {key}
                              </Grid>
                              {value.map((rec: any) => {
                                const selectedValues =
                                  impactAndScopeData?.locationDetails &&
                                  impactAndScopeData?.locationDetails?.cities?.filter(
                                    (locValue) => locValue === `${item};${rec.name}`,
                                  );
                                const checkedValue = selectedValues && selectedValues[0];
                                return (
                                  <Grid item xs={4} key={rec.name}>
                                    <CheckBoxInput
                                      label={rec.name}
                                      type="checkbox"
                                      value={`${item};${rec.name}`}
                                      onChange={(event) =>
                                        handleDataChange(
                                          event,
                                          'cities',
                                          'locationDetails',
                                          'typeCheck',
                                          false,
                                        )
                                      }
                                      disabled={submitted || operateCityAnyWhere}
                                      checked={`${item};${rec.name}` === checkedValue}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </BoxSection>
                        );
                      }
                      return null;
                    });
                  }
                  return null;
                })}
              </ScrollBox>
            )}
          </BoxSection>
        )}
      <BoxSection>
        <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 12 of 12
          {submitted && (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="Is there anything you'd like to add about where your program operates?"
          secondaryTitle="Description"
          isTextField
          infoIcon
          tooltipText="Description"
          value={locationOptional}
          disabled={submitted}
          onChange={(event) => setLocationOptional(event.target.value)}
        />
      </BoxSection>
    </>
  );
};

export default ImpactScope;
