import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { FlexBoxColumn } from '../../shared/components';
import { esg, gri, metric, sasb, sdg } from '../../shared/interfaces';
import { MetricComponent } from './MetricComponent';
import { SDGComponent } from './SDGComponent';

interface Props {
  SDGS: sdg[];
  MSCI: esg[];
  CSRHUB: metric[];
  SASB: sasb[];
  GRI: gri[];
}
export const ReportingMetrics: FC<Props> = ({ SASB, SDGS, MSCI, CSRHUB, GRI }) => (
  <FlexBoxColumn className="reportingMetrics__container">
    <Typography variant="h4" sx={(theme) => ({ color: theme.palette.secondary.dark })}>
      {' '}
      Reporting Metrics for this program
    </Typography>
    {SDGS?.length > 0 && <SDGComponent SDGS={SDGS} />}
    {MSCI?.length > 0 && <MetricComponent type="msci" impacts={MSCI} />}
    {CSRHUB?.length > 0 && <MetricComponent type="csrhub" impacts={CSRHUB} />}
    {SASB?.length > 0 && <MetricComponent type="sasb" impacts={SASB} />}
    {GRI?.length > 0 && <MetricComponent type="GRI" impacts={GRI} />}
  </FlexBoxColumn>
);
