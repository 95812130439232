import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { getSubstrTitle } from '../utils';
import { FlexBox, LightTooltip } from './StyledComponents';

interface Props {
  title: string;
  componentHeader?: JSX.Element;
  styleContainer?: React.CSSProperties;
  styleHeader?: React.CSSProperties;
  styleTitle?: React.CSSProperties;
  maxTitleLength?: number;
  toolTipOnTitle?: boolean;
}

export const CardHeaderBlue: FC<Props> = (props) => {
  const {
    title,
    componentHeader,
    styleContainer,
    styleHeader,
    children,
    maxTitleLength,
    styleTitle,
    toolTipOnTitle,
  } = props;

  return (
    <Box
      sx={{
        borderRadius: 5,
        backgroundColor: 'white',
        ...styleContainer,
      }}
    >
      <FlexBox
        sx={{
          borderRadius: 5,
          backgroundColor: '#313B72',
          padding: '20px',
          alignContent: 'space-between',
          ...styleHeader,
        }}
      >
        <Box sx={{ color: 'white', flex: 1, alignItems: 'center' }}>
          {toolTipOnTitle ? (
            <LightTooltip title={title}>
              <Typography
                variant="h5"
                sx={{ color: 'white', flex: 1, alignItems: 'center', ...styleTitle }}
              >
                {maxTitleLength ? getSubstrTitle(title, maxTitleLength) : title}
              </Typography>
            </LightTooltip>
          ) : (
            <Typography
              variant="h5"
              sx={{ color: 'white', flex: 1, alignItems: 'center', ...styleTitle }}
            >
              {maxTitleLength ? getSubstrTitle(title, maxTitleLength) : title}
            </Typography>
          )}
        </Box>
        {componentHeader && componentHeader}
      </FlexBox>
      <Box sx={{ padding: '20px' }}>{children}</Box>
    </Box>
  );
};
