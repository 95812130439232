import React, { useEffect, ChangeEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Button,
  Box,
  Tooltip,
  TextField,
  Divider,
  Card,
  CardContent,
  Typography,
  CardHeader,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import '../sio/formFilling.scss';
import { fetchProgrambyUser, updateProgrambyUserThunk } from './ProgramSlice';
import { CellPhoneInput } from '../../shared/components';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#68839A',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#FFF',
  },
}));

const ProgramGeneralQuestions = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const programState = useAppSelector((state) => state.programs);
  const { program } = programState;
  const initialFormValue = {
    fistLastName: program?.general?.contact?.name,
    countryCode: program?.general?.contact?.countryCode,
    phone: program?.general?.contact?.phone,
    email: program?.general?.contact?.email,
    internalName: program?.general?.programName?.internalProgramName,
    platformName: program?.general?.programName?.platformProgramName,
    description: program?.general?.programDescription?.description,
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [pageNumber, setPageNumber] = useState(1);
  const countryCodes = [
    { code: '+1', label: 'USA (+1)' },
    { code: '+44', label: 'UK (+44)' },
    { code: '+91', label: 'India (+91)' },
    // Add more country codes as needed
  ];

  useEffect(() => {
    if (id && program) {
      dispatch(fetchProgrambyUser(id)).then((programData: any) => {
        setFormValues({
          fistLastName: programData.payload?.general?.contact?.name,
          countryCode: programData.payload?.general?.contact?.countryCode,
          phone: programData.payload?.general?.contact?.phone,
          email: programData.payload?.general?.contact?.email,
          internalName: programData.payload?.general?.programName?.internalProgramName,
          platformName: programData.payload?.general?.programName?.platformProgramName,
          description: programData.payload?.general?.programDescription?.description,
        });
      });
    }
  }, [id]);

  const updateProgramDetails = () => {
    const payload = {
      general: {
        contact: {
          email: formValues.email,
          name: formValues.fistLastName,
          phone: formValues.phone,
          countryCode: formValues.countryCode,
        },
        programDescription: {
          description: formValues.description,
        },
        programName: {
          internalProgramName: formValues.internalName,
          platformProgramName: formValues.platformName,
        },
      },
    };
    const programPayload = payload;
    if (id) {
      return dispatch(updateProgrambyUserThunk({ program: programPayload, id }));
    }
    return true;
  };

  const handlePreviousbutton = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextButton = async () => {
    const response = await updateProgramDetails();
    if (response) {
      if (pageNumber < 3) {
        setPageNumber(pageNumber + 1);
      } else {
        navigate(`/app/sio/programs/${id}`);
      }
    } else {
      toast.error('An error occurred while submitting in. Please try again.');
    }
    return null;
  };

  const handleSaveExit = async () => {
    const response = await updateProgramDetails();
    if (response) {
      navigate(`/app/sio/programs/${id}`);
    } else {
      toast.error('An error occurred while logging in. Please try again.');
    }
    return null;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    // const NUMERIC_DASH_REGEX = /^[0-9-]+$/;
    // if (name === 'phone') {
    //     console.log(value);
    //     if (NUMERIC_DASH_REGEX.test(value)) { setFormValues({ ...formValues, [name]: value }); }
    // } else {
    setFormValues({ ...formValues, [name]: value });
    // }
  };

  const handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    // Update state specifically for the country code select component
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  // *** Start Questions ***
  const question1 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 5 }} variant="h3">
          Let&apos;s get started!
        </Typography>
        <Typography sx={commonTextStyle.questionStyle}>Question 1 of 3</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Who will be the main contact person for this program?
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          First Name & Last Name{' '}
          <Tooltip title="Enter First Name & Last Name">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="fistLastName"
          placeholder="Enter your first name and last name"
          fullWidth
          value={formValues.fistLastName}
          required
          onChange={handleChange}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Email Address{' '}
          <Tooltip title="Enter email address">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="email"
          placeholder="Enter your email address"
          fullWidth
          required
          value={formValues.email}
          onChange={handleChange}
          variant="outlined"
          type="email"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={{ justifyContent: 'center' }}>
        <Typography sx={commonTextStyle.labelStyle}>
          Phone Number{' '}
          <Tooltip title="Enter phone number">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <FormControl fullWidth sx={{ maxWidth: '120px' }}>
            <InputLabel id="country-code-label">Code</InputLabel>
            <Select
              name="countryCode"
              labelId="country-code-label"
              id="country-code-select"
              value={formValues.countryCode}
              label="Code"
              onChange={handleCountryCodeChange}
              sx={{ height: '100%', mr: 1, fontSize: '12pt' }}
            >
              {countryCodes.map((option) => (
                <MenuItem key={option.code} value={option.code} sx={{ height: '50%' }}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            name="phone"
            placeholder="Enter phone number"
            fullWidth
            onChange={handleChange}
            type="text"
            margin="normal"
            value={formValues.phone}
            variant="outlined"
            error={
              formValues.phone !== undefined &&
              (formValues.phone[0] === '0' ||
                formValues.phone[0] === '1' ||
                (formValues.phone.length < 10 && formValues.phone !== ''))
            }
            InputProps={{
              inputComponent: CellPhoneInput as any,
            }}
            sx={commonTextStyle.textFieldStyle}
          />
        </Box>
      </Grid>
    </Grid>
  );

  const question2 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 2 of 3</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Please provide a name for this program.
        </Typography>
        <Typography sx={{ mb: 2 }} variant="body1">
          Please provide both the internal name (how you refer to it internally) as well as the name
          as you would like it to appear on our platform. Platform program name will be visible to
          companies working with ScaleWith.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Internal Program name{' '}
          <Tooltip title="Enter Internal Program name">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="internalName"
          placeholder="Enter internal program name"
          fullWidth
          value={formValues.internalName}
          onChange={handleChange}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            mb: 3,
            float: 'right',
            padding: '15px 20px',
            width: '300px',
            background: '#E7F2FF',
            borderRadius: '20px',
          }}
        >
          <Typography variant="body1" sx={{ mb: 5 }}>
            <InfoIcon fontSize="medium" />
            Platform program name should start with an action verb in the present tense.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Platform Program Name{' '}
          <Tooltip title="Enter Platform Program Name">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="platformName"
          placeholder="Enter your platform program name"
          value={formValues.platformName}
          fullWidth
          onChange={handleChange}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            mb: 3,
            float: 'right',
            padding: '15px 20px',
            width: '300px',
            background: '#E7F2FF',
            borderRadius: '20px',
          }}
        >
          <Typography variant="body1" sx={{ mb: 5 }}>
            <InfoIcon fontSize="medium" /> Example :{' '}
          </Typography>
          <Typography variant="body1">
            <ul>
              <li>Bring music education to K-12 students</li>
              <li>Connect people to local waterways</li>
              <li>Educate nurses that serve first-time, low-income mothers</li>
            </ul>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  const question3 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 3 of 3 </Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Please provide a description of this program
        </Typography>
        <Typography sx={{ mb: 2 }} variant="body1">
          This will be visible to companies working with ScaleWith
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Description{' '}
          <Tooltip title="Enter description">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="description"
          placeholder="Enter your program description"
          fullWidth
          onChange={handleChange}
          margin="none"
          rows={4}
          multiline
          value={formValues.description}
          variant="outlined"
          sx={commonTextStyle.textAreaStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            float: 'right',
            padding: '15px 20px',
            width: '300px',
            background: '#E7F2FF',
            borderRadius: '20px',
          }}
        >
          <Typography variant="body1">
            <InfoIcon fontSize="medium" /> Please use descriptive language and action verbs.
            <br />
            <br />
            You may want to include details such as your goals, how you will accomplish them, and
            the beneficiaries you will serve.
            <br />
            <br />
            Description should be two paragraphs of about 500 characters each.
            <br />
            <br />
            Please do not use personal pronouns, i.e. use the organization name instead of
            &quot;we&quot; or &quot;our&quot;.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
  // *** end questions ***

  return (
    <section className="bgLight">
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardHeader
              sx={{
                padding: '20px 80px',
                background: colors.backgroundColorGradient,
                borderRadius: '20px 20px 0px 0px',
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize18,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Section 1
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize24,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      General Information
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        fontSize: fonts.fontSize18,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                        textAlign: 'right',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fontWeight600,
                          color: colors.secondary,
                        }}
                      >
                        {pageNumber === 1 ? 0 : (pageNumber - 1) * 30}% complete
                      </Typography>
                    </Grid>
                    <BorderLinearProgress
                      variant="determinate"
                      value={pageNumber === 1 ? 1 : (pageNumber - 1) * 30}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent sx={{ minHeight: '70vh', padding: '40px 80px' }}>
              {pageNumber === 1 && question1}
              {pageNumber === 2 && question2}
              {pageNumber === 3 && question3}
              <Grid item xs={12} textAlign="right">
                <Divider sx={{ my: 3 }} orientation="horizontal" flexItem />
                <Button
                  sx={{ mx: 2, color: colors.primary, border: `1px solid ${colors.primary}` }}
                  onClick={handleSaveExit}
                  variant="outlined"
                  size="large"
                >
                  Save and Exit
                </Button>
                <Button
                  sx={{ mx: 2, color: colors.primary, border: `1px solid ${colors.primary}` }}
                  disabled={pageNumber === 1}
                  onClick={handlePreviousbutton}
                  variant="outlined"
                  size="large"
                >
                  Previous
                </Button>
                <Button
                  sx={{
                    mx: 2,
                    color: colors.secondary,
                    border: `1px solid ${colors.primary}`,
                    backgroundColor: colors.primary,
                  }}
                  onClick={handleNextButton}
                  variant="contained"
                  size="large"
                >
                  Next
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default ProgramGeneralQuestions;
