import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../../shared/hooks';
import { ApprovalLevelsMode } from './accountSettingsSlice';
import { ApprovalLevelsForm } from './ApprovalLevelsForm';
import { ApprovalLevelsList } from './ApprovalLevelsList';

interface Props {
  formDisabled?: boolean;
}

export const AccountApprovalLevels: FC<Props> = (props) => {
  const mode = useAppSelector((x) => x.accountSettings.approvalLevels.mode);
  const data = useAppSelector((x) => x.accountSettings.approvalLevels.data);

  const getComponentMode = (value: ApprovalLevelsMode) => {
    switch (value) {
      case 'edit':
      case 'new':
        return <ApprovalLevelsForm />;
      default:
        return <ApprovalLevelsList {...props} />;
    }
  };

  if (!data) return <Typography>Approval Levels data not found</Typography>;

  return <Box minHeight="450px">{getComponentMode(mode)}</Box>;
};
