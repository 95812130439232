import { FormControlLabel } from '@mui/material';
import React, { FC } from 'react';
import { AntSwitch, FlexBox, SwitchLabelTypography } from './StyledComponents';

interface Props {
  label?: string;
  value: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  messageYes?: string;
  messageNo?: string;
  style?: React.CSSProperties;
}

export const LabelAndSwitch: FC<Props> = (props) => {
  const { label, value, disabled, messageNo, messageYes, onChange, style } = props;

  const changeStatusSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    onChange(e);
  };

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 2, flex: 1, ...style }}>
      <SwitchLabelTypography>{label?.toLocaleUpperCase()}</SwitchLabelTypography>
      <FormControlLabel
        sx={{ paddingLeft: 1, gap: 1 }}
        control={
          <AntSwitch
            onChange={changeStatusSwitch}
            checked={value}
            disabled={disabled === undefined ? false : disabled}
          />
        }
        label={value ? messageYes ?? 'YES' : messageNo ?? 'NO'}
      />
    </FlexBox>
  );
};
