import { Box, Typography, Tooltip } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { BorderLinearProgress, FlexBox } from '../../../shared/components';
import { moneyFormatter, formatter } from '../../../shared/utils';
import { CauseAreaSku, InstanceCauseAreaImpact, Locale } from '../../../shared/interfaces';
import colors from '../../../assets/scss/color.module.scss';

function getNewMax(max: number, total: number): number {
  if (max > total) return max;
  return getNewMax(max * 3, total);
}

interface Props {
  calculation: InstanceCauseAreaImpact;
  skus: Array<CauseAreaSku>;
  grandTotal: number;
  locale?: Locale;
}

const AmountBarComponent: FC<Props> = (props) => {
  const { calculation, skus, grandTotal, locale } = props;
  const [max, setMax] = useState(10000);
  const unitValue = calculation.unitValue ?? 1;
  const unitPrice = calculation.unitPrice ?? 1;
  const serviceFee = calculation.serviceFee ?? 0.18;
  const calculate = unitValue * ((grandTotal - grandTotal * serviceFee) / unitPrice);

  useEffect(() => {
    if (!skus || skus.length === 0) return;
    const maximum = _.meanBy(skus, (x) => x.unitCost);
    setMax(getNewMax(maximum, grandTotal));
  }, [grandTotal, skus]);

  useEffect(() => {
    if (max > grandTotal) return;
    setMax(getNewMax(max, grandTotal));
  }, [grandTotal, max]);

  const barStrongText = (value: number, isMoney?: boolean) => (
    <Typography
      variant="body1"
      sx={{
        fontWeight: '600',
        fontSize: '1.5rem',
      }}
    >
      {isMoney ? moneyFormatter.format(value) : formatter.format(value)}
    </Typography>
  );

  return (
    <>
      <Typography variant="h5" sx={(theme) => ({ color: theme.palette.common.masala })}>
        Estimated impact of
        <Box
          sx={{
            backgroundColor: colors.primary,
            color: '#fff',
            display: 'inline-block',
            fontWeight: '700',
            paddingY: '4px',
            paddingX: 1,
            marginX: 1,
          }}
        >
          {`${locale?.settings.symbol ?? ''}${formatter.format(grandTotal)}`}
        </Box>
        contribution{' '}
        <Tooltip
          title={
            <Typography variant="body2" color="inherit">
              Contribution Amount Breakdown
              <br />
              {`${100 - serviceFee}% Distributed to the Social Impact Partner`}
              <br />
              {`${serviceFee}% ScaleWith Transaction Services Fee`}
            </Typography>
          }
          style={{ maxWidth: '20px' }}
        >
          <HelpOutline fontSize="large" />
        </Tooltip>
      </Typography>
      <FlexBox flexDirection="column">
        <FlexBox sx={{ gap: 2, alignItems: 'center', textAlign: 'center' }}>
          <BorderLinearProgress
            variant="determinate"
            value={(grandTotal / max) * 100}
            sx={{ flex: 10 }}
          />
          {barStrongText(calculate)}
        </FlexBox>
        <FlexBox sx={{ gap: 1, alignItems: 'center' }}>
          <Box flex={1} />
          <Typography variant="body2">{calculation?.impactMeasurement ?? ''}</Typography>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export const AmountBar = React.memo(
  AmountBarComponent,
  (previous, next) =>
    _.isEqual(previous.skus, next.skus) &&
    previous.grandTotal === next.grandTotal &&
    previous.calculation === next.calculation,
);
