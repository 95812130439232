export const MESSAGES_SUPPLIER_BY_STATUS = {
  locked: {
    confirmMessage:
      'Are you sure you would like to lock this supplier? Users will still be able to log in to their accounts but will not be able to create new Social Impact Contributions.',
    titleDialog: 'Lock',
    confirmText: 'Lock',
    successMessage:
      'Users will still be able to log in to their accounts but will not be able to create new Social Impact Contributions.',
  },
  inactive: {
    confirmMessage: `Are you sure you would like to deactivate this supplier? This will remove user's ability to login to their account.`,
    titleDialog: 'Deactivate',
    confirmText: 'Deactivate',
    successMessage: 'Supplier deactivated',
  },
  active: {
    confirmMessage: `Are you sure you want to activate this supplier?`,
    titleDialog: 'Activate',
    confirmText: 'Activate',
    successMessage: 'Supplier activated',
  },
};
