/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Link, styled, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getAppAccountThunk } from '../../app';
import { FlexBox, CircleContainer, ThankYouTypography } from '../../shared/components';
import { AccountStatus, Dialogs, ThankYouMessage } from '../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { platform } from '../../shared/utils';
import { setDialog } from '../main-screen';
import { CommonBody } from './CommonBody';

const StepFlexBox = styled(Box)(() => ({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
}));

interface Props {
  message: ThankYouMessage;
}

export const ThankYou: FC<Props> = (props) => {
  const account = useAppSelector((state) => state.app.account);
  const userClaims = useAppSelector((state) => state.app.userClaims);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { message } = props;

  useEffect(() => {
    if (message !== ThankYouMessage.AccountError || !userClaims) return;

    dispatch(getAppAccountThunk(userClaims?.accountId ?? '-1'));
  }, [dispatch, message, userClaims]);

  useEffect(() => {
    if (message !== ThankYouMessage.AccountError || !account) return;
    switch (account?.status) {
      case AccountStatus.Active:
        navigate('/app/dashboard');
        break;
      case AccountStatus.New:
        navigate('/register-details');
        break;
      case AccountStatus.Pending:
        navigate('/register-details/financial-choice');
        break;
      default:
        break;
    }
  }, [account, account?.status, message, navigate]);

  const RegisterMessage = (
    <>
      <ThankYouTypography>
        <b>
          Thank you for registering with {platform()[0].toUpperCase() + platform().slice(1)}With.
        </b>{' '}
        You&apos;re only a few easy steps away from including social and sustainability impacts in
        your deals to help deepen customer relationships and win more deals.
      </ThankYouTypography>
      <Typography
        sx={(theme) => ({
          color: theme.palette.primary.main,
          fontSize: '1.5rem',
          fontWeight: '700',
          textAlign: 'center',
        })}
      >
        What&apos;s next?
      </Typography>
      <StepFlexBox>
        <CircleContainer>1</CircleContainer>
        <ThankYouTypography flex={1}>
          <b>Follow the instructions in the verification email</b> we just sent to the email address
          you registered with.
        </ThankYouTypography>
      </StepFlexBox>
      <StepFlexBox>
        <CircleContainer>2</CircleContainer>
        <ThankYouTypography flex={1}>
          <b>Continue your account setup</b> by sharing some basic information about your
          organization.
        </ThankYouTypography>
      </StepFlexBox>
      <StepFlexBox>
        <CircleContainer>3</CircleContainer>
        <ThankYouTypography flex={1}>
          <b>Finalize subscription selection</b> and provide payment information to begin.
        </ThankYouTypography>
      </StepFlexBox>
      <StepFlexBox>
        <CircleContainer>4</CircleContainer>
        <ThankYouTypography flex={1}>
          <b>Include Contributions in your deals</b> delighting customers and winning more business.
        </ThankYouTypography>
      </StepFlexBox>
    </>
  );

  const RegisterPendingFinance = (
    <>
      <ThankYouTypography fontWeight="700" marginTop={4}>
        We are so exited for your first Social Impact Contribution.
      </ThankYouTypography>
      <ThankYouTypography>
        As soon as we confirm your invite and finalize your account you will have full access to
        your {platform()[0].toUpperCase() + platform().slice(1)}with account. In the meantime, you
        can follow up with your finance contact and ensure they received your invite.
      </ThankYouTypography>
      <ThankYouTypography fontWeight="700">Thank you,</ThankYouTypography>
      <ThankYouTypography>
        The {platform()[0].toUpperCase() + platform().slice(1)}with Team
      </ThankYouTypography>
    </>
  );

  const AccountStatusInvalidMessage = (
    <>
      <ThankYouTypography sx={{ mb: 4 }}>
        Your {platform()[0].toUpperCase() + platform().slice(1)}with account is not currently
        active. Please &nbsp;
        <Link
          onClick={() =>
            dispatch(setDialog({ open: true, type: Dialogs.ContactUs, maxWidth: 'md' }))
          }
        >
          Contact Us
        </Link>
        &nbsp; if you have questions about the status of your account
      </ThankYouTypography>
      <RouterLink to="/logout">Logout</RouterLink>
    </>
  );

  const getMessage = (type: ThankYouMessage) => {
    switch (type) {
      case ThankYouMessage.Register:
        return RegisterMessage;
      case ThankYouMessage.RegisterPendingFinance:
        return RegisterPendingFinance;
      case ThankYouMessage.AccountError:
        return AccountStatusInvalidMessage;
      default:
        return null;
    }
  };

  return (
    <CommonBody hideLogo coloredLogo>
      <FlexBox
        sx={{
          marginTop: 4,
          flexDirection: 'column',
          flex: 1,
          color: 'black',
          gap: 4,
        }}
      >
        {getMessage(message)}
      </FlexBox>
    </CommonBody>
  );
};
