import { FC } from 'react';
import { useSubcriptionType } from '../shared/hooks';

export function ProAccountRequired({ children }: { children: JSX.Element }) {
  const { isAccountPro } = useSubcriptionType();
  return isAccountPro() ? children : null;
}

export const SelectAccountRequired: FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAccountSelect } = useSubcriptionType();

  return isAccountSelect() ? children : null;
};
