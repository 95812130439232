import React, { FC } from 'react';
import _ from 'lodash';
import { Typography, TypographyProps } from '@mui/material';
import { formatter, isNullOrWhitespace } from '../utils';
import { useAppSelector } from '../hooks';

interface MoneyBalanceProps extends TypographyProps {
  useColor?: boolean;
}

const getColor = (value: number, useColor?: boolean) => {
  if (!useColor) return 'inherit';
  return value > 0 ? 'green' : 'red';
};

export const MoneyBalanceWithLocaleSymbol: FC<MoneyBalanceProps> = (props) => {
  const appState = useAppSelector((x) => x.app);
  const { userData } = { ...appState };
  const { children, sx, useColor, ...rest } = props;

  const text = children?.toString();

  if (isNullOrWhitespace(text) || !_.isFinite(+text!)) return null;
  const number = +text!;

  return (
    <Typography {...rest} sx={{ ...sx, color: getColor(number, useColor) }}>
      {`${userData?.locale?.settings.symbol ?? ' '}${formatter.format(number)}`}
    </Typography>
  );
};
