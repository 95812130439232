import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { FlexBox, UserAvatar, CellPhoneInput } from '../../../../../shared/components';
import { UserRole } from '../../../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { formatString } from '../../../../../shared/utils';
import {
  setMyProfileDepartmentName,
  setMyProfileFirstName,
  setMyProfileLastName,
  setMyProfilePhoneNumber,
  setMyProfileTitle,
} from './myProfileSlice';
import fonts from '../../../../../assets/scss/font.module.scss';

const userRoleFormat = {
  [UserRole.SuperAdmin]: { order: 1, label: 'Scalewith' },
  [UserRole.OrgAdmin]: { order: 2, label: 'Org Admin' },
  [UserRole.UserAdmin]: { order: 3, label: 'User Admin' },
  [UserRole.Sales]: { order: 4, label: 'Sales' },
  [UserRole.Approver]: { order: 5, label: 'Approver' },
  [UserRole.Transactor]: { order: 6, label: 'Transactor' },
  [UserRole.Finance]: { order: 7, label: 'Finance' },
  [UserRole.Executive]: { order: 8, label: 'Executive' },
  [UserRole.GivewithCSM]: { order: 9, label: 'Scalewith CSM' },
  [UserRole.GivewithFinance]: { order: 10, label: 'Scalewith Finance' },
  [UserRole.GivewithOperations]: { order: 11, label: 'Scalewith Operations' },
  [UserRole.SIAdmin]: { order: 12, label: 'Scalewith SI Admin' },
};

const formatRolesList = (roles?: Array<string>) => {
  if (!roles || roles.length < 1) return 'None';
  const userRoleEnumList = Array.from(roles) as Array<UserRole>;
  return userRoleEnumList
    .sort((a, b) => userRoleFormat[a].order - userRoleFormat[b].order)
    .map((r) => userRoleFormat[r].label)
    .join(', ');
};

export function Profile() {
  const accountState = useAppSelector((state) => state.myProfile);
  const dispatch = useAppDispatch();
  const userClaims = useAppSelector((state) => state.app.userClaims);
  const { firstName, lastName, title, departmentName, phoneNumber, username, roles } =
    accountState.user;
  const commonTextFieldStyle = {
    '& label': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
    '& input': {
      fontSize: fonts.fontSize18,
    },
    '&::placeholder': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
  };

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
      <FlexBox sx={{ gap: 4 }} marginY={2}>
        <Box>
          <UserAvatar
            firstName={userClaims?.first_name}
            lastName={userClaims?.last_name}
            size="lg"
          />
        </Box>
        <FlexBox sx={{ flex: 1, flexDirection: 'column', justifyContent: 'space-around' }}>
          <Box>
            <Typography variant="h6">
              {formatString(userClaims?.first_name, '') + formatString(userClaims?.last_name)}
            </Typography>
          </Box>
          <Box>
            <Typography>{userClaims?.email}</Typography>
          </Box>
        </FlexBox>
      </FlexBox>
      <FlexBox sx={{ gap: 4 }}>
        <TextField
          label="First Name"
          fullWidth
          value={firstName}
          onChange={(e) => {
            dispatch(setMyProfileFirstName(e.target.value));
          }}
          inputProps={{ maxLength: 50 }}
          required
          sx={commonTextFieldStyle}
        />
        <TextField
          label="Last Name"
          fullWidth
          value={lastName}
          onChange={(e) => {
            dispatch(setMyProfileLastName(e.target.value));
          }}
          inputProps={{ maxLength: 50 }}
          required
          sx={commonTextFieldStyle}
        />
      </FlexBox>
      <FlexBox sx={{ gap: 4 }}>
        <TextField
          label="Department Name"
          fullWidth
          value={departmentName}
          onChange={(e) => {
            dispatch(setMyProfileDepartmentName(e.target.value));
          }}
          inputProps={{ maxLength: 50 }}
          sx={commonTextFieldStyle}
        />
        <TextField
          label="Title"
          fullWidth
          value={title}
          onChange={(e) => {
            dispatch(setMyProfileTitle(e.target.value));
          }}
          inputProps={{ maxLength: 50 }}
          sx={commonTextFieldStyle}
        />
      </FlexBox>
      <FlexBox sx={{ gap: 4 }}>
        <TextField
          label="Email Address"
          fullWidth
          value={username}
          disabled
          sx={commonTextFieldStyle}
        />
        <TextField
          label="Phone Number"
          name="phoneNumber"
          fullWidth
          value={phoneNumber}
          error={
            phoneNumber !== undefined &&
            phoneNumber!.length > 0 &&
            (phoneNumber[0] === '0' ||
              phoneNumber[0] === '1' ||
              (phoneNumber!.length < 10 && phoneNumber !== ''))
          }
          onChange={(e) => {
            dispatch(setMyProfilePhoneNumber(e.target.value));
          }}
          InputProps={{
            inputComponent: CellPhoneInput as any,
          }}
          sx={commonTextFieldStyle}
        />
      </FlexBox>
      <FlexBox sx={{ gap: 4 }}>
        <TextField
          label="Roles"
          fullWidth
          value={formatRolesList(roles)}
          disabled
          InputProps={{ disableUnderline: true }}
          sx={commonTextFieldStyle}
        />
      </FlexBox>
    </FlexBox>
  );
}
