/* eslint-disable no-console */
import {
  GridCallbackDetails,
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import _ from 'lodash';
import {
  COLUMN_ORDER,
  COLUMN_VISIBILITY,
  COLUMN_WIDTHS,
  DEFAULT_APPROVALS_VISIBILITY_MODEL,
  DEFAULT_FULFILLMENT_VISIBILITY_MODEL,
  DEFAULT_DASHBOARD_VISIBILITY_MODEL,
  DEFAULT_TRANSACTION_VISIBILITY_MODEL,
  DEFAULT_ERROR_LOGS_VISIBILITY_MODEL,
  DEFAULT_INSTANCE_VISIBILITY_MODEL,
  DEFAULT_ACCOUNT_VISIBILITY_MODEL,
  DEFAULT_PROCUREMENT_VISIBILITY_MODEL,
} from '../constants';
import { GridModel, GridRequest } from '../interfaces';
import { isNullOrWhitespace } from './helpers';

export const getOrderKey = (name: string) => `${name}${COLUMN_ORDER}`;
export const getWidthKey = (name: string) => `${name}${COLUMN_WIDTHS}`;
export const getVisibilityKey = (name: string) => `${name}${COLUMN_VISIBILITY}`;

export const getColumnOrderFromStorage = (name: string, defaultData: Array<GridColDef>) => {
  try {
    const widths = JSON.parse(localStorage.getItem(getWidthKey(name)) || '{}');
    if (!widths) return defaultData ?? [];

    const orders = JSON.parse(localStorage.getItem(getOrderKey(name)) || '[]');
    const columnWidths = _.cloneDeep(defaultData).map((col) =>
      widths[col.field] ? { width: widths[col.field], ...col, flex: undefined } : col,
    );
    return columnWidths.sort((a, b) => orders.indexOf(a.field) - orders.indexOf(b.field));
  } catch (error) {
    console.warn(`The following error occurred when parsing columnOrder or columnWidths: ${error}`);
    return defaultData ?? [];
  }
};

export const getColumnVisibilityFromStorage = (
  name: string,
  defaultData?: GridColumnVisibilityModel,
) => {
  try {
    const rawModel = localStorage.getItem(getVisibilityKey(name));
    if (!rawModel) return defaultData ?? {};
    return JSON.parse(rawModel);
  } catch (error) {
    console.warn(`The following error occurred when parsing ${getVisibilityKey(name)}: ${error}`);
    return defaultData ?? {};
  }
};

export const getGridRequest = (model: GridModel<any>): GridRequest => {
  const { pageSize, page, sort, defaultSort, filter } = { ...model };
  const { field, simpleValue, betweenValue } = { ...filter };
  return {
    count: pageSize,
    offset: page * pageSize,
    orderBy: sort?.field ?? defaultSort.field,
    descending: (sort?.direction ?? defaultSort.direction) === 'desc',
    ...(!isNullOrWhitespace(field) && !_.isNil(simpleValue) && { [field!]: simpleValue! }),
    ...(!isNullOrWhitespace(field) &&
      !_.isNil(betweenValue?.from) && { [`${field!}From`]: betweenValue?.from! }),
    ...(!isNullOrWhitespace(field) &&
      !_.isNil(betweenValue?.to) && { [`${field!}To`]: betweenValue?.to! }),
  };
};

export const setColumnVisibilityOnStorage = (name: string, data: GridColumnVisibilityModel) => {
  localStorage.setItem(getVisibilityKey(name), JSON.stringify(data));
};

export const setColumnReorderOnStorage = (
  name: string,
  params: GridColumnOrderChangeParams,
  details: GridCallbackDetails,
) => {
  try {
    // TODO remove deprecated api prop
    localStorage.setItem(
      getOrderKey(name),
      JSON.stringify(details.api.getAllColumns().map((ele: { field: string }) => ele.field)),
    );
  } catch (error) {
    console.warn(`The following error occurred when reordering columns: ${error}`);
    localStorage.setItem(
      getOrderKey(name),
      JSON.stringify({ [params.field]: { index: params.targetIndex } }),
    );
  }
};

export const setColumnResizeOnStorage = (name: string, params: GridColumnResizeParams) => {
  try {
    const detailCol = JSON.parse(localStorage.getItem(getWidthKey(name)) ?? '{}');
    detailCol[params.colDef.field] = params.width;
    localStorage.setItem(getWidthKey(name), JSON.stringify(detailCol));
  } catch (error) {
    console.warn(`The following error occurred when resizing columns: ${error}`);
    localStorage.setItem(
      getWidthKey(name),
      JSON.stringify({ [params.colDef.field]: params.width }),
    );
  }
};

export const resetColumnsOnStorage = (name: string) => {
  try {
    localStorage.removeItem(getOrderKey(name));
    localStorage.removeItem(getWidthKey(name));
    localStorage.removeItem(getVisibilityKey(name));
  } catch (error) {
    console.warn(`The following error occurred when resetting columns: ${error}`);
  }
};

export const getDefaultVisibility = (alternativeName: string) => {
  switch (alternativeName) {
    case 'dashboard':
      return DEFAULT_DASHBOARD_VISIBILITY_MODEL;
    case 'approvals':
      return DEFAULT_APPROVALS_VISIBILITY_MODEL;
    case 'fulfillment':
      return DEFAULT_FULFILLMENT_VISIBILITY_MODEL;
    case 'accountTransactions':
      return DEFAULT_TRANSACTION_VISIBILITY_MODEL;
    case 'errorLogs':
      return DEFAULT_ERROR_LOGS_VISIBILITY_MODEL;
    case 'instance':
      return DEFAULT_INSTANCE_VISIBILITY_MODEL;
    case 'account':
      return DEFAULT_ACCOUNT_VISIBILITY_MODEL;
    case 'switchAccount':
      return DEFAULT_ACCOUNT_VISIBILITY_MODEL;
    case 'procurement':
      return DEFAULT_PROCUREMENT_VISIBILITY_MODEL;
    default:
      return {};
  }
};
