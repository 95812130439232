import { Box, Theme } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import React, { FC } from 'react';
import { FundingStatus } from '../enums';
import fonts from '../../assets/scss/font.module.scss';

const getStatusStyle = (theme: Theme, status?: string) => {
  let backgroundColor = '';
  let fontWeight = '';
  let color = '';

  switch (status) {
    case FundingStatus.Approved: {
      backgroundColor = '#c3fae8';
      color = '#099268';
      break;
    }
    case FundingStatus.Submitted: {
      backgroundColor = '#ffec99';
      color = '#f08c00';
      break;
    }
    case FundingStatus.InProgress: {
      backgroundColor = '#d0ebff';
      color = '#1864ab';
      break;
    }
    case FundingStatus.Archived: {
      backgroundColor = '#DFE2E6';
      color = '#000000';
      break;
    }
    case FundingStatus.PendingApproval: {
      backgroundColor = theme.palette.error.dark;
      fontWeight = '500';
      color = 'white';
      break;
    }
    default: {
      backgroundColor = '';
      color = '';
      break;
    }
  }

  return {
    fontSize: fonts.fontsize16,
    borderRadius: 20,
    padding: '6px 0px 6px 15px',
    width: 200,
    textAlign: 'center',
    backgroundColor,
    fontWeight,
    color,
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };
};

interface Props {}

export const FundingStatusCell: FC<Props> = (props) => {
  const { children } = props;
  const status = children?.toString();

  return (
    <Box sx={(theme) => getStatusStyle(theme, status)}>
      {' '}
      {status === FundingStatus.InProgress && <HourglassEmptyIcon sx={{ marginRight: '0.5rem' }} />}
      {status === FundingStatus.Approved && <CheckCircleOutlineIcon sx={{ marginRight: '0.5rem' }} />}
      {status === FundingStatus.Submitted && <AccessTimeIcon sx={{ marginRight: '0.5rem' }} />}
      {status}
    </Box>
  );
};