import React from 'react';

type Currency = string | undefined;

interface Props {
  currency?: Currency;
}

const CurrencySymbol: React.FC<Props> = ({ currency }) => {
  const value = 100;
  let symbol = '';

  switch (currency) {
    case 'USD':
      symbol =
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
          .formatToParts(value)
          .find((part) => part.type === 'currency')?.value || '';
      break;
    case 'EUR':
      symbol =
        new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' })
          .formatToParts(value)
          .find((part) => part.type === 'currency')?.value || '';
      break;
    case 'GBP':
      symbol =
        new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
          .formatToParts(value)
          .find((part) => part.type === 'currency')?.value || '';
      break;
    default:
      symbol =
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
          .formatToParts(value)
          .find((part) => part.type === 'currency')?.value || '';
      break;
  }
  return <>{symbol.toString()}</>;
};

export default CurrencySymbol;
