import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from 'react';
import _, { parseInt } from 'lodash';
import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers';
import filterListIcon from '../../assets/images/filter_list_icon.svg';
import { FlexBox } from './StyledComponents';
import { GridColumns } from '../enums';
import { FilterValueType, GridFilter, CustomGridColDef } from '../interfaces';
import { MoneyInput } from './MoneyInput';
import { isNullOrWhitespace } from '../utils';
import { BETWEEN_FILTER_OPERATOR } from '../constants';
import { NumberInput } from './NumberInput';
import { MoneyInputWithLocaleSymbol } from './MoneyInputWithLocaleSymbol';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface Props {
  columns: Array<CustomGridColDef>;
  currentFilter: GridFilter | undefined;
  onChange: (value: GridFilter) => void;
}

export interface CommonGridFilterHandle {
  clearFilterSelections: () => void;
}

const CommonGridFilterForward: ForwardRefRenderFunction<CommonGridFilterHandle, Props> = (
  props,
  ref,
) => {
  const [selectedColumn, setSelectedColumn] = useState<CustomGridColDef | null | undefined>(null);
  const [simpleValue, setSimpleValue] = useState<FilterValueType>();
  const [from, setFrom] = useState<FilterValueType>();
  const [to, setTo] = useState<FilterValueType>();
  const { columns, currentFilter, onChange } = props;
  const { length } = columns;
  const isRouteDashboard = window.location.pathname.includes('/dashboard');

  const clearInputControllers = () => {
    if (!_.isNil(simpleValue)) setSimpleValue(undefined);
    if (!_.isNil(from)) setFrom(undefined);
    if (!_.isNil(to)) setTo(undefined);
  };

  const clearFilterController = () => {
    setSelectedColumn(null);
    clearInputControllers();
    if (!_.isEqual(currentFilter ?? {}, {})) onChange({});
  };

  useImperativeHandle(ref, () => ({
    clearFilterSelections() {
      clearFilterController();
    },
  }));

  useEffect(() => {
    if (!currentFilter || !currentFilter.field || !currentFilter.simpleValue) return;
    setSelectedColumn(columns.find((x) => x.field === currentFilter.field));
    setSimpleValue(currentFilter.simpleValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const model: GridFilter =
        selectedColumn?.field && simpleValue && !isNullOrWhitespace(simpleValue?.toString())
          ? {
              field: selectedColumn.field,
              simpleValue,
            }
          : {};
      if (!_.isEqual(currentFilter ?? {}, model)) onChange(model);
    }, 600);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [simpleValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!selectedColumn && !simpleValue) return;
      let model: GridFilter = {};
      if (
        selectedColumn?.field &&
        !isNullOrWhitespace(from?.toString()) &&
        !isNullOrWhitespace(to?.toString()) &&
        from?.toString() !== 'Invalid Date' &&
        to?.toString() !== 'Invalid Date'
      ) {
        const formatFrom =
          selectedColumn.type === GridColumns.DateTime && new Date(from!).toISOString();

        const formatTo =
          selectedColumn.type === GridColumns.DateTime && new Date(to!).toISOString();

        model = {
          field: selectedColumn.field,
          betweenValue: {
            from: formatFrom || from,
            to: formatTo || to,
          },
        };
      }
      if (!_.isEqual(currentFilter ?? {}, model)) onChange(model);
    }, 600);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);
  const renderSimpleInput = (column: CustomGridColDef | null | undefined) => {
    switch (column?.customColumnType ?? column?.type) {
      case GridColumns.Boolean:
        return (
          <FormControl>
            <InputLabel id="filter-select-label">Value</InputLabel>
            <Select
              labelId="filter-select-label"
              id="filter-select"
              variant="standard"
              value={(simpleValue as string) ?? ''}
              disabled={!selectedColumn}
              label="Value"
              sx={{ width: '250px' }}
              onChange={(event: SelectChangeEvent) => {
                setSimpleValue(event.target.value);
              }}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        );
      case GridColumns.SingleSelect:
        if (!column || !column.valueOptions || typeof column.valueOptions === 'function')
          return null;
        return (
          <FormControl>
            <InputLabel id="filter-select-label">Value</InputLabel>
            <Select
              labelId="filter-select-label"
              id="filter-select"
              variant="standard"
              value={(simpleValue as string) ?? ''}
              disabled={!selectedColumn}
              label="Value"
              sx={{ width: '250px' }}
              onChange={(event: SelectChangeEvent) => {
                setSimpleValue(event.target.value);
              }}
            >
              {column.valueOptions.map((option: any) => {
                const optionLabel = option?.label ?? option;
                const optionValue = option?.value ?? option;
                return (
                  <MenuItem value={optionValue} key={`menu-option-${optionValue}`}>
                    {optionLabel}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      case GridColumns.Money:
        return (
          <TextField
            label="Amount"
            value={simpleValue}
            onChange={(e) => {
              const { value } = e.target;
              setSimpleValue(isNullOrWhitespace(value) ? undefined : parseFloat(value));
            }}
            required
            InputProps={{ inputComponent: MoneyInput as any }}
          />
        );
      default: {
        const isExactComparison = column?.filterOperators
          ?.map(({ value }) => value)
          .includes('equals');
        const isStartsWith = column?.filterOperators
          ?.map(({ value }) => value)
          .includes('startsWith');
        let label = 'Contains';
        if (isStartsWith) {
          label = 'Starts With';
        }
        if (isExactComparison) {
          label = 'Value';
        }
        if (column?.type === 'number') {
          return (
            <TextField
              label={label}
              disabled={!selectedColumn}
              value={(simpleValue as string) ?? ''}
              onChange={(e) => {
                setSimpleValue(e.target.value);
              }}
              type="number"
            />
          );
        }
        return (
          <TextField
            label={label}
            disabled={!selectedColumn}
            value={(simpleValue as string) ?? ''}
            onChange={(e) => {
              setSimpleValue(e.target.value);
            }}
          />
        );
      }
    }
  };

  const renderBetweenInput = (column: CustomGridColDef | null | undefined) => {
    switch (column?.customColumnType ?? column?.type) {
      case GridColumns.Money:
        return (
          <>
            <TextField
              label="From"
              value={from}
              onChange={(e) => {
                const { value } = e.target;
                setFrom(isNullOrWhitespace(value) ? undefined : parseFloat(value));
              }}
              InputProps={{ inputComponent: MoneyInput as any }}
              sx={{ width: 200 }}
            />
            <TextField
              label="To"
              value={to}
              onChange={(e) => {
                const { value } = e.target;
                setTo(isNullOrWhitespace(value) ? undefined : parseFloat(value));
              }}
              InputProps={{ inputComponent: MoneyInput as any }}
              sx={{ width: 200 }}
            />
          </>
        );
      case GridColumns.MoneyWithLocaleSymbol:
        return (
          <>
            <TextField
              label="From"
              value={from}
              onChange={(e) => {
                const { value } = e.target;
                setFrom(isNullOrWhitespace(value) ? undefined : parseFloat(value));
              }}
              InputProps={{ inputComponent: MoneyInputWithLocaleSymbol as any }}
              sx={{ width: 200 }}
            />
            <TextField
              label="To"
              value={to}
              onChange={(e) => {
                const { value } = e.target;
                setTo(isNullOrWhitespace(value) ? undefined : parseFloat(value));
              }}
              InputProps={{ inputComponent: MoneyInputWithLocaleSymbol as any }}
              sx={{ width: 200 }}
            />
          </>
        );
      case GridColumns.Date:
        return (
          <>
            <DatePicker
              label="Date From"
              value={from ?? null}
              onChange={(newValue) => {
                setFrom(newValue);
              }}
              renderInput={(params) => <TextField {...params} sx={{ width: 200 }} />}
            />
            <DatePicker
              label="Date To"
              value={to ?? null}
              onChange={(newValue) => {
                setTo(newValue);
              }}
              renderInput={(params) => <TextField {...params} sx={{ width: 200 }} />}
            />
          </>
        );
      case GridColumns.DateTime:
        return (
          <>
            <DateTimePicker
              label="Date From"
              value={from ?? null}
              onChange={(newValue) => {
                setFrom(newValue);
              }}
              renderInput={(params) => <TextField {...params} sx={{ width: 300 }} />}
            />
            <DateTimePicker
              label="Date To"
              value={to ?? null}
              onChange={(newValue) => {
                setTo(newValue);
              }}
              renderInput={(params) => <TextField {...params} sx={{ width: 300 }} />}
            />
          </>
        );
      case GridColumns.Number:
        return (
          <>
            <TextField
              label="From"
              value={from}
              onChange={(e) => {
                const { value } = e.target;
                setFrom(isNullOrWhitespace(value) ? undefined : parseInt(value));
              }}
              InputProps={{ inputComponent: NumberInput as any }}
              sx={{ width: 200 }}
            />
            <TextField
              label="To"
              value={to}
              onChange={(e) => {
                const { value } = e.target;
                setTo(isNullOrWhitespace(value) ? undefined : parseInt(value));
              }}
              InputProps={{ inputComponent: NumberInput as any }}
              sx={{ width: 200 }}
            />
          </>
        );
      default:
        return null;
    }
  };

  if (!columns || length < 1) return null;

  return (
    <FlexBox
      sx={{
        gap: 0.5,
        alignItems: 'end',
        backgroundColor: `${isRouteDashboard && '#FFF'}`,
        zIndex: 9,
      }}
    >
     
      <img src={filterListIcon} alt="filterListIcon"  style={{width:"24px",height:"24px", color:"#4F4F4F" ,marginBottom:"10px",marginRight:"5px"}}/>
     
      <Autocomplete
  disablePortal
  id="combo-box-columns"
  sx={{ width: '250px' }}
  getOptionLabel={(option) => option.headerName ?? ''}
  options={columns.filter((x) => x?.filterable)}
  value={selectedColumn}
  onChange={(event: any, newValue: CustomGridColDef | null) => {
    clearInputControllers();
    setSelectedColumn(newValue);
  }}
  isOptionEqualToValue={(option, value) => option.field === value.field}
        renderInput={(params) => <TextField {...params}  label="Filter Column" sx={{
          "& .MuiFormLabel-root": {
     color:colors.textPrimary,
     fontSize: fonts.fontSize20,
     fontWeight: 600
   }          }}/>}
    />
      {selectedColumn &&
        (_.isEqual(selectedColumn.filterOperators, BETWEEN_FILTER_OPERATOR)
          ? renderBetweenInput(selectedColumn)
          : renderSimpleInput(selectedColumn))}
      {(selectedColumn || simpleValue) && (
        <IconButton
          size="small"
          sx={{ color:colors.textPrimary,fontSize: fonts.fontSize16, fontWeight: 800}}
          onClick={() => {
            clearFilterController();
          }}
        >
          <Close />
        </IconButton>
      )}
    </FlexBox>
  );
};

export const CommonGridFilter = React.memo(
  forwardRef(CommonGridFilterForward),
  (previous, next) =>
    _.isEqual(previous.currentFilter, next.currentFilter) &&
    _.isEqual(previous.currentFilter?.field, next.currentFilter?.field) &&
    _.isEqual(previous.currentFilter?.simpleValue, next.currentFilter?.simpleValue) &&
    _.isEqual(previous.columns, next.columns),
);
