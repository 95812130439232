import React, { useEffect } from 'react';
import { FormControlLabel, TextField, Typography, Button } from '@mui/material';
import { toast } from 'react-toastify';
import {
  FlexBox,
  FormDialog,
  AntSwitch,
  RecordDetails,
  TextFieldAndChips,
} from '../../shared/components';
import { Dialogs } from '../../shared/enums';
import { LocalesFormConfig } from '../../shared/interfaces';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import {
  clearLocaleSettingsState,
  getLocaleThunk,
  postLocaleThunk,
  putLocaleThunk,
  setLocaleSettingCurrency,
  setLocaleSettingCurrencySymbol,
  setLocaleSettingDescription,
  setLocaleSettingIsActive,
  setLocaleSettingLocalName,
  setLocaleSettingRegionLabel,
  setLocaleSettingsRegions,
  setLocaleSettingsTemporalRegionLabel,
} from './localeSettingsSlice';
import { isNullOrWhitespace } from '../../shared/utils';

export const LocaleSettings = () => {
  const state = useAppSelector((x) => x.localeSettings);
  const {
    values: {
      name,
      active,
      description,
      settings: { currency, symbol, regionLabel, regions },
      instanceCount,
    },
    editLocaleId,
    loadingLocale,
    temporalRegionLabel,
    isNew,
    success,
  } = state;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isNullOrWhitespace(editLocaleId)) return;
    dispatch(getLocaleThunk(editLocaleId));
  }, [dispatch, editLocaleId]);

  useEffect(
    () => () => {
      dispatch(clearLocaleSettingsState());
    },
    [dispatch],
  );

  const localesFormConfig: LocalesFormConfig = {
    localeName: { value: name || '', label: 'Locale name', required: true },
    isActive: {
      value: active,
      label: 'Active',
      disabled: instanceCount !== undefined && instanceCount !== 0,
    },
    currency: { value: currency, label: 'Currency', required: true },
    currencySymbol: { value: symbol, label: 'Currency symbol', required: true },
    description: { value: description || '', label: 'Description', required: true },
    regionLabel: { value: regionLabel, label: 'Title for locale regions', required: true },
    regions: { value: regions, label: regionLabel || 'Locale regions', required: true },
  };

  const onDeleteChip = (value: string) => {
    dispatch(
      setLocaleSettingsRegions(localesFormConfig.regions.value.filter((item) => item !== value)),
    );
  };

  const AddNewItem = (value: string) => {
    if (isNullOrWhitespace(value)) return;
    if (
      !localesFormConfig.regions.value.find(
        (item) => item.toLocaleLowerCase() === value.toLocaleLowerCase(),
      )
    ) {
      dispatch(setLocaleSettingsRegions([...localesFormConfig.regions.value, value]));
    } else {
      toast.warning(`'${value}' is duplicate`);
    }
  };

  const handleStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLocaleSettingIsActive(e.target.checked));
  };

  const isLocaleFormInvalid = () =>
    (localesFormConfig.localeName?.required &&
      isNullOrWhitespace(localesFormConfig.localeName?.value)) ||
    (localesFormConfig.currency?.required &&
      isNullOrWhitespace(localesFormConfig.currency?.value)) ||
    (localesFormConfig.currencySymbol?.required &&
      isNullOrWhitespace(localesFormConfig.currencySymbol?.value)) ||
    (localesFormConfig.description?.required &&
      isNullOrWhitespace(localesFormConfig.description?.value)) ||
    (localesFormConfig.currencySymbol?.required &&
      isNullOrWhitespace(localesFormConfig.currencySymbol?.value)) ||
    (localesFormConfig.regionLabel?.required &&
      isNullOrWhitespace(localesFormConfig.regionLabel?.value)) ||
    (localesFormConfig.regions?.required && localesFormConfig.regions?.value.length === 0);

  const handleSave = () => {
    if (isNew) {
      dispatch(postLocaleThunk());
    } else {
      dispatch(putLocaleThunk());
    }
  };

  const getTitle = () => (isNew ? 'Add Locale' : 'Edit Locale');
  return (
    <FormDialog
      title={getTitle()}
      loading={loadingLocale}
      type={Dialogs.LocaleSettings}
      closeFlag={success}
      Buttons={
        <Button
          onClick={handleSave}
          disabled={isLocaleFormInvalid()}
          variant="contained"
          size="large"
        >
          Save
        </Button>
      }
    >
      <FlexBox sx={{ gap: 6 }}>
        <FlexBox
          sx={{
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <FlexBox sx={{ flexDirection: 'row', gap: 4 }}>
            <TextField
              label={localesFormConfig.localeName?.label}
              value={localesFormConfig.localeName?.value}
              required={localesFormConfig.localeName?.required}
              onChange={(e) => dispatch(setLocaleSettingLocalName(e.target.value))}
            />
            <FlexBox sx={{ flexDirection: 'column', gap: 2 }}>
              <Typography sx={{ color: '#424242' }}>STATUS</Typography>
              <FormControlLabel
                sx={{ paddingLeft: 1, gap: 1 }}
                control={
                  <AntSwitch
                    onChange={handleStatus}
                    checked={localesFormConfig.isActive?.value}
                    disabled={localesFormConfig.isActive?.disabled}
                    name="isActive"
                  />
                }
                label={localesFormConfig.isActive?.value ? 'Active' : 'Inactive'}
              />
            </FlexBox>
          </FlexBox>
          <FlexBox sx={{ flexDirection: 'row', gap: 4 }}>
            <TextField
              label={localesFormConfig.currency?.label}
              value={localesFormConfig.currency?.value}
              required={localesFormConfig.currency?.required}
              onChange={(e) => dispatch(setLocaleSettingCurrency(e.target.value))}
            />
            <TextField
              label={localesFormConfig.currencySymbol?.label}
              value={localesFormConfig.currencySymbol?.value}
              required={localesFormConfig.currencySymbol?.required}
              onChange={(e) => dispatch(setLocaleSettingCurrencySymbol(e.target.value))}
            />
          </FlexBox>
          <TextField
            label={localesFormConfig.description?.label}
            value={localesFormConfig.description?.value}
            required={localesFormConfig.description?.required}
            fullWidth
            onChange={(e) => dispatch(setLocaleSettingDescription(e.target.value))}
          />
          <TextField
            label={localesFormConfig.regionLabel?.label}
            value={temporalRegionLabel}
            required={localesFormConfig.regionLabel?.required}
            onChange={(e) => dispatch(setLocaleSettingsTemporalRegionLabel(e.target.value))}
            onBlur={() => dispatch(setLocaleSettingRegionLabel(temporalRegionLabel))}
            fullWidth
          />
          <TextFieldAndChips
            label={localesFormConfig.regions?.label}
            onDelete={onDeleteChip}
            addChip={AddNewItem}
            data={localesFormConfig.regions?.value}
          />
        </FlexBox>
        {!isNew && (
          <FlexBox sx={{ flexDirection: 'column', minWidth: '35%' }}>
            <RecordDetails value={state.values} />
          </FlexBox>
        )}
      </FlexBox>
    </FormDialog>
  );
};
