import React, { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Checkbox,
  Box,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import '../sio/formFilling.scss';
import './programFundingForm.scss';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { fetchFundingbyUser } from './fundingSlice';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { getVocab } from '../../api/portal/programRequest';
import { uploadFile } from '../../api/portal/fundingRequest';
import { Vocabulary } from './fundingInterface';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

const lifetimeOutputForm = [{ quantity: 0, description: '' }];
type ChildComponentProps = {
  onDataSubmit: (data: any) => void;
};

const theme = createTheme({
  palette: {
    primary: {
      light: colors.primary,
      main: colors.primary,
    },
  },
});

const ResearchAndEvaluation: React.FC<ChildComponentProps> = ({ onDataSubmit }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const fundingState = useAppSelector((state) => state.funding);
  const { funding } = fundingState;
  const [evidenceOptionalCheck, setEvidenceOptionalCheck] = useState(false);
  const [studyOptionalCheck, setStudyOptionalCheck] = useState(false);
  const [dataOptionalCheck, setDataOptionalCheck] = useState(false);

  const initialFormValue = {
    researchFile: funding?.researchAndEvaluation?.researchFile,
    evidenceDescription: funding?.researchAndEvaluation?.evidenceDescription?.selected ?? [],
    evidenceOptional: funding?.researchAndEvaluation?.evidenceDescription?.optional ?? '',
    studyDescription: funding?.researchAndEvaluation?.studyDescription?.selected ?? [],
    studyOptional: funding?.researchAndEvaluation?.studyDescription?.optional ?? '',
    dataDescription: funding?.researchAndEvaluation?.dataDescription?.selected ?? [],
    dataOptional: funding?.researchAndEvaluation?.dataDescription?.optional ?? '',
    outcomeDescription: funding?.researchAndEvaluation?.outcomeDescription ?? [],
    strength: funding?.researchAndEvaluation?.strength,
    environmentalOutputValues: lifetimeOutputForm,
    environmentalOutputs: funding?.researchAndEvaluation?.environmentalOutputs?.selected ?? [],
    researchApproach: funding?.researchAndEvaluation?.researchApproach ?? [{ _id: '' }],
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  // const [pageNumber, setPageNumber] = useState(1)
  // const [files, setFiles] = useState<File>()
  const [vocab, setVocab] = useState<Vocabulary>();
  useEffect(() => {
    getVocab().then((res) => {
      if (res.status === 200) {
        setVocab(res.data);
      }
    });
    if (id && funding) {
      dispatch(fetchFundingbyUser(id)).then((fundingData: any) => {
        setFormValues({
          researchFile: fundingData.payload?.researchAndEvaluation?.researchFile,
          evidenceDescription:
            fundingData.payload?.researchAndEvaluation?.evidenceDescription?.selected ?? [],
          evidenceOptional:
            fundingData.payload?.researchAndEvaluation?.evidenceDescription?.optional ?? '',
          studyDescription:
            fundingData.payload?.researchAndEvaluation?.studyDescription?.selected ?? [],
          studyOptional:
            fundingData.payload?.researchAndEvaluation?.studyDescription?.optional ?? '',
          dataDescription:
            fundingData.payload?.researchAndEvaluation?.dataDescription?.selected ?? [],
          dataOptional: fundingData.payload?.researchAndEvaluation?.dataDescription?.optional ?? '',
          outcomeDescription: fundingData.payload?.researchAndEvaluation?.outcomeDescription ?? [],
          strength: fundingData.payload?.researchAndEvaluation?.strength,
          environmentalOutputValues:
            fundingData.payload?.researchAndEvaluation?.environmentalOutputValues ?? [],
          researchApproach: fundingData.payload?.researchAndEvaluation?.researchApproach ?? [],
          environmentalOutputs:
            fundingData.payload?.researchAndEvaluation?.environmentalOutputs?.selected ?? [],
        });
        // savePageNumber()
      });
    }
  }, [id]);

  // const updateProgramDetails = () => {
  //     const payload = {
  //         researchAndEvaluation: {
  //             researchFile: formValues.researchFile,
  //             evidenceDescription: {
  //                 selected: formValues.evidenceDescription
  //             },
  //             studyDescription: {
  //                 selected: formValues.studyDescription
  //             },
  //             dataDescription: {
  //                 selected: formValues.dataDescription
  //             },
  //             outcomeDescription: typeof(formValues.outcomeDescription)==='string' ? [formValues.outcomeDescription] : formValues.outcomeDescription,
  //             strength: formValues.strength,
  //             environmentalOutputValues: formValues.environmentalOutputValues,
  //             environmentalOutputs:{
  //                 selected: formValues.environmentalOutputs
  //             } ,
  //             researchApproach: formValues.researchApproach
  //         },
  //     }
  //     const fundingPayload = payload
  //     if (id) {
  //         return dispatch(updateFundingbyUserThunk({ funding: fundingPayload, id }));
  //     }
  //     return true
  // }

  // const uploadFileData = async () => {
  //     const formData = new FormData();
  //     console.log(files);
  //     if (!files) return
  //     formData.append('file', files)
  //     formData.append('path', 'researchAndEvaluation.researchFile')
  //     if (id) {
  //         const response = await uploadFile(id, formData)
  //         if (response.data) {
  //             setFormValues({
  //                 ...formValues,
  //                 researchFile: response.data.researchAndEvaluation.researchFile.file.name
  //             });
  //             toast.success("Upload Successfull")
  //         } else {
  //             toast.error("Something went wrong")
  //         }
  //     }
  // }

  const getFieldValue = (indexNumber: number, field: string) => {
    let value;
    formValues?.environmentalOutputValues?.map((item, index) => {
      if (index === indexNumber) {
        if (field === 'quantity') {
          value = item?.quantity;
        }
        if (field === 'description') {
          value = item.description;
        }
      }
      return 1;
    });
    return value;
  };

  const uploadFileData = (event: any) => {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size < 910180) {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('path', 'researchAndEvaluation.researchFile');
        uploadFile(id!, formData).then((res) => {
          if (res.status === 200) {
            setFormValues({
              ...formValues,
              researchFile: res.data.researchAndEvaluation.researchFile.file.name,
            });
          }
        });
      } else {
        toast.error('File size should not be more than 900 Kb');
      }
    }
  };

  const handleLifetimeOutputChange = (
    field: string,
    index: number,
    value: any,
    lifetimeOutput: any,
  ) => {
    const newLifetimeOutputs = [...formValues.environmentalOutputValues!];
    const label: any = vocab?.sccConversion?.filter((item: any) => item?._id === lifetimeOutput);
    newLifetimeOutputs[index] = {
      ...newLifetimeOutputs[index],
      [field]: value,
      description: label && label[0]?.label,
    };
    if (Number(value) >= 0) {
      setFormValues((prevValues) => ({
        ...prevValues,
        environmentalOutputValues: newLifetimeOutputs,
      }));
    }
  };

  // const handleSaveExit = async () => {
  //     const response = await updateProgramDetails()
  //     if (response) {
  //         // toast.success('Successfully Submitted Form to ScaleWith');
  //         navigate(`/program-form/${id}`)
  //     } else {
  //         toast.error('An error occurred while logging in. Please try again.');
  //     }
  //     return null
  // }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'evidenceOptionalValue') {
      setFormValues({
        ...formValues,
        evidenceOptional: value,
      });
    }
    if (name === 'studyOptionalValue') {
      setFormValues({
        ...formValues,
        studyOptional: value,
      });
    }
    if (name === 'dataOptionalValue') {
      setFormValues({
        ...formValues,
        dataOptional: value,
      });
    }
    if (name === 'evidenceDescription') {
      setEvidenceOptionalCheck(false);
      let newvalue = formValues.evidenceDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        evidenceDescription: newvalue,
        evidenceOptional: '',
      });
    }
    if (name === 'studyDescription') {
      setStudyOptionalCheck(false);
      let newvalue = formValues.studyDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        studyDescription: newvalue,
        studyOptional: '',
      });
    }
    if (name === 'dataDescription') {
      setDataOptionalCheck(false);
      let newvalue = formValues.dataDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        dataDescription: newvalue,
        dataOptional: '',
      });
    }
    if (name === 'outcomeDescription') {
      let newvalue = formValues.outcomeDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        outcomeDescription: newvalue,
      });
    }
    if (name === 'researchApproach') {
      // let newvalue = formValues.researchApproach;
      // if (newvalue.includes(value)) {
      //   newvalue = newvalue.filter((item) => value !== item);
      // } else if (formValues.researchApproach.length < 3) {
      //   newvalue = [...newvalue, value];
      // }
      // setFormValues({
      //   ...formValues,
      //   researchApproach: newvalue,
      // });
      if (event.target.checked) {
        if (formValues?.researchApproach?.length < 3) {
          setFormValues({
            ...formValues,
            researchApproach: [...formValues.researchApproach, { _id: value }],
          });
        } else {
          toast.warning('You can select upto 3 values');
        }
      } else {
        const filteredList = formValues?.researchApproach?.filter(
          (list: any) => list._id !== value,
        );
        setFormValues({
          ...formValues,
          researchApproach: filteredList,
        });
      }
    }
    if (name === 'environmentalOutputs') {
      let newvalue = formValues.environmentalOutputs;
      let newOutputValues = [...formValues.environmentalOutputValues];
      const label: any = vocab?.sccConversion?.filter((voc: any) => voc?._id === value);

      const index = formValues?.environmentalOutputs?.indexOf(value);
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
        newOutputValues?.splice(index, 1);
      } else {
        newvalue = [...newvalue, value];
        newOutputValues = [...newOutputValues, { quantity: 0, description: label[0]?.label }];
      }

      setFormValues({
        ...formValues,
        environmentalOutputs: newvalue,
        environmentalOutputValues: newOutputValues,
      });
    }
  };

  useEffect(() => {
    if (formValues && formValues?.environmentalOutputs?.length === 0) {
      setFormValues({
        ...formValues,
        environmentalOutputValues: [],
      });
    }
  }, [formValues?.environmentalOutputs]);
  useEffect(() => {
    if (formValues?.evidenceOptional?.length > 0) {
      setEvidenceOptionalCheck(true);
    } else {
      setEvidenceOptionalCheck(false);
    }
  }, [formValues?.evidenceOptional]);
  useEffect(() => {
    if (formValues?.studyOptional?.length > 0) {
      setStudyOptionalCheck(true);
    } else {
      setStudyOptionalCheck(false);
    }
  }, [formValues?.studyOptional]);
  useEffect(() => {
    if (formValues?.dataOptional?.length > 0) {
      setDataOptionalCheck(true);
    } else {
      setDataOptionalCheck(false);
    }
  }, [formValues?.dataOptional]);

  // const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
  //     const fileList = e.target.files;
  //     if (!fileList) return;
  //     setFiles(fileList[0]);
  //     if(files){
  //         uploadFileData();
  //     }
  // }

  useEffect(() => {
    onDataSubmit(formValues);
  }, [formValues]);

  return (
    <div>
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{ display: 'flex', gap: '2rem', marginTop: '2rem' }}
        className="formContainer"
      >
        <Grid container>
          <Grid
            item
            xs={14}
            sx={{
              background: colors.backgroundPrimary,
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
              marginLeft: '-10px',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: fonts.fontSize24,
                fontWeight: fonts.fontWeight700,
                color: colors.textPrimary,
              }}
            >
              Research And Evaluation
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >{`${funding?.progress?.researchAndEvaluation?.complete} of ${funding?.progress?.researchAndEvaluation?.total} Completed`}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            {/* <Typography sx={{ mb: 5 }} variant="h3">
              Let&apos;s get started!
            </Typography> */}
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 1 of 9
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Please upload the research or evaluation that informs your program design.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <input
              type="file"
              id="upload"
              height="40px"
              onChange={uploadFileData}
              style={{ border: '1px solid lightgrey', padding: '1em', borderRadius: '5px' }}
            />
            {formValues?.researchFile?.name !== '' && (
              <p
                style={{
                  fontSize: fonts.fontSize16,
                  fontWeight: fonts.fontWeight500,
                  color: colors.textSecondary,
                }}
              >
                File Name: {formValues?.researchFile?.name}
              </p>
            )}
            {/* <TextField
              name="budgetFile"
              placeholder="Select File"
              fullWidth
              type="file"
              //   value={formValues?.researchFile ? formValues?.researchFile?.name : ''}
              onChange={uploadFileData}
              margin="normal"
              variant="outlined"
              InputProps={{
                sx: {
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '1rem',
                  },
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '50px',
                },
              }}
            /> */}
          </Grid>
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 2 of 9
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              How would you describe the evidence or data?
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Please note, this information helps us derive additional value your organization is
              creating through the use of methods that have been researched and evaluated. This
              should be program-specific, not at an organizational level.
            </Typography>
          </Grid>

          {
            /* eslint no-underscore-dangle: 0 */
            vocab?.studyEvidenceType.map((item: any) => (
              <Grid key={item._id} item>
                <Box
                  className={formValues.evidenceDescription.includes(item._id) ? 'active' : ''}
                  sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                >
                  <Typography
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight400,
                      color: colors.textSecondary,
                    }}
                    display="inline"
                  >
                    {item.label}
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Checkbox
                      name="evidenceDescription"
                      checked={formValues.evidenceDescription.includes(item._id)}
                      value={item._id}
                      onChange={handleChangeSelect}
                      color="primary"
                    />
                  </ThemeProvider>
                </Box>
              </Grid>
            ))
          }
          <Grid style={{ padding: '0.5em' }}>
            <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
              <Typography
                sx={{
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fontWeight400,
                  color: colors.textSecondary,
                }}
                display="inline"
              >
                Others
              </Typography>
              <ThemeProvider theme={theme}>
                <Checkbox
                  name="evidenceOthers"
                  checked={evidenceOptionalCheck}
                  onChange={() => {
                    setEvidenceOptionalCheck(!evidenceOptionalCheck);
                    setFormValues({
                      ...formValues,
                      evidenceDescription: [],
                    });
                  }}
                />
              </ThemeProvider>
            </Box>
          </Grid>
          {evidenceOptionalCheck && (
            <Grid style={{ padding: '0.5em' }}>
              <Typography
                sx={{
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fontWeight400,
                  color: colors.textSecondary,
                }}
                display="inline"
              >
                Please describe others
              </Typography>
              <TextField
                name="evidenceOptionalValue"
                type="text"
                variant="outlined"
                className="inputTextFiled"
                value={formValues.evidenceOptional}
                onChange={handleChangeSelect}
                fullWidth
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '1rem',
                    },
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight400,
                    borderRadius: '5px',
                  },
                  '& .MuiInputBase-root': {
                    height: '40px',
                  },
                  height: '40px',
                  '& .MuiSelect-select': {
                    height: '80%',
                  },
                }}
              />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 3 of 9{' '}
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              How would you describe the study design?
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Please select all that apply.
            </Typography>
          </Grid>
          {
            /* eslint no-underscore-dangle: 0 */
            vocab?.studyDesignType.map((item: any) => (
              <Grid key={item._id} item>
                <Box
                  className={formValues.studyDescription.includes(item._id) ? 'active' : ''}
                  sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                >
                  <Typography
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight400,
                      color: colors.textSecondary,
                    }}
                    display="inline"
                  >
                    {item.label}
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Checkbox
                      name="studyDescription"
                      checked={formValues.studyDescription.includes(item._id)}
                      value={item._id}
                      onChange={handleChangeSelect}
                    />
                  </ThemeProvider>
                </Box>
              </Grid>
            ))
          }
          <Grid style={{ padding: '0.5em' }}>
            <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
              <Typography
                sx={{
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fontWeight400,
                  color: colors.textSecondary,
                }}
                display="inline"
              >
                Others
              </Typography>
              <ThemeProvider theme={theme}>
                <Checkbox
                  name="evidenceOthers"
                  checked={studyOptionalCheck}
                  onChange={() => {
                    setStudyOptionalCheck(!studyOptionalCheck);
                    setFormValues({
                      ...formValues,
                      studyDescription: [],
                    });
                  }}
                />
              </ThemeProvider>
            </Box>
          </Grid>
          {studyOptionalCheck && (
            <Grid style={{ padding: '0.5em' }}>
              <Typography
                sx={{
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fontWeight400,
                  color: colors.textSecondary,
                }}
                display="inline"
              >
                Please describe others
              </Typography>
              <TextField
                name="studyOptionalValue"
                type="text"
                variant="outlined"
                className="inputTextFiled"
                value={formValues.studyOptional}
                onChange={handleChangeSelect}
                fullWidth
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '1rem',
                    },
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight400,
                    borderRadius: '5px',
                  },
                  '& .MuiInputBase-root': {
                    height: '40px',
                  },
                  height: '40px',
                  '& .MuiSelect-select': {
                    height: '80%',
                  },
                }}
              />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 4 of 9{' '}
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              How would you describe the data in the study?
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Please select all that apply.
            </Typography>
          </Grid>
          {
            /* eslint no-underscore-dangle: 0 */
            vocab?.researchDataType.map((item: any) => (
              <Grid key={item._id} item>
                <Box
                  className={formValues.dataDescription.includes(item._id) ? 'active' : ''}
                  sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                >
                  <Typography
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight400,
                      color: colors.textSecondary,
                    }}
                    display="inline"
                  >
                    {item.label}
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Checkbox
                      name="dataDescription"
                      checked={formValues.dataDescription.includes(item._id)}
                      value={item._id}
                      onChange={handleChangeSelect}
                    />
                  </ThemeProvider>
                </Box>
              </Grid>
            ))
          }
          <Grid style={{ padding: '0.5em' }}>
            <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
              <Typography
                sx={{
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fontWeight400,
                  color: colors.textSecondary,
                }}
                display="inline"
              >
                Others
              </Typography>
              <Checkbox
                name="evidenceOthers"
                checked={dataOptionalCheck}
                onChange={() => {
                  setDataOptionalCheck(!dataOptionalCheck);
                  setFormValues({
                    ...formValues,
                    dataDescription: [],
                  });
                }}
              />
            </Box>
          </Grid>
          {dataOptionalCheck && (
            <Grid style={{ padding: '0.5em' }}>
              <Typography variant="body1" display="inline">
                Please describe others
              </Typography>
              <TextField
                name="dataOptionalValue"
                type="text"
                variant="outlined"
                className="inputTextFiled"
                value={formValues.dataOptional}
                onChange={handleChangeSelect}
                fullWidth
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '1rem',
                    },
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight400,
                    borderRadius: '5px',
                  },
                  '& .MuiInputBase-root': {
                    height: '40px',
                  },
                  height: '40px',
                  '& .MuiSelect-select': {
                    height: '80%',
                  },
                }}
              />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 5 of 9{' '}
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              How would you describe the correlation of your program to your outcomes based on the
              study?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <ThemeProvider theme={theme}>
                <RadioGroup
                  sx={{ display: 'inline-block' }}
                  name="outcomeDescription"
                  value={formValues.outcomeDescription}
                  onChange={handleChange}
                >
                  {vocab?.effect.map((item: any) => (
                    <FormControlLabel
                      sx={{
                        p: 2,
                        mx: 2,
                        border: '1px solid #E0E0E0',
                        borderRadius: '20px',
                        fontSize: fonts.fontSize14,
                        fontWeight: fonts.fontWeight400,
                        color: colors.textSecondary,
                      }}
                      labelPlacement="start"
                      key={item._id}
                      value={item._id}
                      control={<Radio />}
                      label={item.label}
                    />
                  ))}
                </RadioGroup>
              </ThemeProvider>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 6 of 9
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Which strategies and approaches align with the research or evaluation this program
              design is based on?
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Please select at least one, and up to three.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {vocab?.causes.map((item: any) => (
              <Grid key={item._id} sx={{ my: 2 }} item xs={12}>
                <Box
                  className={formValues.evidenceDescription.includes(item._id) ? 'active' : ''}
                  sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                >
                  <Typography
                    sx={{
                      my: 2,
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight500,
                      color: colors.textSecondary,
                    }}
                    display="inline"
                  >
                    {item.label}
                  </Typography>
                  <br />
                  {vocab?.approach.map((subItem: any) => {
                    if (subItem.causeId === item._id) {
                      const filteredArray = formValues?.researchApproach?.map(
                        (obj: any) => obj._id,
                      );
                      return (
                        <Box
                          key={subItem._id}
                          className={filteredArray?.includes(subItem._id) ? 'active' : ''}
                          sx={{
                            border: '1px solid #E0E0E0',
                            display: 'inline-block',
                            width: 'fit-content',
                            m: 2,
                            borderRadius: '20px',
                            p: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: fonts.fontSize14,
                              fontWeight: fonts.fontWeight400,
                              color: colors.textSecondary,
                            }}
                            display="inline"
                          >
                            {subItem.label}
                          </Typography>
                          <ThemeProvider theme={theme}>
                            <Checkbox
                              name="researchApproach"
                              checked={filteredArray?.includes(subItem._id)}
                              value={subItem._id}
                              onChange={handleChangeSelect}
                            />
                          </ThemeProvider>
                        </Box>
                      );
                    }
                    return true;
                  })}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 7 of 9{' '}
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              What environmental outputs does this program create?
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              The question was added because you selected a environmental strategies and approaches.
              Please select all that apply.
            </Typography>
          </Grid>
          {
            /* eslint no-underscore-dangle: 0 */
            vocab?.sccConversion.map((item: any) => (
              <Grid key={item._id} item>
                <Box
                  className={formValues.environmentalOutputs.includes(item._id) ? 'active' : ''}
                  sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                >
                  <Typography
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight400,
                      color: colors.textSecondary,
                    }}
                    display="inline"
                  >
                    {item.label}
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Checkbox
                      name="environmentalOutputs"
                      checked={formValues.environmentalOutputs.includes(item._id)}
                      value={item._id}
                      onChange={handleChangeSelect}
                    />
                  </ThemeProvider>
                </Box>
              </Grid>
            ))
          }
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 8 of 9
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Please add numeric values for the environmental outputs this program creates.
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Outputs are purely numeric values that are proportionate to your budget. If your
              program was completely funded, these would be the metrics you report.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {formValues?.environmentalOutputs?.map((lifetimeOutput: any, index) => {
              const label: any = vocab?.sccConversion?.filter(
                (item: any) => item?._id === lifetimeOutput,
              );

              return (
                <Grid container spacing={4} key={lifetimeOutput.key}>
                  <Grid item xs={12} sm={3} mb={2}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize14,
                        fontWeight: fonts.fontWeight400,
                        color: colors.textSecondary,
                      }}
                    >
                      Quantity{' '}
                    </Typography>
                    <TextField
                      name="quantity"
                      type="number"
                      value={getFieldValue(index, 'quantity')}
                      onChange={(event) => {
                        handleLifetimeOutputChange(
                          'quantity',
                          index,
                          event.target.value,
                          lifetimeOutput,
                        );
                      }}
                      variant="outlined"
                      className="inputTextFiled"
                      fullWidth
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                          fontSize: fonts.fontSize14,
                          fontWeight: fonts.fontWeight400,
                          borderRadius: '5px',
                        },
                        '& .MuiInputBase-root': {
                          height: '40px',
                        },
                        height: '40px',
                        '& .MuiSelect-select': {
                          height: '80%',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} mb={2}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize14,
                        fontWeight: fonts.fontWeight400,
                        color: colors.textSecondary,
                      }}
                    >
                      Output{' '}
                    </Typography>
                    <TextField
                      name="description"
                      value={getFieldValue(index, 'description')}
                      type="text"
                      onChange={() => {
                        handleLifetimeOutputChange(
                          'description',
                          index,
                          label && label[0]?.label!,
                          lifetimeOutput,
                        );
                      }}
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                          fontSize: fonts.fontSize14,
                          fontWeight: fonts.fontWeight400,
                          borderRadius: '5px',
                        },
                        '& .MuiInputBase-root': {
                          height: '40px',
                        },
                        height: '40px',
                        '& .MuiSelect-select': {
                          height: '80%',
                        },
                      }}
                      variant="outlined"
                      className="inputTextFiled"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid container spacing={1} className="DetailsContainer">
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 9 of 9
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Please select the strength of your program.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <ThemeProvider theme={theme}>
                <RadioGroup
                  sx={{
                    display: 'inline-block',
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight400,
                    color: colors.textSecondary,
                  }}
                  name="strength"
                  value={formValues.strength}
                  onChange={handleChange}
                >
                  {vocab?.strength.map((item: any) => (
                    <FormControlLabel
                      sx={{
                        p: 2,
                        mx: 2,
                        border: '1px solid #E0E0E0',
                        borderRadius: '20px',
                      }}
                      labelPlacement="start"
                      key={item._id}
                      value={item._id}
                      control={<Radio />}
                      label={item.label}
                    />
                  ))}
                </RadioGroup>
              </ThemeProvider>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResearchAndEvaluation;
