import { Button, Typography } from '@mui/material';
import React from 'react';
import { FormDialog } from '../../../../../shared/components';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { getAppAccountThunk } from '../../../../../app';
import { clearDialog } from '../../../mainScreenSlice';
import { Dialogs } from '../../../../../shared/enums';

export function RevertAccount() {
  const userClaims = useAppSelector((state) => state.app.userClaims);
  const dispatch = useAppDispatch();

  return (
    <FormDialog
      title="Revert Account"
      type={Dialogs.RevertAccount}
      Buttons={
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            dispatch(getAppAccountThunk(userClaims?.accountId ?? '-1'));
            dispatch(clearDialog(Dialogs.RevertAccount));
          }}
        >
          Confirm
        </Button>
      }
    >
      <Typography variant="body1" sx={{ mt: 2 }}>
        Are you sure you want to revert the active account?
      </Typography>
    </FormDialog>
  );
}
