import React, { useState, FC } from 'react';
import {
  Collapse,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import { DeleteOutlineOutlined, ExpandMore } from '@mui/icons-material';
import { FlexBox } from '../../../../shared/components';
import { InstanceProgramSettingsCauseArea } from '../../../../shared/interfaces';
import { CommonPaper } from '../../../../shared/components/StyledComponents';
import fonts from '../../../../assets/scss/font.module.scss';

interface Props {
  onDeleteCauseArea: (item: string) => void;
  data: InstanceProgramSettingsCauseArea;
  causeAreaLength: number;
  handleDrag: (e: object) => void;
  handleDrop: (e: object) => void;
}

export const CauseAreaCard: FC<Props> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const { data, onDeleteCauseArea, causeAreaLength, handleDrag, handleDrop } = props;

  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <FlexBox sx={{ alignItems: 'initial', gap: 3, flex: 1 }}>
      <Typography
        variant="h3"
        sx={{ fontWeight: 'bold', flex: `${causeAreaLength >= 10 ? 0.5 : 0.3}` }}
      >
        {`${data.displayOrder}.`}
      </Typography>
      <Box
        sx={{
          flex: `${causeAreaLength >= 10 ? 9.5 : 9.7}`,
          alignItems: 'initial',
          cursor: 'pointer',
        }}
        id={data.causeArea?.id}
        draggable
        onDragOver={(ev) => ev.preventDefault()}
        onDragStart={handleDrag}
        onDrop={handleDrop}
      >
        <FlexBox
          sx={{
            flexDirection: 'column',
            flex: 0.7,
            bgcolor: 'white',
            padding: 2,
            borderRadius: 2,
            gap: 2,
          }}
        >
          <FlexBox
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <FlexBox sx={{ flexDirection: 'column' }}>
              <Typography sx={{ fontSize: fonts.fontSize20, fontWeight: fonts.fontWeight700 }}>
                {data.causeArea?.name}
              </Typography>
            </FlexBox>
            <FlexBox sx={{ gap: 2 }}>
              <DeleteOutlineOutlined
                onClick={() => onDeleteCauseArea(data.causeArea?.id!)}
                sx={{ cursor: 'pointer' }}
              />
            </FlexBox>
          </FlexBox>
          <Collapse in={expanded} unmountOnExit>
            <FlexBox sx={{ flexDirection: 'column' }}>
              <Typography mb={2}
              sx={(theme) => ({
                borderTop: `1px solid ${theme.palette.common.grey.light}`,
                paddingTop: '0.5rem',
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight700,
              })}>
                Measurable social impact
              </Typography>
              <CommonPaper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Impact Name</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }} align="right">
                        Impact Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.impact &&
                      data.impact.map(
                        (impactData: any) =>
                          impactData?.quantity > 0 && (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row" sx={{ fontSize: '1rem' }}>
                                {impactData?.description}
                              </TableCell>
                              <TableCell align="right" sx={{ fontSize: '1rem' }}>
                                {impactData?.quantity}
                              </TableCell>
                            </TableRow>
                          ),
                      )}
                  </TableBody>
                </Table>
              </CommonPaper>
            </FlexBox>
          </Collapse>
          <FlexBox
            sx={{ gap: 1, alignContent: 'start', cursor: 'pointer' }}
            onClick={handleExpandClick}
          >
            <Typography fontWeight="bold">{ !expanded ? 'More Details' : 'Less Details' }</Typography>
            <ExpandMore
              sx={(theme) => ({
                transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                transitionDuration: theme.transitions.duration.shorter,
              })}
            />
          </FlexBox>
        </FlexBox>
      </Box>
    </FlexBox>
  );
};
