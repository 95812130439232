import React, { FC, useCallback, useMemo } from 'react';
import {
  TextField,
  FormControlLabel,
  Typography,
  Box,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  IconButton,
  Button,
} from '@mui/material';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import {
  Account,
  AccountGiveSettingsConfig,
  FormControl as FormControlModel,
} from '../../../shared/interfaces';
import { isNullOrWhitespace } from '../../../shared/utils';
import { FlexBox, NumberInput, PaddingRoundedBox } from '../../../shared/components';
import { useAppDispatch } from '../../../shared/hooks';
import { toggleCustomField, GiveSettings } from './accountSettingsSlice';

interface Props {
  formConfig: AccountGiveSettingsConfig;
  setAccountFormData: (value: Account) => void;
  formDisabled?: boolean;
}

export function isAccountGiveSettingsInvalid(form: AccountGiveSettingsConfig): boolean | undefined {
  const {
    customForm1Control,
    customForm2Control,
    customForm3Control,
    customForm4Control,
    customerIdControl,
    customerNameControl,
    descriptionControl,
    quoteAmountControl,
    quoteNumberControl,

    showCustomField1 = false,
    showCustomField2 = false,
    showCustomField3 = false,
    showCustomField4 = false,
  } = form;

  return (
    (showCustomField1 && isNullOrWhitespace(customForm1Control?.value)) ||
    (showCustomField2 && isNullOrWhitespace(customForm2Control?.value)) ||
    (showCustomField3 && isNullOrWhitespace(customForm3Control?.value)) ||
    (showCustomField4 && isNullOrWhitespace(customForm4Control?.value)) ||
    (customerIdControl?.required && isNullOrWhitespace(customerIdControl?.value)) ||
    (customerNameControl?.required && isNullOrWhitespace(customerNameControl?.value)) ||
    (descriptionControl?.required && isNullOrWhitespace(descriptionControl?.value)) ||
    (quoteAmountControl?.required && isNullOrWhitespace(quoteAmountControl?.value)) ||
    (quoteNumberControl?.required && isNullOrWhitespace(quoteNumberControl?.value))
  );
}

export const AccountGiveSettings: FC<Props> = (props) => {
  const { formConfig: formValue, setAccountFormData, formDisabled } = props;
  const {
    customForm1Control,
    customForm2Control,
    customForm3Control,
    customForm4Control,
    customForm1RequiredControl,
    customForm2RequiredControl,
    customForm3RequiredControl,
    customForm4RequiredControl,
    customerIdControl,
    customerNameControl,
    descriptionControl,
    quoteAmountControl,
    quoteNumberControl,
    customForm1DefaultValueControl,
    customForm2DefaultValueControl,
    customForm3DefaultValueControl,
    customForm4DefaultValueControl,
    customerIdDefaultValueControl,
    customerNameDefaultValueControl,
    descriptionDefaultValueControl,
    quoteAmountDefaultValueControl,
    quoteNumberDefaultValueControl,
    customerIdRequiredControl,
    customerNameRequiredControl,
    descriptionRequiredControl,
    quoteAmountRequiredControl,
    quoteNumberRequiredControl,
    showCustomField1,
    showCustomField2,
    showCustomField3,
    showCustomField4,
  } = formValue;

  const dispatch = useAppDispatch();

  const customForm1 = useMemo(
    () => (
      <TextField
        label={customForm1Control?.label ?? 'Custom Label 1'}
        fullWidth
        required={customForm1Control?.required}
        disabled={formDisabled || customForm1Control?.disabled}
        value={customForm1Control?.value}
        onChange={(e) => {
          setAccountFormData({
            giveFields: {
              customField1: { label: e.target.value ?? '', displayOrder: 5, custom: true },
            },
          });
        }}
      />
    ),
    [
      customForm1Control?.disabled,
      customForm1Control?.label,
      customForm1Control?.required,
      customForm1Control?.value,
      formDisabled,
      setAccountFormData,
    ],
  );

  const customForm1DefaultValue = useMemo(
    () => (
      <TextField
        label={customForm1DefaultValueControl?.label}
        fullWidth
        value={customForm1DefaultValueControl?.value}
        disabled={formDisabled || customForm1DefaultValueControl?.disabled}
        onChange={(e) => {
          setAccountFormData({
            giveFields: {
              customField1: { defaultValue: e.target.value ?? '', displayOrder: 5, custom: true },
            },
          });
        }}
      />
    ),
    [
      customForm1DefaultValueControl?.label,
      customForm1DefaultValueControl?.value,
      formDisabled,
      customForm1DefaultValueControl?.disabled,
      setAccountFormData,
    ],
  );

  const customForm2 = useMemo(
    () => (
      <TextField
        label={customForm2Control?.label ?? 'Custom Label 2'}
        fullWidth
        required={customForm2Control?.required}
        disabled={formDisabled || customForm2Control?.disabled}
        value={customForm2Control?.value}
        onChange={(e) => {
          setAccountFormData({
            giveFields: {
              customField2: { label: e.target.value ?? '', displayOrder: 6, custom: true },
            },
          });
        }}
      />
    ),
    [
      customForm2Control?.disabled,
      customForm2Control?.label,
      customForm2Control?.required,
      customForm2Control?.value,
      formDisabled,
      setAccountFormData,
    ],
  );
  const customForm2DefaultValue = useMemo(
    () => (
      <TextField
        label={customForm2DefaultValueControl?.label}
        fullWidth
        value={customForm2DefaultValueControl?.value}
        disabled={formDisabled || customForm2DefaultValueControl?.disabled}
        onChange={(e) => {
          setAccountFormData({
            giveFields: {
              customField2: { defaultValue: e.target.value ?? '', displayOrder: 6, custom: true },
            },
          });
        }}
      />
    ),
    [
      customForm2DefaultValueControl?.label,
      customForm2DefaultValueControl?.value,
      formDisabled,
      customForm2DefaultValueControl?.disabled,
      setAccountFormData,
    ],
  );

  const customForm3 = useMemo(
    () => (
      <TextField
        label={customForm3Control?.label ?? 'Custom Label 3'}
        fullWidth
        required={customForm3Control?.required}
        disabled={formDisabled || customForm3Control?.disabled}
        value={customForm3Control?.value}
        onChange={(e) => {
          setAccountFormData({
            giveFields: {
              customField3: { label: e.target.value ?? '', displayOrder: 7, custom: true },
            },
          });
        }}
      />
    ),
    [
      customForm3Control?.disabled,
      customForm3Control?.label,
      customForm3Control?.required,
      customForm3Control?.value,
      formDisabled,
      setAccountFormData,
    ],
  );
  const customForm3DefaultValue = useMemo(
    () => (
      <TextField
        label={customForm3DefaultValueControl?.label}
        fullWidth
        value={customForm3DefaultValueControl?.value}
        disabled={formDisabled || customForm3DefaultValueControl?.disabled}
        onChange={(e) => {
          setAccountFormData({
            giveFields: {
              customField3: { defaultValue: e.target.value ?? '', displayOrder: 7, custom: true },
            },
          });
        }}
      />
    ),
    [
      customForm3DefaultValueControl?.label,
      customForm3DefaultValueControl?.value,
      formDisabled,
      customForm3DefaultValueControl?.disabled,
      setAccountFormData,
    ],
  );
  const customForm4 = useMemo(
    () => (
      <TextField
        label={customForm4Control?.label ?? 'Custom Label 4'}
        fullWidth
        required={customForm4Control?.required}
        disabled={formDisabled || customForm4Control?.disabled}
        value={customForm4Control?.value}
        onChange={(e) => {
          setAccountFormData({
            giveFields: {
              customField4: { label: e.target.value ?? '', displayOrder: 8, custom: true },
            },
          });
        }}
      />
    ),
    [
      customForm4Control?.disabled,
      customForm4Control?.label,
      customForm4Control?.required,
      customForm4Control?.value,
      formDisabled,
      setAccountFormData,
    ],
  );
  const customForm4DefaultValue = useMemo(
    () => (
      <TextField
        label={customForm4DefaultValueControl?.label}
        fullWidth
        value={customForm4DefaultValueControl?.value}
        disabled={formDisabled || customForm4DefaultValueControl?.disabled}
        onChange={(e) => {
          setAccountFormData({
            giveFields: {
              customField4: { defaultValue: e.target.value ?? '', displayOrder: 8, custom: true },
            },
          });
        }}
      />
    ),
    [
      customForm4DefaultValueControl?.label,
      customForm4DefaultValueControl?.value,
      formDisabled,
      customForm4DefaultValueControl?.disabled,
      setAccountFormData,
    ],
  );

  const requiredFieldRadioButtons = useCallback(
    (updateFormData: (value: boolean) => void, control?: FormControlModel<Boolean>) => (
      <FlexBox>
        <FormControl>
          <FormLabel sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
            {control?.label ?? 'Is this label a Required field?'}
          </FormLabel>
          <RadioGroup
            row
            value={control?.value === undefined ? false : control.value}
            onChange={(e) => {
              updateFormData(e.target.value === 'true');
            }}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Yes"
              disabled={formDisabled || control?.disabled}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="No"
              disabled={formDisabled || control?.disabled}
            />
          </RadioGroup>
        </FormControl>
      </FlexBox>
    ),
    [formDisabled],
  );

  const customForm1Required = useMemo(
    () =>
      requiredFieldRadioButtons((value) => {
        setAccountFormData({
          giveFields: { customField1: { required: value, displayOrder: 5, custom: true } },
        });
      }, customForm1RequiredControl),
    [customForm1RequiredControl, requiredFieldRadioButtons, setAccountFormData],
  );
  const customForm2Required = useMemo(
    () =>
      requiredFieldRadioButtons((value) => {
        setAccountFormData({
          giveFields: { customField2: { required: value, displayOrder: 6, custom: true } },
        });
      }, customForm2RequiredControl),
    [customForm2RequiredControl, requiredFieldRadioButtons, setAccountFormData],
  );
  const customForm3Required = useMemo(
    () =>
      requiredFieldRadioButtons((value) => {
        setAccountFormData({
          giveFields: { customField3: { required: value, displayOrder: 7, custom: true } },
        });
      }, customForm3RequiredControl),
    [customForm3RequiredControl, requiredFieldRadioButtons, setAccountFormData],
  );
  const customForm4Required = useMemo(
    () =>
      requiredFieldRadioButtons((value) => {
        setAccountFormData({
          giveFields: { customField4: { required: value, displayOrder: 8, custom: true } },
        });
      }, customForm4RequiredControl),
    [customForm4RequiredControl, requiredFieldRadioButtons, setAccountFormData],
  );

  const customerNameRequired = useMemo(
    () =>
      requiredFieldRadioButtons((value) => {
        setAccountFormData({ giveFields: { customerName: { required: value } } });
      }, customerNameRequiredControl),
    [customerNameRequiredControl, requiredFieldRadioButtons, setAccountFormData],
  );
  const customerIdRequired = useMemo(
    () =>
      requiredFieldRadioButtons((value) => {
        setAccountFormData({ giveFields: { customerId: { required: value } } });
      }, customerIdRequiredControl),
    [customerIdRequiredControl, requiredFieldRadioButtons, setAccountFormData],
  );
  const quoteAmountRequired = useMemo(
    () =>
      requiredFieldRadioButtons((value) => {
        setAccountFormData({ giveFields: { quoteAmount: { required: value } } });
      }, quoteAmountRequiredControl),
    [quoteAmountRequiredControl, requiredFieldRadioButtons, setAccountFormData],
  );
  const quoteNumberRequired = useMemo(
    () =>
      requiredFieldRadioButtons((value) => {
        setAccountFormData({ giveFields: { quoteNumber: { required: value } } });
      }, quoteNumberRequiredControl),
    [quoteNumberRequiredControl, requiredFieldRadioButtons, setAccountFormData],
  );

  const descriptionRequired = useMemo(
    () =>
      requiredFieldRadioButtons((value) => {
        setAccountFormData({ giveFields: { description: { required: value } } });
      }, descriptionRequiredControl),
    [descriptionRequiredControl, requiredFieldRadioButtons, setAccountFormData],
  );

  const hideCustomFieldButton = (giveSettings: GiveSettings, accountData: Account) => (
    <IconButton
      disabled={formDisabled}
      onClick={() => {
        dispatch(toggleCustomField(giveSettings));
        setAccountFormData(accountData);
      }}
    >
      <DeleteOutlineOutlined />
    </IconButton>
  );

  const showNewCustomField = () => {
    if (!showCustomField1) {
      dispatch(toggleCustomField({ showCustomField1: true }));
      return;
    }
    if (!showCustomField2) {
      dispatch(toggleCustomField({ showCustomField2: true }));
      return;
    }
    if (!showCustomField3) {
      dispatch(toggleCustomField({ showCustomField3: true }));
      return;
    }
    if (!showCustomField4) {
      dispatch(toggleCustomField({ showCustomField4: true }));
    }
  };

  return (
    <PaddingRoundedBox
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        backgroundColor: theme.palette.common.grey.main,
      })}
    >
      <FlexBox>
        <Typography variant="h5" flex={1}>
          Custom Labels
        </Typography>
        {!(showCustomField1 && showCustomField2 && showCustomField3 && showCustomField4) && (
          <Box>
            <Button
              variant="outlined"
              color="primary"
              sx={{ backgroundColor: '#fff' }}
              onClick={showNewCustomField}
              disabled={formDisabled}
            >
              Add Custom Label
            </Button>
          </Box>
        )}
      </FlexBox>
      <FlexBox sx={{ gap: 2, flexDirection: 'column' }}>
        <FlexBox
          sx={{
            justifyContent: 'space-between',
            gap: 6,
            borderRadius: 1,
            backgroundColor: '#fff',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <FlexBox sx={{ flexDirection: 'column', flex: 1 }}>
            <TextField
              label={customerNameControl?.label}
              fullWidth
              value={customerNameControl?.value}
              required={customerNameControl?.required}
              onChange={(e) => {
                setAccountFormData({
                  giveFields: {
                    customerName: { label: e.target.value ?? '', displayOrder: 0, custom: false },
                  },
                });
              }}
            />
            <TextField
              label={customerNameDefaultValueControl?.label}
              fullWidth
              value={customerNameDefaultValueControl?.value}
              onChange={(e) => {
                setAccountFormData({
                  giveFields: {
                    customerName: {
                      defaultValue: e.target.value ?? '',
                      displayOrder: 0,
                      custom: false,
                    },
                  },
                });
              }}
            />
          </FlexBox>
          {customerNameRequired}
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: 'space-between',
            gap: 6,
            borderRadius: 1,
            backgroundColor: '#fff',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <FlexBox sx={{ flexDirection: 'column', flex: 1 }}>
            <TextField
              label={customerIdControl?.label}
              fullWidth
              value={customerIdControl?.value}
              required={customerIdControl?.required}
              onChange={(e) => {
                setAccountFormData({
                  giveFields: {
                    customerId: { label: e.target.value ?? '', displayOrder: 1, custom: false },
                  },
                });
              }}
            />
            <TextField
              label={customerIdDefaultValueControl?.label}
              fullWidth
              value={customerIdDefaultValueControl?.value}
              onChange={(e) => {
                setAccountFormData({
                  giveFields: {
                    customerId: {
                      defaultValue: e.target.value ?? '',
                      displayOrder: 1,
                      custom: false,
                    },
                  },
                });
              }}
            />
          </FlexBox>
          {customerIdRequired}
        </FlexBox>
        <FlexBox
          sx={{
            justifyContent: 'space-between',
            gap: 6,
            borderRadius: 1,
            backgroundColor: '#fff',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <FlexBox sx={{ flexDirection: 'column', flex: 1 }}>
            <TextField
              label={quoteNumberControl?.label}
              fullWidth
              value={quoteNumberControl?.value}
              required={quoteNumberControl?.required}
              onChange={(e) => {
                setAccountFormData({
                  giveFields: {
                    quoteNumber: { label: e.target.value ?? '', displayOrder: 2, custom: false },
                  },
                });
              }}
            />
            <TextField
              label={quoteNumberDefaultValueControl?.label}
              fullWidth
              value={quoteNumberDefaultValueControl?.value}
              onChange={(e) => {
                setAccountFormData({
                  giveFields: {
                    quoteNumber: {
                      defaultValue: e.target.value ?? '',
                      displayOrder: 2,
                      custom: false,
                    },
                  },
                });
              }}
            />
          </FlexBox>
          {quoteNumberRequired}
        </FlexBox>

        <FlexBox
          sx={{
            justifyContent: 'space-between',
            gap: 6,
            borderRadius: 1,
            backgroundColor: '#fff',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <FlexBox sx={{ flexDirection: 'column', flex: 1 }}>
            <TextField
              label={quoteAmountControl?.label}
              fullWidth
              value={quoteAmountControl?.value}
              required={quoteAmountControl?.required}
              onChange={(e) => {
                setAccountFormData({
                  giveFields: {
                    quoteAmount: { label: e.target.value ?? '', displayOrder: 3, custom: false },
                  },
                });
              }}
            />
            <TextField
              label={quoteAmountDefaultValueControl?.label}
              fullWidth
              value={quoteAmountDefaultValueControl?.value}
              InputProps={{
                inputComponent: NumberInput as any,
              }}
              onChange={(e) => {
                setAccountFormData({
                  giveFields: {
                    quoteAmount: {
                      defaultValue: Number(e.target.value),
                      displayOrder: 3,
                      custom: false,
                    },
                  },
                });
              }}
            />
          </FlexBox>
          {quoteAmountRequired}
        </FlexBox>

        <FlexBox
          sx={{
            justifyContent: 'space-between',
            gap: 6,
            borderRadius: 1,
            backgroundColor: '#fff',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <FlexBox sx={{ flexDirection: 'column', flex: 1 }}>
            <TextField
              label={descriptionControl?.label}
              fullWidth
              value={descriptionControl?.value}
              required={descriptionControl?.required}
              onChange={(e) => {
                setAccountFormData({
                  giveFields: {
                    description: { label: e.target.value ?? '', displayOrder: 4, custom: false },
                  },
                });
              }}
            />
            <TextField
              label={descriptionDefaultValueControl?.label}
              fullWidth
              value={descriptionDefaultValueControl?.value}
              onChange={(e) => {
                setAccountFormData({
                  giveFields: {
                    description: {
                      defaultValue: e.target.value ?? '',
                      displayOrder: 4,
                      custom: false,
                    },
                  },
                });
              }}
            />
          </FlexBox>
          {descriptionRequired}
        </FlexBox>

        {showCustomField1 && (
          <FlexBox
            sx={{
              justifyContent: 'space-between',
              gap: 6,
              borderRadius: 1,
              backgroundColor: '#fff',
              alignItems: 'center',
              padding: 2,
            }}
          >
            <FlexBox sx={{ flexDirection: 'column', flex: 1 }}>
              {customForm1}
              {customForm1DefaultValue}
            </FlexBox>
            {customForm1Required}
            {hideCustomFieldButton(
              { showCustomField1: false },
              {
                giveFields: {
                  customField1: {
                    defaultValue: '',
                    displayOrder: 5,
                    label: '',
                    required: false,
                    custom: true,
                  },
                },
              },
            )}
          </FlexBox>
        )}
        {showCustomField2 && (
          <FlexBox
            sx={{
              justifyContent: 'space-between',
              gap: 6,
              borderRadius: 2,
              backgroundColor: '#fff',
              alignItems: 'center',
              padding: 2,
            }}
          >
            <FlexBox sx={{ flexDirection: 'column', flex: 1 }}>
              {customForm2}
              {customForm2DefaultValue}
            </FlexBox>
            {customForm2Required}
            {hideCustomFieldButton(
              { showCustomField2: false },
              {
                giveFields: {
                  customField2: {
                    defaultValue: '',
                    displayOrder: 6,
                    label: '',
                    required: false,
                    custom: true,
                  },
                },
              },
            )}
          </FlexBox>
        )}
        {showCustomField3 && (
          <FlexBox
            sx={{
              justifyContent: 'space-between',
              gap: 6,
              borderRadius: 2,
              backgroundColor: '#fff',
              alignItems: 'center',
              padding: 2,
            }}
          >
            <FlexBox sx={{ flexDirection: 'column', flex: 1 }}>
              {customForm3}
              {customForm3DefaultValue}
            </FlexBox>
            {customForm3Required}
            {hideCustomFieldButton(
              { showCustomField3: false },
              {
                giveFields: {
                  customField3: {
                    defaultValue: '',
                    displayOrder: 7,
                    label: '',
                    required: false,
                    custom: true,
                  },
                },
              },
            )}
          </FlexBox>
        )}
        {showCustomField4 && (
          <FlexBox
            sx={{
              justifyContent: 'space-between',
              gap: 6,
              borderRadius: 2,
              backgroundColor: '#fff',
              alignItems: 'center',
              padding: 2,
            }}
          >
            <FlexBox sx={{ flexDirection: 'column', flex: 1 }}>
              {customForm4}
              {customForm4DefaultValue}
            </FlexBox>
            {customForm4Required}
            {hideCustomFieldButton(
              { showCustomField4: false },
              {
                giveFields: {
                  customField4: {
                    defaultValue: '',
                    displayOrder: 8,
                    label: '',
                    required: false,
                    custom: true,
                  },
                },
              },
            )}
          </FlexBox>
        )}
      </FlexBox>
    </PaddingRoundedBox>
  );
};
