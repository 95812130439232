import React, { useEffect, useState, ChangeEvent } from 'react'; // useState
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'; // Typography, Button
import {
  Grid,
  Button,
  Checkbox,
  TextField,
  Divider,
  Tooltip,
  Card,
  CardContent,
  Box,
  Typography,
  CardHeader,
  Popover,
  DialogActions,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import { useAppSelector, useAppDispatch } from '../../../shared/hooks';
import { fetchSioUser, updateSioUserThunk } from '../sioSlice';
import USAFlag from '../../../assets/svg/united-states.svg';
import UKFlag from '../../../assets/svg/united-kingdom.svg';
import '../formFilling.scss';
import { CellPhoneInput } from '../../../shared/components/CellphoneInput';
import fonts from '../../../assets/scss/font.module.scss';
import colors from '../../../assets/scss/color.module.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#68839A',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#FFF',
  },
}));

const General = () => {
  const sioState = useAppSelector((state) => state.sios);
  const { sio } = sioState;
  const { id } = sio;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [emailInputs, setEmailInputs] = useState<string[]>(sio.general?.contact?.email);
  const initialFormValue = {
    legalName: sio.general?.name?.legalOrganizationName,
    publicName: sio.general?.name?.publicOrganizationName,
    websiteURL: sio.general?.social?.websiteURL,
    facebook: sio.general?.social?.facebook,
    linkedIn: sio.general?.social?.linkedIn,
    instagram: sio.general?.social?.instagram,
    twitter: sio.general?.social?.twitter,
    countryCode: sio.general?.contact?.countryCode,
    phoneNumber: sio.general?.contact?.phone,
    firstLastName: sio.general?.contact?.name,
    professionalTitle: sio.general?.contact?.professionalTitle,
    email: sio.general?.contact?.email || [],
    professionalPhoneNo: sio.general?.contact?.phone,
    registeredLocation: sio.general?.location?.specificLocation,
    committed: sio.general?.missionAgreement,
    missionHistory: sio.overviewAndMission?.historyDescription,
    problem: sio.overviewAndMission?.problemDescription,
    causeAreas: { selected: sio.overviewAndMission?.causeAreas?.selected },
    programInitiativesPriorites: sio.overviewAndMission?.initiativesDescription,
    programOperate: sio.overviewAndMission?.programLocations,
    researchEvaluation: sio.overviewAndMission?.researchAndEvaluation,
    fileUpload: sio.overviewAndMission?.researchAndEvaluationFile?.name,
    lifetimeOutputs: sio.overviewAndMission?.lifetimeOutputs ?? [],
    fullTimeStaff: sio.operationalInformation?.staff.fullTime,
    partTimeStaff: sio.operationalInformation?.staff.partTime,
    volunteers: sio.operationalInformation?.staff.volunteers,
    formalInformalRelationship: sio.operationalInformation?.partnershipsDescription,
    budget: sio.operationalInformation?.yearlyBudget,
    financialStatement: sio.operationalInformation?.financialStatement?.file?.name,
    supportersPartners: sio.operationalInformation?.supportersAndPartners,
    reviewName: '',
    reviewEmail: '',
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [termsAccepted, setTermsAccepted] = useState(formValues.committed);
  const countryCodes = [
    { code: '+1', label: 'USA (+1)' },
    { code: '+44', label: 'UK (+44)' },
    { code: '+91', label: 'India (+91)' },
    // Add more country codes as needed
  ];

  useEffect(() => {
    if (id && sio) {
      dispatch(fetchSioUser({ id })).then((sioData: any) => {
        setFormValues({
          legalName: sioData.payload.general?.name?.legalOrganizationName ?? '',
          publicName: sioData.payload.general?.name?.publicOrganizationName ?? '',
          websiteURL: sioData.payload.general?.social?.websiteURL ?? '',
          facebook: sioData.payload.general?.social?.facebook ?? '',
          linkedIn: sioData.payload.general?.social?.linkedIn ?? '',
          instagram: sioData.payload.general?.social?.instagram ?? '',
          twitter: sioData.payload.general?.social?.twitter ?? '',
          countryCode: sioData.payload.general?.contact?.countryCode ?? '',
          phoneNumber: sioData.payload.general?.contact?.phone ?? '',
          firstLastName: sioData.payload.general?.contact?.name ?? '',
          professionalTitle: sioData.payload.general?.contact?.professionalTitle ?? '',
          email: sioData.payload.general?.contact?.email ?? [],
          professionalPhoneNo: sioData.payload.general?.contact?.phone ?? '',
          registeredLocation: sioData.payload.general?.location?.specificLocation ?? '',
          committed: sioData.payload.general?.missionAgreement,
          missionHistory: sioData.payload.overviewAndMission?.historyDescription,
          problem: sioData.payload.overviewAndMission?.problemDescription,
          causeAreas: { selected: sioData.payload.overviewAndMission?.causeAreas?.selected },
          programInitiativesPriorites: sioData.payload.overviewAndMission?.initiativesDescription,
          programOperate: sioData.payload.overviewAndMission?.programLocations,
          researchEvaluation: sioData.payload.overviewAndMission?.researchAndEvaluation,
          fileUpload: sioData.payload.overviewAndMission?.researchAndEvaluationFile?.name,
          lifetimeOutputs: sioData.payload.overviewAndMission?.lifetimeOutputs ?? [],
          fullTimeStaff: sioData.payload.operationalInformation?.staff.fullTime,
          partTimeStaff: sioData.payload.operationalInformation?.staff.partTime,
          volunteers: sioData.payload.operationalInformation?.staff.volunteers,
          formalInformalRelationship:
            sioData.payload.operationalInformation?.partnershipsDescription,
          budget: sioData.payload.operationalInformation?.yearlyBudget,
          financialStatement: sioData.payload.operationalInformation?.financialStatement?.file.name,
          supportersPartners: sioData.payload.operationalInformation?.supportersAndPartners,
          reviewName: '',
          reviewEmail: '',
        });
        // savePageNumber()
      });
    }
  }, [id]);

  useEffect(() => {
    setEmailInputs(sio.general?.contact?.email);
  }, [sio.general?.contact?.email]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'professionalPhoneNo') {
      setFormValues({ ...formValues, [name]: value.replace(/,/g, '') });
    } else if (name === 'email') {
      setEmailInputs([value]);
      setFormValues({ ...formValues, [name]: [value] });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    // Update state specifically for the country code select component
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const updateSioDetails = async () => {
    const payload = {
      general: {
        name: {
          legalOrganizationName: formValues.legalName,
          publicOrganizationName: formValues.publicName,
        },
        social: {
          facebook: formValues.facebook,
          instagram: formValues.instagram,
          linkedIn: formValues.linkedIn,
          twitter: formValues.twitter,
          websiteURL: formValues.websiteURL,
        },
        contact: {
          professionalTitle: formValues.professionalTitle,
          name: formValues.firstLastName,
          countryCode: formValues.countryCode,
          phone: formValues.professionalPhoneNo,
          email: formValues.email,
        },
        location: {
          generalLocation: formValues.registeredLocation,
          specificLocation: formValues.registeredLocation,
        },
        missionAgreement: formValues.committed,
      },
    };
    if (!id) {
      return false;
    }

    if (id) {
      try {
        const result = await dispatch(updateSioUserThunk({ sio: payload, id }));
        return result.meta.requestStatus;
      } catch (error) {
        console.error('Update failed with exception:', error);
        return false;
      }
    }
    return false;
  };

  const handleNextbutton = async () => {
    try {
      const updateResult = await updateSioDetails();

      switch (updateResult) {
        case 'fulfilled':
          toast.success('Successfully Submitted Form to ScaleWith');
          if (pageNumber < 5) {
            setPageNumber(pageNumber + 1);
          } else {
            navigate(`/app/sio/organization-information`);
          }
          break;
        case 'rejected':
          toast.error('Failed to update values');
          break;
        default:
          toast.error(updateResult || 'An unexpected error occurred');
      }
    } catch (error) {
      console.error('Error handling next button:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };

  const handlePreviousbutton = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSaveExit = async () => {
    try {
      const response = await updateSioDetails();
      switch (response) {
        case 'fulfilled':
          toast.success('Successfully Submitted Form to ScaleWith');
          navigate(`/app/sio/organization-information`);
          break;
        case 'rejected':
          toast.error('Failed to update values');
          break;
        default:
          toast.error(response || 'An unexpected error occurred');
      }
    } catch (error) {
      console.error('Error in handleSaveExit:', error);
      toast.error('An error occurred. Please try again later.');
    }
  };

  const disabledButtons = () => {
    let disable;
    if (pageNumber === 1) {
      if (!formValues.legalName || !formValues.publicName) disable = true;
    } else if (pageNumber === 2) {
      if (!formValues.websiteURL) disable = true;
    } else if (pageNumber === 3) {
      if (
        !formValues.professionalTitle ||
        !formValues.professionalPhoneNo ||
        formValues.professionalPhoneNo[0] === '0' ||
        formValues.professionalPhoneNo[0] === '1' ||
        formValues.professionalPhoneNo.length < 10 ||
        !formValues.email ||
        !formValues.email.every(validateEmail) ||
        !formValues.firstLastName
      )
        disable = true;
    } else if (pageNumber === 4) {
      if (!formValues.registeredLocation) disable = true;
    } else if (pageNumber === 5) {
      if (!formValues.committed) disable = true;
    }
    return disable;
  };
  const handleTermsAcceptedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;
    setTermsAccepted(isChecked);
    if (!isChecked) {
      setFormValues((prevFormValues) => {
        const updatedFormValues = {
          ...prevFormValues,
          committed: false,
        };
        return updatedFormValues;
      });
    } else {
      setFormValues((prevFormValues) => {
        const updatedFormValues = {
          ...prevFormValues,
          committed: true,
        };
        return updatedFormValues;
      });
    }
  };

  const handleRemoveInput = (index: number) => {
    if (emailInputs.length > 1) {
      setEmailInputs(emailInputs.filter((_, i) => i !== index));
    }
  };
  const handlePopoverInputChange = (index: number, value: string) => {
    const updatedInputs = [...emailInputs];
    updatedInputs[index] = value;
    setEmailInputs(updatedInputs);
  };

  const handleAddInput = () => {
    setEmailInputs([...emailInputs, '']);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSave = async () => {
    // save emails to database or state management library
    await setFormValues({
      ...formValues,
      email: [...emailInputs],
    });
    handleClose();
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  // *** Start Questions ***
  const question1 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 5 }} variant="h3">
          Let&apos;s get started!
        </Typography>
        <Typography sx={commonTextStyle.questionStyle}>Question 1 of 5</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          What is the name of your organization?
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Legal Name{' '}
          <Tooltip title="Enter The legal Name">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="legalName"
          placeholder="Enter your organization's legal name"
          fullWidth
          margin="normal"
          value={formValues.legalName}
          required
          onChange={handleChange}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            mb: 3,
            float: 'right',
            padding: '15px 20px',
            width: '300px',
            background: '#E7F2FF',
            borderRadius: '20px',
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            <InfoIcon fontSize="medium" /> LEGAL NAME
          </Typography>
          <Typography variant="body1">
            Legal name is how it appears on your legal paperwork, like your W9.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Public Name{' '}
          <Tooltip title="Enter The Public Name">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="publicName"
          placeholder="Enter your organization's public name"
          fullWidth
          required
          value={formValues.publicName}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            float: 'right',
            padding: '15px 20px',
            width: '300px',
            background: '#E7F2FF',
            borderRadius: '20px',
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            <InfoIcon fontSize="medium" /> PUBLIC NAME
          </Typography>
          <Typography variant="body1">
            Public name name is a common name you go by, like a DBA (Doing Business As).
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  const question2 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 2 of 5</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Please provide your website URL and social media handles.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Website URL{' '}
          <Tooltip title="Enter The Website URL">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="websiteURL"
          placeholder="Enter your organization's website URL"
          fullWidth
          value={formValues.websiteURL}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Instagram{' '}
          <Tooltip title="Enter your instagram ID">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="instagram"
          placeholder="Enter your organization's instagram url"
          value={formValues.instagram}
          fullWidth
          margin="normal"
          onChange={handleChange}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Facebook{' '}
          <Tooltip title="Enter your facebook handle">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="facebook"
          placeholder="Enter your organization's facebook handle here"
          fullWidth
          value={formValues.facebook}
          margin="normal"
          onChange={handleChange}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Twitter{' '}
          <Tooltip title="Enter your twitter handle">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="twitter"
          placeholder="Enter your organization's Twitter handle here"
          fullWidth
          margin="normal"
          value={formValues.twitter}
          variant="outlined"
          onChange={handleChange}
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          LinkedIn{' '}
          <Tooltip title="Enter your LinkedIn handle">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="linkedIn"
          placeholder="Enter your organization's linkedIn handle here"
          fullWidth
          value={formValues.linkedIn}
          margin="normal"
          onChange={handleChange}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
    </Grid>
  );

  const question3 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 3 of 5</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Who will be the main contact person for your organization?
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          First And Last Name{' '}
          <Tooltip title="Enter full name">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="firstLastName"
          placeholder="Enter first and last name"
          fullWidth
          onChange={handleChange}
          margin="normal"
          value={formValues.firstLastName}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Professional Title{' '}
          <Tooltip title="Enter professional title">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="professionalTitle"
          placeholder="Enter professional title"
          fullWidth
          margin="normal"
          onChange={handleChange}
          value={formValues.professionalTitle}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography sx={commonTextStyle.labelStyle}>
            Email Address{' '}
            <Tooltip title="Enter email address">
              <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
            </Tooltip>
          </Typography>
          <TextField
            name="email"
            placeholder="Enter email address"
            fullWidth
            onChange={handleChange}
            type="email"
            margin="normal"
            value={formValues.email.length > 0 ? formValues.email.join(', ') : ''}
            variant="outlined"
            error={formValues.email.length > 0 ? !formValues.email.every(validateEmail) : false}
            sx={commonTextStyle.textFieldStyle}
          />
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              '& .MuiPaper-root': {
                width: '400px',
                padding: '1rem',
                borderRadius: '10px',
              },
            }}
          >
            <div className="header">
              <Typography sx={commonTextStyle.heading}>Add more Contact email</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon sx={{ color: '#005fea' }} />
              </IconButton>
            </div>
            <Typography sx={commonTextStyle.labelStyle}>Enter Contact Email Id</Typography>
            {emailInputs &&
              emailInputs.map((value, index) => (
                <div key={value} style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    autoFocus
                    placeholder="Enter Contact email id here"
                    variant="outlined"
                    margin="dense"
                    id={`email-${index}`}
                    type="email"
                    fullWidth
                    value={value}
                    onChange={(event) => handlePopoverInputChange(index, event.target.value)}
                    sx={commonTextStyle.textFieldStyle}
                  />
                  {emailInputs.length >= 1 && (
                    <IconButton
                      onClick={() => handleRemoveInput(index)}
                      sx={{ marginBottom: '1.7rem' }}
                    >
                      <HighlightOffIcon sx={{ color: '#005fea' }} />
                    </IconButton>
                  )}
                </div>
              ))}
            <DialogActions>
              <Button
                variant="outlined"
                sx={commonTextStyle.buttonStyle}
                onClick={() => handleAddInput()}
              >
                Add More
              </Button>
              <Button variant="outlined" sx={commonTextStyle.buttonStyle} onClick={handleSave}>
                Save
              </Button>
            </DialogActions>
          </Popover>
        </Box>
        {formValues.email.length > 0 && !formValues.email.every(validateEmail) && (
          <Box sx={{ height: '16px' }}>
            <FormHelperText error>Please enter valid email</FormHelperText>
          </Box>
        )}
        <Box>
          <Button sx={{ whiteSpace: 'nowrap', color: colors.primary }} onClick={handleOpen}>
            <AddCircleOutlineIcon sx={{ marginRight: '10px' }} />
            <span> Add more</span>
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ justifyContent: 'center' }}>
        <Typography sx={commonTextStyle.labelStyle}>
          Phone Number{' '}
          <Tooltip title="Enter phone number">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <FormControl fullWidth sx={{ maxWidth: '120px' }}>
            <InputLabel id="country-code-label">Code</InputLabel>
            <Select
              name="countryCode"
              labelId="country-code-label"
              id="country-code-select"
              value={formValues.countryCode}
              label="Code"
              onChange={handleCountryCodeChange}
              sx={{ height: '100%', mr: 1, fontSize: '12pt' }}
            >
              {countryCodes.map((option) => (
                <MenuItem key={option.code} value={option.code} sx={{ height: '50%' }}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            name="professionalPhoneNo"
            placeholder="Enter phone number"
            fullWidth
            onChange={handleChange}
            type="text"
            margin="normal"
            value={formValues.professionalPhoneNo}
            variant="outlined"
            error={
              formValues.professionalPhoneNo !== undefined &&
              (formValues.professionalPhoneNo[0] === '0' ||
                formValues.professionalPhoneNo[0] === '1' ||
                (formValues.professionalPhoneNo.length < 10 &&
                  formValues.professionalPhoneNo !== ''))
            }
            InputProps={{
              inputComponent: CellPhoneInput as any,
            }}
            sx={commonTextStyle.textFieldStyle}
          />
        </Box>
      </Grid>
    </Grid>
  );

  const question4 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 4 of 5</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Where is your organization incorporated?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Checkbox
          name="registeredLocation"
          onChange={handleChange}
          checked={formValues.registeredLocation === 'USA'}
          value="USA"
          icon={
            <Box
              sx={{
                border: '1px solid #033156',
                borderRadius: '15px',
                width: '238px',
                height: '200px',
              }}
            >
              <Grid container>
                <Grid item xs={12} textAlign="center">
                  <img src={USAFlag} alt="USA" />
                  <Typography variant="body1">USA</Typography>
                </Grid>
              </Grid>
            </Box>
          }
          checkedIcon={
            <Box
              sx={{
                bgcolor: '#E7F2FF',
                border: '1px solid #033156',
                borderRadius: '15px',
                width: '238px',
                height: '200px',
              }}
            >
              <Grid container>
                <Grid item xs={12} textAlign="center">
                  <img src={USAFlag} alt="USA" />
                  <Typography variant="body1">USA</Typography>
                </Grid>
              </Grid>
            </Box>
          }
        />
        <Checkbox
          checked={formValues.registeredLocation === 'UK'}
          value="UK"
          name="registeredLocation"
          onChange={handleChange}
          icon={
            <Box
              sx={{
                border: '1px solid #033156',
                borderRadius: '15px',
                width: '238px',
                height: '200px',
              }}
            >
              <Grid container>
                <Grid item xs={12} textAlign="center">
                  <img src={UKFlag} alt="UK" />
                  <Typography variant="body1">UK</Typography>
                </Grid>
              </Grid>
            </Box>
          }
          checkedIcon={
            <Box
              sx={{
                bgcolor: '#E7F2FF',
                border: '1px solid #033156',
                borderRadius: '15px',
                width: '238px',
                height: '200px',
              }}
            >
              <Grid container>
                <Grid item xs={12} textAlign="center">
                  <img src={UKFlag} alt="UK" />
                  <Typography variant="body1">UK</Typography>
                </Grid>
              </Grid>
            </Box>
          }
        />
        <Checkbox
          onChange={handleChange}
          checked={formValues.registeredLocation === 'Other'}
          value="Other"
          name="registeredLocation"
          icon={
            <Box
              sx={{
                textAlign: 'center',
                border: '1px solid #033156',
                borderRadius: '15px',
                width: '238px',
                height: '200px',
              }}
            >
              <Typography sx={{ lineHeight: '200px' }} variant="body1">
                Other
              </Typography>
            </Box>
          }
          checkedIcon={
            <Box
              sx={{
                textAlign: 'center',
                bgcolor: '#E7F2FF',
                border: '1px solid #033156',
                borderRadius: '15px',
                width: '238px',
                height: '200px',
              }}
            >
              <Typography sx={{ lineHeight: '200px' }} variant="body1">
                Other
              </Typography>
            </Box>
          }
        />
      </Grid>
      {/* {formValues.registeredLocation === "USA" && <><Grid item xs={12}>
        <Typography sx={{mb:2}} variant='h4' >In which state are you registered?</Typography>
    </Grid>
    <Grid item xs={12} sm={6}>
        <Typography sx={{mb:1}} variant='subtitle1'>Registration State <Tooltip title="Enter phone number"><InfoIcon sx={{fontSize:'15px',color:'#868e96'}}/></Tooltip></Typography>
        <TextField
            name="registeredLocation"
            placeholder="Enter specific location"
            fullWidth
            onChange={handleChange}
            margin="normal"
            value={formValues.registeredLocation}
            variant="outlined"
            InputProps={{
            sx: {
                '& .MuiInputBase-input::placeholder': {
                fontSize: '1rem',
                },
            },
            }}
            sx={{
            '& .MuiInputBase-root': {
                height: '50px',
            },
            }}
        />
    </Grid></>} */}
    </Grid>
  );

  const question5 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 5 of 5</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          ScaleWith is committed to working with organizations that foster inclusivity.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Typography variant="body1">
          We respect the people and communities our social impact partners serve, regardless of
          race, class, sex, gender identity, education level, employment status, financial position,
          or any other characterization, and treat them with dignity and kindness. Please confirm
          here that your organization is aligned with this mission both philosophically and in
          practice.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ background: '#E7F2FF', borderRadius: '10px', padding: '15px' }}>
          <Grid item xs={12}>
            <Checkbox checked={termsAccepted} onChange={handleTermsAcceptedChange} />{' '}
            <Typography sx={{ display: 'inline-block' }} variant="body1">
              Yes, our organization is aligned with this mission
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
  // *** End Questions ***

  return (
    <section className="bgLight">
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardHeader
              sx={{
                padding: '20px 80px',
                background: colors.backgroundColorGradient,
                borderRadius: '20px 20px 0px 0px',
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize18,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Section 1
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize24,
                        fontWeight: fonts.fontWeight700,
                        color: colors.secondary,
                      }}
                    >
                      General Information
                    </Typography>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <Typography
                        sx={{
                          fontSize: fonts.fontSize18,
                          fontWeight: fonts.fontWeight600,
                          color: colors.secondary,
                        }}
                      >
                        {pageNumber === 1 ? 0 : (pageNumber - 1) * 20}% complete
                      </Typography>
                    </Grid>
                    <BorderLinearProgress
                      variant="determinate"
                      value={pageNumber === 1 ? 1 : (pageNumber - 1) * 20}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent sx={{ minHeight: '70vh', padding: '40px 80px' }}>
              {pageNumber === 1 && question1}
              {pageNumber === 2 && question2}
              {pageNumber === 3 && question3}
              {pageNumber === 4 && question4}
              {pageNumber === 5 && question5}
              <Grid item xs={12} textAlign="right">
                {' '}
                {/* className={pageNumber===1 ? "":'bottomFix'} */}
                <Divider sx={{ my: 3 }} orientation="horizontal" flexItem />
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  disabled={disabledButtons()}
                  onClick={handleSaveExit}
                  variant="outlined"
                  size="large"
                >
                  Save and Exit
                </Button>
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  disabled={pageNumber === 1}
                  onClick={handlePreviousbutton}
                  variant="outlined"
                  size="large"
                >
                  Previous
                </Button>
                <Button
                  sx={{
                    ...commonTextStyle.buttonStyle,
                    backgroundColor: colors.primary,
                    color: colors.secondary,
                    mx: 2,
                  }}
                  disabled={disabledButtons()}
                  onClick={handleNextbutton}
                  variant="contained"
                  size="large"
                >
                  Next
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default General;
