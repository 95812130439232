import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { Box, Checkbox, Button, Typography } from '@mui/material';
import { FlexBox } from '../../../shared/components';
import colors from '../../../assets/scss/color.module.scss';


interface FilterProps {
    label: string;
    list?: any;
    selectedFilterValues?: string[] | undefined;
    setFilters?: (value: any) => void;
}

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: '#EDFFFA',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: '#EDFFFA',
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

const PopperComponent = (props: PopperComponentProps) => {
  const { disablePortal, anchorEl, open, ...other } = props;
  console.log(disablePortal, anchorEl, open);
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '::placeholder': {
        fontStyle: 'italic',
      },
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === 'light'
          ? 'rgba(3, 102, 214, 0.3)'
          : 'rgb(12, 45, 107)'
      }`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}));

const SelectButton = styled(Button)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: 'none',
    height: '44px',
    padding: '10px, 12px, 10px, 12px',
    borderRadius: '5px',
    ':hover': {
        backgroundColor: '#EDFFFA !important',
        color: `${colors.primary} !important`,
        border: `1px solid ${colors.primary} !important`,
        boxShadow: 'none',
    },
}));

const ProgramFilter = ({ label, list, setFilters, selectedFilterValues }: FilterProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState<string[] | undefined>(selectedFilterValues);
  const [pendingValue, setPendingValue] = React.useState<string[] | undefined>(selectedFilterValues);

  useEffect(() => {
    setPendingValue(selectedFilterValues);
    setValue(selectedFilterValues);
  }, [selectedFilterValues])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if(value) setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleClear = () => {
    setPendingValue([]);
  };

  const handleApply = () => {
    setValue(pendingValue);
    setFilters?.(pendingValue);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popper' : undefined;

  return (
    <>
      <Box sx={{ fontSize: 13, width: '175px' }}>
        <SelectButton
          disableRipple
          aria-describedby={id}
          onClick={handleClick}
          variant="contained"
          sx={{
            width: '100%',
            backgroundColor: value && value.length > 0 || open ? '#EDFFFA' : 'white',
            color: value && value.length > 0 || open ? colors.primary : 'black',
            border: `1px solid ${value && value.length > 0 || open ? colors.primary : '#C4C4C4'}`,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <Typography noWrap>
            {
              // eslint-disable-next-line no-nested-ternary
              value && value.length === 0
                ? label
                // eslint-disable-next-line no-nested-ternary
                : value && value.length === 1
                ? value && value[0]
                : value && (
                    <Typography>
                      {value && value[0]},{' '}
                      <Typography
                        style={{
                          padding: '0 4px',
                          borderRadius: '4px',
                          backgroundColor: '#ffff',
                          color: 'black',
                        }}
                      >
                        +{value && value.length - 1}
                      </Typography>
                    </Typography>
                  )
            }
          </Typography>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </SelectButton>
      </Box>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Box>
            <Autocomplete
              open
              multiple
              onClose={(event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={pendingValue}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                setPendingValue(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText={`No ${label}s`}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '10px',
                    marginRight: '10px',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                  }}
                >
                  <Checkbox
                    checked={selected}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{ paddingY: 0 }}
                    icon={<CheckBoxOutlineBlankOutlinedIcon />}
                    checkedIcon={<CheckBoxOutlinedIcon />}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      fontFamily: 'Inter,sans-serif',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '21px',
                      letterSpacing: '0px',
                      color: '#333333',
                    }}
                  >
                    {option}
                  </Box>
                </li>
              )}
              options={[...(list || [])]}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={`Find a ${label}`}
                />
              )}
            />
            {list && list?.length > 0 && (
              <FlexBox alignItems="center" sx={{ m: 1.2 }}>
                <Button
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0px',
                    color: 'black',
                  }}
                  onClick={() => handleClear()}
                >
                  Clear Filter
                </Button>
                <Button variant="contained" onClick={() => handleApply()} sx={{ mx: 2 }}>
                  Apply
                </Button>
              </FlexBox>
            )}
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
}

export default ProgramFilter;