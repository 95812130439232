import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  Collapse,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { OpenInNew, Info } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Paper from '@mui/material/Paper';
import { FormDialog } from '../FormDialog';
import { useAppSelector, useAppDispatch, useAuth, useInstance } from '../../hooks';
import {
  patchUpdateContributionStatusThunk,
  getMultipleContributionsThunk,
  clearUpdateContributionStatusState,
  setComments,
  deleteContributionThunk,
  postContributionCheckoutSessionThunk,
  setCompletingGives,
  postContributionShareThunk,
  setUpdateContributionStatusRequest,
  postPaymentMethodThunk,
  postContributionStatusThunk,
} from './updateIncentiveStatusSlice';
import { formatter, isNullOrWhitespace, openAccountSettingsOnTab } from '../../utils';
import { ReadOnlyDataField } from '../ReadOnlyDataField';
import { CommonPaper, FlexBox } from '../StyledComponents';
import { AUTOPAY_ENABLED, ENABLE_COMPLETE_ABANDON, ORDERS_NEGATIVE_ACTIONS } from '../../constants';
import {
  AccountSettingsTabs,
  Dialogs,
  ContributionStatus,
  ContributionStatusLabel,
  UserRole,
  PaymentMethods,
  PaymentMethodLabels,
  PaymentMethodLabelInfo,
} from '../../enums';
import { RoleRequired } from '../../../app';
import { UpdateContributionsProgressBar } from './UpdateIncentivesProgressBar';
import { hideDialogCloseIcon } from '../../../features';

interface Props {
  contributionInfo: any;
}

const MeasurableSocialImpact: React.FC<Props> = ({ contributionInfo }) => (
<Table>
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: 'bold' }}>IMPACT NAME</TableCell>
        <TableCell sx={{ fontWeight: 'bold' }} align="right">
          IMPACT VALUE
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {contributionInfo?.program?.outputs.map(
        (impact: any) =>
          impact?.value !== 0 &&
          impact?.value !== null && (
            <TableRow
              key={impact.description}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ fontSize: '1rem' }}>
                {impact?.description}
              </TableCell>
              <TableCell align="right" sx={{ fontSize: '1rem' }}>
                {impact?.value}
              </TableCell>
            </TableRow>
          ),
      )}
    </TableBody>
  </Table>
  );

const ProgramDetails: React.FC<Props> = ({ contributionInfo }) => {
  const { locale } = useInstance();
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <ReadOnlyDataField label="Program Name" value={contributionInfo?.program?.name} />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyDataField
          label="Deal Value"
          value={`${locale?.settings?.symbol}${formatter.format(contributionInfo?.quoteAmount ?? 0)}`}
          valueProps={{ fontWeight: 'bold' }}
        />
      </Grid>
      <Grid item xs={3}>
        <ReadOnlyDataField
          label="Impact Contribution"
          value={`${locale?.settings?.symbol}${formatter.format(contributionInfo?.grandTotal ?? 0)}`}
          valueProps={{ fontWeight: 'bold' }}
        />
      </Grid>
    </Grid>
  );
};

const SummaryRow: React.FC<Props> = ({ contributionInfo }) => {
  const state = useAppSelector((x) => x.updateContributionStatus);
  const { id } = state;
  const accountState = useAppSelector((x) => x.app.account);
  const { incentiveFields } = { ...accountState };
  const { customField1, customField2, customField3, customField4 } = {
    ...incentiveFields,
  };
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!id) return;
    setOpen(true);
  }, [id]);

  return (
    <>
      <TableRow
        sx={{
          '& > *': { borderBottom: '1px solid #E0E0E0' },
          backgroundColor: open ? '#009f7514' : 'transparent',
        }}
      >
        <TableCell component="th" scope="row">
          {contributionInfo.customerName}
        </TableCell>
        <TableCell>{contributionInfo.quoteNumber}</TableCell>
        <TableCell>{contributionInfo.description}</TableCell>
        {!isNullOrWhitespace(customField1?.label) && (
          <TableCell>{contributionInfo.customField1}</TableCell>
        )}
        {!isNullOrWhitespace(customField2?.label) && (
          <TableCell>{contributionInfo.customField2}</TableCell>
        )}
        {!isNullOrWhitespace(customField3?.label) && (
          <TableCell>{contributionInfo.customField3}</TableCell>
        )}
        {!isNullOrWhitespace(customField4?.label) && (
          <TableCell>{contributionInfo.customField4}</TableCell>
        )}
        <TableCell align="right">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <ProgramDetails contributionInfo={contributionInfo} />
              <FlexBox sx={{ flexDirection: 'column' }}>
                <Typography
                  variant="h5"
                  fontWeight={700}
                  mb={2}
                  sx={(theme) => ({
                    borderTop: `1px solid ${theme.palette.common.grey.light}`,
                    paddingTop: '0.5rem',
                  })}
                >
                  Measurable social impact
                </Typography>
                <CommonPaper>
                  {(!contributionInfo?.program?.outputs?.every((impact: any) => (impact.value === 0 || impact.value === null))) ? <MeasurableSocialImpact contributionInfo={contributionInfo} /> : <p>No impacts found</p>}
                </CommonPaper>
              </FlexBox>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export function UpdateIncentiveStatus() {
  const state = useAppSelector((x) => x.updateContributionStatus);
  const accountState = useAppSelector((x) => x.app.account);
  const gridState = useAppSelector((x) => x.commonContributions.grid);
  const { loadingUserData } = useInstance();

  const dispatch = useAppDispatch();
  const {
    loading,
    proposedStatus,
    id,
    action,
    comments,
    incentives,
    transactionCompleted,
    updatingIncentives,
  } = state;

  const paymentTypes = incentives && incentives.length > 0 ? incentives[0].paymentMethods : [];

  const { incentiveFields, stripe } = { ...accountState };
  const { customField1, customField2, customField3, customField4 } = {
    ...incentiveFields,
  };
  const enabledAutopay = !!stripe?.autopay?.paymentMethodId;
  const [paymentType, setPaymentType] = useState<string>('');

  useEffect(() => {
    if (incentives && incentives.length > 0 && incentives[0].paymentMethods) {
      if (paymentTypes && paymentTypes.length > 0) {
        if (paymentTypes[0].type === PaymentMethods.Stripe && paymentTypes[0].enabled) {
          setPaymentType(paymentTypes[0].type);
        } else if (paymentTypes[1].type === PaymentMethods.Invoice && paymentTypes[1].enabled) {
          setPaymentType(paymentTypes[1].type);
        }
      }    
    }

  }, [incentives]);


  const updateContributionStatusHandler = () => {
    if (!id) return;
    switch (action) {
      case ContributionStatusLabel.Delete:
        dispatch(deleteContributionThunk({ id, incentiveId: incentives![0]?.incentiveId ?? '' }));
        break;
      case ContributionStatusLabel.Complete:
        if (paymentType === PaymentMethods.Invoice)
          dispatch(postPaymentMethodThunk({ id, incentiveId: incentives![0]?.incentiveId ?? '', proposedStatus, comments }));
        else 
        dispatch(postContributionCheckoutSessionThunk());
        break;
      case ContributionStatusLabel.share:
        dispatch(postContributionShareThunk([id]));
        break;
        case ContributionStatusLabel.MarkAsPaid:
          dispatch(postContributionStatusThunk(id));
          dispatch(
            setUpdateContributionStatusRequest({
              id,
              proposedStatus,
              action,
              showSuccessModal: true,
            }),
          );
          break;
      default:
        dispatch(patchUpdateContributionStatusThunk(id));
        dispatch(
          setUpdateContributionStatusRequest({
            id,
            proposedStatus,
            action,
            showSuccessModal: true,
          }),
        );
    }
  };

  const updateMultipleContributionStatusHandler = () => {
    if (!incentives || incentives.length === 0) return;
    if (action === ContributionStatusLabel.share) {
      const shareIds = incentives.map((item: any) => item.id ?? '-1');
      dispatch(postContributionShareThunk(shareIds!));
      return;
    }
    if (paymentType === PaymentMethods.Invoice) {
      dispatch(setCompletingGives());
      dispatch(hideDialogCloseIcon(Dialogs.UpdateContributionStatus));
    } else dispatch(postContributionCheckoutSessionThunk());
  };

  useEffect(() => {
    let ids = [];
    if (id) {
      ids.push(id);
    }
    if (!id) {
      ids =
        gridState.selectionModel && gridState.selectionModel.length > 0
          ? gridState.selectionModel
          : [];
    }
    dispatch(getMultipleContributionsThunk({ ids }));
  }, [dispatch, id]);

  useEffect(
    () => () => {
      dispatch(clearUpdateContributionStatusState({}));
    },
    [dispatch],
  );
  const [isUserInRole] = useAuth();

  const givesSummary = incentives && (
    <Box sx={{ maxHeight: 350, overflowY: 'auto' }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Customer Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Quote #</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Oppurtunity Name</TableCell>
              {!isNullOrWhitespace(customField1?.label) && (
                <TableCell sx={{ fontWeight: 'bold' }}>{customField1?.label}</TableCell>
              )}
              {!isNullOrWhitespace(customField2?.label) && (
                <TableCell sx={{ fontWeight: 'bold' }}>{customField2?.label}</TableCell>
              )}
              {!isNullOrWhitespace(customField3?.label) && (
                <TableCell sx={{ fontWeight: 'bold' }}>{customField3?.label}</TableCell>
              )}
              {!isNullOrWhitespace(customField4?.label) && (
                <TableCell sx={{ fontWeight: 'bold' }}>{customField4?.label}</TableCell>
              )}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {incentives.map((item) => (
              <SummaryRow key={item.id} contributionInfo={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const enableAutopayFragment = (
    <RoleRequired roles={[UserRole.Finance]}>
      <FlexBox sx={{ marginTop: 2, flexDirection: 'column', gap: 2 }}>
        <Typography variant="h5" fontWeight={700}>
          Enable Autopay
        </Typography>
        <Typography>
          Autopay allows you to put your preferred payment method on file and complete social impact
          contributions with just one click. Would you like to enable autopay for a quicker checkout
          process?
        </Typography>
        <FlexBox flexDirection="row-reverse">
          <div>
            <Button
              variant="contained"
              endIcon={<OpenInNew />}
              onClick={() => openAccountSettingsOnTab(AccountSettingsTabs.Payment)}
            >
              Enable Autopay
            </Button>
          </div>
        </FlexBox>
      </FlexBox>
    </RoleRequired>
  );
  if (!proposedStatus) return <p>Invalid status</p>;

  if (updatingIncentives) return <UpdateContributionsProgressBar />;

  const getLabel = (type: string) => {
    let label = '';
    switch (type) {
      case PaymentMethods.Stripe:
        label = PaymentMethodLabels.Stripe;
        break;
      default:
        label = PaymentMethodLabels.Invoice;
    }
    return label;
  };

  const getInfoText = (type: string) => {
    let info = '';
    switch (type) {
      case PaymentMethods.Stripe:
        info = PaymentMethodLabelInfo.Stripe;
        break;
      default:
        info = PaymentMethodLabelInfo.Invoice;
    }
    return info;
  };

const title =
  action !== 'Mark As Paid'
    ? `Are you sure you want to ${action?.toLowerCase()} ${id ? 'this Impact' : 'these Impacts'}?`
    : 'Mark as Paid Confirmation';
  return (
    <FormDialog
      type={Dialogs.UpdateContributionStatus}
      title={title}
      loading={loading || loadingUserData}
      closeFlag={transactionCompleted}
      ExtraBellowButtonsComponent={
        AUTOPAY_ENABLED && !enabledAutopay && action === ContributionStatusLabel.Complete
          ? enableAutopayFragment
          : undefined
      }
      Buttons={
        <Button
          variant="contained"
          size="large"
          sx={{
            color: `${action === ContributionStatusLabel.MarkAsPaid && 'white'}`,
            background: `${action === ContributionStatusLabel.MarkAsPaid && '#0288d1'}`,
          }}
          onClick={id ? updateContributionStatusHandler : updateMultipleContributionStatusHandler}
          disabled={
            (isNullOrWhitespace(comments) &&
              ORDERS_NEGATIVE_ACTIONS.includes(proposedStatus as ContributionStatus)) ||
            !isUserInRole(ENABLE_COMPLETE_ABANDON) ||
            (action === ContributionStatusLabel.Complete &&
              paymentType === PaymentMethods.Invoice &&
              !comments) || (action === 'Complete' && paymentType === '')
          }
          endIcon={
            action === ContributionStatusLabel.Complete && paymentType === PaymentMethods.Stripe ? (
              <OpenInNew />
            ) : null
          }
        >
          {action}
        </Button>
      }
    >
      {action !== 'Mark As Paid' ? (
        <>
          <Typography variant="h5" fontWeight={700} mb={2}>
            Summary
          </Typography>
          <FlexBox>
            {action === ContributionStatusLabel.Complete &&
              paymentTypes &&
              paymentTypes?.length > 0 && (
                <FormControl>
                  <FormLabel sx={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                    Payment Menthod
                  </FormLabel>
                  <RadioGroup
                    row
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                  >
                    {_.map(paymentTypes, (payment) => (
                      <FlexBox alignItems="center" marginRight="20px">
                        <FormControlLabel
                          sx={{ marginRight: '4px' }}
                          value={payment.type}
                          control={<Radio />}
                          label={getLabel(payment.type)}
                          disabled={!payment.enabled}
                        />
                        <Tooltip
                          title={getInfoText(payment.type)}
                          placement="top"
                          arrow
                          TransitionComponent={Zoom}
                        >
                          <Info fontSize="small" sx={{ cursor: 'pointer', color: 'gray' }} />
                        </Tooltip>
                      </FlexBox>
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
          </FlexBox>
          <TextField
            label={`Comments ${
              (isNullOrWhitespace(comments) &&
                ORDERS_NEGATIVE_ACTIONS.includes(proposedStatus as ContributionStatus)) ||
              !isUserInRole(ENABLE_COMPLETE_ABANDON) ||
              (action === ContributionStatusLabel.Complete &&
                paymentType === PaymentMethods.Invoice &&
                !comments)
                ? '*'
                : ''
            }`}
            sx={{ mb: 2 }}
            multiline
            maxRows={3}
            fullWidth
            value={comments}
            onChange={(e) => {
              dispatch(setComments(e.target.value));
            }}
          />
          {givesSummary}
        </>
      ) : (
        <Typography variant="h5" mb={2}>
          Are you sure you want to {action?.toLowerCase()} &quot;
          {incentives && incentives[0]?.description}&quot;&nbsp;Contribution Proposal?
        </Typography>
      )}
    </FormDialog>
  );
}
