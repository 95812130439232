import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getSioUserList,
  getSio,
  getSios,
  postSio,
  getSioByPassword,
  postSioUser,
  updateSioUser,
  getSioStatus,
  deleteSio,
  patchSio,
  addNewSioUser,
  updateUser,
  getUserById,
  getSiosByStatus,
  getMpaDetails,
  updateMpa,
  updateSioPost,
  downloadFile,
} from '../../api/portal/sioRequest';
import { addCancelTokenEvent } from '../../api';
import { GridResponse, GridModel, GridRequest } from '../../shared/interfaces';
import {
  SioUserResponse,
  SioUSerRequest,
  SIO,
  PostSioUSerRequest,
  PostSioUSerSubmitRequest,
  StatusCount,
  PatchSIORequest,
  AddSioUserRequest,
  UpdateSioUserRequest,
  PostSioFormValues,
} from '../../shared/interfaces/sio/sio';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';

interface SiosGridRequest extends GridRequest {
  status: string | null | undefined;
  publicOrganizationName?: string;
  applicationFormName?: string;
  partnership?: string;
}

interface SioUserGridRequest extends SioUSerRequest {
  status: string | null | undefined;
}
interface SioState {
  grid: GridModel<SIO>;
  sio: SIO;
  sioUser: SioUserResponse;
  sioId: string;
  updateStatusSuccess: boolean;
  status: string | null | undefined;
  loading: boolean;
  statusCount: [StatusCount];
  allData: Array<Object>;
  userGrid: GridModel<SioUserResponse>;
  mpa: any;
}
interface SioLoginRequest {
  id: string;
}
const initialState: SioState = {
  grid: {
    name: 'sios',
    data: { totalCount: 0, results: [] },
    page: 0,
    defaultSort: { field: 'lastUpdated', direction: 'desc' },
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
  },
  mpa: {
    info: {
      orgDescription: '',
      name: '',
      title: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
    },
    review: {},
    status: '',
    effectiveDate: '',
    expirationDate: '',
    percentComplete: 0,
    legalNotes: '',
  },
  sio: {
    id: '',
    createdBy: '',
    createdAt: '',
    lastUpdatedBy: '',
    lastUpdated: '',
    applicationFormName: '',
    siAdmin: '',
    editing: false,
    status: '',
    asset: {
      videoFallback: {
        name: '',
        url: '',
      },
      deliverableRequirements: '',
      vimeoId: '',
    },
    program: [
      {
        _id: '',
        lastUpdated: '',
        general: {
          programName: {
            platformProgramName: '',
          },
        },
      },
    ],
    sioUserEmailList: [],
    percentComplete: 0,
    password: '',
    siAdminName: '',
    siAdmin_firstname: '',
    siAdmin_lastname: '',
    publicOrganizationName: '',
    progress: {
      general: {
        total: 0,
        complete: 0,
      },
      overviewAndMission: {
        total: 0,
        complete: 0,
      },
      operationalInformation: {
        total: 0,
        complete: 0,
      },
    },
    blocklist: {
      companies: [],
      industries: [],
    },
    general: {
      name: {
        legalOrganizationName: '',
        publicOrganizationName: '',
      },
      social: {
        facebook: '',
        instagram: '',
        linkedIn: '',
        twitter: '',
        websiteURL: '',
      },
      contact: {
        professionalTitle: '',
        name: '',
        phone: '',
        countryCode: '',
        email: [],
      },
      location: {
        generalLocation: '',
        specificLocation: '',
        charityNumber: '',
        otherId: '',
        taxId: '',
        w9: {
          name: '',
          url: '',
        },
      },
      missionAgreement: false,
    },
    overviewAndMission: {
      causeAreas: {
        selected: [],
      },
      lifetimeOutputs: [
        {
          key: '',
          output: '',
          quantity: 0,
        },
      ],
      researchAndEvaluationFile: {
        name: '',
        url: '',
      },
      historyDescription: '',
      problemDescription: '',
      initiativesDescription: '',
      programLocations: '',
      researchAndEvaluation: '',
    },
    operationalInformation: {
      staff: {
        fullTime: 0,
        partTime: 0,
        volunteers: 0,
      },
      financialStatement: {
        website: '',
        file: {
          name: '',
          url: '',
        },
      },
      partnershipsDescription: '',
      yearlyBudget: 0,
      yearlyBudgetCurrency: '',
      supportersAndPartners: '',
    },
  },
  sioUser: {
    id: '',
    createdBy: '',
    createdAt: '',
    lastUpdatedBy: '',
    lastUpdated: '',
    sioId: '',
    userType: '',
    username: '',
    firstName: '',
    lastName: '',
    active: true,
    roles: [],
    last_login: '',
  },
  sioId: '',
  updateStatusSuccess: false,
  status: null,
  statusCount: [
    {
      status_list: { _id: '', count: 0 },
    },
  ],
  loading: false,
  allData: [],
  userGrid: {
    name: 'sioUser',
    data: { totalCount: 0, results: [] },
    page: 0,
    defaultSort: { field: 'lastUpdated', direction: 'desc' },
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
  },
};

export const downloadFileThunk = createAsyncThunk(
  'sios/downloadFile',
  async (request: any, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await downloadFile(request);
    return data;
  },
);

export const fetchSio = createAsyncThunk('sios/getSioThunk', async (id: string, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getSio(id);
  return data;
});
export const fetchSioUser = createAsyncThunk(
  'sios/getSioByPasswordThunk',
  async ({ id }: SioLoginRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getSioByPassword(id);
    return data;
  },
);

export const fetchSios = createAsyncThunk(
  'sios/fetchSios',
  async (request: SiosGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getSios(request);
    return data;
  },
);

export const addSio = createAsyncThunk('sios/addSio', async (sio: PostSioFormValues) => {
  const response = await postSio(sio);
  return response.data;
});
export const updateSioUserThunk = createAsyncThunk(
  'sios/updateSioUser',
  async ({ sio, id }: { sio: PostSioUSerRequest; id: string }) => {
    const response = await updateSioUser(sio, id);
    return response.data;
  },
);
export const postSioUserThunk = createAsyncThunk(
  'sios/postSioUser',
  async ({ sio, id }: { sio: PostSioUSerSubmitRequest; id: string }) => {
    const response = await postSioUser(sio, id);
    return response.data;
  },
);

export const fetchSioStatus = createAsyncThunk(
  'sios/getSioStatusThunk',
  async (unused, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getSioStatus();
    return data;
  },
);

export const updateSio = createAsyncThunk(
  'sios/updateSio',
  async ({ id, sio }: { id: string; sio: PatchSIORequest }) => {
    const response = await patchSio(id, sio);
    return response.data;
  },
);

export const updatePostSio = createAsyncThunk(
  'sios/updatePostSio',
  async ({ id, sio }: { id: string; sio: PatchSIORequest }) => {
    const response = await updateSioPost(id, sio);
    return response.data;
  },
);

export const removeSio = createAsyncThunk('sios/removeSio', async (id: string) => {
  const { data } = await deleteSio(id);
  return data;
});

export const addNewSioUserThunk = createAsyncThunk(
  'sioUser/addNewSioUser',
  async (sio: AddSioUserRequest) => {
    const response = await addNewSioUser(sio);
    return response.data;
  },
);

export const getSioUserListThunk = createAsyncThunk(
  'sioUser/userList',
  async (request: SioUserGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getSioUserList(request);
    return data;
  },
);

export const updateUserThunk = createAsyncThunk(
  'sioUser/updateSio',
  async ({ id, sio }: { id: string; sio: UpdateSioUserRequest }) => {
    const response = await updateUser(id, sio);
    return response.data;
  },
);

export const updateMpaThunk = createAsyncThunk(
  'sioMpa/updateMpa',
  async ({ id, mpa }: { id: string; mpa: any }) => {
    const response = await updateMpa(id, mpa);
    return response.data;
  },
);

export const fetchUser = createAsyncThunk('sioUser/getUser', async (id: string, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getUserById(id);
  return data;
});

export const fetchSiosByStatus = createAsyncThunk(
  'sios/fetchSiosByStatus',
  async (
    { request, statusFilter }: { request: SiosGridRequest; statusFilter: string },
    { signal },
  ) => {
    addCancelTokenEvent(signal);
    const { data } = await getSiosByStatus(request, statusFilter);
    return data;
  },
);

export const fetchMpa = createAsyncThunk('sioMpa/getMpa', async (id: string, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getMpaDetails(id);
  return data;
});

export const sioSlice = createSlice({
  name: 'sios',
  initialState,
  reducers: {
    setSiosGridModel: (state, action: PayloadAction<Partial<GridModel<SIO>>>) => {
      _.assign(state.grid, action.payload);
    },
    setSioUserGridModel: (state, action: PayloadAction<Partial<GridModel<SioUserResponse>>>) => {
      _.assign(state.userGrid, action.payload);
    },
    clearSiosState: (state) => {
      state.grid = initialState.grid;
      state.updateStatusSuccess = false;
    },
    clearSiosUpdateSuccess: (state) => {
      state.updateStatusSuccess = false;
    },
    setSiosStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setViewSioId: (state, action: PayloadAction<string>) => {
      state.sioId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSios.fulfilled, (state, action: PayloadAction<GridResponse<SIO>>) => {
        // state.allData = action.payload.results
        state.grid.loadingGrid = false;
        state.grid.data = action.payload;
      })
      .addCase(fetchSios.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchSios.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(fetchSiosByStatus.fulfilled, (state, action: PayloadAction<GridResponse<SIO>>) => {
        // state.allData = action.payload.results
        state.grid.loadingGrid = false;
        state.grid.data = action.payload;
      })
      .addCase(fetchSiosByStatus.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchSiosByStatus.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(addSio.fulfilled, (state, action) => {
        state.sio = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(addSio.rejected, (state, action) => {
        state.grid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(addSio.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(addNewSioUserThunk.fulfilled, (state, action) => {
        state.sioUser = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(addNewSioUserThunk.rejected, (state, action) => {
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(addNewSioUserThunk.pending, (state) => {
        if (!state.userGrid.loadingGrid) state.userGrid.loadingGrid = true;
      })
      .addCase(updateSioUserThunk.fulfilled, (state, action) => {
        state.sio = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(updateSioUserThunk.rejected, (state, action) => {
        state.grid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(updateSioUserThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(postSioUserThunk.fulfilled, (state, action) => {
        state.sio = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(postSioUserThunk.rejected, (state, action) => {
        state.grid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(postSioUserThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(fetchSio.fulfilled, (state, action: PayloadAction<SIO>) => {
        state.grid.loadingGrid = false;
        state.sio = action.payload;
      })
      .addCase(fetchSio.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchSio.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(fetchSioUser.fulfilled, (state, action: PayloadAction<SIO>) => {
        state.grid.loadingGrid = false;
        state.sio = action.payload;
      })
      .addCase(fetchSioUser.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchSioUser.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(updateSio.fulfilled, (state, action) => {
        state.sio = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(updateSio.rejected, (state, action) => {
        state.grid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(updateSio.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(updatePostSio.fulfilled, (state, action) => {
        state.sio = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(updatePostSio.rejected, (state, action) => {
        state.grid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(updatePostSio.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(removeSio.fulfilled, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(removeSio.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(removeSio.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(fetchSioStatus.fulfilled, (state, action) => {
        state.statusCount = action.payload;
      })
      .addCase(fetchSioStatus.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchSioStatus.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(
        getSioUserListThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<SioUserResponse>>) => {
          // state.allData = action.payload.results
          state.userGrid.loadingGrid = false;
          state.userGrid.data = action.payload;
        },
      )
      .addCase(getSioUserListThunk.rejected, (state) => {
        state.userGrid.loadingGrid = false;
      })
      .addCase(getSioUserListThunk.pending, (state) => {
        if (!state.userGrid.loadingGrid) state.userGrid.loadingGrid = true;
      })
      .addCase(updateUserThunk.fulfilled, (state, action) => {
        state.sioUser = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(updateUserThunk.rejected, (state, action) => {
        state.userGrid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(updateUserThunk.pending, (state) => {
        if (!state.userGrid.loadingGrid) state.userGrid.loadingGrid = true;
      })
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<SioUserResponse>) => {
        state.userGrid.loadingGrid = false;
        state.sioUser = action.payload;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.userGrid.loadingGrid = false;
      })
      .addCase(fetchUser.pending, (state) => {
        if (!state.userGrid.loadingGrid) state.userGrid.loadingGrid = true;
      })
      .addCase(fetchMpa.fulfilled, (state, action) => {
        state.mpa = action.payload;
      })
      .addCase(fetchMpa.rejected, (state) => {
        state.mpa = initialState.mpa;
      })
      .addCase(updateMpaThunk.fulfilled, (state, action) => {
        state.mpa = action.payload.mpa;
      })
      .addCase(updateMpaThunk.rejected, (state) => {
        state.mpa = initialState.mpa;
      });
  },
});

export const {
  setSiosGridModel,
  setSioUserGridModel,
  clearSiosState,
  setSiosStatus,
  clearSiosUpdateSuccess,
  setViewSioId,
} = sioSlice.actions;

export const sioReducer = sioSlice.reducer;
