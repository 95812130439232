import React, { useEffect, useState, ChangeEvent } from 'react'; // useState
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'; // Typography, Button
import {
  Box,
  Grid,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Divider,
  Tooltip,
  Card,
  CardContent,
  Typography,
  CardHeader,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import { fetchSioUser, updateSioUserThunk } from './sioSlice';
import './formFilling.scss';
import { TopBar } from './SioFormFillingDashboard';
import { uploadFile } from '../../api/portal/sioRequest';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface LifetimeOutput {
  key: string;
  quantity: number | null;
  output: string;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#68839A',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#FFF',
  },
}));
const menuOptions = [
  {
    label: 'Arts and Culture',
    value: 'Arts and Culture',
  },
  {
    label: 'Disaster Response, Relief and Recovery',
    value: 'Disaster Response, Relief and Recovery',
  },
  {
    label: 'Economic Empowerment and Workforce Development',
    value: 'Economic Empowerment and Workforce Development',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Environment',
    value: 'Environment',
  },
  {
    label: 'Equity, Human rights, and Civic Engagement',
    value: 'Equity, Human rights, and Civic Engagement',
  },
  {
    label: 'Food and Hunger',
    value: 'Food and Hunger',
  },
  {
    label: 'Health and Wellness',
    value: 'Health and Wellness',
  },
  {
    label: 'Housing and Homelessness',
    value: 'Housing and Homelessness',
  },
  {
    label: 'Restorative Justice or Criminal Justice',
    value: 'Restorative Justice or Criminal Justice',
  },
];

const lifetimeOutputForm: LifetimeOutput[] = [{ key: '', quantity: null, output: '' }];
const OverviewQuestions = () => {
  const sioState = useAppSelector((state) => state.sios);
  const { sio } = sioState;
  const { id, password } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const initialFormValue = {
    legalName: sio.general?.name?.legalOrganizationName,
    publicName: sio.general?.name?.publicOrganizationName,
    websiteURL: sio.general?.social?.websiteURL,
    facebook: sio.general?.social?.facebook,
    linkedIn: sio.general?.social?.linkedIn,
    instagram: sio.general?.social?.instagram,
    twitter: sio.general?.social?.twitter,
    phoneNumber: sio.general?.contact?.phone,
    firstLastName: sio.general?.contact?.name,
    professionalTitle: sio.general?.contact?.professionalTitle,
    email: sio.general?.contact?.email,
    professionalPhoneNo: sio.general?.contact?.phone,
    registeredLocation: sio.general?.location?.specificLocation,
    committed: sio.general?.missionAgreement,
    missionHistory: sio.overviewAndMission?.historyDescription,
    problem: sio.overviewAndMission?.problemDescription,
    causeAreas: { selected: sio.overviewAndMission?.causeAreas?.selected },
    programInitiativesPriorites: sio.overviewAndMission?.initiativesDescription,
    programOperate: sio.overviewAndMission?.programLocations,
    researchEvaluation: sio.overviewAndMission?.researchAndEvaluation,
    fileUpload: sio.overviewAndMission?.researchAndEvaluationFile?.name,
    lifetimeOutputs: lifetimeOutputForm,
    fullTimeStaff: sio.operationalInformation?.staff.fullTime,
    partTimeStaff: sio.operationalInformation?.staff.partTime,
    volunteers: sio.operationalInformation?.staff.volunteers,
    formalInformalRelationship: sio.operationalInformation?.partnershipsDescription,
    budget: sio.operationalInformation?.yearlyBudget,
    financialStatement: sio.operationalInformation?.financialStatement?.file?.name,
    supportersPartners: sio.operationalInformation?.supportersAndPartners,
    reviewName: '',
    reviewEmail: '',
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [files, setFiles] = useState<File>();

  // const savePageNumber = () => {
  //     if([0,1].includes(sio.progress.operationalInformation.complete)){
  //         setPageNumber(1)
  //     }else{
  //         setPageNumber(sio.progress.operationalInformation.complete)
  //     }
  // }

  useEffect(() => {
    if (id && password && sio) {
      dispatch(fetchSioUser({ id })).then((sioData: any) => {
        setFormValues({
          legalName: sioData.payload.general?.name?.legalOrganizationName ?? '',
          publicName: sioData.payload.general?.name?.publicOrganizationName ?? '',
          websiteURL: sioData.payload.general?.social?.websiteURL ?? '',
          facebook: sioData.payload.general?.social?.facebook ?? '',
          linkedIn: sioData.payload.general?.social?.linkedIn ?? '',
          instagram: sioData.payload.general?.social?.instagram ?? '',
          twitter: sioData.payload.general?.social?.twitter ?? '',
          phoneNumber: sioData.payload.general?.contact?.phone ?? '',
          firstLastName: sioData.payload.general?.contact?.name ?? '',
          professionalTitle: sioData.payload.general?.contact?.professionalTitle ?? '',
          email: sioData.payload.general?.contact?.email ?? '',
          professionalPhoneNo: sioData.payload.general?.contact?.phone ?? '',
          registeredLocation: sioData.payload.general?.location?.specificLocation ?? '',
          committed: sioData.payload.general?.missionAgreement,
          missionHistory: sioData.payload.overviewAndMission?.historyDescription,
          problem: sioData.payload.overviewAndMission?.problemDescription,
          causeAreas: { selected: sioData.payload.overviewAndMission?.causeAreas?.selected },
          programInitiativesPriorites: sioData.payload.overviewAndMission?.initiativesDescription,
          programOperate: sioData.payload.overviewAndMission?.programLocations,
          researchEvaluation: sioData.payload.overviewAndMission?.researchAndEvaluation,
          fileUpload: sioData.payload.overviewAndMission?.researchAndEvaluationFile?.name,
          lifetimeOutputs:
            sioData.payload.overviewAndMission?.lifetimeOutputs ?? lifetimeOutputForm,
          fullTimeStaff: sioData.payload.operationalInformation?.staff.fullTime,
          partTimeStaff: sioData.payload.operationalInformation?.staff.partTime,
          volunteers: sioData.payload.operationalInformation?.staff.volunteers,
          formalInformalRelationship:
            sioData.payload.operationalInformation?.partnershipsDescription,
          budget: sioData.payload.operationalInformation?.yearlyBudget,
          financialStatement: sioData.payload.operationalInformation?.financialStatement?.file,
          supportersPartners: sioData.payload.operationalInformation?.supportersAndPartners,
          reviewName: '',
          reviewEmail: '',
        });
        // savePageNumber()
      });
    }
  }, [id, password]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const addLifetimeOutput = () => {
    setFormValues((prevValues) => {
      const newOutput = { key: `output-${Date.now()}`, quantity: null, output: '' };

      if (prevValues.lifetimeOutputs.length < 3) {
        return {
          ...prevValues,
          lifetimeOutputs: [...prevValues.lifetimeOutputs, newOutput],
        };
      }

      return prevValues;
    });
  };

  const removeLifetimeOutput = (indexToRemove: number) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      lifetimeOutputs: prevValues.lifetimeOutputs.filter((_, index) => index !== indexToRemove),
    }));
  };

  const getfield = () => {
    let field = '';
    let value;
    switch (pageNumber) {
      case 1:
        field = 'historyDescription';
        value = formValues.missionHistory;
        break;
      case 2:
        field = 'problemDescription';
        value = formValues.problem;
        break;
      case 3:
        field = 'causeAreas';
        value = { selected: formValues.causeAreas.selected };
        break;
      case 4:
        field = 'initiativesDescription';
        value = formValues.programInitiativesPriorites;
        break;
      case 5:
        field = 'programLocations';
        value = formValues.programOperate;
        break;
      case 6:
        field = 'researchAndEvaluation';
        value = formValues.researchEvaluation;
        break;
      case 7:
        field = 'lifetimeOutputs';
        value = formValues.lifetimeOutputs.map((output) => ({
          ...output,
          quantity: output.quantity === null ? 0 : output.quantity,
        }));
        break;
      default:
        break;
    }
    return { field, value };
  };

  const updateSioDetails = async (fields: any) => {
    const { field, value } = fields;
    const payload = {
      overviewAndMission: {
        [field]: value,
        researchAndEvaluationFile: {
          name: formValues.fileUpload,
        },
      },
    };

    if (!id || !password) {
      return false;
    }

    if (id && password) {
      try {
        const result = await dispatch(updateSioUserThunk({ sio: payload, id }));
        return result.meta.requestStatus;
      } catch (error) {
        console.error('Update failed with exception:', error);
        return false;
      }
    }

    return false;
  };

  const selectOptions = menuOptions.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));

  const handleChangeSelect = (event: SelectChangeEvent<typeof formValues.causeAreas.selected>) => {
    const { value } = event.target;
    setFormValues({
      ...formValues,
      causeAreas: {
        selected: typeof value === 'string' ? value.split(',') : value,
      },
    });
  };

  const uploadFileData = async () => {
    const formData = new FormData();
    if (!files) return;
    formData.append('file', files);
    formData.append('path', 'overviewAndMission.researchAndEvaluationFile');
    if (id && password) {
      const response = await uploadFile(id, formData);
      if (response.data) {
        setFormValues({
          ...formValues,
          fileUpload: response.data.overviewAndMission.researchAndEvaluationFile.name,
        });
        toast.success('Upload Successfull');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const handleNextbutton = async () => {
    try {
      await uploadFileData();

      const updateResult = await updateSioDetails(getfield());

      switch (updateResult) {
        case 'fulfilled':
          toast.success('Successfully Submitted Form to ScaleWith');
          if (pageNumber < 7) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          } else {
            navigate(`/sio-form/${id}/${password}`);
          }
          break;
        case 'rejected':
          toast.error('Failed to update values');
          break;
        default:
          toast.error(updateResult || 'An unexpected error occurred');
      }
    } catch (error) {
      toast.error('Error while trying to go to the next page.');
    }
  };

  const handlePreviousbutton = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleSaveExit = async () => {
    try {
      const updateFields = getfield();
      const response = await updateSioDetails(updateFields);

      switch (response) {
        case 'fulfilled':
          toast.success('Successfully Submitted Form to ScaleWith');
          navigate(`/sio-form/${id}/${password}`);
          break;
        case 'rejected':
          toast.error('Failed to save values');
          break;
        default:
          toast.error(response || 'An unexpected error occurred');
      }
    } catch (error) {
      toast.error('An error occurred while saving. Please try again later.');
    }
  };

  const handleLifetimeOutputChange =
    (field: string, index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      let newValue: any = value;

      if (field === 'quantity') {
        let numericValue: number | null = value === '' ? 0 : Number(value);
        numericValue = Number.isNaN(numericValue) ? null : numericValue;
        newValue = numericValue;
      }

      const newLifetimeOutputs = [...formValues.lifetimeOutputs];
      newLifetimeOutputs[index] = { ...newLifetimeOutputs[index], [field]: newValue };

      setFormValues((prevValues) => ({
        ...prevValues,
        lifetimeOutputs: newLifetimeOutputs,
      }));
    };
  const disabledButtons = () => {
    let disable;
    if (pageNumber === 3) {
      if (!formValues.causeAreas.selected) disable = true;
    }
    return disable;
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setFiles(fileList[0]);
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  // *** Start Questions ***
  const question1 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 5 }} variant="h3">
          Let&apos;s get started!
        </Typography>
        <Typography sx={commonTextStyle.questionStyle}>Question 1 of 7</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Briefly describe your organization&apos;s history and mission
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ ...commonTextStyle.labelStyle, mb: 1 }}>
          History and Mission{' '}
          <Tooltip title="Enter History and mission">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="missionHistory"
          placeholder="Enter organizations history and mission"
          fullWidth
          value={formValues.missionHistory}
          onChange={handleChange}
          multiline
          rows={4}
          margin="none"
          variant="outlined"
          sx={commonTextStyle.textAreaStyle}
        />
      </Grid>
    </Grid>
  );

  const question2 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 2 of 7</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Briefly describe the need or problem your organization works to address
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ ...commonTextStyle.labelStyle, mb: 1 }}>
          Need or Problem{' '}
          <Tooltip title="Enter need or problem">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="problem"
          placeholder="Enter organizations need or problem"
          fullWidth
          value={formValues.problem}
          onChange={handleChange}
          multiline
          rows={4}
          margin="none"
          variant="outlined"
          sx={commonTextStyle.textAreaStyle}
        />
      </Grid>
    </Grid>
  );

  const question3 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 3 of 7</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Which cause area best matches your organization&apos;s mission?
        </Typography>
      </Grid>
      <Grid item className="gridItem">
        <Typography className="label">
          Please select at least one, and up to two from the list.{' '}
          <Tooltip title="Select The cause area best matches your organization’s mission">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <FormControl fullWidth margin="normal">
          <Select
            labelId="causeArea"
            placeholder="Select causearea"
            variant="outlined"
            name="selected"
            value={formValues.causeAreas.selected ? formValues.causeAreas.selected.slice(0, 2) : []}
            onChange={handleChangeSelect}
            sx={{
              ...commonTextStyle.textFieldStyle,
              '& .MuiSelect-select': {
                fontSize: fonts.fontSize14,
                paddingTop: '26px',
                height: '100%',
              },
            }}
            multiple
          >
            <MenuItem selected value="" disabled>
              <em>ScaleWith Cause Area</em>
            </MenuItem>
            {selectOptions}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  const question4 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 4 of 7</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Briefly describe your organization&apos;s programs, initiatives, and current priorities?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ ...commonTextStyle.labelStyle, mb: 1 }}>
          Programs, Initiatives and Current Priorities{' '}
          <Tooltip title="Enter Programs, Initiatives and Current Priorities">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="programInitiativesPriorites"
          placeholder="Enter you organizations programs, initiatives and current priorities"
          fullWidth
          value={formValues.programInitiativesPriorites}
          onChange={handleChange}
          multiline
          rows={4}
          margin="none"
          variant="outlined"
          sx={commonTextStyle.textAreaStyle}
        />
      </Grid>
    </Grid>
  );

  const question5 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 5 of 7</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Where do your programs operate?
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={{ ...commonTextStyle.labelStyle, mb: 1 }}>
          Please include information like country, state, and city as applicable{' '}
          <Tooltip title="Enter Program Location">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="programOperate"
          placeholder="Enter program location"
          fullWidth
          value={formValues.programOperate}
          onChange={handleChange}
          margin="normal"
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
    </Grid>
  );

  const question6 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 6 of 7</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Briefly describe the research and evaluation used to support your program design
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ ...commonTextStyle.labelStyle, mb: 1 }}>
          Research and Evaluation{' '}
          <Tooltip title="Enter reseach and evaluation">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="researchEvaluation"
          placeholder="Enter organizations research and evaluation"
          fullWidth
          value={formValues.researchEvaluation}
          onChange={handleChange}
          multiline
          rows={4}
          margin="none"
          variant="outlined"
          sx={commonTextStyle.textAreaStyle}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Please upload the research and evaluation that informs your program design
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ ...commonTextStyle.labelStyle, mb: 1 }}>
          Research and Evaluation file{' '}
          <Tooltip title="Upload file for research and evaluation">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="fileUpload"
          placeholder="Upload organizations research and evaluation file"
          fullWidth
          type="file"
          // value={formValues.fileUpload}
          onChange={handleUpload}
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
      {formValues.fileUpload && (
        <Grid item xs={12}>
          <Typography variant="h5">File Name: {formValues.fileUpload}</Typography>
        </Grid>
      )}
    </Grid>
  );

  //   const question7 = (
  //     <Grid container spacing={4}>
  //       <Grid item xs={12}>
  //         <Typography sx={commonTextStyle.questionStyle}>Question 7 of 7</Typography>
  //         <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
  //           What are some of your organization&apos;s lifetime outputs
  //         </Typography>
  //       </Grid>

  //       <Grid item xs={8}>
  //         {formValues?.lifetimeOutputs?.map((lifetimeOutput: any, index) => (
  //           <Grid container spacing={4} key={lifetimeOutput.key}>
  //             <Grid item xs={12} sm={2} mb={2}>
  //               <Typography sx={commonTextStyle.labelStyle}>
  //                 Quantity {index + 1}
  //                 <Tooltip title="Enter The organization’s lifetime output">
  //                   <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
  //                 </Tooltip>
  //               </Typography>
  //               <TextField
  //                 name="quantity"
  //                 type="number"
  //                 value={lifetimeOutput.quantity}
  //                 onChange={handleLifetimeOutputChange('quantity', index)}
  //                 variant="outlined"
  //                 className="inputTextFiled"
  //                 fullWidth
  //                 sx={commonTextStyle.textFieldStyle}
  //               />
  //             </Grid>
  //             <Grid item xs={12} sm={6} mb={2}>
  //               <Typography sx={commonTextStyle.labelStyle}>
  //                 Output {index + 1} Description
  //                 <Tooltip title="Enter The organization’s lifetime outputs description">
  //                   <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
  //                 </Tooltip>
  //               </Typography>
  //               <TextField
  //                 name="output"
  //                 type="text"
  //                 value={lifetimeOutput.output}
  //                 onChange={handleLifetimeOutputChange('output', index)}
  //                 sx={commonTextStyle.textFieldStyle}
  //                 variant="outlined"
  //                 className="inputTextFiled"
  //                 fullWidth
  //               />
  //             </Grid>
  //           </Grid>
  //         ))}
  //       </Grid>
  //       <Grid xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  //         <Grid item xs={12}>
  //           <Box
  //             sx={{
  //               mb: 3,
  //               padding: '15px 20px',
  //               width: '300px',
  //               background: '#E7F2FF',
  //               borderRadius: '20px',
  //             }}
  //           >
  //             <Typography variant="h5" sx={{ mb: 1 }}>
  //               <InfoIcon fontSize="medium" /> Life Time Outputs (example)
  //             </Typography>
  //             <Typography variant="body1">Quantity: 1500</Typography>
  //             <Typography variant="body1">Output Description: Clothes Donated</Typography>
  //           </Box>
  //         </Grid>
  //       </Grid>
  //     </Grid>
  //   );

  const question7 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 7 of 7</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          What are some of your organization&apos;s lifetime outputs
        </Typography>
      </Grid>

      <Grid item xs={8}>
        {formValues?.lifetimeOutputs?.map((lifetimeOutput: any, index) => (
          <Grid container spacing={4} key={lifetimeOutput.key}>
            {' '}
            {/* Updated key to use index for simplicity */}
            <Grid item xs={12} sm={2} mb={2}>
              <Typography sx={commonTextStyle.labelStyle}>
                Quantity {index + 1}
                <Tooltip title="Enter The organization’s lifetime output">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                name="quantity"
                type="number"
                value={lifetimeOutput.quantity || ''} // Handle null quantity
                onChange={handleLifetimeOutputChange('quantity', index)}
                variant="outlined"
                className="inputTextFiled"
                fullWidth
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <Typography sx={commonTextStyle.labelStyle}>
                Output {index + 1} Description
                <Tooltip title="Enter The organization’s lifetime outputs description">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                name="output"
                type="text"
                value={lifetimeOutput.output}
                onChange={handleLifetimeOutputChange('output', index)}
                sx={commonTextStyle.textFieldStyle}
                variant="outlined"
                className="inputTextFiled"
                fullWidth
              />
            </Grid>
            {formValues.lifetimeOutputs.length > 1 && (
              <Grid item xs={12} sm={4} sx={{ display: 'flex' }}>
                <Button onClick={() => removeLifetimeOutput(index)} sx={{ color: '#249ACB' }}>
                  Remove
                </Button>
              </Grid>
            )}
          </Grid>
        ))}
        {formValues.lifetimeOutputs.length < 3 && (
          <Button
            onClick={addLifetimeOutput}
            variant="contained"
            sx={{
              backgroundColor: '#249ACB',
              ':hover': { color: '#249ACB', backgroundColor: 'white' },
            }}
          >
            Add Another Output
          </Button>
        )}
      </Grid>
      <Grid xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid item xs={12}>
          <Box
            sx={{
              mb: 3,
              padding: '15px 20px',
              width: '300px',
              background: '#E7F2FF',
              borderRadius: '20px',
            }}
          >
            <Typography variant="h5" sx={{ mb: 1 }}>
              <InfoIcon fontSize="medium" /> Life Time Outputs (example)
            </Typography>
            <Typography variant="body1">Quantity: 1500</Typography>
            <Typography variant="body1">Output Description: Clothes Donated</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  // *** End Questions ***

  return (
    <section className="bgLight">
      <TopBar />
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardHeader
              sx={{
                padding: '20px 80px',
                background: colors.backgroundColorGradient,
                borderRadius: '20px 20px 0px 0px',
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize18,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Section 2
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize24,
                        fontWeight: fonts.fontWeight700,
                        color: colors.secondary,
                      }}
                    >
                      Overview and Mission Information
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        fontSize: fonts.fontSize18,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                        textAlign: 'right',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: fonts.fontSize18,
                          fontWeight: fonts.fontWeight600,
                          color: colors.secondary,
                        }}
                      >
                        {pageNumber === 1 ? 0 : (pageNumber - 1) * 15}% complete
                      </Typography>
                    </Grid>
                    <BorderLinearProgress
                      variant="determinate"
                      value={pageNumber === 1 ? 1 : (pageNumber - 1) * 15}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent sx={{ minHeight: '70vh', padding: '40px 80px' }}>
              {pageNumber === 1 && question1}
              {pageNumber === 2 && question2}
              {pageNumber === 3 && question3}
              {pageNumber === 4 && question4}
              {pageNumber === 5 && question5}
              {pageNumber === 6 && question6}
              {pageNumber === 7 && question7}
              <Grid item xs={12} textAlign="right">
                {/* className={[1,6].includes(pageNumber) ? "":'bottomFix'} */}
                <Divider sx={{ my: 3 }} orientation="horizontal" flexItem />
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  disabled={disabledButtons()}
                  onClick={handleSaveExit}
                  variant="outlined"
                  size="large"
                >
                  Save and Exit
                </Button>
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  onClick={handlePreviousbutton}
                  variant="outlined"
                  size="large"
                >
                  Previous
                </Button>
                <Button
                  sx={{
                    ...commonTextStyle.buttonStyle,
                    backgroundColor: colors.primary,
                    color: colors.secondary,
                    mx: 2,
                  }}
                  disabled={disabledButtons()}
                  onClick={handleNextbutton}
                  variant="contained"
                  size="large"
                >
                  Next
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default OverviewQuestions;
