import { Avatar, Box, Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FlexBox } from '../../shared/components';

const steps = [
  [
    'Once our deal is confirmed, ',
    'the designated social impact funding will go to the organization facilitating the impact. ',
  ],
  [
    'ScaleWith handles all of the social impact management',
    ' and works with the social impact organization to ensure timely impact reporting so you can share the story of your impact with relevant stakeholders, including employees, customers, investors, and more. ',
  ],
  [
    'You will make a difference for people and the planet, and deliver value across your company.',
    '',
  ],
];
const StepComponent: FC<{ step: string[]; index: number }> = ({ step, index }) => (
  <FlexBox sx={{ display: 'flex', width: '700px', margin: '0 1rem' }}>
    <Avatar className="step__avatar" sx={{ backgroundColor: '#005778', color: 'white' }}>
      {index + 1}
    </Avatar>
    <Box sx={{ width: '500px' }}>
      <Typography className="emphasizedStep" sx={{ fontWeight: 'bold' }}>
        {`${step[0]} `}
      </Typography>
      <Typography sx={{ display: 'inline' }}>{` ${step[1]}`}</Typography>
    </Box>
  </FlexBox>
);

export const SimpleSteps: FC = () => (
  <Box className="SimpleStep__container">
    <Box className="Steps__container">
      <Typography variant="h5">A Simple Step to Great Impact!</Typography>
      <Typography variant="h3">Here&apos;s how it works</Typography>
      {steps.map((step, i) => (
        <StepComponent key={step[0]} index={i} step={step} />
      ))}
    </Box>
    <Box className="triangeImage__outer_container">
      <img
        alt="triangle"
        crossOrigin=""
        src="https://scalewith-assets.s3.amazonaws.com/value-prop-3%404x.png"
        className="pdf-image triangleImage__container"
        style={{
          marginBottom: '5rem',
          marginTop: '5rem',
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'block',
        }}
      />
      <Box className="infoConatiner">
        <Typography>
          <span style={{ fontWeight: 'bold' }}>For questions or additional information, </span>
        </Typography>
        <div className="mailText">
          <p>please contact</p>
          <Link
            className="mailtoLink"
            style={{
              color: '#249ACB',
              fontSize: '13pt',
              textDecoration: 'none',
              paddingTop: '2px',
              fontWeight: 800,
            }}
            href="mailto:assist@scalewith.com"
          >
            assist@scalewith.com
          </Link>
        </div>
      </Box>
    </Box>
  </Box>
);
