import { Box } from '@mui/material';
import React, { FC } from 'react';
import { FlexBox } from './StyledComponents';

interface Props {
  numerator: string;
  dominator: string;
}

export const FractionComponent: FC<Props> = (props) => {
  const { dominator, numerator } = props;

  return (
    <FlexBox sx={{ flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ textAlign: 'center' }}>{numerator}</Box>
      <Box
        sx={{
          borderTop: 1,
          borderColor: 'black',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {dominator}
      </Box>
    </FlexBox>
  );
};
