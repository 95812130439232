import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Chip, Card, CardContent, Divider, Typography, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { GWCookies } from '../../../shared/utils';
import { useAppSelector, useAppDispatch } from '../../../shared/hooks';
import { fetchMpa } from '../sioSlice';
import { TopBar } from '../SioFormFillingDashboard';
import { CK_AUTH_TOKEN, CK_USER_CLAIMS } from '../../../shared/constants';
import {
  buttonPrimary,
  headingStyles,
  subHeadingStyles,
  mainHeadingStyles,
} from '../../funding/FundingStyles';

const MpaDashBoard = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sioState = useAppSelector((state) => state.sios);
  const { mpa } = sioState;
  const [SIOName, setSIOName] = useState('');

  useEffect(() => {
    if (id) {
      dispatch(fetchMpa(id)).then((mpaData: any) => {
        setSIOName(mpaData.payload.general.name.publicOrganizationName);
      });
    }
  }, [id]);

  const removeCookie = (name: string) => {
    const cookieDomain = '.scalewith.com';
    const pastExpirationDate = new Date(0).toUTCString();
    document.cookie = `${name}=;expires=${pastExpirationDate};domain=${cookieDomain};path=/`;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault();
      const userClaims = GWCookies.get(CK_USER_CLAIMS);
      const isSioUser = JSON.parse(userClaims!).roles.includes('sio_user');
      if (isSioUser) {
        removeCookie(CK_USER_CLAIMS);
        removeCookie(CK_AUTH_TOKEN);
        removeCookie('customParam');
        navigate('/logout');
      }
    });
  }, []);

  const getChip = () => {
    let chip;
    if (mpa?.mpa?.percentComplete < 100 || !mpa?.mpa?.percentComplete) {
      chip = (
        <Chip
          sx={{ bgcolor: '#E9F5FF' }}
          icon={<HourglassEmptyIcon color="primary" />}
          color="primary"
          label={
            mpa?.mpa?.percentComplete === 0 || !mpa?.mpa?.percentComplete
              ? 'Not Started'
              : `In Progress ${mpa?.mpa?.percentComplete}%`
          }
          variant="outlined"
        />
      );
    } else {
      chip = (
        <Chip
          color="success"
          sx={{ bgcolor: '#DBF6DB' }}
          icon={<CheckCircleOutlineIcon color="success" />}
          label="Completed"
          variant="outlined"
        />
      );
    }
    return chip;
  };

  const buttonChange = () => {
    let button;
    if (mpa?.mpa?.percentComplete < 100 || !mpa?.mpa?.percentComplete) {
      button = (
        <Button
          onClick={() => navigate(`/mpa-form/${id}/mpa-information`)}
          variant="outlined"
          // color="primary"
          size="large"
          sx={buttonPrimary}
        >
          Start
        </Button>
      );
    } else {
      button = (
        <Button
          onClick={() => navigate(`/mpa-form/${id}/mpa-information`)}
          variant="outlined"
          // color="primary"
          size="large"
          sx={buttonPrimary}
        >
          Review
        </Button>
      );
    }
    return button;
  };

  return (
    <section className="bgLight">
      <TopBar />
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardContent sx={{ padding: '80px' }}>
              <Typography sx={{ mb: 4, ...mainHeadingStyles }} gutterBottom>
                Congratulations! {`"${SIOName}"`} has been approved to join the ScaleWith social
                Impact Network
              </Typography>
              <Typography sx={{ mb: 10, ...subHeadingStyles }}>
                By joining ScaleWith’s social impact Network, your organization will have access to
                ScaleWith’s innovative funding model and awareness building tools.The Master Program
                Agreement (“MPA”) formally establishes the relationship between ScaleWith and your
                organization. It outlines how we will work together, covering everything from the
                creative process to funding procedures. Please complete the “Onboarding Information”
                section below, and then you’ll have the chance to review and submit.
              </Typography>
              <Typography sx={{ my: 4, ...subHeadingStyles }}>
                Please complete and submit the following application.
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Card className="smallCards">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography sx={{ mb: 2, ...headingStyles }}>MPA Information</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {getChip()}
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                          {buttonChange()}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                </Grid>
                <Grid item xs={12} textAlign="right" sx={{ p: 2 }}>
                  <Button
                    disabled={mpa?.mpa?.percentComplete !== 100}
                    onClick={() => navigate(`/mpa-form/${id}/review-submit`)}
                    sx={{ mb: 1, ...buttonPrimary }}
                    variant="outlined"
                    size="large"
                    // color="primary"
                  >
                    Review and Submit
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default MpaDashBoard;
