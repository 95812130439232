import React, { useEffect, useState } from 'react';
import { Grid, TextField, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import BoxSection from '../../../shared/components/common/boxSection';
import GridInput from '../../../shared/components/common/gridTextField';
import TextAreaInput from '../../../shared/components/common/textAreaInput';
import Progressed from '../../../assets/svg/progressed.svg';
import { CellPhoneInput } from '../../../shared/components';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface props {
  setCompletionProgramData: any;
  general: any;
  submitted?: boolean;
  validatedQuestions: (count: number) => void;
  isSaveProgress: boolean;
  setProcess: () => void;
  submitResponse: boolean;
}

const GeneralInformation: React.FC<props> = ({
  setCompletionProgramData,
  general,
  submitted,
  validatedQuestions,
  isSaveProgress,
  setProcess,
  submitResponse,
}) => {
  const [changedValue, setChangedValue] = useState({
    contact: {
      name: '',
      phone: '',
      email: '',
    },
    programName: {
      internalProgramName: '',
      platformProgramName: '',
    },
    programDescription: {
      optional: '',
      description: '',
    },
  });
  const [validatedAnswers, setvalidateAnswers] = useState({
    contact: {
      name: 0,
      phone: 0,
      email: 0,
    },
    programName: {
      internalProgramName: 0,
      platformProgramName: 0,
    },
    programDescription: {
      description: 0,
    },
  });
  const [emailError, setEmailError] = useState('');
  const [fullName, setName] = useState('');
  const [emailAddress, setEmail] = useState('');
  const [phoneNumber, setPhone] = useState('');
  const [internalProgram, setInternalProgram] = useState('');
  const [platformProgram, setPlatformProgram] = useState('');
  const [programDesc, setProgramDesc] = useState('');

  const validateFields = (value: string) => (value === '' || value === undefined ? 0 : 1);
  const phoneError =
    phoneNumber !== undefined &&
    phoneNumber!.length > 0 &&
    (phoneNumber![0] === '0' ||
      phoneNumber![0] === '1' ||
      (phoneNumber!.length < 10 && phoneNumber !== ''));

  useEffect(() => {
    const contactLength = Object.values(validatedAnswers?.contact)?.filter(
      (value) => value === 1,
    )?.length;
    const programLength = Object.values(validatedAnswers?.programName)?.filter(
      (value) => value === 1,
    )?.length;
    const descriptionLength = Object.values(validatedAnswers?.programDescription)?.filter(
      (value) => value === 1,
    )?.length;
    validatedQuestions(contactLength + programLength + descriptionLength);
  }, [validatedAnswers]);

  useEffect(() => {
    setChangedValue(general);
    setName(general?.contact?.name);
    setEmail(general?.contact?.email);
    setPhone(general?.contact?.phone);
    setInternalProgram(general?.programName?.internalProgramName);
    setPlatformProgram(general?.programName?.platformProgramName);
    setProgramDesc(general?.programDescription?.description);
    setvalidateAnswers({
      contact: {
        name: changedValue?.contact?.name ? 1 : 0,
        phone: changedValue?.contact?.phone ? 1 : 0,
        email: changedValue?.contact?.email ? 1 : 0,
      },
      programName: {
        internalProgramName: changedValue?.programName?.internalProgramName ? 1 : 0,
        platformProgramName: changedValue?.programName?.platformProgramName ? 1 : 0,
      },
      programDescription: {
        description: changedValue?.programDescription?.description ? 1 : 0,
      },
    });
  }, [general]);

  useEffect(() => {
    setvalidateAnswers({
      contact: {
        name: changedValue?.contact?.name ? 1 : 0,
        phone: changedValue?.contact?.phone ? 1 : 0,
        email: changedValue?.contact?.email ? 1 : 0,
      },
      programName: {
        internalProgramName: changedValue?.programName?.internalProgramName ? 1 : 0,
        platformProgramName: changedValue?.programName?.platformProgramName ? 1 : 0,
      },
      programDescription: {
        description: changedValue?.programDescription?.description ? 1 : 0,
      },
    });
  }, [changedValue]);

  useEffect(() => {
    if (isSaveProgress || submitResponse) {
      if (emailError === '' && !phoneError) {
        setCompletionProgramData(
          {
            contact: {
              name: fullName,
              phone: phoneNumber,
              email: emailAddress,
            },
            programName: {
              internalProgramName: internalProgram,
              platformProgramName: platformProgram,
            },
            programDescription: {
              optional: '',
              description: programDesc,
            },
          },
          'general',
        );
        setProcess();
      }
    }
  }, [isSaveProgress, submitResponse]);

  return (
    <>
      <Grid item xs={7}>
        <BoxSection>
          <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
            Question 1 of 3{' '}
            {!submitted ? (
              <sup style={{ color: colors.statusAlert }}>*</sup>
            ) : (
              <img
                src={Progressed}
                style={{ height: '14px', marginLeft: '5px' }}
                alt="Progressed"
              />
            )}
          </Typography>
          <TextAreaInput
            title="Who will be the main contact person for this program?"
            disabled={submitted}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <GridInput
                typographyText="First and Last Name"
                type="text"
                variantType="h6"
                infoIcon
                tooltipText="First and Last Name"
                placeholder="First and Last Name"
                value={fullName}
                onChange={(event) => {
                  setName(event.target.value);
                  if (event.target.value.length === 1 || event.target.value.length === 0) {
                    setvalidateAnswers((prevState: any) => ({
                      ...prevState,
                      contact: {
                        ...prevState?.contact,
                        name: validateFields(event.target.value),
                      },
                    }));
                  }
                }}
                required
                disabled={submitted}
              />
            </Grid>
            <Grid item xs={6}>
              <GridInput
                typographyText="Email Address"
                type="email"
                variantType="h6"
                infoIcon
                tooltipText="Email Address"
                placeholder="Email Address"
                error={!!emailError}
                helperText={emailError}
                required
                value={emailAddress}
                onChange={(event) => {
                  if (event.target.value !== '') {
                    const emailRegex = /^[\w-]+(\.[\w-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
                    if (event.target.value.trim() === '') {
                      setEmailError('Email is required');
                    } else if (!emailRegex.test(event.target.value)) {
                      setEmailError('Invalid email format');
                    } else {
                      setEmailError('');
                      setEmail(event.target.value);
                    }
                  }
                  if (event.target.value.length === 1 || event.target.value.length === 0) {
                    setvalidateAnswers((prevState: any) => ({
                      ...prevState,
                      contact: {
                        ...prevState?.contact,
                        email: validateFields(event.target.value),
                      },
                    }));
                  }
                  setEmail(event.target.value);
                }}
                disabled={submitted}
              />
            </Grid>
          
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography sx={{ mb: 1 }} variant="subtitle1">
              Phone Number
              <Tooltip title="Enter phone number">
                <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
              </Tooltip>
            </Typography>
            <TextField
              name="phone"
              value={phoneNumber}
              onChange={(event) => setPhone(event.target.value)}
              placeholder="Enter email address"
              fullWidth
              margin="normal"
              variant="outlined"
              type="text"
              error={
                phoneNumber !== undefined &&
                phoneNumber!.length > 0 &&
                (phoneNumber![0] === '0' ||
                  phoneNumber![0] === '1' ||
                  (phoneNumber!.length < 10 && phoneNumber !== ''))
              }
              InputProps={{
                inputComponent: CellPhoneInput as any,
                sx: {
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '16px',
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '16px',
                  },
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                },
              }}
            />
          </Grid>
        </BoxSection>
        <BoxSection>
          <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
            Question 2 of 3{' '}
            {!submitted ? (
              <sup style={{ color: colors.statusAlert }}>*</sup>
            ) : (
              <img
                src={Progressed}
                style={{ height: '14px', marginLeft: '5px' }}
                alt="Progressed"
              />
            )}
          </Typography>
          <TextAreaInput title="Please provide a name for this program" disabled={submitted} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <GridInput
                typographyText="Internal Program Name"
                type="text"
                variantType="h6"
                infoIcon
                tooltipText="Internal Program Name"
                placeholder="Internal Program Name"
                required
                value={internalProgram}
                onChange={(event) => {
                  setInternalProgram(event.target.value);
                  if (event.target.value.length === 1 || event.target.value.length === 0) {
                    setvalidateAnswers((prevState: any) => ({
                      ...prevState,
                      programName: {
                        ...prevState?.programName,
                        internalProgramName: validateFields(event.target.value),
                      },
                    }));
                  }
                }}
                disabled={submitted}
              />
            </Grid>
            <Grid item xs={6}>
              <GridInput
                typographyText="Platform Program Name"
                type="text"
                variantType="h6"
                infoIcon
                tooltipText="Platform Program Name"
                placeholder="Platform Program Name"
                value={platformProgram}
                onChange={(event) => {
                  setPlatformProgram(event.target.value);
                  if (event.target.value.length === 1 || event.target.value.length === 0) {
                    setvalidateAnswers((prevState: any) => ({
                      ...prevState,
                      programName: {
                        ...prevState?.programName,
                        platformProgramName: validateFields(event.target.value),
                      },
                    }));
                  }
                }}
                disabled={submitted}
              />
            </Grid>
          </Grid>
        </BoxSection>
      </Grid>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 3 of 3{' '}
          {!submitted ? (
            <sup style={{ color: colors.statusAlert, fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight500 }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <Grid item xs={6}>
          <TextAreaInput
            title="Please provide a name for this program"
            secondaryTitle="Description"
            isTextField
            infoIcon
            tooltipText="Description"
            required
            value={programDesc}
            onChange={(event) => {
              setProgramDesc(event.target.value);
              if (event.target.value.length === 1 || event.target.value.length === 0) {
                setvalidateAnswers((prevState: any) => ({
                  ...prevState,
                  programDescription: {
                    ...prevState?.programDescription,
                    description: validateFields(event.target.value),
                  },
                }));
              }
            }}
            disabled={submitted}
          />
        </Grid>
      </BoxSection>
    </>
  );
};

export default GeneralInformation;
