import React from 'react';
import { Box, Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { FlexBox, FormDialog, IsolatedGrid } from '../../../shared/components';
import { Dialogs } from '../../../shared/enums';
import { formatter, getGenericHeight } from '../../../shared/utils';
import { useAppDispatch, useAppSelector, useInstance } from '../../../shared/hooks';
import { CONTAINS_FILTER_OPERATOR, MAX_NUMBER_RECORDS } from '../../../shared/constants';
import { ProcurementMetricsResponseObject, GridModel } from '../../../shared/interfaces';
import { getProcurementCSVThunk } from '../procurementMetricsSlice';

export const ProcurementMetricsDetails = () => {
  const stateProcurement = useAppSelector((x) => x.procurementMetrics);
  const { requestValues, responseValues, isLoadingCSV, isLoadingRequest } = { ...stateProcurement };
  const { currencySymbol } = useInstance();
  const dispatch = useAppDispatch();

  const generatedID = responseValues.details?.results.map((item) => ({
    id: item.key?.account,
    ...item,
  }));
  const total = generatedID?.length ?? 0;
  const grid: GridModel<ProcurementMetricsResponseObject> = {
    name: 'procurementMetrics',
    data: {
      results: !generatedID ? [] : [...generatedID],
      totalCount: total,
    },
    page: 0,
    pageSize: MAX_NUMBER_RECORDS,
    loadingGrid: false,
    defaultSort: { field: 'supplier', direction: 'asc' },
  };

  const getDownloadCSV = () => {
    dispatch(getProcurementCSVThunk(requestValues));
  };

  const columns: Array<GridColDef> = [
    {
      field: 'supplier',
      headerName: 'Supplier',
      flex: 1,
      valueGetter: (params) => params.row.key?.account ?? '',
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'CompletedGiveAmount',
      headerName: 'Completed Contribution Amount',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
      renderCell: ({ row: { totalGiveAmount } }) =>
        `${currencySymbol}${formatter.format(totalGiveAmount ?? 0)}`,
    },
    {
      field: 'AvgOfTDV',
      headerName: 'AVG % of TDV',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
      renderCell: ({ row: { avgGivePercent } }) => `${avgGivePercent ?? 0}%`,
    },
    {
      field: 'participation',
      headerName: 'Participation',
      flex: 1,
      filterable: false,
      renderCell: ({ row: { participation } }) => `${participation ?? 0}%`,
    },
  ];

  return (
    <FormDialog
      title="Suppliers"
      type={Dialogs.ProcurementMetricsDetails}
      loading={isLoadingCSV || isLoadingRequest}
    >
      <Box sx={{ height: getGenericHeight(400) }}>
        <FlexBox sx={{ justifyContent: 'end' }}>
          <Button
            variant="outlined"
            color="info"
            disableElevation
            onClick={getDownloadCSV}
            startIcon={<Download />}
          >
            Download CSV
          </Button>
        </FlexBox>
        <Box sx={{ minHeight: '400px', height: getGenericHeight(300), marginTop: 1 }}>
          <IsolatedGrid model={grid} columns={columns} onChange={() => {}} />
        </Box>
      </Box>
    </FormDialog>
  );
};
