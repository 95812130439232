import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addCancelTokenEvent, deleteCauseArea, getCauseAreas, patchCauseArea } from '../../api';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';
import { CauseAreasTabs } from '../../shared/enums';
import { CauseArea, GridModel, GridRequest, GridResponse } from '../../shared/interfaces';

interface CauseAreaGridRequest extends GridRequest {
  active: boolean;
}

export interface CauseAreasState {
  grid: GridModel<CauseArea>;
  selectedTab: number;
  success: boolean;
  toastMessage: string;
}

const initialState: CauseAreasState = {
  grid: {
    name: 'cause areas',
    data: { totalCount: 0, results: [] },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'name', direction: 'asc' },
  },
  selectedTab: CauseAreasTabs.Active,
  success: false,
  toastMessage: '',
};

export const getCauseAreasInformationThunk = createAsyncThunk<
  GridResponse<CauseArea>,
  CauseAreaGridRequest
>('causeAreas/getCauseAreasInformationThunk', async (request: GridRequest, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getCauseAreas(request);
  return data;
});

export const patchCauseAreaThunk = createAsyncThunk<CauseArea, string>(
  'causeAreas/patchCauseArea',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await patchCauseArea(id);
    return data;
  },
);

export const deleteCauseAreaThunk = createAsyncThunk<CauseArea, string>(
  'causeAreas/deleteCauseArea',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await deleteCauseArea(id);
    return data;
  },
);

const causeAreasSlice = createSlice({
  name: 'causeAreas',
  initialState,
  reducers: {
    setCauseAreasGridModel: (state, action: PayloadAction<Partial<GridModel<CauseArea>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearCauseAreasDashboardState: (state) => {
      state.grid = initialState.grid;
      state.success = initialState.success;
      state.toastMessage = initialState.toastMessage;
    },
    clearCauseAreasState: (state) => {
      _.assign(state, initialState);
    },
    setCauseAreasTab: (state, action: PayloadAction<number>) => {
      state.selectedTab = action.payload;
    },
    clearCauseAreaToastMessage: (state) => {
      state.toastMessage = '';
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCauseAreasInformationThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<CauseArea>>) => {
          state.grid.loadingGrid = false;
          state.grid.data = action.payload;
        },
      )
      .addCase(getCauseAreasInformationThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getCauseAreasInformationThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(patchCauseAreaThunk.fulfilled, (state) => {
        state.success = true;
        state.grid.loadingGrid = false;
        state.toastMessage = 'Cause Area activated';
      })
      .addCase(patchCauseAreaThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(patchCauseAreaThunk.pending, (state) => {
        state.success = false;
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
        state.toastMessage = '';
      })
      .addCase(deleteCauseAreaThunk.fulfilled, (state) => {
        state.success = true;
        state.grid.loadingGrid = false;
        state.toastMessage = 'Cause Area deactivated';
      })
      .addCase(deleteCauseAreaThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(deleteCauseAreaThunk.pending, (state) => {
        state.success = false;
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
        state.toastMessage = '';
      });
  },
});

export const {
  setCauseAreasGridModel,
  clearCauseAreasState,
  setCauseAreasTab,
  clearCauseAreaToastMessage,
  clearCauseAreasDashboardState,
} = causeAreasSlice.actions;

export const causeAreasReducer = causeAreasSlice.reducer;
