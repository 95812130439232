import {
  Account,
  AccountApprovalLevel,
  GridRequest,
  GridResponse,
  Industry,
  SubscriptionDetails,
} from '../../shared/interfaces';
import { httpCommon, httpSecuredCommon, cancelToken } from '../httpCommon';

const base = '/accounts';
const accountBase = '/account';
const companyBase = '/company/registration';

export const ACCOUNT_API_ROUTES = {
  getAccount: (id: string) => `${base}/${id}`,
  putAccount: (id: string) => `${base}/${id}`,
  patchAccountStatus: (id: string) => `${base}/${id}`,
  postAccount: base,
  getAccounts: base,
  getIndustries: `${base}/industries`,
  getIndustriesCounts: `${base}/industries/counts`,
  getAccountApprovals: (id: string) => `${base}/${id}/approvals`,
  accountSubscription: (id: string) => `${base}/${id}/payment`,
  putAccountApprovals: (id: string) => `${base}/${id}/approvals`,
  getAccountAppSettings: (id: string) => `${base}/${id}/app-settings`,
  getBuyerAccount: (id: string) => `${base}/mini/${id}`,
  getScaleWithConstants: (id: string) => `/scalewith_constants/${id}`,
  uploadAccountSettingsFile: (id: string) => `${base}/logo/${id}`,
  deleteAccountSettingsFile: (id: string) => `${base}/logo/${id}`,
  getCompanies: (queryString: string) => `${companyBase}/${queryString}`,
  completeSubscription: (id: string) => `${base}/${id}/received-funds`,
  inviteApp: `${accountBase}/invitations`,
  postInvoice: `${base}/documents/invoices`
};

export const getAccount = async (id: string) => {
  const response = await httpSecuredCommon.get<Account>(ACCOUNT_API_ROUTES.getAccount(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postInvoice = async (transaction: any) => {
  const data = {
    filters: {
      "transaction_type": transaction?.transactionType,
      "order_id": transaction?.itemId
    },
    format: "html"
  }
  const response = await httpSecuredCommon.post<Account>(ACCOUNT_API_ROUTES.postInvoice, data, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postAccount = async (account: Account) => {
  const response = await httpSecuredCommon.post<Account>(ACCOUNT_API_ROUTES.postAccount, account, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const putAccount = async (id: string, account: Account) => {
  const response = await httpSecuredCommon.put<Account>(
    ACCOUNT_API_ROUTES.putAccount(id),
    account,
    {
      cancelToken: cancelToken.token,
    },
  );

  return response;
};

export const postReceivedFunds = async (id: string) => {
  const response = await httpSecuredCommon.post<Account>(
    ACCOUNT_API_ROUTES.completeSubscription(id),
    {},
    {
      cancelToken: cancelToken.token,
    },
  );

  return response;
};

export const uploadAccountSettingsFile = async (id: string, file: any) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  const response = await httpSecuredCommon.put(
    ACCOUNT_API_ROUTES.uploadAccountSettingsFile(id),
    file,
    config,
  );
  return response;
};
export const deleteAccountSettingsFile = async (id: string) => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };
  const response = await httpSecuredCommon.delete(
    ACCOUNT_API_ROUTES.deleteAccountSettingsFile(id),
    config,
  );
  return response;
};

export const patchAccountStatus = async (id: string, status: string) => {
  const response = await httpSecuredCommon.patch(
    ACCOUNT_API_ROUTES.patchAccountStatus(id),
    { status },
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getAccounts = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<Account>>(
    ACCOUNT_API_ROUTES.getAccounts,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const getBuyerAccount = async (id: string) => {
  const response = await httpCommon.get(ACCOUNT_API_ROUTES.getBuyerAccount(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getScaleWithConstants = async (key: string) => {
  const response = await httpCommon.get(ACCOUNT_API_ROUTES.getScaleWithConstants(key), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getIndustries = async () => {
  const response = await httpCommon.get<Array<Industry>>(ACCOUNT_API_ROUTES.getIndustries, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getIndustriesCounts = async () => {
  const response = await httpCommon.get<Array<Industry>>(ACCOUNT_API_ROUTES.getIndustriesCounts, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getAccountApprovals = async (id: string) => {
  const response = await httpSecuredCommon.get<AccountApprovalLevel>(
    ACCOUNT_API_ROUTES.getAccountApprovals(id),
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const accountSubscription = async (id: string, subscriptionDetails: SubscriptionDetails) => {
  const response = await httpSecuredCommon.post<AccountApprovalLevel>(
    ACCOUNT_API_ROUTES.accountSubscription(id),
    subscriptionDetails,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const putAccountApprovals = async (id: string, accountApprovals: AccountApprovalLevel) => {
  const response = await httpSecuredCommon.put<AccountApprovalLevel>(
    ACCOUNT_API_ROUTES.putAccountApprovals(id),
    accountApprovals,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getAccountAppSettings = async (id: string) => {
  const response = await httpSecuredCommon.get(ACCOUNT_API_ROUTES.getAccountAppSettings(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getAccountCompanies = async (searchValue: string) => {
  const queryString = `?name=${searchValue}&count=10&offset=0&orderBy=name&descending=false`;
  const response = await httpSecuredCommon.get<any>(ACCOUNT_API_ROUTES.getCompanies(queryString), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const inviteApp = async (app: {
  forceOrgId: string;
  type: string;
  roles: Array<string>;
}) => {
  const response = await httpSecuredCommon.post(ACCOUNT_API_ROUTES.inviteApp, app, {
    cancelToken: cancelToken.token,
  });
  return response;
};
