/* eslint-disable react/no-array-index-key */
import { Typography } from '@mui/material';
import React, { FC } from 'react';
// import { Subscription, SubscriptionTier } from '../../interfaces';
import { formatter, isNullOrWhitespace } from '../../utils';
import { PaperSelection } from '../PaperSelection';
import {
  FlexBox,
  // MainTypography,
  PopularTypography,
  ThankYouTypography,
} from '../StyledComponents';

const defaultPopularText = 'Most Popular';

interface Props {
  subscription?: SubscriptionTier;
  popularText?: string;
  // subscriptionKey?: string;
  isSelected?: boolean;
  mostPopular?: boolean;
  // isRegister?: boolean;
  // hideFreeTrial?: boolean;
  Buttons?: JSX.Element;
  // subscriptionDetails?: any;
  // subscriptionId?: string;
}

interface SubscriptionTier {
  id?: string;
  label?: string;
  sku?: string;
  price?: SubscriptionPrice[];
}

interface SubscriptionPrice {
  amount?: number;
  stripe?: {
    id: string;
  };
  period?: 'annual' | 'monthly';
  description?: string;
  enabled?: boolean;
}

export const SubscriptionPaper: FC<Props> = (props) => {
  const {
    // subscriptionKey,
    // subscriptions,
    subscription,
    isSelected,
    Buttons,
    popularText,
    // isRegister,
    mostPopular,
    // hideFreeTrial,
    // subscriptionDetails,
    // subscriptionId,
  } = props;

  // console.debug()
  // console.log(props, '>>>>>PROPS');
  // console.log(subscription);

  const showPopular = mostPopular || !isNullOrWhitespace(popularText);
  return (
    <PaperSelection isSelected={isSelected ?? false} sx={{ flex: 1, borderRadius: '10px' }}>
      <FlexBox sx={{ flexDirection: 'column', gap: 1 }}>
        {showPopular && (
          <PopularTypography variant="body2" sx={{ height: 'fit-content' }}>
            {popularText ?? defaultPopularText}
          </PopularTypography>
        )}
        <FlexBox
          sx={{
            gap: 2,
            alignItems: 'baseline',
            marginBottom: 1,
            marginTop: !showPopular ? '1.5rem' : 0,
          }}
        >
          <ThankYouTypography variant="body1" sx={{ fontWeight: '700' }}>
            {subscription?.label}
          </ThankYouTypography>

          {/* {isRegister && subscription.trialPeriodDays !== 0 && !hideFreeTrial && (
            <MainTypography sx={{ height: 'fit-content' }}>
              First {subscription.trialPeriodDays} Days Free
            </MainTypography>
          )} */}
        </FlexBox>

        {subscription?.price!.map((x: any) =>
          x.period === 'annual' ? (
            <>
              <FlexBox
                sx={{
                  flexDirection: 'column',
                  // marginBottom: !isNullOrWhitespace(subscription?.savingFooter) ? 0 : 3,
                }}
              >
                <FlexBox sx={{ gap: 1, alignItems: 'baseline' }}>
                  <Typography variant="h5" fontWeight={700}>
                    ${`${formatter.format(x.amount!)}`}
                  </Typography>
                </FlexBox>
                <Typography>{x.period}</Typography>
                {/* {subscription?.savingFooter && (
                <Typography variant="body2" sx={(theme) => ({ color: theme.palette.primary.main })}>
                {subscription.savingFooter}
                </Typography>
              )} */}
              </FlexBox>
              <Typography>{x.description}</Typography>
              {/* {subscriptionId === subscriptionDetails?.targetTier &&
                subscriptionDetails?.paymentMethod === 'invoice' &&
                subscriptionDetails?.status === 'pending payment' && (
                  <Typography sx={{ color: '#009F75' }}>
                    Current Status: {subscriptionDetails?.status}
                  </Typography>
                )} */}
            </>
          ) : null,
        )}

        {/* {isRegister && (
          <ul>
            {subscription.bullets.map((bullet, index) => (
              <li
                key={`${
                  subscriptionKey ?? `${subscription.title.trim()}subscription`
                }-bullet-${index}`}
              >
                {bullet}
              </li>
            ))}
          </ul>
        )} */}

        <FlexBox flex={1} gap={1} alignItems="end">
          {Buttons}
        </FlexBox>
      </FlexBox>
    </PaperSelection>
  );
};
