import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CommonBody } from '../register/CommonBody';

export const Unauthorized = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/logout');
    }, 2000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <CommonBody hideLogo coloredLogo>
      <Typography
        sx={{
          marginTop: 2,
          fontWeight: '700',
          color: 'black',
          textAlign: 'center',
          flex: 1,
          fontSize: 28,
        }}
      >
        You are not authorized to view this page.
      </Typography>
    </CommonBody>
  );
};