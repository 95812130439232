import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';
import { generatePDFBinary } from '../../../api';

interface LocationState {
  orderId: String
}

export const InvoiceViewer: React.FC = () => {
  const [pdfDataBinary, setPdfUrl] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const orderId = location.state as LocationState;

  useEffect(() => {
    if (!orderId) {
      console.log('OrderId not found');
      return;
    }
    const fetchPDF = async () => {
      try {
        const { data } = await generatePDFBinary(orderId.orderId);
        setPdfUrl(`data:application/pdf;base64,${data.pdfDataBinary}`);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };
    fetchPDF();
  }, [orderId]);

  return (
    <div>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontSize: fonts.fontSize24,
          color: colors.textPrimary,
          fontWeight: fonts.fontWeight700,
        }}
      >
        <ArrowBackIcon onClick={() => navigate(-1)} sx={{ marginRight: '10px', cursor: 'pointer' }} />
        Invoice Details
      </Typography>
      <div id='iframe-div' style={{ minHeight: "70vh" }}>
        {pdfDataBinary ? (
          <iframe src={pdfDataBinary} style={{ width: "100%", minHeight: "70vh" }} title="PDF Viewer" />
        ) : (
          <div>Your invoices will be here soon....</div>
        )}
      </div>
    </div>
  );
};

export default InvoiceViewer;
