import React from 'react';
import { Box } from '@mui/material';
import { FlexBox } from '../../../shared/components';
import { HeaderUserAccount } from './HeaderUserAccount';

export function Header() {
  return (
    <FlexBox
      sx={{
        paddingX: 2,
        paddingY: 1,
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
      }}
    >
      <Box sx={{ flex: '1' }} />
      <Box>
        <HeaderUserAccount />
      </Box>
    </FlexBox>
  );
}
