import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { GiveStages } from '../../../../shared/enums';
import { useAppSelector } from '../../../../shared/hooks';
import { setCauseAreaMetricsGiveStage } from '../../CauseAreaMetricsSlice';

export const FilterGiveStages = () => {
  const stateMetrics = useAppSelector((x) => x.causeAreasMetrics);
  const { giveStagesSelected } = { ...stateMetrics };
  const dispatch = useDispatch();

  const onHandleCheckBox = (event: any) => {
    dispatch(setCauseAreaMetricsGiveStage([...event.target.value]));
  };

  const getValueTextField = () => {
    if (giveStagesSelected.length === 0) return '';
    if (giveStagesSelected.length === 1) return giveStagesSelected[0];
    return `${giveStagesSelected[0]} +${giveStagesSelected.length - 1}`;
  };

  return (
    <Box sx={{ flex: 1 }}>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel>Social Impact Contribution Stages</InputLabel>
        <Select
          multiple
          fullWidth
          labelId="filter-select-label"
          id="filter-select"
          variant="standard"
          onChange={onHandleCheckBox}
          value={giveStagesSelected ?? []}
          renderValue={() => getValueTextField()}
        >
          {Object.values(GiveStages).map((item) => (
            <MenuItem key={`instance-settings-general-${item}`} value={item}>
              <Checkbox checked={giveStagesSelected.indexOf(item) > -1} />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
