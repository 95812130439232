import { OpenInNew } from '@mui/icons-material';
import { Button, TextField, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { FlexBox, LabelAndSwitch, RecordDetails } from '../../../shared/components';
import { useAppSelector } from '../../../shared/hooks';
import { CauseAreaFormConfig } from '../../../shared/interfaces';
import {
  setCauseAreaSettingDescription,
  setCauseAreaSettingDetailImageName,
  setCauseAreaSettingDetailLinkText,
  setCauseAreaSettingDetailLinkUrl,
  setCauseAreaSettingListImageUrl,
  setCauseAreaSettingName,
  setCauseAreaSettingPartnerName,
  setCauseAreaSettingSageVendorId,
  setCauseAreaSettingSKUPrefix,
  setCauseAreaSettingsStatus,
  setCauseAreaSettingTooltip,
} from './causeAreaSettingsSlice';

interface Props {
  value: CauseAreaFormConfig;
  isCauseAreaFormInvalid: (formConfig: CauseAreaFormConfig) => boolean | undefined;
}

export const CauseAreaSettingsGeneral: FC<Props> = (props) => {
  const { value, isCauseAreaFormInvalid } = props;
  const state = useAppSelector((x) => x.causeAreaSettings);
  const dispatch = useDispatch();
  const { values, isNew, instanceAssociatedGrid } = state;
  const { loadingGrid, data } = instanceAssociatedGrid;

  const goToViewCauseArea = () => {
    if (isCauseAreaFormInvalid(value)) return;
    window.open('/app/view-cause-area', '_blank');
    const valuesToLocalStorage = {
      causeAreaData: values,
      associatedInstance: data,
    };
    localStorage.setItem('gw-view-cause-area', JSON.stringify(valuesToLocalStorage));
  };

  return (
    <FlexBox sx={{ gap: 6, maxHeight: 85 }}>
      <FlexBox
        sx={{
          flexDirection: 'column',
          gap: 3,
          flex: 0.7,
        }}
      >
        <TextField
          label={value.name?.label}
          value={value.name?.value}
          required={value.name?.required}
          onChange={(e) => dispatch(setCauseAreaSettingName(e.target.value))}
        />
        <TextField
          label={value.partnerName?.label}
          value={value.partnerName?.value}
          required={value.partnerName?.required}
          onChange={(e) => dispatch(setCauseAreaSettingPartnerName(e.target.value))}
        />
        <TextField
          label={value.detailLinkUrl?.label}
          value={value.detailLinkUrl?.value}
          required={value.detailLinkUrl?.required}
          onChange={(e) => dispatch(setCauseAreaSettingDetailLinkUrl(e.target.value))}
        />
        <TextField
          label={value.detailLinkText?.label}
          value={value.detailLinkText?.value}
          required={value.detailLinkText?.required}
          onChange={(e) => dispatch(setCauseAreaSettingDetailLinkText(e.target.value))}
        />
        <FlexBox sx={{ flexDirection: 'column', alignItems: 'end', justifyContent: 'initial' }}>
          <Typography>{value.description?.value.length}/300</Typography>
          <TextField
            label={value.description?.label}
            value={value.description?.value}
            required={value.description?.required}
            sx={{ paddingRight: 0 }}
            fullWidth
            multiline
            variant="outlined"
            rows={4}
            onChange={(e) => dispatch(setCauseAreaSettingDescription(e.target.value))}
            inputProps={{
              maxLength: 300,
            }}
          />
        </FlexBox>
        <TextField
          label={value.listImageName?.label}
          value={value.listImageName?.value}
          required={value.listImageName?.required}
          onChange={(e) => dispatch(setCauseAreaSettingListImageUrl(e.target.value))}
        />
        <TextField
          label={value.detailImageName?.label}
          value={value.detailImageName?.value}
          required={value.detailImageName?.required}
          onChange={(e) => dispatch(setCauseAreaSettingDetailImageName(e.target.value))}
        />
        <TextField
          label={value.tooltip?.label}
          value={value.tooltip?.value}
          required={value.tooltip?.required}
          onChange={(e) => dispatch(setCauseAreaSettingTooltip(e.target.value))}
        />
        <TextField
          label={value.skuPrefix?.label}
          value={value.skuPrefix?.value}
          required={value.skuPrefix?.required}
          onChange={(e) => dispatch(setCauseAreaSettingSKUPrefix(e.target.value))}
        />
        <FlexBox sx={{ gap: 3 }}>
          <TextField
            label={value.sageVendorId?.label}
            value={value.sageVendorId?.value}
            required={value.sageVendorId?.required}
            onChange={(e) => dispatch(setCauseAreaSettingSageVendorId(e.target.value))}
          />
          <LabelAndSwitch
            value={value.status.value}
            disabled={loadingGrid || data.totalCount !== 0}
            label="STATUS"
            messageYes="Active"
            messageNo="Inactive"
            onChange={(e) => dispatch(setCauseAreaSettingsStatus(e.target.checked))}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox sx={{ flexDirection: 'column', flex: 0.3, gap: 3 }}>
        {!isNew && <RecordDetails value={values} />}

        <Button
          sx={{ padding: 1 }}
          fullWidth
          variant="contained"
          size="large"
          disabled={isCauseAreaFormInvalid(value)}
          onClick={goToViewCauseArea}
          endIcon={<OpenInNew />}
        >
          Preview Cause Area
        </Button>
      </FlexBox>
    </FlexBox>
  );
};
