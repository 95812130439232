import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addCancelTokenEvent, getErrorLog, patchErrorLog } from '../../api';
import { ErrorLog } from '../../shared/interfaces';

interface ViewErrorLogState {
  value?: ErrorLog;
  id: string;
  loading: boolean;
  success: boolean;
}

const initialState: ViewErrorLogState = {
  value: undefined,
  id: '',
  loading: false,
  success: false,
};

export const getErrorLogThunk = createAsyncThunk<ErrorLog, string>(
  'viewErrorLog/getErrorLogsThunk',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getErrorLog(id);
    return data;
  },
);

export const patchViewErrorLogThunk = createAsyncThunk<
  ErrorLog,
  { id: string; value: Omit<ErrorLog, 'id'> }
>('viewErrorLog/patchViewErrorLogThunk', async (payload, { signal }) => {
  const { id, value } = payload;
  addCancelTokenEvent(signal);
  const { data } = await patchErrorLog(id, value);
  return data;
});

const viewErrorLogSlice = createSlice({
  name: 'viewErrorLog',
  initialState,
  reducers: {
    setViewErrorLogId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    clearViewErrorLogState: (state) => {
      _.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getErrorLogThunk.fulfilled, (state, action: PayloadAction<ErrorLog>) => {
        state.value = action.payload;
        state.loading = false;
      })
      .addCase(getErrorLogThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getErrorLogThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(patchViewErrorLogThunk.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(patchViewErrorLogThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(patchViewErrorLogThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      });
  },
});

export const { clearViewErrorLogState, setViewErrorLogId } = viewErrorLogSlice.actions;

export const viewErrorLogReducer = viewErrorLogSlice.reducer;
