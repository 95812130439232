import { ContactUsRequest } from '../../shared/interfaces';
import { httpMatchmakingCommon } from '../httpCommon';

export const PORTAL_API_ROUTES = {
  postContactUs: `/contact-us`,
};

export const postContactUs = async (payload: ContactUsRequest) => {
  const response = await httpMatchmakingCommon.post(PORTAL_API_ROUTES.postContactUs, payload);
  return response;
};
