import React from 'react';
import { Chip } from '@mui/material';
import { FlexBox } from '../../../shared/components';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { setCauseAreaMetricsChipsSelected } from '../CauseAreaMetricsSlice';
import { CauseAreaMetricsPills } from '../../../shared/enums';

export const CauseAreaMetricsChip = () => {
  const CauseAreasMetricsState = useAppSelector((x) => x.causeAreasMetrics);
  const dispatch = useAppDispatch();
  const { chipsSelected } = CauseAreasMetricsState;

  const isSelected = (pill: CauseAreaMetricsPills) => {
    const findChip = chipsSelected.find((chip) => chip === pill);
    return findChip !== undefined;
  };

  const onHandleChip = (pill: CauseAreaMetricsPills) => {
    const findChip = chipsSelected.find((chip) => chip === pill);
    if (findChip === undefined) {
      dispatch(setCauseAreaMetricsChipsSelected([...chipsSelected, pill]));
      localStorage.setItem(
        'gw-cause-area-metrics-pills-selected',
        JSON.stringify([...chipsSelected, pill]),
      );

      return;
    }
    const chipsWithoutElement = chipsSelected.filter((item) => item !== pill);
    dispatch(setCauseAreaMetricsChipsSelected([...chipsWithoutElement]));
    localStorage.setItem(
      'gw-cause-area-metrics-pills-selected',
      JSON.stringify([...chipsWithoutElement]),
    );
  };

  return (
    <FlexBox sx={{ gap: 2 }}>
      {Object.values(CauseAreaMetricsPills).map((pill, index) => (
        <Chip
          // eslint-disable-next-line react/no-array-index-key
          key={`cause-area-metrics-${pill}-${index}`}
          label={pill}
          sx={{
            backgroundColor: isSelected(pill) ? '#2F9C95' : '',
            color: isSelected(pill) ? 'white' : 'black',
            ':hover': { backgroundColor: '#00746C' },
          }}
          variant={isSelected(pill) ? 'filled' : 'outlined'}
          onClick={() => onHandleChip(pill)}
        />
      ))}
    </FlexBox>
  );
};
