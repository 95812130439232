import React, { useState, FC } from 'react';
import { Collapse, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { FlexBox } from './StyledComponents';

interface Props {
  title?: string;
  expanded?: boolean;
  positionTile?: 'Top' | 'Bottom';
}

export const CollapseComponent: FC<Props> = (props) => {
  const { title, expanded: expandedProp, children, positionTile } = props;
  const [expanded, setExpanded] = useState(expandedProp);
  const handleExpandClick = () => setExpanded(!expanded);
  return (
    <FlexBox sx={{ flexDirection: 'column' }}>
      {(positionTile === 'Top' || positionTile === undefined) && (
        <FlexBox
          sx={{ gap: 1, alignContent: 'start', cursor: 'pointer' }}
          onClick={handleExpandClick}
        >
          <Typography fontWeight="bold">{title}</Typography>
          <ExpandMore
            sx={(theme) => ({
              transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
              transitionDuration: theme.transitions.duration.shorter,
            })}
          />
        </FlexBox>
      )}
      <Collapse in={expanded} unmountOnExit>
        {children}
      </Collapse>
      {positionTile === 'Bottom' && (
        <FlexBox
          sx={{ gap: 1, alignContent: 'start', cursor: 'pointer' }}
          onClick={handleExpandClick}
        >
          <Typography fontWeight="bold">{title}</Typography>
          <ExpandMore
            sx={(theme) => ({
              transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
              transitionDuration: theme.transitions.duration.shorter,
            })}
          />
        </FlexBox>
      )}
    </FlexBox>
  );
};
