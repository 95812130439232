import React from 'react';
import { Box } from '@mui/material';
import { FormDialog } from '../../../../shared/components';
import { CauseAreaMetricsDialogTabs, Dialogs } from '../../../../shared/enums';
import { useAppSelector } from '../../../../shared/hooks';
import { CauseAreaMetricsDialogTable } from './CauseAreaMetricsDialogTable';

export const CauseAreaMetricsDialog = () => {
  const { selectedTab } = useAppSelector((x) => x.causeAreaMetricsDialog);

  const getTabComponent = (tab: CauseAreaMetricsDialogTabs) => {
    switch (tab) {
      case CauseAreaMetricsDialogTabs.causeAreas:
        return <CauseAreaMetricsDialogTable />;
      case CauseAreaMetricsDialogTabs.salesUsers:
      default:
        return <CauseAreaMetricsDialogTable />;
    }
  };
  return (
    <FormDialog title="Metrics" type={Dialogs.CauseAreaMetrics}>
      <Box sx={{ minHeight: 545 }}>{getTabComponent(selectedTab)}</Box>
    </FormDialog>
  );
};
