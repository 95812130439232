import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import './searchInput.css';
import fonts from '../../../../assets/scss/font.module.scss';
import colors from '../../../../assets/scss/color.module.scss';

interface Props {
  onChange?: ((event: any) => void) | undefined;
}

const SearchInput: React.FC<Props> = ({ onChange }) => (
  <TextField
    id="standard-basic"
    variant="outlined"
    placeholder="Search with a keyword"
    onChange={onChange}
    size="small"
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon sx={{ color: colors.primary }} />
        </InputAdornment>
      ),
    }}
    sx={{
      '& .MuiInputBase-root': {
        height: '40px',
      },
      marginTop: '-3px',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    }}
  />
);

export default SearchInput;
