import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { emailValid } from '../../../shared/utils';
import fonts from '../../../assets/scss/font.module.scss';
import colors from '../../../assets/scss/color.module.scss';

interface Props {
  emailAddresses: string[];
}

const EmailInput: React.FC<Props> = ({ emailAddresses }) => {
  const [emails, setEmails] = useState<string[]>(emailAddresses);
  const [inputValue, setInputValue] = useState('');
  const [emailError, setEmailError] = useState<string>('');
  const handleInputChange = (event: any) => {
    setInputValue(event.target.value.replace(/\s/g, ''));
  };
  const handleInputKeyDown = (event: any) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      if (emailValid(inputValue)) {
        setEmails([...emails, inputValue]);
        setInputValue('');
      }
      setEmailError('');
    }
  };
  const handleChipDelete = (index: number) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };

  function displayEmail(email: any) {
    let cardCount;
    if (email.length > 0) {
      cardCount = email.slice(0, 1);
    }
    return cardCount;
  }

  const placeholderContent = emails.length! === 0 ? 'Enter buyer email-id' : '';

  return (
      <TextField
      sx={{
        width: '100%',
        marginTop: '4px',
        '& input': {
          fontSize: fonts.fontSize16,
          fontWeight: fonts.fontWeight600,
          color: colors.textPrimary,
        },
        '& input::placeholder': {
          fontSize: fonts.fontSize16,
          fontWeight: fonts.fontWeight600,
          color: colors.textPrimary,
        },
      }}
        error={Boolean(emailError)}
        helperText={emailError}
        type="email"
        size="small"
        placeholder={placeholderContent}
        //  label="Enter Email"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        InputProps={{
          startAdornment: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontWeight: fonts.fontWeight600,
                fontSize: fonts.fontSize16,
                lineHeight: '16px',
              }}
            >
              {displayEmail(emails) &&
                displayEmail(emails).map((email: string, index: number, i: number) => (
                  <Chip
                    key={i}
                    label={email}
                    onDelete={() => handleChipDelete(index)}
                    variant="outlined"
                    deleteIcon={
                      <IconButton>
                        <ClearIcon />
                      </IconButton>
                    }
                    style={{ backgroundColor: '#EEF9FF', margin: '4px', color: '#0D4B7E' }}
                  />
                ))}
              {emails.length > 1 && (
                <div
                  style={{
                    border: '1px solid #E6E6E6',
                    backgroundColor: '#EEF9FF',
                    padding: '0px 16px',
                    borderRadius: '20px',
                    display: 'grid',
                    alignItems: 'center',
                    height: '32px',
                    margin: '4px',
                    color: '#0D4B7E',
                  }}
                >
                  +{emails.length > 1 && emails.length - 1}
                </div>
              )}
            </div>
          ),
        }}
      />
  );
};
export default EmailInput;
