import React, { FC } from 'react';
import { Typography } from '@mui/material';
import {
  CardHeaderBlue,
  FlexBox,
  FractionComponent,
  LightTooltip,
  RoundedBox,
} from '../../../shared/components';
import { useAppSelector } from '../../../shared/hooks';
import { CauseAreaMetricsOverViewItemResponse } from '../../../shared/interfaces';
import { CauseAreaMetricsPills, OrderStatus } from '../../../shared/enums';
import { formatter } from '../../../shared/utils';

interface Props {
  cardValue?: CauseAreaMetricsOverViewItemResponse;
}

export const CauseAreaCardMetrics: FC<Props> = (props) => {
  const { cardValue } = props;
  const CauseAreasMetricsState = useAppSelector((x) => x.causeAreasMetrics);
  const { chipsSelected } = CauseAreasMetricsState;

  const getItemByStatus = (status: OrderStatus) =>
    cardValue?.details?.find((item) => item.status === status);

  const isSelected = (field: CauseAreaMetricsPills) => {
    const findChip = chipsSelected.find((chip) => chip === field);
    return findChip !== undefined;
  };

  const currencySymbol = cardValue?.key?.currencySymbol ?? '';
  return (
    <CardHeaderBlue
      title={cardValue?.key?.causeArea ?? ''}
      styleContainer={{ width: 250, textAlign: 'center' }}
      toolTipOnTitle
      maxTitleLength={14}
      styleHeader={{
        padding: 1,
        minHeight: 50,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
      styleTitle={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
    >
      <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
        {isSelected(CauseAreaMetricsPills.ProposedValue) && (
          <LightTooltip title="Total currency value of Social Impact Contributions in the Proposed status">
            <FlexBox sx={{ flexDirection: 'column' }}>
              <RoundedBox>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Proposed Value
                </Typography>
              </RoundedBox>
              <Typography variant="h6" fontWeight="bold">
                {`${currencySymbol}${formatter.format(cardValue?.proposed ?? 0)}`}
              </Typography>
            </FlexBox>
          </LightTooltip>
        )}
        {isSelected(CauseAreaMetricsPills.ProposedCount) && (
          <LightTooltip title="Total number of Social Impact Contributions in the Proposed status">
            <FlexBox sx={{ flexDirection: 'column' }}>
              <RoundedBox>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Proposed Count
                </Typography>
              </RoundedBox>
              <Typography variant="h6" fontWeight="bold">
                {getItemByStatus(OrderStatus.PendingApproval)?.count ?? 0}
              </Typography>
            </FlexBox>
          </LightTooltip>
        )}
        {isSelected(CauseAreaMetricsPills.ApprovedValue) && (
          <LightTooltip title="Total currency value of Social Impact Contributions in the Approved status">
            <FlexBox sx={{ flexDirection: 'column' }}>
              <RoundedBox>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Approved Value
                </Typography>
              </RoundedBox>
              <Typography variant="h6" fontWeight="bold">
                {`${currencySymbol}${formatter.format(cardValue?.approved ?? 0)}`}
              </Typography>
            </FlexBox>
          </LightTooltip>
        )}
        {isSelected(CauseAreaMetricsPills.ApprovedCount) && (
          <LightTooltip title="Total number of Social Impact Contributions in the Approved status">
            <FlexBox sx={{ flexDirection: 'column' }}>
              <RoundedBox>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Approved Count
                </Typography>
              </RoundedBox>
              <Typography variant="h6" fontWeight="bold">
                {getItemByStatus(OrderStatus.Approved)?.count ?? 0}
              </Typography>
            </FlexBox>
          </LightTooltip>
        )}
        {isSelected(CauseAreaMetricsPills.CompletedValue) && (
          <LightTooltip title="Total currency value of Social Impact Contributions in the Completed status">
            <FlexBox sx={{ flexDirection: 'column' }}>
              <RoundedBox>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Completed Value
                </Typography>
              </RoundedBox>
              <Typography variant="h6" fontWeight="bold">
                {`${currencySymbol}${formatter.format(cardValue?.completed ?? 0)}`}
              </Typography>
            </FlexBox>
          </LightTooltip>
        )}
        {isSelected(CauseAreaMetricsPills.CompletedCount) && (
          <LightTooltip title="Total number of Social Impact Contributions in the Completed status">
            <FlexBox sx={{ flexDirection: 'column' }}>
              <RoundedBox>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Completed Count
                </Typography>
              </RoundedBox>
              <Typography variant="h6" fontWeight="bold">
                {getItemByStatus(OrderStatus.Completed)?.count ?? 0}
              </Typography>
            </FlexBox>
          </LightTooltip>
        )}
        {isSelected(CauseAreaMetricsPills.AvgGive) && (
          <LightTooltip
            title={
              <FractionComponent
                numerator="Approved Value + Completed Value + Financial Hold Value"
                dominator="Approved Count + Completed Count + Financial Hold Count"
              />
            }
          >
            <FlexBox sx={{ flexDirection: 'column' }}>
              <RoundedBox>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Avg. Social Impact Contribution Value
                </Typography>
              </RoundedBox>
              <Typography variant="h6" fontWeight="bold">
                {`${currencySymbol}${formatter.format(cardValue?.avgGiveAmount ?? 0)}`}
              </Typography>
            </FlexBox>
          </LightTooltip>
        )}
        {isSelected(CauseAreaMetricsPills.WinRate) && (
          <LightTooltip
            title={
              <FractionComponent
                numerator="Completed Count + Financial Hold Count"
                dominator="Approved Count"
              />
            }
          >
            <FlexBox sx={{ flexDirection: 'column' }}>
              <RoundedBox>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Win Rate
                </Typography>
              </RoundedBox>
              <Typography variant="h6" fontWeight="bold">
                {`${cardValue?.winRate ?? 0}%`}
              </Typography>
            </FlexBox>
          </LightTooltip>
        )}
        {isSelected(CauseAreaMetricsPills.Impact) && (
          <LightTooltip title="(Approved Value + Completed Value + Financial Hold Value) x impact Multiple">
            <FlexBox sx={{ flexDirection: 'column' }}>
              <RoundedBox>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Impact
                </Typography>
              </RoundedBox>
              <Typography variant="h6" fontWeight="bold">
                {`${formatter.format(cardValue?.impact ?? 0)} ${
                  cardValue?.impactMeasurement ?? ''
                }`}
              </Typography>
            </FlexBox>
          </LightTooltip>
        )}
        {isSelected(CauseAreaMetricsPills.AvgDeal) && (
          <LightTooltip
            title={
              <FractionComponent
                numerator="Avg Social Impact Contribution Value"
                dominator="Avg Total Deal Value"
              />
            }
          >
            <FlexBox sx={{ flexDirection: 'column' }}>
              <RoundedBox>
                <Typography variant="h6" sx={{ flex: 1 }}>
                  Avg. % of Deal
                </Typography>
              </RoundedBox>
              <Typography variant="h6" fontWeight="bold">
                {`${cardValue?.avgGivePercent ?? 0}%`}
              </Typography>
            </FlexBox>
          </LightTooltip>
        )}
      </FlexBox>
    </CardHeaderBlue>
  );
};
