import React,{ useState,ChangeEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import InfoIcon from '@mui/icons-material/Info';
import { Grid, MenuItem, Typography,Tooltip,Select,FormControl,FormControlLabel,Switch,SelectChangeEvent } from '@mui/material';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../shared/hooks';
import { addNewSioUserThunk,updateUserThunk } from './sioSlice';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface InitialState {
  id:string;
  sioId: string | undefined;
  firstName: string;
  lastName: string;
  username: string;
  roles: string[];
  active: boolean;
}

type Props = {
    open: boolean;
    onClose: () => void;
    initialUserState : InitialState;
    isEditMode: boolean;
    updateTable: () => void;
  };
const AddUserPopup = ({ open,onClose,initialUserState, isEditMode,updateTable }:Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const clearFormState = {
    id:'',
    sioId:'',
    firstName:'',
    lastName:'',
    username:'',
    roles:[],
    active:true,
  }
  const initialState:InitialState = {
    id:initialUserState.id,
    sioId:initialUserState.sioId,
    firstName:initialUserState.firstName,
    lastName:initialUserState.lastName,
    username:initialUserState.username,
    roles:initialUserState.roles,
    active:initialUserState.active,
  }

  const [formValues,setFormValues] = useState(initialState)
  const [checked, setChecked] = React.useState(formValues.active);

  useEffect(() => {
    setFormValues(initialState)
    setChecked(initialState.active)
  },[initialUserState])
  const menuOptions = [
    {
        label:'SIO User',
        value:'sio_user'
    },
]
const selectOptions = menuOptions.map((option) => (
    <MenuItem value={option.value}>
      {option.label}
    </MenuItem>
  ));
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;
    setChecked(isChecked);
    if (!isChecked) {
      setFormValues(prevFormValues => {
        const updatedFormValues = {
          ...prevFormValues,
          active: false
        }
        return updatedFormValues;
      });
    }else {
      setFormValues(prevFormValues => {
        const updatedFormValues = {
          ...prevFormValues,
          active: true
        }
        return updatedFormValues;
      });
    }
  };

  const handleDropdownChange = (event: SelectChangeEvent<typeof formValues.roles>) => {
    const { value } = event.target;
    setFormValues({
      ...formValues,
      roles: typeof value === 'string' ? value.split(',') : value,
    });
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value} = event.target; 
    setFormValues({ ...formValues, [name]: value });
  };
  const tooltipIcon = {
    fontSize:'15px',
    color: '#868e96',
    marginLeft: '.3rem',
  }
  const handleAddUser = async() => {
    let response;
    if (isEditMode && formValues.sioId) {
      const payload = {
        id: initialUserState.id ? initialUserState.id : '',
        sio: {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          roles: formValues.roles,
          active: formValues.active,
        },
      };
      response = await dispatch(updateUserThunk(payload));
    } else {
      const payload = {
        sioId:id,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        username: formValues.username,
        roles: formValues.roles,
      };
      response = await dispatch(addNewSioUserThunk(payload));
    }
    if (response && response.payload) {
      toast.success(isEditMode ? 'User Updated Successfully' : 'New User added Successfully');
      setFormValues(clearFormState)
      onClose();
      updateTable();
    } else {
      toast.error('Failed to Updated, Please try again');
      setFormValues(clearFormState)
    }
   setFormValues(clearFormState)
  };

  const commonTextStyle = {
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{padding:'10px','& .MuiDialogContent-root': { overflowX: 'hidden' },'& .MuiDialog-paper':{maxWidth    :'900px'}}} >
      <Typography sx={{padding:'30px',fontSize:'24px',fontFamily:'"Transducer", sans-serif'}}>{isEditMode ? 'Edit User' : 'Add New User'}</Typography>
      <DialogContent sx={{padding:'30px'}}>
      <Grid container spacing={2}>
          <Grid item xs={12} sm={6} sx={{gap:'1rem'}}>
          <Typography sx={ commonTextStyle.labelStyle }>First Name <Tooltip title="Enter The First Name"><InfoIcon sx={{...tooltipIcon}}/></Tooltip></Typography>
            <TextField sx={ commonTextStyle.textFieldStyle } name='firstName' placeholder='Enter the First Name' value={formValues.firstName} onChange={handleInputChange} fullWidth variant='outlined' />
          </Grid>
          <Grid item xs={12} sm={6} >
          <Typography sx={ commonTextStyle.labelStyle }>Last Name <Tooltip title="Enter The Last Name"><InfoIcon sx={{...tooltipIcon}}/></Tooltip></Typography>
            <TextField sx={ commonTextStyle.textFieldStyle } name='lastName' placeholder='Enter the Last Name' value={formValues.lastName} onChange={handleInputChange} fullWidth variant='outlined'  />
          </Grid>
          
          <Grid item xs={12} sm={6} >
          <Typography sx={ commonTextStyle.labelStyle }>Email Id <Tooltip title="Enter The Email Id"><InfoIcon sx={{...tooltipIcon}}/></Tooltip></Typography>
            <TextField sx={ commonTextStyle.textFieldStyle } disabled={isEditMode} name='username'  value={formValues.username}  placeholder='Enter the Email Id' onChange={handleInputChange} fullWidth variant='outlined' />
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography sx={ commonTextStyle.labelStyle }>User Role <Tooltip title="Select User Role"><InfoIcon sx={{...tooltipIcon}}/></Tooltip></Typography>
          <FormControl fullWidth>
                <Select
                  labelId="Role"
                  variant="outlined"
                  name="roles" 
                  value={formValues.roles ? formValues.roles : []} 
                  onChange={handleDropdownChange} 
                  sx={{
                    ...commonTextStyle.textFieldStyle,
                    '& .MuiSelect-select': {
                      fontSize: fonts.fontSize14,
                      paddingTop: '27px',
                      height: '100%',
                    },
                  }}
                  multiple
                >
                 {selectOptions}
                </Select>
              </FormControl>
          </Grid>
  { isEditMode &&   (     <Grid item xs={12} sm={6}>
          <Typography sx={{ marginBottom: '-1px' }}>User Status <Tooltip title="Select the User Status"><InfoIcon sx={{...tooltipIcon}}/></Tooltip></Typography>
          <FormControlLabel
          control={
            <Switch checked={checked} onChange={handleSwitchChange} name="active" />
          }
          label="Active"
        />
          </Grid>)}
          </Grid>
      </DialogContent>
      <DialogActions sx={{padding:'30px',display:"flex",gap:'1rem'}} >
        <Button onClick={onClose} variant="outlined" sx={commonTextStyle.buttonStyle}>Cancel</Button>
        <Button onClick={handleAddUser} variant="contained" sx={{ ...commonTextStyle.buttonStyle, backgroundColor: colors.primary, color: colors.secondary }}>
        {isEditMode ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserPopup;
