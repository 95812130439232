import { Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlexBox, ThankYouTypography } from '../../shared/components';
import { FinancialChoice, RegisterStep } from '../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { platform } from '../../shared/utils';
import { setRegisterDetailsFinancialChoice, setRegisterStepperStep } from './registerDetailsSlice';

export const RegisterFinancialChoice = () => {
  const financialChoice = useAppSelector((x) => x.registerDetails.financialChoice);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setRegisterStepperStep(RegisterStep.AccountSelection));
  }, [dispatch]);

  const handleNext = () => {
    switch (financialChoice) {
      case FinancialChoice.IamReady:
        dispatch(setRegisterStepperStep(RegisterStep.SubscriptionSelection));
        return navigate('/register-details/subscription');
      case FinancialChoice.InviteFinancialUser:
        dispatch(setRegisterStepperStep(RegisterStep.Finalize));
        return navigate('/register-details/finalize');
      default:
        return null;
    }
  };

  return (
    <FlexBox
      sx={{
        marginTop: 4,
        flexDirection: 'column',
        flex: 1,
        color: 'black',
        gap: 2,
      }}
    >
      <ThankYouTypography variant="h3" sx={{ marginBottom: 4 }}>
        Thank you for registering with {platform()[0].toUpperCase() + platform().slice(1)}With.
      </ThankYouTypography>
      <Typography>
        We are ready to help you leverage social impact & sustainability to win more deals.
      </Typography>
      <Typography>
        The last step to set up your account is to tell us how you want to pay for your {platform()[0].toUpperCase() + platform().slice(1)}With subscription.
      </Typography>
      <RadioGroup
        value={financialChoice}
        onChange={(e) => dispatch(setRegisterDetailsFinancialChoice(+e.target.value))}
        sx={{ display: 'flex', gap: 2 }}
      >
        <FormControlLabel
          value={FinancialChoice.IamReady}
          control={<Radio />}
          label={
            <Typography>
              I am ready to make those choices & enter my payment info (credit card or bank debit
              info, ACH).
            </Typography>
          }
        />
        <FormControlLabel
          value={FinancialChoice.InviteFinancialUser}
          control={<Radio />}
          label={
            <Typography>
              I want to enter the name and email address of the person at my company to whom you can
              contact to make those choices and enter payment information.
            </Typography>
          }
        />
      </RadioGroup>
      <FlexBox sx={{ flexDirection: 'row-reverse', marginTop: 2 }}>
        <Button
          variant="contained"
          size="large"
          disabled={financialChoice < FinancialChoice.IamReady}
          sx={{ padding: 2, width: 200 }}
          onClick={() => handleNext()}
        >
          Next
        </Button>
      </FlexBox>
    </FlexBox>
  );
};
