import React, { useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Grid, Typography, Divider, Button, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InputAdornment from '@mui/material/InputAdornment';
import { toast } from 'react-toastify';
import { DateTimePicker } from '@mui/x-date-pickers';
import TextAreaInput from '../../../shared/components/common/textAreaInput';
import BoxSection from '../../../shared/components/common/boxSection';
import GridInput from '../../../shared/components/common/gridTextField';
import Progressed from '../../../assets/svg/progressed.svg';
import DeleteIcon from '../../../assets/svg/Delete.svg';
import InfoIcon from '../../../assets/svg/info-icon.svg';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface props {
  funding: any;
  setCompletionProgramData: any;
  submitted?: boolean;
  validatedQuestions: (count: number) => void;
  isSaveProgress: boolean;
  setProcess: () => void;
  fundingAmount: 0;
  submitResponse: boolean;
}
interface programFunding {
  itemization: {
    value: [
      {
        description: string;
        quantity: number;
      },
    ];
  };
  programDate: {
    start: any;
    end: any;
  };
  location: {
    value: string;
  };
}

const ProgramFundingInformation: React.FC<props> = ({
  funding,
  setCompletionProgramData,
  submitted,
  validatedQuestions,
  isSaveProgress,
  setProcess,
  fundingAmount,
  submitResponse,
}) => {
  const [fundingData, setFundingData] = useState<programFunding>();
  const [validatedAnswers, setvalidateAnswers] = useState({
    programDate: {
      start: 0,
      end: 0,
    },
    location: {
      value: 0,
    },
  });

  const [count, setCount] = useState(3);
  const [totalAmount, setTotalAmount] = useState(0);
  const [showAddItem, setShowAddItem] = useState(false);
  const [locationDesc, setLocationDesc] = useState('');

  useEffect(() => {
    setFundingData(funding);
    const totalQuantity = funding?.itemization?.value?.reduce(
      (total: any, item: any) => total + item.quantity,
      0,
    );
    setTotalAmount(totalQuantity);
    setCount(funding?.itemization?.value?.length);
    setLocationDesc(funding?.location?.value);
    setvalidateAnswers({
      programDate: {
        start: funding?.programDate?.start ? 1 : 0,
        end: funding?.programDate?.end ? 1 : 0,
      },
      location: {
        value: funding?.location.value ? 1 : 0,
      },
    });
  }, [funding]);

  function formatDate(dateString: any) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  const handleIndexChange = (value: any, key: string, index: number) => {
    const targetValue = key === 'quantity' ? Number(value) : value;
    setFundingData((prevValues: any) => ({
      ...prevValues,
      itemization: {
        ...prevValues.itemization,
        value: prevValues.itemization.value.map((item: any, itemIndex: number) => {
          if (itemIndex === index) {
            return {
              ...item,
              [key]: targetValue,
            };
          }
          return item;
        }),
      },
    }));
  };

  const countIncrement = () => {
    setCount((prev: number) => prev + 1);
    fundingData?.itemization.value.push({
      quantity: 0,
      description: '',
    });
  };

  const handleDelete = (index: number) => {
    const updatedItemization = [...(fundingData?.itemization?.value || [])];
    updatedItemization.splice(index, 1);
    setFundingData((prevState: any) => ({
      ...prevState,
      itemization: {
        ...prevState.itemization,
        value: updatedItemization,
      },
    }));
    setCount((prevCount) => prevCount - 1);
  };

  useEffect(() => {
    const sum = fundingData?.itemization?.value.reduce(
      (total: number, item: any) => total + item.quantity,
      0,
    );
    setTotalAmount(Number(sum));
    if (Number(sum) <= Number(Math.round(fundingAmount))) {
      setShowAddItem(false);
    } else if (Number(sum) > Number(Math.round(fundingAmount))) {
      setShowAddItem(true);
      toast.error('Quantity values count should not be more than itemized value');
    }
  }, [fundingData?.itemization?.value]);

  const validateFields = (value: string) => (value === '' || value === undefined ? 0 : 1);

  const handleChange = (event: any, key: string, parentKey: string, isRequired: boolean) => {
    setFundingData((prevState: any) => ({
      ...prevState,
      [parentKey]: {
        ...prevState[parentKey],
        [key]: !['start', 'end'].includes(key) ? event.target.value : event,
      },
    }));
    if (isRequired) {
      setvalidateAnswers((prevState: any) => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [key]: validateFields(!['start', 'end'].includes(key) ? event.target.value : event),
        },
      }));
    }
  };

  const handleDateChange = (event: any, key: string, parentKey: string, isRequired: boolean) => {
    if (key === 'start') {
      if (fundingData?.programDate?.end === '') {
        handleChange(event, key, parentKey, isRequired);
      } else if (formatDate(event) <= formatDate(fundingData?.programDate?.end)) {
        handleChange(event, key, parentKey, isRequired);
      } else {
        toast.error('From date must be less than End date');
      }
    } else if (key === 'end') {
      if (formatDate(fundingData?.programDate?.start) <= formatDate(event)) {
        handleChange(event, key, parentKey, isRequired);
      } else {
        toast.error('End date must be greater than From date');
      }
    }
  };

  useEffect(() => {
    if (isSaveProgress || submitResponse) {
      setCompletionProgramData(
        {
          itemization: fundingData?.itemization,
          programDate: fundingData?.programDate,
          location: {
            value: locationDesc,
          },
        },
        'funding',
      );
      setProcess();
    }
  }, [isSaveProgress, submitResponse]);

  useEffect(() => {
    const programDataLength = Object.values(validatedAnswers?.programDate)?.filter(
      (value) => value === 1,
    )?.length;
    const locationDataLength = Object.values(validatedAnswers?.location)?.filter(
      (value) => value === 1,
    )?.length;
    validatedQuestions(programDataLength + locationDataLength);
  }, [validatedAnswers]);

  const commonTextStyle = {
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      }
    }
  }

  return (
    <>
      <BoxSection>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
            Question 1 of 3
            {!submitted ? (
              <sup style={{ color: colors.statusAlert }}>*</sup>
            ) : (
              <img
                src={Progressed}
                style={{ height: '14px', marginLeft: '5px' }}
                alt="Progressed"
              />
            )}
          </Typography>
          <TextAreaInput title="What are the start and end dates of this program?" />
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ mb: 2 }}>Start Date</Typography>
            <DateTimePicker
              value={fundingData?.programDate.start}
              onChange={(event) => {
                handleDateChange(event, 'start', 'programDate', true);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" sx={{ ...commonTextStyle.textFieldStyle, width: '100%' }} />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ mb: 2 }}>End Date</Typography>
            <DateTimePicker
              value={fundingData?.programDate.end}
              onChange={(event) => {
                handleDateChange(event, 'end', 'programDate', true);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" sx={{ ...commonTextStyle.textFieldStyle, width: '100%' }} />
              )}
            />
          </Grid>
        </Grid>
      </BoxSection>
      <BoxSection>
        <Grid item xs={7}>
          <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
            Question 2 of 3
            {!submitted ? (
              <sup style={{ color: colors.statusAlert }}>*</sup>
            ) : (
              <img
                src={Progressed}
                style={{ height: '14px', marginLeft: '5px' }}
                alt="Progressed"
              />
            )}
          </Typography>
          <TextAreaInput
            title="Please specify the location where this funding will be applied?"
            secondaryTitle="Location Description"
            isTextField
            infoIcon
            tooltipText="Location Description"
            placeholder="Location description..."
            value={locationDesc}
            required
            disabled={submitted}
            onChange={(event) => {
              setLocationDesc(event.target.value);
              if (event.target.value.length === 1 || event.target.value.length === 0) {
                setvalidateAnswers((prevState: any) => ({
                  ...prevState,
                  location: {
                    ...prevState.location,
                    value: event.target.value,
                  },
                }));
              }
            }}
          />
        </Grid>
      </BoxSection>
      <BoxSection>
        <Typography sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 3 of 3
          {submitted && (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight600, color: colors.textPrimary }}>How will this funding be used</Typography>
          <GridInput
            typographyText="Please ensure itemization adds up to the total funding amounts?"
            adornmentText="$"
            helperText={`of ${Math.round(fundingAmount)} itemized`}
            type="text"
            value={totalAmount}
            colWidth={3}
            onChange={(event: any) => setTotalAmount(event.target.value)}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Divider sx={{ marginBottom: '10px' }} />
        </Grid>
        {[...Array(count)].map((countVal: any, index: number) => {
          const indexVal = `indexKey${index}`;
          return (
            <Grid container spacing={2} key={indexVal}>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <span>{`Item ${index + 1} Quantity`}</span>
                  <Tooltip title="quantity" placement="top" arrow TransitionComponent={Zoom}>
                    <img
                      src={InfoIcon}
                      style={{ marginLeft: '10px', marginTop: '5px' }}
                      alt="info Icon"
                    />
                  </Tooltip>
                </Typography>
                <TextField
                  type="number"
                  value={fundingData && fundingData?.itemization?.value[index]?.quantity || ''}
                  onChange={(event) => {
                    handleIndexChange(event.target.value, 'quantity', index);
                  }}
                  margin="normal"
                  variant="outlined"
                  disabled={submitted}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span>$</span>
                      </InputAdornment>
                    ),
                    sx: {
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '16px',
                      },
                      '& .MuiInputBase-input': {
                        fontSize: '16px',
                      },
                      '& input[type=number]': {
                        '-moz-appearance': 'textfield',
                      },
                      '& input[type=number]::-webkit-outer-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                      '& input[type=number]::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                      },
                      backgroundColor: `${submitted && '#f2f2f3'}`,
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '40px',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <GridInput
                  typographyText={`Item ${index + 1} Description`}
                  type="text"
                  infoIcon
                  tooltipText="Description"
                  value={fundingData && fundingData?.itemization?.value[index]?.description}
                  onChange={(event) => handleIndexChange(event.target.value, 'description', index)}
                  disabled={submitted}
                />
              </Grid>
              <Grid item xs={1} onClick={() => handleDelete(index)}>
                {!submitted && <img
                  src={DeleteIcon}
                  style={{ marginLeft: '40px', cursor: 'pointer' }}
                  alt="Delete"
                />}
              </Grid>
            </Grid>
          );
        })}
        <Button
          sx={{ color: colors.primary, padding: '0px !important' }}
          disabled={showAddItem || submitted}
          onClick={countIncrement}
        >
          <AddCircleOutlineIcon sx={{ marginRight: '10px' }} />
          <span>Add Item</span>
        </Button>
      </BoxSection>
    </>
  );
};

export default ProgramFundingInformation;
