import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addCancelTokenEvent,
  getContributionTotals,
  getTransactions,
  postTransaction,
} from '../../../api';
import { DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import {
  Account,
  GridResponse,
  GridModel,
  Transaction,
  TransactionGridRequest,
  AccountContributionTotals,
} from '../../../shared/interfaces';
import { ContributionStatus } from '../../../shared/enums';

interface AccountTransactionsState {
  gridState: { grid: GridModel<Transaction> };
  addTransactionState: {
    sageInvoiceId: string;
    amount?: number;
    memo: string;
    loading: boolean;
  };
  getAccountContributionTotalsState: {
    currentBalance: number;
    completedYTD: number;
    completedMTD: number;
    approvedContributionsCount: number;
    approvedContributionsTotal: number;
    loading: boolean;
  };
  success: boolean;
}

const initialState: AccountTransactionsState = {
  gridState: {
    grid: {
      name: 'accountTransactions',
      data: { totalCount: 0, results: [] },
      page: 0,
      defaultSort: { field: 'createdAt', direction: 'desc' },
      pageSize: DEFAULT_PAGE_SIZE,
      loadingGrid: false,
      filter: {},
    },
  },
  addTransactionState: {
    sageInvoiceId: '',
    memo: '',
    amount: undefined,
    loading: false,
  },
  getAccountContributionTotalsState: {
    currentBalance: 0,
    completedYTD: 0,
    completedMTD: 0,
    approvedContributionsCount: 0,
    approvedContributionsTotal: 0,
    loading: false,
  },
  success: false,
};

export const getAccountTransactionsContributionTotalsThunk = createAsyncThunk(
  'accountTransactions/getAccountTransactionsContributionTotalsThunk',
  async (accountId: string, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getContributionTotals(accountId);
    return data;
  },
);

export const getAccountTransactionsThunk = createAsyncThunk(
  'accountTransactions/getAccountTransactionsThunk',
  async ({ request, pageNumber }: { request: TransactionGridRequest, pageNumber: number }, { signal }) => {
    const requestParams = {...request, count: 10, offset: pageNumber * 10}
    addCancelTokenEvent(signal);
    const { data } = await getTransactions(requestParams);
    return data;
  },
);

export const postAccountTransactionsThunk = createAsyncThunk(
  'accountTransactions/postAccountTransactionsThunk',
  async (
    request: {
      accountId: string;
      accountName: string;
      sageInvoiceId: string;
      amount: number;
      memo: string;
    },
    { signal },
  ) => {
    const { accountId, accountName, amount, memo, sageInvoiceId } = request;
    addCancelTokenEvent(signal);
    const { status } = await postTransaction({
      account: { id: accountId, name: accountName },
      amount,
      memo,
      sageInvoiceId,
    });
    return status;
  },
);

const accountTransactions = createSlice({
  name: 'accountTransactions',
  initialState,
  reducers: {
    setAccountTransactionsGridModel: (
      state,
      action: PayloadAction<Partial<GridModel<Account>>>,
    ) => {
      _.assign(state.gridState.grid, action.payload);
    },
    clearAccountTransactionsState: (state) => {
      _.assign(state, initialState);
    },
    resetAccountTransactionsSuccess: (state) => {
      state.success = false;
    },
    clearAccountTransactionsFormState: (state) => {
      state.addTransactionState = initialState.addTransactionState;
    },
    setAccountTransactionsFormMemo: (state, action: PayloadAction<string>) => {
      state.addTransactionState.memo = action.payload;
    },
    setAccountTransactionsFormSageId: (state, action: PayloadAction<string>) => {
      state.addTransactionState.sageInvoiceId = action.payload;
    },
    setAccountTransactionsFormAmount: (state, action: PayloadAction<number | undefined>) => {
      state.addTransactionState.amount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAccountTransactionsThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<Transaction>>) => {
          state.gridState.grid.loadingGrid = false;
          state.gridState.grid.data = action.payload;
        },
      )
      .addCase(getAccountTransactionsThunk.rejected, (state) => {
        state.gridState.grid.loadingGrid = false;
      })
      .addCase(getAccountTransactionsThunk.pending, (state) => {
        if (!state.gridState.grid.loadingGrid) state.gridState.grid.loadingGrid = true;
      })
      .addCase(
        getAccountTransactionsContributionTotalsThunk.fulfilled,
        (state, action: PayloadAction<AccountContributionTotals>) => {
          state.getAccountContributionTotalsState.loading = false;
          state.getAccountContributionTotalsState.currentBalance = action.payload.currentBalance;
          state.getAccountContributionTotalsState.completedMTD = action.payload.completedMTD;
          state.getAccountContributionTotalsState.completedYTD = action.payload.completedYTD;
          const approvedContributionTotals = action.payload.totalsByStatus.find(
            (x) => x.status === ContributionStatus.Approved,
          );
          state.getAccountContributionTotalsState.approvedContributionsCount =
            approvedContributionTotals?.count ?? 0;
          state.getAccountContributionTotalsState.approvedContributionsTotal =
            approvedContributionTotals?.total ?? 0;
        },
      )
      .addCase(getAccountTransactionsContributionTotalsThunk.rejected, (state) => {
        state.getAccountContributionTotalsState.loading = false;
      })
      .addCase(getAccountTransactionsContributionTotalsThunk.pending, (state) => {
        if (!state.getAccountContributionTotalsState.loading)
          state.getAccountContributionTotalsState.loading = true;
      })
      .addCase(postAccountTransactionsThunk.fulfilled, (state) => {
        state.success = true;
        state.addTransactionState.loading = false;
      })
      .addCase(postAccountTransactionsThunk.pending, (state) => {
        state.addTransactionState.loading = true;
      });
  },
});

export const {
  setAccountTransactionsGridModel,
  clearAccountTransactionsState,
  resetAccountTransactionsSuccess,
  clearAccountTransactionsFormState,
  setAccountTransactionsFormAmount,
  setAccountTransactionsFormMemo,
  setAccountTransactionsFormSageId,
} = accountTransactions.actions;

export const accountTransactionsReducer = accountTransactions.reducer;
