import { Box, Button, Tab, Tabs, createTheme, ThemeProvider } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { AccountSharedForm, FormDialog, isAccountFormInvalid } from '../../../shared/components';
import {
  AccountSettingsTabs,
  AccountStatus,
  AccountType,
  Dialogs,
  InstanceSettingsType,
  UserRole,
} from '../../../shared/enums';
import { useAppDispatch, useAppSelector, useAuth, useInstance } from '../../../shared/hooks';
import {
  AccountFormConfig,
  AccountGiveSettingsConfig,
  AccountResourceConfig,
} from '../../../shared/interfaces';
import {
  clearAccountSettingsState,
  getAccountApprovalLevelsThunk,
  getAccountSettingsAccountThunk,
  saveAccountSettingsThunk,
  setAccountSettingsAccountData,
  setAccountSettingsTab,
} from './accountSettingsSlice';
import { AccountApprovalLevels } from './AccountApprovalLevels';
/* removed isAccountGiveSettingsInvalid in below line */
import { AccountGiveSettings } from './AccountGiveSettings';
import { isNullOrWhitespace } from '../../../shared/utils';
import {
  ENABLE_SAVE_SETTINGS,
  ENABLE_GENERAL_ACCOUNT_SAVE,
  GIVEWITH_ROLES,
  GIVEWITH_ROLES_EDIT_TYPE,
} from '../../../shared/constants';
import { AccountSettingsSubscription } from './AccountSettingsSubscription';
import { AccountSettingsPayment } from './AccountSettingsPayment';
import { getAppAccountThunk } from '../../../app';
import { AccountSettingsResource } from './AccountSettingsResource';
import { InstanceSettingsAccounts } from './instanceSettingsAccounts';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

export function AccountSettings() {
  const [isValidWebsite, setIsValidWebsite] = useState<boolean>(false);
  const state = useAppSelector((x) => x.accountSettings);
  const appState = useAppSelector((x) => x.app);
  const dispatch = useAppDispatch();
  const [isUserInRole] = useAuth();
  const { locale, instance: associatedInstance, loadingUserData } = useInstance();
  const { userClaims, account: accountApp } = appState;
  const { accountId } = { ...userClaims };
  const { instance: instanceCurrentAccount } = { ...accountApp };
  const { settings } = { ...locale };
  const { regions, regionLabel } = { ...settings };
  const {
    selectedTab,
    general,
    approvalLevels,
    success,
    editAccountId,
    isAccountNew,
    giveSettings,
    submitLoading,
    payment: autopay,
    // subscription,
  } = state;
  const { loading: approvalLevelsLoading } = approvalLevels;
  const { account, loading: generalLoading, modified: generalModified } = general;
  const {
    status,
    industry,
    company,
    createdBy,
    createdAt,
    lastUpdatedBy,
    lastUpdated,
    sageCustomerId,
    instance,
    giveFields,
    type,
    procurement,
  } = { ...account };
  const { name, address, phoneNumber, website, countryCode, companyLogo } = { ...company };
  const { address1, address2, city, stateProvince, postalCode, country } = { ...address };
  const { showCustomField1, showCustomField2, showCustomField3, showCustomField4 } = giveSettings;
  /* removeddisable: disablePayment in below line */
  const { disabled: disabledAutopay } = autopay;
  const { supplierTermsOfUseText, supplierTermsOfUseUrl } = { ...procurement };
  // const { cancel: cancelSubscription } = subscription; commented as it was not using
  const {
    customerId,
    customerName,
    description,
    quoteAmount,
    quoteNumber,
    customField1,
    customField2,
    customField3,
    customField4,
  } = { ...giveFields };
  const accountRef = useRef<HTMLDivElement>(null);

  const isGivewithUser = isUserInRole(GIVEWITH_ROLES);
  const isGivewithPermissionToEdit = isUserInRole(GIVEWITH_ROLES_EDIT_TYPE);
  const isProcurementUser = () => type === AccountType.procurement;
  const isSelectSubscriptionOrPayment =
    selectedTab === AccountSettingsTabs.Subscription || selectedTab === AccountSettingsTabs.Payment;

  const formDisabled = !isUserInRole([
    UserRole.SuperAdmin,
    UserRole.OrgAdmin,
    UserRole.Finance,
    UserRole.GivewithOperations,
    UserRole.GivewithFinance,
  ]);
  const isFinanceUser = isUserInRole([UserRole.Finance]);

  const accountSettingsTabsModified = () =>
    generalModified || !_.isEqual(approvalLevels.initialData, approvalLevels.data);

  const [isSaveButton, setIsSaveButton] = useState(true);

  // const [uploadFile, setUploadFile] = useState({ name: '', url: '' });

  useEffect(() => {
    if (!isAccountNew) {
      dispatch(getAccountSettingsAccountThunk());
      dispatch(getAccountApprovalLevelsThunk());
    }
    return () => {
      dispatch(clearAccountSettingsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAccountNew && isNullOrWhitespace(instance?.id)) {
      dispatch(setAccountSettingsAccountData({ instance: instanceCurrentAccount }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountNew]);

  useEffect(() => {
    if (success) toast.success(isAccountNew ? 'Account added' : 'Account information saved');
  }, [isAccountNew, success]);

  const accountFormConfig: AccountFormConfig = isAccountNew
    ? {
        companyControl: { value: name!, required: true },
        industryControl: { value: industry!, required: true },
        addressLine1Control: { value: address1!, required: true },
        addressLine2Control: { value: address2! },
        cityControl: { value: city!, required: true },
        stateControl: { value: stateProvince!, required: true, label: regionLabel },
        zipCodeControl: { value: postalCode!, required: true },
        phoneNumberControl: { value: phoneNumber!, required: true },
        companyWebsiteControl: { value: website! },
        companyLogoControl: { value: companyLogo! },
        sageCustomerIdControl: { value: sageCustomerId! },
        countryCodeControl: { value: countryCode!, required: status! === AccountStatus.Active },
        countryControl: { value: country!, required: status! === AccountStatus.Active },
        instanceControl: {
          value: instance?.id || instanceCurrentAccount?.id!,
          required: true,
          disabled: !isGivewithUser,
        },
        typeControl: {
          value: type!,
          required: true,
          disabled: !isGivewithPermissionToEdit,
        },
      }
    : {
        companyControl: { value: name!, required: true },
        statusControl: { value: status!, required: true, disabled: !isGivewithUser },
        industryControl: { value: industry!, required: true },
        addressLine1Control: { value: address1!, required: true },
        addressLine2Control: { value: address2! },
        cityControl: { value: city!, required: true },
        stateControl: { value: stateProvince!, required: true, label: regionLabel },
        zipCodeControl: { value: postalCode!, required: true },
        phoneNumberControl: { value: phoneNumber!, required: true },
        companyWebsiteControl: { value: website! },
        companyLogoControl: { value: companyLogo! },
        sageCustomerIdControl: { value: sageCustomerId!, disabled: !isGivewithUser },
        createdAtControl: { value: createdAt! },
        createdByControl: { value: createdBy! },
        lastUpdatedControl: { value: lastUpdated! },
        lastUpdatedByControl: { value: lastUpdatedBy! },
        countryCodeControl: { value: countryCode!, required: status! === AccountStatus.Active },
        countryControl: { value: country!, required: status! === AccountStatus.Active },
        instanceControl: {
          value: instance?.id!,
          required: status! === AccountStatus.Active,
          disabled: !isGivewithUser,
        },
        typeControl: {
          value: type!,
          required: true,
          disabled: !isGivewithPermissionToEdit,
        },
      };

  const accountGiveSettingsConfig: AccountGiveSettingsConfig = {
    customForm1Control: { value: customField1?.label!, label: 'Custom Title One', required: true },
    customForm2Control: { value: customField2?.label!, label: 'Custom Title Two', required: true },
    customForm3Control: {
      value: customField3?.label!,
      label: 'Custom Title Three',
      required: true,
    },
    customForm4Control: { value: customField4?.label!, label: 'Custom Title four', required: true },
    customForm1RequiredControl: {
      value: customField1?.required === undefined ? false : customField1?.required,
    },
    customForm2RequiredControl: {
      value: customField2?.required === undefined ? false : customField2?.required,
    },
    customForm3RequiredControl: {
      value: customField3?.required === undefined ? false : customField3?.required,
    },
    customForm4RequiredControl: {
      value: customField4?.required === undefined ? false : customField4?.required,
    },
    customForm1DefaultValueControl: {
      value: customField1?.defaultValue!,
      label: 'Default Value',
      required: true,
    },
    customForm2DefaultValueControl: {
      value: customField2?.defaultValue!,
      label: 'Default Value',
      required: true,
    },
    customForm3DefaultValueControl: {
      value: customField3?.defaultValue!,
      label: 'Default Value',
      required: true,
    },
    customForm4DefaultValueControl: {
      value: customField4?.defaultValue!,
      label: 'Default Value',
      required: true,
    },
    customerIdControl: { value: customerId?.label!, label: 'Customer Id Title', required: true },
    customerNameControl: {
      value: customerName?.label!,
      label: 'Customer Name Title',
      required: true,
    },
    descriptionControl: { value: description?.label!, label: 'Description Title', required: true },
    quoteAmountControl: { value: quoteAmount?.label!, label: 'Quote Amount Title', required: true },
    quoteNumberControl: { value: quoteNumber?.label!, label: 'Quote Number Title', required: true },
    customerIdDefaultValueControl: { value: customerId?.defaultValue!, label: 'Default Value' },
    customerNameDefaultValueControl: { value: customerName?.defaultValue!, label: 'Default Value' },
    descriptionDefaultValueControl: { value: description?.defaultValue!, label: 'Default Value' },
    quoteAmountDefaultValueControl: { value: quoteAmount?.defaultValue!, label: 'Default Value' },
    quoteNumberDefaultValueControl: { value: quoteNumber?.defaultValue!, label: 'Default Value' },
    customerIdRequiredControl: { value: customerId?.required! },
    customerNameRequiredControl: { value: true, disabled: true },
    descriptionRequiredControl: { value: description?.required! },
    quoteAmountRequiredControl: { value: quoteAmount?.required! },
    quoteNumberRequiredControl: { value: quoteNumber?.required! },
    showCustomField1,
    showCustomField2,
    showCustomField3,
    showCustomField4,
  };

  const accountResourceConfig: AccountResourceConfig | undefined = isProcurementUser()
    ? {
        supplierTermsOfUseText: {
          value: supplierTermsOfUseText!,
          label: 'Suppliers Terms & Conditions - Text',
        },
        supplierTermsOfUseURL: {
          value: supplierTermsOfUseUrl!,
          label: 'Suppliers Terms & Conditions - URL',
        },
      }
    : undefined;

  const isRequiredSubscription = () => {
    if (associatedInstance?.type === InstanceSettingsType.default) {
      return associatedInstance?.settings?.stripe?.prices?.required !== undefined
        ? associatedInstance?.settings?.stripe?.prices?.required
        : false;
    }
    if (
      type === AccountType.procurement &&
      associatedInstance?.type === InstanceSettingsType.procurement
    ) {
      return associatedInstance?.settings?.stripe?.prices?.required !== undefined
        ? associatedInstance?.settings?.stripe?.prices?.required
        : false;
    }
    if (
      type === AccountType.supplier &&
      associatedInstance?.type === InstanceSettingsType.procurement
    ) {
      return associatedInstance?.settings?.stripe?.suppliers?.required !== undefined
        ? associatedInstance?.settings?.stripe?.suppliers?.required
        : false;
    }
    return true;
  };

  const isSaveValid = (stripe?: boolean) => {
    if (isAccountFormInvalid(accountFormConfig)) {
      toast.error('Please fill all mandatory fields in General Tab');
      dispatch(setAccountSettingsTab(AccountSettingsTabs.General));
      return false;
    }
    // if (!isProcurementUser() && isAccountGiveSettingsInvalid(accountGiveSettingsConfig)) {
    //   toast.warning('Impact settings tab error');
    //   dispatch(setAccountSettingsTab(AccountSettingsTabs.GiveSettings));
    //   return false;
    // }

    if (approvalLevels.mode !== 'list') {
      toast.error('Please fill all mandatory fields in Approval Levels Tab');
      dispatch(setAccountSettingsTab(AccountSettingsTabs.ApprovalLevels));
      return false;
    }
    if (stripe) return true;

    // if (cancelSubscription && isRequiredSubscription()) {
    //   toast.warning('Subscription tab error');
    //   dispatch(setAccountSettingsTab(AccountSettingsTabs.Subscription));
    //   return false;
    // }
    // if (disablePayment) {
    //   toast.warning('Payment tab error');
    //   dispatch(setAccountSettingsTab(AccountSettingsTabs.Payment));
    //   return false;
    // }

    return true;
  };

  const saveAccount = () => {
    dispatch(saveAccountSettingsThunk(true));
    if (!isAccountNew) dispatch(getAppAccountThunk(accountId ?? '-1'));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    accountRef.current?.scrollIntoView();
    dispatch(setAccountSettingsTab(newValue));
  };

  const getTitle = () => {
    if (isAccountNew) return 'Add Account';
    return !isNullOrWhitespace(editAccountId) ? 'Edit Account' : 'Account Settings';
  };

  if (!account) return <p>Invalid account data...</p>;
  const validDomains = [
    '.com',
    '.in',
    '.edu',
    '.org',
    '.gov',
    '.mil',
    '.us',
    '.au',
    '.ca',
    '.uk',
    '.eu',
    '.fr',
    '.co',
    '.health',
    '.bank',
  ];
  const hideSaveButton = (isHide: boolean) => {
    setIsSaveButton(isHide);
  };
  const getComponent = (tab: AccountSettingsTabs) => {
    switch (tab) {
      case AccountSettingsTabs.General:
        return (
          <AccountSharedForm
            accountId={accountId}
            companyLogo={companyLogo}
            formConfig={accountFormConfig}
            isAccountSettings
            setAccountFormData={(value) => {
              dispatch(setAccountSettingsAccountData(value));
              if (
                !isNullOrWhitespace(value?.company?.website) &&
                !validDomains.some((domain) => value?.company?.website!.includes(domain))
              ) {
                setIsValidWebsite(true);
              } else {
                setIsValidWebsite(false);
              }
            }}
            formDisabled={formDisabled || !isUserInRole(ENABLE_GENERAL_ACCOUNT_SAVE)}
            statesValues={regions}
          />
        );
      case AccountSettingsTabs.GiveSettings:
        return (
          <AccountGiveSettings
            formConfig={accountGiveSettingsConfig}
            setAccountFormData={(value) => dispatch(setAccountSettingsAccountData(value))}
            formDisabled={formDisabled || !isUserInRole(ENABLE_SAVE_SETTINGS)}
          />
        );
      case AccountSettingsTabs.Subscription:
        return (
          <AccountSettingsSubscription
            isSaveValid={isSaveValid}
            canEdit={isFinanceUser}
            accountSettingsTabsModified={accountSettingsTabsModified}
            type={Dialogs.AccountSettings}
          />
        );
      case AccountSettingsTabs.Payment:
        return (
          <AccountSettingsPayment
            isSaveValid={isSaveValid}
            isProcurementUser={isProcurementUser()}
            canEdit={isFinanceUser}
            isSubscriptionRequired={isRequiredSubscription()}
            accountSettingsTabsModified={accountSettingsTabsModified}
          />
        );
      case AccountSettingsTabs.StandardPrograms:
        return <InstanceSettingsAccounts hideSave={(isHide: boolean) => hideSaveButton(isHide)} />;
      case AccountSettingsTabs.Resources:
        return (
          <AccountSettingsResource
            AccountSettingsResourceConfig={accountResourceConfig}
            formDisabled={formDisabled}
          />
        );

      default:
        return (
          <AccountApprovalLevels
            formDisabled={formDisabled || !isUserInRole(ENABLE_SAVE_SETTINGS)}
          />
        );
    }
  };

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: colors.primary,
            height: '4px',
          },
        },
      },
    },
  });

  return (
    <Box sx={{ borderRadius: '15px !important' }}>
      <FormDialog
        title={getTitle()}
        childrenStyle={{ paddingTop: 0 }}
        type={Dialogs.AccountSettings}
        loading={generalLoading || approvalLevelsLoading || submitLoading || loadingUserData}
        hideCancel={isSelectSubscriptionOrPayment}
        closeFlag={success || disabledAutopay}
        Buttons={
          !isSelectSubscriptionOrPayment && isSaveButton ? (
            <Button
              variant="contained"
              size="large"
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight900,
                backgroundColor: colors.primary,
              }}
              disabled={
                formDisabled || !isUserInRole(ENABLE_GENERAL_ACCOUNT_SAVE) || isValidWebsite
              }
              onClick={() => {
                if (isSaveValid()) saveAccount();
              }}
            >
              Save
            </Button>
          ) : undefined
        }
        ExtraHeaderComponent={
          <ThemeProvider theme={theme}>
            <Tabs value={selectedTab} onChange={handleTabChange} sx={{ paddingX: 6,}}>
              <Tab
                value={AccountSettingsTabs.General}
                label="General"
                sx={{
                  textTransform: 'capitalize',
                  color:
                    selectedTab === AccountSettingsTabs.General
                      ? `${colors.primary} !important`
                      : `${colors.textPrimary} !important`,
                  fontWeight: selectedTab === AccountSettingsTabs.General ? fonts.fontWeight700 : fonts.fontWeight600,
                }}
              />
              {/* {!isProcurementUser() && (
            <Tab value={AccountSettingsTabs.GiveSettings} label="Impact Settings" />
          )} */}
              {isUserInRole([UserRole.OrgAdmin]) && (
                <Tab
                  value={AccountSettingsTabs.StandardPrograms}
                  label="Standard Programs"
                  sx={{
                    textTransform: 'capitalize',
                    color:
                      selectedTab === AccountSettingsTabs.StandardPrograms
                        ? `${colors.primary} !important`
                        : `${colors.textPrimary} !important`,
                    fontWeight: selectedTab === AccountSettingsTabs.StandardPrograms ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
              )}
              {!isProcurementUser() && (
                <Tab
                  value={AccountSettingsTabs.ApprovalLevels}
                  label="Approval Levels"
                  disabled={isAccountNew}
                  sx={{
                    textTransform: 'capitalize',
                    color:
                      selectedTab === AccountSettingsTabs.ApprovalLevels
                        ? `${colors.primary} !important`
                        : `${colors.textPrimary} !important`,
                    fontWeight: selectedTab === AccountSettingsTabs.ApprovalLevels ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
              )}
              {!isAccountNew && account?.type !== 'supplier' && (
                <Tab
                  value={AccountSettingsTabs.Subscription}
                  label="Subscription"
                  disabled={isAccountNew}
                  sx={{
                    textTransform: 'capitalize',
                    color:
                      selectedTab === AccountSettingsTabs.Subscription
                        ? `${colors.primary} !important`
                        : `${colors.textPrimary} !important`,
                    fontWeight: selectedTab === AccountSettingsTabs.Subscription ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
              )}
              {/* {!isAccountNew && (isRequiredSubscription() || !isProcurementUser()) && (
            <Tab value={AccountSettingsTabs.Payment} label="Payment" disabled={isAccountNew} />
          )} */}
              {isProcurementUser() && !isAccountNew && (
                <Tab
                  value={AccountSettingsTabs.Resources}
                  label="Resources"
                  sx={{
                    textTransform: 'capitalize',
                    color:
                      selectedTab === AccountSettingsTabs.Resources
                        ? `${colors.primary} !important`
                        : `${colors.textPrimary} !important`,
                    fontWeight: selectedTab === AccountSettingsTabs.Resources ? fonts.fontWeight700 : fonts.fontWeight700,
                  }}
                />
              )}
            </Tabs>
          </ThemeProvider>
        }
      >
        <Box ref={accountRef} sx={{ height: !isSelectSubscriptionOrPayment ? 450 : 589 }}>
          <Box sx={{ paddingTop: 2 }}>{getComponent(selectedTab)}</Box>
        </Box>
      </FormDialog>
    </Box>
  );
}
