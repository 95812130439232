import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import {
  PrecisionManufacturingOutlined,
  LocationOnOutlined,
  MailOutlined,
  PersonOutlined,
} from '@mui/icons-material';
import { FlexBox, FormDialog, PaddingRoundedBox } from '../../../shared/components';
import { Dialogs } from '../../../shared/enums';
import { getGenericHeight, isNullOrWhitespace } from '../../../shared/utils';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  clearViewSupplierState,
  getSupplierThunk,
  setViewSupplierCloseDialog,
} from './supplierDetailsSlice';

export const SupplierDetails = () => {
  const state = useAppSelector((x) => x.viewSupplier);
  const appState = useAppSelector((x) => x.app);
  const { account: currentAccount } = { ...appState };
  const dispatch = useAppDispatch();
  const { close, supplier, supplierId, loading } = { ...state };
  const { account } = { ...supplier };
  const { company } = { ...account };
  const { address } = { ...company };
  const { address1, address2, city, country, postalCode, stateProvince } = { ...address };

  useEffect(() => {
    if (isNullOrWhitespace(supplierId) || isNullOrWhitespace(currentAccount?.id)) return;
    dispatch(getSupplierThunk({ id: supplierId, accountId: currentAccount?.id! }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(clearViewSupplierState());
    },
    [dispatch],
  );

  const onClose = () => {
    dispatch(setViewSupplierCloseDialog(true));
  };

  return (
    <FormDialog
      title="Supplier Details"
      type={Dialogs.DetailsSupplier}
      loading={loading}
      hideCancel
      closeFlag={close}
      Buttons={
        <Button variant="contained" size="large" onClick={onClose}>
          Close
        </Button>
      }
    >
      <Box sx={{ height: getGenericHeight(400) }}>
        <FlexBox sx={{ flexDirection: 'column', gap: 1 }}>
          <Box>
            <Typography variant="h6">Account</Typography>
            <Typography variant="h3">{supplier.account.company?.name ?? ''}</Typography>
          </Box>
          <PaddingRoundedBox
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              backgroundColor: theme.palette.common.grey.main,
            })}
          >
            <FlexBox sx={{ gap: 1, alignItems: 'start' }}>
              <PrecisionManufacturingOutlined fontSize="large" />
              <FlexBox sx={{ flexDirection: 'column' }}>
                <Typography variant="body1">Industry </Typography>
                <Typography variant="h5">
                  {supplier.account.industry?.industry ?? ''}
                </Typography>
              </FlexBox>
            </FlexBox>
            <FlexBox sx={{ gap: 1, alignItems: 'start' }}>
              <LocationOnOutlined fontSize="large" />
              <FlexBox sx={{ flexDirection: 'column', flex: 1 }}>
                <Typography variant="body1">Address </Typography>
                <Typography variant="h5">
                  {isNullOrWhitespace(address1) ? '' : `${address1}, `}
                  {isNullOrWhitespace(address2) ? '' : `${address2}, `}
                  {isNullOrWhitespace(city) ? '' : `${city}, `}
                  {isNullOrWhitespace(stateProvince) ? '' : `${stateProvince}, `}
                  {isNullOrWhitespace(postalCode) ? '' : `${postalCode}, `}
                  {isNullOrWhitespace(country) ? '' : `${country}`}
                </Typography>
              </FlexBox>
            </FlexBox>
            <FlexBox sx={{ gap: 1, alignItems: 'start' }}>
              <MailOutlined fontSize="large" />
              <FlexBox sx={{ flexDirection: 'column' }}>
                <Typography variant="body1">Initial Contact </Typography>
                <Typography variant="h5">{supplier.user.username ?? ''}</Typography>
              </FlexBox>
            </FlexBox>
            <FlexBox sx={{ gap: 1, alignItems: 'start' }}>
              <PersonOutlined fontSize="large" />
              <FlexBox sx={{ flexDirection: 'column' }}>
                <Typography variant="body1">Initial Contact Name</Typography>
                <Typography variant="h5">{`${supplier.user.firstName} ${supplier.user.lastName}`}</Typography>
              </FlexBox>
            </FlexBox>
          </PaddingRoundedBox>
        </FlexBox>
      </Box>
    </FormDialog>
  );
};
