import React from 'react';
import { FileCopy as FileCopyIcon } from '@mui/icons-material';
import { Box } from '@mui/system';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Typography, IconButton, Tooltip } from '@mui/material';
import TextAreaInput from '../../shared/components/common/textAreaInput';
import { useAppSelector } from '../../shared/hooks';

export const ShowProposalText = () => {
  const { incentive } = useAppSelector((state) => state.incentive);
  return (
    <Box className="Share__component__container" gap={0}>
      {incentive?.proposalText && (
        <>
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', width: '100%' }}
          >
            <Typography variant="h4">Proposal Text</Typography>
            <CopyToClipboard
              text={incentive?.proposalText}
              onCopy={() => toast.success('Text Copied Successfully')}
            >
              <Tooltip title="Copy Text to clipboard" arrow>
                <IconButton size="medium">
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Box>
          <Box sx={{ width: '100%' }} className="proposalTextAreaInput">
            <TextAreaInput
              secondaryTitle=""
              tooltipText="Proposal Text"
              isTextField
              value={incentive?.proposalText}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
