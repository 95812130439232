export enum Dialogs {
  MyProfile,
  InviteUser,
  InviteApp,
  EditUser,
  SuccessContributionDialog,
  AccountSettings,
  SwitchAccount,
  RevertAccount,
  AddTransaction,
  UpdateContributionStatus,
  ViewContributionHistory,
  ContactUs,
  ResetUserPassword,
  ResendNotification,
  ConfirmDialog,
  FinancialHold,
  LocaleSettings,
  GivewithBrandLockWarning,
  InstanceSettings,
  CauseAreaSettings,
  ViewErrorLog,
  CauseAreaMetrics,
  InformativeDialog,
  InviteSupplier,
  DetailsSupplier,
  ProcurementMetricsDetails,
  ShareProposal,
  ProcurementMetricsProgramDetails,
  ShowProposalText,
}
