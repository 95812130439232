import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Tooltip,
  TextField,
  Checkbox,
  Box,
  Modal,
  Chip,
  Card,
  CardContent,
  Divider,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import axios from 'axios';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import { CK_AUTH_TOKEN, CK_USER_CLAIMS } from '../../shared/constants';
import '../sio/formFilling.scss';
import { fetchProgram, fetchProgrambyUser, postProgrambyUserThunk } from './ProgramSlice';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';
import EyeIcon from '../../assets/svg/eyeIcon.svg';
import { GWCookies } from '../../shared/utils';
import { uploadFile } from '../../api/portal/programRequest';

interface ProgressTypes {
  general: { complete: number; total: number };
  impactAndScope: { complete: number; total: number };
  strategiesAndApproaches: { complete: number; total: number };
  researchAndEvaluation: { complete: number; total: number };
  finance: { complete: number; total: number };
  content: { complete: number; total: number };
}

const successStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '45vw',
  bgcolor: '#FFFFFF',
  border: '1px solid #E6E6E6',
  boxShadow: 24,
  borderRadius: 5,
};

const ProgramDashboard = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const programState = useAppSelector((state) => state.programs);
  const { program } = programState;
  const progressReport: ProgressTypes = program.progress;
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [accept, setAccept] = useState(true);
  const [termsAndConditions, setTermsAndConditions] = useState<string>('');
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState<string>('');
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [files, setFiles] = useState<File>();
  const [formPreviewFiles, setformPreviewFiles] = useState<any>({});

  useEffect(() => {
    if (id) {
      dispatch(fetchProgrambyUser(id));
    }
  }, [id]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const payload = {
      status: 'submitted',
      general: program.general,
      impactAndScope: program.impactAndScope,
      strategiesAndApproaches: program.strategiesAndApproaches,
      researchAndEvaluation: program.researchAndEvaluation,
      finance: program.finance,
      content: program.content,
      review: {
        name,
        email,
      },
    };
    if (id && accept) {
      dispatch(postProgrambyUserThunk({ program: payload, id })).then((res: any) => {
        if (res) toast.success('Program submitted successfully!');
        navigate(`/app/sio/programs/${id}/success`);
      });
    }
  };

  const progressData = (type: string) => {
    let percentage = 0;
    let final;
    if (Object.keys(progressReport).length === 0) {
      final = 0;
    } else if (progressReport) {
      switch (type) {
        case 'general':
          percentage = (progressReport.general.complete / progressReport.general.total) * 100;
          break;
        case 'impact':
          percentage =
            (progressReport.impactAndScope.complete / progressReport.impactAndScope.total) * 100;
          break;
        case 'strategies':
          percentage =
            (progressReport.strategiesAndApproaches.complete /
              progressReport.strategiesAndApproaches.total) *
            100;
          break;
        case 'research':
          percentage =
            (progressReport.researchAndEvaluation.complete /
              progressReport.researchAndEvaluation.total) *
            100;
          break;
        case 'finance':
          percentage = (progressReport.finance.complete / progressReport.finance.total) * 100;
          break;
        case 'content':
          percentage = (progressReport.content.complete / progressReport.content.total) * 100;
          break;
        default:
      }
    }
    final = Math.ceil(percentage / 10) * 10;
    let chip;
    if (final < 100 || Number.isNaN(final)) {
      chip = (
        <Chip
          sx={{
            bgcolor: '#E9F5FF',
            color: colors.primary,
            border: `1px solid ${colors.strokeClick}`,
          }}
          icon={<HourglassEmptyIcon color="primary" />}
          color="primary"
          label={final === 0 || Number.isNaN(final) ? 'Not Started' : `In Progress ${final}%`}
          variant="outlined"
        />
      );
    } else {
      chip = (
        <Chip
          color="success"
          sx={{ bgcolor: '#DBF6DB' }}
          icon={<CheckCircleOutlineIcon color="success" />}
          label="Completed"
          variant="outlined"
        />
      );
    }
    return chip;
  };

  const changeButton = (type: string) => {
    let percentage = 0;
    let final;
    if (Object.keys(progressReport).length === 0) {
      final = 0;
    } else if (progressReport) {
      switch (type) {
        case 'general':
          percentage = (progressReport.general.complete / progressReport.general.total) * 100;
          break;
        case 'impact':
          percentage =
            (progressReport.impactAndScope.complete / progressReport.impactAndScope.total) * 100;
          break;
        case 'strategies':
          percentage =
            (progressReport.strategiesAndApproaches.complete /
              progressReport.strategiesAndApproaches.total) *
            100;
          break;
        case 'research':
          percentage =
            (progressReport.researchAndEvaluation.complete /
              progressReport.researchAndEvaluation.total) *
            100;
          break;
        case 'finance':
          percentage = (progressReport.finance.complete / progressReport.finance.total) * 100;
          break;
        case 'content':
          percentage = (progressReport.content.complete / progressReport.content.total) * 100;
          break;
        default:
      }
    }
    final = Math.ceil(percentage / 10) * 10;
    let button;
    if (final < 100 || Number.isNaN(final)) {
      button = 'Start';
    } else {
      button = 'Review';
    }
    return button;
  };

  const handleAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    const { name: fileName } = e.target;
    if (fileName === 'assets' || fileName === 'budgetFile' || fileName === 'researchFile') {
      setformPreviewFiles({ ...files, [fileName]: fileList[0] });
      console.log(formPreviewFiles);
    } else {
      setFiles(fileList[0]);
    }
  };

  const uploadFileData = async (fileName: any) => {
    const formData = new FormData();
    if (!files) return;
    formData.append('file', files);
    if (
      fileName === 'imageLandscape' ||
      fileName === 'imagePortrait' ||
      fileName === 'previewImage'
    ) {
      formData.append('path', fileName);
    } else {
      formData.append('path', `deliverables.${fileName}`);
    }
    formData.append('is_public', 'true');
    if (id) {
      const response = await uploadFile(id, formData);
      if (response.data) {
        dispatch(fetchProgram(id));
        toast.success('Upload Successfull');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const commonTextStyle = {
    mainHeading: {
      fontSize: fonts.fontSize24,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  useEffect(() => {
    const userData: any = GWCookies.get(CK_USER_CLAIMS);
    // @ts-ignore
    const token: string = GWCookies.get(CK_AUTH_TOKEN);

    const parsedData = JSON.parse(userData);
    const parsedToken = token;

    const { accountId } = parsedData;
    const url = window.PORTAL_API_ENDPOINT;
    axios
      .get(`${url}accounts/${accountId}`, {
        headers: {
          authorization: parsedToken,
        },
      })
      .then((response) => {
        const instanceId: string = response?.data?.instance?.id;

        axios
          .get(`${url}instance-settings/sioprograms/${instanceId}`, {
            headers: {
              authorization: parsedToken,
            },
          })
          .then((res: any) => {
            setTermsAndConditions(res?.data?.settings?.programSubmissionTermsUrl);
            setPrivacyPolicyUrl(res?.data?.settings?.privacyPolicyUrl);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
          });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, []);

  return (
    <section className="bgLight">
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...successStyle }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                bgcolor: `#E7F2FF`,
                mb: 2,
                p: 2,
                borderRadius: '20px 20px 0 0',
              }}
            >
              <Typography sx={commonTextStyle.mainHeading}>Submit Confirmation</Typography>
            </Grid>
            <Grid item xs={12} sx={{ padding: '0 20px 20px' }}>
              <Typography sx={{ ...commonTextStyle.heading, mb: 1 }}>
                Are you sure you submit Program Submission Form?
              </Typography>
              <Box sx={{ border: '1px solid #c4bebe', borderRadius: '16px', padding: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography sx={{ ...commonTextStyle.heading, mb: 1 }}>
                      Please enter your name and email to submit the application to ScaleWith.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      Name
                      <Tooltip title="Enter Name">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      placeholder="Enter Name"
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography sx={commonTextStyle.labelStyle}>
                      Email
                      <Tooltip title="Enter Email">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      placeholder="Enter Email"
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      variant="outlined"
                      sx={commonTextStyle.textFieldStyle}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <Checkbox checked={accept} sx={{ mt: 2 }} onChange={() => setAccept(!accept)} />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="body1">
                      I agree to the{' '}
                      <u>
                        <a target="_blank" rel="noreferrer" href={termsAndConditions}>
                          Terms And Conditions
                        </a>
                      </u>{' '}
                      &{' '}
                      <u>
                        <a target="_blank" rel="noreferrer" href={privacyPolicyUrl}>
                          Privacy Policy
                        </a>
                      </u>{' '}
                      . I hereby represents and warrants that all information contained herein will
                      be truthful and accurate as of the date of said submission.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} textAlign="right" sx={{ p: 2, borderTop: '1px solid #E6E6E6' }}>
              <Button
                onClick={handleCloseModal}
                sx={{ ...commonTextStyle.buttonStyle, float: 'right', ml: 3 }}
                variant="outlined"
                size="large"
              >
                Cancel
              </Button>
              <Button
                disabled={!name || !email || !accept}
                onClick={handleSubmit}
                sx={{
                  ...commonTextStyle.buttonStyle,
                  backgroundColor: colors.primary,
                  color: colors.secondary,
                  float: 'right',
                  ml: 3,
                }}
                variant="contained"
                size="large"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardContent sx={{ padding: '80px' }}>
              <Typography
                sx={{
                  mb: 4,
                  fontSize: fonts.fontSize24,
                  fontWeight: fonts.fontWeight700,
                  color: colors.textPrimary,
                }}
                gutterBottom
              >
                ScaleWith Program Submission Form
              </Typography>
              <Typography
                sx={{
                  mb: 10,
                  fontSize: fonts.fontSize16,
                  fontWeight: fonts.fontWeight400,
                  color: colors.textPrimary,
                }}
              >
                ScaleWIth is committed to driving more funding, awareness, and resources to SIO
                organizations addressing society’s most pressiogs challenges, while simultaneously
                helping brands make the most of their corporate social responsibility.
              </Typography>
              <Typography
                sx={{
                  my: 4,
                  fontSize: fonts.fontSize16,
                  fontWeight: fonts.fontWeight400,
                  color: colors.textPrimary,
                }}
              >
                Please complete every section in order to submit for review.
              </Typography>
              {/* {(program.status === 'approved' || program.status === 'submitted') && ( */}
              <Link
                to={`/program-preview/${id}?sio_funding_amt=${50000}`}
                target="_blank"
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  marginRight: '30px',
                  textDecoration: 'none',
                  justifyContent: 'flex-end',
                  paddingBottom: '20px',
                }}
              >
                <img width="20px" height="14px" src={EyeIcon} alt="eye icon" />
                <Typography
                  sx={{ color: '#005FEA', fontSize: '18px', fontWeight: 400, paddingLeft: '5px' }}
                >
                  Program Preview
                </Typography>
              </Link>
              {/* )} */}

              {/* {program.status === 'approved' && ( */}
              <Accordion
                expanded={expanded === 'analysis'}
                onChange={handleAccordion('analysis')}
                sx={{ border: '1px solid #E0E0E0', borderRadius: '4px', my: 2, boxShadow: 'none' }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'analysis' ? (
                      <RemoveIcon sx={{ color: '#005FEA' }} />
                    ) : (
                      <AddIcon sx={{ color: '#005FEA' }} />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={commonTextStyle.mainHeading} gutterBottom>
                    Program Assets
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                    <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                      {' '}
                      Potrait Image
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      {program?.imagePortrait && (
                        <Grid item xs={12} sm={2}>
                          <img
                            src={program.imagePortrait}
                            alt="imagePortrait"
                            style={{
                              width: '100%',
                              height: '100px',
                              marginRight: '20px',
                              border: '1px solid #E0E0E0',
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={3}>
                        <TextField
                          name="imagePortrait"
                          type="file"
                          placeholder="Select portrait image"
                          onChange={handleUpload}
                          variant="outlined"
                          sx={{
                            height: '41px !important',
                            border: 'none',
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                              {
                                border: 'none',
                              },
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                              padding: '11px 10px',
                              fontSize: fonts.fontSize14,
                              fontWeight: fonts.fntWeight400,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Button
                          sx={commonTextStyle.buttonStyle}
                          onClick={() => uploadFileData('imagePortrait')}
                          variant="outlined"
                          size="large"
                        >
                          <Typography sx={{ color: colors.primary }}>
                            Upload Portrait Image
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box
                          sx={{
                            backgroundColor: '#f5f5f5',
                            padding: '8px 16px',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              ...commonTextStyle.labelStyle,
                              color: 'text.secondary',
                              textAlign: 'center',
                            }}
                          >
                            Recommended image size: 576x887 pixels at 72 DPI for optimal quality.
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                    <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                      {' '}
                      Landscape Image
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      {program?.imageLandscape && (
                        <Grid item xs={12} sm={2}>
                          <img
                            src={program.imageLandscape}
                            alt="potraitImage"
                            style={{
                              width: '100%',
                              height: '100px',
                              border: '1px solid #E0E0E0',
                              marginRight: '20px',
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item sm={3}>
                        <TextField
                          name="imageLandscape"
                          type="file"
                          placeholder="Select landscape image"
                          onChange={handleUpload}
                          variant="outlined"
                          sx={{
                            height: '41px !important',
                            border: 'none',
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                              {
                                border: 'none',
                              },
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                              padding: '11px 10px',
                              fontSize: fonts.fontSize14,
                              fontWeight: fonts.fntWeight400,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <Button
                          sx={commonTextStyle.buttonStyle}
                          onClick={() => uploadFileData('imageLandscape')}
                          variant="outlined"
                          size="large"
                        >
                          <Typography sx={{ color: colors.primary }}>
                            Upload Landscape Image
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box
                          sx={{
                            backgroundColor: '#f5f5f5',
                            padding: '8px 16px',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              ...commonTextStyle.labelStyle,
                              color: 'text.secondary',
                              textAlign: 'center',
                            }}
                          >
                            Recommended image size: 703x366 pixels at 72 DPI for optimal quality.
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                    <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                      {' '}
                      Preview Image
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      {program?.previewImage && (
                        <Grid item xs={12} sm={2}>
                          <img
                            src={program.previewImage}
                            alt="potraitImage"
                            style={{
                              width: '100%',
                              height: '100px',
                              border: '1px solid #E0E0E0',
                              marginRight: '20px',
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item sm={3}>
                        <TextField
                          name="previewImage"
                          type="file"
                          placeholder="Select preview image"
                          onChange={handleUpload}
                          variant="outlined"
                          sx={{
                            height: '41px !important',
                            border: 'none',
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                              {
                                border: 'none',
                              },
                            '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                              padding: '11px 10px',
                              fontSize: fonts.fontSize14,
                              fontWeight: fonts.fntWeight400,
                            },
                          }}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <Button
                          sx={commonTextStyle.buttonStyle}
                          onClick={() => uploadFileData('previewImage')}
                          variant="outlined"
                          size="large"
                        >
                          <Typography sx={{ color: colors.primary }}>
                            Upload Preview Image
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box
                          sx={{
                            backgroundColor: '#f5f5f5',
                            padding: '8px 16px',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              ...commonTextStyle.labelStyle,
                              color: 'text.secondary',
                              textAlign: 'center',
                            }}
                          >
                            Recommended image size: 576x887 pixels at 72 DPI for optimal quality.
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item sm={5}>
                        <Typography sx={commonTextStyle.labelStyle}>Preview Vimeo Video</Typography>
                        <TextField
                          name="vimeoVideo"
                          disabled
                          fullWidth
                          placeholder="Enter Vimeo Video"
                          margin="none"
                          value={program?.previewVimeoId}
                          variant="outlined"
                          sx={commonTextStyle.textFieldStyle}
                        />
                      </Grid>
                      <Grid item sm={5}>
                        <Typography sx={commonTextStyle.labelStyle}>Deliverables</Typography>
                        <TextField
                          name="deliverables"
                          placeholder="Enter Vimeo Video"
                          margin="none"
                          fullWidth
                          variant="outlined"
                          sx={commonTextStyle.textFieldStyle}
                        />
                      </Grid>
                      <Grid item sm={2}>
                        <Button
                          sx={{ ...commonTextStyle.buttonStyle, mt: 3 }}
                          variant="outlined"
                          size="large"
                          color="primary"
                        >
                          <Typography> Generate</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Box> */}
                  {/* <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        {' '}
                        Deliverables
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 'bold', mt: 2 }}>
                        {' '}
                        Attachments
                      </Typography>
                      <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                        {' '}
                        Long Video
                      </Typography>
                      <Grid container spacing={2}>
                        {program?.deliverables?.longVideo && (
                          <Grid item xs={12} sm={2}>
                            <img
                              src={program?.deliverables?.longVideo}
                              alt="longVideo"
                              style={{
                                width: '100%',
                                height: '100px',
                                marginRight: '20px',
                                border: '1px solid #E0E0E0',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="longVideo"
                            type="file"
                            placeholder="Select portrait image"
                            onChange={handleUpload}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            sx={{ ...commonTextStyle.buttonStyle, margin: '0px 2px' }}
                            onClick={() => uploadFileData('longVideo')}
                            variant="outlined"
                            size="large"
                          >
                            <Typography sx={{ color: colors.primary }}>
                              Upload Long Video
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box> */}
                  {/* <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                      <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                        {' '}
                        Short Video
                      </Typography>
                      <Grid container spacing={2}>
                        {program?.deliverables?.shortVideo && (
                          <Grid item xs={12} sm={2}>
                            <img
                              src={program?.deliverables?.shortVideo}
                              alt="shortVideo"
                              style={{
                                width: '100%',
                                height: '100px',
                                marginRight: '20px',
                                border: '1px solid #E0E0E0',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="shortVideo"
                            type="file"
                            placeholder="Select portrait image"
                            onChange={handleUpload}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            sx={{ ...commonTextStyle.buttonStyle, margin: '0px 2px' }}
                            onClick={() => uploadFileData('shortVideo')}
                            variant="outlined"
                            size="large"
                          >
                            <Typography sx={{ color: colors.primary }}>
                              Upload Short video
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box> */}
                  {/* <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                      <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                        {' '}
                        Photo Gallery
                      </Typography>
                      <Grid container spacing={2}>
                        {program?.deliverables?.photoGallery && (
                          <Grid item xs={12} sm={2}>
                            <img
                              src={program?.deliverables?.photoGallery}
                              alt="photoGallery"
                              style={{
                                width: '100%',
                                height: '100px',
                                marginRight: '20px',
                                border: '1px solid #E0E0E0',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="photoGallery"
                            type="file"
                            placeholder="Select portrait image"
                            margin="normal"
                            onChange={handleUpload}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            sx={{ ...commonTextStyle.buttonStyle, margin: '0px 2px' }}
                            onClick={() => uploadFileData('photoGallery')}
                            variant="outlined"
                            size="large"
                          >
                            <Typography sx={{ color: colors.primary }}>
                              Upload Photo gallery
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box> */}
                  {/* <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                      <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                        {' '}
                        Invester Communications
                      </Typography>
                      <Grid container spacing={2}>
                        {program?.deliverables?.investorCommunications && (
                          <Grid item xs={12} sm={2}>
                            <img
                              src={program?.deliverables?.investorCommunications}
                              alt="investorCommunications"
                              style={{
                                width: '100%',
                                height: '100px',
                                marginRight: '20px',
                                border: '1px solid #E0E0E0',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="investorCommunications"
                            type="file"
                            placeholder="Select portrait image"
                            onChange={handleUpload}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            sx={commonTextStyle.buttonStyle}
                            onClick={() => uploadFileData('investorCommunications')}
                            variant="outlined"
                            size="large"
                          >
                            <Typography sx={{ color: colors.primary }}>
                              Upload Invester Communications
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box> */}
                  {/* <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                      <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                        {' '}
                        Press Release
                      </Typography>
                      <Grid container spacing={2}>
                        {program?.deliverables?.pressRelease && (
                          <Grid item xs={12} sm={2}>
                            <img
                              src={program?.deliverables?.pressRelease}
                              alt="pressRelease"
                              style={{
                                width: '100%',
                                height: '100px',
                                marginRight: '20px',
                                border: '1px solid #E0E0E0',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="pressRelease"
                            type="file"
                            placeholder="Select portrait image"
                            onChange={handleUpload}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            sx={commonTextStyle.buttonStyle}
                            onClick={() => uploadFileData('pressRelease')}
                            variant="outlined"
                            size="large"
                          >
                            <Typography sx={{ color: colors.primary }}>
                              Upload Press Release
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box> */}
                  {/* <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                      <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                        {' '}
                        Sustainability Reporting
                      </Typography>
                      <Grid container spacing={2}>
                        {program?.deliverables?.sustainabilityReporting && (
                          <Grid item xs={12} sm={2}>
                            <img
                              src={program?.deliverables?.sustainabilityReporting}
                              alt="sustainabilityReporting"
                              style={{
                                width: '100%',
                                height: '100px',
                                marginRight: '20px',
                                border: '1px solid #E0E0E0',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="sustainabilityReporting"
                            type="file"
                            placeholder="Select portrait image"
                            onChange={handleUpload}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            sx={commonTextStyle.buttonStyle}
                            onClick={() => uploadFileData('sustainabilityReporting')}
                            variant="outlined"
                            size="large"
                          >
                            <Typography sx={{ color: colors.primary }}>
                              Upload Sustainability Reporting
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                      <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                        {' '}
                        Employee Communications
                      </Typography>
                      <Grid container spacing={2}>
                        {program?.deliverables?.employeeCommunications && (
                          <Grid item xs={12} sm={2}>
                            <img
                              src={program?.deliverables?.employeeCommunications}
                              alt="employeeCommunications"
                              style={{
                                width: '100%',
                                height: '100px',
                                marginRight: '20px',
                                border: '1px solid #E0E0E0',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="employeeCommunications"
                            type="file"
                            placeholder="Select portrait image"
                            onChange={handleUpload}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            sx={commonTextStyle.buttonStyle}
                            onClick={() => uploadFileData('employeeCommunications')}
                            variant="outlined"
                            size="large"
                          >
                            <Typography sx={{ color: colors.primary }}>
                              Upload Employee Communications
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                      <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                        {' '}
                        Program Fact Sheet
                      </Typography>
                      <Grid container spacing={2}>
                        {program?.deliverables?.programFactSheet && (
                          <Grid item xs={12} sm={2}>
                            <img
                              src={program?.deliverables?.programFactSheet}
                              alt="programFactSheet"
                              style={{
                                width: '100%',
                                height: '100px',
                                marginRight: '20px',
                                border: '1px solid #E0E0E0',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="programFactSheet"
                            type="file"
                            placeholder="Select portrait image"
                            onChange={handleUpload}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            sx={commonTextStyle.buttonStyle}
                            onClick={() => uploadFileData('programFactSheet')}
                            variant="outlined"
                            size="large"
                          >
                            <Typography sx={{ color: colors.primary }}>
                              Upload Program Fact Sheet
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                      <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                        {' '}
                        Social Media Posts
                      </Typography>
                      <Grid container spacing={2}>
                        {program?.deliverables?.socialMediaPosts && (
                          <Grid item xs={12} sm={2}>
                            <img
                              src={program?.deliverables?.socialMediaPosts}
                              alt="socialMediaPosts"
                              style={{
                                width: '100%',
                                height: '100px',
                                marginRight: '20px',
                                border: '1px solid #E0E0E0',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="socialMediaPosts"
                            type="file"
                            placeholder="Select portrait image"
                            onChange={handleUpload}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            sx={commonTextStyle.buttonStyle}
                            onClick={() => uploadFileData('socialMediaPosts')}
                            variant="outlined"
                            size="large"
                          >
                            <Typography sx={{ color: colors.primary }}>
                              Upload Social Media Posts
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                      <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                        {' '}
                        Csr Report Highlight
                      </Typography>
                      <Grid container spacing={2}>
                        {program?.deliverables?.csrReportHighlight && (
                          <Grid item xs={12} sm={2}>
                            <img
                              src={program?.deliverables?.csrReportHighlight}
                              alt="csrReportHighlight"
                              style={{
                                width: '100%',
                                height: '100px',
                                marginRight: '20px',
                                border: '1px solid #E0E0E0',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="csrReportHighlight"
                            type="file"
                            placeholder="Select portrait image"
                            onChange={handleUpload}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            sx={commonTextStyle.buttonStyle}
                            onClick={() => uploadFileData('csrReportHighlight')}
                            variant="outlined"
                            size="large"
                          >
                            <Typography sx={{ color: colors.primary }}>
                              Upload Csr Report Highlight
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                      <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                        {' '}
                        Infographic
                      </Typography>
                      <Grid container spacing={2}>
                        {program?.deliverables?.infographic && (
                          <Grid item xs={12} sm={2}>
                            <img
                              src={program?.deliverables?.infographic}
                              alt="infographic"
                              style={{
                                width: '100%',
                                height: '100px',
                                marginRight: '20px',
                                border: '1px solid #E0E0E0',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="infographic"
                            type="file"
                            placeholder="Select portrait image"
                            onChange={handleUpload}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            sx={commonTextStyle.buttonStyle}
                            onClick={() => uploadFileData('infographic')}
                            variant="outlined"
                            size="large"
                          >
                            <Typography sx={{ color: colors.primary }}>
                              Upload Infographic
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2, m: 1 }}>
                      <Typography sx={{ ...commonTextStyle.labelStyle, mt: 2 }}>
                        {' '}
                        Additional
                      </Typography>
                      <Grid container spacing={2}>
                        {program?.deliverables?.additional && (
                          <Grid item xs={12} sm={2}>
                            <img
                              src={program?.deliverables?.additional}
                              alt="additional"
                              style={{
                                width: '100%',
                                height: '100px',
                                marginRight: '20px',
                                border: '1px solid #E0E0E0',
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="additional"
                            type="file"
                            placeholder="Select portrait image"
                            onChange={handleUpload}
                            variant="outlined"
                            sx={commonTextStyle.textFieldStyle}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Button
                            sx={commonTextStyle.buttonStyle}
                            onClick={() => uploadFileData('additional')}
                            variant="outlined"
                            size="large"
                          >
                            <Typography sx={{ color: colors.primary }}>
                              Upload Additional
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box> */}
                </AccordionDetails>
              </Accordion>
              {/* // )} */}
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Card className="smallCards">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ mb: 2, fontSize: fonts.fontSize24, color: colors.textPrimary }}
                          >
                            General Information
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {progressReport && progressData('general')}
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                          <Button
                            onClick={() => navigate(`/app/sio/programs/${id}/general-information`)}
                            variant="outlined"
                            size="large"
                            style={{ color: colors.primary }}
                          >
                            {progressReport && changeButton('general')}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="smallCards">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ mb: 2, fontSize: fonts.fontSize24, color: colors.textPrimary }}
                          >
                            Impact and scope
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {progressReport && progressData('impact')}
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                          <Button
                            onClick={() =>
                              navigate(`/app/sio/programs/${id}/impact-scope-information`)
                            }
                            variant="outlined"
                            size="large"
                            style={{ color: colors.primary }}
                          >
                            {progressReport && changeButton('impact')}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="smallCards">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ mb: 2, fontSize: fonts.fontSize24, color: colors.textPrimary }}
                          >
                            Strategies and approaches
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {progressReport && progressData('strategies')}
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                          <Button
                            onClick={() =>
                              navigate(`/app/sio/programs/${id}/strategies-approaches-information`)
                            }
                            variant="outlined"
                            style={{ color: colors.primary }}
                            size="large"
                          >
                            {progressReport && changeButton('strategies')}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="smallCards">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ mb: 2, fontSize: fonts.fontSize24, color: colors.textPrimary }}
                          >
                            Research and evaluation
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {progressReport && progressData('research')}
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                          <Button
                            onClick={() =>
                              navigate(`/app/sio/programs/${id}/research-evaluation-information`)
                            }
                            variant="outlined"
                            style={{ color: colors.primary }}
                            size="large"
                          >
                            {progressReport && changeButton('research')}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="smallCards">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ mb: 2, fontSize: fonts.fontSize24, color: colors.textPrimary }}
                          >
                            Budget
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {progressReport && progressData('finance')}
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                          <Button
                            onClick={() => navigate(`/app/sio/programs/${id}/budget-information`)}
                            variant="outlined"
                            style={{ color: colors.primary }}
                            size="large"
                          >
                            {progressReport && changeButton('finance')}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Card className="smallCards">
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{ mb: 2, fontSize: fonts.fontSize24, color: colors.textPrimary }}
                          >
                            Content
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {progressReport && progressData('content')}
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                          <Button
                            onClick={() => navigate(`/app/sio/programs/${id}/content-information`)}
                            variant="outlined"
                            style={{ color: colors.primary }}
                            size="large"
                          >
                            {progressReport && changeButton('content')}
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                </Grid>
                <Grid
                  item
                  xs={12}
                  textAlign="right"
                  sx={{ display: 'flex', alignItems: 'baseline', p: 2 }}
                >
                  <Button
                    disabled={program?.percentComplete !== 100}
                    onClick={() => setOpen(true)}
                    sx={{
                      ...commonTextStyle.buttonStyle,
                      backgroundColor: colors.primary,
                      color: colors.secondary,
                      mb: 1,
                      marginLeft: 'auto',
                      '&:hover': {
                        backgroundColor: colors.primary,
                        color: colors.secondary,
                      },
                    }}
                    variant="contained"
                    size="large"
                  >
                    Review and Submit
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => navigate('/app/sio/programs')}
                    sx={{
                      ...commonTextStyle.buttonStyle,
                      backgroundColor: colors.primary,
                      color: colors.secondary,
                      // marginLeft: 'auto',
                      marginLeft: '5px',
                    }}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default ProgramDashboard;
