import { GridRequest, GridResponse } from '../../shared/interfaces';
import {
  FundingList,
  PostFundingRequest,
  Funding,
  UpdateFundingUserRequest,
  UpdateFundingRequest,
} from '../../shared/interfaces/funding/funding';
import { httpSecuredCommon, cancelToken } from '../httpCommon';

const base = '/funding-forms';

export const FUNDING_API_ROUTES = {
  getFundings: base,
  postFunding: base,
  getFundingStatus: `${base}/status`,
  getFundingDetail: (id: string) => `${base}/${id}`,
  updateFundingFormDetail: (id: string) => `/fundings/user/${id}`,
  updateFunding: (id: string) => `${base}/${id}`,
  sendEmail: (id: string) => `${base}/sendform/${id}`,
  fundingAction: (id: string, action: string) => `${base}/${id}/${action}`,
  downloadFile: (id: string) => `${base}/file/${id}`,
};

export const downloadFile = async (request: any) => {
  const { id, url, name } = request;
  const response = await httpSecuredCommon.get(FUNDING_API_ROUTES.downloadFile(id), {
    cancelToken: cancelToken.token,
    params: {
      url,
    },
    responseType: 'blob',
  });

  const responseURL = window.URL.createObjectURL(new Blob([response.data]));
  const a = document.createElement('a');
  a.href = responseURL;
  a.download = name;
  window.URL.revokeObjectURL(responseURL);
  return response;
};

export const getFundingList = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<FundingList>>(
    FUNDING_API_ROUTES.getFundings,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const getFunding = async (id: string) => {
  const response = await httpSecuredCommon.get(FUNDING_API_ROUTES.getFundingDetail(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const patchFunding = async (id: string, funding: UpdateFundingRequest) => {
  const response = await httpSecuredCommon.patch<Funding>(
    FUNDING_API_ROUTES.updateFunding(id),
    funding,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getFundingStatus = async () => {
  const response = await httpSecuredCommon.get(FUNDING_API_ROUTES.getFundingStatus, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postFunding = async (funding: PostFundingRequest) => {
  const response = await httpSecuredCommon.post<Funding>(FUNDING_API_ROUTES.postFunding, funding, {
    cancelToken: cancelToken.token,
  });
  return response;
};

// export const getFundingUserDetails = async (id: string) => {
//   const response = await httpSecuredCommon.get<Funding>(FUNDING_API_ROUTES.getFundingFormDetail(id), {
//     cancelToken: cancelToken.token,
//   });
//   return response;
// };

export const updateFundingUserDetails = async (sio: UpdateFundingUserRequest, id: string) => {
  const response = await httpSecuredCommon.patch<Funding>(
    FUNDING_API_ROUTES.updateFundingFormDetail(id),
    sio,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const sendEmail = async (id: string, data: object) => {
  const response = await httpSecuredCommon.post(FUNDING_API_ROUTES.sendEmail(id), data);
  return response;
};

export const fundingAction = async (id: string, action: string) => {
  const response = await httpSecuredCommon.post(FUNDING_API_ROUTES.fundingAction(id, action));
  return response;
};

export const deleteFunding = async (id: string) => {
  const response = await httpSecuredCommon.delete(FUNDING_API_ROUTES.updateFunding(id));
  return response;
};
