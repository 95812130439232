import React, { useEffect } from 'react';
import _ from 'lodash';
import { Autocomplete, Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { FlexBox, MoneyInputWithLocaleSymbol, PaddingRoundedBox } from '../../../shared/components';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { UserGrid } from '../../../shared/interfaces';
import {
  setApprovalLevelsMode,
  clearApprovalLevelsForm,
  setApprovalLevelsAmountForm,
  setApprovalLevelsUserForm,
  removeApprovalLevel,
  setApprovalLevelsUserTextForm,
  getAccountApprovalLevelUsersThunk,
  addApprovalLevel,
} from './accountSettingsSlice';
import { formatString, isNullOrWhitespace } from '../../../shared/utils';
import { DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { UserRole } from '../../../shared/enums';
import fonts from '../../../assets/scss/font.module.scss';
import colors from '../../../assets/scss/color.module.scss';

export function ApprovalLevelsForm() {
  const state = useAppSelector((x) => x.accountSettings.approvalLevels);
  const success = useAppSelector((x) => x.accountSettings.success);
  const accountId = useAppSelector((x) => x.app.account?.id);
  const dispatch = useAppDispatch();
  const { formData, mode, data, users, usersLoading } = state;
  const { amount, user, userText, editingLevel } = { ...formData };

  const commonTextFieldStyle = {
    '& label': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
    '& input': {
      fontSize: fonts.fontSize18,
    },
    '&::placeholder': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
  };

  const getUsersRequest = () => ({
    count: DEFAULT_PAGE_SIZE,
    offset: 0,
    descending: false,
    orderBy: 'username',
    accountId: accountId ?? '',
    ...(!isNullOrWhitespace(userText) && { username: userText ?? '' }),
  });

  useEffect(() => {
    if (!success) dispatch(getAccountApprovalLevelUsersThunk(getUsersRequest()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (user?.username !== userText)
        dispatch(getAccountApprovalLevelUsersThunk(getUsersRequest()));
    }, 600);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userText]);

  const saveEditApprovalLevel = () => {
    const { id, username, name, firstName, lastName } = { ...user };
    const isEdit = mode === 'edit';
    const modified = editingLevel?.amount !== amount || editingLevel?.approver.id !== id;

    if (isEdit && modified)
      dispatch(
        removeApprovalLevel({
          id: editingLevel?.approver?.id ?? '',
          amount: editingLevel?.amount ?? 0,
        }),
      );

    if ((isEdit && modified) || !isEdit)
      dispatch(
        addApprovalLevel({
          amount: amount!,
          approver: {
            id: id!,
            username: username!,
            name: name ?? formatString(firstName, '') + formatString(lastName),
            type: 'user',
          },
        }),
      );
    dispatch(setApprovalLevelsMode('list'));
    dispatch(clearApprovalLevelsForm());
  };
  const approvalUsers = users.results.filter(item => (item.roles?.includes(UserRole.OrgAdmin) || item.roles?.includes(UserRole.Approver)));
  const ApprovalUserAutocomplete = (
    <Autocomplete
      disablePortal
      fullWidth
      id="combo-box-approval-user"
      options={approvalUsers}
      getOptionDisabled={(option) =>
        !_.isNil(
          data?.levels
            ?.filter((x) => !_.isEqual(x, formData.editingLevel))
            .find((x) => x.approver.id === option.id),
        )
      }
      value={formData?.user ?? null}
      getOptionLabel={(option) => option.username}
      onChange={(event: any, newValue: UserGrid | undefined | null) => {
        dispatch(setApprovalLevelsUserForm(newValue));
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={(event, newInputValue) =>
        dispatch(setApprovalLevelsUserTextForm(newInputValue))
      }
      inputValue={userText ?? ''}
      filterOptions={(x) => x}
      loading={usersLoading}
      sx={{ flex: 2 }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label="Approver"
          sx={commonTextFieldStyle}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {usersLoading ? <CircularProgress color="primary" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );

  return (
    <PaddingRoundedBox
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        border: `1px solid ${theme.palette.common.grey.contrast}`,
      })}
    >
      <FlexBox>
        <Typography variant="h5" sx={{ flex: 1, textTransform: 'capitalize' }}>
          {mode} Approval Level
        </Typography>
        <Box sx={{ backgroundColor: '#fff' }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ fontSize: fonts.fontSize14, fontWeight: fonts.fontWeight900 , backgroundColor: colors.primary }}
            onClick={() => {
              dispatch(setApprovalLevelsMode('list'));
              dispatch(clearApprovalLevelsForm());
            }}
          >
            back
          </Button>
        </Box>
      </FlexBox>
      <FlexBox sx={{ gap: 4, alignItems: 'center' }}>
        <TextField
          label="Up to Amount"
          fullWidth
          value={amount ?? ''}
          sx={{ ...commonTextFieldStyle, flex: 1.5 }}
          onChange={(e) => {
            const { value } = e.target;
            dispatch(
              setApprovalLevelsAmountForm(
                isNullOrWhitespace(value) ? undefined : parseFloat(value),
              ),
            );
          }}
          InputProps={{
            inputComponent: MoneyInputWithLocaleSymbol as any,
            inputProps: { allowNegative: false },
          }}
        />
        {ApprovalUserAutocomplete}
        <Box>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: 180, fontSize: fonts.fontSize14, fontWeight: fonts.fontWeight900 , backgroundColor: colors.primary }}
            disabled={isNullOrWhitespace(userText) || isNullOrWhitespace(amount?.toString() ?? '')}
            onClick={() => saveEditApprovalLevel()}
          >
            {mode === 'new' ? 'Add' : 'Save'} Approval Level
          </Button>
        </Box>
      </FlexBox>
    </PaddingRoundedBox>
  );
}
