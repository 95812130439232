import { useEffect } from 'react';
import { getAccountInstanceLocaleThunk } from '../../app';
import { isNullOrWhitespace } from '../utils';
import { useAppDispatch, useAppSelector } from './redux';

export const useInstance = () => {
  const appState = useAppSelector((x) => x.app);
  const { userData, loadingUserData } = { ...appState };
  const { instance, locale, account } = { ...userData };
  const dispatch = useAppDispatch();
  const currencySymbol = locale?.settings.symbol;

  useEffect(() => {
    if ((!isNullOrWhitespace(instance?.id) && !isNullOrWhitespace(locale?.id)) || loadingUserData)
      return;
    dispatch(getAccountInstanceLocaleThunk());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return { loadingUserData, instance, account, locale, currencySymbol };
};
