import { TextField, Typography } from '@mui/material';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { FlexBox, TextFieldAndChips } from '../../../shared/components';
import { useAppDispatch } from '../../../shared/hooks';
import { InstanceFormConfig } from '../../../shared/interfaces';
import {
  setInstanceSettingsData,
  setInstanceSettingsRestrictedDomains,
} from './instanceSettingsSlice';
import fonts from '../../../assets/scss/font.module.scss';

interface Props {
  value: InstanceFormConfig;
}

export const InstanceSettingsResources: FC<Props> = (props) => {
  const { value } = props;
  const {
    resources: { restrictedDomains, cookiePolicyUrl, privacyPolicyUrl, termsOfUseUrl },
  } = value;

  const dispatch = useAppDispatch();

  const addChip = (chipValue: string) => {
    if (
      !restrictedDomains.value.find(
        (item) => item.toLocaleLowerCase() === chipValue.toLocaleLowerCase(),
      )
    ) {
      dispatch(setInstanceSettingsRestrictedDomains([...restrictedDomains.value, chipValue]));
    } else {
      toast.warning(`'${chipValue}' is a duplicate`);
    }
  };

  const onDeleteChip = (chipValue: string) => {
    dispatch(
      setInstanceSettingsRestrictedDomains(
        restrictedDomains.value.filter((item) => item !== chipValue),
      ),
    );
  };

  const formatChip = (chipValue: string) => `@${chipValue}`;

  const commonTextFieldStyle = {
    '& label': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
    '& input': {
      fontSize: fonts.fontSize18,
    },
    '&::placeholder': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
  };

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
      <FlexBox sx={{ flexDirection: 'column', gap: 1 }}>
        <Typography variant="h6">
          If entered, only users from the domains below will be able to register for this instance
        </Typography>
        <TextFieldAndChips
          label={restrictedDomains.label}
          data={restrictedDomains.value}
          formatValue={formatChip}
          addChip={addChip}
          onDelete={onDeleteChip}
          required={restrictedDomains?.required}
        />
      </FlexBox>

      <TextField
        label={cookiePolicyUrl?.label}
        value={cookiePolicyUrl?.value}
        required={cookiePolicyUrl?.required}
        fullWidth
        onChange={(event) => {
          dispatch(setInstanceSettingsData({ settings: { cookiePolicyUrl: event.target.value } }));
        }}
        sx={commonTextFieldStyle}
      />

      <TextField
        label={privacyPolicyUrl?.label}
        value={privacyPolicyUrl?.value}
        required={privacyPolicyUrl?.required}
        fullWidth
        onChange={(event) => {
          dispatch(setInstanceSettingsData({ settings: { privacyPolicyUrl: event.target.value } }));
        }}
        sx={commonTextFieldStyle}
      />

      <TextField
        label={termsOfUseUrl?.label}
        value={termsOfUseUrl?.value}
        required={termsOfUseUrl?.required}
        fullWidth
        onChange={(event) => {
          dispatch(setInstanceSettingsData({ settings: { termsOfUseUrl: event.target.value } }));
        }}
        sx={commonTextFieldStyle}
      />
    </FlexBox>
  );
};
