import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAppDispatch } from '../../../shared/hooks';
import { clearContributionState } from '../incentiveSlice';
// import { SelectCause } from '../select-cause';
import { SelectSocialContributionForm } from './CorpEntity';
import { NewContributionSelectAmount } from './NewContributionSelectAmount';

const routes = (
  <Routes>
    <Route path="/corpsearch" element={<SelectSocialContributionForm />} />
    <Route path="" element={<NewContributionSelectAmount />} />
    {/* <Route path=":causeId/amount" element={<NewContributionSelectAmount />} /> */}
  </Routes>
);

export function NewContribution() {
  const dispatch = useAppDispatch();

  useEffect(() => () => {
    dispatch(clearContributionState());
  });

  return routes;
}
