import React, { ChangeEvent, useEffect, useState } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Checkbox,
  Button,
  Box,
  Divider,
  Card,
  CardContent,
  Typography,
  CardHeader,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@mui/material';
import '../sio/formFilling.scss';
import { fetchProgrambyUser, updateProgrambyUserThunk } from './ProgramSlice';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { getLocations, getVocab } from '../../api/portal/programRequest';
import { Locations, Vocabulary } from '../../shared/interfaces/program/program';
import { COUNTRIESCONSTANT } from '../../shared/constants';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#68839A',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#FFF',
  },
}));

// const outputForm = [{ quantity: 0, description: '' }, { quantity: 0, description: '' }, { quantity: 0, description: '' }]

const ProgramImpactQuestions = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const programState = useAppSelector((state) => state.programs);
  const { program } = programState;

  const initialFormValue = {
    causes: program?.impactAndScope?.causes ?? [],
    primaryImpact: program?.impactAndScope?.primaryImpact ?? [],
    secondaryImpact: program?.impactAndScope?.secondaryImpacts ?? [],
    targetGroup: program?.impactAndScope?.targetGroup ?? [],
    audienceAge: program?.impactAndScope?.audienceAge ?? [],
    regions: program?.impactAndScope?.locationDetails?.regions ?? [],
    countries: program?.impactAndScope?.locationDetails?.countries ?? [],
    states: program?.impactAndScope?.locationDetails?.states ?? [],
    cities: program?.impactAndScope?.locationDetails?.cities ?? [],
    additionalLocationDetails: program?.impactAndScope?.locationDetails?.additionalLocationDetails,
    // regionDescription:program?.impactAndScope?.locationDetails?.locationExplanation?.regions! ?? '',
    locationExplanation: {
      regions: program?.impactAndScope?.locationDetails?.locationExplanation?.regions ?? '',
      countries: program?.impactAndScope?.locationDetails?.locationExplanation?.countries ?? '',
      states: program?.impactAndScope?.locationDetails?.locationExplanation?.states ?? '',
      cities: program?.impactAndScope?.locationDetails?.locationExplanation?.cities ?? '',
    },
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [pageNumber, setPageNumber] = useState(1);
  // const [files, setFiles] = useState<File>()
  const [vocab, setVocab] = useState<Vocabulary>();
  const [locations, setLocations] = useState<Locations>();
  const [locExpDetails, setLocaExpDetails] = useState({
    regions: false,
    countries: false,
    states: false,
    cities: false,
  });

  useEffect(() => {
    getVocab().then((res) => {
      if (res.status === 200) {
        setVocab(res.data);
      }
    });
    getLocations().then((res) => {
      if (res.status === 200) {
        setLocations(res.data);
      }
    });
    if (id && program) {
      dispatch(fetchProgrambyUser(id)).then((programData: any) => {
        setFormValues({
          causes: programData.payload?.impactAndScope?.causes ?? [],
          primaryImpact: programData.payload?.impactAndScope?.primaryImpact ?? [],
          secondaryImpact: programData.payload?.impactAndScope?.secondaryImpacts ?? [],
          targetGroup: programData.payload?.impactAndScope?.targetGroup ?? [],
          audienceAge: programData.payload?.impactAndScope?.audienceAge ?? [],
          regions: programData.payload?.impactAndScope?.locationDetails?.regions ?? [],
          countries: programData.payload?.impactAndScope?.locationDetails?.countries ?? [],
          states: programData.payload?.impactAndScope?.locationDetails?.states ?? [],
          cities: programData.payload?.impactAndScope?.locationDetails?.cities ?? [],
          locationExplanation: {
            regions:
              programData.payload?.impactAndScope?.locationDetails?.locationExplanation?.regions ??
              '',
            countries:
              programData.payload?.impactAndScope?.locationDetails?.locationExplanation
                ?.countries ?? '',
            states:
              programData.payload?.impactAndScope?.locationDetails?.locationExplanation?.states ??
              '',
            cities:
              programData.payload?.impactAndScope?.locationDetails?.locationExplanation?.cities ??
              '',
          },
          additionalLocationDetails:
            programData.payload?.impactAndScope?.locationDetails?.additionalLocationDetails,
        });
        // savePageNumber()
      });
    }
  }, [id]);

  const updateProgramDetails = (fields: any) => {
    const { field, value } = fields;
    const payload = {
      impactAndScope: {
        [field]: value,
      },
    };
    console.log('payload', payload);
    const programPayload = payload;
    if (id) {
      return dispatch(updateProgrambyUserThunk({ program: programPayload, id }));
    }
    return true;
  };

  const getLocationData = (field: string, data: any) => {
    const newFormat: string[] = [];
    data.map((items: any) => {
      if (field === 'country') {
        const region = Object.entries(locations!.countries);
        region.map((item) => {
          const allCountries = item[1].map((country: any) => country.name);
          const n = items.lastIndexOf(';');
          const result = items.substring(n + 1);
          if (allCountries.includes(result)) {
            newFormat.push(`${item[0]};${result}`);
          }
          return true;
        });
      }
      if (field === 'state') {
        const region = Object.entries(locations!.states);
        region.map((item) => {
          const allCountries = item[1].map((country: any) => country.name);
          const n = items.lastIndexOf(';');
          const result = items.substring(n + 1);
          if (allCountries.includes(result)) {
            newFormat.push(items);
          }
          return true;
        });
      }
      if (field === 'city') {
        const region = Object.entries(locations!.cities);
        region.map((item) => {
          const allCountries = item[1].map((country: any) => country.name);
          const n = items.lastIndexOf(';');
          const result = items.substring(n + 1);
          if (allCountries.includes(result)) {
            newFormat.push(items);
          }
          return true;
        });
      }
      return false;
    });
    return newFormat;
  };

  const getfield = () => {
    const { regions, countries, states, cities, additionalLocationDetails, locationExplanation } =
      formValues;
    let field = '';
    let value;
    switch (pageNumber) {
      case 1:
        field = 'causes';
        value = formValues.causes;
        break;
      case 2:
        field = 'primaryImpact';
        value =
          typeof formValues.primaryImpact === 'string'
            ? [formValues.primaryImpact]
            : formValues.primaryImpact;
        break;
      case 3:
        field = 'secondaryImpacts';
        value = formValues.secondaryImpact;
        break;
      case 4:
        field = 'targetGroup';
        value = formValues.targetGroup;
        break;
      case 5:
        field = 'audienceAge';
        value = formValues.audienceAge;
        break;
      case 8:
        field = 'locationDetails';
        value = { regions, countries, states, cities, locationExplanation };
        break;
      case 9:
        field = 'locationDetails';
        value = {
          countries: getLocationData('country', countries),
          states,
          cities,
          locationExplanation,
        };
        break;
      case 10:
        field = 'locationDetails';
        value = {
          states: getLocationData('state', states),
          countries,
          cities,
          locationExplanation,
        };
        break;
      case 11:
        field = 'locationDetails';
        value = { cities: getLocationData('city', cities), countries, states, locationExplanation };
        break;
      case 12:
        field = 'locationDetails';
        value = { additionalLocationDetails };
        break;
      default:
        break;
    }
    return { field, value };
  };

  const handlePreviousbutton = () => {
    const { regions, countries } = formValues;
    const { COUNTRY1, COUNTRY2 } = COUNTRIESCONSTANT;
    const filteredStateCountries = countries.filter((item: any) => {
      const n = item.lastIndexOf(';');
      const result = item.substring(n + 1);
      return [COUNTRY1, COUNTRY2].includes(result);
    });
    const filteredCityCountries = countries.filter((item: any) => {
      const n = item.lastIndexOf(';');
      const result = item.substring(n + 1);
      return ![COUNTRY1, COUNTRY2].includes(result);
    });
    if (pageNumber > 1) {
      if (pageNumber === 12) {
        if (regions.includes('N/A') || regions.length === 0) {
          setPageNumber(8);
        } else if (filteredCityCountries.length === 0 || filteredCityCountries.includes('N/A')) {
          if (filteredStateCountries.length === 0) {
            setPageNumber(9);
          } else {
            setPageNumber(10);
          }
        } else {
          setPageNumber(11);
        }
      } else if (pageNumber === 11) {
        if (filteredStateCountries.length === 0) {
          setPageNumber(9);
        } else {
          setPageNumber(10);
        }
      } else if (pageNumber === 8) {
        setPageNumber(5);
      } else {
        setPageNumber(pageNumber - 1);
      }
    }
  };
  const handleNextButton = async () => {
    const response = await updateProgramDetails(getfield());
    const { regions, countries } = formValues;
    const { COUNTRY1, COUNTRY2 } = COUNTRIESCONSTANT;
    const filteredStateCountries = countries.filter((item: any) => {
      const n = item.lastIndexOf(';');
      const result = item.substring(n + 1);
      return [COUNTRY1, COUNTRY2].includes(result);
    });
    const filteredCityCountries = countries.filter((item: any) => {
      const n = item.lastIndexOf(';');
      const result = item.substring(n + 1);
      return ![COUNTRY1, COUNTRY2].includes(result);
    });
    if (response) {
      if (pageNumber < 12) {
        if (pageNumber === 8) {
          if (regions.includes('N/A') || regions.length === 0) {
            setPageNumber(12);
          } else {
            setPageNumber(9);
          }
        } else if (pageNumber === 9) {
          if (countries.length === 0 || countries.includes('N/A')) {
            setPageNumber(12);
          } else if (filteredStateCountries.length === 0) {
            if (filteredCityCountries.length === 0) {
              setPageNumber(12);
            } else {
              setPageNumber(11);
            }
          } else {
            setPageNumber(10);
          }
        } else if (pageNumber === 10) {
          if (countries.length === 0) {
            setPageNumber(12);
          } else if (filteredCityCountries.length === 0) {
            setPageNumber(12);
          } else {
            setPageNumber(11);
          }
        } else if (pageNumber === 5) {
          setPageNumber(8);
        } else {
          setPageNumber(pageNumber + 1);
        }
      } else {
        navigate(`/app/sio/programs/${id}`);
      }
    } else {
      toast.error('An error occurred while submitting in. Please try again.');
    }
    return null;
  };

  const handleSaveExit = async () => {
    const response = await updateProgramDetails(getfield());
    if (response) {
      // toast.success('Successfully Submitted Form to ScaleWith');
      navigate(`/app/sio/programs/${id}`);
    } else {
      toast.error('An error occurred while logging in. Please try again.');
    }
    return null;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleLocationExpChange = (event: any, key: string) => {
    console.log(key);
    if (event.target.value.length !== 0) {
      console.log(event.target.value);
      setFormValues((prevValues: any) => ({
        ...prevValues,
        locationExplanation: {
          [key]: event.target.value,
        },
      }));
    } else {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        locationExplanation: {
          [key]: '',
        },
      }));
      setLocaExpDetails((prevValues: any) => ({
        ...prevValues,
        [key]: false,
      }));
    }
  };

  console.log(formValues);
  const handleCheck = (event: any, key: keyof typeof formValues) => {
    let newvalue = formValues[key];
    if (Array.isArray(newvalue)) {
      if (event.target.checked) {
        setFormValues({
          ...formValues,
          [key]: [event.target.value],
        });
      } else {
        newvalue = newvalue.filter((item: any) => item !== event.target.value);
        setFormValues({
          ...formValues,
          [key]: newvalue,
        });
      }
    }
  };
  const locationExpCheck = (key: string, isCheck: boolean) => {
    setLocaExpDetails((prevState) => ({ ...prevState, [key]: isCheck }));
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>, isNa?: boolean) => {
    const { name, value } = event.target;
    if (name === 'causes') {
      let newvalue = formValues.causes;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else if (formValues.causes.length <= 1) {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        causes: newvalue,
      });
    }
    if (name === 'secondaryImpact') {
      let newvalue = formValues.secondaryImpact;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else if (formValues.secondaryImpact.length < 3) {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        secondaryImpact: newvalue,
      });
    }
    if (name === 'targetGroup') {
      if (isNa) {
        setFormValues({
          ...formValues,
          targetGroup: [],
        });
        handleCheck(event, 'targetGroup');
      } else {
        let newvalue = formValues.targetGroup;
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          targetGroup: newvalue,
        });
      }
    }
    if (name === 'audienceAge') {
      if (isNa) {
        setFormValues({
          ...formValues,
          audienceAge: [],
        });
        handleCheck(event, 'audienceAge');
      } else {
        let newvalue = formValues.audienceAge;
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          audienceAge: newvalue,
        });
      }
    }
    if (name === 'regions') {
      if (value === 'N/A') {
        if (event.target.checked) {
          locationExpCheck('regions', true);
          setFormValues({ ...formValues, regions: [], countries: [], states: [], cities: [] });
        } else {
          locationExpCheck('regions', false);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            locationExplanation: {
              regions: '',
            },
          }));
        }
      } else {
        let newvalue = formValues.regions;
        let countriesArr = formValues.countries;
        let statesArr = formValues.states;
        let citiesArr = formValues.cities;

        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
          countriesArr = countriesArr.filter((item) => item.indexOf(value) === -1);
          statesArr = statesArr.filter((item) => item.indexOf(value) === -1);
          citiesArr = citiesArr.filter((item) => item.indexOf(value) === -1);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          regions: newvalue,
          countries: countriesArr,
          states: statesArr,
          cities: citiesArr,
        });
      }
    }
    if (name === 'countries') {
      if (value === 'N/A') {
        if (event.target.checked) {
          locationExpCheck('countries', true);
          setFormValues({ ...formValues, countries: [], states: [], cities: [] });
        } else {
          locationExpCheck('countries', false);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            locationExplanation: {
              countries: '',
            },
          }));
        }
      } else {
        let newvalue = formValues.countries;
        let statesArr = formValues.states;
        let citiesArr = formValues.cities;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
          const n = value.lastIndexOf(';');
          const result = value.substring(n + 1);
          statesArr = statesArr.filter((item) => item.indexOf(result) === -1);
          citiesArr = citiesArr.filter((item) => item.indexOf(result) === -1);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          countries: newvalue,
          states: statesArr,
          cities: citiesArr,
        });
      }
    }
    if (name === 'states') {
      if (value === 'N/A') {
        if (event.target.checked) {
          locationExpCheck('states', true);
          setFormValues({ ...formValues, states: [] });
        } else {
          locationExpCheck('states', false);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            locationExplanation: {
              states: '',
            },
          }));
        }
      } else {
        let newvalue = formValues?.states;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          states: newvalue,
        });
      }
    }
    if (name === 'cities') {
      if (value === 'N/A') {
        if (event.target.checked) {
          locationExpCheck('cities', true);
          setFormValues({ ...formValues, cities: [] });
        } else {
          locationExpCheck('cities', false);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            locationExplanation: {
              cities: '',
            },
          }));
        }
      } else {
        let newvalue = formValues.cities;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          cities: newvalue,
        });
      }
    }
  };
  console.log('loc', locExpDetails);
  const isChecked = (data: any, value: string) => {
    let returnValue = false;
    data.map((item: any) => {
      const n = item.lastIndexOf(';');
      const result = item.substring(n + 1);
      if (result === value) {
        returnValue = true;
      }
      return null;
    });
    return returnValue;
  };
  useEffect(() => {
    if (program?.impactAndScope?.locationDetails?.locationExplanation?.regions) {
      locationExpCheck('regions', true);
    } else {
      locationExpCheck('regions', false);
    }
    if (program?.impactAndScope?.locationDetails?.locationExplanation?.countries) {
      locationExpCheck('countries', true);
    } else {
      locationExpCheck('countries', false);
    }
    if (program?.impactAndScope?.locationDetails?.locationExplanation?.states) {
      locationExpCheck('states', true);
    } else {
      locationExpCheck('states', false);
    }
    if (program?.impactAndScope?.locationDetails?.locationExplanation?.cities) {
      locationExpCheck('cities', true);
    } else {
      locationExpCheck('cities', false);
    }
  }, [program?.impactAndScope?.locationDetails?.locationExplanation]);

  const renderCountriesOptions = (country: any, region: any) =>
    country[0][1]?.map((items: any) => (
      <Box
        key={items.name}
        className={isChecked(formValues.countries, items.name) ? 'active' : ''}
        sx={{
          border: '1px solid #E0E0E0',
          display: 'inline-block',
          width: 'fit-content',
          m: 1,
          borderRadius: '20px',
          p: 2,
        }}
      >
        <Typography variant="body1" display="inline">
          {items.name}
        </Typography>
        <Checkbox
          name="countries"
          checked={isChecked(formValues?.countries, items.name)}
          value={`${region};${items.name}`}
          onChange={handleChangeSelect}
          disabled={locExpDetails?.countries}
        />
      </Box>
    ));

  const renderCitiesOptions = (country: any, regionCountry: any) =>
    country.length > 0 &&
    country[0][1]?.map((items: any) => (
      <Box
        key={items.name}
        className={isChecked(formValues.cities, items.name) ? 'active' : ''}
        sx={{
          border: '1px solid #E0E0E0',
          display: 'inline-block',
          width: 'fit-content',
          m: 1,
          borderRadius: '20px',
          p: 2,
        }}
      >
        <Typography variant="body1" display="inline">
          {items.name}
        </Typography>
        <Checkbox
          name="cities"
          checked={isChecked(formValues.cities, items.name)}
          value={`${regionCountry};${items.name}`}
          onChange={handleChangeSelect}
          disabled={locExpDetails?.cities}
        />
      </Box>
    ));

  const renderstatesOptions = (state: any, regionCountry: any) =>
    state.length > 0 &&
    state[0][1]?.map((items: any) => (
      <Box
        key={items.name}
        className={isChecked(formValues.states, items.name) ? 'active' : ''}
        sx={{
          border: '1px solid #E0E0E0',
          display: 'inline-block',
          width: 'fit-content',
          m: 1,
          borderRadius: '20px',
          p: 2,
        }}
      >
        <Typography variant="body1" display="inline">
          {items.name}
        </Typography>
        <Checkbox
          name="states"
          checked={isChecked(formValues?.states, items.name)}
          value={`${regionCountry};${items.name}`}
          onChange={handleChangeSelect}
          disabled={locExpDetails?.states}
        />
      </Box>
    ));

  const renderCountries = (region: string) => {
    const regions = Object.entries(locations!.countries);
    let countries: any = [];
    if (region !== 'N/A') {
      countries = regions.filter((item) => item[0] === region ?? item);
      return renderCountriesOptions(countries, region);
    }
    return true;
  };

  const renderStates = (country: string, regionCountry: any) => {
    const regions = Object.entries(locations!.states);
    let states: any = [];
    if (country === 'United States' || country === 'Canada' || country !== 'N/A') {
      states = regions.filter((item) => item[0] === country ?? item);
      return renderstatesOptions(states, regionCountry);
    }
    return true;
  };

  const renderCities = (country: string, regionCountry: any) => {
    const regions = Object.entries(locations!.cities);
    let cities: any = [];
    if (country !== 'United States' && country !== 'Canada' && country !== 'N/A') {
      cities = regions.filter((item) => item[0] === country ?? item);
      return renderCitiesOptions(cities, regionCountry);
    }
    return true;
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  // *** Start Questions ***

  const question1 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 5 }} variant="h3">
          Let&apos;s get started!
        </Typography>
        <Typography sx={commonTextStyle.questionStyle}>Question 1 of 12</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Which cause areas best match this program&apos;s work?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>
          Please select up to two from the list below.
        </Typography>
      </Grid>
      {
        /* eslint no-underscore-dangle: 0 */
        vocab?.causes.map((item: any) => (
          <Grid key={item._id} item>
            <Box
              className={formValues.causes.includes(item._id) ? 'active' : ''}
              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
            >
              <Typography variant="body1" display="inline">
                {item.label}
              </Typography>
              <Checkbox
                name="causes"
                checked={formValues.causes.includes(item._id)}
                value={item._id}
                onChange={handleChangeSelect}
                style={{ color: `${formValues.causes.includes(item._id) ? colors.primary : ''}` }}
              />
            </Box>
          </Grid>
        ))
      }
    </Grid>
  );
  const question2 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 2 of 12</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          What is this program&apos;s primary targeted impact?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>
          Impacts are long-term effects of your program. Please select one option from the list
          below.
        </Typography>
      </Grid>

      {vocab?.causes.map((item: any) => (
        <Grid key={item._id} sx={{ my: 1 }} item xs={12}>
          <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
            <Typography sx={{ ...commonTextStyle.heading, my: 2 }} display="inline">
              {item.label}
            </Typography>
            <br />
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  sx={{ display: 'inline-block' }}
                  name="primaryImpact"
                  value={formValues.primaryImpact}
                  onChange={handleChange}
                >
                  {vocab?.impact.map((subitem: any) => {
                    if (subitem.causeId === item._id) {
                      return (
                        <FormControlLabel
                          sx={{
                            p: 2,
                            m: 1,
                            border: '1px solid #E0E0E0',
                            borderRadius: '20px',
                            color: '',
                          }}
                          labelPlacement="start"
                          key={subitem._id}
                          value={subitem._id}
                          control={<Radio />}
                          label={subitem.label}
                        />
                      );
                    }
                    return true;
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  const question3 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 3 of 12</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          What are the secondary impacts of this program?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>
          Impacts are long-term effects of your program. Please select up to three the list below,
          or N/A if not applicable.
        </Typography>
      </Grid>
      {vocab?.impact.map((subItem: any) => {
        if (subItem?.label === 'N/A') {
          return (
            <Grid item>
              <Box
                className={formValues.secondaryImpact.includes(subItem?._id) ? 'active' : ''}
                sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
              >
                <Typography variant="body1" display="inline">
                  N/A
                </Typography>
                <Checkbox
                  name="secondaryImpact"
                  checked={formValues.secondaryImpact.includes(subItem?._id)}
                  value={subItem?._id}
                  onChange={(event) => handleCheck(event, 'secondaryImpact')}
                  style={{
                    color: `${
                      formValues.secondaryImpact.includes(subItem?._id) ? colors.primary : ''
                    }`,
                  }}
                />
              </Box>
            </Grid>
          );
        }
        return true;
      })}
      {vocab?.causes.map((item: any) => (
        <Grid key={item._id} sx={{ my: 1 }} item xs={12}>
          <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
            <Typography sx={{ ...commonTextStyle.heading, my: 2 }} display="inline">
              {item.label}
            </Typography>
            <br />
            {vocab?.impact?.map((subItem: any) => {
              const findNaObject: any = vocab?.impact?.find((obj: any) => obj.label === 'N/A');
              if (subItem.causeId === item._id) {
                return (
                  <Box
                    key={subItem._id}
                    className={formValues.secondaryImpact.includes(subItem._id) ? 'active' : ''}
                    sx={{
                      border: '1px solid #E0E0E0',
                      display: 'inline-block',
                      width: 'fit-content',
                      m: 1,
                      borderRadius: '20px',
                      p: 2,
                    }}
                  >
                    <Typography variant="body1" display="inline">
                      {subItem.label}
                    </Typography>
                    <Checkbox
                      name="secondaryImpact"
                      checked={formValues?.secondaryImpact?.includes(subItem._id)}
                      value={subItem._id}
                      onChange={handleChangeSelect}
                      disabled={formValues?.secondaryImpact?.includes(findNaObject?._id)}
                      style={{
                        color: `${
                          formValues.secondaryImpact.includes(subItem?._id) ? colors.primary : ''
                        }`,
                      }}
                    />
                  </Box>
                );
              }
              return true;
            })}
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  const question4 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 4 of 12 </Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Does this program specifically target any of the following groups of people?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>Please select all that apply.</Typography>
      </Grid>
      {
        /* eslint no-underscore-dangle: 0 */
        vocab?.audience?.attribute?.map((item: any) => {
          const findNaObject: any = vocab?.audience?.attribute?.find(
            (obj: any) => obj.label === 'N/A',
          );
          return (
            <Grid key={item._id} item>
              <Box
                className={formValues.targetGroup.includes(item._id) ? 'active' : ''}
                sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
              >
                <Typography variant="body1" display="inline">
                  {item.label}
                </Typography>
                <Checkbox
                  name="targetGroup"
                  checked={formValues.targetGroup.includes(item._id)}
                  value={item._id}
                  onChange={(event) => handleChangeSelect(event, item.label === 'N/A' && true)}
                  disabled={
                    formValues?.targetGroup?.includes(findNaObject?._id) && item.label !== 'N/A'
                  }
                  style={{
                    color: `${formValues?.targetGroup?.includes(item?._id) ? colors.primary : ''}`,
                  }}
                />
              </Box>
            </Grid>
          );
        })
      }
    </Grid>
  );
  const question5 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 5 of 12 </Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          What age groups does this program serve?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>Please select all that apply.</Typography>
      </Grid>
      {
        /* eslint no-underscore-dangle: 0 */
        vocab?.audience?.age?.map((item: any) => {
          const findNaObject: any = vocab?.audience.age?.find((obj: any) => obj.label === 'N/A');
          return (
            <Grid key={item._id} item>
              <Box
                className={formValues.audienceAge.includes(item._id) ? 'active' : ''}
                sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
              >
                <Typography variant="body1" display="inline">
                  {item.label}
                </Typography>
                <Checkbox
                  name="audienceAge"
                  checked={formValues.audienceAge.includes(item._id)}
                  value={item._id}
                  onChange={(event) => handleChangeSelect(event, item.label === 'N/A' && true)}
                  disabled={
                    formValues?.audienceAge?.includes(findNaObject?._id) && item.label !== 'N/A'
                  }
                  style={{
                    color: `${formValues?.audienceAge?.includes(item?._id) ? colors.primary : ''}`,
                  }}
                />
              </Box>
            </Grid>
          );
        })
      }
      {/* {vocab && (
        <Grid item>
          <Box
            className={formValues.audienceAge.includes('N/A') ? 'active' : ''}
            sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
          >
            <Typography variant="body1" display="inline">
              N/A
            </Typography>
            <Checkbox
              name="audienceAge"
              checked={formValues.audienceAge.includes('N/A')}
              value="N/A"
              onChange={handleChangeSelect}
            />
          </Box>
        </Grid>
      )} */}
    </Grid>
  );

  const question8 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 8 of 12</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          In which specific regions does this program operate?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>Please select all that apply.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center" sm={6}>
            <Box
              className={locExpDetails?.regions ? 'active' : ''}
              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
            >
              <Typography variant="body1" display="inline">
                Able to operate in anywhere (please explain)
              </Typography>
              <Checkbox
                name="regions"
                checked={locExpDetails?.regions}
                value="N/A"
                onChange={handleChangeSelect}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Description</Typography>
            <TextField
              name="locationExplanation"
              placeholder="Enter description here"
              fullWidth
              value={formValues?.locationExplanation?.regions}
              disabled={!locExpDetails?.regions}
              onChange={(event) => handleLocationExpChange(event, 'regions')}
              variant="outlined"
              multiline
              rows={4}
              sx={commonTextStyle.textAreaStyle}
            />
          </Grid>
        </Grid>
        <Grid sx={{ my: 2 }} item xs={12}>
          <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
            <Typography sx={{ ...commonTextStyle.heading, my: 2 }} display="inline">
              Regions
            </Typography>
            <br />
            {locations?.continents.map((subItem: any) => (
              <Box
                key={subItem.name}
                className={formValues.regions.includes(subItem.name) ? 'active' : ''}
                sx={{
                  border: '1px solid #E0E0E0',
                  display: 'inline-block',
                  width: 'fit-content',
                  m: 2,
                  borderRadius: '20px',
                  p: 2,
                }}
              >
                <Typography variant="body1" display="inline">
                  {subItem.name}
                </Typography>
                <Checkbox
                  name="regions"
                  checked={formValues?.regions?.includes(subItem.name)}
                  value={subItem.name}
                  onChange={handleChangeSelect}
                  disabled={locExpDetails?.regions}
                  style={{
                    color: `${formValues?.regions?.includes(subItem?.name) ? colors.primary : ''}`,
                  }}
                />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  const question9 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 9 of 12</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          In which specific countries does this program operate?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>Please select all that apply.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center" sm={6}>
            <Box
              className={locExpDetails?.countries ? 'active' : ''}
              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
            >
              <Typography variant="body1" display="inline">
                Not able to specify further (please explain)
              </Typography>
              <Checkbox
                name="countries"
                checked={locExpDetails?.countries}
                value="N/A"
                onChange={handleChangeSelect}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Description</Typography>
            <TextField
              name="locationExplanation"
              placeholder="Enter description here"
              fullWidth
              value={formValues?.locationExplanation?.countries}
              disabled={!locExpDetails?.countries}
              onChange={(event) => handleLocationExpChange(event, 'countries')}
              variant="outlined"
              multiline
              rows={4}
              sx={commonTextStyle.textAreaStyle}
            />
          </Grid>
        </Grid>
        {formValues.regions.map((item: string) => (
          <Grid key={item} sx={{ my: 1 }} item xs={12}>
            <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
              <Typography sx={{ ...commonTextStyle.heading, my: 2 }} display="inline">
                {item}
              </Typography>
              <br />
              {locations && renderCountries(item)}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const question10 = (formValues.countries.indexOf(COUNTRIESCONSTANT.COUNTRY1) ||
    formValues.countries.indexOf(COUNTRIESCONSTANT.COUNTRY2)) && (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 10 of 12</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          In which specific states does this program operate?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>Please select all that apply.</Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center" sm={6}>
            <Box
              className={locExpDetails?.states ? 'active' : ''}
              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
            >
              <Typography variant="body1" display="inline">
                Able to operate in anywhere (please explain)
              </Typography>
              <Checkbox
                name="states"
                checked={locExpDetails?.states}
                value="N/A"
                onChange={handleChangeSelect}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Description</Typography>
            <TextField
              name="locationExplanation"
              placeholder="Enter description here"
              fullWidth
              value={formValues?.locationExplanation?.states}
              disabled={!locExpDetails?.states}
              onChange={(event) => handleLocationExpChange(event, 'states')}
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        {formValues.countries.map((item: string) => {
          const n = item.lastIndexOf(';');
          const result = item.substring(n + 1);
          if (['United States', 'Canada'].includes(result)) {
            return (
              <Grid key={result} sx={{ my: 1 }} item xs={12}>
                <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                  <Typography sx={{ ...commonTextStyle.heading, my: 2 }} display="inline">
                    {result}
                  </Typography>
                  <br />
                  {locations && renderStates(result, item)}
                </Box>
              </Grid>
            );
          }
          return true;
        })}
      </Grid>
    </Grid>
  );
  const question11 = formValues.countries && (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 11 of 12</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          In which specific cities does this program operate?
        </Typography>
        <Typography sx={commonTextStyle.subHeading}>Please select all that apply.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center" sm={6}>
            <Box
              className={locExpDetails?.cities ? 'active' : ''}
              sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
            >
              <Typography variant="body1" display="inline">
                Able to operate in anywhere (please explain)
              </Typography>
              <Checkbox
                name="cities"
                checked={locExpDetails?.cities}
                value="N/A"
                onChange={handleChangeSelect}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Description</Typography>
            <TextField
              name="locationExplanation"
              placeholder="Enter description here"
              fullWidth
              value={formValues?.locationExplanation?.cities}
              disabled={!locExpDetails?.cities}
              onChange={(event) => handleLocationExpChange(event, 'cities')}
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        {formValues.countries.map((item: string) => {
          const n = item.lastIndexOf(';');
          const result = item.substring(n + 1);
          if (!['United States', 'Canada'].includes(result)) {
            return (
              <Grid key={result} sx={{ my: 1 }} item xs={12}>
                <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                  <Typography sx={{ ...commonTextStyle.heading, my: 2 }} display="inline">
                    {result}
                  </Typography>
                  <br />
                  {locations && renderCities(result, item)}
                </Box>
              </Grid>
            );
          }
          return true;
        })}
      </Grid>
    </Grid>
  );

  const question12 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 12 of 12</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          Is there anything you&apos;d like to add about where your program operates?
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography sx={{ mb: 1 }} variant="subtitle1">
          Description
        </Typography>
        <TextField
          name="additionalLocationDetails"
          placeholder="Enter details"
          fullWidth
          value={formValues.additionalLocationDetails}
          onChange={handleChange}
          variant="outlined"
          multiline
          rows={4}
          sx={commonTextStyle.textAreaStyle}
        />
      </Grid>
    </Grid>
  );

  // *** end questions ***

  return (
    <section className="bgLight">
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardHeader
              sx={{
                padding: '20px 80px',
                background: colors.backgroundColorGradient,
                borderRadius: '20px 20px 0px 0px',
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize18,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Section 2
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize24,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Impact and Scope
                    </Typography>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <Typography
                        sx={{
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fontWeight600,
                          color: colors.secondary,
                        }}
                      >
                        {pageNumber === 1 ? 0 : (pageNumber - 1) * 8}% complete
                      </Typography>
                    </Grid>
                    <BorderLinearProgress
                      variant="determinate"
                      value={pageNumber === 1 ? 1 : (pageNumber - 1) * 8}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent sx={{ minHeight: '70vh', padding: '40px 80px' }}>
              {pageNumber === 1 && question1}
              {pageNumber === 2 && question2}
              {pageNumber === 3 && question3}
              {pageNumber === 4 && question4}
              {pageNumber === 5 && question5}
              {pageNumber === 8 && question8}
              {pageNumber === 9 && question9}
              {pageNumber === 10 && question10}
              {pageNumber === 11 && question11}
              {pageNumber === 12 && question12}
              <Grid item xs={12} textAlign="right">
                <Divider sx={{ my: 3 }} orientation="horizontal" flexItem />
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  onClick={handleSaveExit}
                  variant="outlined"
                  size="large"
                >
                  Save and Exit
                </Button>
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  disabled={pageNumber === 1}
                  onClick={handlePreviousbutton}
                  variant="outlined"
                  size="large"
                >
                  Previous
                </Button>
                <Button
                  sx={{
                    ...commonTextStyle.buttonStyle,
                    mx: 2,
                    backgroundColor: colors.primary,
                    color: colors.secondary,
                  }}
                  onClick={handleNextButton}
                  variant="contained"
                  size="large"
                >
                  Next
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default ProgramImpactQuestions;
