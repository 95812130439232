import { Box, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { BounceLoader } from 'react-spinners';

interface Props {
  loading: boolean;
  fullscreen?: boolean;
  minHeight?: boolean;
}

export const Loading: FC<Props> = (props) => {
  const theme = useTheme();
  const { loading, fullscreen, minHeight } = props;

  const getHeight = () => {
    if (fullscreen) return '100vh';
    if (minHeight) return 150;
    return 300;
  };

  return (
    <Box
      title="loading"
      sx={{
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          minHeight: getHeight(),
          transform: `translate(+50%, +${minHeight ? 25 : 50}%)`,
          overflow: 'hidden',
          position: 'sticky',
        }}
      >
        <BounceLoader
          size={fullscreen ? '100px' : '60px'}
          loading={loading}
          color={theme.palette.primary.main}
        />
      </Box>
    </Box>
  );
};
