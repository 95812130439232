import { Box, Typography, Button, useMediaQuery, CircularProgress } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';

import CurrencySymbol from './CurrencySymbol';
import { FlexBox, FlexBoxColumn } from '../../shared/components';
import { useAppDispatch } from '../../shared/hooks';
import { setDialog } from '../main-screen';
import { Dialogs } from '../../shared/enums';

interface Props {
  totalDealValue: number;
  impactIncentive: number;
  currency?: string;
  showButtons?: boolean;
  downloadFunction?: () => void;
  showDownload?: boolean;
  downloading?: boolean;
}
const DealPageHeaderDetails: FC<Props> = ({
  totalDealValue,
  impactIncentive,
  showButtons,
  currency,
  downloadFunction,
  showDownload,
  downloading,
}) => {
  const getDealImpactSpacing = (dealValue: number, impactValue: number) => {
    const currencyDealValueString = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(dealValue);
    const currencyImpactValueString = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(impactValue);
    const num1DigitCount = currencyDealValueString.length;
    const num2DigitCount = currencyImpactValueString.length;
    const diff = Math.abs(num1DigitCount - num2DigitCount);
    const nbsp = `${' '.repeat(diff)} `;
    const dealSpacing = dealValue < impactValue ? nbsp : '';
    const impactSpacing = impactValue < dealValue ? nbsp : '';
    return [
      <span style={{ whiteSpace: 'pre' }}>{`${dealSpacing}`}</span>,
      <span style={{ whiteSpace: 'pre' }}>{`${impactSpacing}`}</span>,
    ];
  };
  const [dealSpacing, impactSpacing] = getDealImpactSpacing(totalDealValue, impactIncentive);
  const [percentage, setPercentage] = useState(0);
  const dispatch = useAppDispatch();
  const shareFunction = () => {
    dispatch(setDialog({ open: true, type: Dialogs.ShareProposal, maxWidth: 'sm' }));
  };
  useEffect(() => {
    if (totalDealValue && impactIncentive && impactIncentive !== 0) {
      const calculatedPercentage = (impactIncentive / totalDealValue) * 100;
      const roundedPercentage = Math.round(calculatedPercentage * 100) / 100;
      setPercentage(roundedPercentage);
    } else {
      setPercentage(0);
    }
  }, [totalDealValue, impactIncentive]);
  console.log(impactSpacing);

  const iconStyle = {
    marginRight: '8px',
    color: 'white',
    cursor: 'pointer',
  }
  const shareButton = {
    width: '200px',
    height: '50px',
    color: 'white',
    padding: '10px 10px',
    backgroundColor: '#009F75',
    flexDirection: 'row',
    fontWeight: '400',
    fontSize: 'inherit',
    lineHeight: '24.2px',
    '&:hover': {
      color: '#009F75',
      backgroundColor: 'white',
    },
  };
  const is1200 = useMediaQuery('(max-width:1300px)');
  return (
    <FlexBoxColumn className="proposalheaderdetails__container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          position: 'absolute',
          top: '30px',
          right: '50px',
        }}
      >
        {showDownload && downloadFunction ? (
             <FileDownloadIcon
               // DO NOT REMOVE THIS FAKE CLASS AS THIS IS BEING USED BY SELENIUM DOWNLOAD PROPOSAL API
               className="download-pdf-in-background exclude-from-pdf"
               onClick={downloadFunction}
               sx={iconStyle}
             />
        ) : null}
        {downloading && (
          <CircularProgress size={22} sx={iconStyle} className='exclude-from-pdf' />
        )}
        <Typography
          sx={{
            marginTop: `${is1200 && '45px'}`,
            position: 'absolute',
            top: '36px',
            fontSize: '17px',
            fontWeight: '500',
            display: 'contents',
          }}
        >
          Social Impact powered by &nbsp;
        </Typography>
        <img
          src="https://static.scalewith.com/assets/v1/img/png/logo-scalewith-lwt-70.c66d3531ba.png"
          alt=""
          style={{
            height: '35px',
            width: '137px',
          }}
        />
      </div>
      <FlexBox className="exclude-from-pdf">
        <FlexBox>
          <FlexBox>
            
            &nbsp; &nbsp;
            {showButtons && false ? (
              <Button size="medium" variant="contained" onClick={shareFunction} sx={shareButton}>
                <ReplyOutlinedIcon /> Share
              </Button>
            ) : null}
          </FlexBox>
          {/* <Link className="mailtoLink" href="mailto:assist@scaleWith.com">
            <EmailOutlinedIcon style={{ width: '40px', height: '25px', marginBottom: '1.5rem' }} />
            <p className="mailLabel" style={{ marginTop: '3px' }}>
              assist@scalewith.com
            </p>
          </Link> */}
        </FlexBox>
      </FlexBox>
      <FlexBox className="header_container">
        <Box className="proposalheader__details-pill">
          <Typography variant="h3" className="proposalheader__details-Subheading">
            Total Deal Value:
          </Typography>
          <Typography variant="h2" className="proposalheader__details-Subheading">
            <CurrencySymbol currency={currency} /> {dealSpacing}{' '}
            {`${totalDealValue.toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })}`}
          </Typography>
        </Box>
        <Box className="proposalheader__details-pill">
          <Typography variant="h3" className="proposalheader__details-Subheading">
            Impact Contribution Amount:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="h2" className="proposalheader__details-Subheading">
              <CurrencySymbol currency={currency} />{' '}
              {`${impactIncentive.toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })}`}
            </Typography>
            <Typography sx={{ display: 'flex', alignItems: 'flex-end', paddingLeft: '10px' }}>
              ({percentage}% of total deal value)
            </Typography>
          </Box>
        </Box>
      </FlexBox>
    </FlexBoxColumn>
  );
};
export default DealPageHeaderDetails;
