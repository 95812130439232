import { AccountSubscription, RegisterStep } from '../enums';
import { Step, Subscription } from '../interfaces';
// import { platform } from '../utils';

export const ACCOUNT_SUBSCRIPTION_MOST_POPULAR = AccountSubscription.Annual;
const platformName = 'Scale';
const name = `${platformName[0].toUpperCase()}${platformName.slice(1)}with`;
export const ACCOUNT_SUBSCRIPTION: Record<string, Subscription> = {
  [AccountSubscription.Annual]: {
    id: AccountSubscription.Annual,
    title: 'Annual',
    text: `One annual payment to maintain access to your ${name}with account.`,
    price: 4999,
    period: 'year',
    bullets: ['Cancellation anytime'],
    savings: 1000,
    savingFooter: '$500',
  },
  [AccountSubscription.Monthly]: {
    id: AccountSubscription.Monthly,
    title: 'Monthly',
    text: `Regular monthly payments to maintain access to your ${name}with account.`,
    price: 499,
    period: 'month',
    bullets: ['Cancellation anytime'],
    savingFooter: '$500',
  },
};

export const REGISTER_STEPS: Array<Step<RegisterStep>> = [
  {
    label: 'Account Selection',
    step: RegisterStep.AccountSelection,
    description: 'Account selection description',
  },
  {
    label: 'Subscription Selection',
    step: RegisterStep.SubscriptionSelection,
    description: 'Subscription selection description',
  },
  {
    label: 'Finalize',
    step: RegisterStep.Finalize,
    description: 'Finalize description',
  },
];

export const CHECKOUT_SESSION_ID_QUERY_NAME = 'sessionId';
