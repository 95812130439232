import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as SellLogo } from '../../assets/svg/SellWith_horizontal-logo_white.svg';
// import { ReactComponent as SellLogoColored } from '../../assets/svg/SellWith_horizontal-logo.svg';
import { ReactComponent as SellLogoColored } from '../../assets/svg/Scalewith-logo-new.svg';
import { ReactComponent as SellLogoPowered } from '../../assets/svg/SellWith_horizontal-logo-with-tagline_white.svg';
import { ReactComponent as BuyLogo } from '../../assets/svg/BuyWith_horizontal-logo_white.svg';
// import { ReactComponent as BuyLogoColored } from '../../assets/svg/BuyWith_horizontal-logo.svg';
import { ReactComponent as BuyLogoColored } from '../../assets/svg/Buywith-logo-new.svg';
import { ReactComponent as BuyLogoPowered } from '../../assets/svg/BuyWith_horizontal-logo-with-tagline_white.svg';
import { ReactComponent as GiveLogo } from '../../assets/svg/GiveWith_horizontal-logo_white.svg';
import { ReactComponent as GiveLogoPowered } from '../../assets/svg/GiveWith_horizontal-logo-with-tagline_white.svg';
import { ReactComponent as ScaleWithLogo } from '../../assets/svg/Logo_Rectangle.svg';
// import scaleLogoFooter from '../../assets/images/ScaleWith-logo@0.5x.png';
import { ReactComponent as BuywithForSupplier } from '../../assets/svg/BuyWith_for suppliers_white.svg';
// import SioLogo from '../../assets/images/ScaleWith_SIA.png';
import { AccountType } from '../enums';
import { useAccount, useAppSelector } from '../hooks';
import { platform } from '../utils';

interface Props {
  poweredBy?: boolean;
  footer?: boolean;
  coloredLogo?: boolean;
}

const updateFavicon = (newFaviconURL: string) => {
  const link: HTMLLinkElement =
    document.querySelector("link[rel~='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = newFaviconURL;
  document.head.appendChild(link);
  document.title = 'SellWith';
};

const PlatformLogo = (width: string, poweredBy?: boolean, coloredLogo?: boolean) => {
  const platformName = platform();
  const { isAccountInType } = useAccount();
  const instance = useAppSelector((state) => state.app.associatedInstance);
  const isSupplierUser = isAccountInType([AccountType.supplier]);

  useEffect(() => {
    if (isSupplierUser) {
      updateFavicon(
        'https://static.scalewith.com/assets/v1/img/png/logo-buywith-cmrk-32.635829bd66.png',
      );
    }
  }, [isSupplierUser]);

  if (instance) {
    switch (instance.logo) {
      case 'buywith':
        // TODO Replace colored with powered logos
        if (coloredLogo) {
          // return poweredBy ? <BuyLogoPowered width={width} /> : <BuyLogoColored width={width} />;
          // return poweredBy ? <BuyLogoColored width={width} /> : <BuyLogoColored width={width} />;
          return (
            <img
              src="https://static.scalewith.com/assets/v1/img/png/logo-buywithfsply-lct-51.ee3a91cf9e.png"
              alt="buywith"
            />
          );
        }
        if (isSupplierUser) {
          // return <BuyLogoColored width={width} />;

          return (
            <img
              src="https://static.scalewith.com/assets/v1/img/png/logo-sellwith-lct-40.d4bb646bb0.png"
              alt="sellwith"
            />
          );
        }
        // return poweredBy ? <BuyLogoColored width={width} /> : <BuyLogoColored width={width} />;
        // return poweredBy ? <BuyLogoPowered width={width} /> : <BuyLogo width={width} />;
        return (
          <img
            src="https://static.scalewith.com/assets/v1/img/png/logo-buywith-lct-40.08db12679e.png"
            alt="buywith"
          />
        );

      case 'sellwith':
        if (coloredLogo) {
          // return poweredBy ? <SellLogoPowered width={width} /> : <SellLogoColored width={width} />;
          return (
            <img
              src="https://static.scalewith.com/assets/v1/img/png/logo-sellwith-lct-40.d4bb646bb0.png"
              alt="sellwith"
            />
          );
        }

        // Later Replace with SellLogo
        // return poweredBy ? <SellLogoPowered width={width} /> : <SellLogoColored width={width} />;
        return (
          <img
            src="https://static.scalewith.com/assets/v1/img/png/logo-sellwith-lct-40.d4bb646bb0.png"
            alt="sellwith"
          />
        );

      case 'sio':
        return (
          <img
            src="https://static.scalewith.com/assets/v1/img/png/logo-scalewith-lct-40.6cac195f3f.png"
            alt="scalewith"
          />
        );

      // return poweredBy ? <SellLogoPowered width={width} /> : <SellLogo width={width} />;

      case 'givewith':
        return poweredBy ? <GiveLogoPowered width={width} /> : <GiveLogo width={width} />;
      case 'scalewith':
        // return <ScaleWithLogo width={width} />;
        return (
          <img
            src="https://static.scalewith.com/assets/v1/img/png/logo-scalewith-lct-40.6cac195f3f.png"
            alt="scalewith"
          />
        );
      default:
        // return <SellLogoColored width={width} />;
        return (
          <img
            src="https://static.scalewith.com/assets/v1/img/png/logo-sellwith-lct-40.d4bb646bb0.png"
            alt="sellwith"
          />
        );
    }
  } else {
    switch (platformName) {
      case 'buy':
        if (coloredLogo) {
          return poweredBy ? <BuyLogoPowered width={width} /> : <BuyLogoColored width={width} />;
        }
        if (isSupplierUser) {
          return <BuywithForSupplier width={width} />;
        }
        return poweredBy ? <BuyLogoPowered width={width} /> : <BuyLogo width={width} />;

      case 'sell':
        if (coloredLogo) {
          return poweredBy ? <SellLogoPowered width={width} /> : <SellLogoColored width={width} />;
        }

        return poweredBy ? <SellLogoPowered width={width} /> : <SellLogo width={width} />;

      case 'give':
        return poweredBy ? <GiveLogoPowered width={width} /> : <GiveLogo width={width} />;
      default:
        return <ScaleWithLogo width={width} />;
    }
  }
};

const Logo: React.FC<Props> = (props) => {
  const { footer, poweredBy, coloredLogo } = props;
  const useOuterContainerWidth = (): [number, React.RefObject<HTMLDivElement>] => {
    const outerContainer = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);

    useEffect(() => {
      setWidth(outerContainer.current?.offsetWidth || 0);
    }, []);

    return [width, outerContainer];
  };

  const [width, outerContainer] = useOuterContainerWidth();

  return (
    <div
      ref={outerContainer}
      style={{
        width: '100%',
        alignContent: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      {' '}
      {footer ? (
        <div
          style={{
            backgroundImage: `url(https://static.scalewith.com/assets/v1/img/png/logo-scalewith-lct-120.8b82fd9357.png)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            width: `${width}px`,
            height: `${width * (247 / 960)}px`,
          }}
        />
      ) : (
        PlatformLogo(`${width - 30}px`, poweredBy, coloredLogo)
      )}
    </div>
  );
};

export default Logo;
