interface countriesInterface {
  name: string;
  iso: string;
  countryCode: string;
}

export const COUNTRIES: Array<countriesInterface> = [
  {
    name: 'Afghanistan',
    iso: '+93',
    countryCode: 'AF',
  },
  {
    name: 'Åland Islands',
    iso: '+358',
    countryCode: 'AX',
  },
  {
    name: 'Albania',
    iso: '+355',
    countryCode: 'AL',
  },
  {
    name: 'Algeria',
    iso: '+213',
    countryCode: 'DZ',
  },
  {
    name: 'American Samoa',
    iso: '+1684',
    countryCode: 'AS',
  },
  {
    name: 'Andorra',
    iso: '+376',
    countryCode: 'AD',
  },
  {
    name: 'Angola',
    iso: '+244',
    countryCode: 'AO',
  },
  {
    name: 'Anguilla',
    iso: '+1264',
    countryCode: 'AI',
  },
  {
    name: 'Antarctica',
    iso: '+672',
    countryCode: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    iso: '+1268',
    countryCode: 'AG',
  },
  {
    name: 'Argentina',
    iso: '+54',
    countryCode: 'AR',
  },
  {
    name: 'Armenia',
    iso: '+374',
    countryCode: 'AM',
  },
  {
    name: 'Aruba',
    iso: '+297',
    countryCode: 'AW',
  },
  {
    name: 'Australia',
    iso: '+61',
    countryCode: 'AU',
  },
  {
    name: 'Austria',
    iso: '+43',
    countryCode: 'AT',
  },
  {
    name: 'Azerbaijan',
    iso: '+994',
    countryCode: 'AZ',
  },
  {
    name: 'Bahamas',
    iso: '+1242',
    countryCode: 'BS',
  },
  {
    name: 'Bahrain',
    iso: '+973',
    countryCode: 'BH',
  },
  {
    name: 'Bangladesh',
    iso: '+880',
    countryCode: 'BD',
  },
  {
    name: 'Barbados',
    iso: '+1246',
    countryCode: 'BB',
  },
  {
    name: 'Belarus',
    iso: '+375',
    countryCode: 'BY',
  },
  {
    name: 'Belgium',
    iso: '+32',
    countryCode: 'BE',
  },
  {
    name: 'Belize',
    iso: '+501',
    countryCode: 'BZ',
  },
  {
    name: 'Benin',
    iso: '+229',
    countryCode: 'BJ',
  },
  {
    name: 'Bermuda',
    iso: '+1441',
    countryCode: 'BM',
  },
  {
    name: 'Bhutan',
    iso: '+975',
    countryCode: 'BT',
  },
  {
    name: 'Bolivia, Plurinational State of bolivia',
    iso: '+591',
    countryCode: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina',
    iso: '+387',
    countryCode: 'BA',
  },
  {
    name: 'Botswana',
    iso: '+267',
    countryCode: 'BW',
  },
  {
    name: 'Bouvet Island',
    iso: '+47',
    countryCode: 'BV',
  },
  {
    name: 'Brazil',
    iso: '+55',
    countryCode: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    iso: '+246',
    countryCode: 'IO',
  },
  {
    name: 'Brunei Darussalam',
    iso: '+673',
    countryCode: 'BN',
  },
  {
    name: 'Bulgaria',
    iso: '+359',
    countryCode: 'BG',
  },
  {
    name: 'Burkina Faso',
    iso: '+226',
    countryCode: 'BF',
  },
  {
    name: 'Burundi',
    iso: '+257',
    countryCode: 'BI',
  },
  {
    name: 'Cambodia',
    iso: '+855',
    countryCode: 'KH',
  },
  {
    name: 'Cameroon',
    iso: '+237',
    countryCode: 'CM',
  },
  {
    name: 'Canada',
    iso: '+1',
    countryCode: 'CA',
  },
  {
    name: 'Cape Verde',
    iso: '+238',
    countryCode: 'CV',
  },
  {
    name: 'Cayman Islands',
    iso: '+ 345',
    countryCode: 'KY',
  },
  {
    name: 'Central African Republic',
    iso: '+236',
    countryCode: 'CF',
  },
  {
    name: 'Chad',
    iso: '+235',
    countryCode: 'TD',
  },
  {
    name: 'Chile',
    iso: '+56',
    countryCode: 'CL',
  },
  {
    name: 'China',
    iso: '+86',
    countryCode: 'CN',
  },
  {
    name: 'Christmas Island',
    iso: '+61',
    countryCode: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    iso: '+61',
    countryCode: 'CC',
  },
  {
    name: 'Colombia',
    iso: '+57',
    countryCode: 'CO',
  },
  {
    name: 'Comoros',
    iso: '+269',
    countryCode: 'KM',
  },
  {
    name: 'Congo',
    iso: '+242',
    countryCode: 'CG',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    iso: '+243',
    countryCode: 'CD',
  },
  {
    name: 'Cook Islands',
    iso: '+682',
    countryCode: 'CK',
  },
  {
    name: 'Costa Rica',
    iso: '+506',
    countryCode: 'CR',
  },
  {
    name: "Cote d'Ivoire",
    iso: '+225',
    countryCode: 'CI',
  },
  {
    name: 'Croatia',
    iso: '+385',
    countryCode: 'HR',
  },
  {
    name: 'Cuba',
    iso: '+53',
    countryCode: 'CU',
  },
  {
    name: 'Cyprus',
    iso: '+357',
    countryCode: 'CY',
  },
  {
    name: 'Czech Republic',
    iso: '+420',
    countryCode: 'CZ',
  },
  {
    name: 'Denmark',
    iso: '+45',
    countryCode: 'DK',
  },
  {
    name: 'Djibouti',
    iso: '+253',
    countryCode: 'DJ',
  },
  {
    name: 'Dominica',
    iso: '+1767',
    countryCode: 'DM',
  },
  {
    name: 'Dominican Republic',
    iso: '+1849',
    countryCode: 'DO',
  },
  {
    name: 'Ecuador',
    iso: '+593',
    countryCode: 'EC',
  },
  {
    name: 'Egypt',
    iso: '+20',
    countryCode: 'EG',
  },
  {
    name: 'El Salvador',
    iso: '+503',
    countryCode: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    iso: '+240',
    countryCode: 'GQ',
  },
  {
    name: 'Eritrea',
    iso: '+291',
    countryCode: 'ER',
  },
  {
    name: 'Estonia',
    iso: '+372',
    countryCode: 'EE',
  },
  {
    name: 'Ethiopia',
    iso: '+251',
    countryCode: 'ET',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    iso: '+500',
    countryCode: 'FK',
  },
  {
    name: 'Faroe Islands',
    iso: '+298',
    countryCode: 'FO',
  },
  {
    name: 'Fiji',
    iso: '+679',
    countryCode: 'FJ',
  },
  {
    name: 'Finland',
    iso: '+358',
    countryCode: 'FI',
  },
  {
    name: 'France',
    iso: '+33',
    countryCode: 'FR',
  },
  {
    name: 'French Guiana',
    iso: '+594',
    countryCode: 'GF',
  },
  {
    name: 'French Polynesia',
    iso: '+689',
    countryCode: 'PF',
  },
  {
    name: 'French Southern Territories',
    iso: '+262',
    countryCode: 'TF',
  },
  {
    name: 'Gabon',
    iso: '+241',
    countryCode: 'GA',
  },
  {
    name: 'Gambia',
    iso: '+220',
    countryCode: 'GM',
  },
  {
    name: 'Georgia',
    iso: '+995',
    countryCode: 'GE',
  },
  {
    name: 'Germany',
    iso: '+49',
    countryCode: 'DE',
  },
  {
    name: 'Ghana',
    iso: '+233',
    countryCode: 'GH',
  },
  {
    name: 'Gibraltar',
    iso: '+350',
    countryCode: 'GI',
  },
  {
    name: 'Greece',
    iso: '+30',
    countryCode: 'GR',
  },
  {
    name: 'Greenland',
    iso: '+299',
    countryCode: 'GL',
  },
  {
    name: 'Grenada',
    iso: '+1473',
    countryCode: 'GD',
  },
  {
    name: 'Guadeloupe',
    iso: '+590',
    countryCode: 'GP',
  },
  {
    name: 'Guam',
    iso: '+1671',
    countryCode: 'GU',
  },
  {
    name: 'Guatemala',
    iso: '+502',
    countryCode: 'GT',
  },
  {
    name: 'Guernsey',
    iso: '+44',
    countryCode: 'GG',
  },
  {
    name: 'Guinea',
    iso: '+224',
    countryCode: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    iso: '+245',
    countryCode: 'GW',
  },
  {
    name: 'Guyana',
    iso: '+592',
    countryCode: 'GY',
  },
  {
    name: 'Haiti',
    iso: '+509',
    countryCode: 'HT',
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    iso: '+0',
    countryCode: 'HM',
  },
  {
    name: 'Holy See (Vatican City State)',
    iso: '+379',
    countryCode: 'VA',
  },
  {
    name: 'Honduras',
    iso: '+504',
    countryCode: 'HN',
  },
  {
    name: 'Hong Kong',
    iso: '+852',
    countryCode: 'HK',
  },
  {
    name: 'Hungary',
    iso: '+36',
    countryCode: 'HU',
  },
  {
    name: 'Iceland',
    iso: '+354',
    countryCode: 'IS',
  },
  {
    name: 'India',
    iso: '+91',
    countryCode: 'IN',
  },
  {
    name: 'Indonesia',
    iso: '+62',
    countryCode: 'ID',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    iso: '+98',
    countryCode: 'IR',
  },
  {
    name: 'Iraq',
    iso: '+964',
    countryCode: 'IQ',
  },
  {
    name: 'Ireland',
    iso: '+353',
    countryCode: 'IE',
  },
  {
    name: 'Isle of Man',
    iso: '+44',
    countryCode: 'IM',
  },
  {
    name: 'Israel',
    iso: '+972',
    countryCode: 'IL',
  },
  {
    name: 'Italy',
    iso: '+39',
    countryCode: 'IT',
  },
  {
    name: 'Jamaica',
    iso: '+1876',
    countryCode: 'JM',
  },
  {
    name: 'Japan',
    iso: '+81',
    countryCode: 'JP',
  },
  {
    name: 'Jersey',
    iso: '+44',
    countryCode: 'JE',
  },
  {
    name: 'Jordan',
    iso: '+962',
    countryCode: 'JO',
  },
  {
    name: 'Kazakhstan',
    iso: '+7',
    countryCode: 'KZ',
  },
  {
    name: 'Kenya',
    iso: '+254',
    countryCode: 'KE',
  },
  {
    name: 'Kiribati',
    iso: '+686',
    countryCode: 'KI',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    iso: '+850',
    countryCode: 'KP',
  },
  {
    name: 'Korea, Republic of South Korea',
    iso: '+82',
    countryCode: 'KR',
  },
  {
    name: 'Kosovo',
    iso: '+383',
    countryCode: 'XK',
  },
  {
    name: 'Kuwait',
    iso: '+965',
    countryCode: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    iso: '+996',
    countryCode: 'KG',
  },
  {
    name: 'Laos',
    iso: '+856',
    countryCode: 'LA',
  },
  {
    name: 'Latvia',
    iso: '+371',
    countryCode: 'LV',
  },
  {
    name: 'Lebanon',
    iso: '+961',
    countryCode: 'LB',
  },
  {
    name: 'Lesotho',
    iso: '+266',
    countryCode: 'LS',
  },
  {
    name: 'Liberia',
    iso: '+231',
    countryCode: 'LR',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    iso: '+218',
    countryCode: 'LY',
  },
  {
    name: 'Liechtenstein',
    iso: '+423',
    countryCode: 'LI',
  },
  {
    name: 'Lithuania',
    iso: '+370',
    countryCode: 'LT',
  },
  {
    name: 'Luxembourg',
    iso: '+352',
    countryCode: 'LU',
  },
  {
    name: 'Macao',
    iso: '+853',
    countryCode: 'MO',
  },
  {
    name: 'Macedonia',
    iso: '+389',
    countryCode: 'MK',
  },
  {
    name: 'Madagascar',
    iso: '+261',
    countryCode: 'MG',
  },
  {
    name: 'Malawi',
    iso: '+265',
    countryCode: 'MW',
  },
  {
    name: 'Malaysia',
    iso: '+60',
    countryCode: 'MY',
  },
  {
    name: 'Maldives',
    iso: '+960',
    countryCode: 'MV',
  },
  {
    name: 'Mali',
    iso: '+223',
    countryCode: 'ML',
  },
  {
    name: 'Malta',
    iso: '+356',
    countryCode: 'MT',
  },
  {
    name: 'Marshall Islands',
    iso: '+692',
    countryCode: 'MH',
  },
  {
    name: 'Martinique',
    iso: '+596',
    countryCode: 'MQ',
  },
  {
    name: 'Mauritania',
    iso: '+222',
    countryCode: 'MR',
  },
  {
    name: 'Mauritius',
    iso: '+230',
    countryCode: 'MU',
  },
  {
    name: 'Mayotte',
    iso: '+262',
    countryCode: 'YT',
  },
  {
    name: 'Mexico',
    iso: '+52',
    countryCode: 'MX',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    iso: '+691',
    countryCode: 'FM',
  },
  {
    name: 'Moldova',
    iso: '+373',
    countryCode: 'MD',
  },
  {
    name: 'Monaco',
    iso: '+377',
    countryCode: 'MC',
  },
  {
    name: 'Mongolia',
    iso: '+976',
    countryCode: 'MN',
  },
  {
    name: 'Montenegro',
    iso: '+382',
    countryCode: 'ME',
  },
  {
    name: 'Montserrat',
    iso: '+1664',
    countryCode: 'MS',
  },
  {
    name: 'Morocco',
    iso: '+212',
    countryCode: 'MA',
  },
  {
    name: 'Mozambique',
    iso: '+258',
    countryCode: 'MZ',
  },
  {
    name: 'Myanmar',
    iso: '+95',
    countryCode: 'MM',
  },
  {
    name: 'Namibia',
    iso: '+264',
    countryCode: 'NA',
  },
  {
    name: 'Nauru',
    iso: '+674',
    countryCode: 'NR',
  },
  {
    name: 'Nepal',
    iso: '+977',
    countryCode: 'NP',
  },
  {
    name: 'Netherlands',
    iso: '+31',
    countryCode: 'NL',
  },
  {
    name: 'Netherlands Antilles',
    iso: '+599',
    countryCode: 'AN',
  },
  {
    name: 'New Caledonia',
    iso: '+687',
    countryCode: 'NC',
  },
  {
    name: 'New Zealand',
    iso: '+64',
    countryCode: 'NZ',
  },
  {
    name: 'Nicaragua',
    iso: '+505',
    countryCode: 'NI',
  },
  {
    name: 'Niger',
    iso: '+227',
    countryCode: 'NE',
  },
  {
    name: 'Nigeria',
    iso: '+234',
    countryCode: 'NG',
  },
  {
    name: 'Niue',
    iso: '+683',
    countryCode: 'NU',
  },
  {
    name: 'Norfolk Island',
    iso: '+672',
    countryCode: 'NF',
  },
  {
    name: 'Northern Mariana Islands',
    iso: '+1670',
    countryCode: 'MP',
  },
  {
    name: 'Norway',
    iso: '+47',
    countryCode: 'NO',
  },
  {
    name: 'Oman',
    iso: '+968',
    countryCode: 'OM',
  },
  {
    name: 'Pakistan',
    iso: '+92',
    countryCode: 'PK',
  },
  {
    name: 'Palau',
    iso: '+680',
    countryCode: 'PW',
  },
  {
    name: 'Palestinian Territory, Occupied',
    iso: '+970',
    countryCode: 'PS',
  },
  {
    name: 'Panama',
    iso: '+507',
    countryCode: 'PA',
  },
  {
    name: 'Papua New Guinea',
    iso: '+675',
    countryCode: 'PG',
  },
  {
    name: 'Paraguay',
    iso: '+595',
    countryCode: 'PY',
  },
  {
    name: 'Peru',
    iso: '+51',
    countryCode: 'PE',
  },
  {
    name: 'Philippines',
    iso: '+63',
    countryCode: 'PH',
  },
  {
    name: 'Pitcairn',
    iso: '+64',
    countryCode: 'PN',
  },
  {
    name: 'Poland',
    iso: '+48',
    countryCode: 'PL',
  },
  {
    name: 'Portugal',
    iso: '+351',
    countryCode: 'PT',
  },
  {
    name: 'Puerto Rico',
    iso: '+1939',
    countryCode: 'PR',
  },
  {
    name: 'Qatar',
    iso: '+974',
    countryCode: 'QA',
  },
  {
    name: 'Romania',
    iso: '+40',
    countryCode: 'RO',
  },
  {
    name: 'Russia',
    iso: '+7',
    countryCode: 'RU',
  },
  {
    name: 'Rwanda',
    iso: '+250',
    countryCode: 'RW',
  },
  {
    name: 'Reunion',
    iso: '+262',
    countryCode: 'RE',
  },
  {
    name: 'Saint Barthelemy',
    iso: '+590',
    countryCode: 'BL',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    iso: '+290',
    countryCode: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    iso: '+1869',
    countryCode: 'KN',
  },
  {
    name: 'Saint Lucia',
    iso: '+1758',
    countryCode: 'LC',
  },
  {
    name: 'Saint Martin',
    iso: '+590',
    countryCode: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    iso: '+508',
    countryCode: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    iso: '+1784',
    countryCode: 'VC',
  },
  {
    name: 'Samoa',
    iso: '+685',
    countryCode: 'WS',
  },
  {
    name: 'San Marino',
    iso: '+378',
    countryCode: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    iso: '+239',
    countryCode: 'ST',
  },
  {
    name: 'Saudi Arabia',
    iso: '+966',
    countryCode: 'SA',
  },
  {
    name: 'Senegal',
    iso: '+221',
    countryCode: 'SN',
  },
  {
    name: 'Serbia',
    iso: '+381',
    countryCode: 'RS',
  },
  {
    name: 'Seychelles',
    iso: '+248',
    countryCode: 'SC',
  },
  {
    name: 'Sierra Leone',
    iso: '+232',
    countryCode: 'SL',
  },
  {
    name: 'Singapore',
    iso: '+65',
    countryCode: 'SG',
  },
  {
    name: 'Slovakia',
    iso: '+421',
    countryCode: 'SK',
  },
  {
    name: 'Slovenia',
    iso: '+386',
    countryCode: 'SI',
  },
  {
    name: 'Solomon Islands',
    iso: '+677',
    countryCode: 'SB',
  },
  {
    name: 'Somalia',
    iso: '+252',
    countryCode: 'SO',
  },
  {
    name: 'South Africa',
    iso: '+27',
    countryCode: 'ZA',
  },
  {
    name: 'South Sudan',
    iso: '+211',
    countryCode: 'SS',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    iso: '+500',
    countryCode: 'GS',
  },
  {
    name: 'Spain',
    iso: '+34',
    countryCode: 'ES',
  },
  {
    name: 'Sri Lanka',
    iso: '+94',
    countryCode: 'LK',
  },
  {
    name: 'Sudan',
    iso: '+249',
    countryCode: 'SD',
  },
  {
    name: 'Suriname',
    iso: '+597',
    countryCode: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    iso: '+47',
    countryCode: 'SJ',
  },
  {
    name: 'Swaziland',
    iso: '+268',
    countryCode: 'SZ',
  },
  {
    name: 'Sweden',
    iso: '+46',
    countryCode: 'SE',
  },
  {
    name: 'Switzerland',
    iso: '+41',
    countryCode: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    iso: '+963',
    countryCode: 'SY',
  },
  {
    name: 'Taiwan',
    iso: '+886',
    countryCode: 'TW',
  },
  {
    name: 'Tajikistan',
    iso: '+992',
    countryCode: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    iso: '+255',
    countryCode: 'TZ',
  },
  {
    name: 'Thailand',
    iso: '+66',
    countryCode: 'TH',
  },
  {
    name: 'Timor-Leste',
    iso: '+670',
    countryCode: 'TL',
  },
  {
    name: 'Togo',
    iso: '+228',
    countryCode: 'TG',
  },
  {
    name: 'Tokelau',
    iso: '+690',
    countryCode: 'TK',
  },
  {
    name: 'Tonga',
    iso: '+676',
    countryCode: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    iso: '+1868',
    countryCode: 'TT',
  },
  {
    name: 'Tunisia',
    iso: '+216',
    countryCode: 'TN',
  },
  {
    name: 'Turkey',
    iso: '+90',
    countryCode: 'TR',
  },
  {
    name: 'Turkmenistan',
    iso: '+993',
    countryCode: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    iso: '+1649',
    countryCode: 'TC',
  },
  {
    name: 'Tuvalu',
    iso: '+688',
    countryCode: 'TV',
  },
  {
    name: 'Uganda',
    iso: '+256',
    countryCode: 'UG',
  },
  {
    name: 'Ukraine',
    iso: '+380',
    countryCode: 'UA',
  },
  {
    name: 'United Arab Emirates',
    iso: '+971',
    countryCode: 'AE',
  },
  {
    name: 'United Kingdom',
    iso: '+44',
    countryCode: 'GB',
  },
  {
    name: 'United States',
    iso: '+1',
    countryCode: 'US',
  },
  {
    name: 'Uruguay',
    iso: '+598',
    countryCode: 'UY',
  },
  {
    name: 'Uzbekistan',
    iso: '+998',
    countryCode: 'UZ',
  },
  {
    name: 'Vanuatu',
    iso: '+678',
    countryCode: 'VU',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    iso: '+58',
    countryCode: 'VE',
  },
  {
    name: 'Vietnam',
    iso: '+84',
    countryCode: 'VN',
  },
  {
    name: 'Virgin Islands, British',
    iso: '+1284',
    countryCode: 'VG',
  },
  {
    name: 'Virgin Islands, U.S.',
    iso: '+1340',
    countryCode: 'VI',
  },
  {
    name: 'Wallis and Futuna',
    iso: '+681',
    countryCode: 'WF',
  },
  {
    name: 'Yemen',
    iso: '+967',
    countryCode: 'YE',
  },
  {
    name: 'Zambia',
    iso: '+260',
    countryCode: 'ZM',
  },
  {
    name: 'Zimbabwe',
    iso: '+263',
    countryCode: 'ZW',
  },
];
