import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useAppDispatch, useAppSelector, useAuth } from '../../shared/hooks';
import {
  CONTAINS_FILTER_OPERATOR,
  ENABLE_RESET_PASSWORD,
  ROLE_TO_STRING,
} from '../../shared/constants';
import { getUsersThunk, clearUsersState, setUsersGridModel, setUsersActive } from './usersSlice';
import { setDialog } from '../main-screen/mainScreenSlice';
import { setEditedUserId } from './editUserSlice';
import { getGenericHeight, getGridRequest } from '../../shared/utils';
import {
  CommonGridFilter,
  FlexBox,
  InvertedRoundButton,
  IsolatedGrid,
  CommonGridFilterHandle,
} from '../../shared/components';
import { Dialogs, GridColumns, UserRole } from '../../shared/enums';
import { setResetUserPasswordEmail } from './resetUserPasswordSlice';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';
import borders from '../../assets/scss/strokes.module.scss';

function UsersComponent() {
  const success = useAppSelector((state) => state.editUser.success);
  const usersState = useAppSelector((state) => state.users);
  const accountId = useAppSelector((state) => state.app.account?.id!);
  const dispatch = useAppDispatch();
  const filterRef = useRef<CommonGridFilterHandle>(null);
  const { grid, active } = usersState;
  const { page, pageSize, sort, filter } = grid;
  const [isUserInRole] = useAuth();
  const getUsersGridRequest = () => ({
    ...getGridRequest(grid),
    accountId,
  });
  const [tabValue, setTabValue] = useState('All');

  useEffect(() => {
    dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, active]);

  useEffect(() => {
    if (success) dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(
    () => () => {
      dispatch(clearUsersState());
    },
    [dispatch],
  );

  const editUser = (id: string) => {
    dispatch(setEditedUserId(id));
    dispatch(setDialog({ open: true, type: Dialogs.EditUser, maxWidth: 'md' }));
  };

  const resetUserPassword = (email: string) => {
    dispatch(setResetUserPasswordEmail(email));
    dispatch(setDialog({ open: true, type: Dialogs.ResetUserPassword, maxWidth: 'md' }));
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: boolean) => {
    dispatch(setUsersActive(newValue));
    filterRef.current?.clearFilterSelections();
  };

  const handleTabValueChange = (value: string) => {
    setTabValue(value);
  };
  const columns: Array<GridColDef> = [
    {
      field: 'username',
      headerName: 'Email',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      hideable: false,
      filterable: true,
    },
    {
      field: 'roles',
      headerName: 'Roles',
      type: GridColumns.SingleSelect,
      valueOptions: Object.entries(ROLE_TO_STRING).map(([key, value]) => ({
        value: key,
        label: value,
      })),
      align: 'left',
      filterable: true,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      renderCell: ({ row: { roles } }) => (
        <FlexBox>{roles.map((role: UserRole) => ROLE_TO_STRING[role]).join(', ')}</FlexBox>
      ),
    },
    {
      field: 'active',
      headerName: 'Active',
      flex: 1,
      filterable: active === null,
      type: GridColumns.Boolean,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'action',
      resizable: false,
      disableReorder: true,
      headerName: 'Actions',
      type: GridColumns.Actions,
      flex: 1,
      minWidth: 220,
      renderCell: ({ row: { id, username } }) => (
        <FlexBox sx={{ gap: 1 }}>
          <Button
            variant="outlined"
            color="info"
            // sx={{ backgroundColor: '#fff' }}
            onClick={() => {
              editUser(id.toString());
            }}
            sx={{
              backgroundColor: colors.secondary,
              fontSize: fonts.fontSize14,
              fontWeight: fonts.fontWeight700,
              color: colors.statusSuccess,
              borderRadius: borders.borderRadius8,
              borderColor: colors.statusSuccess,
              '&:focus': colors.statusSuccess,
              '&:hover': colors.statusSuccess,
            }}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            color="error"
            disabled={!isUserInRole(ENABLE_RESET_PASSWORD)}
            // sx={{ backgroundColor: '#fff' }}
            onClick={() => {
              resetUserPassword(username.toString());
            }}
            sx={{
              backgroundColor: colors.secondary,
              fontSize: fonts.fontSize14,
              fontWeight: fonts.fontWeight700,
              color: colors.statusAlert,
              borderRadius: borders.borderRadius8,
              borderColor: colors.statusAlert,
              '&:focus': colors.statusAlert,
              '&:hover': colors.statusAlert,
            }}
          >
            Reset Password
          </Button>
        </FlexBox>
      ),
      sortable: false,
      filterable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: colors.primary,
            height: '4px',
          },
        },
      },
    },
  });

  return (
    <Box className="dashboard" sx={{ pb: '30px' }}>
      <FlexBox sx={{ gap: 1 }}>
        <Typography
          sx={{ flex: 1, color: colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 700 }}
        >
          Users
        </Typography>
        <InvertedRoundButton
          sx={{
            height: '48px',
            marginLeft: '30px',
            paddingX: '16px',
            fontWeight: fonts.fontWeight700,
            fontSize: fonts.fontSize14,
            backgroundColor: `${colors.primary} !important`,
            color: `${colors.secondary} !important`,
            borderRadius: '8px !important',
            '&.Mui-disabled': {
              backgroundColor: `${colors.strokeDefault} !important`,
            },
          }}
          onClick={() => {
            dispatch(setDialog({ open: true, type: Dialogs.InviteUser, maxWidth: 'md' }));
          }}
        >
          Invite User
        </InvertedRoundButton>
        <InvertedRoundButton
          sx={{
            height: '48px',
            marginLeft: '30px',
            paddingX: '16px',
            fontWeight: fonts.fontWeight700,
            fontSize: fonts.fontSize14,
            backgroundColor: `${colors.primary} !important`,
            color: `${colors.secondary} !important`,
            borderRadius: '8px !important',
            '&.Mui-disabled': {
              backgroundColor: `${colors.strokeDefault} !important`,
            },
          }}
          onClick={() => {
            dispatch(setDialog({ open: true, type: Dialogs.InviteApp, maxWidth: 'md' }));
          }}
        >
          Invite App
        </InvertedRoundButton>
      </FlexBox>

      <Grid container alignItems="end">
        <Grid item md={4} sm={12}>
          <Box my={1}>
            <ThemeProvider theme={theme}>
              <Tabs value={active} onChange={handleTabChange}>
                <Tab
                  value={null}
                  onClick={() => handleTabValueChange('All')}
                  label="All"
                  sx={{
                    textTransform: 'capitalize',
                    color: tabValue === 'All' ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: tabValue === 'All' ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
                <Tab
                  value="true"
                  label="Active"
                  onClick={() => handleTabValueChange('Active')}
                  sx={{
                    textTransform: 'capitalize',
                    color:
                      tabValue === 'Active' ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: tabValue === 'Active' ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
                <Tab
                  value="false"
                  label="Inactive"
                  onClick={() => handleTabValueChange('Inactive')}
                  sx={{
                    textTransform: 'capitalize',
                    color:
                      tabValue === 'Inactive' ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: tabValue === 'Inactive' ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
              </Tabs>
            </ThemeProvider>
          </Box>
        </Grid>
        <Grid item md={8} sm={12}>
          <Box my={1} display="flex" justifyContent="flex-end">
            <CommonGridFilter
              columns={columns}
              currentFilter={filter}
              onChange={(x) => {
                dispatch(setUsersGridModel({ filter: x }));
              }}
              ref={filterRef}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '400px', height: getGenericHeight(300), marginTop: 1 }}>
        <IsolatedGrid
          status={active}
          model={grid}
          columns={columns}
          onChange={(x) => {
            dispatch(setUsersGridModel(x));
          }}
        />
      </Box>
    </Box>
  );
}

export const Users = React.memo(UsersComponent);
