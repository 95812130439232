/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addCancelTokenEvent,
  getInstance,
  getLocales,
  postInstanceSettings,
  putInstance,
} from '../../../api';
import { RootState } from '../../../app';
import { InstanceSettingsType, InstanceStatus } from '../../../shared/enums';
import {
  GridRequest,
  GridResponse,
  Instance,
  Locale,
  InstanceSettingsCauseArea,
  InstanceProgramSettingsCauseArea,
} from '../../../shared/interfaces';

interface InstanceSettingsLocaleRequest extends GridRequest {
  active?: boolean;
}

interface InstanceSettingsState {
  values: Instance;
  selectedTab: Number;
  locales?: Array<Locale>;
  localesLoading: boolean;
  isNew: boolean;
  loadingInstance: boolean;
  success: boolean;
  idInstanceSetting: string;
  selectedLocale?: Locale;
}

const initialState: InstanceSettingsState = {
  values: {
    name: '',
    description: '',
    settings: {
      locale: {
        id: '',
        name: '',
        type: '',
      },
      loginUrl: '',
      portalUrl: '',
      givewithEmail: '',
      serviceFee: null,
      allowRegistration: true,
      stripe: {
        trialPeriodDays: 0,
        currencyFactor: 0,
        prices: {
          annual: {
            id: '',
            amount: 0,
            footer: '',
            enabled: true,
          },
          monthly: {
            id: '',
            amount: 0,
            footer: '',
            enabled: true,
          },
          required: true,
        },
        suppliers: {
          annual: {
            id: '',
            amount: 0,
            footer: '',
            enabled: true,
          },
          monthly: {
            id: '',
            amount: 0,
            footer: '',
            enabled: true,
          },
          required: true,
        },
      },
      sage: {
        exchangeRate: '',
        locationId: '',
        billTaxDetailId: '',
        invoiceTaxDetailId: '',
        taxSolutionId: '',
        accounts: {
          socialImpactPayable: '',
          subscriptionRevenue: '',
          transactionRevenue: '',
          socialImpactEnablement: '',
        },
        departments: {
          sales: '',
          socialImpact: '',
        },
        products: {
          annualSubscription: '',
          monthlySubscription: '',
          transaction: '',
        },
      },
      minimumFundingAmount: 1,
      defaultIncentivePercentage: 0.01,
    },
    skus: [],
    causeAreas: [],
    restrictedDomains: [],
    status: InstanceStatus.Active,
    type: InstanceSettingsType.default,
  },
  selectedTab: 0,
  locales: [],
  localesLoading: false,

  isNew: false,
  loadingInstance: false,
  success: false,
  idInstanceSetting: '',
  selectedLocale: undefined,
};

export const getLocalesInstanceSettingsThunk = createAsyncThunk<
  GridResponse<Locale>,
  InstanceSettingsLocaleRequest
>('instanceSettings/getLocalesInstanceSettingsThunk', async (request: GridRequest, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getLocales(request);
  return data;
});

export const getInstanceThunk = createAsyncThunk<Instance, string>(
  'instanceSettings/getInstanceThunk',
  async (id, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getInstance(id);
    return data;
  },
);

export const postInstanceSettingsThunk = createAsyncThunk<Instance, void, { state: RootState }>(
  'instanceSettings/postInstanceSettingsThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const {
      instanceSettings: { values },
    } = state;
    const { settings: settingsPayload, type } = { ...values };
    const { stripe } = { ...settingsPayload };
    const { suppliers, ...stripePayload } = { ...stripe };
    const payload =
      type === InstanceSettingsType.procurement
        ? values
        : { ...values, settings: { ...settingsPayload, stripe: { ...stripePayload } } };
    const { data } = await postInstanceSettings(payload);
    return data;
  },
);

export const putInstanceSettingsThunk = createAsyncThunk<Instance, void, { state: RootState }>(
  'instanceSettings/putInstanceSettingsThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const {
      instanceSettings: {
        values: { createdAt, createdBy, lastUpdated, lastUpdatedBy, ...payloadValue },
      },
    } = state;
    const { settings: settingsPayload, type } = { ...payloadValue };
    const { stripe } = { ...settingsPayload };
    const { suppliers, ...stripePayload } = { ...stripe };
    const payload =
      type === InstanceSettingsType.procurement
        ? payloadValue
        : { ...payloadValue, settings: { ...settingsPayload, stripe: { ...stripePayload } } };

    const { data } = await putInstance(payload);
    return data;
  },
);

const InstanceSettingsSlice = createSlice({
  name: 'instanceSettings',
  initialState,
  reducers: {
    setInstanceSettingsTab: (state, action: PayloadAction<number>) => {
      state.selectedTab = action.payload;
    },
    clearInstanceSettingsState: (state) => {
      _.assign(state, initialState);
    },
    setInstanceSettingsData: (state, action: PayloadAction<Partial<Instance>>) => {
      _.merge(state.values, action.payload);
    },
    setInstanceSettingsSkus: (state, action: PayloadAction<Array<number>>) => {
      state.values.skus = action.payload;
    },
    setInstanceSettingsCauseArea: (
      state,
      action: PayloadAction<Array<InstanceSettingsCauseArea>>,
    ) => {
      state.values.causeAreas = action.payload;
    },
    setProgramInstanceSettingsCauseArea: (
      state,
      action: PayloadAction<Array<InstanceProgramSettingsCauseArea>>,
    ) => {
      state.values.causeAreas = action.payload;
    },
    setInstanceSettingsIsNew: (state, action: PayloadAction<boolean>) => {
      state.isNew = action.payload;
    },
    setInstanceSettingsRestrictedDomains: (state, action: PayloadAction<Array<string>>) => {
      state.values.restrictedDomains = action.payload;
    },
    setInstanceSettingsInstanceId: (state, action: PayloadAction<string>) => {
      state.idInstanceSetting = action.payload;
    },
    setInstanceSettingsCurrentLocale: (state, action: PayloadAction<Locale>) => {
      state.selectedLocale = action.payload;
    },
    clearInstanceSettingsLocale: (state) => {
      _.assign(state.values.settings?.locale, initialState.values.settings?.locale);
      state.selectedLocale = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getLocalesInstanceSettingsThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<Locale>>) => {
          state.localesLoading = false;
          state.locales = action.payload.results;
        },
      )
      .addCase(getLocalesInstanceSettingsThunk.rejected, (state) => {
        state.localesLoading = false;
      })
      .addCase(getLocalesInstanceSettingsThunk.pending, (state) => {
        if (!state.localesLoading) state.localesLoading = true;
      })
      .addCase(postInstanceSettingsThunk.fulfilled, (state) => {
        state.loadingInstance = false;
        state.success = true;
      })
      .addCase(postInstanceSettingsThunk.rejected, (state) => {
        state.loadingInstance = false;
      })
      .addCase(postInstanceSettingsThunk.pending, (state) => {
        if (!state.loadingInstance) state.loadingInstance = true;
      })
      .addCase(getInstanceThunk.fulfilled, (state, action: PayloadAction<Instance>) => {
        state.loadingInstance = false;
        _.merge(state.values, action.payload);
      })
      .addCase(getInstanceThunk.rejected, (state) => {
        state.loadingInstance = false;
      })
      .addCase(getInstanceThunk.pending, (state) => {
        if (!state.loadingInstance) state.loadingInstance = true;
      })
      .addCase(putInstanceSettingsThunk.fulfilled, (state) => {
        state.loadingInstance = false;
        state.success = true;
      })
      .addCase(putInstanceSettingsThunk.rejected, (state) => {
        state.loadingInstance = false;
      })
      .addCase(putInstanceSettingsThunk.pending, (state) => {
        if (!state.loadingInstance) state.loadingInstance = true;
      });
  },
});

export const {
  setInstanceSettingsTab,
  clearInstanceSettingsState,
  setInstanceSettingsData,
  setInstanceSettingsIsNew,
  setInstanceSettingsSkus,
  setInstanceSettingsRestrictedDomains,
  setInstanceSettingsInstanceId,
  setInstanceSettingsCauseArea,
  setInstanceSettingsCurrentLocale,
  clearInstanceSettingsLocale,
  setProgramInstanceSettingsCauseArea,
} = InstanceSettingsSlice.actions;

export const instanceSettingsReducer = InstanceSettingsSlice.reducer;
