import React, { useEffect, useState } from 'react';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DetailsView from './DetailsView';
import { FlexBox } from './StyledComponents';
import colors from '../../assets/scss/color.module.scss';

interface CommonGridProps extends DataGridProProps {
  showIcon?: boolean;
  page?: number;
  isGiveWithUser?: boolean;
}

export const CommonGrid = styled(({ showIcon = false, page, ...props }: CommonGridProps) => {
  const [selectedRow, setSelectedRow] = useState<any | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const handleButtonClick = (row: any) => {
    setSelectedRow(row);
  };

  const handleCloseDetailsView = () => {
    setSelectedRow(null);
  };

  const handleInvoiceClick = (params: any) => {
    const orderId = params.row.itemId;
    navigate(`invoice/`, {
      state: {
        orderId,
      },
    })
  }

  const columnsWithButton = [
    ...props.columns,
    {
      field: 'details',
      headerName: 'Actions',
      minWidth: 300,
      renderCell: (params: any) => (
        <FlexBox sx={{ gap: 2 }}>
          <Button
            sx={{
              backgroundColor: colors.secondary,
              color: colors.primary,
              marginLeft: 'auto',
              ':hover': { borderColor: colors.primary },
            }}
            variant="outlined"
            onClick={() => handleInvoiceClick(params)}
          >
            View Invoice
          </Button>
          <Button
            sx={{
              backgroundColor: colors.secondary,
              color: colors.statusSuccess,
              marginLeft: 'auto',
              ':hover': { borderColor: colors.statusSuccess },
            }}
            variant="outlined"
            onClick={() => handleButtonClick(params.row)}
          >
            View Metadata
          </Button>
          {/* <Typography
            sx={{ cursor: 'pointer', textAlign: 'center' }}
            onClick={() => handleButtonClick(params.row)}
          >
            View
            <br />
            Metadata
          </Typography>
          <Typography
            sx={{ cursor: 'pointer', textAlign: 'center' }}
            onClick={() => handleButtonClick(params.row)}
          >
            View
            <br />
            Metadata
          </Typography> */}
        </FlexBox>
      ),
    },
  ];

  useEffect(() => {
    if (page! > 0 && page !== currentPage) {
      handleCloseDetailsView();
      setCurrentPage(page!);
    } else if (page === 0 && currentPage > page) {
      handleCloseDetailsView();
      setCurrentPage(page);
    }
  }, [page]);
  return (
    <>
      {showIcon ? (
        <DataGridPro {...props} columns={columnsWithButton} autoHeight autoPageSize />
      ) : (
        <DataGridPro {...props} />
      )}
      <DetailsView
        isOpen={!!selectedRow}
        onClose={handleCloseDetailsView}
        rowData={selectedRow?.metadata}
      />
    </>
  );
})(() => ({
  padding: 16,
  '& .MuiDataGrid-row': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid  #EAEAEA',
    margin: '4px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#4F4F4F',
    justifyItems: 'center',
    fontFamily: 'inter , sans-serif !important',
  },
  '& .MuiDataGrid-columnHeadersInner': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#F6F6F6',
    borderRadius: '15px 15px 0 0',
    marginBottom: '10px',
    padding: '0px 5px',
    borderBottom: '1px solid #e9ecef',
    fontFamily: 'inter , sans-serif !important',
    // height:'45px',
    // width:'1493px',
    fontSize: '18px',
    fontWeight: 400,
    color: '#4F4F4F',
  },
  '& .css-1tps3zm': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: 'none',
  },
  '& .MuiDataGrid-footerContainer': {
    paddingTop: '1rem',
  },
  '& .MuiDataGrid-selectedRowCount': {
    width: '10%',
  },
  '& .MuiDataGrid-virtualScroller': {
    overflowY: 'auto !important',
    overflowX: 'hidden !important',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },

    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      border: 'solid 3px transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey',
      outline: '1px solid slategrey',
      borderRadius: '10px',
    },
  },
  '& .MuiTabs-scroller': {
    marginLeft: '13px !important',
  },
}));
