import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { FormDialog } from '../../shared/components';
import { ENABLE_USER_SAVE } from '../../shared/constants';
import { Dialogs } from '../../shared/enums';
import { useAppSelector, useAppDispatch, useAuth } from '../../shared/hooks';
import { isNullOrWhitespace, emailValid } from '../../shared/utils';
import { EditUserForm } from './EditUserForm';
import { clearEditUserState, getEditUserThunk, putUserAdminThunk } from './editUserSlice';

export function EditUser() {
  const state = useAppSelector((x) => x.editUser);
  const dispatch = useAppDispatch();
  const { success, loading, editedUserId, editedUser } = state;
  const { firstName, lastName, username } = editedUser;
  const [isUserInRole] = useAuth();

  const isSaveValid = () =>
    isUserInRole(ENABLE_USER_SAVE) &&
    !isNullOrWhitespace(firstName) &&
    !isNullOrWhitespace(lastName) &&
    emailValid(username) &&
    !isNullOrWhitespace(username);

  useEffect(() => {
    dispatch(getEditUserThunk(editedUserId));
    return () => {
      dispatch(clearEditUserState());
    };
  }, [dispatch, editedUserId]);

  useEffect(() => {
    if (success) toast.success('User information saved');
  }, [success]);

  const saveUser = () => {
    dispatch(putUserAdminThunk());
  };

  return (
    <FormDialog
      title="Edit User"
      type={Dialogs.EditUser}
      loading={loading}
      closeFlag={success}
      Buttons={
        <Button variant="contained" size="large" onClick={saveUser} disabled={!isSaveValid()}>
          Save
        </Button>
      }
    >
      <EditUserForm />
    </FormDialog>
  );
}
