/* eslint-disable no-else-return */
import { Box, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { FlexBox, FlexBoxColumn } from '../../shared/components';
import Logo from '../../shared/components/Logo';

import { Contribution, Program } from '../../shared/interfaces';
import ProposalPageHeaderDetails from './ProposalPageDetails';

interface Props {
  downloadFunction?: () => void;
  program: Program;
  incentive?: Contribution;
  showButtons?: boolean;
  showDownload?: boolean;
  downloading?: boolean;
  watermarkText?: boolean;
}
const ProposalCopy = (clientName?: string, customerName?: string) => (
  <Typography>
    By working together,{' '}
    <Typography component="span" sx={{ fontWeight: '700 !important' }}>
      {customerName || 'you'}
    </Typography>{' '}
    and <Typography component="span">{clientName || 'our client'}</Typography> can create positive
    change in the community and for our planet, all while delivering unique business value to our
    business partners and stakeholders.
  </Typography>
);

const ProposalCopy2 = (clientName?: string, customerName?: string, sioName?: string) => (
  <Typography>
    {clientName || 'Our client'} is excited to offer {customerName || 'you'} an incredible
    opportunity to support{' '}
    <Typography component="span" sx={{ fontWeight: '700 !important' }}>
      &quot;{sioName || 'you'}&quot;
    </Typography>{' '}
  </Typography>
);

const ProposalCopy3 = (
  currency?: string | undefined,
  customerName?: string,
  grandTotal?: number,
  clientName?: string,
  sioName?: string,
) => (
  <Typography>
    As part of our proposal, and at{' '}
    <Typography component="span" sx={{ fontWeight: '700 !important' }}>
      no additional cost to {customerName || 'you'}
    </Typography>{' '}
    {clientName || 'our client'} will distribute {currency}{' '}
    {grandTotal?.toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}{' '}
    in social impact funding to {sioName} on behalf of {customerName || 'you'}.
  </Typography>
);

export const ProposalPageHeader: FC<Props> = ({
  incentive,
  program,
  downloadFunction,
  showButtons,
  showDownload,
  downloading,
  watermarkText,
}) => {
  const { grandTotal, account, customerName, currency, quoteAmount, description } = incentive || {
    grandTotal: 0,
    account: { name: '', companyLogo: '' },
    customerName: '',
    currency: 'USD',
    quoteAmount: 0,
  };
  const { name, companyLogo } = account;

  const { sio } = program;

  const currencyHelper = () => {
    const value = 100;

    if (currency === 'USD') {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })
        .formatToParts(value)
        .find((part) => part.type === 'currency')?.value;
    } else if (currency === 'EUR') {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
        .formatToParts(value)
        .find((part) => part.type === 'currency')?.value;
    } else if (currency === 'GBP') {
      return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
      })
        .formatToParts(value)
        .find((part) => part.type === 'currency')?.value;
    } else {
      return '';
    }
  };

  useEffect(() => {
    const htmlDoc = document.querySelector('html');
    htmlDoc?.classList.remove('Html--commerce');
  }, []);

  return (
    <FlexBox className="proposalHeader__container">
      {watermarkText && (
        <Box position="absolute" top="32px" left="50px" zIndex="9999">
          {' '}
          <Typography
            variant="body1"
            fontFamily="Open Sans"
            sx={{
              fontSize: '14pt',
              color: 'white',
              pointerEvents: 'none',
              fontWeight: '500',
            }}
          >
            Sample Proposal. Contact sales@scalewith.com to profit with impact.
          </Typography>
        </Box>
      )}
      <FlexBoxColumn>
        <Box sx={{ maxWidth: '250px', marginTop: '30px' }} padding={4} className="Logo_container">
          {Object.keys(account).includes('companyLogo') ? (
            <div>
              {companyLogo !== '' && <img src={companyLogo} height="56px" alt="Company Logo" />}
            </div>
          ) : (
            <Logo />
          )}
        </Box>
        <FlexBoxColumn className="proposalheadertext__container">
          {!incentive ? <Typography>{sio?.name}</Typography> : null}
          <Typography className="proposalPage_clientname" variant="h1" sx={{ color: '#fff' }}>
            {description || incentive?.customerName || program.name}
          </Typography>
          {incentive ? (
            <>
              {' '}
              <Box className="dealHeader__Text">
                <Typography
                  sx={{
                    paddingBottom: '20px',
                    fontWeight: '700 !important',
                    fontSize: '20px !important',
                  }}
                >
                  Let&apos;s Create Impact Together!
                </Typography>
                <Typography paddingBottom={1} className="paraText">
                  {ProposalCopy(name, incentive?.customerName)}
                </Typography>
                <br />
                <Typography paddingBottom={1} className="paraText">
                  {ProposalCopy2(name, customerName, sio?.name)}
                </Typography>
                <br />
                <Typography paddingBottom={8} className="paraText">
                  {ProposalCopy3(
                    currencyHelper(),
                    incentive?.customerName,
                    grandTotal,
                    name,
                    sio?.name,
                  )}
                </Typography>
                <Box
                  paddingBottom={10}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    alignItems: 'end',
                    justifyContent: 'flex-start',
                  }}
                >
                  <img
                    alt="sdgWhite"
                    src="https://static.scalewith.com/assets/v1/img/png/un-sdg-wtext-72.ff2f9db780.png"
                    width={127}
                    height={80}
                  />
                  <img
                    alt="csrhubWhite"
                    className="pdf-image"
                    src="https://static.scalewith.com/assets/v1/img/png/csrhub-logo-wgtext-35.82aeb18706.png"
                    width={126}
                    height={35}
                  />
                </Box>
              </Box>
            </>
          ) : (
            <Typography>{program.description}</Typography>
          )}
        </FlexBoxColumn>
      </FlexBoxColumn>
      {incentive ? (
        <ProposalPageHeaderDetails
          currency={currency}
          totalDealValue={quoteAmount || 0}
          impactIncentive={grandTotal}
          downloadFunction={downloadFunction}
          showButtons={showButtons}
          showDownload={showDownload}
          downloading={downloading}
        />
      ) : null}
    </FlexBox>
  );
};
