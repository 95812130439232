import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addCancelTokenEvent, getCauseAreas } from '../../../../api';
import {
  CauseArea,
  GridRequest,
  GridResponse,
  InstanceSettingsCauseArea,
} from '../../../../shared/interfaces';

interface causeAreaRequest extends GridRequest {
  active: boolean;
}
interface instanceSettingsCauseAreaState {
  values: InstanceSettingsCauseArea;
  loadingCauseArea: boolean;
  success: boolean;
  causeAreaTemplates: Array<CauseArea>;
  entryMode: boolean;
  isNew: boolean;
  causeAreaIdToEdit: string;
}

const initialState: instanceSettingsCauseAreaState = {
  values: {
    active: true,
    causeArea: {
      id: '',
      name: '',
      type: 'cause_area',
    },
    displayOrder: null,
    impact: {
      impactMeasurement: '',
      unitPrice: null,
      unitValue: null,
    },
    skus: [],
  },
  loadingCauseArea: false,
  success: false,
  causeAreaTemplates: [],
  entryMode: false,
  isNew: true,
  causeAreaIdToEdit: '',
};

export const getCauseAreasInstanceSettingsThunk = createAsyncThunk<
  GridResponse<CauseArea>,
  causeAreaRequest
>(
  'instanceSettings/getCauseAreasInstanceSettingsThunk',
  async (request: causeAreaRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getCauseAreas(request);
    return data;
  },
);

const InstanceSettingsCauseAreaSlice = createSlice({
  name: 'instanceSettingsCauseArea',
  initialState,
  reducers: {
    clearInstanceSettingsCauseAreaState: (state) => {
      _.assign(state, initialState);
    },
    clearInstanceSettingsCauseAreaFormValues: (state) => {
      _.assign(state.values, initialState.values);
    },
    setInstanceSettingsCauseAreaData: (
      state,
      action: PayloadAction<Partial<InstanceSettingsCauseArea>>,
    ) => {
      _.merge(state.values, action.payload);
    },
    setInstanceSettingsCauseAreaSkus: (state, action: PayloadAction<Array<number>>) => {
      state.values.skus = action.payload;
    },
    setInstanceSettingsCauseAreaEntryMode: (state, action: PayloadAction<boolean>) => {
      state.entryMode = action.payload;
    },
    setInstanceSettingsCauseAreaIsNew: (state, action: PayloadAction<boolean>) => {
      state.isNew = action.payload;
    },
    setInstanceSettingsCauseAreaIdToEdit: (state, action: PayloadAction<string>) => {
      state.causeAreaIdToEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCauseAreasInstanceSettingsThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<CauseArea>>) => {
          state.loadingCauseArea = false;
          state.causeAreaTemplates = action.payload.results;
        },
      )
      .addCase(getCauseAreasInstanceSettingsThunk.rejected, (state) => {
        state.loadingCauseArea = false;
      })
      .addCase(getCauseAreasInstanceSettingsThunk.pending, (state) => {
        if (!state.loadingCauseArea) state.loadingCauseArea = true;
      });
  },
});

export const {
  clearInstanceSettingsCauseAreaState,
  setInstanceSettingsCauseAreaData,
  setInstanceSettingsCauseAreaSkus,
  setInstanceSettingsCauseAreaEntryMode,
  setInstanceSettingsCauseAreaIsNew,
  setInstanceSettingsCauseAreaIdToEdit,
  clearInstanceSettingsCauseAreaFormValues,
} = InstanceSettingsCauseAreaSlice.actions;

export const instanceSettingsCauseAreaReducer = InstanceSettingsCauseAreaSlice.reducer;
