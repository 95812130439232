import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCompletion, getCompletionList, getCompletionStatus, postCompletion, patchCompletion,
  deleteCompletion, downloadFile } from '../../api/portal/completionRequest';
import { addCancelTokenEvent } from '../../api';
import { GridResponse, GridModel, GridRequest } from '../../shared/interfaces';
import { Completion, CompletionList, PostCompletionRequest, StatusCount, UpdateCompletionRequest} from '../../shared/interfaces/completion/completion';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';


interface CompletionGridRequest extends GridRequest {
  status: string | null | undefined;
  completionFormName?: string;
  fundingFormName?: string;
  programName?: string;
}
interface CompletionState {
  grid: GridModel<CompletionList>;
  completion: Completion,
  completionId: string;
  updateStatusSuccess: boolean;
  status: string | null | undefined;
  loading: boolean;
  statusCount: [StatusCount]
}

const initialState: CompletionState = {
    grid: {
        name: 'completions',
        data: { totalCount: 0, results: [] },
        page: 0,
        defaultSort: { field: 'lastUpdated', direction: 'desc' },
        pageSize: DEFAULT_PAGE_SIZE,
        loadingGrid: false,
    },
    completionId: '',
    updateStatusSuccess: false,
    status: null,
    statusCount: [{
        status_list: {
            _id: '',
            count: 0
        }
    }],
    loading: false,
    completion : {
      id: '',
      completionFormName: '',
      createdAt: '',
      createdBy : '',
      editing: false,
      lastUpdated: '',
      percentComplete: 0,
      fundingFormId : '',
      siAdmin: '',
      sioName: '',
      status: '',
      fundingFormName : '',
      scalewithCustomer : '',
      client : '',
      dueDate : '',
      fundingAmount : 0,
      fundingCurrency : '',
      completion : {
        adjustments : '',
        stories : '',
      },
      funding:{
        programDate:{
          start:'',
          end:'',
        },
        location:{
          value:''
      },
        itemization:{
          value:[]
      }
      },
      general:{
        contact:{
          name:'',
          email:'',
          phone:''
        }, 
        programName:{
          internalProgramName:'',
          platformProgramName:''
        },
        programDescription:{
          description:'',
          optional:''
        }
      },
      impactAndScope:{
        causes:[],
        primaryImpact:[],
        secondaryImpacts:[],
        protectAndEnhanceForest:'',
        targetGroup:[],
        audienceAge:[],
        locationDetails:{
          regions:[],
          countries:[],
          states:[],
          cities:[],
          additionalLocationDetails:'',
          locationExplanation: {
            regions: '',
            countries: '',
            states: '',
            cities: '',
          }
        },
      },
      strategiesAndApproaches:{
        strategies:[],
        activities: {
          value: ''
        },
        approachDuration:'',
        activitiesFrequency:'',
        dataMeasurementType:'',
        outputs:[{
          description:'',
          quantity:0,
          scaletype:'',
          value:0
        }],
        outcomes:[{
          description:'',
          quantity:0,
          scaletype:'',
          value:0
        }],
        resultsMeasurement:'',
        nonprofitPartners:false,
        nonprofitPartnersDescription:'',
      },
      researchAndEvaluation:{
        researchFile:{
          name: ''
        },
        evidenceDescription:{
          selected:[]
        },
        studyDescription:{
          selected:[]
        },
        dataDescription:{
          selected:[]
        },
        environmentalOutputs: {
          selected: [],
        },
        environmentalOutputValues:{
          quantity: 0,
        description:''
        },
        outcomeDescription:[],
        researchApproach:[],
        strength:''
          },
      progress:{
        completion:{
          total:0,
          complete:0
        },
        funding:{
          total:0,
          complete:0
        },
        general:{
          total:0,
          complete:0
        },
        impactAndScope:{
          total:0,
          complete:0
        },
        strategiesAndApproaches:{
          total:0,
          complete:0
        },
        researchAndEvaluation:{
          total:0,
          complete:0
        }
      },
    },
}

export const downloadFileThunk = createAsyncThunk(
  'completions/downloadFile',
  async (request: any, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await downloadFile(request);
    return data;
  },
);

export const fetchCompletionListThunk = createAsyncThunk('completions/fetchCompletionList', async (request: CompletionGridRequest, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getCompletionList(request);
  return data;
});

export const fetchCompletion = createAsyncThunk(
  'sios/getCompletionThunk',
  async (id: string, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getCompletion(id);
    return data;
  },
);

export const fetchCompletionStatus = createAsyncThunk(
  'completions/getcompletionStatusThunk',
  async (unused, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getCompletionStatus();
    return data;
  },
);

export const addCompletion = createAsyncThunk(
  'completions/addCompletion',
  async (completion: PostCompletionRequest) => {
    const response = await postCompletion(completion);
    return response.data;
  },
);

export const updateCompletion = createAsyncThunk(
  'completions/updateCompletion',
  async ({ id, completion }: { id: string, completion: UpdateCompletionRequest }) => {
    const response = await patchCompletion(id, completion);
    return response.data;
  },
);

export const removeCompletion = createAsyncThunk(
  'completions/removeCompletion',
  async (id: string) => {
    const { data } = await deleteCompletion(id);
    return data;
  },
);


export const completionSlice = createSlice({
  name: 'completions',
  initialState,
  reducers: {
    setCompletionsGridModel: (state, action: PayloadAction<Partial<GridModel<CompletionList>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearCompletionState: (state) => {
      state.grid = initialState.grid;
      state.updateStatusSuccess = false;
    },
    clearCompletionUpdateSuccess: (state) => {
      state.updateStatusSuccess = false;
    },
    setCompletionStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setViewCompletionId: (state, action: PayloadAction<string>) => {
      state.completionId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCompletionListThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<CompletionList>>) => {
          // state.allData = action.payload.results
          state.grid.loadingGrid = false;
          state.grid.data = action.payload;
        },
      )
      .addCase(fetchCompletionListThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchCompletionListThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(
        fetchCompletionStatus.fulfilled,
        (state, action) => {
          state.statusCount = action.payload;
        },
      )
      .addCase(
        fetchCompletion.fulfilled,
        (state, action: PayloadAction<Completion>) => {
          state.grid.loadingGrid = false;
          state.completion=action.payload;
        },
      )
      .addCase(fetchCompletionStatus.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(fetchCompletionStatus.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(addCompletion.fulfilled, (state, action) => {
        state.completion = action.payload;
        state.updateStatusSuccess = true;
      })
      .addCase(addCompletion.rejected, (state, action) => {
        state.grid.loadingGrid = false;
        state.updateStatusSuccess = false;
        state.status = action.error.message;
      })
      .addCase(addCompletion.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
  },
});

export const {
  setCompletionsGridModel,
  clearCompletionState,
  setCompletionStatus,
  clearCompletionUpdateSuccess,
  setViewCompletionId
} = completionSlice.actions

export const completionReducer = completionSlice.reducer;