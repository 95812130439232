import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addCancelTokenEvent,
  getCompanyEntities,
  getCompanyEntity,
  getCompanySioandSdg,
  getCompanyRecommendations,
  getMutualCompanyRecommendations,
  getCompanySDGs,
  getCompanyThemes,
  patchCompanyEntity,
} from '../../api';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';
import {
  GridResponse,
  GridModel,
  GridRequest,
  CompanyEntity,
  CompanySioandSdg,
  CompanyRecommendedProgram,
  theme,
  companysdg,
} from '../../shared/interfaces';
import { RecommedationTabs } from '../../shared/enums';

export interface CompaniesState {
  grid: GridModel<CompanyEntity>;
  updateStatusSuccess: boolean;
  company?: CompanyEntity;
  companySioandSdg?: CompanySioandSdg[];
  status: string | null;
  loading: boolean;
  proRecommendations: CompanyRecommendedProgram[];
  selectRecommendations: CompanyRecommendedProgram[];
  enterpriseRecommendations: CompanyRecommendedProgram[];
  mutualRecommendations: CompanyRecommendedProgram[];
  currentPage: CompanyRecommendedProgram[];
  tab: RecommedationTabs;
  pageNumber: number;
  themes?: theme[];
  sdgs?: companysdg[];
}
interface CompanyGridRequest extends GridRequest {
  name: string | null;
}
interface RecommendationsResponse {
  pro_recommendations: CompanyRecommendedProgram[];
  select_recommendations: CompanyRecommendedProgram[];
  enterprise_recommendations: CompanyRecommendedProgram[];
  mutual_recommendations?: CompanyRecommendedProgram[];
  data?: CompanyRecommendedProgram[];
  default_page?: RecommedationTabs;
}
const initialState: CompaniesState = {
  grid: {
    name: 'companies',
    data: { totalCount: 0, results: [] },
    page: 0,
    defaultSort: { field: 'name', direction: 'asc' },
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
  },
  updateStatusSuccess: false,
  status: null,
  loading: false,
  selectRecommendations: [],
  proRecommendations: [],
  enterpriseRecommendations: [],
  mutualRecommendations: [],
  currentPage: [],
  tab: RecommedationTabs.Standard,
  pageNumber: 1,
  themes: [],
  sdgs: [],
};

export const getCompanyEntitesThunk = createAsyncThunk(
  'companies/getCompanyThunk',
  async (request: CompanyGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getCompanyEntities(request);
    return data;
  },
);
export const patchCompanyThunk = createAsyncThunk(
  'companies/patchCompanyThunk',
  async (request: { corporateEntityId: string; status: string }, { signal }) => {
    addCancelTokenEvent(signal);
    const { corporateEntityId, status } = request;
    const { data } = await patchCompanyEntity(corporateEntityId, status);
    return data;
  },
);

// Get ALL Recommendations
export const getCompanyRecommendationThunk = createAsyncThunk(
  'companies/getCompanyRecommendation',
  async (
    request: { entityName: string; fundingAmount: number; defaultPage: RecommedationTabs },
    { signal },
  ) => {
    addCancelTokenEvent(signal);

    const { data } = await getCompanyRecommendations({
      ...request,
    });
    data.defaultPage = request.defaultPage;
    return data;
  },
);

export const getMutualCompanyRecommendationThunk = createAsyncThunk(
  'companies/getMutualCompanyRecommendation',
  async (
    request: {
      buyerEntityId: string;
      sellerEntityId: string;
      fundingAmount: number;
      filterByThemes: string[];
      filterBySios: string[];
      filterBySDGS: string[];
      defaultPage: RecommedationTabs;
    },
    { signal },
  ) => {
    addCancelTokenEvent(signal);

    const { data } = await getMutualCompanyRecommendations({
      ...request,
    });
    data.defaultPage = request.defaultPage;
    return data;
  },
);

// This is the Thunk for Getting Filtered ENTERPRISE recommendations
export const getFilteredRecommendationThunk = createAsyncThunk(
  'companies/getFilteredRecommendation',
  async (
    request: {
      buyerEntityId: string;
      sellerEntityId: string;
      filterByThemes: string[];
      fundingAmount: number;
      filterBySios: string[];
      filterBySDGS: string[];
    },
    { signal },
  ) => {
    addCancelTokenEvent(signal);

    const { data } = await getMutualCompanyRecommendations({
      ...request,
    });
    return data?.data;
  },
);

export const getCompanyEntityThunk = createAsyncThunk(
  'companies/getCompany',
  async (request: { id: string }, { signal }) => {
    addCancelTokenEvent(signal);
    const { id } = request;
    const { data } = await getCompanyEntity(id);
    return data;
  },
);

export const getCompanySioandSdgThunk = createAsyncThunk(
  'companies/getCompanySioandSdg',
  async (request: { companyName: string }, { signal }) => {
    addCancelTokenEvent(signal);
    const { companyName } = request;
    const { data } = await getCompanySioandSdg(companyName);
    return data;
  },
);

export const getCompanyThemesThunk = createAsyncThunk(
  'companies/getCompanyThemes',
  async (
    request: { id: string; filter_by_sdgs?: string[]; filter_by_sios?: string[] },
    { signal },
  ) => {
    addCancelTokenEvent(signal);
    const { data } = await getCompanyThemes(request);
    return data.results;
  },
);

export const getCompanySDGsThunk = createAsyncThunk(
  'companies/getCompanySDGs',
  async (
    request: { id: string; filter_by_themes?: string[]; filter_by_sios?: string[] },
    { signal },
  ) => {
    addCancelTokenEvent(signal);
    const { data } = await getCompanySDGs(request);
    return data.results;
  },
);

const companyEntitiesSlice = createSlice({
  name: 'companyEntities',
  initialState,
  reducers: {
    setCompaniesGridModel: (state, action: PayloadAction<Partial<GridModel<CompanyEntity>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearcorporateEntitiesState: (state) => {
      state.grid = initialState.grid;
      state.updateStatusSuccess = false;
    },
    clearcorporateEntitiesUpdateSuccess: (state) => {
      state.updateStatusSuccess = false;
    },
    setcorporateEntitiesStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    switchTab: (state, action: PayloadAction<RecommedationTabs>) => {
      const { pageNumber } = state;
      if (action.payload === RecommedationTabs.TopTwelve) {
        state.currentPage = state.selectRecommendations;
      }
      if (action.payload === RecommedationTabs.Enterprise) {
        state.currentPage = state.enterpriseRecommendations.slice(
          (pageNumber - 1) * 12,
          pageNumber * 12,
        );
      }
      if (action.payload === RecommedationTabs.Mutual) {
        state.currentPage = state.mutualRecommendations;
      }
      if (action.payload === RecommedationTabs.Standard) {
        state.currentPage = state.proRecommendations;
      }
      state.tab = action.payload;
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      const pageNumber = action.payload;
      state.pageNumber = action.payload;
      state.currentPage = state.enterpriseRecommendations.slice(
        (pageNumber - 1) * 12,
        pageNumber * 12,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCompanyEntitesThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<CompanyEntity>>) => {
          state.grid.loadingGrid = false;
          state.grid.data = action.payload;
        },
      )
      .addCase(getCompanyEntitesThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getCompanyEntitesThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(patchCompanyThunk.fulfilled, (state, action: PayloadAction<CompanyEntity>) => {
        state.updateStatusSuccess = true;
        state.loading = false;
        const index = state.grid.data.results.findIndex((a) => a.id === action.payload.id);
        if (index === -1) return;
        state.grid.data.results[index] = action.payload;
      })
      .addCase(patchCompanyThunk.pending, (state) => {
        state.updateStatusSuccess = false;
        if (!state.loading) state.loading = true;
      })
      .addCase(getMutualCompanyRecommendationThunk.pending, (state) => {
        state.proRecommendations = [];
        state.selectRecommendations = [];
        state.enterpriseRecommendations = [];
        state.mutualRecommendations = [];
        state.currentPage = [];
        state.loading = true;
      })
      .addCase(
        getMutualCompanyRecommendationThunk.fulfilled,
        (state, action: PayloadAction<RecommendationsResponse>) => {
          const programsWithoutScores = action.payload.data!.filter(
            (program: any) => !program?.scores,
          );
          const programWIthScores = action.payload.data!.filter((program: any) => program?.scores);
          const proScores: any = programWIthScores!.filter(
            (program: any) => program?.productTier === 'pro',
          );
          const selectScores: any = programWIthScores!.filter(
            (program: any) => program?.productTier === 'select',
          );
          const enterpriseScores: any = programWIthScores!.filter(
            (program: any) => program?.productTier === 'enterprise',
          );
          const mutualScores: any = programWIthScores!.filter(
            (program: any) => program?.productTier === 'enterprise' && program?.recommendationType === "buyer_seller",
          );
          const filteredProObjects: any[] = [];
          proScores[0]?.scores?.forEach((obj2: any) => {
            const matchingObject = programsWithoutScores.find((obj1: any) => obj1.programId === obj2.programId);  
            if (matchingObject) {
              filteredProObjects.push(matchingObject);
            }
          });
          
          // const filteredProObjects = programsWithoutScores?.filter((obj1: any) =>
          //   proScores[0]?.scores?.every((obj2: any) => obj1.programId === obj2.programId),
          // );
          const filteredSelectObjects = programsWithoutScores?.filter((obj1: any) =>
            selectScores[0]?.scores?.some((obj2: any) => obj1.programId === obj2.programId),
          );
          const filteredEnterpriseObjects = programsWithoutScores?.filter((obj1: any) =>
            enterpriseScores[0]?.scores?.some((obj2: any) => obj1.programId === obj2.programId),
          );
          const filteredMutualObjects = programsWithoutScores?.filter((obj1: any) =>
            mutualScores[0]?.scores?.some((obj2: any) => obj1.programId === obj2.programId),
          );

          state.proRecommendations = filteredProObjects.concat(proScores); // action.payload.pro_recommendations;
          state.enterpriseRecommendations = filteredEnterpriseObjects.concat(enterpriseScores); // action.payload.enterprise_recommendations;
          state.selectRecommendations = filteredSelectObjects.concat(selectScores); // action.payload.select_recommendations;
          state.mutualRecommendations = filteredMutualObjects.concat(mutualScores); // action.payload.mutual_recommendations!;
          state.currentPage =
            action.payload.default_page === RecommedationTabs.Standard
              ? filteredProObjects.concat(proScores)
              : filteredSelectObjects.concat(selectScores);
          state.loading = false;
        },
      )
      .addCase(getMutualCompanyRecommendationThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getFilteredRecommendationThunk.pending, (state) => {
        state.enterpriseRecommendations = [];
        state.currentPage = [];
        state.loading = true;
      })
      .addCase(
        getFilteredRecommendationThunk.fulfilled,
        (state, action: PayloadAction<CompanyRecommendedProgram[]>) => {
          state.enterpriseRecommendations = action.payload;
          state.currentPage = action.payload.slice(0, 12);
          state.loading = false;
        },
      )
      .addCase(getFilteredRecommendationThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCompanyEntityThunk.pending, (state) => {
        state.company = undefined;
        state.loading = true;
      })
      .addCase(getCompanyEntityThunk.fulfilled, (state, action: PayloadAction<CompanyEntity>) => {
        state.company = action.payload;
        state.loading = false;
      })
      .addCase(getCompanyEntityThunk.rejected, (state) => {
        state.loading = false;
        state.company = undefined;
      })
      .addCase(getCompanySioandSdgThunk.pending, (state) => {
        state.loading = true;
        state.companySioandSdg = undefined;
      })
      .addCase(
        getCompanySioandSdgThunk.fulfilled,
        (state, action: PayloadAction<CompanySioandSdg[]>) => {
          state.loading = false;
          state.companySioandSdg = action.payload;
        },
      )
      .addCase(getCompanySioandSdgThunk.rejected, (state) => {
        state.loading = false;
        state.companySioandSdg = undefined;
      })
      .addCase(getCompanyThemesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyThemesThunk.fulfilled, (state, action: PayloadAction<theme[]>) => {
        state.themes = action.payload;
        state.loading = false;
      })
      .addCase(getCompanyThemesThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCompanySDGsThunk.fulfilled, (state, action: PayloadAction<companysdg[]>) => {
        state.loading = false;
        state.sdgs = action.payload;
      });
  },
});

export const { setCompaniesGridModel, clearcorporateEntitiesState, switchTab, setPageNumber } =
  companyEntitiesSlice.actions;

export const companyEntitiesReducer = companyEntitiesSlice.reducer;
