import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCancelTokenEvent, postRegisterUser, getIndustries } from '../../api';
import { RootState } from '../../app';
import { Industry, IndustryGrouped } from '../../shared/interfaces';

export interface RegisterState {
  firstName: string;
  lastName: string;
  emailAddress: string;
  company: string;
  industry: IndustryGrouped | null;
  industries: Array<Industry>;
  productType: string;
  locale: string;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  success: boolean;
}

const initialState: RegisterState = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  company: '',
  industry: null,
  industries: [],
  productType: '',
  locale: 'US',
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

export const getRegisterIndustriesThunk = createAsyncThunk(
  'register/getRegisterIndustriesThunk',
  async (_void, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getIndustries();
    return data;
  },
);

export const postRegisterUserThunk = createAsyncThunk<number, void, { state: RootState }>(
  'register/postRegisterUserThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const { firstName, lastName, company, industry, emailAddress, productType, locale,  } = state.register;
    const { status } = await postRegisterUser({
      user: {
        username: emailAddress,
        firstName,
        lastName,
      },
      account: {
        industry: {
          displayLabel: industry?.label,
          subIndustry: industry?.value,
        },
        company: {
          name: company,
        },
        
      },
      productType,
      locale,
    });
    return status;
  },
);

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setRegisterFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setRegisterLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setRegisterEmail: (state, action: PayloadAction<string>) => {
      state.emailAddress = action.payload;
    },
    setRegisterCompany: (state, action: PayloadAction<string>) => {
      state.company = action.payload;
    },
    setRegisterIndustry: (state, action: PayloadAction<IndustryGrouped | null>) => {
      state.industry = action.payload;
    },
    setProductType:  (state, action: PayloadAction<string>) => {
      state.productType = action.payload;
    },
    setLocale:  (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getRegisterIndustriesThunk.fulfilled,
        (state, action: PayloadAction<Array<Industry>>) => {
          state.loading = false;
          state.industries = action.payload;
        },
      )
      .addCase(getRegisterIndustriesThunk.rejected, (state) => {
        state.loading = false;
        state.industries = [];
      })
      .addCase(getRegisterIndustriesThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(postRegisterUserThunk.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = false;
      })
      .addCase(postRegisterUserThunk.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(postRegisterUserThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      });
  },
});

export const {
  setRegisterCompany,
  setRegisterEmail,
  setRegisterFirstName,
  setRegisterIndustry,
  setRegisterLastName,
  setProductType,
  setLocale,
} = registerSlice.actions;
export const registerReducer = registerSlice.reducer;
