import React from 'react';
import { Button, Box } from '@mui/material';
import { Download, Cached } from '@mui/icons-material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { FlexBox, IsolatedGrid } from '../../../shared/components';
import { CONTAINS_FILTER_OPERATOR } from '../../../shared/constants';
import { Dialogs, GridColumns } from '../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { setCauseAreaInstancesGridModel } from '../..';
import { getGenericHeight, getGridRequest } from '../../../shared/utils';
import { InstanceSettingsCauseArea } from '../../../shared/interfaces';
import { setDialog } from '../../main-screen';
import {
  getCauseAreaInstanceAssociated,
  getCauseAreaInstanceAssociatedFileThunk,
} from './causeAreaSettingsSlice';
import {
  setInstanceSettingsInstanceId,
  setInstanceSettingsTab,
} from '../../instances/instance-settings';

export const CauseAreaSettingsInstances = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((x) => x.causeAreaSettings);
  const { instanceAssociatedGrid, editCauseAreaId, loadingDownload } = state;

  const getAssignedDate = (causeAreas: Array<InstanceSettingsCauseArea>) => {
    const getCurrentCauseArea = causeAreas.find((item) => item.causeArea?.id === editCauseAreaId);
    if (!getCurrentCauseArea || getCurrentCauseArea.assignedDate === undefined) return ' ';
    const date = new Date(getCurrentCauseArea.assignedDate).toLocaleString();
    return date;
  };

  const editInstance = (id: string) => {
    dispatch(setInstanceSettingsInstanceId(id));
    dispatch(setInstanceSettingsTab(2));
    dispatch(setDialog({ open: true, type: Dialogs.InstanceSettings, maxWidth: 'md' }));
  };

  const refreshInstanceAssociated = () => {
    if (instanceAssociatedGrid.loadingGrid || !editCauseAreaId) return;
    dispatch(
      getCauseAreaInstanceAssociated({
        causeAreaId: editCauseAreaId,
        ...getGridRequest(instanceAssociatedGrid),
      }),
    );
  };

  const downloadFile = () => {
    if (!editCauseAreaId || loadingDownload) return;
    dispatch(getCauseAreaInstanceAssociatedFileThunk(editCauseAreaId));
  };

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'dateAssigned',
      headerName: 'Date Assigned',
      flex: 1,
      renderCell: ({ row: { causeAreas } }) => getAssignedDate(causeAreas),
    },
    {
      field: 'action',
      resizable: false,
      headerName: 'Actions',
      type: GridColumns.Actions,
      flex: 1,
      renderCell: ({ row: { id } }) => (
        <FlexBox sx={{ gap: 1 }}>
          <Button
            variant="outlined"
            color="info"
            sx={{ backgroundColor: '#fff' }}
            onClick={() => editInstance(id)}
          >
            View
          </Button>
        </FlexBox>
      ),
      sortable: false,
      filterable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];
  return (
    <FlexBox sx={{ gap: 1, flexDirection: 'column', maxHeight: 85 }}>
      <FlexBox sx={{ justifyContent: 'end', gap: 1 }}>
        <Cached sx={{ cursor: 'pointer' }} onClick={refreshInstanceAssociated} />
        <Download sx={{ cursor: 'pointer' }} onClick={downloadFile} />
      </FlexBox>
      <Box sx={{ minHeight: '500px', height: getGenericHeight(600), marginTop: 1 }}>
        <IsolatedGrid
          model={instanceAssociatedGrid}
          columns={columns}
          onChange={(x) => dispatch(setCauseAreaInstancesGridModel(x))}
        />
      </Box>
    </FlexBox>
  );
};
