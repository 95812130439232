import { AccountStatus, AccountType } from '../enums';
import { useAppSelector } from './redux';

export const useAccount = () => {
  const account = useAppSelector((x) => x.app.account);

  const isAccountInStatus = (statuses: Array<AccountStatus>) =>
    !!account?.status && statuses.includes(account.status as AccountStatus);

  const isAccountInType = (type: Array<AccountType>) =>
    !!account?.type && type.includes(account.type as AccountType);

  const isAccountStripeEnabled = () => account?.stripe?.stripeEnabled ?? false;

  return { isAccountInStatus, isAccountInType, isAccountStripeEnabled };
};
