import React from 'react';
import {Box} from '@mui/material';
import colors from '../../../assets/scss/color.module.scss';

interface props {
    children: {};
}

const BoxSection: React.FC<props> = ({children = {}}) => (
        <Box sx={{ p: 2, border: `1px solid ${colors.strokeDefault}`, borderRadius: '10px', mb: 2 }}>
            {children}
        </Box>
    )

export default BoxSection;