import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  Autocomplete
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { addProgram } from './ProgramSlice';
import { getUsersThunk, clearUsersState } from '../users/usersSlice';
import { getGridRequest } from '../../shared/utils';
import {
  fetchSios,
} from '../sio/sioSlice';
import { PostProgramRequest } from '../../shared/interfaces/program/program';
import '../sio/Sio.scss';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface ProgramData {
  id: string;
}
const CreateProgram = () => {
  const sioState = useAppSelector((state) => state.sios);
  const accountId = useAppSelector((state) => state.app.account?.id!);
  const programState = useAppSelector((state) => state.programs);
  const { updateStatusSuccess } = programState;
  const success = useAppSelector((state) => state.editUser.success);
  const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const { grid, active } = usersState;
  const { page, pageSize, sort, filter } = grid;
  const navigate = useNavigate();
  const [sioNames, setSioNames] = useState(sioState.grid.data.results);
  const [sioValue, setSioValue] = useState(null);

  const updatedGrid = {
    ...grid,
    pageSize: 1000,
    defaultSort: { field: 'roles', direction: 'asc' as const },
    filter: { field: 'roles', simpleValue: 'sw_si_admin' },
  };
  const getUsersGridRequest = () => ({
    ...getGridRequest(updatedGrid),
    accountId,
  });
  const [formValues, setFormValues] = useState<PostProgramRequest>({
    applicationFormName: '',
    siAdmin: '',
    sio: '',
  });

  const Roles = grid.data.results;
  const SiAdminRoles = Roles.filter((arr) => (arr.roles ? arr.roles.includes('sw_si_admin') : ''));

  const SioNameOptions = sioState.grid.data.results
  const selectOptions = SiAdminRoles.map((option) => (
    <MenuItem key={option.id} value={option.id}>
      {`${option.firstName} ${option.lastName}`}
    </MenuItem>
  ));

  useEffect(() => {
    if (sioState.grid.data.results) {
      setSioNames(sioState.grid.data.results);
    }
  }, [sioState.grid.data.results.length]);

  useEffect(() => {
    dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, active]);

  useEffect(() => {
    if (success) dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    
    dispatch(fetchSios({ status: 'approved', ...getGridRequest({...sioState.grid, pageSize: 500}) }));
  
  }, [sioState.grid.sort, sioState.grid.filter, sioState.grid.page, sioState.status, sioState.grid.pageSize]);

  useEffect(
    () => () => {
      dispatch(clearUsersState());
    },
    [dispatch],
  );
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
      setFormValues({ ...formValues, [name]: value });
  };
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    if (updateStatusSuccess) toast.success('New Program created Successfully');
  }, [updateStatusSuccess]);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newSio: ProgramData = (await dispatch(addProgram(formValues))).payload as ProgramData;
    if (newSio?.id ) {
      navigate(`/app/programs/${newSio.id}`, {
        state: {
          isCreate: true
        }
      });
    }
  };

  const filterWithString = (newInputvalue: string) => {
    const options = SioNameOptions.filter((sio) => sio.publicOrganizationName.toLowerCase().indexOf(newInputvalue.toLowerCase()) >= 0);
    setSioNames(options);
  };

  const handleSioInput = (newValue: any) => {
    setSioValue(newValue);
    const value = newValue?.id || '';
    setFormValues({ ...formValues, sio: value });
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize24,
      fontWeight: fonts.fontWeight600,
      // marginLeft: '0.8em',
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      // marginLeft: '1em',
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      }
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    accordianLabel:{
      fontSize: fonts.fontSize20,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
          fontSize: fonts.fontSize14,
          fontWeight: fonts.fontWeight400,
      }
  }
  }

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto', p: 2, borderRadius: '20px' }}>
      <Typography  sx = {commonTextStyle.heading}  gutterBottom>
        Add New Program
      </Typography>
      <Box boxShadow={3} sx={{ borderRadius: '20px',  bgcolor: 'white' }}>
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            margin: 0,
            border: '1px solid #f1f3f5',
            borderRadius: '20px',
            p: 3,
          }}
        >
          <Grid item xs={12}>
            <Typography  sx = {commonTextStyle.heading} gutterBottom>
              Overview
            </Typography>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{
              width: '100%',
              margin: 0,
              alignItems: 'flex-end',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid item xs={12} sm={3} sx={{ marginBottom: '1rem' }}>
              <Typography sx = {commonTextStyle.labelStyle}>Submission Form Name <Tooltip title="Enter Submission Form Name"><InfoIcon sx={{fontSize:'15px',color:'#868e96'}}/></Tooltip></Typography>
              <TextField
                name="applicationFormName"
                placeholder="Enter The Submission Form Name"
                fullWidth
                value={formValues.applicationFormName}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '1rem',
                    },
                  },
                }}
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={3} sx={{ marginBottom: '1rem' }}>
              <Typography sx={commonTextStyle.labelStyle}>SIO Name <Tooltip title="Select SIO Name"><InfoIcon sx={{fontSize:'15px',color:'#868e96'}}/></Tooltip></Typography>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                    clearOnBlur
                    value={sioValue}
                    options={sioNames}
                    // sx={{
                    //   width: '100%',
                    // }}
                    fullWidth
                    onChange={(e, newValue) => {
                      handleSioInput(newValue);
                    }}
                    onInputChange={(event: any, newInputvalue) => {
                      filterWithString(newInputvalue);
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Regular option
                      return option.publicOrganizationName;
                    }}
                    renderOption={(props, option) => <li {...props} key={option.id}>{option.publicOrganizationName}</li>}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Search SIO Name"
                        variant="outlined"
                        sx={{
                          ...commonTextStyle.textFieldStyle,
                          '& .MuiSelect-select': {
                            fontSize: fonts.fontSize14,
                          },
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: fonts.fontSize16, fontWeight: 600
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} sx={{ marginBottom: '1rem' }}>
              <Typography  sx={commonTextStyle.labelStyle}>ScaleWith Partnership Manager <Tooltip title="ScaleWith Partnership Manager"><InfoIcon sx={{fontSize:'15px',color:'#868e96'}}/></Tooltip></Typography>
              <FormControl fullWidth margin="normal">
                <Select
                  name="siAdmin"
                  labelId="manager-label"
                  placeholder="ScaleWith Partnership Manager"
                  value={formValues.siAdmin}
                  onChange={handleSelectChange}
                  variant="outlined"
                  sx={{
                    ...commonTextStyle.textFieldStyle,
                    '& .MuiSelect-select': {
                      fontSize: fonts.fontSize14,
                    },
                  }}
                >
                  <MenuItem selected value="" disabled>
                    <em>ScaleWith Partnership Manager</em>
                  </MenuItem>
                  {selectOptions}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={1} sx={{ marginBottom: '2rem' }}>
              <Button
              sx = {{...commonTextStyle.buttonStyle,backgroundColor: colors.primary, color: colors.secondary, marginLeft: 'auto'}}
                variant="contained"
                color="primary"
                disabled={
                  !formValues.applicationFormName ||
                  !formValues.siAdmin ||
                  !formValues.sio
                }
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box boxShadow={3} sx={{ borderRadius: '20px', height: '500px',  bgcolor: 'white' }}>
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            margin: 0,
            borderRadius: '20px',
            p: 3,
            marginTop: '2rem',
            maxHeight: '900px',
          }}
        >
          <Grid item xs={12} sx={{ marginBottom: '2rem' }}>
            <Typography sx = {commonTextStyle.heading}>Form Preview</Typography>
          </Grid>
          <Grid item xs={12} sx={{textAlign:'center', mt:3}}>
            <NoteAltIcon sx={{fontSize:'100px'}} color='primary' />
            <Typography sx={{...commonTextStyle.questionStyle,my: 3}}>Please enter above details to get a preview of Submission form.</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateProgram;
