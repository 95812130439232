import React, { FC } from 'react';
import Chart from 'react-apexcharts';
import { Loading } from '../../../shared/components';
import { ChartColors } from '../../../shared/constants';
import { CauseAreaMetricsShowBy, GiveStages, OrderStatus } from '../../../shared/enums';
import { useAppSelector } from '../../../shared/hooks';
import { CauseAreaMetricsOverViewItemResponse } from '../../../shared/interfaces';
import { formatter } from '../../../shared/utils';

interface FormatValues {
  name: string;
  data: Array<number>;
}

interface Props {
  data: Array<CauseAreaMetricsOverViewItemResponse>;
}

export const CauseAreaMetricsChart: FC<Props> = (props) => {
  const { data } = props;
  const causeAreaMetricsState = useAppSelector((x) => x.causeAreasMetrics);
  const {
    chartShowBy,
    currency: currencySelected,
    responseValues,
    isLoadingRequest,
    giveStagesSelected,
  } = causeAreaMetricsState;
  const { details } = { ...responseValues };
  const { currencies } = { ...details };

  const OPTIONS = {
    chart: {
      id: `basic-bar${Math.random()}`,
      stacked: true,
      toolbar: {
        show: true,
      },
      selection: {
        enabled: true,
      },
      zoom: {
        enabled: true,
      },
    },
    fill: {
      opacity: 1,
    },
  };

  const isSelectedStage = (stage: GiveStages) => {
    const findSelectedStage = giveStagesSelected.find((item) => item === stage);
    return findSelectedStage !== undefined;
  };

  const normalizeData = () => {
    const dataByValueOrCount = data.map((principalItem) => {
      if (chartShowBy === CauseAreaMetricsShowBy.values) return principalItem;
      const proposed = principalItem.details?.find(
        (item) => item.status === OrderStatus.PendingApproval,
      );
      const completed = principalItem.details?.find(
        (item) => item.status === OrderStatus.Completed,
      );
      const approved = principalItem.details?.find((item) => item.status === OrderStatus.Approved);
      const financialHold = principalItem.details?.find(
        (item) => item.status === OrderStatus.FinancialHold,
      );
      return {
        ...principalItem,
        proposed: proposed?.count ?? 0,
        completed: completed?.count ?? 0,
        approved: approved?.count ?? 0,
        financialHold: financialHold?.count ?? 0,
      };
    });

    const cleanData = dataByValueOrCount.filter(
      (item) =>
        item.approved !== 0 ||
        item.proposed !== 0 ||
        item.completed !== 0 ||
        item.financialHold !== 0,
    );

    return cleanData;
  };

  const getFormatData = () => {
    const dataByValueOrCount = normalizeData();
    let dataToChart: Array<FormatValues> = [];

    if (isSelectedStage(GiveStages.Proposed)) {
      const dataProposed = dataByValueOrCount.map((item) => item.proposed ?? 0);
      dataToChart = [
        ...dataToChart,
        {
          name: 'PROPOSED',
          data: [...dataProposed],
        },
      ];
    }
    if (isSelectedStage(GiveStages.Approved)) {
      const dataApproved = dataByValueOrCount.map((item) => item.approved ?? 0);
      dataToChart = [
        ...dataToChart,
        {
          name: 'APPROVED',
          data: [...dataApproved],
        },
      ];
    }
    if (isSelectedStage(GiveStages.FinancialHold)) {
      const dataFinancialHold = dataByValueOrCount.map((item) => item.financialHold ?? 0);
      dataToChart = [
        ...dataToChart,
        {
          name: 'FINANCIAL HOLD',
          data: [...dataFinancialHold],
        },
      ];
    }
    if (isSelectedStage(GiveStages.Completed)) {
      const dataCompleted = dataByValueOrCount.map((item) => item.completed ?? 0);
      dataToChart = [
        ...dataToChart,
        {
          name: 'COMPLETED',
          data: [...dataCompleted],
        },
      ];
    }
    return dataToChart;
  };

  const getCurrentCurrencySymbol = () => {
    if (chartShowBy !== CauseAreaMetricsShowBy.values) return '';
    if (currencies.length <= 0) return '';
    if (currencies.length === 1) return currencies[0].symbol;
    const findCurrency = currencies.find(
      (item) =>
        item.currency === currencySelected.currency && item.symbol === currencySelected.symbol,
    );
    if (!findCurrency) return '';
    return findCurrency.symbol;
  };

  const getOnlyImpact = () => data.filter((item) => item.impact !== 0 || item.impact !== undefined);

  const getShowByImpact = () => {
    const impactValues = getOnlyImpact();
    const formatterValues = impactValues.map((item) => item.impact ?? 0);
    const finalData: Array<FormatValues> = [
      {
        name: 'IMPACT',
        data: [...formatterValues],
      },
    ];
    return finalData;
  };

  const getCategories = () => {
    if (chartShowBy === CauseAreaMetricsShowBy.impact) {
      const filterData = getOnlyImpact();
      return filterData.map((item) => item.key?.causeArea) as Array<string>;
    }
    const values = normalizeData();
    return values.map((item) => item.key?.causeArea) as Array<string>;
  };

  const currentSymbol = getCurrentCurrencySymbol();

  const formatterTittleToolTip = (value: any) => value;

  const formatterValueTooltip = (value: any, options: any) => {
    if (chartShowBy !== CauseAreaMetricsShowBy.impact)
      return `${currentSymbol}${formatter.format(value)}`;
    const impactValues = getOnlyImpact();
    return `${formatter.format(value)} ${
      impactValues[options.dataPointIndex].impactMeasurement ?? ''
    }`;
  };

  const formatterValueBar = (value: any) => `${currentSymbol}${formatter.format(value)}`;

  const getColors = () => {
    const values = Object.values(GiveStages).map((item) => {
      const isSelected = giveStagesSelected.find((stage) => stage === item);
      if (isSelected === undefined) return undefined;
      const key = isSelected.replace(' ', '');
      return ChartColors[key as keyof typeof ChartColors];
    });
    return values.filter((item) => item !== undefined);
  };

  if (isLoadingRequest) return <Loading loading={isLoadingRequest} />;
  return (
    <Chart
      options={{
        ...OPTIONS,

        legend: {
          position: 'top',
        },
        yaxis: {
          labels: {
            formatter: (values: any) => `${currentSymbol}${values}`,
          },
        },
        xaxis: {
          type: 'category',
          categories: getCategories(),
          tickPlacement: 'between',
          labels: {
            rotate: 0,
            hideOverlappingLabels: false,
            trim: true,
          },
        },
        colors: getColors(),
        tooltip: {
          x: {
            formatter: formatterTittleToolTip,
          },
          y: {
            formatter: formatterValueTooltip,
          },
        },
        dataLabels: {
          formatter: formatterValueBar,
        },
      }}
      series={chartShowBy === CauseAreaMetricsShowBy.impact ? getShowByImpact() : getFormatData()}
      type="bar"
      height={450}
    />
  );
};
