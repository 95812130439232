import React, { useState, useEffect, ChangeEvent } from 'react';
import { Grid, Typography, TextField, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import './programFundingForm.scss';
import { useParams } from 'react-router-dom';
import { fetchFundingbyUser } from './fundingSlice';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import { CellPhoneInput } from '../../shared/components';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

type ChildComponentProps = {
  onDataSubmit: (data: any) => void;
};

// const commonTextStyle = {
//   textFieldStyle: {
//     border: `1px solid ${colors.strokeDefault}`,
//     borderRadius: '5px',
//     '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
//       fontSize: fonts.fontSize14,
//       fontWeight: fonts.fntWeight400,
//     },
//   },
// };

const GeneralInformation: React.FC<ChildComponentProps> = ({ onDataSubmit }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const fundingState = useAppSelector((state) => state.funding);
  const { funding } = fundingState;
  const initialFormValue = {
    general: {
      contact: {
        name: funding.general?.contact?.name,
        email: funding.general?.contact?.email,
        phone: funding.general?.contact?.phone,
      },
      programName: {
        internalProgramName: funding.general?.programName?.internalProgramName,
        platformProgramName: funding.general?.programName?.platformProgramName,
      },
      programDescription: {
        description: funding.general?.programDescription?.description,
      },
    },
  };

  const [formValues, setFormValues] = useState(initialFormValue);

  useEffect(() => {
    if (id && funding) {
      dispatch(fetchFundingbyUser(id)).then((fundingData: any) => {
        setFormValues({
          general: {
            contact: {
              name: fundingData.payload?.general?.contact?.name,
              email: fundingData.payload?.general?.contact?.email,
              phone: fundingData.payload?.general?.contact?.phone,
            },
            programName: {
              internalProgramName: fundingData.payload?.general?.programName?.internalProgramName,
              platformProgramName: fundingData.payload?.general?.programName?.platformProgramName,
            },
            programDescription: {
              description: fundingData.payload?.general?.programDescription?.description,
            },
          },
        });
      });
    }
  }, [id]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      general: {
        ...prevFormValues.general,
        contact: {
          ...prevFormValues.general.contact,
          [name]: value,
        },
        programName: {
          ...prevFormValues.general.programName,
          [name]: value,
        },
        programDescription: {
          ...prevFormValues.general.programDescription,
          [name]: value,
        },
      },
    }));
  };

  useEffect(() => {
    onDataSubmit(formValues);
  }, [formValues]);

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      sx={{ display: 'flex', gap: '2rem', marginTop: '2rem' }}
      className="formContainer"
    >
      <Grid container>
        <Grid
          item
          xs={14}
          sx={{
            background: colors.backgroundPrimary,
            padding: '8px',
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px',
            marginLeft: '-10px',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontSize: fonts.fontSize24,
              fontWeight: fonts.fontWeight700,
              color: colors.textPrimary,
            }}
          >
            General Information
          </Typography>
          <Typography
            // variant="caption"
            sx={{
              fontSize: fonts.fontSize18,
              fontWeight: fonts.fontWeight400,
              color: colors.textSecondary,
            }}
          >{`${funding?.progress?.general?.complete} of ${funding?.progress?.general?.total} Completed`}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1} className="DetailsContainer">
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: fonts.fontSize14,
              fontWeight: fonts.fontWeight400,
              color: colors.textSecondary,
            }}
          >
            Question 1 of 3<span style={{ color: 'red' }}>*</span>
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.fontSize18,
              fontWeight: fonts.fontWeight600,
              color: colors.textPrimary,
            }}
          >
            Who will be the main contact person for this program?
          </Typography>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px', marginLeft: '-3px' }}>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                mb: 1,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textPrimary,
              }}
            >
              First And Last Name{' '}
              <Tooltip title="Enter full name">
                <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
              </Tooltip>
            </Typography>
            <TextField
              name="name"
              value={formValues.general.contact.name}
              onChange={handleChange}
              placeholder="Enter first and last name"
              fullWidth
              // onChange={handleChange}
              margin="normal"
              // value={formValues.firstLastName}
              variant="outlined"
              InputProps={{
                sx: {
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '1rem',
                  },
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                },
                marginTop: '-3px',
                borderRadius: '5px',
                '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fntWeight400,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                mb: 1,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textPrimary,
              }}
            >
              Email Address{' '}
              <Tooltip title="Enter email address">
                <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
              </Tooltip>
            </Typography>
            <TextField
              name="email"
              value={formValues.general.contact.email}
              onChange={handleChange}
              placeholder="Enter email address"
              fullWidth
              margin="normal"
              // onChange={handleChange}
              // value={formValues.professionalTitle}
              variant="outlined"
              InputProps={{
                sx: {
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '1rem',
                  },
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                },
                marginTop: '-3px',
                borderRadius: '5px',
                '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fntWeight400,
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px', marginLeft: '-3px' }}>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                mb: 1,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textPrimary,
              }}
              variant="subtitle1"
            >
              Phone Number
              <Tooltip title="Enter phone number">
                <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
              </Tooltip>
            </Typography>
            <TextField
              name="phone"
              value={formValues.general.contact.phone}
              onChange={handleChange}
              placeholder="Enter email address"
              fullWidth
              margin="normal"
              variant="outlined"
              type="text"
              error={
                formValues?.general?.contact?.phone !== undefined &&
                formValues?.general?.contact?.phone!.length > 0 &&
                (formValues?.general?.contact?.phone![0] === '0' ||
                  formValues?.general?.contact?.phone![0] === '1' ||
                  (formValues?.general?.contact?.phone!.length < 10 &&
                    formValues?.general?.contact?.phone !== ''))
              }
              InputProps={{
                inputComponent: CellPhoneInput as any,
                sx: {
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '1rem',
                  },
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                },
                marginTop: '-3px',
                borderRadius: '5px',
                '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fntWeight400,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1} className="DetailsContainer">
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: fonts.fontSize14,
              fontWeight: fonts.fontWeight400,
              color: colors.textSecondary,
            }}
          >
            Question 2 of 3<span style={{ color: 'red' }}>*</span>
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.fontSize18,
              fontWeight: fonts.fontWeight600,
              color: colors.textPrimary,
            }}
          >
            Please provide a name for this program?
          </Typography>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px', marginLeft: '-3px' }}>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                mb: 1,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Internal Program Name{' '}
              <Tooltip title="Enter Internal Program Name">
                <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
              </Tooltip>
            </Typography>
            <TextField
              name="internalProgramName"
              value={formValues.general.programName.internalProgramName}
              onChange={handleChange}
              placeholder="Enter first and last name"
              fullWidth
              // onChange={handleChange}
              margin="normal"
              // value={formValues.firstLastName}
              variant="outlined"
              InputProps={{
                sx: {
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '1rem',
                  },
                  borderRadius: '5px',
                  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fntWeight400,
                  },
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                },
                marginTop: '-3px',
                borderRadius: '5px',
                '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fntWeight400,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                mb: 1,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Platform Program Name{' '}
              <Tooltip title="Enter Platform Program Name">
                <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
              </Tooltip>
            </Typography>
            <TextField
              name="platformProgramName"
              value={formValues.general.programName.platformProgramName}
              onChange={handleChange}
              placeholder="Enter your platform program name"
              fullWidth
              margin="normal"
              // onChange={handleChange}
              // value={formValues.professionalTitle}
              variant="outlined"
              InputProps={{
                sx: {
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '1rem',
                  },
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                },
                marginTop: '-3px',
                borderRadius: '5px',
                '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fntWeight400,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1} className="DetailsContainer">
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: fonts.fontSize14,
              fontWeight: fonts.fontWeight400,
              color: colors.textSecondary,
            }}
          >
            Question 3 of 3<span style={{ color: 'red' }}>*</span>
          </Typography>
          <Typography
            sx={{
              fontSize: fonts.fontSize18,
              fontWeight: fonts.fontWeight600,
              color: colors.textPrimary,
            }}
          >
            Please provide a description of this program.
          </Typography>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px', marginLeft: '-3px' }}>
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                mb: 1,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Description{' '}
              <Tooltip title="Enter description">
                <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
              </Tooltip>
            </Typography>
            <TextField
              name="description"
              value={formValues.general.programDescription.description}
              onChange={handleChange}
              fullWidth
              placeholder="Enter description here"
              multiline
              rows={2}
              maxRows={4}
              margin="normal"
              variant="outlined"
              sx={{
                margin: '0',
                marginTop: '-3px',
                borderRadius: '5px',
                '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fntWeight400,
                },
              }}
              InputProps={{
                sx: {
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '1rem',
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralInformation;
