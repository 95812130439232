import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import { Typography, Tooltip, Box, InputAdornment } from '@mui/material';
import searchIcon from '../../../assets/svg/Search.svg';
import closeIcon from '../../../assets/svg/closeIcon2.svg';
import { getFilterList, getFilteredCauseAreas } from '../../../api/portal/programRequest';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface ProgramOptionType {
  displayName: string;
  value: string;
}
interface Props {
  onChangeField?: ((value: any) => void) | undefined;
  onChangeValue?: ((value: any) => void) | undefined;
}

const ProgramFilterSearch: React.FC<Props> = ({ onChangeField, onChangeValue }) => {
  const [primaryList, setprimaryList] = useState([]);
  const [secondaryList, setSecondaryList] = useState<any>([]);
  const [isPrimaryFilter, setIsPrimaryFilter] = useState(false);
  const [isSecondaryFilter, setIsSecondaryFilter] = useState(false);
  const [primaryFilter, setPrimaryFilter] = React.useState<ProgramOptionType | null>(null);
  const [secondaryFilter, setSecondaryFilter] = useState<string | null>();

  const flexValue = primaryFilter ? '0 0 50%' : '1'; // Adjust the width as needed

  useEffect(() => {
    getFilterList().then((res) => {
      if (res.status === 200) {
        setprimaryList(res.data.result);
      }
    });
  }, []);

  useEffect(() => {
    if (
      (secondaryFilter !== null && secondaryFilter?.length! > 1) ||
      primaryFilter?.value === 'age'
    ) {
      if (primaryFilter?.value) {
        const responseFunction = async () => {
          const field: any = primaryFilter?.value;
          const fieldValue: any = primaryFilter?.value !== 'age' ? secondaryFilter : 0;
          const response = await getFilteredCauseAreas({
            offset: 0,
            count: 10,
            orderBy: 'lastUpdated',
            descending: true,
            [field]: fieldValue,
          }).then((res) => res.data);
          if (response !== null) {
            setSecondaryList(response);
          }
          return response;
        };
        responseFunction();
      }
    }
  }, [secondaryFilter, primaryFilter?.value === 'age']);

  const newList = primaryList?.map((item: any) => ({
    ...item,
    displayName: `/${item.displayName}`,
  }));
  const defaultProps = {
    options: newList || [],
    getOptionLabel: (option: ProgramOptionType) => option.displayName,
  };

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '5px' }}>
      <div>
        <Typography
          sx={{
            fontSize: fonts.fontSize16,
            fontWeight: fonts.fontWeight600,
            color: colors.textPrimary,
          }}
        >
          Filter by Search{' '}
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  width: '220px',
                },
              },
            }}
            arrow
            placement="bottom-start"
            title="This Filter by Search allows user to Filter below Table content by a specific criteria such as Cause Area, Program Name, SIO Name, Primary Impact, Region, Age, Country, State and City."
          >
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            border: 'solid 1px #4F4F4F',
            borderRadius: '5px',
            minWidth: '450px',
            height: '44px',
            maxWidth: '560px',
          }}
        >
          <img
            src={searchIcon}
            alt="search"
            height="18px"
            style={{ alignSelf: 'center', marginLeft: '10px' }}
          />
          <Box sx={{ width: isSecondaryFilter ? '172px' : '100%' }}>
            <Autocomplete
              style={{ flex: flexValue }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                '.MuiFormControl-root .MuiInputBase-root': {
                  padding: '10px',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiFormLabel-root': {
                  fontSize: '16px',
                },
              }}
              disableClearable
              {...defaultProps}
              open={isPrimaryFilter}
              id="Add New Program"
              value={primaryFilter!}
              isOptionEqualToValue={(option, optionValue) =>
                option.displayName === optionValue.displayName
              }
              onInputChange={(event: any, newInputvalue) => {
                if (newInputvalue.length === 0) {
                  setIsPrimaryFilter(false);
                } else if (newInputvalue.length > 0 && !newInputvalue.includes('/')) {
                  setIsPrimaryFilter(false);
                  setIsSecondaryFilter(false);
                  setPrimaryFilter({
                    displayName: newInputvalue,
                    value: newInputvalue,
                  });
                  if (onChangeField) {
                    onChangeField({
                      value: 'freeSearch',
                    });
                  }
                  if (onChangeValue) {
                    onChangeValue(newInputvalue);
                  }
                } else {
                  setIsPrimaryFilter(true);
                }
              }}
              onChange={(event: any, newValue: ProgramOptionType | null) => {
                setPrimaryFilter(newValue);
                setSecondaryFilter(null);
                setIsSecondaryFilter(Object.keys(newValue || {}).length > 0);
                setIsPrimaryFilter(false);
                setSecondaryList([]);
                if (newValue && onChangeField) {
                  onChangeField(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Enter / to search by category"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="start">
                        <div style={{ display: 'flex' }}>
                          {isSecondaryFilter && <Typography>&nbsp; :</Typography>}
                        </div>
                      </InputAdornment>
                    ),
                    style: {
                      textOverflow: 'ellipsis',
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight600,
                    },
                  }}
                />
              )}
            />
          </Box>

          {isSecondaryFilter &&
          primaryFilter?.value !== 'region' &&
          primaryFilter?.value !== 'city' &&
          primaryFilter?.value !== 'country' &&
          primaryFilter?.value !== 'state' ? (
            <Autocomplete
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '10px',
                '.MuiFormControl-root .MuiInputBase-root': {
                  padding: '10px !important',
                },
                '.MuiFormControl-root .MuiInputBase-root .MuiAutocomplete-clearIndicator': {
                  visibility: 'visible',
                  position: 'relative',
                  left: '15px',
                },
                '.MuiFormControl-root .MuiInputBase-root .MuiAutocomplete-popupIndicator': {
                  display: 'none',
                },
                '.MuiFormControl-root .MuiInputBase-root .MuiAutocomplete-endAdornment': {
                  display: 'flex',
                  position: 'relative',
                },
                '.MuiFormControl-root .MuiInputBase-root .MuiInputAdornment-root': {
                  maxWidth: 'calc(100% - 55px)',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                backgroundColor: '#F7F8FB',
                borderRadius: '5px',
                height: '34px',
              }}
              clearIcon={
                <CancelIcon
                  fontSize="small"
                  onClick={() => {
                    if (onChangeValue) {
                      setSecondaryList([]);
                      onChangeValue(undefined);
                    }
                  }}
                />
              }
              onOpen={() => setIsSecondaryFilter(true)}
              onClose={() => {
                setIsSecondaryFilter(true);
              }}
              id="second-select-box"
              options={secondaryList}
              value={secondaryFilter}
              onInputChange={(event: any, newInputvalue) => {
                if (newInputvalue.length === 0) {
                  setSecondaryFilter(null);
                } else {
                  setSecondaryFilter(newInputvalue);
                }
              }}
              onChange={(e, newValue) => {
                setSecondaryFilter(newValue);
                if (newValue && onChangeValue) {
                  onChangeValue(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      fontSize: '16px',
                    },
                  }}
                  variant="outlined"
                />
              )}
              renderOption={(props: object, option: any) => <div {...props}>{option.label}</div>}
              style={{ flex: '1', fontSize: '16px' }}
            />
          ) : (
            isSecondaryFilter && (
              <TextField
                variant="outlined"
                size="small"
                style={{
                  textOverflow: 'ellipsis',
                  backgroundColor: '#F7F8FB',
                  borderRadius: '5px',
                  height: '34px',
                  display: 'flex',
                }}
                inputProps={{
                  style: {
                    height: '34px',
                    padding: '0 14px',
                    width: '250px',
                    fontSize: '16px',
                  },
                }}
                onChange={(event: any) => {
                  setSecondaryFilter(event.target.value);
                  if (onChangeValue) {
                    onChangeValue(event.target.value);
                  }
                }}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              />
            )
          )}

          {Object.keys(primaryFilter || {}).length > 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                setPrimaryFilter(null);
                setSecondaryFilter(null);
                setIsSecondaryFilter(false);
                if (onChangeField) {
                  onChangeField(undefined);
                }
                if (onChangeValue) {
                  onChangeValue(undefined);
                }
              }}
            >
              <img
                src={closeIcon}
                alt="search"
                height="14px"
                style={{
                  alignSelf: 'center',
                  marginRight: '10px',
                  marginLeft: `${
                    (primaryFilter?.value === 'region' ||
                      primaryFilter?.value === 'country' ||
                      primaryFilter?.value === 'state' ||
                      primaryFilter?.value === 'city') &&
                    '10px'
                  }`,
                  cursor: 'pointer',
                }}
              />
            </Box>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgramFilterSearch;
