import React, { FC, useEffect, useState } from 'react';
import { FormGroup, FormControlLabel, Checkbox, Typography, Box, Alert } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { RoleRequired } from '../../app/Security';
import { UserRole, AccountType } from '../enums';
import { useAppSelector, useAuth, useAccount } from '../hooks';
import { FlexBox, LightTooltip } from './StyledComponents';
import { ENABLE_USER_INVITE, ENABLE_USER_SAVE, GIVEWITH_ROLES } from '../constants';
import fonts from '../../assets/scss/font.module.scss';
// import { setInviteUserRoles } from '../../features';

const givewithRolesColumn = GIVEWITH_ROLES;
const customerRolesColumnOne = [UserRole.OrgAdmin, UserRole.UserAdmin];
const customerRolesColumnTwo = [UserRole.Sales, UserRole.Approver, UserRole.Transactor];
const customerRolesColumnThree = [UserRole.Finance, UserRole.Executive];

const procUserRole = ['org_admin', 'user_admin', 'finance'];

const roleDescriptions = {
  [UserRole.SuperAdmin]: 'Scalewith admin',
  [UserRole.GivewithCSM]: 'Scalewith CSM',
  [UserRole.GivewithFinance]: 'Scalewith Finance',
  [UserRole.GivewithOperations]: 'Scalewith Operations',
  [UserRole.OrgAdmin]: 'Full access to account',
  [UserRole.UserAdmin]: 'Can invite/update users',
  [UserRole.Sales]: 'Can create Contributions',
  [UserRole.Approver]: 'Can approve Contributions',
  [UserRole.Transactor]: 'Can update approved Contributions status',
  [UserRole.Finance]: 'Can credit/debit account',
  [UserRole.Executive]: 'Can view financial info',
  [UserRole.SIAdmin]: 'Scalewith SI Admin',
};

const roleLabel = {
  [UserRole.SuperAdmin]: 'Scalewith admin',
  [UserRole.GivewithCSM]: 'Scalewith CSM',
  [UserRole.GivewithFinance]: 'Scalewith Finance',
  [UserRole.GivewithOperations]: 'Scalewith Operations',
  [UserRole.OrgAdmin]: 'Org Admin',
  [UserRole.UserAdmin]: 'User Admin',
  [UserRole.Sales]: 'Sales',
  [UserRole.Approver]: 'Approver',
  [UserRole.Transactor]: 'Transactor',
  [UserRole.Finance]: 'Finance',
  [UserRole.Executive]: 'Executive',
  [UserRole.SIAdmin]: 'Scalewith SI Admin',
};

interface Props {
  roles?: Array<string>;
  onRoleChange: (role: string, isSelected: boolean) => void;
  showAlert?: boolean;
}

interface LocalProps {
  onRoleChange: (role: string, isSelected: boolean) => void;
}

export const CommonRolesForm: FC<Props & LocalProps> = ({
  roles: propRoles,
  onRoleChange,
  showAlert,
}) => {
  // const { roles, onRoleChange, showAlert } = props;
  const [isUserInRole] = useAuth();
  const account = useAppSelector((state) => state.app.account);
  const userClaims = useAppSelector((state) => state.app.userClaims);
  const { isAccountInType } = useAccount();
  const isProcUser = isAccountInType([AccountType.procurement]);
  const isSio = isAccountInType([AccountType.sio]);
  const availableRoles = isProcUser
    ? Object.entries(UserRole).filter((role) => procUserRole.includes(role[1]))
    : Object.entries(UserRole);

  const filteredGWRoles = isUserInRole([UserRole.SuperAdmin])
    ? givewithRolesColumn
    : GIVEWITH_ROLES.filter((role) => isUserInRole([role]));

  const [localRoles, setLocalRoles] = useState(propRoles || []);

  useEffect(() => {
    setLocalRoles(propRoles || []);
  }, [propRoles]);

  const handleLocalRoleChange = (role: string, isSelected: boolean) => {
    setLocalRoles((prevRoles: any) => {
      if (isSelected) {
        return [...prevRoles, role];
      }
      return prevRoles.filter((r: any) => r !== role);
    });

    onRoleChange(role, isSelected);
  };

  const renderRolesColumn = (rolesColumn: Array<UserRole>) => (
    <FormGroup>
      {availableRoles
        .filter((role) => rolesColumn.includes(role[1]))
        .map(([key, value]) => (
          <LightTooltip title={roleDescriptions[value]} key={`edit-user-role-${value}`}>
            <FormControlLabel
              control={
                <Checkbox
                  key={key}
                  checked={localRoles?.includes(value)}
                  name={value}
                  onChange={(e) => {
                    // dispatch(setInviteUserRoles({ value, isSelected }));
                    // roles?.push(e.target.checked ? e.target.name : '');
                    handleLocalRoleChange(e.target.name, e.target.checked);
                  }}
                  disabled={
                    showAlert ? !isUserInRole(ENABLE_USER_SAVE) : !isUserInRole(ENABLE_USER_INVITE)
                  }
                />
              }
              label={roleLabel[value]}
            />
          </LightTooltip>
        ))}
    </FormGroup>
  );

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 1 }}>
      {showAlert ? (
        <Typography variant="h5">Roles</Typography>
      ) : (
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: fonts.fontSize18,
            fontWeight: fonts.fontWeight700,
          }}
        >
          Roles
          <LightTooltip title="If no role selection is made, a Sales role will be assigned">
            <InfoOutlined sx={{ paddingLeft: '.5rem' }} />
          </LightTooltip>
        </Typography>
      )}
      <FlexBox sx={{ gap: 6 }}>
        {userClaims?.accountId === account?.id && !isProcUser && (
          <RoleRequired
            roles={[UserRole.SuperAdmin, UserRole.GivewithFinance, UserRole.GivewithOperations]}
          >
            <Box>
              <Typography variant="overline">Scalewith</Typography>
              {renderRolesColumn(filteredGWRoles)}
            </Box>
          </RoleRequired>
        )}
        {isSio ? (
          <Box>
            <Typography variant="overline">Customer</Typography>
            <FlexBox sx={{ gap: 2 }}>{renderRolesColumn(customerRolesColumnOne)}</FlexBox>
          </Box>
        ) : (
          <Box>
            <Typography variant="overline">Customer</Typography>
            <FlexBox sx={{ gap: 2 }}>
              {renderRolesColumn(customerRolesColumnOne)}
              {renderRolesColumn(customerRolesColumnTwo)}
              {renderRolesColumn(customerRolesColumnThree)}
            </FlexBox>
          </Box>
        )}
      </FlexBox>
      {showAlert && (
        <Alert severity="warning">Role permissions will take effect upon relogging in.</Alert>
      )}
    </FlexBox>
  );
};
