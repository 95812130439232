import React, { useEffect, useState, ChangeEvent } from 'react'; // useState
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'; // Typography, Button
import {
  Grid,
  Button,
  TextField,
  Divider,
  Tooltip,
  Card,
  CardContent,
  Box,
  Typography,
  CardHeader,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import { useAppSelector, useAppDispatch } from '../../../shared/hooks';
import { fetchMpa, updateMpaThunk } from '../sioSlice';
import '../formFilling.scss';
import { TopBar } from '../SioFormFillingDashboard';
import { CellPhoneInput } from '../../../shared/components';
import { emailValid } from '../../../shared/utils';
import {
  buttonPrimary,
  headingStyles,
  questionStyles,
  questionSubStyles,
  optionSubStyles,
  questionNoStyles,
  inputFieldParaStyles,
  hintstyles,
  subHeadingStyles,
  mainHeadingStyles,
  statusStyles,
  inputFieldStyles,
} from '../../funding/FundingStyles';
import colors from '../../../assets/scss/color.module.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#68839A',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#FFF',
  },
}));

const MpaQuestions = () => {
  const sioState = useAppSelector((state) => state.sios);
  const { mpa } = sioState;
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const initialFormValue = {
    description: mpa?.info?.orgDescription,
    name: mpa?.info?.name,
    title: mpa?.info?.title,
    phone: mpa?.info?.phone,
    email: mpa?.info?.email,
    address: mpa?.info?.address,
    city: mpa?.info?.city,
    state: mpa?.info?.state,
    zip: mpa?.info?.zip,
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [data, setData] = useState(initialFormValue);

  useEffect(() => {
    if (id && mpa) {
      dispatch(fetchMpa(id)).then((mpaData: any) => {
        setFormValues({
          description: mpaData.payload?.mpa?.info?.orgDescription,
          name: mpaData.payload?.mpa?.info?.name,
          title: mpaData.payload?.mpa?.info?.title,
          phone: mpaData.payload?.mpa?.info?.phone,
          email: mpaData.payload?.mpa?.info?.email,
          address: mpaData.payload?.mpa?.info?.address,
          city: mpaData.payload?.mpa?.info?.city,
          state: mpaData.payload?.mpa?.info?.state,
          zip: mpaData.payload?.mpa?.info?.zip,
        });
        setData({
          description: mpaData.payload?.mpa?.info?.orgDescription,
          name: mpaData.payload?.mpa?.info?.name,
          title: mpaData.payload?.mpa?.info?.title,
          phone: mpaData.payload?.mpa?.info?.phone,
          email: mpaData.payload?.mpa?.info?.email,
          address: mpaData.payload?.mpa?.info?.address,
          city: mpaData.payload?.mpa?.info?.city,
          state: mpaData.payload?.mpa?.info?.state,
          zip: mpaData.payload?.mpa?.info?.zip,
        });
        // savePageNumber()
      });
    }
  }, [id]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const updateMpaDetails = () => {
    const { description, name, title, phone, email, address, city, state, zip } = data;
    const payload = {
      mpa: {
        info: {
          orgDescription: description,
          name,
          title,
          phone,
          email,
          address,
          city,
          state,
          zip,
        },
      },
    };
    const mpaPayload = payload;
    if (id) {
      return dispatch(updateMpaThunk({ id, mpa: mpaPayload }));
    }
    return true;
  };

  const handleNextbutton = async () => {
    const response = await updateMpaDetails();
    if (response) {
      toast.success('Successfully Submitted Form to ScaleWith');
      if (pageNumber < 2) {
        setPageNumber(pageNumber + 1);
      } else {
        navigate(`/mpa-form/${id}`);
      }
    } else {
      toast.error('An error occurred while logging in. Please try again.');
    }
    return null;
  };
  const handlePreviousbutton = () => {
    if (pageNumber > 1) {
      setData(formValues);
      setPageNumber(pageNumber - 1);
    }
  };
  const handleSaveExit = async () => {
    const response = await updateMpaDetails();
    if (response) {
      toast.success('Successfully Submitted Form to ScaleWith');
      navigate(`/mpa-form/${id}`);
    } else {
      toast.error('An error occurred while logging in. Please try again.');
    }
    return null;
  };
  const disabledButtons = () => {
    const { description, name, title, phone, email, address, city, state, zip } = data;
    let disable;
    if (pageNumber === 1) {
      if (!description) disable = true;
    } else if (pageNumber === 2) {
      if (
        !title ||
        !name ||
        !phone ||
        data?.phone[0] === '0' ||
        data?.phone[0] === '1' ||
        data?.phone?.length < 10 ||
        !email ||
        !emailValid(email) ||
        !address ||
        !state ||
        !city ||
        !zip
      )
        disable = true;
    }
    return disable;
  };

  // *** Start Questions ***
  const question1 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 5, ...headingStyles }}>Let&apos;s get started!</Typography>
        <Typography sx={questionNoStyles}>Question 1 of 2</Typography>
        <Typography sx={{ mb: 2, ...questionStyles }}>
          Please provide a description for your organization
        </Typography>
        <Typography sx={{ mb: 2, ...questionSubStyles }}>
          This will be visible to the companies working with ScaleWith.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ mb: 1, ...optionSubStyles }}>
          Description{' '}
          <Tooltip title="Enter Description">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="description"
          placeholder="Enter your organization's description"
          fullWidth
          margin="none"
          value={data.description}
          required
          onChange={handleChange}
          variant="outlined"
          multiline
          rows={4}
          sx={inputFieldParaStyles}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            mb: 3,
            float: 'right',
            padding: '15px 20px',
            width: '300px',
            background: '#E7F2FF',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography>
            <InfoIcon fontSize="medium" sx={{ color: colors.primary }} />
            <i style={hintstyles}>
              Please do not use our personal pronouns i.e, use the organization name instead of “we”
              or “our”.
              <br />
              Aim for 2 or 3 sentences
            </i>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );

  const question2 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={questionNoStyles}>Question 2 of 2</Typography>
        <Typography sx={{ mb: 2, ...questionStyles }}>
          Who is your authorized representative to receive legal notices ?
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={optionSubStyles}>
          First and last name{' '}
          <Tooltip title="Enter First and last name">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="name"
          placeholder="Enter First and last name"
          fullWidth
          value={data.name}
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            sx: {
              '& .MuiInputBase-input::placeholder': {
                fontSize: '1rem',
              },
            },
          }}
          sx={inputFieldStyles}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={optionSubStyles}>
          Professional Title{' '}
          <Tooltip title="Enter Professional Title">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="title"
          placeholder="Enter professional title"
          value={data.title}
          fullWidth
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            sx: {
              '& .MuiInputBase-input::placeholder': {
                fontSize: '1rem',
              },
            },
          }}
          sx={inputFieldStyles}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={optionSubStyles}>
          Email Address{' '}
          <Tooltip title="Enter Email Address">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="email"
          placeholder="Enter Email Address"
          fullWidth
          type="email"
          value={data.email}
          error={!emailValid(data.email)}
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            sx: {
              '& .MuiInputBase-input::placeholder': {
                fontSize: '1rem',
              },
            },
          }}
          sx={inputFieldStyles}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={optionSubStyles}>
          Phone Number{' '}
          <Tooltip title="Enter Phone Number">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="phone"
          placeholder="Enter Phone Number"
          type="text"
          fullWidth
          value={data.phone}
          variant="outlined"
          onChange={handleChange}
          error={
            data.phone !== undefined &&
            (data.phone[0] === '0' ||
              data.phone[0] === '1' ||
              (data.phone.length < 10 && data.phone !== ''))
          }
          InputProps={{
            inputComponent: CellPhoneInput as any,
            sx: {
              '& .MuiInputBase-input::placeholder': {
                fontSize: '1rem',
              },
            },
          }}
          sx={inputFieldStyles}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={optionSubStyles}>
          Street Address{' '}
          <Tooltip title="Enter Street Address">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="address"
          placeholder="Enter street address"
          fullWidth
          value={data.address}
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            sx: {
              '& .MuiInputBase-input::placeholder': {
                fontSize: '1rem',
              },
            },
          }}
          sx={inputFieldStyles}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={optionSubStyles}>
          City{' '}
          <Tooltip title="Enter City">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="city"
          placeholder="Enter city"
          fullWidth
          value={data.city}
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            sx: {
              '& .MuiInputBase-input::placeholder': {
                fontSize: '1rem',
              },
            },
          }}
          sx={inputFieldStyles}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={optionSubStyles}>
          State{' '}
          <Tooltip title="Enter State">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="state"
          placeholder="Enter state"
          fullWidth
          value={data.state}
          margin="none"
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            sx: {
              '& .MuiInputBase-input::placeholder': {
                fontSize: '1rem',
              },
            },
          }}
          sx={inputFieldStyles}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={optionSubStyles}>
          Zip{' '}
          <Tooltip title="Enter Zip Code">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="zip"
          placeholder="Enter Zip code"
          fullWidth
          value={data.zip}
          type="number"
          margin="none"
          onChange={handleChange}
          variant="outlined"
          InputProps={{
            inputMode: 'numeric',
            sx: {
              '& .MuiInputBase-input::placeholder': {
                fontSize: '1rem',
              },
            },
          }}
          sx={inputFieldStyles}
        />
      </Grid>
    </Grid>
  );
  // *** End Questions ***

  return (
    <section className="bgLight">
      <TopBar />
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardHeader
              sx={{
                padding: '20px 80px',
                background: colors.backgroundColorGradient,
                borderRadius: '20px 20px 0px 0px',
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography sx={{ ...subHeadingStyles, color: colors.secondary }}>
                      Section 1
                    </Typography>
                    <Typography sx={{ ...mainHeadingStyles, color: colors.secondary }}>
                      MPA Information
                    </Typography>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <Typography sx={{ ...statusStyles, color: colors.secondary }}>
                        {pageNumber === 1 ? 0 : (pageNumber - 1) * 50}% complete
                      </Typography>
                    </Grid>
                    <BorderLinearProgress
                      variant="determinate"
                      value={pageNumber === 1 ? 1 : (pageNumber - 1) * 50}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent sx={{ minHeight: '70vh', padding: '40px 80px' }}>
              {pageNumber === 1 && question1}
              {pageNumber === 2 && question2}
              <Grid item xs={12} textAlign="right">
                <Divider sx={{ my: 3 }} orientation="horizontal" flexItem />
                <Button
                  sx={{ mx: 2, ...buttonPrimary }}
                  disabled={disabledButtons()}
                  onClick={handleSaveExit}
                  variant="outlined"
                  size="large"
                >
                  Save and Exit
                </Button>
                <Button
                  sx={{ mx: 2, ...buttonPrimary }}
                  disabled={pageNumber === 1}
                  onClick={handlePreviousbutton}
                  variant="outlined"
                  size="large"
                >
                  Previous
                </Button>
                <Button
                  sx={{ mx: 2, ...buttonPrimary }}
                  disabled={disabledButtons()}
                  onClick={handleNextbutton}
                  variant="outlined"
                  size="large"
                >
                  Next
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default MpaQuestions;
