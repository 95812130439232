import React from 'react';
import { Box, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import { FlexBox } from '../../../../shared/components';
import { useAppSelector } from '../../../../shared/hooks';
import { Visible } from '../../../../shared/utils';

export const SupplierInviteError = () => {
  const inviteSupplierState = useAppSelector((x) => x.inviteSupplier);
  const { invitesSent, serverError } = { ...inviteSupplierState };

  return (
    <FlexBox sx={{ flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Visible condition={serverError}>
        <Typography variant="h4">{`${invitesSent} Invites Sent!`}</Typography>
      </Visible>
      <ErrorOutline sx={{ color: '#EA190D', fontSize: 70 }} />
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h6">
          {serverError
            ? 'Some of your invites encountered an error and were unable to be sent.'
            : 'Some invites contain errors and were not sent.  Please address the issues below to try again or cancel to continue.'}
        </Typography>
        <Visible condition={serverError}>
          <Typography variant="h6">Address the issue below and try again.</Typography>
        </Visible>
      </Box>
    </FlexBox>
  );
};
