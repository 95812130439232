import { renewAccessTokenThunk, store } from '../../app';
import { CK_AUTH_TOKEN, CK_REFRESH_TOKEN, CK_GW_TYPE, CK_USER_CLAIMS } from '../constants';
import { UserRole } from '../enums';
import { GWCookies } from './cookies';
import { isNullOrWhitespace } from './helpers';

const mfa: string = 'mfa';

export enum DepartmentType {
  Procurement = 'Procurement',
  Sales = 'Sales',
  Csr = 'CSR',
  Marketing = 'Marketing',
  Executive = 'Executive',
  Other = 'Other',
  Portal = 'Portal',
}

export interface UserClaims {
  user_id: string;
  departmentType?: string;
  subdomain?: string;
  scope?: string;
  version?: string;
  industry?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  orgName?: string;
  accountId?: string;
  exp: number;
  roles?: Array<UserRole>;
  tenantAccessGrants?: any;
}

export const verifyTokenExpiration = async (force?: boolean) => {
  const token = GWCookies.get(CK_AUTH_TOKEN);

  if (token && !force) return {};

  return store.dispatch(renewAccessTokenThunk());
};

export const logout = () => {
  GWCookies.remove(CK_AUTH_TOKEN);
  GWCookies.remove(CK_REFRESH_TOKEN);
  GWCookies.remove(CK_GW_TYPE);
  GWCookies.remove(CK_USER_CLAIMS);
};

export const redirectToLogin = (
  options = { authError: false },
  addContinue = true,
  loginUrl = '',
) => {
  let redirectRoute = isNullOrWhitespace(loginUrl) ? window.LOGIN_UI_URL : loginUrl;
  const url = `${window.AUTH0_DOMAIN}/v2/logout?returnTo=${window.AUTH0_LOGIN_DOMAIN}/&client_id=${window.AUTH0_CLIENT_ID}`;
  const redirectAuthRoute = isNullOrWhitespace(loginUrl) ? url : url;

  if (loginUrl === mfa) {
    redirectRoute = `${window.LOGIN_UI_URL}${mfa}`;
    window.location.href = redirectRoute;
    return;
  }

  if (addContinue) {
    redirectRoute = `${redirectRoute}?continue=${window.location.href}`;
  }

  if (options.authError) {
    redirectRoute = `${redirectRoute}#loginError`;
  }

  if (GWCookies.get(CK_USER_CLAIMS)) {
    logout();
    window.location.href = redirectAuthRoute;
  } else {
    logout();
    window.location.href = redirectRoute;
  }
};

export const isUserAuthorized = (match: boolean) => {
  if (!match) {
    return false;
  }

  return !!(GWCookies.get(CK_AUTH_TOKEN) || GWCookies.get(CK_REFRESH_TOKEN));
};
