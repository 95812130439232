import { Grid, Pagination, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { setPageNumber } from '../../company-intelligence/companiesSlice';
import { ProgramCard } from './ProgramCard';
import { RecommedationTabs } from '../../../shared/enums';

interface Props {
  pagination?: boolean;
  onSelect: (id: string) => void;
  selectedProgram?: string;
  fundingAmount?: number;
  loading?: boolean;
}
export const RecommendationPage: FC<Props> = ({
  onSelect,
  pagination,
  selectedProgram,
  fundingAmount,
  loading,
}) => {
  const dispatch = useAppDispatch();
  const { tab, currentPage, pageNumber, enterpriseRecommendations, proRecommendations } =
    useAppSelector((state) => state.company);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPageNumber(value));
  };
  // const lastIndex = enterpriseRecommendations.length - 1;
  const sioPrograms = tab === RecommedationTabs.Standard ? proRecommendations : currentPage;
  const sioProgramsWithoutScores = sioPrograms?.filter((program) => !program.scores);
  const getScores = sioPrograms?.filter((program) => program.scores);
  const enterpriseScores: any = enterpriseRecommendations
    ?.filter((program) => program?.scores)
    .filter((program: any) => program?.productTier === 'enterprise' && program?.recommendationType === "buyer");
  const sioProgramsWithScores: any = tab !== 2 ? getScores[0]?.scores : enterpriseScores[0]?.scores;

  console.log(sioProgramsWithoutScores);

  return (
    <Box className="recommendation__container">
      {Array.isArray(sioProgramsWithScores) && sioProgramsWithScores.length > 0 ? (
        <Grid container spacing={2}>
          {sioProgramsWithScores.map((score) => {
            const matchingProgram: any = sioProgramsWithoutScores.find(
              (program) => program.programId === score.programId,
            );
            if (
              matchingProgram &&
              matchingProgram?.sioName !== '' &&
              matchingProgram?.sioName !== undefined
            ) {
              return (
                <Grid item key={matchingProgram.programId} sx={{ width: '100%', height: 'auto' }}>
                  <ProgramCard
                    program={matchingProgram}
                    onSelect={() => onSelect(matchingProgram.programId)}
                    selected={selectedProgram === matchingProgram.programId}
                    fundingAmount={fundingAmount || 0}
                    percentage={score.percentage || 0}
                  />
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      ) : (
        <Typography sx={{ textAlign: 'center', fontSize: '2em' }}>
          {!loading && 'Recommendations not available'}
        </Typography>
      )}
      {pagination ? (
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          page={pageNumber}
          count={Math.ceil(enterpriseRecommendations.length / 12)}
          onChange={handleChange}
        />
      ) : null}
    </Box>
  );
};
