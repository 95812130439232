import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

import { FlexBox } from '../../shared/components';
import { sdg } from '../../shared/interfaces';
import { SDGIcon } from './SDGIcon';

interface Props {
  SDGS: sdg[];
}
export const SDGComponent: FC<Props> = ({ SDGS }) => (
  <FlexBox className="IndividualMetric__container SDG">
    <Box className="MetricLogo">
      <img
        src="https://static.scalewith.com/assets/v1/img/png/un-sdg-gtext-72.30118f1292.png"
        alt="sdg"
        className="pdf-image"
        width="127px"
      />
    </Box>
    <FlexBox className="SDGContainer">
      {SDGS.map(({ code, id, label }) => (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
          <SDGIcon key={code} code={code} />
          <Box className="SDGLabelsContainer">
            <Typography sx={{ fontSize: '12pt', fontWeight: '700', color: '#57585A' }} key={id}>
              {code}
            </Typography>
            <Typography sx={{ fontSize: '11pt', color: '#57585A' }} key={label}>
              {label}
            </Typography>
          </Box>
        </Box>
      ))}
    </FlexBox>
  </FlexBox>
);
