import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import {
  CommonContributionsGrid,
  deleteContributionUnshareThunk,
  FlexBox,
  RoundButton,
  setUpdateContributionStatusRequest,
  UpdateContributionStatusButton,
} from '../../shared/components';
import { RoleRequired } from '../../app/Security';
import {
  ContributionStatus,
  GridColumns,
  ContributionStatusLabel,
  Dialogs,
  AccountType,
  UserRole,
  ContributionActions,
  ConfirmDialogType,
} from '../../shared/enums';
import { useAccount, useAppDispatch, useAppSelector, useAuth } from '../../shared/hooks';
import {
  COMPLETE_ABANDON_PERMISSIONS,
  ENABLE_COMPLETE_ABANDON,
  FINANCIAL_HOLD_COMPLETE_ABANDON_PERMISSIONS,
  FULFILL_REJECT_PERMISSIONS,
  MARK_AS_PAID_PERMISSIONS,
} from '../../shared/constants';
import { setDialog } from '../main-screen';
import {
  getContributionStatusLabel,
  isAvailableShareByStatus,
  isNullOrWhitespace,
  openConfirmDialog,
} from '../../shared/utils';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';
import borders from '../../assets/scss/strokes.module.scss';

export function Fulfillment() {
  const gridState = useAppSelector((x) => x.commonContributions.grid);
  const selectedStatus = useAppSelector((x) => x.commonContributions.status);
  const success = useAppSelector((x) => x.updateContributionStatus.success);
  const [isUserInRole] = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { isAccountInType } = useAccount();
  const { selectionModel, data } = { ...gridState };
  const stateConfirmDialog = useAppSelector((x) => x.confirmDialog);
  const { value, confirmed, type } = stateConfirmDialog;

  useEffect(() => {
    if (!confirmed || type !== ConfirmDialogType.UnshareContribution || isNullOrWhitespace(value))
      return;
    dispatch(deleteContributionUnshareThunk(value!));
  }, [value, confirmed, type, dispatch]);

  const isAvailableShareByRole =
    isAccountInType([AccountType.supplier]) &&
    isUserInRole([UserRole.Finance, UserRole.SuperAdmin]);

  const isAvailableShare =
    isAvailableShareByRole && isAvailableShareByStatus(selectedStatus as ContributionStatus);

  const isSelectedTabShare = selectedStatus === ContributionActions.shared;

  const unshareContribution = (id: string) => {
    openConfirmDialog(
      {
        title: 'Unshare Contribution',
        text: 'Are you sure you would like to unshare this Contribution?',
        type: ConfirmDialogType.UnshareContribution,
        confirmText: 'Unshare',
        value: id,
      },
      'sm',
    );
  };

  const getUpdateMultipleContributionsStatus = () => {
    switch (selectedStatus as ContributionStatus) {
      case ContributionStatus.Completed:
        return ContributionActions.Fulfilled;

      case ContributionStatus.FinancialHold:
      case ContributionStatus.Approved:
      default:
        return ContributionActions.Completed;
    }
  };

  const getUpdateMultipleContributionsProposedStatus = () => {
    switch (selectedStatus as ContributionStatus) {
      case ContributionStatus.Completed:
        return ContributionActions.Fulfilled;

      case ContributionStatus.FinancialHold:
      case ContributionStatus.Approved:
      default:
        return ContributionActions.Completed;
    }
  };

  const isDisableBulkShare = () => {
    if (!selectionModel || selectionModel.length === 0) return true;
    const isDisabled = selectionModel.some((item) => {
      const allData = data.results.find((contribution) => contribution.id === item);
      return !!allData?.sharedWith?.id;
    });
    return isDisabled;
  };

  const canSelectContributions =
    /* Disable multi select for now not supporting */
    // (isUserInRole(COMPLETE_FINANCIAL_HOLD_CONTRIBUTIONS) &&
    //   [ContributionStatus.FinancialHold].includes(selectedStatus as ContributionStatus)) ||
    // (isUserInRole(COMPLETE_ABANDON_PERMISSIONS) &&
    //   [ContributionStatus.Approved].includes(selectedStatus as ContributionStatus)) ||
    isUserInRole(FULFILL_REJECT_PERMISSIONS) &&
    [ContributionStatus.Completed].includes(selectedStatus as ContributionStatus);

  const tabs = [
    { label: 'Pending Payment', value: ContributionStatus.PendingPayment },
    { label: 'Approved', value: ContributionStatus.Approved },
    { label: 'Financial Hold', value: ContributionStatus.FinancialHold },
    { label: 'Completed', value: ContributionStatus.Completed },
    { label: 'Abandoned', value: ContributionStatus.Abandoned },

    ...(isAvailableShareByRole ? [{ label: 'Shared', value: ContributionActions.shared }] : []),
    ...(isUserInRole(FULFILL_REJECT_PERMISSIONS)
      ? [
          { label: 'Fulfilled', value: ContributionStatus.Fulfilled },
          { label: 'Rejected', value: ContributionStatus.Rejected },
        ]
      : []),
  ];

  const getActionsColumnWidth = (currentStatus?: string | null) => {
    if (
      (currentStatus === ContributionStatus.Approved &&
        isUserInRole(COMPLETE_ABANDON_PERMISSIONS)) ||
      (currentStatus === ContributionStatus.FinancialHold &&
        isUserInRole(COMPLETE_ABANDON_PERMISSIONS) &&
        isUserInRole(FINANCIAL_HOLD_COMPLETE_ABANDON_PERMISSIONS))
    )
      return 350;
    if (
      (currentStatus === ContributionStatus.Completed &&
        isUserInRole(FULFILL_REJECT_PERMISSIONS)) ||
      (currentStatus === ContributionStatus.FinancialHold &&
        isUserInRole(FINANCIAL_HOLD_COMPLETE_ABANDON_PERMISSIONS))
    )
      return 300;
    if (
      currentStatus === ContributionStatus.FinancialHold ||
      (isUserInRole(MARK_AS_PAID_PERMISSIONS) &&
        currentStatus === ContributionStatus.PendingPayment &&
        isUserInRole(COMPLETE_ABANDON_PERMISSIONS))
    )
      return 240;
    return 140;
  };

  const minWidth =
    isAvailableShare || isSelectedTabShare
      ? getActionsColumnWidth(selectedStatus) + 90
      : getActionsColumnWidth(selectedStatus);

  const actionsColumn: GridColDef = {
    field: 'action',
    headerName: 'Actions',
    type: GridColumns.Actions,
    disableReorder: false,
    resizable: false,
    minWidth,
    renderCell: ({ row: { id, status, sharedWith } }) => (
      <FlexBox sx={{ gap: 1 }}>
        {isUserInRole(MARK_AS_PAID_PERMISSIONS) && status === ContributionStatus.PendingPayment && (
          <RoleRequired roles={COMPLETE_ABANDON_PERMISSIONS}>
            <UpdateContributionStatusButton
              // sx={{ background: '#fff', color: '#0288d1', border: '1px solid #0288d1' }}sx={{
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.statusWarning,
                borderRadius: borders.borderRadius8,
                borderColor: colors.statusWarning,
                '&:focus': colors.statusWarning,
                '&:hover': colors.statusWarning,
              }}
              proposedStatus={ContributionActions.MarkAsPaid}
              action={ContributionStatusLabel.MarkAsPaid}
              id={id}
              isPositiveStatus
            />
          </RoleRequired>
        )}
        {isUserInRole(MARK_AS_PAID_PERMISSIONS) && status === ContributionStatus.ProcessingPayment && (
          <RoleRequired roles={COMPLETE_ABANDON_PERMISSIONS}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <HourglassTopIcon sx={{ color: '#f2b84d' }} />
              &nbsp;
              <Typography sx={{ fontSize: '14px', color: '#f2b84d' }}>
                Processing
                <br />
                Payment
              </Typography>
            </div>
          </RoleRequired>
        )}
        <Button
          variant="outlined"
          // color="info"
          // sx={{ backgroundColor: '#fff', fontSize:"14px",fontWeight:400 }}
          sx={{
            backgroundColor: colors.secondary,
            fontSize: fonts.fontSize14,
            fontWeight: fonts.fontWeight700,
            color: colors.primary,
            borderRadius: borders.borderRadius8,
            borderColor: colors.primary,
            '&:focus': colors.primary,
            '&:hover': colors.primary,
          }}
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            navigate(`/app/contribution/${id}/details`, {
              state: {
                selectedStatusTab: selectedStatus,
                previousPage: pathname,
              },
            });
          }}
        >
          View Details
        </Button>

        {status === ContributionStatus.Approved && !isSelectedTabShare && (
          <RoleRequired roles={COMPLETE_ABANDON_PERMISSIONS}>
            <UpdateContributionStatusButton
              disabled={!isUserInRole(ENABLE_COMPLETE_ABANDON)}
              proposedStatus={ContributionActions.PendingPayment}
              action={ContributionStatusLabel.Complete}
              id={id}
              isPositiveStatus
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.statusSuccess,
                borderRadius: borders.borderRadius8,
                borderColor: colors.statusSuccess,
                '&:focus': colors.statusSuccess,
                '&:hover': colors.statusSuccess,
              }}
            />
          </RoleRequired>
        )}
        {isAvailableShareByStatus(status as ContributionStatus) &&
          isAvailableShareByRole &&
          (!sharedWith?.id ? (
            <UpdateContributionStatusButton
              proposedStatus={ContributionActions.shared}
              action={ContributionStatusLabel.share}
              id={id}
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.statusWarning,
                borderRadius: borders.borderRadius8,
                borderColor: colors.statusWarning,
                '&:focus': colors.statusWarning,
                '&:hover': colors.statusWarning,
              }}
            />
          ) : (
            <Button
              variant="outlined"
              color="error"
              sx={{ backgroundColor: '#fff' }}
              onClick={() => unshareContribution(id)}
            >
              Unshare
            </Button>
          ))}

        {status === ContributionStatus.FinancialHold && !isSelectedTabShare && (
          <RoleRequired roles={FINANCIAL_HOLD_COMPLETE_ABANDON_PERMISSIONS}>
            <UpdateContributionStatusButton
              disabled={!isUserInRole(ENABLE_COMPLETE_ABANDON)}
              proposedStatus={ContributionActions.PendingPayment}
              action={ContributionStatusLabel.Complete}
              id={id}
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.statusSuccess,
                borderRadius: borders.borderRadius8,
                borderColor: colors.statusSuccess,
                '&:focus': colors.statusSuccess,
                '&:hover': colors.statusSuccess,
              }}
            />
          </RoleRequired>
        )}
        {[ContributionStatus.Approved, ContributionStatus.FinancialHold].includes(status) &&
          !isSelectedTabShare && (
            <RoleRequired roles={COMPLETE_ABANDON_PERMISSIONS}>
              <UpdateContributionStatusButton
                disabled={!isUserInRole(ENABLE_COMPLETE_ABANDON)}
                proposedStatus={ContributionActions.Abandoned}
                action={ContributionStatusLabel.Abandon}
                id={id}
                sx={{
                  backgroundColor: colors.secondary,
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fontWeight700,
                  color: colors.statusAlert,
                  borderRadius: borders.borderRadius8,
                  borderColor: colors.statusAlert,
                  '&:focus': colors.statusAlert,
                  '&:hover': colors.statusAlert,
                }}
              />
            </RoleRequired>
          )}
        {status === ContributionStatus.Completed && !isSelectedTabShare && (
          <RoleRequired roles={FULFILL_REJECT_PERMISSIONS}>
            <UpdateContributionStatusButton
              disabled={!isUserInRole(ENABLE_COMPLETE_ABANDON)}
              proposedStatus={ContributionActions.Fulfilled}
              action={ContributionStatusLabel.Fulfill}
              id={id}
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.statusSuccess,
                borderRadius: borders.borderRadius8,
                borderColor: colors.statusSuccess,
                '&:focus': colors.statusSuccess,
                '&:hover': colors.statusSuccess,
              }}
            />
          </RoleRequired>
        )}
        {status === ContributionStatus.Completed && !isSelectedTabShare && (
          <RoleRequired roles={FULFILL_REJECT_PERMISSIONS}>
            <UpdateContributionStatusButton
              disabled={!isUserInRole(ENABLE_COMPLETE_ABANDON)}
              proposedStatus={ContributionActions.Rejected}
              action={ContributionStatusLabel.Reject}
              id={id}
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: colors.statusAlert,
                borderRadius: borders.borderRadius8,
                borderColor: colors.statusAlert,
                '&:focus': colors.statusAlert,
                '&:hover': colors.statusAlert,
              }}
            />
          </RoleRequired>
        )}
      </FlexBox>
    ),
    sortable: false,
    filterable: false,
    hideable: false,
    align: 'left',
    headerAlign: 'left',
  };

  const handleMultipleContributions = (status: ContributionActions) => {
    if (!gridState?.selectionModel || gridState.selectionModel.length < 1) return;
    dispatch(
      setUpdateContributionStatusRequest(
        gridState.selectionModel.length === 1
          ? {
              id: gridState.selectionModel[0].toString(),
              proposedStatus: status,
              action: getContributionStatusLabel(status),
              showSuccessModal: false,
            }
          : {
              incentives: gridState.data.results.filter((x) =>
                gridState.selectionModel!.find((selected) => selected === x.id),
              ),
              proposedStatus: status,
              action: getContributionStatusLabel(status),
              showSuccessModal: false,
            },
      ),
    );
    dispatch(setDialog({ open: true, type: Dialogs.UpdateContributionStatus, maxWidth: 'md' }));
  };

  return (
    <Box className="dashboard" sx={{ pb: '20px' }}>
      <FlexBox sx={{ gap: 1 }}>
        <Typography flex={1} sx={{ flex: 1, color:colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 700 }}>
          Fulfillment
        </Typography>
        {isAvailableShare && (
          <RoundButton
            disabled={gridState?.selectionModel?.length === 0 || isDisableBulkShare()}
            onClick={() => handleMultipleContributions(ContributionActions.shared)}
          >
            Share Contribution
          </RoundButton>
        )}
        {canSelectContributions && (
          <RoundButton
            disabled={gridState?.selectionModel?.length === 0}
            onClick={() =>
              handleMultipleContributions(getUpdateMultipleContributionsProposedStatus())
            }
          >
            {getContributionStatusLabel(getUpdateMultipleContributionsStatus())} Contributions
          </RoundButton>
        )}
      </FlexBox>

      <CommonContributionsGrid
        tabs={tabs}
        defaultTab={ContributionStatus.PendingPayment}
        alternativeName="fulfillment"
        actionsColumn={actionsColumn}
        allowSelection={canSelectContributions}
        success={success}
      />
    </Box>
  );
}
