/* eslint-disable no-console */
import {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { CK_AUTH_TOKEN } from '../shared/constants';
import { downloadFile, GWCookies, verifyTokenExpiration } from '../shared/utils';

interface SchemaError {
  schema_errors?: Record<string, any>;
  message?: string;
}

const onRequest = (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig<any>> => {
  verifyTokenExpiration();
  const token = GWCookies.get(CK_AUTH_TOKEN);
  
  // Ensure headers object exists
  if (!config.headers) {
    config.headers = {} as AxiosRequestHeaders;
  }

  // Safely add the Authorization header

  if (config.headers) {
    const tokenString = token?.toString() ?? '';
    if (config.baseURL === window.CORE_HTTP_API_URL) {
      config.headers.Authorization = `Bearer ${tokenString}`;
    } else {
      config.headers.Authorization = tokenString;
    }
  }
  return Promise.resolve(config);
};

const onResponse = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
  const disposition = response.headers['content-disposition'];
  if (disposition && disposition.indexOf('attachment') >= 0) {
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
    if (matches && matches[1]) {
      const filename = matches[1].replace(/['"]/g, '');
      const blob = new Blob([response.data], { type: 'text/csv' });
      downloadFile(blob, filename);
    }
  }
  return response;
};

const onResponseError = (error: AxiosError<SchemaError>): Promise<AxiosError> => {
  console.error(error.response?.data);

  const schemaErrors = error.response?.data?.schema_errors;
  if (schemaErrors) {
    _.flatMapDeep(Object.values(schemaErrors), (n) => {
      if (typeof n === 'object') {
        return Object.values(n);
      }
      return n;
    });
  } else {
    toast.error(error.response?.data?.message ?? error.message, {
      autoClose: false,
    });
  }
  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance,
  injectToken?: boolean,
): AxiosInstance {
  if (injectToken) {
    axiosInstance.interceptors.request.use(onRequest);
  }
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
