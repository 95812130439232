import { getGridStringOperators, GridFilterOperator } from '@mui/x-data-grid-pro';

export const ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100];
export const DEFAULT_PAGE_SIZE = 10;
export const CONTAINS_FILTER_OPERATOR = getGridStringOperators().filter(
  (x) => x.value === 'contains',
);
export const BETWEEN_FILTER_OPERATOR: Array<GridFilterOperator> = [
  { value: 'between', getApplyFilterFn: () => null },
];
export const EQUALS_FILTER_OPERATOR = getGridStringOperators().filter((x) => x.value === 'equals');

export const STARTS_WITH_OPERATOR = getGridStringOperators().filter(
  (x) => x.value === 'startsWith',
);

export const COLUMN_WIDTHS = 'ColumnWidths';

export const COLUMN_ORDER = 'ColumnOrder';

export const COLUMN_VISIBILITY = 'ColumnVisibility';

export const FILTER_BY_TRANSACTION_ID = 'filterByTransactionId';

export const DEFAULT_DASHBOARD_VISIBILITY_MODEL = {
  id: false,
  customerName: true,
  customerId: false,
  quoteNumber: true,
  quoteAmount: true,
  grandTotal: true,
  causeArea: false,
  customField1: false,
  customField2: false,
  customField3: false,
  customField4: false,
  createdBy: false,
  status: true,
  createdAt: false,
  lastUpdatedBy: false,
  lastUpdated: true,
  actions: true,
};

export const DEFAULT_APPROVALS_VISIBILITY_MODEL = {
  id: false,
  customerName: true,
  customerId: false,
  quoteNumber: true,
  quoteAmount: true,
  grandTotal: true,
  causeArea: false,
  customField1: false,
  customField2: false,
  customField3: false,
  customField4: false,
  createdBy: false,
  status: false,
  createdAt: false,
  lastUpdatedBy: false,
  lastUpdated: true,
  actions: true,
};

export const DEFAULT_FULFILLMENT_VISIBILITY_MODEL = {
  id: false,
  customerName: true,
  customerId: true,
  quoteNumber: true,
  quoteAmount: true,
  grandTotal: true,
  causeArea: false,
  customField1: false,
  customField2: false,
  customField3: false,
  customField4: false,
  createdBy: false,
  status: false,
  createdAt: false,
  lastUpdatedBy: false,
  lastUpdated: false,
  actions: true,
};

export const DEFAULT_TRANSACTION_VISIBILITY_MODEL = {
  id: false,
  createdAt: true,
  giveId: true,
  invoiceId: true,
  memo: true,
  createdBy: true,
  amount: true,
};

export const DEFAULT_ACCOUNTS_VISIBILITY_MODEL = {
  industry: false,
};
export const DEFAULT_ERROR_LOGS_VISIBILITY_MODEL = {
  createdAt: true,
  description: true,
  source: true,
  accountId: false,
  accountName: true,
  orderId: false,
  orderName: true,
  transactionId: false,
  transactionName: true,
};

export const DEFAULT_INSTANCE_VISIBILITY_MODEL = {
  serviceFee: false,
  defaultSKUs: false,
};

export const DEFAULT_ACCOUNT_VISIBILITY_MODEL = {
  invitedBy: false,
  city: false,
  state: false,
};

export const DEFAULT_SWITCH_ACCOUNT_VISIBILITY_MODEL = {
  invitedBy: false,
};

export const DEFAULT_PROCUREMENT_VISIBILITY_MODEL = {
  customerName: false,
  customerId: false,
  status: false,
  createdBy: false,
};
