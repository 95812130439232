import { ContributionActions, ContributionStatus, UserRole } from '../enums';

export const CANCELED_CONTRIBUTIONS_SEARCH_PARAM = 'canceledIncentives';

export const ORDERS_NEGATIVE_ACTIONS = [
  ContributionStatus.Denied,
  ContributionStatus.Abandoned,
  ContributionStatus.Rejected,
  ContributionStatus.Deleted,
];

export const ORDERS_POSITIVE_ACTIONS = [
  ContributionActions.Approved,
  ContributionActions.Completed,
  ContributionActions.Fulfilled,
  ContributionActions.MarkAsPaid
];

// Permissions
export const GIVEWITH_ROLES = [
  UserRole.SuperAdmin,
  UserRole.GivewithCSM,
  UserRole.GivewithFinance,
  UserRole.GivewithOperations
];

export const GIVEWITH_ROLES_EDIT_TYPE = [UserRole.SuperAdmin, UserRole.GivewithOperations];

export const COMPLETE_ABANDON_PERMISSIONS = [...GIVEWITH_ROLES, UserRole.Transactor];

export const FINANCIAL_HOLD_COMPLETE_ABANDON_PERMISSIONS = [...GIVEWITH_ROLES, UserRole.Finance];

export const COMPLETE_FINANCIAL_HOLD_CONTRIBUTIONS = [UserRole.SuperAdmin, UserRole.Finance];

export const FULFILL_REJECT_PERMISSIONS = [...GIVEWITH_ROLES];

export const NEW_CONTRIBUTION_PERMISSIONS = [...GIVEWITH_ROLES, UserRole.Sales];

export const MARK_AS_PAID_PERMISSIONS = [UserRole.SuperAdmin]

export const UPDATE_DELETE_CONTRIBUTIONS_PERMISSIONS = [
  ...NEW_CONTRIBUTION_PERMISSIONS,
  UserRole.OrgAdmin,
];

export const APPROVE_DENY_PERMISSIONS = [UserRole.Approver, ...GIVEWITH_ROLES];

export const VIEW_CONTRIBUTIONS_PERMISSION = [
  ...GIVEWITH_ROLES,
  UserRole.OrgAdmin,
  UserRole.Sales,
  UserRole.Approver,
  UserRole.Finance,
  UserRole.Transactor,
  UserRole.Executive,
  UserRole.UserAdmin,
];

export const RESEND_NOTIFICATIONS_PERMISSION = [
  ...GIVEWITH_ROLES,
  UserRole.OrgAdmin,
  UserRole.Sales,
  UserRole.Finance,
];

export const ENABLE_APPROVE_DENY = [
  UserRole.SuperAdmin,
  UserRole.Sales,
  UserRole.OrgAdmin,
  UserRole.Approver,
  UserRole.Transactor,
];

export const ENABLE_LOCK = [
  UserRole.SuperAdmin,
  UserRole.OrgAdmin,
  UserRole.GivewithFinance,
  UserRole.GivewithOperations,
];

export const ENABLE_COMPLETE_ABANDON = [
  UserRole.SuperAdmin,
  UserRole.Sales,
  UserRole.OrgAdmin,
  UserRole.Approver,
  UserRole.Transactor,
];

export const ENABLE_SAVE_PROPOSAL = [
  UserRole.SuperAdmin,
  UserRole.Sales,
  UserRole.OrgAdmin,
  UserRole.Approver,
  UserRole.Transactor,
];
export const ENABLE_RESET_PASSWORD = [
  UserRole.OrgAdmin,
  UserRole.UserAdmin,
  UserRole.SuperAdmin,
  UserRole.GivewithCSM,
  UserRole.GivewithOperations,
  UserRole.GivewithCSM,
  UserRole.GivewithFinance,
];

export const ENABLE_DELETE = [UserRole.OrgAdmin, UserRole.SuperAdmin];

export const ENABLE_SAVE_SETTINGS = [UserRole.SuperAdmin, UserRole.OrgAdmin];
export const ENABLE_GENERAL_ACCOUNT_SAVE = [
  UserRole.SuperAdmin,
  UserRole.Finance,
  UserRole.OrgAdmin,
  UserRole.GivewithFinance,
  UserRole.GivewithOperations,
];

export const ENABLE_USER_INVITE = [
  UserRole.SuperAdmin,
  UserRole.UserAdmin,
  UserRole.OrgAdmin,
  UserRole.GivewithFinance,
  UserRole.GivewithOperations,
];

export const ENABLE_USER_SAVE = [
  UserRole.SuperAdmin,
  UserRole.UserAdmin,
  UserRole.OrgAdmin,
  UserRole.GivewithOperations,
];
