import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addCancelTokenEvent,
  deleteSupplierInvite,
  getSuppliers,
  patchSupplier,
  postResendInvite,
} from '../../api';

import { DEFAULT_PAGE_SIZE } from '../../shared/constants';
import { AccountStatus } from '../../shared/enums';
import { GridModel, GridRequest, GridResponse, Supplier } from '../../shared/interfaces';

interface SuppliersGridRequest extends GridRequest {
  status: string | null;
  accountId: string;
}

interface GridInterface extends Supplier {
  id?: string;
}

interface SupplierState {
  grid: GridModel<GridInterface>;
  selectedTab: Number;
  successResendInvite: boolean;
  successDeleteInvite: boolean;
  status: string;
  successPatch: boolean;
  actionPatch?: AccountStatus;
}

const initialState: SupplierState = {
  grid: {
    name: 'suppliers',
    data: {
      totalCount: 0,
      results: [],
    },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'lastUpdated', direction: 'desc' },
  },
  successResendInvite: false,
  successDeleteInvite: false,
  successPatch: false,
  selectedTab: 0,
  status: AccountStatus.Active,
};

export const getSuppliersThunk = createAsyncThunk<GridResponse<Supplier>, SuppliersGridRequest>(
  'suppliers/getSuppliersThunk',
  async (request: SuppliersGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getSuppliers(request);
    return data;
  },
);

export const postResendInviteSupplierThunk = createAsyncThunk<
  Supplier,
  { id: string; accountId: string; username: string }
>('suppliers/postResendInvite', async (params, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await postResendInvite(params);
  return data;
});

export const deleteInviteSupplierThunk = createAsyncThunk<
  Supplier,
  { id: string; accountId: string }
>('suppliers/deleteInviteSupplier', async (params, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await deleteSupplierInvite(params);
  return data;
});

export const patchSupplierThunk = createAsyncThunk<
  Supplier,
  { id: string; payload: { status: AccountStatus; accountId: string } }
>('suppliers/patchSupplier', async (params, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await patchSupplier(params.payload, params.id);
  return data;
});

const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    clearSuppliersState: (state) => {
      _.assign(state, initialState);
    },
    setSupplierGridModel: (state, action: PayloadAction<Partial<GridModel<Supplier>>>) => {
      _.assign(state.grid, action.payload);
    },
    setSupplierStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
    setSupplierActionPatch: (state, action: PayloadAction<AccountStatus>) => {
      state.actionPatch = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(
        getSuppliersThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<Supplier>>) => {
          state.grid.loadingGrid = false;
          const filterUser = action.payload.results;
          const generateId = filterUser.map((item) => ({
            id: `${item.user?.username ?? ''}-${item.account?.id ?? ''}`,
            ...item,
          }));
          state.grid.data = { totalCount: action.payload.totalCount, results: generateId };
        },
      )
      .addCase(getSuppliersThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getSuppliersThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(postResendInviteSupplierThunk.fulfilled, (state) => {
        state.successResendInvite = true;
      })
      .addCase(postResendInviteSupplierThunk.rejected, (state) => {
        state.successResendInvite = false;
      })
      .addCase(postResendInviteSupplierThunk.pending, (state) => {
        if (state.successResendInvite) state.successResendInvite = false;
      })
      .addCase(deleteInviteSupplierThunk.fulfilled, (state) => {
        state.successDeleteInvite = true;
      })
      .addCase(deleteInviteSupplierThunk.rejected, (state) => {
        state.successDeleteInvite = false;
      })
      .addCase(deleteInviteSupplierThunk.pending, (state) => {
        if (state.successDeleteInvite) state.successDeleteInvite = false;
      })
      .addCase(patchSupplierThunk.fulfilled, (state) => {
        state.successPatch = true;
      })
      .addCase(patchSupplierThunk.rejected, (state) => {
        state.successPatch = false;
      })
      .addCase(patchSupplierThunk.pending, (state) => {
        if (state.successPatch) state.successPatch = false;
      }),
});

export const {
  clearSuppliersState,
  setSupplierGridModel,
  setSupplierStatus,
  setSupplierActionPatch,
} = supplierSlice.actions;

export const suppliersReducer = supplierSlice.reducer;
