import React from 'react';
import { Box, Typography } from '@mui/material';
import { FlexBox, FormDialog } from '../../../shared/components';
import { Dialogs } from '../../../shared/enums';
import { useAppSelector } from '../../../shared/hooks';
import { ProcurementMetricsResponseObject } from '../../../shared/interfaces';


export const ProcurementMetricsProgramDetails = () => {
  const stateProcurement = useAppSelector((x) => x.procurementMetrics);
  const { responseValues, programNameforMetrics } = { ...stateProcurement };
  const program: ProcurementMetricsResponseObject[] = []
  responseValues.causeArea?.results.forEach((item) => {
    if (item.key?.program === programNameforMetrics) {
       program.push(item);
       
    }
  });

  const  { impact, outputs } = program[0]

  return (
    <FormDialog
      title={`Estimated Impact Count (${impact})`}
      type={Dialogs.ProcurementMetricsProgramDetails}
    >
      <Box sx={{padding: '30px', gap: '40px', width: '850px', height: '500px'}}>
        {outputs?.map((item) => (
          <Box sx={{ padding: '10px'}}>
          <FlexBox sx={{ height: '29px', width: '100%' ,alignItems: 'center', padding: '20px', border: "1px solid #C4C4C4", borderRadius: '10px', gap: '20px', flexDirection: 'row'}}>
            <Typography sx={{ width: '663px', height: '22px', fontSize: '20px',alignItems: 'center', color: ' #000000' }} >{`${item.description ?? 0}`}</Typography>
            <Typography sx={{ width: '49px', height: '30px',fontSize: '20px', fontWeight: 'bold', alignItems: 'center', color: '#5C6ED4' }}>{`${item.value ?? 0}`}</Typography>

          </FlexBox>
          </Box>


        ))}

      </Box>
    </FormDialog>
  );
}