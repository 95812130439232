import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addCancelTokenEvent, getSupplier } from '../../../api';
import { Account, InviteSupplierRequest, User } from '../../../shared/interfaces';

export interface ViewSupplierState {
  supplierId: string;
  supplier: InviteSupplierRequest;
  loading: boolean;
  close: boolean;
}

interface supplierResponse {
  account: Partial<Account>;
  user: Partial<User>;
}

const initialState: ViewSupplierState = {
  supplierId: '',
  supplier: {
    account: {},
    user: {},
  },
  close: false,
  loading: false,
};

export const getSupplierThunk = createAsyncThunk<
  supplierResponse,
  { id: string; accountId: string }
>('suppliers/getSupplierThunk', async (params, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getSupplier(params.id, params.accountId);
  return data;
});

const viewSupplierSlice = createSlice({
  name: 'view supplier',
  initialState,
  reducers: {
    setViewSupplierSupplierId: (state, action: PayloadAction<string>) => {
      state.supplierId = action.payload;
    },
    clearViewSupplierState: (state) => {
      _.assign(state, initialState);
    },
    setViewSupplierCloseDialog: (state, action: PayloadAction<boolean>) => {
      state.close = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getSupplierThunk.fulfilled, (state, action: PayloadAction<supplierResponse>) => {
        state.loading = false;
        state.supplier = action.payload;
      })
      .addCase(getSupplierThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSupplierThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      }),
});

export const { setViewSupplierSupplierId, clearViewSupplierState, setViewSupplierCloseDialog } =
  viewSupplierSlice.actions;

export const viewSupplierReducer = viewSupplierSlice.reducer;
