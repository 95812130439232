import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  // Tooltip,
  Typography,
  // Popover,
} from '@mui/material';
import { Link } from 'react-router-dom';
import React, { FC, useState, useEffect } from 'react';
import { RoleRequired } from '../../../app/Security';
import { Dialogs, UserRole } from '../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
// import { formatString, getUsernameFromEmail } from '../../../shared/utils';
import { setDialog } from '../mainScreenSlice';
import { GIVEWITH_ROLES } from '../../../shared/constants';
import { AccountInstance } from '../../../shared/interfaces';
import { setInstanceSettingsInstanceId } from '../../instances';
import MyProfile from '../../../assets/svg/MyProfile.svg';
import AccountSettings from '../../../assets/svg/AccuntSettings.svg';
import InstanceSettings from '../../../assets/svg/InstanceSettings.svg';
import InviteUser from '../../../assets/svg/InviteUser.svg';
import LogoutIcon from '../../../assets/svg/LogoutBtn.svg';
import colors from '../../../assets/scss/color.module.scss';
import { setCookie } from '../../../shared/utils/findActiveTenantId';
// import { getAccount } from '../../../api';

interface Props {
  // firstName?: string;
  // lastName?: string;
  // email?: string;
  instance?: AccountInstance;
}

export const HeaderDropDownMenu: FC<Props> = (props) => {
  const userClaims = useAppSelector((state) => state.app.userClaims);
  const myOrganizations = userClaims?.tenantAccessGrants.filter(
    (tenant: any) => tenant?.subType !== 'seller',
  );
  const customerOrganizations = userClaims?.tenantAccessGrants.filter(
    (tenant: any) => tenant?.subType === 'seller',
  );
  const dispatch = useAppDispatch();
  const { children, instance } = props; // Removed firstName, lastName, email as per new figma design

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [domainValue, setDomainValue] = useState('');

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const removeCookie = (name: string) => {
    const cookieDomain = '.scalewith.com';
    const pastExpirationDate = new Date(0).toUTCString();
    document.cookie = `${name}=;expires=${pastExpirationDate};domain=${cookieDomain};path=/`;
  };

  const handleClose = () => {
    if (window.location.origin.includes('dev')) {
      removeCookie('dev_tenantId');
    } else if (window.location.origin.includes('qa')) {
      removeCookie('qa_tenantId');
    } else {
      removeCookie('tenantId');
    }
    setAnchorEl(null);
    // window.location.href =
    //   'https://dev-scalewith.us.auth0.com/v2/logout?returnTo=https://auth.dev.scalewith.com/&client_id=8t3n4o6rhTwSAhKKa4bYQGTalvrlxzZu';
  };

  const handleOpenDialog = (
    type: Dialogs,
    maxWidth?: false | 'md' | 'sm' | 'xs' | 'lg' | 'xl' | undefined,
  ) => {
    handleClose();
    dispatch(setDialog({ open: true, type, maxWidth: maxWidth ?? 'md' }));
  };

  const setAccountData = (accountId: string, app: string) => {
    const domain = app === 'Buywith' ? 'buywith.dev' : 'sellwith.dev';
    setDomainValue(domain);
    if (window.location.origin.includes('dev')) {
      setCookie('dev_tenantId', accountId!);
    } else if (window.location.origin.includes('qa')) {
      setCookie('qa_tenantId', accountId!);
    } else {
      setCookie('tenantId', accountId!);
    }
  };

  useEffect(() => {
    if (domainValue !== '') {
      window.location.href = `https://${domainValue}.scalewith.com`;
    }
  }, [domainValue]);

  const isSuperAdmin = myOrganizations[0]?.roles?.includes(UserRole.SuperAdmin);

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ borderRadius: 0 }}
      >
        {children}
        <ExpandMore sx={{ paddingLeft: 1 }} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 500,
            width: 'auto',
            padding: '0px 10px',
            borderRadius: '20px',
            backgroundColor: colors.backgroundPrimary,
          },
        }}
      >
        <div style={{ backgroundColor: colors.secondary, borderRadius: '20px' }}>
          {/* <MenuItem sx={{ pointerEvents: 'none' }}>
            <Typography variant="h6" noWrap>
              {formatString(firstName, '') + formatString(lastName)}
            </Typography>
          </MenuItem>
          <MenuItem>
            <Typography variant="caption" noWrap>
              {email}
            </Typography>
          </MenuItem>
          <Divider sx={{ my: 0.5 }} /> */}
          <MenuItem
            onClick={() => {
              handleOpenDialog(Dialogs.MyProfile);
            }}
          >
            <img
              width="16px"
              height="16px"
              src={MyProfile}
              alt="Instance Settings"
              style={{ marginRight: '10px' }}
            />
            My Profile
          </MenuItem>
          <RoleRequired roles={[UserRole.SuperAdmin]}>
            <MenuItem
              onClick={() => {
                if (!instance?.id) return;
                dispatch(setInstanceSettingsInstanceId(instance?.id));
                handleOpenDialog(Dialogs.InstanceSettings);
              }}
            >
              <img
                width="16px"
                height="16px"
                src={InstanceSettings}
                alt="Instance Settings"
                style={{ marginRight: '10px' }}
              />
              Instance Settings
            </MenuItem>
          </RoleRequired>
          <MenuItem
            onClick={() => {
              handleOpenDialog(Dialogs.AccountSettings);
            }}
          >
            <img
              width="16px"
              height="16px"
              src={AccountSettings}
              alt="Instance Settings"
              style={{ marginRight: '10px' }}
            />
            Account Settings
          </MenuItem>
          <RoleRequired roles={GIVEWITH_ROLES}>
            <MenuItem
              onClick={() => {
                handleOpenDialog(Dialogs.SwitchAccount, 'lg');
              }}
            >
              Switch Account
            </MenuItem>
          </RoleRequired>
          <RoleRequired roles={[...GIVEWITH_ROLES, UserRole.OrgAdmin, UserRole.UserAdmin]}>
            <MenuItem
              onClick={() => {
                handleOpenDialog(Dialogs.InviteUser, 'md');
              }}
            >
              <img
                width="16px"
                height="16px"
                src={InviteUser}
                alt="Instance Settings"
                style={{ marginRight: '10px' }}
              />
              Invite User
            </MenuItem>
          </RoleRequired>
          {!isSuperAdmin && (
            <>
              <Divider sx={{ my: 0.5 }} />
              <Accordion elevation={0}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    My Organizations
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {myOrganizations?.map((tenant: any) => (
                    <Typography
                      sx={{ padding: '5px 10px 5px 20px', mb: '10px', cursor: 'pointer' }}
                      onClick={() => setAccountData(tenant?.id, tenant?.app)}
                    >
                      {tenant?.friendlyName}
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
              {customerOrganizations.length > 0 && (
                <Accordion elevation={0}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Customer Organizations
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {customerOrganizations?.map((tenant: any) => (
                      <Typography>{tenant?.friendlyName}</Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
              <Divider sx={{ my: 0.5 }} />
            </>
          )}
          <MenuItem onClick={handleClose} component={Link} to="/logout">
            <img
              width="16px"
              height="16px"
              src={LogoutIcon}
              alt="Instance Settings"
              style={{ marginRight: '10px' }}
            />
            Logout
          </MenuItem>
        </div>
      </Menu>
    </>
  );
};
