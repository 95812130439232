import React, { FC } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { FlexBox, NumberDecimalInput, NumberInput } from '../../../shared/components';
import { InstanceFormConfig } from '../../../shared/interfaces';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { setInstanceSettingsData } from './instanceSettingsSlice';
import { InstanceSettingsType } from '../../../shared/enums';
import { InstanceSettingsStripeAccountForm, InstanceSettingsStripeSupplierForm } from './stripe';

interface Props {
  value: InstanceFormConfig;
}

export const InstanceSettingsStripe: FC<Props> = (props) => {
  const currentLocale = useAppSelector((x) => x.instanceSettings.selectedLocale);
  const instanceType = useAppSelector((x) => x.instanceSettings.values.type);
  const { value } = props;
  const {
    stripe: { trialPeriodDays, currencyFactor },
  } = value;
  const dispatch = useAppDispatch();

  return (
    <FlexBox sx={{ flexDirection: 'column', maxHeight: 70 }}>
      <FlexBox sx={{ gap: 3 }}>
        <TextField
          label={trialPeriodDays.label}
          value={trialPeriodDays.value}
          required={trialPeriodDays.required}
          InputProps={{
            inputComponent: NumberInput as any,
          }}
          sx={{ flex: 1 }}
          onChange={(event) => {
            dispatch(
              setInstanceSettingsData({
                settings: { stripe: { trialPeriodDays: Number(event.target.value) } },
              }),
            );
          }}
        />
        <TextField
          label={currencyFactor.label}
          value={currencyFactor.value}
          required={currencyFactor.required}
          sx={{ flex: 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {currentLocale?.settings.currency && `(${currentLocale?.settings?.currency})`}
              </InputAdornment>
            ),
            inputComponent: NumberDecimalInput as any,
          }}
          onChange={(event) => {
            dispatch(
              setInstanceSettingsData({
                settings: { stripe: { currencyFactor: Number(event.target.value) } },
              }),
            );
          }}
        />
      </FlexBox>
      <InstanceSettingsStripeAccountForm value={value} />

      {instanceType === InstanceSettingsType.procurement && (
        <InstanceSettingsStripeSupplierForm value={value} />
      )}
    </FlexBox>
  );
};
