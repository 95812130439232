/* eslint-disable jsx-a11y/anchor-is-valid */
import { Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { FlexBox, ThankYouTypography } from '../../shared/components';
import { Dialogs } from '../../shared/enums';
import { useAppDispatch } from '../../shared/hooks';
import { setDialog } from '../main-screen';

export const RegisterStripeCancel = () => {
  const dispatch = useAppDispatch();

  return (
    <FlexBox
      sx={{
        marginTop: 4,
        flexDirection: 'column',
        flex: 1,
        color: 'black',
        gap: 2,
      }}
    >
      <ThankYouTypography variant="h3" sx={{ marginBottom: 4 }}>
        Payment Canceled
      </ThankYouTypography>

      <Typography>
        You can try submitting your payment information again &nbsp;
        <Link component={RouterLink} to="/register-details/finalize">
          here
        </Link>
        .
      </Typography>
      <Typography>
        You can always &nbsp;
        <Link
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            dispatch(setDialog({ open: true, type: Dialogs.ContactUs, maxWidth: 'md' }))
          }
        >
          Contact Us
        </Link>
      </Typography>
    </FlexBox>
  );
};
