import { Avatar } from '@mui/material';
import React from 'react';
import { isNullOrWhitespace } from '../utils';

const getUserInitials = (firstName?: string, lastName?: string) => {
  if (!isNullOrWhitespace(firstName)) {
    if (!isNullOrWhitespace(lastName)) return firstName!.trim()[0] + lastName!.trim()[0];
    return firstName!.trim()[0];
  }
  if (!isNullOrWhitespace(lastName)) return lastName!.trim()[0];
  return '';
};

export function UserAvatar({
  firstName,
  lastName,
  size = 'md',
}: {
  firstName?: string;
  lastName?: string;
  size?: 'md' | 'lg';
}) {
  const avatarSize = size === 'lg' ? 56 : 40;
  const avatarFontSize = size === 'lg' ? '1.5rem' : '22px';
  return (
    <Avatar
      sx={(theme) => ({
        height: avatarSize,
        width: avatarSize,
        fontSize: avatarFontSize,
        fontWeight:600,
        fontFamily: 'Open Sans,sans-serif !important',
        backgroundColor: theme.palette.primary.light,
      })}
    >
      {getUserInitials(firstName, lastName)}
    </Avatar>
  );
}
