import React, { FC } from 'react';
import { RecordDetail } from '../interfaces';
import { getDateString, getUsernameFromEmail } from '../utils';
import { DisabledTextField } from './DisabledTextField';
import { CommonPaper, FlexBox } from './StyledComponents';

interface Props {
  value: RecordDetail;
  additionalValues?: Array<{ label: string; value?: string; tooltip?: string }> | JSX.Element;
}

export const RecordDetails: FC<Props> = (props) => {
  const { value, additionalValues } = props;
  const { createdBy, createdAt, lastUpdatedBy, lastUpdated } = value;

  return (
    <CommonPaper>
      <FlexBox sx={{ flexDirection: 'column', gap: 1 }}>
        {additionalValues &&
          (Array.isArray(additionalValues)
            ? additionalValues
                .filter((x) => x.value)
                .map((x) => (
                  <DisabledTextField
                    key={`record-details-${x.label}-${x.value}`}
                    label={x.label}
                    value={x.value}
                    tooltipTitle={x.tooltip}
                  />
                ))
            : additionalValues)}
        {createdBy && (
          <DisabledTextField
            label="Created By"
            value={getUsernameFromEmail(createdBy)}
            tooltipTitle={createdBy}
          />
        )}
        {createdAt && (
          <DisabledTextField
            label="Created At"
            value={getDateString(createdAt, true)}
            tooltipTitle={getDateString(createdAt)}
          />
        )}
        {lastUpdatedBy && (
          <DisabledTextField
            label="Last Updated By"
            value={getUsernameFromEmail(lastUpdatedBy)}
            tooltipTitle={lastUpdatedBy}
          />
        )}
        {lastUpdated && (
          <DisabledTextField
            label="Last Updated At"
            value={getDateString(lastUpdated, true)}
            tooltipTitle={getDateString(lastUpdated)}
          />
        )}
      </FlexBox>
    </CommonPaper>
  );
};
