import React, { useState } from 'react';
import _ from 'lodash';
import { Grid, Box, Button, Typography } from '@mui/material';
import { FlexBox, PaddingRoundedBox } from '../../../shared/components';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  CauseAreaEntry,
  CauseAreaCard,
  setInstanceSettingsCauseAreaEntryMode,
  setInstanceSettingsCauseAreaIsNew,
} from './cause-areas';
import { setAccountSettingsCauseArea } from './accountSettingsSlice';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface props {
  hideSave: (isHide: boolean) => void;
}

export const InstanceSettingsAccounts: React.FC<props> = ({hideSave}) => {
  const [dragId, setDragId] = useState();
  const dispatch = useAppDispatch();
  const state = useAppSelector((x) => x.instanceSettingsCauseArea);
  const stateCauseArea = useAppSelector((x) => x.accountSettings);

  const {
    general: {
      account: { causeAreas },
    },
  } = stateCauseArea;
  const { entryMode } = state;

  const onDeleteCauseArea = (idLocale: string) => {
    if (!causeAreas) return;
    const indexLocale = causeAreas.findIndex((obj) => obj?.causeArea?.id === idLocale);
    const instanceWithNewOrder = causeAreas.map((item, index) => {
      if (index > indexLocale) {
        const newItem = { ...item };
        if (!newItem.displayOrder) return item;
        newItem.displayOrder -= 1;
        return newItem;
      }
      return item;
    });
    const standardPrograms = [...instanceWithNewOrder];
    const deletedObj = {
      ...standardPrograms[indexLocale],
      active: false,
    };
    standardPrograms[indexLocale] = deletedObj;
    const newOrder = standardPrograms;
    dispatch(setAccountSettingsCauseArea(newOrder));
  };
  const newCauseArea = () => {
    hideSave(false);
    dispatch(setInstanceSettingsCauseAreaIsNew(true));
    dispatch(setInstanceSettingsCauseAreaEntryMode(true));
  };

  const handleDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev: any) => {
    if (causeAreas?.length === undefined) return;
    const dragBox = causeAreas.find((item) => item?.causeArea?.id === dragId);
    const dropBox = causeAreas.find((item) => item?.causeArea?.id === ev.currentTarget.id);

    const dragBoxOrder = dragBox?.displayOrder;
    const dropBoxOrder = dropBox?.displayOrder;
    const newOrderCauseArea = causeAreas.map((item) => {
      const obj = { ...item };
      if (obj?.causeArea?.id === dragId) {
        obj.displayOrder = dropBoxOrder;
      }
      if (obj?.causeArea?.id === ev.currentTarget.id) {
        obj.displayOrder = dragBoxOrder;
      }
      return obj;
    });
    const newOrder = _.orderBy(newOrderCauseArea, ['displayOrder'], ['asc']);
    dispatch(setAccountSettingsCauseArea(newOrder));
  };

  const causeAreaList = (
    <PaddingRoundedBox
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        backgroundColor: theme.palette.common.grey.main,
      })}
    >
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={11}>
          <Typography flex={1} sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }}>
            Standard Programs
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          <Box>
            <Button
              variant="contained"
              size="large"
              sx={{ paddingBottom: '8px', fontSize: fonts.fontSize14, fontWeight: fonts.fontWeight900 , backgroundColor: colors.primary }}
              onClick={newCauseArea}
            >
              Add
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={9}>
          <Box
            sx={() => ({
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            })}
          >
            {causeAreas &&
              causeAreas
                .filter((obj) => obj.active)
                .map((item) => (
                  <CauseAreaCard
                    data={item}
                    onDeleteCauseArea={onDeleteCauseArea}
                    causeAreaLength={causeAreas.length}
                    key={item.causeArea?.id}
                    handleDrag={handleDrag}
                    handleDrop={handleDrop}
                  />
                ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <FlexBox>
            <Box sx={{ color: colors.textPrimary }}>Hint: </Box>
            <Box sx={{ color: colors.strokeDefault, paddingLeft: '4px' }}>
              Drag & Drop to change the order of the standard programs
            </Box>
          </FlexBox>
        </Grid>
      </Grid>
    </PaddingRoundedBox>
  );

  return (
    <FlexBox sx={{ flexDirection: 'column', maxHeight: 75 }}>
      {!entryMode && causeAreaList}
      {entryMode && <CauseAreaEntry hideSave={() => hideSave(true)} />}
    </FlexBox>
  );
};
