import { Box, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlexBox, Loading, SubscriptionPaper, ThankYouTypography } from '../../shared/components';
import { ACCOUNT_SUBSCRIPTION_MOST_POPULAR } from '../../shared/constants';
import { AccountStatus, AccountSubscription, RegisterStep, UserRole } from '../../shared/enums';
import { useAppDispatch, useAppSelector, useInstance } from '../../shared/hooks';
import { RoleRequired, AccountStatusRequired } from '../../app/Security';
import {
  setRegisterDetailsSubscriptionChoice,
  setRegisterStepperStep,
} from './registerDetailsSlice';
import { Subscription } from '../../shared/interfaces';
import { platform } from '../../shared/utils';

export const RegisterSubscription = () => {
  const subscriptionChoice = useAppSelector((x) => x.registerDetails.subscriptionChoice);
  // const stripe = useAppSelector((x) => x.app.account?.stripe);
  const { loadingUserData, instance: currentInstanceData, locale } = useInstance();

  const { settings: settingsInstanceData } = { ...currentInstanceData };
  const { stripe: stripeInstanceData } = { ...settingsInstanceData };
  const { trialPeriodDays: trialPeriodDaysInstanceData, prices: pricesInstanceData } = {
    ...stripeInstanceData,
  };
  const { annual: annualInstanceData, monthly: monthlyInstanceData } = { ...pricesInstanceData };
  const { amount: amountAnnualInstanceData, footer: footerAnnualInstanceData, enabled: annualEnabled } = {
    ...annualInstanceData,
  };
  const { amount: amountMonthlyInstanceData, footer: footerMonthlyInstanceData, enabled: monthlyEnabled } = {
    ...monthlyInstanceData,
  };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const ACCOUNT_SUBSCRIPTION_INFORMATION: Record<string, Subscription> = {
    [AccountSubscription.Annual]: {
      id: AccountSubscription.Annual,
      title: 'Annual',
      text: `One annual payment to maintain access to your ${
        platform()[0].toUpperCase() + platform().slice(1)
      }With account.`,
      price: amountAnnualInstanceData || 4999,
      period: 'year',
      bullets: ['Cancellation anytime'],
      savingFooter: footerAnnualInstanceData!,
      trialPeriodDays: trialPeriodDaysInstanceData!,
      currencySymbol: locale?.settings.symbol,
      enabled: annualEnabled,
    },
    [AccountSubscription.Monthly]: {
      id: AccountSubscription.Monthly,
      title: 'Monthly',
      text: `Regular monthly payments to maintain access to your ${
        platform()[0].toUpperCase() + platform().slice(1)
      }With account.`,
      price: amountMonthlyInstanceData || 499,
      period: 'month',
      bullets: ['Cancellation anytime'],
      savingFooter: footerMonthlyInstanceData!,
      trialPeriodDays: trialPeriodDaysInstanceData!,
      currencySymbol: locale?.settings.symbol,
      enabled: monthlyEnabled,
    },
  };

  useEffect(() => {
    dispatch(setRegisterStepperStep(RegisterStep.SubscriptionSelection));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (loadingUserData) return <Loading loading={loadingUserData} />;

  return (
    <FlexBox
      sx={{
        marginTop: 4,
        flexDirection: 'column',
        flex: 1,
        color: 'black',
        gap: 2,
      }}
    >
      <Box>
        <ThankYouTypography variant="h4" sx={{ marginBottom: 2 }}>
          Subscription
        </ThankYouTypography>
        <ThankYouTypography>Please select a subscription plan.</ThankYouTypography>
      </Box>
      <FlexBox sx={{ gap: 4 }}>
        {!loadingUserData &&
          Object.values(ACCOUNT_SUBSCRIPTION_INFORMATION).filter((y) => y.enabled === true).map((x) => (
              <SubscriptionPaper
                  key={`REGISTER-ACCOUNT-SUBSCRIPTION-CHOICE-${x.id}`}
                  // subscription={x}
                  isSelected={subscriptionChoice === x.id}
                  mostPopular={x.id === ACCOUNT_SUBSCRIPTION_MOST_POPULAR}
                  // isRegister
                  // hideFreeTrial={!!stripe}
                  Buttons={
                    <Button
                      variant={subscriptionChoice === x.id ? 'contained' : 'outlined'}
                      sx={{ height: 'fit-content' }}
                      onClick={() => dispatch(setRegisterDetailsSubscriptionChoice(x.id))}
                    >
                      {subscriptionChoice === x.id ? 'Selected' : 'Select'}
                    </Button>
                  }
              />
          ))}
      </FlexBox>
      <FlexBox sx={{ flexDirection: 'row-reverse', marginTop: 2, gap: 2 }}>
        <Button
          variant="contained"
          size="large"
          disabled={!subscriptionChoice || loadingUserData}
          sx={{ padding: 2, width: 200 }}
          onClick={() => navigate('/register-details/finalize')}
        >
          Next
        </Button>
        <RoleRequired roles={[UserRole.OrgAdmin]}>
          <AccountStatusRequired statuses={[AccountStatus.Pending, AccountStatus.PendingStripe]}>
            <Button
              variant="outlined"
              size="large"
              disabled={loadingUserData}
              sx={{ padding: 2, width: 200 }}
              onClick={() => navigate('/register-details/financial-choice')}
            >
              Back
            </Button>
          </AccountStatusRequired>
        </RoleRequired>
      </FlexBox>
    </FlexBox>
  );
};
