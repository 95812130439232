import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addCancelTokenEvent, getErrorLogs, patchErrorLog } from '../../api';
import { DEFAULT_PAGE_SIZE, ERROR_LOG_SUCCESS_MESSAGE } from '../../shared/constants';
import { ErrorLog, GridModel, GridRequest, GridResponse } from '../../shared/interfaces';
import { patchViewErrorLogThunk } from './viewErrorLogSlice';

interface errorLogsRequest extends GridRequest {
  isResolved: boolean;
}

export interface ErrorLogsState {
  grid: GridModel<ErrorLog>;
  unresolved: boolean;
  updatedMultiple: { failed: number; succeeded: number };
  successMessage?: string;
}

const initialState: ErrorLogsState = {
  grid: {
    name: 'errorLogs',
    data: { totalCount: 0, results: [] },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'createdAt', direction: 'desc' },
    selectionModel: [],
  },
  successMessage: undefined,
  updatedMultiple: { failed: 0, succeeded: 0 },
  unresolved: true,
};

export const getErrorLogsThunk = createAsyncThunk<GridResponse<ErrorLog>, errorLogsRequest>(
  'errorLogs/getErrorLogsThunk',
  async (request: errorLogsRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getErrorLogs(request);
    return data;
  },
);

export const patchMultipleErrorLogThunk = createAsyncThunk<
  ErrorLog,
  { id: string; value: Omit<ErrorLog, 'id'> }
>('errorLogs/patchMultipleErrorLogThunk', async (payload, { signal }) => {
  const { id, value } = payload;
  addCancelTokenEvent(signal);
  const { data } = await patchErrorLog(id, value);
  return data;
});

export const patchErrorLogThunk = createAsyncThunk<
  ErrorLog,
  { id: string; value: Omit<ErrorLog, 'id'> }
>('errorLogs/patchErrorLogThunk', async (payload, { signal }) => {
  const { id, value } = payload;
  addCancelTokenEvent(signal);
  const { data } = await patchErrorLog(id, value);
  return data;
});

const errorLogsSlice = createSlice({
  name: 'errorLogs',
  initialState,
  reducers: {
    setErrorLogsGridModel: (state, action: PayloadAction<Partial<GridModel<ErrorLog>>>) => {
      _.assign(state.grid, action.payload);
    },
    setErrorLogsUnresolved: (state, action: PayloadAction<boolean>) => {
      state.unresolved = action.payload;
    },
    clearErrorLogsState: (state) => {
      _.assign(state, initialState);
    },
    clearErrorLogsSuccessMessage: (state) => {
      state.successMessage = undefined;
      state.updatedMultiple = { succeeded: 0, failed: 0 };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getErrorLogsThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<ErrorLog>>) => {
          state.grid.loadingGrid = false;
          state.grid.data = action.payload;
        },
      )
      .addCase(getErrorLogsThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getErrorLogsThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(patchErrorLogThunk.fulfilled, (state) => {
        state.grid.loadingGrid = false;
        state.successMessage = state.unresolved
          ? ERROR_LOG_SUCCESS_MESSAGE.Resolved
          : ERROR_LOG_SUCCESS_MESSAGE.Unresolved;
      })
      .addCase(patchErrorLogThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(patchErrorLogThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(patchViewErrorLogThunk.fulfilled, (state) => {
        state.grid.loadingGrid = false;
        state.successMessage = state.unresolved
          ? ERROR_LOG_SUCCESS_MESSAGE.Resolved
          : ERROR_LOG_SUCCESS_MESSAGE.Unresolved;
      })
      .addCase(patchViewErrorLogThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(patchViewErrorLogThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(patchMultipleErrorLogThunk.fulfilled, (state) => {
        state.updatedMultiple.succeeded += 1;
        if (
          state.updatedMultiple.failed + state.updatedMultiple.succeeded !==
          state.grid.selectionModel?.length
        )
          return;
        state.grid.loadingGrid = false;
        state.successMessage = state.unresolved
          ? ERROR_LOG_SUCCESS_MESSAGE.ResolvedMultiple(state.updatedMultiple.succeeded)
          : ERROR_LOG_SUCCESS_MESSAGE.UnresolvedMultiple(state.updatedMultiple.succeeded);
      })
      .addCase(patchMultipleErrorLogThunk.rejected, (state) => {
        state.updatedMultiple.failed += 1;
        if (
          state.updatedMultiple.failed + state.updatedMultiple.succeeded !==
          state.grid.selectionModel?.length
        )
          return;
        state.grid.loadingGrid = false;
        state.successMessage = state.unresolved
          ? ERROR_LOG_SUCCESS_MESSAGE.ResolvedMultiple(state.updatedMultiple.succeeded)
          : ERROR_LOG_SUCCESS_MESSAGE.UnresolvedMultiple(state.updatedMultiple.succeeded);
      })
      .addCase(patchMultipleErrorLogThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      });
  },
});

export const {
  setErrorLogsGridModel,
  setErrorLogsUnresolved,
  clearErrorLogsState,
  clearErrorLogsSuccessMessage,
} = errorLogsSlice.actions;

export const errorLogsReducer = errorLogsSlice.reducer;
