import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Divider,Box, useMediaQuery, TextField, Tooltip} from '@mui/material';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BoxSection from '../../../shared/components/common/boxSection';
import TextAreaInput from '../../../shared/components/common/textAreaInput';
import GridInput from '../../../shared/components/common/gridTextField';
import CheckBoxInput from '../../../shared/components/common/checkBox/checkBoxInput';
import Progressed from '../../../assets/svg/progressed.svg';
import DeleteIcon from '../../../assets/svg/Delete.svg';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface vocabulary {
  causes: [
    {
      _id: string;
      label: string;
      slug: string;
    },
  ];
  impact: [];
  audience: [];
  programApproach: [];
  approachDuration: [];
  dataMeasurement: [];
  studyDesignType: [];
  studyEvidenceType: [];
  researchDataType: [];
  effect: [];
}

interface props {
  vocabData: any;
  strategies: any;
  submitted?: boolean;
  setCompletionProgramData: any;
  validatedQuestions: (count: number) => void;
  isSaveProgress: boolean;
  setProcess: () => void;
  fundingAmount: 0;
  submitResponse: boolean;
}

interface strategiesApproach {
  activities: {
    value: string;
  }
  dataMeasurementType: string;
  activitiesFrequency: string;
  nonprofitPartners: boolean;
  nonprofitPartnersDescription:string;
  outcomes: [
    {
      description: string;
      quantity: number;
    },
  ];
  resultsMeasurement: string;
  approachDuration: string;
  outputs: [
    {
      description: string;
      quantity: number;
    },
  ];
  strategies: [];
  [key: string]: any;
}

const StrategiesApproaches: React.FC<props> = ({
  vocabData,
  strategies,
  submitted,
  setCompletionProgramData,
  validatedQuestions,
  isSaveProgress,
  setProcess,
  fundingAmount,
  submitResponse,
}) => {
  const [vocabularyData, setvocabulary] = useState<vocabulary>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [outputCount, setOutputCount] = useState(0);
  const [outcomeCount, setOutcomeCount] = useState(0);
  const [strategiesApproachData, setStrategiesApproachData] = useState<strategiesApproach>();
  const [isNonProfit, setIsNonProfit] = useState('');
  // const [showOutputAdd, setShowOutputAdd] = useState(false);
  const [activitiesDescription, setActivitiesDescription] = useState('');
  const [activityFrequency, setActivityFrequency] = useState('');

  const [validatedAnswers, setvalidateAnswers] = useState({
    activities: 0,
    dataMeasurementType: 0,
    activitiesFrequency: 0,
    nonprofitPartners: 0,
    nonprofitPartnersDescription:0,
    approachDuration: 0,
    outputs: 0,
    strategies: 0,
  });

  const validateFields = (value: string) => (value === '' || value === undefined ? 0 : 1);
  const is1024 = useMediaQuery('(max-width:1024px)');
  useEffect(() => {
    setvocabulary(vocabData);
    setIsLoaded(true);
    setStrategiesApproachData(strategies);
    setOutputCount(strategies?.outputs?.length);
    setOutcomeCount(strategies?.outcomes?.length);
    setActivitiesDescription(strategies?.activities?.value);
    setActivityFrequency(strategies?.activitiesFrequency);
  }, [!isLoaded]);

  useEffect(() => {
    if (strategies?.nonprofitPartners) {
      setIsNonProfit('Yes');
    } else {
      setIsNonProfit('No');
    }
  }, [isLoaded]);

  const handleChange = (
    event: any,
    key: string,
    parentKey: string,
    type: string,
    isRequired: boolean,
  ) => {
    if (parentKey !== '') {
      setStrategiesApproachData((prevState: any) => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [key]: event.target.value,
        },
      }));
    }
    if (parentKey === '') {
      if (type !== 'typeCheck') {
        setStrategiesApproachData((prevState: any) => ({
          ...prevState,
          [key]: event.target.value,
        }));
      } else if (event.target.checked) {
        let targetValue: any;
        if (key === 'nonprofitPartners') {
          if (event.target.value === 'Yes') {
            targetValue = true;
          } else {
            targetValue = false;
          }
        } else {
          targetValue = event.target.value;
        }
        setStrategiesApproachData((prevState: any | undefined) => ({
          ...prevState,
          [key]: [...(prevState?.[key] || []), targetValue],
        }));
      } else if (!event.target.checked) {
        setStrategiesApproachData((prevState: any | undefined) => ({
          ...prevState,
          [key]: prevState[key].filter((item: any) => item !== event.target.value),
        }));
      }
    }
    if (isRequired) {
      if (parentKey !== '') {
        setvalidateAnswers((prevState: any) => ({
          ...prevState,
          [parentKey]: {
            ...prevState[parentKey],
            [key]: validateFields(event.target.value),
          },
        }));
      } else {
        setvalidateAnswers((prevValues: any) => ({
          ...prevValues,
          [key]: validateFields(event.target.value),
        }));
      }
    }
  };

  const handleIndexChange = (event: any, key: string, parentKey: string, index: number) => {
    const targetValue = key === 'quantity' ? Number(event.target.value) : event.target.value;
    setStrategiesApproachData((prevValues: any) => ({
      ...prevValues,
      [parentKey]: prevValues?.[parentKey]?.map((item: any, itemIndex: number) => {
        if (itemIndex === index) {
          return {
            ...item,
            [key]: targetValue,
          };
        }
        return item;
      }),
    }));
  
  };

  useEffect(() => {
    const count = Object.values(validatedAnswers)?.filter((value) => value === 1)?.length;
    validatedQuestions(count);
  }, [validatedAnswers]);

  useEffect(() => {
    setvalidateAnswers({
      activities: strategiesApproachData?.activities ? 1 : 0,
      dataMeasurementType: strategiesApproachData?.dataMeasurementType ? 1 : 0,
      activitiesFrequency: strategiesApproachData?.activitiesFrequency ? 1 : 0,
      nonprofitPartners: strategiesApproachData?.nonprofitPartners || !strategiesApproachData?.nonprofitPartners ? 1 : 0,
      nonprofitPartnersDescription:(strategiesApproachData?.nonprofitPartners && strategiesApproachData?.nonprofitPartnersDescription)|| !strategiesApproachData?.nonprofitPartners ? 1 : 0,
      approachDuration: strategiesApproachData?.approachDuration ? 1 : 0,
      outputs: strategiesApproachData?.outputs?.length! > 0 ? 1 : 0,
      strategies: strategiesApproachData?.strategies?.length! > 0 ? 1 : 0,
    });
  }, [strategiesApproachData]);

  useEffect(() => {
    if (isSaveProgress || submitResponse) {
      setCompletionProgramData(
        {
          activities: strategiesApproachData?.activities,
          dataMeasurementType: strategiesApproachData?.dataMeasurementType,
          activitiesFrequency: activityFrequency,
          nonprofitPartners: strategiesApproachData?.nonprofitPartners,
          nonprofitPartnersDescription:strategiesApproachData?.nonprofitPartnersDescription,
          outcomes: strategiesApproachData?.outcomes,
          resultsMeasurement: strategiesApproachData?.resultsMeasurement,
          approachDuration: strategiesApproachData?.approachDuration,
          outputs: strategiesApproachData?.outputs,
          strategies: strategiesApproachData?.strategies,
        },
        'strategiesAndApproaches',
      );
      setProcess();
    }
  }, [isSaveProgress, submitResponse]);

  const outputIncrement = () => {
    setOutputCount((prev) => prev + 1);
    strategiesApproachData?.outputs.push({
      quantity: 0,
      description: '',
    });
  };
  const outcomeIncrement = () => {
    setOutcomeCount((prev) => prev + 1);
    strategiesApproachData?.outcomes.push({
      quantity: 0,
      description: '',
    });
  };

  const handleDelete = (index: number, key: string) => {
    const updatedItemization = [...(strategiesApproachData?.[key] || [])];
    updatedItemization.splice(index, 1);
    setStrategiesApproachData((prevState: any) => ({
      ...prevState,
      [key]: updatedItemization,
    }));
    if (key === 'outputs') {
      setOutputCount((prevCount) => prevCount - 1);
    } else {
      setOutcomeCount((prevCount) => prevCount - 1);
    }
  };

  const [isNonProfitDesc,setIsNonprofirDesc]=useState<boolean>()
    useEffect(()=>{
        setIsNonprofirDesc(strategiesApproachData?.nonprofitPartners)
    },[strategiesApproachData?.nonprofitPartners])
  // useEffect(() => {
  //   const sum = strategiesApproachData?.outputs.reduce(
  //     (total: number, item: any) => total + item.quantity,
  //     0,
  //   );
  //   if (Number(sum) <= 100) {
  //     setShowOutputAdd(false);
  //   } else if (Number(sum) > 100) {
  //     setShowOutputAdd(true);
  //     toast.error('Output quantity % exceeds');
  //   }
  // }, [strategiesApproachData?.outputs]);


  useEffect(() => {
    if (isNonProfit === 'Yes') {
      setStrategiesApproachData((prevState: any) => ({
        ...prevState,
        nonprofitPartners: true,
      }));
      setIsNonprofirDesc(true)
    } else {
      setStrategiesApproachData((prevState: any) => ({
        ...prevState,
        nonprofitPartners: false,
        nonprofitPartnersDescription:''
      }));
      setIsNonprofirDesc(false)
    }
  }, [isNonProfit]);



  return (
    <>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 1 of 8
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="How would you classify the strategies this program employs?"
          description="Please select up to two from the list below."
          disabled={submitted}
        />
        <Grid container spacing={2}>
          {vocabularyData &&
            vocabularyData?.programApproach?.map((approach: any) => {
              /* eslint-disable no-underscore-dangle */
              const id = approach._id;
              const selectedValues =
                strategiesApproachData &&
                strategiesApproachData?.strategies?.filter((value) => value === approach._id);
              const checkedValue = selectedValues && selectedValues[0];
              return (
                <Grid item xs={4} key={approach.label}>
                  <CheckBoxInput
                    label={approach.label}
                    type="checkbox"
                    disabled={submitted}
                    value={approach._id}
                    onChange={(event) => handleChange(event, 'strategies', '', 'typeCheck', true)}
                    checked={id === checkedValue}
                  />
                </Grid>
              );
            })}
        </Grid>
      </BoxSection>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 2 of 8
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="Describe the specific activities you will undertake to implement your program."
          secondaryTitle="Description"
          isTextField
          infoIcon
          tooltipText="Description"
          required
          disabled={submitted}
          value={activitiesDescription}
          onChange={(event) => {
            setActivitiesDescription(event.target.value);
            if (activitiesDescription.length === 1 || activitiesDescription.length === 0) {
              setvalidateAnswers((prevState: any) => ({
                ...prevState,
                activities: activitiesDescription ? 1 : 0,
              }));
              handleChange(event, 'value', 'activities', '', true)
            }
          }}
        />
      </BoxSection>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 3 of 8
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="Is the program intervention one-time or ongoing?"
          disabled={submitted}
        />
        <Grid container spacing={2}>
          {vocabularyData &&
            vocabularyData.approachDuration &&
            vocabularyData.approachDuration.map((duration: any) => {
              const checkedValue =
                strategiesApproachData && strategiesApproachData.approachDuration;
              return (
                <Grid item xs={4} key={duration.label}>
                  <CheckBoxInput
                    label={duration.label}
                    type="radio"
                    disabled={submitted}
                    value={duration._id}
                    onChange={(event) => handleChange(event, 'approachDuration', '', '', true)}
                    checked={duration._id === checkedValue}
                  />
                </Grid>
              );
            })}
        </Grid>
      </BoxSection>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 4 of 8
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="Describe the frequency and length of the program activities."
          secondaryTitle="Description"
          isTextField
          infoIcon
          tooltipText="Description"
          value={activityFrequency}
          onChange={(event) => {
            setActivityFrequency(event.target.value);
            if (activityFrequency.length === 1 || activityFrequency.length === 0) {
              setvalidateAnswers((prevState: any) => ({
                ...prevState,
                activitiesFrequency: activityFrequency ? 1 : 0,
              }));
            }
          }}
          required
          disabled={submitted}
        />
      </BoxSection>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 5 of 8
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="What outputs does this funding create?"
          description={`These outputs shoulde be based on the ${fundingAmount.toFixed(
            2,
          )} funding amount provided by this opportunity.`}
          disabled={submitted}
        />
        <Grid item xs={12} sm={12}>
          <Divider sx={{ marginBottom: '10px' }} />
        </Grid>
        <Grid xs={12} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
        <Grid item xs={12} sm={8}>
        {[...Array(outputCount)].map((_, index: number) => {
          const indexVal = `key${index}`;
          return (
            <Grid container spacing={2} key={indexVal}>
              <Grid item xs={3}>
                <GridInput
                  typographyText={`Output Unit ${index + 1}`}
                  type="number"
                  placeholder="Enter Output Unit"
                  infoIcon
                  tooltipText={`Output Unit ${index + 1}`}
                  required
                  disabled={submitted}
                  value={
                    strategiesApproachData &&
                    strategiesApproachData?.outputs &&
                    strategiesApproachData?.outputs[index]?.quantity
                  }
                  onChange={(event) => handleIndexChange(event, 'quantity', 'outputs', index)}
                />
              </Grid>
              <Grid item xs={5}>
                <GridInput
                  typographyText={`Output Description ${index + 1}`}
                  type="text"
                  placeholder="Enter Output Description"
                  infoIcon
                  tooltipText={`Output Description ${index + 1}`}
                  required
                  disabled={submitted}
                  value={
                    strategiesApproachData &&
                    strategiesApproachData?.outputs &&
                    strategiesApproachData?.outputs[index]?.description
                  }
                  onChange={(event) => handleIndexChange(event, 'description', 'outputs', index)}
                />
              </Grid>
              <Grid item xs={1} onClick={() => handleDelete(index, 'outputs')}>
                <img
                  src={DeleteIcon}
                  style={{ marginLeft: '40px', cursor: 'pointer' }}
                  alt="Delete"
                />
              </Grid>
            </Grid>
          );
        })}
        </Grid>
        <Grid xs={12} sm={4} sx={{display:'flex',justifyContent:'center',alignItems:'center',alignSelf:'flex-start'}}>
          <Grid item  xs={12}>
            <Box
              sx={{
                mb: 3,
                padding: '15px 20px',
                width: `${is1024?"165px":'300px'}`,
                background: '#E7F2FF',
                borderRadius: '20px',
              }}
            >
              <Typography variant="h5" sx={{ mb: 1 }}>
                <InfoIcon fontSize="medium" /> Outputs (example)
              </Typography>
              <Typography variant="body1">
                Output: 1500
              </Typography>
              <Typography variant="body1">
                Output Description: Clothes Donated
              </Typography>
            </Box>
          </Grid>
          </Grid>
        </Grid>
        <Button
          sx={{ color: colors.primary, padding: '0px !important' }}
          onClick={outputIncrement}
          disabled={submitted}
        >
          <AddCircleOutlineIcon sx={{ marginRight: '10px' }} />
          <span> Add Item</span>
        </Button>
        {/* <GridInput multiline rows={5} /> */}
      </BoxSection>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 6 of 8
          {submitted && (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="What outcomes does this funding create?"
          description="outcomes should show the effectiveness of your program."
          disabled={submitted}
        />
        <Grid item xs={12}>
          <Divider sx={{ marginBottom: '10px' }} />
        </Grid>
        <Grid xs={12} sx={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
        <Grid item xs={12} sm={8}>
        {[...Array(outcomeCount)].map((number: number, index: number) => {
          const indexVal = `key${index}`;
          return (
            <Grid container spacing={2} key={indexVal}>
              <Grid item xs={3}>
              <GridInput
                  typographyText={`Outcome ${index + 1} %`}
                  type="number"
                  placeholder="Enter Outcome Percentage"
                  infoIcon
                  tooltipText={`Outcome Item ${index + 1}`}
                  disabled={submitted}
                  value={
                    strategiesApproachData &&
                    strategiesApproachData?.outcomes &&
                    strategiesApproachData?.outcomes[index]?.quantity
                  }
                  onChange={(event) => {
                    if(Number(event.target.value)<=100){
                      handleIndexChange(event, 'quantity', 'outcomes', index)
                    }
                    else {
                      toast.warning("Outcomes should not exceed 100%")
                    }
                    }
                    }
                    
                />
              </Grid>
              <Grid item xs={4}>
                <GridInput
                  typographyText={`Outcome Description ${index + 1}`}
                  type="text"
                  placeholder="Enter Outcome Description"
                  infoIcon
                  tooltipText={`Outcome Description ${index + 1}`}
                  disabled={submitted}
                  value={
                    strategiesApproachData &&
                    strategiesApproachData?.outcomes &&
                    strategiesApproachData?.outcomes[index]?.description
                  }
                  onChange={(event) => handleIndexChange(event, 'description', 'outcomes', index)}
                />
              </Grid>
              <Grid item xs={1} onClick={() => handleDelete(index, 'outcomes')}>
                {!submitted && <img
                  src={DeleteIcon}
                  style={{ marginLeft: '40px', cursor: 'pointer' }}
                  alt="Delete"
                />}
              </Grid>
            </Grid>
          );
        })}
         </Grid>
         <Grid xs={12} sm={4} sx={{display:'flex',justifyContent:'center',alignItems:'center',alignSelf:'flex-start'}}>
          <Grid item  xs={12}>
            <Box
              sx={{
                mb: 3,
                padding: '15px 20px',
                width: `${is1024?"165px":'300px'}`,
                background: '#E7F2FF',
                borderRadius: '20px',
              }}
            >
              <Typography variant="h5" sx={{ mb: 1 }}>
                <InfoIcon fontSize="medium" /> Outcomes (example)
              </Typography>
              <Typography variant="body1">
                Outcome: 45%
              </Typography>
              <Typography variant="body1">
                Outcome Description:  Increase in children receiving accessing nutritious meals
              </Typography>
            </Box>
          </Grid>
          </Grid>
          </Grid>
        <Button
          sx={{ color: colors.primary, padding: '0px !important' }}
          onClick={outcomeIncrement}
          disabled={submitted}
        >
          <AddCircleOutlineIcon sx={{ marginRight: '10px' }} />
          <span> Add Item</span>
        </Button>
       
        {/* <GridInput multiline rows={3} /> */}
       
      </BoxSection>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 7 of 8
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="Are your results measured qualitatively or quantitatively?"
          disabled={submitted}
        />
        <Grid container spacing={2}>
          {vocabularyData &&
            vocabularyData?.dataMeasurement?.map((measurement: any) => {
              const checkedValue =
                strategiesApproachData && strategiesApproachData?.dataMeasurementType;
              return (
                <Grid item xs={4} key={measurement.label}>
                  <CheckBoxInput
                    label={measurement.label}
                    type="radio"
                    disabled={submitted}
                    value={measurement._id}
                    onChange={(event) => handleChange(event, 'dataMeasurementType', '', '', true)}
                    checked={measurement._id === checkedValue}
                  />
                </Grid>
              );
            })}
        </Grid>
        {/* <Grid xs={7}>
          <GridInput multiline rows={4} disabled={submitted} />
        </Grid> */}
      </BoxSection>
      <BoxSection>
        <Typography variant="h6" sx={{ color: colors.textSecondary , mb: 1, fontSize: `${fonts.fontSize14} !important`, fontWeight: `${fonts.fontWeight400} !important` }}>
          Question 8 of 8
          {!submitted ? (
            <sup style={{ color: colors.statusAlert }}>*</sup>
          ) : (
            <img src={Progressed} style={{ height: '14px', marginLeft: '5px' }} alt="Progressed" />
          )}
        </Typography>
        <TextAreaInput
          title="Are there any SIO partners supporting this program?"
          description="Please select all that apply."
          disabled={submitted}
        />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <CheckBoxInput
              label="Yes"
              type="radio"
              value="Yes"
              checked={isNonProfit === 'Yes'}
              onChange={() => {
                setIsNonProfit('Yes');
              }}
              disabled={submitted}
            />
          </Grid>
          <Grid item xs={3}>
            <CheckBoxInput
              label="No"
              type="radio"
              value="No"
              checked={isNonProfit !== 'Yes'}
              onChange={() => {
                setIsNonProfit('No');
              }}
              disabled={submitted}
            />
          </Grid>
          {isNonProfitDesc &&
           <Grid item xs={12} sm={5} sx={{ ml: 0.5 }}>
           <Typography sx={{ mb: 0.5 }} variant='subtitle1'>SIO partners <Tooltip title="Please mention all SIO partners supporting this program"><InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} /></Tooltip></Typography>
           <TextField
               name="nonprofitPartnersDescription"
               placeholder="Please mention all SIO partners supporting this program."
               fullWidth
               value={strategiesApproachData?.nonprofitPartnersDescription?.trim()}
               onChange={(event) => handleChange(event, 'nonprofitPartnersDescription', '', '', true)}
               margin="normal"
               variant="outlined"
               error={strategiesApproachData?.nonprofitPartnersDescription?.trim()==='' && strategiesApproachData?.nonprofitPartners}
               multiline
               sx={{marginTop:"0px"}}
               InputProps={{
                sx: {
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '1rem',
                  },
                },
              }}
               rows={2}
           />
       </Grid>
       
            }
        </Grid>
      </BoxSection>
    </>
  );
};

export default StrategiesApproaches;
