import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Tooltip,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Modal,
  Card,
  CardContent,
  Typography,
  Button,
  Link,
} from '@mui/material';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
// import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import '../sio/formFilling.scss';
import { fetchProgrambyUser, postProgrambyUserThunk } from './ProgramSlice';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';
import { mainHeadingStyles, subHeadingStyles } from '../funding/FundingStyles';

// interface ProgressTypes {
//   general: { complete: number; total: number };
//   impactAndScope: { complete: number; total: number };
//   strategiesAndApproaches: { complete: number; total: number };
//   researchAndEvaluation: { complete: number; total: number };
//   finance: { complete: number; total: number };
//   content: { complete: number; total: number };
// }

const successStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '45vw',
  bgcolor: '#FFFFFF',
  border: '1px solid #E6E6E6',
  boxShadow: 24,
  borderRadius: 5,
};

const ProgramFormSuccess = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const programState = useAppSelector((state) => state.programs);
  const { program } = programState;
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [accept, setAccept] = useState(true);

  useEffect(() => {
    if (id) {
      dispatch(fetchProgrambyUser(id));
    }
  }, [id]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const payload = {
      status: 'submitted',
      general: program.general,
      impactAndScope: program.impactAndScope,
      strategiesAndApproaches: program.strategiesAndApproaches,
      researchAndEvaluation: program.researchAndEvaluation,
      finance: program.finance,
      content: program.content,
      review: {
        name,
        email,
      },
    };
    if (id && accept) {
      dispatch(postProgrambyUserThunk({ program: payload, id })).then((res) => {
        if (res) toast.success('Program submitted successfully!');
        navigate(`program-forms/${id}/success`);
      });
    }
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  return (
    <section className="bgLight">
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...successStyle }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                bgcolor: `#E7F2FF`,
                mb: 2,
                p: 2,
                borderRadius: '20px 20px 0 0',
              }}
            >
              <Typography sx={mainHeadingStyles}>Submit Confirmation</Typography>
            </Grid>
            <Grid item xs={12} sx={{ padding: '0 20px 20px' }}>
              <Typography sx={subHeadingStyles}>
                Are you sure you submit Program Funding Form?
              </Typography>
              <Box sx={{ border: '1px solid #c4bebe', borderRadius: '16px', padding: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      Please enter your name and email to submit the application to ScaleWith.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      Name
                      <Tooltip title="Enter Name">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      placeholder="Enter Name"
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '50px',
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      Email
                      <Tooltip title="Enter Email">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      placeholder="Enter Email"
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '50px',
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      value="bottom"
                      control={<Checkbox checked={accept} onChange={() => setAccept(!accept)} />}
                      label="I agree to the Terms And Conditions & Privacy Policy . I hereby represents and warrants that all information contained herein will be truthful and accurate as of the date of said submission."
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} textAlign="right" sx={{ p: 2, borderTop: '1px solid #E6E6E6' }}>
              <Button
                onClick={handleCloseModal}
                sx={{ float: 'right', ml: 3 }}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={!name || !email || !accept}
                onClick={handleSubmit}
                sx={{ float: 'right', ml: 3 }}
                variant="contained"
                size="large"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardContent sx={{ padding: '80px' }}>
              <Typography sx={{ ...commonTextStyle.heading, mb: 4 }} gutterBottom>
                Congratulations! Program Submission form Submitted Successfully!
              </Typography>
              <Typography sx={{ ...commonTextStyle.subHeading, mb: 10 }}>
                Thank you for submitting your Program Submission form. We will be in touch regarding
                your submission. <br />
                If you have any questions, please reach out to your designated Partnerships Manager,
                or email{' '}
                <Link
                  className="mailtoLink"
                  href="mailto:help@scalewith.com"
                  sx={{
                    display: 'inline',
                    ml: 1,
                    fontSize: '16px',
                    color: colors.primary,
                    fontWeight: fonts.fontWeight600,
                  }}
                >
                  <span>help@scalewith.com</span>
                </Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default ProgramFormSuccess;
