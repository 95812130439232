import { ErrorLog, GridRequest, GridResponse } from '../../shared/interfaces';
import { cancelToken, httpSecuredCommon } from '../httpCommon';

const base = '/error-logs';

export const ERROR_LOG_API_ROUTES = {
  getErrorLogs: base,
  getErrorLog: (id: string) => `${base}/${id}`,
  patchErrorLog: (id: string) => `${base}/${id}`,
};

export const getErrorLogs = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<ErrorLog>>(
    ERROR_LOG_API_ROUTES.getErrorLogs,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const getErrorLog = async (id: string) => {
  const response = await httpSecuredCommon.get<ErrorLog>(ERROR_LOG_API_ROUTES.getErrorLog(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const patchErrorLog = async (id: string, value: Omit<ErrorLog, 'id'>) => {
  const response = await httpSecuredCommon.patch<ErrorLog>(
    ERROR_LOG_API_ROUTES.patchErrorLog(id),
    value,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};
