import React, { ChangeEvent, useEffect, useState } from 'react';
// import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Checkbox,
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from '@mui/material';
import '../sio/formFilling.scss';
import './programFundingForm.scss';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { fetchFundingbyUser } from './fundingSlice';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { getLocations, getVocab } from '../../api/portal/programRequest';
import { Locations, Vocabulary } from './fundingInterface';
import { COUNTRIESCONSTANT } from '../../shared/constants';
import SearchInput from '../../shared/components/common/searchInput/searchInput';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

type ChildComponentProps = {
  onDataSubmit: (data: any) => void;
};

const theme = createTheme({
  palette: {
    primary: {
      light: colors.primary,
      main: colors.primary,
    },
  },
});

const ImpactAndScope: React.FC<ChildComponentProps> = ({ onDataSubmit }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const fundingState = useAppSelector((state) => state.funding);
  const { funding } = fundingState;
  const initialFormValue = {
    causes: funding?.impactAndScope?.causes ?? [],
    primaryImpact: funding?.impactAndScope?.primaryImpact ?? [],
    secondaryImpact: funding?.impactAndScope?.secondaryImpacts ?? [],
    targetGroup: funding?.impactAndScope?.targetGroup ?? [],
    audienceAge: funding?.impactAndScope?.audienceAge ?? [],
    regions: funding?.impactAndScope?.locationDetails?.regions ?? [],
    countries: funding?.impactAndScope?.locationDetails?.countries ?? [],
    states: funding?.impactAndScope?.locationDetails?.states ?? [],
    cities: funding?.impactAndScope?.locationDetails?.cities ?? [],
    additionalLocationDetails: funding?.impactAndScope?.locationDetails?.additionalLocationDetails,
    locationExplanation: {
      regions: funding?.impactAndScope?.locationDetails?.locationExplanation?.regions ?? '',
      countries: funding?.impactAndScope?.locationDetails?.locationExplanation?.countries ?? '',
      states: funding?.impactAndScope?.locationDetails?.locationExplanation?.states ?? '',
      cities: funding?.impactAndScope?.locationDetails?.locationExplanation?.cities ?? '',
    },
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  // const [files, setFiles] = useState<File>()
  const [vocab, setVocab] = useState<Vocabulary>();
  const [locations, setLocations] = useState<Locations>();
  const [filteredRegions, setFilteredRegions] = useState<any>();
  const [locExpDetails, setLocaExpDetails] = useState({
    regions: false,
    countries: false,
    states: false,
    cities: false,
  });

  useEffect(() => {
    getVocab().then((res) => {
      if (res.status === 200) {
        setVocab(res.data);
      }
    });
    getLocations().then((res) => {
      if (res.status === 200) {
        setLocations(res.data);
        setFilteredRegions(res?.data?.continents);
      }
    });
    if (id && funding) {
      dispatch(fetchFundingbyUser(id)).then((fundingData: any) => {
        setFormValues({
          causes: fundingData.payload?.impactAndScope?.causes ?? [],
          primaryImpact: fundingData.payload?.impactAndScope?.primaryImpact ?? [],
          secondaryImpact: fundingData.payload?.impactAndScope?.secondaryImpacts ?? [],
          targetGroup: fundingData.payload?.impactAndScope?.targetGroup ?? [],
          audienceAge: fundingData.payload?.impactAndScope?.audienceAge ?? [],
          regions: fundingData.payload?.impactAndScope?.locationDetails?.regions ?? [],
          countries: fundingData.payload?.impactAndScope?.locationDetails?.countries ?? [],
          states: fundingData.payload?.impactAndScope?.locationDetails?.states ?? [],
          cities: fundingData.payload?.impactAndScope?.locationDetails?.cities ?? [],
          locationExplanation: {
            regions:
              fundingData?.payload?.impactAndScope?.locationDetails?.locationExplanation?.regions ??
              '',
            countries:
              fundingData?.payload?.impactAndScope?.locationDetails?.locationExplanation
                ?.countries ?? '',
            states:
              fundingData?.payload?.impactAndScope?.locationDetails?.locationExplanation?.states ??
              '',
            cities:
              fundingData?.payload?.impactAndScope?.locationDetails?.locationExplanation?.cities ??
              '',
          },
          additionalLocationDetails:
            fundingData.payload?.impactAndScope?.locationDetails?.additionalLocationDetails,
        });

        // savePageNumber()
      });
    }
  }, [id]);

  // const updateFundingDetails = () => {
  //     const payload = {
  // impactAndScope: {
  //     causes: formValues.causes,
  //     primaryImpact: typeof (formValues.primaryImpact) === "string" ? [formValues.primaryImpact] : formValues.primaryImpact,
  //     secondaryImpacts: formValues.secondaryImpact,
  //     targetGroup: formValues.targetGroup,
  //     audienceAge: formValues.audienceAge,
  //     locationDetails: {
  //         regions: formValues.regions,
  //         countries: formValues.countries,
  //         states: formValues.states,
  //         cities: formValues.cities,
  //         additionalLocationDetails: formValues.additionalLocationDetails
  //     }
  // },
  //     }
  //     const fundingPayload = payload
  //     if (id) {
  //         return dispatch(updateFundingbyUserThunk({ funding: fundingPayload, id }));
  //     }
  //     return true
  // }

  // const handleSaveExit = async () => {
  //     const response = await updateFundingDetails()
  //     if (response) {
  //         // toast.success('Successfully Submitted Form to ScaleWith');
  //         navigate(`/program-form/${id}`)
  //     } else {
  //         toast.error('An error occurred while logging in. Please try again.');
  //     }
  //     return null
  // }
  const [selectAll, setSelectAll] = useState(false);
  const selectAllRegions = (value: boolean) => {
    setSelectAll(value);
    if (value) {
      locations?.continents.forEach((continent: any) => {
        setFormValues((prevValues: any) => ({
          ...prevValues,
          regions: [...(prevValues?.regions || []), continent?.name],
        }));
      });
    } else {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        regions: [],
      }));
    }
  };

  const isChecked = (data: any, value: string) => {
    let returnValue = false;
    data.map((item: any) => {
      const n = item.lastIndexOf(';');
      const result = item.substring(n + 1);
      if (result === value) {
        returnValue = true;
      }
      return null;
    });
    return returnValue;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheck = (event: any, key: keyof typeof formValues) => {
    let newvalue = formValues[key];
    if (Array.isArray(newvalue)) {
      if (event.target.checked) {
        setFormValues({
          ...formValues,
          [key]: [event.target.value],
        });
      } else {
        newvalue = newvalue.filter((item: any) => item !== event.target.value);
        setFormValues({
          ...formValues,
          [key]: newvalue,
        });
      }
    }
  };

  const locationExpCheck = (key: string, isCheck: boolean) => {
    setLocaExpDetails((prevState) => ({ ...prevState, [key]: isCheck }));
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>, isNa?: boolean) => {
    const { name, value } = event.target;
    if (name === 'causes') {
      let newvalue = formValues.causes;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else if (formValues.causes.length <= 1) {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        causes: newvalue,
      });
    }
    if (name === 'secondaryImpact') {
      if (value === 'N/A') {
        handleCheck(event, 'secondaryImpact');
      } else {
        let newvalue = formValues.secondaryImpact;
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else if (formValues.secondaryImpact.length < 3) {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          secondaryImpact: newvalue,
        });
      }
    }
    if (name === 'targetGroup') {
      if (isNa) {
        setFormValues({
          ...formValues,
          targetGroup: [],
        });
        handleCheck(event, 'targetGroup');
      } else {
        let newvalue = formValues.targetGroup;
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          targetGroup: newvalue,
        });
      }
    }
    if (name === 'audienceAge') {
      if (isNa) {
        setFormValues({
          ...formValues,
          audienceAge: [],
        });
        handleCheck(event, 'audienceAge');
      } else {
        let newvalue = formValues.audienceAge;
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          audienceAge: newvalue,
        });
      }
    }
    if (name === 'regions') {
      if (value === 'N/A') {
        if (event.target.checked) {
          locationExpCheck('regions', true);
          setFormValues({ ...formValues, regions: [], countries: [], states: [], cities: [] });
        } else {
          locationExpCheck('regions', false);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            locationExplanation: {
              regions: '',
            },
          }));
        }
      } else {
        let newvalue = formValues.regions;
        let countriesArr = formValues.countries;
        let statesArr = formValues.states;
        let citiesArr = formValues.cities;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
          countriesArr = countriesArr.filter((item) => item.indexOf(value) === -1);
          statesArr = statesArr.filter((item) => item.indexOf(value) === -1);
          citiesArr = citiesArr.filter((item) => item.indexOf(value) === -1);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          regions: newvalue,
          countries: countriesArr,
          states: statesArr,
          cities: citiesArr,
        });
      }
    }
    if (name === 'countries') {
      if (value === 'N/A') {
        if (event.target.checked) {
          locationExpCheck('countries', true);
          setFormValues({ ...formValues, countries: [], states: [], cities: [] });
        } else {
          locationExpCheck('countries', false);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            locationExplanation: {
              countries: '',
            },
          }));
        }
      } else {
        let newvalue = formValues.countries;
        let statesArr = formValues.states;
        let citiesArr = formValues.cities;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
          const n = value.lastIndexOf(';');
          const result = value.substring(n + 1);
          statesArr = statesArr.filter((item) => item.indexOf(result) === -1);
          citiesArr = citiesArr.filter((item) => item.indexOf(result) === -1);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          countries: newvalue,
          states: statesArr,
          cities: citiesArr,
        });
      }
    }
    if (name === 'states') {
      if (value === 'N/A') {
        if (event.target.checked) {
          locationExpCheck('states', true);
          setFormValues({ ...formValues, states: [] });
        } else {
          locationExpCheck('states', false);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            locationExplanation: {
              states: '',
            },
          }));
        }
      } else {
        let newvalue = formValues.states;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          states: newvalue,
        });
      }
    }
    if (name === 'cities') {
      if (value === 'N/A') {
        if (event.target.checked) {
          locationExpCheck('cities', true);
          setFormValues({ ...formValues, cities: [] });
        } else {
          locationExpCheck('cities', false);
          setFormValues((prevValues: any) => ({
            ...prevValues,
            locationExplanation: {
              cities: '',
            },
          }));
        }
      } else {
        let newvalue = formValues.cities;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          cities: newvalue,
        });
      }
    }
  };
  const handleLocationExpChange = (event: any, key: string) => {
    if (event.target.value.length !== 0) {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        locationExplanation: {
          [key]: event.target.value,
        },
      }));
    } else {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        locationExplanation: {
          [key]: '',
        },
      }));
      setLocaExpDetails((prevValues: any) => ({
        ...prevValues,
        [key]: false,
      }));
    }
  };

  useEffect(() => {
    if (funding?.impactAndScope?.locationDetails?.locationExplanation?.regions) {
      locationExpCheck('regions', true);
    } else {
      locationExpCheck('regions', false);
    }
    if (funding?.impactAndScope?.locationDetails?.locationExplanation?.countries) {
      locationExpCheck('countries', true);
    } else {
      locationExpCheck('countries', false);
    }
    if (funding?.impactAndScope?.locationDetails?.locationExplanation?.states) {
      locationExpCheck('states', true);
    } else {
      locationExpCheck('states', false);
    }
    if (funding?.impactAndScope?.locationDetails?.locationExplanation?.cities) {
      locationExpCheck('cities', true);
    } else {
      locationExpCheck('cities', false);
    }
  }, [funding?.impactAndScope?.locationDetails?.locationExplanation]);
  const renderCountriesOptions = (country: any, region: any) =>
    country[0][1]?.map((items: any) => (
      <Box
        key={items.name}
        className={isChecked(formValues.countries, items.name) ? 'active' : ''}
        sx={{
          border: '1px solid #E0E0E0',
          display: 'inline-block',
          width: 'fit-content',
          m: 1,
          borderRadius: '20px',
          p: 2,
        }}
      >
        <Typography variant="body1" display="inline">
          {items.name}
        </Typography>
        <Checkbox
          name="countries"
          checked={isChecked(formValues.countries, items.name)}
          value={`${region};${items.name}`}
          onChange={handleChangeSelect}
          disabled={locExpDetails?.countries}
        />
      </Box>
    ));

  const renderCitiesOptions = (country: any, regionCountry: any) =>
    country.length > 0 &&
    country[0][1]?.map((items: any) => (
      <Box
        key={items.name}
        className={isChecked(formValues.cities, items.name) ? 'active' : ''}
        sx={{
          border: '1px solid #E0E0E0',
          display: 'inline-block',
          width: 'fit-content',
          m: 1,
          borderRadius: '20px',
          p: 2,
        }}
      >
        <Typography variant="body1" display="inline">
          {items.name}
        </Typography>
        <Checkbox
          name="cities"
          checked={isChecked(formValues.cities, items.name)}
          value={`${regionCountry};${items.name}`}
          onChange={handleChangeSelect}
          disabled={locExpDetails?.cities}
        />
      </Box>
    ));

  const renderstatesOptions = (state: any, regionCountry: any) =>
    state.length > 0 &&
    state[0][1]?.map((items: any) => (
      <Box
        key={items.name}
        className={isChecked(formValues.states, items.name) ? 'active' : ''}
        sx={{
          border: '1px solid #E0E0E0',
          display: 'inline-block',
          width: 'fit-content',
          m: 1,
          borderRadius: '20px',
          p: 2,
        }}
      >
        <Typography variant="body1" display="inline">
          {items.name}
        </Typography>
        <Checkbox
          name="states"
          checked={isChecked(formValues.states, items.name)}
          value={`${regionCountry};${items.name}`}
          onChange={handleChangeSelect}
          disabled={locExpDetails?.states}
        />
      </Box>
    ));

  const renderCountries = (region: string) => {
    const regions = Object.entries(locations!.countries);
    let countries: any = [];
    if (region !== 'N/A') {
      countries = regions.filter((item) => item[0] === region ?? item);
      return renderCountriesOptions(countries, region);
    }
    return true;
  };

  const renderStates = (country: string, regionCountry: any) => {
    const regions = Object.entries(locations!.states);
    let states: any = [];
    if (country === 'United States' || country === 'Canada' || country !== 'N/A') {
      states = regions.filter((item) => item[0] === country ?? item);
      return renderstatesOptions(states, regionCountry);
    }
    return true;
  };

  const renderCities = (country: string, regionCountry: any) => {
    const regions = Object.entries(locations!.cities);
    let cities: any = [];
    if (country !== 'United States' && country !== 'Canada' && country !== 'N/A') {
      cities = regions.filter((item) => item[0] === country ?? item);
      return renderCitiesOptions(cities, regionCountry);
    }
    return true;
  };

  const searchRegion = (value: string) => {
    if (locations && locations?.continents) {
      const filteredData = locations?.continents?.filter((continent: any) =>
        continent.name.toLowerCase().includes(value.toLowerCase()),
      );
      setFilteredRegions(filteredData);
    }
  };

  useEffect(() => {
    onDataSubmit(formValues);
  }, [formValues]);
  return (
    <div>
      <Grid
        container
        direction="column"
        spacing={2}
        sx={{ display: 'flex', gap: '2rem', marginTop: '2rem' }}
        className="formContainer"
      >
        <Grid container>
          <Grid
            item
            xs={14}
            sx={{
              background: colors.backgroundPrimary,
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
              marginLeft: '-10px',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                fontSize: fonts.fontSize24,
                fontWeight: fonts.fontWeight700,
                color: colors.textPrimary,
              }}
            >
              Impact And Scope
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >{`${funding?.progress?.impactAndScope?.complete} of ${funding?.progress?.impactAndScope?.total} Completed`}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 1 of 12
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Which cause areas best match this program&apos;s work?
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Please select up to two from the list below.
            </Typography>
          </Grid>
          {
            /* eslint no-underscore-dangle: 0 */
            vocab?.causes.map((item: any) => (
              <Grid key={item._id} item>
                <Box
                  className={formValues.causes.includes(item._id) ? 'active' : ''}
                  sx={{
                    border: formValues.causes.includes(item._id)
                      ? `1px solid ${colors.strokeClick}`
                      : `1px solid ${colors.strokeDefault}`,
                    backgroundColor: formValues.causes.includes(item._id)
                      ? colors.backgroundSecondary
                      : colors.secondary,
                    borderRadius: '20px',
                    p: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight400,
                      color: colors.textPrimary,
                    }}
                    display="inline"
                  >
                    {item.label}
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Checkbox
                      name="causes"
                      checked={formValues.causes.includes(item._id)}
                      value={item._id}
                      color="primary"
                      onChange={handleChangeSelect}
                      sx={{
                        color: formValues.causes.includes(item._id)
                          ? colors.strokeClick
                          : colors.strokeDefault,
                      }}
                    />
                  </ThemeProvider>
                </Box>
              </Grid>
            ))
          }
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 2 of 12
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              What is this program&apos;s primary targeted impact?
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Impacts are long-term effects of your program. Please select one option from the list
              below.
            </Typography>
          </Grid>

          {vocab?.causes.map((item: any) => (
            <Grid key={item._id} sx={{ my: 1 }} item xs={12}>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                <Typography
                  sx={{
                    my: 2,
                    fontSize: fonts.fontSize16,
                    fontWeight: fonts.fontWeight500,
                    color: colors.textSecondary,
                  }}
                  display="inline"
                >
                  {item.label}
                </Typography>
                <br />
                <Grid item xs={12}>
                  <FormControl>
                    <ThemeProvider theme={theme}>
                      <RadioGroup
                        sx={{ display: 'inline-block' }}
                        name="primaryImpact"
                        value={formValues.primaryImpact}
                        onChange={handleChange}
                      >
                        {vocab?.impact.map((subitem: any) => {
                          if (subitem.causeId === item._id) {
                            return (
                              <FormControlLabel
                                sx={{
                                  p: 2,
                                  m: 1,
                                  border: '1px solid #E0E0E0',
                                  borderRadius: '20px',
                                  fontSize: fonts.fontSize14,
                                  fontWeight: fonts.fontWeight400,
                                  color: colors.textPrimary,
                                }}
                                labelPlacement="start"
                                key={subitem._id}
                                value={subitem._id}
                                control={<Radio />}
                                label={subitem.label}
                              />
                            );
                          }
                          return true;
                        })}
                      </RadioGroup>
                    </ThemeProvider>
                  </FormControl>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 3 of 12
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              What are the secondary impacts of this program?
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Impacts are long-term effects of your program. Please select up to three the list
              below, or N/A if not applicable.
            </Typography>
          </Grid>
          {vocab?.impact.map((subItem: any) => {
            if (subItem?.label === 'N/A') {
              return (
                <Grid item>
                  <Box
                    className={formValues.secondaryImpact.includes(subItem?._id) ? 'active' : ''}
                    sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                  >
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize16,
                        fontWeight: fonts.fontWeight500,
                        color: colors.textSecondary,
                      }}
                      display="inline"
                    >
                      N/A
                    </Typography>
                    <ThemeProvider theme={theme}>
                      <Checkbox
                        name="secondaryImpact"
                        checked={formValues.secondaryImpact.includes(subItem?._id)}
                        value={subItem?._id}
                        onChange={(event) => handleCheck(event, 'secondaryImpact')}
                      />
                    </ThemeProvider>
                  </Box>
                </Grid>
              );
            }
            return true;
          })}
          {vocab?.causes.map((item: any) => (
            <Grid key={item._id} sx={{ my: 1 }} item xs={12}>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                <Typography
                  sx={{
                    my: 2,
                    fontSize: fonts.fontSize16,
                    fontWeight: fonts.fontWeight500,
                    color: colors.textSecondary,
                  }}
                  display="inline"
                >
                  {item.label}
                </Typography>
                <br />
                {vocab?.impact.map((subItem: any) => {
                  const findNaObject: any = vocab?.impact?.find((obj: any) => obj.label === 'N/A');
                  if (subItem.causeId === item._id) {
                    return (
                      <Box
                        key={subItem._id}
                        className={formValues.secondaryImpact.includes(subItem._id) ? 'active' : ''}
                        sx={{
                          border: '1px solid #E0E0E0',
                          display: 'inline-block',
                          width: 'fit-content',
                          m: 1,
                          borderRadius: '20px',
                          p: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: fonts.fontSize14,
                            fontWeight: fonts.fontWeight400,
                            color: colors.textPrimary,
                          }}
                          display="inline"
                        >
                          {subItem.label}
                        </Typography>
                        <ThemeProvider theme={theme}>
                          <Checkbox
                            name="secondaryImpact"
                            checked={formValues?.secondaryImpact?.includes(subItem._id)}
                            value={subItem._id}
                            onChange={handleChangeSelect}
                            disabled={formValues?.secondaryImpact?.includes(findNaObject?._id)}
                          />
                        </ThemeProvider>
                      </Box>
                    );
                  }
                  return true;
                })}
              </Box>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 4 of 12{' '}
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Does this program specifically target any of the following groups of people?
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Please select all that apply.
            </Typography>
          </Grid>
          {
            /* eslint no-underscore-dangle: 0 */
            vocab?.audience.attribute.map((item: any) => {
              const findNaObject: any = vocab?.audience?.attribute?.find(
                (obj: any) => obj.label === 'N/A',
              );
              return (
                <Grid key={item._id} item>
                  <Box
                    className={formValues.targetGroup.includes(item._id) ? 'active' : ''}
                    sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                  >
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize14,
                        fontWeight: fonts.fontWeight400,
                        color: colors.textPrimary,
                      }}
                      display="inline"
                    >
                      {item.label}
                    </Typography>
                    <ThemeProvider theme={theme}>
                      <Checkbox
                        name="targetGroup"
                        checked={formValues.targetGroup.includes(item._id)}
                        value={item._id}
                        onChange={(event) =>
                          handleChangeSelect(event, item.label === 'N/A' && true)
                        }
                        disabled={
                          formValues?.targetGroup?.includes(findNaObject?._id) &&
                          item.label !== 'N/A'
                        }
                      />
                    </ThemeProvider>
                  </Box>
                </Grid>
              );
            })
          }
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 5 of 12{' '}
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              What age groups does this program serve?
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Please select all that apply.
            </Typography>
          </Grid>
          {
            /* eslint no-underscore-dangle: 0 */
            vocab?.audience.age.map((item: any) => {
              const findNaObject: any = vocab?.audience.age?.find(
                (obj: any) => obj.label === 'N/A',
              );
              return (
                <Grid key={item._id} item>
                  <Box
                    className={formValues.audienceAge.includes(item._id) ? 'active' : ''}
                    sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                  >
                    <Typography
                      display="inline"
                      sx={{
                        fontSize: fonts.fontSize14,
                        fontWeight: fonts.fontWeight400,
                        color: colors.textPrimary,
                      }}
                    >
                      {item.label}
                    </Typography>
                    <ThemeProvider theme={theme}>
                      <Checkbox
                        name="audienceAge"
                        checked={formValues.audienceAge.includes(item._id)}
                        value={item._id}
                        onChange={(event) =>
                          handleChangeSelect(event, item.label === 'N/A' && true)
                        }
                        disabled={
                          formValues?.audienceAge?.includes(findNaObject?._id) &&
                          item.label !== 'N/A'
                        }
                      />
                    </ThemeProvider>
                  </Box>
                </Grid>
              );
            })
          }
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 8 of 12
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              In which specific regions does this program operate?
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Please select all that apply.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center" sm={6}>
                <Box
                  className={locExpDetails?.regions ? 'active' : ''}
                  sx={{
                    border: '1px solid #E0E0E0',
                    borderRadius: '20px',
                    p: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight400,
                      color: colors.textSecondary,
                    }}
                    display="inline"
                  >
                    Able to operate in anywhere (please explain)
                  </Typography>
                  <ThemeProvider theme={theme}>
                    <Checkbox
                      name="regions"
                      checked={locExpDetails?.regions}
                      value="N/A"
                      onChange={handleChangeSelect}
                    />
                  </ThemeProvider>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    fontSize: fonts.fontSize16,
                    fontWeight: fonts.fontWeight500,
                    color: colors.textSecondary,
                  }}
                >
                  Description
                </Typography>
                <TextField
                  name="locationExplanation"
                  placeholder="Enter description here"
                  fullWidth
                  value={formValues?.locationExplanation?.regions}
                  disabled={!locExpDetails?.regions}
                  onChange={(event) => handleLocationExpChange(event, 'regions')}
                  margin="normal"
                  variant="outlined"
                  multiline
                  rows={4}
                  sx={{
                    marginTop: '5px',
                    borderRadius: '5px',
                    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fntWeight400,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid sx={{ my: 2 }} item xs={12}>
              <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                <Grid
                  container
                  sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                >
                  <Grid item>
                    <SearchInput onChange={(e) => searchRegion(e.target.value)} />
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        mx: 2,
                        cursor: 'pointer',
                        fontSize: fonts.fontSize16,
                        fontWeight: fonts.fontWeight500,
                        color: colors.primary,
                      }}
                      onClick={() => selectAllRegions(!selectAll)}
                    >
                      Select All
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  sx={{
                    my: 2,
                    fontSize: fonts.fontSize16,
                    fontWeight: fonts.fontWeight500,
                    color: colors.textSecondary,
                  }}
                  display="inline"
                >
                  Regions
                </Typography>
                <br />
                {filteredRegions &&
                  filteredRegions?.map((subItem: any) => (
                    <Box
                      key={subItem.name}
                      className={formValues.regions.includes(subItem.name) ? 'active' : ''}
                      sx={{
                        border: '1px solid #E0E0E0',
                        display: 'inline-block',
                        width: 'fit-content',
                        m: 2,
                        borderRadius: '20px',
                        p: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: fonts.fontSize14,
                          fontWeight: fonts.fontWeight400,
                          color: colors.textSecondary,
                        }}
                        display="inline"
                      >
                        {subItem.name}
                      </Typography>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          name="regions"
                          checked={formValues?.regions?.includes(subItem.name)}
                          value={subItem.name}
                          onChange={handleChangeSelect}
                          disabled={locExpDetails?.regions}
                        />
                      </ThemeProvider>
                    </Box>
                  ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {formValues.regions.length !== 0 && !formValues.regions.includes('N/A') && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fontWeight400,
                  color: colors.textSecondary,
                }}
              >
                Question 9 of 12
              </Typography>
              <Typography
                sx={{
                  mb: 2,
                  fontSize: fonts.fontSize18,
                  fontWeight: fonts.fontWeight600,
                  color: colors.textPrimary,
                }}
              >
                In which specific countries does this program operate?
              </Typography>
              <Typography
                sx={{
                  mb: 2,
                  fontSize: fonts.fontSize16,
                  fontWeight: fonts.fontWeight500,
                  color: colors.textSecondary,
                }}
              >
                Please select all that apply.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} textAlign="center" sm={6}>
                  <Box
                    className={locExpDetails?.countries ? 'active' : ''}
                    sx={{
                      border: '1px solid #E0E0E0',
                      borderRadius: '20px',
                      p: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize14,
                        fontWeight: fonts.fontWeight400,
                        color: colors.textSecondary,
                      }}
                      display="inline"
                    >
                      Not able to specify further (please explain)
                    </Typography>
                    <ThemeProvider theme={theme}>
                      <Checkbox
                        name="countries"
                        checked={locExpDetails?.countries}
                        value="N/A"
                        onChange={handleChangeSelect}
                      />
                    </ThemeProvider>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight500,
                      color: colors.textSecondary,
                    }}
                  >
                    Description
                  </Typography>
                  <TextField
                    name="locationExplanation"
                    placeholder="Enter description here"
                    fullWidth
                    value={formValues?.locationExplanation?.countries}
                    disabled={!locExpDetails?.countries}
                    onChange={(event) => handleLocationExpChange(event, 'countries')}
                    margin="normal"
                    variant="outlined"
                    multiline
                    rows={4}
                    sx={{
                      marginTop: '5px',
                      borderRadius: '5px',
                      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                        fontSize: fonts.fontSize14,
                        fontWeight: fonts.fntWeight400,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              {formValues.regions.map((item: string) => (
                <Grid key={item} sx={{ my: 1 }} item xs={12}>
                  <Box
                    sx={{
                      border: '1px solid #E0E0E0',
                      borderRadius: '20px',
                      p: 2,
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight400,
                      color: colors.textSecondary,
                    }}
                  >
                    <Typography
                      sx={{
                        my: 2,
                        fontSize: fonts.fontSize16,
                        fontWeight: fonts.fontWeight500,
                        color: colors.textSecondary,
                      }}
                      display="inline"
                    >
                      {item}
                    </Typography>
                    <br />
                    {locations && renderCountries(item)}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {formValues.regions.length !== 0 &&
          !formValues.regions.includes('N/A') &&
          (formValues.countries.includes(COUNTRIESCONSTANT.COUNTRY1) ||
            formValues.countries.includes(COUNTRIESCONSTANT.COUNTRY2) ||
            formValues.countries.includes(
              `${COUNTRIESCONSTANT.CONTINENT};${COUNTRIESCONSTANT.COUNTRY2}`,
            ) ||
            formValues.countries.includes(
              `${COUNTRIESCONSTANT.CONTINENT};${COUNTRIESCONSTANT.COUNTRY1}`,
            )) && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight400,
                    color: colors.textSecondary,
                  }}
                >
                  Question 10 of 12
                </Typography>
                <Typography
                  sx={{
                    mb: 2,
                    fontSize: fonts.fontSize18,
                    fontWeight: fonts.fontWeight600,
                    color: colors.textPrimary,
                  }}
                >
                  In which specific states does this program operate?
                </Typography>
                <Typography
                  sx={{
                    mb: 2,
                    fontSize: fonts.fontSize16,
                    fontWeight: fonts.fontWeight500,
                    color: colors.textSecondary,
                  }}
                >
                  Please select all that apply.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} textAlign="center" sm={6}>
                    <Box
                      className={locExpDetails?.states ? 'active' : ''}
                      sx={{
                        border: '1px solid #E0E0E0',
                        borderRadius: '20px',
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fontWeight500,
                          color: colors.textSecondary,
                        }}
                        display="inline"
                      >
                        Able to operate in anywhere (please explain)
                      </Typography>
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          name="states"
                          checked={locExpDetails?.states}
                          value="N/A"
                          onChange={handleChangeSelect}
                        />
                      </ThemeProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize16,
                        fontWeight: fonts.fontWeight500,
                        color: colors.textSecondary,
                      }}
                    >
                      Description
                    </Typography>
                    <TextField
                      name="locationExplanation"
                      placeholder="Enter description here"
                      fullWidth
                      value={formValues?.locationExplanation?.states}
                      disabled={!locExpDetails?.states}
                      onChange={(event) => handleLocationExpChange(event, 'states')}
                      margin="normal"
                      variant="outlined"
                      multiline
                      rows={4}
                      sx={{
                        marginTop: '5px',
                        borderRadius: '5px',
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                          fontSize: fonts.fontSize14,
                          fontWeight: fonts.fntWeight400,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                {formValues.countries.map((item: string) => {
                  const n = item.lastIndexOf(';');
                  const result = item.substring(n + 1);
                  if (result === 'United States' || result === 'Canada') {
                    return (
                      <Grid key={result} sx={{ my: 1 }} item xs={12}>
                        <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                          <Typography
                            sx={{
                              my: 2,
                              fontSize: fonts.fontSize14,
                              fontWeight: fonts.fontWeight400,
                              color: colors.textSecondary,
                            }}
                            display="inline"
                          >
                            {result}
                          </Typography>
                          <br />
                          {locations && renderStates(result, item)}
                        </Box>
                      </Grid>
                    );
                  }
                  return true;
                })}
              </Grid>
            </Grid>
          )}
        {formValues.regions.length !== 0 &&
          !formValues.regions.includes('N/A') &&
          formValues.countries.length !== 0 &&
          !formValues.countries.includes('N/A') && (
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight400,
                    color: colors.textSecondary,
                  }}
                >
                  Question 11 of 12
                </Typography>
                <Typography
                  sx={{
                    mb: 2,
                    fontSize: fonts.fontSize18,
                    fontWeight: fonts.fontWeight600,
                    color: colors.textPrimary,
                  }}
                >
                  In which specific cities does this program operate?
                </Typography>
                <Typography
                  sx={{
                    mb: 2,
                    fontSize: fonts.fontSize16,
                    fontWeight: fonts.fontWeight500,
                    color: colors.textSecondary,
                  }}
                >
                  Please select all that apply.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} textAlign="center" sm={6}>
                    <Box
                      className={locExpDetails?.cities ? 'active' : ''}
                      sx={{
                        border: '1px solid #E0E0E0',
                        borderRadius: '20px',
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: fonts.fontSize14,
                          fontWeight: fonts.fontWeight400,
                          color: colors.textSecondary,
                        }}
                        display="inline"
                      >
                        Able to operate in anywhere (please explain)
                      </Typography>
                      <Checkbox
                        name="cities"
                        checked={locExpDetails?.cities}
                        value="N/A"
                        onChange={handleChangeSelect}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize16,
                        fontWeight: fonts.fontWeight500,
                        color: colors.textSecondary,
                      }}
                    >
                      Description
                    </Typography>
                    <TextField
                      name="locationExplanation"
                      placeholder="Enter description here"
                      fullWidth
                      value={formValues?.locationExplanation?.cities}
                      disabled={!locExpDetails?.cities}
                      onChange={(event) => handleLocationExpChange(event, 'cities')}
                      margin="normal"
                      variant="outlined"
                      multiline
                      rows={4}
                      sx={{
                        marginTop: '5px',
                        borderRadius: '5px',
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                          fontSize: fonts.fontSize14,
                          fontWeight: fonts.fntWeight400,
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                {formValues.countries.map((item: string) => {
                  const n = item.lastIndexOf(';');
                  const result = item.substring(n + 1);
                  if (result !== 'United States' && result !== 'Canada') {
                    return (
                      <Grid key={result} sx={{ my: 1 }} item xs={12}>
                        <Box
                          sx={{
                            border: '1px solid #E0E0E0',
                            borderRadius: '20px',
                            p: 2,
                            fontSize: fonts.fontSize14,
                            fontWeight: fonts.fontWeight400,
                            color: colors.textSecondary,
                          }}
                        >
                          <Typography
                            sx={{
                              my: 2,
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight500,
                              color: colors.textSecondary,
                            }}
                            display="inline"
                          >
                            {result}
                          </Typography>
                          <br />
                          {locations && renderCities(result, item)}
                        </Box>
                      </Grid>
                    );
                  }
                  return true;
                })}
              </Grid>
            </Grid>
          )}

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight400,
                color: colors.textSecondary,
              }}
            >
              Question 12 of 12
            </Typography>
            <Typography
              sx={{
                mb: 2,
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight600,
                color: colors.textPrimary,
              }}
            >
              Is there anything you&apos;d like to add about where your program operates?
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                mb: 1,
                fontSize: fonts.fontSize16,
                fontWeight: fonts.fontWeight500,
                color: colors.textSecondary,
              }}
            >
              Description
            </Typography>
            <TextField
              name="additionalLocationDetails"
              placeholder="Enter details"
              fullWidth
              value={formValues.additionalLocationDetails}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
              sx={{
                marginTop: '5px',
                borderRadius: '5px',
                '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fntWeight400,
                },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImpactAndScope;
