export enum ConfirmDialogType {
  ChangeAccountSubscription,
  CancelAccountSubscription,
  UpdatePaymentMethod,
  UpdateAutopayMethod,
  DisableAutopay,
  OpenStripePortal,
  DeactivateLocale,
  ActivateLocale,
  ActivateInstance,
  DeactivateInstance,
  ResolveErrorLog,
  ResolveMultipleErrorLogs,
  UnresolveErrorLog,
  UnresolveMultipleErrorLogs,
  ActivateCauseArea,
  DeactivateCauseArea,
  CurrencySelection,
  ResendInviteSupplier,
  CancelInviteSupplier,
  PatchInviteSupplier,
  UnshareContribution,
}
