import { Box, CircularProgress, Paper } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useSubcriptionType } from '../../shared/hooks';
import { createPdfWithLinks, isOnRoute } from '../../shared/utils';
import { getContributionProposalThunk } from '../incentive';
import AboutScalewith from './AboutScalewith';
import BusinessValue from './BusinessValue';
import ProgramComponent from './ProgramComponent';
import ProgramStrength from './ProgramStrength';
import { ProposalPageHeader } from './ProposalPageHeader';
import { ReportingMetrics } from './ReportingMetrics';
import { SimpleSteps } from './SimpleSteps';
import MeasurableSocialImpact from './SocialImpact';

interface WatermarkProps {
  text: string;
}

const Watermark: React.FC<WatermarkProps> = ({ text }) => {
  const watermarkElements: JSX.Element[] = [];

  const rows = 10;
  const columns = 3;

  const topOffset = 3;

  for (let i = 0; i < rows; i += 1) {
    for (let j = 0; j < columns; j += 1) {
      const left = `${j * (100 / columns)}%`;
      const top = `${i * (100 / rows) + topOffset}%`;
      watermarkElements.push(
        <div
          key={`watermark-${i}-${j}`}
          style={{
            position: 'absolute',
            top,
            left,
            transform: 'rotate(-45deg)',
            fontFamily: 'Arial, sans-serif',
            fontSize: '4em',
            fontWeight: 'bold',
            color: 'rgba(0, 0, 0, 0.1)',
            zIndex: 9999,
            pointerEvents: 'none',
          }}
        >
          {text}
        </div>,
      );
    }
  }

  return <div>{watermarkElements}</div>;
};

export default Watermark;

const defaultIncentive = {
  program: null,
};
interface Props {
  details?: boolean;
}
export const ProposalPage: FC<Props> = ({ details }) => {
  const incentive = useAppSelector((x) => x.incentive.incentive);
  const [searchParams] = useSearchParams();
  const { isAccountTrial } = useSubcriptionType();
  const pdfDownloadName = searchParams.get('pdf_download_id') ?? 'proposalPage';

  const { program } = incentive || defaultIncentive;
  const dispatch = useAppDispatch();
  const { incentiveId } = useParams();

  useEffect(() => {
    if (!incentiveId || incentive) return;
    dispatch(getContributionProposalThunk(incentiveId));
  }, [incentiveId, dispatch]);

  const [downloading, setDownloading] = useState<boolean>(false);

  const convertComponentToPDF = async () => {
    setDownloading(true);
    createPdfWithLinks({
      pdfName: pdfDownloadName,
      elementId: 'Proposal_Page_Snapshot',
    }).then(() => {
      setDownloading(false);
    });
  };

  const watermark = isAccountTrial();

  if (!incentive) {
    return (
      <Paper
        sx={() => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
          color: 'white',
          backgroundColor: 'rgb(0, 70, 96)',
        })}
      >
        <CircularProgress size={200} />
        {/* <Typography variant="h1">Loading your Contribution information</Typography> */}
      </Paper>
    );
  }
  // if (downloading) {
  //   return (
  //     <Paper
  //       sx={{
  //         display: 'flex',
  //         flexDirection: 'column',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         width: '100%',
  //         height: '100vh',
  //         color: 'white',
  //         backgroundColor: '#005778',
  //       }}
  //     >
  //       <CircularProgress size={200} />
  //       <Typography variant="h1">
  //         Downloading your Contribution information. This could take a second.
  //       </Typography>
  //     </Paper>
  //   );
  // }

  if (incentive && program) {
    return (
      <Box id="Proposal_Page_Snapshot" className="ProposalPage__outercontainer">
        <ProposalPageHeader
          incentive={incentive}
          program={program}
          downloadFunction={convertComponentToPDF}
          showButtons={details}
          showDownload={!isOnRoute('/app/contribution/')}
          downloading={downloading}
          watermarkText={watermark}
        />
        {watermark && <Watermark text="ScaleWith Inc." />}
        <ProgramComponent program={program} />
        <MeasurableSocialImpact program={program} />
        <ProgramStrength strengths={program?.programStrength} />
        <BusinessValue />
        <ReportingMetrics
          SASB={program.sasb}
          MSCI={program.esg}
          GRI={program.gri}
          CSRHUB={program.csrhub}
          SDGS={program.sdg}
        />
        <SimpleSteps />
        <AboutScalewith />
      </Box>
    );
  }
  return <div>No data found</div>;
};
