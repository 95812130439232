import { Box, Button, Typography } from '@mui/material';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { FlexBox, Loading, SubscriptionPaper } from '../../../shared/components';
import {
  AccountSubscription,
  ConfirmDialogType,
  PaymentMethods,
  PaymentMethodLabels,
  Dialogs,
} from '../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { Subscription } from '../../../shared/interfaces';
import { getDateString, openConfirmDialog, platform } from '../../../shared/utils';
import {
  deleteAccountSettingsSubscriptionThunk,
  patchAccountSettingsAccountSubscription,
  postSubscriptionCheckoutSessionAccountSettingsThunk,
  saveAccountSettingsThunk,
  setAccountSettingsCancelSubscription,
  setAccountSettingsChangeSubscription,
} from './accountSettingsSlice';
import { accountSubscription } from '../../../api/portal/accountRequests';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';
import { clearDialog } from '../../main-screen';

const changeSubscriptionConfirmDialogType = ConfirmDialogType.ChangeAccountSubscription;
const cancelSubscriptionConfirmDialogType = ConfirmDialogType.CancelAccountSubscription;

interface Props {
  canEdit: boolean;
  isSaveValid: (stripe?: boolean) => boolean;
  accountSettingsTabsModified: () => boolean;
  type: Dialogs;
  closeFlag?: boolean;
}

export const AccountSettingsSubscription: FC<Props> = (props) => {
  const platformName = platform();
  const generalState = useAppSelector((x) => x.accountSettings);
  const confirmDialog = useAppSelector((x) => x.confirmDialog);
  const AppState = useAppSelector((x) => x.app);
  const dispatch = useAppDispatch();
  const { canEdit, isSaveValid, accountSettingsTabsModified, type, closeFlag } = props;
  const { subscription: state } = generalState;
  const { confirmed, negated, type: confirmDialogType } = confirmDialog;
  const subscription = generalState.general.account.stripe?.subscription;
  const { cancel, changeSubscription } = state;
  const { currentPeriodEnd, newFrequencyAtPeriodEnd, cancelAt, id } = { ...subscription };
  const currentSubscription = generalState.general.account.subscriptionFrequency;
  const { paymentMethods } = generalState.general.account;
  const isSubscriptionCanceled = cancelAt && !newFrequencyAtPeriodEnd;
  const isSubscriptionDeleted = !subscription || _.isEmpty(subscription) || !id;
  const { loadingUserData, userData: userDataInstance } = AppState;
  const { instance: currentInstanceData, locale } = { ...userDataInstance };
  const { settings: settingsInstanceData } = { ...currentInstanceData };
  const { stripe: stripeInstanceData } = { ...settingsInstanceData };
  const { trialPeriodDays: trialPeriodDaysInstanceData, prices: pricesInstanceData } = {
    ...stripeInstanceData,
  };
  const { annual: annualInstanceData } = { ...pricesInstanceData };
  const { footer: footerAnnualInstanceData } = {
    ...annualInstanceData,
  };
  const subscriptionTiers = settingsInstanceData?.subscriptionTiers;
  // const { annual: annualInstanceData, monthly: monthlyInstanceData } = { ...pricesInstanceData };
  // const { amount: amountAnnualInstanceData, footer: footerAnnualInstanceData } = {
  //   ...annualInstanceData,
  // };
  // const { amount: amountMonthlyInstanceData, footer: footerMonthlyInstanceData } = {
  //   ...monthlyInstanceData,
  // };
  const subscriptionStatusDetails = generalState?.general?.account?.subscriptionStatus?.current!;
  const [selectedSubscription, setSelectedSubscription] = useState(
    subscriptionStatusDetails !== '' ? subscriptionStatusDetails : '',
  );
  const [paymentType, setPaymentType] = useState('');

  const accountId = generalState.general.account.id;

  const getLabel = (value: string) => {
    let label = '';
    switch (value) {
      case PaymentMethods.Stripe:
        label = PaymentMethodLabels.Stripe;
        break;
      default:
        label = PaymentMethodLabels.Invoice;
    }
    return label;
  };

  useEffect(() => {
    if (closeFlag) {
      dispatch(clearDialog(type));
    }
  }, [closeFlag, dispatch, type]);

  const logo = currentInstanceData?.logo;
  const capitalizedLogo =
    logo && logo.length > 0 ? logo.charAt(0).toUpperCase() + logo.slice(1) : '';

  const ACCOUNT_SUBSCRIPTION_INFORMATION: Record<string, Subscription> = {
    [AccountSubscription.Pro]: {
      id: AccountSubscription.Pro,
      title: `${capitalizedLogo} Pro`,
      text: `One annual payment to maintain access to your ${
        platformName[0].toUpperCase() + platformName.slice(1)
      }with account.`,
      // price: amountAnnualInstanceData || 35000,
      price: 35000,
      period: 'Annually',
      bullets: ['Cancellation anytime'],
      savings: 1000,
      savingFooter: footerAnnualInstanceData!,
      trialPeriodDays: trialPeriodDaysInstanceData!,
      currencySymbol: locale?.settings.symbol,
    },
    [AccountSubscription.Select]: {
      id: AccountSubscription.Select,
      title: `${capitalizedLogo} Select`,
      text: `One annual payment to maintain access to your ${
        platformName[0].toUpperCase() + platformName.slice(1)
      }with account.`,
      // price: amountAnnualInstanceData || 50000,
      price: 50000,
      period: 'Annually',
      bullets: ['Cancellation anytime'],
      savings: 1000,
      savingFooter: footerAnnualInstanceData!,
      trialPeriodDays: trialPeriodDaysInstanceData!,
      currencySymbol: locale?.settings.symbol,
    },
    [AccountSubscription.Enterprise]: {
      id: AccountSubscription.Enterprise,
      title: `${capitalizedLogo} Enterprise`,
      text: `One annual payment to maintain access to your ${
        platformName[0].toUpperCase() + platformName.slice(1)
      }with account.`,
      // price: amountAnnualInstanceData || 170000,
      price: 170000,
      period: 'Annually',
      bullets: ['Cancellation anytime'],
      savings: 1000,
      savingFooter: footerAnnualInstanceData!,
      trialPeriodDays: trialPeriodDaysInstanceData!,
      currencySymbol: locale?.settings.symbol,
    },
    // [AccountSubscription.Annual]: {
    //   id: AccountSubscription.Annual,
    //   title: 'Annual',
    //   text: `One annual payment to maintain access to your ${
    //     platformName[0].toUpperCase() + platformName.slice(1)
    //   }with account.`,
    //   price: amountAnnualInstanceData || 4999,
    //   period: 'year',
    //   bullets: ['Cancellation anytime'],
    //   savings: 1000,
    //   savingFooter: footerAnnualInstanceData!,
    //   trialPeriodDays: trialPeriodDaysInstanceData!,
    //   currencySymbol: locale?.settings.symbol,
    // },
    // [AccountSubscription.Monthly]: {
    //   id: AccountSubscription.Monthly,
    //   title: 'Monthly',
    //   text: `Regular monthly payments to maintain access to your ${
    //     platformName[0].toUpperCase() + platformName.slice(1)
    //   }with account.`,
    //   price: amountMonthlyInstanceData || 499,
    //   period: 'month',
    //   bullets: ['Cancellation anytime'],
    //   savingFooter: footerMonthlyInstanceData!,
    //   trialPeriodDays: trialPeriodDaysInstanceData!,
    //   currencySymbol: locale?.settings.symbol,
    // },
  };

  console.debug(ACCOUNT_SUBSCRIPTION_INFORMATION);
  // const isAddress = generalState?.general?.account?.company?.address === undefined;
  // console.log(isAddress);

  useEffect(() => {
    if (!negated || confirmDialogType !== changeSubscriptionConfirmDialogType) return;

    if (isSubscriptionDeleted)
      dispatch(postSubscriptionCheckoutSessionAccountSettingsThunk(changeSubscription!));
    else dispatch(patchAccountSettingsAccountSubscription());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmDialogType, negated, dispatch]);

  useEffect(() => {
    if (!confirmed || confirmDialogType !== changeSubscriptionConfirmDialogType) return;

    if (!isSaveValid(true)) return;

    dispatch(saveAccountSettingsThunk(false)).then(() => {
      if (isSubscriptionDeleted)
        dispatch(postSubscriptionCheckoutSessionAccountSettingsThunk(changeSubscription!));
      else dispatch(patchAccountSettingsAccountSubscription());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmDialogType, confirmed, dispatch]);

  useEffect(() => {
    if (!negated || confirmDialogType !== cancelSubscriptionConfirmDialogType) return;

    dispatch(deleteAccountSettingsSubscriptionThunk());
  }, [confirmDialogType, negated, dispatch]);

  useEffect(() => {
    if (!confirmed || confirmDialogType !== cancelSubscriptionConfirmDialogType) return;

    if (!isSaveValid(true)) return;

    dispatch(saveAccountSettingsThunk(false)).then(() =>
      dispatch(deleteAccountSettingsSubscriptionThunk()),
    );
  }, [confirmDialogType, confirmed, dispatch, isSaveValid]);

  const changeSubscriptionType = () => {
    if (accountSettingsTabsModified()) {
      openConfirmDialog({
        title: 'Pending Account Changes',
        text: 'Do you want to save your changes before changing your account subscription?',
        type: ConfirmDialogType.ChangeAccountSubscription,
      });
    } else if (isSubscriptionDeleted)
      dispatch(postSubscriptionCheckoutSessionAccountSettingsThunk(changeSubscription!));
    else dispatch(patchAccountSettingsAccountSubscription());
  };

  const cancelSubscription = () => {
    if (accountSettingsTabsModified()) {
      openConfirmDialog({
        title: 'Pending Account Changes',
        text: 'Do you want to save your changes before cancelling your account subscription?',
        type: ConfirmDialogType.CancelAccountSubscription,
      });
    } else dispatch(deleteAccountSettingsSubscriptionThunk());
  };

  const selectedSubscriptionType = (value: string) => {
    setSelectedSubscription(value);
  };

  const handleSelect = (i: string) => {
    setPaymentType(i);
  };

  const handlePayments = () => {
    accountSubscription(accountId!, {
      paymentMethod: paymentType,
      subscriptionTierId: selectedSubscription,
      period: 'annual',
    }).then((response: any) => {
      if (response) {
        if (response?.data?.subscriptionStatus?.paymentMethod === 'invoice') {
          const { protocol } = window.location;
          const { host } = window.location;
          const baseUrl = `${protocol}//${host}`;
          window.location.href = `${baseUrl}/app/dashboard`;
        } else {
          window.location.href = response?.data?.url;
        }
      }
    });
  };

  const mainComponent = (
    <FlexBox sx={{ flexDirection: 'column', gap: 2 }}>
      {isSubscriptionDeleted && (
        <Typography>
          {/* Please select a subscription option to resume access to your account. */}
        </Typography>
      )}
      {newFrequencyAtPeriodEnd && (
        <Typography>
          Your {newFrequencyAtPeriodEnd} subscription selection will go into effect after your
          annual subscription plan expires on {getDateString(currentPeriodEnd, true)}
        </Typography>
      )}
      <Box
      // sx={isAddress ? { backgroundColor: '#f0f0f0', padding: '16px', borderRadius: '8px' } : {}}
      >
        <FlexBox sx={{ gap: 4 }}>
          {loadingUserData ? (
            <Loading loading={loadingUserData} />
          ) : (
            subscriptionTiers?.map((x: any) => {
              const isCurrentSubscription =
                ((selectedSubscription === x.id && !newFrequencyAtPeriodEnd) ||
                  x.id === newFrequencyAtPeriodEnd) &&
                !isSubscriptionCanceled &&
                !isSubscriptionDeleted;
              return (
                <Box onClick={() => selectedSubscriptionType(x.id)}>
                  <SubscriptionPaper
                    subscription={x}
                    key={`REGISTER-ACCOUNT-SETTINGS-SUBSCRIPTION-CHOICE-${x.id}`}
                    isSelected={selectedSubscription === x.id}
                    popularText={currentSubscription === x.id ? 'Current Plan' : ''}
                    // subscriptionDetails={subscriptionStatusDetails}
                    // subscriptionId={x.id}
                    Buttons={
                      <>
                        {!isCurrentSubscription && (
                          <>
                            {/* <Button
                          variant="outlined"
                          // disabled={!canEdit}
                          disabled
                          onClick={() => dispatch(setAccountSettingsChangeSubscription(x.id))}
                        >
                          {isSubscriptionCanceled || isSubscriptionDeleted ? 'Select' : 'Change'}
                          &nbsp; Subscription
                        </Button> */}
                            {/* {currentPeriodEnd && newFrequencyAtPeriodEnd && (
                          <Typography variant="body2">
                            {newFrequencyAtPeriodEnd ? 'Ends on' : 'Renews on'}&nbsp;
                            {getDateString(currentPeriodEnd, true)}
                          </Typography>
                        )} */}
                          </>
                        )}
                        {currentSubscription === x.id && (
                          <>
                            <Button
                              variant="outlined"
                              disabled={!canEdit}
                              onClick={() => dispatch(setAccountSettingsCancelSubscription(true))}
                            >
                              Cancel Subscription
                            </Button>
                            {currentPeriodEnd &&
                              (newFrequencyAtPeriodEnd === x.id ? (
                                <Typography variant="body2">
                                  Starts on&nbsp;
                                  {getDateString(currentPeriodEnd, true)}
                                </Typography>
                              ) : (
                                <Typography variant="body2">
                                  {newFrequencyAtPeriodEnd ? 'Ends on' : 'Renews on'}&nbsp;
                                  {getDateString(currentPeriodEnd, true)}
                                </Typography>
                              ))}
                          </>
                        )}
                      </>
                    }
                  />
                </Box>
              );
            })
          )}
        </FlexBox>
        {/* {isAddress && <Typography variant="h5">
          Please complete your company billing address on the general tab before changing your
          subscription.
        </Typography>} */}
      </Box>
      {isSubscriptionCanceled && (
        <Typography variant="h5" sx={(theme) => ({ color: theme.palette.error.main })}>
          Your subscription has been cancelled. Select a new plan by&nbsp;
          <b>{getDateString(cancelAt, true)}</b>
          &nbsp;to maintain access to your account.
        </Typography>
      )}
    </FlexBox>
  );

  const cancelComponent = (
    <>
      <Typography variant="h5">Cancel Subscription?</Typography>
      <Typography>
        Are you sure you want to cancel your current subscription? You will continue to have access
        to your account until the next renewal date.
      </Typography>
      <FlexBox gap={2}>
        <Button
          variant="outlined"
          sx={{ width: 100 }}
          onClick={() => dispatch(setAccountSettingsCancelSubscription(false))}
        >
          No
        </Button>
        <Button
          variant="contained"
          sx={{ width: 100 }}
          disabled={!canEdit}
          onClick={cancelSubscription}
        >
          Yes
        </Button>
      </FlexBox>
    </>
  );

  const changeSubscriptionComponent = (
    <>
      <Typography variant="h5">Change Subscription?</Typography>
      <Typography>
        Are you sure you want to&nbsp;
        {isSubscriptionDeleted || isSubscriptionCanceled
          ? 'update your subscription to '
          : `change your current ${currentSubscription} subscription to `}
        {changeSubscription}?
      </Typography>
      <FlexBox gap={2}>
        <Button
          variant="outlined"
          sx={{ width: 100 }}
          onClick={() => dispatch(setAccountSettingsChangeSubscription(undefined))}
        >
          No
        </Button>
        <Button
          variant="contained"
          sx={{ width: 100 }}
          disabled={!canEdit}
          onClick={changeSubscriptionType}
        >
          Yes
        </Button>
      </FlexBox>
    </>
  );

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 2, mb: 1 }}>
      <Typography sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }}>Select a subscription level:</Typography>
      {!cancel && !changeSubscription && mainComponent}
      {cancel && cancelComponent}
      {changeSubscription && changeSubscriptionComponent}
      <Typography variant="h4" sx={{ marginTop: '30px' }}>
        <span style={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight700 }}>Select a payment type:</span>
        {paymentMethods &&
          paymentMethods.map((payment: any) => (
            <Button
              onClick={() => handleSelect(payment.type)}
              variant="outlined"
              size="large"
              color="inherit"
              sx={{
                marginRight: '10px',
                marginLeft: '10px',
                border: `${paymentType === payment.type ? `2px solid ${colors.primary}` : ''}`,
              }}
              disabled={!payment.enabled || subscriptionStatusDetails === selectedSubscription}
            >
              {getLabel(payment.type)}
            </Button>
          ))}
      </Typography>
      <Box sx={{ position: 'absolute', bottom: '35px', right: '50px' }}>
        <Button
          size="large"
          color="inherit"
          sx={{ fontSize: fonts.fontSize14, fontWeight: fonts.fontWeight900 }}
          onClick={() => {
            dispatch(clearDialog(type));
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedSubscription || !paymentType}
          sx={{
            marginLeft: '20px',
            fontSize: fonts.fontSize14,
            fontWeight: fonts.fontWeight900,
            backgroundColor: colors.primary,
          }}
          variant="contained"
          size="large"
          color="success"
          onClick={() => handlePayments()}
        >
          Confirm
        </Button>
        {/* <Button
          disabled={currentSubscription === selectedSubscription}
          sx={{ marginLeft: '20px' }}
          variant="contained"
          size="large"
          color="success"
        >
          Confirm
        </Button> */}
      </Box>
    </FlexBox>
  );
};
