/* eslint-disable arrow-body-style */
import React from 'react';
import { FormControlLabel, Checkbox, Grid, Radio } from '@mui/material';
import './CheckBox.css';
import colors from '../../../../assets/scss/color.module.scss';

interface Props {
  label?: string;
  name?: string;
  value?: any;
  onChange?: ((event: any) => void) | undefined;
  colWidth?: number;
  checked?: boolean;
  type?: string;
  disabled?: boolean;
}

const CheckBoxInput: React.FC<Props> = ({
  label,
  name,
  onChange,
  checked,
  value,
  colWidth,
  type,
  disabled,
}) => {
  return (
    <Grid item xs={12} sm={colWidth}>
      <FormControlLabel
        className="check_css"
        style={{
          display: 'inline-flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '95%',
          height: '100px',
          marginLeft: '5px',
          border: `1px solid ${checked ? colors.strokeClick : colors.strokeDefault}`,
          backgroundColor: `${checked ? colors.backgroundPrimary : ''}`,
          borderRadius: '20px',
          padding: '4px 0px 0px 8px',
        }}
        value={value}
        control={
          type === 'checkbox' ? (
            <Checkbox name={name} value={value} onChange={onChange} checked={checked} sx={{ color: `${checked ? colors.primary : colors.strokeDefault} !important` }}/>
          ) : (
            <Radio onChange={onChange} checked={checked} name={name} sx={{ color: `${checked ? colors.primary : colors.strokeDefault} !important` }}/>
          )
        }
        label={label}
        labelPlacement="start"
        disabled={disabled}
      />
    </Grid>
  );
};
export default CheckBoxInput;
