import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  Grid,
  Tooltip,
  Autocomplete
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { addCompletion } from './CompletionSlice';
// import { getUsersThunk, clearUsersState } from '../users/usersSlice';
import { getGridRequest } from '../../shared/utils';
import {
  fetchFundingListThunk,
} from '../funding/fundingSlice';
import { PostCompletionRequest } from '../../shared/interfaces/completion/completion';
import '../sio/Sio.scss';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface CompletionData {
  id: string;
}
const CreateCompletion = () => {
  const fundingState = useAppSelector((state) => state.fundings);
  const completionState = useAppSelector((state) => state.completion);
  const { updateStatusSuccess } = completionState;
  // const success = useAppSelector((state) => state.editUser.success);
  // const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  // const { grid, active } = usersState;
  // const { page, pageSize, sort, filter } = grid;
  const navigate = useNavigate();

  // const updatedGrid = {
  //   ...grid,
  //   pageSize: 1000,
  //   defaultSort: { field: 'roles', direction: 'asc' as const },
  //   filter: { field: 'roles', simpleValue: 'sw_si_admin' },
  // };
  // const getUsersGridRequest = () => ({
  //   ...getGridRequest(updatedGrid),
  //   accountId,
  // });
  const [formValues, setFormValues] = useState<PostCompletionRequest>({
    completionFormName: '',
    fundingFormId: '',
    notes: '',
  });

  const [fundingNames, setFundingNames] = useState(fundingState.grid.data.results);
  const [fundingValue, setFundingValue] = useState(null);


  //  const Roles = grid.data.results;
  //  const SiAdminRoles = Roles.filter((arr) => (arr.roles ? arr.roles.includes('sw_si_admin') : ''));
// TODO TASK to fetch the funding form name details
   const FundingNameOptions = fundingState.grid.data.results
//   const selectOptions = SiAdminRoles.map((option) => (
//     <MenuItem key={option.id} value={option.id}>
//       {`${option.firstName} ${option.lastName}`}
//     </MenuItem>
//   ));

  // useEffect(() => {
  //   dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sort, filter, page, pageSize, active]);

  // useEffect(() => {
  //   if (success) dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [success]);

  useEffect(() => {
    if (fundingState.grid.data.results) {
      setFundingNames(fundingState.grid.data.results);
    }
  }, [fundingState.grid.data.results.length]);

  useEffect(() => {
  
    dispatch(fetchFundingListThunk({ status: 'approved', ...getGridRequest(fundingState.grid) }));
  
  }, [fundingState.grid.sort, fundingState.grid.filter, fundingState.grid.page, fundingState.status, fundingState.grid.pageSize]);

  // useEffect(
  //   () => () => {
  //     dispatch(clearUsersState());
  //   },
  //   [dispatch],
  // );
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
      setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    if (updateStatusSuccess) toast.success('New Completion form created Successfully');
  }, [updateStatusSuccess]);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newCompletion: CompletionData = (await dispatch(addCompletion(formValues))).payload as CompletionData;
    if (newCompletion?.id ) {
      navigate(`/app/completions/${newCompletion.id}`, {
        state: {
          isCreate: true
        }
      });
    }
  };

    
  const filterWithString = (newInputvalue: string) => {
    const options = FundingNameOptions.filter((funding: any) => funding.fundingFormName.toLowerCase().indexOf(newInputvalue.toLowerCase()) >= 0);
    setFundingNames(options);
  };

  const handleFundingInput = (newValue: any) => {
    setFundingValue(newValue);
    const id = newValue?.id || '';
    setFormValues({ ...formValues, fundingFormId: id }); 
  };


  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize24,
      fontWeight: fonts.fontWeight600,
      // marginLeft: '0.8em',
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      // marginLeft: '1em',
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      }
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    accordianLabel:{
      fontSize: fonts.fontSize20,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
          fontSize: fonts.fontSize14,
          fontWeight: fonts.fontWeight400,
      }
  }
  }

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto', p: 2, borderRadius: '20px' }}>
      <Typography sx = {commonTextStyle.heading} gutterBottom>
        Add New Completion
      </Typography>
      <Box boxShadow={3} sx={{ borderRadius: '20px', bgcolor: 'white' }}>
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            margin: 0,
            border: '1px solid #f1f3f5',
            borderRadius: '20px',
            p: 3,
          }}
        >
          <Grid item xs={12}>
            <Typography sx = {commonTextStyle.heading} gutterBottom>
              Overview
            </Typography>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{
              width: '100%',
              margin: 0,
              alignItems: 'flex-end',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid item xs={12} sm={3} sx={{ marginBottom: '1rem' }}>
              <Typography sx = {commonTextStyle.labelStyle}>Completion Form Name <Tooltip title="Enter Completion Form Name"><InfoIcon sx={{fontSize:'15px',color:'#868e96'}}/></Tooltip></Typography>
              <TextField
                name="completionFormName"
                placeholder="Enter The Completion Form Name"
                fullWidth
                value={formValues.completionFormName}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: fonts.fontSize14, fontWeight: 400
                    },
                  },
                }}
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={3} sx={{ marginBottom: '1rem' }}>
              <Typography sx = {commonTextStyle.labelStyle}> Select Funding Name <Tooltip title="Select Funding Name"><InfoIcon sx={{fontSize:'15px',color:'#868e96'}}/></Tooltip></Typography>
              <FormControl fullWidth margin="normal">
                <Autocomplete
                    clearOnBlur
                    value={fundingValue}
                    options={fundingNames}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        height: '42px',
                        padding: 0,
                        fontSize: fonts.fontSize14, fontWeight: 400
                      },
                      "& input::placeholder": {
                        // fontSize: '1rem',
                        fontSize: fonts.fontSize14, fontWeight: 400
                      }
                    }}
                    onChange={(e, newValue) => {
                      handleFundingInput(newValue);
                    }}
                    onInputChange={(event: any, newInputvalue) => {
                      filterWithString(newInputvalue);
                    }}
                    getOptionLabel={(option: any) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      // Regular option
                      return option?.fundingFormName;
                    }}
                    renderOption={(props, option) => <li {...props} key={option?.id}>{option?.fundingFormName}</li>}
                    renderInput={(params) => (
                      <TextField
                        placeholder="Search Funding Name"
                        variant="outlined"
                        {...params}
                      />
                    )}
                  />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} sx={{ marginBottom: '1rem' }}>
            <Typography sx = {commonTextStyle.labelStyle}>Notes <Tooltip title="Notes"><InfoIcon sx={{fontSize:'15px',color:'#868e96'}}/></Tooltip></Typography>
              <TextField
                name="notes"
                placeholder="Enter additional notes"
                fullWidth
                value={formValues.notes}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: fonts.fontSize14, fontWeight: 400
                    },
                  },
                }}
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={1} sx={{ marginBottom: '2rem' }}>
              <Button
                sx = {{...commonTextStyle.buttonStyle,backgroundColor: colors.primary, color: colors.secondary, marginLeft: 'auto'}}
                variant="contained"
                color="primary"
                disabled={
                  !formValues.completionFormName ||
                  !formValues.fundingFormId
                }
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box boxShadow={3} sx={{ borderRadius: '20px', height: '500px', bgcolor: 'white'  }}>
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            margin: 0,
            borderRadius: '20px',
            p: 3,
            marginTop: '2rem',
            maxHeight: '900px',
          }}
        >
          <Grid item xs={12} sx={{ marginBottom: '2rem' }}>
            <Typography sx = {commonTextStyle.heading}>Form Preview</Typography>
          </Grid>
          <Grid item xs={12} sx={{textAlign:'center', mt:3}}>
            <NoteAltIcon sx={{fontSize:'100px'}} color='primary' />
            <Typography sx={{...commonTextStyle.questionStyle,my: 3}}>Please enter above details to get a preview of Completion form.</Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CreateCompletion;
// function fetchFundings(arg0: { offset: number; count: number; orderBy: string; descending: boolean; status: string; }): any {
//   throw new Error('Function not implemented.');
// }

