import { AccountSubscription } from '../../shared/enums';
import {
  SetupCheckoutSession,
  StripeCheckoutResponse,
  // ContributionCheckoutSession,
  SubscriptionCheckoutSession,
} from '../../shared/interfaces';
import { cancelToken, httpSecuredCommon } from '../httpCommon';

const base = '/stripe';

export const STRIPE_API_ROUTES = {
  postSetupCheckoutSession: `${base}/setup-checkout-session`,
  postSubscriptionCheckoutSession: `${base}/subscription-checkout-session`,
  postCustomerPortalSession: `${base}/customer-portal-session`,
  patchStripeSubscription: `${base}/subscription`,
  deleteAutopay: `${base}/autopay`,
};

export const postSetupCheckoutSession = async (data: SetupCheckoutSession) => {
  const response = await httpSecuredCommon.post<StripeCheckoutResponse>(
    STRIPE_API_ROUTES.postSetupCheckoutSession,
    data,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const postSubscriptionCheckoutSession = async (data: SubscriptionCheckoutSession) => {
  const response = await httpSecuredCommon.post<StripeCheckoutResponse>(
    STRIPE_API_ROUTES.postSubscriptionCheckoutSession,
    data,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const postCustomerPortalSession = async (accountId: string) => {
  const response = await httpSecuredCommon.post<StripeCheckoutResponse>(
    STRIPE_API_ROUTES.postCustomerPortalSession,
    { accountId },
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

// export const postContributionCheckoutSession = async (request: ContributionCheckoutSession) => {
//   const response = await httpSecuredCommon.post<StripeCheckoutResponse>(
//     `/stripe/impact-orders-setup-checkout-session`,
//     request,
//     {
//       cancelToken: cancelToken.token,
//     },
//   );
//   return response;
// };

export const patchStripeSubscription = async (value: {
  accountId: string;
  subscriptionFrequency: AccountSubscription;
}) => {
  const response = await httpSecuredCommon.patch(STRIPE_API_ROUTES.patchStripeSubscription, value, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const deleteAutopay = async (accountId: string) => {
  const response = await httpSecuredCommon.delete(STRIPE_API_ROUTES.deleteAutopay, {
    cancelToken: cancelToken.token,
    data: { accountId },
  });

  return response;
};

export const deleteSubscription = async (accountId: string) => {
  const response = await httpSecuredCommon.delete('/stripe/subscription', {
    cancelToken: cancelToken.token,
    data: { accountId },
  });

  return response;
};
