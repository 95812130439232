import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from '@mui/material';
import React, { FC, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SelectAmountFormData } from '../../../shared/interfaces';
import { AmountForm } from '../select-amount/AmountForm';
import { useInstance } from '../../../shared/hooks';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface Props {
  formData: SelectAmountFormData;
  onFormChange: (value: SelectAmountFormData) => void;
  isSalesOpportunityExpanded?: (value: any) => void;
}

export const ContributionDetails: FC<Props> = ({ formData, onFormChange, isSalesOpportunityExpanded }) => {
  const [expanded, setExpanded] = useState<string | false>('panel');
  const { currencySymbol } = useInstance();
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    if (isSalesOpportunityExpanded) {
      isSalesOpportunityExpanded(isExpanded);
    }
  };
  const onChangeAccordian = (value: any) => {
    setExpanded(value);
    if (isSalesOpportunityExpanded) {
      isSalesOpportunityExpanded(value);
    }
  };

  return (
    <Paper
      sx={{
        borderRadius: '15px !important',
        border: `1px solid ${expanded ? colors.primary : colors.statusSuccess}`
      }}
      className="Contribution__Detail__container"
    >
      <Accordion expanded={expanded === 'panel'} onChange={handleChange('panel')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box
            sx={{
              display: 'flex !important',
              justifyContent: 'space-between !important',
              width: '100%',
              alignItems: 'center',
              fontFamily: 'Inter, sans-serif',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {expanded === false && formData?.grandTotal !== 0 && (
                <CheckCircleIcon sx={{ color: '#2E933C', marginRight: '5px' }} />
              )}
              <Typography
                sx={{
                  color: colors.textPrimary,
                  fontSize: fonts.fontSize20,
                  fontWeight: fonts.fontWeight700,
                }}
              >
                {(expanded || !expanded && formData?.grandTotal === 0) && (
                  <span
                    style={{
                      backgroundColor: colors.primary,
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight700,
                      color: colors.secondary,
                      marginRight: '10px',
                      width: '24px',
                      height: '24px',
                      padding: '0px 6px',
                      borderRadius: '30px',
                    }}
                  >
                    1
                  </span>
                )}
                Sales Opportunity Information
              </Typography>
            </Box>
            {expanded === false ? (
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ marginRight: '30px' }}>
                  <span
                    style={{
                      color: colors.textPrimary,
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight700,
                    }}
                  >
                    Opportunity Name:
                  </span>{' '}
                  {formData.description}
                </Typography>
                <Typography>
                  <span
                    style={{
                      color: colors.textPrimary,
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight700,
                    }}
                  >
                    Buyer:
                  </span>{' '}
                  {formData.customerName}
                </Typography>
                <Typography sx={{ marginLeft: '30px', marginRight: '60px' }}>
                  <span
                    style={{
                      color: colors.textPrimary,
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight700,
                    }}
                  >
                    Total Contribution Amt:
                  </span>{' '}
                  {currencySymbol} {formData.grandTotal}
                </Typography>
              </Box>
            ) : (
              ''
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <AmountForm
            data={formData}
            onFormChange={onFormChange}
            companySplCharValidation={false}
            onChangeAccordianClose={onChangeAccordian}
          />
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
