import React, { useEffect, ChangeEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import {
  Grid,
  FormControl,
  MenuItem,
  Select,
  Button,
  Tooltip,
  TextField,
  Divider,
  Card,
  CardContent,
  Typography,
  CardHeader,
  SelectChangeEvent,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import '../sio/formFilling.scss';
import { fetchProgrambyUser, updateProgrambyUserThunk } from './ProgramSlice';
import { uploadFile } from '../../api/portal/programRequest';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#68839A',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#FFF',
  },
}));

const ProgramFinanceQuestions = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const programState = useAppSelector((state) => state.programs);
  const { program } = programState;
  const initialFormValue = {
    currency: program?.finance?.currency,
    budget: program?.finance?.budget,
    inKindContributions: program?.finance?.inKindContributions,
    cashContributions: program?.finance?.cashContributions,
    budgetFile: program?.finance?.budgetFile,
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [files, setFiles] = useState<File>();
  const [pageNumber, setPageNumber] = useState(1);
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  useEffect(() => {
    if (id && program) {
      dispatch(fetchProgrambyUser(id)).then((programData: any) => {
        setFormValues({
          currency: programData.payload?.finance?.currency,
          budget: programData.payload?.finance?.budget,
          inKindContributions: programData.payload?.finance?.inKindContributions,
          cashContributions: programData.payload?.finance?.cashContributions,
          budgetFile: programData.payload?.finance?.budgetFile,
        });
      });
    }
  }, [id]);

  const updateProgramDetails = () => {
    const payload = {
      finance: {
        currency: formValues.currency,
        budget: formValues.budget,
        inKindContributions: formValues.inKindContributions,
        cashContributions: formValues.cashContributions,
        budgetFile: formValues.budgetFile,
      },
    };
    const programPayload = payload;
    if (id) {
      return dispatch(updateProgrambyUserThunk({ program: programPayload, id }));
    }
    return true;
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setFiles(fileList[0]);
  };

  const uploadFileData = async () => {
    const formData = new FormData();
    if (!files) return;
    formData.append('file', files);
    formData.append('path', 'finance.budgetFile');
    if (id) {
      const response = await uploadFile(id, formData);
      if (response.data) {
        setFormValues({
          ...formValues,
          budgetFile: response.data.finance.budgetFile.file.name,
        });
        toast.success('Upload Successfull');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const handlePreviousbutton = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextButton = async () => {
    const response = await updateProgramDetails();
    if (response) {
      uploadFileData();
      if (pageNumber < 4) {
        setPageNumber(pageNumber + 1);
      } else {
        navigate(`/app/sio/programs/${id}`);
      }
    } else {
      toast.error('An error occurred while submitting in. Please try again.');
    }
    return null;
  };

  const handleSaveExit = async () => {
    const response = await updateProgramDetails();
    if (response) {
      navigate(`/app/sio/programs/${id}`);
    } else {
      toast.error('An error occurred while logging in. Please try again.');
    }
    return null;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target as HTMLInputElement;
    if (value !== 'Other') {
      setIsOtherSelected(false);
      setFormValues({ ...formValues, [name]: value });
    } else {
      setFormValues({ ...formValues, [name]: '' });
      setIsOtherSelected(true);
    }
  };

  const handleOtherCurrency = (event: any) => {
    setFormValues({ ...formValues, currency: event.target.value });
  };

  const currency = ['default', 'USD', 'EUR', 'GBP', 'Other'];

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    textAreaStyle: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  // *** Start Questions ***
  const question1 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 5 }} variant="h3">
          Let&apos;s get started!
        </Typography>
        <Typography sx={commonTextStyle.questionStyle}>Question 1 of 4</Typography>
        <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
          What is your total program budget?
        </Typography>
        <Typography sx={{ mb: 2 }} variant="body1">
          Please note, we are solely interested in the budget allocated for your program activities
          and not your overall organizational budget.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography sx={commonTextStyle.labelStyle}>
          Currency{' '}
          <Tooltip title="Currency">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <FormControl variant="outlined" sx={{ width: '100%' }}>
          <Select
            name="currency"
            onChange={handleSelectChange}
            value={
              isOtherSelected ||
              (formValues?.currency !== '' && !currency?.includes(formValues?.currency))
                ? 'Other'
                : formValues.currency ?? 'default'
            }
            // sx={{
            //     height: '50px',
            //     '& .MuiSelect-select': {
            //         height: '100%',
            //     },
            // }}
            sx={{
              ...commonTextStyle.textFieldStyle,
              '& .MuiSelect-select': {
                fontSize: fonts.fontSize14,
                // paddingTop: '28px',
                height: '100%',
              },
            }}
          >
            {currency.map((currencyItem: any) => (
              <MenuItem value={currencyItem === 'default' ? '' : currencyItem} key={currencyItem}>
                {currencyItem === 'default' ? 'Select Currency' : currencyItem}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {(isOtherSelected ||
        (formValues?.currency !== '' && !currency?.includes(formValues?.currency))) && (
        <Grid item xs={12} sm={4}>
          <Typography sx={commonTextStyle.labelStyle}>
            Mention Other{' '}
            <Tooltip title="Enter Other Currency">
              <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
            </Tooltip>
          </Typography>
          <TextField
            name="otherCurrency"
            placeholder="Enter Other Currency"
            type="text"
            fullWidth
            onChange={handleOtherCurrency}
            value={formValues.currency}
            margin="none"
            variant="outlined"
            sx={commonTextStyle.textFieldStyle}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={4}>
        <Typography sx={commonTextStyle.labelStyle}>
          Budget{' '}
          <Tooltip title="Enter Budget">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <TextField
          name="budget"
          placeholder="Enter your budget here"
          value={formValues.budget}
          required
          onChange={handleChange}
          variant="outlined"
          margin="none"
          InputProps={{
            sx: {
              '& .MuiInputBase-input::placeholder': {
                fontSize: '1rem',
              },
            },
          }}
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
    </Grid>
  );

  const question2 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 2 of 4</Typography>
        <Typography sx={commonTextStyle.heading}>Please upload your budget.</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          name="budgetFile"
          placeholder="Select File"
          fullWidth
          type="file"
          // value={formValues.budgetFile}
          onChange={handleUpload}
          margin="normal"
          variant="outlined"
          sx={commonTextStyle.textFieldStyle}
        />
        {program?.finance?.budgetFile?.name && (
          <Grid item xs={12}>
            <Typography sx={commonTextStyle.labelStyle}>Uploaded Files:</Typography>
            <Typography sx={{ ...commonTextStyle.questionStyle, mb: 2 }}>
              <b>File Name: </b>
              {program?.finance?.budgetFile?.name}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const question3 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 3 of 4</Typography>
        <Typography sx={commonTextStyle.heading}>
          What is the total value of cash contributions to the program?
        </Typography>
        <Typography sx={{ mb: 2 }} variant="body1">
          Examples include corporate partners, foundations, grants, and individual support for the
          specific program.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={commonTextStyle.labelStyle}>
          Cash Contributions{' '}
          <Tooltip title="Enter Cash Contributions">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <FormControl variant="outlined">
          <Select
            name="currency"
            disabled
            value={formValues.currency}
            onChange={handleSelectChange}
            sx={{
              ...commonTextStyle.textFieldStyle,
              mr: 2,
              '& .MuiSelect-select': {
                fontSize: fonts.fontSize14,
                // paddingTop: '28px',
                height: '100%',
              },
            }}
            // sx={{
            //     height: '50px',
            //     width:'100px',
            //     mr: 2,
            //     '& .MuiSelect-select': {
            //         height: '100%',
            //         paddingTop: '36px',
            //     },
            // }}
          >
            <MenuItem value={formValues?.currency}>{formValues?.currency}</MenuItem>
            {/* <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="EUR">EUR</MenuItem> */}
          </Select>
        </FormControl>
        <TextField
          name="cashContributions"
          placeholder="Enter Cash Contribution here"
          value={formValues.cashContributions}
          required
          onChange={handleChange}
          variant="outlined"
          margin="none"
          // InputProps={{
          //     sx: {
          //         '& .MuiInputBase-input::placeholder': {
          //             fontSize: '1rem',
          //         },
          //     },
          // }}
          // sx={{
          //     '& .MuiInputBase-root': {
          //         height: '50px',
          //     },
          // }}
          sx={commonTextStyle.textFieldStyle}
        />
      </Grid>
    </Grid>
  );

  const question4 = (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography sx={commonTextStyle.questionStyle}>Question 4 of 4</Typography>
        <Typography sx={commonTextStyle.heading}>
          What is the total value of in-kind contributions to the program?
        </Typography>
        <Typography sx={{ mb: 2 }} variant="body1">
          Examples include materials, labor, supplies, and space for this specific program
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ mb: 1 }} variant="subtitle1">
          In-Kind Contributions{' '}
          <Tooltip title="Enter In-Kind Contributions">
            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
          </Tooltip>
        </Typography>
        <FormControl variant="outlined">
          <Select
            name="currency"
            value={formValues.currency}
            disabled
            onChange={handleSelectChange}
            sx={{
              ...commonTextStyle.textFieldStyle,
              '& .MuiSelect-select': {
                fontSize: fonts.fontSize14,
                paddingTop: '28px',
                height: '100%',
              },
            }}
            // sx={{
            //     height: '50px',
            //     width:'100px',
            //     mr: 2,
            //     '& .MuiSelect-select': {
            //         height: '100%',
            //         paddingTop: '36px',
            //     },
            // }}
          >
            <MenuItem value={formValues?.currency}>{formValues?.currency}</MenuItem>
            {/* <MenuItem value="">Select Currency</MenuItem>
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="EUR">EUR</MenuItem> */}
          </Select>
        </FormControl>
        <TextField
          name="inKindContributions"
          placeholder="Enter In-Kind Contribution here"
          value={formValues.inKindContributions}
          required
          onChange={handleChange}
          variant="outlined"
          margin="none"
          sx={commonTextStyle.textFieldStyle}
          // InputProps={{
          //     sx: {
          //         '& .MuiInputBase-input::placeholder': {
          //             fontSize: '1rem',
          //         },
          //     },
          // }}
          // sx={{
          //     '& .MuiInputBase-root': {
          //         height: '50px',
          //     },
          // }}
        />
      </Grid>
    </Grid>
  );
  // *** end questions ***

  return (
    <section className="bgLight">
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardHeader
              sx={{
                padding: '20px 80px',
                background: colors.backgroundColorGradient,
                borderRadius: '20px 20px 0px 0px',
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize18,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Section 5
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: fonts.fontSize24,
                        fontWeight: fonts.fontWeight400,
                        color: colors.secondary,
                      }}
                    >
                      Budget
                    </Typography>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <Typography
                        sx={{
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fontWeight600,
                          color: colors.secondary,
                        }}
                      >
                        {pageNumber === 1 ? 0 : (pageNumber - 1) * 20}% complete
                      </Typography>
                    </Grid>
                    <BorderLinearProgress
                      variant="determinate"
                      value={pageNumber === 1 ? 1 : (pageNumber - 1) * 20}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent sx={{ minHeight: '70vh', padding: '40px 80px' }}>
              {pageNumber === 1 && question1}
              {pageNumber === 2 && question2}
              {pageNumber === 3 && question3}
              {pageNumber === 4 && question4}
              <Grid item xs={12} textAlign="right">
                <Divider sx={{ my: 3 }} orientation="horizontal" flexItem />
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  onClick={handleSaveExit}
                  variant="outlined"
                  size="large"
                >
                  Save and Exit
                </Button>
                <Button
                  sx={{ ...commonTextStyle.buttonStyle, mx: 2 }}
                  disabled={pageNumber === 1}
                  onClick={handlePreviousbutton}
                  variant="outlined"
                  size="large"
                >
                  Previous
                </Button>
                <Button
                  sx={{
                    ...commonTextStyle.buttonStyle,
                    mx: 2,
                    backgroundColor: colors.primary,
                    color: colors.secondary,
                  }}
                  onClick={handleNextButton}
                  variant="contained"
                  size="large"
                >
                  Next
                </Button>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default ProgramFinanceQuestions;
