import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Tabs,
  Tab,
  Modal,
  Box,
  Tooltip,
  TextField,
  // FormControlLabel,
  Checkbox,
} from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'; // Typography, Button
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { postFundingbyUserThunk, updateFundingbyUserThunk } from './fundingSlice';
import { useAppSelector, useAppDispatch } from '../../shared/hooks';
import ProgramFundingInformation from './ProgramFundingInformation';
import GeneralInformation from './GeneralInformation';
import '../sio/formFilling.scss';
import ImpactAndScope from './ImpactAndScope';
import StrategiesAndApproaches from './StrategiesAndApproaches';
import ResearchAndEvaluation from './ResearchAndEvaluation';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';
import {
  mainHeadingStyles,
  subHeadingStyles,
  questionStyles,
  optionSubStyles,
  selectInputFieldStyles,
  // hintstyles,
} from '../funding/FundingStyles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#68839A',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#FFF',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      light: colors.primary,
      main: colors.primary,
    },
  },
});

interface FundingData {
  funding: {
    programDate: {
      start: string;
      end: string;
    };
    location: {
      value: string;
    };
    itemization: {
      value: [
        {
          description: string;
          quantity: string;
        },
      ];
    };
  };
}

interface GeneralData {
  general: {
    contact: {
      name: string;
      email: string;
      phone: string;
    };
    programName: {
      internalProgramName: string;
      platformProgramName: string;
    };
    programDescription: {
      description: string;
    };
  };
}

interface ImpactData {
  causes: {}[];
  primaryImpact: {}[];
  secondaryImpact: {}[];
  targetGroup: {}[];
  audienceAge: {}[];
  regions: string[];
  countries: string[];
  cities: string[];
  states: string[];
  additionalLocationDetails: string;
  locationExplanation: {
    regions: '';
    countries: '';
    states: '';
    cities: '';
  };
}

interface StrategiesData {
  strategies: {}[];
  activities: {
    value: string;
  };
  activitiesFrequency: string;
  approachDuration: string;
  outputs: any;
  outcomes: any;
  dataMeasurementType: string;
  nonprofitPartners: boolean;
  nonprofitPartnersDescription: string;
}

interface ResearchData {
  researchFile: { name: string };
  evidenceDescription: string[];
  evidenceOptional: string;
  studyDescription: string[];
  studyOptional: string;
  dataDescription: string[];
  dataOptional: string;
  outcomeDescription: string[];
  strength: string;
  environmentalOutputValues: any;
  researchApproach: string[];
  environmentalOutputs: string[];
}

const outputForm = [
  { quantity: 0, description: '' },
  { quantity: 0, description: '' },
  { quantity: 0, description: '' },
];
const lifetimeOutputForm = [{ quantity: 0, description: '' }];

const successStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '45vw',
  bgcolor: '#FFFFFF',
  border: '1px solid #E6E6E6',
  boxShadow: 24,
  borderRadius: 5,
};

const FundingFormFillingPage = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const fundingState = useAppSelector((state) => state.funding);
  const { funding } = fundingState;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [accept, setAccept] = useState(true);

  const initialFormValue = {
    funding: {
      programDate: {
        start: funding.funding?.programDate?.start,
        end: funding.funding?.programDate?.start,
      },
      location: {
        value: funding.funding?.location?.value,
      },
      itemization: {
        value: funding.funding?.itemization?.value.map((item: any) => ({
          quantity: item.quantity,
          description: item.description,
        })),
      },
    },
    general: {
      contact: {
        name: funding.general?.contact?.name,
        email: funding.general?.contact?.email,
        phone: funding.general?.contact?.phone,
      },
      programName: {
        internalProgramName: funding.general?.programName?.internalProgramName,
        platformProgramName: funding.general?.programName?.platformProgramName,
      },
      programDescription: {
        description: funding.general?.programDescription?.description,
      },
    },
    impactAndScope: {
      causes: funding.impactAndScope.causes,
      primaryImpact:
        typeof funding.impactAndScope.primaryImpact === 'string'
          ? [funding.impactAndScope.primaryImpact]
          : funding.impactAndScope.primaryImpact,
      secondaryImpacts: funding.impactAndScope.secondaryImpacts,
      targetGroup: funding.impactAndScope.targetGroup,
      audienceAge: funding.impactAndScope.audienceAge,
      locationDetails: {
        regions: funding.impactAndScope.locationDetails.regions,
        countries: funding.impactAndScope.locationDetails.countries,
        states: funding.impactAndScope.locationDetails.states,
        cities: funding.impactAndScope.locationDetails.cities,
        additionalLocationDetails: funding.impactAndScope.locationDetails.additionalLocationDetails,
        locationExplanation: {
          regions: funding?.impactAndScope?.locationDetails?.locationExplanation?.regions,
          countries: funding?.impactAndScope?.locationDetails?.locationExplanation?.countries,
          states: funding?.impactAndScope?.locationDetails?.locationExplanation?.states,
          cities: funding?.impactAndScope?.locationDetails?.locationExplanation?.cities,
        },
      },
    },
    StrategiesAndApproaches: {
      strategies: funding?.strategiesAndApproaches?.strategies ?? [],
      activities: {
        value: funding?.strategiesAndApproaches?.activities?.value,
      },
      approachDuration: funding?.strategiesAndApproaches?.approachDuration,
      activitiesFrequency: funding?.strategiesAndApproaches?.activitiesFrequency,
      outputs: outputForm,
      outcomes: outputForm,
      dataMeasurementType: funding?.strategiesAndApproaches?.dataMeasurementType,
      nonprofitPartners: funding?.strategiesAndApproaches?.nonprofitPartners,
      nonprofitPartnersDescription: funding?.strategiesAndApproaches?.nonprofitPartnersDescription,
    },

    researchAndEvaluation: {
      researchFile: funding?.researchAndEvaluation?.researchFile,
      evidenceDescription: funding?.researchAndEvaluation?.evidenceDescription?.selected ?? [],
      evidenceOptional: funding?.researchAndEvaluation?.evidenceDescription?.optional ?? '',
      studyDescription: funding?.researchAndEvaluation?.studyDescription?.selected ?? [],
      studyOptional: funding?.researchAndEvaluation?.studyDescription?.optional ?? '',
      dataDescription: funding?.researchAndEvaluation?.dataDescription?.selected ?? [],
      dataOptional: funding?.researchAndEvaluation?.dataDescription?.optional ?? '',
      outcomeDescription: funding?.researchAndEvaluation?.outcomeDescription ?? [],
      strength: funding?.researchAndEvaluation?.strength,
      environmentalOutputValues: lifetimeOutputForm,
      environmentalOutputs: funding?.researchAndEvaluation?.environmentalOutputs?.selected ?? [],
      researchApproach: funding?.researchAndEvaluation?.researchApproach ?? [],
    },
  };

  const [formValues, setFormValues] = useState(initialFormValue);
  const [quantityErr, setQuantityErr] = useState(false);

  useEffect(() => {
    if (
      formValues?.StrategiesAndApproaches?.nonprofitPartners &&
      formValues?.StrategiesAndApproaches?.nonprofitPartnersDescription?.trim() === ''
    ) {
      toast.warning('Please Fill SIO Partners Description');
    }
  }, [formValues.StrategiesAndApproaches.nonprofitPartners]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = scrollContainerRef.current;
      if (scrollContainer) {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;

        const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;

        const components = document.getElementsByClassName('scroll-component');
        let cumulativeHeight = 0;
        let activeComponent = 0;

        for (let i = 0; i <= components.length; i += 1) {
          const { clientHeight: componentHeight } = components[i];
          cumulativeHeight += componentHeight;

          if (scrollPercentage < (cumulativeHeight / scrollHeight) * 100) {
            activeComponent = i;
            break;
          }
        }

        setValue(activeComponent);
      }
    };

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
    return () => {};
  }, []);

  // useEffect(() => {
  //   const scrollContainer = scrollContainerRef.current;
  //   const components = document.getElementsByClassName('scroll-component');

  //   if (scrollContainer && components.length > value) {
  //     const topOffset = Array.from(components)
  //       .slice(0, value)
  //       .reduce((offset, component) => offset + component.clientHeight, 0);

  //     scrollContainer.scrollTop = topOffset;
  //   }
  // }, [value]);

  // useEffect(() => {
  //   if (id && funding) {
  //     dispatch(fetchFundingbyUser(id)).then((fundingData: any) => {
  //       setFormValues({
  //         funding: {
  //           programDate: {
  //             start: formValues.programDate.start,
  //             end: formValues.programDate.end,
  //           },
  //           location: {
  //             value: formValues.location.value,
  //           },
  //           itemization: {
  //             value: [
  //               {
  //                 quantity: formValues.itemization.value[0].quantity,
  //                 description: formValues.value[0].description,
  //               },
  //             ]
  //           },
  //         },
  //       });
  //     });
  //   }
  // }, [id]);

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getDataFromFunding = (fundingData: FundingData) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      funding: {
        programDate: {
          start: fundingData.funding.programDate.start,
          end: fundingData.funding.programDate.end,
        },
        location: {
          value: fundingData.funding.location.value,
        },
        itemization: {
          value: fundingData.funding.itemization.value.map((item: any) => ({
            quantity: item.quantity,
            description: item.description,
          })),
        },
      },
    }));
  };

  const getQuantityChange = (x: any) => {
    setQuantityErr(x);
  };

  const getDataFromGeneral = (generalData: GeneralData) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      general: {
        contact: {
          name: generalData.general.contact.name,
          email: generalData.general.contact.email,
          phone: generalData.general.contact.phone,
        },
        programName: {
          internalProgramName: generalData.general.programName.internalProgramName,
          platformProgramName: generalData.general.programName.platformProgramName,
        },
        programDescription: {
          description: generalData.general.programDescription.description,
        },
      },
    }));
  };

  const getDataFromImpact = (impactData: ImpactData) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      impactAndScope: {
        causes: impactData.causes,
        primaryImpact:
          typeof impactData.primaryImpact === 'string'
            ? [impactData.primaryImpact]
            : impactData.primaryImpact,
        secondaryImpacts: impactData.secondaryImpact,
        targetGroup: impactData.targetGroup,
        audienceAge: impactData.audienceAge,
        locationDetails: {
          regions: impactData.regions,
          countries: impactData.countries,
          states: impactData.states,
          cities: impactData.cities,
          additionalLocationDetails: impactData.additionalLocationDetails,
          locationExplanation: {
            regions: impactData?.locationExplanation?.regions ?? '',
            countries: impactData?.locationExplanation?.countries ?? '',
            states: impactData?.locationExplanation?.states ?? '',
            cities: impactData?.locationExplanation?.cities ?? '',
          },
        },
      },
    }));
    console.log(impactData);
  };

  const getDataFromStrategies = (strategiesData: StrategiesData) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      StrategiesAndApproaches: {
        strategies: strategiesData.strategies,
        activities: strategiesData.activities,
        activitiesFrequency: strategiesData.activitiesFrequency,
        approachDuration: strategiesData.approachDuration,
        outputs: strategiesData.outputs,
        outcomes: strategiesData.outcomes,
        dataMeasurementType: strategiesData.dataMeasurementType,
        nonprofitPartners: strategiesData.nonprofitPartners,
        nonprofitPartnersDescription: strategiesData?.nonprofitPartnersDescription ?? '',
      },
    }));
  };

  const getDataFromResearch = (researchData: ResearchData) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      researchAndEvaluation: {
        researchFile: researchData.researchFile,
        evidenceDescription: researchData.evidenceDescription || [],
        evidenceOptional: researchData.evidenceOptional || '',
        studyDescription: researchData.studyDescription || [],
        studyOptional: researchData.studyOptional || '',
        dataDescription: researchData.dataDescription || [],
        dataOptional: researchData.dataOptional || '',
        outcomeDescription: researchData.outcomeDescription || [],
        strength: researchData.strength,
        environmentalOutputValues: researchData.environmentalOutputValues || lifetimeOutputForm,
        researchApproach: researchData.researchApproach || [],
        environmentalOutputs: researchData.environmentalOutputs || [],
      },
    }));
  };

  const updateForm = () => {
    const newResearch = formValues.researchAndEvaluation.researchApproach?.map((item: any) => {
      if (typeof item === 'string') {
        return { _id: item };
      }
      return item;
    });
    const payload = {
      funding: {
        programDate: {
          start: formValues.funding.programDate.start,
          end: formValues.funding.programDate.end,
        },
        location: {
          value: formValues.funding.location.value,
        },
        itemization: {
          value: formValues.funding.itemization.value.map((item) => ({
            quantity: parseFloat(item.quantity),
            description: item.description,
          })),
        },
      },
      general: {
        contact: {
          name: formValues.general.contact.name,
          email: formValues.general.contact.email,
          phone: formValues.general.contact.phone,
        },
        programName: {
          internalProgramName: formValues.general.programName.internalProgramName,
          platformProgramName: formValues.general.programName.platformProgramName,
        },
        programDescription: {
          description: formValues.general.programDescription.description,
        },
      },
      impactAndScope: {
        causes: formValues.impactAndScope.causes,
        primaryImpact:
          typeof formValues.impactAndScope.primaryImpact === 'string'
            ? [formValues.impactAndScope.primaryImpact]
            : formValues.impactAndScope.primaryImpact,
        secondaryImpacts: formValues.impactAndScope.secondaryImpacts,
        targetGroup: formValues.impactAndScope.targetGroup,
        audienceAge: formValues.impactAndScope.audienceAge,
        locationDetails: {
          regions: formValues.impactAndScope.locationDetails.regions,
          countries: formValues.impactAndScope.locationDetails.countries,
          states: formValues.impactAndScope.locationDetails.states,
          cities: formValues.impactAndScope.locationDetails.cities,
          additionalLocationDetails:
            formValues.impactAndScope.locationDetails.additionalLocationDetails,
          locationExplanation: {
            regions:
              formValues?.impactAndScope?.locationDetails?.locationExplanation?.regions ?? '',
            countries:
              formValues?.impactAndScope?.locationDetails?.locationExplanation?.countries ?? '',
            states: formValues?.impactAndScope?.locationDetails?.locationExplanation?.states ?? '',
            cities: formValues?.impactAndScope?.locationDetails?.locationExplanation?.cities ?? '',
          },
        },
      },
      strategiesAndApproaches: {
        strategies: formValues.StrategiesAndApproaches.strategies,
        activities: formValues.StrategiesAndApproaches.activities,
        activitiesFrequency: formValues.StrategiesAndApproaches.activitiesFrequency,
        approachDuration: formValues.StrategiesAndApproaches.approachDuration,
        outputs: formValues.StrategiesAndApproaches.outputs,
        outcomes: formValues.StrategiesAndApproaches.outcomes,
        dataMeasurementType: formValues.StrategiesAndApproaches.dataMeasurementType,
        nonprofitPartners: formValues.StrategiesAndApproaches.nonprofitPartners,
        nonprofitPartnersDescription:
          formValues?.StrategiesAndApproaches?.nonprofitPartnersDescription ?? '',
      },
      researchAndEvaluation: {
        researchFile: formValues.researchAndEvaluation.researchFile,
        evidenceDescription: {
          optional: formValues.researchAndEvaluation.evidenceOptional,
          selected: formValues.researchAndEvaluation.evidenceDescription,
        },
        studyDescription: {
          optional: formValues.researchAndEvaluation.studyOptional,
          selected: formValues.researchAndEvaluation.studyDescription,
        },
        dataDescription: {
          optional: formValues.researchAndEvaluation.dataOptional,
          selected: formValues.researchAndEvaluation.dataDescription,
        },
        outcomeDescription:
          typeof formValues.researchAndEvaluation.outcomeDescription === 'string'
            ? [formValues.researchAndEvaluation.outcomeDescription]
            : formValues.researchAndEvaluation.outcomeDescription,
        strength: formValues.researchAndEvaluation.strength,
        environmentalOutputValues: formValues.researchAndEvaluation.environmentalOutputValues,
        environmentalOutputs: {
          selected: formValues.researchAndEvaluation.environmentalOutputs,
        },
        researchApproach: newResearch,
      },
    };
    const fundingPayload = payload;
    if (id) {
      return dispatch(updateFundingbyUserThunk({ funding: fundingPayload, id }));
    }
    return true;
  };

  const handleSaveExit = async () => {
    if (quantityErr) {
      toast.warning('Itemization quantity should be less than or equals to Funding amount', {
        toastId: 'success1',
      });
      return false;
    }
    const response = await updateForm();
    if (response) {
      toast.success('Funding Saved Successfully!');
    } else {
      toast.success('Error: Unable to Save!');
    }
    return null;
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const newResearch = formValues.researchAndEvaluation.researchApproach?.map((item: any) => {
      if (typeof item === 'string') {
        return { _id: item };
      }
      return item;
    });
    const payload = {
      // effectiveDate: funding.effectiveDate,
      // fundingFormName: funding.fundingFormName,
      fundingOpportuinityName: funding.fundingOpportuinityName,
      // fundingStatus: funding.fundingStatus,
      status: 'submitted',
      // outputs: funding.outputs,
      // siAdmin: funding.siAdmin,
      review: {
        name,
        email,
      },
      funding: formValues.funding,
      general: formValues.general,
      impactAndScope: formValues.impactAndScope,
      strategiesAndApproaches: {
        strategies: formValues.StrategiesAndApproaches.strategies,
        activities: formValues.StrategiesAndApproaches.activities,
        activitiesFrequency: formValues.StrategiesAndApproaches.activitiesFrequency,
        approachDuration: formValues.StrategiesAndApproaches.approachDuration,
        outputs: formValues.StrategiesAndApproaches.outputs,
        outcomes: formValues.StrategiesAndApproaches.outcomes,
        dataMeasurementType: formValues.StrategiesAndApproaches.dataMeasurementType,
        nonprofitPartners: formValues.StrategiesAndApproaches.nonprofitPartners,
        nonprofitPartnersDescription:
          formValues?.StrategiesAndApproaches?.nonprofitPartnersDescription ?? '',
      },
      researchAndEvaluation: {
        researchFile: formValues.researchAndEvaluation.researchFile,
        evidenceDescription: {
          selected: formValues.researchAndEvaluation.evidenceDescription,
        },
        studyDescription: {
          selected: formValues.researchAndEvaluation.studyDescription,
        },
        dataDescription: {
          selected: formValues.researchAndEvaluation.dataDescription,
        },
        outcomeDescription:
          typeof formValues.researchAndEvaluation.outcomeDescription === 'string'
            ? [formValues.researchAndEvaluation.outcomeDescription]
            : formValues.researchAndEvaluation.outcomeDescription,
        strength: formValues.researchAndEvaluation.strength,
        environmentalOutputValues: lifetimeOutputForm,
        environmentalOutputs: {
          selected: formValues.researchAndEvaluation.environmentalOutputs,
        },
        researchApproach: newResearch,
      },
    };
    if (id && accept) {
      dispatch(postFundingbyUserThunk({ funding: payload, id })).then((res) => {
        if (res) toast.success('Funding submitted successfully!');
        navigate(`/app/sio/funding/${id}/success`);
      });
    }
  };

  return (
    <section className="bgLight">
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...successStyle }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                bgcolor: `#E7F2FF`,
                mb: 2,
                p: 2,
                borderRadius: '20px 20px 0 0',
              }}
            >
              <Typography sx={mainHeadingStyles}>Submit Confirmation</Typography>
            </Grid>
            <Grid item xs={12} sx={{ padding: '0 20px 20px' }}>
              <Typography sx={{ ...subHeadingStyles, mb: '10px' }}>
                Are you sure you submit Program Funding Form?
              </Typography>
              <Box sx={{ border: '1px solid #c4bebe', borderRadius: '10px', padding: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography sx={{ mb: 1, ...questionStyles }}>
                      Please enter your name and email to submit the application to ScaleWith.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ mb: 1, ...optionSubStyles }}>
                      Name
                      <Tooltip title="Enter Name">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      placeholder="Enter Name"
                      fullWidth
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={selectInputFieldStyles}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ mb: 1, ...optionSubStyles }}>
                      Email
                      <Tooltip title="Enter Email">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      placeholder="Enter Email"
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={selectInputFieldStyles}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div style={{ display: 'flex' }}>
                      {/* <FormControlLabel
                      sx={hintstyles}
                      value="bottom"
                      control={
                        <ThemeProvider theme={theme}>
                          <Checkbox checked={accept} onChange={() => setAccept(!accept)} />
                        </ThemeProvider>
                      }
                      label="I agree to the Terms And Conditions & Privacy Policy . I hereby represents and warrants that all information contained herein will be truthful and accurate as of the date of said submission."
                      labelPlacement="end"
                      /> */}
                      <ThemeProvider theme={theme}>
                        <Checkbox checked={accept} onChange={() => setAccept(!accept)} />
                      </ThemeProvider>
                      <p>
                        I agree to the{' '}
                        <a
                          href="https://github.com/EcoMedia/scalewith-api"
                          style={{ color: colors.buttonDefault, textDecoration: 'none' }}
                        >
                          Terms And Conditions{' '}
                        </a>
                        &{' '}
                        <a
                          style={{ color: colors.buttonDefault, textDecoration: 'none' }}
                          href="Privacy Policy"
                        >
                          Privacy Policy
                        </a>
                        . I hereby represents and warrants that all information contained herein
                        will be truthful and accurate as of the date of said submission.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} textAlign="right" sx={{ p: 2, borderTop: '1px solid #E6E6E6' }}>
              <Button
                onClick={handleCloseModal}
                sx={{
                  float: 'right',
                  ml: 3,
                  borderColor: colors.strokeDefault,
                  ':hover': {
                    borderColor: colors.strokeClick,
                  },
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                disabled={!name || !email || !accept}
                onClick={handleSubmit}
                sx={{
                  float: 'right',
                  ml: 3,
                  backgroundColor: colors.primary,
                  color: colors.secondary,
                  ':hover': { backgroundColor: colors.primary },
                }}
                variant="contained"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            <CardHeader
              sx={{
                padding: '20px 40px',
                // bgcolor: '#033156',
                borderRadius: '20px 20px 0px 0px',
                background: colors.backgroundColorGradient,
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      color="white"
                      style={{ fontSize: fonts.fontSize24, fontWeight: fonts.fontWeight700 }}
                    >
                      Program Funding Form
                    </Typography>
                    <Grid item xs={11} sx={{ textAlign: 'left', my: 2 }}>
                      <Typography
                        color="white"
                        style={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight400 }}
                      >
                        Please complete all unanswered Questions and be sure to make any necessary
                        updates to auto-populated information regarding this specific program
                        funding opportunity.{' '}
                      </Typography>
                    </Grid>
                    <BorderLinearProgress variant="determinate" value={funding.percentComplete} />
                  </Grid>
                </Grid>
              }
            />
            <CardContent sx={{ height: '600px' }}>
              <Grid container>
                <Grid item xs={4} spacing={1} textAlign="left" sx={{ p: 3 }}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{}}
                    TabIndicatorProps={{
                      style: { display: 'none' },
                    }}
                  >
                    <a href="#section_0" style={{ textDecoration: 'none' }}>
                      <Tab
                        icon={
                          funding?.progress?.funding?.complete ===
                          funding?.progress?.funding?.total ? (
                            <CheckCircleIcon
                              sx={{
                                position: 'absolute',
                                right: '2%',
                                top: '4%',
                                margin: '0 !important',
                                width: '20px',
                                padding: '5px',
                                color: 'green',
                                height: 'auto',
                              }}
                            />
                          ) : (
                            <AccessTimeFilledIcon
                              sx={{
                                position: 'absolute',
                                right: '2%',
                                top: '4%',
                                margin: '0 !important',
                                width: '20px',
                                padding: '5px',
                                color: '#ED9902',
                                height: 'auto',
                              }}
                            />
                          )
                        }
                        iconPosition="end"
                        label={
                          <div
                            style={{
                              display: 'block',
                              textAlign: 'left',
                              color: '#000',
                            }}
                          >
                            <Typography
                              style={{
                                alignSelf: 'flex-start',
                                marginBottom: '-4px',
                                fontSize: fonts.fontSize16,
                                fontWeight: fonts.fontWeight700,
                                color: value === 0 ? colors.primary : colors.textPrimary,
                              }}
                            >
                              Program Funding Information
                            </Typography>
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: fonts.fontSize12,
                                fontWeight: fonts.fontWeight400,
                                color: colors.textPrimary,
                              }}
                            >
                              {`${funding?.progress?.funding?.complete} of ${funding?.progress?.funding?.total} Completed`}
                            </Typography>
                          </div>
                        }
                        {...a11yProps(0)}
                        style={{
                          marginBottom: '10px',
                          borderRadius: '10px',
                          display: 'block',
                          padding: '6px 14px',
                          minHeight: 'auto',
                          width: '100%',
                          border:
                            value === 0
                              ? `1px solid ${colors.strokeClick}`
                              : `1px solid ${colors.strokeDefault}`,
                          backgroundColor:
                            value === 0 ? colors.backgroundSecondary : colors.Secondary,
                        }}
                      />
                    </a>
                    <a href="#section_1" style={{ textDecoration: 'none' }}>
                      <Tab
                        icon={
                          funding?.progress?.general?.complete ===
                          funding?.progress?.general?.total ? (
                            <CheckCircleIcon
                              sx={{
                                position: 'absolute',
                                right: '2%',
                                top: '4%',
                                margin: '0 !important',
                                width: '20px',
                                padding: '5px',
                                color: 'green',
                                height: 'auto',
                              }}
                            />
                          ) : (
                            <AccessTimeFilledIcon
                              sx={{
                                position: 'absolute',
                                right: '2%',
                                top: '4%',
                                margin: '0 !important',
                                width: '20px',
                                padding: '5px',
                                color: '#ED9902',
                                height: 'auto',
                              }}
                            />
                          )
                        }
                        iconPosition="end"
                        label={
                          <div
                            style={{
                              display: 'block',
                              textAlign: 'left',
                              color: '#000',
                            }}
                          >
                            <Typography
                              style={{
                                alignSelf: 'flex-start',
                                marginBottom: '-4px',
                                fontSize: fonts.fontSize16,
                                fontWeight: fonts.fontWeight700,
                                color: value === 1 ? colors.primary : colors.textPrimary,
                              }}
                            >
                              General Information
                            </Typography>
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: fonts.fontSize12,
                                fontWeight: fonts.fontWeight400,
                                color: colors.textPrimary,
                              }}
                            >
                              {`${funding?.progress?.general?.complete} of ${funding?.progress?.general?.total} Completed`}
                            </Typography>
                          </div>
                        }
                        {...a11yProps(1)}
                        style={{
                          marginBottom: '10px',
                          borderRadius: '10px',
                          display: 'block',
                          padding: '6px 14px',
                          minHeight: 'auto',
                          width: '100%',
                          border:
                            value === 1
                              ? `1px solid ${colors.strokeClick}`
                              : `1px solid ${colors.strokeDefault}`,
                          backgroundColor:
                            value === 1 ? colors.backgroundSecondary : colors.Secondary,
                        }}
                      />
                    </a>
                    <a href="#section_2" style={{ textDecoration: 'none' }}>
                      <Tab
                        icon={
                          funding?.progress?.impactAndScope?.complete ===
                          funding?.progress?.impactAndScope?.total ? (
                            <CheckCircleIcon
                              sx={{
                                position: 'absolute',
                                right: '2%',
                                top: '4%',
                                margin: '0 !important',
                                width: '20px',
                                padding: '5px',
                                color: 'green',
                                height: 'auto',
                              }}
                            />
                          ) : (
                            <AccessTimeFilledIcon
                              sx={{
                                position: 'absolute',
                                right: '2%',
                                top: '4%',
                                margin: '0 !important',
                                width: '20px',
                                padding: '5px',
                                color: '#ED9902',
                                height: 'auto',
                              }}
                            />
                          )
                        }
                        iconPosition="end"
                        label={
                          <div
                            style={{
                              display: 'block',
                              textAlign: 'left',
                              color: '#000',
                            }}
                          >
                            <Typography
                              variant="body1"
                              style={{
                                alignSelf: 'flex-start',
                                marginBottom: '-4px',
                                fontSize: fonts.fontSize16,
                                fontWeight: fonts.fontWeight700,
                                color: value === 2 ? colors.primary : colors.textPrimary,
                              }}
                            >
                              Impact and Scope
                            </Typography>
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: fonts.fontSize12,
                                fontWeight: fonts.fontWeight400,
                                color: colors.textPrimary,
                              }}
                            >
                              {`${funding?.progress?.impactAndScope?.complete} of ${funding?.progress?.impactAndScope?.total} Completed`}
                            </Typography>
                          </div>
                        }
                        {...a11yProps(2)}
                        style={{
                          marginBottom: '10px',
                          borderRadius: '10px',
                          display: 'block',
                          padding: '6px 14px',
                          minHeight: 'auto',
                          width: '100%',
                          border:
                            value === 2
                              ? `1px solid ${colors.strokeClick}`
                              : `1px solid ${colors.strokeDefault}`,
                          backgroundColor:
                            value === 2 ? colors.backgroundSecondary : colors.Secondary,
                        }}
                      />
                    </a>
                    <a href="#section_3" style={{ textDecoration: 'none' }}>
                      <Tab
                        icon={
                          funding?.progress?.strategiesAndApproaches?.complete ===
                          funding?.progress?.strategiesAndApproaches?.total ? (
                            <CheckCircleIcon
                              sx={{
                                position: 'absolute',
                                right: '2%',
                                top: '4%',
                                margin: '0 !important',
                                width: '20px',
                                padding: '5px',
                                color: 'green',
                                height: 'auto',
                              }}
                            />
                          ) : (
                            <AccessTimeFilledIcon
                              sx={{
                                position: 'absolute',
                                right: '2%',
                                top: '4%',
                                margin: '0 !important',
                                width: '20px',
                                padding: '5px',
                                color: '#ED9902',
                                height: 'auto',
                              }}
                            />
                          )
                        }
                        iconPosition="end"
                        label={
                          <div
                            style={{
                              display: 'block',
                              textAlign: 'left',

                              color: '#000',
                            }}
                          >
                            <Typography
                              variant="body1"
                              style={{
                                alignSelf: 'flex-start',
                                marginBottom: '-4px',
                                fontSize: fonts.fontSize16,
                                fontWeight: fonts.fontWeight700,
                                color: value === 3 ? colors.primary : colors.textPrimary,
                              }}
                            >
                              Strategies And Approaches
                            </Typography>
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: fonts.fontSize12,
                                fontWeight: fonts.fontWeight400,
                                color: colors.textPrimary,
                              }}
                            >
                              {`${funding?.progress?.strategiesAndApproaches?.complete} of ${funding?.progress?.strategiesAndApproaches?.total} Completed`}
                            </Typography>
                          </div>
                        }
                        {...a11yProps(3)}
                        style={{
                          marginBottom: '10px',
                          borderRadius: '10px',
                          display: 'block',
                          padding: '6px 14px',
                          minHeight: 'auto',
                          width: '100%',
                          border:
                            value === 3
                              ? `1px solid ${colors.strokeClick}`
                              : `1px solid ${colors.strokeDefault}`,
                          backgroundColor:
                            value === 3 ? colors.backgroundSecondary : colors.Secondary,
                        }}
                      />
                    </a>
                    <a href="#section_4" style={{ textDecoration: 'none' }}>
                      <Tab
                        icon={
                          funding?.progress?.researchAndEvaluation?.complete ===
                          funding?.progress?.researchAndEvaluation?.total ? (
                            <CheckCircleIcon
                              sx={{
                                position: 'absolute',
                                right: '2%',
                                top: '4%',
                                margin: '0 !important',
                                width: '20px',
                                padding: '5px',
                                color: 'green',
                                height: 'auto',
                              }}
                            />
                          ) : (
                            <AccessTimeFilledIcon
                              sx={{
                                position: 'absolute',
                                right: '2%',
                                top: '4%',
                                margin: '0 !important',
                                width: '20px',
                                padding: '5px',
                                color: '#ED9902',
                                height: 'auto',
                              }}
                            />
                          )
                        }
                        iconPosition="end"
                        label={
                          <div
                            style={{
                              display: 'block',
                              textAlign: 'left',

                              color: '#000',
                            }}
                          >
                            <Typography
                              variant="body1"
                              style={{
                                alignSelf: 'flex-start',
                                marginBottom: '-4px',
                                fontSize: fonts.fontSize16,
                                fontWeight: fonts.fontWeight700,
                                color: value === 4 ? colors.primary : colors.textPrimary,
                              }}
                            >
                              Research and Evaluation
                            </Typography>
                            <Typography
                              variant="caption"
                              style={{
                                fontSize: fonts.fontSize12,
                                fontWeight: fonts.fontWeight400,
                                color: colors.textPrimary,
                              }}
                            >
                              {`${funding?.progress?.researchAndEvaluation?.complete} of ${funding?.progress?.researchAndEvaluation?.total} Completed`}
                            </Typography>
                          </div>
                        }
                        {...a11yProps(4)}
                        style={{
                          marginBottom: '10px',
                          borderRadius: '10px',
                          display: 'block',
                          padding: '6px 14px',
                          minHeight: 'auto',
                          width: '100%',
                          border:
                            value === 4
                              ? `1px solid ${colors.strokeClick}`
                              : `1px solid ${colors.strokeDefault}`,
                          backgroundColor:
                            value === 4 ? colors.backgroundSecondary : colors.Secondary,
                        }}
                      />
                    </a>
                  </Tabs>
                </Grid>
                <Grid
                  item
                  xs={8}
                  spacing={2}
                  sx={{ p: 3 }}
                  style={{
                    height: '600px',
                    overflow: 'auto',
                  }}
                  ref={scrollContainerRef}
                >
                  <div id="section_0" className="scroll-component">
                    <ProgramFundingInformation
                      onDataSubmit={getDataFromFunding}
                      onQuantityChange={getQuantityChange}
                    />
                  </div>
                  <div id="section_1" className="scroll-component">
                    <GeneralInformation onDataSubmit={getDataFromGeneral} />
                  </div>
                  <div id="section_2" className="scroll-component">
                    <ImpactAndScope onDataSubmit={getDataFromImpact} />
                  </div>
                  <div id="section_3" className="scroll-component">
                    <StrategiesAndApproaches onDataSubmit={getDataFromStrategies} />
                  </div>
                  <div id="section_4" className="scroll-component">
                    <ResearchAndEvaluation onDataSubmit={getDataFromResearch} />
                  </div>
                </Grid>
              </Grid>
            </CardContent>

            <Grid
              container
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                // padding: '40px',
                padding: '10px',
                paddingX: '40px',
                borderTop: `1px solid ${colors.strokeDefault}`,
              }}
            >
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: fonts.fontSize18,
                    fontWeight: fonts.fontWeight400,
                    color: colors.textPrimary,
                  }}
                >
                  Need Help?
                </Typography>
                <Typography
                  sx={{
                    color: colors.primary,
                    fontSize: fonts.fontSize18,
                    fontWeight: fonts.fontWeight400,
                  }}
                >
                  <MailOutlineIcon
                    sx={{ fontSize: 'small', verticalAlign: 'middle', color: colors.primary }}
                  />{' '}
                  impact@scalewith.com
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  // paddingBottom: '20px',
                  // marginX: '10px',
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleSaveExit}
                  sx={{
                    color: colors.primary,
                    borderColor: colors.primary,
                    ':hover': { borderColor: colors.primary },
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight700,
                    borderRadius: '8px',
                    marginX: '8px',
                    height: '19px',
                    padding: '20px',
                  }}
                >
                  Save Progress
                </Button>
                <Button
                  disabled={funding.percentComplete !== 100 && true}
                  variant="outlined"
                  onClick={() => {
                    if (quantityErr) {
                      toast.warning(
                        'Itemization quantity should be less than or equals to Funding amount',
                        {
                          toastId: 'success1',
                        },
                      );
                    } else {
                      setOpen(true);
                    }
                  }}
                  color="primary"
                  sx={{
                    padding: '20px',
                    height: '19px',
                    border: `1px solid ${colors.strokeClick}`,
                    // backgroundColor: `${funding.percentComplete !== 100 && colors.strokeDefault}`,
                    backgroundColor: `${
                      funding.percentComplete !== 100 ? colors.buttonDisabled : colors.buttonDefault
                    }`,
                    color: `${colors.secondary} !important`,
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight700,
                    ':hover': {
                      backgroundColor: `${
                        funding.percentComplete !== 100
                          ? colors.buttonDisabled + 95
                          : colors.buttonDefault
                      }`,
                      border: `1px solid ${colors.strokeClick}`,
                    },
                  }}
                >
                  Submit Response
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default FundingFormFillingPage;
