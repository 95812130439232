import { Box, Theme, Typography } from '@mui/material';
import React, { FC } from 'react';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { ContributionStatus } from '../enums';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

const getStatusIcon = (status?: string) => {
  let icon;
  switch (true) {
    case status === ContributionStatus.Approved: {
      icon = (
        <CheckCircleIcon
          style={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center' }}
        />
      );
      break;
    }
    case status === ContributionStatus.PendingApproval: {
      icon = (
        <WatchLaterIcon
          style={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center' }}
        />
      );
      break;
    }
    case status === ContributionStatus.Draft: {
      icon = (
        <BookmarkIcon
          style={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center' }}
        />
      );
      break;
    }
    case status === ContributionStatus.Fulfilled:
    case status === ContributionStatus.Completed:
    case status === ContributionStatus.MarkAsPaid: {
      icon = (
        <CheckCircleIcon
          style={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center' }}
        />
      );
      break;
    }
    case status === ContributionStatus.FinancialHold: {
      icon = (
        <HourglassDisabledIcon
          style={{ width: '20px', height: '20px', display: 'flex', alignItems: 'center' }}
        />
      );
      break;
    }
    case status === ContributionStatus.PendingPayment: {
      icon = (
        <HourglassTopIcon
          style={{ width: '20px', height: '20px', display: 'flex', alignItems: 'center' }}
        />
      );
      break;
    }
    case status && status.startsWith(ContributionStatus.Approved): {
      icon = (
        <WarningIcon
          style={{ width: '20px', height: '20px', display: 'flex', alignItems: 'center' }}
        />
      );
      break;
    }
    case status === ContributionStatus.Abandoned: {
      icon = (
        <DoDisturbOffIcon
          style={{ width: '20px', height: '20px', display: 'flex', alignItems: 'center' }}
        />
      );
      break;
    }
    case status === ContributionStatus.Deleted: {
      icon = (
        <DeleteIcon
          style={{ width: '20px', height: '20px', display: 'flex', alignItems: 'center' }}
        />
      );
      break;
    }
    case status === ContributionStatus.Denied:
    case status === ContributionStatus.Rejected: {
      icon = (
        <CancelIcon
          style={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center' }}
        />
      );
      break;
    }
    case status === ContributionStatus.ProcessingPayment: {
      icon = (
        <HourglassTopIcon
          style={{ width: '20px', height: '20px', display: 'flex', alignItems: 'start' }}
        />
      );
      break;
    }
       
    default: {
      icon = null;
    }
  }
  return icon;
};

const getStatusStyle = (theme: Theme, status?: string) => {
  let backgroundColor = '';
  let fontWeight = '';
  let color = '';
  let border = '';

  switch (status) {
    case ContributionStatus.Approved: {
      // backgroundColor = "#DBF6DB";
      backgroundColor = colors.statusBackgroundSuccess;
      border = `1px solid ${colors.statusSuccess}`;
      fontWeight = fonts.fontWeight400;
      color = colors.statusSuccess;
      break;
    }
    case ContributionStatus.PendingApproval:
    case ContributionStatus.PendingPayment: {
      backgroundColor = colors.statusBackgroundWarning;
      border = `1px solid ${colors.statusWarning}`;
      fontWeight = fonts.fontWeight400;
      color = colors.statusWarning;
      break;
    }
    case ContributionStatus.Draft: {
      backgroundColor = colors.statusBackgroundInfo;
      border = `1px solid ${colors.statusInfo}`;
      fontWeight = fonts.fontWeight400;
      color = colors.statusInfo;
      break;
    }
    case ContributionStatus.Completed:
    case ContributionStatus.Fulfilled:
    case ContributionStatus.MarkAsPaid: {
      backgroundColor = colors.statusBackgroundSuccess;
      border = `1px solid ${colors.statusSuccess}`;
      fontWeight = fonts.fontWeight400;
      color = colors.statusSuccess;
      break;
    }
    case ContributionStatus.Abandoned:
    case ContributionStatus.Deleted:
    case ContributionStatus.Denied:
    case ContributionStatus.Rejected: {
      backgroundColor = colors.statusBackgroundAlert;
      border = `1px solid ${colors.statusAlert}`;
      fontWeight = fonts.fontWeight400;
      color = colors.statusAlert;
      break;
    }
    default: {
      backgroundColor = '#F2B84D';
      fontWeight = fonts.fontWeight400;
      color = 'inherit';
    }
  }

  return {
    fontSize: '16px',
    borderRadius: 20,
    padding: '6px 0px 6px 15px',
    width: 200,
    textAlign: 'center',
    backgroundColor,
    border,
    fontWeight,
    color,
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };
};

const statusMapping: { [key: string]: string } = {
  "draft": "Saved As Draft",
  "pending approval": "Ready for Approval",
  "approved": "Ready to Fund",
  "denied": "Denied",
  "financial hold": "Ready to Fund",
  "pending payment": "Payment Processing",
  "completed": "Payment Complete",
  "abandoned": "Abandoned",
  "deleted": "Deleted",
  "mark as paid": "Mark as Paid",
  "processing payment": "Payment Processing"
};

const statusWhitelist = [
  "approved"
]

interface Props {
  children?: React.ReactNode;
  paymentStatus?: string;
}

export const ContributionStatusCell: FC<Props> = (props) => {
  const { children, paymentStatus } = props;
  let status: string = children?.toString() as string;
  const displayText = status ? statusMapping[status] || status : '';
  let showPaymentStatus = false;

  if (statusWhitelist.includes(status)) {
    status = paymentStatus ? `${status}-${paymentStatus}` : status;
    showPaymentStatus = true;
  }
  

  return (
    <Box sx={(theme) => getStatusStyle(theme, status)}>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Box sx={{ marginRight: '5px' }}>{getStatusIcon(status)}</Box>
        <Typography noWrap>{displayText}</Typography>
      </Box>
      {showPaymentStatus && paymentStatus && (
        <Typography sx={{ fontWeight: 600, fontSize: '9px', color: 'gray', marginTop: '26px', position: 'absolute', marginLeft: '26px'}}>
          {paymentStatus}
        </Typography>
      )}
    </Box>
  );
};