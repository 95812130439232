import { Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Dialogs } from '../../enums';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { FormDialog } from '../FormDialog';
import { FlexBox } from '../StyledComponents';
import {
  clearConfirmDialog,
  setConfirmDialogConfirmed,
  setConfirmDialogNegated,
} from './confirmDialogSlice';

export const ConfirmDialog = () => {
  const state = useAppSelector((x) => x.confirmDialog);
  const dispatch = useAppDispatch();

  const { text, title, confirmed, negated, confirmText } = state;

  useEffect(
    () => () => {
      dispatch(clearConfirmDialog());
    },
    [dispatch],
  );

  return (
    <FormDialog
      title={title}
      type={Dialogs.ConfirmDialog}
      hideCancel
      closeFlag={confirmed || negated}
      Buttons={
        <FlexBox sx={{ gap: 1 }}>
          <div>
            <Button
              variant="outlined"
              sx={{ flex: 1 }}
              onClick={() => dispatch(setConfirmDialogNegated())}
            >
              No
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              sx={{ flex: 1 }}
              onClick={() => dispatch(setConfirmDialogConfirmed())}
            >
              {confirmText ?? 'Yes'}
            </Button>
          </div>
        </FlexBox>
      }
    >
      <Typography>{text}</Typography>
    </FormDialog>
  );
};
