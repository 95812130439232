export enum AccountStatus {
  New = 'new',
  Pending = 'pending',
  PendingStripe = 'pending stripe',
  PendingFinance = 'pending finance',
  Active = 'active',
  Locked = 'locked',
  Inactive = 'inactive',
  Deleted = 'deleted',
}
