import React, { useState } from 'react';
import { TextField, Button, Typography, Chip, Box } from '@mui/material';
import { AddOutlined, Close } from '@mui/icons-material';
import { emailValid, replaceStringAtIndex } from '../../../shared/utils';
import { FlexBox } from '../../../shared/components';

interface Props {
  onChange: (addresses: string[]) => void;
  emailAddresses: string[];
  onClose: () => void;
}

interface EmailProps {
  email: string;
  onEdit: (email: string, index: number) => void;
  onDelete: (index: number) => void;
  index: number;
}
const EmailElement: React.FC<EmailProps> = ({ email, onEdit, onDelete, index }) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [value, setValue] = useState(email);
  const [emailError, setEmailError] = useState<string>('');
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (['Enter', 'Tab', ','].includes(e.key)) {
      if (emailValid(value)) {
        onEdit(value, index);
      }
    }
    setEmailError('');
  };

  if (edit) {
    return (
      <TextField
        onChange={(e) => setValue(e.target.value)}
        error={Boolean(emailError)}
        helperText={emailError}
        value={value}
        onKeyDown={handleKeyDown}
      />
    );
  }
  return (
    <Chip
      sx={{ justifyContent: 'space-between' }}
      label={email}
      onDelete={() => onDelete(index)}
      onClick={() => setEdit(true)}
    />
  );
};

const BuyerEmailDropDown: React.FC<Props> = ({ onChange, emailAddresses, onClose }) => {
  const [newEmail, setNewEmail] = useState<string>('');
  const [emails, setEmails] = useState<string[]>(emailAddresses);
  const [error, setError] = useState('');

  const handleInputChange = (newValue: string) => {
    setNewEmail(newValue);
  };
  const handleEmailEdit = (newValue: string, index: number) => {
    const newEmails = replaceStringAtIndex(emails, index, newValue);
    setEmails(newEmails);
  };
  const handleDeleteEmail = (index: number) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };
  const addNew = () => {
    if (emailValid(newEmail) && !emails.includes(newEmail)) {
      setEmails([newEmail, ...emails]);
      setNewEmail('');
    } else {
      setError('Invalid Email');
    }
  };

  const handleApplyClick = () => {
    if (!emails.includes(newEmail) && emailValid(newEmail)) {
      onChange([newEmail, ...emails]);
    } else {
      onChange(emails);
    }
    onClose();
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (['Enter', 'Tab', ','].includes(e.key)) {
      addNew();
    }
    setError('');
  };

  return (
    <Box className="PopperContainer">
      <Button onClick={onClose} className="close__button">
        <Close />
      </Button>
      <Typography>Add more Buyer Email Address</Typography>
      <FlexBox className="custom_scroll_view"
        sx={{display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
          overflowY: 'auto',
        }}
      >
        {emails.map((email, i) => (
          <EmailElement
            onDelete={handleDeleteEmail}
            onEdit={handleEmailEdit}
            email={email}
            index={i}
            key={email}
          />
        ))}
      </FlexBox>
      <FlexBox>
        <TextField
          value={newEmail}
          error={Boolean(error)}
          helperText={error}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        <Button onClick={addNew}>
          <AddOutlined />
        </Button>
      </FlexBox>
      <Button variant="outlined" color="primary" onClick={handleApplyClick}>
        Apply
      </Button>
    </Box>
  );
};

export default BuyerEmailDropDown;
