import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';

const AboutScalewith: FC = () => (
  <Box className="about_scalewith">
    <img
      alt="scalewith grid"
      crossOrigin=""
      src="https://scalewith-assets.s3.amazonaws.com/scalewith_grid%402x.png"
      className="AboutScalewithImageContainer pdf-image"
      style={{ alignSelf: 'flex-start', objectFit: 'contain', width: '900px' }}
    />
    <Box className="about_scalewith__text__container">
      <Typography className="AboutScalewith__title">About ScaleWith</Typography>
      <Typography>
        ScaleWith is a SaaS technology company that empowers customers to increase revenue and drive
        growth by embedding social impact into B2B and B2G transactions.
      </Typography>
      <Typography>
        {' '}
        ScaleWith’s suite of solutions enable businesses, organizations, and public sector entities
        to embed funding for social impact enterprises and programs (e.g., ESG, DE&I, First
        Responders & Veterans, and STEAM Workforce Development) into existing sales and procurement
        transactions at no additional cost.
      </Typography>{' '}
      <Typography>
        ScaleWith users can redirect underperforming and unused customer acquisition budgets towards
        funding social impact contributions, meeting their social impact goals and commitments while
        delivering unique value to their business partners by providing access to ScaleWith’s
        proprietary network of global social impact partners.
      </Typography>
      <Typography>
        {' '}
        ScaleWith and its predecessor companies have directed more than $100M in funding and
        resources to social impact initiatives and improved the lives of more than 60M people.{' '}
      </Typography>
      <Typography>
        Visit{' '}
        <a
          href="https://ScaleWith.com/"
          target="blank"
          rel="noopener noreferrer"
          title="ScaleWith.com"
          aria-label="ScaleWith.com"
        >
          scalewith.com
        </a>{' '}
        to learn more.
      </Typography>
    </Box>
  </Box>
);

export default AboutScalewith;
