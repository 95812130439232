import React from 'react';
import { Grid } from '@mui/material';

interface Props {
  children?: any;
  submitted?: boolean;
}

const ScrollBox: React.FC<Props> = ({ children, submitted }) => (
  <Grid
    sx={{
      height: `${!submitted && '280px'}`,
      overflowY: 'scroll',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
    }}
  >
    {children}
  </Grid>
);

export default ScrollBox;
