import React, { FC, useState } from 'react';
import { Box, TextField, Chip, Typography, InputAdornment, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { FlexBox } from './StyledComponents';
import { isNullOrWhitespace } from '../utils';
import fonts from '../../assets/scss/font.module.scss';

interface Props {
  data?: Array<string>;
  onDelete?: (value: string) => void;
  addChip?: (value: string) => void;
  gap?: number;
  label?: string;
  formatValue?: (value: string) => string;
  inputProps?: any;
  required?: boolean;
}

export const TextFieldAndChips: FC<Props> = (props) => {
  const [text, setText] = useState('');
  const { data, onDelete, addChip, gap, label, formatValue, inputProps, required } = props;

  const onDeleteChip = (value: string) => {
    if (!onDelete) return;
    onDelete(value);
  };

  const addItemByEnter = (e: any) => {
    if (isNullOrWhitespace(text)) return;
    if (!addChip || e.key !== 'Enter') return;
    addChip(text);
    setText('');
  };

  const addItem = () => {
    if (isNullOrWhitespace(text)) return;
    if (!addChip) return;
    addChip(text);
    setText('');
  };

  const formData = (value: string) => {
    if (!formatValue) return value;
    return formatValue(value);
  };

  const commonTextFieldStyle = {
    '& label': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
    '& input': {
      fontSize: fonts.fontSize18,
    },
    '&::placeholder': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
  };

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 3, width: '100%' }}>
      <TextField
        label={label || 'Add Item'}
        value={text}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
        onKeyPress={addItemByEnter}
        id="outlined-end-adornment"
        required={required ?? false}
        sx={commonTextFieldStyle}
        InputProps={{
          ...inputProps,
          endAdornment: text.length > 0 && (
            <InputAdornment position="end">
              <FlexBox>
                <Button endIcon={<CheckIcon />} onClick={addItem}>
                  Press enter or click
                </Button>
              </FlexBox>
            </InputAdornment>
          ),
        }}
      />
      <Box
        sx={{
          maxHeight: 100,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: gap ?? 1,
        }}
      >
        {data?.length !== 0 ? (
          data?.map((value, index) => (
            <Chip
              sx={{ borderRadius: 1, fontSize: fonts.fontSize18 }}
              label={formData(value)}
              // eslint-disable-next-line react/no-array-index-key
              key={`text-field-and-chips-${value}-${index}`}
              onDelete={() => onDeleteChip(value)}
            />
          ))
        ) : (
          <Typography>No data</Typography>
        )}
      </Box>
    </FlexBox>
  );
};
