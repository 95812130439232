import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import BusinessValue from '../ProposalPage/BusinessValue';
import ProgramStrength from '../ProposalPage/ProgramStrength';
import { ReportingMetrics } from '../ProposalPage/ReportingMetrics';
import MeasurableSocialImpact from '../ProposalPage/SocialImpact';
import { ProgramPageHeader } from './ProgramPageHeader';
import { getProgramThunk } from './programSlice';
import SioInformationProgramPage from './SioInformationProgramPage';
import { Footer } from '../main-screen';

interface prop {
  minimumFundAmount?: number;
}

export const ProgramPage: FC<prop> = ({ minimumFundAmount }: prop) => {
  const { program } = useAppSelector((x) => x.program);
  const searchParams = new URLSearchParams(document.location.search);
  const totalGiveAmount = searchParams.get('sio_funding_amt');
  const [funding, setFunding] = useState(parseInt(totalGiveAmount!, 10));
  const dispatch = useAppDispatch();
  const { programId, id } = useParams();
  useEffect(() => {
    const previewId = programId || id;
    if (!previewId) return;
    dispatch(getProgramThunk({ id: previewId, fundingAmount: funding }));
  }, [programId, id, funding]);

  if (!program) {
    return (
      <Paper
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
          color: 'white',
          backgroundColor: theme.palette.secondary.dark,
        })}
      >
        <CircularProgress size={200} />
        <Typography variant="h1">Loading Program information</Typography>
      </Paper>
    );
  }

  if (program) {
    return (
      <>
        <Box id="Proposal_Page_Snapshot" className="ProposalPage__outercontainer">
          <ProgramPageHeader program={program} />
          <MeasurableSocialImpact />
          <ProgramStrength
            currency={program.sio?.operationalInformation?.yearlyBudgetCurrency || 'USD'}
            strengths={program?.programStrength}
            contributionAmount={funding || minimumFundAmount}
            setFunding={setFunding}
          />
          <SioInformationProgramPage program={program} />
          <BusinessValue />
          <ReportingMetrics
            SASB={program.sasb}
            MSCI={program.esg}
            GRI={program.gri}
            CSRHUB={program.csrhub}
            SDGS={program.sdg}
          />
          {/* <SimpleSteps /> */}
          {/* <AboutScalewith /> */}
        </Box>
        <Footer />
      </>
    );
  }
  return <div>No data found</div>;
};
