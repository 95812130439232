import React, { useState, FC, useEffect } from 'react';
import { Button, Checkbox, Divider, FormControlLabel, TextField, Typography } from '@mui/material';
import { FilterAlt, Visibility } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { endOfDay, startOfDay } from 'date-fns';
import { BoldTypography, CollapseComponent, FlexBox, StyledMenu } from '../../../shared/components';

import { isNullOrWhitespace } from '../../../shared/utils';
import {
  FilterPopperConf,
  FilterPopperItems,
  ProcurementMetricsFilterConf,
  ProcurementMetricsRequest,
} from '../../../shared/interfaces';

interface Props {
  values?: ProcurementMetricsFilterConf;
  applyFilter: (values: any) => void;
}

export const ProcurementMetricsFilterPopper: FC<Props> = (props) => {
  const { values, applyFilter } = props;
  const [temporalState, setTemporalState] = useState({ ...values });
  const [searchIndustries, setSearchIndustries] = useState('');
  const [searchAccount, setSearchAccount] = useState('');
  const [isAccountExpanded, setAccountExpanded] = useState(false);
  const [isIndustriesExpanded, setIndustriesExpanded] = useState(false);
  const [fromState, setFrom] = useState<Date | null>(null);
  const [toState, setTo] = useState<Date | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [filtersSelected, setFilterSelected] = useState<ProcurementMetricsRequest>({accountId:''});

  const getValuesSelected = () => {
    let valuesSelected = {};
    const filterDate = temporalState.date?.data?.filter((item) => item.checked);
    if (filterDate && filterDate?.length > 0) {
      if (
        filterDate[0].id === 'customDate' &&
        fromState?.toString() !== 'Invalid Date' &&
        toState?.toString() !== 'Invalid Date'
      ) {
        const from = !isNullOrWhitespace(fromState?.toString())
          ? startOfDay(new Date(fromState!)).toISOString()
          : null;
        const to = !isNullOrWhitespace(toState?.toString())
          ? endOfDay(new Date(toState!)).toISOString()
          : null;

        let dateValues = {};

        if (from !== null) dateValues = { lastUpdatedFrom: from };
        if (to !== null) dateValues = { ...dateValues, lastUpdatedTo: to };
        if (Object.entries(dateValues).length !== 0) {
          valuesSelected = {
            ...valuesSelected,
            ...dateValues,
          };
        }
      } else if (filterDate[0].id !== 'customDate') {
        valuesSelected = {
          ...valuesSelected,
          lastUpdatedFrom: filterDate[0].change.from,
          lastUpdatedTo: filterDate[0].change.to,
        };
      }
    }

    Object.keys(temporalState).map((item) => {
      if (item === 'date') return undefined;
      const isSelectedValue = temporalState[item as keyof typeof temporalState]?.data
        ?.filter((category) => category.checked)
        .map((category) => category.change);
      if (isSelectedValue && isSelectedValue?.length > 0) {
        const finalValues = temporalState[item as keyof typeof temporalState]?.isMultiSelect
          ? { [item]: [...isSelectedValue] }
          : { [item]: isSelectedValue[0] };
        valuesSelected = { ...valuesSelected, ...finalValues };
      }
      return isSelectedValue;
    });

    return valuesSelected;
  };

  useEffect(() => {
    const valuesSelected = getValuesSelected();
    setFilterSelected({
      ...valuesSelected,
      accountId: ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporalState, fromState, toState]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeSelected = (item: FilterPopperItems, key: keyof typeof temporalState) => {
    const isMultiSelect = temporalState[key]?.isMultiSelect;
    const data = temporalState[key as keyof typeof temporalState]?.data?.map((itemMap) => {
      const newValue = { ...itemMap };
      if (newValue.id === item.id) newValue.checked = !newValue.checked;

      if (!isMultiSelect && newValue.id !== item.id) newValue.checked = false;
      return newValue;
    });

    if (!data) return;
    const formatValues = { ...temporalState[key] };
    formatValues.data = data;

    setTemporalState((prevState) => ({
      ...prevState,
      [key as keyof typeof temporalState]: formatValues,
    }));
  };

  const filterValues = (value?: FilterPopperConf, isExpanded?: boolean, find?: string) => {
    let valuesToFilter = [...(value?.data || [])];
    if (!isNullOrWhitespace(find)) {
      valuesToFilter = valuesToFilter.filter((item) =>
        item?.label?.toLocaleLowerCase().includes(find?.toLocaleLowerCase()!),
      );
    }
    if (valuesToFilter?.length > 5 && !isExpanded) {
      valuesToFilter = valuesToFilter
        .map((item, index) => (index <= 4 ? item : {}))
        .filter((item) => Object.entries(item).length !== 0);
    }
    return valuesToFilter;
  };

  const getShowMoreLess = (
    isExpanded: boolean,
    changeState: React.Dispatch<React.SetStateAction<boolean>>,
  ) => (
    <FlexBox
      sx={(theme) => ({ gap: 1, cursor: 'pointer', color: theme.palette.primary.main })}
      onClick={() => changeState(!isExpanded)}
    >
      <Visibility />
      <Typography>{`Show ${isExpanded ? 'less' : 'more'}`}</Typography>
    </FlexBox>
  );

  const getTotalFilters = () => {
    const accounts = !filtersSelected.accounts ? 0 : filtersSelected.accounts?.length;
    const date = !filtersSelected.lastUpdatedFrom && !filtersSelected.lastUpdatedTo ? 0 : 1;
    const industries = !filtersSelected.industries ? 0 : filtersSelected.industries.length;
    const active = !filtersSelected.active ? 0 : filtersSelected.active.length;
    return accounts + date + industries + active;
  };

  const clearFilter = () => {
    setTemporalState({ ...values });
  };

  const applyOptions = () => {
    applyFilter({ ...filtersSelected });
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        startIcon={<FilterAlt />}
      >
        Filter
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <FlexBox sx={{ flexDirection: 'column', padding: 1, gap: 1 }}>
          <FlexBox
            sx={{ justifyContent: 'space-between', alignItems: 'center', padding: 1, flex: 1 }}
          >
            <Typography sx={{ flex: 1 }}>Filters ({getTotalFilters()})</Typography>
            <FlexBox
              sx={{
                justifyContent: 'space-around',
                alignItems: 'center',
                flex: 1,
              }}
            >
              <Button variant="outlined" onClick={clearFilter}>
                Clear
              </Button>
              <Button variant="contained" onClick={applyOptions}>
                Apply
              </Button>
            </FlexBox>
          </FlexBox>
          <Divider sx={{ my: 0.5 }} />
          <CollapseComponent
            title={`Date (${
              !filtersSelected.lastUpdatedFrom && !filtersSelected.lastUpdatedTo ? 0 : 1
            })`}
          >
            <FlexBox sx={{ flexDirection: 'column' }}>
              {temporalState?.date?.data?.map((item) => (
                <FlexBox key={item.id} sx={{ flexDirection: 'column' }}>
                  <FormControlLabel
                    control={<Checkbox checked={item.checked} />}
                    label={item.label}
                    onClick={() => changeSelected(item, 'date')}
                  />
                  {item.id === 'customDate' && item.checked && (
                    <FlexBox sx={{ gap: 3 }}>
                      <DatePicker
                        label="Date From"
                        value={fromState || null}
                        onChange={(newValue) => {
                          setFrom(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ width: 150 }} />}
                      />
                      <DatePicker
                        label="Date To"
                        value={toState || null}
                        onChange={(newValue) => {
                          setTo(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ width: 150 }} />}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
              ))}
            </FlexBox>
          </CollapseComponent>
          <CollapseComponent
            title={`Accounts (${!filtersSelected.accounts ? 0 : filtersSelected.accounts.length})`}
          >
            <FlexBox sx={{ flexDirection: 'column' }}>
              {temporalState.accounts?.data && temporalState.accounts?.data.length > 5 && (
                <TextField
                  label="Search Account"
                  onChange={(e) => setSearchAccount(e.target.value)}
                  value={searchAccount}
                />
              )}
              {filterValues(temporalState.accounts, isAccountExpanded, searchAccount).map(
                (item) => (
                  <FormControlLabel
                    control={<Checkbox checked={item.checked} />}
                    key={item.id}
                    label={item.label}
                    onClick={() => changeSelected(item, 'accounts')}
                  />
                ),
              )}
              {filterValues(temporalState.accounts, isAccountExpanded, searchAccount).length > 5 ||
              (temporalState?.accounts?.data?.length! > 5 && !isAccountExpanded)
                ? getShowMoreLess(isAccountExpanded, setAccountExpanded)
                : null}
            </FlexBox>
          </CollapseComponent>
          <CollapseComponent
            title={`Industries (${
              !filtersSelected.industries ? 0 : filtersSelected.industries.length
            })`}
          >
            <FlexBox sx={{ flexDirection: 'column' }}>
              {temporalState.industries?.data && temporalState.industries?.data.length > 5 && (
                <TextField
                  label="Search Industry"
                  onChange={(e) => setSearchIndustries(e.target.value)}
                  value={searchIndustries}
                />
              )}
              {filterValues(temporalState.industries, isIndustriesExpanded, searchIndustries).map(
                (item, index) => {
                  if (item.type === 'subCategory')
                    return (
                      <BoldTypography
                        // eslint-disable-next-line react/no-array-index-key
                        key={`cause-area-metrics-filter-${item.id}-${index}`}
                        sx={{ marginTop: 2 }}
                      >
                        {item.label}
                      </BoldTypography>
                    );
                  return (
                    <FormControlLabel
                      control={<Checkbox checked={item.checked} />}
                      key={item.id}
                      label={item.label}
                      onClick={() => changeSelected(item, 'industries')}
                    />
                  );
                },
              )}
              {filterValues(temporalState.industries, isIndustriesExpanded, searchIndustries)
                .length > 5 ||
              (temporalState?.industries?.data?.length! > 5 && !isIndustriesExpanded)
                ? getShowMoreLess(isIndustriesExpanded, setIndustriesExpanded)
                : null}
            </FlexBox>
          </CollapseComponent>
          <CollapseComponent
            title={`Status (${!filtersSelected.active ? 0 : filtersSelected.active.length})`}
          >
            <FlexBox sx={{ flexDirection: 'column' }}>
              {temporalState?.active?.data?.map((item) => (
                <FormControlLabel
                  control={<Checkbox checked={item.checked} />}
                  key={item.id}
                  label={item.label}
                  onClick={() => changeSelected(item, 'active')}
                />
              ))}
            </FlexBox>
          </CollapseComponent>
        </FlexBox>
      </StyledMenu>
    </div>
  );
};
