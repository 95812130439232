import React, { useEffect, useRef, useState, FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import _ from 'lodash';
import { Box, Grid } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import fonts from '../../../assets/scss/font.module.scss'
import colors from '../../../assets/scss/color.module.scss';
import { CommonGridFilter, CommonGridFilterHandle } from '../CommonGridFilter';
import { ContributionStatusCell } from '../IncentiveStatusCell';
import { AccountType, GridColumns, ContributionActions, ContributionStatus, UserRole } from '../../enums';
import {
  getGenericHeight,
  getDateString,
  isNullOrWhitespace,
  getUsernameFromEmail,
  getGridRequest,
  isAvailableShareByStatus,
} from '../../utils';
import {
  setCommonContributionsStatus,
  getCommonContributionsThunk,
  clearCommonContributionsState,
  setCommonContributionsGridModel,
  setCommonContributionsInitialState,
} from './commonIncentivesSlice';
import { useAccount, useAppDispatch, useAppSelector, useAuth, useInstance } from '../../hooks';
// import { getCauseAreasThunk } from '../../../features';
import { IsolatedGrid } from '../IsolatedGrid';
import { Account, CustomGridColDef } from '../../interfaces';
import {
  BETWEEN_FILTER_OPERATOR,
  CONTAINS_FILTER_OPERATOR,
  EQUALS_FILTER_OPERATOR,
  STARTS_WITH_OPERATOR,
  FULFILL_REJECT_PERMISSIONS,
  GIVEWITH_ROLES,
} from '../../constants';
import { LightTooltip } from '../StyledComponents';
import { MoneyBalanceWithLocaleSymbol } from '../MoneyBalanceWithLocaleSymbol';
import { useMediaQuery } from '../common/useMediaQuery';

const doesTabExist = (
  tabs: Array<{
    label: string;
    value?: string | null;
  }>,
  tab?: string | null,
) => tabs.map((t) => t.value).includes(tab);

const buildCustomColumnsList = (accountState?: Account): Array<GridColDef> => {
  const customColumns: Array<GridColDef> = [];

  const addCustomColumn = (fieldName: string, label?: string) => {
    if (isNullOrWhitespace(label)) return;

    customColumns.push({
      field: fieldName,
      headerName: label,
      flex: 1,
      filterable: true,
      filterOperators: CONTAINS_FILTER_OPERATOR,
    });
  };

  addCustomColumn('customField1', accountState?.incentiveFields?.customField1?.label);
  addCustomColumn('customField2', accountState?.incentiveFields?.customField2?.label);
  addCustomColumn('customField3', accountState?.incentiveFields?.customField3?.label);
  addCustomColumn('customField4', accountState?.incentiveFields?.customField4?.label);

  return customColumns;
};

const statusParamName = 'status';

interface Props {
  tabs: Array<{
    label: string;
    value?: string | null;
  }>;
  defaultTab: string | null;
  alternativeName: string;
  actionsColumn?: GridColDef;
  isApprovalsPage?: boolean;
  success?: boolean;
  allowSelection?: boolean;
}

export const CommonContributionsGrid: FC<Props> = (props) => {
  const appState = useAppSelector((x) => x.app);
  const commonContributionsState = useAppSelector((x) => x.commonContributions);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isUserInRole] = useAuth();

  const {
    tabs,
    defaultTab,
    actionsColumn,
    success,
    isApprovalsPage,
    allowSelection,
    alternativeName,
  } = props;

  const { status, grid, successUnshare } = commonContributionsState;
  const { loadingUserData } = useInstance();
  const { page, pageSize, sort, filter } = grid;
  const { account: accountState } = { ...appState };
  const filterRef = useRef<CommonGridFilterHandle>(null);
  const initialRenderRef = useRef<boolean>(status !== defaultTab);
  const { isAccountInType } = useAccount();
  const activeTab = doesTabExist(tabs, status) ? status : defaultTab;
  const [tableInitialized, setTableInitialized] = useState(false);
  const [currentIndexVal, setCurrentIndexVal] = useState(0);

  const isAvailableShareByRole =
    isAccountInType([AccountType.supplier]) &&
    isUserInRole([UserRole.Finance, UserRole.SuperAdmin]);

  const availableAllowSelection =
    (isAvailableShareByStatus(status as ContributionStatus) && isAvailableShareByRole) ||
    allowSelection;

  const getStatusList = () => {
    const allStatuses = Object.values(ContributionStatus);
    if (!isUserInRole(FULFILL_REJECT_PERMISSIONS)) {
      _.pull(allStatuses, ContributionStatus.Fulfilled, ContributionStatus.Rejected);
    }
    return allStatuses;
  };

  const getCommonContributionsGridRequest = () => ({
    ...getGridRequest(grid),
    accountId: accountState?.id!,
    isApprovalsPage,
  });

  useEffect(() => {
    const selectedTab = searchParams.get('shared')
      ? ContributionActions.shared
      : searchParams.get(statusParamName) ?? defaultTab;
    dispatch(
      setCommonContributionsInitialState({
        initialTabValue: selectedTab,
        selectionModel: availableAllowSelection ? [] : undefined,
      }),
    );
  }, [availableAllowSelection, defaultTab, dispatch, searchParams]);

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
    } else {
      const payloadParam = status === ContributionActions.shared ? { shared: 1 } : { status };
      dispatch(
        getCommonContributionsThunk({
          ...payloadParam,
          ...getCommonContributionsGridRequest(),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, sort, status, filter]);

  useEffect(() => {
    if (!successUnshare) return;
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
    } else {
      const payloadParam = status === ContributionActions.shared ? { shared: 1 } : { status };
      dispatch(
        getCommonContributionsThunk({
          ...payloadParam,
          ...getCommonContributionsGridRequest(),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUnshare]);

  useEffect(() => {
    if (success) {
      dispatch(
        getCommonContributionsThunk({
          status,
          ...getCommonContributionsGridRequest(),
        }),
      );
      if (grid.selectionModel && grid?.selectionModel.length > 0) {
        dispatch(setCommonContributionsGridModel({ selectionModel: [] }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(
    () => () => {
      dispatch(clearCommonContributionsState());
    },
    [dispatch],
  );

  // useEffect(() => {
  //   dispatch(getCauseAreasThunk());
  // }, [dispatch]);

  const columns: Array<CustomGridColDef> = [
    {
      field: 'giveId',
      description: 'Contribution ID',
      headerName: 'Contribution ID',
      type: 'number',
      
      flex: 0.5,
      filterable: true,
      filterOperators: STARTS_WITH_OPERATOR,
      hideable: false,
      align: 'left',
      headerAlign: 'left',
      },
    {
      field: 'customerName',
      headerName: 'Customer',
      description: 'Customer Name',
      flex: 1,
      filterable: true,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      renderCell: ({ row: { customerName } }) => (
        <div style={{ fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight400, color:colors.textPrimary }}>
          {customerName}
        </div>
      ),
    },
    {
      field: 'customerId',
      headerName: 'Customer ID',
      description: 'Customer ID',
      flex: 1,
      filterable: true,
      filterOperators: STARTS_WITH_OPERATOR,
      renderCell: ({ row: { customerId } }) => (
        <div style={{ fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight400, color:colors.textPrimary }}>
          {customerId}
        </div>
      ),
    },
    // hide Quote for now
    // {
    //   field: 'quoteNumber',
    //   headerName: 'Quote',
    //   description: 'Quote Number',
    //   flex: 0.5,
    //   filterable: true,
    //   filterOperators: STARTS_WITH_OPERATOR,
    // },
    {
      field: 'quoteAmount',
      headerName: 'TDV',
      description: 'Total Deal Value',
      flex: 1,
      filterable: true,
      type: GridColumns.String,
      customColumnType: GridColumns.MoneyWithLocaleSymbol,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      renderCell: (params) => (
        <div style={{ fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight400, color:colors.textPrimary }}>
          <MoneyBalanceWithLocaleSymbol variant="body2">{params.value}</MoneyBalanceWithLocaleSymbol>
        </div>
      ),
    },
    {
      field: 'description',
      headerName: 'Opportunity Name',
      flex: 1,
      filterable: true,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      renderCell: ({ row: { description } }) => (
        <div style={{ fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight400, color:colors.textPrimary }}>
          {description}
        </div>
      ),
    },
    {
      field: 'grandTotal',
      headerName: 'Contribution Amount',
      description: 'Contribution Amount',
      flex: 1,
      filterable: true,
      type: GridColumns.String,
      customColumnType: GridColumns.MoneyWithLocaleSymbol,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      renderCell: (params) => (
        <div style={{ fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight400, color:colors.textPrimary }}>
        <MoneyBalanceWithLocaleSymbol variant="body2">{params.value}</MoneyBalanceWithLocaleSymbol>
        </div>
      ),
    },
    // {
    //   field: 'causeArea',
    //   headerName: 'Cause Area',
    //   description: 'Cause Area',
    //   flex: 1,
    //   filterable: true,
    //   filterOperators: EQUALS_FILTER_OPERATOR,
    //   valueGetter: (params) => params.row.causeArea?.name,
    //   type: GridColumns.SingleSelect,
    //   valueOptions: Object.values(
    //     causeAreasState.causeAreas.map((cause) => ({ value: cause.id, label: cause.name })),
    //   ),
    // },
    ...buildCustomColumnsList(accountState),
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
      filterable: true,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      renderCell: (params) => (
        <div style={{ fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight400, color:colors.textPrimary }}>
          <LightTooltip title={params?.value}>
            <Box>{getUsernameFromEmail(params?.value)}</Box>
          </LightTooltip>
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      description: 'Status',
      type: GridColumns.SingleSelect,
      valueOptions: getStatusList(),
      flex: 1,
      filterOperators: EQUALS_FILTER_OPERATOR,
      filterable: status === null,
      minWidth: 230,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div style={{ fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight400, color:colors.textPrimary }}>
          <ContributionStatusCell paymentStatus={params.row.paymentStatus}>{params.value}</ContributionStatusCell>
        </div>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      filterable: true,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      type: GridColumns.Date,
      renderCell: (params) => (
        <div style={{ fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight400, color:colors.textPrimary }}>
          <LightTooltip title={getDateString(params?.value) ?? ''}>
            <Box>{getDateString(params?.value, true)}</Box>
          </LightTooltip>
        </div>
      ),
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Last Updated By',
      flex: 1,
      filterable: true,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      renderCell: (params) => (
        <div style={{ fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight400, color:colors.textPrimary }}>
          <LightTooltip title={params?.value}>
            <Box>{getUsernameFromEmail(params?.value)}</Box>
          </LightTooltip>
        </div>
      ),
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      flex: 1,
      filterable: true,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      type: GridColumns.Date,
      renderCell: (params) => (
        <div style={{ fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight400, color:colors.textPrimary }}>
          <LightTooltip title={getDateString(params?.value) ?? ''}>
            <Box>{getDateString(params?.value, true)}</Box>
          </LightTooltip>
        </div>
      ),
    },
    // {
    //   field: 'action',
    //   headerName: 'Actions',
    //   disableReorder: true,
    //   resizable: false,
    //   type: GridColumns.Actions,
    //   minWidth: 140,
    //   renderCell: ({ row: { id } }) => (
    //     <FlexBox sx={{ gap: 1 }}>
    //       <Button
    //         variant="outlined"
    //         color="info"
    //         sx={{ backgroundColor: '#fff' }}
    //         onClick={() => navigate(`/app/impact/${id}/details`)}
    //       >
    //         View Details
    //       </Button>
    //     </FlexBox>
    //   ),
    //   sortable: false,
    //   filterable: false,
    //   hideable: false,
    //   align: 'left',
    //   headerAlign: 'left',
    // },
  ];

  if (isUserInRole(GIVEWITH_ROLES)) {
    columns.unshift({
      field: 'id',
      headerName: 'ID',
      flex: 1,
      filterable: true,
      filterOperators: EQUALS_FILTER_OPERATOR,
    });
  }

  if (actionsColumn) columns.push(actionsColumn);

  const isMediumDevice = useMediaQuery(`(min-width:768px) and (max-width:1023px`);
  const isLg = useMediaQuery(`(min-width: 1024px) and (max-width:1279px`);
  const isXL = useMediaQuery(`(min-width: 1280px) and (max-width:1535px`);
  const isXXL = useMediaQuery(`(min-width: 1536px)`);

  let slides: any = 1;
  if (isMediumDevice) {
    slides = 3;
  } else if (isLg) {
    slides = 2;
  } else if (isXL||isXXL) {
    slides = 3;
  }

  const updateCurrentSlide = (val: string) => {
    const currentIndexValue = _.findIndex(tabs, {
      value: val,
    });
    setCurrentIndexVal(
      currentIndexValue >= slides
        ? currentIndexValue - (slides - 1)
        : currentIndexVal
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === ContributionActions.shared) {
      setSearchParams(newValue ? { shared: '1' } : {});
      dispatch(setCommonContributionsStatus(newValue));
      return;
    }
    setSearchParams(newValue ? { [statusParamName]: newValue } : {});
    dispatch(setCommonContributionsStatus(newValue));
    filterRef.current?.clearFilterSelections();
  };

  useEffect(() => {
    if (!tableInitialized && activeTab) {
      setTableInitialized(true);
      updateCurrentSlide(activeTab);
    }
  }, [tabs, activeTab]);

  useEffect(() => {
    setTableInitialized(false);
    setCurrentIndexVal(0);
  }, [tabs.length, activeTab]);

  
  return (
    <>
      <Grid container alignItems="end">
        <Grid item md={6} sm={12}>
          <Box my={1} sx={{ width: '100%' }}>
            <CarouselProvider
              currentSlide={currentIndexVal}
              naturalSlideHeight={25}
              naturalSlideWidth={100}
              totalSlides={tabs?.length}
              visibleSlides={slides}
              className="tabs-carousel"
              step={slides - 1}
            >
              <Slider>
                <Box sx={{ padding: '0 10px' }}>
                  {tabs?.map((tab: any, index: number) => (
                    <Slide index={index}>
                    <Box
                      key={`common-order-tab-${tab.value}`}
                      onClick={(e: any) => handleTabChange(e, tab.value)}
                      className={`tab-carousel ${tab.value === activeTab && 'activeTab'}`}
                      sx={{
                        fontSize: fonts.fontSize18,
                        fontWeight: `${tab.value === activeTab ? fonts.fontWeight700 : fonts.fontWeight600}`,
                        color: colors.textPrimary,
                      }}
                    >
                      {tab.label}
                    </Box>
                  </Slide>
                  
                  ))}
                </Box>
              </Slider>
              <ButtonBack className="tab-carousel-btn-back">
                <KeyboardArrowLeftOutlinedIcon />
              </ButtonBack>
              <ButtonNext className="tab-carousel-btn-next">
                <KeyboardArrowRightOutlinedIcon />
              </ButtonNext>
            </CarouselProvider>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box my={1} display="flex" justifyContent="flex-end">
            <CommonGridFilter
              columns={columns}
              currentFilter={filter}
              onChange={(x) => {
                dispatch(
                  setCommonContributionsGridModel({
                    filter: x,
                  }),
                );
              }}
              ref={filterRef}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '400px', height: getGenericHeight(328), marginTop: 1 }}>
        <IsolatedGrid
          status={activeTab}
          model={grid}
          columns={columns}
          onChange={(x) => dispatch(setCommonContributionsGridModel(x))}
          alternativeName={alternativeName}
          isCheckBoxSelectable={(params) =>
            (isAvailableShareByStatus(params.row.status as ContributionStatus) &&
              !params.row.sharedWith) ||
            !!allowSelection
          }
          extraLoading={loadingUserData}
        />
      </Box>
    </>
  );
};