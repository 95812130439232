import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ConfirmDialogType } from '../../enums';

interface ConfirmDialogState {
  title: string;
  text?: string;
  type?: ConfirmDialogType;
  confirmed: boolean;
  negated: boolean;
  confirmText?: string;
  value?: string;
}

const initialState: ConfirmDialogState = {
  title: '',
  text: '',
  type: undefined,
  confirmed: false,
  negated: false,
  confirmText: undefined,
  value: undefined,
};

const confirmDialogSlice = createSlice({
  name: 'mainScreen',
  initialState,
  reducers: {
    setConfirmDialog: (
      state,
      action: PayloadAction<{
        title: string;
        text?: string;
        type: ConfirmDialogType;
        confirmText?: string;
        value?: string;
      }>,
    ) => {
      const { title, text, type, confirmText, value } = action.payload;
      state.title = title;
      state.text = text;
      state.type = type;
      state.confirmText = confirmText;
      state.value = value;
    },
    clearConfirmDialog: (state) => {
      _.assign(state, initialState);
    },
    setConfirmDialogConfirmed: (state) => {
      state.confirmed = true;
    },
    setConfirmDialogNegated: (state) => {
      state.negated = true;
    },
  },
});

export const {
  setConfirmDialog,
  clearConfirmDialog,
  setConfirmDialogConfirmed,
  setConfirmDialogNegated,
} = confirmDialogSlice.actions;

export const confirmDialogReducer = confirmDialogSlice.reducer;
