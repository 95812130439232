import React, { useState, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  SelectChangeEvent,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../shared/hooks';
import { FormValues } from '../../shared/interfaces/sio/sio';
import { updateSioUserThunk } from './sioSlice';
import { uploadFile } from '../../api/portal/sioRequest';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface EditingState {
  question1: boolean;
  question2: boolean;
  question3: boolean;
  question4: boolean;
  question5: boolean;
}
const OperationalInformationReview = ({ SioData }: { SioData: FormValues }) => {
  const [isEditing, setIsEditing] = useState<EditingState>({
    question1: false,
    question2: false,
    question3: false,
    question4: false,
    question5: false,
  });
  const dispatch = useAppDispatch();
  const { id, password } = useParams();
  const [files, setFiles] = useState<File>();
  const [formValues, setFormValues] = useState(SioData);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const handleEditClick = (questionNumber: keyof EditingState) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [questionNumber]: !prevState[questionNumber],
    }));
  };

  const uploadFileData = async () => {
    const formData = new FormData();
    if (!files) return;
    formData.append('file', files);
    formData.append('path', 'operationalInformation.financialStatement.file');
    if (id && password) {
      const response = await uploadFile(id, formData);
      if (response.data) {
        setFormValues({
          ...formValues,
          financialStatement: response.data.operationalInformation.financialStatement.file.name,
        });
        toast.success('Upload Successfull');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setFiles(fileList[0]);
  };

  const handleSaveClick = async (questionNumber: keyof EditingState) => {
    const payload = {
      general: {
        name: {
          legalOrganizationName: formValues.legalName,
          publicOrganizationName: formValues.publicName,
        },
        social: {
          facebook: formValues.facebook,
          instagram: formValues.instagram,
          linkedIn: formValues.linkedIn,
          twitter: formValues.twitter,
          websiteURL: formValues.websiteUrl,
        },
        contact: {
          professionalTitle: formValues.professionalTitle,
          name: formValues.firstLastName,
          phone: formValues.professionalPhoneNo,
          email: formValues.email,
        },
        location: {
          generalLocation: formValues.registeredLocation,
          specificLocation: formValues.registeredLocation,
        },
        missionAgreement: formValues.committed,
      },
      overviewAndMission: {
        historyDescription: formValues.missionHistory,
        problemDescription: formValues.problem,
        causeAreas: {
          selected: formValues.causeAreas.selected,
        },
        lifetimeOutputs: formValues.lifetimeOutputs,
        researchAndEvaluationFile: {
          name: formValues.fileUpload,
        },
        initiativesDescription: formValues.programInitiativesPriorites,
        programLocations: formValues.programOperate,
        researchAndEvaluation: formValues.researchEvaluation,
      },
      operationalInformation: {
        staff: {
          fullTime: formValues.fullTimeStaff,
          partTime: formValues.partTimeStaff,
          volunteers: formValues.volunteers,
        },
        financialStatement: {
          file: {
            name: formValues.financialStatement,
          },
        },
        partnershipsDescription: formValues.formalInformalRelationship,
        yearlyBudget: formValues.budget,
        yearlyBudgetCurrency: formValues.yearlyBudgetCurrency,
        supportersAndPartners: formValues.supportersPartners,
      },
    };
    if (id && password) {
      const response = await dispatch(updateSioUserThunk({ sio: payload, id }));
      if (response && response.payload) {
        toast.success('Updated Successfully');
      } else {
        toast.error('Failed to Updated, Please try again');
      }
    }
    setIsEditing((prevState) => ({
      ...prevState,
      [questionNumber]: false,
    }));
    setFormValues(formValues);
  };

  const handleCancelClick = (questionNumber: keyof EditingState) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [questionNumber]: false,
    }));
    setFormValues(SioData);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target as HTMLInputElement;
    if (value !== 'Other') {
      setIsOtherSelected(false);
      setFormValues({ ...formValues, [name]: value });
    } else {
      setFormValues({ ...formValues, [name]: '' });
      setIsOtherSelected(true);
    }
  };

  const handleOtherCurrency = (event: any) => {
    setFormValues({ ...formValues, yearlyBudgetCurrency: event.target.value });
  };

  const currency = ['default', 'USD', 'EUR', 'GBP', 'Other'];

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      marginLeft: '0.8em',
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      marginLeft: '1em',
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
      marginLeft: '1em',
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      sx={{ display: 'flex', gap: '2.5rem', marginTop: '2rem', padding: '1rem' }}
      className="formContainer"
    >
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography style={commonTextStyle.questionStyle}>Question 1 of 5</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Please provide the number of paid and volunteer staff at your organization.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question1')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Full-time paid staff members <sup style={commonTextStyle.sup}>*</sup>{' '}
              <Tooltip title="Enter The Full-time paid staff members">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              name="fullTimeStaff"
              value={formValues.fullTimeStaff}
              onChange={handleInputChange}
              disabled={!isEditing.question1}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Part-time paid staff members <sup style={commonTextStyle.sup}>*</sup>
              <Tooltip title="Enter The Part-time paid staff members">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              name="partTimeStaff"
              value={formValues.partTimeStaff}
              onChange={handleInputChange}
              disabled={!isEditing.question1}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Volunteers <sup style={commonTextStyle.sup}>*</sup>
              <Tooltip title="Enter The Volunteers">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              name="volunteers"
              value={formValues.volunteers}
              onChange={handleInputChange}
              disabled={!isEditing.question1}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          {isEditing.question1 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question1')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question1')}
                disabled={
                  !formValues.fullTimeStaff || !formValues.partTimeStaff || !formValues.volunteers
                }
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography style={commonTextStyle.questionStyle}>Question 2 of 5</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Briefly describe any formal and informal partnerships with other nonprofits or
              government entities.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question2')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Description of Formal and Informal Relationship{' '}
              <sup style={commonTextStyle.sup}>*</sup>
              <Tooltip title="Enter The Description of Formal and Informal Relationship">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              name="formalInformalRelationship"
              value={formValues.formalInformalRelationship}
              onChange={handleInputChange}
              disabled={!isEditing.question2}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          {isEditing.question2 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question2')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question2')}
                disabled={formValues.formalInformalRelationship === ''}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography style={commonTextStyle.questionStyle}>Question 3 of 5</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              What is your organization’s total budget for the current year?
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question3')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item xs={12} sm={isOtherSelected ? 2 : 6}>
            <Typography sx={commonTextStyle.labelStyle}>
              Currency <sup style={commonTextStyle.sup}>*</sup>
              <Tooltip title="Enter The Currency">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <FormControl variant="outlined" sx={{ width: '100%' }}>
              <Select
                name="yearlyBudgetCurrency"
                onChange={handleSelectChange}
                value={
                  isOtherSelected ||
                  (formValues?.yearlyBudgetCurrency !== '' &&
                    !currency?.includes(formValues?.yearlyBudgetCurrency))
                    ? 'Other'
                    : formValues.yearlyBudgetCurrency ?? 'default'
                }
                disabled={!isEditing.question3}
                sx={{
                  ...commonTextStyle.textFieldStyle,
                  '& .MuiSelect-select': {
                    fontSize: fonts.fontSize14,
                    paddingTop: '18px !important',
                    height: '100%',
                  },
                }}
              >
                {currency.map((currencyItem: any) => (
                  <MenuItem
                    value={currencyItem === 'default' ? '' : currencyItem}
                    key={currencyItem}
                  >
                    {currencyItem === 'default' ? 'Select Currency' : currencyItem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {(isOtherSelected ||
            (formValues?.yearlyBudgetCurrency !== '' &&
              !currency?.includes(formValues?.yearlyBudgetCurrency))) && (
            <Grid item xs={12} sm={4} className="gridItem">
              <Typography sx={commonTextStyle.labelStyle}>
                Mention Other <sup style={commonTextStyle.sup}>*</sup>
                <Tooltip title="Enter Other Currency">
                  <InfoIcon className="tooltipIcon" />
                </Tooltip>
              </Typography>
              <TextField
                name="otherCurrency"
                value={formValues.yearlyBudgetCurrency}
                disabled={!isEditing.question3}
                variant="outlined"
                sx={{ ...commonTextStyle.textFieldStyle, width: '100%' }}
                fullWidth
                onChange={handleOtherCurrency}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} className="gridItem" sx={{ mb: 2 }}>
            <Typography sx={commonTextStyle.labelStyle}>
              Budget <sup style={commonTextStyle.sup}>*</sup>
              <Tooltip title="Enter The Budget">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              name="budget"
              value={formValues.budget}
              onChange={handleInputChange}
              disabled={!isEditing.question3}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          {isEditing.question3 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question3')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question3')}
                disabled={formValues.yearlyBudgetCurrency === '' || !formValues.budget}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography style={commonTextStyle.questionStyle}>Question 4 of 5</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Please provide your most recent financial statement.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question4')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Financial Statement{' '}
              <Tooltip title="Upload Financial Statement">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              type="file"
              name="financialStatement"
              onChange={handleUpload}
              disabled={!isEditing.question4}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              InputProps={{
                endAdornment: (
                  <Button
                    onClick={uploadFileData}
                    className="uploadButton"
                    disabled={!isEditing.question4}
                    variant="outlined"
                    color="primary"
                  >
                    Reupload
                  </Button>
                ),
              }}
              fullWidth
            />
            <Typography sx={{ my: 2 }} variant="body1">
              File Name: {formValues.financialStatement}
            </Typography>
          </Grid>
          {isEditing.question4 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question4')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question4')}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography style={commonTextStyle.questionStyle}>Question 5 of 5</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Please list your foundation and corporate supporters and partners from this or the
              most recent fiscal year.
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question5')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Description of supporters and Partners <sup style={commonTextStyle.sup}>*</sup>
              <Tooltip title="Enter The Description of supporters and Partners">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              name="supportersPartners"
              value={formValues.supportersPartners}
              onChange={handleInputChange}
              disabled={!isEditing.question5}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          {isEditing.question5 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question5')}
                sx={{ ...commonTextStyle.cancelSaveBtn, marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question5')}
                disabled={formValues.supportersPartners === ''}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OperationalInformationReview;
