import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Tooltip,
  Card,
  TextField,
  CardContent,
  Typography,
  Divider,
  Button,
  CardHeader,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { TopBar } from '../SioFormFillingDashboard';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { fetchMpa, updateMpaThunk } from '../sioSlice';
import '../formFilling.scss';
import { submitMpa } from '../../../api/portal/sioRequest';
import { CellPhoneInput } from '../../../shared/components';
import { emailValid } from '../../../shared/utils';
import colors from '../../../assets/scss/color.module.scss';
import {
  buttonPrimary,
  questionStyles,
  optionSubStyles,
  questionNoStyles,
  inputFieldParaStyles,
  hintstyles,
  subHeadingStyles,
  mainHeadingStyles,
  inputFieldStyles,
} from '../../funding/FundingStyles';

const theme = createTheme({
  palette: {
    primary: {
      light: colors.primary,
      main: colors.primary,
    },
  },
});

const MpaReviewSubmit = () => {
  const sioState = useAppSelector((state) => state.sios);
  const { mpa } = sioState;
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const initialFormValue = {
    submitName: '',
    submitEmail: '',
    description: mpa?.info?.orgDescription,
    name: mpa?.info?.name,
    title: mpa?.info?.title,
    phone: mpa?.info?.phone,
    email: mpa?.info?.email,
    address: mpa?.info?.address,
    city: mpa?.info?.city,
    state: mpa?.info?.state,
    zip: mpa?.info?.zip,
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const [data, setData] = useState(initialFormValue);
  const [edit, setEdit] = useState(0);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (id && mpa) {
      dispatch(fetchMpa(id)).then((mpaData: any) => {
        setFormValues({
          submitName: '',
          submitEmail: '',
          description: mpaData.payload?.mpa?.info?.orgDescription,
          name: mpaData.payload?.mpa?.info?.name,
          title: mpaData.payload?.mpa?.info?.title,
          phone: mpaData.payload?.mpa?.info?.phone,
          email: mpaData.payload?.mpa?.info?.email,
          address: mpaData.payload?.mpa?.info?.address,
          city: mpaData.payload?.mpa?.info?.city,
          state: mpaData.payload?.mpa?.info?.state,
          zip: mpaData.payload?.mpa?.info?.zip,
        });
        setData({
          submitName: '',
          submitEmail: '',
          description: mpaData.payload?.mpa?.info?.orgDescription,
          name: mpaData.payload?.mpa?.info?.name,
          title: mpaData.payload?.mpa?.info?.title,
          phone: mpaData.payload?.mpa?.info?.phone,
          email: mpaData.payload?.mpa?.info?.email,
          address: mpaData.payload?.mpa?.info?.address,
          city: mpaData.payload?.mpa?.info?.city,
          state: mpaData.payload?.mpa?.info?.state,
          zip: mpaData.payload?.mpa?.info?.zip,
        });
        // savePageNumber()
      });
    }
  }, [id]);

  const sumbitMpa = async () => {
    const {
      description,
      name,
      title,
      phone,
      email,
      address,
      city,
      state,
      zip,
      submitName,
      submitEmail,
    } = data;
    const payload = {
      mpa: {
        info: {
          orgDescription: description,
          name,
          title,
          phone,
          email,
          address,
          city,
          state,
          zip,
        },
        review: {
          name: submitName,
          email: submitEmail,
        },
      },
    };
    const mpaPayload = payload;
    if (id) {
      const response = await submitMpa(id, mpaPayload);
      if (response) {
        toast.success('Successfully submitted form to scalewith.');
        setEdit(3);
      } else {
        toast.error('Error occurred while submitting');
      }
    }
    return true;
  };

  const updateMpaDetails = () => {
    const { description, name, title, phone, email, address, city, state, zip } = data;
    const payload = {
      mpa: {
        info: {
          orgDescription: description,
          name,
          title,
          phone,
          email,
          address,
          city,
          state,
          zip,
        },
      },
    };
    const mpaPayload = payload;
    if (id) {
      return dispatch(updateMpaThunk({ id, mpa: mpaPayload }));
    }
    return true;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
  };

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    if (name === 'submitAgree') {
      setCheck(!check);
    }
  };

  const handleSave = async () => {
    const response = await updateMpaDetails();
    if (response) {
      toast.success('Successfully Submitted Form to ScaleWith');
      setEdit(0);
    } else {
      toast.error('An error occurred while logging in. Please try again.');
    }
    return null;
  };

  const disabledEachSection = () => {
    const { description, name, title, phone, email, address, city, state, zip } = data;
    let disable;
    if (edit === 1) {
      if (!description) disable = true;
    } else if (edit === 2) {
      if (
        !title ||
        !name ||
        !phone ||
        data?.phone[0] === '0' ||
        data?.phone[0] === '1' ||
        data?.phone?.length < 10 ||
        !email ||
        !emailValid(email) ||
        !address ||
        !state ||
        !city ||
        !zip
      )
        disable = true;
    }
    return disable;
  };

  const disabledButtons = () => {
    const {
      description,
      name,
      title,
      phone,
      email,
      address,
      city,
      state,
      zip,
      submitEmail,
      submitName,
    } = data;
    let disable;
    if (
      !description ||
      !title ||
      !name ||
      !phone ||
      data?.phone[0] === '0' ||
      data?.phone[0] === '1' ||
      data?.phone?.length < 10 ||
      !email ||
      !emailValid(email) ||
      !state ||
      !address ||
      !city ||
      !zip ||
      !submitEmail ||
      !submitName ||
      !check
    ) {
      disable = true;
    }
    return disable;
  };

  return (
    <section className="bgLight">
      <TopBar />
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            {edit !== 3 && (
              <CardHeader
                sx={{
                  padding: '20px 80px',
                  background: colors.backgroundColorGradient,
                  borderRadius: '30px 30px 0px 0px',
                }}
                title={
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={{ mb: 4, ...mainHeadingStyles, color: colors.secondary }}>
                        Review your application
                      </Typography>
                      <Typography sx={{ ...subHeadingStyles, color: colors.secondary }}>
                        You&apos;ve reached the end of ScaleWith&apos;s Social Impact Network
                        Application Form. By clicking &quot;Submit&quot; you represent and warrant
                        that all information and documentation you&apos;ve provided is truthful and
                        accurate. We will review your application and look forward to being in touch
                        soon. In the meantime, please contact a member of our team with any
                        questions. Thank you!
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            )}
            <CardContent sx={{ minHeight: '70vh', padding: '40px 80px' }}>
              {edit === 3 && (
                <Grid item xs={12}>
                  <Typography sx={{ mb: 2, ...mainHeadingStyles, color: colors.secondary }}>
                    Congratulations! MPA Information Form Submitted Successfully!
                  </Typography>
                  <Typography sx={{ mb: 2, ...subHeadingStyles, color: colors.secondary }}>
                    Thank you for submitting your MPA onboarding information and ScaleWith Master
                    Program Agreement. A ScaleWith Partnerships Manager will reach out soon to
                    follow up on next steps.
                  </Typography>
                </Grid>
              )}
              <section className="sectionStyles">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {edit === 0 && (
                      <Button
                        onClick={() => setEdit(1)}
                        variant="outlined"
                        color="primary"
                        size="large"
                        sx={{ ...buttonPrimary, float: 'right' }}
                      >
                        Edit
                      </Button>
                    )}
                    <Typography sx={questionNoStyles}>Question 1 of 2</Typography>
                    <Typography sx={{ mb: 2, ...questionStyles }}>
                      Please provide a description for your organization
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ mb: 1, ...optionSubStyles }}>
                      Description{' '}
                      <Tooltip title="Enter Description">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="description"
                      placeholder="Enter your organization's description"
                      fullWidth
                      disabled={edit !== 1}
                      margin="none"
                      value={data.description}
                      required
                      onChange={handleChange}
                      variant="outlined"
                      multiline
                      rows={4}
                      sx={inputFieldParaStyles}
                    />
                  </Grid>
                  {edit === 1 && (
                    <Grid item xs={12} textAlign="right" sx={{ my: 2 }}>
                      <Button
                        onClick={() => {
                          setEdit(0);
                          setData({
                            ...formValues,
                            submitEmail: data.submitEmail,
                            submitName: data.submitName,
                          });
                        }}
                        variant="outlined"
                        size="large"
                        sx={{ mx: 2, ...buttonPrimary }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleSave}
                        variant="outlined"
                        size="large"
                        disabled={disabledEachSection()}
                        sx={buttonPrimary}
                      >
                        Save
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </section>
              <Divider sx={{ my: 3 }} orientation="horizontal" flexItem />
              <section className="sectionStyles">
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    {edit === 0 && (
                      <Button
                        onClick={() => setEdit(2)}
                        sx={{ ...buttonPrimary, float: 'right' }}
                        variant="outlined"
                        color="primary"
                        size="large"
                      >
                        Edit
                      </Button>
                    )}
                    <Typography sx={questionNoStyles}>Question 2 of 2</Typography>
                    <Typography sx={{ mb: 2, ...questionStyles }}>
                      Who is your authorized representative to receive legal notices ?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={optionSubStyles}>
                      First and last name{' '}
                      <Tooltip title="Enter First and last name">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="name"
                      placeholder="Enter First and last name"
                      fullWidth
                      disabled={edit !== 2}
                      value={data.name}
                      onChange={handleChange}
                      margin="none"
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={inputFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={optionSubStyles}>
                      Professional Title{' '}
                      <Tooltip title="Enter Professional Title">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="title"
                      placeholder="Enter professional title"
                      value={data.title}
                      fullWidth
                      disabled={edit !== 2}
                      margin="none"
                      onChange={handleChange}
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={inputFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={optionSubStyles}>
                      Email Address{' '}
                      <Tooltip title="Enter Email Address">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="email"
                      placeholder="Enter Email Address"
                      fullWidth
                      type="email"
                      disabled={edit !== 2}
                      error={!emailValid(data.email)}
                      value={data.email}
                      margin="none"
                      onChange={handleChange}
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={inputFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={optionSubStyles}>
                      Phone Number{' '}
                      <Tooltip title="Enter Phone Number">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="phone"
                      placeholder="Enter Phone Number"
                      fullWidth
                      type="text"
                      margin="none"
                      value={data.phone}
                      variant="outlined"
                      disabled={edit !== 2}
                      onChange={handleChange}
                      error={
                        data.phone !== undefined &&
                        (data.phone[0] === '0' ||
                          data.phone[0] === '1' ||
                          (data.phone.length < 10 && data.phone !== ''))
                      }
                      InputProps={{
                        inputComponent: CellPhoneInput as any,
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={inputFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={optionSubStyles}>
                      Street Address{' '}
                      <Tooltip title="Enter Street Address">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="address"
                      placeholder="Enter address"
                      fullWidth
                      value={data.address}
                      margin="none"
                      disabled={edit !== 2}
                      onChange={handleChange}
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={inputFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={optionSubStyles}>
                      City{' '}
                      <Tooltip title="Enter City">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="city"
                      placeholder="Enter city"
                      fullWidth
                      value={data.city}
                      disabled={edit !== 2}
                      margin="none"
                      onChange={handleChange}
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={inputFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={optionSubStyles}>
                      State{' '}
                      <Tooltip title="Enter State">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="state"
                      placeholder="Enter state"
                      fullWidth
                      value={data.state}
                      margin="none"
                      onChange={handleChange}
                      disabled={edit !== 2}
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={inputFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={optionSubStyles}>
                      Zip{' '}
                      <Tooltip title="Enter Zip Code">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="zip"
                      disabled={edit !== 2}
                      placeholder="Enter Zip code"
                      type="number"
                      fullWidth
                      value={data.zip}
                      margin="none"
                      onChange={handleChange}
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={inputFieldStyles}
                    />
                  </Grid>
                  {edit === 2 && (
                    <Grid item xs={12} textAlign="right" sx={{ my: 2 }}>
                      <Button
                        onClick={() => {
                          setEdit(0);
                          setData({
                            ...formValues,
                            submitEmail: data.submitEmail,
                            submitName: data.submitName,
                          });
                        }}
                        variant="outlined"
                        size="large"
                        sx={{ mx: 2, ...buttonPrimary }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={handleSave}
                        variant="outlined"
                        size="large"
                        disabled={disabledEachSection()}
                        sx={buttonPrimary}
                      >
                        Save
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </section>
              <section className="submitSection">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {edit !== 3 ? (
                      <Typography sx={questionStyles}>
                        Please enter your name and email to submit the application to ScaleWith.
                      </Typography>
                    ) : (
                      <Typography sx={questionStyles}>Reviewer</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={optionSubStyles}>
                      Name{' '}
                      <Tooltip title="Enter Name">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="submitName"
                      placeholder="Enter your name"
                      fullWidth
                      value={data.submitName}
                      disabled={edit === 3}
                      margin="none"
                      onChange={handleChange}
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={inputFieldStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={optionSubStyles}>
                      Email{' '}
                      <Tooltip title="Enter Email">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      name="submitEmail"
                      placeholder="Enter your email"
                      fullWidth
                      type="email"
                      disabled={edit === 3}
                      value={data.submitEmail}
                      margin="none"
                      onChange={handleChange}
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={inputFieldStyles}
                    />
                  </Grid>
                  {edit !== 3 && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <ThemeProvider theme={theme}>
                            <Checkbox
                              sx={{ fontSize: '2rem' }}
                              checked={check}
                              name="submitAgree"
                              onChange={handleCheck}
                            />
                          </ThemeProvider>
                        }
                        label={
                          <span style={hintstyles}>
                            I agree to the{' '}
                            <a
                              href="https://www.scalewith.com/terms-and-conditions/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: 'none', color: colors.primary }}
                            >
                              Terms And Conditions
                            </a>{' '}
                            &{' '}
                            <a
                              href="https://www.scalewith.com/privacy-policy/"
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: 'none', color: colors.primary }}
                            >
                              Privacy Policy
                            </a>
                            . I hereby represents and warrants that all information contained herein
                            will be truthful and accurate as of the date of said submission.
                          </span>
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </section>
              {edit !== 3 && (
                <Grid item xs={12} sx={{ my: 4 }} textAlign="right">
                  <Button
                    onClick={sumbitMpa}
                    disabled={disabledButtons()}
                    variant="outlined"
                    size="large"
                    sx={buttonPrimary}
                  >
                    Submit to Scalewith
                  </Button>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default MpaReviewSubmit;
