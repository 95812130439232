import React, { useEffect, useState } from 'react';
import { Box, Button, Tab, Tabs, createTheme, ThemeProvider } from '@mui/material';
import { toast } from 'react-toastify';
import { FormDialog } from '../../../shared/components';
import {
  Dialogs,
  InstanceSettingsTabs,
  InstanceSettingsType,
  InstanceStatus,
} from '../../../shared/enums';
import { InstanceSettingsGeneral } from './InstanceSettingsGeneral';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  clearInstanceSettingsState,
  getInstanceThunk,
  getLocalesInstanceSettingsThunk,
  postInstanceSettingsThunk,
  putInstanceSettingsThunk,
  setInstanceSettingsCurrentLocale,
  setInstanceSettingsTab,
} from './instanceSettingsSlice';
import { InstanceFormConfig } from '../../../shared/interfaces';
import { InstanceSettingsSKUs } from './InstanceSettingsSKUs';
import { InstanceSettingsSage } from './InstanceSettingsSage';
import { InstanceSettingsResources } from './InstanceSettingsResources';
import { InstanceSettingsStripe } from './InstanceSettingsStripe';
import { InstanceSettingsCauseAreas } from './instanceSettingsCauseAreas';
import { isNullOrWhitespace } from '../../../shared/utils';
import { clearInstanceSettingsCauseAreaState } from './cause-areas';
import { MAX_NUMBER_RECORDS } from '../../../shared/constants';
import { setCallInstanceThunk } from '../../../app/appSlice';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

export const InstanceSettings = () => {
  const state = useAppSelector((x) => x.instanceSettings);
  const dispatch = useAppDispatch();

  const {
    selectedTab,
    values: { name, description, settings, status, skus, restrictedDomains, causeAreas, type },
    success,
    idInstanceSetting,
    loadingInstance,
    isNew,
    locales,
    localesLoading,
    selectedLocale,
  } = state;

  const {
    locale,
    givewithEmail,
    allowRegistration,
    loginUrl,
    portalUrl,
    serviceFee,
    stripe,
    sage,
    cookiePolicyUrl,
    privacyPolicyUrl,
    termsOfUseUrl,
    minimumFundingAmount,
    defaultIncentivePercentage,
  } = {
    ...settings,
  };

  const { prices, trialPeriodDays, currencyFactor, suppliers } = { ...stripe };
  const { annual, monthly, required: accountRequired } = { ...prices };
  const {
    annual: supplierAnnual,
    monthly: supplierMonthly,
    required: supplierRequired,
  } = { ...suppliers };
  const {
    amount: supplierAnnualMount,
    enabled: supplierAnnualEnabled,
    footer: supplierAnnualFooter,
    id: supplierAnnualId,
  } = { ...supplierAnnual };
  const {
    amount: supplierMonthlyAmount,
    enabled: supplierMonthlyEnabled,
    footer: supplierMonthlyFooter,
    id: supplierMonthlyId,
  } = { ...supplierMonthly };
  const {
    amount: annualAmount,
    footer: annualFooter,
    id: annualId,
    enabled: accountAnnualEnabled,
  } = { ...annual };
  const {
    amount: monthlyAmount,
    footer: monthlyFooter,
    id: monthlyId,
    enabled: accountMonthlyEnabled,
  } = { ...monthly };
  const {
    accounts,
    departments,
    exchangeRate,
    locationId,
    products,
    billTaxDetailId,
    invoiceTaxDetailId,
    taxSolutionId,
  } = { ...sage };
  const { socialImpactPayable, subscriptionRevenue, transactionRevenue, socialImpactEnablement } = {
    ...accounts,
  };
  const { sales, socialImpact } = { ...departments };
  const { annualSubscription, monthlySubscription, transaction } = { ...products };
  const [isSaveButton, setIsSaveButton] = useState(true);

  useEffect(
    () => () => {
      dispatch(clearInstanceSettingsState());
      dispatch(clearInstanceSettingsCauseAreaState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (isNullOrWhitespace(idInstanceSetting)) return;
    dispatch(getInstanceThunk(idInstanceSetting));
  }, [dispatch, idInstanceSetting]);

  useEffect(() => {
    if (locales?.length !== 0) return;
    dispatch(
      getLocalesInstanceSettingsThunk({
        count: MAX_NUMBER_RECORDS,
        offset: 0,
        orderBy: 'name',
        descending: false,
      }),
    );
  }, [dispatch, locales]);

  useEffect(() => {
    if (locales?.length !== 0 && locale?.id && !selectedLocale) {
      const findCurrentLocale = locales?.find((item) => item.id === locale.id);
      if (!findCurrentLocale) return;
      dispatch(setInstanceSettingsCurrentLocale(findCurrentLocale));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locales, locale]);

  const isActive = status === InstanceStatus.Active;

  const instanceFormConfig: InstanceFormConfig = {
    general: {
      instanceName: { value: name || '', label: 'Instance Name', required: true },
      description: {
        value: description || '',
        label: 'Description',
        required: true,
      },
      locale: {
        value: locale?.id || '',
        label: 'Locale',
        required: true,
      },
      loginUrl: { value: loginUrl || '', label: 'Login URL', required: true },
      portalUrl: { value: portalUrl || '', label: 'Portal URL', required: true },
      givewithEmail: { value: givewithEmail || '', label: 'ScaleWith Email', required: true },
      giveServiceFee: {
        value: serviceFee || null,
        label: 'Contribution Service Fee',
        required: true,
      },
      allowRegistration: {
        value: allowRegistration === undefined ? true : allowRegistration,
        label: 'Allow registration',
      },
      status: { value: status || '', label: 'Status', required: true },
      type: { value: type || '', label: 'Instance Type', required: true },
      minimumFundingAmount: {
        value: minimumFundingAmount || null,
        label: 'Minimum Funding Amount',
        required: true,
      },
      defaultIncentivePercentage: {
        value: defaultIncentivePercentage || null,
        label: 'Default Contribution Percentage',
        required: true,
      },
    },
    sage: {
      exchangeRate: { value: exchangeRate || '', label: 'Exchange Rate', required: isActive },
      locationId: { value: locationId || '', label: 'Location ID', required: isActive },
      billTaxDetailId: {
        value: billTaxDetailId || '',
        label: 'Bill Tax Detail ID',
      },
      invoiceTaxDetailId: {
        value: invoiceTaxDetailId || '',
        label: 'Invoice Tax Detail ID',
      },
      taxSolutionId: { value: taxSolutionId || '', label: 'Tax Solution ID' },
      socialImpactPayable: {
        value: socialImpactPayable || '',
        label: 'Social impact Payable',
        required: isActive,
      },
      socialImpactEnablement: {
        value: socialImpactEnablement || '',
        label: 'Social Impact Enablement',
        required: isActive,
      },
      subscriptionRevenue: {
        value: subscriptionRevenue || '',
        label: 'Subscription Revenue',
        required: isActive,
      },
      transactionRevenue: {
        value: transactionRevenue || '',
        label: 'Transaction Revenue',
        required: isActive,
      },
      sales: { value: sales || '', label: 'Sales', required: isActive },
      socialImpact: { value: socialImpact || '', label: 'Social Impact', required: isActive },
      annualSubscription: {
        value: annualSubscription || '',
        label: 'Annual Subscription',
        required: isActive,
      },
      monthlySubscription: {
        value: monthlySubscription || '',
        label: 'Monthly Subscription',
        required: isActive,
      },
      transaction: { value: transaction || '', label: 'Transaction', required: isActive },
    },
    SKUs: {
      SKUsValues: { value: skus || [], label: 'SKU Values', required: isActive },
    },
    causeArea: {
      causeAreaValue: { value: causeAreas || [], required: isActive },
    },
    resources: {
      restrictedDomains: {
        value: restrictedDomains || [],
        label: 'Allowed Domains',
        required: false,
      },
      cookiePolicyUrl: {
        value: cookiePolicyUrl || '',
        label: 'Cookie Policy URL',
        required: isActive,
      },
      privacyPolicyUrl: {
        value: privacyPolicyUrl || '',
        label: 'Privacy Policy URL',
        required: isActive,
      },
      termsOfUseUrl: {
        value: termsOfUseUrl || '',
        label: 'Terms & Conditions URL',
        required: isActive,
      },
    },
    stripe: {
      trialPeriodDays: {
        value: trialPeriodDays || null,
        label: 'Length of Trial Period (Days)',
        // required: isActive,
      },

      currencyFactor: {
        value: currencyFactor || null,
        label: 'Currency Factor',
        required: isActive,
      },
      monthlyPriceId: {
        value: monthlyId || '',
        label: 'Price ID',
        required: isActive && accountRequired && accountMonthlyEnabled,
      },
      monthlyAmount: {
        value: monthlyAmount || null,
        label: 'Amount',
        required: isActive && accountMonthlyEnabled && accountRequired,
      },
      monthlyFooter: { value: monthlyFooter || '', label: 'Footer Text', required: false },
      annualPriceId: {
        value: annualId || '',
        label: 'Annual Price ID',
        required: isActive && accountAnnualEnabled && accountRequired,
      },
      annualAmount: {
        value: annualAmount || null,
        label: 'Amount',
        required: isActive && accountAnnualEnabled && accountRequired,
      },
      annualFooter: { value: annualFooter || '', label: 'Footer Text', required: false },
      accountRequired: {
        value: accountRequired === undefined ? true : accountRequired,
        label: 'Subscription Required',
        required: true,
      },
      accountAnnualEnable: {
        value: accountAnnualEnabled === undefined ? true : accountAnnualEnabled,
        label: 'Enabled',
        required: true,
      },
      accountMonthlyEnable: {
        value: accountMonthlyEnabled === undefined ? true : accountMonthlyEnabled,
        label: 'Enabled',
        required: true,
      },
      supplierRequired: {
        value: supplierRequired === undefined ? true : supplierRequired,
        label: 'Subscription Required',
        required: true,
      },
      supplierAnnualEnable: {
        value: supplierAnnualEnabled === undefined ? true : supplierAnnualEnabled,
        label: 'Enabled',
        required: true,
      },
      supplierMonthlyEnable: {
        value: supplierMonthlyEnabled === undefined ? true : supplierMonthlyEnabled,
        label: 'Enabled',
        required: true,
      },
      supplierMonthlyPriceId: {
        value: supplierMonthlyId || '',
        label: 'Price ID',
        required:
          isActive &&
          supplierRequired &&
          supplierMonthlyEnabled &&
          type === InstanceSettingsType.procurement,
      },
      supplierMonthlyAmount: {
        value: supplierMonthlyAmount || null,
        label: 'Amount',
        required:
          isActive &&
          supplierRequired &&
          supplierMonthlyEnabled &&
          type === InstanceSettingsType.procurement,
      },
      supplierMonthlyFooter: {
        value: supplierMonthlyFooter || '',
        label: 'Footer Text',
        required: false,
      },
      supplierAnnualPriceId: {
        value: supplierAnnualId || '',
        label: 'Annual Price ID',
        required:
          isActive &&
          supplierRequired &&
          supplierAnnualEnabled &&
          type === InstanceSettingsType.procurement,
      },
      supplierAnnualAmount: {
        value: supplierAnnualMount || null,
        label: 'Amount',
        required:
          isActive &&
          supplierRequired &&
          supplierAnnualEnabled &&
          type === InstanceSettingsType.procurement,
      },
      supplierAnnualFooter: {
        value: supplierAnnualFooter || '',
        label: 'Footer Text',
        required: false,
      },
    },
  };

  const isProcurement =
    state.values.type === 'procurement' &&
    (instanceFormConfig.stripe.supplierAnnualAmount.value === null ||
      instanceFormConfig.stripe.supplierAnnualPriceId.value === null ||
      instanceFormConfig.stripe.supplierAnnualPriceId.value === '',
    instanceFormConfig.stripe.supplierMonthlyAmount.value === null ||
      instanceFormConfig.stripe.supplierMonthlyPriceId.value === null ||
      instanceFormConfig.stripe.supplierMonthlyPriceId.value === '');

  useEffect(() => {
    if (isProcurement) {
      toast.warning('Please Fill the Required Fileds in Stripe tab to Save');
    }
  }, [isProcurement]);

  const hideSaveButton = (isHide: boolean) => {
    setIsSaveButton(isHide);
  };

  const getTabComponent = (tab: InstanceSettingsTabs) => {
    switch (tab) {
      case InstanceSettingsTabs.General:
        return <InstanceSettingsGeneral value={instanceFormConfig} />;
      case InstanceSettingsTabs.StandardPrograms:
        return (
          <InstanceSettingsCauseAreas hideSave={(isHide: boolean) => hideSaveButton(isHide)} />
        );
      case InstanceSettingsTabs.Resources:
        return <InstanceSettingsResources value={instanceFormConfig} />;
      case InstanceSettingsTabs.Sage:
        return <InstanceSettingsSage value={instanceFormConfig} />;
      case InstanceSettingsTabs.SKUs:
        return <InstanceSettingsSKUs value={instanceFormConfig} />;
      case InstanceSettingsTabs.Stripe:
        return <InstanceSettingsStripe value={instanceFormConfig} />;
      default:
        return <InstanceSettingsGeneral value={instanceFormConfig} />;
    }
  };

  const isInstanceSettingsGeneralInValid = () =>
    (instanceFormConfig.general.description?.required &&
      isNullOrWhitespace(instanceFormConfig.general.description?.value)) ||
    (instanceFormConfig.general.giveServiceFee?.required &&
      instanceFormConfig.general.giveServiceFee?.value === null) ||
    (instanceFormConfig.general.givewithEmail?.required &&
      isNullOrWhitespace(instanceFormConfig.general.givewithEmail?.value)) ||
    (instanceFormConfig.general.instanceName?.required &&
      isNullOrWhitespace(instanceFormConfig.general.instanceName?.value)) ||
    (instanceFormConfig.general.locale?.required &&
      isNullOrWhitespace(instanceFormConfig.general.locale?.value)) ||
    (instanceFormConfig.general.loginUrl?.required &&
      isNullOrWhitespace(instanceFormConfig.general.loginUrl?.value)) ||
    (instanceFormConfig.general.portalUrl?.required &&
      isNullOrWhitespace(instanceFormConfig.general.portalUrl?.value)) ||
    (instanceFormConfig.general.minimumFundingAmount?.required &&
      instanceFormConfig.general.minimumFundingAmount?.value === null) ||
    (instanceFormConfig.general.defaultIncentivePercentage?.required &&
      instanceFormConfig.general.defaultIncentivePercentage?.value === null) ||
    (instanceFormConfig.general.status?.required &&
      isNullOrWhitespace(instanceFormConfig.general.status?.value));

  const isInstanceSettingsCauseAreaInValid = () =>
    instanceFormConfig.causeArea.causeAreaValue.required &&
    instanceFormConfig.causeArea.causeAreaValue.value.length === 0;

  const isInstanceSettingsSkusInValid = () =>
    instanceFormConfig.SKUs.SKUsValues.required &&
    instanceFormConfig.SKUs.SKUsValues.value.length === 0;

  const isInstanceSettingsSageInValid = () =>
    (instanceFormConfig.sage?.annualSubscription?.required &&
      isNullOrWhitespace(instanceFormConfig.sage?.annualSubscription?.value)) ||
    (instanceFormConfig.sage?.exchangeRate?.required &&
      isNullOrWhitespace(instanceFormConfig.sage?.exchangeRate?.value)) ||
    (instanceFormConfig.sage?.locationId?.required &&
      isNullOrWhitespace(instanceFormConfig.sage?.locationId?.value)) ||
    (instanceFormConfig.sage?.monthlySubscription?.required &&
      isNullOrWhitespace(instanceFormConfig.sage?.monthlySubscription?.value)) ||
    (instanceFormConfig.sage?.sales?.required &&
      isNullOrWhitespace(instanceFormConfig.sage?.sales?.value)) ||
    (instanceFormConfig.sage?.socialImpact?.required &&
      isNullOrWhitespace(instanceFormConfig.sage?.socialImpact?.value)) ||
    (instanceFormConfig.sage?.socialImpactPayable?.required &&
      isNullOrWhitespace(instanceFormConfig.sage?.socialImpactPayable?.value)) ||
    (instanceFormConfig.sage?.subscriptionRevenue?.required &&
      isNullOrWhitespace(instanceFormConfig.sage?.subscriptionRevenue?.value)) ||
    (instanceFormConfig.sage?.transaction?.required &&
      isNullOrWhitespace(instanceFormConfig.sage?.transaction?.value)) ||
    (instanceFormConfig.sage?.transactionRevenue?.required &&
      isNullOrWhitespace(instanceFormConfig.sage?.transactionRevenue?.value)) ||
    (instanceFormConfig.sage?.socialImpactEnablement?.required &&
      isNullOrWhitespace(instanceFormConfig.sage?.socialImpactEnablement?.value));

  const isInstanceSettingsResourceInValid = () =>
    (instanceFormConfig.resources.restrictedDomains.required &&
      instanceFormConfig.resources.restrictedDomains.value.length === 0) ||
    (instanceFormConfig.resources.cookiePolicyUrl.required &&
      isNullOrWhitespace(instanceFormConfig.resources.cookiePolicyUrl.value)) ||
    (instanceFormConfig.resources.privacyPolicyUrl.required &&
      isNullOrWhitespace(instanceFormConfig.resources.privacyPolicyUrl.value)) ||
    (instanceFormConfig.resources.termsOfUseUrl.required &&
      isNullOrWhitespace(instanceFormConfig.resources.termsOfUseUrl.value));

  const isInstanceSettingsStripeInValid = () =>
    (instanceFormConfig.stripe?.annualPriceId?.required &&
      isNullOrWhitespace(instanceFormConfig.stripe?.annualPriceId?.value)) ||
    (instanceFormConfig.stripe?.annualAmount?.required &&
      instanceFormConfig.stripe?.annualAmount?.value === null) ||
    (instanceFormConfig.stripe?.monthlyAmount?.required &&
      instanceFormConfig.stripe?.monthlyAmount?.value === null) ||
    (instanceFormConfig.stripe?.monthlyPriceId?.required &&
      isNullOrWhitespace(instanceFormConfig.stripe?.monthlyPriceId?.value)) ||
    (instanceFormConfig.stripe?.supplierAnnualPriceId?.required &&
      isNullOrWhitespace(instanceFormConfig.stripe?.supplierAnnualPriceId?.value)) ||
    (instanceFormConfig.stripe?.supplierMonthlyPriceId?.required &&
      isNullOrWhitespace(instanceFormConfig.stripe?.supplierMonthlyPriceId?.value)) ||
    (instanceFormConfig.stripe?.supplierAnnualAmount?.required &&
      instanceFormConfig.stripe?.supplierAnnualAmount?.value === null) ||
    (instanceFormConfig.stripe?.supplierMonthlyAmount?.required &&
      instanceFormConfig.stripe?.supplierMonthlyAmount?.value === null) ||
    (instanceFormConfig.stripe?.trialPeriodDays?.required &&
      instanceFormConfig.stripe?.trialPeriodDays?.value === null) ||
    (instanceFormConfig.stripe?.currencyFactor?.required &&
      instanceFormConfig.stripe?.currencyFactor?.value === null);

  const isInstanceSettingsInValid = () =>
    isInstanceSettingsGeneralInValid() ||
    isInstanceSettingsSkusInValid() ||
    isInstanceSettingsResourceInValid() ||
    isInstanceSettingsSageInValid() ||
    isInstanceSettingsStripeInValid() ||
    isInstanceSettingsCauseAreaInValid();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setInstanceSettingsTab(newValue));
  };

  const handleSave = () => {
    if (isNew) {
      dispatch(postInstanceSettingsThunk()).then((data: any) => {
        if (data?.meta?.requestStatus === 'fulfilled') {
          dispatch(setCallInstanceThunk(true));
        }
      });
    } else {
      dispatch(putInstanceSettingsThunk()).then((data: any) => {
        if (data?.meta?.requestStatus === 'fulfilled') {
          dispatch(setCallInstanceThunk(true));
        }
      });
    }
  };

  const getTitle = () => (isNew ? 'Add Instance' : 'Edit Instance');
  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: colors.primary,
            height: '4px',
          },
        },
      },
    },
  });

  return (
    <FormDialog
      title={getTitle()}
      loading={loadingInstance || localesLoading}
      closeFlag={success}
      Buttons={
        isSaveButton ? (
          <Button
            variant="contained"
            size="large"
            onClick={handleSave}
            disabled={isInstanceSettingsInValid()}
            hidden
            sx={{
              backgroundColor: colors.primary,
              fontWeight: fonts.fontWeight900,
              fontSize: fonts.fontSize14,
              ':hover': {
                backgroundColor: colors.primary,
              },
            }}
          >
            Save
          </Button>
        ) : undefined
      }
      type={Dialogs.InstanceSettings}
      ExtraHeaderComponent={
        <ThemeProvider theme={theme}>
          <Tabs value={selectedTab} onChange={handleTabChange} sx={{ paddingX: 6 }}>
            <Tab
              value={InstanceSettingsTabs.General}
              label="General"
              sx={{
                textTransform: 'capitalize',
                color:
                  selectedTab === InstanceSettingsTabs.General
                    ? `${colors.primary} !important`
                    : `${colors.textPrimary} !important`,
                fontWeight: selectedTab === InstanceSettingsTabs.General ? fonts.fontWeight700 : fonts.fontWeight600,
              }}
            />
            <Tab
              value={InstanceSettingsTabs.SKUs}
              label="SKUs"
              sx={{
                textTransform: 'capitalize',
                color:
                  selectedTab === InstanceSettingsTabs.SKUs
                    ? `${colors.primary} !important`
                    : `${colors.textPrimary} !important`,
                fontWeight: selectedTab === InstanceSettingsTabs.SKUs ? fonts.fontWeight700 : fonts.fontWeight600,
              }}
            />
            <Tab
              value={InstanceSettingsTabs.StandardPrograms}
              label="Standard Programs"
              sx={{
                textTransform: 'capitalize',
                color:
                  selectedTab === InstanceSettingsTabs.StandardPrograms
                    ? `${colors.primary} !important`
                    : `${colors.textPrimary} !important`,
                fontWeight: selectedTab === InstanceSettingsTabs.StandardPrograms ? fonts.fontWeight700 : fonts.fontWeight600,
              }}
            />
            <Tab
              value={InstanceSettingsTabs.Resources}
              label="Resources"
              sx={{
                textTransform: 'capitalize',
                color:
                  selectedTab === InstanceSettingsTabs.Resources
                    ? `${colors.primary} !important`
                    : `${colors.textPrimary} !important`,
                fontWeight: selectedTab === InstanceSettingsTabs.Resources ? fonts.fontWeight700 : fonts.fontWeight600,
              }}
            />
            {/* <Tab value={InstanceSettingsTabs.Sage} label="Sage" />
          <Tab value={InstanceSettingsTabs.Stripe} label="Stripe" /> */}
          </Tabs>
        </ThemeProvider>
      }
    >
      <Box sx={{ minHeight: 450 }}>{getTabComponent(selectedTab as InstanceSettingsTabs)}</Box>
    </FormDialog>
  );
};
