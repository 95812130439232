import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Button,
  TextField,
  IconButton,
  Autocomplete,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassBottom from '@mui/icons-material/HourglassBottom';
import { Close } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { ReactComponent as UnionIcon } from '../../assets/svg/UnionIcon.svg';
import filterListIcon from '../../assets/images/filter_list_icon.svg';
import { GridColumns, FundingStatus } from '../../shared/enums';
import { getGenericHeight, getGridRequest } from '../../shared/utils';
import { CustomGridColDef } from '../../shared/interfaces';
import { FundingStatusCell } from '../../shared/components/FundingStatusCell';
import { CommonGridFilterHandle, FlexBox, IsolatedGrid } from '../../shared/components';
import {
  fetchFundingListThunk,
  fetchFundingStatus,
  setFundingsGridModel,
  clearFundingsState,
  clearFundingsUpdateSuccess,
  setFundingsStatus,
} from './fundingSlice';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { STARTS_WITH_OPERATOR } from '../../shared/constants';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';
import borders from '../../assets/scss/strokes.module.scss';
import { getTenantIdCookie } from '../../api';

interface TabInterface {
  label: string;
  value: FundingStatus | null;
  count: number;
}

interface TenantAccessGrant {
  id: string;
  createdBy: string;
  createdAt: {
    $date: string;
  };
  lastUpdatedBy: string;
  lastUpdated: {
    $date: string;
  };
  default: boolean;
  friendlyName: string;
  roles: string[];
  instanceId: {
    $oid: string;
  };
  app: string;
  type: string;
}

const FundingListPage = () => {
  const fundingState = useAppSelector((state) => state.fundings);
  const user = useAppSelector((x) => x.myProfile.user);
  const dispatch = useAppDispatch();
  const { grid, updateStatusSuccess, status: sioStatus } = fundingState;
  const { sort, filter, page, pageSize } = grid;
  const { totalCount } = grid.data;
  const [searchQuery, setSearchQuery] = useState<any>({
    fundingFormName: '',
    orderOppurtuinityName: '',
    programName: '',
  });
  const tenant = getTenantIdCookie();
  const [hasAdminRole, setHasAdminRole] = useState(false);
  const [filteredItems, setFilteredItems] = useState(grid.data.results);
  const filterRef = useRef<CommonGridFilterHandle>(null);
  const navigate = useNavigate();
  const [selectedColumn, setSelectedColumn] = useState<CustomGridColDef | null | undefined>(null);

  const filteredColumns: Array<CustomGridColDef> = [
    {
      field: 'fundingFormName',
      headerName: 'Funding Form',
      description: 'Funding Form',
      type: 'text',
      flex: 0.5,
      filterable: true,
      filterOperators: STARTS_WITH_OPERATOR,
      hideable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'orderOppurtuinityName',
      headerName: 'Proposal Name',
      description: 'Proposal Name',
      type: 'text',
      flex: 0.5,
      filterable: true,
      filterOperators: STARTS_WITH_OPERATOR,
      hideable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      description: 'Program Name',
      type: 'text',
      flex: 0.5,
      filterable: true,
      filterOperators: STARTS_WITH_OPERATOR,
      hideable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  const [tabs, setTabs] = useState<TabInterface[]>([
    { label: 'All', value: null, count: 0 },
    { label: 'In Progress', value: FundingStatus.InProgress, count: 0 },
    { label: 'Pending Approval', value: FundingStatus.PendingApproval, count: 0 },
    { label: 'Approved', value: FundingStatus.Approved, count: 0 },
    { label: 'Archived', value: FundingStatus.Archived, count: 0 },
  ]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(setFundingsStatus(''));
    };
  }, []);

  useEffect(() => {
    dispatch(fetchFundingStatus());
  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchFundingStatus()).then((siodata: any) => {
      const totalStatusCount = siodata.payload.status_list.reduce(
        (total: number, status: any) => total + status.count,
        0,
      );
      const updatedTabs = tabs.map((tab) => {
        if (tab.label === 'All') {
          return {
            ...tab,
            count: totalStatusCount,
          };
        }
        /* eslint-disable no-underscore-dangle */
        const status = siodata?.payload?.status_list?.find((s: any) => s._id === tab.value);
        return {
          ...tab,
          count: status ? status.count : 0,
        };
      });

      setTabs(updatedTabs);
    });
  }, []);

  useEffect(() => {
    dispatch(setFundingsGridModel({ data: { results: filteredItems, totalCount } }));
  }, [dispatch]);

  useEffect(() => {
    setFilteredItems(grid.data.results);
  }, [grid]);

  useEffect(() => {
    if (selectedColumn?.field) {
      const field: any = selectedColumn?.field;
      const fieldValue: any = searchQuery[field];
      dispatch(
        fetchFundingListThunk({ status: sioStatus, ...getGridRequest(grid), [field]: fieldValue }),
      );
    } else {
      dispatch(fetchFundingListThunk({ status: sioStatus, ...getGridRequest(grid) }));
    }
  }, [sort, filter, page, sioStatus, pageSize, searchQuery]);

  useEffect(() => {
    dispatch(fetchFundingListThunk({ status: sioStatus, ...getGridRequest(grid) }));
  }, []);

  useEffect(
    () => () => {
      dispatch(clearFundingsState());
    },
    [dispatch],
  );
  useEffect(() => {
    dispatch(clearFundingsUpdateSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatusSuccess]);

  useEffect(() => {
    if (user) {
      const correctTenant = (user.tenantAccessGrants as TenantAccessGrant[] | undefined)?.find(
        (grant) => grant.id === tenant,
      );

      if (correctTenant) {
        const givewithRoleExists = correctTenant.roles.includes('givewith');
        setHasAdminRole(givewithRoleExists);
      } else {
        setHasAdminRole(false);
      }
    }
  }, [user, tenant]);

  const clearInputControllers = () => {
    if (!_.isNil(searchQuery))
      setSearchQuery({ fundingFormName: '', orderOppurtuinityName: '', programName: '' });
  };

  const clearFilterController = () => {
    setSelectedColumn(null);
    clearInputControllers();
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSearchQuery({ fundingFormName: '', orderOppurtuinityName: '', programName: '' });
    dispatch(setFundingsStatus(newValue));
    filterRef.current?.clearFilterSelections();
    clearFilterController();
  };
  const handleSearchChange = (event: any, key: any) => {
    const query = event.target.value;
    setSearchQuery((prevState: any) => ({
      ...prevState,
      [key]: query,
    }));
  };
  const getStatusDisplayValue = (status: string): string => {
    switch (status) {
      case 'approved':
        return 'Approved';
      case 'submitted':
        return 'Submitted';
      case 'in_progress':
        return 'In Progress';
      default:
        return status;
    }
  };

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: colors.primary,
            height: '4px',
          },
        },
      },
    },
  });

  const columns: Array<CustomGridColDef> = [
    {
      field: 'srno',
      headerName: 'Sr No',
      type: 'number',
      flex: 0.5,
      align: 'left',
      renderCell: (params) => {
        const { api } = params;
        const rowIndex = api.getRowIndex(params.id);
        return rowIndex + 1;
      },
      headerAlign: 'left',
    },
    {
      field: 'fundingFormName',
      headerName: 'Funding Form',

      flex: 1,
    },
    {
      field: 'orderOppurtuinityName',
      headerName: 'Proposal Name',
      renderCell: (params) => (
        <span>
          {params.row.orderOppurtuinityName} ( {params.row.clientName} with {params.row.sellerName}{' '}
          )
        </span>
      ),
      flex: 1,
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      renderCell: (params) => <span>{params.row.programName}</span>,
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated On',
      flex: 1,
      type: GridColumns.Date,
      renderCell: (params) => (
        <Box>
          {new Date(params.row.lastUpdated).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      type: GridColumns.SingleSelect,
      flex: 1,
      minWidth: 250,
      renderCell: (params: GridRenderCellParams<string>) => (
        <div
          style={{
            ...(params.row.status === FundingStatus.Approved && {
              backgroundColor: colors.statusBackgroundSuccess,
              color: colors.statusSuccess,
              border: `1px solid ${colors.statusSuccess}`,
            }),
            ...(params.row.status === FundingStatus.Submitted && {
              backgroundColor: colors.statusBackgroundWarning,
              border: `1px solid ${colors.statusWarning}`,
              color: colors.statusWarning,
            }),
            ...(params.row.status === FundingStatus.InProgress && {
              backgroundColor: colors.statusBackgroundInfo,
              border: `1px solid ${colors.statusInfo}`,
              fontWeight: fonts.fontWeight400,
              color: colors.statusInfo,
            }),
            ...(params.row.status === FundingStatus.Archived && {
              backgroundColor: '#DFE2E6',
              color: '#000000',
              border: `1px solid #333333`,
              fontWeight: fonts.fontWeight400,
            }),
            borderRadius: '25px',
            display: 'flex',
            alignItems: 'center',
            padding: '2px 0px 2px 15px',
            justifyContent: 'left',
            textTransform: 'capitalize',
            width: 200,
            whiteSpace: 'nowrap',
          }}
        >
          {params.row.status === FundingStatus.InProgress && <HourglassBottom />}
          {params.row.status === FundingStatus.Approved && <CheckCircleIcon />}
          {params.row.status === FundingStatus.Submitted && <WatchLaterIcon />}
          {params.row.status === FundingStatus.Archived && (
            <UnionIcon style={{ marginLeft: '7px' }} />
          )}
          <FundingStatusCell>
            {params.row.status === FundingStatus.InProgress
              ? `${getStatusDisplayValue(params.row.status)} ${params.row.percentComplete}%`
              : getStatusDisplayValue(params.row.status)}
          </FundingStatusCell>
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: ({ row: { id } }) => (
        <Button
          variant="outlined"
          // color="info"
          // sx={{ backgroundColor: '#fff' }}
          sx={{
            backgroundColor: colors.secondary,
            fontSize: fonts.fontSize14,
            fontWeight: fonts.fontWeight700,
            color: colors.primary,
            borderRadius: borders.borderRadius8,
            borderColor: colors.primary,
            '&:focus': colors.primary,
            '&:hover': colors.primary,
          }}
          onClick={() =>
            hasAdminRole
              ? navigate(`/app/sio/admin/funding/${id}`)
              : navigate(`/app/sio/funding/${id}`)
          }
          endIcon={<ArrowForwardIcon />}
        >
          <span style={{ marginLeft: '0.5rem' }}>View Details</span>
        </Button>
      ),
    },
  ];

  const getColumnValue = (field: any) => searchQuery[field];

  return (
    <Box className="dashboard" sx={{ pt: '12px', pb: '1px' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: '2rem',
        }}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <Box sx={{ marginBottom: '1.5rem' }}>
            <FlexBox sx={{ gap: 3, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '10px 0px',
                }}
              >
                <Typography
                  flex={1}
                  sx={{
                    flex: 1,
                    color: colors.textPrimary,
                    fontSize: fonts.fontSize24,
                    fontWeight: 700,
                  }}
                >
                  Funding Forms
                </Typography>
                <Typography
                  flex={1}
                  variant="body1"
                  sx={{
                    flex: 1,
                    color: '#BCBEBF',
                    fontSize: '12pt',
                    fontWeight: 700,
                  }}
                >
                  View, Edit, and Update Funding Forms
                </Typography>
              </Box>
            </FlexBox>
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr',
              gap: '1rem',
              marginLeft: '13px',
              borderBottom: '1px solid #e9ecef',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Box my={1}>
                  <ThemeProvider theme={theme}>
                    <Tabs
                      value={sioStatus}
                      onChange={handleTabChange}
                      sx={{ display: 'flex', gap: '6rem' }}
                    >
                      {tabs.map((tab) => (
                        <Tab
                          key={`common-order-tab-${tab.value}`}
                          value={tab.value}
                          label={`${tab.label}(${tab.count})`}
                          sx={{
                            textTransform: 'capitalize',
                            fontSize: fonts.fontSize18,
                            fontWeight: `${
                              tab.value === sioStatus ? fonts.fontWeight700 : fonts.fontWeight600
                            }`,
                            color: colors.textPrimary,
                          }}
                        />
                      ))}
                    </Tabs>
                  </ThemeProvider>
                </Box>
              </Box>
              <FlexBox
                sx={{
                  gap: '10px',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <img
                  src={filterListIcon}
                  alt="filterListIcon"
                  style={{
                    width: '24px',
                    height: '24px',
                    color: '#4F4F4F',
                    marginTop: '10px',
                    marginRight: '5px',
                  }}
                />

                <Autocomplete
                  disablePortal
                  id="combo-box-columns"
                  sx={{ width: '250px' }}
                  getOptionLabel={(option) => option.headerName ?? ''}
                  options={filteredColumns.filter((x) => x.filterable)}
                  value={selectedColumn}
                  onChange={(event: any, newValue: CustomGridColDef | null) => {
                    clearInputControllers();
                    setSelectedColumn(newValue);
                  }}
                  isOptionEqualToValue={(option, value) => option.field === value.field}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filter Column"
                      sx={{
                        '& .MuiFormLabel-root': {
                          color: colors.textPrimary,
                          fontSize: fonts.fontSize20,
                          fontWeight: 600,
                        },
                      }}
                    />
                  )}
                />
                {selectedColumn && (
                  <TextField
                    label="Starts With"
                    disabled={!selectedColumn}
                    value={getColumnValue(selectedColumn.field)}
                    onChange={(event) => handleSearchChange(event, selectedColumn.field)}
                  />
                )}
                {selectedColumn && searchQuery && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      clearFilterController();
                    }}
                  >
                    <Close />
                  </IconButton>
                )}
                {hasAdminRole && (
                  <Button
                    variant="outlined"
                    onClick={() => navigate('create-funding')}
                    sx={{
                      height: '48px',
                      marginLeft: '30px',
                      paddingX: '16px',
                      fontWeight: fonts.fontWeight700,
                      fontSize: fonts.fontSize14,
                      backgroundColor: `${colors.primary} !important`,
                      color: `${colors.secondary} !important`,
                      borderRadius: '8px !important',
                      '&.Mui-disabled': {
                        backgroundColor: `${colors.strokeDefault} !important`,
                      },
                    }}
                  >
                    Add New Funding
                  </Button>
                )}
              </FlexBox>
            </Box>
          </Box>
          <Box sx={{ minHeight: '400px', height: getGenericHeight(300), border: 'none' }}>
            <IsolatedGrid
              status={sioStatus}
              model={grid}
              columns={columns}
              onChange={(x) => dispatch(setFundingsGridModel(x))}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FundingListPage;
