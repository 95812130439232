import React, { useEffect } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

import { isUserAuthorized } from '../../shared/utils';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import LandingPageBg from '../../assets/images/Redwood-Forest.jpg';
import BuyWithLogo from '../../assets/images/BuyWith-logo@0.5x.png';
import { getBuyerAccountThunk, getScaleWithConstantsThunk } from '../accounts';
import { FlexBox, FlexBoxColumn } from '../../shared/components';

const SupplierGreetingPage: React.FC = () => {
  const companyBenefits = [
    {
      label: 'Investors',
      title: 'Improved reporting',
      description: 'Informed sustainability ratings can increase shareholder value and attract new investors.'
    },
    {
      label: 'Employees',
      title: 'Increased talent retention',
      description: 'Socially responsible companies have lower recruiting costs and higher employee retention rates.'
    },
    {
      label: 'Consumers',
      title: 'Beautiful brand stories',
      description: 'Shareable, high-quality photos, videos and reports capture the full impact of your support.'
    }
  ];
  const navigate = useNavigate();
  const isLoggedIn = isUserAuthorized(true);
  const { buyerAccount, scaleWithConstants, error } = useAppSelector((x) => x.accounts);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getScaleWithConstantsThunk({ key: 'supplier.registration.landing.text' }));
    if (id) {
      dispatch(getBuyerAccountThunk({ id }));
    }
  }, [id, dispatch]);

  const handleClick = () => {
    if (isLoggedIn) navigate('/app/contribution/new');
    if (!isLoggedIn) window.location.href = window.LOGIN_UI_URL;
  };

  const buyer = buyerAccount.company ? buyerAccount.company.name : '';
  const constant = scaleWithConstants
    ? scaleWithConstants.replace(/{|}|buyer/g, '').replace('$', '')
    : '';
  return (
    <Box className='scroll-bar-wrap greetingPage'>
      <Box sx={{ fontFamily: 'inter', height: window?.innerHeight }} className='scroll-box'>
        <Box
          sx={{
            height: window?.innerHeight,
            backgroundImage: `url(${LandingPageBg})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <Box sx={{ padding: '60px 60px 40px', height: '85%' }}>
            <Grid container alignItems="start" sx={{ height: '100%' }}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Box className="LoginLogo">
                  <img
                    src={BuyWithLogo}
                    alt="BuyWith logo"
                    style={{ width: '200px', height: '55px' }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={12}
                xs={12}
                sx={{ display: 'flex', alignItems: 'end', height: '100%' }}
              >
                <Box sx={{ padding: '0 40px 40px 0' }}>
                  <Typography
                    variant="h4"
                    fontWeight={500}
                    sx={{ color: 'white' }}
                    fontFamily="inter"
                  >
                    {buyer ? buyer + constant : error || null}
                  </Typography>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      background: '#df8238',
                      padding: '0.9rem',
                      fontSize: '1rem',
                      width: '350px',
                      marginTop: '6rem',
                    }}
                    onClick={() => handleClick()}
                  >
                    Get Started
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box sx={{ marginTop: '80px' }}>
          <Grid container alignItems="start" sx={{ height: '100%' }}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} />
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ display: 'flex', alignItems: 'end', height: '100%' }}
            >
              <Box sx={{ padding: '0 40px 40px 0' }}>
                <Box>
                  <Typography variant='h4' fontWeight="bold" fontFamily="inter">Purchasing has the power to propel positive social change</Typography>
                  <Typography variant='body1' sx={{ marginTop: '30px'}} fontFamily="inter">{`Creating positive social impact is a key priority for ${
                    buyerAccount.company && buyerAccount.company.name
                  }, and we’ d like to work with you to support this mission as part of our deal together.  Using Buywith, both of our companies are able to advance our corporate social impact and sustainability goals, while generating significant business value for our stakeholders by committing 2% of our Toal deal size to fund a relevant nonprofit program.`}</Typography>
                  <Typography variant='body1' fontFamily="inter" sx={{ marginTop: '20px'}}>
                    We hope you’ll agree to participate in this exciting opportunity to positively
                    impact our companies, customers, and communities through our deals together.
                  </Typography>
                </Box>

                <Box sx={{ marginTop: '80px' }}>
                  <Typography variant='h4' fontFamily="inter" fontWeight="bold" >The value of using BuyWith</Typography>
                  <Typography variant='body1' fontFamily="inter" sx={{ marginTop: '30px'}}>
                    There’s increasing market and consumer demand for companies to be more socially
                    responsible throughout their business. It’s no longer enough to just say you’re
                    doing good; companies need to report on it in order to gain the trust and investment
                    of consumers, employees, and stockholders. Supporting a relevant nonprofit program
                    as part of our deal through Buywith will help you align your every day business
                    practices with our company’s priorities.
                  </Typography>
                  <Typography variant='body1' fontFamily="inter" sx={{ marginTop: '20px'}}>
                    You’ll also create the following benefits for your Corporate Social Responsibility,
                    Marketing, HR, and Investor Relations teams - and your C-Suite.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: '80px' }}>
          <Grid container alignItems="start" sx={{ height: '100%' }}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} />
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ height: '100%' }}
            >
              <FlexBox sx={{ padding: '0 40px 40px 0', display: 'flex' }}>
                {
                  companyBenefits.map((benifit, i) => (
                    <FlexBoxColumn gap={2} sx={{ justifyContent: 'space-between', paddingRight: companyBenefits.length === i ? '0px' : '5px' }}>
                      <Typography variant='h5' fontWeight="bold" fontFamily="inter">{benifit.label}</Typography>
                      <FlexBox sx={{ alignItems: 'start' }}>
                        <Typography variant='h4' fontFamily="inter">{benifit.title}</Typography>
                      </FlexBox>
                      <Typography variant='body1' fontFamily="inter">{benifit.description}</Typography>
                    </FlexBoxColumn>
                  ))
                }
              </FlexBox>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginTop: '80px' }}>
          <Grid container alignItems="start" sx={{ height: '100%' }}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} />
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ display: 'flex', alignItems: 'end', height: '100%' }}
            >
              <Box sx={{ padding: '0 40px 40px 0' }}>
                <Typography variant='h4' fontWeight="bold" fontFamily="inter" >Let’s do good together</Typography>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    background: '#df8238',
                    padding: '0.9rem',
                    fontSize: '1rem',
                    width: '350px',
                    marginTop: '3rem',
                  }}
                  onClick={() => handleClick()}
                >
                  Get Started
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <div className="cover-bar" />
      </Box>
  );
};

export default SupplierGreetingPage;
