import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Funding } from './fundingInterface';
import { addCancelTokenEvent } from '../../api';
import { getFundingbyUser, postFundingbyUser, updateFundingbyUser } from '../../api/portal/fundingRequest';

interface fundingState {
  funding: Funding;
  updateStatusSuccess: boolean;
};

const initialState: fundingState = {
  funding: {
    siAdmin: "",
    status: "",
    fundingStatus: "",
    outputs: "",
    fundingOpportuinityName: "",
    fundingFormName: "",
    effectiveDate: "",
    funding: {
      programDate: {
        end: '',
        start: '',
      },
      itemization: {
        value: [
          {
            description: '',
            quantity: '',
          },
        ]
      },
      location: {
        value: '',
      }
    },
    general: {
      contact: {
        name: '',
        email: '',
        phone: '',
      },
      programName: {
        internalProgramName: '',
        platformProgramName: '',
      },
      programDescription: {
        description: '',
      }
    },

    impactAndScope: {
      causes: [],
      primaryImpact: [],
      secondaryImpacts: [],
      targetGroup: [],
      audienceAge: [],
      locationDetails: {
        regions: [],
        countries: [],
        cities: [],
        states: [],
        additionalLocationDetails: '',
        locationExplanation: {
          regions: '',
          countries: '',
          states: '',
          cities: '',
        }
      }
    },
    strategiesAndApproaches: {
      strategies: [],
      activities: {
        value: ''
      },
      activitiesFrequency: '',
      approachDuration: '',
      outputs: [],
      dataMeasurementType: '',
      nonprofitPartners: false,
      nonprofitPartnersDescription:'',
    },
    researchAndEvaluation: {
      evidenceDescription: {
        optional: '',
        selected: []
      },
      studyDescription: {
        optional: '',
        selected: []
      },
      dataDescription: {
        optional: '',
        selected: []
      },
      environmentalOutputs: {
        selected: []
      },
      researchApproach: [],
      researchFile: {
        name: ''
      },
      outcomeDescription: [],
      strength: '',
      environmentalOutputValues: {
        quantity: 0,
        description: ''
      }
    },
    percentComplete: 0,
    progress:{
      general:{
        total:0,
        complete:0
      },
      impactAndScope:{
        total:0,
        complete:0
      },
      strategiesAndApproaches:{
        total:0,
        complete:0
      },
      researchAndEvaluation:{
        total:0,
        complete:0
      },
      funding:{
        total:0,
        complete:0
      },
    },
  },
  updateStatusSuccess: false,
};

export const fetchFundingbyUser = createAsyncThunk(
  'fundings/getFormsbyUserThunk',
  async (id: string, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getFundingbyUser(id);
    return data;
  },
);

export const updateFundingbyUserThunk = createAsyncThunk(
  'funding/updateFundingbyUser',
  async ({ funding, id }: { funding: any, id: string }) => {
    const response = await updateFundingbyUser(funding, id);
    return response.data;
  },
);

export const postFundingbyUserThunk = createAsyncThunk(
  'funding/postFundingbyUser',
  async ({ funding,id }: {  funding: any,id: string}) => {
    const response = await postFundingbyUser(funding,id);
    return response.data;
  },
);

// export const addFunding = createAsyncThunk(
//   'funding/addFunding',
//   async (funding: PostProgramRequest) => {
//     const response = await postProgram(program);
//     return response.data;
//   },
// );

export const fundingSlice = createSlice({
  name: 'funding',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchFundingbyUser.fulfilled, (state, action) => {
        state.funding = action.payload;
      })
      .addCase(updateFundingbyUserThunk.fulfilled, (state, action) => {
        state.funding = action.payload;
        state.updateStatusSuccess = true;
      })
  },
});

// export const { } = fundingSlice.actions;
export const fundingFormReducer = fundingSlice.reducer;