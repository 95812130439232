import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AccountTypeRequired, RoleRequired } from '../../app/Security';
import { NoMatch } from '../../app/NoMatch';
import { FlexBox } from '../../shared/components';
import { GIVEWITH_ROLES, VIEW_CONTRIBUTIONS_PERMISSION } from '../../shared/constants';
import { AccountType, UserRole } from '../../shared/enums';
import { AccountsRoutes } from '../accounts';
import { Approvals } from '../approvals';
import { DashboardRoute } from '../dashboard';
import { Fulfillment } from '../fulfillment';
import { Contribution } from '../incentive';
import { Users } from '../users';
import { Footer } from './Footer';
import { Header } from './header';
import { LeftNavBar } from './LeftNavBar';
import { Locales } from '../locales';
import { Instances } from '../instances';
import { CauseAreas, ViewCauseArea } from '../cause-areas';
import { ErrorLogs } from '../error-logs';
import { Suppliers } from '../suppliers';
import { ProcurementMetrics } from '../procurement-metrics';
import SIOListPage from '../sio/SioListPage';
import SioDetailsPage from '../sio/SioDetails';
import CreateSIO from '../sio/CreateSio';
import { CompanyIntelligence } from '../company-intelligence';
import ProgramList from '../program/ProgramList';
import CreateProgram from '../program/CreateProgram';
import CreateSioProgram from '../sio/program/CreateProgram';

import CompletionList from '../completion/CompletionList';
import CreateCompletion from '../completion/CreateCompletion';
import CompletionDetails from '../completion/CompletionDetails';
import CreateFunding from '../funding/CreateFunding';
import FundingDetails from '../funding/FundingDetails';
import FundingListPage from '../funding/FundingListPage';
import SioOrganizationInformation from '../sio/SioGeneralInformation';
import General from '../sio/orgainzation-information/General';
import OverviewandMission from '../sio/orgainzation-information/OverviewAndMission';
import OperationalInformation from '../sio/orgainzation-information/OperationalInformation';
import ReviewandSubmit from '../sio/orgainzation-information/ReviewandSubmit';
import ProgramDashboard from '../program/ProgramDashboard';
import ProgramGeneralQuestions from '../program/ProgramGeneralQuestions';
import ProgramContentQuestions from '../program/ProgramContentQuestions';
import ProgramImpactQuestions from '../program/ProgramImpactQuestions';
import ProgramStrategiesQuestions from '../program/ProgramStrategiesQuestions';
import ProgramResearchQuestions from '../program/ProgramResearchQuestions';
import ProgramFinanceQuestions from '../program/ProgramFinanceQuestion';
import ProgramFormSuccess from '../program/ProgramFormSuccess';
import ProgramDetails from '../program/ProgramDetails';
import FundingFormLandingPage from '../program-fundig-form/FundingFormLandingPage';
import FundingFormFillingPage from '../program-fundig-form/FundingFormFillingPage';
import FundingSuccess from '../program-fundig-form/FundingSuccess';
import ExecutedProgramDashboard from '../program/ExecutedProgram';

const routes = (
  <Routes>
    <Route
      path="dashboard"
      element={
        <AccountTypeRequired
          page
          types={[
            AccountType.default,
            AccountType.supplier,
            AccountType.procurement,
            AccountType.sio,
          ]}
        >
          <DashboardRoute />
        </AccountTypeRequired>
      }
    />
    <Route
      path="suppliers"
      element={
        <AccountTypeRequired page types={[AccountType.procurement]}>
          <Suppliers />
        </AccountTypeRequired>
      }
    />
    <Route
      path="metrics"
      element={
        <AccountTypeRequired page types={[AccountType.procurement]}>
          <ProcurementMetrics />
        </AccountTypeRequired>
      }
    />
    <Route path="company-intelligence/:id" element={<CompanyIntelligence />} />
    <Route
      path="users"
      element={
        <RoleRequired page roles={[...GIVEWITH_ROLES, UserRole.OrgAdmin, UserRole.UserAdmin]}>
          <Users />
        </RoleRequired>
      }
    />
    <Route
      path="sio"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <SIOListPage />
        </RoleRequired>
      }
    />
    <Route
      path="sio/create-sio"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <CreateSIO />
        </RoleRequired>
      }
    />
    <Route
      path="sio/:id"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <SioDetailsPage />
        </RoleRequired>
      }
    />
    <Route
      path="sio/organization-information"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <SioOrganizationInformation />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/organization-information/general-information"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <General />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/organization-information/overview-information"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <OverviewandMission />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/organization-information/operational-information"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <OperationalInformation />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/organization-information/review"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <ReviewandSubmit />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/programs"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <ProgramList />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/programs/create-program"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <CreateSioProgram />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/admin/programs/:id"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <ProgramDetails />
        </RoleRequired>
      }
    />

    <Route
      path="sio/programs/:id"
      element={
        <AccountTypeRequired page types={[AccountType.sio, AccountType.default]}>
          <ProgramDashboard />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/programs/:id/general-information"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <ProgramGeneralQuestions />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/programs/:id/impact-scope-information"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <ProgramImpactQuestions />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/programs/:id/strategies-approaches-information"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <ProgramStrategiesQuestions />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/programs/:id/research-evaluation-information"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <ProgramResearchQuestions />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/programs/:id/budget-information"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <ProgramFinanceQuestions />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/programs/:id/content-information"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <ProgramContentQuestions />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/programs/:id/success"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <ProgramFormSuccess />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/funding"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <FundingListPage />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/completions"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <CompletionList />
        </AccountTypeRequired>
      }
    />
    <Route
      path="programs"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <ProgramList />
        </RoleRequired>
      }
    />
    <Route
      path="programs/create-program"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <CreateProgram />
        </RoleRequired>
      }
    />
    <Route
      path="completions"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <CompletionList />
        </RoleRequired>
      }
    />
    <Route
      path="completions/create-completion"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <CreateCompletion />
        </RoleRequired>
      }
    />
    <Route
      path="/sio/admin/completions/:id"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <CompletionDetails />
        </RoleRequired>
      }
    />
    <Route
      path="/sio/completions/:id"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <ExecutedProgramDashboard />
        </AccountTypeRequired>
      }
    />

    {/* <Route
      path="sio/"
      element={
        <RoleRequired page roles={[...GIVEWITH_ROLES, UserRole.OrgAdmin, UserRole.UserAdmin]}>
          <SioDetailsPage />
        </RoleRequired>
      }
    /> */}
    <Route
      path="funding"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <FundingListPage />
        </RoleRequired>
      }
    />
    {
      // for SIO
    }
    <Route
      path="sio/funding/:id"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <FundingFormLandingPage />
        </AccountTypeRequired>
      }
    />

    <Route
      path="sio/funding/:id/edit"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <FundingFormFillingPage />
        </AccountTypeRequired>
      }
    />
    <Route
      path="sio/funding/:id/success"
      element={
        <AccountTypeRequired page types={[AccountType.sio]}>
          <FundingSuccess />
        </AccountTypeRequired>
      }
    />
    {
      // for admin
    }
    <Route
      path="sio/admin/funding/:id"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <FundingDetails />
        </RoleRequired>
      }
    />
    <Route
      path="funding/create-funding"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <CreateFunding />
        </RoleRequired>
      }
    />
    <Route
      path="fundings/:id"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.SIAdmin]}>
          <FundingDetails />
        </RoleRequired>
      }
    />
    <Route path="accounts/*" element={<AccountsRoutes />} />
    <Route
      path="locales"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.GivewithOperations]}>
          <Locales />
        </RoleRequired>
      }
    />
    <Route
      path="instances"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.GivewithOperations]}>
          <Instances />
        </RoleRequired>
      }
    />
    <Route
      path="cause-areas"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.GivewithOperations]}>
          <CauseAreas />
        </RoleRequired>
      }
    />
    <Route
      path="view-cause-area"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.GivewithOperations]}>
          <ViewCauseArea />
        </RoleRequired>
      }
    />
    <Route
      path="contribution/*"
      element={
        <RoleRequired page roles={VIEW_CONTRIBUTIONS_PERMISSION}>
          <Contribution />
        </RoleRequired>
      }
    />
    <Route
      path="approvals"
      element={
        <AccountTypeRequired types={[AccountType.default, AccountType.supplier]} page>
          <RoleRequired page roles={[...GIVEWITH_ROLES, UserRole.OrgAdmin, UserRole.Approver]}>
            <Approvals />
          </RoleRequired>
        </AccountTypeRequired>
      }
    />
    <Route
      path="fulfillment"
      element={
        <AccountTypeRequired types={[AccountType.default, AccountType.supplier]} page>
          <RoleRequired
            page
            roles={[...GIVEWITH_ROLES, UserRole.OrgAdmin, UserRole.Transactor, UserRole.Finance]}
          >
            <Fulfillment />
          </RoleRequired>
        </AccountTypeRequired>
      }
    />
    <Route
      path="error-log"
      element={
        <RoleRequired page roles={[UserRole.SuperAdmin, UserRole.GivewithOperations]}>
          <ErrorLogs />
        </RoleRequired>
      }
    />
    <Route
      path="admin"
      element={
        <RoleRequired roles={GIVEWITH_ROLES}>
          <Typography>Admin Screens</Typography>
        </RoleRequired>
      }
    />
    <Route path="*" element={<NoMatch />} />
  </Routes>
);

export function MainScreen() {
  const location = useLocation();
  return (
    <FlexBox sx={{ width: '100%', height: '100vh', background: '#EFEFEF' }}>
      <LeftNavBar />
      <FlexBox sx={{ height: '100vh', flex: 1, flexDirection: 'column', width: '78%' }}>
        <Header />
        <Box
          flex={1}
          px={1.5}
          pt={1}
          sx={{ borderRadius: '20px' }}
          overflow="auto"
          className="MainScreenOuterContainer"
        >
          <Paper
            elevation={0}
            sx={{ borderRadius: '20px', background: 'transparent', minHeight: '75vh' }}
            className="MainScreenInnerContainer"
          >
            {routes}
          </Paper>
          {location.pathname !== '/app/metrics' && <Footer />}
        </Box>
      </FlexBox>
    </FlexBox>
  );
}
