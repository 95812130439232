import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addCancelTokenEvent,
  getApprovalContributions,
  getContribution,
  getContributionProposal,
  postContributionProposal,
  contributionProposalVerify,
} from '../../api';
import { RootState } from '../../app';
import { AccountType } from '../../shared/enums';
import { GridRequest, GridResponse, Contribution } from '../../shared/interfaces';

export interface ContributionState {
  error?: string;
  incentive?: Contribution;
  selectedProgram?: string;
  loading: boolean;
  canApproveDenyIncentive: boolean;
}

const initialState: ContributionState = {
  selectedProgram: undefined,
  incentive: undefined,
  loading: false,
  canApproveDenyIncentive: false,
};

interface ContributionApproval extends GridRequest {
  accountId?: string;
  incentiveId?: string;
}

export const getContributionThunk = createAsyncThunk<Contribution, string, { state: RootState }>(
  'incentive/getContributionThunk',
  async (id, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const {
      app: { account },
    } = state;
    const accountId = account?.type === AccountType.procurement ? account.id : undefined;
    const payload = { contributionId: id, accountId };
    const response = await getContribution(payload);
    return response.data;
  },
);

export const getContributionApprovalThunk = createAsyncThunk(
  'incentive/getContributionApprovalThunk',
  async (incentive: Contribution, { signal }) => {
    addCancelTokenEvent(signal);
    const request: ContributionApproval = {
      offset: 0,
      count: 1,
      orderBy: 'lastUpdated',
      descending: true,
      incentiveId: incentive.incentiveId,
      accountId: incentive.account.id,
    };
    const response = await getApprovalContributions(request);
    return response.data;
  },
);
export const getContributionProposalThunk = createAsyncThunk(
  'incentive/getContributionProposalThunk',
  async (id: string, { signal }) => {
    addCancelTokenEvent(signal);
    const payload = id;
    const response = await getContributionProposal(payload);
    return response.data;
  },
);
export const postContributionProposalThunk = createAsyncThunk(
  'incentive/postContributionProposalThunk',
  async ({ id, password }: { id: string; password: string }, { signal }) => {
    addCancelTokenEvent(signal);
    const response = await postContributionProposal(id, password);
    return response.data;
  },
);
export const contributionProposalVerifyThunk = createAsyncThunk(
  'incentive/contributionProposalVerifyThunk',
  async ({ id, password }: { id: string; password: string }, { signal }) => {
    addCancelTokenEvent(signal);
    const response = await contributionProposalVerify(id, password);
    return response.data;
  },
);
const contributionSlice = createSlice({
  name: 'incentive',
  initialState,
  reducers: {
    setSelectedProgram: (state, action: PayloadAction<string | undefined>) => {
      state.selectedProgram = action.payload;
    },
    clearContributionState: (state) => {
      _.assign(state, initialState);
      state.incentive = undefined;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(
        getContributionApprovalThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<Contribution>>) => {
          state.loading = false;
          state.canApproveDenyIncentive = action.payload.results.length === 1;
        },
      )
      .addCase(getContributionApprovalThunk.rejected, (state) => {
        state.loading = false;
        state.canApproveDenyIncentive = false;
      })
      .addCase(getContributionApprovalThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(getContributionThunk.fulfilled, (state, action: PayloadAction<Contribution>) => {
        state.loading = false;
        state.incentive = action.payload;
        if (action.payload.selectedProgram) {
          state.selectedProgram = action.payload.selectedProgram;
        }
      })
      .addCase(getContributionThunk.rejected, (state) => {
        state.loading = false;
        state.incentive = undefined;
        window.history.back();
      })
      .addCase(getContributionThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(
        getContributionProposalThunk.fulfilled,
        (state, action: PayloadAction<Contribution>) => {
          state.loading = false;
          state.incentive = action.payload;
        },
      )
      .addCase(getContributionProposalThunk.rejected, (state) => {
        state.loading = false;
        state.incentive = undefined;
      })
      .addCase(
        postContributionProposalThunk.fulfilled,
        (state, action: PayloadAction<Contribution>) => {
          state.loading = false;
          state.incentive = action.payload;
        },
      )
      .addCase(postContributionProposalThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(postContributionProposalThunk.rejected, (state) => {
        state.error = 'Invalid Password Try again';
        state.loading = false;
        state.incentive = undefined;
      })
      .addCase(
        contributionProposalVerifyThunk.fulfilled,
        (state, action: PayloadAction<Contribution>) => {
          state.loading = false;
          state.incentive = action.payload;
        },
      )
      .addCase(contributionProposalVerifyThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(contributionProposalVerifyThunk.rejected, (state) => {
        state.error = 'Invalid Password Try again';
        state.loading = false;
        state.incentive = undefined;
      })
      .addCase(getContributionProposalThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      });
  },
});

export const { setSelectedProgram, clearContributionState } = contributionSlice.actions;

export const contributionReducer = contributionSlice.reducer;
