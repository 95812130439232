import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { addCancelTokenEvent, getContributions, getProposals } from '../../api';
import { DEFAULT_PAGE_SIZE } from '../../shared/constants';
import { ContributionStatus } from '../../shared/enums';
import { GridModel, GridRequest, GridResponse, Contribution } from '../../shared/interfaces';

interface ProcurementRequest extends GridRequest {
  status?: ContributionStatus;
  sharedWithId?: string;
}


interface ProposalGridRequest extends GridRequest {
  status: string | null | undefined;
}

export interface ProcurementState {
  grid: GridModel<Contribution>;
  selectedTab: ContributionStatus | null;
}

const initialState: ProcurementState = {
  grid: {
    proposal:[], 
    name: 'procurement',
    data: { totalCount: 0, results: [] },
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
    defaultSort: { field: 'lastUpdated', direction: 'desc' },
  },
  selectedTab: null,
};


export const getProcurementContributionsThunk = createAsyncThunk<
  GridResponse<Contribution>,
  ProcurementRequest
>('procurement/getProcurementThunk', async (request: ProcurementRequest, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getContributions(request);
  return data;
});


// export const getProposalListThunk = createAsyncThunk<
//   ProposalList
// >('procurement/getProposalThunk', async (request, { signal }) => {
//   addCancelTokenEvent(signal);
//   const { data } = await getProposals(request);
//   console.log("slice",data)
//   return data;
// });



export const getProposalListThunk = createAsyncThunk('procurement/getProposalThunk', async (request: ProposalGridRequest, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getProposals(request);
  return data;
});



// export const fetchFundingListThunk = createAsyncThunk('fundings/fetchFundingList', async (request: ProposalGridRequest, { signal }) => {
//   addCancelTokenEvent(signal);
//   const { data } = await getFundingList(request);
//   return data;
// });

const procurementSlice = createSlice({
  name: 'procurement Dashboard',
  initialState,
  reducers: {
    setProcurementGridModel: (state, action: PayloadAction<Partial<GridModel<Contribution>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearProcurement: (state) => {
      _.assign(state, initialState);
    },
    setProcurementTab: (state, action: PayloadAction<ContributionStatus | null>) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getProcurementContributionsThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<Contribution>>) => {
          state.grid.loadingGrid = false;
          state.grid.data = action.payload;
        },
      )
      .addCase(getProcurementContributionsThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getProcurementContributionsThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      })
      .addCase(
        getProposalListThunk.fulfilled,
        (state, action) => {
          state.grid.loadingGrid = false;
          state.grid.proposal = action.payload;
        },)
      .addCase(getProposalListThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getProposalListThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      });
      
  },
});

export const { setProcurementGridModel, clearProcurement, setProcurementTab } =
  procurementSlice.actions;

export const procurementReducer = procurementSlice.reducer;