/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, Typography, Box } from '@mui/material';
import { FlexBox } from '../../shared/components';
// import SellLogoColored from '../../shared/components/Logo';
import { COOKIES_POLICY_URL, PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '../../shared/constants';
import { getAllRightsReservedText } from '../../shared/utils';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { Dialogs } from '../../shared/enums';
import { setDialog } from './mainScreenSlice';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

const HEIGHT = window.innerHeight;

export function Footer() {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((x) => x.app);
  const { associatedInstance: currentInstance } = appState;

  return (
    <FlexBox
      sx={{
        display: 'flex',
        paddingX: 6,
        paddingBottom: 2,
        paddingTop: 2,
        gap: 4,
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#FFFFFF',
        borderTop: '1px solid #BCBEBF',
        borderRadius: '10px, 40px, 10px, 40px',
        height: '40px',
        position: 'relative',
        bottom: `-${HEIGHT * 0.022}px`,
      }}
    >
      <FlexBox sx={{ flex: 1, alignItems: 'end', gap: 2, position: 'relative' }}>
        <Box>
          <img
            src="https://static.scalewith.com/assets/v1/img/png/logo-scalewith-lct-120.8b82fd9357.png"
            alt="scalewith"
            style={{ height: '25px' }}
          />
        </Box>
        <Typography
          sx={{
            color: `${colors.textPrimary} !important`,
            fontSize: fonts.fontSize12,
            fontWeight: fonts.fontWeight400,
            textDecoration: 'none',
            paddingBottom: '8px',
          }}
        >
          {getAllRightsReservedText()}
        </Typography>
      </FlexBox>
      <Link
        href={currentInstance?.settings?.termsOfUseUrl ?? TERMS_OF_USE_URL}
        rel="noopener noreferrer"
        title="Terms of Use"
        target="_blank"
        aria-label="Terms & Conditions"
        sx={{
          color: `${colors.buttonDefault} !important`,
          fontSize: fonts.fontSize12,
          fontWeight: 700,
          textDecoration: 'none',
        }}
      >
        Terms of Use
      </Link>
      <Link
        href={currentInstance?.settings?.privacyPolicyUrl ?? PRIVACY_POLICY_URL}
        rel="noopener noreferrer"
        title="privacy policy"
        target="_blank"
        aria-label="privacy policy"
        sx={{
          color: `${colors.buttonDefault} !important`,
          fontSize: fonts.fontSize12,
          fontWeight: 700,
          textDecoration: 'none',
        }}
      >
        Privacy Policy
      </Link>
      <Link
        href={currentInstance?.settings?.cookiePolicyUrl ?? COOKIES_POLICY_URL}
        target="blank"
        rel="noopener noreferrer"
        title="cookie policy"
        aria-label="cookie policy"
        sx={{
          color: `${colors.buttonDefault} !important`,
          fontSize: fonts.fontSize12,
          fontWeight: 700,
          textDecoration: 'none',
        }}
      >
        Cookie Policy
      </Link>
      <Link
        sx={{
          color: `${colors.buttonDefault} !important`,
          fontSize: fonts.fontSize12,
          fontWeight: 700,
          textDecoration: 'none',
        }}
        onClick={() => dispatch(setDialog({ open: true, type: Dialogs.ContactUs, maxWidth: 'md' }))}
      >
        Contact Us
      </Link>
    </FlexBox>
  );
}
