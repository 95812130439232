import { Box, Step, StepContent, StepLabel, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { NoMatch } from '../../app/NoMatch';
import { AccountStatusRequired, RoleRequired } from '../../app/Security';
import { InvertedStepper } from '../../shared/components';
import { REGISTER_STEPS } from '../../shared/constants';
import { AccountStatus, FinancialChoice, RegisterStep, UserRole } from '../../shared/enums';
import { useAccount, useAppSelector, useAuth } from '../../shared/hooks';
import { CommonBody } from '../register/CommonBody';
import { RegisterFinalize } from './RegisterFinalize';
import { RegisterFinancialChoice } from './RegisterFinancialChoice';
import { RegisterStripeCancel } from './RegisterStripeCancel';
import { RegisterStripeSuccess } from './RegisterStripeSuccess';
import { RegisterSubscription } from './RegisterSubscription';

const routes = (
  <Routes>
    <Route
      path="financial-choice"
      element={
        <RoleRequired roles={[UserRole.OrgAdmin]}>
          <AccountStatusRequired statuses={[AccountStatus.Pending, AccountStatus.PendingStripe]}>
            <RegisterFinancialChoice />
          </AccountStatusRequired>
        </RoleRequired>
      }
    />
    <Route
      path="subscription"
      element={
        <RoleRequired roles={[UserRole.OrgAdmin, UserRole.Finance]}>
          <AccountStatusRequired
            statuses={[
              AccountStatus.Pending,
              AccountStatus.PendingStripe,
              AccountStatus.PendingFinance,
            ]}
          >
            <RegisterSubscription />
          </AccountStatusRequired>
        </RoleRequired>
      }
    />
    <Route
      path="finalize"
      element={
        <RoleRequired roles={[UserRole.OrgAdmin, UserRole.Finance]}>
          <AccountStatusRequired
            statuses={[
              AccountStatus.Pending,
              AccountStatus.PendingStripe,
              AccountStatus.PendingFinance,
            ]}
          >
            <RegisterFinalize />
          </AccountStatusRequired>
        </RoleRequired>
      }
    />
    <Route
      path="stripe/success"
      element={
        <RoleRequired roles={[UserRole.OrgAdmin, UserRole.Finance]}>
          <AccountStatusRequired
            statuses={[
              AccountStatus.PendingFinance,
              AccountStatus.PendingStripe,
              AccountStatus.Active,
            ]}
            page
          >
            <RegisterStripeSuccess />
          </AccountStatusRequired>
        </RoleRequired>
      }
    />
    <Route
      path="stripe/cancel"
      element={
        <AccountStatusRequired
          statuses={[AccountStatus.PendingFinance, AccountStatus.PendingStripe]}
          page
        >
          <RegisterStripeCancel />
        </AccountStatusRequired>
      }
    />
    <Route path="*" element={<NoMatch />} />
  </Routes>
);

export function RegisterDetails() {
  const financialChoice = useAppSelector((x) => x.registerDetails.financialChoice);
  const currentStep = useAppSelector((x) => x.registerDetails.currentStep);
  const navigate = useNavigate();
  const location = useLocation();
  const [isUserInRole] = useAuth();
  const { isAccountInStatus } = useAccount();

  const isStepHidden = (step: RegisterStep) => {
    switch (step) {
      case RegisterStep.AccountSelection: {
        return !(
          isUserInRole([UserRole.OrgAdmin]) &&
          isAccountInStatus([AccountStatus.Pending, AccountStatus.PendingStripe])
        );
      }
      case RegisterStep.SubscriptionSelection: {
        return financialChoice === FinancialChoice.InviteFinancialUser;
      }
      default:
        return false;
    }
  };

  const getCorrectNumber = (index: number) =>
    financialChoice === FinancialChoice.InviteFinancialUser && index === 3 ? 2 : index;

  const RegistrationStepper = (
    <Box sx={{ paddingTop: 8, paddingLeft: 4 }}>
      <InvertedStepper activeStep={currentStep} orientation="vertical">
        {REGISTER_STEPS.map((step, index) => (
          <Step key={step.label} hidden={isStepHidden(step.step)}>
            <StepLabel icon={getCorrectNumber(index + 1)}>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </InvertedStepper>
    </Box>
  );

  useEffect(() => {
    if (location.pathname.match(/\/financial-choice/i)) return;
    if (location.pathname.match(/\/stripe\//i)) return;
    if (financialChoice === -1) navigate('financial-choice');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CommonBody
      hideLogo
      coloredLogo
      LeftComponent={location.pathname.match(/\/stripe\//i) ? undefined : RegistrationStepper}
    >
      {routes}
    </CommonBody>
  );
}
