import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addCancelTokenEvent,
  deleteAutopay,
  deleteSubscription,
  getAccount,
  getAccountApprovals,
  getUsers,
  patchStripeSubscription,
  postAccount,
  postCustomerPortalSession,
  postSetupCheckoutSession,
  postSubscriptionCheckoutSession,
  putAccount,
  putAccountApprovals,
} from '../../../api';
import { RootState } from '../../../app';
import {
  AccountStatus,
  AccountSubscription,
  AccountType,
  StripeActionType,
} from '../../../shared/enums';
import {
  Account,
  AccountApprovalLevel,
  ApprovalLevel,
  ApproverUser,
  GridRequest,
  GridResponse,
  StripeCheckoutResponse,
  UserGrid,
  InstanceProgramSettingsCauseArea,
} from '../../../shared/interfaces';
import { isNullOrWhitespace, openNewWindow, redirect } from '../../../shared/utils';

export type ApprovalLevelsMode = 'list' | 'new' | 'edit';

export interface ApprovalsUsersGridRequest extends GridRequest {
  accountId: string;
  username?: string;
}

export interface GiveSettings {
  showCustomField1?: boolean;
  showCustomField2?: boolean;
  showCustomField3?: boolean;
  showCustomField4?: boolean;
}

interface AccountSettingsState {
  general: { account: Account; loading: boolean; modified: boolean };
  approvalLevels: {
    initialData?: AccountApprovalLevel;
    data?: AccountApprovalLevel;
    loading: boolean;
    usersLoading: boolean;
    mode: ApprovalLevelsMode;
    formData: {
      user?: ApproverUser | null;
      editingLevel?: ApprovalLevel;
      userText?: string;
      amount?: number;
    };
    users: GridResponse<UserGrid>;
  };
  subscription: {
    cancel: boolean;
    changeSubscription?: AccountSubscription;
  };
  payment: { disable: boolean; disabled: boolean };
  giveSettings: GiveSettings;
  editAccountId?: string;
  isAccountNew?: boolean;
  selectedTab: number;
  success: boolean;
  submitLoading: boolean;
  openStripePortalLoading: boolean;
  paymentMethods: any;
  subscriptionStatus: any;
}

const initialState: AccountSettingsState = {
  general: {
    account: {
      id: '',
      status: null,
      industry: null,
      company: {
        name: '',
        address: {
          address1: '',
          address2: '',
          city: '',
          stateProvince: null,
          postalCode: '',
          country: '',
        },
        countryCode: '',
        phoneNumber: '',
        website: '',
        companyLogo: '',
      },
      sageCustomerId: '',
      incentiveFields: {
        customerName: {
          displayOrder: 1,
          label: '',
          required: true,
          custom: false,
        },
        customerId: {
          displayOrder: 2,
          required: false,
          label: '',
          custom: false,
        },
        quoteNumber: {
          displayOrder: 3,
          label: '',
          required: false,
          custom: false,
        },
        quoteAmount: {
          displayOrder: 4,
          label: '',
          required: true,
          custom: false,
        },
        description: {
          displayOrder: 5,
          label: '',
          required: false,
          custom: false,
        },
        customField1: {
          displayOrder: 6,
          label: '',
          required: false,
          custom: true,
        },
        customField2: {
          displayOrder: 7,
          label: '',
          required: false,
          custom: true,
        },
        customField3: {
          displayOrder: 8,
          label: '',
          required: false,
          custom: true,
        },
        customField4: {
          displayOrder: 9,
          label: '',
          required: false,
          custom: true,
        },
      },
      instance: {
        id: '',
        name: '',
        type: 'instance_settings',
      },
      causeAreas: [],
    },
    loading: false,
    modified: false,
  },
  approvalLevels: {
    data: undefined,
    initialData: undefined,
    formData: {},
    mode: 'list',
    loading: false,
    usersLoading: false,
    users: { results: [], totalCount: 0 },
  },
  giveSettings: {
    showCustomField1: false,
    showCustomField2: false,
    showCustomField3: false,
    showCustomField4: false,
  },
  subscription: {
    cancel: false,
    changeSubscription: undefined,
  },
  payment: {
    disable: false,
    disabled: false,
  },
  selectedTab: 0,
  success: false,
  editAccountId: undefined,
  isAccountNew: false,
  submitLoading: false,
  openStripePortalLoading: false,
  paymentMethods: [],
  subscriptionStatus: {},
};

const getAccountId = (state: RootState) =>
  state.accountSettings.editAccountId ?? state.app.account?.id ?? '-1';

export const getAccountSettingsAccountThunk = createAsyncThunk<Account, void, { state: RootState }>(
  'accountSettings/getAccountSettingsAccountThunk',
  async (_void, { signal, getState }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const { data } = await getAccount(getAccountId(state));
    return data;
  },
);

const postAccountSettingsAccount = async (state: RootState) => {
  const { industry, company, sageCustomerId, incentiveFields, instance, type } =
    state.accountSettings.general.account;
  const { name, address, countryCode, phoneNumber, website, companyLogo } = { ...company };
  const { address1, address2, city, stateProvince, postalCode, country } = { ...address };

  const { status: responseStatus } = await postAccount({
    status: AccountStatus.New.toString(),
    industry,
    company: {
      name,
      address: {
        address1,
        address2,
        city,
        stateProvince,
        postalCode,
        country,
      },
      countryCode,
      phoneNumber,
      website,
      companyLogo,
    },
    sageCustomerId,
    incentiveFields,
    instance,
    type,
  });
  return responseStatus;
};

const putAccountSettingsAccount = async (accountId: string, state: RootState) => {
  const {
    status,
    industry,
    company,
    sageCustomerId,
    instance,
    giveFields,
    type,
    procurement,
    causeAreas,
  } = {
    ...state.accountSettings.general.account,
  };
  const { name, address, countryCode, phoneNumber, website, companyLogo } = { ...company };
  const { address1, address2, city, stateProvince, postalCode, country } = { ...address };

  const isProcurementAccount = type === AccountType.procurement;
  const { data } = await putAccount(accountId, {
    status,
    industry,
    company: {
      name,
      address: {
        address1,
        address2,
        city,
        stateProvince,
        postalCode,
        country,
      },
      countryCode,
      phoneNumber,
      website,
      companyLogo,
    },
    sageCustomerId,
    giveFields,
    instance,
    causeAreas,
    type,
    ...(isProcurementAccount && { procurement }),
  });
  return data;
};

const putAccountApprovalLevels = async (accountId: string, state: RootState) => {
  const { data: putData } = state.accountSettings.approvalLevels;
  const { data } = await putAccountApprovals(accountId, putData!);
  return data;
};

export const saveAccountSettingsThunk = createAsyncThunk<
  { account: Account; closeAfter: boolean },
  boolean,
  { state: RootState }
>('account/saveAccountSettingsThunk', async (closeAfter, { getState, signal }) => {
  addCancelTokenEvent(signal);
  const state = getState();
  const { accountSettings } = state;
  const { isAccountNew, approvalLevels, general } = accountSettings;
  const { initialData: approvalLevelsInitialData, data: approvalLevelsData } = approvalLevels;
  const { modified: generalModified, account } = general;

  let responseAccount = account;

  const id = getAccountId(state);

  if (isAccountNew) {
    if (generalModified) await postAccountSettingsAccount(state);
    return { account: responseAccount, closeAfter };
  }
  if (generalModified || account) {
    responseAccount = await putAccountSettingsAccount(id, state);
  }
  if (!_.isEqual(approvalLevelsInitialData, approvalLevelsData))
    await putAccountApprovalLevels(id, state);

  return { account: responseAccount, closeAfter };
});

export const getAccountApprovalLevelsThunk = createAsyncThunk<
  AccountApprovalLevel,
  void,
  { state: RootState }
>('accountSettings/getAccountApprovalLevelsThunk', async (_void, { signal, getState }) => {
  addCancelTokenEvent(signal);
  const state = getState();
  const { data } = await getAccountApprovals(getAccountId(state));
  return data;
});

export const getAccountApprovalLevelUsersThunk = createAsyncThunk(
  'accountSettings/getAccountApprovalLevelUsersThunk',
  async (request: ApprovalsUsersGridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getUsers(request);
    return data;
  },
);

export const postSetupCheckoutSessionAccountSettingsThunk = createAsyncThunk<
  StripeCheckoutResponse,
  StripeActionType,
  { state: RootState }
>(
  'accountSettings/postSetupCheckoutSessionAccountSettingsThunk',
  async (action, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const { data } = await postSetupCheckoutSession({
      accountId: getAccountId(state),
      actionType: action,
      cancelUrl: window.location.href,
      successUrl: window.location.href,
    });
    return data;
  },
);

export const postSubscriptionCheckoutSessionAccountSettingsThunk = createAsyncThunk<
  StripeCheckoutResponse,
  AccountSubscription,
  { state: RootState }
>(
  'accountSettings/postSubscriptionCheckoutSessionAccountSettingsThunk',
  async (value, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const { data } = await postSubscriptionCheckoutSession({
      accountId: getAccountId(state),
      frequency: value,
      cancelUrl: window.location.href,
      successUrl: window.location.href,
    });
    return data;
  },
);

export const postCustomerPortalSessionAccountSettingsThunk = createAsyncThunk<
  StripeCheckoutResponse,
  void,
  { state: RootState }
>(
  'accountSettings/postCustomerPortalSessionAccountSettingsThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const { data } = await postCustomerPortalSession(getAccountId(state));
    return data;
  },
);

export const deleteAccountSettingsAutopayThunk = createAsyncThunk<
  number,
  void,
  { state: RootState }
>('accountSettings/deleteAccountSettingsAutopayThunk', async (_void, { signal, getState }) => {
  addCancelTokenEvent(signal);
  const state = getState();
  const { status } = await deleteAutopay(getAccountId(state));
  return status;
});

export const deleteAccountSettingsSubscriptionThunk = createAsyncThunk<
  number,
  void,
  { state: RootState }
>('accountSettings/deleteAccountSettingsSubscriptionThunk', async (_void, { signal, getState }) => {
  addCancelTokenEvent(signal);
  const state = getState();
  const { status } = await deleteSubscription(getAccountId(state));
  return status;
});

export const patchAccountSettingsAccountSubscription = createAsyncThunk<
  number,
  void,
  { state: RootState }
>(
  'accountSettings/patchAccountSettingsAccountSubscription',
  async (_void, { signal, getState }) => {
    addCancelTokenEvent(signal);
    const state = getState();
    const { changeSubscription } = state.accountSettings.subscription;
    const { status } = await patchStripeSubscription({
      accountId: getAccountId(state),
      subscriptionFrequency: changeSubscription!,
    });
    return status;
  },
);

const accountSettingsSlice = createSlice({
  name: 'accountSettings',
  initialState,
  reducers: {
    setAccountSettingsMode: (
      state,
      action: PayloadAction<{ editAccountId?: string; isAccountNew?: boolean }>,
    ) => {
      const { editAccountId, isAccountNew } = action.payload;
      state.isAccountNew = isAccountNew;
      state.editAccountId = editAccountId;
    },
    setAccountSettingsAccountData: (state, action: PayloadAction<Account>) => {
      _.merge(state.general.account, action.payload);
      state.general.modified = true;
    },
    clearAccountSettingsState: (state) => {
      _.assign(state, initialState);
    },
    setAccountSettingsTab: (state, action: PayloadAction<number>) => {
      state.selectedTab = action.payload;
    },
    setApprovalLevelsMode: (state, action: PayloadAction<ApprovalLevelsMode>) => {
      state.approvalLevels.mode = action.payload;
    },
    clearApprovalLevelsForm: (state) => {
      state.approvalLevels.formData = {};
    },
    setApprovalLevelsForm: (state, action: PayloadAction<ApprovalLevel>) => {
      state.approvalLevels.formData.editingLevel = action.payload;
      state.approvalLevels.formData.user = action.payload.approver;
      state.approvalLevels.formData.userText = action.payload.approver.username;
      state.approvalLevels.formData.amount = action.payload.amount;
    },
    setApprovalLevelsUserForm: (state, action: PayloadAction<UserGrid | undefined | null>) => {
      state.approvalLevels.formData.user = action.payload;
    },
    setApprovalLevelsUserTextForm: (state, action: PayloadAction<string>) => {
      state.approvalLevels.formData.userText = action.payload;
    },
    setApprovalLevelsAmountForm: (state, action: PayloadAction<number | undefined>) => {
      state.approvalLevels.formData.amount = action.payload;
    },
    removeApprovalLevel: (state, action: PayloadAction<{ id: string; amount: number }>) => {
      const { id, amount } = action.payload;
      _.remove(
        state.approvalLevels!.data!.levels,
        (x) => x.approver.id === id && x.amount === amount,
      );
    },
    addApprovalLevel: (state, action: PayloadAction<ApprovalLevel>) => {
      state.approvalLevels!.data!.levels.push(action.payload);
    },
    toggleCustomField: (state, action: PayloadAction<GiveSettings>) => {
      _.merge(state.giveSettings, action.payload);
    },
    setAccountSettingsChangeSubscription: (
      state,
      action: PayloadAction<AccountSubscription | undefined>,
    ) => {
      state.subscription.changeSubscription = action.payload;
    },
    setAccountSettingsCancelSubscription: (state, action: PayloadAction<boolean>) => {
      state.subscription.cancel = action.payload;
    },
    setAccountSettingsDisableAutopay: (state, action: PayloadAction<boolean>) => {
      state.payment.disable = action.payload;
    },
    setAccountSettingsCauseArea: (
      state,
      action: PayloadAction<Array<InstanceProgramSettingsCauseArea>>,
    ) => {
      state.general.account.causeAreas = action.payload;
    },
    setAccountSettingsPaymentMethods: (state, action: PayloadAction<Array<any>>) => {
      state.general.account.paymentMethods = action.payload;
    },
    setAccountInstanceSettingsCauseArea: (
      state,
      action: PayloadAction<Array<InstanceProgramSettingsCauseArea>>,
    ) => {
      state.general.account.causeAreas = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAccountSettingsAccountThunk.fulfilled,
        (state, action: PayloadAction<Account>) => {
          state.general.account = action.payload;
          state.giveSettings.showCustomField1 = !isNullOrWhitespace(
            action.payload.incentiveFields?.customField1?.label,
          );
          state.giveSettings.showCustomField2 = !isNullOrWhitespace(
            action.payload.incentiveFields?.customField2?.label,
          );
          state.giveSettings.showCustomField3 = !isNullOrWhitespace(
            action.payload.incentiveFields?.customField3?.label,
          );
          state.giveSettings.showCustomField4 = !isNullOrWhitespace(
            action.payload.incentiveFields?.customField4?.label,
          );
          state.general.loading = false;
        },
      )
      .addCase(getAccountSettingsAccountThunk.rejected, (state) => {
        state.general.loading = false;
      })
      .addCase(getAccountSettingsAccountThunk.pending, (state) => {
        if (!state.general.loading) state.general.loading = true;
      })
      .addCase(
        getAccountApprovalLevelsThunk.fulfilled,
        (state, action: PayloadAction<AccountApprovalLevel>) => {
          state.approvalLevels.data = action.payload;
          state.approvalLevels.initialData = action.payload;
          state.approvalLevels.loading = false;
        },
      )
      .addCase(getAccountApprovalLevelsThunk.rejected, (state) => {
        state.approvalLevels.loading = false;
      })
      .addCase(getAccountApprovalLevelsThunk.pending, (state) => {
        if (!state.approvalLevels.loading) state.approvalLevels.loading = true;
      })
      .addCase(
        saveAccountSettingsThunk.fulfilled,
        (state, action: PayloadAction<{ account: Account; closeAfter: boolean }>) => {
          state.submitLoading = false;
          state.success = action.payload.closeAfter;
        },
      )
      .addCase(saveAccountSettingsThunk.rejected, (state) => {
        state.general.loading = false;
      })
      .addCase(saveAccountSettingsThunk.pending, (state) => {
        if (!state.submitLoading) state.submitLoading = true;
      })
      .addCase(
        getAccountApprovalLevelUsersThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<UserGrid>>) => {
          state.approvalLevels.users = action.payload;
          state.approvalLevels.usersLoading = false;
        },
      )
      .addCase(getAccountApprovalLevelUsersThunk.rejected, (state) => {
        state.approvalLevels.usersLoading = false;
      })
      .addCase(getAccountApprovalLevelUsersThunk.pending, (state) => {
        if (!state.approvalLevels.usersLoading) state.approvalLevels.usersLoading = true;
      })
      .addCase(
        postSetupCheckoutSessionAccountSettingsThunk.fulfilled,
        (state, action: PayloadAction<StripeCheckoutResponse>) => {
          redirect(action.payload.url);
        },
      )
      .addCase(postSetupCheckoutSessionAccountSettingsThunk.pending, (state) => {
        if (!state.general.loading) state.general.loading = true;
      })
      .addCase(postSetupCheckoutSessionAccountSettingsThunk.rejected, (state) => {
        state.general.loading = false;
      })
      .addCase(
        postSubscriptionCheckoutSessionAccountSettingsThunk.fulfilled,
        (state, action: PayloadAction<StripeCheckoutResponse>) => {
          redirect(action.payload.url);
        },
      )
      .addCase(postSubscriptionCheckoutSessionAccountSettingsThunk.pending, (state) => {
        if (!state.general.loading) state.general.loading = true;
      })
      .addCase(postSubscriptionCheckoutSessionAccountSettingsThunk.rejected, (state) => {
        state.general.loading = false;
      })
      .addCase(
        postCustomerPortalSessionAccountSettingsThunk.fulfilled,
        (state, action: PayloadAction<StripeCheckoutResponse>) => {
          state.openStripePortalLoading = false;
          openNewWindow(action.payload.url);
        },
      )
      .addCase(postCustomerPortalSessionAccountSettingsThunk.pending, (state) => {
        if (!state.general.loading) state.openStripePortalLoading = true;
      })
      .addCase(postCustomerPortalSessionAccountSettingsThunk.rejected, (state) => {
        state.openStripePortalLoading = false;
      })
      .addCase(deleteAccountSettingsAutopayThunk.fulfilled, (state) => {
        state.payment.disabled = true;
        state.general.loading = false;
      })
      .addCase(deleteAccountSettingsAutopayThunk.pending, (state) => {
        if (!state.general.loading) state.general.loading = true;
      })
      .addCase(deleteAccountSettingsAutopayThunk.rejected, (state) => {
        state.general.loading = false;
      })
      .addCase(deleteAccountSettingsSubscriptionThunk.fulfilled, (state) => {
        state.success = true;
        state.general.loading = false;
      })
      .addCase(deleteAccountSettingsSubscriptionThunk.pending, (state) => {
        if (!state.general.loading) state.general.loading = true;
      })
      .addCase(deleteAccountSettingsSubscriptionThunk.rejected, (state) => {
        state.general.loading = false;
      })
      .addCase(patchAccountSettingsAccountSubscription.fulfilled, (state) => {
        state.success = true;
        state.general.loading = false;
      })
      .addCase(patchAccountSettingsAccountSubscription.pending, (state) => {
        if (!state.general.loading) state.general.loading = true;
      })
      .addCase(patchAccountSettingsAccountSubscription.rejected, (state) => {
        state.general.loading = false;
      });
  },
});

export const {
  setAccountSettingsMode,
  setAccountSettingsAccountData,
  clearAccountSettingsState,
  setAccountSettingsTab,
  setApprovalLevelsMode,
  setApprovalLevelsForm,
  setApprovalLevelsAmountForm,
  setApprovalLevelsUserForm,
  clearApprovalLevelsForm,
  removeApprovalLevel,
  setApprovalLevelsUserTextForm,
  addApprovalLevel,
  toggleCustomField,
  setAccountSettingsCancelSubscription,
  setAccountSettingsDisableAutopay,
  setAccountSettingsChangeSubscription,
  setAccountSettingsCauseArea,
  setAccountInstanceSettingsCauseArea,
  setAccountSettingsPaymentMethods,
} = accountSettingsSlice.actions;

export const accountSettingsReducer = accountSettingsSlice.reducer;
