import { Box, Button, Radio } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getAppAccountThunk } from '../../../../../app';
import { CommonGridFilter, FormDialog, IsolatedGrid } from '../../../../../shared/components';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { getGenericHeight, getGridRequest, isNullOrWhitespace } from '../../../../../shared/utils';
import { ACCOUNTS_COLUMNS } from '../../../../accounts';
import {
  getSwitchAccountsThunk,
  setSwitchAccountGridModel,
  setSelectedAccountId,
  setSwitchAccountSuccess,
  clearSwitchAccountState,
} from './switchAccountSlice';
import { AccountStatus, Dialogs, GridColumns } from '../../../../../shared/enums';
import { CONTAINS_FILTER_OPERATOR } from '../../../../../shared/constants';
import { getInstances } from '../../../../../api';

export function SwitchAccount() {
  const state = useAppSelector((x) => x.switchAccount);
  // const instanceState = useAppSelector((x) => x.instances);
  const { grid, success, selectedAccountId } = state;
  const { sort, filter, page, pageSize } = grid;
  const dispatch = useAppDispatch();
  const [instances, setInstances] = useState<any>([]);
  const columns: Array<GridColDef> = [
    {
      field: 'radiobutton',
      headerName: '',
      width: 60,
      sortable: false,
      renderCell: (params) => (
        <Radio
          checked={selectedAccountId === params.id}
          value={params.id}
          onClick={() => {
            dispatch(setSelectedAccountId(params.id.toString()));
          }}
        />
      ),
    },

    ...ACCOUNTS_COLUMNS(
      instances.map((instance: any) => ({
        label: instance.name || '',
        value: instance.id || '',
      })),
    )
      .filter((x) => x.field !== 'address')
      .map((x) => ({ ...x, flex: 1 })),

    {
      field: 'status',
      headerName: 'Status',
      type: GridColumns.SingleSelect,
      valueOptions: Object.values(AccountStatus),
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
  ];

  const getInstanceData = async() => {
    const response = await getInstances({
      count: 10,
      offset: 0,
      orderBy: 'name',
      descending: false,
    });
    if (response) {
      setInstances(response?.data?.results);
    }
  }

  useEffect(() => {
    getInstanceData();
  }, []);

  useEffect(() => {
    dispatch(getSwitchAccountsThunk(getGridRequest(grid)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize]);

  useEffect(
    () => () => {
      dispatch(clearSwitchAccountState());
    },
    [dispatch],
  );

  const switchAccount = () => {
    dispatch(getAppAccountThunk(selectedAccountId!)).then(() =>
      dispatch(setSwitchAccountSuccess(true)),
    );
  };

  return (
    <FormDialog
      title="Switch Account"
      type={Dialogs.SwitchAccount}
      closeFlag={success}
      Buttons={
        <Button
          variant="contained"
          size="large"
          onClick={switchAccount}
          disabled={isNullOrWhitespace(selectedAccountId)}
        >
          Select
        </Button>
      }
    >
      <CommonGridFilter
        columns={columns}
        currentFilter={filter}
        onChange={(x) => {
          dispatch(
            setSwitchAccountGridModel({
              filter: x,
            }),
          );
        }}
      />
      <Box sx={{ minHeight: '400px', height: getGenericHeight(450), marginTop: 1 }}>
        <IsolatedGrid
          model={grid}
          columns={columns}
          alternativeName="switchAccount"
          onChange={(x) => dispatch(setSwitchAccountGridModel(x))}
          gridProps={{
            disableSelectionOnClick: true,
            selectionModel: selectedAccountId ?? '-1',
          }}
        />
      </Box>
    </FormDialog>
  );
}
