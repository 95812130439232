import { Box, Typography } from '@mui/material';
import parse from 'html-react-parser';
import React, { FC } from 'react';
import { FlexBox, FlexBoxColumn } from '../../shared/components';
import { Program } from '../../shared/interfaces';
import placeholderImage from '../../assets/images/Gray-Proposal-Image-Placeholder-Vertical.png';
import { SampleProgramPreview } from '../../shared/constants/urls';

interface Props {
  program: Program;
}

export const ProgramPageHeader: FC<Props> = ({ program }) => {
  const { sio, imagePortrait, name, description } = program;
  const { name: sioName } = sio;

  return (
    <FlexBox className="programHeader__container">
      <FlexBoxColumn className="FlexBoxColumn" sx={{ margin: '6rem', gap: '60px' }}>
        <Box>
          <img
            src="https://static.scalewith.com/assets/v1/img/png/logo-scalewith-lct-120.8b82fd9357.png"
            alt="scalewith"
            style={{ height: '40px' }}
          />
        </Box>
        <FlexBoxColumn className="programHeader__details">
          <Typography variant="h5">{sioName}</Typography>
          {!name || name.length <= 1 ? (
            <Typography variant="h3">Sample Program Title</Typography>
          ) : (
            <Typography variant="h3">{name}</Typography>
          )}
        </FlexBoxColumn>
        {!description || description.length <= 1 ? (
          <Typography className="programDescription">
            This is a sample description where you can tell potential funders more about your
            program initiatives. Please fill out your program information to replace this sample.
            <a href={SampleProgramPreview}>Click here</a> to see what it will look like in action
          </Typography>
        ) : (
          <Typography className="programDescription">{parse(description)}</Typography>
        )}
      </FlexBoxColumn>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          position: 'absolute',
          top: '30px',
          right: '50px',
        }}
      >
        <Typography
          sx={{
            marginTop: `45px`,
            position: 'absolute',
            top: '36px',
            fontSize: '17px',
            fontWeight: '500',
            display: 'contents',
            color: 'white',
          }}
        >
          Social Impact powered by &nbsp;
        </Typography>
        <img
          src="https://static.scalewith.com/assets/v1/img/png/logo-scalewith-lwt-70.c66d3531ba.png"
          alt=""
          style={{
            height: '35px',
            width: '137px',
          }}
        />
      </div>
      {!imagePortrait ? (
        <img alt="placeholder" src={placeholderImage} className="programImage" />
      ) : (
        <img alt="program" src={imagePortrait} className="programImage" />
      )}
    </FlexBox>
  );
};
