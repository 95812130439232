import React, { useEffect } from 'react';
import { Button, Tabs, Tab, Box } from '@mui/material';
import { FormDialog } from '../../../shared/components';
import { CauseAreaSettingsTabs, Dialogs } from '../../../shared/enums';
import { CauseAreaFormConfig } from '../../../shared/interfaces';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import {
  clearCauseAreaSettingsState,
  getCauseAreaInformationThunk,
  getCauseAreaInstanceAssociated,
  postCauseAreaThunk,
  putCauseAreaThunk,
  setCauseAreaSettingsTab,
} from './causeAreaSettingsSlice';
import { getGridRequest, isNullOrWhitespace } from '../../../shared/utils';
import { CauseAreaSettingsGeneral } from './CauseAreaSettingsGeneral';
import { CauseAreaSettingsInstances } from './CauseAreaSettingsInstances';

export const CauseAreaSettings = () => {
  const state = useAppSelector((x) => x.causeAreaSettings);
  const stateInstanceSettings = useAppSelector((x) => x.instanceSettings);
  const {
    values: {
      name,
      partnerName,
      detailLinkUrl,
      detailLinkText,
      description,
      listImageName,
      detailImageName,
      tooltip,
      skuPrefix,
      sageVendorId,
      active,
    },
    selectedTab,
    editCauseAreaId,
    loadingCauseArea,
    isNew,
    success,
    instanceAssociatedGrid,
  } = state;
  const { success: instanceSettingsSuccess } = stateInstanceSettings;

  const { filter, sort, page, pageSize } = instanceAssociatedGrid;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isNullOrWhitespace(editCauseAreaId)) return;
    dispatch(getCauseAreaInformationThunk(editCauseAreaId));
  }, [dispatch, editCauseAreaId]);

  useEffect(() => {
    if (isNullOrWhitespace(editCauseAreaId) || isNew) return;
    dispatch(
      getCauseAreaInstanceAssociated({
        causeAreaId: editCauseAreaId,
        ...getGridRequest(instanceAssociatedGrid),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize]);

  useEffect(() => {
    if (!instanceSettingsSuccess || isNullOrWhitespace(editCauseAreaId) || isNew) return;
    dispatch(
      getCauseAreaInstanceAssociated({
        causeAreaId: editCauseAreaId,
        ...getGridRequest(instanceAssociatedGrid),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceSettingsSuccess]);

  useEffect(
    () => () => {
      dispatch(clearCauseAreaSettingsState());
    },
    [dispatch],
  );

  const causeAreaFormConfig: CauseAreaFormConfig = {
    name: { value: name || '', label: 'Cause Area Name', required: true },
    partnerName: { value: partnerName || '', label: 'Partner', required: true },
    detailLinkUrl: { value: detailLinkUrl || '', label: 'Learn More URL', required: true },
    detailLinkText: {
      value: detailLinkText || '',
      label: 'Learn More URL Text',
      required: true,
    },
    description: { value: description || '', label: 'Cause Description', required: true },
    listImageName: { value: listImageName || '', label: 'List Image URL', required: true },
    detailImageName: { value: detailImageName || '', label: 'Detail Image URL', required: true },
    tooltip: { value: tooltip || '', label: 'Cause Image ALT Text', required: true },
    skuPrefix: { value: skuPrefix || '', label: 'SKU Prefix', required: true },
    sageVendorId: { value: sageVendorId || '', label: 'Sage Vendor ID', required: true },
    status: { value: active === undefined ? true : active, label: 'STATUS' },
  };

  const isCauseAreaFormInvalid = (formConfig: CauseAreaFormConfig) =>
    (formConfig.name?.required && isNullOrWhitespace(formConfig.name?.value)) ||
    (formConfig.partnerName?.required && isNullOrWhitespace(formConfig.partnerName?.value)) ||
    (formConfig.detailLinkUrl?.required && isNullOrWhitespace(formConfig.detailLinkUrl?.value)) ||
    (formConfig.detailLinkText?.required && isNullOrWhitespace(formConfig.detailLinkText?.value)) ||
    (formConfig.description?.required && isNullOrWhitespace(formConfig.description?.value)) ||
    (formConfig.listImageName?.required && isNullOrWhitespace(formConfig.listImageName?.value)) ||
    (formConfig.tooltip?.required && isNullOrWhitespace(formConfig.tooltip?.value)) ||
    (formConfig.skuPrefix?.required && isNullOrWhitespace(formConfig.skuPrefix?.value)) ||
    (formConfig.detailImageName?.required &&
      isNullOrWhitespace(formConfig.detailImageName?.value)) ||
    (formConfig.sageVendorId?.required && isNullOrWhitespace(formConfig.sageVendorId?.value));

  const handleSave = () => {
    if (isNew) {
      dispatch(postCauseAreaThunk());
    } else {
      dispatch(putCauseAreaThunk());
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setCauseAreaSettingsTab(newValue));
  };

  const getTabComponent = (tab: CauseAreaSettingsTabs) => {
    switch (tab) {
      case CauseAreaSettingsTabs.Instances:
        return <CauseAreaSettingsInstances />;
      case CauseAreaSettingsTabs.General:
      default:
        return (
          <CauseAreaSettingsGeneral
            value={causeAreaFormConfig}
            isCauseAreaFormInvalid={isCauseAreaFormInvalid}
          />
        );
    }
  };

  const getTitle = () => (isNew ? 'Add Cause Area' : 'Edit Cause Area');

  return (
    <FormDialog
      title={getTitle()}
      loading={loadingCauseArea}
      type={Dialogs.CauseAreaSettings}
      closeFlag={success}
      Buttons={
        <Button
          onClick={handleSave}
          disabled={isCauseAreaFormInvalid(causeAreaFormConfig)}
          variant="contained"
          size="large"
        >
          Save
        </Button>
      }
      ExtraHeaderComponent={
        !isNew ? (
          <Tabs value={selectedTab} onChange={handleTabChange} sx={{ paddingX: 6 }}>
            <Tab value={CauseAreaSettingsTabs.General} label="General" disabled={isNew} />
            <Tab
              value={CauseAreaSettingsTabs.Instances}
              label="Associated Instances"
              disabled={isNew}
            />
          </Tabs>
        ) : undefined
      }
    >
      <Box sx={{ minHeight: 545 }}>{getTabComponent(selectedTab)}</Box>
    </FormDialog>
  );
};
