import { Button, styled, Theme, useTheme, Box, CircularProgress } from '@mui/material';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ShareIcon from '@mui/icons-material/Share';
import UpdateIcon from '@mui/icons-material/Update';
import { useAppSelector, useAccount, useAuth } from '../../../shared/hooks';
import { RoleRequired } from '../../../app';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FlexBox, UpdateContributionStatusButton } from '../../../shared/components';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

import {
  APPROVE_DENY_PERMISSIONS,
  COMPLETE_ABANDON_PERMISSIONS,
  ENABLE_SAVE_PROPOSAL,
  FULFILL_REJECT_PERMISSIONS,
  UPDATE_DELETE_CONTRIBUTIONS_PERMISSIONS,
  RESEND_NOTIFICATIONS_PERMISSION,
  NEW_CONTRIBUTION_PERMISSIONS,
} from '../../../shared/constants';
import {
  AccountType,
  Dialogs,
  ContributionStatus,
  ContributionActions,
  ContributionStatusLabel,
} from '../../../shared/enums';

import { createPdfWithLinks, isNullOrWhitespace } from '../../../shared/utils';
import { setDialog } from '../../main-screen';

const actionButtonStyle = (theme: Theme) => ({
  width: 'auto',
  padding: '5px 16px',
  fontSize: fonts.fontSize14,
  fontWeight: fonts.fontWeight700,
  [theme.breakpoints.down('md')]: {
    width: '120px',
    padding: '5px',
  },
});

export const ActionButton = styled(Button)(({ theme }) => actionButtonStyle(theme));

interface Props {
  id: string;
  formInvalid: boolean;
  draftInvalid: boolean;
  submitIncentive: (status: ContributionStatus) => void;
  incentiveStatus?: string;
  canApproveDenyIncentive: boolean;
  proposal?: boolean;
  proposalText?: boolean;
  isApproval?: boolean;
}

export const IncentiveActionButtons: FC<Props> = (props) => {
  const theme = useTheme();
  const [ searchParams ] = useSearchParams();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isUserInRole] = useAuth();
  const [downloading, setDownloading] = useState<boolean>(false);
  const appState = useAppSelector((x: any) => x.app);
  const {
    account: { settings },
  } = appState;
  const {
    incentiveStatus,
    submitIncentive,
    formInvalid,
    draftInvalid,
    id,
    canApproveDenyIncentive,
    proposal,
    proposalText = false,
    isApproval,
  } = props;
  const { isAccountInType } = useAccount();
  const pdfDownloadName = searchParams.get('pdf_download_id') ?? 'proposalPage';


  const resendNotificationButton = (
    <RoleRequired roles={RESEND_NOTIFICATIONS_PERMISSION}>
      <ActionButton
        size="large"
        variant={proposal ? 'contained' : 'outlined'}
        color="info"
        sx={{ width: 180 }}
        onClick={() =>
          dispatch(setDialog({ open: true, type: Dialogs.ResendNotification, maxWidth: 'sm' }))
        }
      >
        Resend Notification
      </ActionButton>
    </RoleRequired>
  );

  const proposalTextStyle = {
    width: '200px',
    height: '50px',
    color: colors.primary,
    padding: '10px 10px',
    backgroundColor: 'white',
    fontWeight: '400',
    fontSize: 'inherit',
    lineHeight: '24.2px',
    marginRight: '10px',
    '&:hover': {
      color: 'white',
      backgroundColor: colors.primary,
    },
  };

  const iconStyle = {
    marginBottom: '4px',
    color: colors.textPrimary,
    cursor: 'pointer',
  }

  const shareFunction = () => {
    dispatch(setDialog({ open: true, type: Dialogs.ShareProposal, maxWidth: 'sm' }));
  };

  const getButtons = (status?: string) => {
    
    switch (status) {
      case ContributionStatus.Draft:
        return (
          <>
            <RoleRequired roles={NEW_CONTRIBUTION_PERMISSIONS}>
              <ActionButton
                size="large"
                variant="contained"
                disabled={!isUserInRole(ENABLE_SAVE_PROPOSAL) || formInvalid}
                onClick={() => submitIncentive(ContributionStatus.PendingApproval)}
              >
                Submit
              </ActionButton>
            </RoleRequired>
            <RoleRequired roles={NEW_CONTRIBUTION_PERMISSIONS}>
              <ActionButton
                size="large"
                variant={proposal ? 'contained' : 'outlined'}
                disabled={!isUserInRole(ENABLE_SAVE_PROPOSAL) || draftInvalid}
                onClick={() => submitIncentive(ContributionStatus.Draft)}
              >
                Save as Draft
              </ActionButton>
            </RoleRequired>
            <RoleRequired roles={UPDATE_DELETE_CONTRIBUTIONS_PERMISSIONS}>
              <UpdateContributionStatusButton
                proposedStatus={ContributionActions.Deleted}
                action={ContributionStatusLabel.Delete}
                id={id}
                sx={actionButtonStyle(theme)}
                disabled={!isUserInRole(ENABLE_SAVE_PROPOSAL)}
                showSuccessModal
              />
            </RoleRequired>
          </>
        );
      case null:
      case undefined:
        return (
          <>
            <RoleRequired roles={NEW_CONTRIBUTION_PERMISSIONS}>
              <ActionButton
                size="large"
                variant="contained"
                disabled={!isUserInRole(ENABLE_SAVE_PROPOSAL) || formInvalid}
                onClick={() => submitIncentive(ContributionStatus.PendingApproval)}
              >
                Submit
              </ActionButton>
            </RoleRequired>
            <RoleRequired roles={NEW_CONTRIBUTION_PERMISSIONS}>
              <ActionButton
                size="large"
                variant={proposal ? 'contained' : 'outlined'}
                disabled={!isUserInRole(ENABLE_SAVE_PROPOSAL) || draftInvalid}
                onClick={() => submitIncentive(ContributionStatus.Draft)}
              >
                Save as Draft
              </ActionButton>
            </RoleRequired>
          </>
        );
      case ContributionStatus.PendingApproval:
        return (
          <>
            {canApproveDenyIncentive && (
              <>
                {isApproval && <RoleRequired roles={APPROVE_DENY_PERMISSIONS}>
                  <UpdateContributionStatusButton
                    proposedStatus={ContributionActions.Approved}
                    action={ContributionStatusLabel.Approve}
                    id={id}
                    sx={{
                      ...actionButtonStyle(theme),
                      backgroundColor: colors.statusSuccess,
                    }}
                    buttonProps={{
                      variant: 'contained',
                      color: 'primary',
                      disabled: !isUserInRole(ENABLE_SAVE_PROPOSAL),
                    }}
                    showSuccessModal
                  />
                </RoleRequired>}
                <RoleRequired roles={APPROVE_DENY_PERMISSIONS}>
                  <UpdateContributionStatusButton
                    proposedStatus={ContributionActions.Denied}
                    action={ContributionStatusLabel.Deny}
                    id={id}
                    sx={{...actionButtonStyle(theme), color: colors.statusAlert}}
                    buttonProps={{ disabled: !isUserInRole(ENABLE_SAVE_PROPOSAL) }}
                    showSuccessModal
                  />
                </RoleRequired>
              </>
            )}
            <RoleRequired roles={UPDATE_DELETE_CONTRIBUTIONS_PERMISSIONS}>
              <UpdateContributionStatusButton
                proposedStatus={ContributionActions.Deleted}
                action={ContributionStatusLabel.Delete}
                id={id}
                sx={{...actionButtonStyle(theme), color: colors.statusAlert}}
                buttonProps={{ disabled: !isUserInRole(ENABLE_SAVE_PROPOSAL) }}
                showSuccessModal
              />
            </RoleRequired>
          </>
        );
      case ContributionStatus.Approved: {
        
        const approvedStatus = ContributionActions.PendingPayment;
        return (
          !isAccountInType([AccountType.procurement]) && <>
            <RoleRequired roles={COMPLETE_ABANDON_PERMISSIONS}>
              <UpdateContributionStatusButton
                proposedStatus={approvedStatus}
                action={ContributionStatusLabel.Complete}
                id={id}
                sx={{ ...actionButtonStyle(theme), backgroundColor: colors.statusSuccess }}
                buttonProps={{
                  variant: 'contained',
                  color: 'primary',
                  disabled: !isUserInRole(ENABLE_SAVE_PROPOSAL),
                }}
                showSuccessModal
              />
            </RoleRequired>
            <RoleRequired roles={COMPLETE_ABANDON_PERMISSIONS}>
              <UpdateContributionStatusButton
                proposedStatus={ContributionActions.Abandoned}
                action={ContributionStatusLabel.Abandon}
                id={id}
                sx={{...actionButtonStyle(theme), color: colors.statusAlert}}
                buttonProps={{ disabled: !isUserInRole(ENABLE_SAVE_PROPOSAL) }}
                showSuccessModal
              />
            </RoleRequired>
          </>
        );
      }
      case ContributionStatus.PendingPayment: {
        
        const approvedStatus = ContributionActions.PendingPayment;
        return (
          !isAccountInType([AccountType.procurement]) && <>
            <RoleRequired roles={COMPLETE_ABANDON_PERMISSIONS}>
              <UpdateContributionStatusButton
                proposedStatus={approvedStatus}
                action={ContributionStatusLabel.Complete}
                id={id}
                sx={{ ...actionButtonStyle(theme), backgroundColor: colors.statusSuccess }}
                buttonProps={{
                  variant: 'contained',
                  color: 'primary',
                  disabled: !isUserInRole(ENABLE_SAVE_PROPOSAL),
                }}
                showSuccessModal
              />
            </RoleRequired>
            <RoleRequired roles={COMPLETE_ABANDON_PERMISSIONS}>
              <UpdateContributionStatusButton
                proposedStatus={ContributionActions.Abandoned}
                action={ContributionStatusLabel.Abandon}
                id={id}
                sx={{...actionButtonStyle(theme), color: colors.statusAlert}}
                buttonProps={{ disabled: !isUserInRole(ENABLE_SAVE_PROPOSAL) }}
                showSuccessModal
              />
            </RoleRequired>
          </>
        );
      }
       
      case ContributionStatus.Completed:
        return (
          <>
            <RoleRequired roles={FULFILL_REJECT_PERMISSIONS}>
              <UpdateContributionStatusButton
                proposedStatus={ContributionActions.Fulfilled}
                action={ContributionStatusLabel.Fulfill}
                id={id}
                sx={{ ...actionButtonStyle(theme), backgroundColor: colors.statusSuccess }}
                buttonProps={{
                  variant: 'contained',
                  color: 'primary',
                  disabled: !isUserInRole(ENABLE_SAVE_PROPOSAL),
                }}
                showSuccessModal
              />
            </RoleRequired>
            <RoleRequired roles={FULFILL_REJECT_PERMISSIONS}>
              <UpdateContributionStatusButton
                proposedStatus={ContributionActions.Rejected}
                action={ContributionStatusLabel.Reject}
                id={id}
                sx={actionButtonStyle(theme)}
                buttonProps={{ disabled: !isUserInRole(ENABLE_SAVE_PROPOSAL) }}
                showSuccessModal
              />
            </RoleRequired>
            {resendNotificationButton}
          </>
        );
      case ContributionStatus.Fulfilled:
        return resendNotificationButton;
      default:
        return null;
    }
  };

  const convertComponentToPDF = async () => {
    setDownloading(true);
    createPdfWithLinks({
      pdfName: pdfDownloadName,
      elementId: 'Proposal_Page_Snapshot',
    }).then(() => {
      setDownloading(false);
    });
  };

  return (
    <>
      {proposalText && settings?.showProposalText && (
        <Button
          size="medium"
          variant="contained"
          onClick={() =>
            dispatch(setDialog({ open: true, type: Dialogs.ShowProposalText, maxWidth: 'md' }))
          }
          sx={proposalTextStyle}
        >
          <Box sx={{ paddingRight: '5px' }}>View Proposal Text</Box> <VisibilityIcon />
        </Button>
      )}
      {/* View History Button */}
      {!isNullOrWhitespace(id) && !isAccountInType([AccountType.procurement]) && (
        <UpdateIcon 
          onClick={() => {
            dispatch(
              setDialog({
                open: true,
                type: Dialogs.ViewContributionHistory,
                maxWidth: 'md',
              }),
            );
          }}
          sx={iconStyle}
        />
      )}
      {/* Download PDF Button */}
      {downloading === true ? 
        
        
        
        <CircularProgress size={22} sx={iconStyle} />
        :
        <FileDownloadIcon
          onClick={convertComponentToPDF}
          sx={iconStyle}
        />
        
      }
      <ShareIcon 
        onClick={shareFunction} 
        sx={iconStyle}
      />
      {/* <Button
        variant="contained"
        disableElevation
        size="medium"
        sx={{
          backgroundColor: `${colors.buttonDefault} !important`,
          height: '56px',
          borderRadius: '5px',
        }}
        onClick={convertComponentToPDF}
        className="download-pdf-in-background"
      >
        <FlexBox
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              marginLeft: '10px',
              fontWeight: 700,
            }}
          >
            Download PDF
          </Typography>
        </FlexBox>
      </Button> */}
      {/* <Button size="medium" variant="contained" onClick={shareFunction}>
        Share
      </Button> */}
      {/* Populates the action buttons depending on the incentive status */}
      {getButtons(incentiveStatus)}

      {/* Download PDF */}
      {/* <FlexBox sx={{ width: '60%', justifyContent: 'space-around' }}> */}
        {/* {convertComponentToPDF ? (
          <Button
            size="medium"
            variant="contained"
            onClick={convertComponentToPDF}
            // DO NOT REMOVE THIS FAKE CLASS AS THIS IS BEING USED BY SELENIUM DOWNLOAD PROPOSAL API
            className="download-pdf-in-background"
          >
            Download PDF
          </Button>
        ) : null}
        &nbsp; &nbsp; */}

      {/* </FlexBox> */}
  
      {/* Back Button */}
      {/* <ActionButton
        size="large"
        variant={proposal ? 'contained' : 'outlined'}
        onClick={() => navigate(-1)}
      >
        Back
      </ActionButton> */}
    </>
  );
};
