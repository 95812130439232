import React, { useState, ChangeEvent, useEffect } from 'react';
import _ from 'lodash';
// import { Grid, Card, CardContent, Divider, Typography, Button } from '@mui/material';
import {
  Box,
  Card,
  Typography,
  TextField,
  Button,
  FormControl,
  Switch,
  Select,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  Modal,
  Divider,
  InputAdornment,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { fetchFunding, updateFunding, removeFunding, downloadFileThunk } from './fundingSlice';
import { getUsersThunk, clearUsersState } from '../users/usersSlice';
import { getGridRequest } from '../../shared/utils';
import { Vocabulary, Locations } from '../../shared/interfaces/program/program';
// import {
//   fetchSios,
// } from '../sio/sioSlice';
// import { UpdateFundingRequest } from '../../shared/interfaces/funding/funding';
import '../sio/Sio.scss';
import { fundingAction, sendEmail } from '../../api/portal/fundingRequests';
import { getLocations, getVocab } from '../../api/portal/programRequest';
import { CellPhoneInput } from '../../shared/components';
// import info from '../../assets/svg/Union.svg';
import close from '../../assets/svg/closeIcon.svg';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

import {
  buttonPrimary,
  headingStyles,
  subHeadingStyles,
  hintstyles,
  accordianHeadingsStyles,
  statusStyles,
  accordianHeaderButtonStyles,
  alertButtonStyles,
  mainHeadingStyles,
  inputFieldStyles,
  inputFieldDisabledStyles,
  selectInputFieldStyles,
  inputLink,
  inputFieldParaDisabledStyles,
  checkAndRadioButtonDisabledStyles,
  checkAndRadioButtonMultiDisabledStyles,
  questionNoStyles,
  questionStyles,
  questionSubStyles,
  optionSubStyles,
} from './FundingStyles';

const lifetimeOutputForm = [{ quantity: 0, description: '' }];

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#FFFFFF',
  border: '1px solid #E6E6E6',
  boxShadow: 24,
  borderRadius: 5,
};

const successStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FFFFFF',
  width: '40vw',
  border: '1px solid #E6E6E6',
  boxShadow: 24,
  borderRadius: 5,
  padding: '30px 50px',
};

interface FundingData {
  id: string;
}

interface LocationState {
  isCreate: string;
}

const FundingDetails = () => {
  const { id } = useParams();
  // const sioState = useAppSelector((state) => state.sios);
  const accountId = useAppSelector((state) => state.app.account?.id!);
  const fundingState = useAppSelector((state) => state.fundings);
  const instance = useAppSelector((state) => state.app.associatedInstance);
  const location = useLocation();
  const isCreate = location.state as LocationState;
  const [openModal, setOpenModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const { funding } = fundingState;
  const success = useAppSelector((state) => state.editUser.success);
  const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const { grid, active } = usersState;
  const { page, pageSize, sort, filter } = grid;
  const navigate = useNavigate();
  const [fundingFormName, setFundingFormName] = useState('');
  const [fundingOpportunityName, setProposalName] = useState('');
  const [fundingStatus, setFundingStatus] = useState('');
  const [sioName, setSioName] = useState('');
  const [client, setBuyerName] = useState('');
  const [scalewithCustomer, setSellerName] = useState('');
  const [programSelected, setSelectedProgram] = useState('');
  const [openApprovedModal, setOpenApprovedModal] = React.useState('');
  // const [sio, setSio] = useState('')
  // const [dueDate, setDueDate] = useState<Date | null>(null);
  const [effectiveDate, setCreationDate] = useState(funding.effectiveDate);
  const [siAdmin, setSiAdmin] = useState('');
  const [fundingAmount, setFundingAmount] = useState('');
  const [fundingCurrency, setFundingCurrency] = useState('');
  const [notes, setNotes] = useState('');
  const [additionalTerms, setAdditionalTerms] = useState('');
  const [edit, setEdit] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [vocab, setVocab] = useState<Vocabulary>();
  const { general } = funding;
  const [locations, setLocations] = useState<Locations>();
  const [, setFiles] = useState<File>();

  //  const [locExpDetails, setLocaExpDetails] = useState({
  //   regions: false,
  //   countries: false,
  //   states: false,
  //   cities: false,
  // });

  // const [fundingName] = useState('')
  // const [vocab, setVocab] = useState<Vocabulary>()

  const themes = createTheme({
    palette: {
      primary: {
        light: colors.primary,
        main: colors.primary,
      },
    },
  });

  const updatedGrid = {
    ...grid,
    pageSize: 1000,
    defaultSort: { field: 'roles', direction: 'asc' as const },
    filter: { field: 'roles', simpleValue: 'sw_si_admin' },
  };
  const getUsersGridRequest = () => ({
    ...getGridRequest(updatedGrid),
    accountId,
  });

  const downloadFile = (file: any) => {
    if (_.has(file, 'url')) {
      dispatch(downloadFileThunk({ id, url: file.url })).then(() => {
        toast.success('File downloaded successfully');
      });
    } else {
      toast.error('Unable to fetch file url');
    }
  };

  const downloadTextButton = (file: any) => (
    <Box
      sx={(theme) => ({
        color: theme.palette.success.dark,
        textDecoration: 'underline',
        lineHeight: '23.8px',
        fontWeight: 400,
        fontSize: '14px',
        cursor: 'pointer',
        marginLeft: '8px',
        display: 'flex',
        alignItems: 'center',
      })}
      onClick={() => downloadFile(file)}
    >
      Download file
    </Box>
  );

  const initialFormValue = {
    phone: funding?.general?.contact?.phone,
    causes: funding?.impactAndScope?.causes ?? [],
    primaryImpact: funding?.impactAndScope?.primaryImpact ?? [],
    secondaryImpact: funding?.impactAndScope?.secondaryImpacts ?? [],
    targetGroup: funding?.impactAndScope?.targetGroup ?? [],
    audienceAge: funding?.impactAndScope?.audienceAge ?? [],
    strategies: funding?.strategiesAndApproaches?.strategies ?? [],
    dataMeasurementType: funding?.strategiesAndApproaches?.dataMeasurementType,
    approachDuration: funding?.strategiesAndApproaches?.approachDuration,
    evidenceDescription: funding?.researchAndEvaluation?.evidenceDescription?.selected ?? [],
    evidenceOptional: funding?.researchAndEvaluation?.evidenceDescription?.optional ?? '',
    studyDescription: funding?.researchAndEvaluation?.studyDescription?.selected ?? [],
    studyOptional: funding?.researchAndEvaluation?.studyDescription?.optional ?? '',
    dataDescription: funding?.researchAndEvaluation?.dataDescription?.selected ?? [],
    dataOptional: funding?.researchAndEvaluation?.dataDescription?.optional ?? '',
    researchApproach: funding?.researchAndEvaluation?.researchApproach ?? [],
    strength: funding?.researchAndEvaluation?.strength,
    outcomeDescription: funding?.researchAndEvaluation?.outcomeDescription ?? [],
    regions: funding?.impactAndScope?.locationDetails?.regions ?? [],
    countries: funding?.impactAndScope?.locationDetails?.countries ?? [],
    states: funding?.impactAndScope?.locationDetails?.states ?? [],
    cities: funding?.impactAndScope?.locationDetails?.cities ?? [],
    additionalLocationDetails: funding?.impactAndScope?.locationDetails?.additionalLocationDetails,
    locationExplanation: {
      regions: funding?.impactAndScope?.locationDetails?.locationExplanation?.regions ?? '',
      countries: funding?.impactAndScope?.locationDetails?.locationExplanation?.countries ?? '',
      states: funding?.impactAndScope?.locationDetails?.locationExplanation?.states ?? '',
      cities: funding?.impactAndScope?.locationDetails?.locationExplanation?.cities ?? '',
    },
    environmentalOutputValues: lifetimeOutputForm,
    environmentalOutputs: funding?.researchAndEvaluation?.environmentalOutputs?.selected ?? [],
    activities: funding?.strategiesAndApproaches?.activities?.value,
    activitiesFrequency: funding?.strategiesAndApproaches?.activitiesFrequency,
    outputs: lifetimeOutputForm,
    outcomes: lifetimeOutputForm,
    nonprofitPartners: funding?.strategiesAndApproaches?.nonprofitPartners ?? false,
    nonprofitPartnersDescription:
      funding?.strategiesAndApproaches?.nonprofitPartnersDescription ?? '',
    researchFile: funding?.researchAndEvaluation?.researchFile,
  };
  const [formValues, setFormValues] = useState(initialFormValue);
  const is1024 = useMediaQuery('(max-width:1024px)');
  const [isShown, setIsShown] = useState(true);
  const handleClick = () => {
    setIsShown(false);
  };
  // const [formValues, setFormValues] = useState<UpdateFundingRequest>({
  //   fundingFormName: '',
  //   // fundingOpportuinityName: '',
  //   effectiveDate:'',
  //   // orderId:'',
  //   fundingStatus: '',
  //   notes: '',
  //   // externalNotes: '',
  //   additionalTerms: '',
  //   siAdmin: '',
  //   editing:false
  // });

  const Roles = grid.data.results;
  const SiAdminRoles = Roles.filter((arr) => (arr.roles ? arr.roles.includes('sw_si_admin') : ''));

  // const SioNameOptions = sioState.grid.data.results
  // console.log(sioState.grid.data.results.map((nameData) => nameData.publicOrganizationName))
  const selectSIOptions = SiAdminRoles.map((option) => (
    <MenuItem key={option.id} value={option.id}>
      {`${option.firstName} ${option.lastName}`}
    </MenuItem>
  ));

  useEffect(() => {
    getVocab().then((res) => {
      if (res.status === 200) {
        setVocab(res.data);
      }
    });
    getLocations().then((res) => {
      if (res.status === 200) {
        setLocations(res.data);
      }
    });
    if (id) {
      dispatch(fetchFunding(id)).then((FundingData: any) => {
        setFundingFormName(FundingData.payload.fundingFormName);
        setProposalName(FundingData.payload.fundingOpportunityName);
        setFundingStatus(FundingData.payload.fundingStatus);
        // setSio(FundingData.payload.sio)
        setSiAdmin(FundingData.payload.siAdmin);
        setEdit(FundingData.payload.editing);
        setCreationDate(FundingData.payload.effectiveDate);
        setSioName(FundingData.payload.sioName);
        setBuyerName(FundingData.payload.client);
        setSellerName(FundingData.payload.scalewithCustomer);
        setSelectedProgram(FundingData.payload.general.programName.platformProgramName);
        setFundingAmount(FundingData.payload.fundingAmount);
        setFundingCurrency(FundingData.payload.fundingCurrency);
        setNotes(FundingData.payload.notes);
        setAdditionalTerms(FundingData.payload.additionalTerms);
        setFormValues({
          phone: FundingData.payload?.general?.contact?.phone,
          causes: FundingData.payload?.impactAndScope?.causes ?? [],
          primaryImpact: FundingData.payload?.impactAndScope?.primaryImpact ?? [],
          secondaryImpact: FundingData.payload?.impactAndScope?.secondaryImpacts ?? [],
          targetGroup: FundingData.payload?.impactAndScope?.targetGroup ?? [],
          audienceAge: FundingData.payload?.impactAndScope?.audienceAge ?? [],
          strategies: FundingData.payload?.strategiesAndApproaches?.strategies ?? [],
          dataMeasurementType: FundingData.payload?.strategiesAndApproaches?.dataMeasurementType,
          approachDuration: FundingData.payload?.strategiesAndApproaches?.approachDuration,
          evidenceDescription:
            FundingData.payload?.researchAndEvaluation?.evidenceDescription?.selected ?? [],
          evidenceOptional:
            FundingData?.payload?.researchAndEvaluation?.evidenceDescription?.optional ?? '',
          studyDescription:
            FundingData.payload?.researchAndEvaluation?.studyDescription?.selected ?? [],
          studyOptional:
            FundingData?.payload?.researchAndEvaluation?.studyDescription?.optional ?? '',
          dataDescription:
            FundingData.payload?.researchAndEvaluation?.dataDescription?.selected ?? [],
          dataOptional:
            FundingData?.payload?.researchAndEvaluation?.dataDescription?.optional ?? '',
          researchApproach: FundingData.payload?.researchAndEvaluation?.researchApproach ?? [],
          strength: FundingData.payload?.researchAndEvaluation?.strength,
          outcomeDescription: FundingData.payload?.researchAndEvaluation?.outcomeDescription ?? [],
          regions: FundingData.payload?.impactAndScope?.locationDetails?.regions ?? [],
          countries: FundingData.payload?.impactAndScope?.locationDetails?.countries ?? [],
          states: FundingData.payload?.impactAndScope?.locationDetails?.states ?? [],
          cities: FundingData.payload?.impactAndScope?.locationDetails?.cities ?? [],
          additionalLocationDetails:
            FundingData.payload?.impactAndScope?.locationDetails?.additionalLocationDetails,
          locationExplanation: {
            regions:
              FundingData.payload?.impactAndScope?.locationDetails?.locationExplanation?.regions ??
              '',
            countries:
              FundingData.payload?.impactAndScope?.locationDetails?.locationExplanation
                ?.countries ?? '',
            states:
              FundingData.payload?.impactAndScope?.locationDetails?.locationExplanation?.states ??
              '',
            cities:
              FundingData.payload?.impactAndScope?.locationDetails?.locationExplanation?.cities ??
              '',
          },
          environmentalOutputValues:
            FundingData.payload?.researchAndEvaluation?.environmentalOutputValues ??
            lifetimeOutputForm,
          environmentalOutputs:
            FundingData.payload?.researchAndEvaluation?.environmentalOutputs?.selected ?? [],
          activitiesFrequency: FundingData.payload?.strategiesAndApproaches?.activitiesFrequency,
          activities: FundingData.payload?.strategiesAndApproaches?.activities?.value,
          outputs: FundingData.payload?.strategiesAndApproaches?.outputs ?? lifetimeOutputForm,
          outcomes: FundingData.payload?.strategiesAndApproaches?.outcomes ?? lifetimeOutputForm,
          nonprofitPartners:
            FundingData.payload?.strategiesAndApproaches?.nonprofitPartners ?? false,
          nonprofitPartnersDescription:
            FundingData.payload?.strategiesAndApproaches?.nonprofitPartnersDescription ?? '',
          researchFile: FundingData.payload?.researchAndEvaluation?.researchFile,
        });
      });
    }
  }, [id]);

  // useEffect(() => {
  //   if (updateStatusSuccess) toast.success('New Funding Form created Successfully');
  // }, [updateStatusSuccess]);

  useEffect(() => {
    dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, active]);

  const getCurrencySymbol = (code: string) => {
    switch (code) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      case 'GBP':
        return '£';
      // Add more cases for other currency codes as needed
      default:
        return '$';
    }
  };

  const sumQuantity = funding?.funding?.itemization!.value.reduce(
    (sum, item) => sum + item.quantity,
    0,
  );

  useEffect(() => {
    if (success) dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  // useEffect(() => {

  //   dispatch(fetchSios({ status: 'approved', ...getGridRequest(sioState.grid) }));

  // }, [sioState.grid.sort, sioState.grid.filter, sioState.grid.page, sioState.status, sioState.grid.pageSize]);

  useEffect(
    () => () => {
      dispatch(clearUsersState());
    },
    [dispatch],
  );
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormValues({ ...formValues, [name]: value });
    if (name === 'fundingFormName') setFundingFormName(value);
    if (name === 'notes') setNotes(value);
    if (name === 'additionalTerms') setAdditionalTerms(value);
  };

  const handleOutputChange =
    (field: string, index: number, name: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (name === 'outputs') {
        const newLifetimeOutputs = [...formValues.outputs];
        newLifetimeOutputs[index] = { ...newLifetimeOutputs[index], [field]: value };
        setFormValues((prevValues) => ({
          ...prevValues,
          outputs: newLifetimeOutputs,
        }));
      } else {
        const newLifetimeOutputs = [...formValues.outcomes];
        newLifetimeOutputs[index] = { ...newLifetimeOutputs[index], [field]: value };
        setFormValues((prevValues) => ({
          ...prevValues,
          outcomes: newLifetimeOutputs,
        }));
      }
    };

  const getFieldValue = (indexNumber: number, field: string) => {
    let value;
    formValues?.environmentalOutputValues?.map((item, index) => {
      if (index === indexNumber) {
        if (field === 'quantity') {
          value = item.quantity;
        }
        if (field === 'description') {
          value = item.description;
        }
      }
      return 1;
    });
    return value;
  };

  // const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = event.target as HTMLInputElement;
  //   setFormValues({ ...formValues, [name]: value });
  //   setCompletionFormName(value);
  // };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target as HTMLInputElement;
    // setFormValues({ ...formValues, [name]: value });
    // if (name === "sio") {
    //   setSio(value);
    // }
    if (name === 'siAdmin') {
      setSiAdmin(value);
    }
    if (name === 'fundingStatus') {
      setFundingStatus(value);
    }
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const data = {
      funding: {
        fundingFormName,
        fundingStatus,
        siAdmin,
        effectiveDate,
        editing: edit,
        notes,
        additionalTerms,
      },
      id: funding.id,
    };
    const newFunding: FundingData = (await dispatch(updateFunding(data))).payload as FundingData;
    // console.log('newFunding',newFunding)
    if (newFunding?.id) {
      toast.success('Funding data updated Successfully');
      navigate(`/app/fundings/${newFunding.id}`);
    }
  };

  const handleOpenModal = (type: string) => {
    if (type === 'delete') {
      setOpenDeleteModal(true);
    } else {
      setOpenModal(true);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const NUMERIC_DASH_REGEX = /^[0-9-]+$/;
    if (name === 'phone') {
      if (NUMERIC_DASH_REGEX.test(value)) {
        setFormValues({ ...formValues, [name]: value });
      }
    } else if (name === 'nonprofitPartners') {
      if (value === 'yes') {
        setFormValues({ ...formValues, [name]: true });
      } else {
        setFormValues({ ...formValues, [name]: false, nonprofitPartnersDescription: '' });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const isChecked1 = (value: string) => {
    let returnValue = false;
    formValues.researchApproach.map((item: any) => {
      if (item._id === value) returnValue = true;
      return true;
    });
    return returnValue;
  };

  const isChecked = (data: any, value: string) => {
    let returnValue = false;
    data.map((item: any) => {
      const n = item.lastIndexOf(';');
      const result = item.substring(n + 1);
      if (result === value) {
        returnValue = true;
      }
      return null;
    });
    return returnValue;
  };

  const handleLifetimeOutputChange = (
    field: string,
    index: number,
    value: any,
    lifetimeOutput: any,
  ) => {
    const newLifetimeOutputs = [...formValues.environmentalOutputValues!];
    const label: any = vocab?.sccConversion?.filter((item: any) => item?._id === lifetimeOutput);
    newLifetimeOutputs[index] = {
      ...newLifetimeOutputs[index],
      [field]: value,
      description: label && label[0]?.label,
    };
    if (Number(value) >= 0) {
      setFormValues((prevValues) => ({
        ...prevValues,
        environmentalOutputValues: newLifetimeOutputs,
      }));
    }
  };

  // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = event.target;
  //   const NUMERIC_DASH_REGEX = /^[0-9-]+$/;
  //   if (name === 'phone') {
  //     if (NUMERIC_DASH_REGEX.test(value)) { setFormValues({ ...formValues, [name]: value }); }
  //   } else if (name === 'nonprofitPartners') {
  //     if (value === 'yes') {
  //       setFormValues({ ...formValues, [name]: true });
  //     } else {
  //       setFormValues({ ...formValues, [name]: false });
  //     }
  //   } else {
  //     setFormValues({ ...formValues, [name]: value });
  //   }
  // }

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'causes') {
      let newvalue = formValues.causes;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else if (formValues.causes.length <= 1) {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        causes: newvalue,
      });
    }
    if (name === 'secondaryImpact') {
      let newvalue = formValues.secondaryImpact;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else if (formValues.secondaryImpact.length < 3) {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        secondaryImpact: newvalue,
      });
    }
    if (name === 'targetGroup') {
      let newvalue = formValues.targetGroup;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        targetGroup: newvalue,
      });
    }
    if (name === 'audienceAge') {
      let newvalue = formValues.audienceAge;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        audienceAge: newvalue,
      });
    }
    if (name === 'regions') {
      if (value === 'N/A') {
        setFormValues({
          ...formValues,
          regions: ['N/A'],
        });
      } else {
        let newvalue = formValues.regions;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          regions: newvalue,
        });
      }
    }
    if (name === 'countries') {
      if (value === 'N/A') {
        setFormValues({
          ...formValues,
          countries: ['N/A'],
        });
      } else {
        let newvalue = formValues.countries;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          countries: newvalue,
        });
      }
    }
    if (name === 'states') {
      if (value === 'N/A') {
        setFormValues({
          ...formValues,
          states: ['N/A'],
        });
      } else {
        let newvalue = formValues.states;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          states: newvalue,
        });
      }
    }
    if (name === 'cities') {
      if (value === 'N/A') {
        setFormValues({
          ...formValues,
          cities: ['N/A'],
        });
      } else {
        let newvalue = formValues.cities;
        if (newvalue.includes('N/A')) {
          newvalue = [];
        }
        if (newvalue.includes(value)) {
          newvalue = newvalue.filter((item) => value !== item);
        } else {
          newvalue = [...newvalue, value];
        }
        setFormValues({
          ...formValues,
          cities: newvalue,
        });
      }
    }
    if (name === 'strategies') {
      let newvalue = formValues.strategies;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        strategies: newvalue,
      });
    }
    if (name === 'evidenceDescription') {
      let newvalue = formValues.evidenceDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        evidenceDescription: newvalue,
      });
    }
    if (name === 'studyDescription') {
      let newvalue = formValues.studyDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        studyDescription: newvalue,
      });
    }
    if (name === 'dataDescription') {
      let newvalue = formValues.dataDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        dataDescription: newvalue,
      });
    }
    if (name === 'outcomeDescription') {
      let newvalue = formValues.outcomeDescription;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        outcomeDescription: newvalue,
      });
    }
    if (name === 'researchApproach') {
      let newvalue = formValues.researchApproach;
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
      } else if (formValues.researchApproach.length < 3) {
        newvalue = [...newvalue, value];
      }
      setFormValues({
        ...formValues,
        researchApproach: newvalue,
      });
    }
    if (name === 'environmentalOutputs') {
      let newvalue = formValues.environmentalOutputs;
      let newOutputValues = [...formValues.environmentalOutputValues];
      const label: any = vocab?.sccConversion?.filter((voc: any) => voc?._id === value);
      const index = formValues?.environmentalOutputs?.indexOf(value);
      if (newvalue.includes(value)) {
        newvalue = newvalue.filter((item) => value !== item);
        newOutputValues?.splice(index, 1);
      } else {
        newvalue = [...newvalue, value];
        newOutputValues = [...newOutputValues, { quantity: 0, description: label[0]?.label }];
      }
      setFormValues({
        ...formValues,
        environmentalOutputs: newvalue,
        environmentalOutputValues: newOutputValues,
      });
    }
  };

  useEffect(() => {
    if (formValues && formValues?.environmentalOutputs?.length === 0) {
      setFormValues({
        ...formValues,
        environmentalOutputValues: [],
      });
    }
  }, [formValues?.environmentalOutputs]);
  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setFiles(fileList[0]);
  };

  const renderCountriesOptions = (country: any) =>
    country[0][1]?.map((items: any) => (
      <Box
        key={items.name}
        className={isChecked(formValues.countries, items.name) ? 'active' : ''}
        sx={checkAndRadioButtonMultiDisabledStyles}
      >
        <Typography variant="body1" display="inline">
          {items.name}
        </Typography>
        <Checkbox
          disabled
          name="countries"
          checked={isChecked(formValues.countries, items.name)}
          value={items.name}
          onChange={handleChangeSelect}
        />
      </Box>
    ));

  const renderCitiesOptions = (country: any) =>
    country.length > 0 &&
    country[0][1]?.map((items: any) => (
      <Box
        key={items.name}
        className={isChecked(formValues.cities, items.name) ? 'active' : ''}
        sx={{
          border: '1px solid #E0E0E0',
          display: 'inline-block',
          width: 'fit-content',
          m: 1,
          borderRadius: '20px',
          p: 2,
        }}
      >
        <Typography variant="body1" display="inline">
          {items.name}
        </Typography>
        <Checkbox
          disabled
          name="cities"
          checked={isChecked(formValues.cities, items.name)}
          value={items.name}
          onChange={handleChangeSelect}
        />
      </Box>
    ));

  const renderstatesOptions = (country: any) =>
    country.length > 0 &&
    country[0][1]?.map((items: any) => (
      <Box
        key={items.name}
        className={isChecked(formValues.states, items.name) ? 'active' : ''}
        sx={checkAndRadioButtonMultiDisabledStyles}
      >
        <Typography variant="body1" display="inline">
          {items.name}
        </Typography>
        <Checkbox
          disabled
          name="states"
          checked={isChecked(formValues.states, items.name)}
          value={items.name}
          onChange={handleChangeSelect}
        />
      </Box>
    ));

  const renderCountries = (region: string) => {
    const regions = Object.entries(locations!.countries);
    let countries: any = [];
    if (region !== 'N/A') {
      countries = regions.filter((item) => item[0] === region ?? item);
      return renderCountriesOptions(countries);
    }
    return true;
  };

  const renderStates = (region: string) => {
    const regions = Object.entries(locations!.states);
    let countries: any = [];
    if (region === 'United States' || region === 'Canada' || region !== 'N/A') {
      countries = regions.filter((item) => item[0] === region ?? item);
      return renderstatesOptions(countries);
    }
    return true;
  };

  const renderCities = (region: string) => {
    const regions = Object.entries(locations!.cities);
    let countries: any = [];
    if (region !== 'United States' && region !== 'Canada' && region !== 'N/A') {
      countries = regions.filter((item) => item[0] === region ?? item);
      return renderCitiesOptions(countries);
    }
    return true;
  };

  const handleAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSendForm = async () => {
    const data = {
      funding_form_link: `${instance?.settings?.portalUrl}/funding-form/${id}`,
    };
    if (id) sendEmail(id, data).then(() => setOpenSuccessModal(true));
  };

  const handleCancelFunding = () => {
    toast.success('Funding Form cancelled successfully');
    navigate('/app/funding');
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenDeleteModal(false);
    setOpenApprovedModal('');
  };

  const handleApprovedAction = async (action: string) => {
    const response = await fundingAction(funding.id, action.toLocaleLowerCase());
    if (response) {
      if (action === 'Reject') {
        navigate('/app/funding');
        toast.success('Funding Form Rejected Successfully');
      }
      if (action === 'Approved') {
        handleCloseModal();
        dispatch(fetchFunding(funding.id));
        toast.success(`Funding form ${action.toLocaleLowerCase()} successfully`);
      } else {
        handleCloseModal();
        toast.success(`Funding Form ${action.toLocaleLowerCase()} successfully`);
      }
    }
  };

  const renderButtons = () => {
    let buttons;
    if (funding.status === 'submitted') {
      buttons = (
        <>
          <Button
            onClick={() => setOpenApprovedModal('Archived')}
            sx={{ ml: 3 }}
            variant="outlined"
            size="large"
            color="primary"
          >
            Archive
          </Button>
          <Button
            onClick={() => setOpenApprovedModal('Rejected')}
            sx={{ ml: 3 }}
            variant="outlined"
            size="large"
            color="error"
          >
            Reject
          </Button>
          <Button
            onClick={() => setOpenApprovedModal('Approved')}
            sx={{ ml: 3 }}
            variant="contained"
            size="large"
            color="primary"
          >
            Approve
          </Button>
        </>
      );
    } else if (isCreate) {
      buttons = (
        <>
          <Button
            onClick={handleSendForm}
            sx={{ float: 'right', ml: 3 }}
            variant="contained"
            size="large"
            color="primary"
          >
            Send Form
          </Button>
          <Button
            onClick={() => handleOpenModal('cancel')}
            sx={{ float: 'right' }}
            variant="outlined"
            size="large"
            color="primary"
          >
            Cancel
          </Button>
        </>
      );
    } else {
      buttons = (
        <Button
          onClick={() => handleOpenModal('delete')}
          sx={{ ...alertButtonStyles, float: 'right', marginX: '20px' }}
          variant="outlined"
          size="large"
          color="error"
        >
          Delete Funding Form
        </Button>
      );
    }

    return buttons;
  };

  const StatusBadge = (status: String) => {
    let data;
    switch (status) {
      case 'in_progress':
        data = (
          <>
            <HourglassEmptyIcon fontSize="small" />{' '}
            <Typography sx={{ display: 'inline-block', position: 'relative' }} variant="button">
              In Progress {funding.percentComplete}%
            </Typography>
          </>
        );

        break;
      case 'approved':
        data = (
          <>
            <CheckCircleOutlineIcon />{' '}
            <Typography sx={{ display: 'inline-block', position: 'relative' }} variant="button">
              Approved
            </Typography>
          </>
        );

        break;
      case 'submitted':
        data = (
          <>
            <AccessTimeIcon />{' '}
            <Typography sx={{ display: 'inline-block', position: 'relative' }} variant="button">
              Submitted
            </Typography>
          </>
        );
        break;
      default:
    }
    return data;
  };

  const handleDeleteFunding = () => {
    if (id) dispatch(removeFunding(id));
    toast.success('Funding form deleted Successfully');
    navigate('/app/funding');
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    navigate('/app/funding');
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
  }

  return (
    <>
      <Modal
        open={openModal || openDeleteModal}
        onClose={handleCloseModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                bgcolor: `${openDeleteModal ? '#FDE3E3' : 'rgba(0,95,234,0.2)'}`,
                mb: 2,
                p: 2,
                borderRadius: '20px 20px 0 0',
              }}
            >
              <Typography variant="h5" color={openDeleteModal ? 'error' : 'primary'}>
                {openDeleteModal ? 'Delete Confirmation' : 'Cancel Confirmation'}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ p: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Are you sure you want to {openDeleteModal ? 'delete this' : 'cancel'}?
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {openDeleteModal
                  ? 'Deleting this record cannot be undone and all associated data will be permanently removed. Please confirm that you wish to proceed with the deletion.'
                  : 'Cancelling this process will redirect you to Funding Dashboard Page. Any unsaved data record will be lost permanently.'}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ p: 2, borderTop: '1px solid #E6E6E6' }}>
              {openDeleteModal ? (
                <>
                  <Button
                    onClick={handleDeleteFunding}
                    sx={{ float: 'right', ml: 3 }}
                    variant="contained"
                    size="large"
                    color="error"
                  >
                    Delete Funding Form
                  </Button>
                  <Button
                    onClick={handleCloseModal}
                    sx={{ float: 'right', ml: 3 }}
                    variant={openDeleteModal ? 'outlined' : 'contained'}
                    size="large"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={handleCloseModal}
                    sx={{ float: 'right', ml: 3 }}
                    variant="outlined"
                    size="large"
                    color="primary"
                  >
                    Stay
                  </Button>
                  <Button
                    onClick={handleCancelFunding}
                    sx={{ float: 'right', ml: 3 }}
                    variant={openDeleteModal ? 'outlined' : 'contained'}
                    size="large"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        open={openSuccessModal}
        onClose={handleCloseSuccessModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...successStyle }}>
          <Grid container>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <CloseIcon
                onClick={handleCloseSuccessModal}
                fontSize="medium"
                sx={{ position: 'absolute', right: '20px', top: '20px' }}
              />
              <CheckCircleOutlineIcon fontSize="large" color="success" />
              <Typography variant="h5" sx={{ mb: 3 }}>
                Submission Form Shared Successfully!
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                An email with below form link has been sent to your onboarded SIO Users
                successfully!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ border: '1px solid #c4bebe', borderRadius: '16px', padding: '20px' }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      Form Link
                      <Tooltip title="Click to copy URL">
                        <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                      </Tooltip>
                    </Typography>
                    <TextField
                      disabled
                      placeholder="No data yet"
                      fullWidth
                      value={`${instance?.settings?.portalUrl}/funding-form/${id}`}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '1rem',
                          },
                        },
                      }}
                      sx={{
                        '& .MuiInputBase-root': {
                          height: '50px',
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
                    <CopyToClipboard
                      text={`${instance?.settings?.portalUrl}/funding-form/${id}`}
                      onCopy={() => toast.success('URL Copied Successfully')}
                    >
                      <Button variant="text" color="success">
                        <FileCopyIcon fontSize="small" color="success" /> copy to clipboard
                      </Button>
                    </CopyToClipboard>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* approve, archive and reject modal */}
      <Modal
        open={openApprovedModal !== ''}
        onClose={handleCloseModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                bgcolor: `${openApprovedModal === 'Rejected' ? '#FDE3E3' : 'rgba(0,95,234,0.2)'}`,
                mb: 2,
                p: 2,
                borderRadius: '20px 20px 0 0',
              }}
            >
              <Typography variant="h5" color={openApprovedModal === 'Reject' ? 'error' : 'primary'}>
                {`${openApprovedModal} Confirmation`}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ p: 2 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Are you sure you want to {openApprovedModal === 'Approved' && 'approve'}
                {openApprovedModal === 'Archived' && 'archive'}
                {openApprovedModal === 'Rejected' && 'reject'} &quot;
                {funding.fundingFormName}&quot; Application?
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="right" sx={{ p: 2, borderTop: '1px solid #E6E6E6' }}>
              <Button
                onClick={handleCloseModal}
                sx={{ ml: 3 }}
                variant="outlined"
                size="large"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleApprovedAction(openApprovedModal)}
                sx={{ ml: 3 }}
                variant="contained"
                size="large"
                color={openApprovedModal === 'Rejected' ? 'error' : 'primary'}
              >
                {openApprovedModal}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Box sx={{ maxWidth: '100%', mx: 'auto', mb: 2, p: 2, borderRadius: '20px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            <span
              style={{
                fontSize: fonts.fontSize24,
                fontWeight: fonts.fontWeight700,
                color: colors.textPrimary,
              }}
            >
              {fundingFormName}
            </span>
            <Typography
              sx={{
                ...mainHeadingStyles,
              }}
              gutterBottom
            >
              <div
                style={{
                  ...(funding.status === 'approved' && {
                    backgroundColor: '#c3fae8',
                    color: '#099268',
                    border: '1px solid #099268',
                  }),
                  ...(funding.status === 'submitted' && {
                    backgroundColor: '#ffec99',
                    color: '#f08c00',
                    border: '1px solid #f08c00',
                  }),
                  ...(funding.status === 'in_progress' && {
                    backgroundColor: '#d0ebff',
                    color: '#1864ab',
                    border: '1px solid #313B72',
                  }),
                  borderRadius: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  height: '27px',
                  width: '197px',
                  fontSize: fonts.fontSize16,
                  fontWeight: fonts.fontWeight400,
                  padding: '6px 15px 6px 15px',
                  marginLeft: '20px',
                  justifyContent: 'center',
                }}
              >
                {StatusBadge(funding.status)}
              </div>
            </Typography>
          </Box>
          <Button
            variant="outlined"
            onClick={() => navigate('/app/funding')}
            sx={{ ...buttonPrimary, float: 'right', height: '48px' }}
          >
            Back
          </Button>
        </Box>
        <Box boxShadow={3} sx={{ borderRadius: '20px', bgcolor: 'white' }}>
          <Grid
            container
            spacing={2}
            sx={{
              width: '100%',
              margin: 0,
              border: '1px solid #f1f3f5',
              borderRadius: '20px',
              p: 3,
            }}
          >
            <Grid item xs={12}>
              <Typography sx={headingStyles} gutterBottom>
                Overview
              </Typography>
            </Grid>
            <Grid
              container
              spacing={3}
              sx={{
                width: '100%',
                margin: 0,
                alignItems: 'flex-end',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  Funding Form Name{' '}
                  <Tooltip title="Enter Funding Form Name">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <TextField
                  name="fundingFormName"
                  placeholder="Enter The Funding Form Name"
                  fullWidth
                  value={fundingFormName}
                  onChange={handleInputChange}
                  variant="outlined"
                  InputProps={{
                    sx: {
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '1rem',
                      },
                    },
                  }}
                  sx={inputFieldStyles}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  Proposal Name{' '}
                  <Tooltip title="Select Proposal Name">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    name="fundingOpportunityName"
                    placeholder="Funding opportunity name"
                    fullWidth
                    value={fundingOpportunityName}
                    disabled
                    variant="outlined"
                    InputProps={{
                      sx: {
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: '1rem',
                        },
                      },
                    }}
                    sx={inputFieldDisabledStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  Funding Status{' '}
                  <Tooltip title="Select Funding Status">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <FormControl fullWidth sx={inputFieldStyles}>
                  <Select
                    name="fundingStatus"
                    placeholder="Select Funding Status"
                    value={fundingStatus}
                    onChange={handleSelectChange}
                    variant="outlined"
                    sx={selectInputFieldStyles}
                  >
                    <MenuItem selected value="Invoiced">
                      Invoiced
                    </MenuItem>
                    <MenuItem selected value="Sent">
                      Sent
                    </MenuItem>
                    <MenuItem selected value="Received">
                      Received
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  Opportunity Name{' '}
                  <Tooltip title="Opportunity Name">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    name="fundingOpportunityName"
                    placeholder="Funding opportunity name"
                    fullWidth
                    value={fundingOpportunityName}
                    disabled
                    variant="outlined"
                    InputProps={{
                      sx: {
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: '1rem',
                        },
                      },
                    }}
                    sx={inputFieldDisabledStyles}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  ScaleWith Partnership Manager{' '}
                  <Tooltip title="ScaleWith Partnership Manager">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <FormControl fullWidth sx={inputFieldStyles}>
                  <Select
                    name="siAdmin"
                    labelId="manager-label"
                    placeholder="ScaleWith Partnership Manager"
                    value={siAdmin}
                    onChange={handleSelectChange}
                    variant="outlined"
                    sx={selectInputFieldStyles}
                  >
                    <MenuItem selected value="" disabled>
                      <em>ScaleWith Partnership Manager</em>
                    </MenuItem>
                    {selectSIOptions}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  Creation Date
                  <Tooltip title="Creation Date">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <FormControl fullWidth sx={inputFieldStyles}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        variant="outlined"
                        fullWidth
                        disabled
                        sx={inputFieldStyles}
                      />
                    )}
                    value={effectiveDate}
                    onChange={(date) => setCreationDate(date !== null ? date : '')}
                    // onChange={(date) => setFormValues({ ...formValues, effectiveDate: date !== null ? date : '' })}
                    toolbarPlaceholder="Select start date"
                    InputProps={{
                      sx: {
                        // border: '1px solid lightgrey',
                        '& .MuiOutlinedInput-root': {
                          height: '40px',
                          fontWeight: 'lighter',
                          fontSize: '1rem',
                          padding: '10px',
                        },
                        '& .MuiOutlinedInput-input': {
                          padding: '4px 12px 5px',
                        },
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: '1rem',
                        },
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  Program Selected
                  <Tooltip title="Program Name">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <TextField
                  name="programSelected"
                  fullWidth
                  value={programSelected}
                  disabled
                  variant="outlined"
                  InputProps={{
                    sx: {
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '1rem',
                      },
                    },
                  }}
                  sx={inputFieldDisabledStyles}
                />
              </Grid>

              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  SIO Name{' '}
                  <Tooltip title="SIO Name">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <TextField
                  name="sioName"
                  placeholder="SIO name"
                  fullWidth
                  value={sioName}
                  disabled
                  variant="outlined"
                  InputProps={{
                    sx: {
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '1rem',
                      },
                    },
                  }}
                  sx={inputFieldDisabledStyles}
                />
              </Grid>

              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  Funding Amount{' '}
                  <Tooltip title="Funding Amount">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <TextField
                  disabled
                  placeholder="With normal TextField"
                  value={fundingAmount}
                  variant="outlined"
                  size="small"
                  sx={inputFieldDisabledStyles}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            ...inputFieldDisabledStyles,
                            backgroundColor: colors.backgroundSecondary,
                            fontSize: "14px"
                          }}
                        >
                          {fundingCurrency}
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem', colour: '#C4C4C4' }}>
                <Typography sx={subHeadingStyles}>
                  Seller
                  <Tooltip title="Seller Name">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <TextField
                  name="sellerName"
                  placeholder="Enter The seller Name"
                  fullWidth
                  value={scalewithCustomer}
                  disabled
                  variant="outlined"
                  InputProps={{
                    sx: {
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '1rem',
                      },
                    },
                  }}
                  sx={inputFieldDisabledStyles}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  Buyer
                  <Tooltip title="Buyer Name">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <TextField
                  name="buyer"
                  placeholder="Buyer Name"
                  fullWidth
                  value={client}
                  disabled
                  variant="outlined"
                  InputProps={{
                    sx: {
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '1rem',
                      },
                    },
                  }}
                  sx={inputFieldDisabledStyles}
                />
              </Grid>

              <Grid item xs={12} sm={4} textAlign="left" sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  Allow Editing{' '}
                  <Tooltip title="Enable editing">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <ThemeProvider theme={themes}>
                  <Switch
                    sx={{ mb: 2 }}
                    color="primary"
                    checked={edit}
                    onClick={() => setEdit(!edit)}
                  />
                </ThemeProvider>
              </Grid>

              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  Funding Form URL{' '}
                  <Tooltip title="Funding Form URL">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <TextField
                  name="fundingFormUrl"
                  placeholder="Enter The Funding Form URL"
                  fullWidth
                  disabled
                  margin="normal"
                  value={`${instance?.settings?.portalUrl}/funding-form/${id}`}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CopyToClipboard
                          text={`${instance?.settings?.portalUrl}/funding-form/${id}`}
                          onCopy={() => toast.success('URL Copied Successfully')}
                        >
                          <FileCopyIcon />
                        </CopyToClipboard>
                      </InputAdornment>
                    ),
                    sx: {
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '1rem',
                      },
                    },
                  }}
                  sx={inputLink}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  Additional Terms
                  <Tooltip title="Enter additional terms">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <TextField
                  name="additionalTerms"
                  placeholder="Enter additional terms"
                  fullWidth
                  value={additionalTerms}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    sx: {
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '1rem',
                      },
                    },
                  }}
                  sx={inputFieldStyles}
                />
              </Grid>

              <Grid item xs={12} sm={4} sx={{ marginBottom: '1rem' }}>
                <Typography sx={subHeadingStyles}>
                  Notes
                  <Tooltip title="Enter Notes">
                    <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                  </Tooltip>
                </Typography>
                <TextField
                  name="notes"
                  placeholder="Enter notes here"
                  fullWidth
                  value={notes}
                  onChange={handleInputChange}
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    sx: {
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '1rem',
                      },
                    },
                  }}
                  sx={inputFieldStyles}
                />
              </Grid>

              <Grid item xs={12} sm={4} sx={{ marginBottom: '2rem' }}>
                <Button
                  variant="outlined"
                  // color="primary"
                  disabled={
                    !fundingFormName ||
                    // !formValues.fundingOpportuinityName ||
                    !effectiveDate ||
                    !fundingStatus ||
                    !siAdmin
                  }
                  onClick={handleSubmit}
                  sx={{ ...buttonPrimary, '&:hover': {
                    backgroundColor: colors.primary,
                    color: colors.secondary,
                  }}}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box boxShadow={3} sx={{ bgcolor: 'white', borderRadius: '20px' }}>
          <Grid
            container
            spacing={2}
            sx={{
              width: '100%',
              margin: 0,
              borderRadius: '20px',
              p: 3,
              marginTop: '2rem',
            }}
          >
            <Grid item xs={12} sx={{ marginBottom: '2rem', display: 'flex' }}>
              <Typography sx={headingStyles}>Form Preview</Typography>
              {isShown && (
                <Box
                  sx={{
                    border: '1px solid #0063B599',
                    borderRadius: '10px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    gap: '10px',
                    marginLeft: '20px',
                    backgroundColor: '#E9F5FF',
                  }}
                >
                  {/* <img
                    style={{ marginLeft: '15px', marginTop: `${is1024 ? '10px' : 'auto'}` }}
                    src={info}
                    alt="info icon"
                    width="24px"
                    height="24px"
                  /> */}
                  <InfoIcon sx={{ color: colors.primary, marginX: '10px' }} />
                  <Typography
                    sx={{
                      ...hintstyles,
                      width: `${is1024 ? '410px' : 'auto'}`,
                    }}
                  >
                    Please Note: Below information is the read only preview of the form that your
                    SIO users will receive.
                  </Typography>
                  <Box onClick={handleClick}>
                    <img
                      style={{ marginRight: '15px', marginTop: `${is1024 ? '15px' : '5px'}` }}
                      src={close}
                      alt="close icon"
                      width="14px"
                      height="14px"
                    />
                  </Box>
                </Box>
              )}
            </Grid>
            {funding && (
              <Grid item xs={12}>
                {/* Funding information Accordion */}

                <Accordion
                  expanded={expanded === 'funding'}
                  onChange={handleAccordion('funding')}
                  sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === 'funding' ? (
                        <RemoveIcon sx={accordianHeaderButtonStyles} />
                      ) : (
                        <AddIcon sx={accordianHeaderButtonStyles} />
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={9}>
                      <Typography sx={accordianHeadingsStyles} gutterBottom>
                        Program Funding Information
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={statusStyles} gutterBottom>
                        {funding?.progress?.funding?.complete
                          ? funding?.progress?.funding?.complete
                          : 0}{' '}
                        of{' '}
                        {funding?.progress?.funding?.total ? funding?.progress?.funding?.total : 3}{' '}
                        Completed
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                        <Card
                          className="smallCards"
                          sx={{ borderColor: '#C4C4C4', boxShadow: 'none' }}
                        >
                          <Typography sx={questionNoStyles}>
                            Question 1 of 3
                            <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                              *
                            </span>
                          </Typography>
                          <Typography sx={questionStyles}>
                            What are the start and end dates of this program?
                            <Tooltip title="Enter the start and end dates of this program">
                              <InfoIcon
                                sx={{ fontSize: '20px', color: '#868e96', marginLeft: '3px' }}
                              />
                            </Tooltip>
                          </Typography>
                          <Grid
                            container
                            spacing={3}
                            sx={{
                              width: '100%',
                              margin: 0,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Grid item xs={12} sm={6} sx={{ marginBottom: '1rem' }}>
                              <Typography sx={optionSubStyles}>
                                Start Date
                                <Tooltip title="Start Date">
                                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                                </Tooltip>
                              </Typography>
                              <FormControl fullWidth margin="normal" sx={inputFieldDisabledStyles}>
                                <DatePicker
                                  disabled
                                  value={funding?.funding?.programDate?.start}
                                  onChange={(newValue) => {
                                    console.log(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} variant="outlined" size="small" />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ marginBottom: '1rem' }}>
                              <Typography sx={optionSubStyles}>
                                End Date
                                <Tooltip title="End Date">
                                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                                </Tooltip>
                              </Typography>
                              <FormControl fullWidth margin="normal" sx={inputFieldDisabledStyles}>
                                <DatePicker
                                  disabled
                                  value={funding?.funding?.programDate?.end}
                                  onChange={(newValue) => {
                                    console.log(newValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} variant="outlined" size="small" />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>

                      {/* <Grid container spacing={2}> */}
                      <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                        <Card
                          className="smallCards"
                          sx={{ borderColor: '#C4C4C4', boxShadow: 'none' }}
                        >
                          <Typography sx={questionNoStyles}>
                            Question 2 of 3
                            <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                              *
                            </span>
                          </Typography>

                          <Typography sx={{ ...questionStyles, marginBottom: '1.5rem' }}>
                            Please specify the location where this funding will be applied.
                            <Tooltip title="Enter the location where this funding will be applied">
                              <InfoIcon
                                sx={{ fontSize: '20px', color: '#868e96', marginLeft: '3px' }}
                              />
                            </Tooltip>
                          </Typography>
                          <Grid item xs={12} sm={6} sx={{ marginBottom: '1rem' }}>
                            <Typography sx={optionSubStyles}>
                              Location Description
                              <Tooltip title="Location Description">
                                <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                              </Tooltip>
                            </Typography>
                            <TextField
                              disabled
                              placeholder="No data yet"
                              fullWidth
                              value={funding?.funding?.location?.value}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                sx: {
                                  '& .MuiInputBase-input::placeholder': {
                                    fontSize: '1rem',
                                  },
                                },
                              }}
                              sx={inputFieldDisabledStyles}
                            />
                          </Grid>
                        </Card>
                      </Grid>
                      {/* </Grid> */}
                      <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                        <Card
                          className="smallCards"
                          sx={{ borderColor: '#C4C4C4', boxShadow: 'none' }}
                        >
                          <Typography sx={questionNoStyles}>Question 3 of 3</Typography>

                          <Typography sx={questionStyles}>
                            How will this funding be used?
                          </Typography>
                          <Typography sx={{ ...questionSubStyles, marginBottom: '1.5rem' }}>
                            Please ensure itemization adds up to the total funding amount.
                          </Typography>

                          <TextField
                            disabled
                            placeholder="With normal TextField"
                            variant="outlined"
                            value={sumQuantity}
                            size="small"
                            sx={inputFieldDisabledStyles}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Box>$</Box>
                                </InputAdornment>
                              ),
                            }}
                          />
                          <Typography
                            sx={{ ...questionSubStyles, marginBottom: '1.5rem', marginTop: '5px' }}
                          >
                            Of {getCurrencySymbol(fundingCurrency)}
                            {fundingAmount} Itemized
                          </Typography>

                          <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
                            {funding?.funding?.itemization?.value.map((item, index: any) => (
                              <>
                                <Grid item xs={12} sm={4}>
                                  <Typography sx={{ mb: 1 }} variant="subtitle1">
                                    {`Item ${index + 1} Quantity`}{' '}
                                    <Tooltip title={`Enter item ${index + 1} quantity`}>
                                      <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                                    </Tooltip>
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      border: '1px solid lightgrey',
                                    }}
                                  >
                                    <AttachMoneyIcon
                                      sx={{
                                        color: 'action.active',
                                        background: 'lightgrey',
                                        padding: '10px',
                                        margin: '0',
                                        height: '30px',
                                        width: '45px',
                                      }}
                                    />
                                    <TextField
                                      id={`quantity-${index}`}
                                      name={`quantity-${index}`}
                                      margin="normal"
                                      placeholder="Enter item quantity"
                                      value={item.quantity}
                                      disabled
                                      InputProps={{
                                        sx: {
                                          '& .MuiInputBase-input::placeholder': {
                                            fontSize: '1rem',
                                          },
                                          '& .MuiInputBase-input': {
                                            padding: '0',
                                          },
                                        },
                                      }}
                                      sx={{
                                        marginBottom: '0',
                                        marginTop: '0',
                                        '& .MuiInputBase-root': {
                                          height: '50px',
                                        },
                                        '& .MuiInput-root:before': {
                                          borderBottom: 'none',
                                        },
                                        '& .MuiInput-root:after': {
                                          borderBottom: 'none',
                                        },
                                        '& .MuiInput-root:hover': {
                                          borderBottom: 'none',
                                        },
                                      }}
                                    />
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography sx={{ mb: 1 }} variant="subtitle1">
                                    {`Item ${index + 1} Description`}{' '}
                                    <Tooltip title={`Enter item ${index + 1} description`}>
                                      <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                                    </Tooltip>
                                  </Typography>
                                  <TextField
                                    name={`description-${index}`}
                                    placeholder="Enter item description"
                                    value={item.description}
                                    disabled
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                      sx: {
                                        '& .MuiInputBase-input::placeholder': {
                                          fontSize: '1rem',
                                        },
                                      },
                                    }}
                                    sx={{
                                      '& .MuiInputBase-root': {
                                        height: '50px',
                                      },
                                    }}
                                  />
                                </Grid>
                              </>
                            ))}
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {/* General information Accordion */}
                <Accordion
                  expanded={expanded === 'general'}
                  onChange={handleAccordion('general')}
                  sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === 'general' ? (
                        <RemoveIcon sx={accordianHeaderButtonStyles} />
                      ) : (
                        <AddIcon sx={accordianHeaderButtonStyles} />
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={9}>
                      <Typography sx={accordianHeadingsStyles} gutterBottom>
                        General Information
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={statusStyles} gutterBottom>
                        {funding?.progress?.general?.complete
                          ? funding?.progress?.general?.complete
                          : 0}{' '}
                        of{' '}
                        {funding?.progress?.general?.total ? funding?.progress?.general?.total : 6}{' '}
                        Completed
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 1 of 3
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Who will be the main contact person for this program?
                          <Tooltip title="Enter the details of main contact person for this program">
                            <InfoIcon
                              sx={{ fontSize: '20px', color: '#868e96', marginLeft: '3px' }}
                            />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={optionSubStyles}>First Name & Last Name</Typography>
                        <TextField
                          disabled
                          placeholder="No data yet"
                          fullWidth
                          value={general?.contact?.name}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            sx: {
                              '& .MuiInputBase-input::placeholder': {
                                fontSize: '1rem',
                              },
                            },
                          }}
                          sx={inputFieldDisabledStyles}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <Typography sx={optionSubStyles}>Email Address</Typography>
                        <TextField
                          disabled
                          type="email"
                          placeholder="No data yet"
                          fullWidth
                          value={general?.contact?.email}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            sx: {
                              '& .MuiInputBase-input::placeholder': {
                                fontSize: '1rem',
                              },
                            },
                          }}
                          sx={inputFieldDisabledStyles}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography sx={optionSubStyles}>Phone Number</Typography>
                        <TextField
                          disabled
                          placeholder="No data yet"
                          fullWidth
                          type="text"
                          value={general?.contact?.phone}
                          margin="normal"
                          variant="outlined"
                          error={
                            general?.contact?.phone !== undefined &&
                            general?.contact?.phone!.length > 0 &&
                            (general?.contact?.phone![0] === '0' ||
                              general?.contact?.phone![0] === '1' ||
                              (general?.contact?.phone!.length < 10 &&
                                general?.contact?.phone !== ''))
                          }
                          InputProps={{
                            inputComponent: CellPhoneInput as any,
                            sx: {
                              '& .MuiInputBase-input::placeholder': {
                                fontSize: '1rem',
                              },
                            },
                          }}
                          sx={inputFieldDisabledStyles}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 2 of 3
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Please provide a name for this program.
                          <Tooltip title="Please provide a name for this program">
                            <InfoIcon
                              sx={{ fontSize: '20px', color: '#868e96', marginLeft: '3px' }}
                            />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={optionSubStyles}>Internal Program Name</Typography>
                        <TextField
                          disabled
                          placeholder="No data yet"
                          fullWidth
                          value={general?.programName?.internalProgramName}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            sx: {
                              '& .MuiInputBase-input::placeholder': {
                                fontSize: '1rem',
                              },
                            },
                          }}
                          sx={inputFieldDisabledStyles}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={optionSubStyles}>Platform Program name</Typography>
                        <TextField
                          disabled
                          placeholder="No data yet"
                          fullWidth
                          value={general?.programName?.platformProgramName}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            sx: {
                              '& .MuiInputBase-input::placeholder': {
                                fontSize: '1rem',
                              },
                            },
                          }}
                          sx={inputFieldDisabledStyles}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 3 of 3
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Please provide a description of this program.
                          <Tooltip title="Please provide a description of this program">
                            <InfoIcon
                              sx={{ fontSize: '20px', color: '#868e96', marginLeft: '3px' }}
                            />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={optionSubStyles}>Description</Typography>
                        <TextField
                          disabled
                          placeholder="No data yet"
                          fullWidth
                          value={general?.programDescription?.description}
                          margin="normal"
                          variant="outlined"
                          multiline
                          maxRows={4}
                          sx={inputFieldParaDisabledStyles}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {/* Impact and scope Accordion */}
                <Accordion
                  expanded={expanded === 'impact'}
                  onChange={handleAccordion('impact')}
                  sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === 'impact' ? (
                        <RemoveIcon sx={accordianHeaderButtonStyles} />
                      ) : (
                        <AddIcon sx={accordianHeaderButtonStyles} />
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={9}>
                      <Typography sx={accordianHeadingsStyles} gutterBottom>
                        Impact and Scope
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={statusStyles} gutterBottom>
                        {funding?.progress?.impactAndScope?.complete
                          ? funding?.progress?.impactAndScope?.complete
                          : 0}{' '}
                        of{' '}
                        {funding?.progress?.impactAndScope?.total
                          ? funding?.progress?.impactAndScope?.total
                          : 5}{' '}
                        Completed
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 1 of 12
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Which cause areas best match this program&apos;s work?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Please select up to two from the list below.
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.causes.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={formValues.causes.includes(item._id) ? 'active' : ''}
                              sx={checkAndRadioButtonMultiDisabledStyles}
                            >
                              <Typography variant="body1" display="inline">
                                {item.label}
                              </Typography>

                              <Checkbox
                                disabled
                                onChange={handleChangeSelect}
                                name="causes"
                                checked={formValues.causes.includes(item._id)}
                                value={item._id}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 2 of 12
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          What is this program&apos;s primary targeted impact?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Impact are long-term effects of your program. Please select one option
                          from the list below.
                        </Typography>
                      </Grid>
                      {vocab?.causes.map((item: any) => (
                        <Grid key={item._id} sx={{ my: 1 }} item xs={12}>
                          <Box sx={checkAndRadioButtonMultiDisabledStyles}>
                            <Typography sx={{ ...commonTextStyle.heading, my: 2 }} display="inline">
                              {item.label}
                            </Typography>
                            <br />
                            <Grid item xs={12}>
                              <FormControl>
                                <RadioGroup
                                  sx={{ display: 'inline-block' }}
                                  name="primaryImpact"
                                  value={formValues.primaryImpact}
                                  onChange={handleChange}
                                >
                                  {vocab?.impact.map((subitem: any) => {
                                    if (subitem.causeId === item._id) {
                                      return (
                                        <FormControlLabel
                                          sx={checkAndRadioButtonMultiDisabledStyles}
                                          labelPlacement="start"
                                          key={subitem._id}
                                          value={subitem._id}
                                          control={<Radio disabled />}
                                          label={subitem.label}
                                        />
                                      );
                                    }
                                    return true;
                                  })}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Box>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 3 of 12
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          What are the secondary impacts of this program?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Impacts are long-term effects of your program. Please select up to three
                          the list below, or N/A if not applicable.
                        </Typography>
                      </Grid>
                      {vocab?.impact.map((subItem: any) => {
                        if (subItem?.label === 'N/A') {
                          return (
                            <Grid item>
                              <Box
                                className={
                                  formValues.secondaryImpact.includes(subItem?._id) ? 'active' : ''
                                }
                                sx={checkAndRadioButtonMultiDisabledStyles}
                              >
                                <Typography variant="body1" display="inline">
                                  N/A
                                </Typography>
                                <Checkbox
                                  name="secondaryImpact"
                                  checked={formValues.secondaryImpact.includes(subItem?._id)}
                                  value={subItem?._id}
                                  disabled
                                />
                              </Box>
                            </Grid>
                          );
                        }
                        return true;
                      })}
                      {vocab?.causes.map((item: any) => (
                        <Grid key={item._id} sx={{ my: 1 }} item xs={12}>
                          <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                            <Typography sx={{ ...commonTextStyle.heading, my: 2 }} display="inline">
                              {item.label}
                            </Typography>
                            <br />
                            {vocab?.impact.map((subItem: any) => {
                              if (subItem.causeId === item._id) {
                                return (
                                  <Box
                                    key={subItem._id}
                                    className={
                                      formValues.secondaryImpact.includes(subItem._id)
                                        ? 'active'
                                        : ''
                                    }
                                    sx={checkAndRadioButtonMultiDisabledStyles}
                                  >
                                    <Typography variant="body1" display="inline">
                                      {subItem.label}
                                    </Typography>
                                    <Checkbox
                                      onChange={handleChangeSelect}
                                      name="secondaryImpact"
                                      checked={formValues.secondaryImpact.includes(subItem._id)}
                                      value={subItem._id}
                                      disabled
                                    />
                                  </Box>
                                );
                              }
                              return true;
                            })}
                          </Box>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 6 of 12
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Does this program specifically target any of the following groups of
                          people?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Please select all that apply.
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.audience.attribute.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={formValues.targetGroup.includes(item._id) ? 'active' : ''}
                              sx={checkAndRadioButtonMultiDisabledStyles}
                            >
                              <Typography variant="body1" display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                disabled
                                name="targetGroup"
                                checked={formValues.targetGroup.includes(item._id)}
                                value={item._id}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 7 of 12
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          What age groups does this program serve?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Please select all that apply.
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.audience.age.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={formValues.audienceAge.includes(item._id) ? 'active' : ''}
                              sx={checkAndRadioButtonMultiDisabledStyles}
                            >
                              <Typography variant="body1" display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                name="audienceAge"
                                checked={formValues.audienceAge.includes(item._id)}
                                value={item._id}
                                disabled
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionStyles}>
                          Question 8 of 12
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          In which specific regions does this program operate?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', questionSubStyles }}
                        >
                          Please select all that apply.
                        </Typography>
                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                          }}
                        >
                          <Grid item xs={12} textAlign="center" sm={5}>
                            <Box
                              className={
                                formValues?.locationExplanation?.regions !== '' ? 'active' : ''
                              }
                              sx={checkAndRadioButtonDisabledStyles}
                            >
                              <Typography display="inline">
                                Able to operate in anywhere (please explain)
                              </Typography>
                              <Checkbox
                                name="regions"
                                disabled
                                checked={formValues?.locationExplanation?.regions !== ''}
                                value="N/A"
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                              display:
                                formValues?.locationExplanation?.regions === ''
                                  ? 'none'
                                  : 'initial',
                            }}
                          >
                            <Typography sx={questionSubStyles}>Description</Typography>
                            <TextField
                              name="localExplanation"
                              placeholder="Enter description here"
                              fullWidth
                              disabled
                              value={formValues?.locationExplanation?.regions}
                              // onChange={handleChange}
                              margin="normal"
                              variant="outlined"
                              multiline
                              rows={4}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid sx={{ my: 2 }} item xs={12}>
                        <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                          <Typography sx={{ my: 2, ...questionSubStyles }} display="inline">
                            Regions
                          </Typography>
                          <br />
                          {locations?.continents.map((subItem: any) => (
                            <Box
                              key={subItem.name}
                              className={formValues.regions.includes(subItem.name) ? 'active' : ''}
                              sx={checkAndRadioButtonMultiDisabledStyles}
                            >
                              <Typography variant="body1" display="inline">
                                {subItem.name}
                              </Typography>
                              <Checkbox
                                disabled
                                name="regions"
                                checked={formValues.regions.includes(subItem.name)}
                                value={subItem.name}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 9 of 12
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          In which specific countries does this program operate?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Please select all that apply.
                        </Typography>
                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                          }}
                        >
                          <Grid item xs={12} textAlign="center" sm={5}>
                            <Box
                              className={
                                formValues?.locationExplanation?.countries !== '' ? 'active' : ''
                              }
                              sx={checkAndRadioButtonDisabledStyles}
                            >
                              <Typography display="inline">
                                Not able to specify further (please explain)
                              </Typography>
                              <Checkbox
                                name="countries"
                                checked={formValues?.locationExplanation?.countries !== ''}
                                disabled
                                value="N/A"
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                              display:
                                formValues?.locationExplanation?.countries === ''
                                  ? 'none'
                                  : 'initial',
                            }}
                          >
                            <Typography sx={questionSubStyles}>Description</Typography>
                            <TextField
                              name="localExplanation"
                              placeholder="Enter description here"
                              fullWidth
                              disabled
                              value={formValues?.locationExplanation?.countries}
                              // onChange={handleChange}
                              margin="normal"
                              variant="outlined"
                              multiline
                              rows={4}
                              sx={inputFieldDisabledStyles}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {formValues.regions.map((item: string) => (
                          <Grid key={item} sx={{ my: 1 }} item xs={12}>
                            <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}>
                              <Typography sx={{ my: 2, ...questionSubStyles }} display="inline">
                                {item}
                              </Typography>
                              <br />
                              {locations && renderCountries(item)}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 10 of 12
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          In which specific states does this program operate?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Please select all that apply.
                        </Typography>
                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                          }}
                        >
                          <Grid item xs={12} textAlign="center" sm={5}>
                            <Box
                              className={
                                formValues?.locationExplanation?.states !== '' ? 'active' : ''
                              }
                              sx={checkAndRadioButtonDisabledStyles}
                            >
                              <Typography display="inline">
                                Able to operate in anywhere (please explain)
                              </Typography>
                              <Checkbox
                                name="states"
                                disabled
                                checked={formValues?.locationExplanation?.states !== ''}
                                value="N/A"
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                              display:
                                formValues?.locationExplanation?.states === '' ? 'none' : 'initial',
                            }}
                          >
                            <Typography sx={questionSubStyles}>Description</Typography>
                            <TextField
                              name="localExplanation"
                              placeholder="Enter description here"
                              fullWidth
                              disabled
                              value={formValues?.locationExplanation?.states}
                              // onChange={handleChange}
                              margin="normal"
                              variant="outlined"
                              multiline
                              rows={4}
                              sx={inputFieldParaDisabledStyles}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {formValues.countries.map((item: string) => {
                          const n = item.lastIndexOf(';');
                          const result = item.substring(n + 1);
                          if (result === 'United States' || result === 'Canada') {
                            return (
                              <Grid key={result} sx={{ my: 1 }} item xs={12}>
                                <Box
                                  sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                                >
                                  <Typography sx={{ my: 2, ...questionSubStyles }} display="inline">
                                    {result}
                                  </Typography>
                                  <br />
                                  {locations && renderStates(result)}
                                </Box>
                              </Grid>
                            );
                          }
                          return true;
                        })}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 11 of 12
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          In which specific cities does this program operate?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Please select all that apply.
                        </Typography>
                        <Grid
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                          }}
                        >
                          <Grid item xs={12} textAlign="center" sm={5}>
                            <Box
                              className={
                                formValues?.locationExplanation?.cities !== '' ? 'active' : ''
                              }
                              sx={checkAndRadioButtonDisabledStyles}
                            >
                              <Typography display="inline">
                                Able to operate in anywhere (please explain)
                              </Typography>
                              <Checkbox
                                name="cities"
                                checked={formValues?.locationExplanation?.cities !== ''}
                                value="N/A"
                                disabled
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                              display:
                                formValues?.locationExplanation?.cities === '' ? 'none' : 'initial',
                            }}
                          >
                            <Typography sx={questionSubStyles}>Description</Typography>
                            <TextField
                              name="localExplanation"
                              placeholder="Enter description here"
                              fullWidth
                              disabled
                              value={formValues?.locationExplanation?.cities}
                              // onChange={handleChange}
                              margin="normal"
                              variant="outlined"
                              multiline
                              rows={4}
                              sx={inputFieldDisabledStyles}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {formValues.countries.map((item: string) => {
                          const n = item.lastIndexOf(';');
                          const result = item.substring(n + 1);
                          if (result !== 'United States' && result !== 'Canada') {
                            return (
                              <Grid key={result} sx={{ my: 1 }} item xs={12}>
                                <Box
                                  sx={{ border: '1px solid #E0E0E0', borderRadius: '20px', p: 2 }}
                                >
                                  <Typography sx={{ ...commonTextStyle.heading, my: 2 }} display="inline">
                                    {result}
                                  </Typography>
                                  <br />
                                  {locations && renderCities(result)}
                                </Box>
                              </Grid>
                            );
                          }
                          return true;
                        })}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 12 of 12
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Is there anything you&apos;d like to add about where your program
                          operates?
                          <Tooltip
                            title="Is there anything you'd like to add about where your program
                          operates"
                          >
                            <InfoIcon
                              sx={{ fontSize: '20px', color: '#868e96', marginLeft: '3px' }}
                            />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ mb: 1, ...questionSubStyles }}>Description</Typography>
                        <TextField
                          name="additionalLocationDetails"
                          placeholder="Enter details"
                          disabled
                          fullWidth
                          value={formValues.additionalLocationDetails}
                          onChange={handleChange}
                          margin="normal"
                          variant="outlined"
                          multiline
                          rows={4}
                          sx={inputFieldParaDisabledStyles}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {/* Strategies and Approaches */}
                <Accordion
                  expanded={expanded === 'strategies'}
                  onChange={handleAccordion('strategies')}
                  sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === 'strategies' ? (
                        <RemoveIcon sx={accordianHeaderButtonStyles} />
                      ) : (
                        <AddIcon sx={accordianHeaderButtonStyles} />
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={9}>
                      <Typography sx={accordianHeadingsStyles} gutterBottom>
                        Strategies and Approaches
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={statusStyles} gutterBottom>
                        {funding?.progress?.strategiesAndApproaches?.complete
                          ? funding?.progress?.strategiesAndApproaches?.complete
                          : 0}{' '}
                        of{' '}
                        {funding?.progress?.strategiesAndApproaches?.total
                          ? funding?.progress?.strategiesAndApproaches?.total
                          : 7}{' '}
                        Completed
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 1 of 8
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          How would you classify the strategies this program employs?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Please select all that apply.
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.programApproach.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={formValues.strategies.includes(item._id) ? 'active' : ''}
                              sx={checkAndRadioButtonDisabledStyles}
                            >
                              <Typography variant="body1" display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                disabled
                                name="strategies"
                                checked={formValues.strategies.includes(item._id)}
                                value={item._id}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 2 of 8
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Describe the specific activities you will undertake to implement your
                          program.
                          <Tooltip
                            title="Describe the specific activities you will undertake to implement your
                          program"
                          >
                            <InfoIcon
                              sx={{ fontSize: '20px', color: '#868e96', marginLeft: '3px' }}
                            />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ mb: 1, ...questionSubStyles }}>Description</Typography>
                        <TextField
                          name="activities"
                          placeholder="Enter activities in your program"
                          fullWidth
                          disabled
                          value={formValues.activities}
                          onChange={handleChange}
                          margin="normal"
                          variant="outlined"
                          multiline
                          rows={4}
                          sx={inputFieldParaDisabledStyles}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 3 of 8
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Is the program intervention one-time or ongoing?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            sx={{ display: 'inline-block' }}
                            name="approachDuration"
                            value={formValues.approachDuration}
                            onChange={handleChange}
                          >
                            {vocab?.approachDuration.map((item: any) => (
                              <FormControlLabel
                                sx={checkAndRadioButtonMultiDisabledStyles}
                                labelPlacement="start"
                                key={item._id}
                                value={item._id}
                                control={<Radio disabled />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12} sx={questionNoStyles}>
                        <Typography>
                          Question 4 of 8
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Describe the frequency and length of the program activities.
                          <Tooltip title="Describe the frequency and length of the program activities">
                            <InfoIcon
                              sx={{ fontSize: '20px', color: '#868e96', marginLeft: '3px' }}
                            />
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ mb: 1, ...questionSubStyles }}>Description</Typography>
                        <TextField
                          name="activitiesFrequency"
                          placeholder="Enter length of program"
                          fullWidth
                          disabled
                          value={formValues.activitiesFrequency}
                          onChange={handleChange}
                          margin="normal"
                          variant="outlined"
                          multiline
                          rows={4}
                          sx={inputFieldParaDisabledStyles}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 5 of 8
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          What outputs does this program create?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >{`These outputs should be based on the $${Number(
                          fundingAmount && fundingAmount,
                        ).toFixed(2)} funding amount provided by this opportunity.`}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {formValues?.outputs?.map((lifetimeOutput: any, index) => (
                          <Grid container spacing={4} key={lifetimeOutput.key}>
                            <Grid item xs={12} sm={2} mb={2}>
                              <Typography sx={optionSubStyles}>Output {index + 1} </Typography>
                              <TextField
                                disabled
                                name="quantity"
                                type="number"
                                value={lifetimeOutput.quantity}
                                onChange={handleOutputChange('quantity', index, 'outputs')}
                                variant="outlined"
                                className="inputTextFiled"
                                fullWidth
                                InputProps={{
                                  sx: {
                                    '& .MuiInputBase-input::placeholder': {
                                      fontSize: '1rem',
                                    },
                                  },
                                }}
                                sx={inputFieldDisabledStyles}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} mb={2}>
                              <Typography sx={optionSubStyles}>
                                Output {index + 1} Description{' '}
                                <Tooltip title="Enter program outputs">
                                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                                </Tooltip>
                              </Typography>
                              <TextField
                                disabled
                                name="description"
                                type="text"
                                value={lifetimeOutput.description}
                                onChange={handleOutputChange('description', index, 'outputs')}
                                InputProps={{
                                  sx: {
                                    '& .MuiInputBase-input::placeholder': {
                                      fontSize: '1rem',
                                    },
                                  },
                                }}
                                sx={inputFieldDisabledStyles}
                                variant="outlined"
                                className="inputTextFiled"
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>Question 6 of 8 </Typography>
                        <Typography sx={questionStyles}>
                          What outcomes does this program create?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Outcomes show the effectiveness of your program.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {formValues?.outcomes?.map((lifetimeOutput: any, index) => (
                          <Grid container spacing={4} key={lifetimeOutput.key}>
                            <Grid item xs={12} sm={2} mb={2}>
                              <Typography sx={optionSubStyles}>Outcome {index + 1} </Typography>
                              <TextField
                                disabled
                                name="quantity"
                                type="number"
                                value={lifetimeOutput.quantity}
                                onChange={handleOutputChange('quantity', index, 'outcomes')}
                                variant="outlined"
                                className="inputTextFiled"
                                fullWidth
                                InputProps={{
                                  sx: {
                                    '& .MuiInputBase-input::placeholder': {
                                      fontSize: '1rem',
                                    },
                                  },
                                }}
                                sx={inputFieldDisabledStyles}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} mb={2}>
                              <Typography sx={optionSubStyles}>
                                Outcome {index + 1} Description{' '}
                                <Tooltip title="Enter program outputs">
                                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                                </Tooltip>
                              </Typography>
                              <TextField
                                disabled
                                name="description"
                                type="text"
                                value={lifetimeOutput.description}
                                onChange={handleOutputChange('description', index, 'outcomes')}
                                InputProps={{
                                  sx: {
                                    '& .MuiInputBase-input::placeholder': {
                                      fontSize: '1rem',
                                    },
                                  },
                                }}
                                sx={inputFieldDisabledStyles}
                                variant="outlined"
                                className="inputTextFiled"
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 7 of 8
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Are your results measured qualitatively or quantitatively?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl>
                          <RadioGroup
                            sx={{ display: 'inline-block' }}
                            name="dataMeasurementType"
                            value={formValues.dataMeasurementType}
                            onChange={handleChange}
                          >
                            {vocab?.dataMeasurement.map((item: any) => (
                              <FormControlLabel
                                sx={checkAndRadioButtonMultiDisabledStyles}
                                labelPlacement="start"
                                key={item._id}
                                value={item._id}
                                control={<Radio disabled />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 8 of 8
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Are there any SIO partners supporting this program?
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl>
                          <RadioGroup
                            sx={{ display: 'inline-block' }}
                            name="nonprofitPartners"
                            value={formValues.nonprofitPartners ? 'yes' : 'no'}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              sx={checkAndRadioButtonMultiDisabledStyles}
                              value="yes"
                              control={<Radio disabled />}
                              label="Yes"
                            />
                            <FormControlLabel
                              sx={checkAndRadioButtonMultiDisabledStyles}
                              value="no"
                              control={<Radio disabled />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {formValues.nonprofitPartners && (
                        <Grid item xs={12} sm={6}>
                          <Typography sx={{ mb: 0.5 }} variant="subtitle1">
                            SIO partners{' '}
                            <Tooltip title="Please mention all SIO partners supporting this program">
                              <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                            </Tooltip>
                          </Typography>
                          <TextField
                            name="nonprofitPartnersDescription"
                            placeholder="Please mention all SIO partners supporting this program."
                            fullWidth
                            disabled
                            value={formValues?.nonprofitPartnersDescription?.trim()}
                            onChange={handleChange}
                            margin="normal"
                            variant="outlined"
                            multiline
                            sx={inputFieldParaDisabledStyles}
                            rows={2}
                            InputProps={{
                              sx: {
                                '& .MuiInputBase-input::placeholder': {
                                  fontSize: '1rem',
                                },
                              },
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                {/* Research and Evaluation */}
                <Accordion
                  expanded={expanded === 'research'}
                  onChange={handleAccordion('research')}
                  sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === 'research' ? (
                        <RemoveIcon sx={accordianHeaderButtonStyles} />
                      ) : (
                        <AddIcon sx={accordianHeaderButtonStyles} />
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid item xs={9}>
                      <Typography sx={accordianHeadingsStyles} gutterBottom>
                        Research and Evaluation
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={statusStyles} gutterBottom>
                        {funding?.progress?.researchAndEvaluation?.complete
                          ? funding?.progress?.researchAndEvaluation?.complete
                          : 0}{' '}
                        of{' '}
                        {funding?.progress?.researchAndEvaluation?.total
                          ? funding?.progress?.researchAndEvaluation?.total
                          : 2}{' '}
                        Completed
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>Question 1 of 9</Typography>
                        <Typography sx={questionStyles}>
                          Please upload the research or evaluation that informs your program design.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="researchFile"
                          placeholder="Select File"
                          fullWidth
                          type="file"
                          disabled
                          // value={formValues.researchFile.name}
                          onChange={handleUpload}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            sx: {
                              '& .MuiInputBase-input::placeholder': {
                                fontSize: '1rem',
                              },
                            },
                          }}
                          sx={inputFieldDisabledStyles}
                        />
                        {formValues.researchFile && (
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              sx={{ lineHeight: '19.36px', display: 'flex', alignItems: 'center' }}
                            >
                              <b style={{ marginRight: '4px' }}>File Name: </b>{' '}
                              {formValues.researchFile.name}
                              {formValues.researchFile.name &&
                                downloadTextButton(formValues.researchFile)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 2 of 9
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          How would you describe the evidence or data?
                        </Typography>
                        <Grid xs={7}>
                          <Typography
                            sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                          >
                            Please note, this information helps us drive additional value your
                            organization is creating through the use of methods that have been
                            researched and evaluated. This should be program-specific, not at an
                            organizational level.
                          </Typography>
                        </Grid>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.studyEvidenceType.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={
                                formValues.evidenceDescription.includes(item._id) ? 'active' : ''
                              }
                              sx={checkAndRadioButtonMultiDisabledStyles}
                            >
                              <Typography sx={optionSubStyles} display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                disabled
                                name="evidenceDescription"
                                checked={formValues.evidenceDescription.includes(item._id)}
                                value={item._id}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid style={{ padding: '1em' }}>
                        <Box sx={checkAndRadioButtonMultiDisabledStyles}>
                          <Typography sx={questionSubStyles} display="inline">
                            Others
                          </Typography>
                          <Checkbox
                            name="evidenceOthers"
                            checked={formValues?.evidenceOptional !== '' && true}
                            disabled
                          />
                        </Box>
                      </Grid>
                      {formValues?.evidenceOptional !== '' && (
                        <Grid style={{ paddingLeft: '1em' }}>
                          <Typography sx={optionSubStyles} display="inline">
                            Please describe others
                          </Typography>
                          <TextField
                            disabled
                            type="text"
                            variant="outlined"
                            className="inputTextFiled"
                            value={formValues.evidenceOptional}
                            fullWidth
                            InputProps={{
                              sx: {
                                '& .MuiInputBase-input::placeholder': {
                                  fontSize: '1rem',
                                },
                              },
                            }}
                            sx={inputFieldDisabledStyles}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 3 of 9
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          How would you describe the study design?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Please select all that apply.
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.studyDesignType.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={
                                formValues.studyDescription.includes(item._id) ? 'active' : ''
                              }
                              sx={checkAndRadioButtonMultiDisabledStyles}
                            >
                              <Typography sx={optionSubStyles} display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                disabled
                                name="studyDescription"
                                checked={formValues.studyDescription.includes(item._id)}
                                value={item._id}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid style={{ padding: '1em' }}>
                        <Box sx={checkAndRadioButtonMultiDisabledStyles}>
                          <Typography display="inline">Others</Typography>
                          <Checkbox
                            name="studyOthers"
                            checked={formValues?.studyOptional !== '' && true}
                            disabled
                          />
                        </Box>
                      </Grid>
                      {formValues?.studyOptional !== '' && (
                        <Grid style={{ paddingTop: '1em' }}>
                          <Typography sx={optionSubStyles} display="inline">
                            Please describe others
                          </Typography>
                          <TextField
                            disabled
                            type="text"
                            variant="outlined"
                            className="inputTextFiled"
                            value={formValues.studyOptional}
                            fullWidth
                            InputProps={{
                              sx: {
                                '& .MuiInputBase-input::placeholder': {
                                  fontSize: '1rem',
                                },
                              },
                            }}
                            sx={inputFieldDisabledStyles}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 4 of 9
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          How would you describe the data in the study?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Please select all that apply.
                        </Typography>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.researchDataType.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={
                                formValues.dataDescription.includes(item._id) ? 'active' : ''
                              }
                              sx={checkAndRadioButtonMultiDisabledStyles}
                            >
                              <Typography sx={optionSubStyles} display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                disabled
                                name="dataDescription"
                                checked={formValues.dataDescription.includes(item._id)}
                                value={item._id}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid style={{ padding: '1em' }}>
                        <Box sx={checkAndRadioButtonMultiDisabledStyles}>
                          <Typography display="inline">Others</Typography>
                          <Checkbox
                            name="dataOthers"
                            checked={formValues?.dataOptional !== '' && true}
                            disabled
                          />
                        </Box>
                      </Grid>
                      {formValues?.dataOptional !== '' && (
                        <Grid style={{ paddingTop: '1em' }}>
                          <Typography sx={optionSubStyles} display="inline">
                            Please describe others
                          </Typography>
                          <TextField
                            disabled
                            type="text"
                            variant="outlined"
                            className="inputTextFiled"
                            value={formValues.dataOptional}
                            fullWidth
                            InputProps={{
                              sx: {
                                '& .MuiInputBase-input::placeholder': {
                                  fontSize: '1rem',
                                },
                              },
                            }}
                            sx={inputFieldDisabledStyles}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 5 of 9
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          How would you describe the correlation of your program to your outcomes
                          based on the study?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            sx={{ display: 'inline-block' }}
                            name="outcomeDescription"
                            value={formValues.outcomeDescription}
                            onChange={handleChange}
                          >
                            {vocab?.effect.map((item: any) => (
                              <FormControlLabel
                                sx={checkAndRadioButtonMultiDisabledStyles}
                                labelPlacement="start"
                                key={item._id}
                                value={item._id}
                                control={<Radio disabled />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 6 of 9
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Which strategies and approaches align with the research or evaluation this
                          program design is based on?
                        </Typography>
                        <Typography
                          sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                        >
                          Please select atleast one and up to three.
                        </Typography>
                      </Grid>
                      {vocab?.causes.map((item: any) => (
                        <Grid key={item._id} sx={{ my: 2 }} item xs={12}>
                          <Box
                            className={
                              formValues.evidenceDescription.includes(item._id) ? 'active' : ''
                            }
                            sx={checkAndRadioButtonMultiDisabledStyles}
                          >
                            <Typography sx={{ my: 2 }} variant="h5" display="inline">
                              {item.label}
                            </Typography>
                            <br />
                            {vocab?.approach.map((subItem: any) => {
                              if (subItem.causeId === item._id) {
                                return (
                                  <Box
                                    key={subItem._id}
                                    className={
                                      isChecked1(subItem._id) ||
                                      formValues.researchApproach.includes(subItem._id)
                                        ? 'active'
                                        : ''
                                    }
                                    sx={checkAndRadioButtonMultiDisabledStyles}
                                  >
                                    <Typography variant="body1" display="inline">
                                      {subItem.label}
                                    </Typography>
                                    <Checkbox
                                      disabled
                                      name="researchApproach"
                                      checked={
                                        isChecked1(subItem._id) ||
                                        formValues.researchApproach.includes(subItem._id)
                                      }
                                      value={subItem._id}
                                      onChange={handleChangeSelect}
                                    />
                                  </Box>
                                );
                              }
                              return true;
                            })}
                          </Box>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 7 of 9
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          What environmental outputs does this program create?
                        </Typography>
                        <Grid xs={7}>
                          <Typography
                            sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                          >
                            This question was added because you selected a environmental strategies
                            and approaches. Please select all that apply.
                          </Typography>
                        </Grid>
                      </Grid>
                      {
                        /* eslint no-underscore-dangle: 0 */
                        vocab?.sccConversion.map((item: any) => (
                          <Grid key={item._id} item>
                            <Box
                              className={
                                formValues.environmentalOutputs.includes(item._id) ? 'active' : ''
                              }
                              sx={checkAndRadioButtonMultiDisabledStyles}
                            >
                              <Typography variant="body1" display="inline">
                                {item.label}
                              </Typography>
                              <Checkbox
                                disabled
                                name="environmentalOutputs"
                                checked={formValues.environmentalOutputs.includes(item._id)}
                                value={item._id}
                                onChange={handleChangeSelect}
                              />
                            </Box>
                          </Grid>
                        ))
                      }
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 8 of 9
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          Please add numeric values for the environmental outputs this program
                          creates.
                        </Typography>
                        <Grid xs={7}>
                          <Typography
                            sx={{ marginBottom: '1.5rem', marginTop: '2px', ...questionSubStyles }}
                          >
                            Outputs are purely numeric values that are proportionate to your budget.
                            If your program was completely funded, these would be the metrics you
                            report.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {formValues?.environmentalOutputs?.map((lifetimeOutput: any, index) => {
                          const label: any = vocab?.sccConversion?.filter(
                            (item: any) => item?._id === lifetimeOutput,
                          );
                          return (
                            <Grid container spacing={4} key={lifetimeOutput}>
                              <Grid item xs={12} sm={2} mb={2}>
                                <Typography sx={optionSubStyles}>Quantity {index + 1}</Typography>
                                <TextField
                                  value={getFieldValue(index, 'quantity')}
                                  disabled
                                  name="quantity"
                                  type="number"
                                  onChange={(event) => {
                                    handleLifetimeOutputChange(
                                      'quantity',
                                      index,
                                      event.target.value,
                                      lifetimeOutput,
                                    );
                                  }}
                                  variant="outlined"
                                  className="inputTextFiled"
                                  fullWidth
                                  InputProps={{
                                    sx: {
                                      '& .MuiInputBase-input::placeholder': {
                                        fontSize: '1rem',
                                      },
                                    },
                                  }}
                                  sx={inputFieldDisabledStyles}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} mb={2}>
                                <Typography sx={optionSubStyles}>
                                  Output {index + 1} Description
                                </Typography>
                                <TextField
                                  disabled
                                  name="description"
                                  value={getFieldValue(index, 'description')}
                                  type="text"
                                  onChange={() => {
                                    handleLifetimeOutputChange(
                                      'description',
                                      index,
                                      label && label[0]?.label!,
                                      lifetimeOutputForm,
                                    );
                                  }}
                                  InputProps={{
                                    sx: {
                                      '& .MuiInputBase-input::placeholder': {
                                        fontSize: '1rem',
                                      },
                                    },
                                  }}
                                  sx={inputFieldDisabledStyles}
                                  variant="outlined"
                                  className="inputTextFiled"
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={questionNoStyles}>
                          Question 9 of 9
                          <span style={{ color: '#f64747', fontSize: '15px', marginLeft: '2px' }}>
                            *
                          </span>
                        </Typography>
                        <Typography sx={questionStyles}>
                          How strong is the relation of the outcomes from the study to the
                          strategies and approaches you identified?
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            sx={{ display: 'inline-block' }}
                            name="strength"
                            value={formValues.strength}
                            onChange={handleChange}
                          >
                            {vocab?.strength.map((item: any) => (
                              <FormControlLabel
                                sx={checkAndRadioButtonMultiDisabledStyles}
                                labelPlacement="start"
                                key={item._id}
                                value={item._id}
                                control={<Radio disabled />}
                                label={item.label}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <Box sx={{ bgcolor: 'white', height: '100px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} textAlign="right">
            {renderButtons()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FundingDetails;
