import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import React, { useEffect, useRef } from 'react';
import { RoleRequired } from '../../app';
import {
  CommonGridFilter,
  CommonGridFilterHandle,
  FlexBox,
  InvertedRoundButton,
  IsolatedGrid,
} from '../../shared/components';
import borders from '../../assets/scss/strokes.module.scss';

import { CONTAINS_FILTER_OPERATOR } from '../../shared/constants';
import {
  GridColumns,
  UserRole,
  Dialogs,
  ConfirmDialogType,
  booleanToTextValues,
} from '../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import {
  booleanToText,
  getGenericHeight,
  isNullOrWhitespace,
  openConfirmDialog,
} from '../../shared/utils';
import {
  clearLocalesState,
  deleteLocaleThunk,
  getLocalesThunk,
  patchLocaleThunk,
  setLocalesActive,
  setLocalesGridModel,
} from './localesSlice';
import { setDialog } from '../main-screen/mainScreenSlice';
import { getGridRequest } from '../../shared/utils/grid';
import { setLocaleSettingsLocaleId, setLocaleSettingsIsNew } from './localeSettingsSlice';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';


export const Locales = () => {
  const state = useAppSelector((x) => x.locales);
  const stateConfirmDialog = useAppSelector((x) => x.confirmDialog);
  const localeSettingsSuccess = useAppSelector((x) => x.localeSettings.success);

  const dispatch = useAppDispatch();

  const filterRef = useRef<CommonGridFilterHandle>(null);
  const { value, confirmed, type } = stateConfirmDialog;
  const { active, grid, success, toastMessage } = state;
  const { filter, sort, page, pageSize } = grid;

  useEffect(() => {
    dispatch(getLocalesThunk({ active, ...getGridRequest(grid) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, active]);

  useEffect(() => {
    if (!localeSettingsSuccess) return;
    dispatch(getLocalesThunk({ active, ...getGridRequest(grid) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localeSettingsSuccess]);

  useEffect(() => {
    if (!success) return;
    dispatch(getLocalesThunk({ active, ...getGridRequest(grid) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (!success || isNullOrWhitespace(toastMessage)) return;
    toast.success(toastMessage);
  }, [success, toastMessage]);

  useEffect(
    () => () => {
      dispatch(clearLocalesState());
    },
    [dispatch],
  );

  useEffect(() => {
    if (!confirmed || type !== ConfirmDialogType.DeactivateLocale || isNullOrWhitespace(value))
      return;
    dispatch(deleteLocaleThunk(value!));
  }, [value, confirmed, type, dispatch]);

  useEffect(() => {
    if (!confirmed || type !== ConfirmDialogType.ActivateLocale || isNullOrWhitespace(value))
      return;
    dispatch(patchLocaleThunk(value!));
  }, [value, confirmed, type, dispatch]);

  const editLocale = (id: string) => {
    dispatch(setLocaleSettingsLocaleId(id));
    dispatch(setDialog({ open: true, type: Dialogs.LocaleSettings, maxWidth: 'md' }));
  };

  const addLocale = () => {
    dispatch(setLocaleSettingsIsNew(true));
    dispatch(setDialog({ open: true, type: Dialogs.LocaleSettings, maxWidth: 'sm' }));
  };

  const deactivateLocale = (id: string) => {
    openConfirmDialog(
      {
        title: 'Deactivate Locale ',
        text: 'Are you sure you want to deactivate this locale? Deactivated locales can be reactivated from the inactive tab on the locales dashboard.',
        type: ConfirmDialogType.DeactivateLocale,
        confirmText: 'Deactivate',
        value: id,
      },
      'sm',
    );
  };

  const activateLocale = (id: string) => {
    openConfirmDialog(
      {
        title: 'Activate Locale ',
        text: 'Are you sure you want to activate this locale?',
        type: ConfirmDialogType.ActivateLocale,
        confirmText: 'Activate',
        value: id,
      },
      'sm',
    );
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(setLocalesActive(newValue === 'true'));
    filterRef.current?.clearFilterSelections();
  };

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'currency',
      valueGetter: (params) => params.row.settings.currency,
      headerName: 'Currency',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'instanceCount',
      headerName: 'Instance Count',
      flex: 1,
      filterable: false,
      renderCell: ({ row: { instanceCount } }) => instanceCount ?? 0,
    },
    {
      field: 'action',
      disableReorder: true,
      resizable: false,
      headerName: 'Actions',
      type: GridColumns.Actions,
      flex: 1,
      minWidth: 200,
      renderCell: ({ row: { instanceCount, id } }) => (
        <FlexBox sx={{ gap: 1 }}>
          <Button
            variant="outlined"
            color="info"
            // sx={{ backgroundColor: '#fff' }}
            onClick={() => {
              editLocale(id);
            }}
            sx={{
              backgroundColor: colors.secondary,
              fontSize: fonts.fontSize14,
              fontWeight: fonts.fontWeight700,
              color: colors.primary,
              borderRadius: borders.borderRadius8,
              borderColor: colors.primary,
              '&:focus': colors.primary,
              '&:hover': colors.primary,
            }}
          >
            Edit
          </Button>
          {!instanceCount && (
            <Button
              variant="outlined"
              color={active ? 'error' : 'success'}
              // sx={{ backgroundColor: '#fff' }}
              onClick={() => (active ? deactivateLocale(id) : activateLocale(id))}
              sx={{
                backgroundColor: colors.secondary,
                fontSize: fonts.fontSize14,
                fontWeight: fonts.fontWeight700,
                color: active ? colors.statusAlert : colors.statusSuccess,
                borderRadius: borders.borderRadius8,
                borderColor: active ? colors.statusAlert : colors.statusSuccess,
                '&:focus': active ? colors.statusAlert : colors.statusSuccess,
                '&:hover': active ? colors.statusAlert : colors.statusSuccess,
              }}
            >
              {booleanToText(!active, booleanToTextValues.DeactivateActive)}
            </Button>
          )}
        </FlexBox>
      ),
      sortable: false,
      filterable: false,
      align: 'left',
      headerAlign: 'left',
    },
  ];

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: colors.primary,
            height: '4px',
          },
        },
      },
    },
  });

  return (
    <Box className="dashboard" sx={{ pb: '30px' }}>
      <FlexBox sx={{ gap: 1 }}>
        <Typography
          sx={{ flex: 1, color: colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 700 }}
        >
          Locales
        </Typography>
        <RoleRequired roles={[UserRole.SuperAdmin]}>
          <InvertedRoundButton onClick={addLocale}sx={{
              height: '48px',
              marginLeft: '30px',
              paddingX: '16px',
              fontWeight: fonts.fontWeight700,
              fontSize: fonts.fontSize14,
              backgroundColor: `${colors.primary} !important`,
              color: `${colors.secondary} !important`,
              borderRadius: '8px !important',
              '&.Mui-disabled': {
                backgroundColor: `${colors.strokeDefault} !important`,
              },
            }}>
            New Locale
          </InvertedRoundButton>
        </RoleRequired>
      </FlexBox>

      <Grid container alignItems="end">
        <Grid item md={3} sm={12}>
          <Box my={1}>
            <ThemeProvider theme={theme}>
              <Tabs value={`${active}`} onChange={handleTabChange}>
                <Tab
                  value="true"
                  label="Active"
                  sx={{
                    textTransform: 'capitalize',
                    color: active ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: active ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
                <Tab
                  value="false"
                  label="Inactive"
                  sx={{
                    textTransform: 'capitalize',
                    color: !active ? `${colors.primary} !important` : colors.textPrimary,
                    fontSize: fonts.fontSize18,
                    fontWeight: !active ? fonts.fontWeight700 : fonts.fontWeight600,
                  }}
                />
              </Tabs>
            </ThemeProvider>
          </Box>
        </Grid>
        <Grid item md={9} sm={12}>
          <Box my={1} display="flex" justifyContent="flex-end">
            <CommonGridFilter
              columns={columns}
              currentFilter={filter}
              onChange={(x) => {
                dispatch(setLocalesGridModel({ filter: x }));
              }}
              ref={filterRef}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ minHeight: '400px', height: getGenericHeight(300), marginTop: 1 }}>
        <IsolatedGrid
          status={active}
          model={grid}
          columns={active ? columns : columns.filter((x) => x.field !== 'instanceCount')}
          onChange={(x) => dispatch(setLocalesGridModel(x))}
        />
      </Box>
    </Box>
  );
};
