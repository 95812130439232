import { SubscriptionType } from '../enums/subscriptionType';
import { useAppSelector } from './redux';

export const useSubcriptionType = () => {
  const account = useAppSelector((x) => x.app.account);
  const isAccountSelect = () => account?.subscription === SubscriptionType.SELLWITH_SELECT;
  const isAccountPro = () =>
    account?.subscription === SubscriptionType.SELLWITH_PRO ||
    account?.subscription === SubscriptionType.SELLWITH_PRO_UK;
  const isAccountInTier = (allowedTiers: SubscriptionType[]) =>
    account?.subscription && allowedTiers.includes(account?.subscription);
  const isAccountTrial = () => account?.subscriptionStatus?.current?.endsWith('trial');
  return { isAccountPro, isAccountSelect, isAccountInTier, isAccountTrial };
};
