import React, { useState, ChangeEvent, useEffect } from 'react';
import _ from 'lodash';
import {
  Box,
  Typography,
  Grid,
  Button,
  IconButton,
  Divider,
  InputAdornment,
  DialogActions,
  Switch,
  Accordion,
  FormHelperText,
  AccordionSummary,
  Popover,
  AccordionDetails,
  Modal,
  TextField,
  MenuItem,
  SelectChangeEvent,
  Select,
  FormControl,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Chip,
  Autocomplete,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  styled,
  tableCellClasses,
} from '@mui/material';
import { format } from 'date-fns';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RemoveIcon from '@mui/icons-material/Remove';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CellPhoneInput, IsolatedGrid } from '../../shared/components';
import { CustomGridColDef } from '../../shared/interfaces';
import { GridColumns } from '../../shared/enums';
import { PostSIORequest } from '../../shared/interfaces/sio/sio';
import {
  fetchSio,
  removeSio,
  updateSio,
  fetchMpa,
  setSioUserGridModel,
  getSioUserListThunk,
  fetchUser,
  updatePostSio,
  downloadFileThunk,
} from './sioSlice';
import { getGridRequest, getGenericHeight } from '../../shared/utils';
import { getUsersThunk, clearUsersState } from '../users/usersSlice';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import AddUserPopup from './AddUserPopup';
import './Sio.scss';
import {
  sendEmail,
  sendMpaEmail,
  sioAction,
  getSios,
  uploadAdminFile,
  postBlockList,
  getIndustriesList,
} from '../../api/portal/sioRequest';
import { getCompanies } from '../../api';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface SioData {
  id: string;
}
interface LocationState {
  isCreate: string;
}

const lifetimeOutputForm = [{ key: '', quantity: 0, output: '' }];

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#FFFFFF',
  border: '1px solid #E6E6E6',
  boxShadow: 24,
  borderRadius: 5,
};

const commonTextStyle = {
  heading: {
    fontSize: fonts.fontSize24,
    fontWeight: fonts.fontWeight600,
    // marginLeft: '0.8em',
    color: colors.textPrimary,
  },
  subHeading: {
    fontSize: fonts.fontSize16,
    fontWeight: fonts.fontWeight600,
    // marginLeft: '1em',
    color: colors.textPrimary,
  },
  buttonStyle: {
    border: `1px solid ${colors.primary}`,
    color: colors.primary,
    fontSize: fonts.fontSize14,
    fontWeight: fonts.fontWeight700,
  },
  questionStyle: {
    fontSize: fonts.fontSize18,
    fontWeight: fonts.fontWeight600,
    color: colors.textPrimary,
  },
  cancelSaveBtn: {
    border: `1px solid ${colors.primary}`,
    color: colors.primary,
    fontSize: fonts.fontSize14,
    fntWeight: fonts.fontWeight700,
    marginTop: '1em',
  },
  textFieldStyle: {
    height: '41px !important',
    borderRadius: '5px',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      padding: '11px 10px',
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fntWeight400,
    },
  },
  sup: {
    color: colors.statusAlert,
    fontSize: fonts.fontSize18,
    fontWeight: fonts.fontWeight500,
    marginLeft: '3px',
  },
  labelStyle: {
    fontSize: fonts.fontSize16,
    fontWeight: fonts.fontWeight600,
    marginBottom: '3px',
    color: colors.textPrimary,
  },
  accordianLabel: {
    fontSize: fonts.fontSize20,
    fontWeight: fonts.fontWeight600,
    color: colors.textPrimary,
  },
  textAreaStyle: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
    },
  },
};

const successStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FFFFFF',
  width: '40vw',
  border: '1px solid #E6E6E6',
  boxShadow: 24,
  borderRadius: 5,
  padding: '30px 50px',
};
type InitialState = {
  id: string;
  sioId: string;
  firstName: string;
  lastName: string;
  username: string;
  roles: string[];
  active: boolean;
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F6F6F6',
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// const StyledTableRow = styled(TableRow)(() => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: 'white',
//   },
//   // hide last border
//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
// }));

const SioDetails = () => {
  const location = useLocation();
  const isCreate = location.state as LocationState;
  const sioUserState = useAppSelector((state) => state.sios);
  const { userGrid, status: sioStatus } = sioUserState;
  const accountId = useAppSelector((state) => state.app.account?.id!);
  const instance = useAppSelector((state) => state.app.associatedInstance);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [openApprovedModal, setOpenApprovedModal] = React.useState('');
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [activeChecked, setActiveChecked] = useState(false);
  const [inactiveChecked, setInactiveChecked] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const usersState = useAppSelector((state) => state.users);
  const { grid, active } = usersState;
  const { page, pageSize, sort, filter } = grid;
  const sioState = useAppSelector((state) => state.sios);
  const { sio, mpa } = sioState;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [emailInputs, setEmailInputs] = useState<string[]>(['']);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [lifetimeOutputs, setLifetimeOutputs] = useState(lifetimeOutputForm);
  const [isOtherSelected, setIsUserSelected] = useState(false);
  // const [industryValue, setIndustryValue] = useState<any>({});
  // const [industries, setIndustries] = useState<any>([]);

  // const selectIndustryOptions = industries?.map((option: any) => (
  //   <MenuItem key={option._id} value={option}>
  //     {`${option.label}`}
  //   </MenuItem>
  // ));

  const currency = ['USD', 'EUR', 'GBP', 'Other'];

  const [addUserPopupInitialState, setAddUserPopupInitialState] = useState<InitialState>({
    id: '',
    sioId: '',
    firstName: '',
    lastName: '',
    username: '',
    roles: [],
    active: true,
  });
  const [isAddUserPopupEditMode, setIsAddUserPopupEditMode] = useState(false);
  const { id } = useParams();
  const [formValues, setFormValues] = useState<PostSIORequest>({
    applicationFormName: '',
    siAdmin: '',
    sioUserEmailList: [],
  });

  const [filteredData, setFilteredData] = useState(userGrid);
  const updatedGrid = {
    ...grid,
    pageSize: 1000,
    defaultSort: { field: 'roles', direction: 'asc' as const },
    filter: { field: 'roles', simpleValue: 'sw_si_admin' },
  };
  const getUsersGridRequest = () => ({
    ...getGridRequest(updatedGrid),
    accountId,
  });

  const [appName, setAppName] = useState(sio.applicationFormName);
  const [siAdminName, setSiAdminName] = useState(sio.siAdmin);
  const [emailList, setEmailList] = useState(sio.sioUserEmailList);
  const [edit, setEdit] = useState(sio.editing);
  const [files, setFiles] = useState<File>();
  const [vimeoId, setVimeoId] = useState(sio?.asset?.vimeoId);
  const [deliverableRequirements, setDeliverableRequirements] = useState(
    sio?.asset?.deliverableRequirements,
  );

  const [isAddUserPopupOpen, setIsAddUserPopupOpen] = useState(false);
  const [industries, setIndustries] = useState<any>([]);
  const [companies, setCompanies] = useState<any>([]);
  const [temporaryCompanies, setTemporaryCompanies] = useState<any>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>([]);
  const [industryBlocklist, setIndustryBlocklist] = useState<any>([]);
  const [companyBlocklist, setCompanyBlocklist] = useState<any>([]);
  const [selectedIndustryBlockList, setSelectedIndustryBlockList] = useState<any>([]);
  const [selectedCompanyBlockList, setSelectedCompanyBlockList] = useState<any>([{}]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (!currency.includes(sio.operationalInformation?.yearlyBudgetCurrency)) {
      setIsUserSelected(true);
    } else {
      setIsUserSelected(false);
    }
  }, [sio.operationalInformation?.yearlyBudgetCurrency]);

  useEffect(() => {
    setEmailInputs(sio.sioUserEmailList);
  }, [sio.sioUserEmailList]);

  const handleAddUserPopupOpen = () => {
    setIsAddUserPopupOpen(true);
  };
  const getUserList = () => {
    if (id) {
      dispatch(getSioUserListThunk({ status: sioStatus, ...getGridRequest(userGrid), sioId: id }));
    }
  };

  const handleAddUserPopupClose = () => {
    setIsAddUserPopupOpen(false);
    setIsAddUserPopupEditMode(false);
    getUserList();
  };
  useEffect(() => {
    dispatch(getUsersThunk({ active, ...getUsersGridRequest() }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize, active]);

  useEffect(() => {
    if (id) {
      dispatch(fetchSio(id)).then((siodata: any) => {
        setAppName(siodata?.payload?.applicationFormName);
        setSiAdminName(siodata?.payload?.siAdmin);
        setEmailList(siodata?.payload?.sioUserEmailList);
        setEdit(siodata?.payload?.editing);
        setVimeoId(siodata?.payload?.asset?.vimeoId);
        setDeliverableRequirements(siodata?.payload?.asset?.deliverableRequirements);
        if (
          siodata?.payload?.overviewAndMission &&
          siodata?.payload?.overviewAndMission?.lifetimeOutputs
        ) {
          setLifetimeOutputs(siodata?.payload?.overviewAndMission?.lifetimeOutputs);
        }
      });
      dispatch(fetchMpa(id));
    }
    getIndustriesList().then((res) => {
      if (res.status === 200) {
        setIndustries(res.data);
      }
    });
  }, []);

  const getCompaniesList = (value: string) => {
    getCompanies(value).then((companiesList) => {
      const companiesArray = companiesList?.data?.results.map(
        (companyData: any) => companyData.name,
      );
      setCompanies(companiesArray);
      setTemporaryCompanies(companiesList?.data?.results);
    });
  };

  useEffect(() => {
    setSelectedIndustryBlockList(sio?.blocklist?.industries);
    setSelectedCompanyBlockList(sio?.blocklist?.companies);
    sio?.blocklist?.industries?.map((industriesData: any) => {
      /* eslint-disable no-underscore-dangle */
      setIndustryBlocklist((prevValues: any) => [...(prevValues || []), industriesData?._id]);
      return null;
    });
    sio?.blocklist?.companies?.map((companiesData: any) => {
      /* eslint-disable no-underscore-dangle */
      setCompanyBlocklist((prevValues: any) => [...(prevValues || []), companiesData?._id]);
      return null;
    });
  }, [sio]);

  const handleEditButtonClick = async (userId: string) => {
    const result = await dispatch(fetchUser(userId));
    if (fetchUser.fulfilled.match(result)) {
      const user = result.payload;
      setIsAddUserPopupOpen(true);
      setAddUserPopupInitialState(user);
      setIsAddUserPopupEditMode(true);
    }
  };
  useEffect(
    () => () => {
      dispatch(clearUsersState());
    },
    [dispatch],
  );
  useEffect(() => {
    getUserList();
  }, [sort, filter, page, sioStatus, pageSize]);

  useEffect(() => {
    const filteredRows = userGrid.data.results.filter((row) => {
      switch (true) {
        case activeChecked && inactiveChecked:
          return true;
        case activeChecked:
          return row.active === true;
        case inactiveChecked:
          return row.active === false;
        default:
          return true;
      }
    });
    setFilteredData({
      ...userGrid,
      data: {
        ...userGrid.data,
        results: filteredRows,
      },
    });
  }, [activeChecked, inactiveChecked, userGrid]);

  const formatDate = (inputDate: string): string => {
    const utcDate = new Date(inputDate);
    return format(utcDate, 'EEE, MMM d, yyyy; hh:mm:ss a');
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name === 'active') {
      setActiveChecked(checked);
    } else if (name === 'inactive') {
      setInactiveChecked(checked);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    if (name === 'sioUserEmailList') {
      const newEmails = value.split(',').map((email) => email.trim());
      setFormValues({ ...formValues, sioUserEmailList: newEmails });
      setEmailList(newEmails);
      setEmailInputs(newEmails);
    } else {
      setFormValues({ ...formValues, [name]: value });
      if (name === 'applicationFormName') {
        setAppName(value);
      }
      if (name === 'siAdmin') {
        setSiAdminName(value);
      }
    }
  };
  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target as HTMLInputElement;
    if (name === 'siAdmin') {
      setSiAdminName(value);
    }
    if (name === 'industry') {
      /* eslint-disable no-underscore-dangle */
      setSelectedIndustry(value);
    }
  };

  const addToBlockList = () => {
    setIsInitialLoad(false);
    setSelectedIndustryBlockList((prevValues: any) => [
      ...(prevValues || []),
      {
        _id: selectedIndustry?._id,
        name: selectedIndustry?.label,
      },
    ]);
    setIndustryBlocklist((prevValues: any) => [...(prevValues || []), selectedIndustry?._id]);
  };

  const addCompanyBlocklist = () => {
    setIsInitialLoad(false);
    setSelectedCompanyBlockList((prevValues: any) => [
      ...(prevValues || []),
      {
        _id: selectedCompany[0]?._id,
        name: selectedCompany[0]?.name,
      },
    ]);
    setCompanyBlocklist((prevValues: any) => [...(prevValues || []), selectedCompany[0]?._id]);
  };

  const deleteFromBlocklist = (deleteId: string, blocklistType: string) => {
    setIsInitialLoad(false);
    if (blocklistType === 'company') {
      const newArray = companyBlocklist.filter((item: any) => item !== deleteId);
      setCompanyBlocklist(newArray);
    } else {
      const newArray = industryBlocklist.filter((item: any) => item !== deleteId);
      setIndustryBlocklist(newArray);
    }
  };

  useEffect(() => {
    if (!isInitialLoad) {
      setIsInitialLoad(true);
      postBlockList(
        {
          industries: industryBlocklist,
          companies: companyBlocklist,
        },
        sio.id,
      ).then((res) => {
        if (res.status === 200) {
          setSelectedIndustryBlockList(res?.data?.blocklist?.industries);
          setSelectedCompanyBlockList(res?.data?.blocklist?.companies);
        }
      });
    }
  }, [industryBlocklist, companyBlocklist]);

  const Roles = grid.data.results;
  const SiAdminRoles = Roles.filter((arr) => (arr.roles ? arr.roles.includes('sw_si_admin') : ''));
  const selectOptions = SiAdminRoles.map((option) => (
    <MenuItem key={option.id} value={option.id}>
      {`${option.firstName} ${option.lastName}`}
    </MenuItem>
  ));
  const handleRemoveInput = (index: number) => {
    if (emailInputs.length > 1) {
      setEmailInputs(emailInputs.filter((item, i) => i !== index));
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePopoverInputChange = (index: number, value: string) => {
    const updatedInputs = [...emailInputs];
    updatedInputs[index] = value;
    setEmailInputs(updatedInputs);
  };
  const handleAddInput = () => {
    setEmailInputs([...emailInputs, '']);
  };
  const handleSave = () => {
    const emails = _.uniq([...emailInputs]);
    setFormValues({
      ...formValues,
      sioUserEmailList: emails,
    });
    setEmailInputs(emails);
    setEmailList(emails);
    handleClose();
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setFiles(fileList[0]);
  };

  const uploadFileData = async () => {
    const formData = new FormData();
    if (!files) return;
    formData.append('file', files);
    formData.append('path', 'asset.videoFallback');
    if (id) {
      const response = await uploadAdminFile(id, formData);
      if (response.data) {
        toast.success('Upload Successfull');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const isValidEmail = emailList.every(validateEmail);
    if (sio.status === 'approved') {
      if (files) {
        uploadFileData();
      }
      const data = {
        sio: {
          applicationFormName: appName,
          siAdmin: siAdminName,
          editing: edit,
          status: sio.status,
          sioUserEmailList: emailList,
          asset: {
            vimeoId,
            deliverableRequirements,
          },
          general: sio.general,
          operationalInformation: sio.operationalInformation,
          overviewAndMission: sio.overviewAndMission,
        },
        id: sio.id,
      };
      const newSio: SioData = (await dispatch(updatePostSio(data))).payload as SioData;
      console.log('newSio', newSio);
      toast.success('SIO updated Successfully');
      navigate(`/app/sio/`);
    } else {
      const data = {
        sio: {
          applicationFormName: appName,
          siAdmin: siAdminName,
          editing: edit,
          status: sio.status,
          sioUserEmailList: emailList,
        },
        id: sio.id,
      };
      const newSio: SioData = (await dispatch(updateSio(data))).payload as SioData;
      if (newSio?.id && isValidEmail) {
        toast.success('SIO updated Successfully');
        navigate(`/app/sio/${newSio.id}`);
      }
    }
  };
  const handleAccordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleDeleteSio = () => {
    dispatch(removeSio(sio.id));
    toast.success('SIO deleted Successfully');
    getSios({ count: 10, offset: 0, orderBy: 'lastUpdated', descending: true });
    navigate('/app/sio');
  };
  const handleCancelSio = () => {
    toast.success('SIO Form cancelled successfully');
    navigate('/app/sio');
  };
  const handleSendForm = async () => {
    const data = {
      sio_form_link: `${instance?.settings?.portalUrl}/sio-form/${sio.id}`,
    };
    sendEmail(sio.id, data).then(() => setOpenSuccessModal(true));
  };
  const handleSendMpaForm = async () => {
    const data = {
      sio_form_link: `${instance?.settings?.portalUrl}/mpa-form/${sio.id}`,
    };
    sendMpaEmail(sio.id, data).then(() => toast.success('Form sent successfully'));
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenDeleteModal(false);
    setOpenApprovedModal('');
  };
  const handleApprovedAction = async (action: string) => {
    const response = await sioAction(sio.id, action.toLocaleLowerCase());
    if (response) {
      if (action === 'Rejected') {
        navigate('/app/sio');
        toast.success('SIO Rejected Successfully');
      } else if (action === 'Archived') {
        navigate('/app/sio');
        toast.success('SIO Archived Successfully');
      } else {
        dispatch(fetchMpa(sio.id));
        dispatch(fetchSio(sio.id));
        handleCloseModal();
        toast.success(`SIO ${action.toLocaleLowerCase()} successfully`);
      }
    }
  };
  const handleOpenModal = (type: string) => {
    if (type === 'delete') {
      setOpenDeleteModal(true);
    } else {
      setOpenModal(true);
    }
  };

  const renderButtons = () => {
    let buttons;
    if (sio.status === 'submitted') {
      buttons = (
        <>
          <Button
            onClick={() => setOpenApprovedModal('Archived')}
            sx={{ ml: 3 }}
            variant="outlined"
            size="large"
            color="primary"
          >
            Archive
          </Button>
          <Button
            onClick={() => setOpenApprovedModal('Rejected')}
            sx={{ ml: 3 }}
            variant="outlined"
            size="large"
            color="error"
          >
            Reject
          </Button>
          <Button
            onClick={() => setOpenApprovedModal('Approved')}
            sx={{ ml: 3 }}
            variant="contained"
            size="large"
            color="primary"
          >
            Approve
          </Button>
        </>
      );
    } else if (isCreate) {
      buttons = (
        <>
          <Button
            onClick={handleSendForm}
            sx={{ ...commonTextStyle.buttonStyle, float: 'right', ml: 3 }}
            // sx={{ float: 'right', ml: 3 }}
            // variant="contained"
            // size="large"
            // color="primary"
          >
            Send Form
          </Button>
          <Button
            onClick={() => handleOpenModal('cancel')}
            sx={{ ...commonTextStyle.buttonStyle, float: 'right' }}
          >
            Cancel
          </Button>
        </>
      );
    } else {
      buttons = (
        <>
          <Button
            onClick={handleSendForm}
            sx={{ ...commonTextStyle.buttonStyle, float: 'right', mr: 3 }}
            // sx={{ mr: 3 }}
            // variant="contained"
            // size="large"
            // color="primary"
          >
            Send Form
          </Button>
          <Button
            onClick={() => handleOpenModal('delete')}
            sx={{ float: 'right' }}
            variant="outlined"
            size="large"
            color="error"
          >
            Delete SIO Form
          </Button>
        </>
      );
    }

    return buttons;
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    navigate('/app/sio');
  };

  const columns: Array<CustomGridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (params) => (
        <span>
          {params.row.firstName} {params.row.lastName}
        </span>
      ),
    },
    {
      field: 'email',
      headerName: 'Email Id',
      renderCell: (params) => <span>{params.row.username}</span>,
      flex: 1,
    },
    {
      field: 'userType',
      headerName: 'User Role',
      renderCell: (params) => (
        <span>{params.row.userType === 'sio_user' ? 'SIO User' : params.row.userType}</span>
      ),
      flex: 1,
    },
    {
      field: 'last_login',
      headerName: 'Last Login Date',
      flex: 1,
      type: GridColumns.Date,
      renderCell: (params) => (
        <Box>
          {params.row.last_login
            ? new Date(params.row.last_login).toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
            : ''}
        </Box>
      ),
    },
    {
      field: 'active',
      headerName: 'User Status',
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span
            style={{
              display: 'inline-block',
              width: 10,
              height: 10,
              borderRadius: '50%',
              marginRight: 5,
              backgroundColor: params.row.active ? 'green' : 'red',
            }}
          />
          <span>{params.row.active ? 'Active' : 'Inactive'}</span>
        </div>
      ),
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: ({ row: { id: userId } }) => (
        <Button
          variant="outlined"
          color="info"
          sx={{ backgroundColor: '#fff' }}
          onClick={() => handleEditButtonClick(userId)}
        >
          <EditOutlinedIcon />
          <span style={{ marginLeft: '0.5rem' }}>Edit</span>
        </Button>
      ),
    },
  ];

  const StatusBadge = (status: String) => {
    let data;
    switch (status) {
      case 'in_progress':
        data = (
          <>
            <HourglassEmptyIcon fontSize="small" sx={{ marginRight: '10px' }} />
            <Typography
              sx={{ display: 'inline-block', position: 'relative', textTransform: 'capitalize' }}
              variant="button"
            >
              In Progress {sio.percentComplete}%
            </Typography>
          </>
        );

        break;
      case 'approved':
        data = (
          <>
            <CheckCircleOutlineIcon sx={{ marginRight: '10px' }} />
            <Typography
              sx={{ display: 'inline-block', position: 'relative', textTransform: 'capitalize' }}
              variant="button"
            >
              Approved
            </Typography>
          </>
        );

        break;
      case 'submitted':
        data = (
          <>
            <AccessTimeIcon sx={{ marginRight: '10px' }} />
            <Typography
              sx={{ display: 'inline-block', position: 'relative', textTransform: 'capitalize' }}
              variant="button"
            >
              Submitted
            </Typography>
          </>
        );
        break;
      default:
    }
    return data;
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const downloadFile = (file: any) => {
    if (_.has(file, 'url')) {
      dispatch(downloadFileThunk({ id, password: sio.password, url: file.url })).then(() => {
        toast.success('File downloaded successfully');
      });
    } else {
      toast.error('Unable to fetch file url');
    }
  };

  const downloadTextButton = (file: any) => (
    <Box
      sx={(theme) => ({
        color: theme.palette.success.dark,
        textDecoration: 'underline',
        lineHeight: '23.8px',
        fontWeight: 400,
        fontSize: '14px',
        cursor: 'pointer',
        marginLeft: '8px',
        display: 'flex',
        alignItems: 'center',
      })}
      onClick={() => downloadFile(file)}
    >
      Download file
    </Box>
  );

  const selectIndustryOptions = industries?.map((option: any) => (
    /* eslint-disable no-underscore-dangle */
    <MenuItem key={option._id} value={option}>
      {`${option.label}`}
    </MenuItem>
  ));

  const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'white',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto', mb: 2, p: 2, borderRadius: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
          <span
            style={{
              fontSize: fonts.fontSize24,
              fontWeight: fonts.fontWeight700,
              color: colors.textPrimary,
            }}
          >
            {sio.applicationFormName}
          </span>
          <div
            style={{
              ...(sio.status === 'approved' && {
                backgroundColor: '#c3fae8',
                color: '#099268',
                border: '1px solid #099268',
              }),
              ...(sio.status === 'submitted' && {
                backgroundColor: '#ffec99',
                color: '#f08c00',
                border: '1px solid #f08c00',
              }),
              ...(sio.status === 'in_progress' && {
                backgroundColor: '#d0ebff',
                color: '#1864ab',
                border: '1px solid #313B72',
              }),
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              height: '27px',
              width: '197px',
              fontSize: fonts.fontSize16,
              fontWeight: fonts.fontWeight400,
              padding: '6px 15px 6px 15px',
              marginLeft: '20px',
              justifyContent: 'center',
            }}
          >
            {StatusBadge(sio.status)}
          </div>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!isCreate?.isCreate && (
            <Grid sx={{ textAlign: 'right' }} item xs={12} sm={3}>
              <Button
                variant="outlined"
                onClick={() => navigate('/app/sio')}
                // color="primary"
                size="large"
                sx={commonTextStyle.buttonStyle}
              >
                Back
              </Button>
            </Grid>
          )}
        </Box>
      </Box>

      <Box boxShadow={3} sx={{ bgcolor: 'white', borderRadius: '20px' }}>
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            margin: 0,
            border: '1px solid #f1f3f5',
            borderRadius: '20px',
            p: 3,
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                flex: 1,
                color: colors.textPrimary,
                fontSize: fonts.fontSize24,
                fontWeight: 600,
              }}
            >
              Overview
            </Typography>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              width: '100%',
              margin: 0,
              alignItems: 'flex-end',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Grid item xs={12} sm={3}>
              <Typography sx={commonTextStyle.labelStyle}>
                Application Name{' '}
                <Tooltip title="Enter The Application Name">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                disabled={!edit}
                name="applicationFormName"
                placeholder="Enter the Application Name"
                fullWidth
                value={appName}
                onChange={handleInputChange}
                variant="outlined"
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography sx={commonTextStyle.subHeading}>
                ScaleWith Partnership Manager{' '}
                <Tooltip title="Select Partnership Manager">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <FormControl fullWidth>
                <Select
                  // disabled={!edit}
                  name="siAdmin"
                  labelId="manager-label"
                  placeholder="Select Partnership Manager"
                  value={siAdminName}
                  onChange={handleSelectChange}
                  variant="outlined"
                  sx={{
                    ...commonTextStyle.textFieldStyle,
                    '& .MuiSelect-select': {
                      fontSize: fonts.fontSize14,
                      paddingTop: '18px !important',
                      height: '100%',
                    },
                  }}
                >
                  <MenuItem selected value="" disabled>
                    <em>ScaleWith Partnership Manager</em>
                  </MenuItem>
                  {selectOptions}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography sx={commonTextStyle.labelStyle}>
                SIO Contact Email Id{' '}
                <Tooltip title="Enter SIO Contact Email Id">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                disabled={!edit}
                name="sioUserEmailList"
                placeholder="Enter SIO Contact Email Id"
                fullWidth
                value={emailList ? emailList.join(', ') : ''}
                error={!formValues.sioUserEmailList.every(validateEmail)}
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight600,
                    },
                  },
                }}
                variant="outlined"
                onChange={handleInputChange}
                sx={commonTextStyle.textFieldStyle}
              />
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    width: '400px',
                    padding: '1rem',
                    marginTop: '3rem',
                    left: '890px !important',
                    borderRadius: '10px',
                  },
                }}
              >
                <div className="header">
                  <Typography
                    sx={{ color: colors.textPrimary, fontSize: fonts.fontSize14, fontWeight: 600 }}
                  >
                    Add more SIO Contact email
                  </Typography>
                  <IconButton onClick={handleClose}>
                    <CloseIcon sx={{ color: '#005fea' }} />
                  </IconButton>
                </div>
                <Typography
                  sx={{
                    color: colors.textPrimary,
                    fontSize: fonts.fontSize14,
                    fontWeight: 400,
                    whiteSpace: 'nowrap',
                  }}
                >
                  Enter SIO Contact Email Id
                </Typography>
                {emailInputs?.map((value, index) => (
                  <div key={value} style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      autoFocus
                      placeholder="Enter SIO Contact email id here"
                      variant="outlined"
                      margin="dense"
                      id={`email-${index}`}
                      type="email"
                      fullWidth
                      value={value}
                      onChange={(event) => handlePopoverInputChange(index, event.target.value)}
                      sx={{
                        height: '2.5rem',
                        width: '350px',
                        marginBottom: '32px',
                        '& ::placeholder': {
                          fontSize: fonts.fontSize16,
                          lineHeight: '19.36px',
                          fontWeight: fonts.fontWeight600,
                          color: colors.textPrimary,
                        },
                      }}
                    />
                    {emailInputs.length >= 1 && (
                      <IconButton
                        onClick={() => handleRemoveInput(index)}
                        sx={{
                          marginBottom: '1.7rem',
                          fontSize: fonts.fontSize14,
                          fontWeight: fonts.fontWeight600,
                          color: colors.textPrimary,
                        }}
                      >
                        <HighlightOffIcon sx={{ color: '#005fea' }} />
                      </IconButton>
                    )}
                  </div>
                ))}
                <DialogActions>
                  <Button
                    variant="outlined"
                    onClick={handleAddInput}
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight600,
                      color: colors.textPrimary,
                    }}
                  >
                    Add More
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleSave}
                    sx={{
                      fontSize: fonts.fontSize14,
                      fontWeight: fonts.fontWeight600,
                      color: colors.textPrimary,
                    }}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Popover>
              {!formValues.sioUserEmailList.every(validateEmail) && (
                <Box sx={{ height: '16px', position: 'absolute', marginTop: '-10px' }}>
                  <FormHelperText error={!formValues.sioUserEmailList.every(validateEmail)}>
                    Please enter valid email
                  </FormHelperText>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={2}>
              {/* {isCreate?.isCreate && (
                <>
                  <Typography sx = {commonTextStyle.subHeading}>
                    Allow Editing{' '}
                    <Tooltip title="Enable editing">
                      <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                    </Tooltip>
                  </Typography>
                  <Switch checked={edit} onClick={() => setEdit(!edit)} />
                </>
              )} */}
              <Button
                disabled={!edit}
                sx={{ whiteSpace: 'nowrap', mb: 2, ml: 3, color: colors.primary }}
                onClick={handleOpen}
              >
                <AddCircleOutlineIcon sx={{ marginRight: '10px' }} />
                <span> Add more</span>
              </Button>
              {/* {isCreate?.isCreate && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: 2 }}
                  disabled={!appName || !siAdminName || !(emailList ?? []).length}
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              )} */}
            </Grid>
            {/* {!isCreate?.isCreate && (
              <> */}
            <Grid item xs={12} sm={3}>
              <Typography sx={commonTextStyle.subHeading}>
                SIO Form URL{' '}
                <Tooltip title="Click to copy URL">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                disabled
                placeholder="No data yet"
                fullWidth
                value={`${instance?.settings?.portalUrl}/sio-form/${sio.id}`}
                margin="normal"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard
                        text={`${instance?.settings?.portalUrl}/sio-form/${sio.id}`}
                        onCopy={() => toast.success('URL Copied Successfully')}
                      >
                        <FileCopyIcon />
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight600,
                    },
                  },
                }}
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography sx={commonTextStyle.subHeading}>
                Password{' '}
                <Tooltip title="Click to copy password">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <TextField
                disabled
                type={showPassword ? 'text' : 'password'}
                placeholder="No data yet"
                fullWidth
                value={sio.password}
                margin="normal"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPasswordToggle} className="eyeIcon">
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                      <InputAdornment position="end">
                        <CopyToClipboard
                          text={`${sio.password}`}
                          onCopy={() => toast.success('Password Copied Successfully')}
                        >
                          <FileCopyIcon />
                        </CopyToClipboard>
                      </InputAdornment>
                    </>
                  ),
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight600,
                    },
                  },
                }}
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography sx={commonTextStyle.subHeading}>
                Allow Editing{' '}
                <Tooltip title="Enable editing">
                  <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                </Tooltip>
              </Typography>
              <Switch checked={edit} onClick={() => setEdit(!edit)} />
            </Grid>
            <Grid item xs={12} sm={2} sx={{ marginBottom: '2rem' }}>
              <Button
                disabled={!appName || !siAdminName || !(emailList ?? []).length}
                onClick={handleSubmit}
                sx={commonTextStyle.buttonStyle}
              >
                Update
              </Button>
            </Grid>
            {/* </>
            )} */}
          </Grid>
        </Grid>
      </Box>
      <br />
      {sio.status === 'approved' && (
        <Box boxShadow={3} sx={{ bgcolor: 'white', borderRadius: '20px', mt: 2 }}>
          <Grid container spacing={2} sx={{ width: '100%', margin: 0, borderRadius: '20px', p: 2 }}>
            <Grid item xs={12}>
              <Typography sx={commonTextStyle.heading}>Master Program Agreement</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={commonTextStyle.subHeading}>Agreement Link</Typography>
              <TextField
                disabled
                placeholder="No data yet"
                fullWidth
                value={`${instance?.settings?.portalUrl}/mpa-form/${sio.id}`}
                margin="normal"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard
                        text={`${instance?.settings?.portalUrl}/mpa-form/${sio.id}`}
                        onCopy={() => toast.success('URL Copied Successfully')}
                      >
                        <FileCopyIcon />
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight600,
                    },
                  },
                }}
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography sx={commonTextStyle.subHeading}>Status</Typography>
              {mpa?.mpa ? (
                <div>
                  {mpa?.mpa?.percentComplete !== 100 ? (
                    <Chip
                      sx={{ float: 'left', bgcolor: '#E9F5FF' }}
                      icon={<HourglassEmptyIcon color="primary" />}
                      color="primary"
                      label={
                        mpa?.mpa?.percentComplete === 0 || !mpa?.mpa?.percentComplete
                          ? 'Not Started'
                          : `In Progress ${mpa?.mpa?.percentComplete}%`
                      }
                      variant="outlined"
                    />
                  ) : (
                    <Chip
                      color="success"
                      sx={{ float: 'left', bgcolor: '#DBF6DB' }}
                      icon={<CheckCircleOutlineIcon color="success" />}
                      label="Completed"
                      variant="outlined"
                    />
                  )}
                </div>
              ) : (
                <Chip
                  color="success"
                  sx={{ float: 'left', bgcolor: '#DBF6DB' }}
                  icon={<HourglassEmptyIcon color="primary" />}
                  label="Not Started"
                  variant="outlined"
                />
              )}
              <Button
                sx={{ ...commonTextStyle.buttonStyle, float: 'right' }}
                // size="large"
                // sx={{ float: 'right' }}
                onClick={handleSendMpaForm}
                // variant="contained"
                // color="primary"
              >
                Send MPA Form
              </Button>
            </Grid>
            {mpa?.mpa?.status === 'executed' && (
              <>
                <Grid item xs={4}>
                  <Typography sx={commonTextStyle.subHeading}>
                    Effective Date{' '}
                    <Tooltip title="Effective date">
                      <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                    </Tooltip>
                  </Typography>
                  <TextField
                    disabled
                    placeholder="No data yet"
                    fullWidth
                    value={mpa?.mpa?.effectiveDate !== '' && mpa?.mpa?.effectiveDate}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      sx: {
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fontWeight600,
                        },
                      },
                    }}
                    sx={commonTextStyle.textFieldStyle}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={commonTextStyle.subHeading}>
                    Expiration Date{' '}
                    <Tooltip title="Expiration Date">
                      <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                    </Tooltip>
                  </Typography>
                  <TextField
                    disabled
                    placeholder="No data yet"
                    fullWidth
                    value={mpa?.mpa?.expirationDate !== '' && mpa?.mpa?.expirationDate}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      sx: {
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fontWeight600,
                        },
                      },
                    }}
                    sx={commonTextStyle.textFieldStyle}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={commonTextStyle.subHeading}>
                    Confirmation Name{' '}
                    <Tooltip title="Confirmation name">
                      <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                    </Tooltip>
                  </Typography>
                  <TextField
                    disabled
                    placeholder="No data yet"
                    fullWidth
                    value={mpa?.mpa?.review?.name !== '' && mpa?.mpa?.review?.name}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      sx: {
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fontWeight600,
                        },
                      },
                    }}
                    sx={commonTextStyle.textFieldStyle}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={commonTextStyle.subHeading}>
                    Confirmation Email{' '}
                    <Tooltip title="Confirmation email address">
                      <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                    </Tooltip>
                  </Typography>
                  <TextField
                    disabled
                    placeholder="No data yet"
                    fullWidth
                    value={mpa?.mpa?.review?.email !== '' && mpa?.mpa?.review?.email}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      sx: {
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fontWeight600,
                        },
                      },
                    }}
                    sx={commonTextStyle.textFieldStyle}
                  />
                </Grid>{' '}
                <Grid item xs={4}>
                  <Typography sx={commonTextStyle.subHeading}>
                    Upload Addendum{' '}
                    <Tooltip title="Upload addendum file">
                      <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                    </Tooltip>
                  </Typography>
                  <TextField
                    disabled
                    placeholder="No data yet"
                    fullWidth
                    type="file"
                    // value={mpa.review?.email !== '' && mpa.review?.email}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      sx: {
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: fonts.fontSize16,
                          fontWeight: fonts.fontWeight600,
                        },
                      },
                    }}
                    sx={commonTextStyle.textFieldStyle}
                  />
                </Grid>{' '}
              </>
            )}
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'panel4'}
                onChange={handleAccordion('panel4')}
                sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'panel4' ? (
                      <RemoveIcon color="success" />
                    ) : (
                      <AddIcon color="success" />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={commonTextStyle.heading}>MPA Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography sx={commonTextStyle.subHeading}>First and Last Name</Typography>
                      <TextField
                        disabled
                        placeholder="No data yet"
                        fullWidth
                        value={mpa && mpa?.mpa?.info?.name}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={commonTextStyle.subHeading}>Professional Title</Typography>
                      <TextField
                        disabled
                        placeholder="No data yet"
                        fullWidth
                        value={mpa && mpa?.mpa?.info?.title}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={commonTextStyle.subHeading}>Email Address</Typography>
                      <TextField
                        disabled
                        placeholder="No data yet"
                        fullWidth
                        value={mpa && mpa?.mpa?.info?.email}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '1rem',
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={commonTextStyle.subHeading}>Phone Number</Typography>
                      <TextField
                        disabled
                        placeholder="No data yet"
                        fullWidth
                        value={mpa && mpa?.mpa?.info?.phone}
                        margin="normal"
                        variant="outlined"
                        type="text"
                        error={
                          mpa &&
                          mpa.info?.phone !== undefined &&
                          mpa &&
                          mpa.info?.phone!.length > 0 &&
                          ((mpa && mpa.info?.phone![0] === '0') ||
                            (mpa && mpa.info?.phone![0] === '1') ||
                            (mpa && mpa.info?.phone!.length < 10 && mpa && mpa.info?.phonee !== ''))
                        }
                        InputProps={{
                          inputComponent: CellPhoneInput as any,
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '1rem',
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={commonTextStyle.subHeading}>Street Address</Typography>
                      <TextField
                        disabled
                        placeholder="No data yet"
                        fullWidth
                        value={mpa && mpa?.mpa?.info?.address}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '1rem',
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={commonTextStyle.subHeading}>City</Typography>
                      <TextField
                        disabled
                        placeholder="No data yet"
                        fullWidth
                        value={mpa && mpa?.mpa?.info?.city}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '1rem',
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={commonTextStyle.subHeading}>State</Typography>
                      <TextField
                        disabled
                        placeholder="No data yet"
                        fullWidth
                        value={mpa && mpa?.mpa?.info?.state}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '1rem',
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={commonTextStyle.subHeading}>Zip</Typography>
                      <TextField
                        disabled
                        placeholder="No data yet"
                        fullWidth
                        value={mpa && mpa?.mpa?.info?.zip}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '1rem',
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      )}
      <br />
      {sio.status === 'approved' && (
        <Box boxShadow={3} sx={{ bgcolor: 'white', borderRadius: '20px', display: 'grid', my: 2 }}>
          <Grid
            container
            spacing={2}
            sx={{
              p: 3,
            }}
          >
            <Grid item xs={12} justifyContent="space-between" sx={{ display: 'flex' }}>
              <Typography sx={commonTextStyle.heading}>Users</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={activeChecked}
                      onChange={handleCheckboxChange}
                      name="active"
                    />
                  }
                  label="Active Users"
                  style={{
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight400,
                    color: colors.textPrimary,
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={inactiveChecked}
                      onChange={handleCheckboxChange}
                      name="inactive"
                    />
                  }
                  label="Inactive Users"
                  style={{
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight400,
                    color: colors.textPrimary,
                  }}
                />
                <Button sx={commonTextStyle.buttonStyle} onClick={handleAddUserPopupOpen}>
                  <span>Add Users</span>
                </Button>
              </Box>
            </Grid>
            <Box
              sx={{
                maxHeight: '300px',
                height: getGenericHeight(100),
                border: 'none',
                width: '100%',
                overflow: 'auto',
              }}
            >
              <IsolatedGrid
                model={filteredData}
                columns={columns}
                onChange={(x) => dispatch(setSioUserGridModel(x))}
              />
            </Box>
          </Grid>
        </Box>
      )}
      <AddUserPopup
        open={isAddUserPopupOpen}
        onClose={handleAddUserPopupClose}
        initialUserState={addUserPopupInitialState}
        isEditMode={isAddUserPopupEditMode}
        updateTable={() => addUserPopupInitialState}
      />
      <br />
      {sio.status === 'approved' && (
        <Box boxShadow={3} sx={{ mb: 3, bgcolor: 'white', borderRadius: '20px' }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ width: '100%', margin: 0, borderRadius: '20px', p: 2 }}
          >
            <Grid item xs={12}>
              <Typography sx={commonTextStyle.heading}>Assets</Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography sx={commonTextStyle.subHeading}>Vimeo Video Id</Typography>
              <TextField
                placeholder="No data yet"
                fullWidth
                value={vimeoId}
                margin="normal"
                onChange={(e) => setVimeoId(e.target.value)}
                variant="outlined"
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight600,
                    },
                  },
                }}
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item sm={4}>
              <Typography sx={commonTextStyle.subHeading}>Deliverable Requirements</Typography>
              <TextField
                placeholder="No data yet"
                fullWidth
                value={deliverableRequirements}
                margin="normal"
                onChange={(e) => setDeliverableRequirements(e.target.value)}
                variant="outlined"
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight600,
                    },
                  },
                }}
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            <Grid item sm={4}>
              <Typography sx={commonTextStyle.subHeading}>Upload Fallback image</Typography>
              <TextField
                placeholder="No data yet"
                fullWidth
                margin="normal"
                type="file"
                onChange={handleUpload}
                variant="outlined"
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: fonts.fontSize16,
                      fontWeight: fonts.fontWeight600,
                    },
                  },
                }}
                sx={commonTextStyle.textFieldStyle}
              />
            </Grid>
            {sio?.asset?.videoFallback?.name && (
              <Grid item xs={12} textAlign="right">
                <Typography variant="body1">
                  Uploaded File: {sio?.asset?.videoFallback?.name}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} textAlign="right">
              <Button onClick={handleSubmit} sx={commonTextStyle.cancelSaveBtn}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {sio.status === 'approved' && sio?.program?.length > 0 && (
        <Box boxShadow={3} sx={{ mb: 3, bgcolor: 'white', borderRadius: '20px' }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ width: '100%', margin: 0, borderRadius: '20px', p: 2 }}
          >
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'prol4'}
                onChange={handleAccordion('prol4')}
                sx={{ borderRadius: '20px', boxShadow: 'none', border: 'none' }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'prol4' ? (
                      <RemoveIcon color="success" />
                    ) : (
                      <AddIcon color="success" />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={commonTextStyle.heading}>Programs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {sio?.program &&
                      sio?.program?.map((proDet: any) => {
                        const formattedDate = proDet?.lastUpdated
                          ? formatDate(proDet.lastUpdated)
                          : 'N/A';
                        return (
                          <>
                            <Grid item xs={9}>
                              <Typography
                                sx={{
                                  fontSize: fonts.fontSize16,
                                  fontWeight: fonts.fontWeight600,
                                  color: colors.textSecondary,
                                }}
                              >
                                {proDet?.general?.programName?.platformProgramName ? (
                                  <Link // eslint-disable-next-line no-underscore-dangle
                                    to={`/program-preview/${proDet?._id}?sio_funding_amt=50000`}
                                    target="_blank"
                                    style={{
                                      display: 'flex',
                                      alignItems: 'baseline',
                                      marginRight: '30px',
                                    }}
                                  >
                                    {proDet?.general?.programName?.platformProgramName}
                                  </Link>
                                ) : (
                                  'N/A'
                                )}
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                sx={{
                                  fontSize: fonts.fontSize16,
                                  fontWeight: fonts.fontWeight400,
                                  color: colors.textPrimary,
                                }}
                              >
                                {formattedDate}
                              </Typography>
                            </Grid>
                          </>
                        );
                      })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      )}
      {sio.status === 'approved' && (
        <Box boxShadow={3} sx={{ mb: 3, bgcolor: 'white', borderRadius: '20px' }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ width: '100%', margin: 0, borderRadius: '20px', p: 2 }}
          >
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'prol5'}
                onChange={handleAccordion('prol5')}
                sx={{ borderRadius: '20px', boxShadow: 'none', border: 'none' }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === 'prol5' ? (
                      <RemoveIcon color="success" />
                    ) : (
                      <AddIcon color="success" />
                    )
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={commonTextStyle.heading}>Block List</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Typography sx={commonTextStyle.subHeading}>Company</Typography>
                    <Autocomplete
                      fullWidth
                      sx={{ width: 300, marginTop: '16px !important' }}
                      freeSolo
                      disableClearable
                      options={companies}
                      // value={formConfig.company.value}
                      onInputChange={(event, newInputValue) => {
                        getCompaniesList(newInputValue);
                      }}
                      onChange={(e, value: any) => {
                        const filteredCompany: any = temporaryCompanies.filter(
                          (companyName: any) => companyName.name === value,
                        );
                        setSelectedCompany([
                          {
                            _id: filteredCompany[0]?.id,
                            name: filteredCompany[0]?.name,
                          },
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          sx={commonTextStyle.textFieldStyle}
                        />
                      )}
                    />
                    <Button
                      sx={{
                        ...commonTextStyle.buttonStyle,
                        position: 'absolute',
                        right: '15px',
                        marginTop: '-40px',
                      }}
                      // disabled={
                      //   program.status !== 'approved' ||
                      //   _.isEmpty(company) ||
                      //   _.isEmpty(industryValue)
                      // }
                      onClick={() => addCompanyBlocklist()}
                      variant="outlined"
                      size="large"
                    >
                      Add
                    </Button>
                    <Box sx={{ minHeight: '100px', border: 'none' }}>
                      <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Company</StyledTableCell>
                              <StyledTableCell align="right" />
                            </TableRow>
                          </TableHead>
                          {selectedCompanyBlockList?.length <= 0 ? (
                            <TableBody>
                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  <Typography>No company selected</Typography>
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          ) : (
                            <TableBody>
                              {selectedCompanyBlockList?.map((row: any) => (
                                <StyledTableRow key={row?._id}>
                                  <StyledTableCell component="th" scope="row">
                                    {row?.name}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    <DeleteOutlineOutlined
                                      sx={{ cursor: 'pointer' }}
                                      onClick={() => deleteFromBlocklist(row?._id, 'company')}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box>
                      <Typography sx={commonTextStyle.subHeading}>Industry</Typography>
                      <FormControl fullWidth margin="normal" sx={{ marginTop: '16px !important' }}>
                        <Select
                          value={selectedIndustry}
                          name="industry"
                          placeholder="Select industry"
                          onChange={handleSelectChange}
                          variant="outlined"
                          sx={{
                            ...commonTextStyle.textFieldStyle,
                            width: '28%',
                            '& .MuiSelect-select': {
                              fontSize: fonts.fontSize14,
                              paddingTop: '27px',
                              height: '100%',
                            },
                          }}
                        >
                          <MenuItem selected value="" disabled>
                            <em>Select Industry</em>
                          </MenuItem>
                          {selectIndustryOptions}
                        </Select>
                      </FormControl>
                    </Box>
                    <Button
                      sx={{
                        ...commonTextStyle.buttonStyle,
                        position: 'absolute',
                        right: '15px',
                        marginTop: '-40px',
                      }}
                      // disabled={
                      //   program.status !== 'approved' ||
                      //   _.isEmpty(company) ||
                      //   _.isEmpty(industryValue)
                      // }
                      onClick={() => {
                        addToBlockList();
                      }}
                      variant="outlined"
                      size="large"
                    >
                      Add
                    </Button>
                    <Box sx={{ minHeight: '100px', border: 'none' }}>
                      <TableContainer sx={{ marginTop: '20px' }} component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Industry</StyledTableCell>
                              <StyledTableCell align="right" />
                            </TableRow>
                          </TableHead>
                          {selectedIndustryBlockList?.length <= 0 ? (
                            <TableBody>
                              <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                  <Typography>No industries selected</Typography>
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          ) : (
                            <TableBody>
                              {selectedIndustryBlockList?.map((row: any) => (
                                <StyledTableRow key={row?._id}>
                                  <StyledTableCell component="th" scope="row">
                                    {row?.name}
                                  </StyledTableCell>
                                  <StyledTableCell align="right">
                                    <DeleteOutlineOutlined
                                      sx={{ cursor: 'pointer' }}
                                      onClick={() => deleteFromBlocklist(row?._id, 'industry')}
                                    />
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box boxShadow={3} sx={{ bgcolor: 'white', borderRadius: '20px' }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          sx={{ width: '100%', margin: 0, borderRadius: '20px', p: 2 }}
        >
          <Grid item xs={12}>
            <Typography sx={commonTextStyle.heading} gutterBottom>
              Form Preview
            </Typography>
          </Grid>
          <Grid container spacing={1} className="container" alignItems="center">
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleAccordion('panel1')}
              sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === 'panel1' ? (
                    <RemoveIcon color="success" />
                  ) : (
                    <AddIcon color="success" />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid item xs={9}>
                  <Typography sx={commonTextStyle.accordianLabel}>General Information</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      my: 2,
                      flex: 1,
                      color: colors.textPrimary,
                      fontSize: fonts.fontSize18,
                      fontWeight: 400,
                    }}
                  >
                    {sio?.progress?.general?.complete ? sio?.progress?.general?.complete : 0} of{' '}
                    {sio?.progress?.general?.total ? sio?.progress?.general?.total : 10} Completed
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        What is the name of your organization?
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={commonTextStyle.labelStyle}>Legal Name</Typography>
                      <TextField
                        disabled
                        placeholder="No data yet"
                        fullWidth
                        value={sio && sio.general?.name?.legalOrganizationName}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={commonTextStyle.labelStyle}>Public Name</Typography>
                      <TextField
                        disabled
                        placeholder="No data yet"
                        fullWidth
                        value={sio.general?.name?.publicOrganizationName}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Please provide your website and social media handles
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography sx={commonTextStyle.labelStyle}>Website URL</Typography>
                      <TextField
                        placeholder="No data yet"
                        fullWidth
                        disabled
                        value={sio.general?.social?.websiteURL}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography sx={commonTextStyle.labelStyle}>Facebook</Typography>
                      <TextField
                        placeholder="No data yet"
                        fullWidth
                        value={sio.general?.social?.facebook}
                        disabled
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography sx={commonTextStyle.labelStyle}>Instagram</Typography>
                      <TextField
                        placeholder="No data yet"
                        fullWidth
                        value={sio.general?.social?.instagram}
                        margin="normal"
                        disabled
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography sx={commonTextStyle.labelStyle}>Twitter</Typography>
                      <TextField
                        placeholder="No data yet"
                        fullWidth
                        value={sio.general?.social?.twitter}
                        margin="normal"
                        disabled
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography sx={commonTextStyle.labelStyle}>LinkedIn</Typography>
                      <TextField
                        placeholder="No data yet"
                        fullWidth
                        value={sio.general?.social?.linkedIn}
                        disabled
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Who will be the main contact person for your organization?
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={commonTextStyle.labelStyle}>First and Last Name</Typography>
                      <TextField
                        placeholder="No data yet"
                        fullWidth
                        value={sio.general?.contact?.name}
                        margin="normal"
                        disabled
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={commonTextStyle.labelStyle}>Professional Title</Typography>
                      <TextField
                        placeholder="No data yet"
                        fullWidth
                        value={sio.general?.contact?.professionalTitle}
                        margin="normal"
                        disabled
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={commonTextStyle.labelStyle}>Email Address</Typography>
                      <TextField
                        placeholder="No data yet"
                        fullWidth
                        value={sio.general?.contact?.email}
                        margin="normal"
                        disabled
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={commonTextStyle.labelStyle}>Phone Number</Typography>
                      <TextField
                        placeholder="No data yet"
                        fullWidth
                        value={sio.general?.contact?.phone}
                        margin="normal"
                        disabled
                        variant="outlined"
                        type="text"
                        error={
                          sio.general?.contact?.phone !== undefined &&
                          sio.general?.contact?.phone!.length > 0 &&
                          (sio.general?.contact?.phone![0] === '0' ||
                            sio.general?.contact?.phone![0] === '1' ||
                            (sio.general?.contact?.phone!.length < 10 &&
                              sio.general?.contact?.phone !== ''))
                        }
                        InputProps={{
                          inputComponent: CellPhoneInput as any,
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Where is your organization incorporated?
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={commonTextStyle.labelStyle}>Registration Location</Typography>
                      <TextField
                        placeholder="No data yet"
                        fullWidth
                        value={sio.general?.location?.generalLocation}
                        disabled
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        ScaleWith is commited to working with organizations that foster inclusivity
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={commonTextStyle.labelStyle}>Align with mission</Typography>
                      <TextField
                        placeholder="No data yet"
                        fullWidth
                        value={sio.general?.missionAgreement}
                        disabled
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel2'}
              onChange={handleAccordion('panel2')}
              sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === 'panel2' ? (
                    <RemoveIcon color="success" />
                  ) : (
                    <AddIcon color="success" />
                  )
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Grid item xs={9}>
                  <Typography sx={commonTextStyle.accordianLabel}>Overview and Mission</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      my: 2,
                      flex: 1,
                      color: colors.textPrimary,
                      fontSize: fonts.fontSize18,
                      fontWeight: 400,
                    }}
                    gutterBottom
                  >
                    {sio?.progress?.overviewAndMission?.complete
                      ? sio?.progress?.overviewAndMission?.complete
                      : 0}{' '}
                    of{' '}
                    {sio?.progress?.overviewAndMission?.total
                      ? sio?.progress?.overviewAndMission?.total
                      : 7}{' '}
                    Completed
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Briefly talk about your organizations history and mission
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.labelStyle}>History and mission</Typography>
                      <TextField
                        placeholder="No data yet"
                        value={sio.overviewAndMission?.historyDescription}
                        id="outlined-multiline-flexible"
                        disabled
                        variant="outlined"
                        multiline
                        fullWidth
                        rows={4}
                        sx={commonTextStyle.textAreaStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Briefly describe the problem your organization works to address
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.labelStyle}>Need or Problem</Typography>
                      <TextField
                        id="outlined-multiline-flexible"
                        placeholder="No data yet"
                        value={sio.overviewAndMission?.problemDescription}
                        disabled
                        variant="outlined"
                        multiline
                        fullWidth
                        rows={4}
                        sx={commonTextStyle.textAreaStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        What cause area matches your organization&#39;s mission?
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.labelStyle}>Cause Area</Typography>
                      <TextField
                        id="outlined-multiline-flexible"
                        placeholder="No data yet"
                        value={sio.overviewAndMission?.causeAreas?.selected}
                        variant="outlined"
                        disabled
                        multiline
                        fullWidth
                        rows={4}
                        sx={commonTextStyle.textAreaStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Briefly describe your organizations program&#39;s, initiatives and
                        priorities
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.labelStyle}>
                        Programs, Initiatives, Priorities
                      </Typography>
                      <TextField
                        placeholder="No data yet"
                        id="outlined-multiline-flexible"
                        value={sio.overviewAndMission?.initiativesDescription}
                        variant="outlined"
                        disabled
                        multiline
                        fullWidth
                        rows={4}
                        sx={commonTextStyle.textAreaStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Where do your program operate
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.labelStyle} gutterBottom>
                        Please include information like country, state, and city as applicable
                      </Typography>
                      <TextField
                        placeholder="No data yet"
                        value={sio.overviewAndMission?.programLocations}
                        id="outlined-multiline-flexible"
                        disabled
                        variant="outlined"
                        multiline
                        fullWidth
                        rows={4}
                        sx={commonTextStyle.textAreaStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Briefly describe research and evaluation used to support and program design
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.labelStyle}>
                        Research and Evaluation
                      </Typography>
                      <TextField
                        placeholder="No data yet"
                        value={sio.overviewAndMission?.researchAndEvaluation}
                        id="outlined-multiline-flexible"
                        variant="outlined"
                        multiline
                        disabled
                        fullWidth
                        rows={4}
                        sx={commonTextStyle.textAreaStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Please upload the research and evalualtion that informs the program design
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.subHeading}>
                        Research and evaluation file
                      </Typography>
                      <TextField
                        name="fileUpload"
                        fullWidth
                        type="file"
                        // onChange={handleUpload}
                        margin="none"
                        variant="outlined"
                        disabled
                        // InputProps={{
                        //   style: {
                        //     fontSize: fonts.fontSize14,
                        //     fontWeight: fonts.fontWeight400,
                        //     color: colors.textPrimary,
                        //     border: `1px solid ${colors.strokeDefault}`
                        //   }
                        // }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                      <Typography sx={commonTextStyle.labelStyle}>
                        <b style={{ marginRight: '4px' }}>File Name: </b>{' '}
                        {sio.overviewAndMission?.researchAndEvaluationFile?.name}
                        {sio.overviewAndMission?.researchAndEvaluationFile?.name &&
                          downloadTextButton(sio.overviewAndMission?.researchAndEvaluationFile)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        What are some of your organization&#39;s lifetime output
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.labelStyle}>Lifetime Output</Typography>
                      {lifetimeOutputs.map((lifetimeOutput, index) => (
                        <Grid container spacing={2} sx={{ mb: 2 }} key={lifetimeOutput.output}>
                          <Grid item xs={12} sm={3} className="gridItem">
                            <Typography sx={commonTextStyle.subHeading}>
                              Quantity {index + 1}
                            </Typography>
                            <TextField
                              name="quantity"
                              placeholder="No data yet"
                              type="number"
                              value={lifetimeOutput.quantity}
                              disabled
                              variant="outlined"
                              className="inputTextFiled"
                              fullWidth
                              sx={commonTextStyle.textFieldStyle}
                            />
                          </Grid>
                          <Grid item xs={12} sm={9} className="gridItem">
                            <Typography sx={commonTextStyle.subHeading}>
                              Output {index + 1} Description
                            </Typography>
                            <TextField
                              name="output"
                              type="text"
                              placeholder="No data yet"
                              value={lifetimeOutput.output}
                              disabled
                              variant="outlined"
                              className="inputTextFiled"
                              fullWidth
                              sx={commonTextStyle.textFieldStyle}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleAccordion('panel3')}
              sx={{ borderRadius: '20px', my: 2, boxShadow: 'none' }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === 'panel3' ? (
                    <RemoveIcon color="success" />
                  ) : (
                    <AddIcon color="success" />
                  )
                }
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Grid item xs={9}>
                  <Typography sx={commonTextStyle.accordianLabel}>Operational Info</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      my: 2,
                      flex: 1,
                      color: colors.textPrimary,
                      fontSize: fonts.fontSize18,
                      fontWeight: 400,
                    }}
                    gutterBottom
                  >
                    {sio?.progress?.operationalInformation?.complete
                      ? sio?.progress?.operationalInformation?.complete
                      : 0}{' '}
                    of{' '}
                    {sio?.progress?.operationalInformation?.total
                      ? sio?.progress?.operationalInformation?.total
                      : 7}{' '}
                    Completed
                  </Typography>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Please provide the number of paid and volunteer staff at your organization
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={commonTextStyle.subHeading}>Full time</Typography>
                      <TextField
                        fullWidth
                        value={sio.operationalInformation?.staff?.fullTime}
                        margin="normal"
                        placeholder="No data yet"
                        disabled
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={commonTextStyle.subHeading}>Part time</Typography>
                      <TextField
                        value={sio.operationalInformation?.staff?.partTime}
                        fullWidth
                        margin="normal"
                        placeholder="No data yet"
                        disabled
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={commonTextStyle.subHeading}>Volunteer</Typography>
                      <TextField
                        value={sio.operationalInformation?.staff?.volunteers}
                        fullWidth
                        disabled
                        placeholder="No data yet"
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: fonts.fontSize16,
                              fontWeight: fonts.fontWeight600,
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Breifly describe any formal and informal partnership with other non-profit
                        of government entities
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.subHeading}>
                        Formal and Informal Relationships
                      </Typography>
                      <TextField
                        placeholder="No data yet"
                        id="outlined-multiline-flexible"
                        disabled
                        variant="outlined"
                        value={sio.operationalInformation?.partnershipsDescription}
                        multiline
                        fullWidth
                        rows={4}
                        sx={commonTextStyle.textAreaStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        What is your organizations total budget for the current year
                      </Typography>
                    </Grid>
                    {isOtherSelected && (
                      <Grid item xs={12} sm={4}>
                        <Typography sx={commonTextStyle.subHeading}>Currency</Typography>
                        <TextField
                          disabled
                          placeholder="No data yet"
                          fullWidth
                          value="Other"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            sx: {
                              '& .MuiInputBase-input::placeholder': {
                                fontSize: '1rem',
                              },
                            },
                          }}
                          sx={commonTextStyle.textFieldStyle}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={isOtherSelected ? 4 : 6}>
                      <Typography sx={commonTextStyle.subHeading}>
                        {isOtherSelected ? 'Mention Other' : 'Currency'}
                      </Typography>
                      <TextField
                        disabled
                        placeholder="No data yet"
                        fullWidth
                        value={sio.operationalInformation?.yearlyBudgetCurrency}
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '1rem',
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} sm={isOtherSelected ? 4 : 6}>
                      <Typography sx={commonTextStyle.subHeading}>Budget</Typography>
                      <TextField
                        value={sio.operationalInformation?.yearlyBudget}
                        disabled
                        fullWidth
                        placeholder="No data yet"
                        margin="normal"
                        variant="outlined"
                        InputProps={{
                          sx: {
                            '& .MuiInputBase-input::placeholder': {
                              fontSize: '1rem',
                            },
                          },
                        }}
                        sx={commonTextStyle.textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Please provide your most recent financial statement
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.subHeading}>Statement File Name</Typography>
                      <TextField
                        name="fileUpload"
                        fullWidth
                        type="file"
                        // onChange={handleUpload}
                        margin="none"
                        variant="outlined"
                        disabled
                        sx={commonTextStyle.textFieldStyle}
                      />
                      <Typography sx={commonTextStyle.labelStyle}>
                        <b style={{ marginRight: '4px' }}>File Name: </b>{' '}
                        {sio.operationalInformation?.financialStatement?.file?.name}
                        {sio.operationalInformation?.financialStatement?.file?.name &&
                          downloadTextButton(sio.operationalInformation?.financialStatement?.file)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
                    </Grid>
                    {/* new section */}
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.questionStyle}>
                        Please list your foundation and corporate supporters and partners from this
                        or the most recent fiscal year
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={commonTextStyle.subHeading}>Support and Partners</Typography>
                      <TextField
                        disabled
                        placeholder="No data yet"
                        id="outlined-multiline-flexible"
                        variant="outlined"
                        value={sio.operationalInformation?.supportersAndPartners}
                        multiline
                        fullWidth
                        rows={4}
                        sx={commonTextStyle.textAreaStyle}
                      />
                    </Grid>
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ my: 2 }} orientation="horizontal" flexItem />
          </Grid>
          {/* approve, archive and reject modal */}
          <Modal
            open={openApprovedModal !== ''}
            onClose={handleCloseModal}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    bgcolor: `${
                      openApprovedModal === 'Rejected' ? '#FDE3E3' : 'rgba(0,95,234,0.2)'
                    }`,
                    mb: 2,
                    p: 2,
                    borderRadius: '20px 20px 0 0',
                  }}
                >
                  <Typography
                    variant="h5"
                    color={openApprovedModal === 'Rejected' ? 'error' : 'primary'}
                  >
                    {`${openApprovedModal} Confirmation`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ p: 2 }}>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    Are you sure you want to {openApprovedModal === 'Approved' && 'approve'}
                    {openApprovedModal === 'Archived' && 'archive'}
                    {openApprovedModal === 'Rejected' && 'reject'} SIO Application for &quot;
                    {sio.applicationFormName}&quot;
                  </Typography>
                </Grid>
                <Grid item xs={12} textAlign="right" sx={{ p: 2, borderTop: '1px solid #E6E6E6' }}>
                  <Button
                    onClick={handleCloseModal}
                    sx={{ ml: 3 }}
                    variant="outlined"
                    size="large"
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleApprovedAction(openApprovedModal)}
                    sx={{ ml: 3 }}
                    variant="contained"
                    size="large"
                    color={openApprovedModal === 'Rejected' ? 'error' : 'primary'}
                  >
                    {openApprovedModal}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          {/* delete and cancel modal */}
          <Modal
            open={openModal || openDeleteModal}
            onClose={handleCloseModal}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...style }}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    bgcolor: `${openDeleteModal ? '#FDE3E3' : 'rgba(0,95,234,0.2)'}`,
                    mb: 2,
                    p: 2,
                    borderRadius: '20px 20px 0 0',
                  }}
                >
                  <Typography variant="h5" color={openDeleteModal ? 'error' : 'primary'}>
                    {openDeleteModal ? 'Delete Confirmation' : 'Cancel Confirmation'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ p: 2 }}>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    Are you sure you want to {openDeleteModal ? 'delete this' : 'cancel'}?
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {openDeleteModal
                      ? 'Deleting this record cannot be undone and all associated data will be permanently removed. Please confirm that you wish to proceed with the deletion.'
                      : 'Cancelling this process will redirect you to SIO Organizations Dashboard Page. Any unsaved data record will be lost permanently.'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ p: 2, borderTop: '1px solid #E6E6E6' }}>
                  {openDeleteModal ? (
                    <>
                      <Button
                        onClick={handleDeleteSio}
                        sx={{ float: 'right', ml: 3 }}
                        variant="contained"
                        size="large"
                        color="error"
                      >
                        Delete SIO Form
                      </Button>
                      <Button
                        onClick={handleCloseModal}
                        sx={{ float: 'right', ml: 3 }}
                        variant={openDeleteModal ? 'outlined' : 'contained'}
                        size="large"
                        color="primary"
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={handleCloseModal}
                        sx={{ float: 'right', ml: 3 }}
                        variant="outlined"
                        size="large"
                        color="primary"
                      >
                        Stay
                      </Button>
                      <Button
                        onClick={handleCancelSio}
                        sx={{ float: 'right', ml: 3 }}
                        variant={openDeleteModal ? 'outlined' : 'contained'}
                        size="large"
                        color="primary"
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Modal
            open={openSuccessModal}
            onClose={handleCloseSuccessModal}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={{ ...successStyle }}>
              <Grid container>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <CloseIcon
                    onClick={handleCloseSuccessModal}
                    fontSize="medium"
                    sx={{ position: 'absolute', right: '20px', top: '20px' }}
                  />
                  <CheckCircleOutlineIcon fontSize="large" color="success" />
                  <Typography variant="h5" sx={{ mb: 3 }}>
                    SIO Organization Form Shared Successfully!
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    An email with below form link and password has been sent to your admin users
                    successfully!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ border: '1px solid #c4bebe', borderRadius: '16px', padding: '20px' }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.subHeading}>
                          SIO Form URL{' '}
                          <Tooltip title="Click to copy URL">
                            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                          </Tooltip>
                        </Typography>
                        <TextField
                          disabled
                          placeholder="No data yet"
                          fullWidth
                          value={`${instance?.settings?.portalUrl}/sio-form/${sio.id}`}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            sx: {
                              '& .MuiInputBase-input::placeholder': {
                                fontSize: '1rem',
                              },
                            },
                          }}
                          sx={commonTextStyle.textFieldStyle}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={commonTextStyle.subHeading}>
                          Password{' '}
                          <Tooltip title="Click to copy password">
                            <InfoIcon sx={{ fontSize: '15px', color: '#868e96' }} />
                          </Tooltip>
                        </Typography>
                        <TextField
                          disabled
                          placeholder="No data yet"
                          type={showPassword ? 'text' : 'password'}
                          fullWidth
                          value={sio.password}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleShowPasswordToggle} className="eyeIcon">
                                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                            sx: {
                              '& .MuiInputBase-input::placeholder': {
                                fontSize: '1rem',
                              },
                            },
                          }}
                          sx={commonTextStyle.textFieldStyle}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
                        <CopyToClipboard
                          text={`${instance?.settings?.portalUrl}/sio-form/${sio.id}/${sio.password}`}
                          onCopy={() => toast.success('URL Copied Successfully')}
                        >
                          <Button variant="text" color="success">
                            <FileCopyIcon fontSize="small" color="success" /> copy to clipboard
                          </Button>
                        </CopyToClipboard>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Grid item textAlign="right" xs={12}>
            {renderButtons()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SioDetails;
