import { httpSecuredCommon, cancelToken } from '../httpCommon';

const base = '/funding-forms'

export const FUNDING_API_ROUTES = {
    getFundings: base,
    postFunding: base,
    getFundingbyUser: (id: string) => `${base}/${id}`,
    postFundingbyUser: (id: string) => `${base}/users/${id}`,
    updateFundingbyUserId: (id: string) => `${base}/users/${id}`,
    // getVocabulary: 'https://dev.api.platform.v1.scalewith.com/programs/vocabulary',
    uploadFile:(id: string) => `${base}/file/${id}`,
    // getLocations: `https://dev.api.platform.v1.scalewith.com/programs/locations`,
}


export const getFundingbyUser = async (id: string) => {
    const response = await httpSecuredCommon.get(FUNDING_API_ROUTES.getFundingbyUser(id), {
      cancelToken: cancelToken.token,
    });
    return response;
  };

  export const updateFundingbyUser = async (program: any,id:string) => {
    const response = await httpSecuredCommon.patch(FUNDING_API_ROUTES.updateFundingbyUserId(id), program, {
      cancelToken: cancelToken.token,
    });
    return response;
  };

  // export const getVocab = async () => {
  //   const response = await httpSecuredCommon.get(FUNDING_API_ROUTES.getVocabulary);
  //   return response;
  // };

  export const uploadFile = async (id: string, data: any) => {
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
  };
    const response = await httpSecuredCommon.post(FUNDING_API_ROUTES.uploadFile(id), data,config);
    return response;
  };

  // export const getLocations = async () => {
  //   const response = await httpSecuredCommon.get(FUNDING_API_ROUTES.getLocations);
  //   return response;
  // };

  export const postFundingbyUser = async (funding: any,id:string) => {
    const response = await httpSecuredCommon.post(FUNDING_API_ROUTES.postFundingbyUser(id), funding, {
      cancelToken: cancelToken.token,
    });
    return response;
  };