import { RenewAccessTokenResponse } from '../../shared/interfaces';
import { httpAuth, cancelToken, httpSecuredCommon } from '../httpCommon';

const base = '/v1/okta';

export const AUTHENTICATION_API_ROUTES = {
  renewAccessToken: `${base}/authorize`,
  requestForgotPassword: `/users/request_reset_password`,
};

export const renewAccessToken = async (refreshToken: string) => {
  const response = await httpAuth.post<RenewAccessTokenResponse>(
    AUTHENTICATION_API_ROUTES.renewAccessToken,
    {
      refresh_token: refreshToken,
      grantType: 'refresh_token',
    },
  );
  return response;
};

export const requestForgotPassword = async (email: string) => {
  const response = await httpSecuredCommon.post(AUTHENTICATION_API_ROUTES.requestForgotPassword, {
    email,
  }, {
    cancelToken: cancelToken.token
  });
  return response;
};
