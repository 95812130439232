/* eslint-disable arrow-body-style */
import React, { ChangeEvent } from 'react';
import { Typography, Grid } from '@mui/material';
import GridInput from './gridTextField';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface Props {
  title?: string;
  description?: string;
  secondaryTitle?: string;
  isTextField?: boolean;
  infoIcon?: boolean;
  tooltipText?: string;
  height?: string;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  defaultValue?: any;
  disabled?:boolean;
}

const TextAreaInput: React.FC<Props> = ({
  title,
  description,
  secondaryTitle,
  isTextField = false,
  infoIcon,
  tooltipText,
  height = '132',
  placeholder,
  onChange,
  value,
  required,
  error,
  helperText,
  defaultValue,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12} sx={{ mb: 1 }}>
        <Typography sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight600, color: colors.textPrimary }}>{title}</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: fonts.fontSize16, fontWeight: fonts.fontWeight500, color: colors.textPrimary }}>{description}</Typography>
      </Grid>
      {isTextField && (
        <GridInput
          typographyText={secondaryTitle}
          variantType="h6"
          type="text"
          multiline
          rows={3}
          infoIcon={infoIcon || false}
          placeholder={placeholder}
          tooltipText={tooltipText}
          height={height}
          onChange={onChange}
          value={value}
          required={false || required}
          error={error}
          helperText={helperText}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      )}
    </>
  );
};
export default TextAreaInput;
