import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addCancelTokenEvent,
  getCauseAreaMetricsDetails,
  getCauseAreaMetricsDetailsDownload,
} from '../../../../api';
import { CauseAreaMetricsDialogTabs, OrderStatus } from '../../../../shared/enums';
import {
  CauseAreaMetricsOverViewItemResponse,
  CauseAreaMetricsRequest,
  CauseAreaMetricsResponse,
  GridModel,
} from '../../../../shared/interfaces';

interface CauseAreasMetricsDialogSlice {
  selectedTab: CauseAreaMetricsDialogTabs;
  causeAreaMetricsDialogData: GridModel<CauseAreaMetricsOverViewItemResponse>;
  isLoadingDownload: boolean;
}

const initialState: CauseAreasMetricsDialogSlice = {
  selectedTab: CauseAreaMetricsDialogTabs.causeAreas,
  causeAreaMetricsDialogData: {
    name: 'Cause Area Metrics Dialog',
    data: {
      totalCount: 0,
      results: [],
    },
    page: 0,
    pageSize: 10000,
    loadingGrid: false,
    defaultSort: { field: 'name', direction: 'asc' },
  },
  isLoadingDownload: false,
};
export const getCauseAreaMetricsViewAsTableThunk = createAsyncThunk<
  CauseAreaMetricsResponse,
  CauseAreaMetricsRequest
>('causeAreasMetricsTable/getCauseAreaMetricsViewAsTableThunk', async (value, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getCauseAreaMetricsDetails(value);
  return data;
});

export const getCauseAreaMetricsDetailsDownloadThunk = createAsyncThunk<
  void,
  CauseAreaMetricsRequest
>('causeAreasMetricsTable/getCauseAreaMetricsDetailsDownloadThunk', async (value, { signal }) => {
  addCancelTokenEvent(signal);
  const { data } = await getCauseAreaMetricsDetailsDownload(value);
  return data;
});

const causeAreaMetricsDialogSlice = createSlice({
  name: 'causeAreaMetricsDialog',
  initialState,
  reducers: {
    setCauseAreaMetricsDialogTab: (state, action: PayloadAction<number>) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getCauseAreaMetricsViewAsTableThunk.fulfilled,
        (state, action: PayloadAction<CauseAreaMetricsResponse>) => {
          const allInformation = action.payload.results.map((principalItem, index) => {
            const proposed = principalItem.details?.find(
              (item) => item.status === OrderStatus.PendingApproval,
            );
            const completed = principalItem.details?.find(
              (item) => item.status === OrderStatus.Completed,
            );
            const approved = principalItem.details?.find(
              (item) => item.status === OrderStatus.Approved,
            );
            const financialHold = principalItem.details?.find(
              (item) => item.status === OrderStatus.FinancialHold,
            );
            return {
              ...principalItem,
              id: `cause-area-metrics-${principalItem.key?.causeArea}${index}`,
              proposed: proposed?.totalGiveAmount ?? 0,
              completed: completed?.totalGiveAmount ?? 0,
              approved: approved?.totalGiveAmount ?? 0,
              financialHold: financialHold?.totalGiveAmount ?? 0,
            };
          });
          state.causeAreaMetricsDialogData.data.results = allInformation;
          state.causeAreaMetricsDialogData.loadingGrid = false;
        },
      )
      .addCase(getCauseAreaMetricsViewAsTableThunk.rejected, (state) => {
        state.causeAreaMetricsDialogData.loadingGrid = false;
      })
      .addCase(getCauseAreaMetricsViewAsTableThunk.pending, (state) => {
        if (!state.causeAreaMetricsDialogData.loadingGrid)
          state.causeAreaMetricsDialogData.loadingGrid = true;
      })
      .addCase(getCauseAreaMetricsDetailsDownloadThunk.fulfilled, (state) => {
        state.isLoadingDownload = false;
      })
      .addCase(getCauseAreaMetricsDetailsDownloadThunk.rejected, (state) => {
        state.isLoadingDownload = false;
      })
      .addCase(getCauseAreaMetricsDetailsDownloadThunk.pending, (state) => {
        if (!state.isLoadingDownload) state.isLoadingDownload = true;
      });
  },
});

export const { setCauseAreaMetricsDialogTab } = causeAreaMetricsDialogSlice.actions;

export const causeAreaMetricsDialogReducer = causeAreaMetricsDialogSlice.reducer;
