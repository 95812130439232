import React from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import _ from 'lodash';
import { useAppSelector } from '../../../shared/hooks';
import { FormDialog, LightTooltip } from '../../../shared/components';
import { getDateString, getUsernameFromEmail } from '../../../shared/utils';
import { Dialogs } from '../../../shared/enums';

export const GiveHistory = () => {
  const comments = useAppSelector((x) => x.incentive.incentive?.comments);
  const hasComments = comments && comments.length > 0;

  return (
    <FormDialog
      title="Social Impact Contribution History"
      type={Dialogs.ViewContributionHistory}
      cancelButtonText="Close"
    >
      {hasComments && (
        <Table sx={{ mb: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>User</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Old Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>New Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Comment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_(comments!)
              .orderBy((c) => c.timestamp, 'desc')
              .map((comment) => (
                <TableRow key={`view-history-comment-${comment?.timestamp}`}>
                  <TableCell>
                    <LightTooltip title={getDateString(comment?.timestamp) ?? ''}>
                      <Box component="span">{getDateString(comment?.timestamp, true)}</Box>
                    </LightTooltip>
                  </TableCell>
                  <TableCell>
                    <LightTooltip title={comment.user ?? ''}>
                      <Box component="span">{getUsernameFromEmail(comment.user)}</Box>
                    </LightTooltip>
                  </TableCell>
                  <TableCell>{comment.oldStatus}</TableCell>
                  <TableCell>{comment.newStatus}</TableCell>
                  <TableCell>{comment.comment}</TableCell>
                </TableRow>
              ))
              .value()}
          </TableBody>
        </Table>
      )}
      {!hasComments && <Typography>History could not be found</Typography>}
    </FormDialog>
  );
};
