import { Box, Typography, Radio } from '@mui/material';
import React, { FC, useState, useEffect } from 'react';
import { CheckCircle } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { FlexBoxColumn } from '../../../shared/components';
import { CompanyRecommendedProgram } from '../../../shared/interfaces';
import { CauseAreaChip } from './CauseAreaChip';
import { MoreDetails } from './MoreDetails';
import fonts from '../../../assets/scss/font.module.scss';
import colors from '../../../assets/scss/color.module.scss';
import unSdgData from '../../../app/assets.json';

interface Props {
  program: CompanyRecommendedProgram;
  onSelect: (program: CompanyRecommendedProgram) => void;
  selected?: boolean;
  fundingAmount: number;
  percentage: number;
}
// interface OutputProps {
//   programOutput: { description: string; value: number };
// }
// const OutputComponent: FC<OutputProps> = ({ programOutput }) => {
//   const { description, value } = programOutput;

//   function nFormatter(number: number) {
//     if (number >= 1000000000000) {
//       return `${(number / 1000000000000).toFixed(0).replace(/\.0$/, '')}T`;
//     }
//     if (number >= 1000000000) {
//       return `${(number / 1000000000).toFixed(0).replace(/\.0$/, '')}B`;
//     }
//     if (number >= 1000000) {
//       return `${(number / 1000000).toFixed(0).replace(/\.0$/, '')}M`;
//     }
//     if (number >= 1000) {
//       return `${(number / 1000).toFixed(0).replace(/\.0$/, '')}K`;
//     }
//     return number;
//   }

//   return (
//     <FlexBox className="program_impact__container">
//       <Typography className="value">{nFormatter(value)}</Typography>
//       <Typography className="description">{description}</Typography>
//     </FlexBox>
//   );
// };
export const ProgramCard: FC<Props> = ({
  program,
  onSelect,
  selected,
  fundingAmount,
  percentage,
}) => {
  const {
    imageLandscape,
    programId,
    programName,
    sioName,
    // outputs,
    themes,
    // causeAreas,
    sdg,
  } = program;

  function displayItemCount(detail: any) {
    let cardCount;
    if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
      cardCount = detail?.slice(0, 1);
    } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
      cardCount = detail?.slice(0, 3);
    } else if (window.innerWidth >= 1920 && window.innerWidth < 2560) {
      cardCount = detail?.slice(0, 4);
    } else if (window.innerWidth >= 2560) {
      cardCount = detail?.slice(0, 7);
    }
    return cardCount;
  }

  const [showSDGAccordion, setShowSDGAccordion] = useState(false);
  const [showCausesAccordion, setShowCausesAccordion] = useState(false);
  const [accordionSDGList, setAccordionSDGList] = useState([]);
  const [accordionCausesList, setAccordionCausesList] = useState([]);
  const [unSdgIcons, setUnSdgIcons] = useState<any>();

  const toggleAccordion = (toggleCount: number, details: any, toggleType: string) => {
    if (toggleType === 'sdg') {
      const reversedDetails = details.slice(`-${toggleCount}`).reverse();
      setShowSDGAccordion(!showSDGAccordion);
      setAccordionSDGList(reversedDetails);
    } else {
      const reversedDetails = details.slice(`-${toggleCount}`).reverse();
      setShowCausesAccordion(!showCausesAccordion);
      setAccordionCausesList(reversedDetails);
    }
  };

  useEffect(() => {
    setUnSdgIcons(unSdgData);
  }, []);

  return (
    <Box
      // onClick={() => onSelect(program)}
      className={`ProgramCard__container ${selected ? 'selected' : 'not_selected'}`}
      sx={{
        display: 'flex',
        // ':hover': { border: '1px solid #009F75 !important' },
        border: `${selected ? `1px solid ${colors.primary}` : `1px solid ${colors.strokeDefault}`}`,
        height: 'auto',
      }}
    >
      <Box sx={{ padding: '12px', display: 'grid' }}>
        <img
          alt="program"
          src={imageLandscape}
          className="programImage__container"
          style={{
            background: imageLandscape,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '195px',
            width: '150px',
            borderRadius: '15px',
            // borderTopLeftRadius: '15px',
            // borderBottomLeftRadius: '15px',
          }}
        />
      </Box>
      <FlexBoxColumn sx={{ height: '100%', width: '100%' }}>
        <Box
          className="programCard_title"
          sx={{
            backgroundColor: `${selected ? colors.backgroundSecondary : colors.backgroundPrimary}`,
            paddingX: '20px',
            paddingY: '15px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: fonts.fontSize18,
                fontWeight: fonts.fontWeight700,
                color: colors.textPrimary,
              }}
            >
              {programName}
            </Typography>
            <Typography
              sx={{
                fontSize: fonts.fontSize18,
                color: colors.primary,
                fontWeight: fonts.fontWeight600,
              }}
            >
              <span style={{ color: '#000000' }}>SIO:</span>&nbsp;
              <Link
                to={`/program-preview/${programId}?sio_funding_amt=${fundingAmount}`}
                target="_blank"
                style={{
                  textDecoration: 'underline',
                  fontSize: fonts.fontSize18,
                  color: colors.primary,
                  fontWeight: fonts.fontWeight700,
                }}
              >
                {sioName}
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center', // Center items vertically
            }}
          >
            <Box
              sx={{
                // position: 'absolute',
                // left: '8%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // padding: '1rem',
                // top: '82.19%',
                // bottom: '6.85%',
                // zIndex: '1',
                background: colors.primary,
                border: `1px solid ${colors.primary}`,
                borderRadius: '20px',
                // width: '152px',
                // height: '32px',
                padding: '5px 10px', // Add padding as needed
                marginRight: '5px', // Add margin as needed
              }}
            >
              <CheckCircle sx={{ color: colors.secondary, marginRight: '5px' }} />
              <Typography
                sx={{
                  fontWeight: fonts.fontWeight800,
                  fontSize: fonts.fontSize12,
                  color: colors.secondary,
                }}
              >
                {Number.isInteger(percentage) === false ? percentage.toFixed(2) : percentage}%
                Match
              </Typography>
            </Box>
            <Radio
              checkedIcon={
                <CheckIcon
                  style={{
                    color: colors.secondary,
                    backgroundColor: colors.primary,
                    borderRadius: '20px',
                    fontSize: '24px',
                  }}
                />
              } // Icon for the checked state
              sx={{
                color: colors.primary,
                '&.Mui-checked': {
                  color: colors.primary,
                },
              }}
              checked={selected}
              onChange={() => onSelect(program)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            {/* <Checkbox
              sx={{
                color: '#009F75',
                '&.Mui-checked': {
                  color: '#009F75',
                },
              }}
              checked={selected}
              onChange={() => onSelect(program)}
              inputProps={{ 'aria-label': 'controlled' }}
            /> */}
          </Box>
        </Box>
        <Box sx={{ width: '90%', marginLeft: '20px' }}>
          <Box sx={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>
            {displayItemCount(themes)?.map((theme: any, i: number) => (
              <CauseAreaChip causeAreaThemes={theme} index={i} />
            ))}
            {themes &&
              displayItemCount(themes) &&
              themes.length - displayItemCount(themes).length !== 0 && (
                <Box
                  sx={{
                    marginLeft: '20px',
                    color: colors.primary,
                    border: `1px solid ${colors.primary}`,
                    borderRadius: fonts.fontSize10,
                    height: '28px',
                    width: '42px',
                    fontSize: fonts.fontSize14,
                    fontWeight: fonts.fontWeight700,
                    marginTop: '0px',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    toggleAccordion(
                      themes.length - displayItemCount(themes).length,
                      themes,
                      'themes',
                    )
                  }
                >
                  {!showCausesAccordion ? '+' : '-'}
                  {themes.length - displayItemCount(themes).length}
                </Box>
              )}
          </Box>
          {showCausesAccordion && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '5px 0px 10px 5.3em' }}>
              {accordionCausesList.map((theme: any, i: number) => (
                <CauseAreaChip causeAreaThemes={theme} index={i} isAccordion />
              ))}
            </Box>
          )}
        </Box>
        <Box sx={{ width: '100%', marginLeft: '20px' }}>
          <Box sx={{ display: 'flex', marginTop: '20px', marginBottom: `${!showSDGAccordion && '20px'}` }}>
            {displayItemCount(sdg)?.map((ele: any, index: number) => {
              /* eslint no-underscore-dangle: 0 */
              const url = unSdgData?._baseurl;
              const sdgId = (ele?.code || '').toLowerCase().replace(/\s+/g, '-');
              const sdgIconKey = `un-${sdgId}-scalable-icon`;
              const iconName = unSdgIcons?.assets[sdgIconKey]?.name;
              const iconPath = unSdgIcons?.assets[sdgIconKey]?.path;
              const iconUrl = `${url!}${iconPath!}/${iconName!}`;
              return (
                <MoreDetails
                  causeAreaName={ele.label}
                  index={index}
                  iconUrl={iconName !== undefined ? iconUrl : ''}
                />
              );
            })}
            {sdg && displayItemCount(sdg) && sdg.length - displayItemCount(sdg).length !== 0 && (
              <Box
                sx={{
                  marginLeft: '20px',
                  color: colors.primary,
                  border: `1px solid ${colors.primary}`,
                  borderRadius: '10px',
                  // padding: '6px 12px',
                  height: '28px',
                  width: '42px',
                  fontSize: fonts.fontSize14,
                  fontWeight: fonts.fontWeight700,
                  marginTop: '-4px',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  toggleAccordion(sdg.length - displayItemCount(sdg).length, sdg, 'sdg')
                }
              >
                {!showSDGAccordion ? '+' : '-'}
                {sdg.length - displayItemCount(sdg).length}
              </Box>
            )}
          </Box>
          {showSDGAccordion && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '5px 0px 15px 4em' }}>
              {accordionSDGList.map((ele: any, index: number) => {
                const url = unSdgData?._baseurl;
                const sdgId = (ele?.code || '').toLowerCase().replace(/\s+/g, '-');
                const sdgIconKey = `un-${sdgId}-scalable-icon`;
                const iconName = unSdgIcons?.assets[sdgIconKey]?.name;
                const iconPath = unSdgIcons?.assets[sdgIconKey]?.path;
                const iconUrl = `${url!}${iconPath!}/${iconName!}`;
                return <MoreDetails causeAreaName={ele.label} index={index} isAccoridon iconUrl={iconUrl}/>;
              })}
            </Box>
          )}
        </Box>
        <Link
          to={`/program-preview/${programId}?sio_funding_amt=${fundingAmount}`}
          target="_blank"
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: fonts.fontSize14,
            fontWeight: fonts.fontWeight700,
            margin: '0px 20px 10px 0px',
            position: 'absolute',
            right: '0',
            bottom: '0',
            color: colors.primary,
            textDecoration: 'underline',
          }}
        >
          Explore Program Impact <ArrowForwardIcon width="16px" height="16px" />
        </Link>

        {/* {outputs ? (
          <FlexBox
            sx={{
              flexWrap: 'wrap',
              gap: '.5rem',
              padding: '2rem 0',
              height: '130px',
              overflow: 'auto',
            }}
          >
            {outputs.map((ele) => ele.value > -1 ? (
              <OutputComponent programOutput={ele} key={`${ele.description}-${ele.value}`} />
            ) : null
            )}
          </FlexBox>
        ) : null} */}

        {/* <FlexBox sx={{ gap: '1rem', alignItems: 'center', marginBottom: '5px' }}> */}
        {/* <Box className="checkbox__container">
            <Checkbox
              checked={selected}
              onChange={() => onSelect(program)}
              inputProps={{ 'aria-label': 'controlled' }} />{' '}
            Select
          </Box> */}

        {/* </FlexBox> */}
      </FlexBoxColumn>
    </Box>
  );
};
