import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { CollapseComponent, PaddingRoundedBox } from '../../../../shared/components';
import { useAppSelector } from '../../../../shared/hooks';
import { isNullOrWhitespace } from '../../../../shared/utils';

interface Props {
  data: Array<string>;
}

export const SupplierInviteDataCard: FC<Props> = (props) => {
  const stateInviteSupplierCSV = useAppSelector((x) => x.inviteSupplierCSV);
  const {isChecked} = { ...stateInviteSupplierCSV.initialState2 }
  const { data } = props;
  const mainData = data.slice(0, 7);

  return (
    <PaddingRoundedBox
      sx={{
        bgcolor: 'white',
        display: 'flex',
        flexDirection: 'column',
        padding: 1,
      }}
    >
      { isChecked ? mainData.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography key={`Supplier-Card-${item}-${index}`}>
          {isNullOrWhitespace(item) ? 'Value not found' : item}
        </Typography>
      )).filter((_,index) => index !== 0) : mainData.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography key={`Supplier-Card-${item}-${index}`}>
          {isNullOrWhitespace(item) ? 'Value not found' : item}
        </Typography>
      ))}
      {data.length > 7 && (
        <CollapseComponent title={`View more (${data.length - 7})`} positionTile="Bottom">
          {data.slice(7).map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Typography key={`Supplier-Card-${item}-${index}`}>{item}</Typography>
          ))}
        </CollapseComponent>
      )}
    </PaddingRoundedBox>
  );
};
