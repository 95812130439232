import { GIVEWITH_ROLES } from './orders';
import { UserRole } from '../enums';

export const getAuthTokenKey = () => {
  if (window.location.origin.includes('dev')) {
    return {
      authTokenKey: 'dev_gw_auth_token',
      userClaimsKey: 'dev_user_claims_details',
    };
  }
  if (window.location.origin.includes('qa')) {
    return {
      authTokenKey: 'qa_gw_auth_token',
      userClaimsKey: 'qa_user_claims_details',
    };
  }
  return {
    authTokenKey: 'gw_auth_token',
    userClaimsKey: 'user_claims_details',
  };
};

const authTokenKeys = getAuthTokenKey();
export const CK_AUTH_TOKEN = authTokenKeys.authTokenKey;
export const CK_REFRESH_TOKEN = 'gw_refresh_token';
export const CK_GW_TYPE = 'gw_type';
export const CK_USER_CLAIMS = authTokenKeys.userClaimsKey;

// urls
export const TERMS_OF_USE_URL = 'https://www.scalewith.com/terms-and-conditions/';
export const PRIVACY_POLICY_URL = 'https://www.scalewith.com/privacy-policy/';
export const COOKIES_POLICY_URL = 'https://www.scalewith.com/cookie-policy/';
export const SUBSCRIPTION_PRICING = 'https://www.scalewith.com/pricing';

// api
export const MAX_NUMBER_RECORDS = 2147483647;
export const DEFAULT_VALUES_REQUEST = {
  count: MAX_NUMBER_RECORDS,
  offset: 0,
  orderBy: 'name',
  descending: false,
};

export const ALL_USER_ROLES = [
  ...GIVEWITH_ROLES,
  UserRole.OrgAdmin,
  UserRole.UserAdmin,
  UserRole.Finance,
  UserRole.Sales,
  UserRole.Approver,
  UserRole.Transactor,
  UserRole.Executive,
];

// countries
export const COUNTRIESCONSTANT = {
  CONTINENT: 'North America',
  COUNTRY1: 'United States',
  COUNTRY2: 'Canada',
};

export const AUTOPAY_ENABLED = false;
export const DEFAULT_FUNDING_VALUE_PROGRAM_PREVIEW = 50000;
