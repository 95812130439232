import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCancelTokenEvent, getCauseAreas, getInstance } from '../../../api';
import { RootState } from '../../../app';
import { MAX_NUMBER_RECORDS } from '../../../shared/constants';
import { CauseArea, GridRequest, GridResponse } from '../../../shared/interfaces';

interface GridRequestCauseArea extends GridRequest {
  accountId: string;
}

interface SelectCauseState {
  causeAreas: Array<CauseArea>;
  loading: boolean;
}

const initialState: SelectCauseState = {
  causeAreas: [],
  loading: false,
};

export const getCauseAreasThunk = createAsyncThunk<
  GridResponse<CauseArea>,
  void,
  { state: RootState }
>('selectCause/getCauseAreasThunk', async (_void, { getState, signal }) => {
  addCancelTokenEvent(signal);
  const state = getState();
  const {
    app: { userData, account },
  } = state;
  const request: GridRequestCauseArea = {
    count: MAX_NUMBER_RECORDS,
    offset: 0,
    orderBy: 'name',
    descending: false,
    accountId: account?.id || '-1',
  };
  const { instance: dataInstanceState } = { ...userData };
  let dataInstance = { ...dataInstanceState };
  if (!dataInstance?.id) {
    const { data: dataInstanceResponse } = await getInstance(account?.instance?.id || '-1');
    dataInstance = { ...dataInstanceResponse };
  }
  const { data } = await getCauseAreas(request);
  if (!dataInstance?.causeAreas || data?.totalCount === 0) return data;
  const orderData = dataInstance.causeAreas?.map((itemInstance) => {
    const findCauseArea = data.results.find(
      (itemCauseArea) => itemCauseArea.id === itemInstance.causeArea?.id,
    );
    if (!findCauseArea) return data.results[0];
    return findCauseArea;
  });
  return {
    totalCount: data.totalCount || 0,
    results: orderData || [],
  };
});

const selectCauseSlice = createSlice({
  name: 'selectCause',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getCauseAreasThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<CauseArea>>) => {
          state.loading = false;
          state.causeAreas = action.payload.results;
        },
      )
      .addCase(getCauseAreasThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCauseAreasThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      });
  },
});

export const selectCauseReducer = selectCauseSlice.reducer;
