import React from 'react';
import { Chip, Divider, Typography } from '@mui/material';
import { FlexBox, UserAvatar } from '../../../shared/components';
import { Dialogs } from '../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { formatString } from '../../../shared/utils';
import { setDialog } from '../mainScreenSlice';
import { HeaderDropDownMenu } from './HeaderDropDownMenu';

export function HeaderUserAccount() {
  const userClaims = useAppSelector((state) => state.app.userClaims);
  const account = useAppSelector((state) => state.app.account);
  const dispatch = useAppDispatch();

  const accountName = (isDifferentAccount: boolean, name: string) =>
    isDifferentAccount ? (
      <Chip
        color="primary"
        label={name}
        onDelete={() => dispatch(setDialog({ open: true, type: Dialogs.RevertAccount }))}
        sx={(theme) => ({
          color: theme.palette.common.white,
          height: 26,
          '& .MuiChip-deleteIcon': { color: theme.palette.common.white },
        })}
      />
    ) : (
      <Typography
        variant="body1"
        sx={{
          color: '#8493A1',
          fontWeight: 600,
          fontSize: '12px',
          fontFamily: 'Open Sans,sans-serif !important',
        }}
      >
        {name}
      </Typography>
    );
  return (
    <HeaderDropDownMenu
      // firstName={userClaims?.first_name}
      // lastName={userClaims?.last_name}
      // email={userClaims?.email}
      instance={account?.instance}
    >
      <Divider sx={{ my: 0.25 ,borderLeft:"1px solid #D2D2D2",mx:2}} orientation="vertical" flexItem />
      <FlexBox sx={{ paddingRight: 1 }}>
        <UserAvatar firstName={userClaims?.first_name} lastName={userClaims?.last_name} />
      </FlexBox>
      <FlexBox
        sx={{
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: '#212121',
            fontWeight: 600,
            fontSize: '14px',
            fontFamily: 'Open Sans,sans-serif !important',
          }}
        >
          {formatString(userClaims?.first_name, '') + formatString(userClaims?.last_name)}
        </Typography>
        {accountName(userClaims?.accountId !== account?.id, account?.company?.name ?? '')}
      </FlexBox>
    </HeaderDropDownMenu>
  );
}
