import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { GIVEWITH_ROLES } from '../../shared/constants';
import { NoMatch } from '../../app/NoMatch';
import { AccountTypeRequired, RoleRequired } from '../../app/Security';
import { AccountType, UserRole } from '../../shared/enums';
import { AccountTransactions } from './account-transaction';
import { Accounts } from './accounts';
import InVoiceViewer from '../../shared/interfaces/transaction/invoice';

const routes = (
  <Routes>
    <Route
      path="/"
      element={
        <RoleRequired page roles={GIVEWITH_ROLES}>
          <Accounts />
        </RoleRequired>
      }
    />
    <Route
      path="/:accountId"
      element={
        <RoleRequired page roles={GIVEWITH_ROLES}>
          <Accounts />
        </RoleRequired>
      }
    />
    <Route
      path="/transactions"
      element={
        <AccountTypeRequired types={[AccountType.default, AccountType.supplier]} page>
          <RoleRequired
            page
            roles={[...GIVEWITH_ROLES, UserRole.OrgAdmin, UserRole.Finance, UserRole.Executive]}
          >
            <AccountTransactions />
          </RoleRequired>
        </AccountTypeRequired>
      }
    />
    <Route
      path="/transactions/invoice"
      element={
        <AccountTypeRequired types={[AccountType.default, AccountType.supplier]} page>
          <RoleRequired
            page
            roles={[...GIVEWITH_ROLES, UserRole.OrgAdmin, UserRole.Finance, UserRole.Executive]}
          >
            <InVoiceViewer />
          </RoleRequired>
        </AccountTypeRequired>
      }
    />
    <Route path="*" element={<NoMatch />} />
  </Routes>
);

export function AccountsRoutes() {
  return routes;
}
