import {
  Box,
  // Theme,
  Typography,
  // useTheme,
} from '@mui/material';
import {
  // Link,
  useSearchParams,
} from 'react-router-dom';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  CommonGridFilter,
  FlexBox,
  IsolatedGrid,
  LightTooltip,
  Loading,
  InvertedRoundButton,
  MoneyBalanceWithLocaleSymbol,
} from '../../../shared/components';
import {
  BETWEEN_FILTER_OPERATOR,
  CONTAINS_FILTER_OPERATOR,
  FILTER_BY_TRANSACTION_ID,
} from '../../../shared/constants';
import { GridColumns, UserRole } from '../../../shared/enums';
import { useAppDispatch, useAppSelector, useAuth, useInstance } from '../../../shared/hooks';
import { CustomGridColDef } from '../../../shared/interfaces';
import fonts from '../../../assets/scss/font.module.scss';
import colors from '../../../assets/scss/color.module.scss';
import {
  getDateString,
  getGridRequest,
  // getUsernameFromEmail,
} from '../../../shared/utils';
import {
  clearAccountTransactionsState,
  getAccountTransactionsThunk,
  resetAccountTransactionsSuccess,
  setAccountTransactionsGridModel,
  getAccountTransactionsContributionTotalsThunk,
} from './accountTransactionsSlice';
import { RoleRequired } from '../../../app';
import { getMonthReconciliation } from '../../../api';

function getAccountTransactionsColumns(
  isGivewithUser: boolean,
  // theme: Theme,
): Array<CustomGridColDef> {
  return [
    {
      field: 'id',
      headerName: 'DB ID',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: false,
    },
    {
      field: 'timestamp',
      headerName: 'Date',
      flex: 1,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      type: GridColumns.Date,
      filterable: true,
      renderCell: (params) => (
        <LightTooltip title={getDateString(params?.value) ?? ''}>
          <Box>{getDateString(params?.value, true)}</Box>
        </LightTooltip>
      ),
    },
    {
      field: 'itemType',
      headerName: 'Item Type',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      type: GridColumns.String,
      filterable: true,
    },
    {
      field: 'contributionDescription',
      headerName: 'Opportunity Name',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      type: GridColumns.String,
      filterable: true,
    },
    {
      field: 'programName',
      headerName: 'Program Name',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      type: GridColumns.String,
      filterable: true,
    },
    ...(isGivewithUser
      ? [
          {
            field: 'transactionType',
            headerName: 'Transaction Type',
            flex: 1,
            filterOperators: CONTAINS_FILTER_OPERATOR,
            type: GridColumns.String,
            filterable: true,
          },
        ]
      : []),
    // {
    //   field: 'giveId',
    //   headerName: 'ID',
    //   flex: 1,
    //   filterable: false,
    //   renderCell: ({ row: { contribution } }) =>
    //     !!contribution && (
    //       <Link
    //         style={{ color: theme.palette.primary.main }}
    //         to={`/app/impact/${contribution.id}/details`}
    //       >
    //         {contribution.name}
    //       </Link>
    //     ),
    // },
    ...(isGivewithUser
      ? [
          // {
          //   field: 'invoiceId',
          //   headerName: 'Sage Invoice #',
          //   flex: 1,
          //   filterOperators: CONTAINS_FILTER_OPERATOR,
          //   filterable: true,
          //   valueGetter: (params: { row: { sage: { invoiceId: string } } }) =>
          //     params?.row?.sage?.invoiceId,
          // },
        ]
      : []),
    {
      field: 'memo',
      headerName: 'Memo',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    // {
    //   field: 'source',
    //   headerName: 'Source',
    //   flex: 1,
    //   filterOperators: CONTAINS_FILTER_OPERATOR,
    //   filterable: true,
    // },
    // {
    //   field: 'createdBy',
    //   headerName: 'Entered By',
    //   flex: 1,
    //   filterOperators: CONTAINS_FILTER_OPERATOR,
    //   filterable: true,
    //   renderCell: (params) => (
    //     <LightTooltip title={params?.value}>
    //       <Box>{getUsernameFromEmail(params?.value)}</Box>
    //     </LightTooltip>
    //   ),
    // },
    {
      field: 'currency',
      headerName: 'Currency',
      flex: 1,
      filterOperators: CONTAINS_FILTER_OPERATOR,
      filterable: true,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      minWidth: 150,
      filterOperators: BETWEEN_FILTER_OPERATOR,
      filterable: true,
      type: GridColumns.String,
      customColumnType: GridColumns.MoneyWithLocaleSymbol,
      align: 'left',
      headerClassName: 'header-align-right',
      renderCell: (params) => (
        <MoneyBalanceWithLocaleSymbol useColor variant="body2">
          {params.value}
        </MoneyBalanceWithLocaleSymbol>
      ),
    },
    ...(!isGivewithUser
      ? [
          {
            field: 'username',
            headerName: 'Username',
            flex: 1,
            filterOperators: CONTAINS_FILTER_OPERATOR,
            type: GridColumns.String,
            filterable: true,
            valueGetter: (params: any) => params.row.metadata?.username || '',
          },
        ]
      : []),
  ];
}

export function AccountTransactions() {
  const gridState = useAppSelector((x) => x.accountTransactions.gridState);
  const success = useAppSelector((x) => x.accountTransactions.success);
  const { loadingUserData } = useInstance();
  const accountContributionTotals = useAppSelector(
    (x) => x.accountTransactions.getAccountContributionTotalsState,
  );
  const appState = useAppSelector((x) => x.app);
  const dispatch = useAppDispatch();
  const [isUserInRole] = useAuth();
  // const theme = useTheme();
  const [searchParams] = useSearchParams();
  const { grid } = gridState;
  const { sort, filter, page, pageSize } = grid;
  const { account } = { ...appState };
  const {
    completedYTD,
    completedMTD,
    approvedContributionsCount,
    approvedContributionsTotal,
    loading: accountContributionTotalsLoading,
  } = accountContributionTotals;
  const filterByTransactionId = searchParams.get(FILTER_BY_TRANSACTION_ID);

  const getTransactionGridRequest = () => ({ ...getGridRequest(grid), accountId: account?.id!, orderBy: 'timestamp' });

  useEffect(() => {
    if (!filterByTransactionId) return;

    dispatch(
      setAccountTransactionsGridModel({
        filter: { field: 'id', simpleValue: filterByTransactionId },
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByTransactionId]);

  useEffect(() => {
    dispatch(getAccountTransactionsThunk({ request: getTransactionGridRequest(), pageNumber: page }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, page, pageSize]);

  useEffect(() => {
    if (success) {
      dispatch(getAccountTransactionsThunk({ request: getTransactionGridRequest(), pageNumber: page }));
      dispatch(resetAccountTransactionsSuccess());
      dispatch(getAccountTransactionsContributionTotalsThunk(account?.id!));
      toast.success('Transaction added');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, account?.id]);

  useEffect(() => {
    dispatch(getAccountTransactionsContributionTotalsThunk(account?.id!));
  }, [dispatch, account?.id]);

  useEffect(
    () => () => {
      dispatch(clearAccountTransactionsState());
      searchParams.delete(FILTER_BY_TRANSACTION_ID);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const handleDownload = () => {
    getMonthReconciliation();
  };

  if (accountContributionTotalsLoading || loadingUserData) return <Loading loading />;

  const columns = getAccountTransactionsColumns(isUserInRole([UserRole.SuperAdmin]));

  return (
    <Box className="dashboard" sx={{ pb: '30px' }}>
      <FlexBox sx={{ gap: 1 }}>
        <Typography flex={1} sx={{color:colors.textPrimary,fontSize: fonts.fontSize24, fontWeight: 700 }}>
          Transactions
        </Typography>
        <RoleRequired roles={[UserRole.SuperAdmin, UserRole.GivewithFinance]}>
          <InvertedRoundButton sx={{
            height: '48px',
            marginLeft: '30px',
            paddingX: '16px',
            fontWeight: fonts.fontWeight700,
            fontSize: fonts.fontSize14,
            backgroundColor: `${colors.primary} !important`,
            color: `${colors.secondary} !important`,
            borderRadius: '8px !important',
            '&.Mui-disabled': {
              backgroundColor: `${colors.strokeDefault} !important`,
            },
          }} onClick={handleDownload}>
            Ledger
          </InvertedRoundButton>
        </RoleRequired>
      </FlexBox>

      <FlexBox sx={{ gap: 2, marginTop: 2, marginBottom: 2 }}>
        <FlexBox sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ flex: 1, color:colors.textPrimary,fontSize: fonts.fontSize16, fontWeight: 700,  textTransform: 'capitalize' }}
           >approved total</Typography>
          <MoneyBalanceWithLocaleSymbol sx={{ flex: 1, color:colors.textPrimary,fontSize: fonts.fontSize20, fontWeight: 700 }}
          useColor>
            {approvedContributionsTotal}
          </MoneyBalanceWithLocaleSymbol>
        </FlexBox>
        <FlexBox sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Typography sx={{ flex: 1, color:colors.textPrimary,fontSize: fonts.fontSize16, fontWeight: 700,  textTransform: 'capitalize' }}
           >approved count</Typography>
          <Typography sx={{ flex: 1, color:colors.textPrimary,fontSize: fonts.fontSize20, fontWeight: 700 }}>{approvedContributionsCount}</Typography>
        </FlexBox>
        <FlexBox sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Typography sx={{ flex: 1, color:colors.textPrimary,fontSize: fonts.fontSize16, fontWeight: 700,  textTransform: 'capitalize' }}
           >completed MTD</Typography>
         <MoneyBalanceWithLocaleSymbol sx={{ flex: 1, color:colors.textPrimary,fontSize: fonts.fontSize20, fontWeight: 700 }}
          useColor>
            {completedMTD}
          </MoneyBalanceWithLocaleSymbol>
        </FlexBox>
        <FlexBox sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Typography sx={{ flex: 1, color:colors.textPrimary,fontSize: fonts.fontSize16, fontWeight: 700,  textTransform: 'capitalize' }}
           >completed YTD</Typography>
         <MoneyBalanceWithLocaleSymbol sx={{ flex: 1, color:colors.textPrimary,fontSize: fonts.fontSize20, fontWeight: 700 }}
          useColor>
            {completedYTD}
          </MoneyBalanceWithLocaleSymbol>
        </FlexBox>
        <FlexBox sx={{ flex: 1, flexDirection: 'row-reverse' }}>
          <CommonGridFilter
            columns={columns}
            currentFilter={filter}
            onChange={(x) => {
              dispatch(
                setAccountTransactionsGridModel({
                  filter: x,
                }),
              );
            }}
          />
        </FlexBox>
      </FlexBox>
      <Box className="dashboard" sx={{ pb: '30px' }}>
        <IsolatedGrid
          model={grid}
          columns={columns}
          extraLoading={loadingUserData}
          onChange={(x) => {
            dispatch(setAccountTransactionsGridModel(x));
          }}
          gridProps={{
            disableColumnMenu: false,
          }}
          showIcon
          isGiveWithUser={isUserInRole([UserRole.SuperAdmin])}
        />
      </Box>
    </Box>
  );
}
