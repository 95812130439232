import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  CommonContributionsGrid,
  deleteContributionUnshareThunk,
  FlexBox,
  // InvertedRoundButton,
  RoundButton,
  UpdateContributionStatusButton,
} from '../../shared/components';
// import { RoleRequired } from '../../app/Security';
import {
  AccountType,
  ConfirmDialogType,
  GridColumns,
  ContributionActions,
  ContributionStatus,
  ContributionStatusLabel,
  UserRole,
} from '../../shared/enums';
// import { NEW_CONTRIBUTION_PERMISSIONS } from '../../shared/constants';
import { useAccount, useAppDispatch, useAppSelector, useAuth } from '../../shared/hooks';
import {
  isAvailableShareByStatus,
  isNullOrWhitespace,
  openConfirmDialog,
  updateMultipleGives,
} from '../../shared/utils';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';
import borders from '../../assets/scss/strokes.module.scss';


export function Dashboard() {
  const selectedStatus = useAppSelector((x) => x.commonContributions.status);
  const stateConfirmDialog = useAppSelector((x) => x.confirmDialog);
  const success = useAppSelector((x) => x.updateContributionStatus.success);
  const dispatch = useAppDispatch();
  const { value, confirmed, type } = stateConfirmDialog;

  const gridState = useAppSelector((x) => x.commonContributions.grid);
  const navigate = useNavigate();
  const { isAccountInType } = useAccount();
  const [isUserInRole] = useAuth();

  useEffect(() => {
    if (!confirmed || type !== ConfirmDialogType.UnshareContribution || isNullOrWhitespace(value))
      return;
    dispatch(deleteContributionUnshareThunk(value!));
  }, [value, confirmed, type, dispatch]);

  const isAvailableShareByRole =
    isAccountInType([AccountType.supplier]) &&
    isUserInRole([UserRole.Finance, UserRole.SuperAdmin]);

  const tabs = [
    { label: 'All', value: null },
    { label: 'Drafts', value: ContributionStatus.Draft },
    { label: 'Pending Approval', value: ContributionStatus.PendingApproval },
    { label: 'Approved', value: ContributionStatus.Approved },
    { label: 'Completed', value: ContributionStatus.Completed },
    ...(isAvailableShareByRole ? [{ label: 'Shared', value: ContributionActions.shared }] : []),
  ];

  const isTabSharedOrNull =
    selectedStatus === ContributionActions.shared || selectedStatus === null;

  const minWidth =
    isAvailableShareByRole &&
    (isAvailableShareByStatus(selectedStatus as ContributionStatus) || isTabSharedOrNull)
      ? 240
      : 190;

  const unshareContribution = (id: string) => {
    openConfirmDialog(
      {
        title: 'Unshare Social Impact Contribution',
        text: 'Are you sure you would like to unshare this Social Impact Contribution?',
        type: ConfirmDialogType.UnshareContribution,
        confirmText: 'Unshare',
        value: id,
      },
      'sm',
    );
  };

  const actionsColumn: GridColDef = {
    field: 'action',
    headerName: 'Actions',
    disableReorder: true,
    resizable: false,
    type: GridColumns.Actions,
    minWidth,
    renderCell: ({ row: { id, status, sharedWith } }) => (
      <FlexBox sx={{ gap: 1 }}>
        <Button
          variant="outlined"
          // color="success"
          // sx={{ backgroundColor: '#fff', fontSize: fonts.fontSize14, fontWeight: 400 }}
          sx={{
            backgroundColor: colors.secondary,
            fontSize: fonts.fontSize14,
            fontWeight: fonts.fontWeight700,
            color: colors.primary,
            borderRadius: borders.borderRadius8,
            borderColor: colors.primary,
            '&:focus': colors.primary,
            '&:hover': colors.primary,
          }}
          endIcon={<ArrowForwardIcon />}
          onClick={() => {
            if (status !== ContributionStatus.Draft) {
              navigate(`/app/contribution/${id}/details`);
            } else {
              navigate(`/app/contribution/${id}/draft`);
            }
          }}
          disabled={false}
        >
          View Details
        </Button>
        {isAvailableShareByStatus(status as ContributionStatus) &&
          isAvailableShareByRole &&
          (!sharedWith?.id ? (
            <UpdateContributionStatusButton
              proposedStatus={ContributionActions.shared}
              action={ContributionStatusLabel.share}
              id={id}
            />
          ) : (
            <Button
              variant="outlined"
              color="error"
              sx={{ backgroundColor: '#fff' }}
              onClick={() => unshareContribution(id)}
            >
              Unshare
            </Button>
          ))}
      </FlexBox>
    ),
    sortable: false,
    filterable: false,
    hideable: false,
    align: 'left',
    headerAlign: 'left',
  };

  return (
    <Box className="dashboard" sx={{ pb: '30px' }}>
      <FlexBox sx={{ gap: 1 }}>
      <Typography flex={1} sx={{ flex: 1, color:colors.textPrimary, fontSize: fonts.fontSize24, fontWeight: 700 }}>
          Dashboard
        </Typography>
        {isAvailableShareByStatus(selectedStatus as ContributionStatus) && isAvailableShareByRole && (
          <RoundButton
            disabled={gridState?.selectionModel?.length === 0}
            onClick={() => updateMultipleGives(gridState, ContributionActions.shared)}
          >
            Share Contribution
          </RoundButton>
        )}

        {/* <RoleRequired roles={NEW_CONTRIBUTION_PERMISSIONS}>
          <InvertedRoundButton sx={{ color: '#000' }} href="/app/contribution/new">
            New Contribution
          </InvertedRoundButton>
        </RoleRequired> */}
      </FlexBox>

      <CommonContributionsGrid
        success={success}
        tabs={tabs}
        defaultTab={null}
        actionsColumn={actionsColumn}
        alternativeName="dashboard"
      />
    </Box>
  );
}
