import { Box, LinearProgress, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { refreshAppAccountThunk } from '../../app';
import { FlexBox, ThankYouTypography } from '../../shared/components';
import { CHECKOUT_SESSION_ID_QUERY_NAME } from '../../shared/constants';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { verifyTokenExpiration } from '../../shared/utils';
import { postRegisterUserSubscriptionThunk } from './registerDetailsSlice';

export const RegisterStripeSuccess = () => {
  const success = useAppSelector((x) => x.registerDetails.success);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const instance = useAppSelector((x) => x.app.associatedInstance);
  const checkoutSessionId = searchParams.get(CHECKOUT_SESSION_ID_QUERY_NAME);

  useEffect(() => {
    if (!success) return;

    dispatch(refreshAppAccountThunk()).then(() => {
      verifyTokenExpiration(true);
      navigate('/app');
    });
  }, [dispatch, navigate, success]);

  useEffect(() => {
    if (!checkoutSessionId || !instance) return;
    dispatch(
      postRegisterUserSubscriptionThunk({
        checkoutSessionId,
      }),
    );
  }, [checkoutSessionId, instance, dispatch]);

  return (
    <FlexBox
      sx={{
        marginTop: 4,
        flexDirection: 'column',
        flex: 1,
        color: 'black',
        gap: 2,
      }}
    >
      <ThankYouTypography variant="h3" sx={{ marginBottom: 4 }}>
        Thank you!
      </ThankYouTypography>
      <Typography>
        We are activating your account to help you leverage social impact & sustainability to win
        more deals.
      </Typography>
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    </FlexBox>
  );
};
