import React, { FC } from 'react';
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { FlexBox } from '../../../../shared/components';
import { FilterPopper } from './FilterPopper';
import { CauseAreaMetricsFilterPopper } from '../../../../shared/interfaces/cause-area/causeAreaFilterPopper';
import { CauseArea, CauseAreaMetricsRequest, GridModel } from '../../../../shared/interfaces';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import {
  setCauseAreaMetricsIsAsc,
  setCauseAreaMetricsData,
  setCauseAreaMetricRequestValues,
} from '../../CauseAreaMetricsSlice';
import { FilterGiveStages } from './FilterGiveStages';

interface Props {
  optionFilter: CauseAreaMetricsFilterPopper;
  causeAreas: GridModel<CauseArea>;
}

export const MetricsFilter: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { optionFilter, causeAreas } = props;
  const stateCauseAreaMetrics = useAppSelector((x) => x.causeAreasMetrics);
  const { isAsc, requestValues, sortBy, responseValues, currency } = stateCauseAreaMetrics;

  const getValuesFromFilter = (values: CauseAreaMetricsRequest) => {
    if (requestValues.causeAreas) {
      dispatch(
        setCauseAreaMetricRequestValues({ causeAreas: [...requestValues.causeAreas], ...values }),
      );
      return;
    }
    dispatch(setCauseAreaMetricRequestValues({ ...values }));
  };

  return (
    <Box
      my={1}
      display="flex"
      justifyContent="flex-end"
      sx={{ flexDirection: 'row', gap: 2, alignItems: 'end' }}
    >
      <Autocomplete
        multiple
        limitTags={1}
        id="multiple-limit-tags"
        disableCloseOnSelect
        onChange={(event: any, newValue) => {
          const justId = newValue.map((item) => item.id);
          if (justId.length === 0) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { causeAreas: causeAreasRequest, ...payload } = { ...requestValues };
            dispatch(setCauseAreaMetricRequestValues({ ...payload }));
            return;
          }
          dispatch(setCauseAreaMetricRequestValues({ ...requestValues, causeAreas: [...justId] }));
        }}
        options={causeAreas.data.results ?? []}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} placeholder="Cause Areas" />}
        sx={{ flex: 2 }}
      />
      <FilterGiveStages />

      <FlexBox sx={{ flex: 1, gap: 0.5, alignItems: 'end' }}>
        <FormControl sx={{ flex: 1 }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            labelId="filter-select-label"
            id="filter-select"
            variant="standard"
            onChange={(e) => dispatch(setCauseAreaMetricsData({ sortBy: e.target.value }))}
            value={sortBy}
          >
            <MenuItem value="proposed">Proposed</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="approved">Approved</MenuItem>
            <MenuItem value="financialHold">Financial Hold</MenuItem>
            <MenuItem value="avgGiveAmount">AVG. Social Impact Contribution</MenuItem>
            <MenuItem value="winRate">Win Rate</MenuItem>
            <MenuItem value="avgGivePercent">AVG. Percent Of Deal</MenuItem>
            <MenuItem value="impact">Impact</MenuItem>
          </Select>
        </FormControl>
        <Box onClick={() => dispatch(setCauseAreaMetricsIsAsc(!isAsc))}>
          <ArrowDownward
            sx={(theme) => ({
              transform: !isAsc ? 'rotate(0deg)' : 'rotate(180deg)',
              transitionDuration: theme.transitions.duration.shorter,
              cursor: 'pointer',
            })}
          />
        </Box>
      </FlexBox>
      {responseValues.details.currencies.length >= 2 && (
        <Box>
          <FormControl sx={{ flex: 1 }}>
            <Select
              labelId="filter-select-label"
              id="filter-select"
              variant="standard"
              onChange={(e) => {
                const findCurrencyObject = responseValues.details.currencies.find(
                  (item) => item.currency === e.target.value,
                );
                if (findCurrencyObject === undefined) return;
                dispatch(setCauseAreaMetricsData({ currency: findCurrencyObject }));
              }}
              value={currency.currency}
            >
              {responseValues.details.currencies.map((item, index) => (
                <MenuItem
                  value={item.currency}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`select-currency-cause-area-metrics=${item.currency}${index}`}
                >{`${item.symbol}${item.currency}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <Box sx={{ flex: 0.5 }}>
        <FilterPopper applyFilter={getValuesFromFilter} values={optionFilter} />
      </Box>
    </Box>
  );
};
