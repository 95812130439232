import React, { useState, ChangeEvent } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../shared/hooks';
import { FormValues } from '../../shared/interfaces/sio/sio';
import { updateSioUserThunk } from './sioSlice';
import USAFlag from '../../assets/svg/united-states.svg';
import UKFlag from '../../assets/svg/united-kingdom.svg';
import './Sio.scss';
import { CellPhoneInput } from '../../shared/components/CellphoneInput';
import fonts from '../../assets/scss/font.module.scss';
import colors from '../../assets/scss/color.module.scss';

interface EditingState {
  question1: boolean;
  question2: boolean;
  question3: boolean;
  question4: boolean;
  question5: boolean;
}

const GeneralInfoDetails = ({ SioData }: { SioData: FormValues }) => {
  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState(SioData);
  const [termsAccepted, setTermsAccepted] = useState(formValues.committed);
  const handleTermsAcceptedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement.checked;
    setTermsAccepted(isChecked);
    if (!isChecked) {
      setFormValues((prevFormValues) => {
        const updatedFormValues = {
          ...prevFormValues,
          committed: false,
        };
        return updatedFormValues;
      });
    } else {
      setFormValues((prevFormValues) => {
        const updatedFormValues = {
          ...prevFormValues,
          committed: true,
        };
        return updatedFormValues;
      });
    }
  };
  const { id, password } = useParams();
  const [isEditing, setIsEditing] = useState<EditingState>({
    question1: false,
    question2: false,
    question3: false,
    question4: false,
    question5: false,
  });

  const handleEditClick = (questionNumber: keyof EditingState) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [questionNumber]: !prevState[questionNumber],
    }));
  };

  const handleSaveClick = async (questionNumber: keyof EditingState) => {
    const payload = {
      general: {
        name: {
          legalOrganizationName: formValues.legalName,
          publicOrganizationName: formValues.publicName,
        },
        social: {
          facebook: formValues.facebook,
          instagram: formValues.instagram,
          linkedIn: formValues.linkedIn,
          twitter: formValues.twitter,
          websiteURL: formValues.websiteUrl,
        },
        contact: {
          professionalTitle: formValues.professionalTitle,
          name: formValues.firstLastName,
          phone: formValues.professionalPhoneNo,
          email: formValues.email,
        },
        location: {
          generalLocation: formValues.registeredLocation,
          specificLocation: formValues.registeredLocation,
        },
        missionAgreement: formValues.committed,
      },
      overviewAndMission: {
        causeAreas: {
          selected: formValues.causeAreas.selected,
        },
        lifetimeOutputs: formValues.lifetimeOutputs,
        researchAndEvaluationFile: {
          name: formValues.fileUpload,
        },
        historyDescription: formValues.missionHistory,
        problemDescription: formValues.problem,
        initiativesDescription: formValues.programInitiativesPriorites,
        programLocations: formValues.programOperate,
        researchAndEvaluation: formValues.researchEvaluation,
      },
      operationalInformation: {
        staff: {
          fullTime: formValues.fullTimeStaff,
          partTime: formValues.partTimeStaff,
          volunteers: formValues.volunteers,
        },
        financialStatement: {
          file: {
            name: formValues.financialStatement,
          },
        },
        partnershipsDescription: formValues.formalInformalRelationship,
        yearlyBudget: formValues.budget,
        supportersAndPartners: formValues.supportersPartners,
      },
    };
    if (id && password) {
      const response = await dispatch(updateSioUserThunk({ sio: payload, id }));
      if (response && response.payload) {
        toast.success('Updated Successfully');
      } else {
        toast.error('Failed to Updated, Please try again');
      }
    }
    setIsEditing((prevState) => ({
      ...prevState,
      [questionNumber]: false,
    }));
    setFormValues(formValues);
  };

  const handleCancelClick = (questionNumber: keyof EditingState) => {
    setIsEditing((prevState) => ({
      ...prevState,
      [questionNumber]: false,
    }));
    setFormValues(SioData);
  };

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'professionalPhoneNo') {
      setFormValues({ ...formValues, [name]: value.replace(/,/g, '') });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      marginLeft: '0.8em',
      color: colors.textPrimary,
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      marginLeft: '1em',
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
    questionStyle: {
      fontSize: fonts.fontSize14,
      fontWeight: fonts.fontWeight400,
      marginLeft: '1em',
    },
    cancelSaveBtn: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
      marginTop: '1em',
    },
    sup: {
      color: colors.statusAlert,
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight500,
      marginLeft: '3px',
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
  };
  return (
    <Grid
      container
      direction="column"
      spacing={2}
      sx={{ display: 'flex', gap: '1.5rem', marginTop: '2rem', padding: '1rem' }}
      className="formContainer"
    >
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 1 of 5</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              What is the Name of your Organization?
            </Typography>
            <Button
              variant="outlined"
              onClick={() => handleEditClick('question1')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Legal Name <sup style={commonTextStyle.sup}>*</sup>
              <Tooltip title="Enter The Legal Name">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              disabled={!isEditing.question1}
              name="legalName"
              value={formValues.legalName}
              variant="outlined"
              fullWidth
              onChange={handleInputChange}
              sx={commonTextStyle.textFieldStyle}
            />
          </Grid>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Public Name <sup style={commonTextStyle.sup}>*</sup>
              <Tooltip title="Enter The Public Name">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              disabled={!isEditing.question1}
              name="publicName"
              onChange={handleInputChange}
              value={formValues.publicName}
              variant="outlined"
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          {isEditing.question1 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question1')}
                sx={commonTextStyle.cancelSaveBtn}
                style={{ marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question1')}
                disabled={formValues.legalName === '' || formValues.publicName === ''}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 2 of 5</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Please Provide Your Website Url and Social Media Handles
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question2')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Website URL <sup style={commonTextStyle.sup}>*</sup>{' '}
              <Tooltip title="Enter The Website URL">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              disabled={!isEditing.question2}
              name="websiteUrl"
              value={formValues.websiteUrl}
              variant="outlined"
              onChange={handleInputChange}
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          <Grid item className="gridItem">
            <Typography sx={{ ...commonTextStyle.labelStyle, marginTop: '7px' }}>
              Facebook{' '}
              <Tooltip title="Enter The Facebook">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              disabled={!isEditing.question2}
              name="facebook"
              value={formValues.facebook}
              variant="outlined"
              onChange={handleInputChange}
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{ marginTop: '4px', marginLeft: '.1rem' }}
          >
            <Grid item className="gridItem">
              <Typography sx={commonTextStyle.labelStyle}>
                Instagram{' '}
                <Tooltip title="Enter The Instagram">
                  <InfoIcon className="tooltipIcon" />
                </Tooltip>
              </Typography>
              <TextField
                disabled={!isEditing.question2}
                name="instagram"
                value={formValues.instagram}
                variant="outlined"
                onChange={handleInputChange}
                sx={commonTextStyle.textFieldStyle}
                fullWidth
              />
            </Grid>
            <Grid item className="gridItem">
              <Typography sx={commonTextStyle.labelStyle}>
                Twitter{' '}
                <Tooltip title="Enter The Twitter">
                  <InfoIcon className="tooltipIcon" />
                </Tooltip>
              </Typography>
              <TextField
                disabled={!isEditing.question2}
                name="twitter"
                value={formValues.twitter}
                variant="outlined"
                onChange={handleInputChange}
                sx={commonTextStyle.textFieldStyle}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{ marginTop: '4px', marginLeft: '.1rem' }}
          >
            <Grid item className="gridItem">
              <Typography sx={commonTextStyle.labelStyle}>
                LinkedIn{' '}
                <Tooltip title="Enter The linkedIn">
                  <InfoIcon className="tooltipIcon" />
                </Tooltip>
              </Typography>
              <TextField
                disabled={!isEditing.question2}
                name="linkedIn"
                value={formValues.linkedIn}
                variant="outlined"
                onChange={handleInputChange}
                sx={commonTextStyle.textFieldStyle}
                fullWidth
              />
            </Grid>
            {isEditing.question2 && (
              <Grid container justifyContent="flex-end">
                <Button
                  variant="outlined"
                  onClick={() => handleCancelClick('question2')}
                  sx={commonTextStyle.cancelSaveBtn}
                  style={{ marginRight: '1em' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleSaveClick('question2')}
                  disabled={formValues.websiteUrl === ''}
                  sx={commonTextStyle.cancelSaveBtn}
                >
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 3 of 5</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Who will be the main contact person for your organization?
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question3')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              First and Last Name <sup style={commonTextStyle.sup}>*</sup>{' '}
              <Tooltip title="Enter The First and Last Name">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              disabled={!isEditing.question3}
              name="firstLastName"
              value={formValues.firstLastName}
              variant="outlined"
              onChange={handleInputChange}
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          <Grid item className="gridItem">
            <Typography sx={commonTextStyle.labelStyle}>
              Professional Title <sup style={commonTextStyle.sup}>*</sup>{' '}
              <Tooltip title="Enter The Professional Title">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <TextField
              disabled={!isEditing.question3}
              name="professionalTitle"
              value={formValues.professionalTitle}
              variant="outlined"
              onChange={handleInputChange}
              sx={commonTextStyle.textFieldStyle}
              fullWidth
            />
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{ marginTop: '4px', marginLeft: '.1rem' }}
          >
            <Grid item className="gridItem">
              <Typography sx={commonTextStyle.labelStyle}>
                Email Address <sup style={commonTextStyle.sup}>*</sup>{' '}
                <Tooltip title="Enter The Email Address">
                  <InfoIcon className="tooltipIcon" />
                </Tooltip>
              </Typography>
              <TextField
                disabled={!isEditing.question3}
                name="email"
                value={formValues.email}
                error={!formValues.email.every(validateEmail) && formValues.email.length > 0}
                variant="outlined"
                onChange={handleInputChange}
                sx={commonTextStyle.textFieldStyle}
                fullWidth
              />
            </Grid>
            <Grid item className="gridItem">
              <Typography sx={commonTextStyle.labelStyle}>
                Phone Number{' '}
                <sup style={{ color: '#f64747', fontSize: '20px', marginLeft: '3px' }}>*</sup>{' '}
                <Tooltip title="Enter The Phone Number">
                  <InfoIcon className="tooltipIcon" />
                </Tooltip>
              </Typography>
              <TextField
                disabled={!isEditing.question3}
                name="professionalPhoneNo"
                value={formValues.professionalPhoneNo}
                error={
                  formValues.professionalPhoneNo !== undefined &&
                  (formValues.professionalPhoneNo[0] === '0' ||
                    formValues.professionalPhoneNo[0] === '1' ||
                    formValues.professionalPhoneNo.length < 10)
                }
                InputProps={{ inputComponent: CellPhoneInput as any }}
                variant="outlined"
                onChange={handleInputChange}
                sx={commonTextStyle.textFieldStyle}
                fullWidth
              />
            </Grid>
            {isEditing.question3 && (
              <Grid container justifyContent="flex-end">
                <Button
                  variant="outlined"
                  onClick={() => handleCancelClick('question3')}
                  sx={commonTextStyle.cancelSaveBtn}
                  style={{ marginRight: '1em' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleSaveClick('question3')}
                  disabled={
                    formValues.firstLastName === '' ||
                    formValues.professionalTitle === '' ||
                    formValues.email.length === 0 ||
                    formValues.professionalPhoneNo === '' ||
                    formValues.professionalPhoneNo[0] === '0' ||
                    formValues.professionalPhoneNo[0] === '1' ||
                    formValues.professionalPhoneNo.length < 10 ||
                    !formValues.email.every(validateEmail)
                  }
                  sx={commonTextStyle.cancelSaveBtn}
                >
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 4 of 5</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              Where is your Organization Incorporated?
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question4')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item>
            <Typography sx={commonTextStyle.labelStyle}>
              Registration Location <sup style={commonTextStyle.sup}>*</sup>{' '}
              <Tooltip title="Enter The Registration Location ">
                <InfoIcon className="tooltipIcon" />
              </Tooltip>
            </Typography>
            <Grid item xs={12}>
              <Checkbox
                name="registeredLocation"
                onChange={handleInputChange}
                checked={formValues.registeredLocation === 'USA'}
                disabled={!isEditing.question4}
                value="USA"
                icon={
                  <Box
                    sx={{
                      border: '1px solid #033156',
                      borderRadius: '15px',
                      width: '238px',
                      height: '200px',
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} textAlign="center">
                        <img src={USAFlag} alt="USA" />
                        <Typography variant="body1">USA</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                }
                checkedIcon={
                  <Box
                    sx={{
                      bgcolor: '#E7F2FF',
                      border: '1px solid #033156',
                      borderRadius: '15px',
                      width: '238px',
                      height: '200px',
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} textAlign="center">
                        <img src={USAFlag} alt="USA" />
                        <Typography variant="body1">USA</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                }
              />
              <Checkbox
                checked={formValues.registeredLocation === 'UK'}
                disabled={!isEditing.question4}
                value="UK"
                name="registeredLocation"
                onChange={handleInputChange}
                icon={
                  <Box
                    sx={{
                      border: '1px solid #033156',
                      borderRadius: '15px',
                      width: '238px',
                      height: '200px',
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} textAlign="center">
                        <img src={UKFlag} alt="UK" />
                        <Typography variant="body1">UK</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                }
                checkedIcon={
                  <Box
                    sx={{
                      bgcolor: '#E7F2FF',
                      border: '1px solid #033156',
                      borderRadius: '15px',
                      width: '238px',
                      height: '200px',
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} textAlign="center">
                        <img src={UKFlag} alt="UK" />
                        <Typography variant="body1">UK</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                }
              />
              <Checkbox
                onChange={handleInputChange}
                checked={formValues.registeredLocation === 'Other'}
                disabled={!isEditing.question4}
                value="Other"
                name="registeredLocation"
                icon={
                  <Box
                    sx={{
                      textAlign: 'center',
                      border: '1px solid #033156',
                      borderRadius: '15px',
                      width: '238px',
                      height: '200px',
                    }}
                  >
                    <Typography sx={{ lineHeight: '200px' }} variant="body1">
                      Other
                    </Typography>
                  </Box>
                }
                checkedIcon={
                  <Box
                    sx={{
                      textAlign: 'center',
                      bgcolor: '#E7F2FF',
                      border: '1px solid #033156',
                      borderRadius: '15px',
                      width: '238px',
                      height: '200px',
                    }}
                  >
                    <Typography sx={{ lineHeight: '200px' }} variant="body1">
                      Other
                    </Typography>
                  </Box>
                }
              />
            </Grid>
          </Grid>
          {isEditing.question4 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question4')}
                sx={commonTextStyle.cancelSaveBtn}
                style={{ marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question4')}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className="DetailsContainer">
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Typography sx={commonTextStyle.questionStyle}>Question 5 of 5</Typography>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={commonTextStyle.heading}>
              ScaleWith Is Committed To Working With Organizations That Foster Inclusivity?
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEditClick('question5')}
              sx={commonTextStyle.buttonStyle}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '4px' }}>
          <Grid item className="gridItem">
            {isEditing.question5 ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAccepted}
                    name="committed"
                    onChange={handleTermsAcceptedChange}
                  />
                }
                label={
                  <span style={{ fontSize: fonts.fontSize14, fontWeight: fonts.fontWeight400 }}>
                    Yes our organization is aligned with this mission
                  </span>
                }
              />
            ) : (
              <TextField
                disabled={!isEditing.question5}
                name="committed"
                variant="outlined"
                value={
                  formValues.committed ? 'Yes our organization is aligned with this mission' : ''
                }
                sx={commonTextStyle.textFieldStyle}
                fullWidth
              />
            )}
          </Grid>
          {isEditing.question5 && (
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleCancelClick('question5')}
                sx={commonTextStyle.cancelSaveBtn}
                style={{ marginRight: '1em' }}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSaveClick('question5')}
                sx={commonTextStyle.cancelSaveBtn}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralInfoDetails;
