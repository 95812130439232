import _ from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addCancelTokenEvent, getAccounts } from '../../../../../api';
import { DEFAULT_PAGE_SIZE } from '../../../../../shared/constants';
import { Account, GridResponse, GridModel, GridRequest } from '../../../../../shared/interfaces';

interface SwitchAccountState {
  grid: GridModel<Account>;
  success: boolean;
  selectedAccountId?: string;
}

const initialState: SwitchAccountState = {
  grid: {
    name: 'switchAccount',
    data: { totalCount: 0, results: [] },
    page: 0,
    defaultSort: { field: 'name', direction: 'asc' },
    pageSize: DEFAULT_PAGE_SIZE,
    loadingGrid: false,
  },
  success: false,
  selectedAccountId: undefined,
};

export const getSwitchAccountsThunk = createAsyncThunk(
  'switchAccount/getSwitchAccountsThunk',
  async (request: GridRequest, { signal }) => {
    addCancelTokenEvent(signal);
    const { data } = await getAccounts(request);
    return data;
  },
);

const accountsSlice = createSlice({
  name: 'switchAccount',
  initialState,
  reducers: {
    setSwitchAccountGridModel: (state, action: PayloadAction<Partial<GridModel<Account>>>) => {
      _.assign(state.grid, action.payload);
    },
    clearSwitchAccountState: (state) => {
      _.assign(state, initialState);
    },
    setSelectedAccountId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedAccountId = action.payload;
    },
    setSwitchAccountSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getSwitchAccountsThunk.fulfilled,
        (state, action: PayloadAction<GridResponse<Account>>) => {
          state.grid.loadingGrid = false;
          state.grid.data = action.payload;
        },
      )
      .addCase(getSwitchAccountsThunk.rejected, (state) => {
        state.grid.loadingGrid = false;
      })
      .addCase(getSwitchAccountsThunk.pending, (state) => {
        if (!state.grid.loadingGrid) state.grid.loadingGrid = true;
      });
  },
});

export const {
  setSwitchAccountGridModel,
  clearSwitchAccountState,
  setSwitchAccountSuccess,
  setSelectedAccountId,
} = accountsSlice.actions;

export const switchAccountReducer = accountsSlice.reducer;
