import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import React from 'react';
import { FlexBox, CellPhoneInput, CommonRolesForm, RecordDetails } from '../../shared/components';
import { ENABLE_USER_SAVE } from '../../shared/constants';
import { useAppSelector, useAppDispatch, useAuth } from '../../shared/hooks';
import {
  setEditUserFirstName,
  setEditUserLastName,
  setEditUserTitle,
  setEditUserDepartmentName,
  setEditUserPhoneNumber,
  setEditUserActive,
  setSelectedRoles,
} from './editUserSlice';

export function EditUserForm() {
  const editedUser = useAppSelector((x) => x.editUser.editedUser);
  const dispatch = useAppDispatch();
  const [isUserInRole] = useAuth();
  const { firstName, lastName, title, departmentName, phoneNumber, username, active, roles } =
    editedUser;

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
      <FlexBox sx={{ gap: 6 }}>
        <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
          <FlexBox sx={{ gap: 4 }}>
            <TextField
              label="First Name"
              fullWidth
              value={firstName}
              onChange={(e) => {
                dispatch(setEditUserFirstName(e.target.value));
              }}
              required
            />
            <TextField
              label="Last Name"
              fullWidth
              value={lastName}
              onChange={(e) => {
                dispatch(setEditUserLastName(e.target.value));
              }}
              required
            />
          </FlexBox>
          <FlexBox sx={{ gap: 4 }}>
            <TextField
              label="Department Name"
              fullWidth
              value={departmentName}
              onChange={(e) => {
                dispatch(setEditUserDepartmentName(e.target.value));
              }}
            />
            <TextField
              id="edit-user-title"
              label="Title"
              fullWidth
              value={title}
              onChange={(e) => {
                dispatch(setEditUserTitle(e.target.value));
              }}
            />
          </FlexBox>
          <FlexBox sx={{ gap: 4 }}>
            <TextField label="Email Address" fullWidth value={username} disabled />
            <TextField
              label="Phone Number"
              name="phoneNumber"
              fullWidth
              value={phoneNumber}
              error={
                phoneNumber !== undefined && phoneNumber!.length > 0 && phoneNumber!.length < 10
              }
              onChange={(e) => {
                dispatch(setEditUserPhoneNumber(e.target.value));
              }}
              InputProps={{
                inputComponent: CellPhoneInput as any,
              }}
            />
          </FlexBox>
          <FlexBox sx={{ gap: 4 }}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!isUserInRole(ENABLE_USER_SAVE)}
                  checked={active}
                  onChange={(e) => {
                    dispatch(setEditUserActive(e.target.checked));
                  }}
                />
              }
              label="Active"
            />
          </FlexBox>
        </FlexBox>

        <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
          <RecordDetails value={editedUser} />
        </FlexBox>
      </FlexBox>
      <CommonRolesForm
        roles={roles}
        onRoleChange={(role, isSelected) => {
          dispatch(setSelectedRoles({ role, isSelected }));
        }}
        showAlert
      />
    </FlexBox>
  );
}
