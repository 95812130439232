/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { toast } from 'react-toastify';
import { CellPhoneInput } from './CellphoneInput';
import { CommonPaper, FlexBox } from './StyledComponents';
import { getDateString, getUsernameFromEmail, isNullOrWhitespace } from '../utils';
import { Account, AccountFormConfig, IndustryGrouped } from '../interfaces';
import { useAppDispatch, useAppSelector, useAuth } from '../hooks';
import { getIndustriesGroupedThunk, getInstancesAppThunk } from '../../app';
import { AccountStatus, UserRole, InstanceStatus, AccountType } from '../enums';
import { Loading } from './Loading';
import { DisabledTextField } from './DisabledTextField';
import { COUNTRIES, MAX_NUMBER_RECORDS, US_STATES } from '../constants';
import BrandLogo from '../../features/accounts/account-settings/BrandLogo';
import { getAccountCompanies } from '../../api';
import { setCallInstanceThunk } from '../../app/appSlice';
import fonts from '../../assets/scss/font.module.scss';

interface Props {
  formConfig: AccountFormConfig;
  setAccountFormData: (value: Account) => void;
  formDisabled?: boolean;
  statesValues?: Array<string>;
  accountId?: string;
  companyLogo?: string;
  isAccountSettings?: boolean;
}

export function isAccountFormInvalid(form: AccountFormConfig): boolean | undefined {
  const {
    companyControl,
    statusControl,
    industryControl,
    addressLine1Control,
    addressLine2Control,
    cityControl,
    stateControl,
    zipCodeControl,
    phoneNumberControl,
    companyWebsiteControl,
    countryCodeControl,
    countryControl,
    instanceControl,
    typeControl,
  } = form;
  return (
    (companyControl?.required && isNullOrWhitespace(companyControl?.value)) ||
    (statusControl?.required && isNullOrWhitespace(statusControl?.value)) ||
    (industryControl?.required && industryControl?.value === null) ||
    (addressLine1Control?.required && isNullOrWhitespace(addressLine1Control?.value)) ||
    (addressLine2Control?.required && isNullOrWhitespace(addressLine2Control?.value)) ||
    (cityControl?.required && isNullOrWhitespace(cityControl?.value)) ||
    (stateControl?.required && isNullOrWhitespace(stateControl?.value)) ||
    (zipCodeControl?.required &&
      zipCodeControl?.value?.length !== 5 &&
      zipCodeControl?.value?.length !== 9) ||
    (phoneNumberControl?.required && phoneNumberControl?.value!.length < 10) ||
    phoneNumberControl?.value![0] === '0' ||
    phoneNumberControl?.value![0] === '1' ||
    (companyWebsiteControl?.required && isNullOrWhitespace(companyWebsiteControl?.value)) ||
    (countryCodeControl?.required && isNullOrWhitespace(countryCodeControl?.value)) ||
    (countryControl?.required && isNullOrWhitespace(countryControl?.value)) ||
    (instanceControl?.required && isNullOrWhitespace(instanceControl?.value)) ||
    (typeControl?.required && isNullOrWhitespace(typeControl?.value))
  );
}

export const AccountSharedForm: FC<Props> = (props) => {
  const {
    formConfig: formValue,
    setAccountFormData,
    formDisabled,
    statesValues,
    accountId,
    companyLogo,
    isAccountSettings,
  } = props;
  const {
    companyControl,
    statusControl,
    industryControl,
    addressLine1Control,
    addressLine2Control,
    cityControl,
    stateControl,
    zipCodeControl,
    phoneNumberControl,
    companyWebsiteControl,
    createdByControl,
    createdAtControl,
    lastUpdatedByControl,
    lastUpdatedControl,
    sageCustomerIdControl,
    countryCodeControl,
    countryControl,
    instanceControl,
    typeControl,
  } = formValue;
  const dispatch = useAppDispatch();
  const app = useAppSelector((x) => x.app);
  const [isUserInRole] = useAuth();
  const { industriesGrouped, loadingIndustries, instances, loadingInstances, account, callAppInstancesThunk } = app;
  const getIndustryGrouped = (subIndustry: string | undefined) =>
    subIndustry ? industriesGrouped.find((ig) => ig?.value === subIndustry) : null;
  const getState = (abbreviation: string | undefined) => {
    const findState = US_STATES.find((s) => s.abbreviation === abbreviation);
    if (!findState) return abbreviation;
    return findState.name;
  };

  const commonTextFieldStyle = {
    '& label': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
    '& input': {
      fontSize: fonts.fontSize18,
    },
    '&::placeholder': {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight700,
    },
  };

  const getCountry = (countryName: string | undefined) =>
    countryName ? COUNTRIES.find((item) => item.name === countryName) : undefined;

  const getCountryCode = (iso: string | undefined) => {
    if (!iso) return undefined;
    const getObjectCountryByIsoAndName = COUNTRIES.find(
      (item) => item.iso === iso && item.name === countryControl?.value,
    );
    if (getObjectCountryByIsoAndName) return getObjectCountryByIsoAndName;
    return COUNTRIES.find((item) => item.iso === iso);
  };

  const getInstance = (id: string | undefined) => {
    if (instanceControl?.disabled) return account?.instance;
    return instances?.find((item) => item.id === id);
  };

  useEffect(() => {
    if (industryControl && !industriesGrouped.length) dispatch(getIndustriesGroupedThunk());
  }, [industryControl]);

  const getAppInstances = async () => {
    await dispatch(
      getInstancesAppThunk({
        count: MAX_NUMBER_RECORDS,
        orderBy: 'name',
        offset: 0,
        descending: true,
        status: InstanceStatus.Active,
      })
    );
    dispatch(setCallInstanceThunk(false));
  };

  useEffect(() => {
    if (instanceControl && instanceControl.disabled) return;
    if (instanceControl && callAppInstancesThunk) getAppInstances();
  }, [instanceControl, callAppInstancesThunk]);

  const [temporaryCompanies, settemporaryCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [display, setDisplay] = useState(false);
  // const [industryValues, setIndustryValues] = useState({
  //   subIndustry: '',
  //   industry: '',
  // });

  const getCompaniesList = (value: string) => {
    getAccountCompanies(value).then((companiesList) => {
      settemporaryCompanies(companiesList?.data?.results);
      const companiesArray = companiesList?.data?.results.map(
        (companyData: any) => companyData.name,
      );
      setCompanies(companiesArray);
    });
  };

  useEffect(() => {
    getCompaniesList(companyControl?.value!);
  }, [companyControl?.value]);

  useEffect(() => {
    const selectedCompany: any = temporaryCompanies.filter(
      (companyName: any) => companyName.name === companyControl?.value,
    );
    if (selectedCompany.length > 0) {
      setDisplay(true);
      setAccountFormData({
        industry: {
          industry: selectedCompany[0]?.sector,
          subIndustry: selectedCompany[0]?.industry,
        },
      });
    } else {
      setDisplay(false);
    }
  }, [companies]);

  // const company = useMemo(
  //   () => (
  //     // <TextField
  //     //   label={companyControl?.label ?? 'Company'}
  //     //   fullWidth
  //     //   required={companyControl?.required}
  //     //   disabled={formDisabled || companyControl?.disabled}
  //     //   value={companyControl?.value}
  //     //   onChange={(e) => {
  //     //     setAccountFormData({ company: { name: e.target.value } });
  //     //   }}
  //     //   inputProps={{ maxLength: 50 }}
  //     // />
  //     <Autocomplete
  //       fullWidth
  //       freeSolo
  //       disableClearable
  //       options={companies}
  //       value={companyControl?.value}
  //       onInputChange={(event, newInputValue) => {
  //         getCompaniesList(newInputValue);
  //         setAccountFormData({ company: { name: newInputValue } });
  //         setDisplay(false);
  //         setIndustryValues({
  //           subIndustry: '',
  //           industry: '',
  //         });
  //       }}
  //       renderInput={(params) => (
  //         <TextField
  //           required={companyControl?.required}
  //           {...params}
  //           label={companyControl?.label ?? 'Company'}
  //           InputProps={{
  //             ...params.InputProps,
  //             type: 'search',
  //           }}
  //         />
  //       )}
  //     />
  //   ),
  //   [
  //     companyControl?.disabled,
  //     companyControl?.label,
  //     companyControl?.required,
  //     companyControl?.value,
  //     formDisabled,
  //   ],
  // );
  const status = useMemo(
    () => (
      <Autocomplete
        disablePortal
        fullWidth
        id="combo-box-status"
        options={Object.values(AccountStatus)}
        value={statusControl?.value}
        onChange={(event: any, newValue: string | null) => {
          setAccountFormData({ status: newValue });
        }}
        renderInput={(params) => (
          <TextField
            required={statusControl?.required}
            {...params}
            label={statusControl?.label ?? 'Status'}
            sx={commonTextFieldStyle}
          />
        )}
        disableClearable={statusControl?.required}
        disabled={
          formDisabled ||
          statusControl?.disabled ||
          !isUserInRole([
            UserRole.SuperAdmin,
            UserRole.GivewithFinance,
            UserRole.GivewithOperations,
          ])
        }
      />
    ),
    [
      statusControl?.disabled,
      statusControl?.label,
      statusControl?.required,
      statusControl?.value,
      formDisabled,
    ],
  );

  const type = useMemo(
    () => (
      <Autocomplete
        disablePortal
        fullWidth
        id="combo-box-status"
        options={Object.values(AccountType)}
        value={typeControl?.value ?? null}
        onChange={(event: any, newValue: AccountType | null) => {
          if (!newValue) return;
          setAccountFormData({ type: newValue });
        }}
        renderInput={(params) => (
          <TextField
            required={typeControl?.required}
            {...params}
            label={typeControl?.label ?? 'Account Type'}
            sx={commonTextFieldStyle}
          />
        )}
        disableClearable={typeControl?.required}
        disabled={
          formDisabled ||
          typeControl?.disabled ||
          !isUserInRole([
            UserRole.SuperAdmin,
            UserRole.GivewithFinance,
            UserRole.GivewithOperations,
          ])
        }
      />
    ),
    [
      typeControl?.disabled,
      typeControl?.label,
      typeControl?.required,
      typeControl?.value,
      formDisabled,
    ],
  );

  // const industry = useMemo(
  //   () =>
  //     !display ? (
  //       <Autocomplete
  //         disablePortal
  //         fullWidth
  //         id="combo-box-industry"
  //         getOptionLabel={(option) => option.label}
  //         options={industriesGrouped}
  //         value={getIndustryGrouped(industryControl?.value?.subIndustry)!}
  //         groupBy={(option) => option.group!}
  //         onChange={(event: any, newValue: IndustryGrouped | null) => {
  //           setAccountFormData({
  //             industry: {
  //               industry: newValue!.group,
  //               subIndustry: newValue!.value,
  //             },
  //           });
  //         }}
  //         renderInput={(params) => (
  //           <TextField
  //             required={industryControl?.required}
  //             {...params}
  //             label={industryControl?.label ?? 'Industry'}
  //           />
  //         )}
  //         disableClearable={industryControl?.required}
  //         disabled={formDisabled || industryControl?.disabled}
  //       />
  //     ) : (
  //       <TextField
  //         value={industryValues?.subIndustry}
  //         fullWidth
  //         label={industryControl?.label ?? 'Industry'}
  //         disabled
  //       />
  //     ),
  //   [
  //     industryControl?.disabled,
  //     industryControl?.label,
  //     industryControl?.required,
  //     industryControl?.value,
  //     loadingIndustries,
  //     formDisabled,
  //   ],
  // );
  const addressLine1 = useMemo(
    () => (
      <TextField
        label={addressLine1Control?.label ?? 'Address Line 1'}
        fullWidth
        required={addressLine1Control?.required}
        disabled={formDisabled || addressLine1Control?.disabled}
        value={addressLine1Control?.value}
        onChange={(e) => {
          setAccountFormData({ company: { address: { address1: e.target.value } } });
        }}
        sx={commonTextFieldStyle}
      />
    ),
    [
      addressLine1Control?.disabled,
      addressLine1Control?.label,
      addressLine1Control?.required,
      addressLine1Control?.value,
      formDisabled,
    ],
  );
  const addressLine2 = useMemo(
    () => (
      <TextField
        label={addressLine2Control?.label ?? 'Address Line 2'}
        fullWidth
        required={addressLine2Control?.required}
        disabled={formDisabled || addressLine2Control?.disabled}
        value={addressLine2Control?.value}
        onChange={(e) => {
          setAccountFormData({ company: { address: { address2: e.target.value } } });
        }}
        sx={commonTextFieldStyle}
      />
    ),
    [
      addressLine2Control?.disabled,
      addressLine2Control?.label,
      addressLine2Control?.required,
      addressLine2Control?.value,
      formDisabled,
    ],
  );
  const city = useMemo(
    () => (
      <TextField
        sx={{ ...commonTextFieldStyle, flex: 4 }}
        label={cityControl?.label ?? 'City'}
        required={cityControl?.required}
        disabled={formDisabled || cityControl?.disabled}
        value={cityControl?.value}
        onChange={(e) => {
          setAccountFormData({ company: { address: { city: e.target.value } } });
        }}
      />
    ),
    [
      cityControl?.disabled,
      cityControl?.label,
      cityControl?.required,
      cityControl?.value,
      formDisabled,
    ],
  );
  const state = useMemo(
    () => (
      <Autocomplete
        disablePortal
        sx={{ flex: 3 }}
        id="combo-box-state"
        options={statesValues ?? [stateControl?.value]}
        value={getState(stateControl?.value)}
        onChange={(event: any, newValue) => {
          setAccountFormData({ company: { address: { stateProvince: newValue } } });
        }}
        renderInput={(params) => (
          <TextField
            required={stateControl?.required}
            {...params}
            label={stateControl?.label ?? 'State'}
            sx={commonTextFieldStyle}
          />
        )}
        disableClearable={stateControl?.required}
        disabled={formDisabled || stateControl?.disabled}
      />
    ),
    [
      stateControl?.disabled,
      stateControl?.label,
      stateControl?.required,
      stateControl?.value,
      formDisabled,
    ],
  );
  const zipCode = useMemo(
    () => (
      <TextField
        label={zipCodeControl?.label ?? 'Postal Code'}
        name="zipCode"
        sx={{ ...commonTextFieldStyle, flex: 3 }}
        required={zipCodeControl?.required}
        disabled={formDisabled || zipCodeControl?.disabled}
        value={zipCodeControl?.value}
        error={
          zipCodeControl &&
          zipCodeControl.value?.length > 0 &&
          zipCodeControl.value?.length !== 5 &&
          zipCodeControl.value?.length !== 9
        }
        onChange={(e) => {
          setAccountFormData({ company: { address: { postalCode: e.target.value } } });
        }}
      />
    ),
    [
      zipCodeControl?.disabled,
      zipCodeControl?.label,
      zipCodeControl?.required,
      zipCodeControl?.value,
      formDisabled,
    ],
  );

  const phoneNumberErrorControl = () => {
    const cond2 = phoneNumberControl?.value
      ? phoneNumberControl?.value?.length < 10 ||
        (phoneNumberControl?.value?.length > 0 && ['0', '1'].includes(phoneNumberControl?.value[0]))
      : false;
    const cond1 = phoneNumberControl ? cond2 : false;
    return cond1;
  };

  const phoneNumber = useMemo(
    () => (
      <TextField
        label="Phone Number"
        name="phoneNumber"
        fullWidth
        required={phoneNumberControl?.required}
        disabled={formDisabled || phoneNumberControl?.disabled}
        value={phoneNumberControl?.value}
        error={phoneNumberErrorControl()}
        onChange={(e) => {
          setAccountFormData({ company: { phoneNumber: e.target.value } });
        }}
        InputProps={{
          inputComponent: CellPhoneInput as any,
        }}
        sx={{ ...commonTextFieldStyle, flex: 1.3 }}
      />
    ),
    [
      phoneNumberControl?.disabled,
      phoneNumberControl?.label,
      phoneNumberControl?.required,
      phoneNumberControl?.value,
      formDisabled,
    ],
  );

  const companyWebsite = useMemo(
    () => (
      <TextField
        label={companyWebsiteControl?.label ?? 'Website'}
        fullWidth
        required={companyWebsiteControl?.required}
        disabled={formDisabled || companyWebsiteControl?.disabled}
        value={companyWebsiteControl?.value}
        type="url"
        onChange={(e) => {
          const regex = /^[a-zA-Z0-9\-.]*$/;
          if (regex.test(e.target.value)) {
            setAccountFormData({ company: { website: e.target.value } });
          } else {
            toast.error('Special characters not allowed.');
          }
        }}
        sx={{ ...commonTextFieldStyle, flex: 2 }}
        inputProps={{ maxLength: '50' }}
      />
    ),
    [
      companyWebsiteControl?.disabled,
      companyWebsiteControl?.label,
      companyWebsiteControl?.required,
      companyWebsiteControl?.value,
      formDisabled,
    ],
  );
  const sageCustomerId = useMemo(
    () => (
      <TextField
        label={sageCustomerIdControl?.label ?? 'ScaleWith Customer #'}
        required={sageCustomerIdControl?.required}
        disabled={
          formDisabled ||
          !isUserInRole([
            UserRole.SuperAdmin,
            UserRole.GivewithFinance,
            UserRole.GivewithOperations,
          ])
        }
        value={sageCustomerIdControl?.value}
        onChange={(e) => {
          setAccountFormData({ sageCustomerId: e.target.value });
        }}
        sx={{ ...commonTextFieldStyle, flex: 1 }}
      />
    ),
    [
      sageCustomerIdControl?.disabled,
      sageCustomerIdControl?.label,
      sageCustomerIdControl?.required,
      sageCustomerIdControl?.value,
      formDisabled,
    ],
  );

  const createdBy = useMemo(
    () => (
      <DisabledTextField
        label={createdByControl?.label ?? 'Created By'}
        value={getUsernameFromEmail(createdByControl?.value)}
        tooltipTitle={createdByControl?.value}
      />
    ),
    [createdByControl?.label, createdByControl?.value],
  );
  const createdAt = useMemo(
    () => (
      <DisabledTextField
        label={createdAtControl?.label ?? 'Created At'}
        value={getDateString(createdAtControl?.value, true)}
        tooltipTitle={getDateString(createdAtControl?.value)}
      />
    ),
    [createdAtControl?.label, createdAtControl?.value],
  );
  const lastUpdatedBy = useMemo(
    () => (
      <DisabledTextField
        label={lastUpdatedByControl?.label ?? 'Last Updated By'}
        value={getUsernameFromEmail(lastUpdatedByControl?.value)}
        tooltipTitle={lastUpdatedByControl?.value}
      />
    ),
    [lastUpdatedByControl?.label, lastUpdatedByControl?.value],
  );
  const lastUpdated = useMemo(
    () => (
      <DisabledTextField
        label={lastUpdatedControl?.label ?? 'Last Updated At'}
        value={getDateString(lastUpdatedControl?.value, true)}
        tooltipTitle={getDateString(lastUpdatedControl?.value)}
      />
    ),
    [lastUpdatedControl?.label, lastUpdatedControl?.value],
  );

  const countryCode = useMemo(
    () => (
      <Autocomplete
        disablePortal
        options={COUNTRIES}
        getOptionLabel={(option) => `${option.countryCode} ${option.iso}`}
        value={getCountryCode(countryCodeControl?.value)}
        sx={{ flex: 1.1 }}
        disableClearable
        onChange={(event: any, newValue) => {
          setAccountFormData({ company: { countryCode: newValue.iso } });
        }}
        renderInput={(params) => (
          <TextField {...params} required={countryCodeControl?.required} label="Country Code" sx={commonTextFieldStyle} />
        )}
      />
    ),
    [
      countryCodeControl?.label,
      countryCodeControl?.value,
      countryCodeControl?.required,
      countryCodeControl?.disabled,
      formDisabled,
    ],
  );

  const country = useMemo(
    () => (
      <Autocomplete
        disablePortal
        disableClearable
        options={COUNTRIES}
        getOptionLabel={(option) => option.name}
        value={getCountry(countryControl?.value)}
        sx={{ flex: 1 }}
        onChange={(event: any, newValue) => {
          setAccountFormData({ company: { address: { country: newValue?.name } } });
        }}
        renderInput={(params) => (
          <TextField required={countryControl?.required} {...params} label="Country" sx={commonTextFieldStyle} />
        )}
      />
    ),
    [
      countryControl?.label,
      countryControl?.value,
      countryControl?.required,
      countryControl?.disabled,
      formDisabled,
    ],
  );

  const instance = useMemo(
    () => (
      <Autocomplete
        disablePortal
        disableClearable
        options={!instanceControl?.disabled ? instances : [account?.instance]}
        loading={loadingInstances}
        getOptionLabel={(option) => option?.name ?? 'Instance'}
        value={getInstance(instanceControl?.value)}
        sx={{ flex: 1 }}
        onChange={(event: any, newValue) => {
          if (!newValue?.id || !newValue.name) return;
          setAccountFormData({
            instance: { id: newValue?.id, name: newValue?.name, type: 'instance_settings' },
          });
        }}
        disabled={
          formDisabled ||
          instanceControl?.disabled ||
          !isUserInRole([
            UserRole.SuperAdmin,
            UserRole.GivewithFinance,
            UserRole.GivewithOperations,
          ])
        }
        renderInput={(params) => (
          <TextField required={instanceControl?.required} {...params} label="Instance" sx={commonTextFieldStyle}/>
        )}
      />
    ),
    [
      instanceControl?.label,
      instanceControl?.value,
      instanceControl?.disabled,
      loadingInstances,
      formDisabled,
    ],
  );

  if ((industryControl && loadingIndustries) || (instanceControl && loadingInstances))
    return <Loading loading={loadingIndustries || loadingInstances} />;

  const includeAuditFields =
    createdByControl || createdAtControl || lastUpdatedByControl || lastUpdatedControl;

  return (
    <FlexBox
      sx={includeAuditFields ? { gap: 6 } : { flexDirection: 'column', overflowX: 'hidden' }}
    >
      <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
        {isAccountSettings && (
          <BrandLogo
            formId={accountId}
            companyLogo={companyLogo!}
            onChangeBrand={(value: string) =>
              setAccountFormData({ company: { companyLogo: value } })
            }
            onDeleteBrand={() => setAccountFormData({ company: { companyLogo: '' } })}
          />
        )}

        <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
          <FlexBox
            sx={{
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            {/* {companyControl && company}
            {industryControl && industry} */}

            <Autocomplete
              fullWidth
              freeSolo
              disableClearable
              options={companies}
              value={companyControl?.value}
              onInputChange={(event, newInputValue) => {
                getCompaniesList(newInputValue);
                setAccountFormData({ company: { name: newInputValue } });
                setDisplay(false);
              }}
              onChange={(e, value: any) => {
                const selectedCompany: any = temporaryCompanies.filter(
                  (companyName: any) => companyName.name === value,
                );
                if (selectedCompany.length > 0) {
                  setDisplay(true);
                  setAccountFormData({
                    industry: {
                      industry: selectedCompany[0]?.sector,
                      subIndustry: selectedCompany[0]?.industry,
                    },
                  });
                } else {
                  setDisplay(false);
                }
              }}
              renderInput={(params: any) => (
                <TextField
                  required={companyControl?.required}
                  {...params}
                  label={companyControl?.label ?? 'Company'}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                  sx={commonTextFieldStyle}
                />
              )}
            />
            {!display ? (
              <Autocomplete
                disablePortal
                fullWidth
                id="combo-box-industry"
                getOptionLabel={(option) => option.label}
                options={industriesGrouped}
                value={getIndustryGrouped(industryControl?.value?.subIndustry)!}
                groupBy={(option) => option.group!}
                onChange={(event: any, newValue: IndustryGrouped | null) => {
                  setAccountFormData({
                    industry: {
                      industry: newValue!.group,
                      subIndustry: newValue!.value,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    required={industryControl?.required}
                    {...params}
                    label={industryControl?.label ?? 'Industry'}
                    sx={commonTextFieldStyle}
                  />
                )}
                disableClearable={industryControl?.required}
                disabled={formDisabled || industryControl?.disabled}
              />
            ) : (
              <TextField
                value={getIndustryGrouped(industryControl?.value?.subIndustry)!?.value}
                fullWidth
                label={industryControl?.label ?? 'Industry'}
                disabled
                sx={commonTextFieldStyle}
              />
            )}
          </FlexBox>
          <FlexBox
            sx={{
              gap: 2,
            }}
          >
            {statusControl && status}
            {typeControl && type}
          </FlexBox>
          {addressLine1Control && addressLine1}
          {addressLine2Control && addressLine2}
          {countryControl && country}
          <FlexBox
            sx={{
              gap: 2,
            }}
          >
            {cityControl && city}
            {stateControl && state}
            {zipCodeControl && zipCode}
          </FlexBox>
          <FlexBox
            sx={{
              justifyContent: 'space-between',
              gap: 2,
            }}
          >
            {countryCodeControl && countryCode}
            {phoneNumberControl && phoneNumber}
          </FlexBox>
          {companyWebsiteControl && companyWebsite}
          <FlexBox sx={{ gap: 2 }}>
            {instanceControl && instance}
            {sageCustomerIdControl && sageCustomerId}
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {(createdByControl || createdAtControl || lastUpdatedByControl || lastUpdatedControl) && (
        <FlexBox sx={{ flexDirection: 'column', gap: 3 }}>
          <CommonPaper>
            <FlexBox sx={{ flexDirection: 'column', gap: 1 }}>
              {createdByControl && createdBy}
              {createdAtControl && createdAt}
              {lastUpdatedByControl && lastUpdatedBy}
              {lastUpdatedControl && lastUpdated}
            </FlexBox>
          </CommonPaper>
        </FlexBox>
      )}
    </FlexBox>
  );
};
