import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Card,
  Typography,
  Button,
  CardHeader,
  Link,
  // CircularProgress
} from '@mui/material';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import WatchLater from '../../assets/svg/watch_later_24px.svg';
import Progressed from '../../assets/svg/progressed.svg';
import CompletionInformation from './components/CompletionInformation';
import ProgramInitiative from './ProgramInitiative';
import ProgramFundingInformation from './components/ProgramFundingInformation';
import GeneralInformation from './components/GeneralInformation';
import ImpactScope from './components/ImpactScope';
import StrategiesApproaches from './components/StrategiesApproaches';
import ResearchEvaluation from './components/ResearchEvaluation';
import CompletionFooter from './components/Footer';
import { createPdfWithLinks } from '../../shared/utils';
import {
  getVocab,
  getCompletionFormData,
  postCompletionForm,
  submitCompletion,
} from '../../api/portal/programRequest';
import {
  completionProgram,
  postProgramSchema,
} from '../../shared/interfaces/completion-form/completionProgram';
import SubmitModal from './components/submitFormModal/submitFormModal';
import colors from '../../assets/scss/color.module.scss';
import fonts from '../../assets/scss/font.module.scss';

interface progress {
  completion: {
    total: number;
    complete: number;
  };
  funding: {
    total: number;
    complete: number;
  };
  general: {
    total: number;
    complete: number;
  };
  impactAndScope: {
    total: number;
    complete: number;
  };
  researchAndEvaluation: {
    total: number;
    complete: number;
  };
  strategiesAndApproaches: {
    total: number;
    complete: number;
  };
  [key: string]: any;
}

const ExecutedProgramDashboard = () => {
  const [isStarted, setIsStarted] = useState(true);
  const [activeIndex, setActiveIndex] = useState('program0');
  const [vocabData, setVocabData] = useState({});
  const [completionFormData, setCompletionFormData] = useState<completionProgram>();
  const [tempFormData, setTempFormData] = useState<postProgramSchema>();
  const [checkSubmitResponse, setSubmitResponse] = useState(false);
  const [getFormId, setFormId] = useState<any>('');
  const [submitted, setSubmitted] = useState<any>(false);
  const [searchParams] = useSearchParams();
  const pdfDownloadName = searchParams.get('pdf_download_id') ?? 'completedFormPage';
  const [progressData, setProgressData] = useState<progress>();
  const [questionsFilled, SetQuestionsFilled] = useState(2);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [isSaveProgress, setIsSaveProgress] = useState(false);
  const [process, setProcess] = useState({
    completion: false,
    funding: false,
    general: false,
    impactAndScope: false,
    researchAndEvaluation: false,
    strategiesAndApproaches: false,
  });
  // const [isLoader, setIsLoader] = useState(false);
  // const [downloading, setDownloading] = useState<boolean>(false);
  const convertComponentToPDF = async () => {
    // setDownloading(true);
    createPdfWithLinks({
      pdfName: pdfDownloadName,
      elementId: 'completion_form',
    }).then(() => {
      // setDownloading(false);
    });
  };

  const handleClick = (id: string) => {
    setActiveIndex(id);
  };

  useEffect(() => {
    const targetElement = document.getElementById(activeIndex);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeIndex]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const formId = url.pathname.split('/').pop();
    setFormId(formId);
    getVocab().then((res) => {
      if (res.status === 200) {
        setVocabData(res.data);
      }
    });
    if (!formId) return;
    getCompletionFormData(formId).then((res) => {
      if (res.status === 200) {
        setCompletionFormData(res.data);
        setTempFormData(res.data);
      }
    });
  }, []);

  useEffect(() => {
    setProgressData(completionFormData?.progress);
    if (completionFormData?.status === 'submitted') {
      setSubmitted(true);
      setIsStarted(false);
    } else {
      setSubmitted(false);
    }
  }, [completionFormData]);

  useEffect(() => {
    if (progressData) {
      let totalSum = 0;
      let completedSum = 0;
      Object.values(progressData).forEach((value) => {
        if (value.total) {
          totalSum += value.total;
        }
      });
      Object.keys(progressData).forEach((val) => {
        completedSum += progressData[val].complete;
      });
      setTotalQuestions(totalSum);
      SetQuestionsFilled(completedSum);
    }
  }, [progressData]);

  const handleData = (data: any, key: string) => {
    setTempFormData((prevState: any) => ({
      ...prevState,
      [key]: data,
    }));
  };

  const saveData = (type: string) => {
    if (!tempFormData || !getFormId) return;
    let submitKeysArray = [
      'completion',
      'funding',
      'general',
      'impactAndScope',
      'strategiesAndApproaches',
      'researchAndEvaluation',
      'status',
    ];
    if (type === 'submit') {
      submitKeysArray = [...submitKeysArray, 'review'];
    }
    const pickedData = _.pick(tempFormData, submitKeysArray);
    if (type === 'save') {
      postCompletionForm(pickedData, getFormId).then((res) => {
        if (res.status === 200) {
          setIsSaveProgress(false);
          // setIsLoader(false);
          toast.success('Progress saved successfully');
          setProcess({
            completion: false,
            funding: false,
            general: false,
            impactAndScope: false,
            researchAndEvaluation: false,
            strategiesAndApproaches: false,
          });
        }
      });
    } else {
      submitCompletion(pickedData, getFormId).then((res) => {
        if (res.status === 200) {
          setSubmitResponse(false);
          setSubmitted(true);
          toast.success('Form submitted successfully');
          setProcess({
            completion: false,
            funding: false,
            general: false,
            impactAndScope: false,
            researchAndEvaluation: false,
            strategiesAndApproaches: false,
          });
        }
      });
    }
  };

  useEffect(() => {
    const isAllKeysTrue = Object.values(process).every((value) => value === true);
    if (isAllKeysTrue && isSaveProgress) {
      // setIsLoader(true);
      saveData('save');
    }
  }, [process]);

  const formTypes = [
    {
      title: 'Completion Information',
      slug: 'completion',
      id: 0,
    },
    {
      title: 'Program Funding Information',
      slug: 'funding',
      id: 1,
    },
    {
      title: 'General Information',
      slug: 'general',
      id: 2,
    },
    {
      title: 'Impact and Scope',
      slug: 'impactAndScope',
      id: 3,
    },
    {
      title: 'Strategies and Approaches',
      slug: 'strategiesAndApproaches',
      id: 4,
    },
    {
      title: 'Research and Evaluation',
      slug: 'researchAndEvaluation',
      id: 5,
    },
  ];
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#68839A',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#FFF',
    },
  }));
  const Heading = !submitted
    ? ' Program Completion Form'
    : 'Congratulations! Program Completion Form Submitted Successfully!';

  const text = (status: boolean) => {
    if (status) {
      return (
        <Typography variant="h6" sx={{ color: 'black' }}>
          Thank you for submitting Program Completion form. We will be in touch regarding your
          submission. If you have any questions, please reach out to your designated Partneships
          Manager, or email
          <Link
            className="mailtoLink"
            href="mailto:help@scalewith.com"
            sx={{ display: 'inline', ml: 1, fontSize: '16px', color: '#1a6feb' }}
          >
            <span>help@scalewith.com</span>
          </Link>
        </Typography>
      );
    }
    return (
      <Typography color="white" sx={{ fontSize: fonts.fontSize18, width: '60%' }}>
        Please complete all unanswered questions and be sure to make any necessary updates to the
        auto-populated information regarding this specific program completion opportunity.
      </Typography>
    );
  };
  const size = !submitted ? 9 : 12;

  const getProgressCount = (key: any) => {
    const progressDetails: any = completionFormData && completionFormData.progress;
    return progressDetails?.[key];
  };
  const setProcessData = (key: string) => {
    setProcess((prevState: any) => ({
      ...prevState,
      [key]: true,
    }));
  };

  return (
    <section className="bgLight">
      {/* {isLoader && <CircularProgress />} */}
      <Grid container>
        <Grid item xs={12}>
          <Card className="mainCard">
            {isStarted && <ProgramInitiative onClick={() => setIsStarted(false)} />}
            {!isStarted && (
              <Card sx={{ padding: `${submitted && '60px'}` }}>
                <CardHeader
                  sx={{
                    padding: '25px',
                    background: `${!submitted ? colors.backgroundColorGradient : ''}`,
                    borderRadius: '20px 20px 0px 0px',
                  }}
                  title={
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            fontSize: fonts.fontSize24,
                            fontWeight: fonts.fontWeight700,
                            mb: 3,
                            color: `${!submitted ? '#FFFFFF' : '#154062'}`,
                          }}
                        >
                          {Heading}
                        </Typography>
                        {text(submitted)}
                        {!submitted && (
                          <>
                            <Grid item xs={12} sx={{ textAlign: 'right' }}>
                              <Typography
                                sx={{
                                  fontSize: fonts.fontSize18,
                                  fontWeight: 600,
                                  color: colors.secondary,
                                }}
                              >
                                {/* {completionFormData && completionFormData?.percentComplete}% */}
                                {Math.floor((questionsFilled / totalQuestions) * 100)}% complete
                              </Typography>
                            </Grid>
                            <BorderLinearProgress
                              variant="determinate"
                              // value={completionFormData && completionFormData?.percentComplete}
                              value={Math.floor((questionsFilled / totalQuestions) * 100)}
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  }
                />
                <Grid container spacing={2} sx={{ padding: '1.5em' }}>
                  <Grid item xs={3}>
                    {!submitted && (
                      <Grid container direction="column" spacing={2}>
                        {formTypes &&
                          formTypes.map((item) => {
                            const programId = `program${item.id}`;
                            return (
                              <Grid item key={programId}>
                                <Button
                                  // variant={activeIndex === programId ? 'contained' : 'outlined'}
                                  onClick={() => handleClick(programId)}
                                  sx={{
                                    width: '100%',
                                    backgroundColor: `${
                                      activeIndex === programId && colors.backgroundSecondary
                                    }`,
                                    borderRadius: '10px',
                                    border: `1px solid ${
                                      activeIndex === programId
                                        ? colors.strokeClick
                                        : colors.strokeDefault
                                    }`,
                                    padding: '10px 15px',
                                  }}
                                >
                                  <Grid item xs={12}>
                                    <Typography
                                      sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: `${
                                            activeIndex === programId
                                              ? colors.primary
                                              : colors.textPrimary
                                          }`,
                                          fontSize: fonts.fontSize16,
                                          fontWeight: fonts.fontWeight700,
                                        }}
                                      >
                                        {item.title}
                                      </span>
                                      {progressData?.[item.slug]?.complete !==
                                      getProgressCount(item.slug)?.total ? (
                                        <img src={WatchLater} alt="Watch Later" />
                                      ) : (
                                        <img src={Progressed} alt="Progressed" />
                                      )}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        textAlign: 'left',
                                        color: colors.textPrimary,
                                        fontWeight: fonts.fontWeight400,
                                        fontSize: '12px',
                                      }}
                                    >
                                      {progressData?.[item.slug]?.complete} of{' '}
                                      {getProgressCount(item.slug) &&
                                        getProgressCount(item.slug)?.total}
                                    </Typography>
                                  </Grid>
                                </Button>
                              </Grid>
                            );
                          })}
                      </Grid>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={size}
                    sx={{
                      border: `${submitted && '1px solid grey'}`,
                      borderRadius: `${submitted && '20px'}`,
                    }}
                  >
                    {submitted && (
                      <Grid
                        container
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          p: 2,
                        }}
                      >
                        <Grid item>
                          <Typography
                            sx={{
                              fontSize: fonts.fontSize24,
                              fontWeight: fonts.fontWeight700,
                              color: colors.textPrimary,
                            }}
                          >
                            {completionFormData?.completionFormName}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            size="large"
                            onClick={convertComponentToPDF}
                            sx={{
                              backgroundColor: colors.primary,
                              color: colors.secondary,
                              '&:hover': {
                                backgroundColor: colors.primary,
                                color: colors.secondary,
                              },
                            }}
                          >
                            Download Form
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    <Grid
                      sx={{
                        height: '520px',
                        overflow: 'auto',
                        scrollbarWidth: 'thin',
                        paddingRight: '4px',
                        '&::-webkit-scrollbar': {
                          width: '6px',
                        },
                        '&::-webkit-scrollbar-track': {
                          background: '#ffffff',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#d9d9d9',
                          borderRadius: '10px',
                          height: '150px',
                        },
                      }}
                    >
                      <Grid id="completion_form">
                        {formTypes.map((item, index) => (
                          <Grid key={item.title}>
                            <Grid
                              id={`program${index}`}
                              sx={{
                                backgroundColor: `${
                                  !submitted ? colors.backgroundPrimary : colors.backgroundPrimary
                                }`,
                                color: `${!submitted ? colors.textPrimary : colors.textPrimary}`,
                                padding: '15px',
                                marginBottom: '1em',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Grid
                                sx={{
                                  flex: '1',
                                  fontSize: fonts.fontSize24,
                                  fontWeight: fonts.fontWeight700,
                                }}
                              >
                                {item.title}
                              </Grid>
                              <Grid
                                sx={{ fontSize: fonts.fontSize18, fontWeight: fonts.fontWeight400 }}
                              >
                                {progressData?.[item.slug]?.complete} of{' '}
                                {getProgressCount(item.slug) && getProgressCount(item.slug)?.total}
                                &nbsp;Completed
                              </Grid>
                            </Grid>
                            {item.title === 'Completion Information' && (
                              <CompletionInformation
                                setCompletionProgramData={handleData}
                                isSaveProgress={isSaveProgress}
                                submitResponse={checkSubmitResponse}
                                setProcess={() => setProcessData('completion')}
                                completion={completionFormData?.completion}
                                submitted={submitted}
                                validatedQuestions={(count: number) =>
                                  setProgressData((prevState: any) => ({
                                    ...prevState,
                                    completion: {
                                      ...prevState?.completion,
                                      complete: count,
                                    },
                                  }))
                                }
                              />
                            )}
                            {item.title === 'Program Funding Information' && (
                              <ProgramFundingInformation
                                setCompletionProgramData={handleData}
                                isSaveProgress={isSaveProgress}
                                submitResponse={checkSubmitResponse}
                                setProcess={() => setProcessData('funding')}
                                funding={completionFormData?.funding}
                                submitted={submitted}
                                fundingAmount={completionFormData?.fundingAmount || 0}
                                validatedQuestions={(count: number) =>
                                  setProgressData((prevState: any) => ({
                                    ...prevState,
                                    funding: {
                                      ...prevState?.funding,
                                      complete: count,
                                    },
                                  }))
                                }
                              />
                            )}
                            {item.title === 'General Information' && (
                              <GeneralInformation
                                setCompletionProgramData={handleData}
                                isSaveProgress={isSaveProgress}
                                submitResponse={checkSubmitResponse}
                                setProcess={() => setProcessData('general')}
                                general={completionFormData?.general}
                                submitted={submitted}
                                validatedQuestions={(count: number) =>
                                  setProgressData((prevState: any) => ({
                                    ...prevState,
                                    general: {
                                      ...prevState?.general,
                                      complete: count,
                                    },
                                  }))
                                }
                              />
                            )}
                            {item.title === 'Impact and Scope' && (
                              <ImpactScope
                                setCompletionProgramData={handleData}
                                isSaveProgress={isSaveProgress}
                                submitResponse={checkSubmitResponse}
                                setProcess={() => setProcessData('impactAndScope')}
                                vocabData={vocabData}
                                impactScope={completionFormData?.impactAndScope}
                                submitted={submitted}
                                validatedQuestions={(count: number) =>
                                  setProgressData((prevState: any) => ({
                                    ...prevState,
                                    impactAndScope: {
                                      ...prevState?.impactAndScope,
                                      complete: count,
                                    },
                                  }))
                                }
                              />
                            )}
                            {item.title === 'Strategies and Approaches' && (
                              <StrategiesApproaches
                                setCompletionProgramData={handleData}
                                isSaveProgress={isSaveProgress}
                                submitResponse={checkSubmitResponse}
                                setProcess={() => setProcessData('strategiesAndApproaches')}
                                vocabData={vocabData}
                                strategies={completionFormData?.strategiesAndApproaches}
                                fundingAmount={completionFormData?.fundingAmount || 0}
                                submitted={submitted}
                                validatedQuestions={(count: number) =>
                                  setProgressData((prevState: any) => ({
                                    ...prevState,
                                    strategiesAndApproaches: {
                                      ...prevState?.strategiesAndApproaches,
                                      complete: count,
                                    },
                                  }))
                                }
                              />
                            )}
                            {item.title === 'Research and Evaluation' && (
                              <ResearchEvaluation
                                setCompletionProgramData={handleData}
                                isSaveProgress={isSaveProgress}
                                submitResponse={checkSubmitResponse}
                                setProcess={() => setProcessData('researchAndEvaluation')}
                                vocabData={vocabData}
                                researchEvaluation={completionFormData?.researchAndEvaluation}
                                submitted={submitted}
                                formId={getFormId}
                                validatedQuestions={(count: number) =>
                                  setProgressData((prevState: any) => ({
                                    ...prevState,
                                    researchAndEvaluation: {
                                      ...prevState?.researchAndEvaluation,
                                      complete: count,
                                    },
                                  }))
                                }
                              />
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            )}
            {!isStarted && !submitted && (
              <CompletionFooter
                saveProgress={() => {
                  setIsSaveProgress(true);
                }}
                submitResponse={() => {
                  setSubmitResponse(true);
                  // setSubmitted(true);
                }}
                progressData={progressData}
              />
            )}
            <SubmitModal
              setCompletionProgramData={handleData}
              openModal={checkSubmitResponse}
              onClose={() => setSubmitResponse(false)}
              onClickCancel={() => setSubmitResponse(false)}
              onClickSubmit={() => {
                setTempFormData((prevValues: any) => ({
                  ...prevValues,
                  status: 'submitted',
                }));
                saveData('submit');
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};

export default ExecutedProgramDashboard;
