import { Box, Typography } from '@mui/material';
import parse from "html-react-parser";
import React, { FC, useState } from 'react';
import { FlexBox } from '../../shared/components';
import { Program } from '../../shared/interfaces';
import ProgramImage from './ProgramImage';

interface Props {
  program: Program;
}
const ProgramComponent: FC<Props> = ({
  program: { type, description, name, previewImage, imageLandscape, imagePortrait, vimeoID, sio },
}) => {
  const [videoError, setVideoError] = useState<boolean>(false);
  const showVideo = () => {
    if (!videoError && (vimeoID || sio?.vimeoId)) {
      if (type !== 'causeArea') {
        return true;
      }
    }
    return false;
  };

  return (
    <FlexBox className="program_section__container">
      <FlexBox className="program_section__details">
        <Typography variant="h3">{sio?.name}</Typography>
        <Typography variant="h2">{name}</Typography>
        <Typography variant="body2">{parse(description)}</Typography>
        <Box className="ProgramVideo__container">
          {showVideo() ? (
            <a href={`https://vimeo.com/${vimeoID || sio?.vimeoId}`}>
              <iframe
                className="ProgramVideo pdf-image"
                title="Nonprofit vimeo"
                onError={() => setVideoError(true)}
                src={`https://player.vimeo.com/video/${vimeoID || sio?.vimeoId}?autoplay=0`}
              />
              <img
                id="hiddenImage"
                className="ProgramVideo pdf-image"
                src={previewImage || imageLandscape}
                alt="Fallback Program"
                style={{ display: 'none' }}
              />
            </a>
          ) : (
            <img
              className="ProgramVideo pdf-image"
              src={previewImage || imageLandscape}
              alt="Fallback Program"
            />
          )}
        </Box>
      </FlexBox>

      <ProgramImage image={imagePortrait} />
    </FlexBox>
  );
};
export default ProgramComponent;
