import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Modal,
  Grid,
  FormControlLabel,
  Checkbox,
  Link,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import BoxSection from '../../../../shared/components/common/boxSection';
import GridInput from '../../../../shared/components/common/gridTextField';
import { CK_AUTH_TOKEN, CK_USER_CLAIMS } from '../../../../shared/constants';
import './formModal.css';
import fonts from '../../../../assets/scss/font.module.scss';
import colors from '../../../../assets/scss/color.module.scss';
import { GWCookies } from '../../../../shared/utils';

interface Props {
  openModal?: boolean;
  onClickCancel?: () => void;
  onClickSubmit?: () => void;
  onClose?: () => void;
  setCompletionProgramData: any;
}

interface userDetails {
  name: string;
  email: string;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: '#FFFFFF',
  border: '1px solid #E6E6E6',
  boxShadow: 24,
  borderRadius: 5,
};

const SubmitModal: React.FC<Props> = ({
  openModal = false,
  onClickCancel,
  onClickSubmit,
  onClose,
  setCompletionProgramData,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [userInfo, setUserInfo] = useState<userDetails>();
  const [termsAndConditions, setTermsAndConditions] = useState<string>('');
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState<string>('');
  const handleChange = (event: any, key: string) => {
    setUserInfo((prevState: any) => ({
      ...prevState,
      [key]: event.target.value,
    }));
  };

  useEffect(() => {
    const userData: any = GWCookies.get(CK_USER_CLAIMS);
    // @ts-ignore
    const token: string = GWCookies.get(CK_AUTH_TOKEN);

    const parsedData = JSON.parse(userData);
    const parsedToken = token;

    const { accountId } = parsedData;
    const url = window.PORTAL_API_ENDPOINT;
    axios
      .get(`${url}accounts/${accountId}`, {
        headers: {
          authorization: parsedToken,
        },
      })
      .then((response) => {
        const instanceId: string = response?.data?.instance?.id;

        axios
          .get(`${url}instance-settings/sioprograms/${instanceId}`, {
            headers: {
              authorization: parsedToken,
            },
          })
          .then((res: any) => {
            setTermsAndConditions(res?.data?.settings?.programSubmissionTermsUrl);
            setPrivacyPolicyUrl(res?.data?.settings?.privacyPolicyUrl);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error);
          });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setCompletionProgramData(userInfo, 'review');
  }, [userInfo]);
  const commonTextStyle = {
    heading: {
      fontSize: fonts.fontSize18,
      fontWeight: fonts.fontWeight600,
      color: colors.textPrimary,
    },
    labelStyle: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight600,
      marinBottom: '3px',
      color: colors.textPrimary,
    },
    textFieldStyle: {
      height: '41px !important',
      borderRadius: '5px',
      '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '11px 10px',
        fontSize: fonts.fontSize14,
        fontWeight: fonts.fntWeight400,
      },
    },
    subHeading: {
      fontSize: fonts.fontSize16,
      fontWeight: fonts.fontWeight400,
      color: colors.textPrimary,
    },
    buttonStyle: {
      border: `1px solid ${colors.primary}`,
      color: colors.primary,
      fontSize: fonts.fontSize14,
      fntWeight: fonts.fontWeight700,
    },
  };
  return (
    <Modal
      open={openModal}
      onClose={onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              bgcolor: '#e7f2ff',
              mb: 1,
              p: 3,
              borderRadius: '20px 20px 0 0',
            }}
          >
            <Typography
              sx={{
                fontWeight: fonts.fontWeight700,
                fontSize: fonts.fontSize24,
                color: colors.TextPrimary,
              }}
            >
              Submit Confirmation
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ p: 2 }}>
            <Typography sx={{ ...commonTextStyle.heading, mb: 2 }}>
              Are you sure you submit Program Completion Form?
            </Typography>
            <BoxSection>
              <Grid item xs={12}>
                <Typography sx={{ ...commonTextStyle.heading, mb: 4 }}>
                  Please enter your Name and Email to submit application with scaleWith
                </Typography>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <GridInput
                    typographyText="Name"
                    type="text"
                    variantType="h6"
                    value={userInfo?.name}
                    onChange={(event) => {
                      handleChange(event, 'name');
                    }}
                    infoIcon
                    tooltipText="Name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <GridInput
                    typographyText="Email Address"
                    type="email"
                    variantType="h6"
                    value={userInfo?.email}
                    onChange={(event) => {
                      handleChange(event, 'email');
                    }}
                    infoIcon
                    tooltipText="Email Address"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className="modal_css"
                  control={<Checkbox checked={isChecked} />}
                  label={
                    <Typography sx={commonTextStyle.subHeading}>
                      I agree to the
                      <Link
                        href={termsAndConditions}
                        color="inherit"
                        sx={{
                          marginLeft: '3px',
                          marginRight: '3px',
                          color: colors.primary,
                          fontWeight: fonts.fontWeight600,
                        }}
                      >
                        Terms And Conditions
                      </Link>
                      &
                      <Link
                        href={privacyPolicyUrl}
                        color="inherit"
                        sx={{
                          marginLeft: '3px',
                          marginRight: '3px',
                          color: colors.primary,
                          fontWeight: fonts.fontWeight600,
                        }}
                      >
                        Privacy Policy
                      </Link>
                      . I hereby represents and warrants that all information contained herein will
                      be truthfull and accurate as of the date of said submission
                    </Typography>
                  }
                  onChange={() => {
                    if (userInfo && userInfo.name !== '' && userInfo.email !== '') {
                      setIsChecked(!isChecked);
                    } else {
                      toast.error('Please provide name and email!!!');
                    }
                  }}
                />
              </Grid>
            </BoxSection>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              p: 2,
              borderTop: '1px solid #E6E6E6',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <>
              <Button
                onClick={onClickCancel}
                sx={{
                  ...commonTextStyle.buttonStyle,
                  ml: 3,
                }}
                variant="outlined"
                size="small"
              >
                Cancel
              </Button>
              <Button
                onClick={onClickSubmit}
                sx={{
                  ...commonTextStyle.buttonStyle,
                  ml: 3,
                  backgroundColor: colors.primary,
                  color: colors.secondary,
                  '&.hover': {
                    backgroundColor: colors.primary,
                  },
                }}
                variant="contained"
                size="small"
                disabled={!isChecked}
              >
                Submit
              </Button>
            </>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default SubmitModal;
