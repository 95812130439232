import { Typography } from '@mui/material';
import React, { FC } from 'react';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface Props {
  causeAreaName?: string;
  index?: number;
  isAccoridon?: boolean;
  iconUrl?: any;
}

export const MoreDetails: FC<Props> = ({ causeAreaName, index, isAccoridon, iconUrl }) => (
  <>
    <Typography
      sx={{
        fontWeight: fonts.fontWeight700,
        fontSize: fonts.fontSize14,
        color: colors.textPrimary,
        marginTop: '2px',
        marginRight: '20px',
      }}
    >
      {index === 0 && !isAccoridon && 'UN SDGs:'}
    </Typography>
    <Typography>
      <span
        style={{
          display: 'flex',
        }}
      >
        {iconUrl !== '' && (
          <img src={iconUrl} alt="sdg icon" style={{ color: colors.textPrimary, height: '30px' }} />
        )}
        <Typography
          sx={{
            minWidth: 'auto',
            maxWidth: '160px',
            height: 'auto',
            marginLeft: '2px',
            color: colors.textPrimary,
            fontWeight: fonts.fontWeight400,
            fontSize: fonts.fontWeight14,
            lineHeight: '16px',
            alignItems: 'center',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            marginBottom: '10px',
            padding: '0px 8px 4px 8px',
          }}
        >
          {causeAreaName}
        </Typography>
      </span>
    </Typography>
  </>
);
