import React from 'react';
import { AccountType } from '../../shared/enums';
import { useAccount } from '../../shared/hooks';
import SioHome from '../sio/SioHome';
import { Procurement } from '../procurement';
import { Dashboard } from './Dashboard';

export const DashboardRoute = () => {
  const { isAccountInType } = useAccount();
  let componentToRender;

  switch (true) {
    case isAccountInType([AccountType.procurement]):
      componentToRender = <Procurement />;
      break;
    case isAccountInType([AccountType.sio]):
      componentToRender = <SioHome />;
      break;
    default:
      componentToRender = <Dashboard />;
      break;
  }

  return componentToRender;
};
