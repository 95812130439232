import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  description: string;
  value?: number | string;
}
const ImpactComponent: FC<Props> = ({ description, value }) => {
  if (!value) return null;
  return (
    <Box className="Impact__container">
      <Typography variant="h1">{value}</Typography>
      <Typography className="impactLabel">{description}</Typography>
    </Box>
  );
};
export default ImpactComponent;
