import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { FlexBox } from '../../../../shared/components';

export const SupplierLoading = () => (
  <FlexBox
    sx={{
      height: 565,
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 2,
    }}
  >
    <Typography variant="h4">Importing Data...</Typography>
    <Box sx={{ width: '100%' }}>
      <LinearProgress color="secondary" />
    </Box>
  </FlexBox>
);
