import {
  Account,
  GridRequest,
  GridResponse,
  RegisterSubscriptionRequest,
  RegisterUser,
  StripeCheckoutResponse,
  User,
  UserGrid,
} from '../../shared/interfaces';
import { httpSecuredCommon, cancelToken, httpCommon } from '../httpCommon';

const base = '/users';
const accountBaseUrl = '/account'

export const USER_API_ROUTES = {
  getUser: (id: string) => `${base}/${id}`,
  putUser: (id: string) => `${base}/${id}`,
  getUserProfile: `${base}/profile`,
  getUsers: base,
  postUser: base,
  postRegisterUser: `${base}/register`,
  putRegisterUser: `${base}/register`,
  putRegisterUserSubscription: `${base}/subscription`,
  postRegisterUserSubscription: `${base}/subscription`,
  putUserProfile: `${base}/profile`,
  searchUser: base,
  invitePostUser: `${accountBaseUrl}/invitations`,
};

export const getUser = async (id: string) => {
  const response = await httpSecuredCommon.get<User>(USER_API_ROUTES.getUser(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getUserProfile = async () => {
  const response = await httpSecuredCommon.get<User>(USER_API_ROUTES.getUserProfile, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const getUsers = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<UserGrid>>(USER_API_ROUTES.getUsers, {
    cancelToken: cancelToken.token,
    params: request,
  });
  return response;
};

export const searchUser = async (request: any) => {
  const response = await httpSecuredCommon.get<GridResponse<UserGrid>>(USER_API_ROUTES.searchUser, {
    cancelToken: cancelToken.token,
    params: request,
  });
  return response;
};

export const postUser = async (user: {
  accountId?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  roles?: Array<string>;
  type?: string;
}) => {
  const response = await httpSecuredCommon.post(USER_API_ROUTES.postUser, user, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const invitePostUser = async (user: {
  firstName?: string;
  lastName?: string;
  username?: string;
  roles?: Array<string>;
  type?: string;
}) => {
  const response = await httpSecuredCommon.post(USER_API_ROUTES.invitePostUser, user, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postRegisterUser = async (user: RegisterUser) => {
  const response = await httpCommon.post<RegisterUser>(USER_API_ROUTES.postRegisterUser, user, {
    cancelToken: cancelToken.token,
  });
  return response;
};

// registration step 3
export const putRegisterUserSubscription = async (subscription: RegisterSubscriptionRequest) => {
  const response = await httpSecuredCommon.put<StripeCheckoutResponse>(
    USER_API_ROUTES.putRegisterUserSubscription,
    subscription,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

// registration step 4
export const postRegisterUserSubscription = async (request: { checkoutSessionId: string }) => {
  const response = await httpSecuredCommon.post<StripeCheckoutResponse>(
    USER_API_ROUTES.postRegisterUserSubscription,
    request,
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

// registration step 2
export const putRegisterUser = async (registerUser: { account: Account }) => {
  const response = await httpSecuredCommon.put(USER_API_ROUTES.putRegisterUser, registerUser, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const putUser = async (
  id: string,
  user: {
    firstName?: string;
    lastName?: string;
    title?: string;
    departmentName?: string;
    phoneNumber?: string;
    active?: boolean;
    roles?: Array<string>;
  },
) => {
  const response = await httpSecuredCommon.put(USER_API_ROUTES.putUser(id), user, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const putUserProfile = async (user: {
  firstName?: string;
  lastName?: string;
  title?: string;
  departmentName?: string;
  phoneNumber?: string;
}) => {
  const response = await httpSecuredCommon.put(USER_API_ROUTES.putUserProfile, user, {
    cancelToken: cancelToken.token,
  });
  return response;
};
