import React from 'react';

interface StatusPillProps {
  text: 'approved' | 'in_progress' | 'submitted' | 'denied';
}

const statusStyles: Record<StatusPillProps['text'], { color: string; backgroundColor: string }> = {
  approved: {
    color: '#065F08',
    backgroundColor: '#EDF8EF',
  },
  submitted: {
    color: '#8D3F1C',
    backgroundColor: '#FFFFF2',
  },
  in_progress: {
    color: '#0A369D',
    backgroundColor: '#E9F5FF',
  },
  denied: {
    color: '#FF220C',
    backgroundColor: '#FFE9E7',
  },
};

const capitalizeWords = (str: string) =>
  str
    .replace('_', ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const StatusPill: React.FC<StatusPillProps> = ({ text }) => {
  const { color, backgroundColor } = statusStyles[text];
  const displayText = capitalizeWords(text);

  return (
    <div
      style={{
        display: 'inline-block',
        borderRadius: '12px',
        padding: '4px 8px',
        backgroundColor,
        border: `1px solid ${color}`,
        color,
        width: 'auto',
        minWidth: '75px',
        textAlign: 'center',
        fontWeight: 'bold',
      }}
    >
      {displayText}
    </div>
  );
};
