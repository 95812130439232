import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { renewAccessTokenThunk } from '../../../../../app';
import { FormDialog } from '../../../../../shared/components';
import { Dialogs } from '../../../../../shared/enums';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { isNullOrWhitespace, GWCookies } from '../../../../../shared/utils';
import { clearMyProfileState, getUserProfileThunk, putUserProfileThunk } from './myProfileSlice';
import { Profile } from './Profile';
import { CK_USER_CLAIMS } from '../../../../../shared/constants';
import colors from '../../../../../assets/scss/color.module.scss';

export function MyProfile() {
  const userClaims = useAppSelector((state) => state.app.userClaims);
  const profileState = useAppSelector((state) => state.myProfile);
  const dispatch = useAppDispatch();
  const { success, loading } = profileState;
  const { firstName, lastName, phoneNumber } = profileState.user;

  useEffect(() => {
    dispatch(getUserProfileThunk());

    return () => {
      dispatch(clearMyProfileState());
    };
  }, [dispatch, userClaims?.user_id]);

  useEffect(() => {
    if (success) {
      toast.success('User Profile saved successfully');
      if(GWCookies.get(CK_USER_CLAIMS)) return;
      dispatch(renewAccessTokenThunk());
    }
  }, [success, dispatch]);

  const isSaveValid = () =>
    !isNullOrWhitespace(firstName) &&
    !isNullOrWhitespace(lastName) &&
    (isNullOrWhitespace(phoneNumber)
      ? true
      : phoneNumber!.length > 9 && phoneNumber![0] !== '0' && phoneNumber![0] !== '1');

  const saveUser = () => {
    dispatch(putUserProfileThunk());
  };

  return (
    <FormDialog
      title="My Profile"
      type={Dialogs.MyProfile}
      loading={loading}
      closeFlag={success}
      Buttons={
        <Button variant="contained" sx={{ backgroundColor: !isSaveValid() ? colors.strokeDefault : colors.primary, color: colors.secondary }} size="large" onClick={saveUser} disabled={!isSaveValid()}>
          Save
        </Button>
      }
    >
      <Profile />
    </FormDialog>
  );
}
