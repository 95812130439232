import { Box, InputAdornment, TextField } from '@mui/material';
import React, { FC } from 'react';
import {
  BoldTypography,
  FlexBox,
  LabelAndSwitch,
  NumberInput,
} from '../../../../shared/components';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { InstanceFormConfig } from '../../../../shared/interfaces';
import { setInstanceSettingsData } from '../instanceSettingsSlice';

interface Props {
  value: InstanceFormConfig;
}

export const InstanceSettingsStripeSupplierForm: FC<Props> = (props) => {
  const currentLocale = useAppSelector((x) => x.instanceSettings.selectedLocale);
  const { value } = props;
  const {
    stripe: {
      supplierAnnualAmount,
      supplierAnnualEnable,
      supplierAnnualFooter,
      supplierAnnualPriceId,
      supplierMonthlyAmount,
      supplierMonthlyEnable,
      supplierMonthlyFooter,
      supplierMonthlyPriceId,
      supplierRequired,
    },
  } = value;
  const dispatch = useAppDispatch();

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 1, marginTop: 3 }}>
      <BoldTypography variant="h5">Supplier Products</BoldTypography>
      <LabelAndSwitch
        value={supplierRequired.value}
        style={{ gap: 2, flexDirection: 'row' }}
        label={supplierRequired.label}
        onChange={(e) => {
          dispatch(
            setInstanceSettingsData({
              settings: { stripe: { suppliers: { required: e.target.checked } } },
            }),
          );
        }}
      />
      {supplierRequired.value && (
        <Box>
          <FlexBox sx={{ marginTop: 3 }}>
            <BoldTypography variant="h6" sx={{ flex: 1 }}>
              Annual Subscription
            </BoldTypography>
            <LabelAndSwitch
              value={supplierAnnualEnable.value}
              style={{
                gap: 2,
                flexDirection: 'row',
                alignItems: 'center',
                flex: 1,
                justifyContent: 'end',
              }}
              onChange={(e) => {
                dispatch(
                  setInstanceSettingsData({
                    settings: {
                      stripe: { suppliers: { annual: { enabled: e.target.checked } } },
                    },
                  }),
                );
              }}
              messageNo="DISABLED"
              messageYes="ENABLED"
            />
          </FlexBox>
          <FlexBox sx={{ gap: 3, marginTop: 1, flexDirection: 'column' }}>
            <FlexBox sx={{ gap: 3 }}>
              <TextField
                label={supplierAnnualPriceId.label}
                value={supplierAnnualPriceId.value}
                required={supplierAnnualPriceId.required}
                disabled={!supplierAnnualEnable.value}
                sx={{ flex: 1 }}
                onChange={(event) => {
                  dispatch(
                    setInstanceSettingsData({
                      settings: { stripe: { suppliers: { annual: { id: event.target.value } } } },
                    }),
                  );
                }}
              />
              <TextField
                label={supplierAnnualAmount.label}
                value={supplierAnnualAmount.value}
                required={supplierAnnualAmount.required}
                disabled={!supplierAnnualEnable.value}
                sx={{ flex: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {currentLocale?.settings.currency && `(${currentLocale?.settings?.currency})`}
                    </InputAdornment>
                  ),
                  inputComponent: NumberInput as any,
                }}
                onChange={(event) => {
                  dispatch(
                    setInstanceSettingsData({
                      settings: {
                        stripe: { suppliers: { annual: { amount: Number(event.target.value) } } },
                      },
                    }),
                  );
                }}
              />
            </FlexBox>
            <TextField
              label={supplierAnnualFooter.label}
              value={supplierAnnualFooter.value}
              required={supplierAnnualFooter.required}
              disabled={!supplierAnnualEnable.value}
              sx={{ flex: 1 }}
              onChange={(event) => {
                dispatch(
                  setInstanceSettingsData({
                    settings: { stripe: { suppliers: { annual: { footer: event.target.value } } } },
                  }),
                );
              }}
            />
          </FlexBox>
          <Box sx={{ marginTop: 3 }}>
            <FlexBox>
              <BoldTypography variant="h6" sx={{ flex: 1 }}>
                Monthly Subscription
              </BoldTypography>
              <LabelAndSwitch
                value={supplierMonthlyEnable.value}
                style={{
                  gap: 2,
                  flexDirection: 'row',
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'end',
                }}
                onChange={(e) => {
                  dispatch(
                    setInstanceSettingsData({
                      settings: {
                        stripe: { suppliers: { monthly: { enabled: e.target.checked } } },
                      },
                    }),
                  );
                }}
                messageNo="DISABLED"
                messageYes="ENABLED"
              />
            </FlexBox>
            <FlexBox sx={{ gap: 3, marginTop: 1, flexDirection: 'column' }}>
              <FlexBox sx={{ gap: 3 }}>
                <TextField
                  label={supplierMonthlyPriceId.label}
                  value={supplierMonthlyPriceId.value}
                  required={supplierMonthlyPriceId.required}
                  disabled={!supplierMonthlyEnable.value}
                  sx={{ flex: 1 }}
                  onChange={(event) => {
                    dispatch(
                      setInstanceSettingsData({
                        settings: {
                          stripe: { suppliers: { monthly: { id: event.target.value } } },
                        },
                      }),
                    );
                  }}
                />
                <TextField
                  label={supplierMonthlyAmount.label}
                  value={supplierMonthlyAmount.value}
                  required={supplierMonthlyAmount.required}
                  disabled={!supplierMonthlyEnable.value}
                  sx={{ flex: 1 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currentLocale?.settings.currency &&
                          `(${currentLocale?.settings?.currency})`}
                      </InputAdornment>
                    ),
                    inputComponent: NumberInput as any,
                  }}
                  onChange={(event) => {
                    dispatch(
                      setInstanceSettingsData({
                        settings: {
                          stripe: {
                            suppliers: { monthly: { amount: Number(event.target.value) } },
                          },
                        },
                      }),
                    );
                  }}
                />
              </FlexBox>
              <TextField
                label={supplierMonthlyFooter.label}
                value={supplierMonthlyFooter.value}
                required={supplierMonthlyFooter.required}
                disabled={!supplierMonthlyEnable.value}
                sx={{ flex: 1 }}
                onChange={(event) => {
                  dispatch(
                    setInstanceSettingsData({
                      settings: {
                        stripe: { suppliers: { monthly: { footer: event.target.value } } },
                      },
                    }),
                  );
                }}
              />
            </FlexBox>
          </Box>
        </Box>
      )}
    </FlexBox>
  );
};
