import { Program, GridRequest, GridResponse } from '../../shared/interfaces';
import { httpSecuredCommon, cancelToken, httpCommon } from '../httpCommon';

const base = '/programs';

export const PROGRAM_API_ROUTES = {
  getProgram: (id: string, fundingAmount: number) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(fundingAmount)) {
      return `${base}/${id}`;
    }
    return `${base}/${id}?sio_funding_amt=${fundingAmount}`;
  },
  putProgram: (id: string) => `${base}/${id}`,
  patchProgramStatus: (id: string) => `${base}/${id}`,
  postProgram: base,
  getPrograms: base,
  getProgramAppSettings: (id: string) => `${base}/${id}/app-settings`,
};

export const getProgram = async (id: string, fundingAmount: number = 50000) => {
  const response = await httpCommon.get<Program>(PROGRAM_API_ROUTES.getProgram(id, fundingAmount), {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const postProgram = async (program: Program) => {
  const response = await httpSecuredCommon.post<Program>(PROGRAM_API_ROUTES.postProgram, program, {
    cancelToken: cancelToken.token,
  });
  return response;
};

export const putProgram = async (id: string, program: Program) => {
  const response = await httpSecuredCommon.put<Program>(
    PROGRAM_API_ROUTES.putProgram(id),
    program,
    {
      cancelToken: cancelToken.token,
    },
  );

  return response;
};

export const patchProgramStatus = async (id: string, status: string) => {
  const response = await httpSecuredCommon.patch(
    PROGRAM_API_ROUTES.patchProgramStatus(id),
    { status },
    {
      cancelToken: cancelToken.token,
    },
  );
  return response;
};

export const getPrograms = async (request: GridRequest) => {
  const response = await httpSecuredCommon.get<GridResponse<Program>>(
    PROGRAM_API_ROUTES.getPrograms,
    {
      cancelToken: cancelToken.token,
      params: request,
    },
  );
  return response;
};

export const getProgramAppSettings = async (id: string) => {
  const response = await httpSecuredCommon.get(PROGRAM_API_ROUTES.getProgramAppSettings(id), {
    cancelToken: cancelToken.token,
  });
  return response;
};
