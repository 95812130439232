import React, { FC } from 'react';
import { Button, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CardHeaderBlue, FlexBox } from '../../../shared/components';
import { formatter, Visible } from '../../../shared/utils';
import { ProcurementMetricsSupplierChart } from './ProcurementMetricsSuppliersChart';
import { useAppDispatch, useInstance } from '../../../shared/hooks';
import { Dialogs } from '../../../shared/enums';
import { setDialog } from '../../main-screen';
import colors from '../../../assets/scss/color.module.scss';
import fonts from '../../../assets/scss/font.module.scss';

interface Props {
  title: string;
  data: {
    names: Array<string>;
    values: Array<number>;
  };
  typeValue: 'money' | 'percentage';
  total: number;
  valuesLength: number;
}

export const ProcurementMetricsSupplierCard: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { data, title, total, typeValue, valuesLength } = props;
  const { locale } = useInstance();

  const formatValue = (value: number | string) => {
    if (typeValue === 'money')
      return `${locale?.settings.symbol ?? ''}${formatter.format(Number(value))}`;
    return `${value}%`;
  };

  const openDetails = () => {
    dispatch(setDialog({ open: true, type: Dialogs.ProcurementMetricsDetails, maxWidth: 'md' }));
  };

  return (
    <CardHeaderBlue
      title={title}
      styleContainer={{ width: '100%', border: '1px solid #BCBEBF'  }}
      styleTitle={{ color: colors.textPrimary, fontSize: `${fonts.fontSize18} !important`, fontWeight: 700, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',  }}
      styleHeader={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0, backgroundColor: colors.backgroundPrimary }}>

      <FlexBox sx={{ flexDirection: 'column', justifyContent: 'space-between' }}>
        <FlexBox sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '32px', fontWeight: 600 }}>{formatValue(total)}</Typography>
          <ProcurementMetricsSupplierChart
            categories={data?.names}
            series={data?.values}
            formatter={formatValue}
          />
          <FlexBox sx={{ flexDirection: 'column', width: '100%' }}>
            {data.names.map((item, index) => (
              <FlexBox
                sx={{ alignContent: 'space-around', width: '100%' }}
                // eslint-disable-next-line react/no-array-index-key
                key={`ProcurementMetrics-details-${item}-${index}`}
              >
                <Typography sx={{ flex: 1 }}>{item}</Typography>
                <Typography sx={{ flex: 0.5, textAlign: 'end', fontWeight: fonts.fontWeight700 }}>
                  {formatValue(data.values[index])}
                </Typography>
              </FlexBox>
            ))}
          </FlexBox>
        </FlexBox>
        <Visible condition={valuesLength > 0}>
          <FlexBox sx={{ alignContent: 'space-around' }}>
            <Visible condition={valuesLength - data.names.length > 0}>
              <Typography sx={{ flex: 1 }}>{`${
                valuesLength - data.names.length
              } more rows...`}</Typography>
            </Visible>
          </FlexBox>
        </Visible>
        <Button endIcon={<ArrowForwardIcon />} sx={{marginTop: '20px', width: '120px', fontSize: fonts.fontSize14,
            fontWeight: fonts.fontWeight700,}} onClick={openDetails} variant="outlined"
          size="large"
          color="info">View All</Button>
      </FlexBox>
    </CardHeaderBlue>
  );
};