import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  addCancelTokenEvent,
  patchContribution,
  getContribution,
  getCauseArea,
  deleteContribution,
  postContributionCheckoutSession,
  postContributionShare,
  getMultipleContributions,
  postPaymentMethod,
  postContributionStatus,
} from '../../../api';
import { RootState } from '../../../app/store';
import {
  postContributionThunk,
  putContributionThunk,
} from '../../../features/incentive/select-amount/selectAmountSlice';
import { ContributionActions, ContributionStatus, ContributionStatusLabel } from '../../enums';
import { Contribution, CauseArea, StripeCheckoutResponse, } from '../../interfaces';
import { redirect } from '../../utils';

interface UpdateContributionStatusModel {
  id?: string;
  incentives?: Array<Contribution>;
  proposedStatus: ContributionStatus | ContributionActions | null;
  incentive?: Contribution;
  selectedCause?: CauseArea;
  action?: ContributionStatusLabel;
  comments?: string;
  showSuccessModal?: boolean;
}

interface UpdateContributionStatusState extends UpdateContributionStatusModel {
  loading: boolean;
  success: boolean;
  transactionCompleted: boolean;
  successIncentive?: Partial<Contribution>;
  updatedIncentives: number;
  failedIncentives: number;
  updatingIncentives: boolean;
}

const initialState: UpdateContributionStatusState = {
  id: '',
  incentives: [],
  proposedStatus: null,
  loading: false,
  success: false,
  transactionCompleted: false,
  comments: '',
  showSuccessModal: false,
  updatedIncentives: 0,
  failedIncentives: 0,
  updatingIncentives: false,
};

export const getUpdateContributionStatusThunk = createAsyncThunk(
  'updateIncentiveStatus/getUpdateContributionStatusThunk',
  async (id: string, { signal }) => {
    addCancelTokenEvent(signal);
    const { data: incentiveData } = await getContribution({ contributionId: id });
    const { data: causeData } = await getCauseArea(incentiveData?.causeArea?.id || '', true);
    return { incentiveData, causeData };
  },
);

export const getMultipleContributionsThunk = createAsyncThunk(
  'updateIncentiveStatus/getMultipleContributionsThunk',
  async (ids: any, { signal }) => {
    addCancelTokenEvent(signal);
    const data = await getMultipleContributions({ ids: ids.ids });
    return data;
  },
);

export const patchUpdateContributionStatusThunk = createAsyncThunk<
Contribution,
  string,
  { state: RootState }
>('updateIncentiveStatus/patchUpdateContributionStatusThunk', async (id, { getState, signal }) => {
  addCancelTokenEvent(signal);
  const {
    updateContributionStatus: { proposedStatus, comments },
  } = getState();
  const { data } = await patchContribution(id, proposedStatus!, [{ comment: comments }]);
  return data;
});

export const postContributionStatusThunk = createAsyncThunk<
Contribution,
  string,
  { state: RootState }
>('updateIncentiveStatus/postContributionStatusThunk', async (id, { getState, signal }) => {
  addCancelTokenEvent(signal);
  const {
    updateContributionStatus: { proposedStatus, comments },
  } = getState();
  const { data } = await postContributionStatus(id, proposedStatus!, [{ comment: comments }]);
  return data;
});

export const postContributionShareThunk = createAsyncThunk<Contribution, Array<string>>(
  'updateIncentiveStatus/postContributionShareThunk',
  async (ids, { signal }) => {
    addCancelTokenEvent(signal);
    if (ids.length === 1) {
      const { data } = await postContributionShare(ids[0]);
      return data;
    }
    const responseValues = ids.map(async (id) => {
      const { data } = await postContributionShare(id);
      return data;
    });

    return responseValues[0];
  },
);

export const postContributionCheckoutSessionThunk = createAsyncThunk<
  StripeCheckoutResponse,
  void,
  { state: RootState }
>(
  'updateIncentiveStatus/postContributionCheckoutSessionThunk',
  async (_void, { getState, signal }) => {
    addCancelTokenEvent(signal);
    const {
      app: { associatedInstance },
      updateContributionStatus: { id, incentives, showSuccessModal, proposedStatus },
    } = getState();
    const { settings } = { ...associatedInstance };
    const { portalUrl } = { ...settings };
    const orderIds: Array<string> = id ? [id] : incentives!.map((x) => x.id!);
    const { data } = await postContributionCheckoutSession(orderIds,{
      // accountId: account!.id!,
      successUrl: showSuccessModal && portalUrl ? portalUrl : window.location.href,
      cancelUrl: showSuccessModal && portalUrl ? portalUrl : window.location.href,
      paymentMethod: 'stripe',
      status: proposedStatus,
    });
    return data;
  },
);

export const deleteContributionThunk = createAsyncThunk(
  'updateIncentiveStatus/deleteContributionThunk',
  async (giveIds: { id: string; incentiveId: string }, { signal }) => {
    const { id, incentiveId } = giveIds;
    addCancelTokenEvent(signal);
    await deleteContribution(id);
    return incentiveId;
  },
);

export const postPaymentMethodThunk = createAsyncThunk('updateIncentiveStatus/postPaymentMethodThunk', async (request: { id: string, incentiveId: string, proposedStatus: any, comments: any }, { signal }) => {
  addCancelTokenEvent(signal);
  const { id, incentiveId, proposedStatus, comments } = request;
  await postPaymentMethod(id, proposedStatus, [{ comment: comments}]);
  return incentiveId;
});

const updateContributionStatusSlice = createSlice({
  name: 'updateIncentiveStatus',
  initialState,
  reducers: {
    setUpdateContributionStatusRequest: (state, action: PayloadAction<UpdateContributionStatusModel>) => {
      _.assign(state, action.payload);
    },
    clearUpdateContributionStatusState: (
      state,
      action: PayloadAction<{ clearSuccessIncentive?: boolean }>,
    ) => {
      _.assign(state, initialState);
      if (action.payload.clearSuccessIncentive) state.successIncentive = undefined;
    },
    setComments: (state, action: PayloadAction<string>) => {
      state.comments = action.payload;
    },
    setCompletingGives: (state) => {
      state.updatingIncentives = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getUpdateContributionStatusThunk.fulfilled,
        (state, action: PayloadAction<{ incentiveData: Contribution; causeData: CauseArea }>) => {
          state.incentive = action.payload.incentiveData;
          state.selectedCause = action.payload.causeData;
          state.loading = false;
        },
      )
      .addCase(getUpdateContributionStatusThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUpdateContributionStatusThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(getMultipleContributionsThunk.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.incentives = data.results;
        state.loading = false;
      })
      .addCase(getMultipleContributionsThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getMultipleContributionsThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(
        patchUpdateContributionStatusThunk.fulfilled,
        (state, action: PayloadAction<Contribution>) => {
          const incentive = action.payload;
          state.loading = false;
          state.updatedIncentives += 1;
          state.success = state.id ? true : state.updatedIncentives === state.incentives?.length;
          state.showSuccessModal =
            state.proposedStatus === incentive.status && state.showSuccessModal;
          state.transactionCompleted = true;
          state.successIncentive = incentive;
        },
      )
      .addCase(patchUpdateContributionStatusThunk.rejected, (state) => {
        state.loading = false;
        state.failedIncentives += 1;
      })
      .addCase(patchUpdateContributionStatusThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(
        postContributionStatusThunk.fulfilled,
        (state, action: PayloadAction<Contribution>) => {
          const incentive = action.payload;
          state.loading = false;
          state.updatedIncentives += 1;
          state.success = state.id ? true : state.updatedIncentives === state.incentives?.length;
          state.showSuccessModal =
            state.proposedStatus === incentive.status && state.showSuccessModal;
          state.transactionCompleted = true;
          state.successIncentive = incentive;
        },
      )
      .addCase(postContributionStatusThunk.rejected, (state) => {
        state.loading = false;
        state.failedIncentives += 1;
      })
      .addCase(postContributionStatusThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(
        postPaymentMethodThunk.fulfilled,
        (state, action) => {
          state.loading = false;
          state.success = true;
          state.showSuccessModal = true;
          state.successIncentive = { incentiveId: action.meta.arg.incentiveId, id: action.meta.arg.id, status: action.meta.arg.proposedStatus };
          state.updatedIncentives += 1;
          state.transactionCompleted = true;
        },
      )
      .addCase(postPaymentMethodThunk.rejected, (state) => {
        state.loading = false;
        state.failedIncentives += 1;
      })
      .addCase(postPaymentMethodThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(postContributionThunk.fulfilled, (state, action: PayloadAction<Contribution>) => {
        state.successIncentive = action.payload;
        state.showSuccessModal = true;
      })
      .addCase(putContributionThunk.fulfilled, (state, action: PayloadAction<Contribution>) => {
        state.successIncentive = action.payload;
        state.showSuccessModal = true;
      })
      .addCase(deleteContributionThunk.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.success = true;
        state.successIncentive = { incentiveId: action.payload, status: ContributionStatus.Deleted };
        state.transactionCompleted = true;
      })
      .addCase(deleteContributionThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteContributionThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
      })
      .addCase(
        postContributionCheckoutSessionThunk.fulfilled,
        (state, action: PayloadAction<StripeCheckoutResponse>) => {
          redirect(action.payload.url);
        },
      )
      .addCase(postContributionCheckoutSessionThunk.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postContributionCheckoutSessionThunk.pending, (state) => {
        if (!state.loading) state.loading = true;
        state.loading = false;
      })
      .addCase(postContributionShareThunk.fulfilled, (state) => {
        state.success = true;
        state.loading = false;
        state.transactionCompleted = true;
      })
      .addCase(postContributionShareThunk.rejected, (state) => {
        state.success = false;
      })
      .addCase(postContributionShareThunk.pending, (state) => {
        if (state.success) state.success = false;
        if (!state.loading) state.loading = true;
      });
  },
});

export const {
  setUpdateContributionStatusRequest,
  clearUpdateContributionStatusState,
  setComments,
  setCompletingGives,
} = updateContributionStatusSlice.actions;

export const updateContributionStatusReducer = updateContributionStatusSlice.reducer;
