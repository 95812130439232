import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
// import { ReactComponent as GRI } from '../../assets/svg/Gri-logo-white.svg';
import { ReactComponent as MSCI } from '../../assets/svg/Msci-logo-white.svg';
// import { ReactComponent as SASB } from '../../assets/svg/Sasb_logo.svg';
import { FlexBox } from '../../shared/components';
import { metric } from '../../shared/interfaces';

interface Props {
  impacts: metric[];
  type: string;
}
export const MetricComponent: FC<Props> = ({ impacts, type }) => {
  const getLogo = (name: string) => {
    switch (name) {
      case 'msci':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            <MSCI width="132px" height="60px" />
          </Box>
        );
      case 'sasb':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              alt="sasb"
              className="pdf-image"
              src="https://static.scalewith.com/assets/v1/img/svg/sasb-logo-dgtext.56d9332bb3.svg"
              width={132}
              height="60px"
            />
          </Box>
        );
      case 'csrhub':
        return (
          <img
            alt="csrhub"
            className="pdf-image"
            src="https://static.scalewith.com/assets/v1/img/png/csrhub-logo-gdgtext-35.4ee2a71238.png"
            width={132}
            //  height="60px"
          />
        );
      default:
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              alt="gri"
              className="pdf-image"
              src="https://static.scalewith.com/assets/v1/img/svg/gri-logo-g.84be13c8b1.svg"
              width={132}
              height="60px"
              style={{ paddingBottom: '10px', paddingRight: '50px' }}
            />
          </Box>
        );
    }
  };
  const getCopy = (name: string) => {
    switch (name) {
      case 'msci':
        return 'Investment community ESG performance';
      case 'sasb':
        return 'Sustainability for financial performance';
      case 'csrhub':
        return 'Consensus ratings on ESG';
      default:
        return 'Impact reporting';
    }
  };
  return (
    <Box
      className="IndividualMetric__container"
      sx={{
        borderRadius: '20px',
        padding: '31px 40px 31px 40px',
      }}
    >
      <FlexBox
        sx={(theme) => ({
          borderBottom: `solid 1px ${theme.palette.common.grey.main}`,
        })}
      >
        <Box sx={{ maxWidth: '130px', maxHeight: '72px', marginRight: '2rem' }}>
          {getLogo(type)}
        </Box>
        <Typography className="metric_title" sx={{ fontWeight: 'bold', marginLeft: '3rem' }}>
          {getCopy(type)}
        </Typography>
      </FlexBox>
      <div style={{ width: '92%' }}>
        <ul style={{ display: 'flex', flexDirection: 'row' }}>
          {impacts.map((impact: metric) => (
            <li key={`${impact.label}_${type}`}>
              <span>{impact.label}</span>
            </li>
          ))}
        </ul>
      </div>
    </Box>
  );
};
